import { Component, Input, OnInit } from '@angular/core';
import { TravelReport } from 'src/app/client-management/parc-management';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);

@Component({
  selector: 'app-classification-hour-activity-chart',
  templateUrl: './classification-hour-activity-chart.component.html',
  styleUrls: ['./classification-hour-activity-chart.component.css']
})
export class ClassificationHourActivityChartComponent implements OnInit {

  classments: TravelReport[] = [];
  classmentActivityObject: Object | null = null;
  @Input()
  set activities(activities: TravelReport[]) {
    if (activities) {
      this.classments = Object.assign(activities);
        this.activityClassmentChart();
    }
  }

  constructor(private chartConfig : ChartConfigService) { }

  ngOnInit() {
    this.activityClassmentChart();
  }

  activityClassmentChart() {
    this.chartConfig.setHighCartsLang();

    var seriesData: { name: string, y: number, cust: string }[] = [];

    var totalpathTime = this.classments.reduce((acc, pt) => acc + pt.pathTime, 0);
    for (let classment of this.classments) {
      let percent = (classment.pathTime * 100) / totalpathTime;
      seriesData.push({ name: classment.vehiculeMatricule, y: percent, cust: classment.workingDurationStr })
    }

    this.classmentActivityObject = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Heure D\'activité',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, xAxis: {
        type: 'category',

        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: 'Σ.t Activité'
        },
        labels: {
          enabled: false
        }
      }, legend: {
        enabled: false
      }, credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.cust}</b>'
      },
      plotOptions: {
        column: {
          borderRadius: 5
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.cust} ',
            softThreshold: true
          }
        }
      },
      series: [{
        colorByPoint: true,
        name: 'Durée d\'activité',
        data: seriesData
      }]
    }
    Highcharts.chart('classmentHourActivityObject', this.classmentActivityObject);
  }

}
