<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<form #chargeForm="ngForm">
  <!-- start charge items -->
  <div class="row">
    <div class="col-md-3 grp-slct">
      <select class="form-control input-sm" name="transport" [(ngModel)]="chargePfbAddDto.transportType"
        [disabled]="isNotCurrentDate" required>
        <option *ngFor="let transport of transportTypeService?.transportTypes" [ngValue]="transport?.idTransportType">
          {{transport?.name}}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <div class="form-group border-color">
        <input class="form-control" type="text" name="name" min="2" [(ngModel)]="chargePfbAddDto.name" required
          autocomplete="off" placeholder="Site" title="Site" [readonly]="isNotCurrentDate" />
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="nbDays" min="0" [(ngModel)]="chargePfbAddDto.nbDays" required
          autocomplete="off" placeholder="NBR Jour" title="NBR Jour" [readonly]="isNotCurrentDate" />
      </div>
    </div>

  </div>

  <div class="row">
    <!-- Display all Poi for the departure option -->
    <div class="col-md-6">
      <div class="form-group border-color">
        <ng-select [items]="poiItems" [(ngModel)]="selectedPoiItems"
          [multiple]="true"
          bindLabel="text"
          bindValue="id"
          [clearable]="false"
          placeholder="aucun POI de départ selectionné"
          title="POI de départ"
          [disabled]="isNotCurrentDate">
        </ng-select>
      </div>
    </div>

    <!-- Display all Poi for the arrival option -->
    <div class="col-md-6">
      <div class="form-group border-color">
        <ng-select [items]="poiItems" [(ngModel)]="selectedEndPoiItems"
          [multiple]="true"
          bindLabel="text"
          bindValue="id"
          [clearable]="false"
          placeholder="aucun POI d'arrivée selectionné"
          title="POI d'arrivée"
          [disabled]="isNotCurrentDate">
        </ng-select>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-6">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="rotation" min="0" [(ngModel)]="chargePfbAddDto.rotation"
          required autocomplete="off" placeholder="Objectif Rotation" title="Objectif Rotation"
          [readonly]="isNotCurrentDate" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="caTransport" min="0" [(ngModel)]="chargePfbAddDto.caTransport"
          required autocomplete="off" placeholder="Objectif C.A Transport (DH)" title="Objectif C.A Transport (DH)"
          [readonly]="isNotCurrentDate" />
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="transportCharge" min="0"
          [(ngModel)]="chargePfbAddDto.transportCharge" required autocomplete="off"
          placeholder="Budget Charge Location (DH)" title="Budget Charge Location (DH)" [readonly]="isNotCurrentDate" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="fuelCharge" min="0" [(ngModel)]="chargePfbAddDto.fuelCharge"
          required autocomplete="off" placeholder="Budget Charge Carburant (DH)" title="Budget Charge Carburant (DH)"
          [readonly]="isNotCurrentDate" />
      </div>
    </div>

  </div>
  <!-- end charge items -->

  <!-- start charge réalisée items -->
  <h1 class="one"><span>Charge Réalisée</span></h1>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group border-color">
        <input type="text" class="form-control input-sm" bsDatepicker name="operationTime" placeholder="Date"
          [(ngModel)]="chargeRealise.operationTime" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
          [minDate]="firstDay" [maxDate]="endDate" autocomplete="off" placement="bottom">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="caRealise" min="1" [(ngModel)]="chargeRealise.caRealise"
          autocomplete="off" placeholder="C.A Réalisé (DH)" title="C.A Réalisé (DH)" />
      </div>
    </div>
  </div>

  <div *ngIf="isManually" class="row">
    <div class="col-md-4">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="caLocation" min="1" [(ngModel)]="chargeRealise.location"
          autocomplete="off" placeholder="Location Réalisé (DH)" title="Location Réalisé (DH)" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="caCarburant" min="1" [(ngModel)]="chargeRealise.carburant"
          autocomplete="off" placeholder="Gasoil Consommé (DH)" title="Gasoil Consommé (DH)" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group border-color">
        <input class="form-control" type="number" name="caRotation" min="1" [(ngModel)]="chargeRealise.rotation"
          autocomplete="off" placeholder="Rotation Réalisé" title="Rotation Réalisé" />
      </div>
    </div>
  </div>

  <!-- end charge réalisée items -->

  <div class="box-footer">
    <button *ngIf="chosenMode == 'ADD'" class="btn btn-info pull-right"
      [disabled]="!chargeForm.form.valid || !poiValid || !endPoiValid" (click)="onSaveChargePfb()">
      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
    </button>
    <button *ngIf="chosenMode == 'UPDATE'" class="btn btn-info pull-right"
      [disabled]="!chargeForm.form.valid || !poiValid || !endPoiValid" (click)="onUpdateChargePfb()">
      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
    </button>
  </div>
</form>
