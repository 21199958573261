<div class=" mt-2 mb-2 text-center well bgg">
  <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
    <i class="fa fa-plus-circle add"></i>
    <span> Balance Supply Chain - Classification véhicule</span>
  </h4>
</div>

<div class="row" style="font-family: 'Inconsolata', monospace;">
  <section class="content col-md-12 groups-container">
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-12">
        <div class="box" style="border-radius: 0px;min-height: unset;">
          <div class="box-body">
            <form class="form-horizontal" #activityForm="ngForm">
              <div class="form-group row">

                <label class="col-sm-1 control-label grp-lbl">Pôle</label>
                <div class="col-sm-2 grp-slct">
                  <select class="form-control input-sm" name="transportType" [(ngModel)]="selectedTransport"
                    (ngModelChange)="chooseTransport($event)" required>
                    <option *ngFor="let transport of transportTypeService?.transportTypes" [ngValue]="transport">
                      {{transport?.name}}
                    </option>
                  </select>
                </div>

                <label class="col-sm-1 control-label grp-lbl">Type</label>
                <div class="col-sm-2 grp-slct">
                  <select class="form-control input-sm" name="type" [(ngModel)]="selectedType" required>
                    <option *ngIf="selectedTransport.name == 'PF'" value="SOLO">
                      SOLO
                    </option>
                    <option *ngIf="selectedTransport.name == 'PF'" value="SOLO GRUE">
                      SOLO GRUE
                    </option>
                    <option *ngIf="selectedTransport.name == 'PF'" value="CANTER">
                      CANTER
                    </option>
                    <option *ngIf="selectedTransport.name == 'PF'" value="SEMI REMORQUE">
                      SEMI REMORQUE
                    </option>
                    <option *ngIf="selectedTransport.name == 'PF'" value="SEMI REMORQUE GRUE">
                      SEMI REMORQUE GRUE
                    </option>
                    <option *ngIf="selectedTransport.name == 'BPE'" value="POMPE">
                      POMPE
                    </option>
                    <option *ngIf="selectedTransport.name == 'BPE'" value="BETONNIERE">
                      BETONNIERE
                    </option>
                  </select>
                </div>

                <div class="col-lg-2 form-group">
                  <label class="col-lg-2 col-md-2 control-label">Du</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                      [(ngModel)]="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [maxDate]="endDate"
                      placement="bottom" required autocomplete="off" (ngModelChange)="onChangeStartDate()">
                  </div>
                </div>

                <div class="col-lg-2 orm-group">
                  <label class="col-lg-2 col-md-2 control-label">Au</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="startDate" autocomplete="off"
                      [maxDate]="now" placement="bottom" required>
                  </div>
                </div>

                <div class="col-sm-1 form-btns">
                  <button type="button" class="btn btn-success btn-flat btn-sm"
                    [disabled]="loadingActivity || loadingFuel || loadingRotation || !activityForm.form.valid"
                    (click)="loadAllClasifications()" title="Valider">
                    <b><i class="fa fa-play" aria-hidden="true"></i></b>
                  </button>
                </div>

                <div *ngIf="loadingActivity || loadingFuel || loadingRotation" class="col-sm-2 text-center"
                  style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loadingActivity || loadingFuel || loadingRotation"></i>
                  Chargement ...
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingActivity" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingActivity"></i> Chargement ...
              </div>
              <app-classification-percentage-chart [activities]="activities">
              </app-classification-percentage-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingActivity" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingActivity"></i> Chargement ...
              </div>
              <div *ngIf="isKmChart; else activityTemplate">
                <app-classification-kmchart [activities]="distanceDriven">
                </app-classification-kmchart>
              </div>
              <ng-template #activityTemplate>
                <app-classification-hour-activity-chart [activities]="distanceDriven">
                </app-classification-hour-activity-chart>
              </ng-template>

            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingFuel" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingFuel"></i> Chargement ...
              </div>
              <app-classification-fueling-chart [fuelings]="fuelings"></app-classification-fueling-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingRotation" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingRotation"></i> Chargement ...
              </div>
              <app-classification-rotation-chart [rotations]="rotations"></app-classification-rotation-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingFuel" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingNotification"></i> Chargement ...
              </div>
               <app-classification-speed-carto-chart [speeding]="speeding"></app-classification-speed-carto-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<ng-container *ngIf="ratings.length > 0 && !loadingActivity && !loadingFuel && !loadingRotation && !loadingNotification">
  <div class=" mt-2 mb-2 text-center well bgg">
    <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
      <i class="fa fa-plus-circle add"></i>
      <span> Balance Supply Chain - Top 5 véhicules</span>
    </h4>
  </div>

  <div class="row">
    <div class="col-md-2 col-lg-2 col-sm-4" *ngFor="let r of topFive(); let i = index;"
      [ngClass]="{'col-md-offset-1' : i == '0'}">
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img *ngIf="!r?.avatarUri" src="{{imagesDir}}/img_avatar.png" alt="Avatar">
            <img *ngIf="r?.avatarUri" src="{{r?.avatarUri}}" class="img-responsive img-radio">
          </div>
          <div class="flip-card-back">
            <h1>{{i + 1}}</h1>
            <h3>{{r?.matricule}}</h3>
            <h3>{{r?.driver}}</h3>
            <p>{{r?.note / 20 | number: '.0-2'}}/10</p>

            <span class="fa fa-star" [ngClass]="[r?.note > 0 && r?.note < 40 ? 'fa-star-half-full' : '',
            r.note > 0  ? 'checked' : ''  ]"></span>

            <span [ngClass]="[
             r?.note > 40 && r?.note < 80 ? 'fa-star-half-full' : '',
            r.note > 40  ? 'checked' : ''  ]" class="fa fa-star"></span>

            <span class="fa fa-star" [ngClass]="[
           r?.note > 80 && r?.note < 120  ? 'fa-star-half-full' : '',
            r.note > 80  ? 'checked' : ''  ]"></span>

            <span class="fa fa-star" [ngClass]="[
             r?.note > 120 && r?.note < 160  ? 'fa-star-half-full' : '',
             r.note > 120 ? 'checked' : '' ]"></span>

            <span class="fa fa-star" [ngClass]="[
             r?.note > 160 && r?.note < 200 ? 'fa-star-half-full' : '',
            r.note > 160  ? 'checked' : ''  ]"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
