<div class="app-content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">

    <div id="map" class="leaflet-fullscreen-on" [ngClass]="!isFullscreen && according ? 'according-screen' : ''"></div>

  <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
       aria-labelledby="mySmallModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
          <div class="modal-content">
              <div class="modal-header black-bg">


                  <h4 *ngIf="showButtons" class="modal-title pull-left" style="font-family: 'Inconsolata', monospace;font-size: 17px;">
                      <i class="fa fa-plus-circle"></i>
                      <span *ngIf="pointInterest.type == 1 || poiClient.type == 1">Nouvelle zone</span>
                      <span *ngIf="(selectedPoiForm === 'POI') && (pointInterest.type == 0 || poiClient.type == 0)">Nouveau point d'intérêt</span>
                      <span *ngIf="(selectedPoiForm === 'CLIENT') && (pointInterest.type == 0 || poiClient.type == 0)">Nouveau client</span>
                  </h4>
                  <h4 *ngIf="!showButtons" class="modal-title pull-left" style="font-family: 'Inconsolata', monospace;font-size: 17px;">
                      <i class="fa fa-plus-circle"></i>
                      <span *ngIf="pointInterest.type == 1 || poiClient.type == 1">Nouvelle zone</span>

                      <span *ngIf="(selectedType != 'client') && (pointInterest.type == 0 || poiClient.type == 0)">Nouveau point d'intérêt</span>
                      <span *ngIf="(selectedType === 'client') && (pointInterest.type == 0 || poiClient.type == 0)">Nouveau client</span>
                  </h4>
                  <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>

              <div *ngIf="showButtons" align="center" style="padding-top: 15px;">
                  <button type="button" (click)='selectedPoiType(1)' [ngClass]="status ? 'btn-success': 'btn-secondary active-point'"  aria-haspopup="true" aria-expanded="false" style="width: 30%;" [disabled]="status"  class="btn btn-secondary"><font size="4">Client</font></button>
                  <button type="button" (click)='selectedPoiType(2)' [ngClass]="!status ? 'btn-success': 'btn-secondary active-point'"  aria-haspopup="true" aria-expanded="false" style="width: 30%;" [disabled]="!status" class="btn"><font size="4">Point d'intérêt</font></button>
              </div>

              <div class="modal-body">
                  <app-poi-form *ngIf="selectedType != 'client' " [pointInterest]="pointInterest" [selectedPoiForm]="selectedPoiForm" [mode]="'ADD'" (pointInterestWasAdded)="childModal.hide()"></app-poi-form>
                  <app-poi-client-form *ngIf="selectedType === 'client'" [poiItems]="poiClientsPoint" [poiClient]="poiClient" [mode]="'ADD'" (poiClientWasAdded)="childModal.hide()"></app-poi-client-form>
              </div>
          </div>
      </div>
  </div>

</div>
