<ngx-loading *ngIf="loader" [show]="loader"></ngx-loading>

<div class="container" style="font-family: 'Inconsolata', monospace;font-size: 14px;">

  <div class=" mt-2 mb-2 text-center   well bgg">
    <h3 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
      Liste des Missions</h3>
  </div>

  <div class="box box-info">
      <form class="form-horizontal">
        <div class="box-body">
          <!--if report neads to choose start and end date-->
          <div class="row">
            <div class="form-group" class="col-md-6">
              <label class="col-sm-2 control-label" style="color:limegreen;">Du</label>
              <div class="col-sm-10">
                <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                  [(ngModel)]="repport.dateInterval.startDate"
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' , containerClass: 'theme-dark-blue'}" [maxDate]="maxDate"
                  placement="bottom" autocomplete="off">

              </div>
            </div>
            <div class="form-group" class="col-md-6">
              <label class="col-sm-2 control-label" style="color:limegreen;">Au</label>
              <div class="col-sm-10">

                <input type="text" class="form-control input-sm" bsDatepicker name="endDate"
                  [(ngModel)]="repport.dateInterval.endDate"
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' , containerClass: 'theme-dark-blue'}"
                  [minDate]="repport.dateInterval.startDate" placement="bottom" autocomplete="off">

              </div>
            </div>
          </div>

          <!-- choose report option (all vehicule ,by group or by vehicule)-->
          <div class=" form-group">
            <label class="col-sm-2 control-label" style="color:limegreen;">Rapport de </label>
            <div class="col-sm-10">
              <select class="form-control" [(ngModel)]='Selectedoption' (change)="onSelectingReportOf()"
                name='selectedoption'>
                <option style="font-size: 14px;" *ngFor="let opt of selectReportOf" [value]="opt.name">
                  {{opt.label}}</option>
              </select>
            </div>
          </div>

          <!-- display all vehicules of user choose this option-->
          <div class="form-group" *ngIf="reportOf && repport.rapportPayloadDto.byVehicules">
            <label class="col-sm-2 control-label" style="color:limegreen;">Véhicules</label>
            <div class="col-sm-10">

              <ng-select
              [multiple]="true"
              [items]="vehiculesItems"
              bindLabel="text"
              bindValue="id"
              placeholder="aucun vehicule selectionné"
              [(ngModel)]="vehiculesSelected"
              [clearable]="true"
              (data)="selectedVehicules($event)">
              </ng-select>
            </div>
          </div>

          <!-- display all vehicules of user choose this option-->
          <div class="form-group" *ngIf="reportOf && repport.rapportPayloadDto.byGroups">
            <label class="col-sm-2 control-label" style="color:limegreen;">Groupes</label>
            <div class="col-sm-10">

              <ng-select
              [multiple]="true"
              [items]="groupsItems"
              bindLabel="text"
              bindValue="id"
              placeholder="aucun groupe selectionné"
              [(ngModel)]="selectedGroupsItems"
              [clearable]="true"
              (data)="selectedGroups($event)">
              </ng-select>
            </div>
          </div>

          <!-- export button-->
          <div class="box-footer">
            <button class="btn btn-success pull-right" (click)='export()'>
              Exporter
            </button>
          </div>
        </div>
      </form>
  </div>
</div>
