import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timediff'
})
export class TimediffPipe implements PipeTransform {

  transform(value: number, args?: any): any {

    var minutes = Math.floor(value / 60);

    var hours = Math.floor(minutes / 60);
    minutes -= hours * 60;

    var days = Math.floor(hours / 24);
    hours -= days * 24;
 
    var str = days + " Jours, " + hours + " Heurs, " + minutes + " Minutes"

    return str;
  }

}
