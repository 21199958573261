import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopChartComponent } from './stop-chart.component';
import { ToastrModule } from 'ngx-toastr';
import { HighchartsChartModule } from 'highcharts-angular';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxLoadingModule } from 'ngx-loading';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    StopChartComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    HighchartsChartModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    StopChartComponent
  ]
})
export class StopChartModule { }
