import { Component, OnInit } from '@angular/core';
import { VehiculeService } from './providers/vehicule.service'
import { Device } from "./data-management.model";
import { NavigationEnd, Router } from "@angular/router";
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-data-management',
    templateUrl: './data-management.component.html',
    styleUrls: ['./data-management.component.css']
})
export class DataManagementComponent implements OnInit {

    vehiculeIcon: Boolean = false;
    groupeIcon: Boolean = false;
    driverIcon: Boolean = false;
    programIcon: Boolean = false;

    unusedDevices: Device[] = [];

    constructor(private vehiculeService: VehiculeService, private router: Router, public signinService :SigninService) {
    }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe(() => {
            this.initRoutes();
          });        
        this.loadUnusedDevices();
        this.vehiculeService.getReloadUnusedDevices().subscribe(() => {
            this.loadUnusedDevices();
        });
    }

    loadUnusedDevices() {
        this.vehiculeService.getUnusedDevices().subscribe(response => {
            this.unusedDevices = response;
        });
    }

    initRoutes() {
        if (this.router.isActive('client/dataManagement/groupe', false)) {
            this.displayGroupeIcon();
        }else if (this.router.isActive('client/dataManagement/vehicule', false)) {
            this.displayVehiculeIcon();
        }else if (this.router.isActive('client/dataManagement/driver', false)) {
            this.displayDriverIcon();
        }else if (this.router.isActive('client/dataManagement/program', false)) {
            this.displayProgramIcon();
        }else if (this.router.isActive('client/dataManagement/poi', false)){
            this.vehiculeIcon = false;
            this.groupeIcon = false;
            this.driverIcon = false;
            this.programIcon = false;
        }

    }

    displayVehiculeIcon() {
        this.vehiculeIcon = true;
        this.groupeIcon = false;
        this.driverIcon = false;
        this.programIcon = false;
    }

    displayGroupeIcon() {
        this.vehiculeIcon = false;
        this.groupeIcon = true;
        this.driverIcon = false;
        this.programIcon = false;
    }

    displayDriverIcon() {
        this.vehiculeIcon = false;
        this.groupeIcon = false;
        this.programIcon = false;
        this.driverIcon = true;
    }

    initAllIcons() {
        this.vehiculeIcon = false;
        this.groupeIcon = false;
        this.driverIcon = false;
        this.programIcon = false;
    }
    
    displayProgramIcon() {
        this.vehiculeIcon = false;
        this.groupeIcon = false;
        this.driverIcon = false;
        this.programIcon = true;
    }

}
