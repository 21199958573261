import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffensesComponent } from './offenses.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modals
//import { ImportExportModule } from '../../../import-export'; // Import ImportExportModule if it exists
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { LossesService } from '../../providers/losses.service';
import { DataManagementService } from 'src/app/client-management/data-management/data-management.service';
import { OffenseFormModule } from './offense-form/offense-form.module';
import { ImportExportFormsModule } from '../../import-export-forms/import-export-forms.module';
import { OffenseFormComponent } from './offense-form/offense-form.component';

@NgModule({
  declarations: [
    OffensesComponent,
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    ModalModule.forRoot(), // Import ModalModule for Bootstrap modals
    ImportExportFormsModule,
    FormsModule,
    OffenseFormModule
  ],
  providers: [
    LossesService, // Provide LossesService
    DataManagementService // Provide DataManagementService
  ],
  exports: [
    OffensesComponent // Export OffensesComponent for use in other modules
  ]
})
export class OffensesModule { }
