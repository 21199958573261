import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { saveAs as importedSaveAs } from "file-saver";
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { Group } from "../model";
import { Subscription } from 'rxjs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ExportDatatableService {

  groups: Group[] = [];
  AllGroups: Subscription = new Subscription();

  constructor(private toastr: ToastrService, private datePipe: DatePipe) { }

  public exportAsExcelFile(rows: any[], excelFileName: string): void {
    if (rows.length > 0) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
      const workbook: XLSX.WorkBook = { Sheets: { 'Compte-rendu': worksheet }, SheetNames: ['Compte-rendu'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    } else {
      this.toastr.error('Aucune ligne à exporter...');
    }
  }


  private saveAsExcelFile(buffer: any, baseFileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    importedSaveAs(data, baseFileName + '_' + this.getDateFormat(new Date()) + EXCEL_EXTENSION);
  }


  private getDateFormat(date: Date): string {
    return this.datePipe.transform(date, "dd/MM/yyyy HH:mm:ss", '', 'en-US');
  }
}
