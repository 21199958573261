import { PointInterest } from "../data-management";
import { TravelReport } from "../parc-management";
import {TransportTypeInfoDto} from "./parametrage/transport-type";

export class ChargePfbInfoDto {
    idChargePfb: number;
    name: string;
    fuelCharge: number;
    transportCharge: number;
    rotation: number;
    caTransport: number;
    nbDays: number;
    pointOfInterests?: PointInterest[];
    endPointOfInterests?: PointInterest[];
    chargesRealise?:ChargeRealise[];
    transportType?: TransportTypeInfoDto;
    constructor() {
        this.pointOfInterests = [];
        this.endPointOfInterests = [];
        this.chargesRealise = [];
        this.transportType = new TransportTypeInfoDto();
    }
}

export class ChargeRealise{
    id : number;
	caRealise : number;
	location : number;
    carburant : number;
    rotation : number;
    operationTime : Date;

    constructor(){
        this.operationTime = new Date();
    }
}


export class ChargePfbAddDto{
    idChargePfb: number;
    name: string;
    fuelCharge: number;
    transportCharge: number;
    rotation: number;
    caTransport: number;
    nbDays: number;
    pointOfInterests?: number[];
    endPointOfInterests?: number [];
    chargesRealise?:ChargeRealise[];
    transportType : number;
    constructor() {
        this.pointOfInterests = [];
        this.endPointOfInterests = [];
        this.chargesRealise = [];
    }
}

export class VoyageDto{
   commandeFerme : number;
   nbVoyage : number;
   location : number;
   travelReport : [];
   travelsByDate : Map<number,TravelReport[]> = new Map();

   constructor(){
       this.travelReport = [];
   }
}
