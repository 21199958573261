import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CimentMissionScheduleComponent } from './ciment-mission-schedule.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { CimentMissionService } from '../ciment-mission.service';
import { ScheduleChartModule } from 'src/app/client-management/charges-pfb/schedule/schedule-chart/schedule-chart.module';

@NgModule({
  declarations: [
    CimentMissionScheduleComponent // Declare the CimentMissionScheduleComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(), // Import BsDatepickerModule for date picking
    FormsModule,
    ScheduleChartModule
  ],
  providers: [
    CimentMissionService // Provide CimentMissionService
  ],
  exports: [
    CimentMissionScheduleComponent // Export CimentMissionScheduleComponent for use in other modules
  ]
})
export class CimentMissionScheduleModule { }
