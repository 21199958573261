import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { forkJoin, interval, Subscription } from 'rxjs';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MileageService } from '../../historical';
import { RealtimeRestService } from '../../realtime/services/realtime-rest.service';

interface StateItem {
  groupName: string;
}

@Component({
  selector: 'app-ciment-overview',
  templateUrl: './ciment-overview.component.html',
  styleUrls: ['./ciment-overview.component.css'],
  animations: [

    // Trigger animation cards array
    trigger('cardAnimation', [
      // Transition from any state to any state
      transition('* => *', [
        // Initially the all cards are not visible
        query(':enter', style({ opacity: 0 }), { optional: true }),

        // Each card will appear sequentially with the delay of 300ms
        query(':enter', stagger('300ms', [
          animate('.5s ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(-10px) scale(1.1)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
          ]))]), { optional: true }),

        // Cards will disappear sequentially with the delay of 300ms
        query(':leave', stagger('300ms', [
          animate('500ms ease-out', keyframes([
            style({ opacity: 1, transform: 'scale(1.1)', offset: 0 }),
            style({ opacity: .5, transform: 'scale(.5)', offset: 0.3 }),
            style({ opacity: 0, transform: 'scale(0)', offset: 1 }),
          ]))]), { optional: true })
      ]),
    ])
  ]
})
export class CimentOverviewComponent implements OnInit {


  public currentStateByGroup: any[] = [];


  loading = false;

  globalLoading: boolean = false;

  /** UPDATE TRACKING TIMER */
  updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;

  vehiculesWithMileage: any[] = [];
  vehicules: any[] = [];

  indexToScrollTo = 0;

  @ViewChild('breakModal', { static: false }) breakModal: ModalDirective;
  @ViewChildren("groupData", { read: ElementRef }) renderedGroups: QueryList<ElementRef>;

  constructor(public realtimeRestService: RealtimeRestService, private mileageService: MileageService) { }

  ngOnInit() {
    // load current state (vehicules state)! and groups
    this.loadCurrentStatesWithMileage();
  }



  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 1200;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        this.loadCurrentStatesWithMileage();
      } else {
        if (this.updateTrackingTimer % 5 == 0) {
          this.dynamicScroller();
        }
      }
    })
  }

  dynamicScroller() {

    if (this.indexToScrollTo == this.currentStateByGroup.length - 1) {
      this.indexToScrollTo = 0;
    } else {
      this.indexToScrollTo ++;
      if (this.indexToScrollTo > this.currentStateByGroup.length - 1) {
        this.indexToScrollTo = this.currentStateByGroup.length - 1;
      }
    }

    const groupToScrollOn = this.renderedGroups.toArray();
    groupToScrollOn[this.indexToScrollTo].nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  loadCurrentStatesWithMileage() {
    this.globalLoading = true;
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let startHour: any = date.getTime();
    this.vehiculesWithMileage = [];
    this.indexToScrollTo = 0;
    forkJoin([
      this.mileageService.getDailyMileageOfAllVehicules({
        startDate: startHour,
        endDate: startHour
      }),
      this.realtimeRestService.getCurrentState(true)
    ])
      .subscribe(
        ([vehiculesWithMileage, currentState]) => {
          this.vehiculesWithMileage = vehiculesWithMileage;
          this.currentStateMap(currentState);
          this.startUpdateTrackingTimer();
          this.globalLoading = false;
        }, (err) => {
          this.vehiculesWithMileage = [];
          this.currentStateByGroup = [];
          this.globalLoading = false;
        })
  }

  currentStateMap(currentState: any) {
    let startNames: [] = currentState.startState.map((item: { groupName: any; }) => item.groupName);
    let stopNames: [] = currentState.stopState.map((item: { groupName: any; }) => item.groupName);
    let parkingNames: [] = currentState.parkingState.map((item: { groupName: any; }) => item.groupName);
    let breakDownNames: [] = currentState.breakDownState.map((item: { groupName: any; }) => item.groupName);
    var listOfGroupNames = startNames.concat(stopNames, parkingNames, breakDownNames);

    var groups = new Set(listOfGroupNames);
    this.currentStateByGroup = [];
    this.vehicules = [];
    groups.forEach(groupName => {
      let startStateData = currentState.startState.filter((item: { groupName: any; }) => item.groupName == groupName);
      let stopStateData = currentState.stopState.filter((item: { groupName: any; }) => item.groupName == groupName);
      let parkingStateData = currentState.parkingState.filter((item: { groupName: any; }) => item.groupName == groupName);
      let panneStateData = currentState.breakDownState.filter((item: { groupName: any; }) => item.groupName == groupName);
      let vehicules = this.vehiculesWithMileage.filter(item => item.groupeName === groupName);
      let totalMileage = vehicules.reduce((acc, cur) => acc + cur.totalMileage, 0)
      Array.prototype.push.apply(this.vehicules, vehicules.map(v => ({ deviceId: v.idDevice, matricule: v.vehiculeMatricule })));

      this.currentStateByGroup.push({
        group: groupName,
        start: startStateData as StateItem[], // Assertion de type
        stop: stopStateData as StateItem[], // Assertion de type
        parking: parkingStateData as StateItem[], // Assertion de type
        panne: panneStateData as StateItem[], // Assertion de type
        isOpen: false,
        nbVehicule: vehicules.length,
        mileage: totalMileage
      })
    });
  }

  breakWasSaved() {
    this.updateTrackingTimer = null;
    this.loadCurrentStatesWithMileage();
    this.breakModal.hide();
  }

  showBreakForm() {
    this.breakModal.show();
  }

}
