import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiTypeSettingsFormComponent } from './multi-type-settings-form.component'; // Adjust the path if necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modal functionality
import { MultiTypeService } from '../../../providers/multi-type-service';

@NgModule({
  declarations: [
    MultiTypeSettingsFormComponent // Declare the MultiTypeSettingsFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule to handle form controls
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    ModalModule.forRoot() // Import ModalModule for modal functionality
  ],
  providers: [
    MultiTypeService // Provide MultiTypeService
  ],
  exports: [
    MultiTypeSettingsFormComponent // Export MultiTypeSettingsFormComponent for use in other modules
  ]
})
export class MultiTypeSettingsFormModule { }
