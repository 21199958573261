<form #batteryForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Date *</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" class="form-control input-sm" bsDatepicker name="operationTime" [(ngModel)]="battery.operationTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" placement="bottom"
                    placeholder="Date Garantie" required autocomplete="off">
            </div>
            <label class="col-sm-2 control-label">Date de réparation réelle</label>
            <div class="col-sm-4">
                <input type="text" class="form-control input-sm" bsDatepicker [(bsValue)]="battery.actualRepairTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    placeholder="Date de réparation réelle" autocomplete="off">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Durée garantie *</label>
            <div class="col-lg-4 col-md-4">
                <input type="number" [(ngModel)]="battery.duration" name="duration" class="form-control" placeholder="Durée garantie / ans"
                    min="1" required>
            </div>
            <label class="col-sm-2 control-label">Date d'expiration</label>
            <div class="col-lg-4 col-md-4">
                <input disabled type="text" class="form-control input-sm" name="expirationDate" [ngModel]="battery.expirationDate | date:'dd-MM-yyyy, HH:mm'">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Ns batterie *</label>
            <div class="col-lg-4 col-md-4">
                <input name="number" [(ngModel)]="battery.number" #number="ngModel" type="number" class="form-control"
                    placeholder="Ns batterie" required min="1">
            </div>
            <label class="col-sm-2 control-label">Amperage</label>
            <div class="col-lg-4 col-md-4">
                <input name="amperage" [(ngModel)]="battery.amperage" type="number" class="form-control" placeholder="Amperage"
                    min="1">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Voltage</label>
            <div class="col-lg-4 col-md-4">
                <input name="voltage" [(ngModel)]="battery.voltage" type="number" class="form-control" placeholder="Voltage"
                    min="1">
            </div>
            <label class="col-sm-2 control-label">Numéro Serie *</label>
            <div class="col-lg-4 col-md-4">
                <input name="Numéro Serie" [(ngModel)]="battery.serieNumber" #serieNumber="ngModel" type="text" class="form-control"
                    placeholder="Numéro Serie" required maxlength="50">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Marque *</label>
            <div class="col-lg-4 col-md-4">
                <input name="Marque" [(ngModel)]="battery.mark" #mark="ngModel" type="text" class="form-control"
                    placeholder="Marque" required maxlength="50">
            </div>
            <label class="col-sm-2 control-label">Coût *</label>
            <div class="col-lg-4 col-md-4">
                <input name="cost" [(ngModel)]="battery.cost" #cost="ngModel" type="number" class="form-control"
                    placeholder="Coût" required min="1">
            </div>
        </div>
        <div *ngIf="battery.provider.id != 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Fournisseur *</label>
            <div class="col-sm-10">
                <select [(ngModel)]="battery.provider.id" name="providerId" #providerId="ngModel" class="form-control select2"
                    required>
                    <option [value]="0">Autres</option>
                    <option *ngFor="let provider of providers" [selected]="provider.id == battery.provider.id" [value]="provider.id">{{provider?.name}}</option>
                    <option hidden [value]="null"></option>
                </select>
            </div>
        </div>
        <div *ngIf="battery.provider.id == 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Nom Fournisseur *</label>
            <div class="col-sm-10">
                <input [(ngModel)]="battery.provider.name" name="providerName" #providerName="ngModel" type="text"
                    class="form-control" placeholder="Nom Fournisseur" required maxlength="50">
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="battery.provider.id = null;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Observation</label>
            <div class="col-lg-4 col-md-4">
                <input name="Observations" [(ngModel)]="battery.observation" maxlength="50" type="text" class="form-control"
                    placeholder="Observation">
            </div>
            <label class="col-sm-2 control-label">Coût de main d'oeuvre</label>
            <div class="col-lg-4 col-md-4">
                <input name="laborCost" [(ngModel)]="battery.laborCost" type="number" class="form-control" placeholder="Coût de main d'oeuvre"
                    min="1">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Références</label>
            <div class="col-lg-4 col-md-4">
                <input name="referenceDADR" [(ngModel)]="battery.referenceDADR" maxlength="50" type="text" class="form-control"
                    placeholder="Références">
            </div>
        </div>
        <div class="box-footer">
            <button type="submit" *ngIf="mode == 'ADD'" [disabled]="!batteryForm.form.valid" class="btn btn-info pull-right"
                (click)="onAdd(batteryForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
            <button type="submit" *ngIf="mode == 'UPDATE'" [disabled]="!batteryForm.form.valid" class="btn btn-info pull-right"
                (click)="onUpdate(batteryForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
        </div>
    </div>
</form>