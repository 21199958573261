import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { User } from 'src/app/authentification/signin/credentials';
import { SigninService } from 'src/app/authentification/signin/signin.service';


export function getDefaultRout(): string {
  const currentUser: User = JSON.parse(localStorage.getItem('currentUser') ?? '{}');
  if (!currentUser) {
    return '/signin';
  }
  if (currentUser.isRoot) {
    return '/client/realtime';
  } else if (currentUser.authorities) {
    if (currentUser.authorities.find(authority => authority.name === 'ROLE_RT')) {
      return '/client/realtime';
    }
    for (let i = 0; i < currentUser.authorities.length; i++) {
      switch (currentUser.authorities[i].name) {
        case 'ROLE_OV':
          return '/client/overview';
        case 'ROLE_ACOV':
          return '/client/activityOverview';
        case 'ROLE_PATH':
          return '/client/historical';
        case 'ROLE_SM':
          return '/client/statistical';
        case 'ROLE_DM':
          return '/client/dataManagement';
        case 'ROLE_MISSION':
          return '/client/missions';
        case 'ROLE_PM':
          return '/client/parcManagement';
        case 'ROLE_PM_CD':
          return '/client/parcManagement/documentCirculation';
        case 'ROLE_PM_FUEL':
          return '/client/parcManagement/carburant';
        case 'ROLE_PM_VIDANGE':
          return '/client/parcManagement/vidange';
        case 'ROLE_PM_MAINTENANCE':
          return '/client/parcManagement/entretien';
        case 'ROLE_PM_LOSSES':
          return '/client/parcManagement/losses';
        case 'ROLE_PM_LEASING':
          return '/client/parcManagement/leasing';
        case 'ROLE_PM_VARIOUS':
          return '/client/parcManagement/divers';
        case 'ROLE_PM_DC':
          return '/client/parcManagement/driverCharge';
        case 'ROLE_PM_DASHBOARD':
          return '/client/parcManagement/dashbord';
        case 'ROLE_RM':
          return '/client/dataExport';
        case 'ROLE_AM':
          return '/client/alertManagement/alertSettings';
        case 'ROLE_GEO':
          return '/client/geo-marketings';
        case 'ROLE_ACBTNT':
          return '/client/activityBtnt';
        case 'ROLE_CHARGE_PFB':
          return '/client/parametrage/chargesPfb';
        case 'ROLE_CHARGE_PFB_PASSAGE':
          return '/client/parametrage/chargesPfbPassage';
        case 'ROLE_CHARGE_PFB_TRANSPORT_TYPE':
          return '/client/parametrage/chargesPfbtransportType';
        case 'ROLE_CHARGE_PFB_VALIDATOR':
          return '/client/parametrage/chargesPfbvalidator';
        case 'ROLE_CHARGE_PFB_ORDONNENCEMENT':
          return '/client/chargesPfbSchedule';
        case 'ROLE_CHARGE_PFB_AFFECTATION':
          return '/client/chargesPfb/affectation';
        case 'ROLE_CHARGE_PFB_TRACKING':
          return '/client/chargesPfbTracking';
        case 'ROLE_CHARGE_PFB_TRACKING_HISTORY':
          return '/client/chargesPfbHistory';
        case 'ROLE_CIMENT':
          return '/client/cimentManagement';
        case 'ROLE_GLOBAL_OVERVIEW':
          return '/client/globalOverviewManagement';
        case 'ROLE_PERFORMANCE_CANAL':
          return '/client/canalPerfomance/type';
        case 'ROLE_PERFORMANCE_CHANTIER':
          return '/client/affectationCharge';
        case 'ROLE_RAM':
          return '/client/rentalAgreementManagement';
        case 'ROLE_TMM':
          return '/client/tmm';
      }
    }
    return '/signin';
  } else {
    return '/signin';
  }
}

@Injectable()
export class HistoricalGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_PATH')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class DataManagementGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_DM')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class ParcMangementGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_PM') ||
      this.signinService.isHasRole('ROLE_PM_CD') ||
      this.signinService.isHasRole('ROLE_PM_FUEL') ||
      this.signinService.isHasRole('ROLE_PM_VIDANGE') ||
      this.signinService.isHasRole('ROLE_PM_MAINTENANCE') ||
      this.signinService.isHasRole('ROLE_PM_LOSSES') ||
      this.signinService.isHasRole('ROLE_PM_LEASING') ||
      this.signinService.isHasRole('ROLE_PM_VARIOUS') ||
      this.signinService.isHasRole('ROLE_PM_DASHBOARD')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class AlertGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_AM')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class ImportExportGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_RM')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class OverviewGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_OV')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class ActivityOverview implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasActivity() ||
      this.signinService.isHasRole('ROLE_ACOV')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class GeoMarketing implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasGeoMarketing() ||
      this.signinService.isHasRole('ROLE_GEO')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class RentalAgreementGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRentalAgreementOption() ||
      this.signinService.isHasRole('ROLE_RAM')
    ) {
      return true;
    }
    return false;
  }
}

@Injectable()
export class MissionGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isHasMissionManagement() ||
      this.signinService.isHasRole('ROLE_MISSION')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class chargesPfb implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isHasChargePfb() ||
      this.signinService.isHasRole('ROLE_CHARGE_PFB') ||
      this.signinService.isHasRole('ROLE_CHARGE_PFB_PASSAGE') ||
      this.signinService.isHasRole('ROLE_CHARGE_PFB_TRANSPORT_TYPE') ||
      this.signinService.isHasRole('ROLE_CHARGE_PFB_VALIDATOR') ||
      this.signinService.isHasRole('ROLE_CHARGE_PFB_ORDONNENCEMENT') ||
      this.signinService.isHasRole('ROLE_CHARGE_PFB_AFFECTATION') ||
      this.signinService.isHasRole('ROLE_CHARGE_PFB_TRACKING') ||
      this.signinService.isHasRole('ROLE_CHARGE_PFB_TRACKING_HISTORY')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class CimentManagement implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isHasCimentManagement() ||
      this.signinService.isHasRole('ROLE_CIMENT')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class GlobalOverviewManagement implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isHasGlobalOverviewManagement() ||
      this.signinService.isHasRole('ROLE_GLOBAL_OVERVIEW')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class TransportMonitoringMaintenance implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isHasTransportMonitoringMaintenance() ||
      this.signinService.isHasRole('ROLE_TMM')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class OverallView implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isHasOverallViewManagement() ||
      this.signinService.isHasRole('ROLE_OVERALL_VIEW')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class CanalPerformanceManagement implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isHasCanalPerformanceManagement() ||
      this.signinService.isHasRole('ROLE_PERFORMANCE_CANAL')
    ) {
      return true;
    }
    return false;
  }
}

@Injectable()
export class AffectationManagement implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isHasAffectationManagement()
      || this.signinService.isHasRole('ROLE_PERFORMANCE_CHANTIER')
    ) {
      return true;
    }
    return false;
  }
}

@Injectable()
export class engineControl implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasEngineControl() ||
      this.signinService.isHasRole('ROLE_ENGINE_CONTROLE')
    ) {
      return true;
    }

    return false;
  }
}


@Injectable()
export class ActivityBtnt implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_ACBTNT')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class AccountManagementGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (this.signinService.isRootAdmin()) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class StatisticalGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_SM')
    ) {
      return true;
    }

    return false;
  }
}

@Injectable()
export class FuelGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasCarburantOption()
    ) {
      return true;
    }

    return false;
  }


}

@Injectable()
export class RealTimeGuard implements CanActivate {
  constructor(private signinService: SigninService) { }

  canActivate() {
    if (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_RT')
    ) {
      return true;
    }

    return false;
  }
}
