import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFullDuration'
})
export class FormatFullDurationPipe implements PipeTransform {

  transform(value: number): string {
    if (!value && value !== 0) {
      return '';
    }
  
    const days = Math.floor(value / 86400);
    const hours = Math.floor((value % 86400) / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;
  
    let formattedDuration = '';
  
    if (days > 0) {
      formattedDuration += `${days}d `;
    }
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
    if (minutes > 0 || hours > 0 || days > 0) {
      formattedDuration += `${minutes}min `;
    }
    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }
  
    return formattedDuration.trim();
  }
  

}
