<form #breakDownForm="ngForm" class="form-horizontal">

  <div class="box-body">

    <div class="has-success col-md-12">

      <label class="col-lg-1 col-md-1 control-label">Véhicule</label>
      <div class="col-lg-3 col-md-3 mrg">

        <ng-multiselect-dropdown-angular7 name="vehiculeSelected" [data]="vehicules" placeholder="select Véhicule"
          [(ngModel)]="breakDown.deviceId" [settings]="vehiculesDropdownSettings" (onSelect)="onItemSelect($event)"
          required>
        </ng-multiselect-dropdown-angular7>
      </div>

      <label class="col-sm-1 control-label grp-lbl">Date *</label>
      <div class="col-sm-3">
        <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
          [(ngModel)]="breakDown.beginStopTime" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
          [maxDate]="maxDate" placement="bottom" required autocomplete="off">
      </div>

      <div class="col-lg-2 col-md-2 mrg">
        <select class="form-control input-sm" name="type" [(ngModel)]="breakDown.active" required>
          <option value=true>
            Oui
          </option>
          <option value=false>
            Non
          </option>
        </select>
      </div>

      <div class="col-lg-2 col-md-2">
        <button type="submit" class="btn btn-info pull-right" (click)="onSaveBreakDown(breakDownForm)"
          [disabled]="!breakDownForm.form.valid || loading">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
        </button>
      </div>
    </div>
  </div>

</form>