import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertManagementComponent } from './alert-management.component'; // Adjust the import path as necessary
import { AlertManagementService } from './alert-management.service'; // Import the service
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; // Import Angular Material Slide Toggle Module
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    AlertManagementComponent // Declare the AlertManagementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(), // Initialize Toastr notifications
    MatSlideToggleModule,
    MatCheckboxModule,
    RouterModule,
    NgxLoadingModule.forRoot({}),
    LeafletModule,
    SharedPipesModule,
    TruncateModule
    // Uncomment if needed
  ],
  providers: [
    AlertManagementService // Provide the AlertManagementService
  ]
})
export class AlertManagementModule { }
