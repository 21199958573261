import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ChargePfbInfoDto, ChargePfbAddDto, ChargeRealise } from '../charges-pfb';
import { DataManagementService, PointInterest } from '../../data-management';
import { ToastrService } from 'ngx-toastr';
import { ChargesPfbService } from '../charges-pfb.service';
import {TransportTypeInfoDto, TransportTypeService} from "../parametrage/transport-type";

@Component({
  selector: 'app-charges-pfb-form',
  templateUrl: './charges-pfb-form.component.html',
  styleUrls: ['./charges-pfb-form.component.scss']
})
export class ChargesPfbFormComponent implements OnInit {

  @Output()
  chargePfbSaved: EventEmitter<ChargePfbInfoDto> = new EventEmitter<ChargePfbInfoDto>();

  chargeRealise: ChargeRealise = new ChargeRealise();

  loading: boolean = false;
  poiValid: boolean = false;
  endPoiValid: boolean = false;


  chargePfb: ChargePfbInfoDto = new ChargePfbInfoDto();
  chargePfbAddDto: ChargePfbAddDto = new ChargePfbAddDto();

  pointInterests: PointInterest[] = [];
  poiItems: { text: string | number; id: number }[] = [];
  selectedPoiItems: { text: string; id: number }[] = [];
  selectedPoiHash: string = "";

  selectedEndPoiItems: { text: string; id: number }[] = [];
  selectedEndPoiHash: string = "";
  chosenMode: string = 'ADD';

  firstDay: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  endDate: Date = new Date();
  selectedMonth_: number;
  selectedYear_:number;
  isNotCurrentDate = false;

   @Input()
  set selectedYear(year: number) {
    this.selectedYear_ = year;
  }

  /**
   * selected month
   * in case the selected month is equal to current month
   * and selected year is also equal to current year then
   * initialize the first and endDate with new date
   */
  @Input()
  set selectedMonth(month: number) {
    this.selectedMonth_ = month;

    this.isNotCurrentDate = this.selectedMonth_ != new Date().getMonth() + 1 || this.selectedYear_ != new Date().getFullYear();
    if (this.isNotCurrentDate) {
      this.endDate = new Date(this.selectedYear_, month, 0);
      this.firstDay = new Date(this.selectedYear_, month - 1, 1);
    } else {
      this.firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      this.endDate = new Date();
    }
  }

  transportTypes: TransportTypeInfoDto[] = [];

  @Input()
  set mode(mode: string) {
    this.chosenMode = mode;
  }

  @Input()
  set selectedCharge(charge: ChargePfbInfoDto) {
    /**
   * if charge is not null then assign the object else create new object
   */
    if (charge != null) {
      /** passage par copie */
      this.chargePfbAddDto = Object.assign(this.chargePfbAddDto, charge);
      this.chargePfbAddDto.transportType = charge.transportType.idTransportType;
      if (this.chargePfbAddDto.chargesRealise[0]) {
        /** passage par copie */
        Object.assign(this.chargeRealise, this.chargePfbAddDto.chargesRealise[0]);
      }
      else {
        this.chargeRealise = new ChargeRealise();
        if (this.isNotCurrentDate)
          this.chargeRealise.operationTime = null;
      }
      this.mapSelectedPoisToItems(charge.pointOfInterests);
      this.mapSelectedEndPoisToItems(charge.endPointOfInterests);
    }
    else {
      this.chargePfbAddDto = new ChargePfbAddDto();
      this.chargeRealise = new ChargeRealise();
      this.mapSelectedPoisToItems([]);
      this.mapSelectedEndPoisToItems([]);
    }

  }

  /**
   * manual or automatic
   */
  @Input() isManually: any;

  constructor(private toastr: ToastrService,
    public dataManagementService: DataManagementService,
    private chargesPfbService: ChargesPfbService, public transportTypeService: TransportTypeService) {
  }

  ngOnInit() {
    this.loadPOI();
    this.loadTransportTypes();
  }

  /**
   * load all poi (markers only)
   */
  loadPOI() {
    this.loading = true;
    this.dataManagementService.getAllPointInterests()
      .subscribe(pointInterests => {
        this.loading = false;
        this.pointInterests = pointInterests.filter(p => p.type === 'MARKER');
        this.mapPoisToItems(this.pointInterests);

      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargement", "Point Interest")
      });
  }


  /**
   * load all transport types
   */
  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.loading = true;
      this.transportTypeService.findAllTransportTypes().subscribe(transportTypes => {
        this.transportTypeService.transportTypes = transportTypes;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Type de transport");
      })
    }
  }


  mapPoisToItems(pois: PointInterest[]) {
    this.poiItems = [];
    for (let i = 0; i < pois.length; i++) {
      this.poiItems.push({
        id: pois[i].idPointInterest,
        text: pois[i].name
      });
    }
  }

  mapSelectedPoisToItems(pois: PointInterest[]) {
    this.selectedPoiItems = [];
    for (let i = 0; i < pois.length; i++) {
      this.selectedPoiItems.push({
        id: pois[i].idPointInterest,
        text: pois[i].name
      });
    }

    this.selectedPois(this.selectedPoiItems);
  }

  selectedPois(pois: { id: number; text: string }[]) {
    let prefix = "";
    this.selectedPoiHash = "";
    pois.forEach(poi => {
      this.selectedPoiHash += prefix + poi.id;
      prefix = ",";
    });
    if (pois.length > 0)
      this.poiValid = true;
    else
      this.poiValid = false;
    this.chargePfbAddDto.pointOfInterests = this.selectedPoiHash.split(',').map(x => +x);

  }


  mapSelectedEndPoisToItems(pois: PointInterest[]) {
    this.selectedEndPoiItems = [];
    for (let i = 0; i < pois.length; i++) {
      this.selectedEndPoiItems.push({
        id: pois[i].idPointInterest,
        text: pois[i].name
      });
    }

    this.selectedEndPois(this.selectedEndPoiItems);
  }

  selectedEndPois(pois: { id: number; text: string }[]) {
    let prefix = "";
    this.selectedEndPoiHash = "";
    pois.forEach(poi => {
      this.selectedEndPoiHash += prefix + poi.id;
      prefix = ",";
    });
    if (pois.length > 0)
      this.endPoiValid = true;
    else
      this.endPoiValid = false;
    this.chargePfbAddDto.endPointOfInterests = this.selectedEndPoiHash.split(',').map(x => +x);
  }

  /**
   * reset charge pfb data
   */
  resetChargePfb() {
    this.chargePfb = new ChargePfbInfoDto();
    this.chargePfbAddDto = new ChargePfbAddDto();
    this.chargeRealise = new ChargeRealise();
    this.selectedPoiItems = [];
    this.selectedEndPoiItems = [];
  }

  /**
   * save charge pfb
   */
  onSaveChargePfb() {
    this.loading = true;
    this.chargePfbAddDto.chargesRealise.push(this.chargeRealise);
    this.chargesPfbService.add(this.chargePfbAddDto)
      .subscribe(chargePfb => {
        this.chargePfbSaved.emit(chargePfb);
        this.resetChargePfb();
        this.loading = false;
        this.toastr.success("Enregistré", "Charge PFB");
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors d'ajout", "Charge PFB");
      })
  }

/**
   * update charge pfb
   */
  onUpdateChargePfb() {
    this.loading = true;
    this.chargePfbAddDto.chargesRealise = [];
    if (this.chargeRealise.operationTime)
      this.chargePfbAddDto.chargesRealise.push(this.chargeRealise);
    this.chargesPfbService.update(this.chargePfbAddDto, this.selectedYear_, this.selectedMonth_)
      .subscribe(chargePfb => {
        this.resetChargePfb();
        this.chargePfbSaved.emit(chargePfb);
        this.loading = false;
        this.toastr.success("Modifié", "Charge PFB");
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors modification", "Charge PFB");
      })
  }

}
