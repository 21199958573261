<section class="content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
    <div class="row">
        <div class="datatable-container" id="datatable-container" style="margin-top: 20px;">
            <div class="col-md-12 datatable-container-col">
                <div class="box" style="border-radius: 0px;">
                    <div class="overlay" *ngIf="!isResultLoaded">
                        <i class="fa fa-refresh fa-spin"></i>
                    </div>
                    <div class="box-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                                <div class="col-sm-1 grp-slct">
                                    <select class="form-control input-sm" [(ngModel)]="selectedGroup" name="groups" (ngModelChange)="chooseGroup($event)">
                                        <option *ngFor="let group of groups" [ngValue]="group">
                                            <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst | truncate:[30]}}  / </span> {{group.nom}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label vhcl-lbl">Vehicule</label>
                                <div class="col-sm-1 vhcl-slct">
                                    <select class="form-control input-sm" [(ngModel)]="selectedVehicule" name="vehicules" (ngModelChange)="chooseVehicule($event)">
                                        <option *ngFor="let vehicule of selectedGroup.vehicules" [ngValue]="vehicule">{{dataManagementService.getVehiculeName(vehicule)}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label dd-lbl">Date début</label>
                                <div class="col-sm-2 dd-npt">
                                    <div class="input-group">

                                        <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}" placement="bottom" [maxDate]="endDate" >
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                                    </div>
                                </div>
                                <label class="col-sm-1 control-label df-lbl">Date fin</label>
                                <div class="col-sm-2 df-npt">
                                    <div class="input-group">

                                        <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm', containerClass: 'theme-dark-blue'}" placement="bottom" [minDate]="startDate">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                                    </div>
                                </div>
                                <div class="col-sm-2 sl-npt">
                                    <angular2-multiselect name="selectItems" [data]="dropdownList" [(ngModel)]="selectItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                                    </angular2-multiselect>
                                </div>
                                <div class="col-sm-1 form-btns">
                                    <button type="button" class="btn btn-success btn-flat btn-sm" (click)="displayChart()">
                                        Afficher
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-12 charts">
                <div id="container"></div>
            </div>
            <div *ngIf="options && useFuel" class="col-md-12 charts">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <h3 class="box-title">Véhicule : {{selectedVehicule.matricule}}</h3>

                        <!--==================================== Export functionality(START)=============== -->
                        <div class="option-export">
                            <span title="Enregistrer sous format excel" (click)="export()">
                                <i *ngIf="!loader" class="fa fa-download" ></i> 
                                <i *ngIf="loader" class="fa fa-refresh fa-spin"></i>
                                Exporter</span>
                        </div>
                        <!--==================================== Export functionality(END)================= -->

                        <div class="box-tools pull-right">
                            <button type="button" class="btn btn-box-tool" data-widget="collapse">
                                <i class="fa fa-minus"></i>
                            </button>
                            <button type="button" class="btn btn-box-tool" data-widget="remove">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="box-body">
                        <div class="table-responsive">
                            <table class="table no-margin">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Volume</th>
                                        <th>Odometre</th>
                                    </tr>
                                </thead> 
                                <tbody>
                                    <tr *ngFor="let rep of repFuelVariation | reverse">
                                        <td *ngIf="rep.type == 'APPRO'">
                                            <span class="label label-success">Appro</span>
                                        </td>
                                        <td *ngIf="rep.type == 'THIEF'">
                                            <span class="label label-danger">Diminution</span>
                                        </td>
                                        <td>
                                            <a href="">{{rep.approStartTime | date:'dd-MM-yyyy, HH:mm'}}</a>
                                        </td>
                                        <td>{{((rep.approEndFuelLevel - rep.approStartFuelLevel) / 100 * volume) | round}}
                                        </td>
                                        <td *ngIf="deviceopt.useFms == 1">{{rep.approStartOdo}} KM</td>
                                        <td *ngIf="deviceopt.useFms == 0">-----</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
