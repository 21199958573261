import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // For template-driven forms
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker functionality
import { ToastrModule } from 'ngx-toastr'; // For toast notifications
import { CorrelationLocationComponent } from './correlation-location.component'; // Import the component

@NgModule({
  declarations: [
    CorrelationLocationComponent  // Declare the CorrelationLocationComponent
  ],
  imports: [
    CommonModule,                 // Common directives like ngIf, ngFor
    FormsModule,                  // FormsModule for ngModel and ngForm
    BsDatepickerModule.forRoot(),  // Bootstrap datepicker module
    ToastrModule.forRoot()        // Initialize Toastr notifications
  ],
  exports: [
    CorrelationLocationComponent    // Export the component for use in other modules
  ]
})
export class CorrelationLocationModule { }
