<div class="button-container">
  <div class="trajet-container">
    <div class="trajet" 
      [ngClass]="{ spinner: !isDataLoaded }" 
      (click)="showItem()"
    >
      <ng-container *ngIf="isDataLoaded; else loading">
        <div class="bars">
          <div
            width="8%"
            data-toggle="tooltip"
            title="{{ parentDetails?.startAddress }}"
          >
            <img
              src="assets/icons/realtime/km-jour/play-button.png"
              class="icon"
              alt="My Icon"
            />
            {{ parentDetails?.startAddress | slice : 0 : 17 }}
            <span *ngIf="parentDetails && parentDetails.startAddress && parentDetails.startAddress.length > 20"> ...</span>
          </div>
          <div
            width="8%"
            style="flex: 1.6;"
          >
            <img
              src="assets/icons/realtime/km-jour/highway.png"
              class="icon"
              alt="My Icon"
            />
            {{ parentDetails?.distanceDriven | number : "1.0-3" }} Km
          </div>
          <div
            width="8%"
            data-toggle="tooltip"
            title="{{ parentDetails?.endAddress }}"
          >
            <img
              src="assets/icons/realtime/km-jour/stop-button.png"
              class="icon"
              alt="My Icon"
            />
            {{ parentDetails?.endAddress | slice : 0 : 17 }}
            <span *ngIf="(parentDetails && parentDetails.endAddress  && parentDetails.startAddress.length > 20)"> ...</span>
          </div>
          <div>
            <img
            src="assets/icons/realtime/km-jour/truck.png"
            class="icon-image"
            alt="My Icon"
            /> {{selectedVehicule?.matricule}}
          </div>
          <div *ngIf="useFms" style="flex: 1.4;">
            <img
            src="assets/icons/realtime/km-jour/gas-station.png"
            class="icon-image"
            alt="My Icon"
            /> {{parentDetails?.usedFuel}} L
          </div>
        </div>
        <div class="bars bar2">
          <div>
            <img
              src="assets/icons/realtime/km-jour/hourglass.png"
              class="watch"
              alt="My Icon"
            />
            {{ (parentDetails ? parentDetails.pathDuration  : 00) | durationSec}}
          </div>
          <div
              style="flex: 1.6;"
            >
            <img
              src="assets/icons/realtime/km-jour/Speed.png"
              class="icon"
              alt="My Icon"
            />
            {{ parentDetails?.maxSpeed }} Km/ H
          </div>
          <div>
            <img
              src="assets/icons/realtime/km-jour/Key.png"
              class="icon"
              alt="My Icon"
            />
            {{ (parentDetails ? parentDetails.stopDuration: 00) | durationSec }}
          </div>
          <div>
            <img
            src="assets/icons/realtime/km-jour/driver.png"
            class="icon-image"
            alt="My Icon"
            /> {{dataManagementService.getDriverName(selectedVehicule.driver) | slice : 0 : 15}}
          </div>
          <div *ngIf="useFms" style="flex: 1.4;">
            <img
            src="assets/icons/realtime/km-jour/gas-pump.png"
            class="icon-image"
            alt="My Icon"
            /> {{calculeConsumation(parentDetails?.fuelConsumption) | number: '1.0-2'}} %
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <div style="padding-bottom: 2px">
    <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
  </div>
</ng-template>
