import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs/Subscription';
import { imagesPOIDir } from '../../../global.config';
import { RapportDto, Vehicule } from '../../data-management';
import { DataManagementService } from "../../data-management/data-management.service";
import { ImportExportService } from '../../import-export';
import { OilChange, Provider, Group, MultiType } from '../model/index';
import { OilChangeService } from '../providers/oil-change-service';

@Component({
    selector: "app-vidange",
    templateUrl: "./vidange.component.html",
    styleUrls: ["./vidange.component.css"]
})
export class VidangeComponent implements OnInit {
    /** select item */
    selectedItem = 'OIL_CHANGE';

    /** import (start)*/

    @ViewChild('tireModal', { static: false })
    tireModal: ModalDirective;

  @ViewChild('tireDeleteModal', { static: false })
  tireDeleteModal: ModalDirective;

  @ViewChild('importModal', { static: false })
  importModal: ModalDirective;

  @ViewChild('oilChangeModal', { static: false })
  oilChangeModal: ModalDirective;

  @ViewChild('oilChangeDeleteModal', { static: false })
  oilChangeDeleteModal: ModalDirective;

    /** export list of oil change as excel report */
    repport: RapportDto = new RapportDto();

    oilChanges: OilChange[] = [];

    oilChangesId: number[] = [];

    vehicule: Vehicule = new Vehicule();

    group: Group = new Group();

    getGroupWasSelectedSubscription: Subscription;

    getVehiculeWasSelectedSubscription: Subscription;

    oilChange: OilChange = new OilChange();

    mode: 'ADD' | 'UPDATE' = 'ADD';

    imagesPOIDir = imagesPOIDir;

    loading: boolean = false;

    isDeleteMultiple: boolean = false;

  searchTerm: string | null = null;
  constructor(private oilChangeService: OilChangeService, public dataManagementService: DataManagementService,
        public toastr: ToastrService, public exportImport: ImportExportService) {
    }

    ngOnInit() {

        if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
            this.vehicule = this.dataManagementService.selectedVehicule;
            this.group = this.dataManagementService.selectedGroup;
            this.loadOilChanges();
        }

        this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
            this.vehicule = vehicule;
            this.loadOilChanges();
        });

        this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
            this.group = group;
        });

    }

    ngOnDestroy() {
        if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
        if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
    }

    /**
     * Charger la liste des vidanges
     */
    loadOilChanges() {
        if (!this.vehicule.idVehicule) return;
        this.loading = true;
        this.oilChangeService.getListOilChanges(this.vehicule.idVehicule).subscribe(oilChanges => {
            this.oilChanges = oilChanges;
            this.loading = false;
        }, error => {
            this.loading = false;
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Afficher modal de modification du vidange
     */
    onUpdate(oilChange: OilChange) {
        if (!oilChange.provider) {
            oilChange.provider = new Provider();
        }
        if(!oilChange.refTypeAirF){
            oilChange.refTypeAirF = new MultiType();
        }
        if(!oilChange.refTypeEngineOil){
            oilChange.refTypeEngineOil = new MultiType();
        }
        if(!oilChange.refTypeFuelF){
            oilChange.refTypeFuelF = new MultiType();
        }
        if(!oilChange.refTypeOilF){
            oilChange.refTypeOilF = new MultiType();
        }
        if(!oilChange.refTypeSeparatorF){
            oilChange.refTypeSeparatorF = new MultiType();
        }

        this.oilChange = Object.assign({}, oilChange);
        if (this.oilChange.operationTime) this.oilChange.operationTime = new Date(this.oilChange.operationTime);
        this.mode = 'UPDATE';
        this.oilChangeModal.show();
    }

    /**
     * Afficher modal d'ajout du vidange
     */
    onAdd() {
        this.oilChange = new OilChange();
        this.mode = 'ADD';
        this.oilChangeModal.show();
    }

    /**
     * Afficher modal de confirmation du suppression
     */
    onConfirmDelete(oilChange: OilChange) {
        this.oilChange = oilChange;
        if (!this.oilChange.provider) {
            this.oilChange.provider = new Provider();
        }
        if(!this.oilChange.refTypeAirF){
            this.oilChange.refTypeAirF = new MultiType();
        }
        if(!this.oilChange.refTypeEngineOil){
            this.oilChange.refTypeEngineOil = new MultiType();
        }
        if(!this.oilChange.refTypeFuelF){
            this.oilChange.refTypeFuelF = new MultiType();
        }
        if(!this.oilChange.refTypeOilF){
            this.oilChange.refTypeOilF = new MultiType();
        }
        if(!this.oilChange.refTypeSeparatorF){
            this.oilChange.refTypeSeparatorF = new MultiType();
        }
        this.isDeleteMultiple = false;
        this.oilChangeDeleteModal.show();
    }

    /**
     * Suppression du vidange
     */
    delete() {
        this.loading = true;
        this.oilChangeService.delete(this.oilChange.id).subscribe(response => {
            if (response) {
                this.oilChanges = this.oilChanges.filter(oilChange => oilChange.id !== this.oilChange.id);
                this.oilChangesId = this.oilChangesId.filter(oilChangeId => oilChangeId != this.oilChange.id);
                this.toastr.success('Suppression effectuée avec succès.', 'info');
            } else {
                this.toastr.error('La vidange n\'a pas supprimé correctement !', 'Erreur');
            }
            this.oilChangeDeleteModal.hide();
            this.loading = false;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }


    /**
     * Suppression multiple du vidange
     */
    deleteMultiple() {
        this.loading = true;
        this.oilChangeService.deleteMultiple(this.oilChangesId).subscribe(response => {

            if (response) {
                for (let oilChangeId of this.oilChangesId) {
                    this.oilChanges = this.oilChanges.filter(oilChange => oilChange.id != oilChangeId);
                }

                this.oilChangesId = [];
                this.toastr.success('Suppression effectuée avec succès.', 'info');
            } else {
                this.toastr.error('La vidange n\'a pas supprimé correctement !', 'Erreur');
            }

            this.oilChangeDeleteModal.hide();
            this.loading = false;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * Actualiser la liste des vidanges apés l'événement d'ajout
     */
    oilChangeWasAdded(oilChange: OilChange) {
        this.oilChangeModal.hide();
        this.oilChanges.unshift(oilChange);
    }

    /**
     * Actualiser la liste des vidanges apés l'événement de modification
     */
    oilChangeWasUpdated(oilChange: OilChange) {
        this.oilChangeModal.hide();

        for (let i = 0; i < this.oilChanges.length; i++) {
            if (this.oilChanges[i].id == oilChange.id) {
                this.oilChanges[i] = oilChange;
                break;
            }
        }

    }

    export() {
        this.repport.type = 'OIL_CHANGE';
        this.exportImport.export(this.oilChanges, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
    }

    import() {
        this.importModal.show();
    }

    itemWasImported(res: boolean) {
        if (res) {
            this.importModal.hide();
            this.loading = false;
            this.loadOilChanges();
        }
    }

    /**
     * Event confirm delete multiple
     */
    onConfirmDeleteMultiple() {
        if (this.oilChangesId.length == 0) {
            this.toastr.warning("Veuillez choisir des vidanges à supprimer !");
            return;
        }
        this.isDeleteMultiple = true;
        this.oilChangeDeleteModal.show();
    }

    /**
     * Event delete
     */
    onDelete() {
        if (this.isDeleteMultiple) {
            this.deleteMultiple();
        } else {
            this.delete();
        }
    }

    /**
     * get list search
     */
    getOilChanges(): OilChange[] {
        if ((this.searchTerm) && (this.searchTerm.length > 0)) {
           return this.oilChanges.filter(oilChange => (oilChange.provider) && (oilChange.provider.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
        } else {
            return this.oilChanges;
        }
    }

    getOvertaking(oilChange : OilChange, index: number): number {
        if(!oilChange)
        return null;
        if(oilChange.overtaking){
            return oilChange.overtaking;
        }else{
            if(index+1 < this.oilChanges.length && oilChange.typeEngineOil){
                return +((oilChange.odometre -  this.oilChanges[index+1].odometre ) - oilChange.typeEngineOil)
            }else{
                return null;
            }
        }
    }

    /**
     * Event check row for delete multiple
     */
    onChecked(id: number) {
        if (this.oilChangesId.find(oilChangeId => oilChangeId == id)) {
            this.oilChangesId = this.oilChangesId.filter(oilChangeId => oilChangeId != id);
        } else {
            this.oilChangesId.push(id);
        }
    }

    /**
     * Event check all row for delete multiple
     */
    onCheckedAll() {
        if (this.oilChangesId.length == this.oilChanges.length) {
            this.oilChangesId = [];
        } else {
            this.oilChangesId = [];
            for (let oilChange of this.oilChanges) {
                this.oilChangesId.push(oilChange.id);
            }
        }
    }

    /**
     * check if id exists in array,
     */
    isCheckedInList(id: number): boolean {
        return this.oilChangesId.find(oilChangeId => oilChangeId == id) ? true : false;
    }

}
