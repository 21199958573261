import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DataManagementService, Group, PointInterest, RapportDto, VehiculesDto } from '../../data-management';
import { ImportExportService, rapportsItem, reportOf } from '../../import-export';
import { saveAs as importedSaveAs } from 'file-saver';


@Component({
  selector: 'app-ciment-rotation',
  templateUrl: './ciment-rotation.component.html',
  styleUrls: ['./ciment-rotation.component.css']
})
export class CimentRotationComponent implements OnInit {


  /** GROUP VAR */
  selectedGroup: Group = new Group();


  groups: Group[] = [];

  vehicules: VehiculesDto[] = [];

  loader = false;

  public ANIMATION_TYPES = ngxLoadingAnimationTypes;

  /** GROUP SUBSCRIPTION */
  // groupsSubscription: Subscription = null;

  loading: boolean;

  repport: RapportDto = new RapportDto();

  reportOf = true;

  maxDate: Date = new Date();

  vehiculesItems: { text: string | number; id: number }[] = [];
  vehiculesSelected: { text: string; id: number }[] = [];
  selectedVehiculesHash: string = "";
  groupsItems: { text: string | number; id: number }[] = [];
  selectedGroupsItems: { text: string; id: number }[] = [];
  selectedGroupsHash: string = "";

  /**option */
  Selectedoption: string = 'ALL_VEHICULES';
  /**options */
  selectReportOf = reportOf;

  rapports = [{ name: 'PATH_POI_COUNT', label: 'Rapport des voyages Par P.O.I' },
  { name: 'PATH_ZONE', label: 'Rapport des voyages Par Zone' }];

  poiName: PointInterest[] = [];
  filteredPoi: PointInterest[] = [];

  constructor(private dataManagementService: DataManagementService,
    private toastr: ToastrService,
    public exportImport: ImportExportService,
    private localeService: BsLocaleService
  ) {
    this.loader = this.exportImport.loader;
  }

  ngOnInit() {
    this.localeService.use('fr');

    var toDay = new Date();
    this.repport.dateInterval.startDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 0, 0, 0, 0);
    this.repport.dateInterval.endDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59, 59);
    this.repport.dateInterval.startDate.setTime(this.repport.dateInterval.startDate.getTime())
    this.init();
  }

  init() {
    this.repport.rapportPayloadDto.allVehicules = true;
    this.repport.rapportPayloadDto.byGroups = false;
    this.repport.rapportPayloadDto.byVehicules = false;
    this.repport.type = 'PATH_POI_COUNT';
    this.Selectedoption = 'ALL_VEHICULES';

    /**get only groups with more than one vehicule */
    this.dataManagementService.getAllGroupsDetails('').subscribe(res => {
      this.groups = res.filter(group => group.vehicules.length > 0);
      this.mapGroupsToItems(this.groups);
      if (this.groups) {
        this.getVehicules();
      }
    });

    this.dataManagementService.getListNamesOfPointInterests().subscribe(res => {
      this.poiName = res;
      this.filteredPoi = this.poiName.filter(p => p.type === 'MARKER');
    });
  }

  /**get vehicule  Ids */
  getVehicules() {
    this.groups.forEach(group => {
      group.vehicules.forEach(vehicule => {
        let v = new VehiculesDto();
        v.matricule = vehicule.matricule;
        v.idVehicule = vehicule.idVehicule;
        if (this.checkIfVehiculeExists(v)) {
          this.vehicules.push(v);
        }
      });
    });

    this.vehiculesToItems(this.vehicules);
  }

  mapGroupsToItems(groups: Group[]) {
    this.groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      let parentName: string;
      if (groups[i].nameGroupParent) {
        parentName = groups[i].nameGroupParent + ' / ' + groups[i].nom;
      } else { parentName = groups[i].nom }
      this.groupsItems.push({
        id: groups[i].idGroupe,
        text: parentName
      });
    }
  }

  selectedGroups(groups: { id: number; text: string }[]) {
    let prefix = "";
    this.selectedGroupsHash = "";
    groups.forEach(group => {
      this.selectedGroupsHash += prefix + group.id;
      prefix = ",";
    });
    this.repport.rapportPayloadDto.idGroups = this.selectedGroupsHash.split(',').map(x => +x);
  }

  vehiculesToItems(vehicules: VehiculesDto[]) {
    this.vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesItems.push({

        id: vehicules[i].idVehicule,
        text: vehicules[i].matricule
      });
    }
  }

  itemsToVehicules(
    items: {
      id: {
        id: number;
      };
      text: string;
      parent: number;
    }[]
  ) {
    let vehicules: any[] = [];
    for (let i = 0; i < items.length; i++) {
      vehicules.push({
        idVehicule: items[i].id.id,
        matricule: items[i].text
      });
    }
    this.selectedVehicules(vehicules);
  }

  selectedVehicules(vehicules: { id: number; text: string }[]) {
    let prefix = "";
    this.selectedVehiculesHash = "";
    vehicules.forEach(vehicule => {
      this.selectedVehiculesHash += prefix + vehicule.id;
      prefix = ",";
    });
    this.repport.rapportPayloadDto.idVehicules = this.selectedVehiculesHash.split(',').map(x => +x);
  }

  /** check if vehicule already exists */
  checkIfVehiculeExists(vehicule: VehiculesDto) {
    let v = this.vehicules.filter(ve => ve.idVehicule === vehicule.idVehicule);
    if (v.length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  onSelectingReport() {
    this.repport.rapportPayloadDto.poiAdresses = [];
    this.repport.rapportPayloadDto.endPoiAdresses = [];
    if (this.repport.type === 'PATH_ZONE') {
      this.filteredPoi = this.poiName.filter(p => p.type === 'POLYGON')
    } else {
      this.filteredPoi = this.poiName.filter(p => p.type === 'MARKER')
    }
  }

  /**on switching between report of all vehicule,by group and by vehicule */
  onSelectingReportOf() {
    /**give ability to downoald */
    switch (this.Selectedoption) {
      case 'ALL_VEHICULES':
        this.repport.rapportPayloadDto.allVehicules = true;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      case 'BY_GROUPS':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = true;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      case 'BY_VEHICULE':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = true;
        break;
      default:
        this.repport.rapportPayloadDto.allVehicules = true;
    }
  }

  export() {
    this.loader = true;
    this.exportImport.getRepport(this.repport).subscribe(
      blob => {
        if (blob.size > 0) {
          importedSaveAs(blob, 'Rapport ' + this.exportImport.getReportName(this.repport.type) + '.xlsx');
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        }

        this.loader = false;
      },
      error => {
        this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        this.loader = false;
      }
    );
  }
}
