import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { InterventionService, Intervetion } from './intervention.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-intervention-list',
  templateUrl: './intervention-list.component.html',
  styleUrls: ['./intervention-list.component.css']
})
export class InterventionListComponent implements OnInit {

  @Input() mode: string; // Ensure this input property is defined
  @Input() intervention: Intervetion; // Ensure this input property is defined


  // pagination vars
  public maxSize: number = 5;
  public bigTotalItems: number = 175;
  public bigCurrentPage: number = 1;
  public numPages: number = 0;
  itemsPerPage = 6;

  // local vars
  intervetions: Intervetion[] = [];
  currentIntervention: Intervetion = new Intervetion();
  loading: boolean;

  @ViewChild('deleteModal', { static: false })
  deleteModal: ModalDirective;

  @ViewChild('addEditModal', { static: false })
  addEditModal: ModalDirective;

  // mode = 'ADD';

  constructor(private interventionService: InterventionService, public toastr: ToastrService) { }

  ngOnInit() {
    this.loadIntervetions();
  }

  loadIntervetions() {
    this.loading = true;
    this.interventionService
      .getAll(
        this.bigCurrentPage - 1,
        this.itemsPerPage
      )
      .subscribe(
        intervetions => {
          this.intervetions = intervetions.content;
          this.bigTotalItems = intervetions.totalElements;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  /**
  * callback : when page change !
  * */
  public pageChanged(event: any): void {
    this.bigCurrentPage = event.page;
    this.loadIntervetions();
  }

  onDelete() {
    this.loading = true;
    this.interventionService.delete(this.currentIntervention.id).subscribe(deleted => {
      this.loading = false;
      this.toastr.success('demande bien supprimer !', 'info');
      this.bigCurrentPage = 1
      this.loadIntervetions();
      this.deleteModal.hide();
    }, () => {
      this.loading = false;
      this.deleteModal.hide();
    })
  }

  init(){
    this.currentIntervention = new Intervetion();
  }
}
