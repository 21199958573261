import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { DateInterval } from 'src/app/shared/model';
import { AffectationInfo } from '..';
import { ChargePfbInfoDto } from '../../charges-pfb/charges-pfb';
import { AffectationChargeService } from '../affectation-charge/affectation-charge.service';
interface IMonth {
  id: number;
  name: string;
}
interface IYear {
  id: number;
  name: string;
}

@Component({
  selector: 'app-history-suivi-charge',
  templateUrl: './history-suivi-charge.component.html',
  styleUrls: ['./history-suivi-charge.component.css']
})
export class HistorySuiviChargeComponent implements OnInit {

  /**
   * Map of CA Realise
   */
  mapCaRealise: any;

  /** used for the filter by date */
  endDate: Date | null = new Date();
  startDate: Date = new Date();
  /** used as min date */
  firstDay = new Date();
  /** used as max date */
  lastDay: Date = new Date();
  date: DateInterval = new DateInterval();
  years: IYear [] = [];
  months: IMonth[] = [];
  selectedMonth: number;
  selectedYear: IYear = this.years[0];

  now: Date = new Date();
  selectedaffectation: AffectationInfo | null;
  selectedChargePfb: ChargePfbInfoDto | null;
  /** list of affectations */
  affectations: AffectationInfo[] = [];
  filtredAffectations: AffectationInfo[] = [];
  dayDif: number = 0;

  /**
   * list of chiffre d'affaire realise
   */
  caRealise: any[] = [];
  fueling: any[] = [];
  maintenances: any[] = [];
  /** for Corrélation charts */
  crCaRealise: any[] = [];

  /** realise loader */
  loadingTracking = false;

  constructor(private affectationService: AffectationChargeService, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.years = this.affectationService.getYears();
    this.selectedYear = this.years[0];
    this.months = this.affectationService.months.filter(m => m.id < this.now.getMonth() + 1);
  }

  /**
   *
   * @param month A numeric value equal to the month value.
   * load All charges by year and month
   */
  loadHistoricalCharges(month: number) {
    this.affectationService.getAffectations(this.selectedYear.id, month)
      .subscribe(affectation => {
        this.affectations = affectation;
        this.selectedaffectation = this.affectations[0];
      }, err => {
        this.toastr.error("Erreur lors de chargements", "Affectation")
      })
  }

  /**
   *
   * @param affectation An object value equal to the AffectationInfo value.
   * Action Affectation
   */
  chooseAffectation(affectation: any) {
    this.selectedaffectation = affectation;
  }



  /**
  * Action change date début
  */
  onChangeStartDate() {
    if (this.startDate && this.endDate && this.endDate < this.startDate) {
      this.endDate = null;
    }
  }



/**
   * load data by date and selected affectation
   */

  onTracking() {

    this.selectedChargePfb = new ChargePfbInfoDto();

    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;

    /** get the days difference and check if the result is bigger than the selected number of days */
    this.loadingTracking = true;

    /** get the days difference and check if the result is bigger than the selected number of days */
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);
    if (this.dayDif >= this.selectedaffectation.nbrJours)
      this.dayDif = this.selectedaffectation.nbrJours;
    else
      this.dayDif;

    /**
     * assign selectedChargePfb with selectedaffectation
     * so we can use it for the charts because our charts
     * require to have Charge pfb attributes
     */
    this.selectedChargePfb.nbDays = this.selectedaffectation.nbrJours;
    this.selectedChargePfb.fuelCharge = this.selectedaffectation.budgetCarburant;
    this.selectedChargePfb.transportCharge = this.selectedaffectation.budgetLocation;
    this.selectedChargePfb.caTransport = this.selectedaffectation.chiffreAffaire;

    forkJoin(
      [this.affectationService.getLocationRealise(this.selectedaffectation.idChargeChantier, this.date),
      this.affectationService.getMaintenanceCharge(this.selectedaffectation.group.idGroupe, this.date),
      //  this.affectationService.getLocationCharge(this.selectedaffectation.group.idGroupe, this.date),
      this.affectationService.getFuelCharge(this.selectedaffectation.group.idGroupe, this.date)
      ]).subscribe(([caRealise, maintenances, /**locations,**/ fueling]) => {
        this.fueling = fueling;
      this.maintenances = maintenances as any[];
      // this.locations = locations;

        /**
       * mapCaRealise has two types
       * 1) ListByDay = ca grouped by date
       * and it is used when the user choose more than one date
       * 2) SimpleList = it is used when the user choose only
       * on date
       */
        this.mapCaRealise = caRealise;
        this.caRealise = this.mapCaRealise.simpleList;
        if (this.dayDif > 1) {
          this.crCaRealise = this.mapCaRealise.ListByDay;
        } else {
          this.crCaRealise = this.caRealise;
        }
        this.loadingTracking = false;
      }, err => {
        this.fueling = [];
        this.maintenances = [];
       // this.locations = [];
        this.loadingTracking = false;
        this.toastr.error("Erreur lors de chargement", "C.A Réalisé")
      })

  }

  /**
   * Action year change
   */
  onYearChange() {
    if (this.selectedYear.id == new Date().getFullYear()) {
      this.months = this.affectationService.months.filter(m => m.id < this.now.getMonth() + 1);
    } else
      this.months = this.affectationService.months;

    /**
     * check if selected month exists in months list
     * well, this is used for the current year
     * because in the current year we only display
     * months before the current month
     */
    if (this.months.find(m => m.id == this.selectedMonth))
      this.onMonthChange(this.selectedMonth)
  }

  /**
   * Action month change
   */
  onMonthChange(month: number) {
    if (!month) return;

    this.endDate = new Date(this.selectedYear.id, month, 0, 23, 59, 59, 59);
    this.startDate = new Date(this.selectedYear.id, month - 1, 1, 0, 0, 0, 0);
    this.lastDay = new Date(this.selectedYear.id, month, 0);
    this.firstDay = new Date(this.selectedYear.id, month - 1, 1);

    this.selectedaffectation = null;
    this.selectedChargePfb = null;
    this.affectations = [];
    this.selectedMonth = month;
    this.loadHistoricalCharges(month);
  }

}
