import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { Mission, RouteLegs, StateMission, TrackingMission, TrackingMissionData } from 'src/app/client-management/data-management';
import { imagesCarsDir } from 'src/app/global.config';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { Passage, PassageService, ScheduleService } from '../schedule';
import { TransportTypeInfoDto, TransportTypeService } from "../parametrage/transport-type";
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-mission-return-tracking',
  templateUrl: './mission-return-tracking.component.html',
  styleUrls: ['./mission-return-tracking.component.scss']
})
export class MissionReturnTrackingComponent implements OnInit {
  [x: string]: any;

  /** IMAGE CAR */
  imagesCarsDir = imagesCarsDir;

  /** GROUP SUBSCRIPTION */
  groupsSubscription: Subscription | undefined = undefined;



  missions: Mission[] = [];

  stateMap: Map<string, number> = new Map<string, number>(
    [
      ["INPROGRESS", 1],
      ["PENDING", 4],
      ["DELIVERY", 3],
      ["DELAY", 3],
      ["PROBABLE_ARRIVAL", 2]
    ]
  );

  //POle And Site
  selectedPassage: Passage = new Passage();
  selectedTransport: TransportTypeInfoDto = new TransportTypeInfoDto();
  vehiculesId: number[] = [];

  /** UPDATE TRACKING TIMER */
  updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;

  /** LOADING */
  loadingTracking: boolean = false;


  loading: boolean = false;

  missionIds: Number[] = [];

  trackingMissionData: TrackingMissionData[] = [];
  trackingMissionDataSorted: TrackingMissionData[] = [];

  trackingIndex: number[] = [];

  trackingSize = 0;

  isSorting = false;

  constructor(private toastr: ToastrService,
    private geocodingService: GeocodingService,
    private scheduleService: ScheduleService,
    public transportTypeService: TransportTypeService,
    public passageService: PassageService) { }

  ngOnInit() {
    this.loadTransportTypes();
    // this.passagesData();
    //this.loadMissions();
  }



  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer(index: any) {
    // Assurez-vous que updateTrackingTimerSubscription n'est pas null avant de tenter de le désabonner
    if (this.updateTrackingTimerSubscription) {
      this.updateTrackingTimerSubscription.unsubscribe();
    }

    // Initialisez updateTrackingTimer avec une valeur sûre
    this.updateTrackingTimer = 300;

    // Abonnez-vous à l'observable d'intervalle
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      // Vérifiez que updateTrackingTimer n'est pas null avant de le décrémenter
      if (this.updateTrackingTimer !== null) {
        this.updateTrackingTimer--;
      }

      // Si updateTrackingTimer atteint 0, arrêtez le timer et chargez les missions
      if (this.updateTrackingTimer === 0) {
        // Assurez-vous que updateTrackingTimerSubscription n'est pas null avant de tenter de le désabonner
        if (this.updateTrackingTimerSubscription) {
          this.updateTrackingTimerSubscription.unsubscribe();
        }

        this.loadMissions();

        if (
          this.trackingMissionData[index] &&
          this.trackingMissionData[index].trackingMission != null
        ) {
          this.onTracking(index, true);
        }
      }
    });
  }

  /** LOAD MISSIONS */
  loadMissions() {

    this.loading = true;
    this.missionIds = [];
    this.trackingIndex = [];
    this.trackingMissionDataSorted = [];
    this.trackingMissionData = [];
    //if(this.missionIds.length == 0)
    this.missionIds.push(-1);
    this.scheduleService.getMissionsBySiteAndPoleNotInIds(this.missionIds, this.selectedPassage.mainPoi[0].idPointInterest, this.selectedTransport.idTransportType, 1)
      .subscribe(missions => {

        for (let i = 0; i < missions.length; i++) {
          this.trackingMissionData.unshift(missions[i]);
          this.trackingIndex.push(this.trackingMissionData.length - 1);

          this.missionIds.unshift(missions[i].selectedVehicule.idVehicule);
        }
        this.trackingMissionDataSorted = this.trackingMissionData;
        if (missions.length > 0)
          this.onTracking(0, true);
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargement", "Mission")
      });
  }

  /** ON TRACKING */
  onTracking(index: any, isSorting: any) {
    if (this.trackingIndex.indexOf(index) === -1)
      this.trackingIndex.push(index);

    this.loadingTracking = true;
    this.trackingSize = 0;
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    for (let trIndex of this.trackingIndex) {
      this.tracking(trIndex, isSorting);
    }

  }



  tracking(trIndex: any, isSorting: any) {
    this.scheduleService
      .trackingReturn(
        this.trackingMissionData[trIndex].selectedMission.id,
        this.trackingMissionData[trIndex].selectedVehicule.idVehicule,
        1
      )
      .pipe(
        finalize(() => {
          this.loadingTracking = false;
          this.trackingSize++;

          if (isSorting && this.trackingSize >= this.trackingIndex.length) {
            this.trackingMissionDataSorted = [...this.trackingMissionData]; // Create a copy to sort

            // Sort by state using the stateMap
            this.trackingMissionDataSorted.sort((a: any, b: any) => {
              const stateA = this.stateMap.get(a.trackingMission.state) ?? 0; // Default to 0 if undefined
              const stateB = this.stateMap.get(b.trackingMission.state) ?? 0;
              if (stateA < stateB) return 1;
              if (stateA > stateB) return -1;
              return 0;
            });

            // Sort by startDate
            this.trackingMissionDataSorted.sort((a: any, b: any) => {
              const dateA = new Date(a.trackingMission.startDate).getTime();
              const dateB = new Date(b.trackingMission.startDate).getTime();
              if (dateB > dateA) return 1;
              if (dateB < dateA) return -1;
              return 0;
            });

            // Sort by travelDistance
            this.trackingMissionDataSorted.sort((a: any, b: any) => {
              const distanceA =
                a.selectedMission.routeLegs[0].travelDistance -
                a.trackingMission.accordingDistance;
              const distanceB =
                b.selectedMission.routeLegs[0].travelDistance -
                b.trackingMission.accordingDistance;
              if (distanceA > distanceB) return 1;
              if (distanceA < distanceB) return -1;
              return 0;
            });

            // Sort by endDate for specific states
            this.trackingMissionDataSorted.sort((a: any, b: any) => {
              if (
                a.trackingMission.state === b.trackingMission.state &&
                a.trackingMission.state !== 'DELIVERY' &&
                a.trackingMission.state !== 'DELAY'
              ) {
                const endDateA = new Date(a.trackingMission.endDate).getTime();
                const endDateB = new Date(b.trackingMission.endDate).getTime();
                if (endDateB < endDateA) return 1;
                if (endDateB > endDateA) return -1;
              }
              return 0;
            });
          }
        })
      )
      .subscribe(
        (response: TrackingMission) => {
          if (response.last) {
            response.progressPercent = 100;
            response.accordingDistance = response.mission.travelDistance;
          }

          this.trackingMissionData[trIndex].trackingMission = response;
          this.trackingMissionData[trIndex].selectedMission = response.mission;

          this.inverseGeocoding(trIndex);
          this.loadingTracking = false;
          this.startUpdateTrackingTimer(trIndex);
          this.countDistance(
            this.trackingMissionData[trIndex].trackingMission,
            trIndex
          );
        },
        (err) => {
          this.loadingTracking = false;
          let matricule = '';
          if (this.trackingMissionData[trIndex].selectedVehicule)
            matricule = this.trackingMissionData[trIndex].selectedVehicule
              .matricule;
          this.toastr.error(
            'Erreur lors de chargement',
            'Mission ' + matricule
          );
        }
      );
  }

  countDistance(tracking: TrackingMission, index: any) {
    this.trackingMissionData[index].trackingAccordingDistance = 0;
    this.trackingMissionData[index].trackingAccordingDuration = 0;
    for (var i = 0; i < tracking.mission.routeLegs.length; i++) {

      if (tracking.mission.routeLegs[i].selected === false) {
        this.trackingMissionData[index].trackingAccordingDistance += tracking.mission.routeLegs[i].travelDistance;
        this.trackingMissionData[index].trackingAccordingDuration += tracking.mission.routeLegs[i].travelDuration;
      } else {
        this.trackingMissionData[index].trackingAccordingDistance = tracking.mission.routeLegs[i].travelDistance;
        this.trackingMissionData[index].trackingAccordingDuration = tracking.mission.routeLegs[i].travelDuration;

      }
    }
  }



  /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
  selectedRouteLeg(index: any): RouteLegs {
    if (this.trackingMissionData[index].trackingMission) {
      return this.trackingMissionData[index].trackingMission.mission.routeLegs.find(
        route => route.selected == true
      );
    } else {
      return null;
    }
  }

  /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
  inverseGeocoding(index: any) {
    if (!this.trackingMissionData[index].trackingMission)
      return;
    this.geocodingService.inverseGeoconding(
      this.trackingMissionData[index].trackingMission!.realTimeRecord.coordinate.lat,
      this.trackingMissionData[index].trackingMission!.realTimeRecord.coordinate.lng,
      18)
      .subscribe((response: { display_name: string; }) => {
        this.trackingMissionData[index].trackingMission!.realTimeRecord.geocoding = response.display_name;
      }, err => {
        //  this.toastr.warning("Inverse geocoding");
      });

  }

  // POle Site
  loadAllPassage() {
    this.loading = true;
    this.passageService.findAllPassages().subscribe(
      (passages) => {
        this.passageService.passages = passages;
        if (this.passageService.passages.length > 0) {
          this.selectedPassage = this.passageService.passages[0];
          this.loadMissions();
        } else {
          this.loading = false;
        }
      },
      (error) => {
        this.toastr.error('Erreur lors de chargements', 'Passage');
        this.loading = false;
      }
    );
  }

  passagesData() {
    if (this.passageService.passages.length > 0) {
      this.selectedPassage = this.passageService.passages[0];
      this.loadMissions();
      // this.stop();
    } else {
      this.loadAllPassage();
    }
  }

  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.loading = true;
      this.transportTypeService.findAllTransportTypes().subscribe(
        (transportTypes) => {
          this.transportTypeService.transportTypes = transportTypes;
          if (transportTypes.length > 0) {
            this.selectedTransport = transportTypes[transportTypes.length - 1];
            this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
              return v.idVehicule;
            });
            this.passagesData();
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Type de transport');
        }
      );
    } else {
      this.selectedTransport =
        this.transportTypeService.transportTypes[
        this.transportTypeService.transportTypes.length - 1
        ];
      this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
        return v.idVehicule;
      });
      this.passagesData();
    }
  }

  getTrackingTime(): string {
    if (this.updateTrackingTimer >= 100)
      return Math.round(this.updateTrackingTimer / 60) + " ' ";
    else var sec = this.updateTrackingTimer / 60;
    return sec.toFixed(1) + ' " ';
  }

  chooseSchedule(passage: any) {
    this.selectedPassage = passage;
    //this.missionIds = [];
    this.stopTracking();
  }

  chooseTransport(transport: any) {
    this.selectedTransport = transport;
    this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
      return v.idVehicule;
    });
    //this.missionIds = [];
    this.stopTracking();
  }

  stopTracking() {
    if (this.updateTrackingTimerSubscription)
      this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = null;
  }

}