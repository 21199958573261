import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiculeComponent } from './vehicule.component'; // Adjust the path if necessary
import { ToastrModule } from 'ngx-toastr'; // For notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // For modal
import { VehiculeService } from '../providers/vehicule.service'; // Ensure the path is correct
import { RouterModule } from '@angular/router'; // If you need routing
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NoValuePipeModule } from 'src/app/pipes/no-value.module';
import { VehiculeDetailsModule } from './vehicule-form/vehicule-details/vehicule-details.module';
import { VehiculeDetailModule } from './vehicule-details/vehicule-details.module';
import { DataManagementModule } from '../data-management.module';

@NgModule({
  declarations: [
    VehiculeComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(), // Import Toastr module for notifications
    ModalModule.forRoot(), // Import Bootstrap modal module
    RouterModule, // Import RouterModule if needed
    NgxLoadingModule.forRoot({}),
    PaginationModule.forRoot(),
    NoValuePipeModule,
    VehiculeDetailsModule,
    VehiculeDetailModule,
    DataManagementModule
  ],
  providers: [
    VehiculeService // Provide the vehicule service
  ],
  exports: [
    VehiculeComponent // Export if you want to use this component outside of this module
  ]
})
export class VehiculeModule { }
