import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Vehicule, Group, RapportDto } from '../../../data-management/data-management.model';
import { Provider, RunningDocument } from '../../model';
import { DocumentCirculationService } from '../../providers/document-circulation-service';
import { ImportExportService } from 'src/app/client-management/import-export';

@Component({
  selector: 'app-diver-management',
  templateUrl: './diver-management.component.html',
  styleUrls: ['./diver-management.component.css']
})
export class DiverManagementComponent implements OnInit {

  @Input()
  documents: RunningDocument[] = [];

  @Input()
  selectedDocumentType: 'LAVAGE' | 'TONNAGE' | 'PARCKING' | 'TOLL' | 'CA_REALIZATION' | 'C_LOCATION' = 'LAVAGE';

  /**
   * callback when selected vehicule change !
   * */
  @Input()
  set selectedVehicule(vehicule: Vehicule) {
    if (vehicule) {
      this._selectedVehicule = vehicule;
      this.loadDocument();
    }
  }

  /**
   * callback when selected groupe change !
   * */
  @Input()
  set selectedGroup(group: Group) {
    if (group) {
      this._selectedGroup = group;
    }
  }

  @ViewChild('addEditModal', { static: false }) addEditModal: ModalDirective;
  @ViewChild('documentDeleteModal', { static: false }) documentDeleteModal: ModalDirective;
  @ViewChild('importModal', { static: false }) importModal: ModalDirective;

  _selectedVehicule: Vehicule = new Vehicule();

  _selectedGroup: Group = new Group();

  documentsId: number[] = [];

  loading: boolean = false;

  isDeleteMultiple: boolean = false;

  selectedDocument: RunningDocument = new RunningDocument();

  mode: 'ADD' | 'UPDATE' = 'ADD';

  searchTerm: string;

  /** export/import start */
  repport: RapportDto = new RapportDto();

  /** export/import end */

  constructor(public toastr: ToastrService, private documentService: DocumentCirculationService, public exportImport: ImportExportService) { }

  ngOnInit() { }

  costTotal() {
    let costTotal = 0;
    this.documents.forEach(document => {
      costTotal += document.cost;
    });
    return costTotal;
  }


  delete() {
    this.documentService.delete(this.selectedDocument.id).subscribe(response => {

      if (response) {
        this.documents = this.documents.filter(document => document.id !== this.selectedDocument.id);
        this.documentsId = this.documentsId.filter(documentId => documentId != this.selectedDocument.id);
        this.toastr.success('Divers bien supprimé !', 'Info');
      } else {
        this.toastr.error('Divers n\'a pas supprimé correctement !', 'Erreur');
      }

      this.documentDeleteModal.hide();
      this.loading = false;

    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }

  /**
   * Suppression multiple du vidange
   */
  deleteMultiple() {
    this.loading = true;
    this.documentService.deleteMultiple(this.documentsId).subscribe(response => {

      if (response) {
        for (let documentId of this.documentsId) {
          this.documents = this.documents.filter(document => document.id != documentId);
        }

        this.documentsId = [];
        this.toastr.success('Suppression effectuée avec succès.', 'info');
      } else {
        this.toastr.error('Divers n\'a pas supprimé correctement !', 'Erreur');
      }

      this.documentDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }


  updateDocument(document: RunningDocument) {
    this.selectedDocument = Object.assign({}, document);
    if (!this.selectedDocument.provider) {
      this.selectedDocument.provider = new Provider();
    }
    this.mode = 'UPDATE';
    this.addEditModal.show();
  }

  documentWasUpdated(runningDocuments: RunningDocument[]) {
    this.addEditModal.hide();

    for (let document of runningDocuments) {
      document.selected = true;

      if (!this.documents.find(d => d.id == document.id)) {

        this.documents.unshift(document);

      } else {

        for (let i = 0; i < this.documents.length; i++) {
          if (document.id === this.documents[i].id) {
            this.documents[i] = document;
            break;
          }
        }

      }
    }

    this.toastr.success('Divers bien modifié !', 'Info');
  }

  openAddDocumentModal() {
    this.selectedDocument = new RunningDocument();
    this.selectedDocument.type = this.selectedDocumentType;
    this.mode = 'ADD';
    this.addEditModal.show();
  }

  documentWasAdded(runningDocuments: RunningDocument[]) {
    this.addEditModal.hide();

    for (let document of runningDocuments) {
      document.selected = true;
      this.documents.unshift(document);
    }
    this.toastr.success('Divers bien ajouté !', 'Info');
  }

  loadDocument() {
    if (!this._selectedVehicule || !this._selectedVehicule.idVehicule) return;
    this.loading = true;
    this.documents = [];
    this.documentService.getListDocuments(this._selectedVehicule.idVehicule, this.selectedDocumentType)
      .subscribe(response => {
        this.documents = response;
        this.loading = false;
      }, () => {
        this.loading = false;
      }
      );

  }



  /**
 * Event confirm delete multiple
 */
  onConfirmDeleteMultiple() {
    if (this.documentsId.length == 0) {
      this.toastr.warning("Veuillez choisir des divers à supprimer !");
      return;
    }
    this.isDeleteMultiple = true;
    this.documentDeleteModal.show();
  }

  /**
   * Afficher modal de confirmation du suppression
   */
  onConfirmDelete(document: RunningDocument) {
    this.selectedDocument = document;
    this.isDeleteMultiple = false;
    this.documentDeleteModal.show();
  }

  /**
   * Event delete
   */
  onDelete() {
    if (this.isDeleteMultiple) {
      this.deleteMultiple();
    } else {
      this.delete();
    }
  }

  /**
   * get list search
   */
  getDocuments(): RunningDocument[] {
    if ((this.searchTerm) && (this.searchTerm.length > 0)) {
      return this.documents.filter(document => document.cost.toString() === this.searchTerm);
    } else {
      return this.documents;
    }
  }


  /**
   * Event check row for delete multiple
   */
  onChecked(id: number) {
    if (this.documentsId.find(oilChangeId => oilChangeId == id)) {
      this.documentsId = this.documentsId.filter(oilChangeId => oilChangeId != id);
    } else {
      this.documentsId.push(id);
    }
  }

  /**
   * Event check all row for delete multiple
   */
  onCheckedAll() {
    if (this.documentsId.length == this.documents.length) {
      this.documentsId = [];
    } else {
      this.documentsId = [];
      for (let oilChange of this.documents) {
        this.documentsId.push(oilChange.id);
      }
    }
  }

  /**
   * check if id exists in array,
   */
  isCheckedInList(id: number): boolean {
    return this.documentsId.find(oilChangeId => oilChangeId == id) ? true : false;
  }

  export() {
    this.repport.type = this.selectedDocumentType;
    this.exportImport.export(this.documents, this.repport, this._selectedVehicule.idVehicule);
  }

  import() {
    this.importModal.show();
  }

  itemWasImported(res: boolean) {
    if (res) {
      this.importModal.hide();
      this.loadDocument();
    }
  }

}
