import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CanalService } from '../../canal.service';
import { SupervisorVehicleDTO, Vehicule } from '../../canals';
import { ToastrService } from 'ngx-toastr';
// import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { SupervisorFormComponent } from './supervisor-form/supervisor-form.component';
import { ExtendedMatDialogConfig } from '../../utils/extended-mat-dialog-config';
import {
  DataManagementService,
  Group,
} from 'src/app/client-management/data-management';
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.css'],
})
export class SupervisorComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<SupervisorVehicleDTO> =
    new MatTableDataSource<SupervisorVehicleDTO>([]);
  displayedColumns: string[] = ['lastName', 'telephone', 'action'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  groups: Group[];
  vehicules: Vehicule[] = [];

  superviseurs: SupervisorVehicleDTO[] = [];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private dataManagementService: DataManagementService,
    public dialog: MatDialog,
    private canalService: CanalService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.LoadAllSuperviseur();
    this.dataSource.filterPredicate = this.createFilter();

    if (this.canalService.vehicules.length <= 0) this.getAllGroupsDetails();
    else this.vehicules = this.canalService.vehicules;
  }

  getAllGroupsDetails() {
    this.dataManagementService.getAllGroupsDetails('').subscribe((res) => {
      this.groups = res.filter((group) => group.vehicules.length > 0);
      if (this.groups) {
        this.getVehicules();
      }
    });
  }

  getVehicules() {
    this.groups.forEach((group) => {
      group.vehicules.forEach((vehicule) => {
        let v = new Vehicule();
        v.matricule = vehicule.matricule;
        v.idVehicule = vehicule.idVehicule;
        v.idDevice = vehicule.idDevice;
        v.mark = vehicule.mark;
        if (this.checkIfVehiculeExists(v)) {
          this.vehicules.push(v);
        }
      });
    });

    this.canalService.vehicules = this.vehicules;
  }

  checkIfVehiculeExists(vehicule: Vehicule) {
    let v = this.vehicules.filter(
      (ve) => ve.idVehicule === vehicule.idVehicule
    );
    if (v.length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  LoadAllSuperviseur(): void {
    this.dataSource.data = [];
    this.superviseurs = [];
    this.canalService.getAllSuperviseur().subscribe(
      (data: SupervisorVehicleDTO[]) => {
        this.superviseurs = data;
        this.dataSource.data = this.superviseurs;
        // this.toastr.success('Superviseurs bien téléchargés !');
      },
      (error) => {
        this.toastr.error(
          'Erreur lors de téléchargements des superviseurs !',
          'Erreur !',
          {
            closeButton: true,
            positionClass: 'toast-top-center',
            timeOut: 3000,
          }
        );
        console.error('Error fetching superviseurs', error);
      }
    );
  }

  createFilter(): (data: SupervisorVehicleDTO, filter: string) => boolean {
    return (data: SupervisorVehicleDTO, filter: string): boolean => {
      const superviseur = data.superviseur;
      const vehicules = data.vehicules;

      const searchString = `${superviseur.idDriver} ${superviseur.firstName} ${
        superviseur.lastName
      } ${superviseur.telephone} ${vehicules
        .map((v) => v.matricule)
        .join(' ')} ${vehicules.map((v) => v.mark).join(' ')}`;

      return searchString.toLowerCase().includes(filter);
    };
  }

  openSupervisorDialog(element: SupervisorVehicleDTO): void {
    const dialogRef = this.dialog.open(SupervisorFormComponent, {
      width: '1000px',
      data: {
        superviseur: element,
        vehicules: this.vehicules,
      },
    });

    dialogRef
      .afterClosed()
      .first()
      .subscribe((result) => {
        this.LoadAllSuperviseur();
      });
  }
}
