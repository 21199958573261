import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CimentMissionHistoryComponent } from './ciment-mission-history.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms'; // Import FormsModule if using ngModel
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule if using reactive forms
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    CimentMissionHistoryComponent
  ],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    FormsModule, // Add if using ngModel
    ReactiveFormsModule, // Add if using reactive forms
    NgSelectModule,

  ],
  exports: [
    CimentMissionHistoryComponent
  ]
})
export class CimentMissionHistoryModule {}
