import { Component, OnInit, ViewChild } from '@angular/core';
import { TmmService } from '../../../_service/tmm.service';
import { CanalHistoryDto } from '../../../models';
import { ModalDirective } from 'ngx-bootstrap/modal';

import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import drilldown from 'highcharts/modules/drilldown';

// Initialisation des modules Highcharts
exporting(Highcharts);
noDataToDisplay(Highcharts);
drilldown(Highcharts);

@Component({
  selector: 'app-history-canal-group-vehicule',
  templateUrl: './history-canal-group-vehicule.component.html',
  styleUrls: ['./history-canal-group-vehicule.component.css'],
})
export class HistoryCanalGroupVehiculeComponent implements OnInit {
  @ViewChild('canalgroupHistory', { static: false }) canalgroupHistory!: ModalDirective;

  showModal = false;

  options: Highcharts.Options = {}; // Initialisation avec un objet vide
  optionsModal: Highcharts.Options = {}; // Initialisation avec un objet vide

  selectedMonth: number = new Date().getMonth() + 1; // Initialisation avec la valeur du mois actuel

  dataHistory: CanalHistoryDto[] = [];

  months = [
    { id: 1, name: 'Janvier' },
    { id: 2, name: 'Février' },
    { id: 3, name: 'Mars' },
    { id: 4, name: 'Avril' },
    { id: 5, name: 'Mai' },
    { id: 6, name: 'Juin' },
    { id: 7, name: 'Juillet' },
    { id: 8, name: 'Août' },
    { id: 9, name: 'Septembre' },
    { id: 10, name: 'Octobre' },
    { id: 11, name: 'Novembre' },
    { id: 12, name: 'Décembre' },
  ];

  constructor(private tmmService: TmmService) {}

  ngOnInit() {
    this.loadHistoryByYearAndMonth(2024, this.selectedMonth);
  }

  openModal() {
    this.showModal = true;
    // this.canalgroupHistory.show(); // Décommentez si nécessaire
  }

  closeModal() {
    this.showModal = false;
  }

  onMonthChange() {
    this.dataHistory = [];
    this.loadHistoryByYearAndMonth(2024, this.selectedMonth);
  }

  loadHistoryByYearAndMonth(year: number, month: number) {
    this.tmmService
      .getDashbordHistoryCanalGroupVehicule(year, month)
      .subscribe((response) => {
        this.dataHistory = response;
        this.generateDataAndDrilldownSeries();
      });
  }

  getColorForStatus(status: boolean): string {
    return !status ? '#35a9e7' : '#e77e22';
  }

  getColorForStatusVehicule(status: boolean): string {
    return !status ? '#35a9e7' : '#e77e22';
  }

  generateDataAndDrilldownSeries() {
    this.setHighCartsLang();

    const drilldownSeries: Highcharts.SeriesOptionsType[] = [];
    const seriesData: Highcharts.SeriesOptionsType[] = this.dataHistory.map((canal) => {
      // Initialisation de canalDrilldown avec un tableau vide pour data
      const canalDrilldown: Highcharts.SeriesOptionsType = {
        type: 'column',
        name: canal.canalName,
        id: `canal_${canal.canalId}`,
        data: [], // Initialisation explicite
      };

      canal.groupes.forEach((groupe) => {
        // Initialisation de groupDrilldown avec un tableau vide pour data
        const groupDrilldown: Highcharts.SeriesOptionsType = {
          type: 'column',
          name: canal.canalName + ' > ' + groupe.groupName,
          id: `groupe_${groupe.groupeId}`,
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>',
          },
          data: [], // Initialisation explicite
        };

        groupe.vehicules.forEach((vehicule) => {
          groupDrilldown.data!.push({ // Utilisation de l'opérateur de non-null assertion
            name: vehicule.vehiculeName,
            y: 1,
            color: this.getColorForStatusVehicule(vehicule.status),
          });
        });

        drilldownSeries.push(groupDrilldown);
        canalDrilldown.data!.push({ // Utilisation de l'opérateur de non-null assertion
          name: groupe.groupName,
          id: `${canal.canalId}_${groupe.groupeId}`,
          y: groupe.vehicules.length !== 0 ? groupe.vehicules.length : 0,
          drilldown: `groupe_${groupe.groupeId}`,
          color: this.getColorForStatus(groupe.status),
        });
      });

      drilldownSeries.push(canalDrilldown);
      return {
        type: 'column',
        name: canal.canalName,
        pointWidth: 30,
        data: [
          {
            name: canal.canalName,
            y: canal.groupes.length !== 0 ? canal.groupes.length : 0,
            drilldown: 'canal_' + canal.canalId,
          },
        ],
      };
    });

    this.options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Historique Canal',
        style: {
          fontWeight: 'bold',
        },
        align: 'center',
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span><br><b>     {point.y}</b><br/>',
      },
      xAxis: {
        type: 'category',
        title: {
          text: null,
        },
        labels: {
          enabled: true,
        },
      },
      yAxis: {
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
      },
      plotOptions: {
        column: {
          borderRadius: 5,
        },
      },
      series: seriesData,
      drilldown: {
        series: drilldownSeries,
      },
    };

    this.optionsModal = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Historique Canal',
        style: {
          fontWeight: 'bold',
        },
        align: 'center',
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span><br><b>     {point.y}</b><br/>',
      },
      xAxis: {
        type: 'category',
        title: {
          text: null,
        },
        labels: {
          enabled: true,
        },
      },
      yAxis: {
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
      },
      plotOptions: {
        column: {
          borderRadius: 5,
        },
      },
      series: seriesData,
      drilldown: {
        series: drilldownSeries,
      },
    };

     Highcharts.chart('history', this.options);
    Highcharts.chart('historyModal', this.optionsModal);
  }

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7',
        '#e77e22',
        '#3ea6dc',
        '#47a4d2',
        '#51a2c7',
        '#5a9fbd',
        '#639db3',
        '#6d9ba8',
        '#76999e',
        '#7f9694',
        '#899489',
        '#92927f',
        '#9c9074',
        '#a58d6a',
        '#ae8b60',
        '#b88955',
        '#c1874b',
        '#ca8441',
        '#d48236',
        '#dd802c',
        '#e77e22',
      ],
      lang: {
        printChart: 'Imprimer',
        decimalPoint: ',',
        thousandsSep: ' ',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        viewFullscreen: 'Voir en plein écran',
        noData: "Aucune donnée n'est disponible",
      },
    });
  }

}

interface DrilldownSeries {
  name: string;
  id: string;
  tooltip?: Highcharts.TooltipOptions;
  data: {
    name: string;
    id?: string;
    y: number;
    drilldown?: string;
    color?: string | null;
  }[];
}
