<form class="form-inline">
  <div class="form-group mx-sm-6 mb-6">
    <label>Durée d'arrêt hors P.O.I (min)</label>

    <select *ngIf="stopTimeSelectMode" class="form-control" [(ngModel)]="nonPoiAlertConfiguration.value1"
            name="stopTime"
            (ngModelChange)="chooseStopTime($event)">
      <option *ngFor="let minute of minutes" [ngValue]="minute" style="font-size: 14px;"
              [ngClass]="{'autre': minute == 'Autre..'}">
        {{minute}}
      </option>
    </select>

    <input *ngIf="!stopTimeSelectMode" type="number" name="stopTime" [(ngModel)]="stopTime" class="form-control"
           placeholder="Durée d'arrêt">

    <i *ngIf="!stopTimeSelectMode" class="fa fa-times" style="color: #aa1111"
       (click)="stopTimeSelectMode = true;nonPoiAlertConfiguration.value1='10'"></i>


  </div>

  <span *ngIf="!showAdvancedSettings" (click)="collapseAdvancedSettings()">
                <i class="fa fa-plus-square"></i> <span class="link">paramétrage avancé</span>
            </span>

  <span *ngIf="showAdvancedSettings" (click)="collapseAdvancedSettings()">
                <i class="fa fa-minus-square"></i> <span class="link">masquer paramétrage avancé</span>
            </span>

  <br *ngIf="showAdvancedSettings">
  <br *ngIf="showAdvancedSettings">

  <app-cron-settings *ngIf="showAdvancedSettings" [cron]="cronAlertConfiguration"></app-cron-settings>

  <br>
   <app-email-manager [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails"
                     (onChange)="emailSettings($event)"></app-email-manager>
  <hr>

  <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive">Active/Désactive</mat-slide-toggle>

  <button type="submit" class="btn btn-flat btn-primary mb-2" (click)="save()">
      <ngx-loading [show]="loader" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
      Sauvegarder</button>
</form>
