<form *ngIf="mode === 'ADD' || mode === 'UPDATE'" #poiClientForm="ngForm"
    style="font-family: 'Inconsolata', monospace;font-size: 14px;">

    <div class="form-group">
        <label>Nom</label>
        <input type="text" class="form-control" placeholder="Nom ..." [(ngModel)]="poiClient.name" name="name" required>
    </div>

    <div class="form-group">
        <label>Adresse</label>
        <textarea class="form-control" rows="3" placeholder="Adresse ..." [(ngModel)]="poiClient.address" name="address"
            rows=5></textarea>
    </div>

    <div class="form-group">
        <label>Rayon (m)</label>
        <input type="number" class="form-control" placeholder="" value="10" [(ngModel)]="poiClient.ray" name="ray">
    </div>

    <div class="form-group" *ngIf="poiClient.coordinate">
        <label>[Lat,Lng] = [{{poiClient?.coordinate?.lat | number:'2.2-6'}},{{poiClient?.coordinate?.lng |
            number:'2.2-6'}}]</label>
    </div>

    <div class="form-group" *ngIf="(poiClient.type == 0 || poiClient.type == 'MARKER')">
        <label>Type de client</label>
        <select class="form-control" [(ngModel)]="poiClient.clientType" name="clientType" required>
            <option style="font-size: 14px;" *ngFor="let clientType of clientTypes" [value]="clientType.value">
                {{clientType.text}}</option>
        </select>
    </div>

    <div class="form-group" *ngIf="(poiClient.type == 1 || poiClient.type == 'POLYGON')">
        <label>POI</label>
        <div>
            <ng-multiselect-dropdown-angular7 name="myPois" [data]="poiItems" placeholder="Select poi"
                [(ngModel)]="poiClient.myPois" (onSelect)="onItemSelect($event)" [settings]="poiDropdownSettings">
            </ng-multiselect-dropdown-angular7>
        </div>
    </div>

    <div *ngIf="(poiClient.type == 1 || poiClient.type == 'POLYGON')" class="row">
        <div class="col-md-3">
            <div class="form-group">
                <div class="dropdown">
                    <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="border-radius: 0px;">
                        <img src="{{selectedImage.uri}}" width="17px" /> {{selectedImage.text}}
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" style="border-radius: 0px;">
                        <li *ngFor="let image of images" (click)="selectedImage = image;">
                            <a>
                                <img src="{{image.uri}}" width="17px" /> {{image.text}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <div class="dropdown">
                    <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="border-radius: 0px;">
                        {{selectedColor.text}}
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" style="border-radius: 0px;">
                        <li *ngFor="let color of colors" (click)="selectedColor = color;">
                            <a>
                                {{color.text}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <button *ngIf="mode === 'ADD'" type="submit" [disabled]="!poiClientForm.form.valid"
        class="btn btn-primary btn-block btn-flat" (click)="addPoiClient()">
        <i class="fa fa-plus" aria-hidden="true"></i> Ajouter
    </button>

    <button *ngIf="mode === 'UPDATE'" type="submit" [disabled]="!poiClientForm.form.valid"
        class="btn btn-primary btn-block btn-flat" (click)="updatePoiClient()">
        <ngx-loading [show]="loader" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
        Modifier
    </button>

</form>