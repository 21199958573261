import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionTrackingInfoComponent } from './mission-tracking-info.component'; // Adjust the import path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if used
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service'; // Adjust if needed
import { ScheduleService } from '../schedule'; // Adjust the import path if necessary
import { PassageService } from '../parametrage/passage'; // Adjust the import path if necessary
import { TransportTypeService } from '../parametrage/transport-type'; // Adjust the import path if necessary
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TimediffPipeModule } from 'src/app/pipes/timediff.pipe.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PositiveNumberPipeModule } from 'src/app/pipes/positive-number.pipe.module';
import { MissionInfoTrackingModule } from '../mission-info-tracking/mission-info-tracking.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    MissionTrackingInfoComponent // Declare the component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(), // Add ToastrModule if using toastr for notifications
    NgxLoadingModule.forRoot({}),
    TimediffPipeModule,
    PopoverModule.forRoot(),
    PositiveNumberPipeModule,
    MissionInfoTrackingModule,
    TruncateModule,
    MatTooltipModule
  ],
  providers: [
    GeocodingService, // Provide any services that are used in this component
    ScheduleService,
    PassageService,
    SharedPipesModule,
    TransportTypeService
  ],
  exports: [
    MissionTrackingInfoComponent // Export the component if it needs to be used outside this module
  ]
})
export class MissionTrackingInfoModule {}
