import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { Battery, Provider, Vehicule, Group } from '../../../model/index';
import { BatteryService } from '../../../providers/battery-service';
import { ProviderService } from '../../../providers/provider-service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';


@Component({
    selector: 'app-battery-form',
    templateUrl: './battery-form.component.html'
})

export class BatteryFormComponent implements OnInit {

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';

    @Input()
    battery: Battery = new Battery();

    @Input()
    vehicule: Vehicule = new Vehicule();

    @Input()
    group: Group = new Group();

    @Output()
    batteryWasAdded: EventEmitter<Battery> = new EventEmitter<Battery>();

    @Output()
    batteryWasUpdated: EventEmitter<Battery> = new EventEmitter<Battery>();

    providers: Provider[] = [];

    loading: boolean = false;

    showProvider = false;

    maxDate: Date = new Date();

    constructor(private batteryService: BatteryService, private providerService: ProviderService, public toastr: ToastrService, private localeService: BsLocaleService) { }

    ngOnInit() {
        this.loadProviders();
        this.localeService.use('fr');
    }

    /**
     * Charger liste des fournisseurs
     */
    loadProviders() {
        this.providerService.getProviders("MAINTENANCE").subscribe(providers => {
            this.providers = providers;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Ajouter nouveau batterie
     */
    onAdd(form: NgForm) {
        this.loading = true;
        this.batteryService.add(this.battery, this.vehicule.idVehicule, this.group.idGroupe).subscribe(battery => {
            this.toastr.success('Batterie informations bien sauvegarder !', 'info');
            this.batteryWasAdded.emit(battery);
            this.loading = false;
            form.reset();
            this.loadProviders();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * modifier batterie
     */
    onUpdate(form: NgForm) {
        this.loading = true;
        this.batteryService.update(this.battery).subscribe(battery => {
            this.toastr.success('Batterie informations bien sauvegarder !', 'info');
            this.batteryWasUpdated.emit(battery);
            this.loading = false;
            form.reset();
            this.loadProviders();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }
}
