<div class="row padd">
    <!-- ==================== Objectif Section ======================= -->
    <div class="col-xl-4 col-md-4 mb-2 col-xs-3">
        <div class="card blueStyle " title="Budget Gasoil">
            <div class="title">Budget</div>
            <div class="value">
                <span *ngIf="selectedCharge && objectif">
                    {{objectif | number: '.0-2' }} DH</span>
                <span *ngIf="!selectedCharge || !objectif"> - </span>
            </div>
        </div>
    </div>

    <!-- ==================== Réalisé Section ======================= -->
    <div class="col-xl-4 col-md-4 mb-2 col-xs-3">
        <div class="card yellowStyle" title="Charge Gasoil Consommée">
            <div class="title">Consommée</div>
            <div class="value">
                <span *ngIf="selectedCharge && objectif">{{realise | number: '.0-2'}} DH</span>
                <span *ngIf="!selectedCharge || !objectif"> - </span>
            </div>

        </div>
    </div>

    <!-- ==================== a réaliser Section ======================= -->
    <div class="col-xl-4 col-md-4 mb-2 col-xs-3">
        <div class="card " [ngClass]="aRealiser < 0 ? 'redStyle' : 'greenStyle'" title="Charge Gasoil à Réaliser">
            <div class="title">Écart</div>
            <div class="value">
                <span *ngIf="selectedCharge && objectif">{{aRealiser | number: '.0-2' }} DH</span>
                <span *ngIf="!selectedCharge || !objectif"> - </span>
            </div>
        </div>
    </div>

</div>

<div id="fuelingChart" class="w-100 d-inline-block"></div>