import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GroupDto } from 'src/app/client-management/data-management';
import { ActivityVehiculeClassification } from '../activity-vehicule-classifications.model';
import { ActivityVehiculeClassificationsService } from '../activity-vehicule-classifications.service';

@Component({
  selector: 'app-activity-vehicle-classification-form',
  templateUrl: './activity-vehicle-classification-form.component.html',
  styleUrls: ['./activity-vehicle-classification-form.component.css']
})
export class ActivityVehicleClassificationFormComponent implements OnInit {

  classification: ActivityVehiculeClassification = new ActivityVehiculeClassification();

  @Input()
  groups: GroupDto[] = [];

  @Input()
  set coefficient(classification: ActivityVehiculeClassification) {
    if(classification.id > 0){
      this.classification = Object.assign(this.classification, classification);
    }else{
      this.classification = new ActivityVehiculeClassification();
    }
  }

  loading: boolean = false;

  listCoefficients: number[] = [];

  isGreaterThanFourParams = false;
  isNotEqualToTwenty = false;

  @Output()
  classificationWasSaved: EventEmitter<ActivityVehiculeClassification> = new EventEmitter<ActivityVehiculeClassification>();

  groupsDropdownSettings = {};

  constructor(public toastr: ToastrService, private classificationService: ActivityVehiculeClassificationsService) {
    this.initCoefficient();
  }

  initCoefficient() {
    let j = 1;
    for (let i = 1; i <= 20; i++) {
      this.listCoefficients.push(j);
      j++;
    }
  }

  ngOnInit() {
    this.groupsDropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };
  }

  onSave() {
    this.loading = true;
    this.readCheckBoxesForm();
    if (this.isGreaterThanFourParams) {
      this.toastr.error("Vous ne pouvez sélectionner que 4 paramètres au maximum");
      this.loading = false;
      return;
    }

    if (this.isNotEqualToTwenty) {
      this.toastr.error("Total du coefficient doit être égal à 20");
      this.loading = false;
      return;
    }

    this.classificationService.save(this.classification).subscribe(classification => {
      this.toastr.success('Coefficient bien sauvegarder !', 'info');
      this.classificationWasSaved.emit(classification);
      this.loading = false;
    }, error => {
      this.toastr.error("Erreur lors d'ajout", "Coefficient");
      this.loading = false;
    });

  }

  readCheckBoxesForm() {
    var note: number = 0;
    let max = 0;
    this.isNotEqualToTwenty = false;
    this.isGreaterThanFourParams = false;

    /**
     * sinistre
     */
    if (!this.classification.sinistre) {
      this.classification.coefficientSinistre = null;
    } else {
      max++;
      note += (this.classification.coefficientSinistre || 0);
    }

    /**
     * fueling
     */
    if (!this.classification.fueling) {
      this.classification.coefficientFueling = null;
    } else {
      max++;
      note += (this.classification.coefficientFueling || 0);
    }

    /**
     * Chiffre d'affaire
     */
    if (!this.classification.turnover) {
      this.classification.coefficientTurnover = null;
    } else {
      max++;
      note += (this.classification.coefficientTurnover || 0);
    }

    /**
     * KM
     */
    if (!this.classification.mileage) {
      this.classification.coefficientMileage = null;
    } else {
      max++;
      note += (this.classification.coefficientMileage || 0);
    }

    /**
     * Infraction
     */
    if (!this.classification.offense) {
      this.classification.coefficientOffense = null;
    } else {
      max++;
      note += (this.classification.coefficientOffense || 0);
    }

    /**
     * Heure de travail
     */
    if (!this.classification.workingTime) {
      this.classification.coefficientWorkingTime = null;
    } else {
      max++;
      note += (this.classification.coefficientWorkingTime || 0);
    }

    /**
     * Heure d'activité
     */
    if (!this.classification.hActivity) {
      this.classification.coefficientHactivity = null;
    } else {
      max++;
      note += (this.classification.coefficientHactivity || 0);
    }

    if (max > 4) {
      this.isGreaterThanFourParams = true;
    }

    if (note != 20) {
      this.isNotEqualToTwenty = true;
    }
  }

}
