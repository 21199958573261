import {Component, OnInit} from '@angular/core';
import {imagesCarsDir} from "../../../global.config";
import { Router } from '@angular/router';

@Component({
    selector: 'app-losses',
    templateUrl: './losses.component.html',
    styleUrls: ['./losses.component.css']
})
export class LossesComponent implements OnInit {

    public imagesCarsDir: string = imagesCarsDir;

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    getCurrentUrl(){
        return this.router.url.split("/").pop();
    }

}
