import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Mission } from '../../data-management.model';
import { DataManagementService } from '../../data-management.service';
import { MissionService } from '../../providers';



@Component({
    selector: 'app-mission-form',
    templateUrl: './mission-form.component.html',
    styleUrls: ['./mission-form.component.scss']
})
export class MissionFormComponent implements OnInit {

    @Output()
    missionSaved: EventEmitter<Mission> = new EventEmitter<Mission>();

    loading: boolean = false;

    mission: Mission = new Mission();

    @Input() mode = 'ADD';

    @Input()
    set mission_(mission: Mission) {
        this.selectedPoi = [];
        this.mission = Object.assign({}, mission);
        if (this.mission) {
            var index = 0;
            for (let poi of this.mission.routeLegs) {
                if (index == 0) {
                    this.selectedPoi.push({
                        id: poi.idPOISource,
                        name: poi.labelPOISource
                    });
                }
                this.selectedPoi.push({
                    id: poi.idPOITarget,
                    name: poi.labelPOITarget
                }
                );
                index++;
            }
        }
    }

    items: { id: number, name: string }[] = [];

    selectedItems: { id: number, name: string }[] = [];

    selectedPoi: { id: number, name: string }[] = [];

    found = false;

    constructor(private toastr: ToastrService,
        private dataManagementService: DataManagementService,
        private missionService: MissionService) { }

    ngOnInit() {
        this.loadPOI();
    }

    loadPOI() {
        this.loading = true;
        this.dataManagementService.getAllPointInterestsByType('MARKER')
            .subscribe(pointInterests => {
                this.loading = false;
                pointInterests.forEach(p => {
                    this.items.push({ id: p.idPointInterest, name: p.name })
                })
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de chargement", "Point interest")
            });
    }

    resetMission() {
        this.mission = new Mission();
        this.selectedPoi = [];
    }

    onSaveMission() {
        this.loading = true;
        this.mission.pointOfInterests = this.selectedPoi.map(item => item.id);
        this.missionService.add(this.mission)
            .subscribe(mission => {
                this.missionSaved.emit(mission);
                this.mission = new Mission();
                this.loading = false;
                this.toastr.success("Enregistré", "Mission");
            }, err => {
                this.loading = false;
                this.toastr.error("Erreu lors d'ajout", "Mission")
            })
    }


    onUpdateMission() {
        this.loading = true;
        this.mission.pointOfInterests = this.selectedPoi.map(item => item.id);
        this.missionService.update(this.mission)
            .subscribe(mission => {
                this.missionSaved.emit(mission);
                this.mission = new Mission();
                this.loading = false;
                this.toastr.success("Modifié", "Mission");
            }, err => {
                this.loading = false;
                this.toastr.error("Erreu lors la modification", "Mission")
            })
    }

    onDropSuccess(itemId: any) {
        this.found = false;
        for (let j = 0; j < itemId.length; j++) {
            if (j > 0 && itemId[j].id == itemId[j - 1].id) {
                this.found = true;
                this.toastr.warning("Veuillez verifier le P.O.I précédent !!", "Mission");
                break;
            }
        }
    }

    onDelete(selectedPoi: any, i: any) {
        this.found = false;
        selectedPoi.splice(i, 1);
        for (let j = 0; j < selectedPoi.length; j++) {
            if (j > 0 && selectedPoi[j].id == selectedPoi[j - 1].id) {
                this.found = true;
                this.toastr.warning("Veuillez verifier le P.O.I précédent !!", "Mission");
                break;
            }
        }
    }

    onChange(event: any) {
        this.selectedItems = this.items.filter(item => item.id == event);
        if (this.selectedPoi.length > 0) {
            if (event == this.selectedPoi[this.selectedPoi.length - 1].id) {
                this.toastr.warning("Veuillez verifier le P.O.I précédent !!", "Mission");
                return;
            }
        }
        for (let i = 0; i < this.selectedItems.length; i++) {
            this.selectedPoi.push({
                id: this.selectedItems[i].id,
                name: this.selectedItems[i].name
            });
        }
    }

}
