import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if needed
import { ValidatorSettingsComponent } from './validator-settings.component';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { NgxLoadingModule } from 'ngx-loading';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ValidatorSettingsComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule, // Include FormsModule if your component uses forms
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    OrderPipModule,
    FilterPipeModule,
    RouterModule

  ],
  exports: [
    ValidatorSettingsComponent // Export the component if used in other modules
  ]
})
export class ValidatorSettingsModule {}
