import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // If you are using forms
import { RouterModule } from '@angular/router'; // For routing if applicable
import { VehiculeDetailsComponent } from './vehicule-details.component';
import { DataManagementService } from '../../../data-management.service';
import { DataManagementComponent } from '../../../data-management.component';
import { DataManagementModule } from '../../../data-management.module';
import { NoValuePipeModule } from 'src/app/pipes/no-value.module';
import { AvatarModule } from 'ngx-avatar';

@NgModule({
  declarations: [
    VehiculeDetailsComponent, // Declare the VehiculeDetailsComponent
    // DataManagementComponent // Include any other components used in this module
  ],
  imports: [
    CommonModule,
    FormsModule, // Import form module if forms are used
    RouterModule,
    DataManagementModule,
    NoValuePipeModule,
    AvatarModule
  ],
  providers: [
    DataManagementService // Add any services that are used in this component
  ],
  exports: [
    VehiculeDetailsComponent // Export the component if it is used in other modules
  ]
})
export class VehiculeDetailsModule { }
