import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';

import { DataManagementService } from '../../data-management';
import { FuelingService } from '../../parc-management';
import { TechnicalDataService } from '../../statistical/technical-data/technical-data.service';

import { ActivityBtntComponent } from './activity-btnt.component';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { CarburantDashbordModule } from '../../parc-management/carburant/carburant-dashbord/carburant-dashbord.module';
import { FormsModule } from '@angular/forms';
import { VehiculeAccordingPoiComponent } from '../../overview/vehicule-according-poi/vehicule-according-poi.component';
import { VidangeChartComponent } from '../../overview/dashbord-chart/vidange-chart/vidange-chart.component';
import { VehiculeAccordingPoiModule } from '../../overview/vehicule-according-poi/vehicule-according-poi.module';
import { VidangeChartModule } from '../../overview/dashbord-chart/vidange-chart/vidange-chart.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    ActivityBtntComponent,

  ],
  imports: [
    CommonModule,
    LeafletModule,
    CarburantDashbordModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    VehiculeAccordingPoiModule,
    VidangeChartModule,
    SharedPipesModule,
    TruncateModule,
    VehiculeAccordingPoiModule,

  ],
  providers: [
    BsLocaleService,
    DataManagementService,
    FuelingService,
    TechnicalDataService,
    ToastrService
  ]
})
export class ActivityBtntModule { }
