<form class="form-inline">
  <div class="form-group mx-sm-6 mb-6">
    <label>Zone</label>
    <ng-select
    [multiple]="true"
    bindLabel="text"
    bindValue="id"
    [items]="zoneItems"
    placeholder="select authorité"
    [ngModel]="zonesSelected"
    (data)="selectedzone($event)">
    </ng-select>

    <input *ngIf="!stopTimeSelectMode" type="number" name="stopTime" [(ngModel)]="zoneAlertConfiguration.value2" class="form-control"
      placeholder="Durée d'arrêt">
    <i *ngIf="!stopTimeSelectMode" class="fa fa-times" style="color: #aa1111" (click)="stopTimeSelectMode = true;zoneAlertConfiguration.value2='10'"></i>
  </div>

  <div style="margin: 10px 0 10px 0" *ngIf="!showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-plus-square"></i>
    <span class="link">paramétrage avancé</span>
  </div>

  <div style="margin: 10px 0 10px 0" *ngIf="showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-minus-square"></i>
    <span class="link">masquer paramétrage avancé</span>
  </div>

  <app-cron-settings *ngIf="showAdvancedSettings" [cron]="cronAlertConfiguration"></app-cron-settings>

  <app-email-manager [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails" (onChange)="emailSettings($event)"></app-email-manager>

  <br>

  <div class="form-group">
      <label>Activer l'alert</label>
      <div class="input-group">
          <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive" ></mat-slide-toggle>
      </div>
  </div>

  <button type="submit" class="btn btn-flat btn-primary pull-right" style="margin-top: 45px;" (click)="save()">
        Sauvegarder
  </button>

</form>

<ngx-loading [show]="loader" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
