import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TmmComponent } from './tmm.component'; // Adjust the path if necessary
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    TmmComponent // Declare your TmmComponent here
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    TmmComponent // Export if you need to use this component in other modules
  ]
})
export class TmmModule { }
