import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { TheoreticalCost } from '../model';

@Injectable()
export class TheoreticalCostService {

  constructor(private _http: HttpClient) { }

  theoreticalCosts : TheoreticalCost[] = [];

  public getTheoreticalCostByVehiculeId(idVehicule: number): Observable<TheoreticalCost> {
    let headers = createAuthorizationHeader();
   return this._http.get<TheoreticalCost>(dns + "theorecticalcosts/vehicule/" + idVehicule, { headers: headers });
 }

 public getListTheoreticalCostByGroupId(idGroupe: number, month : number): Observable<TheoreticalCost[]> {
  let headers = createAuthorizationHeader();
 return this._http.get<TheoreticalCost[]>(dns + "theorecticalcosts/groupe/" + idGroupe +"/month/" + month, { headers: headers });
}

 public update(theoreticalCost: TheoreticalCost): Observable<TheoreticalCost> {
  let headers = createAuthorizationHeader();
 return this._http.put<TheoreticalCost>(dns + "theorecticalcosts",theoreticalCost, { headers: headers });
}

}
