import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateInterval } from 'src/app/shared/model';
import { TravelDashbordDto } from 'src/app/client-management/parc-management';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { dns } from 'src/app/global.config';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ActivityChartService {

  constructor(private _http: HttpClient) { }

  /** Return dashbord info of all the groups and vehicules  */
  public getActivityInfo(
    date : DateInterval
    ): Observable<TravelDashbordDto[]> {
  let headers = createAuthorizationHeader();
  let options = { headers: headers };

  return this._http
    .post<TravelDashbordDto[]>(dns + 'paths/getPathsByGroupId/'+ -1+'?timezoneOffset=' + (new Date().getTimezoneOffset())+'&type=AllGroups' , date, options);
}

getActivityBygroup(idGroupe: number, date : DateInterval): Observable<TravelDashbordDto[]> {
  let headers = createAuthorizationHeader();
  let options = { headers: headers };

  return this._http.post<TravelDashbordDto[]>(dns + 'paths/getPathsByGroupId/' + idGroupe+'?timezoneOffset=' + (new Date().getTimezoneOffset())+'&type=ByGroupId', date, options);
}
}
