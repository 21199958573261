import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { Group } from "../data-management.model";
import { HttpClient } from '@angular/common/http';
import { GroupDto } from '..';

@Injectable()
export class GroupService {

    constructor(private _http: HttpClient) {
    }

    // valid !
    getGroupsLabel(): Observable<Group[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<Group[]>(dns + 'groupes/labels', { headers: headers });
    }

    // valid !
    getOne(idGroup: any): Observable<Group> {
        let headers = createAuthorizationHeader();
        return this._http.get<Group>(dns + 'groupes/' + idGroup, { headers: headers });
    }

    // valid !
    getGroupsByPageAndSize(page: number, size: number, search: string = ''): Observable<any> {
        let headers = createAuthorizationHeader();
        return this._http.get(dns + 'groupes/list' + '?page=' + page + '&size=' + size + '&search=' + search, { headers: headers });
    }

    // valid !
    addGroup(groupDto: Group, idVehicules: string): Observable<Group> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.post<Group>(dns + 'groupes/?idVehicules=' + idVehicules, groupDto, options);
    }

    // valid !
    public updateGroup(group: Group, idVehicules: string): Observable<Group> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.put<Group>(dns + 'groupes/?idVehicules=' + idVehicules, group, options);
    }


    // valid !
    deleteGroupById(id: number): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + 'groupes/' + id, { headers: headers });
    }


    getGroups(): Observable<Group[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<Group[]>(dns + 'groupes/minifyGroups', { headers: headers });
    }

    getCountVehiculesByGroupes(): Observable<any[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<Group[]>(dns + 'groupes/nbVehiculesOfEachGroup', { headers: headers });
    }

    getAllGroups(): Observable<GroupDto[]> {
        let headers = createAuthorizationHeader();
        return this._http
          .get<GroupDto[]>(dns + 'groupes/minifyWithVehicules', { headers: headers });
      }
}

