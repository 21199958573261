<div class="row">
    <div class="col-lg-12">
        <div class="box-header with-border">
            <form #dashbordForm="ngForm" class="form-horizontal">

                <div class="col-lg-4 form-group">
                    <label class="col-lg-4 col-md-4 control-label">Date début</label>
                    <div class="col-lg-8 col-md-8">
                        <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                            required (ngModelChange)="onChangeStartDate()">
                    </div>
                </div>

                <div class="col-lg-3 orm-group"style=";margin-left: -40px;">
                    <label class="col-lg-4 col-md-4 control-label">Date fin</label>
                    <div class="col-lg-8 col-md-8">
                        <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [minDate]="startDate" placement="bottom"
                            required>
                    </div>
                </div>

                <div class="col-lg-4 orm-group" style="margin-right: -40px;margin-left: 110px;">
                    <label class="col-lg-4 col-md-4 control-label">Mode {{currentMode}}</label>
                    <div class="col-lg-8 col-md-8">
                        <select class="form-control input-sm" name="dash" [(ngModel)]="currentMode" (change)="ModeChange($event.target.value)">
                            <option [value]="0">
                                par véhicule «{{dataManagementService.getVehiculeName(selectedVehicule)}}»
                            </option>

                            <option [value]="1">
                                par groupe «{{selectedGroup?.nom}}»
                            </option>

                            <option [value]="2">
                                par véhicule «{{dataManagementService.getVehiculeName(selectedVehicule)}}» du groupe «{{selectedGroup?.nom}}»
                            </option>

                            <option [value]="3">
                                Parc
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-1 orm-group">

                    <button type="button" (click)="getInfoVehiculeDashbord()" [disabled]="loading" class="btn btn-block btn-success btn-flat" style="width: 10px;">
                        <i class="fa fa-refresh pull-left" aria-hidden="true"style="margin-left: -5px;" *ngIf="!loading"></i>
                        <i class="fa fa-spinner fa-spin pull-left" aria-hidden="true"style="margin-left: -5px;" *ngIf="loading"></i>
                    </button>

                </div>
            </form>
        </div>
        <div class="pull-right">
            <span *ngIf="dashbordInfo" style="color: red; font-weight: bold;">TOTAL: {{dashbordInfo?.total | number:'.2-3'}} DH</span>
            <span *ngIf="dashbordInfo" style="color: red; font-weight: bold; margin-left:10px">CA: {{dashbordInfo?.turnover | number:'.2-3'}} DH</span>
            <span *ngIf="dashbordInfo" style="color: red; font-weight: bold; margin-left:10px">Résultat: {{ (dashbordInfo?.turnover - dashbordInfo?.total) | number:'.2-3'}} DH</span>
        </div>
    </div>
</div>
<!-- <highcharts-chart  [options]="options"></highcharts-chart> -->
<div style="    margin-top: 72px;" >
  <div id="dashbordpieII" style="min-height: 500px;"></div>
</div>
