import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { imagesDir } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { VehiculeClassification } from '../../activity-overview-management';
import { ActivityVehiculeClassificationsService } from '../../activity-overview-management/activity-vehicle-classification/activity-vehicule-classifications.service';
import { FuelingClassification, TravelReport } from '../../parc-management';
import {TransportTypeInfoDto, TransportTypeService} from "../parametrage/transport-type";


@Component({
  selector: 'app-vehicle-classification',
  templateUrl: './vehicle-classification.component.html',
  styleUrls: ['./vehicle-classification.component.css']
})
export class VehicleClassificationComponent implements OnInit {

  imagesDir = imagesDir;

  // BPE/PF
  selectedTransport: TransportTypeInfoDto = new TransportTypeInfoDto();

  // type de camion
  selectedType: string | null = null;

  dateInterval: DateInterval = new DateInterval();

  now: Date = new Date();

  endDate: Date | null = null;
  startDate: Date = new Date();

  loadingActivity = false;
  loadingFuel = false;
  loadingRotation = false;
  loadingNotification = false;

  // chart lists
  activities: TravelReport[] = [];
  distanceDriven: TravelReport[] = [];
  fuelings: FuelingClassification[] = [];
  rotations: TravelReport[] = [];
  speeding: VehiculeClassification[] = [];

  isKmChart = false;

  // best 5 drivers lists
  ratings: { matricule: string, driver: string, avatarUri: string, note: number }[] = [];

  constructor(private classificationService: ActivityVehiculeClassificationsService, public transportTypeService: TransportTypeService, public toastr: ToastrService) { }

  ngOnInit() {
    this.endDate!.setDate(new Date().getDate());
    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate!.setHours(23, 59, 59, 59);
    this.loadTransportTypes();
  }

  /**
   * load all transport types
   */
  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.transportTypeService.findAllTransportTypes().subscribe(transportTypes => {
        this.transportTypeService.transportTypes = transportTypes;
        if (transportTypes.length > 0) {
          this.selectedTransport = transportTypes[0];
          this.typeSelected();
          this.loadClassification();
          this.loadRotationClassification();
          this.loadFuelingClassification();
          this.loadSpeedCartoClassification();
        }
      }, err => {
        this.toastr.error("Erreur lors de chargements", "Type de transport");
      })
    } else {
      this.selectedTransport = this.transportTypeService.transportTypes[0];
      this.typeSelected();
      this.loadClassification();
      this.loadRotationClassification();
      this.loadFuelingClassification();
      this.loadSpeedCartoClassification();
    }

  }


/**
 * load activity data
 */
  loadClassification() {
    this.loadingActivity = true;
    this.activities = [];
    this.distanceDriven = [];
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.endDate!.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getVehicleActivityClassificationByTransportTypeIdOrGroupId(this.selectedTransport.idTransportType, this.dateInterval, this.selectedType)
      .subscribe(activities => {
        if (activities.length > 0) {
          this.activities = activities.slice().sort((a: any, b: any) => (a.pathDuration > b.pathDuration) ? -1 : 1);
          this.ratings = this.classificationService.topFiveVehicules(this.activities, 3, this.ratings, false);
          // if BPE sort by path time else by km
          if(this.selectedTransport.name == 'BPE'){
            this.isKmChart = false;
          this.distanceDriven = activities.slice().sort((a: any, b: any) => (a.pathTime > b.pathTime) ? -1 : 1);
          }else{
            this.isKmChart = true;
          this.distanceDriven = activities.slice().sort((a: any, b: any) => (a.distanceDriven > b.distanceDriven) ? -1 : 1);
          }
          this.ratings = this.classificationService.topFiveVehicules(this.distanceDriven, 3, this.ratings, false);

        }
        this.loadingActivity = false;
      }, err => {
        this.loadingActivity = false;
        this.toastr.error("Erreur lors de chargements", "Classification");
      }
      )
  }

  /**
 * sort by note and return top 5
 */
  topFive() : any[]{
    return this.ratings.sort((a, b) => (a.note > b.note) ? -1 : 1).slice(0,5);
  }

/**
 * load speed data
 */
  loadSpeedCartoClassification(){
    this.loadingNotification = true;
    this.speeding = [];
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.endDate!.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getSpeedCartoClassificationsByTransportTypeId(this.selectedTransport.idTransportType, this.dateInterval, this.selectedType!)
      .subscribe(speeding => {
        this.speeding = speeding;
        this.ratings = this.classificationService.offenseAndSinistreRating(this.speeding, 2, this.ratings);
        this.loadingNotification = false;
      }, err => {
        this.loadingNotification = false;
        this.toastr.error("Erreur lors de chargements", "Classification E.vitesse");
      }
      )

  }

  /**
 * load rotation data
 */
  loadRotationClassification() {
    this.loadingRotation = true;
    this.rotations = [];
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.endDate!.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getVehicleRotationClassificationByTransportTypeId(this.selectedTransport.idTransportType, this.dateInterval, this.selectedType!)
      .subscribe(rotations => {
        this.rotations = rotations.sort((a: any, b:any) => (a.rotation > b.rotation) ? -1 : 1);
        this.ratings = this.classificationService.topFiveVehicules(this.rotations, 5, this.ratings, false);
        this.loadingRotation = false;
      }, err => {
        this.loadingRotation = false;
        this.toastr.error("Erreur lors de chargements", "Classification Rotation");
      }
      )
  }

  /**
 * load fuel data
 */
  loadFuelingClassification() {
    this.loadingFuel = true;
    this.fuelings = [];
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.endDate!.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getVehicleFuelingClassificationByTransportTypeId(this.selectedTransport.idTransportType, this.dateInterval, this.selectedType)
      .subscribe(fuelings => {
        this.fuelings = fuelings;
        this.ratings = this.classificationService.topFiveVehicules(this.fuelings, 7, this.ratings, true);
        this.loadingFuel = false;
      }, err => {
        this.loadingFuel = false;
        this.toastr.error("Erreur lors de chargements", "Classification Carburant");
      }
      )
  }

  /**
 * load all data
 */
  loadAllClasifications() {
    this.ratings = [];
    this.loadClassification();
    this.loadRotationClassification();
    this.loadFuelingClassification();
    this.loadSpeedCartoClassification();
  }

  typeSelected() {
    if (this.selectedTransport.name == 'PF') {
      this.selectedType = 'SOLO';
    } else {
      this.selectedType = 'BETONNIERE';
    }
  }

  chooseTransport(transport:any) {
    this.selectedTransport = transport;
    this.typeSelected();
  }

  /**
  * Action change date début
  */
  onChangeStartDate() {
    if (this.endDate! < this.startDate) {
      this.endDate = new Date();
    }
  }


}
