import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarburantTemplateSettingComponent } from './carburant-template-setting.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { FuelingTemplateSettingService } from '../../providers/fueling-template-setting.service';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    CarburantTemplateSettingComponent // Declare the CarburantTemplateSettingComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    FormsModule,
    NgxLoadingModule.forRoot({}),
  ],
  providers: [
    FuelingTemplateSettingService // Provide FuelingTemplateSettingService
  ],
  exports: [
    CarburantTemplateSettingComponent // Export CarburantTemplateSettingComponent for use in other modules
  ]
})
export class CarburantTemplateSettingModule { }
