import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { imagesDir } from '../../../../../global.config';
import { Provider, Tire, Vehicule, Group } from '../../../model/index';
import { ProviderService } from '../../../providers/provider-service';
import { TireService } from '../../../providers/tire-service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-tire-form',
    templateUrl: './tire-form.component.html'
})

export class TireFormComponent implements OnInit {

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';

    @Input()
    tire: Tire = new Tire();

    @Input()
    group: Group = new Group();

    @Input()
    vehicule: Vehicule = new Vehicule();

    @Output()
    tireWasAdded: EventEmitter<Tire> = new EventEmitter<Tire>();

    @Output()
    tireWasUpdated: EventEmitter<Tire> = new EventEmitter<Tire>();

    providers: Provider[] = [];

    listPositions: object[] = [
        { label: "R1", value: "R1" },
        { label: "R2", value: "R2" },
        { label: "R3", value: "R3" },
        { label: "R4", value: "R4" },
        { label: "R5", value: "R5" },
        { label: "R6", value: "R6" },
        { label: "R7", value: "R7" },
        { label: "R8", value: "R8" },
        { label: "R9", value: "R9" },
        { label: "R10", value: "R10" },
        { label: "R11", value: "R11" },
        { label: "R12", value: "R12" },
        { label: "R13", value: "R13" },
        { label: "R14", value: "R14" },
        { label: "R15", value: "R15" },
        { label: "R16", value: "R16" },
        { label: "R17", value: "R17" },
        { label: "R18", value: "R18" },
        { label: "R19", value: "R19" },
        { label: "R20", value: "R20" }];

    imagesDir = imagesDir;

    loading: boolean = false;

    showProvider = false;

    maxDate: Date = new Date();

    constructor(private tireService: TireService, private providerService: ProviderService, public toastr: ToastrService, private localeService: BsLocaleService) { }

    ngOnInit() {
        this.loadProviders();
        this.localeService.use('fr');
    }

    /**
     * Charger liste des fournisseurs
     */
    loadProviders() {
        this.providerService.getProviders("MAINTENANCE").subscribe(providers => {
            this.providers = providers;
        }, error => {
            let textError = error.json().errorMessage;
            if (error.json().errors) textError += ' ' + error.json().errors;
            this.toastr.error(textError, error.json().errorCode);
        });
    }

    /**
     * Ajouter nouveau pneu
     */
    onAdd(form: NgForm) {
        this.loading = true;
        this.tireService.add(this.tire, this.vehicule.idVehicule, this.group.idGroupe).subscribe(tire => {
            this.toastr.success('Pneu informations bien sauvegarder !', 'info');
            this.tireWasAdded.emit(tire);
            this.loading = false;
            form.reset();
            this.loadProviders();
        }, error => {
            let textError = error.json().errorMessage;
            if (error.json().errors) textError += ' ' + error.json().errors;
            this.toastr.error(textError, error.json().errorCode);
            this.loading = false;
        });
    }

    /**
     * modifier pneu
     */
    onUpdate(form: NgForm) {
        this.loading = true;
        this.tireService.update(this.tire).subscribe(tire => {
            this.toastr.success('Pneu informations bien sauvegarder !', 'info');
            this.tireWasUpdated.emit(tire);
            this.loading = false;
            form.reset();
            this.loadProviders();
        }, error => {
            let textError = error.json().errorMessage;
            if (error.json().errors) textError += ' ' + error.json().errors;
            this.toastr.error(textError, error.json().errorCode);
            this.loading = false;
        });
    }
}
