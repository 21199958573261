import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiviCanalComponent } from './suivi-canal.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { DriverChargeService } from '../../parc-management'; // Import your service
import { CanalService } from '../canal.service'; // Import CanalService
import { NgxLoadingModule } from 'ngx-loading';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [
    SuiviCanalComponent // Declare the SuiviCanalComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(), // Import BsDatepickerModule for date picking
    FormsModule,
    NgxLoadingModule.forRoot({}),
    SharedPipesModule,
    HighchartsChartModule
  ],
  providers: [
    DriverChargeService, // Provide DriverChargeService
    CanalService // Provide CanalService
  ],
  exports: [
    SuiviCanalComponent // Export SuiviCanalComponent for use in other modules
  ]
})
export class SuiviCanalModule { }
