import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentStateMoodalComponent } from './current-state-moodal.component';

@NgModule({
  declarations: [
    CurrentStateMoodalComponent  // Declare the component here
  ],
  imports: [
    CommonModule  // Import necessary Angular modules
  ],
  exports: [
    CurrentStateMoodalComponent  // Export the component to be used in other modules if needed
  ]
})
export class CurrentStateMoodalModule { }
