import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [
    FooterComponent // Declare the FooterComponent
  ],
  imports: [
    CommonModule // Import necessary Angular modules
  ],
  exports: [
    FooterComponent // Export the FooterComponent for use in other modules
  ]
})
export class FooterModule { }
