<form class="form-inline">
  <div class="form-group mx-sm-3 mb-2">
    <label>Nombre de jour</label>
    <select class="form-control" ng-selected="1" [(ngModel)]="value1" name="value1" (change)="save()">
      <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
        {{day}}
      </option>
    </select>
  </div>

  <div style="margin: 10px 0 10px 0" *ngIf="!showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-plus-square"></i>
    <span class="link">paramétrage avancé</span>
  </div>

  <div style="margin: 10px 0 10px 0" *ngIf="showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-minus-square"></i>
    <span class="link">masquer paramtrage avancé</span>
  </div>
 
  <div *ngIf="showAdvancedSettings">

    <table style="border-spacing: 10px;border-collapse: separate;">
      <tr>
        <td>
          Assurance
        </td>
        <td>
          <mat-slide-toggle [(ngModel)]="insuranceAlertConfiguration.isActive" name="assurance" [labelPosition]="'before'"></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="insuranceAlertConfiguration.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="insuranceAlertConfiguration.value1" name="insuranceDay">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(jours)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>Visite</td>
        <td>
          <mat-slide-toggle [(ngModel)]="visiteAlertConfiguration.isActive" name="visite" [labelPosition]="'before'"></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="visiteAlertConfiguration.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="visiteAlertConfiguration.value1" name="visiteDay">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(jours)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>Certification d'exploitation</td>
        <td>
          <mat-slide-toggle [(ngModel)]="operationalCertificationAlertConfiguration.isActive" name="roadTaxesAlert" [labelPosition]="'before'"
            (change)="save()"></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="operationalCertificationAlertConfiguration.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="operationalCertificationAlertConfiguration.value1" name="operationalCertificationDay"
              (change)="save()">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(jours)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>Permis Circulation</td>
        <td>
          <mat-slide-toggle [(ngModel)]="permitCertificationAlertConfiguration.isActive" name="permitCertification" [labelPosition]="'before'">
          </mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="permitCertificationAlertConfiguration.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="permitCertificationAlertConfiguration.value1" name="permitCertificationDay">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(jours)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>Vignette</td>
        <td>
          <mat-slide-toggle [(ngModel)]="roadTaxesAlertConfiguration.isActive" name="roadTaxes" [labelPosition]="'before'"></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="roadTaxesAlertConfiguration.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="roadTaxesAlertConfiguration.value1" name="roadTaxesDay">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(jours)</b>
          </span>
        </td>
      </tr>
    </table>

  </div>
 
  <app-email-manager [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails"
    (onChange)="emailSettings($event)"></app-email-manager>

  <br>

  <div class="form-group">
      <label>Activer l'alert</label>
      <div class="input-group">
          <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive" ></mat-slide-toggle>
      </div>
  </div>
 
  <button type="submit" class="btn btn-flat btn-primary pull-right" style="margin-top: 45px;" (click)="save()">
        Sauvegarder
  </button>

</form>