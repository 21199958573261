import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParcSettingsComponent } from './parc-settings.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ParcSettingsComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  exports: [
    ParcSettingsComponent // Export the component if it needs to be used outside of this module
  ]
})
export class ParcSettingsModule { }
