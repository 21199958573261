import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { Device, Group, Vehicule, Driver } from "../data-management.model";

import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from "rxjs";

@Injectable()
export class VehiculeService {
  constructor(private _http: HttpClient) {}

  reloadUnusedDevices = new Subject();

  sendReloadUnusedDevices() {
    this.reloadUnusedDevices.next();
  }

  getReloadUnusedDevices(): Observable<any> {
    return this.reloadUnusedDevices.asObservable();
  }

  // valid
  add(payload: FormData, idGroups: string): Observable<Vehicule> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<Vehicule>(dns + "vehicules/?idGroups=" + idGroups, payload, options);
  }

  // valid
  update(payload: FormData, idGroups: string, avatar : boolean , grayCard : boolean, contracting : boolean, secondContracting : boolean, thirdContracting : boolean): Observable<Vehicule> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<Vehicule>(dns + "vehicules/update?idGroups=" + idGroups + "&deleteAvatar="+ avatar + "&deleteGrayCard=" + grayCard +"&deleteContracting="+contracting+"&deleteSecondContracting="+secondContracting+"&deleteThirdContracting="+thirdContracting, payload, options);
  }

  updateDocument(payload: FormData, idGroups: string, avatar : boolean , permisCard : boolean, grayCard : boolean, contracting : boolean, secondContracting : boolean, thirdContracting : boolean): Observable<Vehicule> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<Vehicule>(dns + "vehicules/updateDocument?idGroups=" + idGroups + "&deleteAvatar="+ avatar + "&deleteGrayCard=" + grayCard+ "&deletePermisCard=" + permisCard +"&deleteContracting="+contracting+"&deleteSecondContracting="+secondContracting+"&deleteThirdContracting="+thirdContracting, payload, options);
  }

  getAll(): Observable<Vehicule[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Vehicule[]>(dns + "vehicules/minifyVehicules", { headers: headers });
  }

  // valid
  getOne(id: number): Observable<Vehicule> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Vehicule>(dns + "vehicules/" + id, { headers: headers });
  }

  getOneWithDocuments(id: number): Observable<Vehicule> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Vehicule>(dns + "vehicules/documents/" + id, { headers: headers });
  }

  // valid
  assignement(idVehicule: number, idDriver: number, assignmentDate: String): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(
        dns +
          "vehicules/assignement/?idVehicule=" +
          idVehicule +
          "&idDriver=" +
          idDriver +
          "&assignmentDate="+
          assignmentDate,
        { headers: headers }
      );
  }

  getListVehicules(
    page: number,
    size: number,
    search: string = ""
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(
        dns +
          "vehicules/list" +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,
        { headers: headers }
      )
      ;
  }

  // valid
  delete(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete<Boolean>(dns + "vehicules/" + id, { headers: headers });
  }

  // valid
  getUnusedDevices(): Observable<Device[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Device[]>(dns + "devices/unused", { headers: headers })
      ;
  }

  // valid
  getGroupsOfVehicule(idVehicule: number): Observable<Group[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Group[]>(dns + "vehicules/groups/" + idVehicule, { headers: headers })
      ;
  }

  getVehiculeCounts() : Observable<number> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<number>(dns + "vehicules/count", { headers: headers });
  }




  exportPassationPDF(passation: any, avatarUri: string): Observable<Blob> {
    const headers = createAuthorizationHeader();
    const params = new HttpParams()
      .set('passation', JSON.stringify(passation))
      .set('avatarUri', avatarUri);

    return this._http.get(`${dns}/passationPdf`, { headers, params, responseType: 'blob' });
  }




  getPassationOfVehicule(idVehicule: number): Observable<any[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<any[]>(dns + "vehicules/passation/" + idVehicule, { headers: headers })
      ;
  }

    // Méthode pour supprimer une passation
    supprimerPassation(id: number): Observable<any> {
      let headers = createAuthorizationHeader();
      return this._http.delete<any>(`${dns}vehicules/passationDelet/${id}`, {
        headers: headers,
      });
    }

}
