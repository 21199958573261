<form #classificationForm="ngForm" class="form-horizontal">
  <div class="box-body">

    <!-- activity settings -->
    <ng-container>

      <div class="form-group has-success form-horizontal">
        <label class="col-sm-2 control-label">H.Activité</label>
        <div class="col-lg-1 col-md-1">
          <mat-checkbox class="col-sm-4" [(ngModel)]="classification.hActivity" name="hActivity">
          </mat-checkbox>
        </div>
        <label *ngIf="!classification.hActivity" class="col-sm-1 control-label">Coefficient</label>
        <label *ngIf="classification.hActivity" class="col-sm-1 control-label">Coefficient *</label>
        <div class="col-lg-2 col-md-2">
          <select [disabled]="!classification.hActivity" [(ngModel)]="classification.coefficientHactivity"
            name="coefficientHactivity" class="form-control input-sm select2" #coefficientHactivity="ngModel" required>
            <option *ngFor="let coefficient of listCoefficients" [value]="coefficient"
              [selected]="coefficient == classification.coefficientHactivity">
              {{coefficient}}</option>
          </select>
        </div>

        <label class="col-sm-2 control-label">H.Travail</label>
        <div class="col-lg-1 col-md-1">
          <mat-checkbox class="col-sm-4" [(ngModel)]="classification.workingTime" name="workingTime">
          </mat-checkbox>
        </div>
        <label *ngIf="!classification.workingTime" class="col-sm-1 control-label">Coefficient</label>
        <label *ngIf="classification.workingTime" class="col-sm-1 control-label">Coefficient *</label>
        <div class="col-lg-2 col-md-2">
          <select [disabled]="!classification.workingTime" [(ngModel)]="classification.coefficientWorkingTime"
            name="coefficientWorkingTime" class="form-control input-sm select2" #coefficientWorkingTime="ngModel"
            required>
            <option *ngFor="let coefficient of listCoefficients" [value]="coefficient"
              [selected]="coefficient == classification.coefficientWorkingTime">
              {{coefficient}}</option>
          </select>
        </div>

      </div>

      <div class="form-group has-success form-horizontal">
        <label class="col-sm-2 control-label">KM</label>
        <div class="col-lg-1 col-md-1">
          <mat-checkbox class="col-sm-4" [(ngModel)]="classification.mileage" name="mileage">
          </mat-checkbox>
        </div>
        <label *ngIf="!classification.mileage" class="col-sm-1 control-label">Coefficient</label>
        <label *ngIf="classification.mileage" class="col-sm-1 control-label">Coefficient *</label>
        <div class="col-lg-2 col-md-2">
          <select [disabled]="!classification.mileage" [(ngModel)]="classification.coefficientMileage"
            name="coefficientMileage" class="form-control input-sm select2" #coefficientMileage="ngModel" required>
            <option *ngFor="let coefficient of listCoefficients" [value]="coefficient"
              [selected]="coefficient == classification.coefficientMileage">
              {{coefficient}}</option>
          </select>
        </div>

      </div>

    </ng-container>

    <!-- END activity -->

    <!-- G.Parc  -->
    <ng-container>

      <div class="form-group has-success form-horizontal">

        <label class="col-sm-2 control-label">Carburant</label>
        <div class="col-lg-1 col-md-1">
          <mat-checkbox class="col-sm-4" [(ngModel)]="classification.fueling" name="fueling">
          </mat-checkbox>
        </div>
        <label *ngIf="!classification.fueling" class="col-sm-1 control-label">Coefficient</label>
        <label *ngIf="classification.fueling" class="col-sm-1 control-label">Coefficient *</label>
        <div class="col-lg-2 col-md-2">
          <select [disabled]="!classification.fueling" [(ngModel)]="classification.coefficientFueling"
            name="coefficientFueling" class="form-control input-sm select2" #airF="ngModel" required>
            <option *ngFor="let coefficient of listCoefficients" [value]="coefficient"
              [selected]="coefficient == classification.coefficientFueling">
              {{coefficient}}</option>
          </select>
        </div>

        <label class="col-sm-2 control-label">Chiffre d'affaire</label>
        <div class="col-lg-1 col-md-1">
          <mat-checkbox class="col-sm-4" [(ngModel)]="classification.turnover" name="turnover">
          </mat-checkbox>
        </div>
        <label *ngIf="!classification.turnover" class="col-sm-1 control-label">Coefficient</label>
        <label *ngIf="classification.turnover" class="col-sm-1 control-label">Coefficient *</label>
        <div class="col-lg-2 col-md-2">
          <select [disabled]="!classification.turnover" [(ngModel)]="classification.coefficientTurnover"
            name="coefficientTurnover" class="form-control input-sm select2" #coefficientTurnover="ngModel" required>
            <option *ngFor="let coefficient of listCoefficients" [value]="coefficient"
              [selected]="coefficient == classification.coefficientTurnover">
              {{coefficient}}</option>
          </select>
        </div>


      </div>

      <div class="form-group has-success form-horizontal">

        <label class="col-sm-2 control-label">Sinistre</label>
        <div class="col-lg-1 col-md-1">
          <mat-checkbox class="col-sm-4" [(ngModel)]="classification.sinistre" name="sinistre">
          </mat-checkbox>
        </div>
        <label *ngIf="!classification.sinistre" class="col-sm-1 control-label">Coefficient</label>
        <label *ngIf="classification.sinistre" class="col-sm-1 control-label">Coefficient *</label>
        <div class="col-lg-2 col-md-2">
          <select [disabled]="!classification.sinistre" [(ngModel)]="classification.coefficientSinistre"
            name="coefficientSinistre" class="form-control input-sm select2" #coefficientSinistre="ngModel" required>
            <option *ngFor="let coefficient of listCoefficients" [value]="coefficient"
              [selected]="coefficient == classification.coefficientSinistre">
              {{coefficient}}</option>
          </select>
        </div>


        <label class="col-sm-2 control-label">Infraction</label>
        <div class="col-lg-1 col-md-1">
          <mat-checkbox class="col-sm-4" [(ngModel)]="classification.offense" name="offense">
          </mat-checkbox>
        </div>
        <label *ngIf="!classification.offense" class="col-sm-1 control-label">Coefficient</label>
        <label *ngIf="classification.offense" class="col-sm-1 control-label">Coefficient *</label>
        <div class="col-lg-2 col-md-2">
          <select [disabled]="!classification.offense" [(ngModel)]="classification.coefficientOffense"
            name="coefficientOffense" class="form-control input-sm select2" #coefficientOffense="ngModel" required>
            <option *ngFor="let coefficient of listCoefficients" [value]="coefficient"
              [selected]="coefficient == classification.coefficientOffense">
              {{coefficient}}</option>
          </select>
        </div>
      </div>

    </ng-container>
    <!-- END G.Parc  -->

    <!-- groups -->
    <ng-container>
      <div class="form-group has-success form-horizontal">
        <label class="col-sm-2 control-label">Groups</label>
        <div class="col-sm-10">
          <ng-multiselect-dropdown-angular7 name="groupsSelected" [data]="groups" placeholder="select Group"
            [(ngModel)]="classification.groups" [settings]="groupsDropdownSettings" required>
          </ng-multiselect-dropdown-angular7>
        </div>
      </div>
    </ng-container>
    <!-- END groups -->

    <div class="box-footer">
      <button type="submit" [disabled]="!classificationForm.form.valid" class="btn btn-info pull-right"
        (click)="onSave()">
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
      </button>
    </div>
  </div>
</form>