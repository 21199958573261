import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiversComponent } from './divers.component'; // Adjust the path as necessary
import { RouterModule } from '@angular/router'; // Import RouterModule if routing is used
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you plan to use ToastrService
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DiversComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule, // Import RouterModule for routing functionalities, if needed
    ToastrModule.forRoot() // Import ToastrModule for notifications, if needed
  ],
  exports: [
    DiversComponent // Export the component if it needs to be used in other modules
  ]
})
export class DiversModule { }
