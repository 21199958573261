<ngx-loading [show]="globalLoading"></ngx-loading>
<div class="card">
  <div class="card-header">
    <div class="row" #customScroll>
      <div class="col-md-1">
        <p (click)="openModal()">
          <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
          ...
        </p>
      </div>
      <div class="col-md-5">
        <select class="form-control input-sm select3" (change)="onChange($event.target.value)">
          <option [value]="ChartTypes[i].id" *ngFor="let charttype of ChartTypes; let i = index">
            {{ ChartTypes[i].name }}
          </option>
        </select>
      </div>
      <!-- <div class="col-md-4">
                <select class="form-control input-sm select3"  [(ngModel)]="IsByParent" (ngModelChange)="onChangeType()"
                  style="margin-bottom: 20px;height: 25px;width: 105px;margin-left: -23px">
                  <option [ngValue]="false">
                      Par Groupe
                  </option>
                  <option [ngValue]="true">
                      Par Parent
                  </option>
              </select>
          </div> -->

      <!-- style="
          margin-bottom: 20px;
          height: 25px;
          margin-left: -42px;
          width: 140px;
        " -->
      <div class="col-md-6" *ngIf="!IsByParent">
        <select class="form-control input-sm select3" [(ngModel)]="isByMultipleGroups"
          (ngModelChange)="onChangeByMultipleGroups()">
          <option [ngValue]="false">Tous les Groupes</option>
          <option [ngValue]="true">Groupe de Véhicules</option>
        </select>
      </div>
    </div>

    <div *ngIf="isByMultipleGroups && !IsByParent" class="row">
      <div class="col-md-12">
        <form #groupForm="ngForm" class="form-horizontal">
          <div class="row">
            <div class="col-lg-8 col-md-8">
              <label class="col-lg-4 col-md-4 control-label">Groupes</label>
              <div class="col-lg-8 form-group">
                <ng-multiselect-dropdown [placeholder]="'personnalisé'" [settings]="groupDropdownSettings"
                  [data]="groups" [(ngModel)]="groupIds" (onSelect)="onItemSelect($event)">
                </ng-multiselect-dropdown>
              </div>
            </div>

            <div class="col-lg-4 col-md-4">
              <div class="form-group">
                <button type="button" (click)="onValidate()" class="btn btn-success btn-block">
                  <i class="fa fa-search"></i>
                  Valider
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div id="bar" style="zoom: 0.54" class="d-inline-block"></div>
  </div>
</div>

<!-- Bootstrap Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{ show: showModal }" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <p>Historique Canal / Groupe / vehicule par Mois</p> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="barModal" style="width: 100%; height: 100%"></div>
      </div>
    </div>
  </div>
</div>
