import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataManagementService, PoiClient } from 'src/app/client-management/data-management';
import { GeoMarketingService } from '../geo-marketing.service';
import { GeoMarketingInfoDto, GeoMarketingAddDto } from '../geo-marketing';


@Component({
    selector: 'app-geo-marketing-form',
    templateUrl: './geo-marketing-form.component.html',
    styleUrls: ['./geo-marketing-form.component.scss'],
    providers: [DataManagementService]
})
export class GeoMarketingFormComponent implements OnInit {

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';


    @Output()
    geoMarketingSaved: EventEmitter<GeoMarketingInfoDto> = new EventEmitter<GeoMarketingInfoDto>();

    @Output()
    geoMarketingUpdated: EventEmitter<GeoMarketingInfoDto> = new EventEmitter<GeoMarketingInfoDto>();

    loading: boolean = false;

    @Input()
    geoMarketing: GeoMarketingInfoDto = new GeoMarketingInfoDto();

    geoMarketingAddDto: GeoMarketingAddDto = new GeoMarketingAddDto();

    items: { id: number, name: string }[] = [];

    zones: PoiClient[] = [];

    selectedItems: { id: number, name: string }[] = [];

    @Input()
    selectedPoi: { id: number, name: string }[] = [];

    constructor(private toastr: ToastrService,
        public dataManagementService: DataManagementService,
        private geoMarketingService: GeoMarketingService) { }

    ngOnInit() {
        this.loadPOI();
    }

    loadPOI() {
        this.loading = true;
        this.dataManagementService.getAllPointClients()
            .subscribe(pointInterests => {
                this.loading = false;
                pointInterests.filter(p => p.type === 'POLYGON').forEach(p => {
                    if (p.myPois.length > 0) {
                        this.items.push({ id: p.idPointClient, name: p.name })
                        this.zones.push(p);
                    }
                })
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de chargement", "Point client")
            });
    }

    resetGeoMarketing() {
        this.geoMarketing = new GeoMarketingInfoDto();
        this.geoMarketingAddDto = new GeoMarketingAddDto();
        this.selectedPoi = [];
    }

    onSaveGeoMarketing() {
        this.loading = true;
        this.geoMarketingAddDto.label = this.geoMarketing.label;
        this.geoMarketingAddDto.idPointClient = this.selectedPoi.map(item => item.id);
        this.geoMarketingService.add(this.geoMarketingAddDto)
            .subscribe(geoMarketing => {
                this.geoMarketingSaved.emit(geoMarketing);
                this.resetGeoMarketing();
                this.loading = false;
                this.toastr.success("Enregistré", "Secteur");
            }, err => {
                this.loading = false;
                this.toastr.error("Erreu lors d'ajout", "Secteur");
            })
    }

    /**
   * modifier Info SIM
   */
    onUpdate() {
        this.loading = true;
        this.geoMarketingAddDto.id = this.geoMarketing.id;
        this.geoMarketingAddDto.label = this.geoMarketing.label;
        this.geoMarketingAddDto.idPointClient = this.selectedPoi.map(item => item.id);
        this.geoMarketingService.update(this.geoMarketingAddDto).subscribe(geoMarketing => {
            this.toastr.success("Modifié", "Secteur");
            this.resetGeoMarketing();
            this.geoMarketingUpdated.emit(geoMarketing);
            this.loading = false;
        }, error => {
            this.loading = false;
            this.toastr.error("Erreu lors modification", "Secteur");

        });
    }

    onDelete(selectedPoi: any, i: any) {
        selectedPoi.splice(i, 1);
    }

    onChange(event: any) {
        if (event == 'null')
            return;
        let selectedItem = this.zones.filter(item => item.idPointClient == event)[0];
        if (!this.selectedPoi.find(p => p.id == selectedItem.idPointClient)) {
            this.selectedPoi.push({
                id: selectedItem.idPointClient,
                name: selectedItem.name
            });
        }
    }

}
