import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametrageCanalComponent } from './parametrage-canal.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule if you are using forms
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule if you are using reactive forms
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { MatDialogModule } from '@angular/material/dialog'; // Import Angular Material Dialog module (if needed)
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ParametrageCanalComponent // Declare the ParametrageCanalComponent
  ],
  imports: [
    CommonModule, // Provides common directives like ngIf and ngFor
    FormsModule, // Add FormsModule for template-driven forms (if needed)
    ReactiveFormsModule, // Add ReactiveFormsModule for reactive forms (if needed)
    ToastrModule.forRoot(), // Initialize the Toastr notifications
    MatDialogModule, // Add Material Dialog Module if you are using dialogs
    RouterModule
  ],
  exports: [
    ParametrageCanalComponent // Export the ParametrageCanalComponent if you want to use it in other modules
  ]
})
export class ParametrageCanalModule { }
