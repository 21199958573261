import { Component, OnInit, ViewChild } from '@angular/core';

import * as Highcharts from 'highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TravelReportDto } from 'src/app/client-management/parc-management';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts)

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
  selector: 'app-charges-transport',
  templateUrl: './charges-transport.component.html',
  styleUrls: ['./charges-transport.component.scss']
})
export class ChargesTransportComponent implements OnInit {
  TravelReportsData: TravelReportDto[] = [];

  @ViewChild("chargesTransport", { static: false }) chargesTransport: ModalDirective;

  options: Object | null = null;
  optionsModal: Object | null = null;
  constructor() { }

  ngOnInit() {
    this.ChargeTransport(this.TravelReportsData);
  }

  showGraphChargesTransport() {
    this.chargesTransport.show();
  }

  ChargeTransport(TravelReportsData: TravelReportDto[]) {
    this.TravelReportsData = TravelReportsData;

    const subMarkTotalsMap = new Map<string, { totalCharge: number; ChargeMission: number }>();

    // Calculate the total 'objectifRotation' and 'averageRotation' for each 'subMark'
    let matricule: string[] = [];

    TravelReportsData.forEach(item => {
      const subMark = item.vehicule.subMark; // Ensure subMark is a string
      const countVh = TravelReportsData.filter(mat => mat.deviceId === item.deviceId);

      // Check if subMark is a string
      if (typeof subMark !== 'string') {
        console.error(`Invalid subMark: ${subMark}`);
        return; // Skip this iteration if subMark is not valid
      }

      if (!subMarkTotalsMap.has(subMark)) {
        subMarkTotalsMap.set(subMark, { totalCharge: 0, ChargeMission: 0 });
      }

      if (!matricule.includes(item.vehiculeId.toString())) {
        matricule.push(item.vehiculeId.toString());
        const rentalCharge = typeof item.vehicule.rental === 'number' ? item.vehicule.rental : 0;
        const count = countVh.length > 0 ? countVh.length : 1; // Avoid division by zero

        subMarkTotalsMap.get(subMark)!.ChargeMission += rentalCharge / count;
      }

      subMarkTotalsMap.get(subMark)!.totalCharge += 0; // This line can be simplified or removed if it's not needed
    });

    const chartData = [
      {
        name: 'C.A (Dh)',
        data: Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => ({
          name: subMark,
          y: totals.totalCharge,
          drilldown: `${subMark}_CA`
        }))
      },
      {
        type: 'column',
        name: 'Charges Transport Réalisées',
        data: Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => ({
          name: subMark,
          y: totals.ChargeMission,
          drilldown: `${subMark}_CT`
        }))
      }
    ];

    // const drilldownDataObjectifRotation = Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => ({
    //   id: `${subMark}_CA`,
    //   name: 'C.A (Dh)',
    //   data: this.TravelReportsData
    //     .filter(item => item.vehicule.subMark === subMark)
    //     .map(item => [item.vehicule.matricule, item.vehicule.rental])
    // }));

    // Create the drilldown data series for 'averageRotation'
    // const drilldownDataAverageRotation = Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => ({
    //   id: `${subMark}_CT`,
    //   name: 'Charges Transport Réalisées',
    //   data: this.TravelReportsData
    //     .filter(item => item.vehicule.subMark === subMark)
    //     .map(item => [item.vehicule.matricule, item.vehicule.rental/item.averageRotation])
    // }));


    const drilldown_data = Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => {
      const vehicles = this.TravelReportsData
        .filter(item => item.vehicule.subMark === subMark)
        .reduce((acc, item) => {
          const existingVehicle = acc.find((v: { name: any; }) => v.name === item.vehicule.matricule);
          const countVh = TravelReportsData.filter(mat => mat.deviceId === item.deviceId)
          if (existingVehicle) {
          } else {
            acc.push({
              name: item.vehicule.matricule,
              y: item.vehicule.rental / countVh.length,
            });
          }

          return acc;
        }, []);

      return {
        id: `${subMark}_CT`,
        type: 'column',
        name: 'Charges Transport Réalisées',
        data: vehicles,
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</b><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span> <br/><b>{point.y}</b>',
        },

      };
    });


    this.setHighCartsLang();
    this.options = {
      chart: {
        type: 'column',
        height: 260,
      },
      title: {
        text: 'Corrélation Charges Transport',
        style: {
          color: '#3c8dbc',
          fontSize: "20px",
        },
        align: 'center'
      },
      xAxis: {
        type: 'category',
        title: {
          text: null,
        },
        labels: {
          enabled: true, // Set this to false to hide the xAxis labels
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: chartData,
      drilldown: {
        series: drilldown_data
      }
    };

    this.optionsModal = {
      chart: {
        type: 'column',
        height: 300
      },
      title: {
        text: 'Corrélation Charges Transport',
        style: {
          color: '#3c8dbc'
        },
        align: 'center'
      },
      xAxis: {
        //categories: ['CANTER', 'SEMI REMORQUE', 'SEMI REMORQUE GRUE','SOLO','SOLO GRUE' ],
        type: 'category',
        title: {
          text: null,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Population (millions)',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        shadow: true,
      },
      credits: {
        enabled: false,
      },
      series: chartData,
      drilldown: {
        series: drilldown_data
      }
    };

    Highcharts.chart('chargesTransport', this.options);
    Highcharts.chart('chargesTransportModal', this.optionsModal);

  }

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7', '#e77e22', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b', '#00f194'
      ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

  }

}
