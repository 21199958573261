import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Vehicule, RapportDto, Group } from '../../../data-management/data-management.model';
import { DataManagementService } from '../../../data-management/data-management.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { Sinistre, MultiType } from '../../model';
import { LossesService } from '../../providers/losses.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImportExportService } from '../../../import-export';

@Component({
  selector: 'app-sinistres',
  templateUrl: './sinistres.component.html',
  styleUrls: ['./sinistres.component.css']
})
export class SinistresComponent implements OnInit, OnDestroy {

  @ViewChild('addEditModal', { static: false }) addEditModal: ModalDirective;

  @ViewChild('importModal', { static: false }) importModal: ModalDirective;

  @ViewChild('sinistreDeleteModal', { static: false }) sinistreDeleteModal: ModalDirective;

  selectedVehicule: Vehicule = new Vehicule();

  selectedGroup: Group = new Group();

  sinistres: Sinistre[] = [];

  sinistresId: number[] = [];

  /**export  */
  repport: RapportDto = new RapportDto();

  getVehiculeWasSelectedSubscription: Subscription;

  getGroupWasSelectedSubscription: Subscription;

  loading = false;

  isDeleteMultiple: boolean = false;

  mode = 'ADD';

  selectedItem = 'SINISTRE';

  currentSinistre: Sinistre = new Sinistre();

  searchTerm: string | null = null;

  constructor(private dataManagementService: DataManagementService, public toastr: ToastrService, private lossesService: LossesService, public exportImport: ImportExportService) { }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
      this.selectedGroup = this.dataManagementService.selectedGroup;
      this.loadSinistre();
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.selectedVehicule = vehicule;
      this.loadSinistre();
    });

    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.selectedGroup = group;
    });
  }

  ngOnDestroy() {
    if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
  }

  public loadSinistre() {
    if (!this.selectedVehicule.idVehicule) return;
    this.loading = true;
    this.lossesService.getSinistres(this.selectedVehicule.idVehicule).subscribe(
      sinistres => {
        this.sinistres = sinistres;
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }


  onAdd() {
    this.currentSinistre = new Sinistre();
    this.mode = 'ADD';
    this.addEditModal.show();
  }


  updateSinistre(sinistre: Sinistre) {
    this.currentSinistre = Object.assign({}, sinistre);
    this.mode = 'UPDATE';
    if (!this.currentSinistre.cabinetExpertise) {
      this.currentSinistre.cabinetExpertise = new MultiType();
    }
    this.addEditModal.show();
  }

  sinistreWasAdded(sinistre: any) {
    this.sinistres.unshift(sinistre);

    this.addEditModal.hide();
    this.toastr.success('Sinistre informations bien sauvegarder !', 'info');
  }

  sinistreWasUpdated(sinistre: any) {

    for (let i = 0; i < this.sinistres.length; i++) {
      if (this.sinistres[i].id === sinistre.id) {
        this.sinistres[i] = sinistre;
        break;
      }
    }

    this.addEditModal.hide();
    this.toastr.success('Sinistre informations bien sauvegarder !', 'info');
  }

  export() {
    this.repport.type = 'SINISTRE';
    this.exportImport.export(this.sinistres, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
  }

  itemWasImported(res: boolean) {
    if (res) {
      this.importModal.hide();
      this.loadSinistre();
    }
  }


  /**
   * Afficher modal de confirmation du suppression
   */
  onConfirmDelete(sinistre: Sinistre) {
    this.currentSinistre = sinistre;
    if (!this.currentSinistre.cabinetExpertise) {
      this.currentSinistre.cabinetExpertise = new MultiType();
    }
    this.isDeleteMultiple = false;
    this.sinistreDeleteModal.show();
  }

  /**
 * Event confirm delete multiple
 */
  onConfirmDeleteMultiple() {
    if (this.sinistresId.length == 0) {
      this.toastr.warning("Veuillez choisir des sinistres à supprimer !");
      return;
    }
    this.isDeleteMultiple = true;
    this.sinistreDeleteModal.show();
  }

  /**
   * Event delete
   */
  onDelete() {
    if (this.isDeleteMultiple) {
      this.deleteMultiple();
    } else {
      this.delete();
    }
  }


  /**
   * Suppression du batterie
   */
  delete() {
    this.loading = true;
    this.lossesService.deleteSinistre(this.currentSinistre.id).subscribe(response => {
      if (response) {

        this.sinistres = this.sinistres.filter(sinistre => sinistre.id != this.currentSinistre.id);
        this.sinistresId = this.sinistresId.filter(sinistreId => sinistreId != this.currentSinistre.id);

        this.toastr.success('Sinistre informations bien supprimer !', 'info');

      } else {
        this.toastr.error('Le sinistre n\'a pas supprimé correctement !', 'Erreur');
      }
      this.loading = false;
      this.sinistreDeleteModal.hide();

    }, error => {
      this.loading = false;
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);

    });
  }

  /**
   * Suppression multiple du pneu
   */
  deleteMultiple() {
    this.loading = true;
    this.lossesService.deleteMultipleSinistre(this.sinistresId).subscribe(response => {
      if (response) {

        for (let sinistreId of this.sinistresId) {
          this.sinistres = this.sinistres.filter(sinistre => sinistre.id != sinistreId);
        }

        this.sinistresId = [];

        this.toastr.success('Suppression effectuée avec succès.', 'info');
      } else {
        this.toastr.error('Les sinistres n\'a pas supprimé correctement !', 'Erreur');
      }
      this.sinistreDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }

  /**
   * Event search
   */
  getSinistres(): Sinistre[] {
    if ((this.searchTerm) && (this.searchTerm.length > 0)) {
      return this.sinistres.filter(sinistre => (sinistre.descriptionDamage) && (sinistre.descriptionDamage.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
    } else {
      return this.sinistres;
    }
  }


  /**
   * Event check row for delete multiple
   */
  onChecked(id: number) {
    if (this.sinistresId.find(sinistreId => sinistreId == id)) {
      this.sinistresId = this.sinistresId.filter(sinistreId => sinistreId != id);
    } else {
      this.sinistresId.push(id);
    }
  }

  /**
   * Event check all row for delete multiple
   */
  onCheckedAll() {
    if (this.sinistresId.length == this.sinistres.length) {
      this.sinistresId = [];
    } else {
      this.sinistresId = [];
      for (let sinistre of this.sinistres) {
        this.sinistresId.push(sinistre.id);
      }
    }
  }

  /**
   * check if id exists in array,
   */
  isCheckedInList(id: number): boolean {
    return this.sinistresId.find(brakeId => brakeId == id) ? true : false;
  }

}
