import { Component, Host, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DataManagementComponent } from '../data-management.component';
import { Program } from '../data-management.model';
import { DataManagementService } from '../data-management.service';
import { ProgramService } from '../providers/program.service';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css']
})
export class ProgramComponent implements OnInit {

  // local vars
  loading: boolean;
  globalLoading: boolean = false;
  currentProgram: Program = new Program();
  // @ViewChild('deleteModal', { static: false })


  @ViewChild('deleteModal', { static: false }) deleteModal: ModalDirective;


  constructor(
    public toastr: ToastrService,
    public programService: ProgramService,
    private dataManagementComponent: DataManagementComponent,
    private dataManagementService: DataManagementService) {
  }


  ngOnInit() {
    this.loadPrograms();
  }

  loadPrograms() {
    this.loading = false;
    this.dataManagementService.loadPrograms();
  }

  confirmDeleteProgramme(program : Program){
    this.currentProgram= program;
    this.deleteModal.show();
  }

  onDelete() {
    this.loading = true;
    this.programService.deleteProgram(this.currentProgram.idProgram).subscribe(deleted => {
      this.deleteModal.hide();
      this.loadPrograms();
      this.loading = false;
      this.toastr.success('Programme bien supprimer !', 'info');

    }, () => {
      this.loading = false;
    })
  }

}
