import { Injectable } from '@angular/core';
import { DataManagementService, Group, Vehicule } from '../../data-management';
import * as Leaflet from 'leaflet';

import { RealTimeRecord, StateCounts } from '../model/real-time.model';
import { DecimalPipe } from '@angular/common';
import { Icon, IconOptions } from 'leaflet';
import { RealtimeRestService } from './realtime-rest.service';
import { DateInterval } from 'src/app/shared/model';

interface ICoordinate {
  lat: number;
  lng: number;
}

@Injectable()
export class RealtimeHelperService {
  constructor(private pipe: DecimalPipe,private dataManagementService: DataManagementService, private realTimeRestService: RealtimeRestService) {}

  // To Do In The Back Side
  getVehicule(idDevice: number | undefined, groups: Group[] | null): Vehicule {
    let foundVehicule = new Vehicule();

    // Vérifiez si groups est null
    if (!groups) {
      return foundVehicule;
    }

    // Parcourez les groupes
    for (let i = 0; i < groups.length; i++) {
      // Vérifiez si chaque groupe a une propriété vehicules et si ce tableau est défini
      if (!groups[i].vehicules) {
        continue; // Passez au groupe suivant si vehicules est null
      }

      // Parcourez les véhicules du groupe
      for (let j = 0; j < groups[i].vehicules.length; j++) {
        // Vérifiez si le véhicule actuel a une propriété idDevice
        if (groups[i].vehicules[j].idDevice === idDevice) {
          return groups[i].vehicules[j];
        }
      }
    }

    return foundVehicule;
  }

  getOldRealTimeRecord(
    id: number,
    oldRealTimeRecords: RealTimeRecord[]
  ): RealTimeRecord | null {
    let result = oldRealTimeRecords.filter((rt) => {
      return rt.idRealTimeRecord === id;
    });
    if (result.length > 0) {
      return result[0];
    }
    return null;
  }


  compareTwoCoordinate(p1: ICoordinate, p2: ICoordinate): boolean {
    return p1.lat === p2.lat && p1.lng === p2.lng;
  }

  getGroupName(idDevice: number, groups: Group[]) {
    let name = '';
    let count = 0;
    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < groups[i].vehicules.length; j++) {
        if (groups[i].vehicules[j].idDevice === idDevice) {
          name = groups[i].nom;
          if (count > 0) {
            name = name + '...';
            break;
          }
          count++;
        }
      }
    }
    return name;
  }

  //Decimale Pipe function
  PipeLngLat(value: any) {
    return this.pipe.transform(value, '2.2-6');
  }

  getGroupimage(idDevice: number, groups: Group[]) {
    let img = '';
    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < groups[i].vehicules.length; j++) {
        if (groups[i].vehicules[j].idDevice === idDevice) {
          img = groups[i].imageUrl;
          break;
        }
      }
    }
    return img;
  }

  // Load dynamiq data
  updateSpecificGroups(realTimeRecord: RealTimeRecord, groups: Group[]) {
    if (groups) {
      for (let i = 0; i < groups.length; i++) {
        for (let j = 0; j < groups[i].vehicules.length; j++) {
          if (
            groups[i].vehicules[j].idDevice === realTimeRecord.idRealTimeRecord
          ) {
            groups[i].vehicules[j].realTimeRecord = realTimeRecord;
            break;
          }
        }
      }
    }
  }
  updateRealTimeRecords(
    realTimeRecord: RealTimeRecord,
    realTimeRecords: RealTimeRecord[]
  ) {
    if (realTimeRecords) {
      for (let i = 0; i < realTimeRecords.length; i++) {
        if (
          realTimeRecords[i].idRealTimeRecord ===
          realTimeRecord.idRealTimeRecord
        ) {
          realTimeRecords[i] = realTimeRecord;
          break;
        }
      }
    }
  }

  isValidPoint(point: { lat: number; lng: number }) {
    if (point && point.lng !== 0 && point.lng !== 0) {
      return true;
    }
    return false;
  }

  // beautify geoconding
  getGeocoding(address: any): string {
    let geocoding: string = '';
    /**addressWasReturned : get address as json object*/
    //let pattern = /[^\x00-\x7F|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û']\^ +| +$|( )+/g;
    let pattern = /[^\x00-\x7F|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û']/g;
    /**
     *  don't replace any char that appear in this (|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û')
     * ^\x00-\x7F replace all non asccii char
     *  replace any char in pattern with signe space
     * ^ + : any sequence of spaces at the beginning of the string
     *  +$ : any sequence of spaces at the end of the string
     *  () any sequence of spaces that matches none of the above
     */

    if (address) {
      if (address.road != null) {
        geocoding = address.road;
      } else {
        if (address.city_district != null) {
          geocoding = address.city_district;
        }
      }

      if (address.neighbourhood != null) {
        geocoding = geocoding
          ? geocoding + ' ' + address.neighbourhood
          : address.neighbourhood;
      }

      if (address.city != null) {
        geocoding = geocoding
          ? geocoding + ' (' + address.city + ')'
          : address.city;
      }

      if (geocoding == null) {
        geocoding = '';
      } else {
        geocoding = geocoding.replace(pattern, ' ');
      }
    }

    return geocoding;
  }

  createIcon(
    angle: number,
    vehicule: any,
    realTimeRecord: any,
    oldRealTimeRecord: any,
    pathSpeed: number,
    imagesCarsDir: string,
    imagesDir: string,
    popup: string,
    groups: Group[]
  ): { icon: Leaflet.DivIcon; popup: string } {
    let iconUrl = '';
    let iconAnchor = [20, 20];
    let popupAnchor = [10, -10];
    let textContent;
    let marginTop;
    let marginRight;

    if (realTimeRecord.realTimeRecordStatus === 'VALID') {
      if (realTimeRecord.speed <= pathSpeed) {
        if (oldRealTimeRecord.speed <= pathSpeed) {
          iconUrl =
            imagesCarsDir +
            'c2x' +
            Math.abs(Math.round(angle / 45) * 45) +
            '.png';
          popupAnchor = [1, 1];
          popup =
            '<span class="badge bg-yellow pull-right">En arrêt..</span><br>' +
            popup;
        } else {
          iconUrl =
            imagesDir + 'c4x' + Math.abs(Math.round(angle / 45) * 45) + '.png';
          popup =
            '<span class="badge bg-blue pull-right">En arrêt provisoirement..</span><br>' +
            popup;
        }
      } else {
        iconUrl =
          imagesDir + 'c1x' + Math.abs(Math.round(angle / 45) * 45) + '.png';
        popup =
          '<span class="badge bg-green pull-right">En route..</span><br>' +
          popup;
      }
    }
    if (
      realTimeRecord.realTimeRecordStatus === 'TECHNICAL_ISSUE' ||
      realTimeRecord.realTimeRecordStatus === 'NON_VALID'
    ) {
      iconUrl =
        imagesDir + 'c3x' + Math.abs(Math.round(angle / 45) * 45) + '.png';
      popup =
        '<span class="badge bg-red pull-right">GPS non valide</span><br>' +
        popup;
    }

    if (vehicule.icon == null || vehicule.icon === 'cabriolet.png') {
      popupAnchor = popupAnchor[0] === 1 ? [10, -10] : [10, -25];
      iconAnchor = [15, 15];
      iconUrl = iconUrl;
    } else if (!vehicule.icon) {
      iconUrl = imagesCarsDir + this.getGroupimage(vehicule.idDevice, groups);
    } else {
      iconUrl = imagesCarsDir + vehicule.icon;
    }
    if(localStorage.getItem('matricule') === 'true'){
      marginTop=35;
      marginRight= 10;
      textContent = '<div class="icon-with-text">' +
      '<div class="additional-text">'+vehicule.matricule+'</div>' +
      '<img src="' + iconUrl + '" class="existing-icon" />' +
      '</div>';
    }else{
      marginTop=15;
      marginRight= 15;
      textContent = '<div class="icon-with-text">' +
      '<img src="' + iconUrl + '" class="existing-icon" />' +
      '</div>';
    }

    const customIcon = Leaflet.divIcon({
      className: 'custom-div-icon',
      html: textContent,
      iconAnchor: [marginRight, marginTop],
      popupAnchor: [popupAnchor[0], popupAnchor[1]],
      iconSize:  [30, 30],
      shadowSize: [30, 30],
      shadowAnchor: [20, 20],
    });
    // Create and return the icon
    const icon = new Icon({
      iconUrl,
      iconSize: [30, 30],
      iconAnchor,
      popupAnchor,
      shadowSize: [30, 30],
      shadowAnchor: [20, 20],
    } as IconOptions);
    return { icon: customIcon, popup };
  }

  popUp(
    driverName: string,
    vehicule: Vehicule,
    subMark: string,
    realTimeRecord: RealTimeRecord
  ) {

    let date = new Date(realTimeRecord.recordTime);
    let minutes = date.getMinutes() + '';
    if (minutes.length === 1) {
      minutes = '0' + date.getMinutes();
    }

    return (
      '<b>Conducteur:</b> ' +
      driverName +
      '<br><b>Matricule:</b> ' +
      vehicule.matricule +
      '<br><b>Mark:</b> ' +
      vehicule.mark +
      subMark +
      '<br><b>Groupe(s):</b> ' +
      vehicule.groupName +
      '<br><b>Lat,Lng:</b><i> [' +
      this.PipeLngLat(realTimeRecord.coordinate.lat) +
      ',' +
      this.PipeLngLat(realTimeRecord.coordinate.lng) +
      ']</i><br><b>Vitesse :</b>' +
      realTimeRecord.speed +
      "<br><b>date et l'heure:</b> " +
      date.getDate() +
      '/' +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      minutes +
      '<br><b>Signal GSM:</b> ' +
      realTimeRecord.signal +
      " <i class='fa fa-wifi' aria-hidden='true'></i><br><b>Sat en vue:</b> " +
      realTimeRecord.satInView +
      " <i class='fa fa-globe' aria-hidden='true'></i>"
    );
  }

  isCordinateEqual(first: any, second: any): boolean {
    return !(first.lat === second.lat && first.lng === second.lng);
  }
  calculeAngle(coordinateEnd: any, coordinateStart: any): number {
    return (
      (Math.atan2(
        coordinateEnd.lng - coordinateStart.lng,
        coordinateEnd.lat - coordinateStart.lat
      ) *
        180) /
      Math.PI
    );
  }

  // isTheRealTimeRecordChnge(records, record) {
  //   /*lng lat*/
  //   if(records == null)
  //     return true;
  //
  //   let result = this.getOldRealTimeRecord(record.idRealTimeRecord, records);
  //
  //   if (
  //     result == null ||
  //     (result.coordinate.lng == record.coordinate.lng &&
  //       result.coordinate.lat == record.coordinate.lat)
  //   ) {
  //     return false;
  //   }else {
  //     return true;
  //   }
  // }



  calculateStateCounts(records: RealTimeRecord[], groups: Group[]): Record<number, StateCounts> {
    let groupStateCounts: Record<number, StateCounts> = {};
    groups.forEach((group) => {
      groupStateCounts[group.idGroupe] = {
        activeCars: [],
        desactiveCars: [],
        nonValidCars: [],
        technicalIssueCars: [],
      };
    });
    records = records.filter((realTimeRecord, index, self) => 
      index === self.findIndex((r) => r.idRealTimeRecord === realTimeRecord.idRealTimeRecord)
    );
    records.forEach((realTimeRecord) => {
      const correspondingGroupId = this.dataManagementService.checkIfDeviceExists(groups, realTimeRecord.idRealTimeRecord);
      if (correspondingGroupId != -1) {
        const stateCounts = groupStateCounts[correspondingGroupId];
        if (
          realTimeRecord.speed === 0 &&
          realTimeRecord.ignition === false &&
          !(realTimeRecord.realTimeRecordStatus === 'NON_VALID') &&
          !(realTimeRecord.realTimeRecordStatus === 'TECHNICAL_ISSUE')
        ) {
          stateCounts.desactiveCars.push(realTimeRecord);
        } else if (
          realTimeRecord.realTimeRecordStatus === 'NON_VALID' ||
          realTimeRecord.realTimeRecordStatus === 'TECHNICAL_ISSUE'
        ) {
          stateCounts.technicalIssueCars.push(realTimeRecord);
        } else if (
          realTimeRecord.speed <=
            this.pathMinSpeedByDeviceId(realTimeRecord.idRealTimeRecord) &&
          realTimeRecord.ignition === true
        ) {
          stateCounts.nonValidCars.push(realTimeRecord);
        } else if (
          realTimeRecord.speed >
          this.pathMinSpeedByDeviceId(realTimeRecord.idRealTimeRecord)
        ) {
          stateCounts.activeCars.push(realTimeRecord);
        }
      }
    });
    return groupStateCounts;
  }

  pathMinSpeedByDeviceId(deviceId: number): number {
    const pathConfig = this.realTimeRestService.pathConfigs.filter(
      (p) => p.deviceId == deviceId
    );
    if (pathConfig && pathConfig.length > 0) {
      return pathConfig[0].pathMinSpeed;
    }
    return 0;
  }
  getTimeDifferenceString(date: any) {
    const now = new Date();
    const inputDate = new Date(date);

    let differenceInMilliseconds = now.getTime() - inputDate.getTime();

    if (differenceInMilliseconds < 0) {
      differenceInMilliseconds = 0;
    }

    const seconds = Math.floor(differenceInMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const remainingSeconds = seconds % 60;
    const remainingMinutes = minutes % 60;
    const remainingHours = hours % 24;

    let result = '';

    if (days > 0) {
      result += `${days} J `;
    }
    if (days > 0 || remainingHours > 0) {
      result += `${remainingHours} H `;
    }
    if (days > 0 || remainingHours > 0 || remainingMinutes > 0) {
      result += `${remainingMinutes} Min `;
    }
    result += `${remainingSeconds} S`;

    return result;
  }

  IntervalDateConverter(currentDate: Date): DateInterval {
    const startDateToDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0
    );
    const endDateToDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      23,
      59,
      59
    );
    return { startDate: startDateToDay, endDate: endDateToDay };
  }
}
