import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { imagesDir } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { DataManagementService, GroupDto } from '../../data-management';
import { FuelingClassification  } from '../../parc-management';
import {  TravelReport } from '../../parc-management';
import { ActivityVehiculeClassification, VehiculeClassification } from './activity-vehicule-classifications.model';
import { ActivityVehiculeClassificationsService } from './activity-vehicule-classifications.service';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-activity-vehicle-classification',
  templateUrl: './activity-vehicle-classification.component.html',
  styleUrls: ['./activity-vehicle-classification.component.css']
})
export class ActivityVehicleClassificationComponent implements OnInit {

  imagesDir = imagesDir;

  loading = false;

  dateInterval: DateInterval = new DateInterval();

  now: Date = new Date();

  startDate: Date = new Date();
  endDate: Date = new Date();

  /** GROUP VAR */
  selectedGroup: GroupDto = new GroupDto();

  groups: GroupDto[] = [];

  // best 5 drivers lists
  ratings: { matricule: string, driver: string, avatarUri: string, note: number }[] = [];

  // chart lists
  activities: TravelReport[] = [];
  distanceDriven: TravelReport[] = [];
  pathTime: TravelReport[] = [];
  fuelings: FuelingClassification[] = [];
  offenses: VehiculeClassification[] = [];
  sinistres: VehiculeClassification[] = [];
  turnOvers: VehiculeClassification[] = [];

  months = [
    { id: 0, name: 'ce mois-ci' },
    { id: 1, name: 'Mois dernier' },
    { id: 2, name: 'Mois -2' },
  ];

  /** classification settings */
  classification: ActivityVehiculeClassification = new ActivityVehiculeClassification();
  @ViewChild('classificationModal', { static: false })
  classificationModal: ModalDirective;

  constructor(private classificationService: ActivityVehiculeClassificationsService, private groupService: DataManagementService,
    public toastr: ToastrService) { }

  ngOnInit() {
    this.endDate.setDate(new Date().getDate());
    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);
    this.loadGroups();

  }

  loadGroups() {
    this.loading = true;
    this.groupService.getAllGroups().subscribe(groups => {
      this.groups = groups;
      if (this.groups.length > 0) {
        this.selectedGroup = this.groups[0];
        this.loadCoefficientSetting();

      }
      this.loading = false;
    }, err => {
      this.loading = false;
      this.toastr.error("Erreur lors de chargements", "Group");
    })
  }

  onGroupChange() {
    this.classification = new ActivityVehiculeClassification();
    this.ratings = [];
    this.loadCoefficientSetting();
  }

  loadOffenseClassification() {
    this.loading = true;
    this.offenses = [];
    // this.dateInterval.startDate = new Date(this.startDate.getTime());
    // this.dateInterval.endDate = new Date(this.endDate.getTime());
    // this.dateInterval.startDate.setHours(0, 0, 0, 0);
    // this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getVehicleLossesClassificationsByGroupId('offense', this.selectedGroup.idGroupe, this.dateInterval)
      .subscribe(offenses => {
        this.offenses = offenses;
        this.ratings = this.classificationService.offenseAndSinistreRating(this.offenses, this.classification.coefficientOffense, this.ratings);
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Classification Offense");
      }
      )
  }

  loadSinistreClassification() {
    this.loading = true;
    this.sinistres = [];
    // this.dateInterval.startDate = new Date(this.startDate.getTime());
    // this.dateInterval.endDate = new Date(this.endDate.getTime());
    // this.dateInterval.startDate.setHours(0, 0, 0, 0);
    // this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getVehicleLossesClassificationsByGroupId('sinistre', this.selectedGroup.idGroupe, this.dateInterval)
      .subscribe(sinistres => {
        this.sinistres = sinistres;
        this.ratings = this.classificationService.offenseAndSinistreRating(this.sinistres, this.classification.coefficientSinistre, this.ratings);
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Classification sinistre");
      }
      )
  }

  loadTurnOverClassification() {
    this.loading = true;
    this.turnOvers = [];
    // this.dateInterval.startDate = new Date(this.startDate.getTime());
    // this.dateInterval.endDate = new Date(this.endDate.getTime());
    // this.dateInterval.startDate.setHours(0, 0, 0, 0);
    // this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getVehicleTurnOverClassificationsByGroupId(this.selectedGroup.idGroupe, this.dateInterval)
      .subscribe(turnOvers => {
        this.turnOvers = turnOvers;
        this.ratings = this.classificationService.topFiveVehicules(this.turnOvers, this.classification.coefficientTurnover, this.ratings, true);
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Classification Charge conducteur");
      }
      )
  }

  loadCoefficientSetting() {
    this.loading = true;
    this.classificationService.getCoefficicientSettingsByGroupId(this.selectedGroup.idGroupe).subscribe(classification => {
      if (classification) {
        this.classification = classification;
        //this.loadAllClasifications();
        this.onChangePeriode('m');
      }
      this.loading = false;
    }, err => {
      this.loading = false;
      this.toastr.error("Erreur lors de chargements", "Coefficient");
    })
  }

  loadFuelingClassification() {
    this.loading = true;
    this.fuelings = [];
    // this.dateInterval.startDate = new Date(this.startDate.getTime());
    // this.dateInterval.endDate = new Date(this.endDate.getTime());
    // this.dateInterval.startDate.setHours(0, 0, 0, 0);
    // this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getVehicleFuelingClassificationByTransportTypeId(this.selectedGroup.idGroupe, this.dateInterval, '')
      .subscribe(fuelings => {
        this.fuelings = fuelings.sort((a, b) => (a.consommation < b.consommation) ? -1 : 1);
        this.ratings = this.classificationService.topFiveVehicules(this.fuelings, this.classification.coefficientFueling, this.ratings, true);
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Classification Carburant");
      }
      )
  }

  loadClassification() {
    this.loading = true;
    this.activities = [];
    this.distanceDriven = [];
    this.pathTime = [];
    // this.dateInterval.startDate = new Date(this.startDate.getTime());
    // this.dateInterval.endDate = new Date(this.endDate.getTime());
    // this.dateInterval.startDate.setHours(0, 0, 0, 0);
    // this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.classificationService.getVehicleActivityClassificationByTransportTypeIdOrGroupId(this.selectedGroup.idGroupe, this.dateInterval, null)
      .subscribe(activities => {
        if (activities.length > 0) {
          if (this.classification?.hActivity) {
            this.activities = activities.slice().sort((a, b) => (a.pathDuration > b.pathDuration) ? -1 : 1);
            this.ratings = this.classificationService.topFiveVehicules(this.activities, this.classification.coefficientHactivity!, this.ratings, false);
          }
          if (this.classification?.mileage) {
            this.distanceDriven = activities.slice().sort((a: { distanceDriven: number }, b: { distanceDriven: number }) => (a.distanceDriven > b.distanceDriven) ? -1 : 1);
            this.ratings = this.classificationService.topFiveVehicules(this.distanceDriven, this.classification.coefficientMileage!, this.ratings, false);
          }
          if (this.classification?.workingTime) {
            this.pathTime = activities.slice().sort((a: { pathTime: number }, b: { pathTime: number }) => (a.pathTime > b.pathTime) ? -1 : 1);
            this.ratings = this.classificationService.topFiveVehicules(this.pathTime, this.classification.coefficientWorkingTime!, this.ratings, false);
          }
        }
        this.loading = false;
      }, () => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Classification");
      });

  }

  loadAllClasifications() {
    this.ratings = [];
    if (this.classification.mileage || this.classification.hActivity || this.classification.workingTime)
      this.loadClassification();
    if (this.classification.fueling)
      this.loadFuelingClassification();
    if (this.classification.offense)
      this.loadOffenseClassification();
    if (this.classification.sinistre)
      this.loadSinistreClassification();
    if (this.classification.turnover)
      this.loadTurnOverClassification();
  }

  topFive(): any[] {
    return this.ratings.sort((a, b) => (a.note > b.note) ? -1 : 1).slice(0, 6);
  }


  /**
  * Action change date début
  */
  // onChangeStartDate() {
  //   if (this.endDate < this.startDate) {
  //     this.endDate = null;
  //   }
  // }

  /**
   * Actualiser l'objet aprés l'événement "save"
   */
  classificationWasSaved(classification: ActivityVehiculeClassification) {
    if (classification.groups.find(g => g.idGroupe == this.selectedGroup.idGroupe)) {
      this.classification = classification;
      // must change it with another method that can calculate just the RATING only
      //this.loadAllClasifications();
      this.onChangePeriode('m');
    } else {
      this.classification = new ActivityVehiculeClassification();
      this.ratings = [];
    }
    this.classificationModal.hide();
  }

  onChangePeriode(month: any){
    let start = new Date();
    let end = new Date();
    end.setDate(new Date().getDate());
    switch(month.toString()){
      case '0':
        start = new Date(this.endDate.getFullYear(), this.endDate.getMonth() - 1, this.endDate.getDate());
        break;
      case '1':
        start = new Date(this.endDate.getFullYear(), this.endDate.getMonth() - 2, this.endDate.getDate());
        break;
      case '2':
        start = new Date(this.endDate.getFullYear(), this.endDate.getMonth() - 3, this.endDate.getDate());
        break;
      default:
        start = new Date(this.startDate.getTime());
        end = new Date(this.endDate.getTime());

    }
    this.dateInterval.startDate = start;
    this.dateInterval.endDate = end;
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);
    this.loadAllClasifications();

  }




}
