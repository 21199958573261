import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderSettingsFormComponent } from './provider-settings-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modal functionality
import { ProviderService } from '../../../providers/provider-service';

@NgModule({
  declarations: [
    ProviderSettingsFormComponent // Declare the ProviderSettingsFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule to handle form controls
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    ModalModule.forRoot() // Import ModalModule for Bootstrap modals
  ],
  exports: [
    ProviderSettingsFormComponent // Export ProviderSettingsFormComponent for use in other modules
  ],
  providers: [
    ProviderService // Provide ProviderService for dependency injection
  ]
})
export class ProviderSettingsFormModule { }
