import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanalPfbFormComponent } from './canal-pfb-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule if you are using forms
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule if you are using reactive forms
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { MatDialogModule } from '@angular/material/dialog'; // Import Angular Material Dialog module (if needed)
import { RouterModule } from '@angular/router'; // Import RouterModule if using routing
import { NgxLoadingModule } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    CanalPfbFormComponent // Declare the CanalPfbFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    RouterModule,
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),

  ],
  exports: [
    CanalPfbFormComponent // Export the CanalPfbFormComponent if you want to use it in other modules
  ]
})
export class CanalPfbFormModule { }
