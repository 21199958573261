<form #oilChangeForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Date *</label>
            <div class="col-sm-10">
                <input type="text" class="form-control input-sm" bsDatepicker [(bsValue)]="oilChange.operationTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    required autocomplete="off">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">H.Moteur</label>
            <div class="col-lg-1 col-md-1">
                <mat-checkbox class="col-sm-4" [(ngModel)]="oilChange.engineOil" name="engineOil">
                </mat-checkbox>
            </div>
            <label *ngIf="!oilChange.engineOil" class="col-sm-1 control-label">Type</label>
            <label *ngIf="oilChange.engineOil" class="col-sm-1 control-label">Type *</label>
            <div *ngIf="showTypeEngineOil" class="col-lg-2 col-md-2">
                <select [disabled]="!oilChange.engineOil" [(ngModel)]="oilChange.typeEngineOil"
                    (change)="onChangeSelectOilChange()" name="typeEngineOil" class="form-control input-sm select2"
                    #typeEngineOil="ngModel" required>
                    <option *ngFor="let type of listType" [value]="type.value"
                        [selected]="type.value == oilChange.typeEngineOil">{{type?.name}}</option>
                    <option *ngIf="isNewTypeOil(oilChange.typeEngineOil)" [selected]="true"
                        [value]="oilChange.typeEngineOil">{{oilChange.typeEngineOil}}</option>
                </select>
            </div>
            <div *ngIf="!showTypeEngineOil" class="col-lg-2 col-md-2">
                <input type="number" [disabled]="!oilChange.engineOil" [(ngModel)]="oilChange.typeEngineOil"
                    name="typeEngineOil" class="form-control input-sm" placeholder="H Moteur" #typeEngineOil="ngModel"
                    min="1" required>
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                    (click)="showTypeEngineOil = true;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
            <label *ngIf="!oilChange.engineOil" class="col-sm-2 control-label">Quantité</label>
            <label *ngIf="oilChange.engineOil" class="col-sm-2 control-label">Quantité *</label>
            <div class="col-sm-4">
                <input [disabled]="!oilChange.engineOil" [(ngModel)]="oilChange.quantity" name="quantity" type="number"
                    class="form-control input-sm" #quantity="ngModel" min="1" required placeholder="Quantité">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">F.Huile</label>
            <div class="col-lg-1 col-md-1">
                <mat-checkbox class="col-sm-4" [(ngModel)]="oilChange.oilF" name="oilF">
                </mat-checkbox>
            </div>
            <label *ngIf="!oilChange.oilF" class="col-sm-1 control-label">Type</label>
            <label *ngIf="oilChange.oilF" class="col-sm-1 control-label">Type *</label>
            <div *ngIf="showTypeOilF" class="col-lg-2 col-md-2">
                <select [disabled]="!oilChange.oilF" [(ngModel)]="oilChange.typeOilF"
                    (change)="onChangeSelectTypeOilF()" name="typeOilF" class="form-control input-sm select2"
                    #typeOilF="ngModel" required>
                    <option *ngFor="let type of listType" [value]="type.value"
                        [selected]="type.value == oilChange.typeOilF">{{type?.name}}</option>
                    <option *ngIf="isNewTypeOil(oilChange.typeOilF)" [selected]="true" [value]="oilChange.typeOilF">
                        {{oilChange.typeOilF}}</option>
                </select>
            </div>
            <div *ngIf="!showTypeOilF" class="col-lg-2 col-md-2">
                <input [disabled]="!oilChange.oilF" [(ngModel)]="oilChange.typeOilF" name="typeOilF" type="number"
                    class="form-control input-sm" placeholder="F Huile" #typeOilF="ngModel" min="1" required>
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="showTypeOilF = true;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
            <label class="col-sm-2 control-label">F.Air</label>
            <div class="col-lg-1 col-md-1">
                <mat-checkbox class="col-sm-4" [(ngModel)]="oilChange.airF" name="airF">
                </mat-checkbox>
            </div>
            <label *ngIf="!oilChange.airF" class="col-sm-1 control-label">Type</label>
            <label *ngIf="oilChange.airF" class="col-sm-1 control-label">Type *</label>
            <div *ngIf="showTypeAirF" class="col-lg-2 col-md-2">
                <select [disabled]="!oilChange.airF" [(ngModel)]="oilChange.typeAirF"
                    (change)="onChangeSelectTypeAirF()" name="typeAirF" class="form-control input-sm select2"
                    #airF="ngModel" required>
                    <option *ngFor="let type of listType" [value]="type.value"
                        [selected]="type.value == oilChange.typeAirF">{{type?.name}}</option>
                    <option *ngIf="isNewTypeOil(oilChange.typeAirF)" [selected]="true" [value]="oilChange.typeAirF">
                        {{oilChange.typeAirF}}</option>
                </select>
            </div>
            <div *ngIf="!showTypeAirF" class="col-lg-2 col-md-2">
                <input [disabled]="!oilChange.airF" [(ngModel)]="oilChange.typeAirF" name="typeAirF" type="number"
                    class="form-control input-sm" placeholder="F Air" #airF="ngModel" min="1" required>
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="showTypeAirF = true;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">F Carburant</label>
            <div class="col-lg-1 col-md-1">
                <mat-checkbox class="col-sm-4" [(ngModel)]="oilChange.fuelF" name="fuelF">
                </mat-checkbox>
            </div>
            <label *ngIf="!oilChange.fuelF" class="col-sm-1 control-label">Type</label>
            <label *ngIf="oilChange.fuelF" class="col-sm-1 control-label">Type *</label>
            <div *ngIf="showTypeFuelF" class="col-lg-2 col-md-2">
                <select [disabled]="!oilChange.fuelF" [(ngModel)]="oilChange.typeFuelF"
                    (change)="onChangeSelectTypeFuelF()" name="typeFuelF" class="form-control input-sm select2"
                    #fuelF="ngModel" required>
                    <option *ngFor="let type of listType" [value]="type.value"
                        [selected]="type.value == oilChange.typeFuelF">{{type?.name}}</option>
                    <option *ngIf="isNewTypeOil(oilChange.typeFuelF)" [selected]="true" [value]="oilChange.typeFuelF">
                        {{oilChange.typeFuelF}}</option>
                </select>
            </div>
            <div *ngIf="!showTypeFuelF" class="col-lg-2 col-md-2">
                <input [disabled]="!oilChange.fuelF" [(ngModel)]="oilChange.typeFuelF" name="typeFuelF" type="number"
                    class="form-control input-sm" placeholder="F Carburant" #fuelF="ngModel" min="1" required>
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="showTypeFuelF = true;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
            <label class="col-sm-2 control-label">F.Séparateur</label>
            <div class="col-lg-1 col-md-1">
                <mat-checkbox class="col-sm-4" [(ngModel)]="oilChange.separatorF" name="separatorF">
                </mat-checkbox>
            </div>
            <label *ngIf="!oilChange.separatorF" class="col-sm-1 control-label">Type</label>
            <label *ngIf="oilChange.separatorF" class="col-sm-1 control-label">Type *</label>
            <div *ngIf="showTypeSeparatorF" class="col-lg-2 col-md-2">
                <select [disabled]="!oilChange.separatorF" [(ngModel)]="oilChange.typeSeparatorF"
                    (change)="onChangeSelectSeparatorF()" name="typeSeparatorF" class="form-control input-sm select2"
                    #typeSeparatorF="ngModel" required>
                    <option *ngFor="let type of listType" [value]="type.value"
                        [selected]="type.value == oilChange.typeSeparatorF">{{type?.name}}</option>
                    <option *ngIf="isNewTypeOil(oilChange.typeSeparatorF)" [selected]="true"
                        [value]="oilChange.typeSeparatorF">{{oilChange.typeSeparatorF}}</option>
                </select>
            </div>
            <div *ngIf="!showTypeSeparatorF" class="col-lg-2 col-md-2">
                <input [disabled]="!oilChange.separatorF" [(ngModel)]="oilChange.typeSeparatorF" name="typeSeparatorF"
                    type="number" class="form-control input-sm" placeholder="F Séparateur" #typeSeparatorF="ngModel"
                    min="1" required>

                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                    (click)="showTypeSeparatorF = true;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Filtre de climatisation</label>
            <div class="col-sm-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="oilChange.airConditioningFilter"
                    name="airConditioningFilter">
                </mat-checkbox>
            </div>
            <label class="col-sm-2 control-label">Joint</label>
            <div class="col-sm-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="oilChange.seal" name="seal">
                </mat-checkbox>
            </div>
        </div>

        <div class="form-group has-success form-horizontal">

            <!-- ref filter (F.Huile) -->
            <ng-container *ngIf="showTypeOilF && oilChange.oilF">
                <div *ngIf="oilChange?.refTypeOilF?.id != 0">
                    <label class="col-sm-2 control-label">Ref (F.Huile)</label>
                    <div class="col-sm-2">
                        <select [(ngModel)]="oilChange.refTypeOilF.id" name="refTypeOilFId" #refTypeOilFId="ngModel"
                            class="form-control input-sm select2">
                            <option *ngFor="let refTypeOilFilter of refTypeOilFilters" [value]="refTypeOilFilter.id"
                                [selected]="refTypeOilFilter.id == oilChange.refTypeOilF.id">
                                {{refTypeOilFilter?.name}}
                            </option>
                            <option hidden [value]="null"></option>
                            <option [value]="0">Autres</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="oilChange?.refTypeOilF?.id == 0">
                    <label class="col-sm-2 control-label"> Ref (F.Huile) </label>
                    <div class="col-sm-2">
                        <input type="text" [(ngModel)]="oilChange.refTypeOilF.name" name="refTypeOilFName"
                            class="form-control input-sm" placeholder="Fil" #refTypeOilFName="ngModel" maxlength="50">
                        <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                            (click)="oilChange.refTypeOilF.id = null;">
                            <i class="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </ng-container>
            <!-- end ref filter (F.Huile) -->

            <!-- ref H.Moteur -->
            <ng-container *ngIf="showTypeEngineOil && oilChange.engineOil">
                <div *ngIf="oilChange?.refTypeEngineOil?.id != 0">
                    <label class="col-sm-2 control-label">Ref (H.Moteur)</label>
                    <div class="col-sm-2">
                        <select [(ngModel)]="oilChange.refTypeEngineOil.id" name="refTypeEngineOilId" #refTypeEngineOilId="ngModel"
                            class="form-control input-sm select2">
                            <option *ngFor="let refTypeEngineOilFilter of refTypeEngineOilFilters" [value]="refTypeEngineOilFilter.id"
                                [selected]="refTypeEngineOilFilter.id == oilChange.refTypeEngineOil.id">
                                {{refTypeEngineOilFilter?.name}}
                            </option>
                            <option hidden [value]="null"></option>
                            <option [value]="0">Autres</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="oilChange?.refTypeEngineOil?.id == 0">
                    <label class="col-sm-2 control-label"> Ref (H.Moteur) </label>
                    <div class="col-sm-2">
                        <input type="text" [(ngModel)]="oilChange.refTypeEngineOil.name" name="refTypeEngineOilFName"
                            class="form-control input-sm" placeholder="Fil" #refTypeEngineOilFName="ngModel" maxlength="50">
                        <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                            (click)="oilChange.refTypeEngineOil.id = null;">
                            <i class="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </ng-container>
            <!-- end ref filter (H.Moteur) -->

            <!-- ref filter (F Carburant) -->
            <ng-container *ngIf="showTypeFuelF && oilChange.fuelF">
                <div *ngIf="oilChange?.refTypeFuelF?.id != 0">
                    <label class="col-sm-2 control-label">Ref (F Carburant)</label>
                    <div class="col-sm-2">
                        <select [(ngModel)]="oilChange.refTypeFuelF.id" name="refTypeFuelFId" #refTypeFuelFId="ngModel"
                            class="form-control input-sm select2">
                            <option *ngFor="let refTypeFuelFilter of refTypeFuelFilters" [value]="refTypeFuelFilter.id"
                                [selected]="refTypeFuelFilter.id == oilChange.refTypeFuelF.id">
                                {{refTypeFuelFilter?.name}}
                            </option>
                            <option hidden [value]="null"></option>
                            <option [value]="0">Autres</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="oilChange?.refTypeFuelF?.id == 0">
                    <label class="col-sm-2 control-label"> Ref (F Carburant) </label>
                    <div class="col-sm-2">
                        <input type="text" [(ngModel)]="oilChange.refTypeFuelF.name" name="refTypeFuelFName"
                            class="form-control input-sm" placeholder="Fil" #refTypeFuelFName="ngModel" maxlength="50">
                        <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                            (click)="oilChange.refTypeFuelF.id = null;">
                            <i class="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </ng-container>
            <!-- end ref filter (F Carburant) -->


        </div>

        <div class="form-group has-success">
            <!-- ref F.Air -->
            <ng-container *ngIf="showTypeAirF && oilChange.airF">
                <div *ngIf="oilChange?.refTypeAirF?.id != 0">
                    <label class="col-sm-2 control-label">Ref (F.Air)</label>
                    <div class="col-sm-2">
                        <select [(ngModel)]="oilChange.refTypeAirF.id" name="refTypeAirFId" #refTypeAirFId="ngModel"
                            class="form-control input-sm select2">
                            <option *ngFor="let refTypeAirFilter of refTypeAirFilters" [value]="refTypeAirFilter.id"
                                [selected]="refTypeAirFilter.id == oilChange.refTypeAirF.id">
                                {{refTypeAirFilter?.name}}
                            </option>
                            <option hidden [value]="null"></option>
                            <option [value]="0">Autres</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="oilChange?.refTypeAirF?.id == 0">
                    <label class="col-sm-2 control-label"> Ref (F.Air) </label>
                    <div class="col-sm-2">
                        <input type="text" [(ngModel)]="oilChange.refTypeAirF.name" name="refTypeAirFName"
                            class="form-control input-sm" placeholder="Fil" #refTypeAirFName="ngModel" maxlength="50">
                        <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                            (click)="oilChange.refTypeAirF.id = null;">
                            <i class="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </ng-container>
            <!-- end ref filter (F.Air) -->


            <!-- ref filter (F.Séparateur) -->
            <ng-container *ngIf="showTypeSeparatorF && oilChange.separatorF">
                <div *ngIf="oilChange?.refTypeSeparatorF?.id != 0">
                    <label class="col-sm-2 control-label">Ref (F.Séparateur)</label>
                    <div class="col-sm-2">
                        <select [(ngModel)]="oilChange.refTypeSeparatorF.id" name="refTypeSeparatorFId" #refTypeSeparatorFId="ngModel"
                            class="form-control input-sm select2">
                            <option *ngFor="let refTypeSeparatorFilter of refTypeSeparatorFilters" [value]="refTypeSeparatorFilter.id"
                                [selected]="refTypeSeparatorFilter.id == oilChange.refTypeSeparatorF.id">
                                {{refTypeSeparatorFilter?.name}}
                            </option>
                            <option hidden [value]="null"></option>
                            <option [value]="0">Autres</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="oilChange?.refTypeSeparatorF?.id == 0">
                    <label class="col-sm-2 control-label"> Ref (F.Séparateur) </label>
                    <div class="col-sm-2">
                        <input type="text" [(ngModel)]="oilChange.refTypeSeparatorF.name" name="refTypeSeparatorFName"
                            class="form-control input-sm" placeholder="Fil" #refTypeSeparatorFName="ngModel" maxlength="50">
                        <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                            (click)="oilChange.refTypeSeparatorF.id = null;">
                            <i class="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </ng-container>
            <!-- end ref filter (F.Séparateur) -->
        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Marque d'huile</label>
            <div class="col-sm-10">
                <input [(ngModel)]="oilChange.oilMark" name="oilMark" type="text" class="form-control input-sm"
                    placeholder="Marque d'huile">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Coût Total *</label>
            <div class="col-sm-2">
                <input type="number" [(ngModel)]="oilChange.cost" name="cost" class="form-control input-sm"
                    placeholder="Coût Total" required min="1">
            </div>
            <label class="col-sm-2 control-label">Coût M.O</label>
            <div class="col-sm-2">
                <input type="number" [(ngModel)]="oilChange.laborCost" name="laborCost" class="form-control input-sm"
                    placeholder="Coût main d'oeuvre" min="1">
            </div>
            <label class="col-sm-2 control-label">Dépassement</label>
            <div class="col-sm-2">
                <input type="number" [(ngModel)]="oilChange.overtaking" name="overtaking" class="form-control input-sm"
                    placeholder="Dépassement" min="1">
            </div>
        </div>
        <div *ngIf="oilChange.provider.id != 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Fournisseur *</label>
            <div class="col-sm-10">
                <select [(ngModel)]="oilChange.provider.id" name="providerId" #providerId="ngModel"
                    class="form-control input-sm select2" required>
                    <option *ngFor="let provider of providers" [value]="provider.id"
                        [selected]="provider.id == oilChange.provider.id">
                        {{provider?.name}}
                    </option>
                    <option hidden [value]="null"></option>
                    <option [value]="0">Autres</option>
                </select>
            </div>
        </div>
        <div *ngIf="oilChange.provider.id == 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Nom Fournisseur *</label>
            <div class="col-sm-10">
                <input type="text" [(ngModel)]="oilChange.provider.name" name="providerName"
                    class="form-control input-sm" placeholder="Nom Fournisseur" #providerName="ngModel" required
                    maxlength="50">
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                    (click)="oilChange.provider.id = null;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Odomètre *</label>
            <div class="col-sm-10">
                <input type="number" [(ngModel)]="oilChange.odometre" name="odometre" class="form-control input-sm"
                    placeholder="Odomètre" #odometre="ngModel" required min="1">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Observations</label>
            <div class="col-sm-10">
                <input [(ngModel)]="oilChange.observation" name="observation" type="text" class="form-control input-sm"
                    placeholder="Observations">
            </div>
        </div>
        <div class="box-footer">
            <button type="submit" *ngIf="mode == 'ADD'" [disabled]="!oilChangeForm.form.valid"
                class="btn btn-info pull-right" (click)="onAdd(oilChangeForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
            <button type="submit" [disabled]="!oilChangeForm.form.valid" class="btn btn-info pull-right"
                *ngIf="mode == 'UPDATE'" (click)="onUpdate(oilChangeForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
        </div>
    </div>
</form>