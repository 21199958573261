import { Component, Input, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { AffectationInfo, LocationRealise } from '../../affectations';
const Highcharts21 = require('highcharts');

require('highcharts/modules/exporting')(Highcharts21);
require('highcharts/modules/no-data-to-display')(Highcharts21);
require('highcharts/highcharts-more')(Highcharts21);
@Component({
  selector: 'app-correlation-maintenance',
  templateUrl: './correlation-maintenance.component.html',
  styleUrls: ['./correlation-maintenance.component.css']
})
export class CorrelationMaintenanceComponent implements OnInit {
  chargePfbInfoDto: AffectationInfo;
  correlationMaintenanceChart: Object | null = null;
  correlationMaintenanceTimeLineChart: Object | null = null;
  globalLoading: boolean;

  selectedCharge: AffectationInfo = new AffectationInfo();
  dayDif: number = 0;
  chart: any;
  caRealise_: any[];
  percentageRealise = 0;
  percentageCible = 0;

  maintenances_: any[] = [];

  @Input()
  set selectedCharges(chargePfb: AffectationInfo) {
    if (chargePfb) {
      this.selectedCharge = chargePfb;
    }
  }

  @Input()
  set dayDifference(dayDifference: number) {
    this.dayDif = dayDifference;
  }

  @Input()
  set caRealise(caRealise: LocationRealise[]) {
    this.caRealise_ = Object.assign(caRealise);
  }

  @Input()
  set maintenance(maintenances: any[]) {
    this.maintenances_ = maintenances;
    if (this.dayDif > 1)
      this.timeChart();
    else
      this.gaugeChart();
  }

  constructor(public toastr: ToastrService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.gaugeChart();
  }

  timeChart() {

    var data: { x: Date, y: number }[] = [];
    var index = 0;

    if (this.selectedCharge) {
      var budget = (this.selectedCharge.chiffreAffaire / this.selectedCharge.nbrJours);
      var maintenanceCharge = (this.selectedCharge.budgetMaintenance / this.selectedCharge.nbrJours);

      this.percentageCible = (maintenanceCharge / budget) * 100;
    }

    this.caRealise_.forEach(ca => {
      let maintenance;
      let fuel = [];
      if (this.maintenances_ && this.maintenances_.length > 0) {
        let operationTime = new Date(ca.date);
        fuel = this.maintenances_.filter(f => new Date(f.operationDate).getTime() == operationTime.getTime());
        if (fuel.length > 0) {
          maintenance = fuel[0].cost;
        }
      }

      data.push({ x: new Date(ca.date), y: (maintenance / ca.caRealise) * 100 });
      if (index == this.caRealise_.length - 1)
        this.percentageRealise = (maintenance / ca.caRealise) * 100;
      index++;
    })

    this.correlationMaintenanceTimeLineChart = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      plotOptions: {
        series: {
          connectNulls: true
         },
        enableMouseTracking: false
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: ' %'
      },
      title: {
        text: 'Corrélation Charge maintenance/Chiffre d\'affaire',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 86400000,
        labels: {
          formatter: function (): string {
            return Highcharts21.dateFormat("%d/%m/%Y", (this as any).value);
          }
        }
      },
      yAxis: {
        minRange: this.percentageCible,
        min: 0,
        plotLines: [{
          value: this.percentageCible,
          color: '#ff0000',
          dashStyle: 'shortdash',
          width: 2,
          zIndex: 4,
          label: {
            text: 'Cible',
            textAlign: 'center'
          }
        }],
      },
      series: [{
        name: 'Réalisé',
        data: data,
        zones: [{
          value: this.percentageCible,
          color: 'green'
        }, {
          value: this.percentageCible + 6,
          color: 'orange'
        }, {
          color: 'red'
        }]
      }]

    }, Highcharts21.chart('correlationMaintenanceChart', this.correlationMaintenanceTimeLineChart);

  }


  gaugeChart() {

    var color = '#FFA500';
    this.percentageRealise = 0;
    if (this.selectedCharge) {
      var budget = (this.selectedCharge.chiffreAffaire / this.selectedCharge.nbrJours) * this.dayDif;
      var maintenanceCharge = (this.selectedCharge.budgetMaintenance
        / this.selectedCharge.nbrJours) * this.dayDif;

      this.percentageCible = (maintenanceCharge / budget) * 100;
    }

    if (this.caRealise_) {
      var caR = this.caRealise_.filter(ca => ca.caRealise > 0);
      if (caR.length > 0) {
        let maintenance = [];
        let lastMaintenance = 0;
        if (this.maintenances_ && this.maintenances_.length > 0) {
          maintenance = this.maintenances_.filter(f => new Date(f.operationDate).getTime() == new Date(caR[caR.length - 1].date).getTime());
          if (maintenance.length > 0) {
            lastMaintenance = maintenance[0].cost;
          }
        }
        this.percentageRealise = (lastMaintenance / caR[caR.length - 1].caRealise) * 100;

      }
    }

    if (this.percentageCible > 0) {
      if (this.percentageRealise >= this.percentageCible) {
        if ((this.percentageRealise - this.percentageCible) > 5) {
          color = "#c90000";
        }
      } else {
        color = '#008000';
      }
    }

    this.correlationMaintenanceChart = {

      chart: {
        type: 'solidgauge',
      },

      title: {
        text: 'Corrélation Charge maintenance/Chiffre d\'affaire',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
        enabled: false,
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ // Réalisé
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: '#f5b198',
          borderWidth: 0
        }]
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {

          dataLabels: {
            enabled: true,
            y: -40,
            borderWidth: 0,
            backgroundColor: 'none',
            useHTML: true,
            shadow: false,
            style: {
              fontSize: '13px'
            },
            formatter: function (): string {
              return '<div style="width:100%;text-align:center;"><span style="font-size:13px;font-weight:bold;">' +
                (this as any).point.series.name +
                '</span><br/><span style="font-size:3em;color:' + color + ';font-weight:bold;">' +
                Highcharts21.numberFormat((this as any).y, 1) +
                '%</span>';
            }

          },

          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },

      series: [{
        name: 'Réalisé',
        data: [{
          color: color,
          radius: '87%',
          innerRadius: '63%',
          y: this.percentageRealise
        }]
      }],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        noData: 'Aucune donnée n\'est disponible'
      }
    }, Highcharts21.chart('correlationMaintenanceChart', this.correlationMaintenanceChart);
  }


}
