<div class="kmj-simulation">
  <div class="plus-information">
    <span 
      style="color: #198754;" 
      width="8%"
      data-toggle="tooltip"
      title="{{ childDetail ? childDetail.startAddress  : ''}}">
      <img
          src="assets/icons/realtime/km-jour/location-green.png"
          style="width: 16px;"
          class="icon"
          alt="My Icon"
      />{{childDetail.startAddress || "-----" | slice :0:15 }}
      <span *ngIf="childDetail && childDetail.startAddress && childDetail.startAddress.length > 10"> ...</span>

    </span>
    <span 
      style="color: #D22B2B;" 
      width="8%"
      data-toggle="tooltip"
      title="{{ childDetail ? childDetail.endAddress  : ''}}">
      <img
        src="assets/icons/realtime/km-jour/location-red.png"
        class="icon"
        style="width: 16px;"
        alt="My Icon"
      />{{childDetail.endAddress || "-----" | slice :0:15 }}
        <span *ngIf="childDetail && childDetail.endAddress && childDetail.endAddress.length > 10"> ...</span>
    </span>
    <span 
      width="8%"
      data-toggle="tooltip"
      title="{{ selectedVehicule ? selectedVehicule.matricule : '' }}">
      <img
        src="assets/icons/realtime/km-jour/truck.png"
        class="icon-image"
        style="width: 16px;"
        alt="My Icon"
      /> {{selectedVehicule ? selectedVehicule.matricule :'----'}}
    </span>
    <span
      width="8%"
      data-toggle="tooltip"
      title="{{ selectedVehicule ? dataManagementService.getDriverName(selectedVehicule.driver) : '' }}" >
      <img
          src="assets/icons/realtime/km-jour/driver.png"
          class="icon-image"
          style="width: 16px;"
          alt="My Icon"
      />{{ selectedVehicule ? dataManagementService.getDriverName(selectedVehicule.driver) : '' }}
    </span>
  </div>
  <div 
  class="control-container"
  >
  <div class="simulation-container">
      <div class="control-buttons">
          <span class="main-color" style="cursor: pointer;" (click)="slowDownSimulation()"><i class="fa fa-fast-backward" aria-hidden="true"></i> </span>
          <span 
              (click)="startSimulation()"
              *ngIf="!simulation"
              class="main-color" style="cursor: pointer;">
                <i class="fa fa-play" aria-hidden="true"></i>
          </span>
          <span 
          (click)="stopSimulation()"
          *ngIf="simulation"
          class="main-color" style="cursor: pointer;">
              <i class="fa fa-pause" aria-hidden="true"></i>
          </span>
          <span class="main-color" style="cursor: pointer;"  (click)="speedUpSimulation()"><i class="fa fa-fast-forward" aria-hidden="true"></i></span>
          <span style="font-size: 18px;color: #919191;">|</span>
          <span class="progress-bar-container">
              <progress-range-bar
                  [min]=0
                  [max]="range"
                  [rangeValue]="position"
                  [step]=1
                  (startingDraggin)="startDragin($event)"
                  (rangeValueChange)="onRangeValueChange($event)"
                  (enddedDraggin)="endDragin($event)"
              ></progress-range-bar>
          </span>
          <span style="font-size: 12px;width: 110px;font-weight: 600;">{{childDetail ? (childDetail.beginPathTime| date: 'HH:mm') :'--:--'}} / {{childDetail ? (childDetail.endPathTime| date: 'HH:mm') :'--:--'}}</span>
          <span style="font-size: 18px;color: #919191;">|</span>
          <span style="cursor: pointer;" (click)="restartSimulation()"><i class="fa fa-repeat" aria-hidden="true"></i></span>
      </div>
      <div class="control-information">
          <span style="color: #198754;">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              {{childDetail ? ( childDetail.pathDuration | formatDuration) :'--:--'}}
          </span>
          <span style="color: #D22B2B;">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              {{childDetail ? ( childDetail.stopDuration | formatDuration) :'--:--'}}
          </span>
          <span>
            <img
              src="assets/icons/realtime/km-jour/highway.png"
              class="icon"
              style="width: 16px;"
              alt="My Icon"
            />
              {{childDetail ? ( childDetail.distanceDriven| number : "1.0-1") :'--:--' }} km
          </span>
          <span>
            <img
            src="assets/icons/realtime/km-jour/gas-station.png"
            class="parking"
            style="width: 16px;"
            alt="My Icon"
            />
              {{childDetail ? ( childDetail.ful| number : "1.0-1") :'--:--' }} L
          </span>
          <span>
              <i class="ri-speed-up-fill"></i>
              {{childDetail ? ( childDetail.maxSpeed| number : "1.0-1") :'--:--' }} Km/h
          </span>
          <span style="width: 30px;">
              {{speedSimulation}} X
          </span>
      </div>  
  </div>
  <div class="temps-info">
      <div [numberCircle]="speed" class="circle">
        {{ speed }} <span style="font-size: 10px;"> Km/h </span>
      </div>
  </div>
  <div class="list" [routerLink]="[destinationRoute]">
    <button
      type="button"
      class="btn-md close-button"
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div> 
</div>
</div>
