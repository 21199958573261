<section class="content">
  <div class="row" style="margin: -19px;">

    <router-outlet></router-outlet>
        
    <section class="dashboard_new">
      <app-dashboard></app-dashboard>
    </section>

    <section class="map-container">
      <app-leaflet></app-leaflet> 
    </section>
  </div>
</section>

