<input
  type="range"
  [(ngModel)]="rangeValue"
  [min]="min"
  [max]="max"
  [step]="step"
  (touchend)="onMouseUp()"
  (mouseup)="onMouseUp()"
  (input)="onDrag()"
  (mousedown)="onMouseDown()"
  (touchstart)="onMouseDown()"
/>
