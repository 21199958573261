import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you're using toastr
import { DocumentSettingsComponent } from './document-settings.component'; // Adjust the path as necessary
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    DocumentSettingsComponent,

  ],
  imports: [
    CommonModule, // Include CommonModule for Angular common directives
    FormsModule,   // Include FormsModule if you are using forms in the component
    ToastrModule.forRoot(),
    EmailManagerModule,
    NgSelectModule,
    MatSlideToggleModule

  ],
  exports: [
    DocumentSettingsComponent // Export the component if it needs to be used in other modules
  ]
})
export class DocumentSettingsModule { }
