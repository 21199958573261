<form #fuelForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Date *</label>
            <div class="col-sm-4">
                <input type="text" class="form-control input-sm" bsDatepicker name="operationTime" [(ngModel)]="fuel.operationTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    placeholder="Date" required autocomplete="off">
            </div>
            <label class="col-sm-2 control-label">Quantité *</label>
            <div class="col-sm-4">
                <input name="quantity" [(ngModel)]="fuel.quantity" type="number" class="form-control input-sm"
                    placeholder="Quantité" required>
            </div>
        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Coût/L * {{fuel.costUnit}}</label>
            <div class="col-sm-4">
                <input name="cout" [value]="getCostUnit()" type="number" step="0.01" class="form-control input-sm"
                    placeholder="Coût" disabled>
            </div>
            <label class="col-sm-2 control-label">Coût Total *</label>
            <div class="col-sm-4">
                <input name="cout total" [(ngModel)]="fuel.cost" type="number" min="10" step="0.01"
                    class="form-control input-sm" required="true" placeholder="Coût Total">
            </div>
        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Odomètre *</label>
            <div class="col-sm-4">
                <input name="odometre" [(ngModel)]="fuel.odometre" type="number" min=10 class="form-control input-sm"
                    required="true" placeholder="Odomètre">
            </div>
            <label class="col-sm-2 control-label">N° paiement </label>
            <div class="col-sm-4">
                <input name="Numpaiement" [(ngModel)]="fuel.numberPayment" type="text" class="form-control input-sm"
                     placeholder="N° paiement">
            </div>
        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">N° carte carburant</label>
            <div class="col-sm-4">
                <input name="oilMark" [(ngModel)]="fuel.numCartFuel" type="text" class="form-control input-sm"
                    placeholder="N° carte carburant">
            </div>

            <label class="col-sm-2 control-label">Référence</label>
            <div class="col-sm-4">
                <input name="reference" [(ngModel)]="fuel.reference" type="text" class="form-control input-sm"
                    placeholder="Référence">
            </div>
        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Endroit</label>
            <div class="col-sm-4">
                <input name="place" [(ngModel)]="fuel.place" type="text" class="form-control input-sm" placeholder="Endroit">
            </div>

            <label class="col-sm-2 control-label">Consommation (%)</label>
            <div class="col-sm-4">
                <input name="consumption" [(ngModel)]="fuel.consumption" type="number" class="form-control input-sm"
                    placeholder="Consommation">
            </div>
        </div>

        <div class="form-group has-success">
            <div *ngIf="fuel.paymentType.id != 0">
                <label class="col-sm-2 control-label">Type Paiement</label>
                <div class="col-sm-4">
                    <select [(ngModel)]="fuel.paymentType.id" name="paymentTypeId" #paymentTypeId="ngModel" class="form-control input-sm select2"
                        >
                        <option *ngFor="let paymentType of paymentTypes" [value]="paymentType.id" [selected]="paymentType.id == fuel.paymentType.id">
                            {{paymentType?.name}}
                        </option>
                        <option [value]="0">Autres</option>
                    </select>
                </div>
            </div>

            <div *ngIf="fuel.paymentType.id == 0">
                <label class="col-sm-2 control-label">Nom type *</label>
                <div class="col-sm-4">
                    <input type="text" [(ngModel)]="fuel.paymentType.name" name="paymentTypeName" class="form-control input-sm"
                        placeholder="Nom type" #paymentTypeName="ngModel" required maxlength="50">
                    <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="fuel.paymentType.id = null;">
                        <i class="fa fa-times"></i>
                    </span>
                </div>
            </div>

            <div *ngIf="fuel.provider.id != 0">
                <label class="col-sm-2 control-label">Fournisseur </label>
                <div class="col-sm-4">
                    <select [(ngModel)]="fuel.provider.id" name="providerId" #providerId="ngModel" class="form-control input-sm select2"
                        >
                        <option *ngFor="let provider of providers" [value]="provider.id" [selected]="provider.id == fuel.provider.id">
                            {{provider?.name}}
                        </option>
                        <option hidden [value]="null"></option>
                        <option [value]="0">Autres</option>
                    </select>
                </div>
            </div>
            <div *ngIf="fuel.provider.id == 0">
                <label class="col-sm-2 control-label">Nom Fournisseur *</label>
                <div class="col-sm-4">
                    <input type="text" [(ngModel)]="fuel.provider.name" name="providerName" class="form-control input-sm"
                        placeholder="Nom Fournisseur" #providerName="ngModel" required maxlength="50">
                    <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="fuel.provider.id = null;">
                        <i class="fa fa-times"></i>
                    </span>
                </div>
            </div>

        </div>

        <!-- <div class="form-group has-success">
            <label class="col-sm-2 control-label">Signification </label>
            <div class="col-sm-10">
                <select [(ngModel)]="fuel.signification" name="signification" #signification="ngModel" class="form-control input-sm select2"
                    >
                    <option value="km">KM</option>
                    <option value="h">H</option>
                </select>
            </div>
        </div> -->

        <div class="form-group has-success">
                <label class="col-sm-2 control-label">Observations</label>
                <div class="col-sm-10">
                        <input name="Observations" [(ngModel)]="fuel.observation" type="text" class="form-control input-sm"
                        placeholder="Observations">
                </div>
        </div>


        <div class="box-footer">
            <button (click)="onAdd(fuelForm)" *ngIf="mode == 'ADD'" [disabled]="!fuelForm.form.valid" class="btn btn-info pull-right">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
            <button (click)="onUpdate(fuelForm)" *ngIf="mode == 'UPDATE'" [disabled]="!fuelForm.form.valid" class="btn btn-info pull-right">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
        </div>
    </div>

</form>