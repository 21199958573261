// diver-management.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiverManagementComponent } from './diver-management.component';
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ngx-bootstrap modal module
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { ImportExportService } from 'src/app/client-management/import-export'; // Import the service if needed in the module
import { ImportExportFormsModule } from '../../import-export-forms/import-export-forms.module';
import { DocumentFormModule } from '../../document-circulation/document-form/document-form.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DiverManagementComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(), // Initialize the modal module
    ToastrModule.forRoot(),
    FormsModule,
    ImportExportFormsModule,
    DocumentFormModule
  ],
  exports: [
    DiverManagementComponent // Export the component for use in other modules
  ],
  providers: [
    ImportExportService // Register the service if needed
  ]
})
export class DiverManagementModule { }
