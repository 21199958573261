import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderSettingsComponent } from './provider-settings.component';
import { ProviderService } from '../../providers';
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import for modal functionality
import { ProviderSettingsFormComponent } from './provider-settings-form/provider-settings-form.component';
import { ProviderSettingsFormModule } from './provider-settings-form/provider-settings-form.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProviderSettingsComponent,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Import Toastr for notifications
    ModalModule.forRoot(),
    FormsModule,
    ProviderSettingsFormModule,
    PaginationModule.forRoot(),
    FilterPipeModule,

  ],
  providers: [
    ProviderService // Provide the ProviderService here
  ],
  exports: [
    ProviderSettingsComponent // Export the component if it needs to be used outside of this module
  ]
})
export class ProviderSettingsModule { }
