import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, InjectionToken } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpBackend,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxLoadingModule } from 'ngx-loading';
import { AuthGuard } from './utils/security/auth.guard';
import * as accountGuards from './utils/security/subAccount.guard';
import { AvatarModule } from 'ngx-avatar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { DecimalPipe, DatePipe, CommonModule } from '@angular/common';
import { DndModule } from 'ng2-dnd';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';


import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import {BsDatepickerModule, DatepickerModule} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { MatTableModule } from '@angular/material/table';

registerLocaleData(localeFr, 'fr');

defineLocale('fr', frLocale);

moment.locale('fr-fr');

import { SigninService } from './authentification/signin/signin.service';
import { RealtimeRestService } from './client-management/realtime/services/realtime-rest.service';
import { MapService } from './utils/leaflet/service/map.service';
import { GeocodingService } from './utils/leaflet/service/geocoding.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
import { PathService, MileageService } from './client-management/historical';

/**
 * data management module !
 */
import {
  VehiculeService,
  GroupService,
  DriverService,
  DataManagementService,
  MissionService,
} from './client-management/data-management';

import {
  GeoMarketingService,
} from './client-management/geo-marketing';

/**
 * parc management module !
 */
import {
  TireService,
  BatteryService,
  ReparationService,
  DashbordService,
  DocumentCirculationService,
  ProviderService,
  OilChangeService,
  MultiTypeService,
  LossesService,
  BrakeService,
  FuelingService,
  DriverChargeService,
  TheoreticalCostService,
  FuelingTemplateSettingService,
  FuelingPriceSettingService,
} from './client-management/parc-management';

/**
 * report module !
 */
import {
  ImportExportService,
} from './client-management/import-export';

/**
 * alert management module !
 */
import {

  AlertManagementServiceUtils,
  AlertManagementService,

} from './client-management/alert-management';



import {
  ChargesPfbService,
  ScheduleService,
} from './client-management/charges-pfb';

/**
 * Charge PFB param module
 */

import {
  TransportTypeService,
  PassageService,
  PesageService,
  ValidatorSettingsService,
  ValidationService,
} from './client-management/charges-pfb/parametrage';

import {
  StopChartService,
  PositionByPoiService,
  ActivityTimeChartService,
  KilomtrageChartService,
  ActivityChartService,
  ActivityVehiculeClassificationsService,
} from './client-management/activity-overview-management';

import { TechnicalDataService } from './client-management/statistical/technical-data/technical-data.service';
import { InterventionService } from './client-management/help/intervention-list/intervention.service';
import { VidangeChartService } from './client-management/overview/dashbord-chart/vidange-chart/vidange-chart.service';
import { DepenseChartService } from './client-management/overview/dashbord-chart/depense-chart/depense-chart.service';
import { SinistreChartService } from './client-management/overview/dashbord-chart/sinistre-chart/sinistre-chart.service';
import { RunningDocumentChartService } from './client-management/overview/dashbord-chart/running-document-chart/running-document-chart.service';
import { EmailsService } from './utils/emails/emails.service';
import { HeaderService } from './header/header.service';
import { ParentService } from './client-management/parc-management/providers/parent-service';
import { OverviewService } from './client-management/overview/overview.service';
import { AuthInterceptor } from './utils/security/AuthInterceptor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { AverageCostOfFuelConsumptionChartService } from './client-management/overview/dashbord-chart/average-cost-of-fuel-consumption-chart/average-cost-of-fuel-consumption-chart.service';
import { MonthlyAvailableVehiculesService } from './client-management/overview/dashbord-chart/monthly-available-vehicules-chart/monthly-available-vehicules.service';
import { MonthlyMaintenanceCostsService } from './client-management/overview/dashbord-chart/monthly-maintenance-costs/monthly-maintenance-costs.service';


// Import FusionCharts library and chart modules
import * as charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as Gantt from 'fusioncharts/fusioncharts.gantt.js';
import {
  CimentMissionService,
} from './client-management/ciment-management';
import { ChartConfigService } from './utils/chart/chart-config.service';
import { SpiderChartComponent } from './client-management/statistical/spider-chart/spider-chart.component';
import {
  AlertManagementNewService,
} from './client-management/alert-management-new';
import { RentalAgreementManagementService } from './client-management/rental-agreement-management/rental-agreement-management.service';
import {
  CimentSiteService,
} from './client-management/global-overview-management/ciment-sites';
import {
  CanalService,
} from './client-management/canal-performance';
import { AffectationChargeService } from './client-management/affectation-management/affectation-charge/affectation-charge.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RealtimeService } from './client-management/realtime/services/realtime.service';
import { RealtimeHelperService } from './client-management/realtime/services/realtime-helper.service';
import { RealTimeModule } from './client-management/realtime/realtime.module';
import { StateFilterDirective } from './pipes/statefilter.directive';
import { CanalPfbService } from './client-management/charges-pfb/parametrage/_service/canalpfb.service';
import { SupervisorFormComponent } from './client-management/canal-performance/parametrage-canal/supervisor/supervisor-form/supervisor-form.component';
import {defineLocale, frLocale} from "ngx-bootstrap/chronos";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {Ng2SmartTableModule} from "ng2-smart-table";
import { MomentModule } from 'ngx-moment';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChartArretPoiModule } from './client-management/charges-pfb/performance/graphes/chart-arret-poi/chart-arret-poi.module';
import { DailyKilomtrageChartModule } from './client-management/tmm/daily-kilomtrage-chart/daily-kilomtrage-chart.module';
import { NumeroOtModule } from './client-management/charges-pfb/schedule/numero-ot/numero-ot.module';
import { InterventionFormModule } from './client-management/help/intervention-form/intervention-form.module';
import { HeaderModule } from './header/header.module';
import { HistorySuiviChargeModule } from './client-management/affectation-management/history-suivi-charge/history-suivi-charge.module';
import { SuiviChargeModule } from './client-management/affectation-management/suivi-charge/suivi-charge.module';
import { ChargesPfbHistoryModule } from './client-management/charges-pfb/charges-pfb-history/charges-pfb-history.module';
import { FuelingPriceSettingModule } from './client-management/parc-management/parc-settings/fueling-price-setting/fueling-price-setting.module';
import { VisitFormModule } from './client-management/geo-marketing/visit-form/visit-form.module';
import { DashbordModule } from './client-management/parc-management/dashbord/dashbord.module';
import { GeoMarketingFormModule } from './client-management/geo-marketing/geo-marketing-form/geo-marketing-form.module';
import { FuelSettingsModule } from './client-management/statistical/fuel-settings/fuel-settings.module';
import { VehicleClassificationModule } from './client-management/charges-pfb/vehicle-classification/vehicle-classification.module';
import { SigninModule } from './authentification/signin/signin.module';
import { EmailManagerModule } from './client-management/alert-management/alert-settings/email-manager/email-manager.module';
import { ActivityVehicleClassificationFormModule } from './client-management/activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-form/activity-vehicle-classification-form.module';
import { ActivityVehicleClassificationModule } from './client-management/activity-overview-management/activity-vehicle-classification/activity-vehicle-classification.module';
import { StopChartModule } from './client-management/activity-overview-management/activity-overview/dashbord-chart/stop-chart/stop-chart.module';
import { KilomtrageChartModule } from './client-management/activity-overview-management/activity-overview/dashbord-chart/kilomtrage-chart/kilomtrage-chart.module';
import { ActivityTimeChartModule } from './client-management/activity-overview-management/activity-overview/dashbord-chart/activity-time-chart/activity-time-chart.module';
import { SchedulingFormModule } from './client-management/charges-pfb/schedule/scheduling-form/scheduling-form.module';
import { BreakDownFormModule } from './client-management/ciment-management/break-down-form/break-down-form.module';
import { ScheduleModule } from './client-management/charges-pfb/schedule/schedule.module';
import { ActivityChartModule } from './client-management/activity-overview-management/activity-overview/dashbord-chart/activity-chart/activity-chart.module';
import { CimentMissionScheduleModule } from './client-management/ciment-management/ciment-mission/ciment-mission-schedule/ciment-mission-schedule.module';
import { CimentDashboardModule } from './client-management/ciment-management/ciment-dashboard/ciment-dashboard.module';
import { ChargesPfbTrackingModule } from './client-management/charges-pfb/charges-pfb-tracking/charges-pfb-tracking.module';
import { ChargesPfbFormModule } from './client-management/charges-pfb/charges-pfb-form/charges-pfb-form.module';
import { MissionTrackingModule } from './client-management/data-management/mission/mission-tracking/mission-tracking.module';
import { MissionFormModule } from './client-management/data-management/mission/mission-form/mission-form.module';
import { DriverChargeFormModule } from './client-management/parc-management/driver-charge/driver-charge-form/driver-charge-form.module';
import { CarburantTemplateSettingModule } from './client-management/parc-management/parc-settings/carburant-template-setting/carburant-template-setting.module';
import { MultiTypeSettingsFormModule } from './client-management/parc-management/parc-settings/multi-type-settings/multi-type-settings-form/multi-type-settings-form.module';
import { ProviderSettingsFormModule } from './client-management/parc-management/parc-settings/provider-settings/provider-settings-form/provider-settings-form.module';
import { TireFormModule } from './client-management/parc-management/maintenance/tire/tire-form/tire-form.module';
import { ReparationFormModule } from './client-management/parc-management/maintenance/reparation/reparation-form/reparation-form.module';
import { BatteryFormModule } from './client-management/parc-management/maintenance/battery/battery-form/battery-form.module';
import { VidangeFormModule } from './client-management/parc-management/vidange/vidange-form/vidange-form.module';
import { BrakeFormModule } from './client-management/parc-management/maintenance/brake/brake-form/brake-form.module';
import { SinistreFormModule } from './client-management/parc-management/losses/sinistres/sinistre-form/sinistre-form.module';
import { SinistresModule } from './client-management/parc-management/losses/sinistres/sinistres.module';
import { OffenseFormModule } from './client-management/parc-management/losses/offenses/offense-form/offense-form.module';
import { LeasingModule } from './client-management/parc-management/leasing/leasing.module';
import { BrakeModule } from './client-management/parc-management/maintenance/brake/brake.module';
import { OffensesModule } from './client-management/parc-management/losses/offenses/offenses.module';
import { DocumentFormModule } from './client-management/parc-management/document-circulation/document-form/document-form.module';
import { CarburantFormModule } from './client-management/parc-management/carburant/carburant-form/carburant-form.module';
import { SuiviCanalModule } from './client-management/canal-performance/suivi-canal/suivi-canal.module';
import { HistoryCanalGroupVehiculeModule } from './client-management/tmm/expenses/graph/history-canal-group-vehicule/history-canal-group-vehicule.module';
import { ToolBarModule } from './client-management/realtime/components/control-options/tool-bar/tool-bar.module';
import { SearchBarModule } from './client-management/realtime/components/groups-container/search-bar/search-bar.module';
import { CarburantModule } from './client-management/parc-management/carburant/carburant.module';
import { QualityDrivingModule } from './client-management/tmm/quality-driving/quality-driving.module';
import { MaintenanceChargeModule } from './client-management/affectation-management/suivi-charge/maintenance-charge/maintenance-charge.module';
import { MonthlyAverageCostOfFuelConsumptionChartModule } from './client-management/overview/dashbord-chart/average-cost-of-fuel-consumption-chart/monthly-average-cost-of-fuel-consumption-chart/monthly-average-cost-of-fuel-consumption-chart.module';
import { MonthlyMaintenanceCostsModule } from './client-management/overview/dashbord-chart/monthly-maintenance-costs/monthly-maintenance-costs.module';
import { MonthlyAvailableVehiculesChartModule } from './client-management/overview/dashbord-chart/monthly-available-vehicules-chart/monthly-available-vehicules-chart.module';
import { AverageCostOfFuelConsumptionChartModule } from './client-management/overview/dashbord-chart/average-cost-of-fuel-consumption-chart/average-cost-of-fuel-consumption-chart.module';
import { TurnoverAchievedModule } from './client-management/charges-pfb/charges-pfb-tracking/turnover-achieved/turnover-achieved.module';
import { LocationCostModule } from './client-management/charges-pfb/charges-pfb-tracking/location-cost/location-cost.module';
import { RotationChargeModule } from './client-management/charges-pfb/charges-pfb-tracking/rotation-charge/rotation-charge.module';
import { FuelingChargeModule } from './client-management/charges-pfb/charges-pfb-tracking/fueling-charge/fueling-charge.module';
import { ReportSentModule } from './client-management/import-export/report-sent/report-sent.module';
import { VidangeChartModule } from './client-management/overview/dashbord-chart/vidange-chart/vidange-chart.module';
import { DriverMissionFormModule } from './client-management/driver-mission-management/driver-mission-form/driver-mission-form.module';
import { DriverMissionManagementModule } from './client-management/driver-mission-management/driver-mission-management.module';
import { InterventionListModule } from './client-management/help/intervention-list/intervention-list.module';
import { LeafletModule } from './utils/leaflet/leaflet.module';
import { RouterModule, Routes } from '@angular/router';
import { IMileageDataModule } from './client-management/statistical/mileage-data/mileage-data.module';
import { SupervisorModule } from './client-management/canal-performance/parametrage-canal/supervisor/supervisor.module';
import { SupervisorFormModule } from './client-management/canal-performance/parametrage-canal/supervisor/supervisor-form/supervisor-form.module';
import { ParametrageCanalModule } from './client-management/canal-performance/parametrage-canal/parametrage-canal.module';
import { CanalPfbFormModule } from './client-management/charges-pfb/parametrage/canal-pfb/canal-pfb-form/canal-pfb-form.module';
import { CanalPfbModule } from './client-management/charges-pfb/parametrage/canal-pfb/canal-pfb.module';
import { ParametrageModule } from './client-management/charges-pfb/parametrage/parametrage.module';
import { TonnageModule } from './client-management/parc-management/divers/tonnage/tonnage.module';
import { ControlOptionsModule } from './client-management/realtime/components/control-options/control-options.module';
import { GroupsContainerModule } from './client-management/realtime/components/groups-container/groups-container.module';
import { PerformanceModule } from './client-management/charges-pfb/performance/performance.module';
import { MissionTrackingInfoModule } from './client-management/charges-pfb/mission-tracking-info/mission-tracking-info.module';
import { DeadlinesModule } from './client-management/parc-management/deadlines/deadlines.module';
import { MissionReturnTrackingModule } from './client-management/charges-pfb/mission-return-tracking/mission-return-tracking.module';
import { ExpensesModule } from './client-management/tmm/expenses/expenses.module';
import { ActivityModule } from './client-management/tmm/activity/activity.module';
import { TmmModule } from './client-management/tmm/tmm.module';
import { TravelReportModule } from './client-management/charges-pfb/travel-report/travel-report.module';
import { AffectationManagementModule } from './client-management/affectation-management/affectation-management.module';
import { GlobalOverviewManagementModule } from './client-management/global-overview-management/global-overview-management.module';
import { CimentOverviewModule } from './client-management/global-overview-management/ciment-overview/ciment-overview.module';
import { CimentSiteFormModule } from './client-management/global-overview-management/ciment-sites/ciment-site-form/ciment-site-form.module';
import { CimentSitesModule } from './client-management/global-overview-management/ciment-sites/ciment-sites.module';
import { AlertNewModule } from './client-management/alert-management-new/alert-new/alert-new.module';
import { AlertManagementNewModule } from './client-management/alert-management-new/alert-management-new.module';
import { DrivingQualityModule } from './client-management/statistical/driving-quality/driving-quality.module';
import { ActivityOverviewManagementModule } from './client-management/activity-overview-management/overview-management.module';
import { ProgramFormModule } from './client-management/data-management/program/program-form/program-form.module';
import { ProgramModule } from './client-management/data-management/program/program.module';
import { OverviewModule } from './client-management/overview/overview.module';
import { PoiClientModule } from './client-management/geo-marketing/poi-client/poi-client.module';
import { HelpModule } from './client-management/help/help.module';
import { VisitTrackingModule } from './client-management/geo-marketing/visit-tracking/visit-tracking.module';
import { VisitModule } from './client-management/geo-marketing/visit/visit.module';
import { GeoMarketingTrackingModule } from './client-management/geo-marketing/geo-marketing-tracking/geo-marketing-tracking.module';
import { GeoMarketingModule } from './client-management/geo-marketing/geo-marketing.module';
import { FuelDataModule } from './client-management/statistical/fuel-data/fuel-data.module';
import { TechnicalDataModule } from './client-management/statistical/technical-data/technical-data.module';
import { StatisticalModule } from './client-management/statistical/statistical.module';
import { AccountFormModule } from './client-management/account-management/account-form/account-form.module';
import { AccountManagementModule } from './client-management/account-management/account-management.module';
import { HistoricalModule } from './client-management/historical/historical.module';
import { DashboardModule } from './client-management/statistical/dashboard/dashboard.module';
import { AlertManagementModule } from './client-management/alert-management/alert-management.module';
import { RemisageSettingsModule } from './client-management/alert-management/alert-settings/remisage-settings/remisage-settings.module';
import { SpeedCartoSettingsModule } from './client-management/alert-management/alert-settings/speed-carto-settings/speed-carto-settings.module';
import { ApproFuelSettingsModule } from './client-management/alert-management/alert-settings/appro-fuel-settings/appro-fule-settings.module';
import { ActivityDailySettingsModule } from './client-management/alert-management/alert-settings/activity-daily-settings/activity-daily-settings.module';
import { ActivitySettingsModule } from './client-management/alert-management/alert-settings/activity-settings/activity-settings.module';
import { ActivityHourSettingsModule } from './client-management/alert-management/alert-settings/activity-hour-settings/activity-hour-settings.module';
import { ConsumptionSettingsModule } from './client-management/alert-management/alert-settings/consumption-settings/consumption-settings.module';
import { AlertSettingsModule } from './client-management/alert-management/alert-settings/alert-settings.module';
import { ZoneSettingsModule } from './client-management/alert-management/alert-settings/zone-settings/zone-settings.module';
import { TowingSettingsModule } from './client-management/alert-management/alert-settings/towing-settings/towing-settings.module';
import { TireSettingsModule } from './client-management/alert-management/alert-settings/tire-settings/tire-settings.module';
import { SpeedSettingsModule } from './client-management/alert-management/alert-settings/speed-settings/speed-settings.module';
import { PoiSettingsModule } from './client-management/alert-management/alert-settings/poi-settings/poi-settings.module';
import { OilChangeSettingsModule } from './client-management/alert-management/alert-settings/oil-change-settings/oil-change-settings.module';
import { ChargementSettingsModule } from './client-management/alert-management/alert-settings/chargement_settings/chargement-settings.module';
import { ChomagSettingsModule } from './client-management/alert-management/alert-settings/chomag_settings/chomag-settings.module';
import { NonPoiSettingsModule } from './client-management/alert-management/alert-settings/non-poi-settings/non-poi-settings.module';
import { IgnitionSettingsModule } from './client-management/alert-management/alert-settings/ignition-settings/ignition-settings.module';
import { DocumentSettingsModule } from './client-management/alert-management/alert-settings/document-settings/document-settings.module';
import { CronSettingsModule } from './client-management/alert-management/alert-settings/cron-settings/cron-settings.module';
import { BrakeSettingsModule } from './client-management/alert-management/alert-settings/brake-settings/brake-settings.module';
import { BatterySettingsModule } from './client-management/alert-management/alert-settings/battery-settings/battery-settings.module';
import { BatteriesSettingsModule } from './client-management/alert-management/alert-settings/batteries-settings/batteries-settings.module';
import { AlertNotificationsModule } from './client-management/alert-management/alert-notifications/alert-notifications.module';
import { ClientManagementModule } from './client-management/client-management.module';
import { ClassificationSpeedCartoChartModule } from './client-management/activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-speed-carto-chart/classification-speed-carto-chart.module';
import { ActivityBtntModule } from './client-management/activity-overview-management/activity-btnt/activity-btnt.module';
import { CarburantDashbordModule } from './client-management/parc-management/carburant/carburant-dashbord/carburant-dashbord.module';
import { PositionByPoiModule } from './client-management/activity-overview-management/position-by-poi/position-by-poi.module';
import { VehiculeAccordingPoiModule } from './client-management/overview/vehicule-according-poi/vehicule-according-poi.module';
import { ActivityOverviewModule } from './client-management/activity-overview-management/activity-overview/activity-overview.module';
import { ValidationFormModule } from './client-management/charges-pfb/parametrage/validation-form/validation-form.module';
import { ValidatorSettingsFormModule } from './client-management/charges-pfb/parametrage/validator-settings/validator-settings-form/validator-settings-form.module';
import { ValidatorSettingsModule } from './client-management/charges-pfb/parametrage/validator-settings/validator-settings.module';
import { PassageFormModule } from './client-management/charges-pfb/parametrage/passage/passage-form/passage-form.module';
import { PassageModule } from './client-management/charges-pfb/parametrage/passage/passage.module';
import { CimentRotationModule } from './client-management/ciment-management/ciment-rotation/ciment-rotation.module';
import { CimentMissionHistoryModule } from './client-management/ciment-management/ciment-mission/ciment-mission-history/ciment-mission-history.module';
import { CimentMissionModule } from './client-management/ciment-management/ciment-mission/ciment-mission.module';
import { CimentMissionTrackingModule } from './client-management/ciment-management/ciment-mission/ciment-mission-tracking/ciment-mission-tracking.module';
import { CimentManagementModule } from './client-management/ciment-management/ciment-management.module';
import { PathPoiModule } from './client-management/historical/path-poi/path-poi.module';
import { MileageModule } from './client-management/historical/mileage/mileage.module';
import { PathModule } from './client-management/historical/path/path.module';
import { StopModule } from './client-management/historical/stop/stop.module';
import { PoiModule } from './client-management/data-management/poi/poi.module';
import { PoiFormModule } from './client-management/data-management/poi/poi-form/poi-form.module';
import { ChargesPfbModule } from './client-management/charges-pfb/charges-pfb.module';
import { TransportTypeModule } from './client-management/charges-pfb/parametrage/transport-type/transport-type.module';
import { TransportTypeFormModule } from './client-management/charges-pfb/parametrage/transport-type/transport-type-form/transport-type-form.module';
import { MissionModule } from './client-management/data-management/mission/mission.module';
import { GroupFormModule } from './client-management/data-management/group/group-form/group-form.module';
import { GroupModule } from './client-management/data-management/group/group.module';
import { VehiculeFormModule } from './client-management/data-management/vehicule/vehicule-form/vehicule-form.module';
import { VehiculeModule } from './client-management/data-management/vehicule/vehicule.module';
import { DriverFormModule } from './client-management/data-management/driver/driver-form/driver-form.module';
import { DriverModule } from './client-management/data-management/driver/driver.module';
import { DriverChargeListModule } from './client-management/parc-management/driver-charge/driver-charge-list/driver-charge-list.module';
import { DriverChargeModule } from './client-management/parc-management/driver-charge/driver-charge.module';
import { MultiTypeSettingsModule } from './client-management/parc-management/parc-settings/multi-type-settings/multi-type-settings.module';
import { ProviderSettingsModule } from './client-management/parc-management/parc-settings/provider-settings/provider-settings.module';
import { ParcSettingsModule } from './client-management/parc-management/parc-settings/parc-settings.module';
import { VidangeModule } from './client-management/parc-management/vidange/vidange.module';
import { TireModule } from './client-management/parc-management/maintenance/tire/tire.module';
import { ReparationModule } from './client-management/parc-management/maintenance/reparation/reparation.module';
import { BatteryModule } from './client-management/parc-management/maintenance/battery/battery.module';
import { MaintenanceModule } from './client-management/parc-management/maintenance/maintenance.module';
import { ParcManagementModule } from './client-management/parc-management/parc-management.module';
import { LossesModule } from './client-management/parc-management/losses/losses.module';
import { DocumentCirculationModule } from './client-management/parc-management/document-circulation/document-circulation.module';
import { ClocationModule } from './client-management/parc-management/divers/clocation/clocation.module';
import { CaRealizationModule } from './client-management/parc-management/divers/ca-realization/ca-realization.module';
import { TollModule } from './client-management/parc-management/divers/toll/toll.module';
import { ParkingModule } from './client-management/parc-management/divers/parcking/parcking.module';
import { LavageModule } from './client-management/parc-management/divers/lavage/lavage.module';
import { DiversModule } from './client-management/parc-management/divers/divers.module';
import { ImportExportModule } from './client-management/import-export/import-export.module';
import { CanalExportModule } from './client-management/canal-performance/canal-export/canal-export.module';
import { CanalTypeModule } from './client-management/canal-performance/parametrage-canal/canal-type/canal-type.module';
import { CanalTypeFormModule } from './client-management/canal-performance/parametrage-canal/canal-type/canal-type-form/canal-type-form.module';
import { CanalPerformanceModule } from './client-management/canal-performance/canal-performance.module';
import { VehiculeDetailsModule } from './client-management/data-management/vehicule/vehicule-form/vehicule-details/vehicule-details.module';
import { CimentFormModule } from './client-management/ciment-management/ciment-mission/ciment-form/ciment-form.module';
import { ScheduleChartModule } from './client-management/charges-pfb/schedule/schedule-chart/schedule-chart.module';
import { PassageChartModule } from './client-management/charges-pfb/schedule/passage-chart/passage-chart.module';
import { ChartMultipleModule } from './client-management/canal-performance/charts/chart-multiple/chart-multiple.module';
import { GroupElementModule } from './client-management/realtime/components/groups-container/group-element/group-element.module';
import { GroupItemButtonsModule } from './client-management/realtime/components/groups-container/group-item-buttons/group-item-buttons.module';
import { GroupItemModule } from './client-management/realtime/components/groups-container/group-item/group-item.module';
import { RunningDocumentChartModule } from './client-management/overview/dashbord-chart/running-document-chart/running-document-chart.module';
import { ClassificationHourActivityChartModule } from './client-management/activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-hour-activity-chart/classification-hour-activity-chart.module';
import { ClassificationKMChartModule } from './client-management/activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-kmchart/classification-kmchart.module';
import { ClassificationSinistreChartModule } from './client-management/activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-sinistre-chart/classification-sinistre-chart.module';
import { ClassificationOffenseChartModule } from './client-management/activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-offense-chart/classification-offense-chart.module';
import { ClassificationDriverTurnOverChartModule } from './client-management/activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-driver-turn-over-chart/classification-driver-turn-over-chart.module';
import { AlertCustomizeModule } from './client-management/alert-management-new/alert-customize/alert-customize.module';
import { AuthentificationModule } from './authentification/authentification.module';
import { FooterModule } from './footer/footer.module';
import { RentalAgreementManagementModule } from './client-management/rental-agreement-management/rental-agreement-management.module';
import { RightSidebarModule } from './right-sidebar/right-sidebar.module';
import { CurrentStateMoodalModule } from './client-management/overview/current-state/current-state-moodal/current-state-moodal.module';
import { VehiculeDetailModule } from './client-management/data-management/vehicule/vehicule-details/vehicule-details.module';
import { TimediffPipeModule } from './pipes/timediff.pipe.module';
import { CurrentStateModule } from './client-management/overview/current-state/current-state.module';
import { PositiveNumberPipeModule } from './pipes/positive-number.pipe.module';
import { OrderPipModule } from './pipes/order-by.pipe.module';
import { DiverManagementModule } from './client-management/parc-management/divers/diver-management/diver-management.module';
import { GroupFilterModule } from './pipes/group-filter.module';
import { MissionInfoTrackingModule } from './client-management/charges-pfb/mission-info-tracking/mission-info-tracking.module';
import { DurationPipeModule } from './pipes/duration.module';
import { FormatNumberModule } from './pipes/format-number.module';
import { NoValuePipeModule } from './pipes/no-value.module';
import { ReverseModule } from './pipes/revers.module';
import { RoundPipeModule } from './pipes/round.module';
import { DataManagementModule } from './client-management/data-management/data-management.module';
import { StopsModule } from './client-management/realtime/components/control-options/tool-bar/stops/stops.module';
import { ToolKmJourModule } from './client-management/realtime/components/control-options/tool-bar/km-jr/tool-km-jour.module';
import { KmJrTrajetParentModule } from './client-management/realtime/components/control-options/tool-bar/km-jr/km-jour-trajet-parent/km-jour-trajet-parent.module';

import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';

declare var require: any;

export function highchartsFactory() {
  const hc = require('highcharts');
  const hcm = require('highcharts/highcharts-3d');
  const exp = require('highcharts/modules/exporting');
  const hm = require('highcharts/highcharts-more');
  const st = require('highcharts/modules/stock');

  hcm(hc);
  exp(hc);
  //sg(hc);
  st(hc);
  hm(hc);
  return hc;
}

let options: any = {
  animate: 'flyRight',
  positionClass: 'toast-top-right',
  showCloseButton: true,
};

export const HTTP_NOAUTH = new InjectionToken('http_noauth');




@NgModule({
    declarations: [
        AppComponent,
        StateFilterDirective,
    ],
    imports: [
        ChartArretPoiModule,
        NumeroOtModule,
        DailyKilomtrageChartModule,
        InterventionFormModule,
        HeaderModule,
        SuiviChargeModule,
        ChargesPfbHistoryModule,
        FuelingPriceSettingModule,
        VisitFormModule,
        DashbordModule,
        GeoMarketingFormModule,
        FuelSettingsModule,
        EmailManagerModule,
        SigninModule,
        VehicleClassificationModule,
        ActivityVehicleClassificationFormModule,
        ActivityVehicleClassificationModule,
        StopChartModule,
        KilomtrageChartModule,
        ActivityTimeChartModule,
        SchedulingFormModule,
        ScheduleModule,
        BreakDownFormModule,
        ActivityChartModule,
        CimentMissionScheduleModule,
        CimentDashboardModule,
        ChargesPfbTrackingModule,
        ChargesPfbFormModule,
        MissionTrackingModule,
        DriverChargeFormModule,
        CarburantTemplateSettingModule,
        MultiTypeSettingsFormModule,
        ProviderSettingsFormModule,
        TireFormModule,
        ReparationFormModule,
        BatteryFormModule,
        VidangeFormModule,
        BrakeFormModule,
        SinistreFormModule,
        SinistresModule,
        OffenseFormModule,
        LeasingModule,
        BrakeModule,
        OffensesModule,
        DocumentFormModule,
        CarburantFormModule,
        SuiviCanalModule,
        HistoryCanalGroupVehiculeModule,
        ToolBarModule,
        SearchBarModule,
        CarburantModule,
        QualityDrivingModule,
        MaintenanceChargeModule,
        // CorrelationMaintenanceModule,
        // CorrelationLocationModule,
        MonthlyAverageCostOfFuelConsumptionChartModule,
        MonthlyMaintenanceCostsModule,
        MonthlyAvailableVehiculesChartModule,
        AverageCostOfFuelConsumptionChartModule,
        TurnoverAchievedModule,
        LocationCostModule,
        // CorrelationGasoilModule,




        RotationChargeModule,
        FuelingChargeModule,
        ReportSentModule,
        VidangeChartModule,
        DriverMissionFormModule,
        DriverMissionManagementModule,
        InterventionListModule,
        LeafletModule,
        IMileageDataModule,
        SupervisorModule,
        SupervisorFormModule,
        ParametrageCanalModule,
        CanalPfbFormModule,
        CanalPfbModule,
        ParametrageModule,
        TonnageModule,
        ControlOptionsModule,
        GroupsContainerModule,
        PerformanceModule,
        MissionTrackingInfoModule,
        DeadlinesModule,
        MissionReturnTrackingModule,
        ExpensesModule,
        ActivityModule,
        TmmModule,
        TravelReportModule,
        AffectationManagementModule,
        GlobalOverviewManagementModule,
        CimentOverviewModule,
        CimentSiteFormModule,
        CimentSitesModule,
        AlertNewModule,
        AlertManagementNewModule,
        DrivingQualityModule,
        ActivityOverviewManagementModule,
        ProgramFormModule,
        ProgramModule,
        OverviewModule,
        PoiClientModule,
        HelpModule,
        VisitTrackingModule,
        VisitModule,
        GeoMarketingTrackingModule,
        GeoMarketingModule,
        FuelDataModule,
        TechnicalDataModule,
        StatisticalModule,
        AccountFormModule,
        AccountManagementModule,
        HistoricalModule,
        DashboardModule,
        RealTimeModule,
        AlertManagementModule,
        RemisageSettingsModule,
        SpeedCartoSettingsModule,
        ApproFuelSettingsModule,
        ActivityDailySettingsModule,
        ActivitySettingsModule,
        ActivityHourSettingsModule,
        ConsumptionSettingsModule,
        AlertSettingsModule,
        ZoneSettingsModule,
        TowingSettingsModule,
        TireSettingsModule,
        SpeedSettingsModule,
        PoiSettingsModule,
        OilChangeSettingsModule,
        ChargementSettingsModule,
        ChomagSettingsModule,
        NonPoiSettingsModule,
        IgnitionSettingsModule,
        DocumentSettingsModule,
        CronSettingsModule,
        BrakeSettingsModule,
        BatterySettingsModule,
        BatteriesSettingsModule,
        AlertNotificationsModule,
        ClientManagementModule,
        ClassificationSpeedCartoChartModule,
        ActivityBtntModule,
        CarburantDashbordModule,
        PositionByPoiModule,
        VehiculeAccordingPoiModule,
        ActivityOverviewModule,
        ValidationFormModule,
        ValidatorSettingsFormModule,
        ValidatorSettingsModule,
        PassageFormModule,
        PassageModule,
        CimentRotationModule,
        CimentMissionHistoryModule,
        CimentMissionModule,
        CimentMissionTrackingModule,
        CimentManagementModule,
        PathPoiModule,
        MileageModule,
        PathModule,
        StopModule,
        PoiModule,
        PoiFormModule,
        ChargesPfbModule,
        TransportTypeModule,
        TransportTypeFormModule,
        MissionFormModule,
        MissionModule,
        GroupFormModule,
        GroupModule,
        VehiculeFormModule,
        VehiculeModule,
        DriverFormModule,
        DriverModule,
        DriverChargeListModule,
        DriverChargeModule,
        MultiTypeSettingsModule,
        ProviderSettingsModule,
        ParcSettingsModule,
        VidangeModule,
        TireModule,
        ReparationModule,
        BatteryModule,
        MaintenanceModule,
        ParcManagementModule,
        LossesModule,
        DocumentCirculationModule,
        ClocationModule,
        CaRealizationModule,
        TollModule,
        ParkingModule,
        LavageModule,
        DiversModule,
        ImportExportModule,
        CanalExportModule,
        CanalTypeModule,
        CanalTypeFormModule,
        CanalPerformanceModule,
        HistorySuiviChargeModule,
        VehiculeDetailsModule,
        CimentFormModule,
        ScheduleChartModule,
        PassageChartModule,
        ChartMultipleModule,
        GroupElementModule,
        GroupItemButtonsModule,
        GroupItemModule,
        RunningDocumentChartModule,
        ClassificationHourActivityChartModule,
        ClassificationKMChartModule,
        ClassificationSinistreChartModule,
        ClassificationOffenseChartModule,
        ClassificationDriverTurnOverChartModule,
        AlertCustomizeModule,
        AuthentificationModule,
        FooterModule,
        RentalAgreementManagementModule,
        RightSidebarModule,
        CurrentStateMoodalModule,
        VehiculeDetailModule,
        TimediffPipeModule,
        CurrentStateModule,
        PositiveNumberPipeModule,
        OrderPipModule,
        DiverManagementModule,
        GroupFilterModule,
        MissionInfoTrackingModule,
        DurationPipeModule,
        FormatNumberModule,
        NoValuePipeModule,
        ReverseModule,
        RoundPipeModule,
        RouterModule,
        BrowserModule,
        HighchartsChartModule,
        NgxChartsModule,
        MomentModule,
        CommonModule,
        DataManagementModule,
        StopsModule,
        ToolKmJourModule,
        FormsModule,
        ModalModule.forRoot(),
        NgxDaterangepickerMd.forRoot(),
        BsDatepickerModule.forRoot(),
        AppRoutingModule,
        ToastrModule.forRoot({
            maxOpened: 4,
            timeOut: 10000,
            autoDismiss: true,
            positionClass: 'toast-bottom-right',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ReactiveFormsModule,
        HttpClientModule,
        NgxLoadingModule.forRoot({}),
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
        BrowserAnimationsModule,
        AvatarModule,
        MatCheckboxModule,
        FilterPipeModule,
        // SelectModule,
        DndModule.forRoot(),
        MatTooltipModule,
        BsDatepickerModule.forRoot(),
        DatepickerModule,
        HttpClientModule,
        MatSlideToggleModule,
        AngularMultiSelectModule,
        NgMultiSelectDropDownModule.forRoot(),
        Ng2SmartTableModule,
        NgxDatatableModule,
        MatTableModule,
        MatExpansionModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatMenuModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
    ],
    providers: [
        Title,
        DecimalPipe,
        DatePipe,
        MapService,
        RealtimeRestService,
        RealtimeHelperService,
        RealtimeService,
        GeocodingService,
        DataManagementService,
        PathService,
        OverviewService,
        MileageService,
        TechnicalDataService,
        VehiculeService,
        GroupService,
        DriverService,
        DocumentCirculationService,
        ParentService,
        ProviderService,
        FuelingService,
        OilChangeService,
        TireService,
        BatteryService,
        ImportExportService,
        AlertManagementServiceUtils,
        ChartConfigService,
        ReparationService,
        DashbordService,
        HeaderService,
        EmailsService,
        BrakeService,
        LossesService,
        MultiTypeService,
        DriverChargeService,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        SigninService,
        AuthGuard,
        accountGuards.RealTimeGuard,
        accountGuards.AccountManagementGuard,
        accountGuards.AlertGuard,
        accountGuards.DataManagementGuard,
        accountGuards.OverviewGuard,
        accountGuards.HistoricalGuard,
        accountGuards.ParcMangementGuard,
        accountGuards.ImportExportGuard,
        accountGuards.StatisticalGuard,
        accountGuards.FuelGuard,
        accountGuards.ActivityOverview,
        accountGuards.GeoMarketing,
        accountGuards.ActivityBtnt,
        accountGuards.CimentManagement,
        accountGuards.MissionGuard,
        accountGuards.RentalAgreementGuard,
        accountGuards.GlobalOverviewManagement,
        accountGuards.CanalPerformanceManagement,
        accountGuards.AffectationManagement,
        accountGuards.TransportMonitoringMaintenance,
        AlertManagementService,
        InterventionService,
        MissionService,
        VidangeChartService,
        DepenseChartService,
        SinistreChartService,
        RunningDocumentChartService,
        ActivityChartService,
        StopChartService,
        ActivityTimeChartService,
        PositionByPoiService,
        GeoMarketingService,
        KilomtrageChartService,
        AverageCostOfFuelConsumptionChartService,
        MonthlyAvailableVehiculesService,
        MonthlyMaintenanceCostsService,
        TheoreticalCostService,
        ChargesPfbService,
        TransportTypeService,
        ScheduleService,
        PassageService,
        PesageService,
        ValidatorSettingsService,
        ValidationService,
        CimentMissionService,
        SpiderChartComponent,
        ActivityVehiculeClassificationsService,
        AlertManagementNewService,
        RentalAgreementManagementService,
        CimentSiteService,
        FuelingTemplateSettingService,
        FuelingPriceSettingService,
        AffectationChargeService,
        CanalPfbService,
        CanalService,
        {
            provide: HTTP_NOAUTH,
            deps: [HttpBackend],
            useFactory: (handler: HttpBackend) => {
                return new HttpClient(handler);
            },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
