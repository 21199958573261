<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<section>
      <div class="card">
        <div class="card-header">
          <i class="fa fa-car"></i> <span>Tournée |</span>

          <button type="button" class="btn btn-success btn-sm " title="Ajouter"
          (click)="openGeoMarketingAssignmentForm()" >Ajouter  <i class="fa fa-plus-circle"></i> </button>
        </div>
        <!-- CARD BODY -->
        <div class="card-body p-0">
          <div class="app">
            <div class="main">
              <div class="container p-0">

                <div class="messages">
                  <form>
                    <div class="inner-addon left-addon">
                        <i class="fa fa-search"></i>
                        <input type="search" class="search" placeholder="Rechercher..." #searchTerm />
                    </div>
                  </form>

                     <!-- GEOMARKETING ITEMS -->
                    <ul class="message-list">
                      <li *ngFor="let geo of geoMarketing | filterBy : {label : searchTerm.value}" [ngClass]="{'active': selectedGeoMarketing?.id == geo.id}" (click)="selectedGeoMarketing = geo">
                        <div class="preview">
                          <h5>{{geo?.label}} </h5>
                          <p>Vehicule: {{geo?.vehicule?.matricule}}</p>
                        </div>
                      </li>
                    </ul>
                    <!-- END GEOMARKETING ITEMS -->
                  </div>

                  <!-- GEOMARKETING BODY -->
                  <section *ngIf="selectedGeoMarketing" class="message">
                    <h3>{{selectedGeoMarketing?.label}}</h3>

                    <div class="option-add">
                      <span title="Modifier" (click)="onUpdate(selectedGeoMarketing)"><i class="fa fa-edit"></i> Modifier</span>
                  </div>

                    <div class="option-delete">
                        <span title="Supprimer" (click)="onConfirmDelete()"><i class="fa fa-minus-circle"></i> Supprimer</span>
                    </div>

                    <div class="meta-data">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>Date Début</th>
                                    <th>Date Fin</th>
                                    <th>Lundi</th>
                                    <th>Mardi</th>
                                    <th>Mercredi</th>
                                    <th>Jeudi</th>
                                    <th>Vendredi</th>
                                    <th>Samedi</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                  <td><strong>{{selectedGeoMarketing?.dateDebut | date:'dd-MM-yy, HH:mm'}}</strong></td>
                                    <td> <strong>{{selectedGeoMarketing?.dateFin | date:'dd-MM-yy, HH:mm'}}</strong></td>

                                   <td><strong *ngIf="selectedGeoMarketing?.monday">{{selectedGeoMarketing?.geoMarketingMn?.label}}</strong></td>
                                   <td><strong *ngIf="selectedGeoMarketing?.tuesday">{{selectedGeoMarketing?.geoMarketingTu?.label}}</strong></td>
                                   <td><strong *ngIf="selectedGeoMarketing?.wednesday">{{selectedGeoMarketing?.geoMarketingWd?.label}}</strong></td>
                                   <td><strong *ngIf="selectedGeoMarketing?.thursday">{{selectedGeoMarketing?.geoMarketingTr?.label}}</strong></td>
                                   <td><strong *ngIf="selectedGeoMarketing?.friday">{{selectedGeoMarketing?.geoMarketingFr?.label}}</strong></td>
                                   <td><strong *ngIf="selectedGeoMarketing?.saturday">{{selectedGeoMarketing?.geoMarketingSt?.label}}</strong></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                  </section>
                  <!-- END GEOMARKETING BODY -->
                </div>
            </div>
          </div>
        </div>
        <!-- END CARD BODY -->
      </div>
  </section>



<!-- ========================================== DashBord Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #geoMarketingAssignmentFormModal="bs-modal" bsModal role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- MODAL HEADER -->
            <div class="modal-header">
                <button type="button" class="close" (click)="marketingAssignmentFormModal()">&times;</button>
                <h4  *ngIf="mode=='ADD'" class="modal-title"><i class="fa fa-plus-circle" aria-hidden="true"></i> Nouvelle Tournée</h4>
                <h4 *ngIf="mode=='UPDATE'" class="modal-title"><i class="fa fa-edit" aria-hidden="true"></i> Modifier Tournée</h4>

            </div>
            <!-- END MODAL HEADER -->

            <!-- MODAL BODY -->
            <div class="modal-body">
              <div class="box-body">
                <app-visit-form [mode]="mode" (geoMarketingAssignmentSaved)="onGeoMarketingSaved($event)" (geoMarketingAssignmentUpdated)="onGeoMarketingUpdated($event)"  [geoMarketingAssignment]="geoMarketingAssignment"></app-visit-form>
              </div>
            </div>
            <!-- END MODAL BODY -->

            <!-- MODAL FOOTER -->
            <div class="box-footer">
              <button (click)="marketingAssignmentFormModal()" class="btn btn-default pull-right">
                Fermer
              </button>
            </div>
            <!-- END MODAL FOOTER -->

        </div>
    </div>
</div>
<!-- ========================================== DashBord Modal (END) ====================================================-->


<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" tabindex="-1" #geoMarketingAssignmentDeleteModal="bs-modal" bsModal role="dialog" >
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" (click)="geoMarketingAssignmentDeleteModal.hide()">&times;</button>
              <h4 class="modal-title">
                  <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
              </h4>
          </div>
          <div class="modal-body">
              <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                  <h4 style="color: white">
                      <i class="fa fa-exclamation-triangle"></i> Suppression !</h4>
                  <span>Êtes-vous sûr de supprimer la tournée ?</span>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-default" (click)="geoMarketingAssignmentDeleteModal.hide()">
                  Annuler
              </button>
              <button type="button" class="btn btn-success" (click)="onDelete()">
                  <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
              </button>
          </div>
      </div>
  </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
