import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DashbordService, DeadlinesGroupsDto} from '../../parc-management';
import { DashbordInfo } from '../../parc-management';
import { RealtimeRestService } from '../../realtime/services/realtime-rest.service';
import {CurrentStateService} from "./current-state.service";
import {DeadlinesService} from "../../parc-management/deadlines/deadlines.service";
import {DriveQualityAlerts} from "../../data-management";
import { Notification } from "../../../client-management/alert-management/alert";
import {Subscription, interval} from "rxjs";
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as Highcharts from 'highcharts';
import { DatePipe } from '@angular/common';
import { User } from '../../../authentification/signin/credentials';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts)

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
    selector: 'app-current-state',
    templateUrl: './current-state.component.html',
    styleUrls: ['./current-state.component.css']
})





export class CurrentStateComponent implements OnInit {


  options: Object | null = null;

    currentState: any = {};
    stateDocs: any = {
        ENGINE_OIL_F: [],
        ENGINE_OIL_F_outdated: [],
        INSURANCE: [],
        INSURANCE_outdated: [],
        VISIT: [],
        VISIT_outdated: [],
        LEASING: [],
        LEASING_outdated: [],
        EMBRAYAGE: [],
        EMBRAYAGE_outdated: [],
        DISTRIBUTION: [],
        DISTRIBUTION_outdated: [],
        ENGINE_OIL__BOITEPONT_F: [],
        ENGINE_OIL__BOITEPONT_F_outdated: [],
        OIL_F: [],
        OIL_F_outdated: [],
        AIR_F: [],
        AIR_F_outdated: [],
        FUEL_F: [],
        FUEL_F_outdated: [],
        TIRE: [],
        TIRE_outdated: [],
    };

    vitesseAlert : DriveQualityAlerts;
    accAlert : DriveQualityAlerts;
    deriveeAlert : DriveQualityAlerts;
    freinageAlert : DriveQualityAlerts;


    showDetails1: boolean = false;
    showDetails2: boolean = false;
    showDetails3: boolean = false;
    showDetails4: boolean = false;
    showDetails5: boolean = false;

    loadingState: boolean = false;
    loadingCostDetails: boolean = false;
    costDetails: DashbordInfo | null = null;
    totalofpositions: number;
    deadlines = {};


    status: any;
  currentStartState: any[] = [];
  currentStopState: any[] = [];
  currentTechnicalIssue: any[] = [];


  currentStartStateLength = 0;
    currentStopStateLength = 0;
    currentTechnicalIssueLength = 0;
    rows: DeadlinesGroupsDto[] = new Array();
    type: string ='';
    unite: string ='';



    notifications: Notification[] = [];
    notificationsVitesse: Notification[] = [];
    notificationsPOI: Notification[] = [];
    notificationVidange: Notification[] = [];
    notificationVidangeAfter: Notification[] = [];
    notificationVidangeBefor: Notification[] = [];
    notificationsDoc: Notification[] = [];
    notificationsHuileMoteurAfter: Notification[] = [];
    notificationsHuileMoteurBefor: Notification[] = [];
    notificationAssurance: Notification[] = [];
    notificationVisiteTechnique: Notification[] = [];


    notificationsAssuranceMoteurAfter: Notification[] = [];
    notificationsAssuranceMoteurBefor: Notification[] = [];

    notificationsVisiteTechniqueAfter: Notification[] = [];
    notificationsVisiteTechniqueBefor: Notification[] = [];






    // km : string[] = ['fuel','air','oil','engine','pneu','embrayage','distribution'];
    js : string[] = ['fuel','air','oil','engine'];

    @Output()
    showDashboard: EventEmitter<DashbordInfo> = new EventEmitter<DashbordInfo>();

    @Input() show: boolean = false;


    @ViewChild("dashboardModal", { static: false }) dashboardModal: ModalDirective;
    @ViewChild("dashboardModalNotif", { static: false }) dashboardModalNotif: ModalDirective;

    constructor(
        public realtimeRestService: RealtimeRestService,
        private dashbordService: DashbordService,
        private currentStateService: CurrentStateService,
        private deadlinesService: DeadlinesService,
        private datePipe: DatePipe
        ) {
    }

    /** UPDATE TRACKING TIMER */
    updateTrackingTimer: number | null = null;
    updateTrackingTimerSubscription: Subscription |null= null;


    ngOnInit() {
        // load current state (vehicules state)!
        this.loadCurrentState();
        this.loadCostParc();
        let currentUser: User = JSON.parse(localStorage.getItem('currentUser') || '{}');

        //delet this error
            this.loadDeadlines();
            this.deadlinesService.getDeadlines();
        //end error ;

        this.loadDriveQuality();
        interval(180000).subscribe(() => {
            this.loadDriveQuality();
        });

        this.getNotification();


    }

    valueKm :number =0;

    getNotification(){
        if(localStorage.getItem('notifications')){
            this.notifications=JSON.parse(localStorage.getItem('notifications') || '{}');

            console.log("************0_0*************");
            console.log(this.notifications);
            console.log("*************************");


            this.notificationsVitesse = this.notifications.filter(
              nt => nt.name === 'SPEED' || nt.name === 'SPEED_CARTO'
            );
            this.notificationsVitesse = this.notifications.filter(
              nt => nt.name === 'SPEED' || nt.name === 'SPEED_CARTO'
            );

            this.notificationVidange =this.notifications.filter(nt => nt.name == 'VIDANGE');
            console.log(this.notificationVidange);

            this.notificationVidangeAfter = this.notificationVidange.filter(
              rt => {
                this.valueKm = Number(rt.alertValue)
                if(this.valueKm > 0){
                  return rt;
                }
              }
            );
            this.notificationsHuileMoteurAfter =  this.notificationVidangeAfter.filter(
              rt => {
                if(rt.message.includes("L'huile moteur")){
                  if(this.valueKm > 0){
                    return rt;
                  }
                }
              }
            )
            this.notificationsHuileMoteurBefor =  this.notificationVidangeAfter.filter(
              rt => {
                if(rt.message.includes("L'huile moteur")){

                  if(this.valueKm < 0){
                    return rt;
                  }
                }
              }
            )
            console.log(this.notificationsHuileMoteurAfter)
            console.log(this.notificationsHuileMoteurBefor)

            this.notificationsDoc =this.notifications.filter(nt => nt.name == "DOC");
            this.notificationAssurance =  this.notificationsDoc.filter(
              rt => {
                if(rt.message.includes("Assurance")){
                  return rt;
                }
              }
            )
            this.notificationVisiteTechnique =  this.notificationsDoc.filter(
              rt => {
                if(rt.message.includes("visite technique")){
                  return rt;
                }
              }
            )

            this.notificationsVisiteTechniqueAfter = this.notificationVisiteTechnique.filter(
              rt => {
                this.valueKm = Number(rt.alertValue)
                if(this.valueKm > 0){
                  return rt;
                }
              }
            );

            this.notificationsVisiteTechniqueBefor = this.notificationVisiteTechnique.filter(
              rt => {
                this.valueKm = Number(rt.alertValue)
                if(this.valueKm < 0){
                  return rt;
                }
              }
            );




            //
            this.notificationsAssuranceMoteurAfter = this.notificationAssurance.filter(
              rt => {
                this.valueKm = Number(rt.alertValue)
                if(this.valueKm > 0){
                  return rt;
                }
              }
            );

            this.notificationsAssuranceMoteurBefor = this.notificationAssurance.filter(
              rt => {
                this.valueKm = Number(rt.alertValue)
                if(this.valueKm < 0){
                  return rt;
                }
              }
            );






            this.notificationVidangeBefor = this.notificationVidange.filter(
              rt => {
                this.valueKm = Number(rt.alertValue)
                if(this.valueKm < 0){
                  return rt;
                }
              }
            );

            this.notificationsPOI=this.notifications.filter(nt => nt.name == 'POI')
            this.notificationsVitesse.slice().sort((a, b) => {
                if(a.createdAt > b.createdAt)
                    return 1;
                else
                    return -1
            });


        }

        this.startUpdateTrackingTimer();
        this.VitesseChart();
        this.PoiChart();
    }



    filterData(type: string) {
        if (this.js.find(js => js === type) === undefined) {
            this.unite = 'JS';
        } else {
            this.unite = 'KM';
        }
        this.type = type;
        this.rows = this.deadlinesService.rows
        this.rows = this.rows.filter(row => {
            return row[type] < 0;
        })
        const uniqueMatricules = {};
        this.rows = this.rows.filter(row => {
            if (!uniqueMatricules[row.matricule]) {
                uniqueMatricules[row.matricule] = true;
                return true;
            }
            return false;
        });
    }







    totalposition() {
        this.totalofpositions = 0;
        this.realtimeRestService.getVehiculesAccordingToPoi().subscribe(vehiculesAccordingToPoi => {
            this.totalofpositions = vehiculesAccordingToPoi.length;
        });
    }



    loadDriveQuality() {
        this.currentStateService.getDriveQualityAlerts().subscribe(alerts => {
            this.vitesseAlert = alerts.find((alert: { addedInfo: number; }) => alert.addedInfo === 236);
            this.accAlert = alerts.find((alert: { addedInfo: number; }) => alert.addedInfo === 234);
            this.deriveeAlert = alerts.find((alert: { addedInfo: number; }) => alert.addedInfo === 233);
            this.freinageAlert = alerts.find((alert: { addedInfo: number; }) => alert.addedInfo === 235);
        })
    }


    loadCurrentState() {
        this.loadingState = true;
        this.realtimeRestService.getCurrentState(false).subscribe(currentState => {
            this.currentState = currentState;

            var groupsStart = new Set(this.currentState.startState.map((item: { groupName: any; }) => item.groupName));
            var groupsStop = new Set(this.currentState.stopState.map((item: { groupName: any; }) => item.groupName));
            var groupsTechnicalIssue = new Set(this.currentState.technicalIssue.map((item: { groupName: any; }) => item.groupName));

            groupsStart.forEach(g=>{
                let startFilter = this.currentState.startState.filter((str: { groupName: unknown; }) => str.groupName === g)
                this.currentStartStateLength += startFilter.length;
                this.currentStartState.push({
                    name: g,
                    values: startFilter
                })
            })

            groupsStop.forEach(g=>{
                let stopFilter = this.currentState.stopState.filter((str: { groupName: unknown; }) => str.groupName === g)
                this.currentStopStateLength += stopFilter.length;
                this.currentStopState.push({
                    name: g,
                    values: stopFilter
                })
            })

            groupsTechnicalIssue.forEach(g=>{
                let technicalIssueFilter = this.currentState.technicalIssue.filter((str: { groupName: unknown; }) => str.groupName === g)
                this.currentTechnicalIssueLength += technicalIssueFilter.length;
                this.currentTechnicalIssue.push({
                    name: g,
                    values: technicalIssueFilter
                })
            })

            this.loadingState = false;
        }, (err) => {
            this.loadingState = false;
        });
    };

    loadCostParc() {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.loadingCostDetails = true;
        this.dashbordService.getDashbordInfo(-1, -1, {
            startDate: firstDay.getTime(),
            endDate: lastDay.getTime()
        }).subscribe(value => {
            this.costDetails = value;
            this.loadingCostDetails = false;
        }, (err) => {
            this.loadingCostDetails = false;
        });
    };

    displayDashboard(currentmd: number) {
        this.showDashboard.emit(this.costDetails);
    }


    loadDeadlines() {
        this.currentStateService.getDeadline().subscribe(deadlines => {

          console.log("**********************")
          console.log(deadlines)
            for (const key in deadlines) {
                if (deadlines.hasOwnProperty(key)) {
                    const deadline = deadlines[key];
                    for (const subKey in deadline) {
                        if (deadline.hasOwnProperty(subKey)) {
                            const deadLines = deadline[subKey];

                            for (const mainKey in deadLines) {
                                if (deadLines.hasOwnProperty(mainKey)) {
                                    const Deadlines = deadLines[mainKey];
                                    const stateDocKey = `${mainKey}${Deadlines["currentValue"] > Deadlines["deadlineValue"] ? "_outdated" : ""}`;
                                    if (this.stateDocs.hasOwnProperty(stateDocKey)) {
                                        this.stateDocs[stateDocKey].push(Deadlines);
                                    } else {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    showGraph() {
        //this.configHighcharts(this.costDetails);
        this.dashboardModal.show();
    }

    showGraphNotif() {
        this.dashboardModalNotif.show();
    }

    /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 900;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        this.getNotification();
      }
    })
  }

  VitesseChart() {

    var today= new Date();
    this.setHighCartsLang();
    /** Create Pie Chart( depense chart ) **/
    var series = [];
    var seriesData: any[] = [];
    var drilldownSeries: { type: string; threshold: number[]; name: any; id: any; data: { name: string; y: number; cust: string; }[]; }[] = [];

    const matriculeCounts = {};
    let max=0;

    this.notificationsVitesse.forEach((item) => {
      const matricule = item.matricule;
      if (matriculeCounts[matricule]) {
        matriculeCounts[matricule]++;
      } else {
        matriculeCounts[matricule] = 1;
      }
      if(max<matriculeCounts[matricule]){
        max=matriculeCounts[matricule];
      }
    });

    seriesData = Object.keys(matriculeCounts).map((matricule) => ({
      name:matricule,
      y: matriculeCounts[matricule],
      drilldown:matricule
    }));

    seriesData.map(s_d=>{
      series=[];
      series=this.notificationsVitesse.filter(nt=>nt.matricule==s_d.name)
      var vehiculesdata: { name: string, y: number, cust: string  }[] = [];
        for (let i = 0; i < series.length; i++) {
            const timestamp = series[i].createdAt; // Milliseconds since epoch
            const date = new Date(timestamp);

            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            // Format hours and minutes to ensure they have leading zeros if needed
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');

            const timeString = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            vehiculesdata.push({name: timeString,  y: parseInt(series[i].alertValue), cust:series[i].alertValue});
      }

      //series.map(s=>vehiculesdata.push([s.createdAt,s.alertValue]));
      drilldownSeries.push({type: "line",threshold: [40, 60, 80, 100],name:s_d.name,id:s_d.name,data: vehiculesdata})
    })

    seriesData.sort((a, b) => {
        if(a.y < b.y)
            return 1;
        else
            return -1
    });

    this.options= {
      chart: {
          type: 'column',
      },
      title: {
          align: 'left',
          //text: 'Excès de vitesse '+today
          text: ''
      },
      subtitle: {
          align: 'left',
          //text: 'Clicker sur les column pour voir plus details'
      },
      accessibility: {
          announceNewData: {
              enabled: true
          }
      },
      xAxis: {
          type: 'category'
      },
      yAxis: {
          title: {
              text: 'Nombre Excès de vitesse / Vehicule'
          }

      },
      legend: {
          enabled: false
      },
      plotOptions: {
          series: {
              borderWidth: 0,
            //   dataLabels: {
            //       enabled: true,
            //       format: '{point.y:1f}',
            //       textDocoration:'none'
            //   }
          }
      },

      tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
      },

      series: [
          {
              name: 'Matricule',
              colorByPoint: true,
              data: seriesData
          }
      ],
      drilldown: {
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b>: {point.y}Km',
        },
        breadcrumbs: {
            position: {
                align: 'right'
            }
        },
        series: drilldownSeries
      }
  }

    Highcharts.chart('chartVitesse', this.options);


  }

  PoiChart() {

    this.setHighCartsLang();
    /** Create Pie Chart( depense chart ) **/
    var series: string | any[] = [];
    var seriesData = [];
    var drilldownSeries: { type: string; name: string; id: string; data: { name: string; y: number; cust: string; }[]; }[] = [];

    const PoiCounts = {};

    this.notificationsPOI.forEach((item) => {
      const mark = item.subMark;
      if (PoiCounts[mark]) {
        PoiCounts[mark]++;
      } else {
        PoiCounts[mark] = 1;
      }
    });

    seriesData = Object.keys(PoiCounts).map((mark) => ({
      name:mark,
      y: PoiCounts[mark],
      drilldown:mark
    }));

    seriesData.map(s_d=>{
      series=[];
      series=this.notificationsPOI.filter(nt=>nt.subMark==s_d.name)
      var vehiculesdata: { name: string, y: number, cust: string  }[] = [];
      for (let i = 0; i < series.length; i++) {
        const nbr=this.notificationsPOI.filter(nt=>nt.matricule==series[i].matricule)
        vehiculesdata.push({name: series[i].matricule,  y: nbr.length, cust:series[i].matricule});
      }
      vehiculesdata.sort((a,b)=>{
        if(a.y<b.y)
        return 1
        else
        return -1
      })
      drilldownSeries.push({type: "column",name:s_d.name,id:s_d.name,data: vehiculesdata})
    })


    this.options= {
        chart: {
            type: 'pie'
        },
        title: {
            text: '',
            align: 'left'
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
        },
        plotOptions: {

        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            labels: {
              enabled: false,
              text: "Arret Poi / Vehicule"
            }
        },
        series: [
            {
                minPointSize: 10,
                innerSize: '20%',
                zMin: 0,
                name: 'groupes',
                borderRadius: 5,
                data: seriesData
            }
        ],
        drilldown: {
            tooltip: {
                headerFormat: '',
                pointFormat: '<b>{point.name}</b>: {point.y}Km',
            },
            breadcrumbs: {
                position: {
                    align: 'right'
                }
            },
            series: drilldownSeries
          }
    }

    Highcharts.chart('chartPoi', this.options);


  }

  // function for set lang hightcarts

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b',  '#00f194'
    ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

  }


}
