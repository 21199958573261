import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { dns, imagesPOIDir } from '../../global.config';
import {
  PointInterest,
  GroupDto,
  Group,
  Vehicule,
  Driver,
  VehiculesDtoWithGroup,
  PoiClient
} from './data-management.model';
import { createAuthorizationHeader } from '../../utils/security/headers';
import { MapService } from '../../utils/leaflet/service/map.service';
import * as L from 'leaflet';
import Polygon = L.Polygon;
import Marker = L.Marker;
import Icon = L.Icon;
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { User } from 'src/app/authentification/signin/credentials';
import { HttpClient } from '@angular/common/http';
import { ProgramService } from './providers/program.service';
import { DateInterval } from 'src/app/shared/model';
import { FormattedStopDto } from 'src/app/shared/model/sinistre.model';

@Injectable()
export class DataManagementService {
  pointInterests: PointInterest[] = null;
  pointClient: PoiClient[] = null;
  groups: Group[] = null;
  vehicules: Vehicule[] =[] ;

  private groupsSub: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);
  private regiogroupsSub: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);

  selectedGroup: Group = null;
  selectedVehicule: Vehicule = null;
  selectedSubmark: string = null;

  selectedGroup$ = new Subject<Group>();
  selectedVehicule$ = new Subject<Vehicule>();
  // selectedSubmark = new Subject<Vehicule>();

  constructor(private _http: HttpClient, private mapService: MapService, private programService: ProgramService) {}

  sendGroupWasSelected(group: Group) {
    this.selectedGroup$.next(group);
  }

  getGroupWasSelected(): Observable<Group> {
    return this.selectedGroup$.asObservable();
  }

  sendVehiculeWasSelected(vehicule: Vehicule) {
    this.selectedVehicule$.next(vehicule);
  }

  getVehiculeWasSelected(): Observable<Vehicule> {
    return this.selectedVehicule$.asObservable();
  }

  getGroupWasUpdated(): Observable<Group> {
    return this.selectedGroup$.asObservable();
  }

  getGroupsRegion(): Observable<Group[]> {
    return this.regiogroupsSub.asObservable();
  }
  setGroupsRegion(groups: Group[]) {
      return this.regiogroupsSub.next(groups);
  }
  getCurrentGroupsRegion(): Group[] {
      return this.regiogroupsSub.getValue();
  }

  getGroups(): Observable<Group[]> {
    return this.groupsSub.asObservable();
  }
  setGroups(groups: Group[]) {
      this.groupsSub.next(groups);
      this.setVehicules(this.getAllVehicles(groups));
  }
  getCurrentGroups(): Group[] {
      return this.groupsSub.getValue();
  }

  setVehicules(allVehicles: Vehicule[]){
    this.vehicules = allVehicles;
    this.setGroupsRegion(this.groupVehiculeIntoRegionGroup(allVehicles));
  }
  getAllVehicles(groups: Group[]): Vehicule[] {
    return groups.reduce((allVehicles: Vehicule[], group: Group) => {
      if (group.nom === 'Boitiers Semi Remorques') {
        return allVehicles;
      }
      return allVehicles.concat(group.vehicules);
    }, []);
  }

  getAllGroupsDetails(keyword: string): Observable<Group[]> {
    let headers = createAuthorizationHeader();
    let parms = '';
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    if (!currentUser.isRoot) {
      parms = '&id=' + currentUser.id;
    }
    return this._http
      .get<Group[]>(dns + 'groupes/details?keyword=' + keyword + parms, {
        headers: headers
      });
  }

  checkIfDeviceExists(groups: Group[], idDeviceToCheck: number): number {
    if (!groups || groups.length === 0) {
      return -1;
    }
    for (const group of groups) {
      for (const vehicule of group.vehicules) {
        if (vehicule.idDevice === idDeviceToCheck) {
          return group.idGroupe;
        }
      }
    }
    return -1;
  }

  getGroupLabels(): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.get<any>(dns + "groupes/labels", options);
  }

  getAllPointInterests(): Observable<PointInterest[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<PointInterest[]>(dns + 'pointInterests', { headers: headers });
  }

  getAllPointClients(): Observable<PoiClient[]> {
    let headers = createAuthorizationHeader();

    return this._http
      .get<PoiClient[]>(dns + 'pointClients', { headers: headers });
  }

  getAllPointInterestsByType(type : String): Observable<PointInterest[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<PointInterest[]>(dns + 'pointInterests/type/'+type, { headers: headers });
  }

  getListNamesOfPointInterests():Observable<PointInterest[]>{
    let headers = createAuthorizationHeader();
    return this._http
      .get<PointInterest[]>(dns + 'pointInterests/names', { headers: headers });
  }

  getListNamesOfPointInterestsByType(type : string):Observable<String[]>{
    let headers = createAuthorizationHeader();
    return this._http
      .get<String[]>(dns + 'pointInterests/type/'+ type +'/names', { headers: headers });
  }

  addPointInterest(pointInterest: PointInterest): Observable<PointInterest> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<PointInterest>(dns + 'pointInterests', pointInterest, { headers: headers });
  }

  addPointClient(pointClient: PoiClient): Observable<PoiClient> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<PoiClient>(dns + 'pointClients', pointClient, { headers: headers });
  }

  getListOfPointClientsByType(type : string):Observable<PoiClient[]>{
    let headers = createAuthorizationHeader();
    return this._http
      .get<PoiClient[]>(dns + 'pointClients/type/'+ type, { headers: headers });
  }

  getAllGroups(): Observable<GroupDto[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<GroupDto[]>(dns + 'groupes/minify', { headers: headers });
  }

  getCurrentOdo(id: number): Observable<number> {
    let headers = createAuthorizationHeader();
    return this._http.get<number>(dns + 'vehicules/currentOdo/' + id, { headers: headers });
  }

  updateVehiculeOffset(idvehicule: number, odo: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();

    return this._http
      .get<Boolean>(
        dns +
          'vehicules/updateOdoOffset?idVehicule=' +
          idvehicule +
          '&offset=' +
          odo,
        { headers: headers }
      );
  }

  // must exe only if pointInterests not NULL !!!
  getRelativePosition(lat: number, lng: number): string {
    let relativePosition = null;
    let mindistance=1000000;

    this.pointInterests.filter(p=>p.type==='MARKER').forEach(pointInterest => {
      let distance = this.distance(
        lat,
        lng,
        pointInterest.coordinate.lat,
        pointInterest.coordinate.lng
      );
      if(distance < mindistance){
        if (distance <= pointInterest.ray / 1000) {
          relativePosition = pointInterest.name;
        }else if (distance > pointInterest.ray / 1000 && distance < 0.2) {
          let distanceStr = distance.toString().substr(0, 4);
          relativePosition = 'à ' + distanceStr + ' Km de ' + pointInterest.name;
        }
        mindistance=distance;
      }
    });
    return relativePosition;
  }


  deletePointInterest(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete<Boolean>(dns + 'pointInterests/' + id, { headers: headers });
  }

  getStopsInfos(dateIntervalDto: DateInterval, idDevice: number): Observable<FormattedStopDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<FormattedStopDto>(dns + 'stops/stopJour/' + idDevice, dateIntervalDto, options);
  }


  deletePointClinet(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete<Boolean>(dns + 'pointClients/' + id, { headers: headers });
  }

  updatePointInterest(pointinterest: PointInterest): Observable<PointInterest> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    let body = JSON.stringify(pointinterest);
    return this._http
      .put<PointInterest>(dns + 'pointInterests/', pointinterest, options);
  }

  updatePointClient(pointclient: PoiClient): Observable<PoiClient> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    let body = JSON.stringify(pointclient);

    return this._http
      .put<PoiClient>(dns + 'pointClients/', pointclient, options);
  }

  updateVehiculeOdometre() {
    return 'ok';
  }

  distance(lat1: number, lon1: number, lat2: number, lon2: number) {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    var distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }

  getVehicule(idDevice: number): Vehicule {
    let foundVehicule = new Vehicule();
    if (this.groups) {
      for (let i = 0; i < this.groups.length; i++) {
        for (let j = 0; j < this.groups[i].vehicules.length; j++) {
          if (this.groups[i].vehicules[j].idDevice === idDevice) {
            foundVehicule = this.groups[i].vehicules[j];
            break;
          }
        }
      }
    }
    return foundVehicule;
  }

  /**
   * return nom complet du chauffeur
   * */
  getDriverName(driver: Driver) {
    if (driver) {
      if (driver.firstName && driver.lastName) {
        return driver.firstName + ' ' + driver.lastName;
      } else if (driver.firstName) {
        return driver.firstName;
      } else if (driver.lastName) {
        return driver.lastName;
      } else return 'anonyme';
    } else return 'pas de chauffeur';
  }

  /**
   * return surnom(if exist) si non retunr matricule !
   * */
  getVehiculeName(vehicule: Vehicule) {
    if (vehicule) {
      if (vehicule.alias) {
        return vehicule.alias;
      } else if (vehicule.matricule) {
        return vehicule.matricule;
      }
    }
  }

  /**
   * return number of vehicules
   * */
  getCountVehicule(groups: Group[]) {
    let count = 0;
    let element: number[] = [];
    if (groups)
      groups.forEach(group => {
        group.vehicules.forEach(vehicule => {
          element.push(vehicule.idDevice);
        });
      });
    var uniqueElements = element.filter(function(item, i, ar) {
      return ar.indexOf(item) === i;
    });
    count = uniqueElements.length;
    return count;
  }

  /*** vehicules service */
  getAllVehicules(): Observable<VehiculesDtoWithGroup[]> {
    let headers = createAuthorizationHeader();

    return this._http
      .get<VehiculesDtoWithGroup[]>(dns + 'vehicules/minify', { headers: headers });
  }

  getPointInterestById(id: number) {
    return this.pointInterests.filter(
      pointInterest => pointInterest.idPointInterest === id
    );
  }

  getPointClientsById(id: number){
    return this.pointClient.filter(
      pointClient => pointClient.idPointClient === id
    );
  }

  getPolygons(): PointInterest[] {
    return this.pointInterests.filter(
      pointInterest => pointInterest.type === 'POLYGON'
    );
  }

  getMarkers(): PointInterest[] {
    return this.pointInterests.filter(
      pointInterest => pointInterest.type === 'MARKER'
    );
  }
  groupVehiculeIntoRegionGroup(vehicles: Vehicule[]): Group[]{
    const groupsMap: Map<string | null, Vehicule[]> = new Map();
    groupsMap.set("_Aucune_Information", []);
    vehicles.forEach(vehicle => {
      const addressString = vehicle && vehicle.realTimeRecord && vehicle.realTimeRecord.address ? vehicle.realTimeRecord.address : "{}";
      let addressObj;
      try {
        addressObj = JSON.parse(addressString); 
      } catch (error) {
        console.error("Invalid address JSON", error);
        addressObj = {};  
      }
      const region = addressObj.rg ? addressObj.rg : "_Aucune_Information";
      const groupVehicles = groupsMap.get(region) || [];
      groupVehicles.push(vehicle);
      groupsMap.set(region, groupVehicles);
    });

    let idCounter = 1;
    const groups: Group[] = [];
    groupsMap.forEach((vehicles, region) => {
        const groupName = region ? region.toString() : "_Aucune_Information";
        const group = new Group(vehicles, groupName, undefined, idCounter++);
        groups.push(group);
    });
    return groups;
  }

  displayPointPolygon(pointInterest: PointInterest) {
    this.mapService.removeMarkersFromMap();
    this.mapService.removeMarkersPoiFromMap();
    this.mapService.removePolygonsPoiFromMap();

    let popup =
      "<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" +
      pointInterest.name +
      "</strong><br><hr><b>Adresse : " +
      pointInterest.address +
      "</b>";
    let marker :any = null;

    if (pointInterest.type == 'POLYGON') {
      let polygon = new Polygon(pointInterest.decode);
      this.mapService.addPolygonPoi(polygon);
    }

    marker = new Marker(pointInterest.coordinate);

    marker.on('click', () => {
      this.mapService.map.setView(pointInterest.coordinate, 17);
    });

    marker.on('mouseover', () => {
      marker.openPopup();
    });

    marker.on('add', () => {
      marker.openPopup();
    });

    marker.bindPopup(popup);
    marker.openPopup();
    marker.setIcon(
      new Icon({
        iconUrl: imagesPOIDir + pointInterest.imageUri,
        iconAnchor: [-2, 10],
        popupAnchor: [10, -25]
      })
    );
    this.mapService.addMarkerPoi(marker);
    var mapCenterMarker = new L.LatLng(
      pointInterest.coordinate.lat + 0.05,
      pointInterest.coordinate.lng
    );
    var mapCenterPolygin = new L.LatLng(
      pointInterest.coordinate.lat + 0.001,
      pointInterest.coordinate.lng
    );

    if (pointInterest.type == 'POLYGON') {
      this.mapService.map.setView(mapCenterPolygin, 17);
    } else {
      this.mapService.map.setView(mapCenterMarker, 12);
    }
  }

  loadPrograms() {
    this.programService.findAllProgram().subscribe(program => {
      this.programService.programs = program;
    }, () => {
    });
  }

  getDailyKm(id: number,dateInterval: DateInterval): Observable<any[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<any[]>(dns + "groupes/dailyKm/"+id, dateInterval, { headers: headers });
  }

  resetService(){
    this.pointInterests = null;
    this.pointClient = null;
    this.groups = null;
    this.vehicules = null;
    this.selectedGroup = null;
    this.selectedVehicule = null;
    this.setGroups([]);
    this.setGroupsRegion([]);
  }



  exportPassationPDF(rentalId: number, avatarUri: string): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + "locationPdf/?rentalId=" + rentalId +
     "&avatarUri=" + avatarUri, { headers: headers, responseType: 'blob' as 'json' });
  }


}