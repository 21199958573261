<div class="login-box">
    <img *ngIf="isNewVersion" src="../../../assets/images/new.png" alt="new" style="position: absolute; width: 80px;" class="animated pulse infinite">
    <div class="login-box-body" style="background: rgba(255,255,255,.8);border: 1px solid transparent;border-radius: 18px;">
        <div class="login-logo">
            <img alt="{{owner}}" src="{{avatar}}">
            <h5 [ngClass]="{'animated infinite heartBeat': isNewVersion}" style="color: #c41d23;font-family: 'Inconsolata', monospace;">2.14.0/{{lastUpdate | date: 'dd-MM-yy'}}</h5>
        </div>
        <form #signinForm="ngForm" style="font-family: 'Josefin Sans', sans-serif;">
            <div class="form-group has-feedback">
                <input type="text" class="form-control" placeholder="Login" [(ngModel)]="credentials.username" name="username"
                    #username="ngModel" required style="font-family: 'Inconsolata', monospace;">
                <span class="glyphicon glyphicon-user form-control-feedback"></span>
            </div>
            <div class="form-group has-feedback">
                <input type="password" class="form-control" placeholder="Mot de passe" [(ngModel)]="credentials.password"
                    name="password" #password="ngModel" style="font-family: 'Inconsolata', monospace;" required>
                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div class="row">
                <div class="text-center">
                    <button style="font-family: 'Inconsolata', monospace;" type="submit" class="btn btn-primary btn-flat" (click)="login()" [disabled]="!signinForm.form.valid || loading">Login
                        <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i></button>
                </div>
            </div>
        </form>
    </div>

</div>
