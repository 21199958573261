import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KmJrTrajetParentComponent } from './km-jour-trajet-parent.component';
import { DurationPipesModule } from 'src/app/pipes/duration-sec.module';


@NgModule({
  declarations: [
    KmJrTrajetParentComponent // Declare the KmJrTrajetParentComponent
  ],
  imports: [
    CommonModule,
    DurationPipesModule
  ],
  exports: [
    KmJrTrajetParentComponent // Export the component for use in other modules
  ],
  providers: [] // Add service providers here if necessary
})
export class KmJrTrajetParentModule { }
