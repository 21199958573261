import { Component, OnDestroy, OnInit } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { User } from 'src/app/authentification/signin/credentials';
import { RentalAgreementManagementService } from './rental-agreement-management.service';
import { saveAs as importedSaveAs } from "file-saver";
import { NgForm } from '@angular/forms';
import { RentalAgreementSetting } from './rental-agreement';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-rental-agreement-management',
  templateUrl: './rental-agreement-management.component.html',
  styleUrls: ['./rental-agreement-management.component.css']
})
export class RentalAgreementManagementComponent implements OnInit, OnDestroy {

  loader = false;
  showSetting : boolean = false;

  public ANIMATION_TYPES = ngxLoadingAnimationTypes;

  /** Location SUBSCRIPTION */
  locatioSubscription: Subscription | null = null;

  loading: boolean;

  public currentUser = new User();

  rentalId: number;
  rentalAgreementIds: Number[] = [];
  rentalAgreementSetting: RentalAgreementSetting = new RentalAgreementSetting();
  error: { errorMessage: string } = { errorMessage: '' };
  constructor(private toastr: ToastrService, private rentalAgreementManagementService: RentalAgreementManagementService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || '{}');
  }


  ngOnInit() {
    // load locations
    this.locatioSubscription = this.rentalAgreementManagementService.loadAllRentalAgreementIds().subscribe(res => {
      this.rentalAgreementIds = res;
    })

    this.loadSetting();
  }

  ngOnDestroy() {
    if (this.locatioSubscription) this.locatioSubscription.unsubscribe();
  }

  toggleOption(showSetting: any) {
    this.showSetting = showSetting;
  }

  /** setting */
  loadSetting() {
    this.loader = true;
    this.rentalAgreementManagementService.getSetting()
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe(
        setting => {
          if (setting) {
            this.rentalAgreementSetting = setting;
          }
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error?.errorMessage) {
            this.error = this.error || { errorMessage: "SERVER ERROR" };
            this.error.errorMessage = "SERVER ERROR";
          }
        }
      );
  }

  cancel(settingForm: NgForm) {
    settingForm.form.reset();
  }

  saveTemplateSetting() {
    this.loading = true;
    this.rentalAgreementManagementService
      .saveSetting(this.rentalAgreementSetting)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        setting => {
          this.loading = false;
          this.toastr.success("enregistré avec succès !");
          this.rentalAgreementSetting = setting;
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }
  /** end setting */


  /** export contrat (PDF) */
  export() {
    this.loader = true;

    this.rentalAgreementManagementService.exportPDF(this.rentalId, this.currentUser.avatartUri).subscribe(blob => {
      if (blob.size != 0)
        importedSaveAs(blob, "CONTRAT_DE_LOCATION.pdf");
      this.loader = false;
    }, () => {
      this.toastr.error('aucune contrat !', 'Error');
      this.loader = false;
    });
  }

}
