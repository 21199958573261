import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MonthlyMaintenanceCostsComponent } from './monthly-maintenance-costs.component';
import { MonthlyMaintenanceCostsService } from './monthly-maintenance-costs.service';
import { HighchartsChartModule } from 'highcharts-angular';  // Highcharts integration module
import { NgxLoadingModule } from 'ngx-loading';
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    MonthlyMaintenanceCostsComponent  // Declare the component in this module
  ],
  imports: [
    CommonModule,       // Import CommonModule for common directives
    FormsModule,        // Import FormsModule for template-driven forms
    HighchartsChartModule, // Import Highcharts module for charting
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    MonthlyMaintenanceCostsService  // Provide the service for the component
  ],
  exports: [
    MonthlyMaintenanceCostsComponent  // Export the component for use in other modules
  ]
})
export class MonthlyMaintenanceCostsModule { }
