import {Injectable} from "@angular/core";
import "rxjs/add/operator/map";
import {Observable} from "rxjs/Observable";
import {dns} from "../../../global.config";
import {createAuthorizationHeader} from "../../../utils/security/headers";
import {Brake} from "../model";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class BrakeService {

    constructor(private _http: HttpClient) {
    }

    getListBatteries(idVehicule: number): Observable<Brake[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<Brake[]>(dns + 'brakes/' + idVehicule, { headers: headers });
    }

    public add(brake: Brake, idVehicule: number, groupId: number): Observable<Brake> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.post<Brake>(dns + 'brakes/' + idVehicule + "?groupId=" + groupId, brake, options);
    }

    public delete(idBrake: number): Observable<boolean> {
        let headers = createAuthorizationHeader();
       let options = { headers: headers };
        return this._http.delete<boolean>(dns + 'brakes/' + idBrake, options);
    }

    deleteMultiple(brakesId: number[]): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + "brakes/multiple/" + brakesId, { headers: headers });
    }

    public update(brake: Brake): Observable<Brake> {
        let headers = createAuthorizationHeader();
       let options = { headers: headers };
        return this._http.put<Brake>(dns + 'brakes', brake, options);
    }
}
