import {Observable} from 'rxjs/Rx';
import {dns} from '../../../global.config';
import {Injectable} from '@angular/core';
import {DeviceCarb, DeviceMems, DeviceOpt} from './technical-data';
import {createAuthorizationHeader} from '../../../utils/security/headers';
import {DateInterval} from '../../../shared/model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TechnicalDataService {

  deviceopts: DeviceOpt[] = null;
  deviceCarbs: DeviceCarb[] = null;


  constructor(private _http: HttpClient) { }

  getFuelDetail(
    deviceId: number,
    dateInterval: DateInterval,
    options: any
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(
        dns + 'fuels/details/' + deviceId + '?options=' + options,
        dateInterval,
        { headers: headers }
      )
      ;
  }

  getDeviceOpt(deviceId: number): Observable<DeviceOpt> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<DeviceOpt>(dns + 'deviceopt/' + deviceId, { headers: headers })
      ;
  }


  getAllDeviceOpt(): Observable<DeviceOpt[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<DeviceOpt[]>(dns + 'deviceopt', { headers: headers })
      ;
  }

  getDeviceCarb(deviceId: number): Observable<DeviceCarb> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<DeviceCarb>(dns + 'devicecarb/' + deviceId, { headers: headers })
      ;
  }

  getDeviceMems(deviceId: number): Observable<DeviceMems> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<DeviceMems>(dns + 'devicemems/' + deviceId, { headers: headers })
      ;
  }



  getAllDeviceCarb(): Observable<DeviceCarb[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<DeviceCarb[]>(dns + 'devicecarb', { headers: headers })
      ;
  }

  getRepFuelVariation(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + 'repfuelvariation/' + deviceId, dateInterval, {
        headers: headers
      })
      ;
  }

  updateDeviceCarb(
    deviceId: number,
    payload: DeviceCarb
  ): Observable<DeviceCarb> {
    let headers = createAuthorizationHeader();
    return this._http
      .put<DeviceCarb>(dns + 'devicecarb/' + deviceId, payload, { headers: headers })
      ;
  }

  updateDeviceMems(
    deviceId: number,
    payload: DeviceMems
  ): Observable<DeviceMems> {
    let headers = createAuthorizationHeader();
    return this._http
      .put<DeviceMems>(dns + 'devicemems/' + deviceId, payload, { headers: headers });
  }


}
