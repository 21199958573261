import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportExportFormsComponent } from './import-export-forms.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms'; // If you are using forms in your component
import { ModalModule } from 'ngx-bootstrap/modal'; // Import if you're using modals

@NgModule({
  declarations: [
    ImportExportFormsComponent // Declare the component
  ],
  imports: [
    CommonModule, // Common module for common directives
    ToastrModule.forRoot(), // Toastr for notifications
    FormsModule, // Import FormsModule if you are using forms
    ModalModule.forRoot() // Import ModalModule if you are using modals
  ],
  exports: [
    ImportExportFormsComponent // Export the component to make it usable in other modules
  ]
})
export class ImportExportFormsModule { }
