import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReparationFormComponent } from './reparation-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { ProviderService } from '../../../providers/provider-service'; // Adjust the path as necessary
import { ReparationService } from '../../../providers/reparation-service'; // Adjust the path as necessary
import { MultiTypeService } from '../../../providers/multi-type-service';

@NgModule({
  declarations: [
    ReparationFormComponent // Declare the ReparationFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule to handle form controls
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot() // Import BsDatepickerModule for date picking
  ],
  providers: [
    MultiTypeService, // Provide MultiTypeService
    ProviderService, // Provide ProviderService
    ReparationService // Provide ReparationService
  ],
  exports: [
    ReparationFormComponent // Export ReparationFormComponent for use in other modules
  ]
})
export class ReparationFormModule { }
