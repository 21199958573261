<section class="groups-container">
  <div
    class="control-option"
    [ngStyle]="{'border' : !hideComponent? 'none': ' 0.2rem solid #eeeded', 'background' : !hideComponent? 'transparent': '#F5F4F5'}"
  >
    <div 
      class="d-flex primary-card justify-content-between"
      *ngIf="hideComponent"
    >
      <div>
        <button
          [routerLink]="['km-jour']"
          routerLinkActive="btn-custom-checked"
          type="button"
          class="btn btn-md"
        >
          Km jour
        </button>
        <button
          [routerLink]="['en-cours']"
          routerLinkActive="btn-custom-checked"
          type="button"
          class="btn btn-md"
          [disabled]="hideEncours"
        >
          En cours
        </button>
        <button
          [routerLink]="['en-stops']"
          routerLinkActive="btn-custom-checked"
          type="button"
          class="btn btn-md"
        >
          Stops
        </button>
        <!--         <button
          type="button"
          class="btn btn-md"
          (click)="moreOptions()"
          disabled
        >
          options
          <i class="fa fa-arrow-down" aria-hidden="true"></i>
        </button> -->
      </div>  
      <div style="display: flex; gap: 10px; align-items: center;">
        
        <div>
          <div class="calender-item">
            <input 
              type="text" 
              ngxDaterangepickerMd 
              [locale]="locale"
              [autoApply]="false"
              [maxDate]="maxDate"
              [(ngModel)]="selected"
              (datesUpdated)="choosedDate()" 
              placeholder="Personnaliser"
              class="calender-input"
              [showDropdowns]="true"        
              [timePicker]="true"           
              [timePicker24Hour]="true"     
              [timePickerIncrement]="1" 
            />
          </div>
        </div>
        <div style="width: 150px;">
          <search-dropdown 
            [items]="vehicules" 
            [size]="'small'" 
            [img]="'product'" 
            [label]="'matricule'" 
            [uid]="'idDevice'" 
            (afterChange)="onItemChange($event)"
            [disabled]="isDisabledCondition"
            [(ngModel)]="item">
          </search-dropdown>
        </div>
        <button
          type="button"
          class="btn-md"
          style="color: red"
          (click)="stopRealTime()"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div [ngClass]="{ 'bar-tool': hideComponent }">
      <component-tool-bar></component-tool-bar>
    </div>

  </div>
</section>