import {
  Component,
  OnInit,
  ElementRef,
  OnDestroy
} from "@angular/core";
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { Router } from '@angular/router';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { DecimalPipe, DatePipe } from '@angular/common';
import { Subscription } from "rxjs";
@Component({
  selector: 'app-position-by-poi',
  templateUrl: './position-by-poi.component.html',
  styleUrls: ['./position-by-poi.component.css']
})


export class PositionByPoiComponent implements OnInit, OnDestroy {
loaded$: Subscription;
  constructor(
    private geocodingService: GeocodingService,
    private elementRef: ElementRef,
    public router: Router,
    private mapService: MapService,
    private pipe: DecimalPipe,
    public datepipe: DatePipe
  ) {
    this.loaded$ = this.mapService.mapLoaded.subscribe((value: boolean) => {
      this.mapService.map.removeLayer(this.mapService.baseMaps['Google Sat']);
      this.mapService.map.addLayer(this.mapService.baseMaps.OpenStreetMap);
    });
  }
  ngOnInit() {
  }
ngOnDestroy(): void {
      if(this.loaded$){this.loaded$.unsubscribe()}
  }
}
