import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component'; // Adjust the import path as necessary
import { FormsModule } from '@angular/forms'; // If you are using forms in your component
import { TechnicalDataService } from '../technical-data/technical-data.service'; // Import the service if needed
import { ToolKmJourService } from '../../realtime/services/tool-km-jour.service';
import { jqxGaugeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgauge';
import { RoundPipeModule } from 'src/app/pipes/round.module';


@NgModule({
  declarations: [
    DashboardComponent, // Declare your DashboardComponent
    jqxGaugeComponent // Declare the jqxGaugeComponent if you are using it
  ],
  imports: [
    CommonModule, // Import CommonModule for common directives
    FormsModule,
    RoundPipeModule
  ],
  providers: [
    TechnicalDataService, // Add services to providers if they are not provided elsewhere
    ToolKmJourService
  ],
  exports: [
    DashboardComponent // Export if this module needs to be used in other modules
  ]
})
export class DashboardModule { }
