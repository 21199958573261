import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // For template-driven forms
import { ReactiveFormsModule } from '@angular/forms'; // For reactive forms
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For date picker
import { AlertNotificationsComponent } from './alert-notifications.component'; // Import the component
import { ImportExportService } from 'src/app/client-management/import-export'; // Adjust the path as needed
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service'; // Adjust the path as needed
import { DataManagementService } from '../../data-management/data-management.service'; // Data management service
import { AlertManagementService } from '../alert-management.service'; // Alert management service
import { DatePipe } from '@angular/common'; // Import DatePipe
import { NgxLoadingModule } from 'ngx-loading';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    AlertNotificationsComponent // Declare the component
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(), // Initialize Bootstrap datepicker
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    FilterPipeModule,
  ],
  providers: [
    ImportExportService, // Import/export service provider
    GeocodingService, // Geocoding service provider
    DataManagementService, // Data management service provider
    AlertManagementService, // Alert management service provider
    DatePipe // Provide DatePipe for date formatting
  ],
  exports: [
    AlertNotificationsComponent // Export the component if needed
  ]
})
export class AlertNotificationsModule { }
