import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GroupsContainerComponent } from '../groups-container.component';
import { RealtimeComponent } from '../../../realtime.component';
import { DataManagementService } from 'src/app/client-management/data-management';
import { StateCounts } from '../../../model/real-time.model';
import { Subscription } from 'rxjs';
import { RealtimeService } from '../../../services/realtime.service';
import { animate, style, transition, trigger } from '@angular/animations';

interface IGroup {
  id: number;
  name: string;
  // Autres propriétés selon votre modèle
}

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: '0px', opacity: 0 }), // Start with no height
        animate('0.3s ease-out', style({ height: '*', opacity: 1 })) // Animate to full height
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }), // Start with full height
        animate('0.3s ease-in', style({ height: '0px', opacity: 0 })) // Animate to no height
      ])
    ]),
    trigger('showUp', [
      transition(':enter', [
        style({ opacity: 0 }), // Start with no height
        animate('0.6s ease-out', style({ opacity: 1 })) // Animate to full height
      ]),
      transition(':leave', [
        style({ opacity: 1 }), // Start with full height
        animate('0.6s ease-in', style({ opacity: 0 })) // Animate to no height
      ])
    ])
  ]

})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input('groups') groups: any[];
  @Output('groupsToggle') groupsToggle = new EventEmitter<void>();
  @Output('filterGroups') filterGroups = new EventEmitter<string>()

  activeCars= 0;
  desactiveCars= 0;
  nonValidCars= 0;
  technicalIssueCars= 0;

  statesCountSubscription: Subscription;

  searchedSubscription: Subscription;
  searched: string = 'all';

  isListVisible: boolean = false;

  constructor(public realTime: RealtimeComponent,
    public dataManagementService: DataManagementService,private realTimeService: RealtimeService) { }

  ngOnInit(): void {
    this.searchedSubscription= this.realTimeService.searchedOptionObs().subscribe(searched=>this.searched= searched)
    this.statesCountSubscription= this.realTimeService.getStatesCount().subscribe(statesCounts => {
      this.activeCars= 0;
      this.desactiveCars= 0;
      this.nonValidCars= 0;
      this.technicalIssueCars= 0;
      Object.values(statesCounts).forEach((groupStateCount) => {
        this.activeCars += groupStateCount.activeCars.length;
        this.desactiveCars += groupStateCount.desactiveCars.length;
        this.nonValidCars += groupStateCount.nonValidCars.length;
        this.technicalIssueCars += groupStateCount.technicalIssueCars.length;
      });
    })
  }

  filter(event: any, searched: string){
    event.stopPropagation();

    if(this.searched !== searched){
      this.realTimeService.setsearchedOption(searched);
    }else{
      this.realTimeService.setsearchedOption('all');
    }
  }


  filterGroup(event: any, filter: string){
    event.stopPropagation();
    this.filterGroups.emit(filter);
    if (this.isListVisible) {
      this.isListVisible= false;
    }
  }

  removeEvent(event: any){
    event.stopPropagation();
  }


  colapseGroupe(){
    this.groupsToggle.emit();
  }
  toggleList(event: any): void {
    event.stopPropagation();
    this.isListVisible = !this.isListVisible;
  }


  formatNumber(num: number) {
    //return num ? `${num}`.padStart(3, '0') : '000';
    return num;
  }

  ngOnDestroy(): void {
    this.statesCountSubscription.unsubscribe();
    this.searchedSubscription.unsubscribe();
  }
}
