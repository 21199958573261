<form #brakeForm="ngForm" class="form-horizontal">
    <div class="box-body">

        <!-- ========================== Modal FILEDS (START) ===============================-->
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Date de demande de réparation</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" class="form-control input-sm" bsDatepicker name="operationTime" [(ngModel)]="operationTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    placeholder="Date de demande de réparation" (ngModelChange)="onChangeOperationTime()" autocomplete="off">
            </div>

            <label class="col-sm-2 control-label">Date de réparation réelle</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" class="form-control input-sm" bsDatepicker name="actualRepairTime" [(ngModel)]="actualRepairTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" [minDate]="operationTime"
                    placement="bottom" placeholder="Date de réparation réelle" autocomplete="off">
            </div>
        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Coût *</label>
            <div class="col-lg-4 col-md-4">
                <input name="prix" [(ngModel)]="_brake.cost" type="number" step="0.01" class="form-control" required="true"
                    miplaceholder="Coût">
            </div>

            <label class="col-sm-2 control-label">Coût de main d'oeuvre</label>
            <div class="col-lg-4 col-md-4">
                <input name="laborCost" [(ngModel)]="_brake.laborCost" type="number" step="0.01" class="form-control"
                    miplaceholder="Coût de main d'oeuvre">
            </div>

        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Références</label>
            <div class="col-lg-4 col-md-4">
                <input name="referenceDADR" [(ngModel)]="_brake.referenceDADR" maxlength="50" type="text" class="form-control"
                    placeholder="Références">
            </div>

            <label class="col-sm-2 control-label">Observations</label>
            <div class="col-lg-4 col-md-4">
                <input name="Observations" [(ngModel)]="_brake.observation" maxlength="50" type="text" class="form-control"
                    placeholder="Observations" name="observation">
            </div>

        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Jeu de plaquette</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="_brake.boardGames" name="boardGames">
                </mat-checkbox>
            </div>

            <label class="col-sm-2 control-label">Disque</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="_brake.disk" name="disk">
                </mat-checkbox>
            </div>

        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Position avant</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="_brake.rightPosition" name="position">
                </mat-checkbox>
            </div>
            <label class="col-sm-2 control-label">Position arrière</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="_brake.leftPosition" name="position">
                </mat-checkbox>
            </div>
        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Pompe</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="_brake.pump" name="pump">
                </mat-checkbox>
            </div>
            <label class="col-sm-2 control-label">Huile</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="_brake.oil" name="oil">
                </mat-checkbox>
            </div>
        </div>

        <div *ngIf="!showProvider" class="form-group has-success">
            <label class="col-sm-2 control-label">Fournisseur *</label>
            <div class="col-sm-10">
                <select class="form-control select2" name="currentProvider" required (change)="onChangeProvider($event.target.value)">
                    <option [value]=-1>Autres</option>
                    <option [selected]="provider.id == _brake.provider.id" *ngFor="let provider of listProviders"
                        [value]="provider.id">{{provider?.name}}
                    </option>
                    <option hidden [value]="null"></option>     
                </select>
            </div>
        </div>

        <div *ngIf="showProvider" class="form-group has-success">
            <label class="col-sm-2 control-label">Nom Fournisseur *</label>
            <div class="col-sm-8">
                <input name="Nom Fournisseur" [(ngModel)]="_brake.provider.name" minlength="4" maxlength="50" type="text"
                    class="form-control" placeholder="Nom Fournisseur" required="true">
            </div>
            <div class="col-sm-2" style="color: #9f191f;text-align: center">
                <i class="fa fa-times" (click)="showProvider = false;"></i>
            </div>
        </div>

        <!-- ========================== Modal FILEDS (END) ===============================-->

        <!-- ========================== Modal FOOTER (START) ===============================-->
        <div class="box-footer">
            <button *ngIf="mode === 'ADD'" [disabled]="!brakeForm.form.valid" class="btn btn-info pull-right" (click)="addBrake()"><i
                    class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
            <button [disabled]="!brakeForm.form.valid" class="btn btn-info pull-right" *ngIf="mode === 'UPDATE'"
                (click)="updateBrake()"><i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
        </div>
        <!-- ========================== Modal FOOTER (END) ===============================-->

    </div>
</form>