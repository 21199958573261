import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitTrackingComponent } from './visit-tracking.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using forms
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'; // Import any ngx-bootstrap modules you need
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import datepicker if used
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    VisitTrackingComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    FormsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxLoadingModule.forRoot({}),


  ],
  exports: [
    VisitTrackingComponent // Optionally export if you need to use it in other modules
  ],
  providers: []
})
export class VisitTrackingModule { }
