import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DateInterval } from 'src/app/shared/model';
import { ChargePfbInfoDto, ChargeRealise, VoyageDto } from '../charges-pfb';
import { ChargesPfbService } from '../charges-pfb.service';
import { Subscription, interval, forkJoin } from 'rxjs';
import { ImportExportService } from '../../import-export';
import { saveAs as importedSaveAs } from "file-saver";
import { RapportDto } from '../../data-management';
import { FuelingDashboardDto } from '../../parc-management';
import {TransportTypeInfoDto, TransportTypeService} from "../parametrage/transport-type";

@Component({
  selector: 'app-charges-pfb-tracking',
  templateUrl: './charges-pfb-tracking.component.html',
  styleUrls: ['./charges-pfb-tracking.component.css']
})
export class ChargesPfbTrackingComponent implements OnInit {

  /**
   * Map of CA Realise
   * it has two types
   * 1) ListByDay = ca grouped by date
   * and it is used when the user choose more than one date
   * 2) SimpleList = it is used when the user choose only
   * on date
   */
  mapCaRealise: any;
  caRealise: ChargeRealise[] = [];
  /** for Corrélation charts */
  crCaRealise: ChargeRealise[] = [];
  voyageData: VoyageDto = new VoyageDto();
  // list fuel
  fueling: FuelingDashboardDto[] = [];
  // list charge pfb
  chargesPfb: ChargePfbInfoDto[] = [];
  // filtred list charge pfb
  filtredChargesPfb: ChargePfbInfoDto[] = [];
  selectedCharge: ChargePfbInfoDto;
  date: DateInterval = new DateInterval();
  endDate: Date;
  startDate: Date;

  /** current date */
  maxDate: Date = new Date();
  // minimum fixed date in calendar
  firstDay = new Date();
  // maximum fixed date in calendar
  lastDay: Date = new Date();

  /**show global loader element */
  globalLoading: boolean;

  /** difference between choosen dates */
  dayDif: number = 0;
  locationDayDif: number = 0;

  /**show loader element */
  loader = false;

  /** UPDATE TRACKING TIMER */
  updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;
  /** LOADING */
  loadingTracking: boolean = false;
  loadingFuelTracking: boolean = false;
  loadingCATracking: boolean = false;
  /** excel report */
  repport: RapportDto = new RapportDto();

  selectedTransport: TransportTypeInfoDto = new TransportTypeInfoDto();

  /** manual or automatic mode */
  isManually = false;

  constructor(private chargePfbService: ChargesPfbService, public toastr: ToastrService, private importExportService: ImportExportService,
    public transportTypeService: TransportTypeService) {
    this.isManually = this.chargePfbService.isManually;
    /** fix min and max date to current month */
    this.lastDay = new Date(this.lastDay.getFullYear(), this.lastDay.getMonth() + 1, 0);
    this.firstDay = new Date(this.lastDay.getFullYear(), this.lastDay.getMonth(), 1);
    this.repport.type = 'TRANSPORT_BASE';
    this.repport.rapportPayloadDto.byGroups = false;
    this.repport.rapportPayloadDto.byVehicules = true;
    this.repport.rapportPayloadDto.allVehicules = false;
  }
  ngOnInit() {
    this.endDate.setDate(new Date().getDate());
    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);
    this.loadTransportTypes();
  }

  /**
   * load all transport types
   */
  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.transportTypeService.findAllTransportTypes().subscribe(transportTypes => {
        this.transportTypeService.transportTypes = transportTypes;
        if (transportTypes.length > 0) {
          this.selectedTransport = transportTypes[0];
          this.loadChargePfb();
        }
      }, err => {
        this.toastr.error("Erreur lors de chargements", "Type de transport");
      })
    } else {
      this.selectedTransport = this.transportTypeService.transportTypes[0];
      this.loadChargePfb();
    }

  }

  ngOnDestroy() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
  }

  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 300;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        if (this.isManually) {
          this.manualData();
        } else {
          this.onTracking();
        }
      }
    })
  }
  /**
   * retrieve data entered manually
   */
  manualData() {

    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;
    this.loadingTracking = true;
    this.loadingCATracking = true;
    this.loadingFuelTracking = true;
    this.globalLoading = true;
    /** get the days difference and check if the result is bigger than the selected number of days */
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);
    if (this.dayDif >= this.selectedCharge.nbDays)
      this.dayDif = this.selectedCharge.nbDays;
    else
      this.dayDif;
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();

    /**
     * end date can't be greater than current date
     */
    if (this.date.endDate > this.maxDate) {
      this.date.endDate = this.maxDate;
      this.date.endDate.setHours(23, 59, 59, 59);
    }

    this.loadCaRealise();
    this.startUpdateTrackingTimer();
  }

  /**
   * load fuel data
   */
  onFuelTracking() {
    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;
    this.globalLoading = true;
    this.loadingFuelTracking = true;
    // CA REALISE
    this.mapCaRealise = new Map();
    this.caRealise = [];
    this.crCaRealise = [];

    /** get the days difference and check if the result is bigger than the selected number of days */
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);

    if (this.dayDif >= this.selectedCharge.nbDays)
      this.dayDif = this.selectedCharge.nbDays;
    else
      this.dayDif;
    if (this.date.endDate > this.maxDate) {
      this.date.endDate = this.maxDate;
      this.date.endDate.setHours(23, 59, 59, 59);
    }

    forkJoin(
      [this.chargePfbService.getFuelCharge(this.selectedCharge.idChargePfb, this.date, -1),
      this.chargePfbService.getCaRealise(this.selectedCharge.idChargePfb, this.date)]
    ).subscribe(([fueling, caRealise]) => {
      this.fueling = fueling;
      this.mapCaRealise = caRealise;
      this.caRealise = this.mapCaRealise.simpleList;
      if (this.dayDif > 1) {
        this.crCaRealise = this.mapCaRealise.ListByDay;
      } else {
        this.crCaRealise = this.caRealise;
      }
      this.globalLoading = false;
      this.loadingFuelTracking = false;
    }, err => {
      this.fueling = [];
      this.globalLoading = false;
      this.loadingFuelTracking = false;
      this.toastr.error("Erreur lors de chargement", "Charge Carburant")
    })
  }

  /**
   *
   * @param transport
   * Action choose transport
   */
  chooseTransport(transport: any) {
    this.selectedTransport = transport;
    this.filtredChargesPfb = this.chargesPfb.filter(c => c.transportType.idTransportType == this.selectedTransport.idTransportType);
    this.selectedCharge = this.filtredChargesPfb[0];
    this.stopTracking();
  }

  /**
   * load rotation data
   */
  onTracking() {

    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;
    this.loadingTracking = true;
    this.loadingCATracking = true;
    this.globalLoading = true;
    /** get the days difference and check if the result is bigger than the selected number of days */
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);
    if (this.dayDif >= this.selectedCharge.nbDays)
      this.dayDif = this.selectedCharge.nbDays;
    else
      this.dayDif;
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    if (this.date.endDate > this.maxDate) {
      this.date.endDate = this.maxDate;
      this.date.endDate.setHours(23, 59, 59, 59);
    }

    this.RotationCharge();
    // this.loadCaRealise();
    this.startUpdateTrackingTimer();
  }

  /**
   * load ca realise data
   */
  loadCaRealise() {
    this.loadingCATracking = true;
    this.loadingFuelTracking = true;
    this.mapCaRealise = new Map();
    this.caRealise = [];
    this.crCaRealise = [];
    this.chargePfbService.getCaRealise(this.selectedCharge.idChargePfb, this.date).subscribe(caRealise => {
      this.mapCaRealise = caRealise;
      this.caRealise = this.mapCaRealise.simpleList;
      if (this.dayDif > 1) {
        this.crCaRealise = this.mapCaRealise.ListByDay;
      } else {
        this.crCaRealise = this.caRealise;
      }
      this.globalLoading = false;
      this.loadingCATracking = false;
      this.loadingFuelTracking = false;
    }, err => {
      this.globalLoading = false;
      this.loadingCATracking = false;
      this.loadingFuelTracking = false;
      this.toastr.error("Erreur lors de chargement", "C.A Réalisé")
    })
  }

  RotationCharge() {
    this.loadingTracking = true;
    this.loadingCATracking = true;
    this.mapCaRealise = new Map();
    this.caRealise = [];
    this.crCaRealise = [];
    this.voyageData = new VoyageDto();
    forkJoin(
      [this.chargePfbService.getRotationCharge(this.selectedCharge.idChargePfb, this.date),
      this.chargePfbService.getCaRealise(this.selectedCharge.idChargePfb, this.date)]
    ).subscribe(([voyage, caRealise]) => {
      this.voyageData = voyage;
      this.mapCaRealise = caRealise;
      this.caRealise = this.mapCaRealise.simpleList;
      if (this.dayDif > 1) {
        this.crCaRealise = this.mapCaRealise.ListByDay;
      } else {
        this.crCaRealise = this.caRealise;
      }
      this.loadingCATracking = false;
      this.globalLoading = false;
      this.loadingTracking = false;
    }, err => {
      this.globalLoading = false;
      this.loadingCATracking = false;
      this.loadingTracking = false;
      this.toastr.error("Erreur lors de chargement", "Erreur")
    })
  }


  /** STOP TRACKING */
  stopTracking() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = null;
  }

  /**
   * load charge pfb lists
   * and filter it by selected transport type
   */
  loadChargePfb() {
    this.chargePfbService.getChargesPfb()
      .subscribe(chargePfb => {
        this.chargesPfb = chargePfb;
        this.filtredChargesPfb = this.chargesPfb.filter(c => c.transportType.idTransportType == this.selectedTransport.idTransportType);
        this.selectedCharge = this.filtredChargesPfb[0];
      }, err => {
        this.toastr.error("Erreur lors de chargements", "Charge PFB/BPE")
      })
  }

  /**
   *
   * @param charge
   * Action choose charge
   */
  chooseCharge(charge: any) {
    this.selectedCharge = charge;
    this.stopTracking();
  }
  /**
   * Action change date début
   */
  onChangeStartDate() {
    // Vérifier que startDate et endDate ne sont pas null avant la comparaison
    if (this.startDate && this.endDate && this.endDate < this.startDate) {
      this.endDate = null;
    }

    // Appeler stopTracking après avoir éventuellement mis à jour endDate
    this.stopTracking();
  }


  /**
   * export excel file
   */
  export() {
    this.loader = true;
    this.repport.dateInterval.startDate = this.startDate;
    this.repport.dateInterval.endDate = this.endDate;
    this.repport.rapportPayloadDto.poiAdresses = this.selectedCharge.pointOfInterests.map(p => p.name);
    this.repport.rapportPayloadDto.endPoiAdresses = this.selectedCharge.endPointOfInterests.map(p => p.name);
    this.repport.rapportPayloadDto.idVehicules = this.selectedCharge.transportType.vehicules.map(v => v.idVehicule);

    this.importExportService.getRepport(this.repport).subscribe(
      blob => {
        if (blob.size > 0) {
          importedSaveAs(blob, 'La Base Transports.xlsx');
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates!', 'Error');
        }
        this.loader = false;
      },
      error => {
        this.toastr.warning('Aucune donnée entre ces deux dates!', 'Error');
        this.loader = false;
      }
    );

  }

}
