import {Component, OnInit, Input} from '@angular/core';
import {AlertConfiguration} from '../../alert';

@Component({
    selector: 'app-cron-settings',
    templateUrl: 'cron-settings.component.html',
    styleUrls: ['cron-settings.component.css']
})
export class CronSettingsComponent implements OnInit {

    @Input()
    cron: AlertConfiguration = new AlertConfiguration();

    checked = true;

  public hours: { value: number; label: string }[] = [];
  public minutes: { value: number; label: string }[] = [];

  constructor() {
        this.initHours();
        this.initMinutes();
    }

    ngOnInit() {
    }

    initHours() {
        for (let i = 0; i <= 24; i++) {
            this.hours.push({value: i, label: i > 9 ? i.toString() : '0' + i});
        }
    }

    initMinutes() {
        for (let i = 0; i < 4; i++) {
            this.minutes.push({value: i * 15, label: i > 0 ? (i * 15).toString() : '0' + i});
        }
    }
}
