import { Injectable } from '@angular/core';
import { ChildDetail, KmJourPath, Point } from '../model/km-jour.model';
import { RealtimeRestService } from 'src/app/client-management/realtime/services/realtime-rest.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { RealtimeService } from 'src/app/client-management/realtime/services/realtime.service';
import { MileageService } from 'src/app/client-management/historical';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { DateInterval } from 'src/app/shared/model';
import { DeviceOpt } from '../../statistical/technical-data/technical-data';
import { DataManagementService } from '../../data-management';
import { FormattedStopDto } from 'src/app/shared/model/sinistre.model';

@Injectable({providedIn: 'root'})
export class ToolKmJourService {
  isloadedSubjec = new BehaviorSubject<boolean>(false);

  selectedKmJourPathSubjec = new BehaviorSubject<KmJourPath>(null);
  selectedKmJourStopSubjec = new BehaviorSubject<FormattedStopDto|null>(null);

  selectedMileage = new Subject<any>();
  mileage: any;

  selectedChildPathSubject = new Subject<ChildDetail>();
  childTrajet: ChildDetail;

  enCoursPathSubject = new BehaviorSubject<ChildDetail>(null);

  destinationSubject = new Subject<string>();
  destination: string;

  deviceopt= new BehaviorSubject<DeviceOpt>(null);
  deviceopts: DeviceOpt[];


  printedKmJourSubject = new BehaviorSubject<{ stops: any[]; points: Point[] }>(
    null
  );

  constructor(
    private realTimeRestApi: RealtimeRestService,
    private realTimeService: RealtimeService,
    private mileageService: MileageService,
    private toastr: ToastrService, 
    private dataMnagementService: DataManagementService
  ) {}

  loadKmJourPath(id: number, dateInterval: DateInterval) {
    this.setLoaded(false);
    this.realTimeRestApi
      .getRealTimeKmJour(id, dateInterval)
      .pipe(
        tap((kmJour) => this.processKmJourDetails(kmJour))
      )
      .subscribe(
        (kmJour) => {
          this.updateKmJourParentDetails(kmJour, dateInterval);
          this.setKmJourPath(kmJour);
          this.setLoaded(true);
        },
        (error) => this.handleError(error)
      );
  }


  private processKmJourDetails(kmJour: any) {
    const points = kmJour.childDetails
      .flatMap((element:  any) => element.points || []);
    const stops = kmJour.childDetails
      .flatMap((element:  any) => element.stops || []);
  
    this.setPrintedKmJour({ points, stops });
  }
  private updateKmJourParentDetails(kmJour: any, dateInterval: DateInterval) {
    const selectedVehicule = this.realTimeService.selectedVehicule;
    const driverName = selectedVehicule.driver ? `${selectedVehicule.driver.firstName} ${selectedVehicule.driver.lastName}` : '';
    kmJour.parentDetails.chauffeurName = driverName;
    kmJour.parentDetails.matricule = selectedVehicule.matricule;
  
    this.handleChildDetails(kmJour, dateInterval);
    this.updateAddress(kmJour.parentDetails);
  }
  private handleChildDetails(kmJour: any, dateInterval: DateInterval) {
    if (kmJour.childDetails.length != 0) {
      const lastChildDetail = kmJour.childDetails[kmJour.childDetails.length - 1];
      if (lastChildDetail.endAddress === '' && lastChildDetail.endPathTime < dateInterval.endDate) {
        this.setEnCoursPath(lastChildDetail);
        kmJour.childDetails.splice(kmJour.childDetails.length - 1, 1)[0]
      } else {
        this.setEnCoursPath(null);
      }
      kmJour.childDetails.forEach((element: any) => this.updateAddress(element));
    }
  }
  
  private updateAddress(detail: any) {
    const relativeStartAddress = this.dataMnagementService.getRelativePosition(detail.lat_start, detail.lng_start);
    const relativeEndAddress = this.dataMnagementService.getRelativePosition(detail.lat_end, detail.lng_end);
    detail.startAddress = relativeStartAddress || detail.startAddress;
    detail.endAddress = relativeEndAddress || detail.endAddress;
  }
  
  private handleError(error: any) {
    this.toastr.error('Problem Handling', 'Alert!', {
      closeButton: true,
      positionClass: 'toast-bottom-right',
      timeOut: 10000,
    });
    this.setLoaded(true);
  }

  loadMileage(id: number) {
    this.mileageService.getTodaysRealTimeMileage(id).subscribe(
      (mileage) => {
        this.mileage = mileage;
        this.setMileage(mileage);
      },
      (err) => {}
    );
  }

  getMileage(): Observable<any> {
    if (this.mileage) {
      return of(this.mileage);
    }
    return this.selectedMileage.asObservable();
  }
  setMileage(mileage: any) {
    this.mileage = mileage;
    this.selectedMileage.next(mileage);
  }
  getKmJourPath(): Observable<KmJourPath> {
    return this.selectedKmJourPathSubjec.asObservable();
  }
  setKmJourPath(kmJourPath: KmJourPath) {
    this.selectedKmJourPathSubjec.next(kmJourPath);
  }
  setDeviceopts(deviceopts: DeviceOpt[]){
    this.deviceopts = deviceopts;
  }

  setDeviceoption(id: number){
    let deviceOpt = new DeviceOpt();



    for (let i = 0; i < this.deviceopts.length; i++) {
        if (this.deviceopts[i].idDevice == id) {
            deviceOpt = this.deviceopts[i]
        }
    }
    this.deviceopt.next(deviceOpt)
  }

  getDeviceoption(): Observable<DeviceOpt> {
    return this.deviceopt.asObservable();
  }

  getChildPath(): Observable<ChildDetail> {
    if (this.childTrajet) {
      return of(this.childTrajet);
    }
    return this.selectedChildPathSubject.asObservable();
  }
  setChildPath(childTrajet: ChildDetail) {
    this.childTrajet = childTrajet;
    this.selectedChildPathSubject.next(childTrajet);
  }

  isLoaded(): Observable<boolean> {
    return this.isloadedSubjec.asObservable();
  }
  setLoaded(isloaded: boolean) {
    this.isloadedSubjec.next(isloaded);
  }

  getEnCoursPath(): Observable<ChildDetail> {
    return this.enCoursPathSubject.asObservable();
  }
  setEnCoursPath(enCoursPath: ChildDetail) {
    this.enCoursPathSubject.next(enCoursPath);
  }

  getDestination(): Observable<string> {
    if (this.destination) {
      return of(this.destination);
    }
    return this.destinationSubject.asObservable();
  }
  setDestination(destination: string) {
    this.destination = destination;
    this.destinationSubject.next(destination);
  }

  getPrintedKmJour(): Observable<{ stops: any[]; points: Point[] }> {
    return this.printedKmJourSubject.asObservable();
  }

  setPrintedKmJour(element: { stops: any[]; points: Point[] }) {
    this.printedKmJourSubject.next(element);
  }

  getKmJourStop(): Observable<FormattedStopDto|null> {
    return this.selectedKmJourStopSubjec.asObservable();
  }
  setKmJourStop(stopDto: FormattedStopDto| null) {
    this.selectedKmJourStopSubjec.next(stopDto);
  }
}