import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Required for [(ngModel)]
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

// Import your components
import { CanalTypeComponent } from './canal-type.component';

// Import any services used by the component
import { CanalService } from '../../canal.service';
import { NgxLoadingModule } from 'ngx-loading';
import { CanalTypeFormComponent } from './canal-type-form/canal-type-form.component';
import { CanalTypeFormModule } from './canal-type-form/canal-type-form.module';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    CanalTypeComponent,

  ],
  imports: [
    CommonModule,       // Required for common Angular directives
    FormsModule,        // Required for ngModel
    RouterModule,       // Required for routing
    ModalModule.forRoot(), // Required for ngx-bootstrap modal
    ToastrModule.forRoot(), // Required for Toastr notifications
    NgxLoadingModule.forRoot({}),
    CanalTypeFormModule,
    FilterPipeModule,
    OrderPipModule
  ],
  providers: [CanalService], // Add any services you want to provide in this module
  exports: [CanalTypeComponent] // Export the component if needed elsewhere
})
export class CanalTypeModule { }
