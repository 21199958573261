import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy
} from "@angular/core";
import {  trigger,
  state,
  keyframes,
  animate,
  transition,
  style} from "@angular/animations";
import { Subscription } from "rxjs/Subscription";
import { Notification, Mileage } from "./notification";
import { DateInterval } from "../../shared/model";
import { OverviewService } from "../overview/overview.service";
import { MileageService } from "../historical/mileage/mileage.service";
import { DecimalPipe, DatePipe } from "@angular/common";
import { Group, GroupDto } from "../data-management/data-management.model";
import { Router } from "@angular/router";
import { MapService } from "../../utils/leaflet/service/map.service";

import { GeocodingService } from "../../utils/leaflet/service/geocoding.service";
import { DashbordInfo, Sinistre } from "../parc-management/model";
import { ModalDirective } from "ngx-bootstrap/modal";
import { VehiculesAccordingToPoi } from "./overview";
import { DashbordService } from "../parc-management";
import { DataManagementService } from "../data-management";
import { ChartConfigService } from "src/app/utils/chart/chart-config.service";
const Highcharts = require('highcharts');
 require('highcharts/modules/exporting')(Highcharts);

 if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}



@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.css"],

  //Animation list of notifications
  animations: [
    trigger("flyInOut", [
      state("in", style({ transform: "translateX(0)" })),
      transition("void => *", [
        animate(
          900,
          keyframes([
            style({ opacity: 0, transform: "translateX(-100%)", offset: 0 }),
            style({ opacity: 1, transform: "translateX(15px)", offset: 0.3 }),
            style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
          ])
        )
      ]),
      transition("* => void", [
        animate(
          300,
          keyframes([
            style({ opacity: 1, transform: "translateX(0)", offset: 0 }),
            style({ opacity: 1, transform: "translateX(-15px)", offset: 0.7 }),
            style({ opacity: 0, transform: "translateX(100%)", offset: 1.0 })
          ])
        )
      ])
    ])
  ]
})
export class OverviewComponent implements OnInit, OnDestroy {
  loaded$: Subscription;
  notifications: Notification[] = [];
  mileages: Mileage[] = [];
  dashbordInfo: DashbordInfo | null= null;
  allGroups: Subscription;
  groups: GroupDto[] = [];
  options: Object | null= null;
  groupeStatistics: any[] = [];
  cost: number;
  idGroup: number;
  idVehicule: number = -1;
  date: DateInterval = new DateInterval();
  endDate: Date = new Date();
  startDate: Date = new Date();
  selectedMonth: number;
  loader = false;
  error = false;
  months = [{ id: 0, name: 'Janvier' }, { id: 1, name: 'Février' }, { id: 2, name: 'Mars' }, { id: 3, name: 'Avril' }, { id: 4, name: 'Mai' }, { id: 5, name: 'Juin' },
  { id: 6, name: 'Juillet' }, { id: 7, name: 'Août' }, { id: 8, name: 'Septembre' }, { id: 9, name: 'Octobre' }, { id: 10, name: 'Novembre' }, { id: 11, name: 'Décembre' }]


  now: Date = new Date();
  @ViewChild("dashboardModal", { static: false }) dashboardModal: ModalDirective;


  constructor(

    private mileageService: MileageService,
    public router: Router,
    private mapService: MapService,
    public datepipe: DatePipe,
    private dashbordService: DashbordService,
    private groupService: DataManagementService,
    private chartConfig: ChartConfigService
      ) {
    this.loaded$ = this.mapService.mapLoaded.subscribe(() => {
      this.mapService.map.removeLayer(this.mapService.baseMaps['Google Sat']);
      this.mapService.map.addLayer(this.mapService.baseMaps.OpenStreetMap);
    });
  }

  loadCostParc() {
    this.loader = true;
    this.error = false;
    var date = new Date();
    date.setMonth(this.selectedMonth)
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.now = lastDay;
    this.dashbordInfo = null;
    this.dashbordService.getDashbordInfo(-1, -1, {
        startDate: firstDay.getTime(),
        endDate: lastDay.getTime()
    }).subscribe(value => {
      this.loader = false;
        this.configHighcharts(value);
    }, error =>{
      this.error = true;
      this.loader = false;
    });
};

onMonthChange(){
  this.loadCostParc();
}

  ngOnInit() {
    this.loadGroups();

    let endTime = new Date();
    let month = endTime.getMonth() + 1;
    let startTime = new Date(
      endTime.getFullYear() + "-" + month + "-" + endTime.getDate()
    );

    let dateInterval = new DateInterval();


    dateInterval.startDate = startTime;
    dateInterval.endDate = endTime;
    this.mileageService
      .getMileageDetailsParc(dateInterval)
      .subscribe(response => {
        this.mileageManipulation(response);
      });


  }

  loadGroups(){
    this.groupService.getAllGroups().subscribe(groups => {
      this.groups =groups;
    })
    console.log("$$$$$$ grt all groupe",this.groups)
    console.log(this.allGroups)
  }

  mileageManipulation(mileages: Mileage[]) {
    for (let j = 0; j < mileages.length; j++) {
      this.prototypeToHHMMSS(mileages[j]);
      this.mileages.push(mileages[j]);
    }
  }

  prototypeToHHMMSS(mileage: Mileage) {
    var hours = Math.floor(mileage.drivingDuration / 3600);
    var minutes = Math.floor((mileage.drivingDuration - hours * 3600) / 60);
    var seconds = mileage.drivingDuration - hours * 3600 - minutes * 60;
    if (hours == 0)
      mileage.drivingDurationStr = minutes + " min " + seconds + " sec";
    else {
      mileage.drivingDurationStr =
        hours + " h " + minutes + " min " + seconds + " sec";
    }
  }

  showDashboard(costDetails: DashbordInfo) {
    this.dashbordInfo = null;
    this.now = new Date();
    this.selectedMonth = this.now.getMonth();

      this.configHighcharts(costDetails);
      this.dashboardModal.show();


  }

  configHighcharts(dashbordInfo: DashbordInfo) {
    this.dashbordInfo = dashbordInfo;
    this.options = {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        }
      },
      title: {
        text: ""
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          depth: 35,
          dataLabels: {
            enabled: true,
            format: "{point.name}"
          }
        }
      },
      series: [
        {
          type: "pie",
          name: "percentage",
          data: [
            [
              "Assurances :" + this.chartConfig.numberWithSpaces(dashbordInfo.insurance) +" DH",
              (dashbordInfo.insurance * 100) / dashbordInfo.total
            ],
            [
              "Visites :" + this.chartConfig.numberWithSpaces(dashbordInfo.visit) +" DH",
              (dashbordInfo.visit * 100) / dashbordInfo.total
            ],
            [
              "Vignettes :" + this.chartConfig.numberWithSpaces(dashbordInfo.roadTaxes) +" DH",
              (dashbordInfo.roadTaxes * 100) / dashbordInfo.total
            ],
            [
              "C.exploitation :" +
              this.chartConfig.numberWithSpaces(dashbordInfo.operationalCertification) +
              " DH",
              (dashbordInfo.operationalCertification * 100) /
              dashbordInfo.total
            ],
            [
              "Permis circulation :" +
              this.chartConfig.numberWithSpaces(dashbordInfo.permitCirculation) +
              " DH",
              (dashbordInfo.permitCirculation * 100) /
              dashbordInfo.total
            ],
            [
              "Carnet métrologique :" +
              this.chartConfig.numberWithSpaces(dashbordInfo.metologicalNotbook) +
              " DH",
              (dashbordInfo.metologicalNotbook * 100) /
              dashbordInfo.total
            ],
            [
              "Extincteurs :" + this.chartConfig.numberWithSpaces(dashbordInfo.extincteurs) +" DH",
              (dashbordInfo.extincteurs * 100) / dashbordInfo.total
            ],
            [
              "Taxe tonnage :" + this.chartConfig.numberWithSpaces(dashbordInfo.tonnageTax) +" DH",
              (dashbordInfo.tonnageTax * 100) / dashbordInfo.total
            ],
            [
              "Carburant : " + this.chartConfig.numberWithSpaces(dashbordInfo.fuel) +" DH",
              (dashbordInfo.fuel * 100) / dashbordInfo.total
            ],
            {
              name: "Vidange : " + this.chartConfig.numberWithSpaces(dashbordInfo.oilChange) +" DH",
              y: (dashbordInfo.oilChange * 100) / dashbordInfo.total,
              sliced: true,
              selected: true
            },
            [
              "Pneu : " + this.chartConfig.numberWithSpaces(dashbordInfo.tireMaintenance) +" DH",
              (dashbordInfo.tireMaintenance * 100) /
              dashbordInfo.total
            ],
            [
              "Batterie : " + this.chartConfig.numberWithSpaces(dashbordInfo.batteryMaintenance) +" DH",
              (dashbordInfo.batteryMaintenance * 100) /
              dashbordInfo.total
            ],
            [
              "Reparation : " + this.chartConfig.numberWithSpaces(dashbordInfo.reparation) +" DH",
              (dashbordInfo.reparation * 100) / dashbordInfo.total
            ],
            [
              "Dégâts : " + this.chartConfig.numberWithSpaces(dashbordInfo.losses) +" DH",
              (dashbordInfo.losses * 100) / dashbordInfo.total
            ],
            [
              "Frein : " + this.chartConfig.numberWithSpaces(dashbordInfo.brakeMaintenance) +" DH",
              (dashbordInfo.brakeMaintenance * 100) /
              dashbordInfo.total
            ],
            [
              "Location : " + this.chartConfig.numberWithSpaces(dashbordInfo.leasing) +" DH",
              (dashbordInfo.leasing * 100) / dashbordInfo.total
            ],
            [
              "Parking : " + this.chartConfig.numberWithSpaces(dashbordInfo.parking) +" DH",
              (dashbordInfo.parking * 100) / dashbordInfo.total
            ],
            [
              "Lavage : " + this.chartConfig.numberWithSpaces(dashbordInfo.lavage) +" DH",
              (dashbordInfo.lavage * 100) / dashbordInfo.total
            ],
            [
              "Péage : " + this.chartConfig.numberWithSpaces(dashbordInfo.tool) +" DH",
              (dashbordInfo.tool * 100) / dashbordInfo.total
            ],
            [
              "Charge conducteur :" + this.chartConfig.numberWithSpaces(dashbordInfo.driverCharge) +" DH",
              (dashbordInfo.driverCharge * 100) / dashbordInfo.total
            ],
            [
              "Chiffre d'affaire :" + this.chartConfig.numberWithSpaces(dashbordInfo.turnover) +" DH",
              (dashbordInfo.turnover * 100) / dashbordInfo.total
            ]
          ]
        }
      ]
    }
    Highcharts.chart('dashbordpie', this.options);

  }

ngOnDestroy(): void {
      if(this.loaded$){this.loaded$.unsubscribe()}
      if(this.allGroups){this.allGroups.unsubscribe()}
  }




}
