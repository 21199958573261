import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RealtimeService } from 'src/app/client-management/realtime/services/realtime.service';
import { ToolKmJourService } from 'src/app/client-management/realtime/services/tool-km-jour.service';

@Component({
  selector: 'component-en-cours',
  templateUrl: './en-cours.component.html',
  styleUrls: ['./en-cours.component.css'],
}) 
export class EnCoursComponent implements OnInit, OnDestroy {
  toolKmSubscription: Subscription;


  constructor(
    private toolKmJourService: ToolKmJourService,
    private router: Router,
    private route: ActivatedRoute,
    private realTimeService: RealtimeService
  ) {}

  ngOnInit() {
    this.toolKmSubscription= this.toolKmJourService.getEnCoursPath().subscribe((trajet) => {
      this.toolKmJourService.setChildPath(trajet);
      this.toolKmJourService.setDestination('../../');
      this.realTimeService.setBarToolStatus(false);
      this.router.navigate(['trajet'], {relativeTo: this.route});
    })
    
  }

  ngOnDestroy(): void {
    this.toolKmSubscription.unsubscribe();
  }


}
