<ngx-loading [show]="globalLoading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="row">

  <div class="col-md-2" style="margin-top: -10px; margin-bottom: 40px;">
    <span (click)="showBreakForm()" title="Panne" style="cursor: pointer;">
      <i *ngIf="!loading" class="fa fa fa-gear add break-down" aria-hidden="true"></i>
      <i *ngIf="loading" class="fa fa fa-gear fa-spin add break-down" aria-hidden="true"></i></span>
  </div>

  <div class="col-md-8">
    <div *ngIf="loading" style="font-size: 12px; text-align: center;">
      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
    </div>
    <div *ngIf="updateTrackingTimer && !loading" style="font-size: 12px;text-align: center;">
      <i class="fa fa-hourglass-half" aria-hidden="true"></i>
      Mise à jour dans {{updateTrackingTimer}} seconds...
    </div>
  </div>
  <div class="col-md-2 text-right" style="margin-top: -10px; margin-bottom: 40px;">
    <span (click)="loadCurrentStatesWithMileage()" title="Actualiser" style="cursor: pointer;">
      <i *ngIf="!loading" class="fa fa-refresh add" aria-hidden="true"></i>
      <i *ngIf="loading" class="fa fa-refresh fa-spin add" aria-hidden="true"></i></span>
  </div>
</div>

<ng-container *ngIf="currentStateByGroup.length < 1">
  Aucune donnée
</ng-container>

<div [@cardAnimation]="currentStateByGroup.length">

  <ng-container *ngFor="let currentState of currentStateByGroup">

    <div #groupData class="row">

      <!-- ==================== Group Section ======================= -->
      <div class="col-md-2 col-xl-2 col-xs-2">
        <div class="card blueStyle">
          <div class="title">Group <b>({{currentState?.nbVehicule}})
            </b> </div>
          <span class="glyphicon fa fa-car"></span>
          <div class="value">
            <span *ngIf="!loading" class="font-size" title="{{currentState?.group}}">{{currentState?.group}}</span>
            <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
          </div>
          <div class="stat" *ngIf="!loading">
            <b>{{currentState?.mileage | number: '2.1-2'}}
            </b> KM
          </div>
        </div>
      </div>

      <!-- ==================== En mission Section ======================= -->
      <div class="col-md-2 col-xl-2 col-xs-2">
        <div class="card greenStyle">
          <div class="title">En mission</div>
          <span class="glyphicon glyphicon-play"></span>
          <div class="value">
            <span *ngIf="!loading" class="font-size">{{currentState?.start?.length}}</span>
            <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
          </div>
          <div class="stat" *ngIf="!loading && currentState?.start?.length > 0 && !currentState?.start?.isOpen;"
            (click)="currentState?.start.isOpen = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de
            </b> details
          </div>
          <div class="stat" *ngIf="!loading && (currentState?.start?.length == 0)">
            <b>Aucun </b> véhicule
          </div>
          <div class="stat overflowdv" *ngIf="currentState?.start?.isOpen" (click)="currentState?.start.isOpen = false">
            <div *ngFor="let startState of currentState?.start">
              <span>
                {{realTimeService.getVehiculeAlias(startState)}}
              </span>
            </div>
            <i class="fa fa-caret-up" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <!-- ==================== Arrêt Section ======================= -->
      <div class="col-md-2 col-xl-2 col-xs-2">
        <div class="card redStyle">
          <div class="title">Arrêt</div>
          <span class="glyphicon glyphicon-pause"></span>
          <div class="value">
            <span *ngIf="!loading" class="font-size">{{currentState?.stop?.length}}</span>
            <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
          </div>
          <div class="stat" *ngIf="!loading && currentState?.stop?.length > 0 && !currentState?.stop?.isOpen;"
            (click)="currentState?.stop.isOpen = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de
            </b> details
          </div>
          <div class="stat" *ngIf="!loading && (currentState?.stop?.length == 0)">
            <b>Aucun </b> véhicule
          </div>
          <div class="stat overflowdv" *ngIf="currentState?.stop?.isOpen" (click)="currentState?.stop.isOpen = false">
            <div *ngFor="let stopState of currentState?.stop">
              <span>
                {{realTimeService.getVehiculeAlias(stopState)}}
              </span>
            </div>
            <i class="fa fa-caret-up" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <!-- ==================== Arrêt POI Section ======================= -->
      <div class="col-md-2 col-xl-2 col-xs-2">
        <div class="card yellowStyle">
          <div class="title">Parking</div>
          <span class="glyphicon glyphicon-th"></span>
          <div class="value">
            <span *ngIf="!loading" class="font-size">{{currentState?.parking?.length}}</span>
            <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
          </div>
          <div class="stat" *ngIf="!loading && currentState?.parking?.length > 0 && !currentState?.parking?.isOpen;"
            (click)="currentState?.parking.isOpen = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de
            </b> details
          </div>
          <div class="stat" *ngIf="!loading && (currentState?.parking?.length == 0)">
            <b>Aucun </b> véhicule
          </div>
          <div class="stat overflowdv" *ngIf="currentState?.parking?.isOpen"
            (click)="currentState?.parking.isOpen = false">
            <div *ngFor="let parkingState of currentState?.parking">
              <span>
                {{realTimeService.getVehiculeAlias(parkingState)}}
              </span>
            </div>
            <i class="fa fa-caret-up" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <!-- ==================== En Panne Section ======================= -->
      <div class="col-md-2 col-xl-2 col-xs-2">
        <div class="card blackStyle">
          <div class="title">En Panne</div>
          <span class="glyphicon glyphicon-remove"></span>
          <div class="value">
            <span *ngIf="!loading" class="font-size">{{currentState?.panne?.length}}</span>
            <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
          </div>
          <div class="stat" *ngIf="!loading && currentState?.panne?.length > 0 && !currentState?.panne?.isOpen;"
            (click)="currentState?.panne.isOpen = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de
            </b> details
          </div>
          <div class="stat" *ngIf="!loading && (currentState?.panne?.length == 0)">
            <b>Aucun </b> véhicule
          </div>
          <div class="stat overflowdv" *ngIf="currentState?.panne?.isOpen" (click)="currentState?.panne.isOpen = false">
            <div *ngFor="let panneState of currentState?.panne">
              <span>
                {{realTimeService.getVehiculeAlias(panneState)}}
              </span>
            </div>
            <i class="fa fa-caret-up" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <!-- Percentage section -->
      <div class="col-md-2 col-xl-2 col-xs-2">
        <div class="card light-sky">
          <div class="col-md-6 col-xl-6 col-xs-6">
            <h3 class="progress-title" title="Arrêt">A - {{(currentState?.stop?.length / currentState?.nbVehicule) * 100
              | number: '1.0-0'
              }}%</h3>
            <div class="progress pink">
              <div class="progress-bar"
                [ngStyle]="{'width': ((currentState?.stop?.length / currentState?.nbVehicule) * 100) + '%'}"
                style="background:#f72754;"></div>
            </div>
          </div>

          <div class="col-md-6 col-xl-6 col-xs-6">
            <h3 class="progress-title" title="Parking">P - {{(currentState?.parking?.length / currentState?.nbVehicule)
              * 100 |
              number: '1.0-0' }}%</h3>
            <div class="progress yellow">
              <div class="progress-bar"
                [ngStyle]="{'width': ((currentState?.parking?.length / currentState?.nbVehicule) * 100) + '%'}"
                style="background:#ffae03;"></div>
            </div>
          </div>

          <div class="col-md-6 col-xl-6 col-xs-6">
            <h3 class="progress-title" title="En mission">M - {{(currentState?.start?.length / currentState?.nbVehicule)
              * 100 |
              number: '1.0-0' }}%</h3>
            <div class="progress green">
              <div class="progress-bar"
                [ngStyle]="{'width': ((currentState?.start?.length / currentState?.nbVehicule) * 100) + '%'}"
                style="background:#38f727;"></div>
            </div>
          </div>

          <div class="col-md-6 col-xl-6 col-xs-6">
            <h3 class="progress-title" title="en Panne">Pn - {{(currentState?.panne?.length / currentState?.nbVehicule)
              * 100 | number: '1.0-0'
              }}%</h3>
            <div class="progress black">
              <div class="progress-bar"
                [ngStyle]="{'width': ((currentState?.panne?.length / currentState?.nbVehicule) * 100) + '%'}"
                style="background:#000;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 20px;">
    </div>
  </ng-container>
</div>

<!-- ========================================== Panne Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #breakModal="bs-modal" bsModal role="dialog" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- ========================== Modal TITLE (START) ===============================-->
      <div class="modal-header">

        <button type="button" class="close" (click)="breakModal.hide()">&times;</button>

        <h4 class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
          <i class="fa fa-gear" aria-hidden="true"></i>
          <span>Panne</span>
        </h4>
      </div>
      <!-- ========================== Modal TITLE (END) ===============================-->

      <div class="modal-body">
        <div class="box-body">
          <app-break-down-form [vehicules]="vehicules" (breakDownSaved)="breakWasSaved()"></app-break-down-form>
        </div>
      </div>

      <div class="box-footer">
        <button (click)="breakModal.hide()" class="btn btn-default pull-right">
          Fermer
        </button>
      </div>

    </div>

  </div>
</div>
<!-- ========================================== Panne Modal (END) ====================================================-->
