import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';

import { SigninService } from '../authentification/signin/signin.service';
import { AlertManagementService } from '../client-management/alert-management/alert-management.service';
import { EmailsService } from '../utils/emails/emails.service';
import { HeaderService } from './header.service';
import { RealtimeService } from '../client-management/realtime/services/realtime.service';
import { DataManagementService } from '../client-management/data-management';
import { MapService } from '../utils/leaflet/service/map.service';
import { CanalService } from '../client-management/canal-performance';
import { MomentModule } from 'ngx-moment';
import { CapitalizefirstPipe } from '../pipes/capitalizefirst.pipe';
import { SharedPipesModule } from '../pipes/shared-pipes.module';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    FormsModule,
    MomentModule,
    SharedPipesModule
  ],
  providers: [
    SigninService,
    AlertManagementService,
    EmailsService,
    HeaderService,
    RealtimeService,
    DataManagementService,
    MapService,
    CanalService,
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
