import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassificationKMChartComponent } from './classification-kmchart.component';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    ClassificationKMChartComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot()
  ],
  providers: [
    ChartConfigService
  ],
  exports: [
    ClassificationKMChartComponent
  ]
})
export class ClassificationKMChartModule { }
