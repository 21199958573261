<section class="content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
    <div class="row" style="position: absolute; width: 100%;">
        <div class="datatable-container" id="datatable-container" style="margin-top: -10px;">
            <div class="col-md-12 datatable-container-col">
                <div class="box" style="border-radius: 0px;">
                    <div class="overlay" *ngIf="!isResultLoaded">
                        <i class="fa fa-refresh fa-spin"></i>
                    </div>
                    <div class="box-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <i class="col-sm-1 fa fa-list table-toggle" (click)="tableToggle()"></i>
                                <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                                <div class="col-sm-2 grp-slct">
                                    <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup"
                                        (ngModelChange)="chooseGroup($event)">
                                        <option *ngFor="let group of groups" [ngValue]="group" style="font-size: 14px;">
                                            <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst | truncate:[30]}}  / </span>  {{group.nom | capitalizefirst}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                                <div class="col-sm-2 vhcl-slct">
                                    <select class="form-control input-sm" name="vehicules"
                                        [(ngModel)]="selectedVehicule" (ngModelChange)="chooseVehicule($event)">
                                        <option *ngFor="let vehicule of selectedGroup.vehicules"
                                            style="font-size: 14px;" [ngValue]="vehicule">{{dataManagementService.getVehiculeName(vehicule)
                                            |
                                            capitalizefirst}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label dd-lbl">Date début</label>
                                <div class="col-sm-2 dd-npt">
                                    <div class="input-group">
  
                                        <input type="text" (click)="expandTable()" class="form-control input-sm"
                                            bsDatepicker name="startDate" [(ngModel)]="startDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                                            placement="bottom" [maxDate]="endDate" autocomplete="off">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>
  
                                    </div>
                                </div>
                                <label class="col-sm-1 control-label df-lbl">Date fin</label>
                                <div class="col-sm-2 df-npt">
  
                                    <div class="input-group">
  
                                        <input type="text" (click)="expandTable()" class="form-control input-sm"
                                            bsDatepicker name="endDate" [(ngModel)]="endDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm', containerClass: 'theme-dark-blue'}"
                                            placement="bottom" [minDate]="startDate" autocomplete="off">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>
  
                                    </div>
  
                                </div>
                                <div class="col-sm-1 form-btns" style="display: flex; align-items: center; padding-left: 0 !important;">
                                  <mat-slide-toggle [(ngModel)]="isByClient" name="isByClient"
                                  title="Poi Client" class="mat-slide-padding" color="primary"></mat-slide-toggle>
                                  <button type="button" class="btn btn-success btn-flat btn-sm" style="border-radius: 4px;"
                                        (click)="getAllPaths()">
                                        <b><i class="fa fa-search" aria-hidden="true"></i></b> 
                                    </button>
                                    <button class="btn btn-primary btn-sm excel-button" (click)="exportPaths()"
                                        [disabled]="paths.length == 0">
                                        <i *ngIf="!loader" class="fa fa-file-excel-o"></i>
                                        <i *ngIf="loader" class="fa fa-refresh fa-spin"></i>
                                    </button>
                                    <button class="btn btn-primary btn-sm pdf-button" (click)="exportPathsPDF()"
                                        [disabled]="paths.length == 0" title="PDF">
                                        <i *ngIf="!pdfLoader" class="fa fa-file-pdf-o"></i>
                                        <i *ngIf="pdfLoader" class="fa fa-refresh fa-spin"></i>
                                    </button>
  
                                    
                                </div>
                            </div>
                        </form>
                        <table class="table table-striped" style="font-size: 12px;font-weight: bold" width="100%">
                            <thead>
                                <tr>
                                    <th width="9%" style="text-align: center"><i class="fa fa-clock-o"
                                            aria-hidden="true"></i>
                                        Date Départ
                                    </th>
                                    <th width="24%" style="text-align: center">Lieu Départ</th>
                                    <th width="9%" style="text-align: center"><i class="fa fa-clock-o"
                                            aria-hidden="true"></i>
                                        Date Arrivée
                                    </th>
                                    <th width="24%" style="text-align: center">Lieu Arrivée</th>
                                    <th width="7%" style="text-align: center">Kilométrage</th>
                                    <th width="7%" style="text-align: center"> V Max</th>
                                    <th width="10%" style="text-align: center">Durée Trajet</th>
                                    <th width="10%" style="text-align: center">Durée Arrêt</th>
                                </tr>
                            </thead>
                            <tbody class="datatable-body">
                                <ng-container *ngFor="let path of paths">
                                    <tr (click)="addPathToArray(path, $event)" [class.path]="PathSelected(path)">
                                        <td width="8%" data-toggle="tooltip"
                                            title="{{path.displayBeginPathTime | date:'dd-MM-y HH:mm'}}">
                                            {{path.beginPathTime | date:'dd-MM HH:mm'}}
                                        </td>
                                        <td width="25%" data-toggle="tooltip"
                                            title="{{path.startAddress}}">
                                            {{path.startAddress | truncate :[45]}}
                                        </td>
                                        <td width="8%" data-toggle="tooltip"
                                            title="{{path.displayEndPathTime | date:'dd-MM-y HH:mm'}}">
                                            {{path.endPathTime | date:'dd-MM HH:mm'}}
                                        </td>
                                        <td width="25%" data-toggle="tooltip" title="{{path.endAddress}}">
                                            {{path.endAddress | truncate :[45]}}
                                        </td>
                                        <td width="7%" style="text-align: center" data-toggle="tooltip"
                                            title="{{path.distanceDriven | number:'1.2-2'}} km">
                                            {{path.distanceDriven | number:'1.2-2'}}
                                        </td>
                                        <td width="7%" style="text-align: center" data-toggle="tooltip"
                                            title="{{path.maxSpeed}} Km/h">
                                            {{path.maxSpeed}}
                                        </td>
                                        <td width="10%" data-toggle="tooltip" title="{{path.pathDurationStr}}">
                                            {{path.pathDurationStr}}
                                        </td>
                                        <td width="10%" data-toggle="tooltip" title="{{path.nextStopDurationStr}}">
                                            {{path.nextStopDurationStr | truncate :[15]}}
                                        </td>
                                    </tr>
  
                                    <tr *ngIf="PathSelected(path)">
  
                                        <td colspan="4" style="text-align: center">
  
                                            <button style="font-size: 10px;" type="button"
                                                title="Afficher adresse google" class="btn btn-success btn-xs"
                                                (click)="displayGoogleAdress(path)">
                                                <i class="fa fa-google" aria-hidden="true"></i>
                                            </button>
  
  
                                            <a href="https://www.google.com/maps/dir/'{{pathsArray[0]?.beginPathLatitude}},{{pathsArray[0]?.beginPathLongitude}}'/{{pathsArray[0]?.endPathLatitude}},{{pathsArray[0]?.endPathLongitude}}/"
                                                target="_black" style="font-size: 10px;" title="Afficher trajet google"
                                                class="btn btn-primary btn-xs">
                                                <i class="fa fa-road" aria-hidden="true"></i>
                                            </a>
                                        </td>
                                    </tr>
  
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="map-container">
            <app-leaflet></app-leaflet>
        </div>
    </div>
  </section>