import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { StpsService } from 'src/app/client-management/realtime/services/stops.service';
import { ToolKmJourService } from 'src/app/client-management/realtime/services/tool-km-jour.service';
import { FormattedStopDto } from 'src/app/shared/model/sinistre.model';


@Component({
  selector: 'app-stops-parents',
  templateUrl: './stops-parents.component.html',
  styleUrls: ['./stops-parents.component.css']
})
export class StopsParentsComponent implements OnInit, OnDestroy{

  @Input() kmJourStop: any;  // Ensure this is an @Input property
  @Output() showingItems = new EventEmitter();  // Ensure event emitter is correctly defined


  isDataLoaded$: Subscription;
  clickedMarker$: Subscription;

  isDataLoaded: boolean = false;
  clickedMarker: L.Marker | null = null;


  constructor(
    private toolKmJourService: ToolKmJourService,
    public stpsService: StpsService
    ) {
      console.log("************ Load ************")
      console.log(this.kmJourStop)
    }


  ngOnInit(): void {
    this.isDataLoaded$ = this.toolKmJourService.isLoaded().subscribe(isLoaded => {
      this.isDataLoaded = isLoaded;
    });
    this.clickedMarker$ = this.stpsService.getClickedMarker().subscribe(clicked => {
      this.clickedMarker = clicked;
    });
  }

  showItem(){
    this.showingItems.emit();
  }
  activatSimulation(event: any){
    event.stopPropagation();
    this.stpsService.setModeSimulation(!this.stpsService.isInSimulation()) ; 
    this.stpsService.hideAllMarkersAndPolylines(this.clickedMarker);
  }

  ngOnDestroy(): void {
     if(this.isDataLoaded$){this.isDataLoaded$.unsubscribe()} 
     if(this.clickedMarker$){this.clickedMarker$.unsubscribe()} 
  }

}
