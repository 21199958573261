import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TransportTypeService } from '../parametrage/transport-type';
import { ChargesPfbService } from '../charges-pfb.service';
import { ImportExportService } from '../../import-export';
import { RotationChargeComponent } from './rotation-charge/rotation-charge.component';
import { ActivityChartComponent, ActivityTimeChartComponent, KilomtrageChartComponent, StopChartComponent} from '../../activity-overview-management';
import { TurnoverAchievedComponent } from './turnover-achieved/turnover-achieved.component';
import { ChargesPfbTrackingComponent } from './charges-pfb-tracking.component';
import { RotationChargeModule } from './rotation-charge/rotation-charge.module';
import { StopChartModule } from '../../activity-overview-management/activity-overview/dashbord-chart/stop-chart/stop-chart.module';
import { ActivityTimeChartModule } from '../../activity-overview-management/activity-overview/dashbord-chart/activity-time-chart/activity-time-chart.module';
import { KilomtrageChartModule } from '../../activity-overview-management/activity-overview/dashbord-chart/kilomtrage-chart/kilomtrage-chart.module';
import { TurnoverAchievedModule } from './turnover-achieved/turnover-achieved.module';
import { ActivityChartModule } from '../../activity-overview-management/activity-overview/dashbord-chart/activity-chart/activity-chart.module';
import { CorrelationLocationModule } from './correlation-location/correlation-location.module';
import { CorrelationGasoilModule } from './correlation-gasoil/correlation-gasoil.module';
import { LocationCostComponent } from './location-cost/location-cost.component';
import { LocationCostModule } from './location-cost/location-cost.module';
import { FuelingChargeModule } from './fueling-charge/fueling-charge.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { CurrentStateModule } from '../../overview/current-state/current-state.module';

@NgModule({
  declarations: [
    ChargesPfbTrackingComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(), // Include Toastr for notifications
    ModalModule.forRoot(),  // Include Bootstrap Modals
    BsDatepickerModule.forRoot(), // Include Bootstrap Datepicker
    DatepickerModule.forRoot(),
    RotationChargeModule,
    StopChartModule,
    ActivityTimeChartModule,
    KilomtrageChartModule,
    TurnoverAchievedModule,
    ActivityChartModule,
    CorrelationLocationModule,
    CorrelationGasoilModule,
    LocationCostModule,
    FuelingChargeModule,
    SharedPipesModule,
    CurrentStateModule,
  ],
  providers: [
    ChargesPfbService,
    ImportExportService,
    TransportTypeService
  ],
  exports: [ChargesPfbTrackingComponent]
})
export class ChargesPfbTrackingModule { }
