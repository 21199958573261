import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Vehicule, Group } from '../../../data-management/data-management.model';
import { DataManagementService } from '../../../data-management/data-management.service';

@Component({
  selector: 'app-ca-realization',
  templateUrl: './ca-realization.component.html',
  styleUrls: ['./ca-realization.component.css']
})
export class CaRealizationComponent implements OnInit {

  selectedVehicule: Vehicule | null = null;

  selectedGroup: Group | null = null;

  getVehiculeWasSelectedSubscription!: Subscription;

  getGroupWasSelectedSubscription!: Subscription;

  documentType: 'CA_REALIZATION' = 'CA_REALIZATION';

  constructor(public toastr: ToastrService, private dataManagementService: DataManagementService) { }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
      this.selectedGroup = this.dataManagementService.selectedGroup;
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.selectedVehicule = vehicule;
    });
    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.selectedGroup = group;
    });
  }

  ngOnDestroy() {
    if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
  }

}
