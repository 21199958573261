import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopsParentsComponent } from './stops-parents.component';
import { DurationPipesModule } from 'src/app/pipes/duration-sec.module';

@NgModule({
    declarations: [StopsParentsComponent],
    imports: [CommonModule,DurationPipesModule],  // Import CommonModule to access Angular directives like ngIf, ngFor, etc.
    exports: [StopsParentsComponent]  // Export the component to use it in other modules
})
export class StopsParentsModule { }
