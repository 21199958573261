import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { AvailableVehiculesOverviewDto } from '../../overview';

@Injectable()
export class MonthlyAvailableVehiculesService {

  constructor(private _http: HttpClient) { }

  public getMonthlyAvailableNumberOfVehicles(groupId : number, month : number): Observable<AvailableVehiculesOverviewDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .get<AvailableVehiculesOverviewDto[]>(dns + 'activities/availableVehicles/month/'+ month +'/groupId/'+groupId, options);
  }
  
}
