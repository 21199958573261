import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DataManagementService, VehiculesDto, Group } from 'src/app/client-management/data-management';
import { ToastrService } from 'ngx-toastr';
import { GeoMarketingService } from '../geo-marketing.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { GeoMarketingAssignmentAddDto, GeoMarketingAssignmentInfoDto, GeoMarketingInfoDto } from '../geo-marketing';

@Component({
  selector: 'app-visit-form',
  templateUrl: './visit-form.component.html',
  styleUrls: ['./visit-form.component.scss'],
  providers: [DataManagementService]
})
export class VisitFormComponent implements OnInit {

  loading: boolean = false;


  @Input()
  mode: 'ADD' | 'UPDATE' = 'ADD';

  @Output()
  geoMarketingAssignmentSaved: EventEmitter<GeoMarketingAssignmentInfoDto> = new EventEmitter<GeoMarketingAssignmentInfoDto>();

  @Output()
  geoMarketingAssignmentUpdated: EventEmitter<GeoMarketingAssignmentInfoDto> = new EventEmitter<GeoMarketingAssignmentInfoDto>();


  vehicules: VehiculesDto[] = [];
  groups: Group[] = [];

  error: { errorMessage: string } = { errorMessage: '' };

  geoMarketingAssignmentAddDto: GeoMarketingAssignmentAddDto = new GeoMarketingAssignmentAddDto();

  @Input()
  set geoMarketingAssignment(geoMarketingAssignment: any) {
    this.vehiculesSelected = [];
    if (geoMarketingAssignment != null) {
      this.geoMarketingAssignmentAddDto = Object.assign({}, geoMarketingAssignment);
      this.geoMarketingAssignmentAddDto.dateDebut = new Date(geoMarketingAssignment.dateDebut);
      this.geoMarketingAssignmentAddDto.dateFin = new Date(geoMarketingAssignment.dateFin);
      this.geoMarketingAssignmentAddDto.idVehicule = geoMarketingAssignment.vehicule.idVehicule;
      if (geoMarketingAssignment.geoMarketingMn)
        this.geoMarketingAssignmentAddDto.idgeoMarketingMn = geoMarketingAssignment.geoMarketingMn.id;
      if (geoMarketingAssignment.geoMarketingTu)
        this.geoMarketingAssignmentAddDto.idgeoMarketingTu = geoMarketingAssignment.geoMarketingTu.id;
      if (geoMarketingAssignment.geoMarketingWd)
        this.geoMarketingAssignmentAddDto.idgeoMarketingWd = geoMarketingAssignment.geoMarketingWd.id;
      if (geoMarketingAssignment.geoMarketingTr)
        this.geoMarketingAssignmentAddDto.idgeoMarketingTr = geoMarketingAssignment.geoMarketingTr.id;
      if (geoMarketingAssignment.geoMarketingFr)
        this.geoMarketingAssignmentAddDto.idgeoMarketingFr = geoMarketingAssignment.geoMarketingFr.id;
      if (geoMarketingAssignment.geoMarketingSt)
        this.geoMarketingAssignmentAddDto.idgeoMarketingSt = geoMarketingAssignment.geoMarketingSt.id;
      this.vehiculesSelected.push({
        text: geoMarketingAssignment.vehicule.matricule,
        id: geoMarketingAssignment.vehicule.idVehicule
      })
    } else {
      this.geoMarketingAssignmentAddDto = new GeoMarketingAssignmentAddDto();
    }
  }

  geoMarketing: GeoMarketingInfoDto[] = [];
  maxDate: Date = new Date();

  vehiculesItems: any[] = [];
  vehiculesSelected: { text: string; id: number }[] = [];
  selectedVehiculesHash: string = "";
  groupsItems: { text: string | number; id: number }[] = [];
  selectedGroupsItems: { text: string; id: number };
  selectedGroupsHash: string = "";

  constructor(private dataManagementService: DataManagementService,
    private toastr: ToastrService,
    private geoMarketingService: GeoMarketingService,
    private localeService: BsLocaleService) { }


  ngOnInit() {
    this.localeService.use('fr');
    /**get only groups with more than one vehicule */
    this.dataManagementService.getAllGroupsDetails('').subscribe(res => {
      this.groups = res.filter(group => group.vehicules.length > 0);
      this.mapGroupsToItems(this.groups);
      if (this.groups) {
        this.getVehicules();
      }
    });
    this.loadGeoMarketing();
  }


  /**get vehicule  Ids */
  getVehicules() {
    this.groups.forEach(group => {
      group.vehicules.forEach(vehicule => {
        let v = new VehiculesDto();
        v.matricule = vehicule.matricule;
        v.idVehicule = vehicule.idVehicule;
        if (this.checkIfVehiculeExists(v)) {
          this.vehicules.push(v);
        }
      });
    });

    this.vehiculesToItems(this.vehicules, 0);
  }

  mapGroupsToItems(groups: Group[]) {
    this.groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      let parentName: string;
      if (groups[i].nameGroupParent) {
        parentName = groups[i].nameGroupParent + ' / ' + groups[i].nom;
      } else { parentName = groups[i].nom }
      this.groupsItems.push({
        id: groups[i].idGroupe,
        text: parentName
      });
    }
  }

  mapSelectedGroupsToItems(groups: Group) {
    this.selectedGroupsItems;
    this.selectedGroupsItems.id = groups.idGroupe
    this.selectedGroupsItems.text = groups.nom
    this.selectedGroups(this.selectedGroupsItems);
  }

  selectedGroups(groups: { id: number; text: string }) {
    let g = this.groups.filter(item => {
      return item.idGroupe === groups.id ? item : null;
    });
    if (g.length > 0) {
      this.vehiculesToItems(g[0].vehicules, g[0].idGroupe);
    }

  }

  vehiculesToItems(vehicules: VehiculesDto[], idGroup: number) {
    this.vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesItems.push({
        id: {
          id: vehicules[i].idVehicule,
          idG: idGroup
        },
        text: vehicules[i].matricule
      });
    }
  }

  itemsToVehicules(
    items: {
      id: {
        id: number;
      };
      text: string;
      parent: number;
    }[]
  ) {
    let vehicules: any;
    for (let i = 0; i < items.length; i++) {
      vehicules.push({
        idVehicule: items[i].id.id,
        matricule: items[i].text
      });
    }
    this.selectedVehicules(vehicules);

  }

  selectedVehicules(vehicules: { id: { id: number, idG: number }; text: string }) {
    this.geoMarketingAssignmentAddDto.idVehicule = vehicules.id.id;
  }


  /** check if vehicule already exists */
  checkIfVehiculeExists(vehicule: VehiculesDto) {
    let v = this.vehicules.filter(ve => ve.idVehicule === vehicule.idVehicule);
    if (v.length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  loadGeoMarketing() {
    this.geoMarketingService.getGeoMarketing()
      .subscribe(geoMarketing => {
        this.geoMarketing = geoMarketing;
      }, err => {
        this.toastr.error("Erreur lors de chargements", "Secteur")
      })
  }

/** ajouter **/
  onSaveVisit() {
    this.loading = true;
    /*** check if the day is selected or not if not then
     * set the value of the day not selected to null */
    if (!this.geoMarketingAssignmentAddDto.monday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingMn = null;
    if (!this.geoMarketingAssignmentAddDto.tuesday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingTu = null;
    if (!this.geoMarketingAssignmentAddDto.wednesday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingWd = null;
    if (!this.geoMarketingAssignmentAddDto.thursday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingTr = null;
    if (!this.geoMarketingAssignmentAddDto.friday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingFr = null;
    if (!this.geoMarketingAssignmentAddDto.saturday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingSt = null;

    this.geoMarketingService.addGeoMarketingAssignment(this.geoMarketingAssignmentAddDto)
      .subscribe(geoMarketing => {
        this.geoMarketingAssignmentSaved.emit(geoMarketing);
        this.geoMarketingAssignmentAddDto = new GeoMarketingAssignmentAddDto();
        this.loading = false;
        this.toastr.success("Enregistré", "Tournée");
      }, err => {
        this.loading = false;
        this.error = JSON.parse(JSON.stringify(err.error || null));

        if (!this.error.errorMessage) {
          this.error.errorMessage = "SERVER ERROR";
        }
        this.toastr.error(this.error.errorMessage, "Tournée");
      })
  }

  /** modifier **/
  onUpdateVisit() {
    this.loading = true;
    /*** check if the day is selected or not if not then
     * set the value of the day not selected to null */
    if (!this.geoMarketingAssignmentAddDto.monday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingMn = null;
    if (!this.geoMarketingAssignmentAddDto.tuesday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingTu = null;
    if (!this.geoMarketingAssignmentAddDto.wednesday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingWd = null;
    if (!this.geoMarketingAssignmentAddDto.thursday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingTr = null;
    if (!this.geoMarketingAssignmentAddDto.friday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingFr = null;
    if (!this.geoMarketingAssignmentAddDto.saturday)
      this.geoMarketingAssignmentAddDto.idgeoMarketingSt = null;

    this.geoMarketingService.updateGeoMarketingAssignment(this.geoMarketingAssignmentAddDto)
      .subscribe(geoMarketing => {
        this.geoMarketingAssignmentUpdated.emit(geoMarketing);
        this.geoMarketingAssignmentAddDto = new GeoMarketingAssignmentAddDto();
        this.loading = false;
        this.toastr.success("Modifié", "Tournée");
      }, err => {
        this.loading = false;
        this.error = JSON.parse(JSON.stringify(err.error || null));

        if (!this.error.errorMessage) {
          this.error.errorMessage = "SERVER ERROR";
        }
        this.toastr.error(this.error.errorMessage, "Tournée");
      })
  }

  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.geoMarketingAssignmentAddDto.dateFin && this.geoMarketingAssignmentAddDto.dateFin < this.geoMarketingAssignmentAddDto.dateDebut) {
      this.geoMarketingAssignmentAddDto.dateFin = undefined; // Utilisez undefined si approprié
    }
  }


}
