import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransportTypeFormComponent } from './transport-type-form.component'; // Adjust the path if necessary
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule if you're using reactive forms
import { ToastrModule } from 'ngx-toastr'; // Importing ToastrModule for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Importing ModalModule if needed
import { RouterModule } from '@angular/router'; // Importing RouterModule for routing
import { NgxLoadingModule } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    TransportTypeFormComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, // Include this if you're using reactive forms
    ToastrModule.forRoot(), // Include toastr module for notifications
    ModalModule.forRoot(), // Include modal module if your component uses modals
    RouterModule, // Include RouterModule if routing is involved
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),

  ],
  exports: [
    TransportTypeFormComponent // Export the component for use in other modules
  ]
})
export class TransportTypeFormModule { }
