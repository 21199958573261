<div
  class="box"
  style="margin-right: 50px; margin-top: -15px; z-index: 10; width: 672px"
>
  <div class="box-body">
    <span
      style="line-height: 35px; padding: 0 10px; font-size: 20px; color: #444"
      ><i class="fa fa-gear"></i> Notifications</span
    >
    <div class="nav-tabs-custom" style="margin-bottom: -10px">
      <ul class="nav nav-tabs pull-right">
        <li class="dropdown">
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <i class="fa fa-plus-circle Plus"></i>
          </a>
          <ul class="dropdown-menu">
            <li
              [ngClass]="{ active: selectedAlert == 'SPEED_CARTO' }"
              (click)="selectedAlert = 'SPEED_CARTO'; notifications = []"
            >
              <a>Vitesse Carto</a>
            </li>

            <li
              [ngClass]="{ active: selectedAlert == 'TOW' }"
              (click)="selectedAlert = 'TOW'; notifications = []"
            >
              <a>Remorquage</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'DOC' }"
              (click)="selectedAlert = 'DOC'; notifications = []"
            >
              <a>Documents de circulations</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'VIDANGE' }"
              (click)="selectedAlert = 'VIDANGE'; notifications = []"
            >
              <a>Vidange</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'TIRE' }"
              (click)="selectedAlert = 'TIRE'; notifications = []"
            >
              <a>Pneus</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'BRAKE' }"
              (click)="selectedAlert = 'BRAKE'; notifications = []"
            >
              <a>Freins</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'BATTERY' }"
              (click)="selectedAlert = 'BATTERY'; notifications = []"
            >
              <a>Batteries</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'CHARGER' }"
              (click)="selectedAlert = 'CHARGER'; notifications = []"
            >
              <a>Alimentation Batterie</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'CONSUMPTION' }"
              (click)="selectedAlert = 'CONSUMPTION'; notifications = []"
            >
              <a>Ecart Consommation</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'ACTIVITY_DAILY' }"
              (click)="selectedAlert = 'ACTIVITY_DAILY'; notifications = []"
            >
              <a>Heure de travail</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'ACTIVITY' }"
              (click)="selectedAlert = 'ACTIVITY'; notifications = []"
            >
              <a>Heure D'activité</a>
            </li>
            <li
              [ngClass]="{ active: selectedAlert == 'APPRO_FUEL' }"
              (click)="selectedAlert = 'APPRO_FUEL'; notifications = []"
            >
              <a>Approvisionnement carburant</a>
            </li>
          </ul>
        </li>

        <li
          [ngClass]="{ active: selectedAlert == 'SPEED' }"
          (click)="selectedAlert = 'SPEED'; notifications = []"
        >
          <a><i class="fa fa-car"></i> Vitesse</a>
        </li>

        <li
          [ngClass]="{ active: selectedAlert == 'NO_POI' }"
          (click)="selectedAlert = 'NO_POI'; notifications = []"
        >
          <a>Arrêt Non P.O.I</a>
        </li>
        <li
          [ngClass]="{ active: selectedAlert == 'POI' }"
          (click)="selectedAlert = 'POI'; notifications = []"
        >
          <a>Arrêt P.O.I</a>
        </li>
        <li
          [ngClass]="{ active: selectedAlert == 'INGNITION_V0' }"
          (click)="selectedAlert = 'INGNITION_V0'; notifications = []"
        >
          <a>Contact v = 0</a>
        </li>
        <li
          [ngClass]="{ active: selectedAlert == 'ZONE' }"
          (click)="selectedAlert = 'ZONE'; notifications = []"
        >
          <a>Zones</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div
  class="box"
  style="margin-right: 50px; margin-top: -15px; z-index: 9; width: 672px"
>
  <div class="box-header with-border">
    <form class="form-horizontal">
      <div class="form-group">
        <label class="col-sm-1 control-label">Date début</label>
        <div class="col-sm-4">
          <div class="input-group">
            <input
              type="text"
              class="form-control input-sm"
              bsDatepicker
              name="startDate"
              [(ngModel)]="startDate"
              [bsConfig]="{
                dateInputFormat: 'DD-MM-YYYY, HH:mm',
                containerClass: 'theme-dark-blue'
              }"
              placement="bottom"
              [maxDate]="endDate"
            />
            <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>
          </div>
        </div>
        <label class="col-sm-1 control-label">Date fin</label>
        <div class="col-sm-4">
          <div class="input-group">
            <input
              type="text"
              class="form-control input-sm"
              bsDatepicker
              name="endDate"
              [(ngModel)]="endDate"
              [bsConfig]="{
                dateInputFormat: 'DD-MM-YYYY, HH:mm',
                containerClass: 'theme-dark-blue'
              }"
              placement="bottom"
              [minDate]="startDate"
            />
            <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>
          </div>
        </div>
        <div class="col-sm-2">
          <button
            *ngIf="selectedAlert != 'CONSUMPTION'"
            type="button"
            class="btn btn-success btn-flat btn-sm"
            (click)="getNotificationsByAlertNameOfDeviceId()"
          >
            <b> <i class="fa fa-search" aria-hidden="true"></i> </b>
          </button>
          <button
            *ngIf="selectedAlert != 'CONSUMPTION'"
            [disabled]="notifications.length == 0"
            type="button"
            class="btn btn-primary btn-flat btn-sm"
            (click)="generateReportNotifications()"
          >
            <b> <i class="fa fa-book" aria-hidden="true"></i> </b>
          </button>

          <button
            *ngIf="selectedAlert == 'CONSUMPTION'"
            type="button"
            class="btn btn-success btn-flat btn-sm"
            (click)="getFuelingNotificationsOfDeviceId()"
          >
            <b> <i class="fa fa-search" aria-hidden="true"></i> </b>
          </button>
          <button
            *ngIf="selectedAlert == 'CONSUMPTION'"
            [disabled]="fuelingNotificationDto.length == 0"
            type="button"
            class="btn btn-primary btn-flat btn-sm"
            (click)="generateReportFuelingNotifications()"
          >
            <b> <i class="fa fa-book" aria-hidden="true"></i> </b>
          </button>
        </div>
      </div>
    </form>
  </div>
  <!-- /.box-header -->
  <div class="box-body">
    <table class="table table-fixed" style="font-size: 12px">
      <thead *ngIf="selectedAlert != 'CONSUMPTION'">
        <tr>
          <th class="col-xs-3">Valeur</th>
          <th class="col-xs-5">Date</th>
          <th class="col-xs-4">
            <div class="input-group input-group-sm">
              <input
                type="text"
                name="searchTerm"
                [(ngModel)]="searchTerm"
                class="form-control pull-right"
                placeholder="Rechercher"
              />
              <div class="input-group-btn">
                <button type="submit" class="btn btn-default">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </th>
        </tr>
      </thead>

      <thead *ngIf="selectedAlert == 'CONSUMPTION'">
        <tr>
          <th class="col-xs-3">C.Réelle</th>
          <th class="col-xs-3">Date</th>
          <th class="col-xs-3">Diff</th>
          <th class="col-xs-3">
            <div class="input-group input-group-sm">
              <input
                type="text"
                name="searchTerm"
                [(ngModel)]="searchTerm"
                class="form-control pull-right"
                placeholder="Rechercher"
              />
              <div class="input-group-btn">
                <button type="submit" class="btn btn-default">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </th>
        </tr>
      </thead>

      <tbody *ngIf="selectedAlert != 'CONSUMPTION'">
        <tr
          class="col-xs-12"
          *ngFor="
            let notification of notifications
              | filterBy : { alertValue: searchTerm }
          "
          (click)="notification.isReaded = true; showNotification(notification)"
          [class.unrededNotification]="!notification.isReaded"
        >
          <td class="col-xs-3" style="font-weight: bold">
            {{ notification?.alertValue }}
          </td>
          <td class="col-xs-5" style="font-weight: bold">
            {{ notification?.createdAt | date : "dd-MM-yyyy, HH:mm" }}
          </td>
          <td class="col-xs-4">
            <label *ngIf="location"
              >{{ notification?.lat | number : "2.2-6" }},{{
                notification?.lng | number : "2.2-6"
              }}
            </label>
            <span
              *ngIf="location && notification.highway == true"
              class="badge"
              style="
                background-color: #3976bf;
                border-radius: 0;
                padding: 1px 7px;
              "
              >A</span
            >
            <span
              *ngIf="location && notification.highway == false"
              class="badge"
              style="
                background-color: #d1626b;
                border-radius: 0;
                padding: 1px 7px;
              "
              >N</span
            >
            <label *ngIf="!location">{{ notification?.message }}</label>
          </td>
        </tr>
      </tbody>

      <tbody *ngIf="selectedAlert == 'CONSUMPTION'">
        <tr
          class="col-xs-12"
          *ngFor="
            let fuelingNotification of fuelingNotificationDto
              | filterBy : { consumption: searchTerm }
          "
        >
          <td class="col-xs-3" style="font-weight: bold">
            {{ fuelingNotification?.consumption | number : ".0-2" }}
          </td>
          <td class="col-xs-3" style="font-weight: bold">
            {{
              fuelingNotification?.operationTime | date : "dd-MM-yyyy, HH:mm"
            }}
          </td>
          <td class="col-xs-3" style="font-weight: bold; color: red">
            {{ fuelingNotification?.difference | number : ".0-2" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ngx-loading
  [show]="loader"
  [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"
></ngx-loading>
