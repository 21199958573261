import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { Provider, Page } from "../model/index";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProviderService {

    constructor(private _http: HttpClient) { }

    public getProviders(type: string): Observable<Provider[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<Provider[]>(dns + 'providers/listByType?type=' + type, { headers: headers });
    }

    public getProvidersByPagination(type: string, page: number, size: number): Observable<Page<Provider>> {
         let headers = createAuthorizationHeader();
        return this._http.get<Page<Provider>>(dns + 'providers/paginate/listByType?type=' + type + '&page=' + page + '&size=' + size, { headers: headers });
    }

    public delete(id: number): Observable<Boolean> {
         let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + "providers/" + id, { headers: headers });
    }

    public add(provider: Provider): Observable<Provider> {
         let headers = createAuthorizationHeader();
        return this._http.post<Provider>(dns + "providers", provider, { headers: headers });
    }

    public update(provider: Provider): Observable<Provider> {
         let headers = createAuthorizationHeader();
        return this._http.put<Provider>(dns + "providers", provider, { headers: headers });
    }
}

