import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Required for template-driven forms or ngModel
import { ReactiveFormsModule } from '@angular/forms'; // Required if you're using reactive forms
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker from ngx-bootstrap
import { ToastrModule } from 'ngx-toastr'; // For toast notifications
import { VisitFormComponent } from './visit-form.component'; // Your component import
import { DataManagementService } from '../../data-management';
import { GeoMarketingService } from '../geo-marketing.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
  declarations: [VisitFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    MatCheckboxModule,
    SharedPipesModule,
    NgSelectModule,
    NgxLoadingModule.forRoot({}),

  ],
  exports: [VisitFormComponent],  // Services to be used in the component
  providers: [
    // Provide any services needed here if necessary
  ]
})
export class VisitFormModule {}
