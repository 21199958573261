import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Vehicule } from '../../../data-management/data-management.model';
import { Provider, RunningDocument, Group } from '../../model';
import { DocumentCirculationService } from '../../providers/document-circulation-service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-document-form',
    templateUrl: './document-form.component.html',
    styleUrls: ['./document-form.component.css']
})
export class DocumentFormComponent implements OnInit {

    _document: RunningDocument = new RunningDocument();

    @Input()
    mode = 'ADD';

    @Input()
    vehicule: Vehicule = new Vehicule();

    @Input()
    group: Group = new Group();

    @Output()
    documentWasAdded: EventEmitter<RunningDocument[]> = new EventEmitter<RunningDocument[]>();

    @Output()
    documentWasUpdated: EventEmitter<RunningDocument[]> = new EventEmitter<RunningDocument[]>();

    @Output()
    cancelOperation: EventEmitter<void> = new EventEmitter<void>();

    operationTime: Date | null = null;
    endTime: Date | null = null;

    // providers vars
    providers: Provider[] = [];

    documentsNotHaveProvider = ['PARCKING','TONNAGE', 'LAVAGE', 'TOLL', 'CA_REALIZATION', 'C_LOCATION', 'PERMIT_CIRCULATION', 'TONNAGE_TAX','GRAY_CARD', 'ROAD_TAXES', 'OPERATIONAL_CERTIFICATION'];
    isHaveProvider: boolean = true;

    loading: boolean = false;

    minDate: Date = new Date(2010, 1, 1);

    constructor(private documentService: DocumentCirculationService, public toastr: ToastrService, private localeService: BsLocaleService) {
    }

    ngOnInit() {
        this.localeService.use('fr');
    }

    @Input()
    set document(document: RunningDocument) {
        this._document = document;
        if (document.type) {
            if (this.documentsNotHaveProvider.indexOf(document.type) > -1) {
                if (document.type == 'CA_REALIZATION') this.minDate = new Date();
                this.isHaveProvider = false;
            } else {
                this.isHaveProvider = true;
                this.loadProviders(document.type);
            }
        }
        // init times according to selected document !
        this.loadDocumentDatesToLocalDates();
    }

    /**
     * load providers
     * */
    loadProviders(documentType: any) {
        this.documentService.getProviders(documentType).subscribe(providers => {
            this.providers = providers;
        });
    }

    loadLocalDatesToDocuments() {
        this._document.operationTime = this.operationTime.getTime();
        if (this._document.type == 'PARCKING' || this._document.type == 'TONNAGE' || this._document.type == 'LAVAGE' || this._document.type == 'TOLL' || this._document.type == 'CA_REALIZATION' || this._document.type == 'C_LOCATION') {
            this.endTime = new Date();
        }
        this.endTime.setHours(new Date().getHours());
        if (this.endTime) this._document.endTime = this.endTime.getTime();


    }

    loadDocumentDatesToLocalDates() {
        if (this._document) {
            if (this._document.operationTime) {
                this.operationTime = new Date(this._document.operationTime);
            }
            if (this._document.endTime) {
                this.endTime = new Date(this._document.endTime);
            }
        }
    }

    addDocument(form: NgForm) {
        this.loading = true;
        this.loadLocalDatesToDocuments();
        this._document.provider.type = this._document.type;
        this.documentService.add(this._document, this.vehicule.idVehicule, this.group.idGroupe).subscribe((runningDocuments) => {
            this.documentWasAdded.emit(runningDocuments);
            form.reset();
            this._document.provider.id = null;
            this.loading = false;
            this.loadProviders(this._document.type);
        }, error => {
            this.loading = false;
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    updateDocument(form: NgForm) {
        this.loading = true;
        this.loadLocalDatesToDocuments();
        this._document.provider.type = this._document.type;
        this.documentService.update(this._document).subscribe((runningDocuments) => {
            this.documentWasUpdated.emit(runningDocuments);
            form.reset();
            this.loading = false;
            this.loadProviders(this._document.type);
        }, error => {
            this.loading = false;
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Action change date  opération
     */
    onChangeOperationTime() {
        if (this.endTime < this.operationTime) {
            this.endTime = null;
        }
    }
}
