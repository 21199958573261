import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LossesService } from '../../../providers/losses.service';
import { MultiTypeService } from '../../../providers/multi-type-service';
import { ToastrService } from 'ngx-toastr';
import { Vehicule, Group } from '../../../../data-management/data-management.model';
import { Offense, MultiType } from '../../../model';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-offense-form',
  templateUrl: './offense-form.component.html',
  styleUrls: ['./offense-form.component.css']
})
export class OffenseFormComponent implements OnInit {
  operationTime: Date = null;

  _offense: Offense = new Offense();

  offenseTypes: MultiType[] = [];

  showOffenseTypesDropDown = false;

  @Input() vehicule: Vehicule = new Vehicule();

  @Input() group: Group = new Group();

  @Input() mode = 'ADD';

  @Output()
  offenseWasAdded: EventEmitter<Offense> = new EventEmitter<Offense>();

  @Output()
  offenseWasUpdated: EventEmitter<Offense> = new EventEmitter<Offense>();

  loading = false;

  maxDate: Date = new Date();

  constructor(
    private multiTypeService: MultiTypeService,
    private lossesService: LossesService,
    public toastr: ToastrService,
    private localeService: BsLocaleService
  ) { }

  ngOnInit() {
    this.localeService.use('fr');
    this.loadOffenseTypes();
  }

  @Input()
  set offense(offense: Offense) {
    this._offense = offense;
    if (this.mode === 'ADD' && this.offenseTypes.length > 0) {
      this._offense.type = this.offenseTypes[0];
    }
    if (this.mode === 'UPDATE' && this.offenseTypes.length > 0) {
      for (let i = 0; i < this.offenseTypes.length; i++) {
        if (this._offense.type.id === this.offenseTypes[i].id) {
          this._offense.type = this.offenseTypes[i];
          break;
        }
      }
    }
    // init operation and actualRepear time !
    if (this._offense) {
      if (this._offense.operationTime) {
        this.operationTime = new Date(this._offense.operationTime);
      }
    }
  }

  loadOffenseTypes() {
    this.multiTypeService
      .getListType('OFFENSE_TYPE')
      .subscribe(offenseTypes => {
        this.offenseTypes = offenseTypes;
        if (this.offenseTypes.length > 0) {
          this.showOffenseTypesDropDown = true;
          let other = new MultiType();
          other.id = -1;
          other.name = 'Autre';
          this.offenseTypes.push(other);
        }
      });
  }

  onChangeOffenseType(offenseType: MultiType) {
    this._offense.type = offenseType;
    if (offenseType.id === -1) {
      this.showOffenseTypesDropDown = false;
      this._offense.type.name = '';
    }
  }

  toggleDropDown() {
    this.showOffenseTypesDropDown = true;
    this.offenseTypes.map(type => {
      if (type.id === -1) {
        type.name = 'Autre';
      }
      return type;
    });
    this._offense.type = this.offenseTypes[0];
  }

  addOffense() {
    this.loading = true;
    this._offense.operationTime = this.operationTime.getTime();
    this.lossesService.addOffensee(this._offense, this.vehicule.idVehicule, this.group.idGroupe).subscribe(offense => {
      this.offenseWasAdded.emit(offense);
      this.loading = false;
    },
      error => {
        this.loading = false;
      }
    );
  }

  updateOffense() {
    this.loading = true;
    this._offense.operationTime = this.operationTime.getTime();
    this.lossesService.updateOffense(this._offense).subscribe(
      offense => {
        this._offense = offense;
        this.offenseWasUpdated.emit(offense);
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
}
