import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { ToastrService } from '../../../../../../node_modules/ngx-toastr';
import { MultiType } from '../../model';
import { MultiTypeService } from '../../providers/multi-type-service';

@Component({
    selector: 'app-multi-type-settings',
    templateUrl: './multi-type-settings.component.html',
    styleUrls: ['./multi-type-settings.component.css']
})
export class MultiTypeSettingsComponent implements OnInit {

    types: MultiType[] = [];

  public searchTerm: string | null = null;

  typeValue: 'PAYMENT' | 'REPARATION' | 'CABINET_EXPERTISE' | 'OFFENSE_TYPE' | 'OIL_CHANGE';

    multiType: MultiType = new MultiType();

    mode: 'ADD' | 'UPDATE';

    loading: boolean = false;

    private getTypeSubscription: Subscription;

    /**
     * variables de pagination
     */
    currentPage = 1;
    size = 25;
    maxSize = 9;
    boundaryLinks = true;
    totalItems: number;

    @ViewChild('multiTypeModal', { static: false })
    multiTypeModal: ModalDirective;

    @ViewChild('multiTypeDeleteModal', { static: false })
    multiTypeDeleteModal: ModalDirective;

    oilChangeTypes: Object[] = [{ name: 'OIL_CHANGE', label: "Ref F.Huile" },
    { name: 'REF_TYPE_AIR', label: "Ref F.Air" },
    { name: 'REF_TYPE_ENGINE', label: "Ref H.Moteur" },
    { name: 'REF_TYPE_FUEL', label: "Ref F.Carburant" },
    { name: 'REF_TYPE_SEPARATOR', label: "Ref F.Séparateur" }];

    oilChangeType : string = 'OIL_CHANGE';
    isOilChange: boolean = false;

    constructor(private multiTypeService: MultiTypeService, public toastr: ToastrService, private route: ActivatedRoute) { }

    ngOnInit() {
        /**
         * Récupérer le nom de type
         */
        this.getTypeSubscription = this.route.params.subscribe(params => {
            this.typeValue = params['type'];
            this.isOilChange = this.typeValue == 'OIL_CHANGE';
            this.loadTypes();
        });
    }

    ngOnDestroy() {
        if (this.getTypeSubscription) this.getTypeSubscription.unsubscribe();
    }

    /**
     * Charger liste des types
     */
    loadTypes() {
        this.loading = true;
        this.types = [];
        let type;
        if(this.isOilChange)
        type = this.oilChangeType;
        else
        type = this.typeValue;
        this.multiTypeService.getListTypeByPagination(type, this.currentPage - 1, this.size).subscribe(data => {
            this.types = data.content;
            this.totalItems = data.totalElements;
            this.loading = false;
        }, error => {
            this.loading = false;
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Evénement de changement de pagination
     */
    onPageChanged(event: any): void {
        this.currentPage = event.page;
        this.loadTypes();
    }

    /**
     * Afficher modal de modification du type
     */
    onUpdate(multiType: MultiType) {
        this.multiType = Object.assign({}, multiType);
        this.mode = 'UPDATE';
        this.multiTypeModal.show();
    }

    /**
     * Afficher modal d'ajout du type
     */
    onAdd() {
        this.multiType = new MultiType();
        this.mode = 'ADD';
        this.multiTypeModal.show();
    }

    /**
     * Afficher modal de confirmation du suppression
     */
    onConfirmDelete(multiType: MultiType) {
        this.multiType = multiType;
        this.multiTypeDeleteModal.show();
    }

    /**
     * Suppression du type
     */
    onDelete() {
        this.loading = true;
        this.multiTypeService.delete(this.multiType.id).subscribe(response => {
            this.multiTypeDeleteModal.hide();
            if (response) {
                this.toastr.success('Type informations bien supprimer !', 'info');
                this.types = this.types.filter(multiType => multiType.id != this.multiType.id);
            } else {
                this.toastr.error('Le type n\'a pas supprimé correctement !', 'Erreur');
            }
            this.loading = false;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * Actualiser la liste des types apés l'événement d'ajout
     */
    multiTypeWasAdded(multiType: MultiType) {
        this.multiTypeModal.hide();
        if (this.typeValue == multiType.type) {
            this.types.push(multiType);
        } else {
            this.types = this.types.filter(multiType => multiType.id != this.multiType.id);
        }
    }

    /**
     * Actualiser la liste des types apés l'événement de modification
     */
    multiTypeWasUpdated(multiType: MultiType) {
        this.multiTypeModal.hide();
        if (this.typeValue == multiType.type) {
            for (let i = 0; i < this.types.length; i++) {
                if (this.types[i].id == multiType.id) {
                    this.types[i] = multiType;
                    break;
                }
            }
        } else {
            this.types = this.types.filter(multiType => multiType.id != this.multiType.id);
        }
    }
}
