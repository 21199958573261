import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargesTransportComponent } from './charges-transport.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [ChargesTransportComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot() // Importing ModalModule for modal functionalities if needed
  ],
  exports: [ChargesTransportComponent] // Exporting the component for use in other modules
})
export class ChargesTransportModule {}
