import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffectationFormComponent } from './affectation-form.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { AffectationChargeService } from '../affectation-charge/affectation-charge.service'; // Adjust the path as necessary
import { DataManagementService } from '../../data-management'; // Adjust the path as necessary
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxLoadingModule } from 'ngx-loading';
import { AffectationChargeComponent } from '../affectation-charge/affectation-charge.component';
import { AffectationChargeModule } from '../affectation-charge/affectation-charge.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    AffectationFormComponent,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Initialize Toastr if you're using it
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    AngularMultiSelectModule,
    NgSelectModule,
    FormsModule,

  ],
  providers: [
    AffectationChargeService, // Provide the AffectationChargeService
    DataManagementService // Provide the DataManagementService if it's used in the component
  ],
  exports: [
    AffectationFormComponent // Export the component if it needs to be used in other modules
  ]
})
export class AffectationFormModule { }
