import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { StopComponent } from './stop.component';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { PoiClientFormComponent } from '../../geo-marketing/poi-client/poi-client-form/poi-client-form.component';
import { PoiModule } from '../../data-management/poi/poi.module';
import { PoiClientFormModule } from '../../geo-marketing/poi-client/poi-client-form/poi-client-form.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { PoiFormModule } from '../../data-management/poi/poi-form/poi-form.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { DurationPipeModule } from 'src/app/pipes/duration.module';
//import { StopService } from './stop.service'; // Ensure you have a StopService if needed
//import { LeafletModule } from '@asymmetrik/ngx-leaflet'; // If you're using ngx-leaflet for maps

@NgModule({
  declarations: [
    StopComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(), // Initialize ngx-bootstrap dropdown
    BsDatepickerModule.forRoot(), // Initialize ngx-bootstrap datepicker
    ModalModule.forRoot(), // Initialize modal component
    ToastrModule.forRoot(), // Initialize Toastr for notifications
    LeafletModule,
    PoiModule,
    SharedPipesModule,
    PoiClientFormModule,
    PoiFormModule,
    TruncateModule,
    DurationPipeModule

  ],
  providers: [
    // StopService // Provide your StopService
  ],
  exports: [
    StopComponent // Export StopComponent for use in other modules
  ]
})
export class StopModule { }
