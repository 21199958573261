import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiviChargeComponent } from './suivi-charge.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you're using Toastr for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using ngModel
import { HttpClientModule } from '@angular/common/http'; // Import HttpClientModule for HTTP requests
import { AffectationChargeService } from '../affectation-charge/affectation-charge.service'; // Adjust the path as necessary
import { MaintenanceChargeComponent } from './maintenance-charge/maintenance-charge.component';
import { MaintenanceChargeModule } from './maintenance-charge/maintenance-charge.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CorrelationLocationModule } from '../../charges-pfb/charges-pfb-tracking/correlation-location/correlation-location.module';
import { CorrelationGasoilModule } from '../../charges-pfb/charges-pfb-tracking/correlation-gasoil/correlation-gasoil.module';
import { CorrelationMaintenanceModule } from './correlation-maintenance/correlation-maintenance.module';
import { TurnoverAchievedModule } from '../../charges-pfb/charges-pfb-tracking/turnover-achieved/turnover-achieved.module';
import { LocationCostComponent } from '../../charges-pfb/charges-pfb-tracking/location-cost/location-cost.component';
import { LocationCostModule } from '../../charges-pfb/charges-pfb-tracking/location-cost/location-cost.module';
import { FuelingChargeModule } from '../../charges-pfb/charges-pfb-tracking/fueling-charge/fueling-charge.module';

@NgModule({
  declarations: [
    SuiviChargeComponent,

  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    FormsModule,
    HttpClientModule,
    MaintenanceChargeModule,
    BsDatepickerModule.forRoot(),
    CorrelationLocationModule,
    CorrelationGasoilModule,
    CorrelationMaintenanceModule,
    TurnoverAchievedModule,
    LocationCostModule,
    FuelingChargeModule

  ],
  providers: [
    AffectationChargeService // Provide any services the component needs
  ],
  exports: [
    SuiviChargeComponent // Export the component if it needs to be used in other modules
  ]
})
export class SuiviChargeModule { }
