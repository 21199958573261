import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LocationCostComponent } from './location-cost.component';
// import { NgxToastrModule } from 'ngx-toastr';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';  // Bootstrap datepicker
import { HighchartsChartModule } from 'highcharts-angular';  // Highcharts wrapper for Angular

@NgModule({
  declarations: [
    LocationCostComponent  // Declare LocationCostComponent in this module
  ],
  imports: [
    CommonModule,          // Provides common Angular directives
    FormsModule,           // Required for template-driven forms if needed
    // NgxToastrModule,       // Toastr notifications
    BsDatepickerModule.forRoot(),  // Bootstrap datepicker for date inputs
    HighchartsChartModule  // Highcharts Angular module for charting
  ],
  providers: [
    BsLocaleService  // Provide locale service for datepicker localization
  ],
  exports: [
    LocationCostComponent  // Export the component so it can be used in other modules
  ]
})
export class LocationCostModule { }
