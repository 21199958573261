<div class="content" style="overflow-y: scroll; height:590px;">
  <div class="row">
    <div class="form-group has-success">

      <div class="col-md-3 col-sm-3">

        <br />
        <label class="control-label">Nom de Programme</label>
        <input [(ngModel)]="currentProgram.name" required name="request" type="text" maxlength="50" placeholder="Nom"
          class="form-control">

        <br />

        <label class="control-label">Liste de vehicule</label>

        <ng-multiselect-dropdown
        [placeholder]="'select Véhicule'"
        [settings]="groupDropdownSettings"
        [data]="vehiculesItems"
        [(ngModel)]="selectedVehiculesItems">
        </ng-multiselect-dropdown>

        <br />
        <div class="bg-light text-right" *ngIf="mode == 'ADD'">
          <button type="button" class="btn btn-warning ml-2"
            (click)="addProgramTimePlan()">Valider
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </button>
        </div>
        <div class="bg-light text-right" *ngIf="mode == 'UPDATE'">
          <button type="button" class="btn btn-warning ml-2" (click)="updateProgramTimePlan()">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              Enregistrer
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-9 col-sm-9">
      <jqxScheduler [theme]="'light'" #scheduler [width]="getWidth()" [height]="490" [date]="date"
        [source]="dataAdapter" [showLegend]="true" [localization]="localization" (onAppointmentAdd)="appointmentAdd($event)" (onAppointmentDelete)="appointmentDelete($event)"
        [editDialogOpen]="editDialogOpen" (onAppointmentChange)="appointmentChange($event)" (onEditRecurrenceDialogOpen)="EditRecurrenceDialogOpen($event)" [view]="'weekView'" [views]="views"
        [appointmentDataFields]="appointmentDataFields">
      </jqxScheduler>
    </div>
  </div>
</div>
