import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametrageComponent } from './parametrage.component'; // Adjust the path as needed
import { RouterModule } from '@angular/router'; // Import RouterModule if routing is used
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ParametrageComponent // Declare the ParametrageComponent
  ],
  imports: [
    CommonModule, // CommonModule for common directives
    FormsModule,
    RouterModule,
    MatTooltipModule
  ],
  exports: [
    ParametrageComponent // Export if needed for other modules
  ]
})
export class ParametrageModule { }
