import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramFormComponent } from './program-form.component';
import { FormsModule } from '@angular/forms'; // Import if you're using forms
import { ToastrModule } from 'ngx-toastr'; // For toastr notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // For Bootstrap modals
//import { jqxSchedulerComponent } from 'jqwidgets-scripts/jqwidgets-ts//angular_jqxscheduler';
import { RouterModule } from '@angular/router'; // For routing
import { jqxSchedulerComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxscheduler';
import { DataManagementService } from '../../data-management.service';
import { VehiculeService } from '../../providers/vehicule.service';
import { ProgramService } from '../../providers/program.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    ProgramFormComponent,
    jqxSchedulerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    DataManagementService,
    ProgramService,
    VehiculeService
  ],
  exports: [
    ProgramFormComponent // Export the component if you want to use it in other modules
  ]
})
export class ProgramFormModule { }
