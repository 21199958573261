<ngx-loading [show]="globalLoading"></ngx-loading>

<div class="card">
  <div class="card-header">
    <div class="row" style="padding: 0px 25px 0px 10px;display: flex;justify-content: center;align-items: center;">
      <div class="col-md-1" style="position: relative;">
        <p (click)="openModal()">
          <i class="fa fa-bar-chart" aria-hidden="true" style="cursor: pointer;"></i>
        </p>
      </div>
      <div class="col-md-5">
        <select
          class="form-control input-sm select3"
          [(ngModel)]="selectedMonth"
          (ngModelChange)="onMonthChange()"
          name="selectedMonth"
          required
          style="height: 25px"
        >
          <option [ngValue]="null" style="font-weight: bold">
            Choisir le mois
          </option>
          <option
            style="font-size: 14px"
            *ngFor="let month of months"
            [value]="month.id"
          >
            {{ month.name }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <select
          class="form-control input-sm select3"
          [(ngModel)]="isByMultipleGroups"
          (ngModelChange)="onChangeByMultipleGroups()"
          style="height: 25px"
        >
          <option [ngValue]="false">Tous les Groupes</option>
          <option [ngValue]="true">Groupe de Véhicules</option>
        </select>
      </div>
    </div>
    <div *ngIf="isByMultipleGroups" class="row">
      <div class="col-md-12">
        <form #groupForm="ngForm" class="form-horizontal">
          <div class="col-lg-10 col-md-10">
            <label class="col-lg-4 col-md-4 control-label">Groupes</label>
            <div class="col-lg-8 form-group">
              <ng-multiselect-dropdown
              [placeholder]="'personnalisé'"
              [settings]="dropdownSettings"
              [data]="groups"
              [(ngModel)]="groupIds"
              (onSelect)="onItemSelect($event)"
            >
            </ng-multiselect-dropdown>
            </div>
          </div>

          <div class="col-lg-2 orm-group">
            <button
              type="button"
              (click)="onValidate()"
              class="btn btn-block btn-success btn-flat"
            >
              <i class="fa fa-search pull-left" aria-hidden="true"></i>
              Valider
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div
      id="monthlyAvailableVehicules"
      style="zoom: 0.58"
      class="w-100 d-inline-block"
    ></div>
  </div>
</div>

<!-- Bootstrap Modal -->
<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showModal }"
  data-backdrop="static"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <p>Historique Canal / Groupe / vehicule par Mois</p> -->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          id="monthlyAvailableVehiculesModal"
          style="width: 100%; height: 100%"
        ></div>
      </div>
    </div>
  </div>
</div>
