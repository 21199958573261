<div class="row">
    <div class="col-lg-12">
        <div class="box-header page-header with-border" style="margin: 0px;">
            <!-- =================================== TITLE (START)==================================== -->
            <!-- <h3 class="box-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;"><i class="fa fa-list"></i>

                <span style="text-decoration: underline">

                      <span *ngIf="selectedDocumentType=='LAVAGE'">Liste des lavages du véhicule :</span>
                      <span *ngIf="selectedDocumentType=='PARCKING'">Liste des parking du véhicule :</span>
                      <span *ngIf="selectedDocumentType=='TOLL'">Liste des péages du véhicule :</span>
                      <span *ngIf="selectedDocumentType=='CA_REALIZATION'">Liste des chiffre d'affaires réalisé du véhicule :</span>
                      <span *ngIf="selectedDocumentType=='C_LOCATION'">Liste des C.Location réalisé du véhicule :</span>

                      <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher la véhicule" [routerLink]="['/client/dataManagement/vehicule/form', _selectedVehicule.idVehicule, 'SHOW']">{{_selectedVehicule.matricule}}</a>

                      <span *ngIf="_selectedVehicule.driver"> - conducteur :
                            <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher le conducteur" [routerLink]="['/client/dataManagement/driver/form', _selectedVehicule.driver.idDriver, 'SHOW']">{{_selectedVehicule?.driver?.firstName}} {{_selectedVehicule?.driver?.lastName}}</a>
                      </span>

                </span>


                <span *ngIf="!_selectedVehicule.driver" data-toggle="tooltip" class="badge label-warning">
                      <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                </span>

            </h3> -->
            <!-- =================================== TITLE (END)==================================== -->
            <!-- =================================== OPTIONS (START)==================================== -->
            <div class="option-add">


                    <button type="button" class="btn btn-success btn-sm  " title="Ajouter"
                    (click)="openAddDocumentModal()" ><i class="fa fa-plus-circle"></i> </button>

            </div>
            <div *ngIf="documentsId.length > 0" class="option-delete">
                <span title="Supprimer tout" (click)="onConfirmDeleteMultiple()">
                    <i class="fa fa-minus-circle"></i> </span>
            </div>
            <!-- =================================== OPTIONS (END)==================================== -->
            <!--==================================== import/Export functionality(START)=============== -->
            <div style="display: inline-block; margin-left: 5px;">
               <div class="option-export">
                <button type="button" class="btn btn-primary btn-sm   " title="Exporter sous format excel"
                (click)='export()' >Exporter <i class="fa fa-download"></i> </button>

                <button type="button" class="btn btn-primary btn-sm" style="margin-left: 5px;" title="Importer sous format excel"
                (click)='importModal.show()' >Importer <i class="fa fa-upload"></i> </button>
            </div>

            </div>
            <!--==================================== import/Export functionality(END)================= -->
            <!--==================================== Search(START)=============== -->
            <div class="pull-right">
                <div class="inputWithIcon">
                    <input [(ngModel)]="searchTerm" name="searchTerm" type="text" class="form-control input-sm"
                        placeholder="Coût...">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <!--==================================== Search(END)================= -->
        </div>
    </div>
    <div class="col-lg-12">
        <!-- ========================================== LIST SINISTRES (START) ====================================================-->
        <div class="content">

            <div class="table-responsive tableFixHead">
                <table class="table table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 2%; text-align: left;"><input type="checkbox" [disabled]="documents.length == 0"
                                    [checked]="documents.length == documentsId.length" (click)="onCheckedAll()"></th>
                            <th style="width:5%">Date début</th>
                            <th *ngIf="selectedDocumentType!='TONNAGE'" style="width:5%">Coût <span style="background-color: white; color: #3c8dbc">(total :
                                {{costTotal() | number: '.0-4'}} DH)</span></th>
                                <th *ngIf="selectedDocumentType=='TONNAGE'" style="width:5%">Tonnage</th>
                            <th style="width: 83%" style="border-left-style:none;"></th>
                            <th style="width:5%"></th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngIf="loading">
                            <td colspan="5">
                                <div style="text-align: center">
                                    <span><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></span>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="!loading && documents.length == 0">
                            <td colspan="5">
                                <div class="alert alert-dismissible" style="border-radius: 0px;text-align: center;">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                        ×
                                    </button>
                                    <h4 style="color: orange">
                                        <i class="fa fa-info-circle"></i> Information
                                    </h4>
                                    Veuillez ajouter des informations pour divers.
                                </div>
                            </td>
                        </tr>

                        <tr *ngFor="let document of getDocuments()">
                            <td>
                                <input type="checkbox" [checked]="isCheckedInList(document.id)" (click)="onChecked(document.id)">
                            </td>
                            <td *ngIf="selectedDocumentType != 'CA_REALIZATION'" style="font-weight: bold;">
                                {{document?.operationTime | date: 'dd-MM-yyyy'}}
                            </td>

                            <td *ngIf="selectedDocumentType == 'CA_REALIZATION'" style="font-weight: bold;">
                                {{document?.operationTime | date: 'dd-MM-yyyy hh:mm'}}
                            </td>

                            <td *ngIf="selectedDocumentType != 'TONNAGE'">
                                {{document?.cost}}
                                <span class="label label-info">({{document?.cost / costTotal() * 100 |number:'.2-3'}}
                                    %)</span>
                            </td>

                            <td  *ngIf="selectedDocumentType == 'TONNAGE'">
                                {{document?.cost}}
                            </td>
                            <td></td>
                            <td>
                                <button title="Supprimer" style="color: red;" (click)="onConfirmDelete(document)"
                                    type="button" class="btn btn-sm btn-default">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                                <button title="Modifier" style="color: rgb(2, 157, 196); " type="button" class="btn btn-sm btn-default"
                                    (click)="updateDocument(document)">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <!-- ========================================== LIST SINISTRES (END) ====================================================-->
    </div>
</div>
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" bsModal #documentDeleteModal="bs-modal" role="dialog">
        <div class="modal-dialog modal-lg">
              <div class="modal-content">
                    <div class="modal-header">
                          <button type="button" class="close" (click)="documentDeleteModal.hide()">&times;</button>
                          <h4 class="modal-title">
                                <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                          </h4>
                    </div>
                    <div class="modal-body">
                          <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                                <h4 style="color: white">
                                      <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                                <span *ngIf="!isDeleteMultiple">Êtes-vous sûr de supprimer cette divers ?</span>
                                <span *ngIf="isDeleteMultiple">Êtes-vous sûr de supprimer cettes {{documentsId.length}}
                                        divers
                                      ?</span>
                          </div>
                    </div>
                    <div class="modal-footer">
                          <button type="button" class="btn btn-default" (click)="documentDeleteModal.hide()">
                                Annuler
                          </button>
                          <button type="button" class="btn btn-success" (click)="onDelete()">
                                <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                          </button>
                    </div>
              </div>
        </div>
  </div>
  <!-- =============================== DELETE MODAL (END) ============================= -->
  <!--=================================Import fuel(start) ====================================-->
<div class="modal" role="dialog" bsModal #importModal="bs-modal">
        <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                    <div class="modal-header">
                          <button type="button" class="close" (click)="importModal.hide()">&times;
                          </button>
                          <h4 class="modal-title">
                                <i class="fa fa-cogs" aria-hidden="true"></i> Importer le fichier Excel
                          </h4>
                    </div>
                    <div class="modal-body vertical-spacer">
                          <app-import-export-forms [item]='selectedDocumentType' [vehiculeMatricule]='_selectedVehicule.matricule' [groupId]='_selectedGroup.idGroupe'
                                (importedItem)='itemWasImported($event)'></app-import-export-forms>
                    </div>
                    <p></p>
                    <div class="modal-footer">
                          <button type="button" class="btn btn-default" (click)="importModal.hide()">
                                Fermer
                          </button>
                    </div>
              </div>
        </div>
  </div>
  <!--=================================Import fuel (end) ====================================-->
<!-- =================================== EDIT MODAL (START)====================================-->
<div class="modal fade" role="dialog" bsModal #addEditModal="bs-modal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="addEditModal.hide();">&times;
                </button>
                <h4 class="modal-title" *ngIf="mode === 'ADD'">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Ajouter informations document
                </h4>

                <h4 class="modal-title" *ngIf="mode === 'UPDATE'">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Modifier informations document
                </h4>
            </div>
            <div class="modal-body">
                <app-document-form [document]="selectedDocument" [mode]="mode" [vehicule]="_selectedVehicule" [group]="_selectedGroup"
                    (documentWasAdded)="documentWasAdded($event)" (documentWasUpdated)="documentWasUpdated($event)">
                </app-document-form>
            </div>
        </div>
    </div>
</div>
<!-- =================================== EDIT MODAL (START)====================================-->
