import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import {DriverCharge} from '../model/index';
import {dns} from "../../../global.config";
import {createAuthorizationHeader} from "../../../utils/security/headers";
import {HttpClient} from '@angular/common/http';
import {DateInterval} from 'src/app/shared/model';

@Injectable()
export class DriverChargeService {

    constructor(private _http: HttpClient) { }

    public getList(driverId: number): Observable<DriverCharge[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<DriverCharge[]>(dns + 'driverCharges/list/' + driverId, { headers: headers });
    }

    public delete(id: number): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + 'driverCharges/' + id, { headers: headers });
    }

    deleteMultiple(driverChargesId: number[]): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + "driverCharges/multiple/" + driverChargesId, { headers: headers });
    }

    public add(driverCharge: DriverCharge, groupId: number): Observable<DriverCharge> {
        let headers = createAuthorizationHeader();
        return this._http.post<DriverCharge>(dns + 'driverCharges?groupId=' + groupId, driverCharge, { headers: headers });
    }

    public update(driverCharge: DriverCharge): Observable<DriverCharge> {
        let headers = createAuthorizationHeader();
        return this._http.put<DriverCharge>(dns + 'driverCharges', driverCharge, { headers: headers });
    }

    public getMonthlyDriverChargeCostByTransportTypeId(date : DateInterval , transportTypeId : number): Observable<any> {
        let headers = createAuthorizationHeader();
        return this._http.post<any>(dns + 'driverCharges/'+ transportTypeId +'/monthly', date, { headers: headers });
    }

    public getMonthlyDriverChargeCostByCanalId(date : DateInterval , canalId : number): Observable<any> {
        let headers = createAuthorizationHeader();
        return this._http.post<any>(dns + 'driverCharges/canal/'+ canalId +'/monthly', date, { headers: headers });
    }

    public getTurnover(date : DateInterval): Observable<any>{
        let headers = createAuthorizationHeader();
        return this._http.post<any>(dns + 'driverCharges/turnover', date, { headers: headers });
    }
}
