import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { FuelingTemplateSetting } from '../model/index';

@Injectable()
export class FuelingTemplateSettingService {

  constructor(private _http: HttpClient) { }

  getFuelingTemplateSetting(): Observable<FuelingTemplateSetting> {
    let headers = createAuthorizationHeader();
    return this._http.get<FuelingTemplateSetting>(dns + "fuelingTemplateSettings", { headers: headers });
  }

  save(fuelingTemplateSetting: FuelingTemplateSetting): Observable<FuelingTemplateSetting> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<FuelingTemplateSetting>(dns + "fuelingTemplateSettings", fuelingTemplateSetting, options);
  }

}
