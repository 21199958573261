import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if needed
import { ZoneSettingsComponent } from './zone-settings.component';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you are using ToastrService
import { NgxLoadingModule } from 'ngx-loading';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
//import { SelectModule } from 'ng2-select';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    ZoneSettingsComponent // Declare the component
  ],
  imports: [
    CommonModule, // CommonModule is required for common directives like ngIf, ngFor
    FormsModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    EmailManagerModule,
    NgSelectModule,
    CronSettingsModule,
    MatSlideToggleModule
  ],
  exports: [
    ZoneSettingsComponent // Export the component if it needs to be used in other modules
  ]
})
export class ZoneSettingsModule { }
