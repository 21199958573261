import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanalExportComponent } from './canal-export.component';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
//import { LoadingModule } from 'ngx-loading'; // Import LoadingModule if used
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import Bootstrap Datepicker if used
import { NgxLoadingModule } from 'ngx-loading';
//import { SelectModule } from 'ng2-select';
import { FormsModule } from '@angular/forms';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';


@NgModule({
  declarations: [
    CanalExportComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for common directives
    // SomeComponent,
    //SelectModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    ModalModule.forRoot(),

  ],
  exports: [
    CanalExportComponent // Export the component if needed in other modules
  ]
})
export class CanalExportModule { }
