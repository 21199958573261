import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DataManagementService, Vehicule, Group } from '../../data-management';
import { Subscription } from 'rxjs';
import { DeviceCarb, RepFuelVariation, TechnicalData, DeviceOpt } from '../../statistical/technical-data/technical-data';
import { FuelingService, Fueling } from '../../parc-management';
import { TechnicalDataService } from '../../statistical/technical-data/technical-data.service';
import { ToastrService } from 'ngx-toastr';

import * as Highcharts from 'highcharts';

import { DateInterval } from 'src/app/shared/model';



@Component({
  selector: 'app-activity-btnt',
  templateUrl: './activity-btnt.component.html',
  styleUrls: ['./activity-btnt.component.css']
})


export class ActivityBtntComponent implements OnInit {


  /** GROUP VAR */
  selectedGroup: Group = new Group();


  groups: Group[] = [];

  /** VEHICULE VAR */
  selectedVehicule: Vehicule = new Vehicule();

  currentCarbSetting: DeviceCarb = new DeviceCarb();

  fuels: Fueling[] = [];

    //Chart vars
    chart: any;
    options: Object;
    serieName: any;

    //Device Options
    deviceopt: DeviceOpt;

    //Device Carburant
    devicecarb: DeviceCarb;

    AllGroups: Subscription;
    vehicule: Vehicule;

    technicaldata: TechnicalData[];

    repFuelVariation: RepFuelVariation[] = [];

    startDate: Date = new Date();
    endDate: Date = new Date();

    //MultiSelelct vars
  dropdownList: { id: number; option: string; itemName: string }[] = [];
  selectItems: { id: number; option: string; itemName: string }[] = [];

  dropdownSettings = {};

    isResultLoaded: boolean = true;

    volume: number;

    useFuel: boolean = false;


    year: Date = new Date();
  Years: { id: string; name: string; }[] = [];

  constructor(private fuelingService: FuelingService,
     private dataManagementService: DataManagementService
    , public toastr: ToastrService, private technicalDataService: TechnicalDataService
    ,    private localeService: BsLocaleService
    ) {
      this.Years = this.getYears();
    }

  ngOnInit() {
    this.localeService.use("fr");
    this.loadDataManagement();
  }

  ngOnDestroy() {
    if (this.AllGroups) this.AllGroups.unsubscribe();
  }


  // ngAfterViewInit() {
  //   // this.activityDtsService.Zoom();
  //   this.changeStyles();
  // }

  changeStyles() {

    var x = document.getElementsByClassName("content") as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.minHeight = "0px";
    }
  }


  changeContentStyle() {

    var x = document.getElementsByClassName("content") as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      if (x[i].style.minHeight == "0px")
        x[i].style.minHeight = "250px";
      else
        x[i].style.minHeight = "0px";
    }
  }

  /** COOSE VEHICULE */
  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;

  }
  /** CHOOSE GROUP */
  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;

    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
    }

  }

/*** error */
  displayChart() {
      //Get Selected Options
      let devopt: any[] = [];
      if (this.selectItems.length > 0) {
        this.selectItems.forEach(itm => {
          if (itm.option == "fuel") {
            this.useFuel = true;
          }
          devopt.push(itm.option);
        });
      }


    // Options vars
  let fuel: { x: number; y: number }[] = [];
  let ignition: { x: number; y: number }[] = [];
  let tfu: { x: number; y: number }[] = [];
  let accum_odo: { x: number; y: number }[] = [];
  let ok: boolean = false;

    this.technicalDataService
      .getRepFuelVariation(this.selectedVehicule.idDevice, {
        startDate: this.startDate,
        endDate: this.endDate
      })
      .subscribe(res => {
        this.repFuelVariation = res;
      });

    //Select data Device carburant
    this.technicalDataService
      .getDeviceCarb(this.selectedVehicule.idDevice)
      .subscribe(res => {
        this.devicecarb = res;
        this.volume = this.devicecarb.volume;
      });

    //Get Fuel Data and fill all options
    this.technicalDataService
      .getFuelDetail(
        this.selectedVehicule.idDevice,
        {
          startDate: this.startDate,
          endDate: this.endDate
        },
        devopt
      )
      .subscribe(res => {
        this.technicaldata = res;
        if (this.technicaldata.length == 0) {
          this.toastr.warning("Pas de données à afficher");
        }
        this.technicaldata.forEach(stat => {
          if (stat.fuel != undefined) {
            if (this.devicecarb.max - this.devicecarb.min > 0) {
              if (
                stat.fuel >= this.devicecarb.min &&
                stat.fuel <= this.devicecarb.max
              ) {
                fuel.push({
                  x: stat.date,
                  y:
                    ((stat.fuel - this.devicecarb.min) /
                      (this.devicecarb.max - this.devicecarb.min)) *
                    this.devicecarb.volume
                });
              }
            } else {
              if (
                stat.fuel >= this.devicecarb.max &&
                stat.fuel <= this.devicecarb.min
              ) {
                fuel.push({
                  x: stat.date,
                  y:
                    ((stat.fuel - this.devicecarb.min) /
                      (this.devicecarb.max - this.devicecarb.min)) *
                    this.devicecarb.volume
                });
              }
            }
          }
          if (stat.ignition != undefined) {
            ignition.push({ x: stat.date, y: stat.ignition ? 1 : 0 });
            ok = true;
          }
          if (stat.tfu != undefined && ok) {
            tfu.push({ x: stat.date, y: stat.tfu });
          }
          if (stat.accum_odo != undefined && ok) {
            accum_odo.push({ x: stat.date, y: stat.accum_odo + this.selectedVehicule.odoOffset });
          }
        });

        //Charts vars
        let serie = [];
        let yaxis = [];
        let flags = [];

        for (let i = 1; i < ignition.length; i++) {
          if (
            ignition[i].y > ignition[i - 1].y ||
            ignition[i].y < ignition[i - 1].y
          ) {
            ignition[i].x = ignition[i - 1].x;
          }
        }

        //Flags
        for (let j = 0; j < this.repFuelVariation.length; j++) {
          for (let i = 1; i < fuel.length; i++) {
            // Comparaison correcte en utilisant getTime()
            if (
              fuel[i - 1].x === this.repFuelVariation[j].approStartTime.getTime() &&
              this.repFuelVariation[j].type === "APPRO"
            ) {
              flags.push({
                type: "flags",
                data: [
                  {
                    x: +fuel[i].x,
                    title: "Appro",
                    text:
                      "Appro Level: " +
                      (this.repFuelVariation[j].approEndFuelLevel -
                        this.repFuelVariation[j].approStartFuelLevel)
                  }
                ],
                linkedTo: "dataseries",
                onSeries: "dataseries",
                shape: "flag",
                showInLegend: false,
                color: "rgb(44, 161, 33)",
                fillColor: "rgb(44, 161, 33)"
              });
            } else if (
              fuel[i - 1].x === this.repFuelVariation[j].approStartTime.getTime() &&
              this.repFuelVariation[j].type === "THIEF"
            ) {
              flags.push({
                type: "flags",
                data: [
                  {
                    x: fuel[i].x,
                    title: "Diminution",
                    text:
                      "Appro Level: " +
                      (this.repFuelVariation[j].approEndFuelLevel -
                        this.repFuelVariation[j].approStartFuelLevel)
                  }
                ],
                linkedTo: "dataseries",
                onSeries: "dataseries",
                shape: "flag",
                showInLegend: false,
                color: "rgb(230, 20, 9)",
                fillColor: "rgb(230, 20, 9)"
              });
            }
          }
        }

        if (this.ChartSeries(fuel)) {
          yaxis.push({
            title: {
              text: "carburant",
              style: {
                color: "rgb(23, 119, 182)"
              }
            },
            labels: {
              format: "{value} " + "L",
              style: {
                color: "rgb(23, 119, 182)"
              }
            },
            min: 0,
            opposite: yaxis.length !== 0
          });
          serie.push({
            name: "Carburant",
            data: fuel,
            id: "dataseries",
            color: "rgb(23, 119, 182)",
            yAxis: this.PositionYaxis(yaxis, "carburant"),
            tooltip: {
              valueSuffix: "L",
              valueDecimals: 2
            }
          });
          flags.forEach(flag => {
            serie.push(flag);
          });
        }

        //Create a new HighChart
        this.options = new Highcharts.Chart({
          title: { text: "Véhicule: " + this.selectedVehicule.matricule },
          chart: {
            type: "spline",
            renderTo: "container",
            height: 400,
            zoomType: "x"

          },

          xAxis: [
            {
              type: "datetime",
              tickInterval: 3600 * 1000,
              labels: {
                // tooltip: {
                //   formatter: function (this:): string {
                //     return Highcharts.dateFormat("%d/%m/%Y %H:%M", this.x);
                //   }
                // }

              },
              crosshair: true
            }
          ],

          yAxis: yaxis,

          plotOptions: {
            series: {
              turboThreshold: 100000
            }
          },

          // series: serie
        });
      });
  }


  //Return the position of Yaxis
  PositionYaxis(data: any, option: string) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.text === option) {
        return i;
      }
    }
  }

  //check if the series is empty or not
  ChartSeries(data: { y?: number }[] = []): boolean {
    let ok: boolean = false; // Initialisation de `ok` à false
    data.forEach((dt: { y?: number }) => { // Spécification du type de `dt`
      ok = dt.y !== undefined; // Vérification de la présence de `y`
    });
    return ok;
  }

  // saveInstance(chartInstance) {
  //   this.chart = chartInstance;
  // }

  // onSeriesMouseOver(e) {
  //   this.serieName = e.context.name;
  // }


  /** LOAD DATA MANAGEMENT */
  loadDataManagement() {
    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule;

      this.getDeviceOpt(this.selectedVehicule.idDevice);
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
              this.getDeviceOpt(this.selectedVehicule.idDevice);
            }
          }
        });
    }
  }

//Get Device Options and fill the dropdownList
getDeviceOpt(deviceid: number) {
  if (deviceid <= 0) {
    this.toastr.warning("Id de boitier introuvable !", "Erreur")
    return;
  }
  this.dropdownList = [];
  this.isResultLoaded = false;
  this.technicalDataService.getDeviceOpt(deviceid).subscribe(response => {
    this.deviceopt = response;
    this.dropdownList.push({
      id: 1,
      option: "fuel",
      itemName: "Carburant"
    });
    this.selectItems = this.dropdownList;
    this.isResultLoaded = true;
  });
}


  loadDeviceFuelStettings() {
    this.technicalDataService.getDeviceCarb(this.selectedVehicule.idDevice).subscribe(reponse => {
      this.currentCarbSetting = reponse;
      this.toastr.info('Configuration carburant chargé', 'info');
    }, (error) => {
      this.currentCarbSetting = new DeviceCarb();
      this.toastr.warning('Erreur lors de chargement de configuration carburant', 'info');
    });
  }




  loadFuels() {
    if (!this.selectedVehicule.idVehicule) return;
    this.fuelingService.getListFuelingByDate(this.selectedVehicule.idVehicule, this.year).subscribe(fuels => {
      this.fuels = fuels;
    });
  }

  carburantInfo() {

    this.loadDeviceFuelStettings();
    this.loadFuels();
    this.fuels = Object.assign([], this.fuels);
  }
  getYears() {
    return [
      { id: 'ThisYear', name: 'Année En cours' },
      { id: 'PreviousYear', name: 'Année Antérieure' }

    ];
  }

  onChangeYear(Year: string) {
    if (Year == 'ThisYear' || Year == 'PreviousYear') {
      if(Year == 'ThisYear'){
      this.year = new Date(this.startDate.getFullYear(), 1, 0);
     }else{
      this.year = new Date(this.startDate.getFullYear(), 0, 0);
     }
    }
    else {
      this.toastr.error("Type Non Valide !", 'Erreur');
    }
  }

}
