import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatNumberPipe } from './format-number.pipe'; // Adjust the path as needed

@NgModule({
  declarations: [
    FormatNumberPipe // Declare the FormatNumberPipe here
  ],
  imports: [
    CommonModule // Import CommonModule for common directives
  ],
  exports: [
    FormatNumberPipe // Export the pipe so it can be used in other modules
  ]
})
export class FormatNumberModule { }
