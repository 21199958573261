import { OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataManagementService } from '../../../data-management/data-management.service';
import { LossesService } from '../../providers/losses.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { Vehicule, RapportDto } from '../../../data-management/data-management.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Offense, MultiType, Group } from '../../model';
import { ImportExportService } from '../../../import-export';

@Component({
  selector: 'app-offenses',
  templateUrl: './offenses.component.html',
  styleUrls: ['./offenses.component.css']
})
export class OffensesComponent implements OnInit, OnDestroy {

  @ViewChild('addEditModal', { static: false }) addEditModal!: ModalDirective;

  @ViewChild('importModal', { static: false }) importModal!: ModalDirective;

  @ViewChild('offenseDeleteModal', { static: false }) offenseDeleteModal!: ModalDirective;

  selectedVehicule: Vehicule = new Vehicule();

  selectedGroup: Group = new Group();

  selectedItem = 'OFFENSE';

  offenses: Offense[] = [];

  offensesId: number[] = [];

  repport: RapportDto = new RapportDto();

  getVehiculeWasSelectedSubscription!: Subscription;

  getGroupWasSelectedSubscription!: Subscription;

  loading: boolean = false;

  isDeleteMultiple: boolean = false;

  mode = 'ADD';

  currentOffense: Offense = new Offense();

  searchTerm: string | null = null;

  constructor(private dataManagementService: DataManagementService, public toastr: ToastrService,
              private lossesService: LossesService, public exportImport: ImportExportService) {
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
      this.selectedGroup = this.dataManagementService.selectedGroup;
      this.loadOffenses();
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.selectedVehicule = vehicule;
      this.loadOffenses();
    });
    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.selectedGroup = group;
    });
  }

  ngOnDestroy() {
    if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
  }

  init() {
    this.currentOffense = new Offense();
  }

  public loadOffenses(): void {
    if(!this.selectedVehicule.idVehicule) return;
    this.loading = true;
    this.lossesService.getOffenses(this.selectedVehicule.idVehicule).subscribe(offenses => {
      this.offenses = offenses;
      this.loading = false;
    }, (err) => {
      this.loading = false;
    });
  }

  updateoffense(offense: Offense) {
    this.currentOffense = Object.assign({}, offense);
    this.mode = 'UPDATE';
    if (!this.currentOffense.type) {
      this.currentOffense.type = new MultiType();
    }
    this.addEditModal.show();
  }

  offenseWasAdded(offense: Offense) {
    this.offenses.unshift(offense);
    this.addEditModal.hide();
    this.toastr.success('Infraction informations bien sauvegarder !', 'info');
  }

  offenseWasUpdated(offense: Offense) {
    for (let i = 0; i < this.offenses.length; i++) {
      if (this.offenses[i].id === offense.id) {
        this.offenses[i] = offense;
        break;
      }
    }
    this.addEditModal.hide();
    this.toastr.success('Infraction informations bien sauvegarder !', 'info');
  }

  export() {
    this.repport.type = 'OFFENSE'
    this.exportImport.export(this.offenses, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
  }

  itemWasImported(res: boolean) {
    if(res){
      this.importModal.hide();
      this.loadOffenses();
    }
  }

  onConfirmDelete(offense: Offense) {
    this.currentOffense = offense;
    if (!this.currentOffense.type) {
      this.currentOffense.type = new MultiType();
    }
    this.isDeleteMultiple = false;
    this.offenseDeleteModal.show();
  }

  onConfirmDeleteMultiple() {
    if (this.offensesId.length == 0) {
      this.toastr.warning("Veuillez choisir des sinistres à supprimer !");
      return;
    }
    this.isDeleteMultiple = true;
    this.offenseDeleteModal.show();
  }

  onDelete() {
    if (this.isDeleteMultiple) {
      this.deleteMultiple();
    } else {
      this.delete();
    }
  }

  delete() {
    this.loading = true;
    this.lossesService.deleteOffense(this.currentOffense.id).subscribe(response => {
      if (response) {
        this.offenses = this.offenses.filter(offense => offense.id != this.currentOffense.id);
        this.offensesId = this.offensesId.filter(offenseId => offenseId != this.currentOffense.id);
        this.toastr.success('Sinistre informations bien supprimer !', 'info');
      } else {
        this.toastr.error('Le sinistre n\'a pas supprimé correctement !', 'Erreur');
        this.loading = false;
        this.offenseDeleteModal.hide();
      }
    }, error => {
      this.loading = false;
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
    });
  }

  deleteMultiple() {
    this.loading = true;
    this.lossesService.deleteMultipleOffense(this.offensesId).subscribe(response => {
      if (response) {
        for (let offenseId of this.offensesId) {
          this.offenses = this.offenses.filter(offense => offense.id != offenseId);
        }
        this.offensesId = [];
        this.toastr.success('Suppression effectuée avec succès.', 'info');
      } else {
        this.toastr.error('Les sinistres n\'a pas supprimé correctement !', 'Erreur');
      }
      this.offenseDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)). errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }

  getOffenses(): Offense[] {
    if ((this.searchTerm) && (this.searchTerm.length > 0)) {
      return this.offenses.filter(sinistre =>
        (sinistre.type) && (sinistre.type.name.toLowerCase().indexOf(this.searchTerm!.toLowerCase()) > -1)
      );
    } else {
      return this.offenses;
    }
  }

  onChecked(id: number) {
    if (this.offensesId.find(offenseId => offenseId == id)) {
      this.offensesId = this.offensesId.filter(sinistreId => sinistreId != id);
    } else {
      this.offensesId.push(id);
    }
  }

  onCheckedAll() {
    if (this.offensesId.length == this.offenses.length) {
      this.offensesId = [];
    } else {
      this.offensesId = [];
      for (let sinistre of this.offenses) {
        this.offensesId.push(sinistre.id);
      }
    }
  }



  isCheckedInList(id: number): boolean {
    return this.offensesId.find(brakeId => brakeId == id) ? true : false;
  }
}
