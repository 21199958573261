import { Injectable } from '@angular/core';
import { GroupDashbordDto, SinistreDashbordDto } from 'src/app/client-management/parc-management';
import { Observable } from 'rxjs';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SinistreChartService {

  constructor(private _http: HttpClient) { }

  /** Return dashbord info of all the groups and vehicules  */
  public getSinistreInfo(
    date: DateInterval,
    groupIds: string,
    IsByParent: boolean
  ): Observable<GroupDashbordDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<GroupDashbordDto[]>(dns + 'groupes/getAllExpensesByGroup?type=SINISTRE&IsByParent=' + IsByParent+'&groupIds='+groupIds, date , options);
  }

  getSinistresBygroupe(idGroupe: number, date: DateInterval, IsByParent: boolean): Observable<SinistreDashbordDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<SinistreDashbordDto[]>(dns + 'sinistres/groupe/' + idGroupe + '?IsByParent=' + IsByParent, date, options);
  }

}
