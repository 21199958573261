<section class="content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
    <div class="row">
        <div>
            <div class="col-md-12 datatable-container-col">
                <div class="box box-info" style="border-radius: 0px;">

                    <div class="box-header with-border">
                        <form class="form-horizontal">
                            <div class="form-group row">

                                <label class="col-sm-2 control-label">Groupe</label>
                                <div class="col-sm-4">
                                    <select class="form-control input-sm" [(ngModel)]="selectedGroup" name="groups"
                                        (ngModelChange)="chooseGroup($event)">
                                        <option *ngFor="let group of groups" [ngValue]="group" style="font-size: 14px;">
                                            <span *ngIf="group.nameGroupParent">{{group.nameGroupParent |
                                                capitalizefirst | truncate:[30]}} / </span> {{group.nom |
                                            capitalizefirst}}
                                        </option>
                                    </select>
                                </div>

                                <label class="col-sm-2 control-label">Vehicule</label>
                                <div class="col-sm-4">
                                    <select class="form-control input-sm" [(ngModel)]="selectedVehicule"
                                        name="vehicules" (ngModelChange)="chooseVehicule($event)">
                                        <option *ngFor="let vehicule of selectedGroup.vehicules"
                                            style="font-size: 14px;" [ngValue]="vehicule">
                                            {{dataManagementService.getVehiculeName(vehicule) |
                                            capitalizefirst}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </form>
                    </div>

                    <!-- device Carb -->
                    <div>
                        <div class="box-body">

                            <div class="alert  alert-dismissible"
                                [ngClass]="{'alert-success': isResutSuccess  && isResultLoaded,'alert-danger': !isResutSuccess && isResultLoaded,'alert-warning':!isResultLoaded}"
                                style="border-radius: 0px;">

                                <i class="icon fa fa-check" *ngIf="isResutSuccess && isResultLoaded"></i>

                                <i class="fa fa-times" *ngIf="!isResutSuccess && isResultLoaded"></i>

                                <i class="fa fa-spinner fa-spin" *ngIf="!isResultLoaded"></i>

                                {{message}}

                            </div>

                            <form class="form-horizontal" #fuelSettingForm="ngForm">
                                <div class="form-group row">

                                    <label class="col-sm-2 control-label">Max</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control input-sm" placeholder="Max"
                                            required=true [(ngModel)]="currentCarbSetting.max" name="max"
                                            #max="ngModel">
                                    </div>

                                    <label class="col-sm-2 control-label">Min</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control input-sm" placeholder="Min"
                                            required=true [(ngModel)]="currentCarbSetting.min" name="min"
                                            #min="ngModel">
                                    </div>

                                </div>
                                <div class="form-group row">

                                    <label class="col-sm-2 control-label">Filtrage</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control input-sm" placeholder="Filtrage"
                                            required=true [(ngModel)]="currentCarbSetting.filtrage" name="filtrage"
                                            #filtrage="ngModel">
                                    </div>

                                    <label class="col-sm-2 control-label">Volume</label>
                                    <div class="col-sm-4">

                                        <input type="number" class="form-control input-sm" placeholder="Volume"
                                            required=true [(ngModel)]="currentCarbSetting.volume" name="volume"
                                            #volume="ngModel">

                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 control-label">Consommation théorique (%)</label>
                                    <div class="col-sm-4">

                                        <input type="number" class="form-control input-sm"
                                            placeholder="Consommation théorique" required=true
                                            [(ngModel)]="currentCarbSetting.fuelAverage" name="fuelAverage">

                                    </div>

                                    <label class="col-sm-2 control-label">Marge</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control input-sm" placeholder="Marge"
                                            required=true [(ngModel)]="currentCarbSetting.margin" name="margin"
                                            #margin="ngModel">
                                    </div>

                                </div>

                            </form>
                        </div>

                        <div class="box-footer">
                            <button type="submit" class="btn btn-flat btn-info pull-right" (click)="updateDeviceCarb()"
                                [disabled]="!fuelSettingForm.form.valid"><i class="fa fa-spinner fa-spin"
                                    *ngIf="!isResultLoaded"></i> Sauvegarder
                            </button>
                        </div>
                    </div>
                    <!-- end device Carb -->

                    <!-- device Mems -->
                    <div *ngIf="currentMemsSetting">
                        <div class="box-body">

                            <div class="box-header page-header with-border">


                                <h3 class="box-title"
                                    style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;">
                                    <i class="fa fa-list"></i>
                                    <span style="text-decoration: underline">Qualité de conduite</span>
                                </h3>


                            </div>

                            <div class="alert  alert-dismissible"
                                [ngClass]="{'alert-success': isMemsResutSuccess  && isMemsResultLoaded,'alert-danger': !isMemsResutSuccess && isMemsResultLoaded,'alert-warning':!isMemsResultLoaded}"
                                style="border-radius: 0px;">

                                <i class="icon fa fa-check" *ngIf="isMemsResutSuccess && isMemsResultLoaded"></i>

                                <i class="fa fa-times" *ngIf="!isMemsResutSuccess && isMemsResultLoaded"></i>

                                <i class="fa fa-spinner fa-spin" *ngIf="!isMemsResultLoaded"></i>

                                {{memsMessage}}

                            </div>

                            <form class="form-horizontal" #memsSettingForm="ngForm">
                                <div class="form-group row">

                                    <label class="col-sm-2 control-label">Freinage</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control input-sm" placeholder="Freinage"
                                            required=true [(ngModel)]="currentMemsSetting.nbFrein" name="nbFrein">
                                    </div>

                                    <label class="col-sm-2 control-label">Accélération agressif</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control input-sm"
                                            placeholder="Accélération agressif" required=true
                                            [(ngModel)]="currentMemsSetting.nbAcc" name="nbAcc" #nbAcc="ngModel">
                                    </div>

                                </div>
                                <div class="form-group row">

                                    <label class="col-sm-2 control-label">Virage</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control input-sm" placeholder="Virage"
                                            required=true [(ngModel)]="currentMemsSetting.nbDrift" name="nbDrift"
                                            #nbDrift="ngModel">
                                    </div>

                                    <label class="col-sm-2 control-label">Secousse</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control input-sm" placeholder="Secousse"
                                            required=true [(ngModel)]="currentMemsSetting.nbJerk" name="nbJerk"
                                            #nbJerk="ngModel">
                                    </div>
                                </div>

                            </form>
                        </div>

                        <div class="box-footer">
                            <button type="submit" class="btn btn-flat btn-info pull-right" (click)="updateDeviceMems()"
                                [disabled]="!memsSettingForm.form.valid"><i class="fa fa-spinner fa-spin"
                                    *ngIf="!isMemsResultLoaded"></i> Sauvegarder
                            </button>
                        </div>
                    </div>
                    <!-- end device Mems -->
                </div>
            </div>
        </div>
    </div>
</section>