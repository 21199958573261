import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Fueling, Provider, MultiType } from "../../model";
import { Vehicule, Group } from "../../../data-management/data-management.model";
import { ProviderService } from "../../providers/provider-service";
import { MultiTypeService } from "../../providers/multi-type-service";
import { FuelingService } from "../../providers/fueling-service";
import { ToastrService } from "ngx-toastr";
import { NgForm } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-carburant-form',
    templateUrl: './carburant-form.component.html',
    styleUrls: ['./carburant-form.component.css']
})
export class CarburantFormComponent implements OnInit {

    loading: boolean = false;

    providers: Provider[] = [];

    paymentTypes: MultiType[] = [];

    @Input()
    fuel: Fueling = new Fueling();

    @Input()
    vehicule: Vehicule = new Vehicule();

    @Input()
    group: Group = new Group();

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';

    @Output()
    fuelWasAdded: EventEmitter<Fueling> = new EventEmitter<Fueling>();

    @Output()
    fuelWasUpdated: EventEmitter<Fueling> = new EventEmitter<Fueling>();

    operationTime: Date = new Date();

    maxDate: Date = new Date();

    constructor(private fuelingService: FuelingService, public toastr: ToastrService, private providerService: ProviderService, private multiTypeService: MultiTypeService, private localeService: BsLocaleService) {
    }

    ngOnInit() {
        this.localeService.use('fr');
        this.loadPaymentType();
        this.loadProviders();
    }

    getCostUnit(): number {
        if (this.fuel.cost > 0 && this.fuel.quantity > 0){
            var costUnit =   (this.fuel.cost / this.fuel.quantity).toFixed(2)  ;
           return +costUnit;
        }
    }

    loadProviders() {
        this.providerService.getProviders("FUEL").subscribe(providers => {
            this.providers = providers;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    loadPaymentType() {
        this.multiTypeService.getListType("PAYMENT").subscribe(offenseTypes => {
            this.paymentTypes = offenseTypes;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    onAdd(form: NgForm) {
        this.loading = true;
        this.fuelingService.add(this.fuel, this.vehicule.idVehicule, this.group.idGroupe).subscribe(fuel => {
            this.fuelWasAdded.emit(fuel);
            form.reset();
            this.operationTime = new Date();
            this.loadProviders();
            this.loadPaymentType();
            this.loading = false;
            this.toastr.success('Carburant informations bien sauvegarder !', 'info');
        }, (error) => {
            this.loading = false;
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }


    onUpdate(form: NgForm) {
        this.loading = true;
        this.fuelingService.update(this.fuel).subscribe(fuel => {
            this.fuelWasUpdated.emit(fuel);
            form.reset();
            this.operationTime = new Date();
            this.loadProviders();
            this.loadPaymentType();
            this.loading = false;
            this.toastr.success('Modification effectuée avec succès.', 'info');
        }, (error) => {
            this.loading = false;
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        })
    }

}
