import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassificationDriverTurnOverChartComponent } from './classification-driver-turn-over-chart.component';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if ToastrService is used

@NgModule({
  declarations: [
    ClassificationDriverTurnOverChartComponent // Declare the ClassificationDriverTurnOverChartComponent
  ],
  imports: [
    CommonModule,                  // Import necessary Angular modules
    ToastrModule.forRoot()         // Include ToastrModule if ToastrService is used
  ],
  providers: [
    ChartConfigService             // Provide the ChartConfigService for dependency injection
  ],
  exports: [
    ClassificationDriverTurnOverChartComponent // Export the ClassificationDriverTurnOverChartComponent for use in other modules
  ]
})
export class ClassificationDriverTurnOverChartModule { }
