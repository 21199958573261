<div
  class="box"
  style="margin-right: 50px; margin-top: -20px; z-index: 10; width: 672px"
>
  <div class="box-body">
    <span
      style="line-height: 35px; padding: 0 10px; font-size: 17px; color: #444"
      ><i class="fa fa-gear"></i> Paramétrage des alertes</span
    >
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs pull-right">
        <li class="dropdown">
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <i class="fa fa-plus-circle Plus"></i>
          </a>
          <ul class="dropdown-menu">
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/speedCartoSetting"
            >
              <a>Vitesse Carto</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/documentSetting"
            >
              <a>Documents de circulations</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/oilChangeSetting"
            >
              <a>Vidange</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/tireSetting"
            >
              <a>Pneus</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/batteriesSetting"
            >
              <a>Gestion Batterie</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/batterySetting"
            >
              <a
                ><!--<i class="fa fa-battery-full"></i>-->
                Alimentation Batterie</a
              >
            </li>
            <!-- <li routerLinkActive="active" routerLink="/client/alertManagement/alertSettings/consumptionSetting"><a><i class="fa fa-car"></i> Consommation</a></li> -->

            <!--<li routerLinkActive="active" routerLink="/client/alertManagement/alertSettings/brakeSetting"><a>Freins</a></li>-->
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/activitySetting"
            >
              <a>Heure d'activité continue</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/activityHourSetting"
            >
              <a>Heure de travail</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/chomage"
            >
              <a>Retard d'affectation</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/chargement"
            >
              <a>Retard chargement</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/remisageSetting"
            >
              <a>Remisage</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/approFuelSetting"
            >
              <a>Approvisionnement carburant</a>
            </li>
            <li
              routerLinkActive="active"
              routerLink="/client/alertManagement/alertSettings/activityDailySetting"
            >
              <a>Planification</a>
            </li>
          </ul>
        </li>
        <!--<li routerLinkActive="active" routerLink="/client/alertManagement/alertSettings/towingSetting"><a>Remorquage</a></li>-->
        <li
          routerLinkActive="active"
          routerLink="/client/alertManagement/alertSettings/speedSetting"
        >
          <a><i class="fa fa-car"></i> Vitesse</a>
        </li>
        <li
          routerLinkActive="active"
          routerLink="/client/alertManagement/alertSettings/noPoiSetting"
        >
          <a>Arrêt Non P.O.I</a>
        </li>
        <li
          routerLinkActive="active"
          routerLink="/client/alertManagement/alertSettings/poiSetting"
        >
          <a>Arrêt P.O.I</a>
        </li>
        <li
          routerLinkActive="active"
          routerLink="/client/alertManagement/alertSettings/ignitionSetting"
        >
          <a>Contact v = 0</a>
        </li>
        <li
          routerLinkActive="active"
          routerLink="/client/alertManagement/alertSettings/zoneSetting"
        >
          <a>Zones</a>
        </li>
      </ul>
      <div class="tab-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
