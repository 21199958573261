import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechnicalDataComponent } from './technical-data.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // For displaying notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker functionality
import { FormsModule } from '@angular/forms'; // For using ngModel
import { TechnicalDataService } from './technical-data.service'; // Service for technical data
import { DataManagementService } from '../../data-management/data-management.service'; // Service for data management
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
//import { ChartModule } from 'angular-highcharts'; // Assuming you are using angular-highcharts for charts

@NgModule({
  declarations: [
    TechnicalDataComponent, // Declare the TechnicalDataComponent
  ],
  imports: [
    CommonModule, // Common module for Angular common directives
    ToastrModule.forRoot(), // Import Toastr for notifications
    BsDatepickerModule.forRoot(), // Import Bootstrap datepicker
    FormsModule,
    AngularMultiSelectModule,
    SharedPipesModule,
    TruncateModule
  ],
  providers: [
    TechnicalDataService, // Provide the TechnicalDataService
    DataManagementService, // Provide the DataManagementService
  ],
  exports: [
    TechnicalDataComponent, // Export the TechnicalDataComponent for use in other modules
  ]
})
export class TechnicalDataModule { }
