import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { MissionComponent } from './mission.component';
import { DataManagementComponent } from '../data-management.component';
import { MissionService } from '../providers';
import { RouterModule } from '@angular/router';
import { MissionFormComponent } from './mission-form/mission-form.component';
import { DataManagementModule } from '../data-management.module';
import { MissionFormModule } from './mission-form/mission-form.module';
import { TimediffPipeModule } from 'src/app/pipes/timediff.pipe.module';
import { NgxLoadingModule } from 'ngx-loading';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    MissionComponent,
  ],
  imports: [
    CommonModule,  // Angular common functionalities
    FormsModule,   // For form handling (ngModel, ngForm, etc.)
    ModalModule.forRoot(),  // Import ngx-bootstrap modal module
    ToastrModule.forRoot(),  // Import ngx-toastr for notifications
    RouterModule,
    DataManagementModule,
    MissionFormModule,
    NgxLoadingModule.forRoot({}),
    TimediffPipeModule,
    FilterPipeModule,
  ],
  providers: [
    MissionService  // Provide the MissionService for dependency injection
  ],
  exports: [
    MissionComponent  // Export MissionComponent for use in other modules if needed
  ]
})
export class MissionModule { }
