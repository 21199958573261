import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChargePfbInfoDto, ChargePfbAddDto, VoyageDto } from './charges-pfb';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { FuelingDashboardDto } from '../parc-management';

@Injectable({
  providedIn: 'root' // Cela rend le service disponible dans toute l'application
})

export class ChargesPfbService {

  months = [
    { 'id': 1, 'name': 'janvier' },
    { 'id': 2, 'name': 'février' },
    { 'id': 3, 'name': 'mars' },
    { 'id': 4, 'name': 'avril' },
    { 'id': 5, 'name': 'mai' },
    { 'id': 6, 'name': 'juin' },
    { 'id': 7, 'name': 'juillet' },
    { 'id': 8, 'name': 'août' },
    { 'id': 9, 'name': 'septembre' },
    { 'id': 10, 'name': 'octobre' },
    { 'id': 11, 'name': 'novembre' },
    { 'id': 12, 'name': 'décembre' },
  ];
  isManually: boolean = false;
  constructor(private http: HttpClient) {
    const isManuallyStr = localStorage.getItem('isManually');
    if (isManuallyStr !== null) {
      this.isManually = JSON.parse(isManuallyStr);
    }
  }

  getYears() {
    return [
      { id: new Date().getFullYear(), name: 'Année En cours' },
      { id: new Date().getFullYear() - 1, name: 'Année Antérieure' },
      { id: new Date().getFullYear() - 2, name: 'Année -2' }
    ];
  }

  getChargesPfb(): Observable<ChargePfbInfoDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<ChargePfbInfoDto[]>(dns + "charges-pfb", options);
  }

  getHistoricalCharges(): Observable<String[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<String[]>(dns + "charges-pfb/historical", options);
  }

  getHistoricalChargesByMonth(month: number, year: number): Observable<ChargePfbInfoDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<ChargePfbInfoDto[]>(dns + "charges-pfb/historical/year/" + year + "/month/" + month, options);
  }

  add(payload: ChargePfbAddDto): Observable<ChargePfbInfoDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<ChargePfbInfoDto>(dns + "charges-pfb", payload, options);
  }

  update(payload: ChargePfbAddDto, selectedYear_: number, selectedMonth: number): Observable<ChargePfbInfoDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.put<ChargePfbInfoDto>(dns + "charges-pfb?month=" + selectedMonth + "&year=" + selectedYear_, payload, options);
  }

  delete(chargePfbName: string, transportTypeId: number): Observable<boolean> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.delete<boolean>(dns + "charges-pfb/" + transportTypeId + "/" + chargePfbName, options);
  }

  getFuelCharge(chargePfbId: number, dateIntervalDto: DateInterval, month: number): Observable<FuelingDashboardDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<FuelingDashboardDto[]>(dns + "charges-pfb/fuelingCost/" + chargePfbId + "?month=" + month + "&timezoneOffset=" + (new Date().getTimezoneOffset()), dateIntervalDto, options);
  }

  getRotationCharge(chargePfbId: number, dateIntervalDto: DateInterval): Observable<VoyageDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<VoyageDto>(dns + 'charges-pfb/TravelCount/' + chargePfbId + '/?timezoneOffset=' + (new Date().getTimezoneOffset()), dateIntervalDto, options);
  }

  getCaRealise(chargePfbId: number, dateIntervalDto: DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<any>(dns + 'charges-pfb/manually/caRealise/' + chargePfbId + "?timezoneOffset=" + (new Date().getTimezoneOffset()), dateIntervalDto, options);
  }

}
