import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeadlinesComponent } from './deadlines.component'; // Adjust the import path as necessary
import { DeadlinesService } from './deadlines.service'; // Adjust if needed
import { ExportDatatableService } from './export-datatable.service'; // Adjust if needed
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [
    DeadlinesComponent // Declare the component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxDatatableModule,
  ],
  providers: [
    DeadlinesService, // Provide the service that is used in this component
    ExportDatatableService // Provide the export service as well
  ],
  exports: [
    DeadlinesComponent // Export the component if it needs to be used in other modules
  ]
})
export class DeadlinesModule {}
