import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninComponent } from './signin.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { SigninService } from './signin.service';
import { DataManagementService } from '../../client-management/data-management/data-management.service';
import { MapService } from '../../utils/leaflet/service/map.service';
import { PassageService, TransportTypeService } from 'src/app/client-management/charges-pfb';
import { RealtimeRestService } from 'src/app/client-management/realtime/services/realtime-rest.service';

@NgModule({
  declarations: [
    SigninComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    SigninService,
    DataManagementService,
    MapService,
    PassageService,
    TransportTypeService,
    RealtimeRestService
  ],
  exports: [
    SigninComponent
  ]
})
export class SigninModule { }
