import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { Driver } from "../data-management.model";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DriverService {
  constructor(private _http: HttpClient) {}

  // valid
  getDriversLabel(): Observable<Driver[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Driver[]>(dns + "drivers", { headers: headers });
  }

  // valid
  getDriversByPageAndSize(
    page: number,
    size: number,
    search: string = ""
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(
        dns +
          "drivers/" +
          "list" +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,
        { headers: headers }
      );
  }

  // valid
  deleteDriverById(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete<Boolean>(dns + "drivers/" + id, { headers: headers });
  }

  // valid
  public updateDriver(payload: FormData, deleteDriverPicture : boolean): Observable<Driver> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<Driver>(dns + "drivers/update?deleteDriverPicture=" + deleteDriverPicture, payload, options);
  }

  // valid
  public addDriver(payload: FormData): Observable<Driver> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<Driver>(dns + "drivers/", payload, options);
  }

  // valid
  getOne(idDriver: number): Observable<Driver> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Driver>(dns + "drivers/" + idDriver, { headers: headers });
  }
}
