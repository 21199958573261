import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartTitreGraphiqueComponent } from './chart-titre-graphique.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [ChartTitreGraphiqueComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot() // Importing ModalModule for modal functionalities if needed
  ],
  exports: [ChartTitreGraphiqueComponent] // Exporting the component for use in other modules
})
export class ChartTitreGraphiqueModule {}
