import { Component, Input, OnInit } from '@angular/core';
import { Schedule } from '../schedule';

@Component({
  selector: 'app-schedule-chart',
  templateUrl: './schedule-chart.component.html',
  styleUrls: ['./schedule-chart.component.css']
})
export class ScheduleChartComponent implements OnInit {

  dataSource: Object | null = null;

  scheduleList_: Schedule[] = [];
  height = 300;
  start = "04:00:00";
  category = [
    {
      start: "04:00:00",
      end: "04:59:59",
      label: "4H"
    },
    {
      start: "05:00:00",
      end: "05:59:59",
      label: "5H"
    },
    {
      start: "6:00:00",
      end: "06:59:59",
      label: "6H"
    },
    {
      start: "07:00:00",
      end: "07:59:59",
      label: "7H"
    },
    {
      start: "8:00:00",
      end: "8:59:59",
      label: "8H"
    },
    {
      start: "09:00:00",
      end: "09:59:59",
      label: "9H"
    },
    {
      start: "10:00:00",
      end: "10:59:59",
      label: "10H"
    },
    {
      start: "11:00:00",
      end: "11:59:59",
      label: "11H"
    },
    {
      start: "12:00:00",
      end: "12:59:59",
      label: "12H"
    },
    {
      start: "13:00:00",
      end: "13:59:59",
      label: "13H"
    },
    {
      start: "14:00:00",
      end: "14:59:59",
      label: "14H"
    },
    {
      start: "15:00:00",
      end: "15:59:59",
      label: "15H"
    },
    {
      start: "16:00:00",
      end: "16:59:59",
      label: "16H"
    },
    {
      start: "17:00:00",
      end: "17:59:59",
      label: "17H"
    },
    {
      start: "18:00:00",
      end: "18:59:59",
      label: "18H"
    },
    {
      start: "19:00:00",
      end: "19:59:59",
      label: "19H"
    },
    {
      start: "20:00:00",
      end: "20:59:59",
      label: "20H"
    },
    {
      start: "21:00:00",
      end: "21:59:59",
      label: "21H"
    },
    {
      start: "22:00:00",
      end: "22:59:59",
      label: "22H"
    },
    {
      start: "23:00:00",
      end: "23:59:59",
      label: "23H"
    }];

  isCiment_ = false;

  @Input()
  set isCiment(isCiment: boolean) {
    this.isCiment_ = isCiment;
    if (isCiment) {

      this.category.unshift(
        {
          start: "00:00:00",
          end: "00:59:59",
          label: "0H"
        },
        {
          start: "01:00:00",
          end: "01:59:59",
          label: "1H"
        },
        {
          start: "02:00:00",
          end: "02:59:59",
          label: "2H"
        },
        {
          start: "03:00:00",
          end: "03:59:59",
          label: "3H"
        }
      );
      this.start = "00:00:00";
    }
  }


  @Input()
  set scheduleList(scheduleList: Schedule[]) {
    if (scheduleList) {
      this.scheduleList_ = [];
      this.scheduleList_ = Object.assign([], scheduleList);
      this.CreateChart();
    }
  }

  constructor() { }

  ngOnInit() {
  }

  CreateChart() {
    if (this.isCiment_) {
      this.start = "00:00:00";
    }

    var task: any[] = [];
    var process: any[] = [];
    var matricule = new Set(this.scheduleList_.map(s => { return s.matricule }));

    matricule.forEach(matricule => {

      process.push({
        label: matricule,
        id: matricule,
      })
    });

    if (matricule.size > 5) {
      this.height = matricule.size * 35;
    }

    for (let dt of this.scheduleList_) {
      //var label = dt.name != null ? dt.name : ""  + "<br>";
      var label = dt.name;

      if (dt.poiDepart && dt.poiArrive)
        label += "<br> <b>Poi Départ :</b>" + dt.poiDepart + " ,<b>Poi arrivé :</b> " + dt.poiArrive + "<br>";
      if (dt.dateStart) {
        let date = new Date(dt.dateStart);
        let minutes = date.getMinutes() + "";
        if (minutes.length === 1) {
          minutes = "0" + date.getMinutes();
        }
        label += "<b>Date Départ :</b>" + date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear() +
          " " +
          date.getHours() +
          ":" +
          minutes +"<br >"
      }
      task.push({
        processid: dt.matricule,
        start: new Date(dt.beginStopTime).getHours().toString() + ':' + new Date(dt.beginStopTime).getMinutes().toString(),
        end: new Date(dt.endStopTime).getHours().toString() + ':' + new Date(dt.endStopTime).getMinutes().toString(),
        label: label,
        color: dt.color,
      })

    }
    const data = {
      tasks: {
        showlabels: "0",
        color: "#5D62B5",
        task: task,
      },



      processes: {
        fontsize: "14",
        isbold: "1",
        align: "Center",
        headertext: "Matricule",
        headerfontsize: "16",
        headervalign: "middle",
        headeralign: "left",
        process: process
      },
      categories: [
        // {
        //   category: [
        //     {
        //       start: this.start,
        //       end: "23:59:59",
        //      // label: "Temps"
        //     }
        //   ]
        // },
        {
          align: "center",
          category: this.category
        }
      ],
      chart: {
        dateformat: "dd/mm/yyyy",
        outputdateformat: "hhH:mn",
        //caption: "Ordonnacement BPE",
        ganttpaneduration: "24",
        ganttpanedurationunit: "h",
        scrolltodate: "20:00:00",
        useverticalscrolling: "0",
        theme: "fusion",
      }
    };
    this.dataSource = data;
  }
}
