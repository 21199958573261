import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DateInterval } from 'src/app/shared/model';
import { AffectationInfo } from '../affectations';
import { ChargePfbInfoDto } from '../../charges-pfb/charges-pfb';
import { Group } from '../../data-management';
import { AffectationChargeService } from '../affectation-charge/affectation-charge.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-suivi-charge',
  templateUrl: './suivi-charge.component.html',
  styleUrls: ['./suivi-charge.component.scss']
})
export class SuiviChargeComponent implements OnInit {

  mapCaRealise: any;

  endDate: Date | null = new Date();
  startDate: Date = new Date();
  firstDay = new Date();
  lastDay: Date = new Date();
  date: DateInterval = new DateInterval();
  maxDate: Date = new Date();
  dayDif: number = 0;


  selectedaffectation: AffectationInfo;
  selectedChargePfb: ChargePfbInfoDto;
  affectations: AffectationInfo[] = [];
  filtredAffectations: AffectationInfo[] = [];

  caRealise: any[] = [];
  fueling: any[] = [];
  maintenances: any[] = [];
//  locations: any[] = [];
  /** for Corrélation charts */
  crCaRealise: any[] = [];

  groups: any[];
  selectedGroup: Group = new Group();

  /** realise loader */
  loadingTracking = false;

  constructor(private affectationService: AffectationChargeService, private toastr: ToastrService) {
    this.lastDay = new Date(this.lastDay.getFullYear(), this.lastDay.getMonth() + 1, 0);
    this.firstDay = new Date(this.lastDay.getFullYear(), this.lastDay.getMonth(), 1);
  }

  ngOnInit() {
    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);

    // Assurez-vous que endDate est une date valide
    if (!this.endDate) {
      this.endDate = new Date();
    }
    this.endDate.setDate(new Date().getDate());
    this.endDate.setHours(23, 59, 59, 59);

    this.loadAffectations();
  }


  chooseAffectation(affectation: any) {
    this.selectedaffectation = affectation;
  }

  loadAffectations() {
    this.affectationService.getAffectationsWithotParams()
      .subscribe(affectation => {
        this.affectations = affectation;
        this.selectedaffectation = this.affectations[0];
      }, err => {
        this.toastr.error("Erreur lors de chargements", "Affectations")
      })
  }


  /**
  * Action change date début
  */
  onChangeStartDate() {
    if (this.endDate && this.endDate < this.startDate) {
      this.endDate = null;
    }
  }


  onTracking() {
    this.selectedChargePfb = new ChargePfbInfoDto();

    if (!this.startDate || !this.endDate) {
      this.toastr.error("Les dates doivent être définies.", "Erreur");
      return;
    }


    if (this.selectedaffectation.idChargeChantier === undefined) {
      this.toastr.error("L'identifiant de charge chantier est requis.", "Erreur");
      return;
    }

    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;

    // Calculer la différence en jours
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);
    if (this.dayDif >= this.selectedaffectation.nbrJours) {
      this.dayDif = this.selectedaffectation.nbrJours;
    }

    // Vérifier si endDate dépasse maxDate
    if (this.maxDate && this.date.endDate > this.maxDate) {
      this.date.endDate = this.maxDate;
      this.date.endDate.setHours(23, 59, 59, 59);
    }

    // Affectation des charges
    this.selectedChargePfb.nbDays = this.selectedaffectation.nbrJours;
    this.selectedChargePfb.fuelCharge = this.selectedaffectation.budgetCarburant;
    this.selectedChargePfb.transportCharge = this.selectedaffectation.budgetLocation;
    this.selectedChargePfb.caTransport = this.selectedaffectation.chiffreAffaire;

    this.loadingTracking = true;
    this.mapCaRealise = new Map();
    this.caRealise = [];
    this.crCaRealise = [];

    // Utilisez forkJoin pour exécuter les appels de service
    forkJoin([
      this.affectationService.getLocationRealise(this.selectedaffectation.idChargeChantier, this.date),
      this.affectationService.getMaintenanceCharge(this.selectedaffectation.group.idGroupe, this.date),
      this.affectationService.getFuelCharge(this.selectedaffectation.group.idGroupe, this.date)
    ]).subscribe({
      next: ([caRealise, maintenances, fueling]: [any[], any[], any[]]) => {
        this.fueling = fueling;
        this.maintenances = maintenances;
        this.mapCaRealise = caRealise;
        this.caRealise = this.mapCaRealise.simpleList;

        this.crCaRealise = (this.dayDif > 1) ? this.mapCaRealise.ListByDay : this.caRealise;
        this.loadingTracking = false;
      },
      error: err => {
        this.fueling = [];
        this.maintenances = [];
        this.loadingTracking = false;
        this.toastr.error("Erreur lors de chargement", "C.A Réalisé");
      }
    });
  }


}
