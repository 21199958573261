import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationSecPipe } from './duration-sec.pipe'; // Adjust path as needed

@NgModule({
  declarations: [DurationSecPipe], // Declare the pipe
  imports: [CommonModule],        // CommonModule is useful for shared modules
  exports: [DurationSecPipe]      // Export the pipe for use in other modules
})
export class DurationPipesModule {}
