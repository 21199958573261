<form #typeForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Nom *</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" [(ngModel)]="multiType.name" name="name" #typeName="ngModel" class="form-control input-sm" placeholder="Nom"
                    required maxlength="50">
            </div>
            <label class="col-sm-2 control-label">Type *</label>
            <div class="col-lg-4 col-md-4">
                <select class="form-control input-sm" [(ngModel)]="multiType.type" name="type" #type="ngModel" required>
                    <option *ngFor="let typeValue of typeValues" [selected]="typeValue.value == multiType.type" [value]="typeValue.value">{{typeValue.label}}</option>
                </select>
            </div>
        </div>

        <div class="box-footer">
            <button type="submit" *ngIf="mode == 'ADD'" [disabled]="!typeForm.form.valid" class="btn btn-info pull-right" (click)="onAdd(typeForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
            <button type="submit" *ngIf="mode == 'UPDATE'" [disabled]="!typeForm.form.valid" class="btn btn-info pull-right" (click)="onUpdate(typeForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
            <button type="reset" class="btn btn-default" (click)="multiTypeModal.hide()">Annuler</button>
        </div>
    </div>
</form>