import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderByPipe } from './order-by.pipe'; // Make sure the path is correct
import { TruncatePipe } from './truncate.pipe';
import { TruncateModule } from './truncate.pipe.module';

@NgModule({
  declarations: [
    OrderByPipe,
  ],
  imports: [
    CommonModule,
    TruncateModule
  ],
  exports: [
    OrderByPipe,
  ]
})
export class OrderPipModule { }
