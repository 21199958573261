<form class="form-inline">
  <div class="form-group mx-sm-3 mb-2">
    <label>Nombre de jour</label>
    <input type="number" class="form-control" placeholder="alerter avant" name="batterie" [(ngModel)]="batterieAlertConfiguartion.value1">
  </div>
  <br>
  <app-email-manager [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails" (onChange)="emailSettings($event)"></app-email-manager>

  <br>

  <div class="form-group">
      <label>Activer l'alert</label>
      <div class="input-group">
          <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive" ></mat-slide-toggle>
      </div>
  </div>
 
  <button type="submit" class="btn btn-flat btn-primary pull-right" style="margin-top: 45px;" (click)="save()">
        Sauvegarder
  </button>
  
</form>