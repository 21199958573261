<div class="row" style="height:85%;">
    <div class="col-lg-12">
        <div class="box-header page-header with-border">

            <!-- =================================== TITLE (START)==================================== -->
            <!-- <h3 class="box-title"
                style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;"><i
                    class="fa fa-list"></i>

                <span style="text-decoration: underline">

                    <span>Liste des sinistres du véhicule :</span>
                    <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher la véhicule"
                        [routerLink]="['/client/dataManagement/vehicule/form', selectedVehicule.idVehicule, 'SHOW']">{{selectedVehicule.matricule}}</a>

                    <span *ngIf="selectedVehicule.driver"> - conducteur :
                        <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher le conducteur"
                            [routerLink]="['/client/dataManagement/driver/form', selectedVehicule.driver.idDriver, 'SHOW']">{{selectedVehicule?.driver?.firstName}}
                            {{selectedVehicule?.driver?.lastName}}</a>
                    </span>

                </span>


                <span *ngIf="!selectedVehicule.driver" data-toggle="tooltip" class="badge label-warning">
                    <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                </span>

            </h3> -->
            <!-- =================================== TITLE (END)==================================== -->

            <!-- =================================== OPTIONS (START)==================================== -->

            <button type="button" class="btn btn-success btn-sm " title="Ajouter" (click)="onAdd()"><i class="fa fa-plus-circle"></i> </button>

            <div *ngIf="sinistresId.length > 0" class="option-delete">
                <span title="Supprimer tout" (click)="onConfirmDeleteMultiple()">
                    <i class="fa fa-minus-circle"></i> Supprimer tout</span>
            </div>
            <!-- =================================== OPTIONS (END)==================================== -->
            <!--==================================== import/Export functionality(START)=============== -->
            <div style="display: inline-block; margin-left: 5px;">


                <div class="option-export">
                <button type="button" class="btn btn-primary btn-sm   " title="Importer"
                    (click)='export()'>Exporter <i class="fa fa-download"></i> </button>

                <button type="button" class="btn btn-primary btn-sm  " title="Exporter"
                    (click)='importModal.show()'>Importer <i
                            class="fa fa-upload"></i> </button>
                </div>
            </div>
            <!--==================================== import/Export functionality(END)================= -->
            <!--==================================== Search(START)=============== -->
            <div class="pull-right">
                <div class="inputWithIcon">
                    <input [(ngModel)]="searchTerm" name="searchTerm" type="text" class="form-control input-sm"
                        placeholder="Description de dégats...">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <!--==================================== Search(END)================= -->

        </div>

    </div>
    <div class="col-lg-12">
        <!-- ========================================== LIST SINISTRES (START) ====================================================-->
        <div class="content">
            <div class="table-responsive tableFixHead">
                <table class="table table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 2%; text-align: left;"><input type="checkbox"
                                    [disabled]="sinistres.length == 0"
                                    [checked]="sinistres.length == sinistresId.length" (click)="onCheckedAll()"></th>
                            <th style="width:5%">Date</th>
                            <th style="width:5%">Lieu</th>
                            <th style="width:5%">Description Dégats</th>
                            <th style="width:5%">Réparé le</th>
                            <th style="width:5%">Coût Réparation</th>
                            <th style="width:5%">Remboursement</th>
                            <th style="width:5%">Franchise</th>
                            <th style="width:5%">Conducteur</th>
                            <th style="width:5%">Cabinet Expertise</th>
                            <th style="width:5%">Interne/Externe</th>
                            <th style="width:48%">Observations</th>
                            <th style="width:5%"></th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngIf="loading">
                            <td colspan="12" style="text-align: center">
                                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                            </td>
                        </tr>

                        <tr *ngIf="!loading && sinistres.length == 0">
                            <td colspan="12">
                                <div class="alert alert-dismissible"
                                    style="border-radius: 0px;text-align: center;">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                        ×
                                    </button>
                                    <h4 style="color: orange">
                                        <i class="fa fa-info-circle"></i> information
                                    </h4>
                                    Veuillez ajouter des informations pour sinistres.
                                </div>
                            </td>
                        </tr>

                        <tr *ngFor="let sinistre of getSinistres()">
                            <td>
                                <input type="checkbox" [checked]="isCheckedInList(sinistre.id)"
                                    (click)="onChecked(sinistre.id)">
                            </td>
                            <td style="font-weight: bold">
                                {{sinistre?.operationTime | date: 'dd-MM-yyyy, HH:mm'}}
                            </td>
                            <td>
                                {{sinistre?.place}}
                            </td>
                            <td>
                                {{sinistre?.descriptionDamage}}
                            </td>
                            <td style="width:6%;font-weight: bold">
                                {{sinistre?.actualRepairTime | date: 'dd-MM-yyyy, HH:mm'}}
                            </td>
                            <td>
                                {{sinistre?.cost | number: '.0-4'}}
                            </td>
                            <td>
                                {{sinistre?.refund | number: '.0-4'}}
                            </td>
                            <td>
                                {{sinistre?.cost - sinistre?.refund | number: '.0-4'}}
                            </td>
                            <td>
                                {{sinistre?.driver}}
                            </td>
                            <td>
                                {{sinistre?.cabinetExpertise?.name}}
                            </td>
                            <td>
                                {{sinistre?.type}}
                            </td>
                            <td>
                                {{sinistre?.observation}}
                            </td>
                            <td>

                                <button title="Supprimer" style="color: red;" (click)="onConfirmDelete(sinistre)"
                                    type="button" class="btn btn-sm btn-default">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                                <button title="Modifier" style="color: rgb(2, 157, 196); " type="button"
                                    class="btn btn-sm btn-default" (click)="updateSinistre(sinistre)">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
        <!-- ========================================== LIST SINISTRES (END) ====================================================-->
    </div>

</div>

<!-- ========================================== ADD/Edit Modal (START) ====================================================-->
<div class="modal fade" bsModal #addEditModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- ========================== Modal TITLE (START) ===============================-->
            <div class="modal-header">

                <button type="button" class="close" (click)="addEditModal.hide()">&times;</button>

                <h4 *ngIf="mode == 'ADD'" class="modal-title"
                    style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-plus"></i>
                    Ajouter informations sinistre</h4>

                <h4 *ngIf="mode == 'UPDATE'" class="modal-title"
                    style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-edit"></i>
                    Modifier informations sinistre</h4>
            </div>
            <!-- ========================== Modal TITLE (END) ===============================-->

            <div class="modal-body">
                <form #batteryForm="ngForm" class="form-horizontal">
                    <div class="box-body">
                        <app-sinistre-form [sinistre]="currentSinistre" [mode]="mode" [vehicule]="selectedVehicule"
                            [group]="selectedGroup" (sinistreWasAdded)="sinistreWasAdded($event)"
                            (sinistreWasUpdated)="sinistreWasUpdated($event)"></app-sinistre-form>
                    </div>
                </form>
            </div>

        </div>

    </div>
</div>
<!-- ========================================== ADD/Edit Modal (END) ====================================================-->
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" bsModal #sinistreDeleteModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="sinistreDeleteModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                    <span *ngIf="!isDeleteMultiple">Êtes-vous sûr de supprimer cette sinistre ?</span>
                    <span *ngIf="isDeleteMultiple">Êtes-vous sûr de supprimer cettes {{sinistresId.length}}
                        sinistres
                        ?</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="sinistreDeleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="onDelete()">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
<!--=================================Import battery(start) ====================================-->
<div class="modal" role="dialog" bsModal #importModal="bs-modal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="importModal.hide()">&times;
                </button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Importer la liste des sinistres
                </h4>
            </div>
            <div class="modal-body vertical-spacer">
                <app-import-export-forms [item]='selectedItem' [vehiculeMatricule]='selectedVehicule.matricule'
                    [groupId]="selectedGroup.idGroupe" (importedItem)='itemWasImported($event)'>
                </app-import-export-forms>
            </div>
            <p></p>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="importModal.hide()">
                    Fermer
                </button>
            </div>
        </div>
    </div>
</div>
<!--=================================Import battery (end) ====================================-->
