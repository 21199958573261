import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sinistre, MultiType } from '../../../model';
import { Vehicule, Group, Driver } from '../../../../data-management/data-management.model';
import { MultiTypeService } from '../../../providers/multi-type-service';
import { LossesService } from '../../../providers/losses.service';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DriverService } from 'src/app/client-management/data-management';

@Component({
    selector: 'app-sinistre-form',
    templateUrl: './sinistre-form.component.html',
    styleUrls: ['./sinistre-form.component.css']
})
export class SinistreFormComponent implements OnInit {

  operationTime: Date | null = null;
  actualRepairTime: Date | null = null;
  _sinistre: Sinistre = new Sinistre();

    cabinetExpertises: MultiType[] = [];

    showCabinetExpertisesDropDown = false;

    @Input()
    vehicule: Vehicule = new Vehicule();

    @Input()
    group: Group = new Group();

    @Input()
    mode = 'ADD';

    @Output()
    sinistreWasAdded: EventEmitter<Sinistre> = new EventEmitter<Sinistre>();

    @Output()
    sinistreWasUpdated: EventEmitter<Sinistre> = new EventEmitter<Sinistre>();

    loading = false;

    maxDate: Date = new Date();

    drivers: Driver[] = [];

    showDriverDropDown = false;

    constructor(private multiTypeService: MultiTypeService,private driverService: DriverService, private lossesService: LossesService, public toastr: ToastrService, private localeService: BsLocaleService) {
    }

    ngOnInit() {
        this.localeService.use('fr');
        this.loadDrivers();
        this.loadCabinetExpertise();
    }

    @Input()
    set sinistre(sinistre: Sinistre) {
        this._sinistre = sinistre;
        if (this.mode === 'ADD' && this.cabinetExpertises.length > 0) {
            this._sinistre.cabinetExpertise = this.cabinetExpertises[0];
        }
        if (this.mode === 'UPDATE' && this.cabinetExpertises.length > 0) {
            for (let i = 0; i < this.cabinetExpertises.length; i++) {
                if (this._sinistre.cabinetExpertise.id === this.cabinetExpertises[i].id) {
                    this._sinistre.cabinetExpertise = this.cabinetExpertises[i];
                    break;
                }
            }
        }
        // init operation and actualRepear time !
        if (this._sinistre) {
            if (this._sinistre.operationTime) {
                this.operationTime = new Date(this._sinistre.operationTime);
            }
            if (this._sinistre.actualRepairTime) {
                this.actualRepairTime = new Date(this._sinistre.actualRepairTime);
            }
        }
    }

    loadCabinetExpertise() {
        this.multiTypeService.getListType('CABINET_EXPERTISE').subscribe(cabinetExpertises => {
            this.cabinetExpertises = cabinetExpertises;
            if (this.cabinetExpertises.length > 0) {
                this.showCabinetExpertisesDropDown = true;
                let other = new MultiType();
                other.id = -1;
                other.name = 'Autre';
                this.cabinetExpertises.push(other);
            }
        });
    }

  /**
     * Charger liste des chauffeurs
     */
   loadDrivers() {
    this.driverService.getDriversLabel().subscribe(drivers => {
        this.drivers = drivers;
        if (this.drivers.length > 0) {
            this.showDriverDropDown = true;
            let other = new Driver();
            other.idDriver = -1;
            other.lastName = 'Autre';
            this.drivers.push(other);
        }
    }, error => {
        let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
        if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
        this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
    });
}

    addSinistre() {
        this.loading = true;
        this._sinistre.operationTime = this.operationTime.getTime();
        this._sinistre.actualRepairTime = this.actualRepairTime.getTime();
        this.lossesService.addSinistre(this._sinistre, this.vehicule.idVehicule, this.group.idGroupe).subscribe(sinistre => {
            this.sinistreWasAdded.emit(sinistre);
            this.loading = false;
        }, (error) => {
            this.loading = false;
        });
    }

    updateSinistre() {
        this.loading = true;
        this._sinistre.operationTime = this.operationTime.getTime();
        this._sinistre.actualRepairTime = this.actualRepairTime.getTime();
        this.lossesService.updateSinistre(this._sinistre).subscribe(sinistre => {
            this._sinistre = sinistre;
            this.sinistreWasUpdated.emit(sinistre);
            this.loading = false;
        }, (error) => {
            this.loading = false;
        });
    }

    onChangeCabinetExpertise(selectedCabinetExpertise: MultiType) {
        this._sinistre.cabinetExpertise = selectedCabinetExpertise;
        if (selectedCabinetExpertise.id === -1) {
            this.showCabinetExpertisesDropDown = false;
            this._sinistre.cabinetExpertise.name = '';
        }
    }

    toggleDropDown() {
        this.showCabinetExpertisesDropDown = true;
        this.cabinetExpertises.map(cabinetExpertise => {
            if (cabinetExpertise.id === -1) {
                cabinetExpertise.name = 'Autre';
            }
            return cabinetExpertise;
        });
        this._sinistre.cabinetExpertise = this.cabinetExpertises[0];
    }

    onChangeDriver(selectedDriver: string) {
        this._sinistre.driver = selectedDriver;
        if (selectedDriver == 'Autre') {
            this.showDriverDropDown = false;
            this._sinistre.driver = '';
        }
    }

    toggleDriverDropDown() {
        this.showDriverDropDown = true;
    }

    /**
     * Action change date  opération
     */
    onChangeOperationTime() {
        if (this.actualRepairTime < this.operationTime) {
            this.actualRepairTime = null;
        }
    }

    isNewDriver(type: string) {
        if (!type) return false;
        if (this.drivers.find(x => x.lastName == type)) {
          return false;
        } else {
          return true;
        }

    }
}
