import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import FormsModule for ngModel
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for toast notifications
import { BatterySettingsComponent } from './battery-settings.component'; // Adjust the path as necessary
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    BatterySettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EmailManagerModule,
    CronSettingsModule,
    MatSlideToggleModule
  ],
  exports: [
    BatterySettingsComponent // Export the component if needed
  ]
})
export class BatterySettingsModule { }
