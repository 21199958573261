export class ParentData {
  dateEnd: number;
  datestart: number;
  IsByParents: boolean;
  groups: string;
  activatechange: boolean;

  constructor() {
    this.dateEnd = 0;
    this.datestart = 0;
    this.IsByParents = false;
    this.groups = '';
    this.activatechange = false;
  }
}
