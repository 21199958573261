import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { DataManagementService } from 'src/app/client-management/data-management'; // Import DataManagementService
import { RealtimeService } from '../../../services/realtime.service'; // Import RealtimeService
import { FormatNumberModule } from 'src/app/pipes/format-number.module';

@NgModule({
  declarations: [
    SearchBarComponent // Declare the SearchBarComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule,
    FormatNumberModule
  ],
  providers: [
    DataManagementService, // Provide DataManagementService
    RealtimeService // Provide RealtimeService
  ],
  exports: [
    SearchBarComponent // Export SearchBarComponent for use in other modules
  ]
})
export class SearchBarModule { }
