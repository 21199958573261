import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivityOverviewComponent } from './activity-overview.component';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { DecimalPipe, DatePipe } from '@angular/common';
import { StopChartModule } from './dashbord-chart/stop-chart/stop-chart.module';
import { ActivityTimeChartModule } from './dashbord-chart/activity-time-chart/activity-time-chart.module';
import { KilomtrageChartModule } from './dashbord-chart/kilomtrage-chart/kilomtrage-chart.module';
import { ActivityChartModule } from './dashbord-chart/activity-chart/activity-chart.module';
import { CurrentStateModule } from '../../overview/current-state/current-state.module';
import { CurrentStateComponent } from '../../overview/current-state/current-state.component';
import { StopChartComponent } from './dashbord-chart/stop-chart/stop-chart.component';
import { ActivityTimeChartComponent } from './dashbord-chart/activity-time-chart/activity-time-chart.component';
import { KilomtrageChartComponent } from './dashbord-chart/kilomtrage-chart/kilomtrage-chart.component';
import { ActivityChartComponent } from './dashbord-chart/activity-chart/activity-chart.component';

@NgModule({
  declarations: [
    ActivityOverviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CurrentStateModule,
    StopChartModule,
    ActivityTimeChartModule,
    KilomtrageChartModule,
    ActivityChartModule,


  ],
  providers: [
    GeocodingService,
    MapService,
    DecimalPipe,
    DatePipe,
  ],
  exports: [
    ActivityOverviewComponent
  ]
})
export class ActivityOverviewModule { }
