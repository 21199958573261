import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuelSettingsComponent } from './fuel-settings.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { TechnicalDataService } from '../technical-data/technical-data.service'; // Adjust the path as necessary
import { DataManagementService } from '../../data-management/data-management.service'; // Adjust the path as necessary
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    FuelSettingsComponent // Declare the FuelSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule, // Include FormsModule for template-driven forms
    ToastrModule.forRoot(),
    SharedPipesModule,
    TruncateModule,

  ],
  providers: [
    TechnicalDataService, // Provide the TechnicalDataService
    DataManagementService // Provide the DataManagementService
  ],
  exports: [
    FuelSettingsComponent // Export the component if it needs to be used in other modules
  ]
})
export class FuelSettingsModule { }
