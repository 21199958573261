import { Component, OnDestroy, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { DataManagementService } from "../../data-management/data-management.service";
import { Group, Vehicule } from "../../data-management/data-management.model";
import { MapService } from "../../../utils/leaflet/service/map.service";
import { MileageService } from "./mileage.service";
import { Mileage } from "./mileage";
import { imagesDir } from "../../../global.config";
import * as L from 'leaflet';
import { Subscription } from "rxjs";
import { saveAs as importedSaveAs } from "file-saver";
import { PathService } from "../path";
import {Icon, Marker} from "leaflet";

@Component({
  selector: "app-mileage",
  templateUrl: "mileage.component.html",
  styleUrls: ["mileage.component.css"]
})
export class MileageComponent implements OnInit, OnDestroy {
  loaded$: Subscription;
  startDate: Date = new Date();
  endDate: Date = new Date();

  AllGroups: Subscription;
  AllPaths: Subscription;
  totalMileage: Mileage;
  mileageClicked: Mileage;

  groups: Group[];
  selectedGroup: Group = new Group();

  mileages: Mileage[] = [];

  selectedVehicule: Vehicule = new Vehicule();
  isResultLoaded: boolean = true;

  isTableExpanded: boolean = false;

  public MileageArray: number[] = [];


  constructor(
    public dataManagementService: DataManagementService,
    private mapService: MapService,
    private mileageService: MileageService,
    private pathService: PathService
  ) {

    this.startDate.setTime(this.startDate.getTime() - 3 * 86400000);
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);

    this.totalMileage = new Mileage();
    this.loaded$ = this.mapService.mapLoaded.subscribe((value: boolean) => {
      this.mapService.map.removeLayer(this.mapService.baseMaps.OpenStreetMap);
      this.mapService.map.addLayer(this.mapService.baseMaps['Google Sat']);
    });

  }

  ngOnInit() {
    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
            }
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.AllGroups) this.AllGroups.unsubscribe();
    if (this.AllPaths) this.AllPaths.unsubscribe();
    if(this.loaded$){this.loaded$.unsubscribe()}
  }

  distance(lat1: any, lon1: any, lat2: any, lon2: any) {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    var distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }

  addMileageToArray(mileage: Mileage, event: any) {
    if (!event.ctrlKey) {
      this.MileageArray = [];
      this.mapService.removePolylinesFromMap();
      this.mapService.removeMarkersFromMap();
    }

    this.MileageArray.push(mileage.deviceId);
    this.drawMileage(mileage);
  }

  MileageSelected(mileage: any) {
    return this.MileageArray.indexOf(mileage) !== -1;
  }

  getAllMileages() {
    this.isResultLoaded = false;
    this.AllPaths = this.mileageService
      .getAllMileagesDaily(this.selectedVehicule.idDevice, {
        startDate: this.startDate,
        endDate: this.endDate
      })
      .subscribe(
        mileages => {
          this.mileages = mileages;
          this.isResultLoaded = true;
        },
        err => {
          this.isResultLoaded = true;
        }
      );
    document.getElementById("datatable-container")!.style.height = "auto";
    document.getElementById("datatable-container")!.style.overflow = "visible";

    this.isTableExpanded = true;
  }

  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
    }
  }

  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
  }

  drawMileage(mileage: Mileage) {
    if (
      this.mileageClicked == null ||
      this.mileageClicked.startHour != mileage.startHour
    ) {
      this.mileageClicked = new Mileage();
      this.mileageClicked.startHour = mileage.startHour;
      this.mileageClicked.endHour = mileage.endHour;
    }

    let startHour = new Date(mileage.startDate).getTime();

    this.mileageService
      .getMileageDetails(this.selectedVehicule.idDevice, {
        startDate: startHour,
        endDate: mileage.endHour
      })
      .subscribe(mileage => {
        let points = mileage.points;
        let stops = mileage.stops;
        let polyline = L.polyline(points, { color: "#0031D9", weight: 3 });
        points.forEach((coordinate: any, i: any) => {
          let marker = new Marker(coordinate);

          if (points.length > 1) {
            if (i == 0) {
              marker.setIcon(
                new Icon({
                  iconUrl: imagesDir + "startMarker.png",
                  iconAnchor: [-2, 30],
                  popupAnchor: [10, -25]
                })
              );
            } else if (i == points.length - 1) {
              marker.setIcon(
                new Icon({
                  iconUrl: imagesDir + "endMarker.png",
                  iconAnchor: [-2, 30],
                  popupAnchor: [10, -25]
                })
              );
            } else {
              marker.setIcon(
                new Icon({
                  iconUrl: imagesDir + "green-point.png",
                  iconAnchor: [2, 2]
                })
              );
            }

            var pointDatePipe = new DatePipe(coordinate.date);
            let popup =
              "<b>Heure:</b> " +
              pointDatePipe.transform(coordinate.date, "dd/MM/yyyy HH:mm:ss", '', 'fr-fr') +
              "<b><br>vitesse:</b> " +
              coordinate.speed +
              " Km/h";
            marker.bindPopup(popup);
            marker.on("click", () => {
              this.mapService.map.setView(coordinate, 17);
            });
            this.mapService.addMarker(marker);
          }
        });
        stops.forEach((stop: any, i: any) => {
          let marker = new Marker({
            lat: stop.stopLatitude,
            lng: stop.stopLongitude
          });
          var pointDatePipe = new DatePipe(stop.beginStopTime);
          let popup =
            "<b>Date début d'arrêt: </b>" +
            pointDatePipe.transform(stop.beginStopTime, "dd/MM/yyyy HH:mm:ss", '', 'fr-fr') +
            "<br><b>Durée d'arrêt: </b>" +
            stop.stopDurationStr;
          marker.setIcon(
            new Icon({
              iconUrl: imagesDir + "stop_smal.png",
              iconAnchor: [-2, 30],
              popupAnchor: [10, -25]
            })
          );
          marker.bindPopup(popup);
          marker.on("click", () => {
            this.mapService.map.setView(
              { lat: stop.stopLatitude, lng: stop.stopLongitude },
              17
            );
          });
          this.mapService.addMarker(marker);
        });
        this.mapService.addPolyline(polyline);
        var middle = points[Math.round((points.length - 1) / 2)];

        //map center offset(axis y)
        this.mapService.offsetMap(middle, 12, 0, -120);
      });
  }

  getSumTotalDrivingDistance(): number {
    var s = 0;
    for (let m of this.mileages) {
      s += m.totalDrivingDistance;
    }
    return s;
  }

  tableToggle() {
    if (this.isTableExpanded) {
      document.getElementById("datatable-container").style.height = "55px";
      document.getElementById("datatable-container").style.overflow = "hidden";
      this.isTableExpanded = false;
    } else {
      document.getElementById("datatable-container").style.height = "auto";
      document.getElementById("datatable-container").style.overflow = "visible";
      this.isTableExpanded = true;
    }
  }

  expandTable() {
    if (!this.isTableExpanded) {
      document.getElementById("datatable-container").style.height = "auto";
      document.getElementById("datatable-container").style.overflow = "visible";
      this.isTableExpanded = true;
    }
  }

  //==========================
  // export of mileage
  //===========================
  exportKilometrage() {
    this.mileageService
      .exportMileage(
        this.selectedVehicule.device.idDevice,
        {
          startDate: this.startDate,
          endDate: this.endDate
        }
      ).subscribe(blob => {
        if (blob.size != 0)
          importedSaveAs(blob, "ETAT_KILOMETRAGE_PAR_DATE.xlsx");
      });
  }

  exportKilometragePDF() {
    var vehicule = this.pathService.getMatriculeByDeviceId(
      this.selectedGroup,
      this.selectedVehicule.device.idDevice
    );
    this.mileageService.exportMileagePDF(this.selectedVehicule.device.idDevice, vehicule, this.selectedGroup.nom, this.startDate.getTime(), this.endDate.getTime(),
      this.totalMileage.drivingDistance, this.totalMileage.drivingDurationStr).subscribe(blob => {
        if (blob.size != 0)
          importedSaveAs(blob, "ETAT_KILOMETRAGE_PAR_DATE.pdf");
      });
  }
}
