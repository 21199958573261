import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerformanceComponent } from './performance.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

// Import the components that are used within PerformanceComponent
import { RotationTypeComponent } from './graphes/rotation-type/rotation-type.component';
import { ChartDisponibleFlotteComponent } from './graphes/chart-disponible-flotte/chart-disponible-flotte.component';
import { ChartTitreGraphiqueComponent } from './graphes/chart-titre-graphique/chart-titre-graphique.component';
import { ChargesTransportComponent } from './graphes/charges-transport/charges-transport.component';
import { ConsommationMoyenneComponent } from './graphes/consommation-moyenne/consommation-moyenne.component';
import { ChartArretPoiEpsComponent } from './graphes/chart-arret-poi-eps/chart-arret-poi-eps.component';
import { ChartExcesVitesseComponent } from './graphes/chart-exces-vitesse/chart-exces-vitesse.component';

// Import any necessary services
import { PassageService } from '../schedule';
import { FuelingPriceSettingService } from '../../parc-management';
import { PathService } from '../../historical';
import { TransportTypeService } from '../parametrage/transport-type';
import { CanalPfbService } from '../parametrage/_service/canalpfb.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PopoverModule } from 'ngx-bootstrap/popover'; // Import PopoverModule
import { QualityDrivingComponent } from '../../tmm/quality-driving/quality-driving.component';
import { RotationTypeModule } from './graphes/rotation-type/rotation-type.module';
import { ChartDisponibleFlotteModule } from './graphes/chart-disponible-flotte/chart-disponible-flotte.module';
import { ChartTitreGraphiqueModule } from './graphes/chart-titre-graphique/chart-titre-graphique.module';
import { ChargesTransportModule } from './graphes/charges-transport/charges-transport.module';
import { ConsommationMoyenneModule } from './graphes/consommation-moyenne/consommation-moyenne.module';
import { ChartArretPoiEpsModule } from './graphes/chart-arret-poi-eps/chart-arret-poi-eps.module';
import { ChartExcesVitesseModule } from './graphes/chart-exces-vitesse/chart-exces-vitesse.module';
import { QualityDrivingModule } from '../../tmm/quality-driving/quality-driving.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { NgxLoadingModule } from 'ngx-loading';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DurationPipeModule } from 'src/app/pipes/duration.module';
import { VehiculeAccordingPoiModule } from '../../overview/vehicule-according-poi/vehicule-according-poi.module';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';

@NgModule({
  declarations: [
    PerformanceComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule, // Import RouterModule for routing
    NgxDaterangepickerMd,
    PopoverModule,
    RotationTypeModule,
    ChartDisponibleFlotteModule,
    ChartTitreGraphiqueModule,
    ChargesTransportModule,
    ConsommationMoyenneModule,
    ChartArretPoiEpsModule,
    ChartExcesVitesseModule,
    QualityDrivingModule,
    SharedPipesModule,
    PopoverModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
    DurationPipeModule,
    VehiculeAccordingPoiModule,
    LeafletModule

  ],
  providers: [
    PassageService,
    FuelingPriceSettingService,
    PathService,
    TransportTypeService,
    CanalPfbService
  ],
  exports: [
    PerformanceComponent
  ]
})
export class PerformanceModule { }
