import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TechnicalDataService } from '../technical-data/technical-data.service';
import { TechnicalData, DeviceOpt, DeviceCarb } from '../technical-data/technical-data';
import { Subscription, from } from 'rxjs';
import { DataManagementService } from './../../data-management/data-management.service';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { Group, Vehicule } from '../../data-management';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { time_difference } from 'src/app/global.config';

const Highcharts = require('highcharts/highcharts.src');
//import "highcharts/adapters/standalone-framework.src";
const HichartsExporting = require('highcharts/modules/exporting')(Highcharts);

interface SelectItem {
  itemName: string;
  option: string;
}

interface DropdownItem {
  id: number;
  option: string;
  itemName: string;
}

@Component({
  selector: 'app-driving-quality',
  templateUrl: './driving-quality.component.html',
  styleUrls: ['./driving-quality.component.css']
})
export class DrivingQualityComponent implements OnInit {

  //Chart vars
  chart: any;
  options: Object;
  serieName: any;

  //Device Options
  deviceopt: DeviceOpt;

  //Device Carburant
  devicecarb: DeviceCarb;

  chooseGroup: any;  // Replace 'any' with the appropriate type
  chooseVehicule: any; // Replace 'any' with the appropriate type

  groups: Group[];
  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();
  AllGroups: Subscription;
  vehicule: Vehicule;

  technicaldata: TechnicalData[];

  startDate: Date = new Date();
  endDate: Date = new Date();

  //MultiSelelct vars
  selectItems: SelectItem[] = [];
  dropdownSettings = {};
  dropdownList: DropdownItem[] = [];
  isResultLoaded: boolean = true;


  items: any[] = [];
  selectedItem: any;



  constructor(
    private technicaldataservice: TechnicalDataService,
    public dataManagementService: DataManagementService,
    public toastr: ToastrService, private signinService: SigninService,
    private localeService: BsLocaleService
  ) {
    this.chooseGroup = null;
    this.chooseVehicule = null;
    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });
    this.endDate.setHours(23, 59, 59, 0);
    this.startDate.setTime(this.startDate.getTime() - 3 * 86400000);
    this.startDate.setHours(0, 0, 0, 0);

  }

  ngOnInit() {
    this.localeService.use("fr");
    //init multiselect
    this.createDropList();
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select option',
      selectAllText: 'Select Tous',
      unSelectAllText: 'UnSelect Tous',
      enableSearchFilter: false,
      classes: 'custom-class-multiselect',
      badgeShowLimit: 1
    };

    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectItems = [];
      this.selectedVehicule = this.dataManagementService.selectedVehicule!;

    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            this.selectItems = [];
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
            }
          }
        });
    }

  }

  createDropList() {
    this.dropdownList.push({ id: 0, option: 'speed', itemName: 'Vitesse' });
    this.dropdownList.push({ id: 1, option: 'x', itemName: 'Accélération' });
    this.dropdownList.push({ id: 4, option: 'x', itemName: 'Freinage' });
    this.dropdownList.push({ id: 2, option: 'y', itemName: 'Dérive' });
    this.dropdownList.push({ id: 3, option: 'z', itemName: 'Secousse' });
  }
  PositionYaxis(yaxis: Highcharts.YAxisOptions[], name: string): number {
    return yaxis.findIndex(axis => axis.title?.text === name);
  }
  ChartSeries(data: { x: number, y: number }[]): boolean {
    return data.length > 0;
  }

  displayChart() {
    let devopt: string[] = [];
    if (this.selectItems.length > 0) {
      this.selectItems.forEach((itm: SelectItem) => {
        devopt.push(itm.option);
      });
    }

    let overSpeed: { x: number, y: number }[] = [];
    let drift: { x: number, y: number }[] = [];
    let shake: { x: number, y: number }[] = [];
    let acceleration: { x: number, y: number }[] = [];
    let braking: { x: number, y: number }[] = [];
    let maxSpeed: { x: number, y: number }[] = [];

    this.technicaldataservice.getDeviceCarb(this.selectedVehicule.idDevice).subscribe((res) => {
      this.devicecarb = res;
    });

    this.technicaldataservice
      .getFuelDetail(
        this.selectedVehicule.idDevice,
        {
          startDate: this.startDate,
          endDate: this.endDate
        },
        devopt
      )
      .subscribe((res) => {
        this.technicaldata = res;
        if (this.technicaldata.length === 0) {
          this.toastr.warning('Pas de données à afficher');
        }
        this.technicaldata.forEach((stat) => {
          stat.date = stat.date + time_difference;
          if (stat.speed !== undefined) {
            overSpeed.push({
              x: stat.date,
              y: stat.speed
            });
            maxSpeed.push({
              x: stat.date,
              y: this.selectedVehicule.maxSpeed
            });
          }
          if (stat.x !== undefined) {
            if (stat.x > 0) acceleration.push({ x: stat.date, y: Math.abs(stat.x) / 128 * 2 });
            if (stat.x < 0) braking.push({ x: stat.date, y: Math.abs(stat.x) / 128 * 2 });
          }
          if (stat.y !== undefined) {
            drift.push({ x: stat.date, y: Math.abs(stat.y) / 128 * 2 });
          }
          if (stat.z !== undefined) {
            shake.push({ x: stat.date, y: Math.abs(stat.z) / 128 * 2 });
          }
        });

        let serie: Highcharts.SeriesOptionsType[] = [];
        let yaxis: Highcharts.YAxisOptions[] = [];

        this.selectItems.forEach((itm) => {
          if (itm.itemName === 'Accélération') {
            if (this.ChartSeries(acceleration)) {
              yaxis.push({
                title: {
                  text: 'Accélération',
                  style: {
                    color: 'rgb(255, 127, 0)'
                  }
                },
                labels: {
                  format: '{value} G',
                  style: {
                    color: 'rgb(255, 127, 0)'
                  }
                },
                min: 0,
                max: 2
              });
              serie.push({
                type: 'spline',
                name: 'Accélération',
                data: acceleration,
                color: 'rgb(255, 127, 0)',
                yAxis: this.PositionYaxis(yaxis, 'Accélération'),
                tooltip: {}
              });
            }
          }
          if (itm.itemName === 'Freinage') {
            if (this.ChartSeries(braking)) {
              yaxis.push({
                title: {
                  text: 'Freinage',
                  style: {
                    color: 'rgb(153, 51, 0)'
                  }
                },
                labels: {
                  format: '{value} G',
                  style: {
                    color: 'rgb(153, 51, 0)'
                  }
                },
                min: 0,
                max: 2
              });
              serie.push({
                type: 'spline',
                name: 'Freinage',
                data: braking,
                color: 'rgb(153, 51, 0)',
                yAxis: this.PositionYaxis(yaxis, 'Freinage'),
                tooltip: {
                  valueSuffix: '',
                  valueDecimals: 2
                }
              });
            }
          }
        });

        if (this.ChartSeries(overSpeed)) {
          yaxis.push({
            title: {
              text: 'Vitesse',
              style: {
                color: 'rgb(44, 161, 33)'
              }
            },
            labels: {
              format: '{value} KM/H',
              style: {
                color: 'rgb(44, 161, 33)'
              }
            },
            min: 0,
            max: 100,
            opposite: yaxis.length !== 0
          });
          serie.push({
            type: 'spline',
            name: 'Vitesse',
            data: overSpeed,
            color: 'rgb(44, 161, 33)',
            tooltip: {
              valueSuffix: ' KM/H'
            }
          });

          serie.push({
            type: 'spline',
            name: 'Vitesse Max',
            data: maxSpeed,
            color: 'rgb(230, 20, 9)',
            tooltip: {
              headerFormat: '',
              valueSuffix: 'KM/H'
            }
          });
        }
        if (this.ChartSeries(drift)) {
          yaxis.push({
            title: {
              text: 'Dérive',
              style: {
                color: 'rgb(23, 119, 182)'
              }
            },
            labels: {
              format: '{value} ',
              style: {
                color: 'rgb(23, 119, 182)'
              }
            },
            min: 0,
            max: 2
          });
          serie.push({
            type: 'spline',
            name: 'Dérive',
            data: drift,
            color: 'rgb(23, 119, 182)',
            yAxis: this.PositionYaxis(yaxis, 'Dérive'),
            tooltip: {
              valueSuffix: '',
              valueDecimals: 2
            }
          });
        }
        if (this.ChartSeries(shake)) {
          yaxis.push({
            title: {
              text: 'Secousse',
              style: {
                color: 'rgb(107, 57, 156)'
              }
            },
            labels: {
              format: '{value} ',
              style: {
                color: 'rgb(107, 57, 156)'
              }
            },
            min: 0,
            max: 2
          });
          serie.push({
            type: 'spline',
            name: 'Secousse',
            data: shake,
            color: 'rgb(107, 57, 156)',
            yAxis: this.PositionYaxis(yaxis, 'Secousse'),
            tooltip: {
              valueSuffix: '',
              valueDecimals: 2
            }
          });
        }

        this.options = new Highcharts.Chart({
          time: {
            timezoneOffset: time_difference
          },
          title: { text: 'Véhicule: ' + this.selectedVehicule.matricule },
          chart: {
            type: 'spline',
            renderTo: 'container',
            height: 420,
            zoomType: 'x'
          },
          xAxis: [
            {
              type: 'datetime',
              tickInterval: 1800 * 1000,
              labels: {
                2: function () {
                  return Highcharts.dateFormat('%d/%m/%Y %H:%M', (this as any).value);
                }
              },
              crosshair: true
            }
          ],
          yAxis: yaxis,
          plotOptions: {
            series: {
              turboThreshold: 10000
            }
          },
          series: serie
        });
      });
  }

  onItemSelect(item :any){
  }
}

