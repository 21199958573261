import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';  // Import FormsModule if needed
import { MonthlyAverageCostOfFuelConsumptionChartComponent } from './monthly-average-cost-of-fuel-consumption-chart.component';
import { AverageCostOfFuelConsumptionChartService } from '../average-cost-of-fuel-consumption-chart.service';  // Ensure the correct path is used
import { TheoreticalCostService } from 'src/app/client-management/parc-management'; // Ensure the correct path is used
import { HighchartsChartModule } from 'highcharts-angular';  // Import Highcharts if used in the component
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
  declarations: [
    MonthlyAverageCostOfFuelConsumptionChartComponent  // Declare the component
  ],
  imports: [
    CommonModule,  // Provides common Angular directives
    FormsModule,   // Required for forms (if any)
    HighchartsChartModule , // Import Highcharts module for chart integration
    NgxLoadingModule.forRoot({}),

  ],
  providers: [
    AverageCostOfFuelConsumptionChartService,  // Provide the chart service
    TheoreticalCostService  // Provide the theoretical cost service
  ],
  exports: [
    MonthlyAverageCostOfFuelConsumptionChartComponent  // Export the component to make it available in other modules
  ]
})
export class MonthlyAverageCostOfFuelConsumptionChartModule { }
