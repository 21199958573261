import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // if you're using forms
import { AffectationManagementComponent } from './affectation-management.component';
import { RouterModule } from '@angular/router';
import { AffectationFormModule } from './affectation-form/affectation-form.module';
import { AffectationChargeModule } from './affectation-charge/affectation-charge.module';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    AffectationManagementComponent // declare the component
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxLoadingModule.forRoot({}),
    AffectationFormModule,
  ],
  exports: [
    AffectationManagementComponent // export the component if it's used in other modules
  ]
})
export class AffectationManagementModule { }
