import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TurnoverAchievedComponent } from './turnover-achieved.component';
import { ToastrModule } from 'ngx-toastr';    // For Toastr notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';  // Datepicker from ngx-bootstrap

@NgModule({
  declarations: [
    TurnoverAchievedComponent  // Declare the TurnoverAchievedComponent
  ],
  imports: [
    CommonModule,      // Import CommonModule for common Angular directives
    FormsModule,       // FormsModule for template-driven forms
    ToastrModule.forRoot(),   // Toastr for notifications
    BsDatepickerModule.forRoot()  // ngx-bootstrap Datepicker for date-related operations
  ],
  exports: [
    TurnoverAchievedComponent  // Export the component so it can be used in other modules
  ]
})
export class TurnoverAchievedModule { }
