import { Component, OnInit, Input } from '@angular/core';
import { ChargePfbInfoDto, ChargeRealise } from '../../charges-pfb';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FuelingDashboardDto } from 'src/app/client-management/parc-management';
const Highcharts2 = require('highcharts');

require('highcharts/highcharts-more')(Highcharts2);
require('highcharts/modules/solid-gauge')(Highcharts2);
require('highcharts/modules/exporting')(Highcharts2);
require('highcharts/modules/no-data-to-display')(Highcharts2);

@Component({
  selector: 'app-fueling-charge',
  templateUrl: './fueling-charge.component.html',
  styleUrls: ['./fueling-charge.component.css']
})
export class FuelingChargeComponent implements OnInit {
  caRealise_: any[] = [];
  fuelingCharge: Object | null = null;
  fuelingChargeTimeLineChart: Object | null = null;
  globalLoading: boolean;

  @Input() isManually: boolean;

  selectedCharge: ChargePfbInfoDto = new ChargePfbInfoDto();
  dayDif: number = 0;

  objectif: number = 0;
  aRealiser: number = 0;
  realise: number = 0;
  // isManually: boolean = false;

  @Input()
  set selectedCharges(chargePfb: ChargePfbInfoDto) {
    if (chargePfb) {
      this.selectedCharge = chargePfb;
    }
  }

  @Input()
  set dayDifference(dayDifference: number) {
    this.dayDif = dayDifference;
  }

  @Input()

  @Input()
  set caRealise(caRealise: ChargeRealise[]) {
    if (this.isManually) {
      this.caRealise_ = Object.assign(caRealise);
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.fuelingChart();
    }
  }

  @Input()
  set fueling(fueling: FuelingDashboardDto[]) {
    if (!this.isManually) {
      this.caRealise_ = fueling;
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.fuelingChart();
    }
  }

  constructor(public toastr: ToastrService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.fuelingChart();
  }

  timeChart() {


    this.objectif = 0;
    this.aRealiser = 0;
    this.realise = 0;

    var data: { x: Date, y: number }[] = [];
    var index = 0;


    if (this.selectedCharge) {
      this.objectif = this.selectedCharge.fuelCharge / this.selectedCharge.nbDays;
    }

    this.caRealise_.forEach(ca => {
      let cost;
      let date;
      if (this.isManually) {
        cost = ca.carburant;
        date = ca.operationTime;
      } else {
        cost = ca.cost;
        date = ca.operationDate;
      }
      data.push({ x: date, y: cost });
      if (index == this.caRealise_.length - 1) {
        this.realise = cost;
        this.aRealiser = this.objectif - this.realise;
      }
      index++;
    })


    this.fuelingChargeTimeLineChart = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      plotOptions: {
        enableMouseTracking: false
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: ' DH'
      },
      title: {
        text: 'Charge Gasoil',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 86400000,
        labels: {
          formatter: () => {
            // Here we need to get the value using `this` context from the arrow function
            const context = (this as any); // Use 'any' or define a more specific type
            return Highcharts2.dateFormat("%d/%m/%Y", context.value);
          }
        }
      },
      yAxis: {
        minRange: this.objectif,
        min: 0,
        plotLines: [{
          value: this.objectif,
          color: '#ff0000',
          dashStyle: 'shortdash',
          width: 2,
          zIndex: 4,
          label: {
            text: 'Budget',
            textAlign: 'center'
          }
        }],
      },
      series: [{
        findNearestPointBy: 'xy',
        name: 'Consommée',
        data: data,
        zones: [{
          value: this.objectif,
          color: 'green'
        }, {
          color: 'red'
        }]
      }]

    }, Highcharts2.chart('fuelingChart', this.fuelingChargeTimeLineChart);

  }


  fuelingChart() {
    // Create the chart
    this.objectif = 0;
    this.aRealiser = 0;
    this.realise = 0;

    var color = '#008000';
    var caCarburant;
    if (this.isManually) {
      caCarburant = this.caRealise_.filter(ca => ca.carburant > 0);
    } else {
      caCarburant = this.caRealise_;
    }

    if (caCarburant.length > 0) {

      this.objectif = (this.selectedCharge.fuelCharge / this.selectedCharge.nbDays) * this.dayDif;
      if (this.isManually)
        this.realise = caCarburant[caCarburant.length - 1].carburant;
      else
        this.realise = caCarburant[caCarburant.length - 1].cost;

      this.aRealiser = this.objectif - this.realise;
    }

    if (this.realise > this.objectif)
      color = '#c90000';

    this.fuelingCharge = {

      chart: {
        type: 'solidgauge',
        events: {
          load: function (this :any) {
            let yData = this.series[0].data[0].y;
            let xData = this.series[0].data[0].x;

            var chart = this;
            if (!chart.lbl) {
              chart.lbl = chart.renderer.label('', (this.chartWidth - 80) / 2, (this.plotHeight / 2) + 15)
                .attr({
                  padding: 10,
                  r: 10,
                })
                .css({
                  color: '#000',
                  fontSize: 13
                })
                .add();
            }
            chart.lbl
              .show()
              .attr({
                text: '<span style="font-size:15px;font-weight:bold;">' + this.series[0].name + '</span><br/><span style="font-size:13px;color:' + this.series[0].data[0].color + ';font-weight:bold;">' + Highcharts2.numberFormat(xData, 2) + ' DH</span><br/>'
                  + '<span style="font-size:17px; color: ' + this.series[0].data[0].color + '; font-weight: bold">' + Highcharts2.numberFormat(yData, 1) + '%</span>',
              });
          }
        }
      },
      title: {
        text: 'Charge Gasoil ',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
        enabled: false,
        positioner: function (this:any, labelWidth: number): { x: number; y: number } {
          // Now TypeScript recognizes 'this.chart'
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: (this.chart.plotHeight / 2) + 15
          };
        }
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ //objectif
          outerRadius: '112%',
          innerRadius: '88%',
          backgroundColor: "#98c5f5",
          borderWidth: 0
        }, { // Réalisé
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: '#98c5f5',
          borderWidth: 0
        }]
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        },
        series: {
          point: {
            events: {
              mouseOver: function ( this: any) {
                var chart = this.series.chart;
                if (!chart.lbl) {
                  chart.lbl = chart.renderer.label('', (this.chartWidth - 80) / 2, (this.plotHeight / 2) + 15)
                    .attr({
                      padding: 10,
                      r: 10,
                    })
                    .css({
                      color: '#000',
                      fontSize: 13
                    })
                    .add();
                }
                chart.lbl
                  .show()
                  .attr({
                    text: '<div style="width:100%;text-align:center;"><span style="font-size:15px;font-weight:bold;">' + this.name + '</span><br/><span style="font-size:13px;color:' + this.color + ';font-weight:bold;">' + Highcharts2.numberFormat(this.x, 2) + ' DH</span><br/>'
                      + '<span style="font-size:17px;color:' + this.color + ';font-weight:bold;">' + Highcharts2.numberFormat(this.y, 1) + '%</span>',
                  });
              }
            }
          },
        }
      },
      series: [{
        name: 'Budget',
        data: [{
          name: 'Budget',
          color: '#007bff',
          radius: '112%',
          innerRadius: '88%',
          y: (this.objectif / this.objectif) * 100,
          x: this.objectif
        }]
      }, {
        name: 'Réalisé',
        data: [{
          name: 'Réalisé',
          color: color,
          radius: '87%',
          innerRadius: '63%',
          y: (this.realise / this.objectif) * 100,
          x: this.realise
        }]
      }],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        noData: 'Aucune donnée n\'est disponible'
      }
    },
      Highcharts2.chart('fuelingChart', this.fuelingCharge);
  }

}
