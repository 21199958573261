export class Stop {
  stopDurationStr: string;
  deviceId: number;

  beginStopTime: number;
  stopDuration: Date;
  stopLatitude: number;
  stopLongitude: number;

  endStopTime: Date;
  inPath: boolean;

  geocoding: string;
  geocodingDetails: string;

  constructor() {}
}
