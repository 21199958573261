import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataManagementService, Group } from 'src/app/client-management/data-management';
import { RealtimeComponent } from '../../../realtime.component';
import { RealtimeService } from '../../../services/realtime.service';
import { StateCounts } from '../../../model/real-time.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'group-element-component',
  templateUrl: './group-element.component.html',
  styleUrls: ['./group-element.component.css'], 
})
export class GroupElementComponent implements OnInit, OnDestroy {
  @Input('item') item: Group;
  statesCount : StateCounts;
  statesCountSubscription: Subscription;
  searechedElement: Subscription;
  lengh: number;

  constructor(public dataManagementService: DataManagementService, public realTime: RealtimeComponent, private realTimeService: RealtimeService) { }

  ngOnInit(): void {
    this.statesCountSubscription= this.realTimeService.getStatesCount().subscribe(statesCounts => {
      this.statesCount= statesCounts[this.item.idGroupe];
    })
    this.searechedElement = this.realTimeService.searchedOptionObs().subscribe(result =>{
      switch(result) { 
        case "activeCars": { 
          this.lengh = this.statesCount.activeCars.length;
           break; 
        } 
        case "desactiveCars": { 
          this.lengh = this.statesCount.desactiveCars.length;
          break; 
        } 
        case "nonValidCars": { 
          this.lengh = this.statesCount.nonValidCars.length;
          break; 
       } 
       case "technicalIssueCars": { 
        this.lengh = this.statesCount.technicalIssueCars.length;
        break; 
       } 
        default: { 
        this.lengh = this.item.vehicules.length;
          break; 
        } 
     } 
    })
    
  }

  ngOnDestroy(): void {
    this.statesCountSubscription.unsubscribe();
    this.searechedElement.unsubscribe()
  }
  

} 
