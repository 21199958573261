import { Injectable, Inject } from '@angular/core';
import { Credentials, User, Authority, Action, AuthorityName, UserMission } from './credentials';
import {
  contentHeaders,
  createAuthorizationHeader
} from '../../utils/security/headers';
import { dns } from '../../global.config';
import { Subject, Observable } from 'rxjs';
import { DateInterval } from '../../shared/model';
import { Group } from '../../client-management/data-management/data-management.model';
import { HttpClient } from '@angular/common/http';
import { HTTP_NOAUTH } from 'src/app/app.module';

@Injectable()
export class SigninService {
  currentUser: User | null = null;
  authorities: Authority[];
  accounts: User[] ;
  userMissions: UserMission[] | null = null;
  accountWasDeleted = new Subject<User>();
  accountWasCreated = new Subject<User>();
  accountWasUpdated = new Subject<User>();
  accountsWasUpdated = new Subject<User>();
  accountWasChanged = new Subject<User>();

  /** userMission */


  userMissionWasDeleted = new Subject<UserMission>();
  userMissionWasCreated = new Subject<UserMission>();
  userMissionWasUpdated = new Subject<UserMission>();
  userMissionsWasUpdated = new Subject<UserMission>();
  userMissionWasChanged = new Subject<UserMission>();

  /**listner for groups  */
  groupeWasDeleted = new Subject<Group>();
  groupeWasCreatedOrUpdate = new Subject<Group>();

  constructor(@Inject(HTTP_NOAUTH) private _http: HttpClient) {}

  sendAccountWasChanged(account: User) {
    this.accountWasChanged.next(account);
  }

  sendAccountWasCreated(account: User) {
    this.accountWasCreated.next(account);
  }

  sendAccountWasDeleted(account: User) {
    this.accountWasDeleted.next(account);
  }

  sendAccountWasUpdated(account: User) {
    this.accountWasUpdated.next(account);
  }

  /** user mission */
  sendUserMissionWasChanged(account: UserMission) {
    this.userMissionWasChanged.next(account);
  }

  sendUserMissionWasCreated(account: UserMission) {
    this.userMissionWasCreated.next(account);
  }

  sendUserMissionWasDeleted(account: UserMission) {
    this.userMissionWasDeleted.next(account);
  }

  sendUserMissionWasUpdated(account: UserMission) {
    this.userMissionWasUpdated.next(account);
  }

  /**listner when groupe is created, updated or deleted */
  sendGroupeWasCreatedOrUpdate() {
    this.groupeWasCreatedOrUpdate.next();
  }

  getGroupeWasCreatedOrUpdate(): Observable<Group> {
    return this.groupeWasCreatedOrUpdate.asObservable();
  }

  sendGroupeWasDeleted(groupe: Group) {
    this.groupeWasDeleted.next(groupe);
  }

  getGroupeWasDeleted(): Observable<Group> {
    return this.groupeWasDeleted.asObservable();
  }

  /** end listner when groupe is created, updated or deleted */
  getAccountWasCreated(): Observable<User> {
    return this.accountWasCreated.asObservable();
  }

  getAccountWasDeleted(): Observable<User> {
    return this.accountWasDeleted.asObservable();
  }

  getAccountWasUpdated(): Observable<User> {
    return this.accountWasUpdated.asObservable();
  }

  getAccountWasChanged(): Observable<User> {
    return this.accountWasChanged.asObservable();
  }

    /** (user) end listner when mission is created, updated or deleted */
    getUserMissionWasCreated(): Observable<UserMission> {
      return this.userMissionWasCreated.asObservable();
    }

    getUserMissionWasDeleted(): Observable<UserMission> {
      return this.userMissionWasDeleted.asObservable();
    }

    getUserMissionWasUpdated(): Observable<UserMission> {
      return this.userMissionWasUpdated.asObservable();
    }

    getUserMissionWasChanged(): Observable<UserMission> {
      return this.userMissionWasChanged.asObservable();
    }

  login(credentials: Credentials): Observable<any> {
    return this._http
      .post(dns + 'signin', credentials, { headers: contentHeaders });
  }

  logout() {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + 'signout', {}, { headers: headers });
  }

  getAuthorities(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(dns + 'accounts/authorities', { headers: headers });
  }

  getAccounts(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(dns + 'accounts', { headers: headers });
  }

  deleteAccount(idSubUser: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete(dns + 'accounts/' + idSubUser, { headers: headers });
  }

  addAccount(user: User): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + 'accounts', user, { headers: headers });
  }

  updateAccount(user: User): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .put(dns + 'accounts', user, { headers: headers });
  }

  sendMailAccount(user: User) : Observable<Response>{
    let headers = createAuthorizationHeader();
    return this._http.put<Response>(dns + 'accounts/mail', user, { headers: headers });
  }

  disableOrEnaleAccount(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .put(dns + 'accounts/enabled/' + id, null, { headers: headers });
  }

  /** user mission */

  getUserMissions(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(dns + 'userMissions', { headers: headers });
  }

  deleteUserMission(idSubUser: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete(dns + 'userMissions/' + idSubUser, { headers: headers });
  }

  addUserMission(user: UserMission): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + 'userMissions', user, { headers: headers });
  }

  updateUserMission(user: UserMission): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .put(dns + 'userMissions', user, { headers: headers });
  }

  sendMailUserMission(user: UserMission) : Observable<Response>{
    let headers = createAuthorizationHeader();
    return this._http.put<Response>(dns + 'userMissions/mail', user, { headers: headers });
  }

  disableOrEnaleUserMission(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .put(dns + 'userMissions/enabled/' + id, null, { headers: headers });
  }

  /** end user mission */

  addAction(idUser: number, action: Action): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + 'accounts/actions/add/' + idUser, action, {
        headers: headers
      });
  }

  getActions(idUser: number, dateInterval: DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + 'accounts/actions/' + idUser, dateInterval, {
        headers: headers
      });
  }

  public isRootAdmin(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.isRoot ? true : false;
    }
    return false;
  }

  public isHasCarburantOption(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      if (currentUser && Array.isArray(currentUser.options) && currentUser.isRoot) {
        for (let option of currentUser.options) {
          if (option.idOption === 1) {
            return true;
          }
        }
      }
    }
    return false;
  }
  public isHasActivity(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 2) !== undefined : false;
    }
    return false;
  }

  public isHasChargePfb(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 3) !== undefined : false;
    }
    return false;
  }

  public isHasCimentManagement(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 18) !== undefined : false;
    }
    return false;
  }

  public isHasOverallViewManagement(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 30) !== undefined : false;
    }
    return false;
  }

  public isHasGlobalOverviewManagement(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 24) !== undefined : false;
    }
    return false;
  }

  public isHasTransportMonitoringMaintenance(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 29) !== undefined : false;
    }
    return false;
  }

  public isHasCanalPerformanceManagement(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 26) !== undefined : false;
    }
    return false;
  }

  public isHasAffectationManagement(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 27) !== undefined : false;
    }
    return false;
  }

  public isHasMissionManagement(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 20) !== undefined : false;
    }
    return false;
  }

  public isHasGeoMarketing(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 4) !== undefined : false;
    }
    return false;
  }

  public isHasRentalAgreementOption(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 23) !== undefined : false;
    }
    return false;
  }

  public isHasEngineControl(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 5) !== undefined : false;
    }
    return false;
  }

  public isHasRole(authority: AuthorityName): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      if (currentUser.authorities && !currentUser.isRoot) {
        return currentUser.authorities.some(auth => auth.name === authority);
      }
    }
    return false;
  }

  public isHasNotificationCarburant(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 6) !== undefined : false;
    }
    return false;
  }

  public isHasCaRealization(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 14) !== undefined : false;
    }
    return false;
  }

  public isHasTemplateCarburantNomatis(): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === 7) !== undefined : false;
    }
    return false;
  }

  public isHasOption(id: number): boolean {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.options && currentUser.isRoot ?
        currentUser.options.find(option => option.idOption === id) !== undefined : false;
    }
    return false;
  }

  public getUserId(): number | undefined {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      let currentUser: User = JSON.parse(currentUserString);
      return currentUser.id;
    }
    return undefined;
  }

  public getCurrentUser(): User | undefined {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      return JSON.parse(currentUserString);
    }
    return undefined;
  }
}
