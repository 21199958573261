import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMileageDataComponent } from './mileage-data.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import the datepicker module
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { HighchartsChartModule } from 'highcharts-angular'; // If using Highcharts with Angular
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    IMileageDataComponent // Declare the component
  ],
  imports: [
    CommonModule, // Provides common directives like ngIf and ngFor
    FormsModule, // To use forms
    BsDatepickerModule.forRoot(), // Initialize the datepicker module
    ToastrModule.forRoot(), // Initialize the Toastr notifications
    HighchartsChartModule,
    SharedPipesModule,
    TruncateModule
  ],
  exports: [
    IMileageDataComponent // Export the component if you want to use it in other modules
  ]
})
export class IMileageDataModule { }
