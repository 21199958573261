import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { Schedule } from 'src/app/client-management/charges-pfb';
import { DateInterval } from 'src/app/shared/model';
import { CimentMissionService } from '../ciment-mission.service';

@Component({
  selector: 'app-ciment-mission-schedule',
  templateUrl: './ciment-mission-schedule.component.html',
  styleUrls: ['./ciment-mission-schedule.component.css']
})
export class CimentMissionScheduleComponent implements OnInit {

  scheduleList: Schedule[] = [];

  matricule: Set<String> = new Set();
  dateInterval: DateInterval = new DateInterval();

  loading = false;

  startDate: Date = new Date();

  now: Date = new Date();

  isToday = true;

  /** state of the missions */
  deliveryLength = 0;
  delayLength = 0;
  inprogressLength = 0;
  not_startedLength = 0;

  showDetails1: boolean = false;
  showDetails2: boolean = false;
  showDetails3: boolean = false;
  showDetails4: boolean = false;

  /** UPDATE TRACKING TIMER */
  updateTrackingTimer: number;
  updateTrackingTimerSubscription: Subscription | null = null;

  constructor(public missionService: CimentMissionService, public toastr: ToastrService) { }

  ngOnInit() {
    this.mission();
  }


  /** STOP TRACKING */
  stopTracking() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = null;
  }

  onChangeStartDate() {
    this.stopTracking();
  }

  mission() {
    this.loading = true;
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.startDate.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.isToday = (this.dateInterval.startDate.toDateString() == this.now.toDateString());

    this.deliveryLength = 0;
    this.delayLength = 0;
    this.inprogressLength = 0;
    this.not_startedLength = 0;
    this.showDetails1 = this.showDetails2 = this.showDetails3 = this.showDetails4 = false;
    this.scheduleList = [];

    this.missionService.dailyListOfMissionCiment(this.dateInterval).subscribe(data => {
      this.scheduleList = data;

      for (let schedule of this.scheduleList) {
        if (schedule.type == 'delivery') {
          this.deliveryLength++;
        } else if (schedule.type == 'delay') {
          this.delayLength++;
        } else if (schedule.type == 'inprogress') {
          this.inprogressLength++;
        } else if (schedule.type == 'not_started') {
          this.not_startedLength++;
        }
      }

      if (this.isToday) this.startUpdateTrackingTimer();

      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 1200;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        this.mission();
      }
    })
  }

}
