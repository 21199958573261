import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { dns } from '../../global.config';
import { createAuthorizationHeader } from '../security/headers';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  constructor(private _http: HttpClient) { }

  
  sendEmail(email: { subject: string, body: string }): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.post<Boolean>(dns + '/utils/emails', email, {headers: headers});
  }
 
}
