import { Component, OnInit, ViewChild } from '@angular/core';
import { Provider } from '../../model';
import { ProviderService } from '../../providers';
import { ToastrService } from '../../../../../../node_modules/ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-provider-settings',
    templateUrl: './provider-settings.component.html',
    styleUrls: ['./provider-settings.component.css']
})
export class ProviderSettingsComponent implements OnInit {

    providerTypes: Object[] = [{ name: "INSURANCE", label: "Assurance" },
    { name: "VISIT", label: "Visite" },
    { name: "OPERATIONAL_CERTIFICATION", label: "Certification opérationnelle" },
    { name: "ROAD_TAXES", label: "Vignette" },
    { name: "METOLOGICA_NOTBOOK", label: "Metological notebook" },
    { name: "EXTINCTEURS", label: "Extincteurs" },
    { name: "LEASING", label: "Location" },
    { name: "PARCKING", label: "Parcking" },
    { name: "LAVAGE", label: "Lavage" },
    { name: "FUEL", label: "Carburant" },
    { name: "MAINTENANCE", label: "Entretien" }];

    providerType: string = "INSURANCE";

  public searchTerm: string | null = null;


  providers: Provider[] = [];

    provider: Provider = new Provider();

    mode: 'ADD' | 'UPDATE';

    loading: boolean = false;

    /**
     * variables de pagination
     */
    currentPage = 1;
    size = 25;
    maxSize = 9;
    boundaryLinks = true;
    totalItems: number;

    @ViewChild('providerModal', { static: false })
    providerModal: ModalDirective;

    @ViewChild('providerDeleteModal', { static: false })
    providerDeleteModal: ModalDirective;

    constructor(private providerService: ProviderService, public toastr: ToastrService) {
    }

    ngOnInit() {
        this.loadProviders();
    }

    /**
     * Charger liste des fournisseurs
     */
    loadProviders() {
        this.loading = true;
        this.providers = [];
        this.providerService.getProvidersByPagination(this.providerType, this.currentPage - 1, this.size).subscribe(data => {
            this.providers = data.content;
            this.totalItems = data.totalElements;
            this.loading = false;
        }, error => {
            this.loading = false;
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Evénement de changement de pagination
     */
    onPageChanged(event: any): void {
        this.currentPage = event.page;
        this.loadProviders();
    }

    /**
     * Afficher modal de modification du fournisseur
     */
    onUpdate(provider: Provider) {
        this.provider = Object.assign({}, provider);
        this.mode = 'UPDATE';
        this.providerModal.show();
    }

    /**
     * Afficher modal d'ajout du fournisseur
     */
    onAdd() {
        this.provider = new Provider();
        this.provider.type = this.providerType;
        this.mode = 'ADD';
        this.providerModal.show();
    }

    /**
     * Afficher modal de confirmation du suppression
     */
    onConfirmDelete(provider: Provider) {
        this.provider = provider;
        this.providerDeleteModal.show();
    }

    /**
     * Suppression du fournisseur
     */
    onDelete() {
        this.loading = true;
        this.providerService.delete(this.provider.id).subscribe(response => {
            this.providerDeleteModal.hide();
            if (response) {
                this.toastr.success('Fournisseur informations bien supprimer !', 'info');
                this.providers = this.providers.filter(provider => provider.id != this.provider.id);
            } else {
                this.toastr.error('Le fournisseur n\'a pas supprimé correctement !', 'Erreur');
            }
            this.loading = false;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * Actualiser la liste des fournisseurs apés l'événement d'ajout
     */
    providerWasAdded(provider: Provider) {
        this.providerModal.hide();
        if (this.providerType == provider.type) {
            this.providers.push(provider);
        } else {
            this.providers = this.providers.filter(provider => provider.id != this.provider.id);
        }
    }

    /**
     * Actualiser la liste des fournisseurs apés l'événement de modification
     */
    providerWasUpdated(provider: Provider) {
        this.providerModal.hide();
        if (this.providerType == provider.type) {
            for (let i = 0; i < this.providers.length; i++) {
                if (this.providers[i].id == provider.id) {
                    this.providers[i] = provider;
                    break;
                }
            }
        } else {
            this.providers = this.providers.filter(provider => provider.id != this.provider.id);
        }
    }
}
