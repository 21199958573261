import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { FuelingClassification, TravelReport } from '../../parc-management';
import { ActivityVehiculeClassification, VehiculeClassification } from './activity-vehicule-classifications.model';

@Injectable()
export class ActivityVehiculeClassificationsService {

  constructor(private http: HttpClient) { }

  save(payload: ActivityVehiculeClassification): Observable<ActivityVehiculeClassification> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<ActivityVehiculeClassification>(dns + "coefficient-settings", payload, options);
  }

  getCoefficicientSettingsByGroupId(groupId : number): Observable<ActivityVehiculeClassification> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<ActivityVehiculeClassification>(dns + "coefficient-settings/" + groupId + "/group", options);
  }

  getVehicleActivityClassificationByTransportTypeIdOrGroupId(id: number, date: DateInterval, type: string): Observable<TravelReport[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<TravelReport[]>(dns + 'activities/classification/' + id + '?timeZoneOfeset=' + (new Date().getTimezoneOffset()) + '&type=' + type, date, options);
  }

  getVehicleTurnOverClassificationsByGroupId(id: number, date: DateInterval): Observable<VehiculeClassification[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<VehiculeClassification[]>(dns + 'driverCharges/classification/' + id, date, options);
  }

  getVehicleFuelingClassificationByTransportTypeId(transportTypeId: number, date: DateInterval, type: string): Observable<FuelingClassification[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<FuelingClassification[]>(dns + 'fuelings/classification/' + transportTypeId + '?type=' + type, date, options);
  }

  getVehicleLossesClassificationsByGroupId(type: string, groupId: number, date: DateInterval): Observable<VehiculeClassification[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<VehiculeClassification[]>(dns + type + '/classification/' + groupId, date, options);
  }

  getSpeedCartoClassificationsByTransportTypeId(transportTypeId: number, date: DateInterval, type: string): Observable<VehiculeClassification[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<VehiculeClassification[]>(dns + 'alerts/notifications/classification/' + transportTypeId + '?type=' + type, date, options);
  }

  getVehicleRotationClassificationByTransportTypeId(transportTypeId: number, date: DateInterval, type: string): Observable<TravelReport[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<TravelReport[]>(dns + 'paths/classification/' + transportTypeId + '?type=' + type, date, options);
  }

  topFiveVehicules(values: any[], coefficient: number, ratings: any[], isMatricule: boolean): any[] {

    var topFiveActivities = values.slice(0, 5);
    let mark = 10;
    for (let topFive of topFiveActivities) {
      topFive.mark = mark * coefficient;
      let note = topFive.mark

      var rate = [];
      let matricule;
      let driver;

      if (isMatricule) {
        rate = ratings.filter(r => r.matricule == topFive.matricule);
        matricule = topFive.matricule;
        driver = topFive.driver;
      } else {
        rate = ratings.filter(r => r.matricule == topFive.vehiculeMatricule);
        matricule = topFive.vehiculeMatricule;
        driver = topFive.driverFullName;
      }

      if (rate.length > 0) {
        rate[0].note += note;
      } else {
        ratings.push({
          matricule: matricule,
          driver: driver,
          avatarUri: topFive.avatarUri,
          note: topFive.mark
        });
      }
      mark--;
    }
    return ratings;
  }

  offenseAndSinistreRating(values: any[], coefficient: number, ratings: any[]): any[] {

    let mark = -10;
    for (let vl of values) {
      if (vl.cost == 0)
        vl.mark = 10 * coefficient;
      else {
        vl.mark = mark * coefficient;
        mark--;
      }
      let note = vl.mark


      var rate = [];
      let matricule;
      let driver;

      rate = ratings.filter(r => r.matricule == vl.matricule);
      matricule = vl.matricule;
      driver = vl.driver;

      if (rate.length > 0) {
        rate[0].note += note;
      } else {
        ratings.push({
          matricule: matricule,
          driver: driver,
          avatarUri: vl.avatarUri,
          note: vl.mark
        });
      }

    }
    return ratings;
  }
}
