import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AlertManagementService } from '../../alert-management.service';
import { Alert, AlertConfiguration } from '../../alert';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Subscription } from 'rxjs';
import { AlertManagementServiceUtils } from '../../alert-management-utils.service';

@Component({
  selector: 'app-consumption-settings',
  templateUrl: './consumption-settings.component.html',
  styleUrls: ['./consumption-settings.component.css']
})
export class ConsumptionSettingsComponent implements OnInit {


  public checked = true;
  public showAdvancedSettings = false;
  public loader = false;
  hours: { value: number; label: string }[] = [];
  minutes: { value: number; label: string }[] = [];
  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  public alert: Alert = new Alert();

  public consumptionAlertConfiguration: AlertConfiguration = new AlertConfiguration();
  public cronAlertConfiguration: AlertConfiguration = new AlertConfiguration();

  public isResultLoaded = true;

  public getVehiculeWasSelectedSubscription: Subscription;

  constructor(
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService,
    private dataManagementService: DataManagementService,
    private alertManagementServiceUtils: AlertManagementServiceUtils
  ) {
    this.initHours();
    this.initMinutes();
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService.selectedVehicule.idDevice
        );
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('CONSUMPTION', device)
      .subscribe(
        alert => {
          this.alert = alert;
          if(this.alert.alertConfigurations.length > 0) {

            this.cronAlertConfiguration = this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'DATE'
            )
              ? this.alert.alertConfigurations.filter(
                alertConfiguration => alertConfiguration.type === 'DATE'
              )[0]
              : this.cronAlertConfiguration;
            this.consumptionAlertConfiguration = this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'CONSUMPTION'
            )
              ? this.alert.alertConfigurations.filter(
                alertConfiguration => alertConfiguration.type === 'CONSUMPTION'
              )[0]
              : this.consumptionAlertConfiguration;
            if (this.consumptionAlertConfiguration == null) {
              this.alertManagementServiceUtils.initializeAlertConfiguration(
                'CONSUMPTION'
              );
            }

        }
          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  initHours() {
    for (let i = 0; i <= 24; i++) {
      this.hours.push({ value: i, label: i > 9 ? i.toString() : '0' + i });
    }
  }

  initMinutes() {
    for (let i = 0; i < 4; i++) {
      this.minutes.push({
        value: i,
        label: i > 0 ? (i * 15).toString() : '0' + i
      });
    }
  }

  save() {

    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.toastr.success('Configuration enregistrée', 'info !');
        this.loader = false;
      },
      error => {
        this.toastr.success('Configuration non enregistrée', 'info !');
        this.loader = false;
      }
    );
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean, enableGlobalConfigToAllVehicules: boolean }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }

  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails, id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }


}





