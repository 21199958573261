import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuelDataComponent } from './fuel-data.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Toastr for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Datepicker for date selection
import { ImportExportService } from 'src/app/client-management/import-export';
import { TechnicalDataService } from '../technical-data/technical-data.service'; // Ensure the path is correct
import { DataManagementService } from '../../data-management/data-management.service'; // Ensure the path is correct
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { ReverseModule } from 'src/app/pipes/revers.module';
import { RoundPipeModule } from 'src/app/pipes/round.module';

@NgModule({
  declarations: [
    FuelDataComponent, // Declare the FuelDataComponent
  ],
  imports: [
    CommonModule, // CommonModule for Angular common directives
    FormsModule,
    ToastrModule.forRoot(), // Import Toastr module for notifications
    BsDatepickerModule.forRoot(),
    AngularMultiSelectModule,
    SharedPipesModule,
    TruncateModule,
    ReverseModule,
    RoundPipeModule
  ],
  providers: [
    TechnicalDataService, // Provide the TechnicalDataService
    DataManagementService, // Provide the DataManagementService
    ImportExportService, // Provide the ImportExportService
  ],
  exports: [
    FuelDataComponent, // Export the FuelDataComponent for use in other modules
  ]
})
export class FuelDataModule { }
