import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { Reparation } from "../model/index";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReparationService {

  constructor(private _http: HttpClient) { }

  public getListReparations(idVehicule: number, groupId: number): Observable<Reparation[]> {
     let headers = createAuthorizationHeader();
    return this._http.get<Reparation[]>(dns + "reparations/list/" + idVehicule + "?groupId=" + groupId, { headers: headers });
  }

  public delete(id: number): Observable<Boolean> {
     let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + "reparations/" + id, { headers: headers });
  }

  deleteMultiple(oilChangesId: number[]): Observable<Boolean> {
     let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + "reparations/multiple/" + oilChangesId, { headers: headers });
  }

  public add(reparation: Reparation, idVehicule: number, groupId: number): Observable<Reparation> {
     let headers = createAuthorizationHeader();
    return this._http.post<Reparation>(dns + "reparations?idVehicule=" + idVehicule + "&groupId=" + groupId, reparation, { headers: headers });
  }

  public update(reparation: Reparation): Observable<Reparation> {
     let headers = createAuthorizationHeader();
    return this._http.put<Reparation>(dns + "reparations", reparation, { headers: headers });
  }
}
