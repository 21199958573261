<div class="nav-tabs-custom" style="height:100%;">

  <!-- <ul class="nav nav-tabs pull-center" style="font-family: arial,sans-serif;max-width: 41%;margin: 10px auto;overflow: hidden;">

    <li [routerLink]="['tire']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img1" title="Pneu" height="60px" width="90px" src="{{imagesCarsDir}}pneu.png" />
        <img class="img2" title="Pneu" height="60px" width="90px" src="{{imagesCarsDir}}pneu2.png" />
        <span>Pneu</span>
      </a>
    </li>

    <li [routerLink]="['battery']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img1" title="Batterie" height="60px" width="90px" src="{{imagesCarsDir}}demarrage.png" />
        <img class="img2" title="Batterie" height="60px" width="90px" src="{{imagesCarsDir}}demarrage2.png" />
        <span>Batterie</span>
      </a>
    </li>

    <li [routerLink]="['reparation']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img1" title="Reparation" height="60px" width="90px" src="{{imagesCarsDir}}reparation.png" />
        <img class="img2" title="Reparation" height="60px" width="90px" src="{{imagesCarsDir}}reparation2.png" />
        <span>Reparation</span>
      </a>
    </li>

    <li [routerLink]="['brake']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img1" title="Reparation" height="60px" width="90px" src="{{imagesCarsDir}}brake.png" />
        <img class="img2" title="Reparation" height="60px" width="90px" src="{{imagesCarsDir}}brake2.png" />
        <span>Frein</span>
      </a>
    </li>
  </ul> -->
  <!-- new design -->
  <div class="maintenancemenu">
  
    <!-- Item 1 -->
  
        <input type="radio" name="maintenanceItem" id="maintenance-item-1" class="maintenance-toggle" [routerLink]="['tire']" 
        [checked]="getCurrentUrl() == 'tire'"/>
        <label title="Pneu" for="maintenance-item-1"><p class="icon"><img src="assets/icons/parcManagementIcons/tires.svg" class="svgImg" alt=""></p>
          <!-- <span>Pneu</span> -->
        </label>
   
    <!-- Item 2 -->
  
        <input type="radio" name="maintenanceItem" id="maintenance-item-2" [routerLink]="['battery']" 
        [checked]="getCurrentUrl() == 'battery'" class="maintenance-toggle"/>
        <label title="Batterie" for="maintenance-item-2"><p class="icon"><img src="assets/icons/parcManagementIcons/accumulator.svg" class="svgImg" alt=""></p>
          <!-- <span>Batterie</span> -->
        </label>
    
    <!-- Item 3 -->
 
        <input type="radio" name="maintenanceItem" id="maintenance-item-3" [routerLink]="['reparation']" 
        [checked]="getCurrentUrl() == 'reparation'"class="maintenance-toggle"/>
        <label title="Reparation" for="maintenance-item-3"><p class="icon"><img src="assets/icons/parcManagementIcons/lifter.svg" class="svgImg" alt=""></p>
          <!-- <span>Reparation</span> -->
        </label>

        <!-- Item 4 -->
 
        <input type="radio" name="maintenanceItem" id="maintenance-item-4" [routerLink]="['brake']" 
        [checked]="getCurrentUrl() == 'brake'" class="maintenance-toggle"/>
        <label title="Frein" for="maintenance-item-4"><p class="icon"><img src="assets/icons/parcManagementIcons/brake-disc.svg" class="svgImg" alt=""></p>
          <!-- <span>Frein</span> -->
        </label>

    
    
    <div class="clear"></div>
    
    <!-- Bar -->
    <div class="maintenance">
      <div class="bar"></div>
    </div>
    
  </div>
  <!-- end new design -->
  <router-outlet></router-outlet>

</div>