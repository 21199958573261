<section class="content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
    <div class="row" style="position: absolute; width: 100%;">
        <div class="datatable-container" id="datatable-container" style="margin-top: -10px; z-index: 1000;">
            <div class="col-md-12 datatable-container-col">
                <div class="box" style="border-radius: 0px;">
                    <div class="overlay" *ngIf="!isResultLoaded">
                        <i class="fa fa-refresh fa-spin"></i>
                    </div>
                    <div class="box-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <i class="col-sm-1 fa fa-list table-toggle" (click)="tableToggle()"></i>
                                <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                                <div class="col-sm-2 grp-slct">
                                    <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup"
                                        (ngModelChange)="chooseGroup($event)">
                                        <option *ngFor="let group of groups" [ngValue]="group" style="font-size: 14px;">
                                            <span *ngIf="group.nameGroupParent">{{group.nameGroupParent |
                                                capitalizefirst | truncate:[30]}} / </span> {{group.nom |
                                            capitalizefirst}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                                <div class="col-sm-2 vhcl-slct">
                                    <select class="form-control input-sm" name="vehicules"
                                        [(ngModel)]="selectedVehicule" (ngModelChange)="chooseVehicule($event)">
                                        <option *ngFor="let vehicule of selectedGroup.vehicules"
                                            style="font-size: 14px;" [ngValue]="vehicule">
                                            {{dataManagementService.getVehiculeName(vehicule)
                                            |
                                            capitalizefirst}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label dd-lbl">Date début</label>
                                <div class="col-sm-2 dd-npt">
                                    <div class="input-group">

                                        <input type="text" (click)="expandTable()" class="form-control input-sm"
                                            bsDatepicker name="startDate" [(ngModel)]="startDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                                            placement="bottom" [maxDate]="endDate" autocomplete="off">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                                    </div>
                                </div>
                                <label class="col-sm-1 control-label df-lbl">Date fin</label>
                                <div class="col-sm-2 df-npt">

                                    <div class="input-group">

                                        <input type="text" (click)="expandTable()" class="form-control input-sm"
                                            bsDatepicker name="endDate" [(ngModel)]="endDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm', containerClass: 'theme-dark-blue'}"
                                            placement="bottom" [minDate]="startDate" autocomplete="off">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                                    </div>



                                </div>
                                <div class="col-sm-1 form-btns">
                                    <button type="button" class="btn btn-primary btn-flat btn-sm" style="border-radius: 4px;"
                                        (click)="getAllPaths()">
                                        <b><i class="fa fa-search" aria-hidden="true"></i></b>
                                    </button>
                                    <button class="btn btn-primary btn-sm excel-button" (click)="exportPaths()"
                                        [disabled]="paths.length == 0">
                                        <i *ngIf="!loader" class="fa fa-file-excel-o"></i>
                                        <i *ngIf="loader" class="fa fa-refresh fa-spin"></i>
                                    </button>
                                    <button class="btn btn-primary btn-sm pdf-button" (click)="exportPathsPDF()"
                                        [disabled]="paths.length == 0" title="PDF">
                                        <i *ngIf="!pdfLoader" class="fa fa-file-pdf-o"></i>
                                        <i *ngIf="pdfLoader" class="fa fa-refresh fa-spin"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <table class="table table-striped" style="font-size: 12px;font-weight: bold" width="100%" *ngIf="isTableExpanded">
                            <thead>
                                <tr>
                                    <th width="9%" style="text-align: center">
                                        Conducteur
                                    </th>
                                    <th width="9%" style="text-align: center"><i class="fa fa-clock-o"
                                            aria-hidden="true"></i>
                                        Date Départ
                                    </th>
                                    <th width="24%" style="text-align: center">Lieu Départ</th>
                                    <th width="9%" style="text-align: center"><i class="fa fa-clock-o"
                                            aria-hidden="true"></i>
                                        Date Arrivée
                                    </th>
                                    <th width="24%" style="text-align: center">Lieu Arrivée</th>
                                    <th width="7%" style="text-align: center">Kilométrage</th>
                                    <th width="7%" style="text-align: center"> V Max</th>
                                    <th width="10%" style="text-align: center">Durée Trajet</th>
                                    <th width="10%" style="text-align: center">Durée Arrêt</th>
                                </tr>
                            </thead>
                            <tbody class="datatable-body">
                                <ng-container *ngFor="let path of paths">
                                    <tr (click)="addPathToArray(path, $event)" [class.path]="PathSelected(path)">
                                        <td width="8%" data-toggle="tooltip">
                                            {{path.lastName}}
                                        </td>
                                        <td width="8%" data-toggle="tooltip"
                                            title="{{path.displayBeginPathTime | date:'dd-MM-y HH:mm'}}">
                                            {{path.beginPathTime | date:'dd-MM HH:mm'}}
                                        </td>
                                        <td width="25%" data-toggle="tooltip"
                                            title="{{path.beginPathGeocodingDetails}}">
                                            {{path.beginPathGeocodingDetails | truncate :[45]}}
                                        </td>
                                        <td width="8%" data-toggle="tooltip"
                                            title="{{path.displayEndPathTime | date:'dd-MM-y HH:mm'}}">
                                            {{path.endPathTime | date:'dd-MM HH:mm'}}
                                        </td>
                                        <td width="25%" data-toggle="tooltip" title="{{path.endPathGeocodingDetails}}">
                                            {{path.endPathGeocodingDetails | truncate :[45]}}
                                        </td>
                                        <td width="7%" style="text-align: center" data-toggle="tooltip"
                                            title="{{path.distanceDriven | number:'1.2-2'}} km">
                                            {{path.distanceDriven | number:'1.2-2'}}
                                        </td>
                                        <td width="7%" style="text-align: center" data-toggle="tooltip"
                                            title="{{path.maxSpeed}} Km/h">
                                            {{path.maxSpeed}}
                                        </td>
                                        <td width="10%" data-toggle="tooltip" title="{{path.pathDurationStr}}">
                                            {{path.pathDurationStr}}
                                        </td>
                                        <td width="10%" data-toggle="tooltip" title="{{path.nextStopDurationStr}}">
                                            {{path.nextStopDurationStr | truncate :[15]}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="PathSelected(path)">

                                        <td colspan="5" style="text-align: center">
                                            <form class="form-horizontal">
                                                <label class="col-sm-1 control-label grp-lbl">Comparer avec le trajet
                                                    typique</label>
                                                <div class="col-sm-2 grp-slct">
                                                    <select class="form-control input-sm" name="groups"
                                                        [(ngModel)]="selectedTypicalPath">
                                                        <option *ngFor="let typicalPath of allTypicalPath"
                                                            [ngValue]="typicalPath" style="font-size: 14px;">
                                                            {{typicalPath?.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-1">
                                                    <button [disabled]="!selectedTypicalPath || !pointsPath"
                                                        type="button" class="btn btn-success btn-flat btn-sm"
                                                        (click)="findTypicalPath()">
                                                        <b><i class="fa fa-search" aria-hidden="true"></i></b>
                                                    </button>
                                                </div>

                                                <span *ngIf="selectedPathToCompare"
                                                    style="padding-top: 5px; float:left; margin-left: 10px;">
                                                    Km: {{selectedPathToCompare?.distanceDriven | number:'1.2-2'}}
                                                </span>
                                                <span *ngIf="isLoadingTypicalPath"
                                                    style="padding-top: 2px; display: block;">
                                                    <i class="fa fa-spinner fa-spin"></i> Comparaison en cours...
                                                </span>
                                                <span *ngIf="isTypicalPath != null"
                                                    [ngStyle]="{'background-color':isTypicalPath == true ? 'green' : 'red'}"
                                                    style="height: 4px;width: 90px;position: relative;display: inline-block;top: 8px;">&nbsp;</span>
                                                <span *ngIf="isTypicalPath == true" class="badge bg-green">
                                                    <i class="fa fa-check"></i> Trajet identique
                                                </span>
                                                <span *ngIf="isTypicalPath == false" class="badge bg-red">
                                                    <i class="fa fa-exclamation-triangle"></i> Trajet non identique
                                                </span>
                                            </form>
                                        </td>

                                        <td colspan="4" style="text-align: center">

                                            <button [disabled]="!pointsPath" type="button"
                                                class="btn btn-success btn-xs" style="font-size: 10px;"
                                                (click)="showChildModal()">
                                                <i class="fa fa-road" aria-hidden="true"></i> Enregistrer comme trajet
                                                typique
                                            </button>

                                            <button style="font-size: 10px;" type="button"
                                                title="Afficher adresse google" class="btn btn-success btn-xs"
                                                (click)="displayGoogleAdress(path)">
                                                <i class="fa fa-google" aria-hidden="true"></i>
                                            </button>

                                            <button type="button" class="btn btn-success btn-xs"
                                                style="font-size: 10px;" (click)="displayDetails()">
                                                Détails
                                            </button>

                                            <a href="https://www.google.com/maps/dir/'{{pathsArray[0]?.beginPathLatitude}},{{pathsArray[0]?.beginPathLongitude}}'/{{pathsArray[0]?.endPathLatitude}},{{pathsArray[0]?.endPathLongitude}}/"
                                                target="_black" style="font-size: 10px;" title="Afficher trajet google"
                                                class="btn btn-primary btn-xs">
                                                <i class="fa fa-road" aria-hidden="true"></i>
                                            </a>
                                        </td>
                                    </tr>

                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- start details -->
                <div id="details">
                    <ng-container *ngIf="selectedPath">
                        <a>
                            <div class="historik-result-row-details text-center">
                                <div class="row nopadding nomargin">
                                    <div class="col-lg-2">
                                        <i class="fa fa-barcode" aria-hidden="true"></i>
                                        {{selectedVehicule?.matricule}}
                                        <br>
                                        <i class="fa fa-user" aria-hidden="true"></i> {{selectedPath.lastName}}
                                    </div>
                                    <div class="col-lg-1">
                                        <i class="fa fa-car" aria-hidden="true"></i>
                                        {{selectedVehicule?.mark}}
                                        <br>
                                        <i class="fa fa-credit-card" aria-hidden="true"></i>
                                        {{selectedVehicule?.subMark}}
                                    </div>
                                    <div class="col-lg-1">
                                        <i class="fa fa-road" aria-hidden="true"></i>{{selectedPath.distanceDriven |
                                        number:'1.2-2'}} Km
                                    </div>
                                    <div class="col-lg-1">
                                        <i class="fa fa-road" aria-hidden="true"></i>{{selectedPath.maxSpeed}} km/h
                                    </div>
                                    <div class="col-lg-3">
                                        <i class="fa fa-flag" style="color:green" aria-hidden="true"></i>
                                        {{selectedPath.displayBeginPathTime | date:'dd-MM HH:mm'}}@
                                        {{selectedPath.beginPathGeocodingDetails | truncate :[45]}}
                                        <br>
                                        <i class="fa fa-flag" style="color:red" aria-hidden="true"></i>
                                        {{selectedPath.displayEndPathTime | date:'dd-MM HH:mm'}}@
                                        {{selectedPath.endPathGeocodingDetails | truncate :[45]}}
                                        <br>
                                    </div>
                                    <div class="col-lg-2">
                                        <i class="fa fa-hourglass" aria-hidden="true"></i>
                                        {{selectedPath.pathDurationStr}} min
                                        <br><i class="fa fa-hourglass-half"
                                            aria-hidden="true"></i>{{selectedPath.nextStopDurationStr | truncate :[15]}}
                                    </div>
                                    <div class="col-lg-2">
                                        <app-spider-chart></app-spider-chart>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-container>
                </div>
                <!-- end details -->
            </div>
        </div>
        <div class="map-container">
            <div id="save_path_button" class="save_path hide leaflet-bottom leaflet-right" (click)="showChildModal()">
                <div class=" leaflet-bar leaflet-control">
                    <a class="fa fa-plus leaflet-bar-part" title="Ajouter comme trajet typique"
                        style="outline: none;"></a>
                </div>
            </div>
            <app-leaflet></app-leaflet>
        </div>
    </div>
    <div #childModal class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header black-bg">
                    <h4 class="modal-title pull-left" style="font-family: 'Inconsolata', monospace;">
                        <i class="fa fa-plus-circle"></i> Enregistrer comme trajet typique
                    </h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Nom</label>
                        <input type="text" class="form-control" placeholder="Nom ..." [(ngModel)]="typicalPathName"
                            name="name">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary btn-block btn-flat" (click)="savePath()">+
                        Enregistrer le trajet typique
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
