import { Component, OnInit } from '@angular/core';
import { ChargesPfbService } from '../charges-pfb.service';
import { saveAs as importedSaveAs } from "file-saver";
import { ToastrService } from 'ngx-toastr';
import { ImportExportService } from '../../import-export';
import { DateInterval } from 'src/app/shared/model';
import { ChargePfbInfoDto, ChargeRealise, VoyageDto } from '../charges-pfb';
import { FuelingDashboardDto } from '../../parc-management';
import { forkJoin } from 'rxjs';
import {TransportTypeInfoDto, TransportTypeService} from "../parametrage/transport-type";

interface IMonth {
  id: number;
  name: string;
}
interface IYear {
  id: number;
  name: string;
}

@Component({
  selector: 'app-charges-pfb-history',
  templateUrl: './charges-pfb-history.component.html',
  styleUrls: ['./charges-pfb-history.component.css']
})
export class ChargesPfbHistoryComponent implements OnInit {

  /**
   * Map of CA Realise
   * it has two types
   * 1) ListByDay = ca grouped by date
   * and it is used when the user choose more than one date
   * 2) SimpleList = it is used when the user choose only
   * on date
   */
  mapCaRealise: any;
  caRealise: ChargeRealise[] = [];
  /** for Corrélation charts */
  crCaRealise: ChargeRealise[] = [];
  voyageData: VoyageDto;
  fueling: FuelingDashboardDto[] = [];
  chargesPfb: ChargePfbInfoDto[] = [];
  filtredChargesPfb: ChargePfbInfoDto[] = [];
  selectedCharge: ChargePfbInfoDto ;


  /** used for the filter by date */
  date: DateInterval = new DateInterval();
  endDate: Date | null = null;
  startDate: Date | null = null;
  /** used as min date */
  firstDay = new Date();
  /** used as max date */
  lastDay: Date = new Date();
  public years: IYear[] = [];
  public months: IMonth[] = [];

  now: Date = new Date();


  globalLoading: boolean;
  dayDif: number = 0;
  locationDayDif: number = 0;
  /**show loader element */
  loader = false;

  loading: boolean = false;

  selectedMonth: number;
  selectedYear: number;
  selectedOption: any = "";

  /** LOADING */
  loadingTracking: boolean = false;
  loadingFuelTracking: boolean = false;
  loadingCATracking: boolean = false;

  selectedTransport: TransportTypeInfoDto = new TransportTypeInfoDto();

  /** manual or automatic mode */
  isManually = false;

  constructor(private toastr: ToastrService, private chargePfbService: ChargesPfbService,
    private importExportService: ImportExportService, public transportTypeService: TransportTypeService) {
    this.isManually = this.chargePfbService.isManually;
  }

  ngOnInit() {
    this.loadTransportTypes();
    this.years = this.getYears();
    this.selectedYear = this.years[0].id;
    this.months = this.chargePfbService.months.filter(m => m.id < this.now.getMonth() + 1);
  }

  /**
   * list of last 3 years
   */
  getYears() {
    return [
      { id: new Date().getFullYear(), name: 'Année En cours' },
      { id: new Date().getFullYear() - 1, name: 'Année Antérieure' },
      { id: new Date().getFullYear() - 2, name: 'Année -2' }
    ];
  }

  /**
   * load charges by month and year
   */
  loadHistoricalCharges(month: number) {
    this.loading = true;
    this.chargePfbService.getHistoricalChargesByMonth(month, this.selectedYear)
      .subscribe(chargePfb => {
        this.chargesPfb = chargePfb;
        this.filtredChargesPfb = this.chargesPfb.filter(c => c.transportType.idTransportType == this.selectedTransport.idTransportType);
        this.selectedCharge = this.filtredChargesPfb[0];
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Charge")
      })
  }

  /**
   * load all transport types
   */
  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.transportTypeService.findAllTransportTypes().subscribe(transportTypes => {
        this.transportTypeService.transportTypes = transportTypes;
        this.selectedTransport = transportTypes[0];
      }, err => {
        this.toastr.error("Erreur lors de chargements", "Type de transport");
      })
    } else {
      this.selectedTransport = this.transportTypeService.transportTypes[0];
    }

  }

  /**
   * export excel file
   */
  export() {
    this.loader = true;
    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;
    /** get the days difference and check if the result is bigger than the selected number of days */
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);

    /**
     * the diffence must be lower than
     * or equal the chosen number of days
     * in a month
     */
    if (this.dayDif >= this.selectedCharge.nbDays)
      this.dayDif = this.selectedCharge.nbDays;
    else
      this.dayDif;
    this.importExportService.getChargePfbReport(this.selectedCharge.idChargePfb, this.date, this.selectedMonth, this.dayDif).subscribe(
      blob => {
        if (blob.size > 0) {
          importedSaveAs(blob, 'Charge_PFB.xlsx');
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates!', 'Error');
        }
        this.loader = false;
      },
      error => {
        this.toastr.warning('Aucune donnée entre ces deux dates!', 'Error');
        this.loader = false;
      }
    );

  }

  /**
   * retrieve data entered manually
   */
  manualData() {
    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;
    this.loadingTracking = true;
    this.loadingCATracking = true;
    this.loadingFuelTracking = true;
    this.globalLoading = true;
    /** get the days difference and check if the result is bigger than the selected number of days */
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);

    /**
     * the diffence must be lower than
     * or equal the chosen number of days
     * in a month
     */
    if (this.dayDif >= this.selectedCharge.nbDays)
      this.dayDif = this.selectedCharge.nbDays;
    else
      this.dayDif;

    this.loadCaRealise();
  }

  /**
     * load fuel consommé and CA réalisée
     */
  onFuelTracking() {
    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;
    this.globalLoading = true;
    this.loadingFuelTracking = true;
    // CA REALISE
    this.mapCaRealise = new Map();
    this.caRealise = [];
    this.crCaRealise = [];

    /** get the days difference and check if the result is bigger than the selected number of days */
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);

    if (this.dayDif >= this.selectedCharge.nbDays)
      this.dayDif = this.selectedCharge.nbDays;
    else
      this.dayDif;

    forkJoin(
      [this.chargePfbService.getFuelCharge(this.selectedCharge.idChargePfb, this.date, this.selectedMonth),
      this.chargePfbService.getCaRealise(this.selectedCharge.idChargePfb, this.date)]
    ).subscribe(([fueling, caRealise]) => {
      this.fueling = fueling;
      this.mapCaRealise = caRealise;
      this.caRealise = this.mapCaRealise.simpleList;
      if (this.dayDif > 1) {
        this.crCaRealise = this.mapCaRealise.ListByDay;
      } else {
        this.crCaRealise = this.caRealise;
      }
      this.globalLoading = false;
      this.loadingFuelTracking = false;
    }, err => {
      this.fueling = [];
      this.globalLoading = false;
      this.loadingFuelTracking = false;
      this.toastr.error("Erreur lors de chargement", "Charge Carburant")
    })
  }

  /**
   * load CA réalisée
   */
  loadCaRealise() {
    this.loadingCATracking = true;
    this.loadingFuelTracking = true;
    this.caRealise = [];
    this.crCaRealise = [];
    this.mapCaRealise = new Map();
    this.chargePfbService.getCaRealise(this.selectedCharge.idChargePfb, this.date).subscribe(caRealise => {
      this.mapCaRealise = caRealise;
      this.caRealise = this.mapCaRealise.simpleList;
      if (this.dayDif > 1) {
        this.crCaRealise = this.mapCaRealise.ListByDay;
      } else {
        this.crCaRealise = this.caRealise;
      }
      this.globalLoading = false;
      this.loadingCATracking = false;
      this.loadingFuelTracking = false;
    }, err => {
      this.globalLoading = false;
      this.loadingCATracking = false;
      this.loadingFuelTracking = false;
      this.toastr.error("Erreur lors de chargement", "C.A Réalisé")
    })
  }

  /**
   * load rotation réalisé and CA réalisée
   */
  RotationCharge() {
    this.loadingTracking = true;
    this.loadingCATracking = true;
    this.mapCaRealise = new Map();
    this.caRealise = [];
    this.crCaRealise = [];
    this.voyageData = new VoyageDto();
    forkJoin(
      [this.chargePfbService.getRotationCharge(this.selectedCharge.idChargePfb, this.date),
      this.chargePfbService.getCaRealise(this.selectedCharge.idChargePfb, this.date)]
    ).subscribe(([voyage, caRealise]) => {
      this.voyageData = voyage;
      this.mapCaRealise = caRealise;
      this.caRealise = this.mapCaRealise.simpleList;
      if (this.dayDif > 1) {
        this.crCaRealise = this.mapCaRealise.ListByDay;
      } else {
        this.crCaRealise = this.caRealise;
      }
      this.loadingCATracking = false;
      this.globalLoading = false;
      this.loadingTracking = false;
    }, err => {
      this.globalLoading = false;
      this.loadingCATracking = false;
      this.loadingTracking = false;
      this.toastr.error("Erreur lors de chargement", "Erreur")
    })
  }

  /**
   *
   * @param transport An object value equal to the TransportTypeInfoDto value.
   * Action change transport type
   */
  chooseTransport(transport: any) {
    this.selectedTransport = transport;
    this.filtredChargesPfb = this.chargesPfb.filter(c => c.transportType.idTransportType == this.selectedTransport.idTransportType);
    this.selectedCharge = this.filtredChargesPfb[0];
  }

  onTracking() {

    this.date.startDate = this.startDate;
    this.date.endDate = this.endDate;
    this.loadingTracking = true;
    this.loadingCATracking = true;
    this.globalLoading = true;
    /** get the days difference and check if the result is bigger than the selected number of days */
    this.dayDif = Math.round((this.date.endDate.getTime() - this.date.startDate.getTime()) / 1000 / 60 / 60 / 24);
    if (this.dayDif >= this.selectedCharge.nbDays)
      this.dayDif = this.selectedCharge.nbDays;
    else
      this.dayDif;

    this.RotationCharge();
    // this.loadCaRealise();
  }

  chooseCharge(charge: any) {
    this.selectedCharge = charge;
  }
  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.startDate && this.endDate && this.endDate < this.startDate) {
      this.endDate = null;
    }
  }


  /**
   * on action year change call month change
   */
  onYearChange() {
    if (this.selectedYear == new Date().getFullYear()) {
      this.months = this.chargePfbService.months.filter(m => m.id < this.now.getMonth() + 1);
    } else
      this.months = this.chargePfbService.months;

    /**
   * check if selected month exists in months list
   * well, this is used for the current year
   * because in the current year we only display
   * months before the current month
   */
    if (this.months.find(m => m.id == this.selectedMonth))
      this.onMonthChange(this.selectedMonth)
  }

  /**
   *
   * @param month A numeric value equal to the month value.
   * on month change event load charges by month and year
   */
  onMonthChange(month: number) {

    if (!month)
      return;

    this.endDate = new Date(this.selectedYear, month, 0, 23, 59, 59, 59);
    this.startDate = new Date(this.selectedYear, month - 1, 1, 0, 0, 0, 0);
    this.lastDay = new Date(this.selectedYear, month, 0);
    this.firstDay = new Date(this.selectedYear, month - 1, 1);
    this.selectedCharge = null;
    this.chargesPfb = [];
    this.selectedMonth = month;
    if (this.transportTypeService.transportTypes.length > 0)
      this.loadHistoricalCharges(month);
  }

}
