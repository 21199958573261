
<!-- <div class="row">
    <div class="col-xl-11 col-md-11 col-xs-11 content-counters">
        <div class="col-xl-6 col-md-6 col-xs-6">
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3">
                <div class="card green">
                    <div class="title">Clients Détail</div>
                    <div class="value">
                        <span>{{clientCounter}}</span>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-3 mb-1 col-xs-3">
                <div class="card orange">
                    <div class="title">Clients Gros</div>
                    <div class="value">
                        <span>{{clientGrosCounter}}</span>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-3 mb-1 col-xs-3">
                <div class="card blue">
                    <div class="title">Prospects</div>
                    <div class="value">
                        <span>{{prospectCounter}}</span>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-3 mb-1 col-xs-3">
                <div class="card label-brown">
                    <div class="title">Intermédiaire</div>
                    <div class="value">
                        <span>{{intermediaireCounter}}</span>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-xl-6 col-md-6 col-xs-6">

            <div class="col-xl-3 col-md-3 mb-1 col-xs-3">
                <div class="card yellow">
                    <div class="title">Dépôts</div>
                    <div class="value">
                        <span>{{depotCounter}}</span>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-3 mb-1 col-xs-3">
                <div class="card red">
                    <div class="title">Concurrents</div>
                    <div class="value">
                        <span>{{concurrentCounter}}</span>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-3 mb-1 col-xs-3">
                <div class="card gray">
                    <div class="title">Parkings</div>
                    <div class="value">
                        <span>{{driverCounter}}</span>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-3 mb-1 col-xs-3">
                <div class="card label-violet">
                    <div class="title">Concessionnaires</div>
                    <div class="value">
                        <span>{{concessionnaireCounter}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div> -->

<!--  new car design-->
<div class="row">
    <div class="col-xl-6 col-md-6 col-xs-6 content-counters" style="z-index: 100; width: 823px;">
        <div class="" style="padding-left: 0px; padding-right: 5px;" >
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3 general">
                  <div class="stati" style="color: #66B92E;" >
                    <img class="stati-icon" src="assets/icons/geoMarketing/information.svg" alt="" style="filter: invert(55%) sepia(39%) saturate(772%) hue-rotate(52deg) brightness(105%) contrast(91%);">
                      <div>
                             <b>{{clientCounter}}</b>
                            <span class="stati-text" ><strong style="color: #66B92E;">Clients Détail</strong></span>
                      </div>
                  </div>
            </div>
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3 general">
                <div class="stati" style="color: #DA932C;" >
                    <img class="stati-icon" src="assets/icons/geoMarketing/rating.svg" alt="" style="filter: invert(55%) sepia(52%) saturate(646%) hue-rotate(356deg) brightness(101%) contrast(86%);">
                      <div>
                             <b>{{clientGrosCounter}}</b>
                            <span class="stati-text" ><strong style="color: #DA932C;">Clients Gros</strong></span>
                      </div>
                  </div>
            </div>
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3 general">
                <div class="stati" style="color: #007bff;" >
                    <img class="stati-icon" src="assets/icons/geoMarketing/search.svg" alt="" style="filter: invert(46%) sepia(81%) saturate(5819%) hue-rotate(201deg) brightness(103%) contrast(111%);">
                      <div>
                             <b>{{prospectCounter}}</b>
                            <span class="stati-text" ><strong style="color: #007bff;">Prospects</strong></span>
                      </div>
                  </div>
            </div>
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3 general">
                <div class="stati" style="color: #964b00;" >
                    <img class="stati-icon" src="assets/icons/geoMarketing/intermediary.svg" alt="" style="filter: invert(23%) sepia(56%) saturate(3849%) hue-rotate(36deg) brightness(94%) contrast(104%);">
                      <div>
                             <b>{{intermediaireCounter}}</b>
                            <span class="stati-text" ><strong style="color: #964b00;">Intermédiaire</strong></span>
                      </div>
                  </div>
            </div>
        </div>

    </div>
</div>

<div class="row">
    <div class="col-xl-6 col-md-6 col-xs-6 content-counters2" style="width: 823px;">
        <div class="" style="padding-left: 0px; padding-right: 5px;">
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3 general">
                <div class="stati" style="color: #d2cf0a;" >
                    <img class="stati-icon" src="assets/icons/geoMarketing/warehouse.svg" alt="" style="filter: invert(72%) sepia(75%) saturate(1437%) hue-rotate(14deg) brightness(104%) contrast(92%);">
                      <div>
                             <b>{{depotCounter}}</b>
                            <span class="stati-text" ><strong style="color: #d2cf0a;">Dépôts</strong></span>
                      </div>
                  </div>
            </div>
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3 general">
                <div class="stati" style="color: #d65b4a;" >
                    <img class="stati-icon" src="assets/icons/geoMarketing/competitor.svg" alt="" style="filter: invert(55%) sepia(47%) saturate(6294%) hue-rotate(337deg) brightness(95%) contrast(76%);">
                      <div>
                             <b>{{concurrentCounter}}</b>
                            <span class="stati-text" ><strong style="color: #d65b4a;">Concurrents</strong></span>
                      </div>
                  </div>
            </div>
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3 general">
                <div class="stati" style="color: #808080;" >
                    <img class="stati-icon" src="assets/icons/geoMarketing/parking.svg" alt="" style="filter: invert(54%) sepia(0%) saturate(1404%) hue-rotate(221deg) brightness(93%) contrast(85%);">
                      <div>
                             <b>{{driverCounter}}</b>
                            <span class="stati-text" ><strong style="color: #808080;">Parkings</strong></span>
                      </div>
                  </div>
            </div>
            <div class="col-xl-3 col-md-3 mb-1 col-xs-3 general">
                <div class="stati" style="color: #7f00ff;" >
                    <img class="stati-icon" src="assets/icons/geoMarketing/handshake.svg" alt="" style="filter: invert(9%) sepia(100%) saturate(7421%) hue-rotate(273deg) brightness(100%) contrast(117%);">
                      <div>
                             <b>{{concessionnaireCounter}}</b>
                            <span class="stati-text" ><strong style="color: #7f00ff;">Concessionnaires</strong></span>
                      </div>
                  </div>
            </div>
        </div>
    </div>
</div>


<!-- end new card-design -->

<div class="row">
    <div class="col-xs-6 content-poi" style="background-color: transparent !important; width: 818px;">
        <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
                <li class="active">
                    <a href="#points" data-toggle="tab" aria-expanded="true"
                        (click)="searchTerm = null;importExport=true;initPolygons();">

                        <span class="pull-left-container">
                            <i class="fa fa-map-marker" aria-hidden="true"></i> Points
                            <small class="label bg-blue">{{poiClientsPoint.length}}</small>
                        </span>
                    </a>
                </li>
                <li class="">
                    <a href="#zones" data-toggle="tab" aria-expanded="false"
                        (click)="searchTerm = null;importExport=false;initPoints();">

                        <a href="#zones" data-toggle="tab" aria-expanded="false">
                            <span class="pull-left-container">
                                <i class="fa fa-map" aria-hidden="true"></i> Zones
                                <small class="label bg-blue">{{poiClientsPolygon.length}}</small>
                            </span>
                        </a>

                    </a>
                </li>

                <div *ngIf="importExport" id="importExpo">
                    <!--==================================== import/Export functionality(START)=============== -->
                    <li class="pull-right mt-2 pr-2">

                        <button type="button" class="btn btn-primary exportImpo" title="Enregistrer sous format excel"
                            (click)='export()'>Exporter <span class="badge"><i
                                    class="fa fa-download"></i></span></button>

                    </li>
                    <li class="pull-right mt-2">

                        <button type="button" class="btn btn-primary exportImpo" title="Enregistrer sous format excel"
                            (click)='importModal.show()'>Importer <span class="badge"><i
                                    class="fa fa-upload"></i></span></button>


                    </li>
                    <!--==================================== import/Export functionality(END)================= -->
                    <li class="pull-right mt-2" style="padding-right: 5px;">
                        <select class="form-control" [(ngModel)]="selectedClientType" name="clientType"
                            (ngModelChange)="initPoints()">
                            <option style="font-size: 14px;" [ngValue]="null">Tous</option>
                            <option style="font-size: 14px;" *ngFor="let clientType of clientTypes"
                                [value]="clientType.value">
                                {{clientType.text}}</option>
                        </select>
                    </li>
                </div>

            </ul>

            <!-- toggle tab -->
            <div style="text-align:center; cursor: pointer;" title="afficher/cacher" (click)="poiToggle()">
                <i *ngIf="!isPoiExpanded" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="isPoiExpanded" class="fa fa-caret-up" aria-hidden="true"></i>
            </div>

            <div class="tab-content" style="background-color: transparent !important;" [hidden]="!isPoiExpanded">

                <div class="overlay" *ngIf="!isDataLoaded">
                    <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
                </div>

                <div *ngIf="isDataLoaded" class="tab-pane active" id="points"
                    style="background-color: transparent !important;">

                    <table class="table table-fixed" style="font-size: 12px;">
                        <thead>
                            <tr>
                                <th class="col-xs-3">Libellé</th>
                                <th class="col-xs-5">Adresse</th>
                                <th class="col-xs-4" colspan="2">
                                    <div class="input-group input-group-sm">
                                        <input type="text" name="table_search" class="form-control pull-right"
                                            placeholder="Rechercher..." [(ngModel)]="searchTerm"
                                            (ngModelChange)="initPoints()">

                                        <div class="input-group-btn">
                                            <button type="submit" class="btn btn-default">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="col-xs-12" *ngFor="let point of dataPoint" (click)="displayPointPolygon(point)"
                                [ngClass]="{'activePOI': point == poiClient}">
                                <td class="col-xs-2" style="font-weight: bold">
                                    <span *ngIf="point?.clientType == 'CLIENT'" class="badge label-success"
                                        title="Clients détail">C</span>
                                    <span *ngIf="point?.clientType == 'CLIENT_GROS'" class="badge label-warning"
                                        title="Clients gros">G</span>
                                    <span *ngIf="point?.clientType == 'DRIVER'" class="badge label-dark"
                                        title="Parkings">P</span>
                                    <span *ngIf="point?.clientType == 'DEPOT'" class="badge label-yellow"
                                        title="Dépôts">D</span>
                                    <span *ngIf="point?.clientType == 'INTERMEDIAIRE'" class="badge label-brown"
                                        title="Intermédiaire">I</span>
                                    <span *ngIf="point?.clientType == 'PROSPECT'" class="badge label-primary"
                                        title="Prospect">P</span>
                                    <span *ngIf="point?.clientType == 'CONCURENT'" class="badge label-danger"
                                        title="Concurrent">CN</span>
                                    <span *ngIf="point?.clientType == 'CONCESSIONNAIRE'" class="badge label-violet"
                                        title="concessionnaire">CO</span>
                                    <span *ngIf="point?.clientType == NULL " class="badge label-black"
                                        title="Aucune type">A</span>

                                    {{point.name | truncate :[30]}}
                                </td>
                                <td class="col-xs-5" style="font-weight: bold">
                                    {{point.address | truncate :[50]}}
                                </td>
                                <td class="col-xs-3">
                                    <label>{{point?.coordinate?.lat | number:'2.2-6'}},{{point?.coordinate?.lng |
                                        number:'2.2-6'}}
                                    </label>
                                </td>
                                <td class="col-xs-1" (click)="$event.stopPropagation();updatePOI(point)">
                                    <i title="Modifier" class="fa fa-cog" aria-hidden="true"></i>
                                </td>
                                <td class="col-xs-1" (click)="$event.stopPropagation();deletePointPolygon(point)">
                                    <i title="Supprimer" class="fa fa-trash-o" style="color: red !important;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="box-footer clearfix" *ngIf="poiClientsPoint.length >0" style="text-align: center">
                        <pagination class="pagination-sm no-margin" [(ngModel)]="page"
                            [totalItems]="poiClientsPoint.length" [itemsPerPage]="itemsPerPage" [maxSize]="maxSize"
                            [boundaryLinks]="true" (pageChanged)="PageChangePoint($event)" previousText="Précédent"
                            nextText="Suivant" firstText="Premier" lastText="Dernier">
                        </pagination>
                    </div>

                </div>

                <div *ngIf="isDataLoaded" class="tab-pane" id="zones"
                    style="background-color: rgba(255, 255, 255, 0.55)">

                    <table class="table table-fixed" style="font-size: 12px;">
                        <thead>
                            <tr>
                                <th class="col-xs-3">Libellé</th>
                                <th class="col-xs-5">Adresse</th>
                                <th class="col-xs-4" colspan="2">
                                    <div class="input-group input-group-sm">
                                        <input type="text" name="table_search" class="form-control pull-right"
                                            placeholder="Rechercher..." [(ngModel)]="searchTerm"
                                            (ngModelChange)="initPolygons()">

                                        <div class="input-group-btn">
                                            <button type="submit" class="btn btn-default">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="col-xs-12" *ngFor="let point of dataPolygon" (click)="displayPointPolygon(point)"
                                [ngClass]="{'activePOI': point == poiClient}">
                                <td class="col-xs-2" style="font-weight: bold">{{point.name | truncate :[30]}}</td>
                                <td class="col-xs-5" style="font-weight: bold">
                                    {{point.address | truncate :[50]}}
                                </td>
                                <td class="col-xs-3">
                                    <label>{{point?.coordinate?.lat | number:'2.2-6'}},{{point?.coordinate?.lng |
                                        number:'2.2-6'}}
                                    </label>
                                </td>
                                <td class="col-xs-1" (click)="$event.stopPropagation();updatePOI(point)">
                                    <i title="Modifier" class="fa fa-cog" aria-hidden="true"></i>
                                </td>
                                <td class="col-xs-1" (click)="$event.stopPropagation();deletePointPolygon(point)">
                                    <i title="Supprimer" class="fa fa-trash-o" style="color: red !important;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="box-footer clearfix" *ngIf="poiClientsPolygon.length >0" style="text-align: center">
                        <pagination class="pagination-sm no-margin" [(ngModel)]="pagePolgygon"
                            [totalItems]="poiClientsPolygon.length" [itemsPerPage]="itemsPerPagePolgygon"
                            [maxSize]="maxSizePolgygon" [boundaryLinks]="true" (pageChanged)="PageChangePolygon($event)"
                            previousText="Précédent" nextText="Suivant" firstText="Premier" lastText="Dernier">
                        </pagination>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<section class="map-container">
    <app-leaflet [item]="'client'" [hidebutton]="false"></app-leaflet>
</section>
<div   #childModal  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header black-bg">
                <h4 class="modal-title pull-left" style="font-family: 'Inconsolata', monospace;font-size: 17px;">
                    <i class="fa fa-plus-pen"></i> Modifier client
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="childModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <app-poi-client-form [poiItems]="poiClientsPoint" [poiClient]="poiClient" [mode]="'UPDATE'"
                    (poiClientWasUpdated)="childModal.hide()"></app-poi-client-form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" tabindex="-1" #poiDeleteModal="bs-modal" bsModal role="dialog" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="poiDeleteModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !
                    </h4>
                    Êtes-vous sûr de supprimer ce poi ?
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="poiDeleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="onDelete()">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>


<!--=================================Import POI(start) ====================================-->
<div class="modal" tabindex="-1" #importModal >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="importModal.hide()">&times;
                </button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Importer la liste des clients
                </h4>
            </div>
            <div class="modal-body vertical-spacer">
                <app-import-export-forms [item]='selectedItem' [clientType]='selectedClientType'
                    (importedItem)='itemWasImported($event)'>
                </app-import-export-forms>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="importModal.hide()">
                    Fermer
                </button>
            </div>
        </div>
    </div>
</div>
<!--=================================Import POI (end) ====================================-->
