import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FuelingPriceSettingService } from '../../providers/fueling-price-setting.service';
import { FuelingPriceSetting } from '../../model/index';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-fueling-price-setting',
  templateUrl: './fueling-price-setting.component.html',
  styleUrls: ['./fueling-price-setting.component.css']
})
export class FuelingPriceSettingComponent implements OnInit {

  error: { errorMessage: string } = { errorMessage: '' };
  fuelingPriceSetting: FuelingPriceSetting = new FuelingPriceSetting();
  fuelingPriceSettingToDelete: FuelingPriceSetting = new FuelingPriceSetting();
  globalLoading = false;

  fuelingPriceSettings: FuelingPriceSetting[] = [];

  @ViewChild('fuelingPriceSettingDeleteModal', { static: false })
  fuelingPriceSettingDeleteModal: ModalDirective;

  constructor(public toastr: ToastrService, private fuelingPriceSettingService: FuelingPriceSettingService) { }

  ngOnInit() {
    this.loadFuelingSettings();
  }

  loadFuelingSettings() {
    this.globalLoading = true;
    this.fuelingPriceSettingService.getList().finally(() => {
      this.globalLoading = false;
    }).subscribe(fuelingPriceSettings => {
      this.fuelingPriceSettings = fuelingPriceSettings;
    },
      error => {
        this.error = error;
        this.error = JSON.parse(JSON.stringify(error.error || null));

        if (!this.error.errorMessage) {
          this.error.errorMessage = "SERVER ERROR";
        }
      });
  }

  /**
  * Suppression du paramétrage
  */
  onDelete() {
    this.globalLoading = true;
    this.fuelingPriceSettingService.delete(this.fuelingPriceSettingToDelete.id).subscribe(response => {
      this.fuelingPriceSettingDeleteModal.hide();
      if (response) {
        this.toastr.success('Paramétrage bien supprimer !', 'info');
        this.fuelingPriceSettings = this.fuelingPriceSettings.filter(f => f.id != this.fuelingPriceSettingToDelete.id);
      } else {
        this.toastr.error('Le paramétrage n\'a pas supprimé correctement !', 'Erreur');
      }
      this.globalLoading = false;
    }, error => {
      this.error = error;
      this.error = JSON.parse(JSON.stringify(error.error || null));

      if (!this.error.errorMessage) {
        this.error.errorMessage = "SERVER ERROR";
      }
      this.globalLoading = false;
    });
  }

  /**
     * Afficher modal de confirmation du suppression
     */
  onConfirmDelete(fuelingPriceSetting: FuelingPriceSetting) {
    this.fuelingPriceSettingToDelete = fuelingPriceSetting;
    this.fuelingPriceSettingDeleteModal.show();
  }

  cancel(templateForm: NgForm) {
    templateForm.form.reset();
  }

  saveTemplateSetting(templateForm: NgForm) {
    this.globalLoading = true;
    this.fuelingPriceSettingService
      .save(this.fuelingPriceSetting)
      .finally(() => {
        this.globalLoading = false;
      })
      .subscribe(
        fuelingTemplateSetting => {
          this.globalLoading = false;
          this.fuelingPriceSettings.unshift(fuelingTemplateSetting);
          templateForm.form.reset();
          this.toastr.success("enregistré avec succès !");
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.globalLoading = false;
        }
      );
  }
}
