<form #otNumber="ngForm" class="form-horizontal">

  <div class="box-body">
    <div class="form-group">

      <label class="col-sm-2 control-label">Véhicule</label>
      <div class="col-sm-3">

        <ng-multiselect-dropdown [placeholder]="'select Véhicule'" [settings]="vehiculesDropdownSettings"
          [data]="scheduleListForm_" [(ngModel)]="otNum.deviceId">
        </ng-multiselect-dropdown>



      </div>
      <label class="col-sm-2 control-label">Numéro OT <span style="color: red;">*</span></label>
      <div class="col-sm-4" *ngIf="isOt ;else otChoice">
        <input type="text" [(ngModel)]="otNum.otNumber" name="otNumber">
      </div>
      <ng-template #otChoice>
        <div class="col-sm-4">

          <ng-multiselect-dropdown [placeholder]="'Livraison'" [settings]="dropdownSettings" [data]="otNumberList"
            (onSelect)="onSelectOtNumber($event)">
          </ng-multiselect-dropdown>

        </div>
      </ng-template>

    </div>

    <div class="form-group">

      <label class="col-sm-2 control-label">type mission</label>
      <div class="col-sm-10">
        <!-- <ng-multiselect-dropdown-angular7
          name="typeSelected"
          [data]="missionTypeList"
          placeholder="Livraison"
          [(ngModel)]="selectedType"
          [settings]="typeDropdownSettings"
          (onSelect)="onSelectMissionType($event)" >
          </ng-multiselect-dropdown-angular7> -->



        <ng-multiselect-dropdown [placeholder]="'Livraison'" [settings]="dropdownSettings" [data]="missionTypeList"
          [(ngModel)]="selectedType">
        </ng-multiselect-dropdown>

      </div>

    </div>
  </div>

  <div class="col-lg-2 col-md-2">
    <button type="submit" class="btn btn-success" (click)="onSaveotNumber()" style="position: relative; left: 575%;"
      [disabled]="!otNumber.form.valid || loading || (otNum.missionType == 'Livraison' && otNum.otNumber == null)">
      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
    </button>
  </div>
  <div class="col-lg-2 col-md-2">
    <button (click)="onHideModal();isOt = false" class="btn btn-default" style="position: relative; left: 530%;">
      Fermer
    </button>
  </div>


</form>

<!--
  id / name / otNumber / idVehicule / missionType
 -->
