import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CanalInfoDto } from '../../canals';
import { CanalService } from '../../canal.service';

@Component({
  selector: 'app-canal-type',
  templateUrl: './canal-type.component.html',
  styleUrls: ['./canal-type.component.css'],
})
export class CanalTypeComponent implements OnInit {
  // local vars
  loading: boolean;
  globalLoading: boolean = false;
  canal: CanalInfoDto = new CanalInfoDto();
  @ViewChild('deleteModal', { static: false }) deleteModal: ModalDirective;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private canalService: CanalService
  ) {}

  ngOnInit() {
    this.loadCanals();
  }

  goToFormPage(mode: any, id: any) {
    this.router.navigate(['form/' + id + '/' + mode], {
      relativeTo: this.route,
    });
  }

  loadCanals() {
    if (this.canalService.canals.length <= 0) {
      this.loading = true;
      this.canalService.findAllCanals().subscribe(
        (canals) => {
          this.canalService.canals = canals;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Canal');
        }
      );
    }
  }

  confirmDeleteCanal(canal: CanalInfoDto) {
    this.canal = canal;
    this.deleteModal.show();
  }

  onDelete() {
    this.loading = true;
    this.canalService.deleteCanal(this.canal.idCanal).subscribe(
      (deleted) => {
        this.deleteModal.hide();
        this.loading = false;
        if (deleted) {
          this.canalService.canals = this.canalService.canals.filter(
            (canal) => canal.idCanal !== this.canal.idCanal
          );
          this.toastr.success('Canal bien supprimer !', 'info');
        } else {
          this.toastr.error('Erreur lors de suppression', 'Canal');
        }
      },
      (err) => {
        this.loading = false;
        this.toastr.error('Erreur lors de suppression', 'Canal');
      },
      () => {
        this.loading = false;
      }
    );
  }
}
