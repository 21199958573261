import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiculeDetailComponent } from './vehicule-details.component';
import { FormsModule } from '@angular/forms'; // Import if using forms in the component
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { NoValuePipeModule } from 'src/app/pipes/no-value.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    VehiculeDetailComponent // Declare the VehiculeDetailComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for common directives
    FormsModule, // Import FormsModule if using template-driven forms
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    NoValuePipeModule,
    NgxLoadingModule.forRoot({}),
  ],
  exports: [
    VehiculeDetailComponent // Export the VehiculeDetailComponent for use in other modules
  ]
})
export class VehiculeDetailModule { }
