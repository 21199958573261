<div class="row padd">
    <!-- ==================== Objectif Section ======================= -->
    <div class="col-xl-4 col-md-4 mb-2 col-xs-4">
        <div class="card blueStyle" title="Objectif">
            <div class="title">Objectif</div>
            <div class="value">
                <span *ngIf="selectedCharge && total">{{objectifRotation | number: '1.0-0' }}</span>
                <span *ngIf="!selectedCharge || !total"> - </span>
            </div>
        </div>
    </div>


    <!-- ==================== Commande Ferme Section ======================= -->
    <!-- <div class="col-xl-4 col-md-4 mb-2 col-xs-4">
        <div class="card greyStyle" title="Commande Ferme">
            <div class="title">C.Ferme</div>
            <div class="value">
                <span *ngIf="total && selectedCharge && voyageData_">{{voyageData_?.commandeFerme}}</span>
                <span *ngIf="!selectedCharge || !total"> - </span>
            </div>

        </div>
    </div> -->

    <!-- ==================== Réalisé Section ======================= -->
    <div class="col-xl-4 col-md-4 mb-2 col-xs-4">
        <div class="card yellowStyle" title="Rotation Réalisée">
            <div class="title">Réalisée</div>
            <div class="value">
                <span *ngIf="selectedCharge && total && rotationR">{{rotationR | number: '1.0-0'}}</span>
                <span *ngIf="!selectedCharge || !total"> - </span>
            </div>

        </div>
    </div>

    <!-- ==================== a réaliser Section ======================= -->
    <div class="col-xl-4 col-md-4 mb-2 col-xs-4">
        <div class="card" [ngClass]="total < 0 ? 'greenStyle' : 'redStyle'" title="Écart">
            <div class="title">Écart</div>
            <div class="value">
                <span
                    *ngIf="selectedCharge && rotationR && objectifRotation">{{rotationR - objectifRotation | number: '1.0-0' }}</span>
                    <span *ngIf="!objectifRotation || !rotationR || !selectedCharge"> - </span>
            </div>

        </div>
    </div>

</div>

<div id="rotationChart" class="w-100 d-inline-block"></div>