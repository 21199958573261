import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';



@Component({
  selector: 'progress-range-bar',
  templateUrl: './progress-range-bar.component.html',
  styleUrls: ['./progress-range-bar.component.css'],
})
export class ProgressRangeBarComponent {
  @Input() rangeValue: any ;
  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input()step: number = 1;

  @Output() rangeValueChange: Subject<number> = new Subject<number>(); 
  @Output() enddedDraggin: Subject<boolean> = new Subject<boolean>(); 
  @Output() startingDraggin: Subject<boolean> = new Subject<boolean>(); 


  isDraging: boolean= false;

  constructor() {}


  onMouseDown(){
    this.isDraging = true;
    this.startingDraggin.next(true)
  }
  
  onDrag(){
    if(this.isDraging)
      this.rangeValueChange.next(this.rangeValue)
   }

  onMouseUp(){
    this.enddedDraggin.next(true);
    this.isDraging = false;
  }



} 
