import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChargesPfbHistoryComponent } from './charges-pfb-history.component';
import { ToastrModule } from 'ngx-toastr';
import { ChargesPfbService } from '../charges-pfb.service';
import { ImportExportService } from '../../import-export/import-export.service';
import { TransportTypeService } from '../parametrage/transport-type/transport-type.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { RotationChargeComponent } from '../charges-pfb-tracking/rotation-charge/rotation-charge.component';
import { RotationChargeModule } from '../charges-pfb-tracking/rotation-charge/rotation-charge.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CorrelationLocationModule } from '../charges-pfb-tracking/correlation-location/correlation-location.module';
import { CorrelationGasoilModule } from '../charges-pfb-tracking/correlation-gasoil/correlation-gasoil.module';
import { TurnoverAchievedModule } from '../charges-pfb-tracking/turnover-achieved/turnover-achieved.module';
import { LocationCostComponent } from '../charges-pfb-tracking/location-cost/location-cost.component';
import { LocationCostModule } from '../charges-pfb-tracking/location-cost/location-cost.module';
import { FuelingChargeModule } from '../charges-pfb-tracking/fueling-charge/fueling-charge.module';

@NgModule({
  declarations: [
    ChargesPfbHistoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),  // Importing ngx-bootstrap modal functionality
    ToastrModule.forRoot(),  // Importing Toastr for notifications
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    RotationChargeModule,
    CorrelationLocationModule,
    CorrelationGasoilModule,
    TurnoverAchievedModule,
    LocationCostModule,
    FuelingChargeModule

  ],
  providers: [
    ChargesPfbService,
    ImportExportService,
    TransportTypeService
  ],
  exports: [
    ChargesPfbHistoryComponent
  ]
})
export class ChargesPfbHistoryModule { }
