import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ToastrModule } from 'ngx-toastr';

import { ApproFuelSettingsComponent } from './appro-fuel-settings.component';
import { AlertManagementService } from '../../alert-management.service';
import { AlertManagementServiceUtils } from '../../alert-management-utils.service';
import { DataManagementService } from '../../../data-management/data-management.service';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';

@NgModule({
  declarations: [
    ApproFuelSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSlideToggleModule,
    ToastrModule.forRoot(), // Assuming Toastr is needed globally; adjust if necessary
    EmailManagerModule,
    CronSettingsModule
  ],
  providers: [
    AlertManagementService,
    AlertManagementServiceUtils,
    DataManagementService
  ],
  exports: [
    ApproFuelSettingsComponent
  ]
})
export class ApproFuelSettingsModule { }
