import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { imagesCarsDir } from 'src/app/global.config';
import { DataManagementService } from '../../data-management.service';
import { Vehicule } from '../../data-management.model';
import { User } from 'src/app/authentification/signin/credentials';
import { saveAs as importedSaveAs } from "file-saver";
import { ToastrService } from 'ngx-toastr';
import { VehiculeService } from '../../providers/vehicule.service';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
    selector: 'app-vehicule-detail',
    templateUrl: './vehicule-details.component.html',
    styleUrls: ['./vehicule-details.component.css']
})
export class VehiculeDetailComponent implements OnInit {
    


  @ViewChild('confirmDeleteModal', { static: false }) confirmDeleteModal!: ModalDirective;

  @Input() selectedVehicule: any;
  @Output() showDocuments: EventEmitter<{ imgUrl: string, imgType: string, all: boolean, documents: Map<string, string | null> }> = new EventEmitter<{ imgUrl: string, imgType: string, all: boolean, documents: Map<string, string | null> }>();
  documents: Map<string, string | null> = new Map<string, string | null>();
  documentEntries: [string, string | null][] = [];
  url: string = imagesCarsDir;
  refreshFiles: Date = new Date();
  public currentUser = new User();
  loader = false;
  loading: boolean;

  listPassation: any[] = [];
  globalLoading: boolean = false;


  constructor(
    private toastr: ToastrService,
    private vehiculeService: VehiculeService,
    public dataManagementService: DataManagementService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

  }

  ngOnInit() {
    let conducteur = (this.selectedVehicule.driver && this.selectedVehicule.driver.avatarUri) ? (this.selectedVehicule.driver.avatarUri + "?" + this.refreshFiles) : (this.url + 'sign-add-icon.png');
    this.documents.set('Car Image', 'assets/icons/carImg2.png');
    this.documents.set('Conducteur', conducteur);

    // let assurance = (this.selectedVehicule.avatarUri) ? (this.selectedVehicule.avatarUri + "?" + this.refreshFiles) : (this.url + 'sign-add-icon.png');
    // let carte_grise = (this.selectedVehicule.grayCardUri) ? (this.selectedVehicule.grayCardUri + "?" + this.refreshFiles) : (this.url + 'sign-add-icon.png');
    // let passassion1 = (this.selectedVehicule.contractingUri)? (this.selectedVehicule.contractingUri + "?" + this.refreshFiles) : (this.url + 'sign-add-icon.png');
    // let passassion2 = (this.selectedVehicule.secondContractingUri) ? (this.selectedVehicule.secondContractingUri + "?" + this.refreshFiles) : (this.url + 'sign-add-icon.png');
    // let engagement = (this.selectedVehicule.thirdContractingUri) ? (this.selectedVehicule.thirdContractingUri + "?" + this.refreshFiles) : (this.url + 'sign-add-icon.png');


    // this.documents.set( 'Assurance', assurance);
    // this.documents.set('Carte Grise', carte_grise);
    // this.documents.set('Passation 1', passassion1);
    // this.documents.set( 'Passation 2', passassion2 );
    // this.documents.set('Engagement', engagement);

    this.documents = this.refactoreFiles(this.selectedVehicule.documents, this.documents);

    this.documentEntries = Array.from(this.documents.entries());
    this.getListPassation();
  }

  showDocument(name: string) {
    this.showDocuments.emit({ imgUrl: this.documents.get(name), imgType: name, all: true, documents: this.documents });
  }

  showDoc(name: string) {
    this.showDocuments.emit({ imgUrl: this.documents.get(name), imgType: name, all: false, documents: this.documents });
  }

  getListPassation() {
    this.vehiculeService.getPassationOfVehicule(this.selectedVehicule.idVehicule).subscribe(item => {
      this.listPassation = item;
      console.log(this.listPassation)

    })
  }

  exportPassationPdf(passation: any) {
    console.log(passation.immatriculation)
    passation.loader = true;
    var filename = passation.datePassation;
    this.loader = true;
    this.vehiculeService.exportPassationPDF(passation.id, this.currentUser.avatartUri).subscribe(blob => {
      if (blob.size != 0)
        importedSaveAs(blob, `PASSATION_${filename}.pdf`);
      passation.loader = false;
    }, () => {
      this.toastr.error('aucune file pdf !', 'Error');
      passation.loader = false;
    });
  }

  private refactoreFiles(documents: Map<string, string>, document: Map<string, string>): Map<string, string> {
    let index = 3;
    let indexVehicule = 1;
    Object.entries(documents).forEach(([key, value]) => {
      let documentUrl = value ? (value + "?" + this.refreshFiles) : (this.url + 'sign-add-icon.png');

      switch (key) {
        case 'avatar':
          this.documents.set('Assurance', documentUrl);
          break;
        case 'grayCard':
          this.documents.set('Carte Grise', documentUrl);
          break;
        case 'permis':
          this.documents.set('Permis', documentUrl);
          break;
        default:
          if (key.startsWith('contracting_')) {
            let contractNumber = key.split('_')[1]; // Assuming the format is always 'contracting_X'
            if (contractNumber === "3") {
              this.documents.set(`Engagement`, documentUrl);
            } else {
              this.documents.set(`Passasion ${contractNumber}`, documentUrl);
            }
          } else if (key.startsWith('passasion_')) {
            this.documents.set(`Passasion ${index++}`, documentUrl);

          } else if (key.startsWith('vehicule_')) {
            this.documents.set(`Vehicule  ${indexVehicule++}`, documentUrl);

          }
          break;
      }
    });


    const sortedArray = Array.from(document.entries());

    sortedArray.sort((a, b) => a[0].localeCompare(b[0]));

    const sortedMap = new Map<string, string>(sortedArray);

    return sortedMap;
  }

  passationselect: any;
  confirmDeleteVehicule(passation: any) {
    this.passationselect = passation;
    console.log(this.passationselect)
    this.confirmDeleteModal.show();
  }


  supristionPassationPdf() {
    this.loading = true;
    this.globalLoading = true;
    this.confirmDeleteModal.hide();
    this.vehiculeService.supprimerPassation(this.passationselect.id).subscribe(item => {
      console.log("error : ", item)
      this.getListPassation()
      this.loading = false;
      this.globalLoading = false;
    })

  }

}
