import { Injectable } from '@angular/core';
import { GroupDashbordDto, RunningDocumentDashbordDto } from 'src/app/client-management/parc-management';
import { Observable } from 'rxjs';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { DateInterval } from 'src/app/shared/model';
import { dns } from 'src/app/global.config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RunningDocumentChartService {

  constructor(private _http: HttpClient) { }
  
  /** Return dashbord info of all the groups and vehicules  */
  public getRunningDocumentInfo(
    date : DateInterval, 
    groupIds: string,
    IsByParent : boolean
    ): Observable<GroupDashbordDto[]> {
  let headers = createAuthorizationHeader();
  let options = { headers: headers };

  return this._http
    .post<GroupDashbordDto[]>(dns + 'groupes/getAllExpensesByGroup?type=INSURANCE&IsByParent='+IsByParent+'&groupIds='+groupIds , date, options);
}

getRunningDocumentsBygroupe(idGroupe: number, date : DateInterval, IsByParent : boolean): Observable<RunningDocumentDashbordDto[]> {
  let headers = createAuthorizationHeader();
  let options = { headers: headers };
  return this._http.post<RunningDocumentDashbordDto[]>(dns + 'runningDocuments/groupe/' + idGroupe + '?type=INSURANCE&IsByParent='+IsByParent, date, options);
}

}
