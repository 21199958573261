<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<form #geoForm="ngForm">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Label</label>
                <input class="form-control" type="text" name="label" [(ngModel)]="geoMarketing.label" required autocomplete="off"/>
            </div>
        </div>

        <div class="col-md-6">
            <label class="control-label">Veuillez choisir les zones</label>

            <select *ngIf="items.length > 0" class="form-control" (change)="onChange($event.target.value)">
                <option [value]="null" style="font-weight: bold;">Choisir une zone</option>
                <option style="font-size: 14px;" [value]="items[i].id" *ngFor="let g of items; let i = index">{{g.name}}</option>
            </select>

        </div>
    </div>
    <div class="row">

        <div class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-bordered table-striped mb-0">
                <thead>
                  <tr>
                    <th class="col-md-2">#</th>
                    <th>Libellé</th>
                    <th class="col-md-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let t of selectedPoi; let i = index">
                      <td> {{i+1}}</td>
                    <td>{{t.name}}</td>
                    <td>
                        <button class="btn btn-sm btn-default" style="color: red;" title="Supprimer" type="button" (click)="onDelete(selectedPoi,i)">
                            <i aria-hidden="true" class="fa fa-trash-o"></i>
                        </button>
                    </td>
                  </tr>
                  <tr *ngIf="selectedPoi.length <= 0">
                      <td colspan="3">
                    <div class="alert alert-warning alert-dismissible" style="border-radius: 0px;text-align: center">
                        <button aria-hidden="true" class="close" data-dismiss="alert" type="button">
                            ×
                        </button>
                        <h4 style="color: white">
                            <i class="fa fa-info-circle"></i> Information
                        </h4>
                        Veuillez ajouter une zone
                    </div>
                </td>
                  </tr>
                </tbody>
              </table>
              
         </div>
    </div>

    <div class="box-footer">
        <button class="btn btn-info pull-right" 
            [disabled]="!geoForm.form.valid || (selectedPoi.length < 1)"
            (click)="onSaveGeoMarketing()" *ngIf="mode == 'ADD'">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
        </button>
        <button class="btn btn-info pull-right" 
            [disabled]="!geoForm.form.valid || (selectedPoi.length < 1)"
            (click)="onUpdate()"*ngIf="mode == 'UPDATE'">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
        </button>
    </div>
</form>