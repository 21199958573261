import { Component, Input, OnInit } from '@angular/core';
import { FuelingClassification } from 'src/app/client-management/parc-management';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);

@Component({
  selector: 'app-classification-fueling-chart',
  templateUrl: './classification-fueling-chart.component.html',
  styleUrls: ['./classification-fueling-chart.component.css']
})
export class ClassificationFuelingChartComponent implements OnInit {

  classments: FuelingClassification[] = [];
  classmentObject: Object | null = null;

  @Input()
  set fuelings(fuelings: FuelingClassification[]) {
    if (fuelings) {
      this.classments = Object.assign(fuelings);
      this.fuelingChart();
    }
  }
  constructor(private chartConfig: ChartConfigService) { }

  ngOnInit() {
    this.fuelingChart();
  }

  fuelingChart() {
    this.chartConfig.setHighCartsLang();
    var data: { name: string, y: number }[] = [];

    this.classments.forEach(fl => {
      data.push({ name: fl.matricule, y: fl.consommation });
    })
    this.classmentObject = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Carburant',
        style: {
          fontWeight: 'bold'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',

        labels: {
          rotation: -45,
          style: {
            color: "#000000",
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: '%'
        },
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          borderRadius: 5
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:.2f} '
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name} </span> <br><b>{point.y:.2f} %</b> <br/>'
      },
      series: [
        {
          name: "Matricule",
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.y:.2f}'
          }, tooltip: {
            pointFormat: '<span style="color:{point.color}">{point.name} </span> <br><b>{point.y:.2f} %</b> <br/>'
          },
          data: data
        }
      ]
    }
    Highcharts.chart('fuelingClassmentObject', this.classmentObject);
  }

}
