import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // If you are using forms
import { CimentFormComponent } from './ciment-form.component';

@NgModule({
  declarations: [
    CimentFormComponent // Declare the CimentFormComponent
  ],
  imports: [
    CommonModule, // Provides common directives like *ngIf, *ngFor
    FormsModule // Import form module if forms are used in the component
  ],
  exports: [
    CimentFormComponent // Export the component if needed elsewhere
  ]
})
export class CimentFormModule { }
