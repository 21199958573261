import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import {Group, Vehicule} from '../model/index';
import {dns} from '../../../global.config';
import {createAuthorizationHeader} from '../../../utils/security/headers';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ParentService {
    public idVehicule = 0;
  groupeName: string | null = null;
  matricule: string | null = null;


  constructor(private _http: HttpClient) {
    }

    // getGroups(): Observable<Group[]> {
    //     let headers = createAuthorizationHeader();
    //     return this._http.get<Group[]>(dns + 'groupes/minifyGroups', {headers: headers});
    // }
    //
    // getVehicules(idGroup: number): Observable<Vehicule[]> {
    //     let headers = createAuthorizationHeader();
    //     return this._http.get<Vehicule[]>(dns + 'groupes/vehicules/' + idGroup, {headers: headers});
    // }
}
