import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartMultipleComponent } from './chart-multiple.component';

// Import other Angular modules if required

@NgModule({
  declarations: [
    ChartMultipleComponent  // Declare the component here
  ],
  imports: [
    CommonModule  // Import necessary Angular modules here
  ],
  exports: [
    ChartMultipleComponent  // Export the component to be used in other modules if needed
  ]
})
export class ChartMultipleModule { }
