import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ValidatorSettingsService } from './validator-settings.service';
import { ValidatorDto } from './validators';

@Component({
  selector: 'app-validator-settings',
  templateUrl: './validator-settings.component.html',
  styleUrls: ['./validator-settings.component.css']
})
export class ValidatorSettingsComponent implements OnInit {


  // local vars
  loading: boolean;
  globalLoading: boolean = false;
  validator: ValidatorDto = new ValidatorDto();
  @ViewChild('deleteModal', { static: false })
  deleteModal: ModalDirective;



  constructor(public toastr: ToastrService, private validatorService: ValidatorSettingsService) {
  }


  ngOnInit() {
    this.loadValidators();
  }

  loadValidators() {
    if (this.validatorService.validators.length <= 0) {
      this.loading = true;
      this.validatorService.findAllValidators().subscribe(validators => {
        this.validatorService.validators = validators;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Validateur");
      })
    }

  }

  confirmDeleteValidator(validator: ValidatorDto) {
    this.validator = validator;
    this.deleteModal.show();
  }

  onDelete() {
    this.loading = true;
    this.validatorService.deleteValidator(this.validator.idValidator).subscribe(deleted => {
      this.deleteModal.hide();
      this.loading = false;
      if (deleted) {
        this.validatorService.validators = this.validatorService.validators.filter(validator => validator.idValidator !== this.validator.idValidator);
        this.toastr.success('Type bien supprimer !', 'info');
      } else {
        this.toastr.error("Erreur lors de suppression", "Validateur");
      }
    }, err => {
      this.loading = false;
      this.toastr.error("Erreur lors de suppression", "Validateur");
    }, () => {
      this.loading = false;
    })
  }
}
