import { Component, OnInit } from "@angular/core";
import { DashbordService } from "../providers/dashbord-service";
import { DashbordInfo } from "../model/index";
import { Subscription } from "rxjs/Subscription";
import { Group, Vehicule } from "../../data-management/data-management.model";
import { DataManagementService } from "../../data-management/data-management.service";
import { ToastrService } from "ngx-toastr";
import { DateInterval } from "../../../shared/model";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ChartConfigService } from "src/app/utils/chart/chart-config.service";
import Highcharts from "highcharts";
import Highcharts3D from 'highcharts/highcharts-3d';

Highcharts3D(Highcharts);
@Component({
  selector: "app-dashbord",
  templateUrl: "./dashbord.component.html",
  styleUrls: ["./dashbord.component.css"]
})
export class DashbordComponent implements OnInit {
  dashbordInfo: DashbordInfo ;
  dashbordInfoTotal: number = 0;
  options: any;
  date: DateInterval = new DateInterval();
  endDate: Date = new Date();
  startDate: Date = new Date();
  maxDate: Date = new Date();
  loading: boolean = false;
  md: number;
  public selectedVehicule: Vehicule;
  public selectedGroup: Group;
  public getVehiculeWasSelectedSubscription: Subscription;
  public getGroupWasSelectedSubscription: Subscription;
  public idGroup: number = -1;
  public idVehicule: number = -1;
  public currentMode = "0";
  public dahsboardTitle: string;

  constructor(
    private dashbordService: DashbordService,
    public dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private localeService: BsLocaleService,
    private chartConfig: ChartConfigService
  ) { }

  ngOnInit() {
    this.localeService.use('fr');

    this.startDate = new Date(this.endDate.getFullYear(), 0, 1);
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);

    /** VEHICULE SELECTED */
    if (this.dataManagementService.selectedVehicule) {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
      this.idVehicule = this.selectedVehicule.idVehicule;
      this.getInfoVehiculeDashbord();
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe((vehicule: Vehicule) => {
        this.selectedVehicule = vehicule;
        this.idVehicule = this.selectedVehicule.idVehicule;
        this.getInfoVehiculeDashbord();
      });

    /** GROUP SELECTED */
    if (this.dataManagementService.selectedGroup) {
      this.selectedGroup = this.dataManagementService.selectedGroup;
    }

    this.getGroupWasSelectedSubscription = this.dataManagementService
      .getGroupWasSelected()
      .subscribe((group: Group) => {
        this.selectedGroup = group;
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription)
      this.getVehiculeWasSelectedSubscription.unsubscribe();
  }

  ModeChange(mode: string) {
    switch (mode) {
      case "0": // mode vehicule
        this.idVehicule = this.selectedVehicule!.idVehicule;
        this.idGroup = -1;
        break;
      case "1": // mode grp
        this.idVehicule = -1;
        this.idGroup = this.selectedGroup.idGroupe;
        break;
      case "2": // mode vehicule & grp
        this.idVehicule = this.selectedVehicule!.idVehicule;
        this.idGroup = this.selectedGroup.idGroupe;
        break;
      case "3": // mode parc
        this.idVehicule = -1;
        this.idGroup = -1;
        break;
    }
    this.md = parseInt(mode, 10);
  }


  getInfoVehiculeDashbord() {
    if (this.md == 0) {
      this.idVehicule = this.selectedVehicule!.idVehicule;
      this.idGroup = -1;
    }
    if (this.md == 1) {
      this.idVehicule = -1;
      this.idGroup = this.selectedGroup.idGroupe;
    }
    if (this.md == 2) {
      this.idVehicule = this.selectedVehicule!.idVehicule;
      this.idGroup = this.selectedGroup.idGroupe;
    }
    if (this.md == 3) {
      this.idVehicule = -1;
      this.idGroup = -1;
    }

    this.loading = true;
    this.date.startDate = this.startDate.getTime();
    this.date.endDate = this.endDate.getTime();
    this.dashbordService
      .getDashbordInfo(this.idGroup, this.idVehicule, this.date)
      .subscribe(
        response => {
          this.dashbordInfo = response;
          this.loading = false;
          if (this.dashbordInfo.total > 0) {
            this.dashbordInfoTotal = this.dashbordInfo.total;
            this.configHighcharts();
          }
          else {
            this.toastr.warning(
              "pas de dépense pour cette période",
              "Attention"
            );
          }
        },
        () => {
          this.loading = false;
        }
      );
  }

  initDashbboardTitle() {
    if (this.currentMode == "0") {
      this.dahsboardTitle =
        "<div style=\"font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;\">Coûts de gestion du véhicule : " +
        this.selectedVehicule.matricule +
        "</div>";
    }

    if (this.currentMode == "1") {
      this.dahsboardTitle =
        "<div style=\"font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;\">Coûts de gestion du groupe :" +
        this.selectedGroup.nom +
        " </div>";
    }

    if (this.currentMode == "2") {
      this.dahsboardTitle =
        "<div style=\"font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;\">Coûts de gestion du véhicule : " +
        this.selectedVehicule.matricule + " et  du groupe : " + this.selectedGroup.nom + "</div>";
    }

    if (this.currentMode == "3") {
      this.dahsboardTitle =
        "<div style=\"font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;\">Coûts de gestion de tout le parc </div>";
    }
  }

  configHighcharts() {
    var that = this;
    this.initDashbboardTitle();
    this.options = {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        }
      },
      title: {
        text: this.dahsboardTitle
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
      },
      plotOptions: {
        pie: {
          point: {
            events: {
              // used to cut fuel cost on select
              select: function (e: any) {
                if (e.target.index == 8 && !e.target.selected && e.target.sliced) {
                  that.dashbordInfo.total = that.dashbordInfoTotal - that.dashbordInfo.fuel;
                }
              },
              // used to increase fuel cost on unSelect (only if the user already selected fuel point)
              unselect: function (e: any) {
                if (e.target.index == 8) {
                  that.dashbordInfo.total = that.dashbordInfoTotal;
                }
              }
            }
          },
          allowPointSelect: true,
          cursor: "pointer",
          depth: 35,
          dataLabels: {
            enabled: true,
            format: "{point.name}"
          }
        }
      },
      series: [
        {
          type: "pie",
          name: "percentage",
          data: [
            [
              "Assurances :" + this.chartConfig.numberWithSpaces(this.dashbordInfo.insurance)+ " DH",
              (this.dashbordInfo.insurance * 100) / this.dashbordInfo.total
            ],
            [
              "Visites :" + this.chartConfig.numberWithSpaces(this.dashbordInfo.visit)+ " DH",
              (this.dashbordInfo.visit * 100) / this.dashbordInfo.total
            ],
            [
              "Vignettes :" + this.chartConfig.numberWithSpaces(this.dashbordInfo.roadTaxes)+ " DH",
              (this.dashbordInfo.roadTaxes * 100) / this.dashbordInfo.total
            ],
            [
              "C.exploitation :" +
              this.chartConfig.numberWithSpaces(this.dashbordInfo.operationalCertification) +
              " DH",
              (this.dashbordInfo.operationalCertification * 100) /
              this.dashbordInfo.total
            ],
            [
              "Permis circulation :" +
              this.chartConfig.numberWithSpaces(this.dashbordInfo.permitCirculation) +
              " DH",
              (this.dashbordInfo.permitCirculation * 100) /
              this.dashbordInfo.total
            ],
            [
              "Carnet métrologique :" +
              this.chartConfig.numberWithSpaces(this.dashbordInfo.metologicalNotbook) +
              " DH",
              (this.dashbordInfo.metologicalNotbook * 100) /
              this.dashbordInfo.total
            ],
            [
              "Extincteurs :" + this.chartConfig.numberWithSpaces(this.dashbordInfo.extincteurs)+ " DH",
              (this.dashbordInfo.extincteurs * 100) / this.dashbordInfo.total
            ],
            [
              "Taxe tonnage :" + this.chartConfig.numberWithSpaces(this.dashbordInfo.tonnageTax)+ " DH",
              (this.dashbordInfo.tonnageTax * 100) / this.dashbordInfo.total
            ],
            [
              "Carburant : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.fuel)+ " DH",
              (this.dashbordInfo.fuel * 100) / this.dashbordInfo.total
            ],
            {
              name: "Vidange : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.oilChange)+ " DH",
              y: (this.dashbordInfo.oilChange * 100) / this.dashbordInfo.total,
              sliced: true,
              selected: true
            },
            [
              "Pneu : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.tireMaintenance)+ " DH",
              (this.dashbordInfo.tireMaintenance * 100) /
              this.dashbordInfo.total
            ],
            [
              "Batterie : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.batteryMaintenance)+ " DH",
              (this.dashbordInfo.batteryMaintenance * 100) /
              this.dashbordInfo.total
            ],
            [
              "Reparation : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.reparation)+ " DH",
              (this.dashbordInfo.reparation * 100) / this.dashbordInfo.total
            ],
            [
              "Dégâts : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.losses)+ " DH",
              (this.dashbordInfo.losses * 100) / this.dashbordInfo.total
            ],
            [
              "Frein : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.brakeMaintenance)+ " DH",
              (this.dashbordInfo.brakeMaintenance * 100) /
              this.dashbordInfo.total
            ],
            [
              "Location : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.leasing)+ " DH",
              (this.dashbordInfo.leasing * 100) / this.dashbordInfo.total
            ],
            [
              "Parking : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.parking)+ " DH",
              (this.dashbordInfo.parking * 100) / this.dashbordInfo.total
            ],
            [
              "Lavage : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.lavage)+ " DH",
              (this.dashbordInfo.lavage * 100) / this.dashbordInfo.total
            ],
            [
              "Péage : " + this.chartConfig.numberWithSpaces(this.dashbordInfo.tool)+ " DH",
              (this.dashbordInfo.tool * 100) / this.dashbordInfo.total
            ],
            [
              "Charge conducteur :" + this.chartConfig.numberWithSpaces(this.dashbordInfo.driverCharge)+ " DH",
              (this.dashbordInfo.driverCharge * 100) / this.dashbordInfo.total
            ],
            [
              "Chiffre d'affaire :" + this.chartConfig.numberWithSpaces(this.dashbordInfo.turnover)+ " DH",
              (this.dashbordInfo.turnover * 100) / this.dashbordInfo.total
            ]
          ]
        }
      ]
    }; Highcharts.chart('dashbordpieII', this.options);
  }

  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.endDate < this.startDate) {
      this.endDate = null;
    }
  }
}
