import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Include if using forms or ngModel
import { ToastrModule } from 'ngx-toastr'; // Include ToastrModule if using ToastrService
import { ChargementSettingsComponent } from './chargement-settings.component'; // Adjust the path as necessary
import { NgxLoadingModule } from 'ngx-loading';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    ChargementSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule, // Include if using forms or ngModel
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    EmailManagerModule,
    CronSettingsModule,
    MatSlideToggleModule
  ],
  exports: [
    ChargementSettingsComponent // Export ChargementSettingsComponent if needed in other modules
  ]
})
export class ChargementSettingsModule { }
