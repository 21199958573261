import { Component, OnInit, HostListener } from '@angular/core';
import { imagesDir, owner } from '../global.config';
import { SigninService } from '../authentification/signin/signin.service';
import { User } from '../authentification/signin/credentials';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.css']
})
export class LeftSidebarComponent implements OnInit {
  avatar: string;
  owner: string;
  menaraIcon: string;
  public currentUser: User | null = null;

  constructor(public signinService: SigninService) {
    this.avatar = imagesDir + 'rim.png';
    this.owner = owner;
    this.menaraIcon = imagesDir + 'menara-icon.png';
  }

  ngOnInit(): void {
    /** add logo (Menara only) */
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      this.currentUser = JSON.parse(storedUser);
    }

    if (this.currentUser?.username?.includes('Menara')) {
      this.avatar = imagesDir + 'menara.png';
    }
  }

  showGPItem(): boolean {
    return (
      this.signinService.isRootAdmin() ||
      this.signinService.isHasRole('ROLE_PM') ||
      this.signinService.isHasRole('ROLE_PM_CD') ||
      this.signinService.isHasRole('ROLE_PM_DASHBOARD') ||
      this.signinService.isHasRole('ROLE_PM_FUEL') ||
      this.signinService.isHasRole('ROLE_PM_LEASING') ||
      this.signinService.isHasRole('ROLE_PM_LOSSES') ||
      this.signinService.isHasRole('ROLE_PM_MAINTENANCE') ||
      this.signinService.isHasRole('ROLE_PM_VARIOUS') ||
      this.signinService.isHasRole('ROLE_PM_VIDANGE')
    );
  }

  showCHPItem(): boolean {
    return (
      Boolean(this.signinService.isHasChargePfb()) ||
      Boolean(this.signinService.isHasRole('ROLE_CHARGE_PFB')) ||
      Boolean(this.signinService.isHasRole('ROLE_CHARGE_PFB_PASSAGE')) ||
      Boolean(this.signinService.isHasRole('ROLE_CHARGE_PFB_TRANSPORT_TYPE')) ||
      Boolean(this.signinService.isHasRole('ROLE_CHARGE_PFB_VALIDATOR')) ||
      Boolean(this.signinService.isHasRole('ROLE_CHARGE_PFB_ORDONNENCEMENT')) ||
      Boolean(this.signinService.isHasRole('ROLE_CHARGE_PFB_AFFECTATION')) ||
      Boolean(this.signinService.isHasRole('ROLE_CHARGE_PFB_TRACKING')) ||
      Boolean(this.signinService.isHasRole('ROLE_CHARGE_PFB_TRACKING_HISTORY'))
    );
  }


  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  changeStyle(event: MouseEvent): void {
    if (event.type === 'mouseenter') {
      document.body.classList.remove("sidebar-collapse");
      document.body.classList.remove("content-wrapper.show");
    } else if (event.type === 'mouseleave') {
      document.body.classList.add("sidebar-collapse");
      document.body.classList.add("content-wrapper.show");
    }
  }
}
