import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeasingComponent } from './leasing.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for bootstrap modals
import { FormsModule } from '@angular/forms'; // Import FormsModule to handle forms
import { DataManagementService } from '../../data-management/data-management.service';
import { ImportExportService } from '../../import-export/import-export.service';
import { DocumentCirculationService } from '../providers/document-circulation-service';
import { ImportExportFormsModule } from '../import-export-forms/import-export-forms.module';
import { DocumentFormComponent } from '../document-circulation/document-form/document-form.component';
import { DocumentFormModule } from '../document-circulation/document-form/document-form.module';

@NgModule({
  declarations: [
    LeasingComponent,
  ],
  imports: [
    CommonModule, // Import CommonModule for basic Angular directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    ModalModule.forRoot(), // Import ModalModule to manage modals
    FormsModule,
    ImportExportFormsModule,
    DocumentFormModule

  ],
  providers: [
    DataManagementService, // Provide DataManagementService
    ImportExportService, // Provide ImportExportService
    DocumentCirculationService // Provide DocumentCirculationService
  ],
  exports: [
    LeasingComponent // Export LeasingComponent to use in other modules
  ]
})
export class LeasingModule { }
