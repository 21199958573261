import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // For two-way data binding and form controls
import { HistorySuiviChargeComponent } from './history-suivi-charge.component';
import { ToastrModule } from 'ngx-toastr'; // For toast notifications
import { AffectationChargeService } from '../affectation-charge/affectation-charge.service'; // Service for charge affectations
import { MaintenanceChargeComponent } from '../suivi-charge/maintenance-charge/maintenance-charge.component';
import { FuelingChargeComponent } from '../../charges-pfb/charges-pfb-tracking/fueling-charge/fueling-charge.component';
import { LocationCostComponent } from '../../charges-pfb/charges-pfb-tracking/location-cost/location-cost.component';
import { TurnoverAchievedComponent } from '../../charges-pfb/charges-pfb-tracking/turnover-achieved/turnover-achieved.component';
import { MaintenanceChargeModule } from '../suivi-charge/maintenance-charge/maintenance-charge.module';
import { SuiviChargeComponent } from '../suivi-charge/suivi-charge.component';
import { TurnoverAchievedModule } from '../../charges-pfb/charges-pfb-tracking/turnover-achieved/turnover-achieved.module';
import { LocationCostModule } from '../../charges-pfb/charges-pfb-tracking/location-cost/location-cost.module';
import { FuelingChargeModule } from '../../charges-pfb/charges-pfb-tracking/fueling-charge/fueling-charge.module';
import { SuiviChargeModule } from '../suivi-charge/suivi-charge.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CorrelationLocationModule } from '../../charges-pfb/charges-pfb-tracking/correlation-location/correlation-location.module';
import { CorrelationGasoilModule } from '../../charges-pfb/charges-pfb-tracking/correlation-gasoil/correlation-gasoil.module';
import { CorrelationMaintenanceModule } from '../suivi-charge/correlation-maintenance/correlation-maintenance.module';
@NgModule({
  declarations: [
    HistorySuiviChargeComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MaintenanceChargeModule,
    TurnoverAchievedModule,
    SuiviChargeModule,
    CorrelationLocationModule,
    CorrelationGasoilModule,
    CorrelationMaintenanceModule,
    TurnoverAchievedModule,
    LocationCostModule,
    FuelingChargeModule

  ],
  providers: [
    AffectationChargeService  // Provide AffectationChargeService for handling charge affectations
  ],
  exports: [
    HistorySuiviChargeComponent  // Export the component so it can be used in other modules
  ]
})
export class HistorySuiviChargeModule { }
