<div class="row">
    <div class="col-lg-12">
        <div class="box-header page-header with-border mb-0">

            <!-- =================================== TITLE (START)==================================== -->
            <!-- <h3 class="box-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;"><i class="fa fa-list"></i>

                <span style="text-decoration: underline">

                      <span>Liste des carburants du véhicule :</span>
                      <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher la véhicule" [routerLink]="['/client/dataManagement/vehicule/form', selectedVehicule.idVehicule, 'SHOW']">{{selectedVehicule.matricule}} <span *ngIf="currentCarbSetting.fuelAverage">(CT={{currentCarbSetting?.fuelAverage}})</span></a>

                      <span *ngIf="selectedVehicule.driver"> - conducteur :
                            <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher le conducteur" [routerLink]="['/client/dataManagement/driver/form', selectedVehicule.driver.idDriver, 'SHOW']">{{selectedVehicule?.driver?.firstName}} {{selectedVehicule?.driver?.lastName}}</a>
                      </span>

                </span>


                <span *ngIf="!selectedVehicule.driver" data-toggle="tooltip" class="badge label-warning">
                      <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                </span>

            </h3> -->
            <!-- =================================== TITLE (END)============================={{matricule}}======= -->

            <!-- =================================== OPTIONS (START)==================================== -->

            <button type="button" class="btn btn-success btn-sm " title="Ajouter"
            (click)="addFuel()" ><i class="fa fa-plus-circle"></i> </button>

            <div *ngIf="fuelsId.length > 0" class="option-delete">
                <span title="Supprimer tout" (click)="onConfirmDeleteMultiple()">
                    <i class="fa fa-minus-circle"></i> Supprimer tout</span>
            </div>
            <!-- =================================== OPTIONS (END)==================================== -->
            <!--==================================== import/Export functionality(START)=============== -->
            <div style="display: inline-block; margin-left: 5px;">

                <div class="option-export">
                <button type="button" class="btn btn-primary btn-sm" title="Exporter sous format excel"
                (click)='export()' >Exporter <i class="fa fa-download"></i></button>

                <button type="button" class="btn btn-primary btn-sm  " title="Importer sous format excel"
                (click)='importModal.show()' >Importer <i class="fa fa-upload"></i> </button>
                </div>

            </div>
            <!--==================================== import/Export functionality(END)================= -->
            <!--==================================== Search(START)=============== -->
            <div class="pull-right">
                <div class="inputWithIcon">
                    <input [(ngModel)]="searchTerm" name="searchTerm" type="text" class="form-control input-sm"
                        placeholder="Fournisseur...">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <!--==================================== Search(END)================= -->
        </div>


        <div class="option-dashbord">
            <span title="Afficher le graphe" (click)="showDashBord()"><i class="fa fa-line-chart"></i> Afficher le graphe</span>
            <span *ngIf="currentCarbSetting.fuelAverage">(CT={{currentCarbSetting?.fuelAverage}})</span>
        </div>

    </div>

    <div class="col-lg-12">

        <div class="content">
            <div class="table-responsive tableFixHead">
                <table class="table table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 2%; text-align: left;"><input type="checkbox" [disabled]="fuels.length == 0"
                                    [checked]="fuels.length == fuelsId.length" (click)="onCheckedAll()"></th>
                            <th style="width:5%">Date</th>
                            <th style="width:5%">Quantité</th>
                            <th style="width:5%">Coût Total</th>
                            <th style="width:5%">Coût/L</th>
                            <th style="width:5%">Odomètre</th>
                            <th style="width:5%">Fournisseur</th>
                            <th style="width:5%">Type Paiement</th>
                            <th style="width:5%">N° Paiement</th>
                            <th style="width:5%">Carte</th>
                            <th style="width:5%">Sign</th>
                            <th style="width:5%">Endroit</th>
                            <th style="width:5%">C.réel</th>
                            <th style="width:5%">Q.Théorique(L)</th>
                            <th style="width:5%">Coût.T</th>
                            <th style="width:5%">Ecart cons.</th>
                            <th style="width:5%">Référence</th>
                            <th style="width:33%">Observations</th>
                            <th style="width:5%"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngIf="loading">
                            <td colspan="16" style="text-align: center">
                                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                            </td>
                        </tr>

                        <tr *ngIf="!loading && fuels.length == 0">
                            <td colspan="18">

                                <div class="alert alert-dismissible" style="border-radius: 0px;text-align: center;">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                        ×
                                    </button>
                                    <h4 style="color: orange">
                                        <i class="fa fa-info-circle"></i> Information
                                    </h4>
                                    Veuillez ajouter des informations pour carburants.
                                </div>

                            </td>
                        </tr>
                        <tr *ngFor="let fuel of getFuels(); let i = index">
                            <td>
                                <input type="checkbox" [checked]="isCheckedInList(fuel.id)" (click)="onChecked(fuel.id)">
                            </td>
                            <td style="font-weight: bold">{{fuel?.operationTime | date: 'dd-MM-yyyy, HH:mm'}}</td>
                            <td>{{fuel?.quantity}}</td>
                            <td>{{fuel?.cost | number: '.0-4'}}</td>
                            <td>{{getCostUnit(fuel) | number: '.0-2'}}</td>
                            <td>{{fuel?.odometre}}</td>
                            <td>{{fuel?.provider?.name}}</td>
                            <td>{{fuel?.paymentType?.name}}</td>
                            <td>{{fuel?.numberPayment}}</td>
                            <td>{{fuel?.numCartFuel}}</td>
                            <td>{{fuel?.signification}}</td>
                            <td>{{fuel?.place}}</td>
                            <td>
                                {{getFuelConsumption(fuel, i) | number: '.0-2'}}
                          </td>
                          <td>
                           <span *ngIf="fuel.signification.toLowerCase() != 'h'"> {{(currentCarbSetting?.fuelAverage / getFuelConsumption(fuel, i)) * fuel?.quantity | number: '.0-2'}} </span>
                        </td>
                          <td>
                            <span *ngIf="fuel.signification.toLowerCase() != 'h'">{{((currentCarbSetting?.fuelAverage / getFuelConsumption(fuel, i)) * fuel?.quantity) * getCostUnit(fuel) | number: '.0-2'}} </span>
                        </td>
                            <!-- <td><span *ngIf="fuel.consumption">{{fuel?.consumption}}%</span></td> -->
                            <td  [ngStyle]="{'color':((getFuelConsumption(fuel, i) - currentCarbSetting?.fuelAverage) >= 2 || (getFuelConsumption(fuel, i) - currentCarbSetting?.fuelAverage) < 0) ? 'red' : (getFuelConsumption(fuel, i) - currentCarbSetting?.fuelAverage) > 0 ? 'orange' : 'black',
                                    'font-weight': (getFuelConsumption(fuel, i) - currentCarbSetting?.fuelAverage) == 0 ? 'normal' : 'bold'}">
                               <span *ngIf="getFuelConsumption(fuel, i) && currentCarbSetting?.fuelAverage">{{getFuelConsumption(fuel, i) - currentCarbSetting?.fuelAverage | number: '.0-2'}}</span>
                            </td>

                            <td>{{fuel?.reference}}</td>
                            <td>{{fuel?.observation}}</td>
                            <td>
                                <button (click)="onConfirmDelete(fuel)" title="Supprimer" style="color: red;" type="button"
                                    class="btn btn-sm btn-default">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                                <button title="Modifier" style="color: rgb(2, 157, 196); " type="button" class="btn btn-sm btn-default"
                                    (click)="updateFuel(fuel)">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>


<!-- ========================================== ADD/Edit Modal (START) ====================================================-->
<div class="modal fade" bsModal #addEditFuelModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- ========================== Modal TITLE (START) ===============================-->
            <div class="modal-header">

                <button type="button" class="close" (click)="addEditFuelModal.hide()">&times;</button>

                <h4 *ngIf="mode == 'ADD'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-plus"></i>
                    Ajouter informations carburant</h4>

                <h4 *ngIf="mode == 'UPDATE'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-edit"></i>
                    Modifier informations carburant</h4>
            </div>
            <!-- ========================== Modal TITLE (END) ===============================-->

            <div class="modal-body">
                <form #batteryForm="ngForm" class="form-horizontal">
                    <div class="box-body">
                        <app-carburant-form [mode]="mode" [fuel]="currentFuel" [vehicule]="selectedVehicule" [group]="group"
                            (fuelWasAdded)="fuelWasAdded($event)" (fuelWasUpdated)="fuelWasUpdated($event)">
                        </app-carburant-form>
                    </div>
                </form>
            </div>

        </div>

    </div>
</div>
<!-- ========================================== ADD/Edit Modal (END) ====================================================-->

 <!-- ============================== DELETE MODAL (START) ========================== -->
 <div class="modal fade" bsModal #fuelDeleteModal="bs-modal" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" (click)="fuelDeleteModal.hide()">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                        <h4 style="color: white">
                            <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                        <span *ngIf="!isDeleteMultiple">Êtes-vous sûr de supprimer ce carburant ?</span>
                        <span *ngIf="isDeleteMultiple">Êtes-vous sûr de supprimer ces {{fuelsId.length}} carburants
                            ?</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="fuelDeleteModal.hide()">
                        Annuler
                    </button>
                    <button type="button" class="btn btn-success" (click)="onDelete()">
                        <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- =============================== DELETE MODAL (END) ============================= -->

<!--=================================Import fuel(start) ====================================-->
<div class="modal" role="dialog" bsModal #importModal="bs-modal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="importModal.hide()">&times;
                </button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Importer la liste des carburants
                </h4>
            </div>
            <div class="modal-body vertical-spacer">
                <app-import-export-forms [item]='selectedItem' [vehiculeMatricule]='selectedVehicule.matricule'  [groupId]='group.idGroupe'
                    (importedItem)='itemWasImported($event)'></app-import-export-forms>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="importModal.hide()">
                    Fermer
                </button>
            </div>
        </div>
    </div>
</div>
<!--=================================Import fuel (end) ====================================-->


<!-- ========================================== DashBord Modal (START) ====================================================-->
<div class="modal fade" bsModal #dashBordFuelModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- ========================== Modal TITLE (START) ===============================-->
            <div class="modal-header">

                <button type="button" class="close" (click)="dashBordFuelModal.hide()">&times;</button>

                <h4 class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-line-chart" aria-hidden="true"></i> Rapport graphique des carburants</h4>
            </div>
            <!-- ========================== Modal TITLE (END) ===============================-->

            <div class="modal-body">
                    <div class="box-body">
                        <app-carburant-dashbord [fuels]="fuels" [currentCarbSetting]="currentCarbSetting" [vehicule]="selectedVehicule"></app-carburant-dashbord>
                    </div>
            </div>

            <div class="box-footer">
                    <button (click)="dashBordFuelModal.hide()" class="btn btn-default pull-right">
                        Fermer
                    </button>
            </div>

        </div>

    </div>
</div>
<!-- ========================================== DashBord Modal (END) ====================================================-->
