import {
  Component,
  OnInit,
  ElementRef
} from "@angular/core";
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { Router } from '@angular/router';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { DecimalPipe, DatePipe } from '@angular/common';



@Component({
  selector: 'app-activity-overview',
  templateUrl: './activity-overview.component.html',
  styleUrls: ['./activity-overview.component.css']
})
export class ActivityOverviewComponent implements OnInit {
  
  constructor(
    public router: Router
  ) { 
  }
  ngOnInit() {
  }

}
