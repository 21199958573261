import { Component, OnInit, ViewChild } from '@angular/core';

import * as Highcharts from 'highcharts';
import { Notification } from "../../../../../client-management/alert-management/alert";
import { ModalDirective } from 'ngx-bootstrap/modal';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts)

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
  selector: 'app-chart-exces-vitesse',
  templateUrl: './chart-exces-vitesse.component.html',
  styleUrls: ['./chart-exces-vitesse.component.scss']
})
export class ChartExcesVitesseComponent implements OnInit {

  options: Object | null = null;

  notificationsVitesse: Notification[] = [];
  notifications: Notification[] = [];

  @ViewChild("chartVitesse", { static: false }) chartVitesse: ModalDirective;

  constructor() { }

  ngOnInit() {
    this.getNotification([]);
  }

  getNotification(matricule: any) {
    const storedNotifications = localStorage.getItem('notifications');
    if (storedNotifications) {
      this.notifications = JSON.parse(storedNotifications);  // Pas besoin de gérer 'null' ici car on a déjà vérifié
      this.notificationsVitesse = this.notifications.filter(nt =>
        nt.name == 'SPEED' || nt.name == 'SPEED_CARTO'
      );
      this.notificationsVitesse = this.notificationsVitesse.filter(obj =>
        matricule.has(obj.matricule)
      );
      this.VitesseChart();
    }
  }

  showchartVitesse() {
    this.chartVitesse.show();
  }

  VitesseChart() {

    var today= new Date();
    this.setHighCartsLang();
    /** Create Pie Chart( depense chart ) **/
    var series = [];
    var seriesData = [];
    var drilldownSeries: any[] = [];

    const matriculeCounts = {};
    let max=0;

    this.notificationsVitesse.forEach((item) => {
      const matricule = item.matricule;
      if (matriculeCounts[matricule]) {
        matriculeCounts[matricule]++;
      } else {
        matriculeCounts[matricule] = 1;
      }
      if(max<matriculeCounts[matricule]){
        max=matriculeCounts[matricule];
      }
    });

    seriesData = Object.keys(matriculeCounts).map((matricule) => ({
      name:matricule,
      y: matriculeCounts[matricule],
      drilldown:matricule
    }));

    seriesData.map(s_d=>{
      series=[];
      series=this.notificationsVitesse.filter(nt=>nt.matricule==s_d.name)
      var vehiculesdata: { name: string, y: number, cust: string  }[] = [];
        for (let i = 0; i < series.length; i++) {
            const timestamp = series[i].createdAt; // Milliseconds since epoch
            const date = new Date(timestamp);

            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            // Format hours and minutes to ensure they have leading zeros if needed
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');

            const timeString = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            vehiculesdata.push({name: timeString,  y: parseInt(series[i].alertValue), cust:series[i].alertValue});
      }

      //series.map(s=>vehiculesdata.push([s.createdAt,s.alertValue]));
      drilldownSeries.push({type: "line",threshold: [40, 60, 80, 100],name:s_d.name,id:s_d.name,data: vehiculesdata})
    })

    seriesData.sort((a, b) => {
        if(a.y < b.y)
            return 1;
        else
            return -1
    });

    this.options= {
      chart: {
          type: 'column',
          height:260,
      },
      title: {
          align: 'center',
          //text: 'Excès de vitesse '+today
          text: 'Nombre des Excès de vitesse',
          style:{
            color: '#3c8dbc',
            fontSize: "20px",
          }
      },
      subtitle: {
          align: 'left',
          //text: 'Clicker sur les column pour voir plus details'
      },
      accessibility: {
          announceNewData: {
              enabled: true
          }
      },
      xAxis: {
          type: 'category',
          labels: {
            enabled: true,
          }
      },
      yAxis: {
          title: {
              text: 'Nombre Excès de vitesse / Vehicule'
          }

      },
      legend: {
          enabled: true
      },
      plotOptions: {
          series: {
              borderWidth: 0,
            //   dataLabels: {
            //       enabled: true,
            //       format: '{point.y:1f}',
            //       textDocoration:'none'
            //   }
          }
      },

      tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
      },

      series: [
          {
              name: 'Matricule',
              colorByPoint: true,
              data: seriesData
          }
      ],
      drilldown: {
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b>: {point.y}Km',
        },
        breadcrumbs: {
            position: {
                align: 'right'
            }
        },
        series: drilldownSeries
      }
  }

    Highcharts.chart('chartVitesse', this.options);
    Highcharts.chart('chartVitesseModal', this.options);
  }

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7', '#e77e22', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b',  '#00f194'
    ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

  }

}
