import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { Validation } from './validations';
import { dns } from 'src/app/global.config';

@Injectable()
export class ValidationService {

  constructor(private _http: HttpClient) { }

  add(validation: Validation): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<Boolean>(dns + 'utils/emails/validation', validation, options);
  }
}
