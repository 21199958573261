<div class="content" style="overflow-y: scroll; height:430px;">
    <table class="table table-fixed table-striped table-hover table-condensed">
        <thead class="thread-dark">
            <tr>
                <th style="width:20%">Title</th>
                <th style="width:20%">Emails</th>
                <th style="width:20%">Choix Période</th>
                <th style="width:20%"></th>
            </tr>
        </thead>

        <tr *ngIf="loading ">
            <td style="text-align: center" colspan="14">
                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </td>
        </tr>

        <tr *ngIf="!loading && reportAutos.length == 0">
            <td colspan="14">

                <div class="alert alert-warning alert-dismissible" style="border-radius: 0px;text-align: center">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                        ×
                    </button>
                    <h4 style="color: white">
                        <i class="fa fa-info-circle"></i> Information
                    </h4>
                    Veuillez ajouter des demandes.
                </div>

            </td>
        </tr>

        <tbody *ngIf="!loading && reportAutos.length > 0">
            <tr *ngFor="let reportAuto of reportAutos; let i = index ">
                <td style="width: 20%; font-weight: bold">
                    {{reportAuto.title}}
                </td>
                <td style="width: 20%; font-weight: bold">
                    {{reportAuto.emails}}
                </td>
                <td style="width: 20%; font-weight: bold">
                    {{reportAuto.period}}
                </td>

                <td style="width:20%">
                    <div class="pull-right" style="margin-top: 1.5%;">
                        <div class="btn-group">
                            <button (click)="currentReportAuto = reportAuto; updateModal.show()" style="color: orange;"
                                type="button" class="btn btn-default">
                                <i class="fa fa fa-cog" aria-hidden="true"></i>
                            </button>
                            <button (click)="currentReportAuto = reportAuto; deleteModal.show()" style="color: red;"
                                type="button" class="btn  btn-default">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>

                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade"  tabindex="-1" #deleteModal="bs-modal" bsModal role="dialog" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="deleteModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !
                    </h4>
                    Êtes-vous sûr de supprimer cette rapport automatique ?
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="deleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="onDelete()">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->


<!-- ============================== UPDATE MODAL (START) ========================== -->
<div class="modal fade" bsModal #updateModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="updateModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Modifier
                </h4>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label class="col-sm-2 control-label" style="color:limegreen;">Email </label>
                        <div class="col-sm-4">
                            <input [(ngModel)]="currentReportAuto.emails" type="email" name="emails"
                                placeholder="Emails" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <button type="button" class="btn btn-default" (click)="updateModal.hide()">
                            Annuler
                        </button>
                        <button type="button" class="btn btn-success" (click)="onUpdate()">
                            <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
                        </button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
