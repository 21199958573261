import { Component, OnInit, ViewChild } from '@angular/core';
import { Provider } from '../../model/index';
import { Subscription } from 'rxjs/Subscription';
import { ToastrService } from "ngx-toastr";
import { DataManagementService } from "../../../data-management/data-management.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Battery } from '../../model';
import { BatteryService } from '../../providers/battery-service';
import { RapportDto, Vehicule, Group } from '../../../data-management';
import { ImportExportService } from '../../../import-export';

@Component({
  selector: "app-battery",
  templateUrl: "./battery.component.html",
  styleUrls: ["./battery.component.css"]
})
export class BatteryComponent implements OnInit {

  repport: RapportDto = new RapportDto();

  batteries: Battery[] = [];

  batteriesId: number[] = [];

  vehicule: Vehicule = new Vehicule();

  group: Group = new Group();

  getVehiculeWasSelectedSubscription: Subscription;

  getGroupWasSelectedSubscription: Subscription;

  battery: Battery = new Battery();

  mode: 'ADD' | 'UPDATE';

  loading: boolean = false;

  isDeleteMultiple: boolean = false;

  selectedItem = 'BATTERY';

  @ViewChild('batteryModal', { static: false })
  batteryModal: ModalDirective;

  @ViewChild('batteryDeleteModal', { static: false })
  batteryDeleteModal: ModalDirective;

  @ViewChild('importModal', { static: false }) importModal: ModalDirective;

  searchTerm: string | null = null;

  constructor(private batteryService: BatteryService, private dataManagementService: DataManagementService,
    public toastr: ToastrService, public exportImport: ImportExportService) { }

  ngOnInit() {

    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.vehicule = this.dataManagementService.selectedVehicule;
      this.group = this.dataManagementService.selectedGroup;
      this.loadBatteries();
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.vehicule = vehicule;
      this.loadBatteries();
    });

    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.group = group;
    });
  }

  ngOnDestroy() {
    if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
  }

  /**
   * Charger la liste des batteries
   */
  loadBatteries() {
    if(!this.vehicule.idVehicule) return;
    this.loading = true;
    this.batteryService.getListBatteries(this.vehicule.idVehicule).subscribe(batteries => {
      this.batteries = batteries;
      this.loading = false;
    }, error => {
      this.loading = false;
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
    });
  }

  /**
   * Afficher modal de modification du batterie
   */
  onUpdate(battery: Battery) {
    if (!battery.provider) {
      battery.provider = new Provider();
    }
    this.battery = Object.assign({}, battery);
    if (this.battery.operationTime) this.battery.operationTime = new Date(this.battery.operationTime);
    if (this.battery.actualRepairTime) this.battery.actualRepairTime = new Date(this.battery.actualRepairTime);
    this.mode = 'UPDATE';
    this.batteryModal.show();
  }

  /**
   * Afficher modal d'ajout du batterie
   */
  onAdd() {
    this.battery = new Battery();
    this.mode = 'ADD';
    this.batteryModal.show();
  }

  /**
   * Afficher modal de confirmation du suppression
   */
  onConfirmDelete(battery: Battery) {
    this.battery = battery;
    if (!this.battery.provider) {
      this.battery.provider = new Provider();
    }
    this.isDeleteMultiple = false;
    this.batteryDeleteModal.show();
  }

  /**
   * Suppression du batterie
   */
  delete() {
    this.loading = true;
    this.batteryService.delete(this.battery.id).subscribe(response => {

      if (response) {
        this.batteries = this.batteries.filter(battery => battery.id != this.battery.id);
        this.batteriesId = this.batteriesId.filter(batteryId => batteryId != this.battery.id);
        this.toastr.success('Batterie informations bien supprimer !', 'info');
      } else {
        this.toastr.error('Le batterie n\'a pas supprimé correctement !', 'Erreur');
      }

      this.batteryDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }

  /**
   * Suppression multiple du pneu
   */
  deleteMultiple() {
    this.loading = true;
    this.batteryService.deleteMultiple(this.batteriesId).subscribe(response => {

      if (response) {
        for (let batteryId of this.batteriesId) {
          this.batteries = this.batteries.filter(battery => battery.id != batteryId);
        }

        this.batteriesId = [];
        this.toastr.success('Suppression effectuée avec succès.', 'info');
      } else {
        this.toastr.error('La battery n\'a pas supprimé correctement !', 'Erreur');
      }

      this.batteryDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }


  /**
   * Actualiser la liste des batteries apés l'événement d'ajout
   */
  batteryWasAdded(battery: Battery) {
    this.batteryModal.hide();
    this.batteries.unshift(battery);
  }
  /**
   * Actualiser la liste des batteries apés l'événement de modification
   */
  batteryWasUpdated(battery: Battery) {
    this.batteryModal.hide();

    for (let i = 0; i < this.batteries.length; i++) {
      if (this.batteries[i].id == battery.id) {
        this.batteries[i] = battery;
        break;
      }
    }

  }

  export() {
    this.repport.type = 'BATTERY';
    this.exportImport.export(this.batteries, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
  }

  itemWasImported(res: boolean) {
    if(res){
      this.importModal.hide();
      this.loadBatteries();
    }
  }

  /**
   * Event confirm delete multiple
   */
  onConfirmDeleteMultiple() {
    if (this.batteriesId.length == 0) {
      this.toastr.warning("Veuillez choisir des batteries à supprimer !");
      return;
    }
    this.isDeleteMultiple = true;
    this.batteryDeleteModal.show();
  }

  /**
   * Event delete
   */
  onDelete() {
    if (this.isDeleteMultiple) {
      this.deleteMultiple();
    } else {
      this.delete();
    }
  }


  /**
   * get list search
   */
  getBatteries(): Battery[] {
    if ((this.searchTerm) && (this.searchTerm.length > 0)) {
      return this.batteries.filter(battery => (battery.provider) && (battery.provider.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
    } else {
      return this.batteries;
    }
  }


  /**
   * Event check row for delete multiple
   */
  onChecked(id: number) {
    if (this.batteriesId.find(tireId => tireId == id)) {
      this.batteriesId = this.batteriesId.filter(tireId => tireId != id);
    } else {
      this.batteriesId.push(id);
    }
  }

  /**
   * Event check all row for delete multiple
   */
  onCheckedAll() {
    if (this.batteriesId.length == this.batteries.length) {
      this.batteriesId = [];
    } else {
      this.batteriesId = [];
      for (let battery of this.batteries) {
        this.batteriesId.push(battery.id);
      }
    }
  }

  /**
   * check if id exists in array,
   */
  isCheckedInList(id: number): boolean {
    return this.batteriesId.find(batteryId => batteryId == id) ? true : false;
  }
}
