import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform { 

  transform(value: number): string {
    if (!value && value !== 0) {
      return '';
    }

    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);

    let formattedDuration = '';

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
      formattedDuration += `${minutes}min`;
    }

    return formattedDuration.trim();
  }

}