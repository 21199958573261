import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpensesComponent } from './expenses.component';
import { FormsModule } from '@angular/forms'; // Include if using forms in your component
import { ModalModule } from 'ngx-bootstrap/modal'; // Import for ngx-bootstrap modals
import { HighchartsChartModule } from 'highcharts-angular'; // Import for Highcharts
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { MileageService } from '../../historical/mileage/mileage.service'; // Adjust path if needed
//import { DashbordService } from '../../parc-management/dashbord.service'; // Adjust path if needed
import { DataManagementService } from '../../data-management/data-management.service'; // Adjust path if needed
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service'; // Adjust path if needed
import { DepenseChartService } from '../../overview/dashbord-chart/depense-chart/depense-chart.service'; // Adjust path if needed
import { ChargeComponent } from './graph/charge/charge.component';
import { DashbordService } from '../../parc-management';
import { SinistreChartComponent } from '../../overview/dashbord-chart/sinistre-chart/sinistre-chart.component';
import { DepenseChartComponent } from '../../overview/dashbord-chart/depense-chart/depense-chart.component';
import { VidangeChartComponent } from '../../overview/dashbord-chart/vidange-chart/vidange-chart.component';
import { SinistreChartModule } from '../../overview/dashbord-chart/sinistre-chart/sinistre-chart.module';
import { DepenseChartModule } from '../../overview/dashbord-chart/depense-chart/depense-chart.module';
import { VidangeChartModule } from '../../overview/dashbord-chart/vidange-chart/vidange-chart.module';
import { ChargeModule } from './graph/charge/charge.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { NgxLoadingModule } from 'ngx-loading';
import { MonthlyMaintenanceCostsModule } from '../../overview/dashbord-chart/monthly-maintenance-costs/monthly-maintenance-costs.module';
import { RunningDocumentChartModule } from '../../overview/dashbord-chart/running-document-chart/running-document-chart.module';
import { MonthlyAvailableVehiculesChartModule } from '../../overview/dashbord-chart/monthly-available-vehicules-chart/monthly-available-vehicules-chart.module';
import { HistoryCanalGroupVehiculeModule } from './graph/history-canal-group-vehicule/history-canal-group-vehicule.module';
import { FormatNumberModule } from 'src/app/pipes/format-number.module';

@NgModule({
  declarations: [
    ExpensesComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    ToastrModule.forRoot(),
    SinistreChartModule,
    DepenseChartModule,
    VidangeChartModule,
    ChargeModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    MonthlyMaintenanceCostsModule,
    BrowserModule,
    RunningDocumentChartModule,
    MonthlyAvailableVehiculesChartModule,
    HistoryCanalGroupVehiculeModule,
    FormatNumberModule,

  ],
  providers: [
    MileageService,
    DashbordService,
    DataManagementService,
    ChartConfigService,
    DepenseChartService
  ],
  exports: [
    ExpensesComponent
  ]
})
export class ExpensesModule {}
