import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AlertManagementService } from '../../alert-management.service';
import { AlertConfiguration, Alert } from '../../alert';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ignition-settings',
  templateUrl: './ignition-settings.component.html',
  styleUrls: ['./ignition-settings.component.css']
})
export class IgnitionSettingsComponent implements OnInit, OnDestroy {
  public loader = false;

  showAdvancedSettings = false;
  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  stopTimeSelectMode = true;
  minutes: string[] = [];
  stopTime: number | null;

  alert: Alert = new Alert();

  ignitionAlertConfiguration: AlertConfiguration = new AlertConfiguration();
  cronAlertConfiguration: AlertConfiguration = new AlertConfiguration();

  getVehiculeWasSelectedSubscription: Subscription;

  constructor(
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService,
    private dataManagementService: DataManagementService
  ) {
    this.initMinutes();
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        if (this.dataManagementService.selectedVehicule) {
          this.loadAlertConfigOfDevice(
            this.dataManagementService.selectedVehicule.idDevice
          );
        } else {
          // Gérez le cas où selectedVehicule est null
          console.warn('Selected vehicule is null');
        }
      });

  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('INGNITION_V0', device)
      .subscribe(
        alert => {
          this.alert = alert;
          this.cronAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'DATE'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'DATE'
            )[0]
            : this.cronAlertConfiguration;
          this.ignitionAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'INGNITION'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'INGNITION'
            )[0]
            : this.ignitionAlertConfiguration;
          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  chooseStopTime($event: any) {
    if ($event === 'Autre..') {
      this.stopTimeSelectMode = false;
      this.ignitionAlertConfiguration.value1 = '10';
    }
  }

  initMinutes() {
    for (let i = 1; i <= 12; i++) {
      this.minutes.push((i * 10).toString());
    }
    this.minutes.push('Autre..');
  }
  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrer', 'info !');
      },
      error => {
        this.toastr.error('Configurations non enregisrée', 'info !');
        this.loader = false;
      }
    );
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean, enableGlobalConfigToAllVehicules: boolean }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }
  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails, id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }
}
