import { Component, EventEmitter, Host, OnInit, ViewChild } from '@angular/core';
import { jqxSchedulerComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxscheduler';
import { ToastrService } from 'ngx-toastr';
import { DataManagementService } from '../../data-management.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DataManagementComponent } from '../../data-management.component';
import { ProgramService } from '../../providers/program.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Program, TimePlan, Vehicule } from '../../data-management.model';
import { VehiculeService } from '../../providers';

@Component({
  selector: 'app-program-form',
  templateUrl: './program-form.component.html',
  styleUrls: ['./program-form.component.css']
})
export class ProgramFormComponent implements OnInit {

  loading: boolean
  currentProgram: Program = new Program();

  mode: 'ADD' | 'UPDATE';
  @ViewChild('scheduler', { static: false }) scheduler: jqxSchedulerComponent;

  @ViewChild('programModal', { static: false })
  programModal: ModalDirective;

  @ViewChild('addEditModal', { static: false })
  addEditModal: ModalDirective;

  public ProgramLoaded$: EventEmitter<Program[]> = new EventEmitter();

  selectedVehiculesItems: { item_text: string | number; item_id: number }[] = [];

  vehiculesItems: { item_text: string | number; item_id: number }[] = [];

  vehiculesDropdownSettings = {};
  groupDropdownSettings : any;

  selectedVehiculesHash: any = [];
  timeplan: TimePlan = new TimePlan();
  error: { errorMessage: string } = { errorMessage: '' };
  isReadonly = false;

  editRecurringAppointmentOccurrenceElement: HTMLElement;


  constructor(
    public programService: ProgramService,
    private toastr: ToastrService,
    private router: Router,
    private vehiculeService: VehiculeService,
    public dataManagementService: DataManagementService,
    private route: ActivatedRoute,
    public parent: DataManagementComponent) {
    parent.displayProgramIcon();
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
      this.init(params.id);
    });
    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.vehiculesDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  ngAfterViewInit() {
  }

  init(id: any) {
    this.currentProgram = new Program();
    if (this.mode == "ADD") {
      this.loading = true;
      forkJoin(
        this.vehiculeService.getAll()
      ).subscribe(
        ([vehicules]) => {
          this.mapVehiculeToItems(vehicules);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }
    if (this.mode == "UPDATE") {
      this.loading = true;
      forkJoin(
        this.programService.getOneProgram(id),
        this.vehiculeService.getAll()
      ).subscribe(
        ([program, vehicules]) => {
          this.currentProgram = program;
          if (program.timePlans.length > 0) {

            this.source.localData = this.currentProgram.timePlans;
            this.dataAdapter = new jqx.dataAdapter(this.source);
          }
          this.mapVehiculeToItems(vehicules);
          this.mapSelectedVehiculeToItems(program.vehicules);
          this.initSelectedHash(program.vehicules);
          this.loading = false;
        },
        error => {
          this.error = error;

          this.error = JSON.parse(error._body);

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }

          this.loading = false;
        }
      );
    }
  }


  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return '90%';
    }

    return 870;
  }

  date: any = new jqx.date(new Date());

  source: any =
    {
      dataType: 'array',
      dataFields: [
        { name: 'id', type: 'string' },
        { name: 'about', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'background', type: 'string' },
        { name: 'start', type: 'date', format: 'yyyy-MM-dd HH:mm' },
        { name: 'end', type: 'date', format: 'yyyy-MM-dd HH:mm' }
        , { name: 'recurrenceRule', type: 'string' }
      ],
      id: 'id',
      //localData: this.loadPrograms()
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  appointmentDataFields: any =
    {
      from: 'start',
      to: 'end',
      id: 'id',
      description: 'about',
      subject: 'name',
      background: 'background',
      recurrencePattern: "recurrenceRule"
    };


    localization: any = {
      firstDay: 1,
      days: {
          // full day names
          names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'],
          // abbreviated day names
          namesAbbr: ["Dim" , "Lun","Mar", "Mer", "Jeu", "Ven", "Sam"],
          // shortest day names
          namesShort: ["Di" , "Lu","Ma", "Me", "Je", "Ve", "Sa"]
      },

      months: {
          // full month names (13 months for lunar calendards -- 13th month should be "" if not lunar)
          names: [
            'Janvier', 'Février', 'Mars', 'Avril',
            'Mai', 'Juin', 'Juillet', 'Août',
            'Septembre', 'Octobre', 'Novembre', 'Décembre',''
          ],
          // abbreviated month names
          namesAbbr: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc',''],
      },
      loadString: "Chargement...",
      todayString: "aujourd'hui",
      dayViewString: "Jour",
      weekViewString: "Semaine",
      monthViewString: "Mois",
      editRecurringAppointmentDialogSeriesString: "Modifier le plan",
      editRecurringAppointmentDialogContentString: "Voulez-vous modifier le plan?",
      editDialogTitleString: "Modifier le Plan",
      editDialogCreateTitleString: "Ajouter un plan",
      contextMenuEditAppointmentString: "Modifier le Plan",
      contextMenuCreateAppointmentString: "Ajouter un Plan",
      editDialogSubjectString: "Titre",
      editDialogFromString: "Debut",
      editDialogToString: "Fin",
      editDialogColorString: "Couleur",
      editDialogSaveString: "Enregistrer",
      editDialogDeleteString: "Supprimer",
      editDialogCancelString: "Annuler",
  };


  views: any[] =
    [
      'weekView',
      'agendaView'
    ];

  editDialogOpen = (dialog: any, fields: any, editAppointment: any) => {

    if (fields) {
      // hide repeat option
      fields.repeatContainer.hide();
      fields.repeatEndPanelContainer.hide();
      // hide exception option
      fields.resetExceptionsContainer.hide();
      // hide status option
      fields.statusContainer.hide();
      // hide timeZone option
      fields.timeZoneContainer.hide();
      // fields.subjectLabel.html("Titre");
      fields.locationContainer.hide();
      // fields.fromLabel.html("Debut");
      // fields.toLabel.html("Fin");
      // fields.colorLabel.html("Couleur");
      fields.resourceContainer.hide();
      fields.allDayContainer.hide();
      fields.repeatPanel.hide();
      fields.resetExceptionsLabel.hide();
      fields.resetExceptions.hide();
    }
  }


  EditRecurrenceDialogOpen(event: any): void {
    this.editRecurringAppointmentOccurrenceElement = event.args.dialog[0].children[1].children[1].children[0];
    this.editRecurringAppointmentOccurrenceElement.hidden = true;
  }

  /**
* Afficher modal d'ajout du program
*/
  onAddProgram() {
    this.currentProgram = new Program();
    this.mode = 'ADD';
    this.programModal.show();
  }

  appointmentAdd(event: any): void {
    let timeplan = event.args.appointment.originalData;
    timeplan.recurrenceRule = "FREQ=WEEKLY;";
    this.currentProgram.timePlans.push(timeplan);
  };

  appointmentDelete(event: any): void {
    let timeplan = event.args.appointment.originalData;
    for (let i = 0; i < this.currentProgram.timePlans.length; i++) {
      if (timeplan.id === this.currentProgram.timePlans[i].id) {
        this.currentProgram.timePlans.splice(i, 1);
        break;
      }
    }
  }


  appointmentChange(event: any): void {
    let timeplan = event.args.appointment.originalData;
    for (let i = 0; i < this.currentProgram.timePlans.length; i++) {
      if (timeplan.id === this.currentProgram.timePlans[i].id) {
        this.currentProgram.timePlans[i].about = timeplan.about;
        this.currentProgram.timePlans[i].background = timeplan.background;
        this.currentProgram.timePlans[i].end = timeplan.end;
        this.currentProgram.timePlans[i].start = timeplan.start;
        this.currentProgram.timePlans[i].name = timeplan.name;
        this.currentProgram.timePlans[i].id = timeplan.id;
        break;
      }
    }
  };

  addProgramTimePlan() {
    // this.currentProgram.timePlans.forEach(tp => { tp.id = null });
    this.loading = true;
    this.currentProgram.vehiculeIds = this.selectedVehicules(this.selectedVehiculesItems);
    this.programService.addProgram(this.currentProgram).subscribe(program => {
      this.loading = false;
      this.toastr.success("Ajoute avec succes");
      this.router.navigate(['/client/dataManagement/program']);
    }, (err) => {
      this.loading = false;
      this.toastr.error(err);
    });
  }

  updateProgramTimePlan() {
    // this.currentProgram.timePlans.forEach(tp => { tp.id = null });
    this.loading = true;
    this.currentProgram.vehiculeIds = this.selectedVehicules(this.selectedVehiculesItems);
    this.programService.updateProgram(this.currentProgram).subscribe(program => {
      for (let i = 0; i < this.programService.programs.length; i++) {
        if (this.programService.programs[i].idProgram == program.idProgram) {
          this.programService.programs[i] = program;
        }
      }
      this.loading = false;
      this.toastr.success("Modifie avec succes");
      this.router.navigate(['/client/dataManagement/program']);
    }, (err) => {
      this.loading = false;
      this.toastr.error(err);
    });
  }

  initSelectedHash(vehicules: Vehicule[]) {
    let prefix = "";
    this.selectedVehiculesHash = [];
    vehicules.forEach(vehicule => {
      this.selectedVehiculesHash.push(vehicule.idVehicule);
    });
  }

  selectedVehicules(vehicules: { item_text: string | number; item_id: number }[]) {
    let prefix = "";
    this.selectedVehiculesHash = [];
    vehicules.forEach(vehicule => {
      this.selectedVehiculesHash.push(vehicule.item_id);
    });

    return this.selectedVehiculesHash;

  }

  mapVehiculeToItems(vehicules: Vehicule[]) {
    this.vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesItems.push({
        item_id: vehicules[i].idVehicule,
        item_text: vehicules[i].matricule
      });
    }
  }

  mapSelectedVehiculeToItems(vehicules: Vehicule[]) {
    this.selectedVehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.selectedVehiculesItems.push({
        item_id: vehicules[i].idVehicule,
        item_text: vehicules[i].matricule
      });
    }
    this.selectedVehicules(this.selectedVehiculesItems);
  }
}
