<div class="rowpf">
  <!-- Left col -->
  <section class="col-lg-12 connectedSortable">
    <!-- Custom tabs (Charts with tabs)-->
    <div class="nav-tabs-custom">
      <!-- Tabs within a box -->
      <ul class="nav nav-tabs pull-right">
        <li
          routerLink="/client/canalPerfomance/parametrage/superviseur"
          routerLinkActive="active"
        >
          <a data-toggle="tab">
            <i
              class="fa fa-user-plus"
              aria-hidden="true"
              style="color: #790fec"
              style="color: #790fec"
            ></i>
            &nbsp;Superviseur
          </a>
        </li>

        <li
          routerLink="/client/canalPerfomance/parametrage/type"
          routerLinkActive="active"
        >
          <a data-toggle="tab">
            <i
              class="fa fa-check-square-o"
              aria-hidden="true"
              style="color: #790fec"
            ></i>
            &nbsp; Type de Canal
          </a>
        </li>
      </ul>

      <div class="tab-content heightDiv">
        <router-outlet></router-outlet>
      </div>
    </div>
  </section>
</div>
