import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { BreakDown } from '../../ciment-management';
import { Mission, TrackingMission, TrackingMissionData } from '../../data-management';
import { OtNumber, ScheduleForm } from './schedule';

@Injectable()
export class ScheduleService {

  constructor(private http: HttpClient) { }

  add(payload: ScheduleForm, validatorId : number): Observable<ScheduleForm> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<ScheduleForm>(dns + "schedules?timezoneOffset=" +
    (new Date().getTimezoneOffset()) + "&validatorId="+validatorId, payload, options);
  }

  addBreakDownCiment(payload: BreakDown): Observable<BreakDown> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<BreakDown>(dns + "schedules/breakDownCiment", payload, options);
  }


  addNumeroOt(otNumber: OtNumber){
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<OtNumber>(dns + "numeroOt/addOT", otNumber, options);
  }

  getNumeroOt(dateIntervate: DateInterval){
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<OtNumber[]>(dns + "numeroOt/getOT", dateIntervate,options);
  }

  addMission(mission: Mission): Observable<Mission> {
      let headers = createAuthorizationHeader();
      let options = { headers: headers };
      return this.http.post<Mission>(dns + "missionReturns", mission, options);
  }

  trackingInfo(idSite: number): Observable<string[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<string[]>(dns + "missionReturns/trackingInfo?idSite="+idSite,options);
  }

//   trackingReturn(vehiclesIds: number[]): Observable<TrackingMission[]> {
//     let headers = createAuthorizationHeader();
//     let options = { headers: headers };
//     return this.http.post<TrackingMission[]>(dns + "missionReturns/tracking" , vehiclesIds, options);
// }
trackingReturn(missionId: number, vehicleId: number,sequence:Number): Observable<TrackingMission> {
  let headers = createAuthorizationHeader();
  let options = { headers: headers };
  return this.http.get<TrackingMission>(dns + "missionReturns/" + missionId + "/tracking/" + vehicleId+"?sequence="+sequence, options);
}

getMissionsNotInIds(missionIds : Number[]): Observable<TrackingMissionData[]> {
  let headers = createAuthorizationHeader();
  let options = { headers: headers };
  return this.http.post<TrackingMissionData[]>(dns + "missionReturns/notInIds",
       missionIds, options);
}

getMissionsBySiteAndPoleNotInIds(missionIds : Number[],poi:Number,type:Number,sequence:Number): Observable<TrackingMissionData[]> {
  let headers = createAuthorizationHeader();
  let options = { headers: headers };
  return this.http.post<TrackingMissionData[]>(dns + "missionReturns/"+type+"/notInIds/"+poi+"?sequence="+sequence,
       missionIds, options);
}



}
