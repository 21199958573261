import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChildDetail } from '../../../../../model/km-jour.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RealtimeService } from 'src/app/client-management/realtime/services/realtime.service';
import { ToolKmJourService } from 'src/app/client-management/realtime/services/tool-km-jour.service';
import { ChartParametre } from 'src/app/client-management/realtime/model/chart-parameter.model';
import { DataManagementService } from 'src/app/client-management/data-management';

@Component({
  selector: 'km-jour-trajet-item',
  templateUrl: './km-jour-trajet-item.component.html',
  styleUrls: ['./km-jour-trajet-item.component.css'],
})
export class KmJrTrajetItemComponent {
  @Input() childDetail: ChildDetail;
  @Input() useFms: boolean;
  @Output('displayDash') displayDash = new EventEmitter<ChartParametre>();
  
  constructor(
    private toolKmJourService: ToolKmJourService,
    private router: Router,
    private route: ActivatedRoute,
    private realTimeService: RealtimeService
  ) {}

  ngOnInit() {

  }

  goToSimulation() {
    this.toolKmJourService.setChildPath(this.childDetail);
    this.toolKmJourService.setDestination('../');
    this.realTimeService.setBarToolStatus(false);
    this.router.navigate(['trajet'], {relativeTo: this.route});
    this.displayDash.emit({
      intervald: {startDate: this.childDetail.beginPathTime, 
        endDate:this.childDetail.endPathTime}, 
        time: 5,
        height: 150, 
        fontSize: "10px"
    })
  }



  ngOnDestroy() {}

    
  calculeConsumation(consumtion: any): string {
    if (!consumtion || consumtion > 100) {
        return "0";
    } else {
        return consumtion.toFixed(2) + " %";  // Convert the number to a string with 2 decimal places
    }
  }

}
