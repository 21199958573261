<ngx-loading [show]="globalLoading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="content" style="overflow-y: scroll; height:430px;">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>
                    <div class="input-group input-group-sm">
                        <input type="text" name="searchTerm" class="form-control pull-left" placeholder="Nom .."
                            name="search" #searchTerm>

                        <div class="input-group-btn">
                            <button type="submit" class="btn btn-default"><i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>

            <tr *ngIf="loading">
                <td style="text-align: center" colspan="13"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></td>
            </tr>

            <ng-container *ngIf="!loading">
                <tr
                    *ngFor="let passage of passageService.passages | filterBy : {name : searchTerm.value} | orderBy : 'name'">
                    <td>{{passage.name}}</td>
                    <td style="width:20%">
                        <div class="pull-right" style="margin-top: 1.5%;">
                            <div class="btn-group">
                                <button tooltip="Delete" (click)="confirmDeletePassage(passage)"
                                    class="btn ink-reaction btn-danger pull-right">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>


                              <a class="pull-right"
                                    [routerLink]="['/client/parametrage/chargesPfbPassage/form/', passage.idPassage, 'UPDATE']"
                                    routerLinkActive="active">
                                <button class="btn btn-warning" tooltip="Modifier">
                                      <i class="fa fa-cog" aria-hidden="true"></i>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-container>

        </tbody>
    </table>
</div>
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" tabindex="-1" #deleteModal="bs-modal" bsModal role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="deleteModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !
                    </h4>
                    Êtes-vous sûr de supprimer ce passage ?
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="deleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="onDelete()">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->

