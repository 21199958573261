import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import {DashBordExpensesInfoDTO, DashbordInfo} from '../model/index';
import {dns} from '../../../global.config';
import {createAuthorizationHeader} from '../../../utils/security/headers';
import {DateInterval} from '../../../shared/model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DashbordService {
  constructor(private _http: HttpClient) {}

  public getDashbordInfo(
    idGroup: number,
    idVehicule: number,
    date: DateInterval
  ): Observable<DashbordInfo> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<DashbordInfo>(
      dns + 'vehicules/' + idGroup + '/' + idVehicule,
      date,
      options
    );
  }

  public getDashbordInfoExpenses(
    date: DateInterval
  ): Observable<{ [key: string]: DashBordExpensesInfoDTO[] }> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<{ [key: string]: DashBordExpensesInfoDTO[] }>(
      dns + 'canals/getAllExpenses/',
      date,
      options
    );
  }
}
