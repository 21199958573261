import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolBarComponent } from './tool-bar.component'; // Adjust the path as necessary
import { RouterModule } from '@angular/router'; // Import RouterModule for route-related services
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms

// Services
import { RealtimeService } from '../../../services/realtime.service'; // Import RealtimeService
import { ToolKmJourService } from '../../../services/tool-km-jour.service'; // Import ToolKmJourService
import { MileageService } from 'src/app/client-management/historical'; // Import MileageService
import { MapService } from 'src/app/utils/leaflet/service/map.service'; // Import MapService
import { RealtimeHelperService } from '../../../services/realtime-helper.service'; // Import RealtimeHelperService

@NgModule({
  declarations: [
    ToolBarComponent // Declare the ToolBarComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    RouterModule, // Import RouterModule to handle routing in the component
    FormsModule // Import FormsModule to handle form controls
  ],
  providers: [
    RealtimeService, // Provide RealtimeService
    ToolKmJourService, // Provide ToolKmJourService
    MileageService, // Provide MileageService
    MapService, // Provide MapService
    RealtimeHelperService // Provide RealtimeHelperService
  ],
  exports: [
    ToolBarComponent // Export ToolBarComponent for use in other modules
  ]
})
export class ToolBarModule { }
