<div class="inputs-containers">
    <form [formGroup]="poiForm" class="form-container">
        <div class="form-inputs-container"  (click)="handleClick($event)">     
            <div>
                <input type="text" id="fname" name="firstname" placeholder="Le nom de poi" formControlName="name">
            </div>
            <div>
                <select formControlName="typePoi">
                    <option value="Client">Client</option>
                    <option value="Point d'intérêt">Point d'intérêt</option>
                </select>
            </div>
        </div>
        <div class="button-control">
            <button type="button" (click)="closeAddPoi()"><i class="fa fa-times" aria-hidden="true" style="color: red;"></i></button>
            <button type="submit" (click)="onSubmit()" *ngIf="!startSaving"><i class="fa fa-check" aria-hidden="true" style="color: green;"></i></button>
            <span  class="link-icon loader" *ngIf="startSaving"></span>
        </div>
    </form>

</div>
