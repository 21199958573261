import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RunningDocumentChartComponent } from './running-document-chart.component';
import { RunningDocumentChartService } from './running-document-chart.service';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    RunningDocumentChartComponent  // Declare the RunningDocumentChartComponent
  ],
  imports: [
    CommonModule,                  // Import necessary Angular modules
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    FormsModule,


  ],
  providers: [
    RunningDocumentChartService     // Provide the RunningDocumentChartService
  ],
  exports: [
    RunningDocumentChartComponent    // Export the RunningDocumentChartComponent for use in other modules
  ]
})
export class RunningDocumentChartModule { }
