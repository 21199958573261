import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ChargePfbInfoDto, ChargeRealise, VoyageDto } from '../../charges-pfb';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

@Component({
  selector: 'app-rotation-charge',
  templateUrl: './rotation-charge.component.html',
  styleUrls: ['./rotation-charge.component.css']
})
export class RotationChargeComponent implements OnInit {

  caRealise_: ChargeRealise[] = [];
  rotationCharge: Object | null = null;
  globalLoading: boolean;
  selectedCharge: ChargePfbInfoDto = new ChargePfbInfoDto();
  dayDif: number = 0;
  total: number = 0;
  objectifRotation: number = 0;
  rotationR: number = 0;
  voyage_: VoyageDto = new VoyageDto();

  @Input()
  set selectedCharges(chargePfb: ChargePfbInfoDto) {
    if (chargePfb) {
      this.selectedCharge = chargePfb;
    }
  }

  @Input() isManually: any;


  @Input()
  set dayDifference(dayDifference: number) {
    this.dayDif = dayDifference;
  }

  @Input()
  set caRealise(caRealise: ChargeRealise[]) {
    if (this.isManually) {
      if (caRealise) {
        this.caRealise_ = Object.assign(caRealise);
        this.rotationChart();
      }
    }
  }

  @Input()
  set voyage(voyage: VoyageDto) {
    if (!this.isManually) {
      if(voyage)
      this.voyage_ = Object.assign(voyage);
      this.rotationChart();
    }
  }

  constructor(public toastr: ToastrService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.rotationChart();
  }

  rotationChart() {
    this.setHighCartsLang();
    /** Create Pie Chart( rotation chart ) **/
    var series = [];
    var seriesData = [];

    if (this.selectedCharge != null) {
      this.total = (this.selectedCharge.rotation / this.selectedCharge.nbDays) * this.dayDif;
      this.objectifRotation = this.total;
      var percentage = this.total / 100;

      var caRotation: any[] = [];
      if (this.isManually)
        caRotation = this.caRealise_.filter(ca => ca.rotation > 0);
      if (caRotation.length > 0 || this.voyage_.nbVoyage > 0) {
        if (this.isManually)
          this.rotationR = caRotation[caRotation.length - 1].rotation;
        else
          this.rotationR = this.voyage_.nbVoyage;
        this.total = this.objectifRotation - this.rotationR;

        if (this.total < 0) {
          var rest = Math.abs(this.total)

          seriesData.push({
            name: '',
            y: null,
            x: null
          }, {
            name: '',
            x: null,
            y: null
          }, {
            name: '',
            y: percentage * (this.rotationR),
            x: this.rotationR,
            noTooltip: true
          }, {
            name: 'Dépassement',
            y: percentage * rest,
            x: rest
          });

        } else {
          seriesData.push({
            name: "à Réaliser",
            y: percentage * this.total,
            x: this.total
          }, {
            name: 'Réalisée',
            y: percentage * (this.rotationR),
            x: this.rotationR
          });
        }
      }

      series.push({
        type: 'pie',
        innerSize: '50%',
        name: this.selectedCharge.name,
        colorByPoint: true,
        data: seriesData
      });

    }
    var objectifRotation = this.objectifRotation;
    /** SET OPTIONS **/
    this.rotationCharge = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: 'Evolution Rotation',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          // If the point is going to have a tooltip
          // if (!this.point.noTooltip) {
          //   // Mimic default tooltip contents
          //   return '<span style="font-size:11px">' + this.series.name +
          //     '</span><br><b>Objectif : ' + objectifRotation.toFixed(0) + ' </b><br /><span style="color:' + this.point.color + '">' + this.point.name +
          //     '<br /><b>' + this.point.x.toFixed(0) + ' </b> <br />' + this.point.percentage.toFixed(1) +
          //     '% <br /> </span> <br/>';
          // }

          // If tooltip is disabled
          return false;
        }
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              // If the point is going to have a datalabel
              // if (!this.point.noTooltip) {
              //   // Mimic default datalabel contents
              //   return '<b>' + this.point.name + '</b><br>' + this.point.percentage.toFixed(1) +
              //     '%<br/>';
              // }

              // If datalabel is disabled
              return '';
            },
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'black',
              fontSize: '13px',
              textOutline: false
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      },
      series: series,
    }
    Highcharts.chart('rotationChart', this.rotationCharge);
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts.setOptions({
      colors: ['#ed3833', '#ffe600', '#007db1', '#21bf73'],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        noData: 'Aucune donnée n\'est disponible'
      }
    });
  }

}
