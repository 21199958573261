<div class="row">
  <div class="col-md-8"></div>
  <div class="col-md-4">
    <!-- <mat-form-field appearance="fill">
      <mat-placeholder>Filtrer ici</mat-placeholder>
      <input matInput (keyup)="applyFilter($event)" #input />
    </mat-form-field> -->

    <div class="input-group input-group-sm">
      <input
        type="text"
        name="searchTerm"
        class="form-control pull-left"
        placeholder="Nom .."
        name="search"
        (keyup)="applyFilter($event)"
        #input
      />

      <div class="input-group-btn">
        <button type="button" class="btn btn-default">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" style="width: 100%">
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Nom Complet</th>
      <td mat-cell *matCellDef="let element">
        {{ element.superviseur.completeName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="telephone">
      <th mat-header-cell *matHeaderCellDef>Téléphone</th>
      <td mat-cell *matCellDef="let element">
        {{ element.superviseur.telephone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a
          class="pull-right"
          (click)="openSupervisorDialog(element)"
          routerLinkActive="active"
        >
          <button class="btn btn-warning" tooltip="Modifier">
            <i class="fa fa-cog" aria-hidden="true"></i>
          </button>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[6, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  ></mat-paginator>
</div>
