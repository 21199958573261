import { GroupDto } from "../../data-management";

export class ActivityVehiculeClassification {
  id: number;
  turnover: boolean;
  coefficientTurnover: number;
  fueling: boolean;
  coefficientFueling: number ;
  sinistre: boolean;
  coefficientSinistre: number | null;
  hActivity: boolean;
  coefficientHactivity: number | null;
  mileage: boolean;
  coefficientMileage: number | null;
  workingTime: boolean;
  coefficientWorkingTime: number | null;
  offense: boolean;
  coefficientOffense: number | null;
  groups: GroupDto[];

    constructor() {
        this.groups = [];
    }

}

export class VehiculeClassification {
    driver: string;
    matricule: string;
    total: number;
    driverId: number;
    avatarUri: string;
    mark: number;
}
