import {Directive, ElementRef, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[numberCircle]'
})
export class NumberCircleDirective {
  @Input('numberCircle') number: number;

  constructor() {}

  @HostBinding('style.outline-color')
  get circleColor() {

    if(this.number < 60){
      return 'green';
    }else if (this.number < 120){
      return '#FFD500';
    }else {
      return 'red';
    }

  }

}
