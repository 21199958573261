<div
  class="box-header with-border"
  style="color: black; background: #fafafa;"
  (click)="colapseGroupe()"
>
  <!-- vehicules counter ! -->
  <div class="search-box"  >
    <div
      class="circle-container"
      style="
        font-family: Inconsolata, monospace;
        font-size: 16px;
        font-weight: bold;
        margin-right: 25px;
      "
    >  
      <!-- <div class="circle circle1">{{dataManagementService.getCountVehicule(groups)}}</div> -->
      <div class="parent-element">{{formatNumber(dataManagementService.getCountVehicule(groups)) | number : ".0-0"}}</div>

      <div 
        class="element circle2" 
        (click)="filter($event, 'activeCars')"
        [ngStyle]="{ 'outline': (searched === 'activeCars') ? '3px solid black' : 'none' }"
      >
        <span class="element-number">{{ formatNumber(activeCars) }}</span>
      </div>
      <div 
        class="element circle3"
        (click)="filter($event, 'desactiveCars')" 
        [ngStyle]="{ 'outline': (searched === 'desactiveCars') ? '3px solid black' : 'none' }"
      >
        <span class="element-number"> {{ formatNumber(desactiveCars)   }}</span>
      </div>
      <div 
        class="element circle4"
        (click)="filter($event, 'nonValidCars')" 
        [ngStyle]="{ 'outline': (searched === 'nonValidCars') ? '3px solid black' : 'none' }"
      >
        <span class="element-number"> {{ formatNumber(nonValidCars )}}</span>
      </div>
      <div 
        class="element circle5"
        (click)="filter($event, 'technicalIssueCars')"
        [ngStyle]="{ 'outline': (searched === 'technicalIssueCars') ? '3px solid black' : 'none' }"
      >
        <span class="element-number">{{ formatNumber(technicalIssueCars) }}</span>
      </div>
    </div>


    <div class="wrap">
      <div class="search">
        <input
          type="text"
          class="searchTerm"
          name="table_search"
          placeholder="Matricule ..."
          [(ngModel)]="realTime.searchWord"
          (click)="removeEvent($event)"
        />
        <button type="submit" class="searchButton">
          <img src="assets/icons/realtime/groups/search.png" alt="" style="width: 15px; height: 15px;">
        </button>
      </div>
    </div>
    <div style="display: flex; align-items: center; position: relative; cursor: pointer;" (click)="toggleList($event)"> 
      <img src="assets/icons/realtime/groups/tune.png" style="width: 20px; height: 20px;">
      <div *ngIf="isListVisible" class="toggle-list-container" @slideInOut>
        <ul class="toggle-list">
          <li @showUp (click)="filterGroup($event, 'groups')">Groups</li>
          <li @showUp (click)="filterGroup($event, 'regions')">Regions</li>
        </ul>
      </div>
    </div>
  </div>
</div>
