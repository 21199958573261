import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthlyAvailableVehiculesChartComponent } from './monthly-available-vehicules-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';  // Use Angular wrapper for Highcharts
import { GroupService } from 'src/app/client-management/data-management';  // Import GroupService
import { MonthlyAvailableVehiculesService } from './monthly-available-vehicules.service';  // Import MonthlyAvailableVehiculesService
import { SigninService } from 'src/app/authentification/signin/signin.service';  // Import SigninService
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    MonthlyAvailableVehiculesChartComponent  // Declare the component
  ],
  imports: [
    CommonModule,          // Common Angular directives
    HighchartsChartModule, // Module for Highcharts support in Angular
    NgxLoadingModule.forRoot({}),
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    BrowserModule,

  ],
  providers: [
    GroupService,                  // Provide GroupService
    MonthlyAvailableVehiculesService,  // Provide MonthlyAvailableVehiculesService
    SigninService
  ],
  exports: [
    MonthlyAvailableVehiculesChartComponent  // Export the component for usage in other modules
  ]
})
export class MonthlyAvailableVehiculesChartModule { }
