import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailManagerComponent } from './email-manager.component'; // Adjust the path as necessary
import { AlertManagementService } from '../..'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr/toastr/toastr.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    EmailManagerComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule,
    MatSlideToggleModule,

  ],
  providers: [
    AlertManagementService // Provide the AlertManagementService if used in this module
  ],
  exports: [
    EmailManagerComponent
  ]
})
export class EmailManagerModule { }
