import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargeComponent } from './charge.component';
import { ToastrModule } from 'ngx-toastr'; // For toast notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // For modals
import { HighchartsChartModule } from 'highcharts-angular'; // Assuming highcharts-angular is used for Highcharts

@NgModule({
  declarations: [ChargeComponent], // Declare the component
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Import Toastr module
    ModalModule.forRoot(), // Import Modal module
    HighchartsChartModule // Import Highcharts module
  ],
  exports: [ChargeComponent] // Export the component for use in other modules
})
export class ChargeModule {}
