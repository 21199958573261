import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, Subscription} from "rxjs";
import {DeadlinesDto, DeadlinesGroupsDto, DeadlinesNotifDto, Sinistre} from "../../parc-management";
import {createAuthorizationHeader} from "../../../utils/security/headers";
import {dns} from "../../../global.config";
import {DataManagementService, DriveQualityAlerts, Group, Vehicule} from "../../data-management";
import {DeadlinesService} from "../../parc-management/deadlines/deadlines.service";

@Injectable({
  providedIn: 'root'
})
export class CurrentStateService {

  constructor(
      private _http: HttpClient,
      private deadlinesService: DeadlinesService,
      private dataManagementService: DataManagementService
  ) {
    this.initDeadlines();
  }

  rows: DeadlinesGroupsDto[] = new Array();
  deadlinesDto: DeadlinesDto[] = [];
  isLoading: boolean = false;
  selectedVehicule: Vehicule = new Vehicule();
  deadlinesNotifs: DeadlinesNotifDto[] = [];
  groups: Group[];
  driveQualityAlerts: DriveQualityAlerts[] = [];
  selectedGroup: Group = new Group();
  AllGroups: Subscription;

  initDeadlines() {
    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
          group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
    } else {
      this.AllGroups = this.dataManagementService
          .getAllGroupsDetails("")
          .subscribe(groups => {
            this.groups = groups.filter(group => group.vehicules.length > 0);
            this.dataManagementService.groups = groups;
            this.dataManagementService.setGroups(groups);
            if (this.groups.length > 0 && this.groups) {
              this.selectedGroup = this.groups[0];
              this.dataManagementService.selectedGroup = this.selectedGroup;
              if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
                this.selectedVehicule = this.selectedGroup.vehicules[0];
                this.dataManagementService.selectedVehicule = this.selectedVehicule;
              }
            }
          });
    }
  }

  getDeadlines() {
    this.isLoading = true;

    this.deadlinesService.getListDeadlines().subscribe(deadlinesData => {
      this.deadlinesService.deadlinesDto = deadlinesData;
      this.deadlinesService.deadlinesNotifs = [];
      this.rows = [];
      if (this.dataManagementService.groups != null) {
        for (let i = 0; i < this.dataManagementService.groups.length; i++) {
          if (this.dataManagementService.groups[i].vehicules) {
            for (let j = 0; j < this.dataManagementService.groups[i].vehicules.length; j++) {
              let deadlinesGp = new DeadlinesGroupsDto();
              deadlinesGp.matricule = this.dataManagementService.groups[i].vehicules[j].matricule;
              deadlinesGp.driver = this.dataManagementService.getDriverName(this.dataManagementService.groups[i].vehicules[j].driver);
              deadlinesGp.group = this.dataManagementService.groups[i].nom;
              if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]) {
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["INSURANCE"]) {
                  deadlinesGp.assurance = Math.round(Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["INSURANCE"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["INSURANCE"]["currentValue"]) / 24 / 3600000));
                  if (deadlinesGp.assurance < 10)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.assurance, "ASSURANCE"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["VISIT"]) {
                  deadlinesGp.visite = Math.round(Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["VISIT"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["VISIT"]["currentValue"]) / 24 / 3600000));
                  if (deadlinesGp.visite < 10)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.visite, "VISITE"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["ROAD_TAXES"]) {
                  deadlinesGp.vignette = Math.round(Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["ROAD_TAXES"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["ROAD_TAXES"]["currentValue"]) / 24 / 3600000));
                  if (deadlinesGp.vignette < 10)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.vignette, "VIGNETTES"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["OPERATIONAL_CERTIFICATION"]) {
                  deadlinesGp.taxecirculation = Math.round(Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["OPERATIONAL_CERTIFICATION"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["OPERATIONAL_CERTIFICATION"]["currentValue"]) / 24 / 3600000));
                  if (deadlinesGp.taxecirculation < 10)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.taxecirculation, "OPERATIONAL_CERTIFICATION"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["TIRE"]) {
                  deadlinesGp.pneu = Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["TIRE"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["TIRE"]["currentValue"]) * 100) / 100;
                  if (deadlinesGp.pneu < 500)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.pneu, "PNEU"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["OIL_F"]) {
                  deadlinesGp.oil = Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["OIL_F"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["OIL_F"]["currentValue"]) * 100) / 100;
                  if (deadlinesGp.oil < 500)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.oil, "F_HUILE"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["AIR_F"]) {
                  deadlinesGp.air = Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["AIR_F"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["AIR_F"]["currentValue"]) * 100) / 100;
                  if (deadlinesGp.air < 500)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.air, "F_AIR"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["ENGINE_OIL_F"]) {
                  deadlinesGp.engine = Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["ENGINE_OIL_F"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["ENGINE_OIL_F"]["currentValue"]) * 100) / 100;
                  if (deadlinesGp.engine < 0)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.engine, "H_MOTEUR"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["FUEL_F"]) {
                  deadlinesGp.fuel = Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["FUEL_F"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["FUEL_F"]["currentValue"]) * 100) / 100;
                  if (deadlinesGp.fuel < 500)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.fuel, "F_CARBURANT"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["EMBRAYAGE"]) {
                  deadlinesGp.embrayage = Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["EMBRAYAGE"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["EMBRAYAGE"]["currentValue"]) * 100) / 100;
                  if (deadlinesGp.embrayage < 500)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.embrayage, "EMBRAYAGE"))
                }
                if (deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["DISTRIBUTION"]) {
                  deadlinesGp.distribution = Math.round((deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["DISTRIBUTION"]["deadlineValue"] -
                      deadlinesData.deadlines[this.dataManagementService.groups[i].vehicules[j].idVehicule]["DISTRIBUTION"]["currentValue"]) * 100) / 100;
                  if (deadlinesGp.distribution < 500)
                    this.deadlinesNotifs.push(new DeadlinesNotifDto(deadlinesGp.matricule, deadlinesGp.group, deadlinesGp.distribution, "DISTRIBUTION"))
                }

                if (this.dataManagementService.groups[i].vehicules[j].odo > 0) {
                  deadlinesGp.odo = Math.round(this.dataManagementService.groups[i].vehicules[j].odo);
                }

              }
              this.rows.push(deadlinesGp);
            }
          }
        }
      }
      this.rows = [...this.rows];
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }


  getDeadline(): Observable<any> {
    const headers = createAuthorizationHeader();
    return this._http.get<any>(dns + 'dashborad/deadline', {headers: headers});
  }

  getDriveQualityAlerts(): Observable<any> {
    const headers = createAuthorizationHeader();
    return this._http.get<any>(dns+ 'drivequality/alerts', {headers: headers});
  }



}

