import { Component, OnInit, ViewChild } from '@angular/core';

import * as Highcharts from 'highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TravelReportDto } from '../../../../parc-management';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts)

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
  selector: 'app-chart-arret-poi-eps',
  templateUrl: './chart-arret-poi-eps.component.html',
  styleUrls: ['./chart-arret-poi-eps.component.scss']
})
export class ChartArretPoiEpsComponent implements OnInit {

  TravelReportsData: TravelReportDto[] = [];

  @ViewChild("chartPoi", { static: false }) chartPoiModal: ModalDirective;

  options: Object | null = null;
  optionsModal: Object | null = null;


  constructor() { }

  ngOnInit() {
    this.PoiChart(this.TravelReportsData);
  }

  showGraphchartPoi(){
    this.chartPoiModal.show();
  }

  transform(value: number, args?: any): any {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);

    let result = '';

    result= hours + "h" + minutes + "min"

    return result;
  }

  PoiChart(TravelReportsData: TravelReportDto[]) {



    this.TravelReportsData= TravelReportsData;

    const groupedData = this.TravelReportsData.reduce((result, item) => {
      const key = item.vehicule.subMark;
      if (!result[key]) {
        result[key] = {
          name: key,
          y: 0,
          drilldown: key,
          vehicles: []
        };
      }

      result[key].y += item.time_in_poi;
      result[key].vehicles.push({
        name: item.vehicule.matricule,
        y: item.time_in_poi,
        time:this.transform(item.time_in_poi)
      });

      return result;
    }, {});
    const groupedArray = Object.values(groupedData);

    const drilldownSeries = Object.keys(groupedData).map((key) => ({
      id: key,
      data: groupedData[key].vehicles
    }));

    this.setHighCartsLang();


    this.options= {
      chart: {
          type: 'pie',
          height:280,
      },
      title: {
          text: 'Temps Arrêt POI',
          style:{
            color: '#3c8dbc',
            fontSize:12
          },
          align: 'center'
      },
      tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.time}</b><br/>'
      },
      plotOptions: {

      },
      xAxis: {
          type: 'category',
          labels: {
            enabled: true, // Set this to false to hide the xAxis labels
          },
      },
      yAxis: {
          labels: {
            enabled: true,
            text: "Arret Poi / Vehicule"
          }
      },
      series: [
          {
              minPointSize: 10,
              innerSize: '20%',
              zMin: 0,
              name: 'groupes',
              borderRadius: 5,
              data: groupedArray
          }
      ],
      drilldown: {
          tooltip: {
              headerFormat: '',
              pointFormat: '<b>{point.name}</b>: {point.y}Km',
          },
          breadcrumbs: {
              position: {
                  align: 'right'
              }
          },
          series: drilldownSeries
        }
  }

  this.optionsModal= {
    chart: {
        type: 'pie',
        height:280,
    },
    title: {
        text: 'Notification Arrêt POI',
        style:{
          color: '#3c8dbc',
          fontSize:12
        },
        align: 'center'
    },
    tooltip: {
        headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
    },
    plotOptions: {

    },
    xAxis: {
        type: 'category',
        labels: {
          enabled: true, // Set this to false to hide the xAxis labels
        },
    },
    yAxis: {
        labels: {
          enabled: true,
          text: "Arret Poi / Vehicule"
        }
    },
    series: [
        {
            minPointSize: 10,
            innerSize: '20%',
            zMin: 0,
            name: 'groupes',
            borderRadius: 5,
            data: groupedData
        }
    ],
    drilldown: {
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b>: {point.y}Km',
        },
        breadcrumbs: {
            position: {
                align: 'right'
            }
        },
        series: drilldownSeries
      }
}

    Highcharts.chart('ArretPoiEps', this.options);
    Highcharts.chart('ArretPoiEpsModal', this.optionsModal);


  }

  // function for set lang hightcarts

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7', '#e77e22', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b',  '#00f194'
    ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

  }

}
