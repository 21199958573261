import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassificationSpeedCartoChartComponent } from './classification-speed-carto-chart.component';
import { HighchartsChartModule } from 'highcharts-angular'; // Import Highcharts Angular module if needed
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ClassificationSpeedCartoChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule // Include this if you're using Highcharts in your component
  ],
  exports: [
    ClassificationSpeedCartoChartComponent
  ]
})
export class ClassificationSpeedCartoChartModule { }
