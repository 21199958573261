import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityVehicleClassificationComponent } from './activity-vehicle-classification.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ActivityVehiculeClassificationsService } from './activity-vehicule-classifications.service';
import { ActivityVehicleClassificationFormComponent } from './activity-vehicle-classification-form/activity-vehicle-classification-form.component';
import { ActivityVehicleClassificationFormModule } from './activity-vehicle-classification-form/activity-vehicle-classification-form.module';
import { ClassificationHourActivityChartModule } from './activity-vehicle-classification-charts/classification-hour-activity-chart/classification-hour-activity-chart.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { ClassificationPercentageChartModule } from './activity-vehicle-classification-charts/classification-percentage-chart/classification-percentage-chart.module';
import { ClassificationKMChartModule } from './activity-vehicle-classification-charts/classification-kmchart/classification-kmchart.module';
import { ClassificationFuelingChartModule } from './activity-vehicle-classification-charts/classification-fueling-chart/classification-fueling-chart.module';
import { ClassificationOffenseChartModule } from './activity-vehicle-classification-charts/classification-offense-chart/classification-offense-chart.module';
import { ClassificationSinistreChartModule } from './activity-vehicle-classification-charts/classification-sinistre-chart/classification-sinistre-chart.module';
import { ClassificationDriverTurnOverChartModule } from './activity-vehicle-classification-charts/classification-driver-turn-over-chart/classification-driver-turn-over-chart.module';

@NgModule({
  declarations: [
    ActivityVehicleClassificationComponent,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(),
    ActivityVehicleClassificationFormModule,
    BsDatepickerModule.forRoot(),
    SharedPipesModule,
    ClassificationHourActivityChartModule,
    ClassificationFuelingChartModule,
    ClassificationPercentageChartModule,
    ClassificationKMChartModule,
    ClassificationOffenseChartModule,
    ClassificationSinistreChartModule,
    ClassificationDriverTurnOverChartModule


  ],
  providers: [
    ActivityVehiculeClassificationsService
  ],
  exports: [
    ActivityVehicleClassificationComponent
  ]
})
export class ActivityVehicleClassificationModule { }
