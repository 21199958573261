<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<section>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-4">
          <i class="fa fa-tachometer"></i> <span>Charge PF/BPE ( {{selectedMonth?.name}} ) |</span>
          <i *ngIf="selectedMonth?.id == months[months?.length - 1]?.id && selectedYear?.id == years[0]?.id" class="fa fa-plus-circle add"
            aria-hidden="true">
            <span (click)="openChargePfbForm('ADD')"> Ajouter </span>
          </i>
        </div>
        <div class="col-sm-2">
          <label class="col-sm-6 control-label grp-lbl" title="Manuellement">Manuellement</label>
          <div class="col-sm-6">
            <mat-slide-toggle [(ngModel)]="chargePfbService.isManually" (change)="ManuallyOrAutomatically()"
              title="Manuellement"></mat-slide-toggle>
          </div>
        </div>

        <div class="col-sm-3">
          <label class="col-sm-2 control-label grp-lbl">Année</label>
          <div class="col-sm-10 grp-slct">
            <select class="form-control input-sm"  [(ngModel)]='selectedYear' (ngModelChange)="onMonthOrYearChange(true)"
              name='selectedYear' required>
              <option style="font-size: 14px;" *ngFor="let year of years" [ngValue]="year" >
                {{year.id}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <label class="col-sm-2 control-label grp-lbl">Mois</label>
          <div class="col-sm-10 grp-slct">
            <select class="form-control input-sm" [(ngModel)]='selectedMonth' (ngModelChange)="onMonthOrYearChange(false)"
              name='selectedMonth' required>
              <option style="font-size: 14px;" *ngFor="let month of months" [ngValue]="month">
                {{month.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>


    <!-- CARD BODY -->
    <div class="card-body p-0">
      <div class="app">
        <div class="main">
          <div class="container p-0">

            <div class="messages">
              <form>
                <div class="inner-addon left-addon">
                  <i class="fa fa-search"></i>
                  <input type="search" class="search" placeholder="Rechercher..." #searchTerm />
                </div>
              </form>

              <!-- CHARGEPFB ITEMS -->
              <ul class="message-list">
                <li *ngFor="let charge of chargePfb | filterBy : {name : searchTerm.value}"
                  [ngClass]="{'active': selectedChargePfb?.idChargePfb == charge.idChargePfb}"
                  (click)="selectedChargePfb = charge">
                  <div class="preview">
                    <h5>{{charge?.transportType?.name}} {{charge?.name}} <small>{{charge?.nbDays}}</small></h5>
                    <p>POI Départ: {{charge?.pointOfInterests?.length}}</p>
                    <p>POI Arrivée: {{charge?.endPointOfInterests?.length}}</p>

                  </div>
                </li>
              </ul>
              <!-- END CHARGEPFB ITEMS -->
            </div>

            <!-- CHARGEPFB BODY -->
            <section *ngIf="selectedChargePfb" class="message">
              <h3>{{selectedChargePfb?.transportType?.name}} {{selectedChargePfb?.name}}</h3>

              <div class="option-add">
                <span title="Modifier" (click)="openChargePfbForm('UPDATE')"><i class="fa fa-edit"></i> Modifier</span>
              </div>

              <div *ngIf="selectedMonth?.id == months[months?.length - 1]?.id" class="option-delete">
                <span title="Supprimer" (click)="onConfirmDelete()"><i class="fa fa-minus-circle"></i> Supprimer</span>
              </div>

              <div class="meta-data">
                <div class="table-responsive">
                  <table class="table table-sm tableFixHead ">
                    <thead>
                      <tr>
                        <th>Pôle</th>
                        <th>POI Départ</th>
                        <th>POI Arrivée</th>
                        <th>Budget Charge Carburant (DH)</th>
                        <th>Budget Location (DH)</th>
                        <th>Objectif C.A Transport (DH)</th>
                        <th>Rotation</th>
                        <th>NBR. Jour</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{selectedChargePfb?.transportType?.name}}</td>
                        <td>{{selectedChargePfb?.pointOfInterests?.length}}</td>
                        <td>{{selectedChargePfb?.endPointOfInterests?.length}}</td>
                        <td>{{selectedChargePfb?.fuelCharge}}</td>
                        <td>{{selectedChargePfb?.transportCharge}}</td>
                        <td>{{selectedChargePfb?.caTransport}}</td>
                        <td>{{selectedChargePfb?.rotation}}</td>
                        <td>{{selectedChargePfb?.nbDays}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <fieldset class="poi-depart">
                <legend>POI Départ</legend>
                <div class="form-group"
                  *ngIf="selectedChargePfb.pointOfInterests && selectedChargePfb.pointOfInterests.length > 0">
                  <span data-toggle="tooltip" title="" class="message badge green-badge" style="margin: 15px;"
                    *ngFor="let poi of selectedChargePfb.pointOfInterests">
                    {{poi.name}}
                  </span>
                </div>
              </fieldset>
              <fieldset class="poi-arrive">
                <legend>POI Arrivée</legend>
                <div class="form-group"
                  *ngIf="selectedChargePfb.endPointOfInterests && selectedChargePfb.endPointOfInterests.length > 0">
                  <span data-toggle="tooltip" title="" class="message badge purple-badge" style="margin: 15px;"
                    *ngFor="let endPoi of selectedChargePfb.endPointOfInterests">
                    {{endPoi.name}}
                  </span>
                </div>
              </fieldset>
            </section>
            <!-- END CHARGEPFB BODY -->
          </div>
        </div>
      </div>
    </div>
    <!-- END CARD BODY -->
  </div>
</section>



<!-- ========================================== Form Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #chargePfbFormModal="bs-modal" bsModal role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- MODAL HEADER -->
      <div class="modal-header">
        <button type="button" class="close" (click)="chargePfbFormModalhide()">&times;</button>
        <h4 class="modal-title"><i class="fa fa-plus-circle" aria-hidden="true"></i> Charge Mensuelle PFB (
          {{selectedMonth?.name}} )</h4>
      </div>
      <!-- END MODAL HEADER -->

      <!-- MODAL BODY -->
      <div class="modal-body">
        <div class="box-body">
          <app-charges-pfb-form (chargePfbSaved)="onChargePfbSaved($event)" [isManually]="chargePfbService.isManually" [selectedYear]="selectedYear?.id" [selectedMonth]="selectedMonth?.id"
            [mode]="mode" [selectedCharge]="selectedCharge"></app-charges-pfb-form>
        </div>
      </div>
      <!-- END MODAL BODY -->

      <!-- MODAL FOOTER -->
      <div class="box-footer">
        <button (click)="chargePfbFormModalhide()" class="btn btn-default pull-right">
          Fermer
        </button>
      </div>
      <!-- END MODAL FOOTER -->

    </div>
  </div>
</div>
<!-- ========================================== Form Modal (END) ====================================================-->


<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" tabindex="-1" #chargePfbDeleteModal="bs-modal" bsModal role="dialog" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="chargePfbDeleteModalhide()">&times;</button>
        <h4 class="modal-title">
          <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Suppression !
          </h4>
          <span>Êtes-vous sûr de supprimer cette charge '{{selectedChargePfb?.name}}', ça risque de perdre tous les
            historiques !</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="chargePfbDeleteModalhide()">
          Annuler
        </button>
        <button type="button" class="btn btn-success" (click)="onDelete()">
          <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
        </button>
      </div>
    </div>
  </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
