<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"
></ngx-loading>

<div class="container">
  <ng-container *ngIf="mode == 'ADD' || mode == 'UPDATE'">
    <div class="mt-2 mb-2 text-center well bgg">
      <h4
        *ngIf="mode == 'ADD'"
        style="
          font-family: 'Inconsolata', monospace;
          font-weight: bold;
          color: white;
        "
      >
        <i class="fa fa-plus-circle add"></i>
        <span>Ajouter un nouveau conducteur </span>
      </h4>

      <h4
        *ngIf="mode == 'UPDATE'"
        style="
          font-family: 'Inconsolata', monospace;
          font-weight: bold;
          color: white;
        "
      >
        <i class="fa fa-cog"></i>
        <span>Modifier conducteur </span>
      </h4>
    </div>

    <div class="col-lg-12 well formBC">
      <div class="row">
        <form #driverForm="ngForm" class="form-horizontal">
          <div class="col-sm-12">
            <!-- firstName & lastName -->
            <div class="row">
              <div class="col-sm-6 form-group mrg">
                <label>Prénom</label>
                <input
                  [(ngModel)]="driver.firstName"
                  required
                  name="firstName"
                  type="text"
                  maxlength="50"
                  placeholder="Prénom"
                  class="form-control"
                />
              </div>

              <div class="col-sm-6 form-group mrg">
                <label>Nom</label>
                <input
                  [(ngModel)]="driver.lastName"
                  name="lastName"
                  type="text"
                  maxlength="50"
                  placeholder="Nom"
                  class="form-control"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 form-group mrg">
                <label>Téléphone</label>
                <input
                  [(ngModel)]="driver.telephone"
                  name="telephone"
                  type="text"
                  maxlength="50"
                  placeholder="Téléphone"
                  class="form-control"
                />
              </div>
              <div class="col-sm-6 form-group mrg">
                <label>N° Permis</label>
                <input
                  [(ngModel)]="driver.permitNumber"
                  name="permitNumber"
                  type="text"
                  maxlength="50"
                  placeholder="N° Permis"
                  class="form-control"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3 form-group mrg">
                <label>CIN</label>
                <input
                  [(ngModel)]="driver.cin"
                  name="cin"
                  type="text"
                  maxlength="50"
                  placeholder="CIN"
                  class="form-control"
                />
              </div>
              <div class="col-sm-3 form-group mrg">
                <label>Matricule</label>
                <input
                  [(ngModel)]="driver.matricule"
                  name="matricule"
                  type="text"
                  maxlength="50"
                  placeholder="Matricule"
                  class="form-control"
                />
              </div>
              <div class="col-sm-3 form-group mrg">
                <label>G.Sanguin</label>
                <select
                  class="form-control"
                  [(ngModel)]="driver.bloodGroup"
                  name="bloodGroup"
                >
                  <option
                    *ngFor="let groupSanguin of groupSanguins"
                    [ngValue]="groupSanguin"
                    style="font-size: 14px"
                  >
                    {{ groupSanguin }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3 form-group mrg">
                <label>Fonction</label>
                <input
                  [(ngModel)]="driver.driverFunction"
                  name="driverFunction"
                  type="text"
                  maxlength="50"
                  placeholder="Fonction"
                  class="form-control"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 form-group mrg">
                <label>Identification conducteur</label>
                <input
                  [(ngModel)]="driver.code"
                  name="code"
                  type="text"
                  maxlength="50"
                  placeholder="Identification conducteur"
                  class="form-control"
                />
              </div>
              <div class="col-sm-6 form-group mrg">
                <label>Date d'expiration du permis</label>
                <div>
                  <input
                    type="text"
                    class="form-control input-sm"
                    bsDatepicker
                    name="permitExperiationDate"
                    [(ngModel)]="driver.permitExperiationDate"
                    [bsConfig]="{
                      dateInputFormat: 'DD-MM-YYYY, HH:mm',
                      containerClass: 'theme-dark-blue'
                    }"
                    placement="bottom"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 form-group mrg">
                <label>Date d'expiration carte professionnelle</label>
                <div>
                  <input
                    type="text"
                    class="form-control input-sm"
                    bsDatepicker
                    name="profesionalCardExperiationDate"
                    [(ngModel)]="driver.profesionalCardExperiationDate"
                    [bsConfig]="{
                      dateInputFormat: 'DD-MM-YYYY, HH:mm',
                      containerClass: 'theme-dark-blue'
                    }"
                    placement="bottom"
                  />
                </div>
              </div>
              <div class="col-sm-6 form-group mrg">
                <label>Date d'expiration visite médicale</label>
                <div>
                  <input
                    type="text"
                    class="form-control input-sm"
                    bsDatepicker
                    name="healthCareExperiationDate"
                    [(ngModel)]="driver.healthCareExperiationDate"
                    [bsConfig]="{
                      dateInputFormat: 'DD-MM-YYYY, HH:mm',
                      containerClass: 'theme-dark-blue'
                    }"
                    placement="bottom"
                  />
                </div>
              </div>
            </div>

            <div class="form-group mrg">
              <label></label>
              <app-email-manager
                [showActiveSectionOfAllVehicules]="false"
                [showActiveSection]="false"
                [emailMode]="true"
                [emailsHash]="driver.email"
                (onChange)="onEmailChange($event)"
              >
              </app-email-manager>
            </div>

            <div class="row">
              <div class="form-group mrg float-left">
                <label>Photo</label>
                <button
                  *ngIf="!driver.avatar"
                  class="btn btn-success"
                  (click)="avatar.click()"
                >
                  <i class="fa fa-camera" aria-hidden="true"></i>
                </button>

                <button
                  *ngIf="driver.avatar"
                  class="btn btn-success"
                  (click)="driver.avatar = null"
                >
                  {{ driver.avatar.name }}
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>

                <input
                  #avatar
                  name="avatar"
                  style="display: none"
                  (change)="onAvatarChange($event)"
                  type="file"
                  class="form-control"
                />
              </div>
              <div class="float-left pdg-top">
                <mat-checkbox
                  *ngIf="driver?.avatarUri"
                  class="col-sm-4"
                  [value]="deleteDriverPicture"
                  (change)="driverCheckboxChange($event.checked)"
                >
                  Supprimer La photo
                </mat-checkbox>
              </div>
            </div>

            <!-- ========================== Modal FOOTER (START) ===============================-->
            <div class="box-footer">
              <button
                (click)="addDriver()"
                *ngIf="mode == 'ADD'"
                class="btn btn-info pull-right"
                [disabled]="!driverForm.valid"
              >
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
              </button>
              <button
                (click)="updateDriver()"
                *ngIf="mode == 'UPDATE'"
                class="btn btn-outline-info pull-right"
                [disabled]="!driverForm.valid"
              >
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                Enregistrer
              </button>
              <button class="btn btn-default" (click)="cancel()">
                Annuler
              </button>
            </div>
            <!-- ========================== Modal FOOTER (END) ===============================-->
          </div>
        </form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="mode == 'SHOW'">
    <div class="box box-widget">
      <div class="box-header with-border">
        <div class="user-block">
          <div class="col-md-1">
            <ngx-avatar
              *ngIf="!driver.avatarUri"
              class="img-circle"
              size="100"
              [name]="dataManagementService.getDriverName(driver)"
            ></ngx-avatar>

            <img
              style="width: 100px !important; height: 100px !important"
              *ngIf="driver.avatarUri"
              src="{{ driver?.avatarUri }}"
              class="img-circle"
            />
          </div>
          <div class="col-md-8">
            <span class="username"
              ><a>{{ dataManagementService.getDriverName(driver) }}</a></span
            >
            <span class="description">tel : {{ driver?.telephone }}</span>
          </div>
        </div>
        <div class="box-tools">
          <button
            [routerLink]="[
              '/client/dataManagement/driver/form/',
              driver.idDriver,
              'UPDATE'
            ]"
            type="button"
            class="btn btn-default btn-xs"
          >
            <i class="fa fa-cog"></i> Modifier
          </button>
        </div>
      </div>

      <div class="box-body">
        <p>
          <span style="font-weight: bold">N° permis :</span>
          {{ driver?.permitNumber | noValue }} /
          <span style="font-weight: bold">CIN :</span>
          {{ driver?.cin | noValue }} /
          <span style="font-weight: bold">G.sanguin : </span
          >{{ driver?.bloodGroup | noValue }} /
          <span style="font-weight: bold">Emails : </span
          >{{ driver?.email | noValue }}
        </p>

        <div class="row">
          <div class="col-lg-12">
            <div class="box-header page-header with-border">
              <!-- =================================== TITLE (START)==================================== -->
              <h3
                class="box-title"
                style="
                  font-family: 'Inconsolata', monospace;
                  font-weight: bold;
                  color: #3c8dbc;
                  margin-left: 10px;
                "
              >
                <i class="fa fa-list"></i>
                <span style="text-decoration: underline"
                  >Liste des charges conducteur :</span
                >
              </h3>
              <!-- =================================== TITLE (END)====================================== -->
              <!-- =================================== OPTIONS (START)==================================== -->
              <div class="option-add">
                <span title="Ajouter" (click)="onAdd()">
                  <i class="fa fa-plus-circle"></i> Ajouter</span
                >
              </div>
              <div *ngIf="driverChargesId.length > 0" class="option-delete">
                <span
                  title="Supprimer tout"
                  (click)="onConfirmDeleteMultiple()"
                >
                  <i class="fa fa-minus-circle"></i> Supprimer tout</span
                >
              </div>
              <!-- =================================== OPTIONS (END)==================================== -->
            </div>
          </div>
          <div class="col-lg-12">
            <div class="content">
              <app-driver-charge-list
                [driver]="driver"
                [group]="group"
                [actionAdd]="actionAdd"
                [actionDeleteMultiple]="actionDeleteMultiple"
                (wasChecked)="wasChecked($event)"
              >
              </app-driver-charge-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
