import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-parametrage',
  templateUrl: './parametrage.component.html',
  styleUrls: ['./parametrage.component.scss'],
})
export class ParametrageComponent implements OnInit {
  passageIcon: boolean = true;
  transportIcon: boolean = false;
  validateurIcon: boolean = false;
  validationIcon: boolean = false;
  canalIcon: boolean = false;
  searchTerm='';

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.initRoutes(event.urlAfterRedirects);
      });
  }


  private initRoutes(url: string) {
    this.resetIcons();

    if (url.includes('client/parametrage/chargesPfbtransportType')) {
      this.displayTransportIcon();
    } else if (url.includes('client/parametrage/chargesPfbPassage')) {
      this.displayPassageIcon();
    } else if (url.includes('client/parametrage/chargesPfbvalidator')) {
      this.displayValidateurIcon();
    } else if (url.includes('client/parametrage/chargesPfbvalidation')) {
      this.displayValidationIcon();
    }
    else if (url.includes('client/parametrage/canalsPfp')) {
      this.displayCanalIcon();
    }
  }

  private resetIcons() {
    this.passageIcon = false;
    this.transportIcon = false;
    this.validateurIcon = false;
    this.validationIcon = false;
    this.canalIcon = false;
  }

  private displayPassageIcon() {
    this.passageIcon = true;

  }

  private displayCanalIcon() {
    this.canalIcon = true;
  }

  private displayTransportIcon() {
    this.transportIcon = true;

  }

  private displayValidateurIcon() {
    this.validateurIcon = true;
  }

  private displayValidationIcon() {
    this.validationIcon = true;
  }
}


