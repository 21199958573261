import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { RouterModule } from '@angular/router';
import { InterventionListComponent } from './intervention-list/intervention-list.component';
import { InterventionListModule } from './intervention-list/intervention-list.module';

@NgModule({
  declarations: [
    HelpComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    InterventionListModule

  ],
  exports: [
    HelpComponent
  ]
})
export class HelpModule { }
