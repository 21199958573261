import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressRangeBarComponent } from './progress-range-bar.component'; // Adjust the import path if necessary
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ProgressRangeBarComponent], // Declare the component
  imports: [CommonModule,FormsModule], // Import CommonModule to ensure basic Angular functionality
  exports: [ProgressRangeBarComponent], // Export the component for use in other modules
})
export class ProgressRangeBarModule {}
