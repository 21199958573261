<div class="row">
      <div class="col-lg-12">
            <div class="box-header page-header with-border">
                  <!-- =================================== TITLE (START)==================================== -->
                  <!-- <h3 class="box-title"
                        style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;">
                        <i class="fa fa-list"></i>

                        <span style="text-decoration: underline">

                              <span>Liste des vidanges du véhicule :</span>
                              <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher la véhicule"
                                    [routerLink]="['/client/dataManagement/vehicule/form', vehicule.idVehicule, 'SHOW']">{{vehicule.matricule}}</a>

                              <span *ngIf="vehicule.driver"> - conducteur :
                                    <a style="color: black; text-decoration-color: #3c8dbc;"
                                          title="Afficher le conducteur"
                                          [routerLink]="['/client/dataManagement/driver/form', vehicule.driver.idDriver, 'SHOW']">{{vehicule?.driver?.firstName}}
                                          {{vehicule?.driver?.lastName}}</a>
                              </span>

                        </span>


                        <span *ngIf="!vehicule.driver" data-toggle="tooltip" class="badge label-warning">
                              <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                        </span>

                  </h3> -->
                  <!-- =================================== TITLE (END)====================================== -->

                  <button type="button" class="btn btn-success btn-sm" title="Ajouter" (click)="onAdd()"><i
                              class="fa fa-plus-circle"></i> </button>

                  <div *ngIf="oilChangesId.length > 0" class="option-delete">
                        <span title="Supprimer tout" (click)="onConfirmDeleteMultiple()">
                              <i class="fa fa-minus-circle"></i> Supprimer tout</span>
                  </div>
                  <!-- =================================== OPTIONS (END)==================================== -->
                  <!--==================================== import/Export functionality(START)=============== -->
                  <div style="display: inline-block; margin-left: 5px;">

                        <div class="option-export">
                              <button type="button" class="btn btn-primary btn-sm "
                                    title="Exporter sous format excel" (click)='export()'>Exporter <i
                                          class="fa fa-download"></i></button>

                              <button type="button" class="btn btn-primary btn-sm "
                                    title="Importer sous format excel" (click)='importModal.show()'>Importer <i
                                          class="fa fa-upload"></i> </button>
                        </div>


                  </div>
                  <!--==================================== import/Export functionality(END)================= -->
                  <!--==================================== Search(START)=============== -->
                  <div class="pull-right ">
                        <div class="inputWithIcon">
                              <input [(ngModel)]="searchTerm" name="searchTerm" type="text"
                                    class="form-control input-sm mr-2" placeholder="Fournisseur...">
                              <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                  </div>
                  <!--==================================== Search(END)================= -->
            </div>
      </div>
      <div class="col-lg-12">
            <div class="content">
                  <div class="table-responsive tableFixHead">
                        <table class="table table-striped table-hover table-condensed">
                              <thead>
                                    <tr>
                                          <th style="width: 2%; text-align: left;"><input type="checkbox"
                                                      [disabled]="oilChanges.length == 0"
                                                      [checked]="oilChanges.length == oilChangesId.length"
                                                      (click)="onCheckedAll()"></th>
                                          <th style="width:4%">Date</th>
                                          <th style="width:4%">H Moteur</th>
                                          <th style="width:4%">Type</th>
                                          <th style="width:4%">Quantité</th>
                                          <th style="width:4%">F Huile</th>
                                          <th style="width:4%">Type</th>
                                          <th style="width:4%">F Air</th>
                                          <th style="width:4%">Type</th>
                                          <th style="width:4%">F Carburant</th>
                                          <th style="width:4%">Type</th>
                                          <th style="width:4%">F Separator</th>
                                          <th style="width:4%">Type</th>
                                          <th style="width:4%">Fournisseur</th>
                                          <th style="width:4%">Coût Total</th>
                                          <th style="width:4%" title="Coût main d'oeuvre">Coût M.O</th>
                                          <th style="width:4%">Dépassement</th>
                                          <th style="width:4%">Odométre</th>
                                          <th style="width:4%">Filtre Climatisation</th>
                                          <th style="width:4%">Joint</th>
                                          <th style="width:4%">Marque Huile</th>
                                          <th style="width:4%">Observations</th>
                                          <th style="width:18%"></th>
                                    </tr>
                              </thead>
                              <tbody>
                                    <tr *ngIf="loading">
                                          <td colspan="22" style="text-align: center">
                                                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                                          </td>
                                    </tr>
                                    <tr *ngIf="!loading && oilChanges.length == 0">
                                          <td colspan="23">
                                                <div class="alert alert-dismissible"
                                                      style="border-radius: 0px;text-align: center;">
                                                      <button type="button" class="close" data-dismiss="alert"
                                                            aria-hidden="true">
                                                            ×
                                                      </button>
                                                      <h4 style="color: orange">
                                                            <i class="fa fa-info-circle"></i> Information
                                                      </h4>
                                                      Veuillez ajouter des informations pour vidanges.
                                                </div>
                                          </td>
                                    </tr>
                                    <tr *ngFor="let oilChange of getOilChanges(); let i = index ">
                                          <td>
                                                <input type="checkbox" [checked]="isCheckedInList(oilChange.id)"
                                                      (click)="onChecked(oilChange.id)">
                                          </td>
                                          <td style="font-weight: bold">
                                                {{oilChange?.operationTime | date:'dd-MM-yyyy, HH:mm'}}
                                          </td>
                                          <td align="center">
                                                <img *ngIf="oilChange.engineOil" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}valid_point.png" />
                                                <img *ngIf="!oilChange.engineOil" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}error_point.png" />
                                          </td>
                                          <td>
                                                {{oilChange?.typeEngineOil}}
                                          </td>
                                          <td>
                                                {{oilChange?.quantity}}
                                          </td>
                                          <td align="center">
                                                <img *ngIf="oilChange.oilF" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}valid_point.png" />
                                                <img *ngIf="!oilChange.oilF" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}error_point.png" />
                                          </td>
                                          <td>
                                                {{oilChange?.typeOilF}}
                                          </td>
                                          <td align="center">
                                                <img *ngIf="oilChange.airF" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}valid_point.png" />
                                                <img *ngIf="!oilChange.airF" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}error_point.png" />
                                          </td>
                                          <td>
                                                {{oilChange?.typeAirF}}
                                          </td>
                                          <td align="center">
                                                <img *ngIf="oilChange.fuelF" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}valid_point.png" />
                                                <img *ngIf="!oilChange.fuelF" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}error_point.png" />
                                          </td>
                                          <td>
                                                {{oilChange?.typeFuelF}}
                                          </td>
                                          <td align="center">
                                                <img *ngIf="oilChange.separatorF" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}valid_point.png" />
                                                <img *ngIf="!oilChange.separatorF" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}error_point.png" />
                                          </td>
                                          <td>
                                                {{oilChange?.typeSeparatorF}}
                                          </td>
                                          <td>{{oilChange?.provider?.name}}</td>
                                          <td> {{oilChange?.cost | number: '.0-4'}}</td>
                                          <td> {{oilChange?.laborCost | number: '.0-4'}}</td>
                                          <td [ngClass]="getOvertaking(oilChange, i) > 0 ? 'overTaking' : 'not-overTaking'">
                                                {{getOvertaking(oilChange, i)}}
                                          </td>

                                          <td>{{oilChange?.odometre}}</td>
                                          <td align="center">
                                                <img *ngIf="oilChange.airConditioningFilter" title="Reparation"
                                                      height="15px" width="20px"
                                                      src="{{imagesPOIDir}}valid_point.png" />
                                                <img *ngIf="!oilChange.airConditioningFilter" title="Reparation"
                                                      height="15px" width="20px"
                                                      src="{{imagesPOIDir}}error_point.png" />
                                          </td>
                                          <td align="center">
                                                <img *ngIf="oilChange.seal" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}valid_point.png" />
                                                <img *ngIf="!oilChange.seal" title="Reparation" height="15px"
                                                      width="20px" src="{{imagesPOIDir}}error_point.png" />
                                          </td>
                                          <td>{{oilChange?.oilMark}}</td>
                                          <td>{{oilChange?.observation}}</td>
                                          <td>
                                                <button title="Supprimer" style="color: red;" type="button"
                                                      class="btn btn-sm btn-default"
                                                      (click)="onConfirmDelete(oilChange)">
                                                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </button>
                                                <button title="Modifier" style="color: rgb(2, 157, 196); " type="button"
                                                      class="btn btn-sm btn-default" (click)="onUpdate(oilChange)">
                                                      <i class="fa fa-edit" aria-hidden="true"></i>
                                                </button>
                                          </td>
                                    </tr>
                              </tbody>
                        </table>
                  </div>
            </div>
      </div>
</div>
<!-- ============================== ADD/UPDATE MODAL (START) ========================== -->
<div class="modal fade" bsModal #oilChangeModal="bs-modal" role="dialog">
      <div class="modal-dialog modal-lg">
            <div class="modal-content">
                  <div class="modal-header">
                        <button type="button" class="close" (click)="oilChangeModal.hide()">&times;</button>
                        <h4 *ngIf="mode=='ADD'" class="modal-title"
                              style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                              <i class="fa fa-plus"></i>
                              Ajouter informations vidange
                        </h4>
                        <h4 *ngIf="mode=='UPDATE'" class="modal-title"
                              style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                              <i class="fa fa-edit"></i>
                              Modifier informations vidange
                        </h4>
                  </div>
                  <div class="modal-body">
                        <div class="box-body">
                              <app-vidange-form [oilChange]="oilChange" [vehicule]="vehicule" [group]="group"
                                    [mode]="mode" (oilChangeWasAdded)="oilChangeWasAdded($event)"
                                    (oilChangeWasUpdated)="oilChangeWasUpdated($event)">
                              </app-vidange-form>
                        </div>
                  </div>
            </div>
      </div>
</div>
<!-- ============================== ADD/UPDATE MODAL (END) ======================== -->
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" bsModal #oilChangeDeleteModal="bs-modal" role="dialog">
      <div class="modal-dialog modal-lg">
            <div class="modal-content">
                  <div class="modal-header">
                        <button type="button" class="close" (click)="oilChangeDeleteModal.hide()">&times;</button>
                        <h4 class="modal-title">
                              <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                        </h4>
                  </div>
                  <div class="modal-body">
                        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                              <h4 style="color: white">
                                    <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                              <span *ngIf="!isDeleteMultiple">Êtes-vous sûr de supprimer cette vidange ?</span>
                              <span *ngIf="isDeleteMultiple">Êtes-vous sûr de supprimer cettes {{oilChangesId.length}}
                                    vidanges
                                    ?</span>
                        </div>
                  </div>
                  <div class="modal-footer">
                        <button type="button" class="btn btn-default" (click)="oilChangeDeleteModal.hide()">
                              Annuler
                        </button>
                        <button type="button" class="btn btn-success" (click)="onDelete()">
                              <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                        </button>
                  </div>
            </div>
      </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
<!--=================================Import fuel(start) ====================================-->
<div class="modal" role="dialog" bsModal #importModal="bs-modal">
      <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                  <div class="modal-header">
                        <button type="button" class="close" (click)="importModal.hide()">&times;
                        </button>
                        <h4 class="modal-title">
                              <i class="fa fa-cogs" aria-hidden="true"></i> Importer le fichier Excel
                        </h4>
                  </div>
                  <div class="modal-body vertical-spacer">
                        <app-import-export-forms [item]='selectedItem' [vehiculeMatricule]='vehicule.matricule'
                              [groupId]='group.idGroupe' (importedItem)='itemWasImported($event)'>
                        </app-import-export-forms>
                  </div>
                  <p></p>
                  <div class="modal-footer">
                        <button type="button" class="btn btn-default" (click)="importModal.hide()">
                              Fermer
                        </button>
                  </div>
            </div>
      </div>
</div>
<!--=================================Import fuel (end) ====================================-->
