import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";

import { Fueling, Provider, FuelingInfo, MultiType } from "../model/index";
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { DateInterval } from "../../../shared/model";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FuelingService {
  constructor(private _http: HttpClient) { }

  getListFueling(idVehicule: number): Observable<Fueling[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Fueling[]>(dns + "fuelings/list/" + idVehicule, { headers: headers });
  }

  getListFuelingByDate(idVehicule: number, date: Date): Observable<Fueling[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<Fueling[]>(dns + "fuelings/listByDate/" + idVehicule,date, { headers: headers });
  }

  getListType(type: string): Observable<MultiType[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<MultiType[]>(dns + "types/list?type=" + type, { headers: headers })
      ;
  }

  getProviders(type: string): Observable<Provider[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Provider[]>(dns + "providers/listByType?type=" + type, { headers: headers })
      ;
  }

  public getDataInExcel(
    idVehicule: number,
    date: DateInterval
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    let options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this._http
      .post<Blob>(dns + "fuelings/exportExcel/" + idVehicule, date, options);
  }

  readData(idVehicule: number, fileName: string): Observable<FuelingInfo[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<FuelingInfo[]>(dns + "fuelings/readData/" + idVehicule + "?fileName=" + fileName, {
        headers: headers
      })
      ;
  }

  delete(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete<Boolean>(dns + "fuelings/" + id, { headers: headers })
      ;
  }

  deleteMultiple(documentsId: number[]): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + "fuelings/multiple/" + documentsId, { headers: headers });
  }

  public add(fueling: Fueling, idVehicule: number, groupId: number): Observable<Fueling> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<Fueling>(dns + "fuelings?idVehicule=" + idVehicule + "&groupId=" + groupId, fueling, options);
  }

  public update(fueling: Fueling): Observable<Fueling> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .put<Fueling>(dns + "fuelings", fueling, options)
      ;
  }
}
