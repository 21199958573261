import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupItemComponent } from './group-item.component';
import { GroupsContainerComponent } from '../groups-container.component'; // Import the GroupsContainerComponent if used
import { GroupsContainerModule } from '../groups-container.module';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { GroupItemButtonsModule } from '../group-item-buttons/group-item-buttons.module';

@NgModule({
  declarations: [
    GroupItemComponent,
  ],
  imports: [
    CommonModule,
    OrderPipModule,
    PopoverModule.forRoot(),
    GroupItemButtonsModule
  ],
  exports: [
    GroupItemComponent        // Export the GroupItemComponent for use in other modules
  ]
})
export class GroupItemModule { }
