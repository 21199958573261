import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuelingPriceSettingComponent } from './fueling-price-setting.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modal functionality
import { FuelingPriceSettingService } from '../../providers/fueling-price-setting.service'; // Adjust the path as necessary
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    FuelingPriceSettingComponent // Declare the FuelingPriceSettingComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Initialize Toastr if you're using it
    FormsModule, // Include FormsModule for template-driven forms
    ModalModule.forRoot(), // Initialize ModalModule for modal functionality
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    FuelingPriceSettingService // Provide the FuelingPriceSettingService
  ],
  exports: [
    FuelingPriceSettingComponent // Export the component if it needs to be used in other modules
  ]
})
export class FuelingPriceSettingModule { }
