import { GroupDto, PointInterest } from "../../data-management";

export class CimentSiteForm {
    id : number;
    name : string;
    mainPoi : number[];
    groups : number[];
    constructor(){
        this.mainPoi = [];
        this.groups = [];
    }
}

export class CimentSite {
    id : number;
    name : string;
    mainPoi : PointInterest[];
    groups : GroupDto[];
    constructor(){
        this.mainPoi = [];
        this.groups = [];
    }
}