import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker functionalities
import { ToastrModule } from 'ngx-toastr'; // For notifications
import { ChargesPfbFormComponent } from './charges-pfb-form.component'; // Importing your component
import { ChargesPfbService } from '../charges-pfb.service'; // Service for handling charges PFB operations
import { DataManagementService } from '../../data-management/data-management.service'; // Service for managing data
import { TransportTypeService } from '../parametrage/transport-type/transport-type.service';
//import { SelectModule } from 'ng2-select';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';

//import { TransportTypeService } from '../parametrage/transport-type.service'; // For handling transport type logic

@NgModule({
  declarations: [
    ChargesPfbFormComponent, // Declaring the component
    // ChargesPfbComponent
  ],
  imports: [
    CommonModule, // Provides common Angular directives
    FormsModule,
    BsDatepickerModule.forRoot(), // Bootstrap datepicker
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    SharedPipesModule,
    NgSelectModule,
  ],
  providers: [
    ChargesPfbService, // Service for charges PFB logic
    DataManagementService, // Data management logic
    TransportTypeService // Transport type service
  ],
  exports: [
    ChargesPfbFormComponent // Exporting component for use in other modules
  ]
})
export class ChargesPfbFormModule { }
