import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatteryFormComponent } from './battery-form.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { BatteryService } from '../../../providers/battery-service'; // Import BatteryService
import { ProviderService } from '../../../providers/provider-service'; // Import ProviderService

@NgModule({
  declarations: [
    BatteryFormComponent // Declare the BatteryFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(), // Import BsDatepickerModule for date picking
    FormsModule,
  ],
  providers: [
    BatteryService, // Provide BatteryService
    ProviderService // Provide ProviderService
  ],
  exports: [
    BatteryFormComponent // Export BatteryFormComponent for use in other modules
  ]
})
export class BatteryFormModule { }
