import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { Battery } from "../model/index";
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BatteryService {

  constructor(private _http: HttpClient) { }

  public getListBatteries(idVehicule: number): Observable<Battery[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Battery[]>(dns + "batteries/list/" + idVehicule, { headers: headers });
  }

  public delete(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + "batteries/" + id, { headers: headers });
  }

  deleteMultiple(tiresId: number[]): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + "batteries/multiple/" + tiresId, { headers: headers });
  }

  public add(battery: Battery, idVehicule: number, groupId: number): Observable<Battery> {
    let headers = createAuthorizationHeader();
    return this._http.post<Battery>(dns + "batteries?idVehicule=" + idVehicule + "&groupId=" + groupId, battery, { headers: headers });
  }

  public update(battery: Battery): Observable<Battery> {
    let headers = createAuthorizationHeader();
    return this._http.put<Battery>(dns + "batteries", battery, { headers: headers });
  }
}
