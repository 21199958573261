import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { AlertManagementNewService } from '../alert-management-new.service';
import { UserAlert } from '../alert-new';
import {BsModalRef, BsModalService, ModalDirective} from "ngx-bootstrap/modal";


@Component({
  selector: 'app-alert-new',
  templateUrl: './alert-new.component.html',
  styleUrls: ['./alert-new.component.css']
})
export class AlertNewComponent implements OnInit {

  mode: "ADD" | "UPDATE" | "DELETE";
  @ViewChild('template', { static: false }) templateRef: TemplateRef<any>;
  public modalRef: BsModalRef;
  @ViewChild('confirmDeleteModal', { static: false }) confirmDeleteModal: ModalDirective;

  idDelete: number;
  listIds: number[] = [];
  data: UserAlert[] = [];

  sendDataEdit:UserAlert = new UserAlert();

  settings = {
    pager: {
      display: true,
      perPage: 10,
    },
    actions: {
      columnTitle: 'Actions',
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'delete',
          title:
            '<span class="btn btn-danger"><i class="fa fa-trash-o"></i></span>',
        },
        { name: 'edit',
        title: '<span class="btn btn-warning"><i class="fa fa-pencil-square-o"></i></span>' },
      ],
      position: 'right',
    },
    columns: {

      name: {
        title: 'Nom',
      },
      matricule: {
        title: 'Vehicule',
      },
      emails: {
        title: 'Emails',
      },
      active: {
        title: 'Etat',
        filter: {
          type: 'list',
          config: {
            selectText: 'Select...',
            list: [
              { value: 'true', title: 'true' },
              { value: 'false', title: 'false' },
            ],
          },
        },
      },
    },
  };

  constructor(
    private modalService: BsModalService,
    private alertService: AlertManagementNewService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.loadAllUserAlertes();
  }
  selectedItemfromPoiId(value1: string): number[] {
    let poiId = ('' + value1).split(';');
    let pois: number[] = [];
    for (let p of poiId) {
      pois.push(+p);
    }
    return pois;
  }

  loadAllUserAlertes() {
    this.alertService.getAllUserAlertes().subscribe((res) => {
      this.data = res;
    });
  }
  onCustomAction(event: any) {
    switch (event.action) {
      case 'delete':
        this.idDelete = event.data.id;
        this.confirmDeleteModal.show();
        break;
      case 'edit':
        this.sendDataEdit = event.data;
        this.mode = "UPDATE";
        this.modalRef = this.modalService.show(this.templateRef);
    }
  }

  delete() {
    this.alertService.deleteUserAlert(this.idDelete).subscribe((suprimed) => {
      if (suprimed) {
        this.confirmDeleteModal.hide();
        this.loadAllUserAlertes();
        this.toastr.success('Votre suppression est bien effectuée!', 'info');
      } else {
        this.toastr.error("L'operation de suppression est annulée!", 'Error');
      }
    });
  }



  openModal(template: TemplateRef<any>) {
    this.sendDataEdit = new UserAlert();
    this.mode = "ADD";
    this.modalRef = this.modalService.show(template);
  }

}
