import { Component, OnInit } from '@angular/core';
import { Form, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FuelingTemplateSetting } from '../../model/index';
import { FuelingTemplateSettingService } from '../../providers/fueling-template-setting.service';

@Component({
  selector: 'app-carburant-template-setting',
  templateUrl: './carburant-template-setting.component.html',
  styleUrls: ['./carburant-template-setting.component.css']
})
export class CarburantTemplateSettingComponent implements OnInit {

  error: { errorMessage: string } = { errorMessage: '' };
  fuelingTemplateSetting: FuelingTemplateSetting = new FuelingTemplateSetting();
  loading = false;
  globalLoading: boolean = false;

  constructor(public toastr: ToastrService, private fuelingTemplateSettingService: FuelingTemplateSettingService) { }

  ngOnInit() {
    this.loadTemplateSetting();
  }

  loadTemplateSetting() {
    this.globalLoading = true;
    this.fuelingTemplateSettingService.getFuelingTemplateSetting().finally(() => {
      this.globalLoading = false;
    }).subscribe(fuelingTemplateSetting => {
      if (fuelingTemplateSetting)
        this.fuelingTemplateSetting = fuelingTemplateSetting;
    },
      error => {
        this.error = error;
        this.error = JSON.parse(JSON.stringify(error.error || null));

        if (!this.error.errorMessage) {
          this.error.errorMessage = "SERVER ERROR";
        }
      });
  }

  cancel(templateForm: NgForm) {
    templateForm.form.reset();
  }

  saveTemplateSetting() {
    this.loading = true;
    this.fuelingTemplateSettingService
      .save(this.fuelingTemplateSetting)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        fuelingTemplateSetting => {
          this.loading = false;
          this.toastr.success("enregistré avec succès !");
          this.fuelingTemplateSetting = fuelingTemplateSetting;
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

}
