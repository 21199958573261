<div class="content">

    <button type="button" class="btn btn-success" (click)="mode = 'ADD';init();addEditModal.show()">
        <i class="fa fa-plus-circle"></i> Ajouter
    </button>

    <table class="table  table-fixed table-striped table-hover table-condensed">
        <thead class="thead-dark">

            <tr>
                <th align="left" style="width:10%">Crée le</th>
                <th align="left" style="width:20%">Demande</th>
                <th align="left" style="width:20%">Détails</th>
                <th align="left" style="width:5%">Priorité</th>
                <th align="left" style="width:5%">Etat</th>
                <th align="left" style="width:10%">Traité le</th>
                <th align="left" style="width:20%">Réponse</th>
                <th align="left" style="width:10%"></th>
            </tr>
        </thead>

        <tr *ngIf="loading">
            <td style="text-align: center" colspan="14">
                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </td>
        </tr>

        <tr *ngIf="!loading && intervetions.length == 0">
            <td colspan="14">

                <div class="alert alert-warning alert-dismissible" style="border-radius: 0px;text-align: center">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                        ×
                    </button>
                    <h4 style="color: white">
                        <i class="fa fa-info-circle"></i> Information
                    </h4>
                    Veuillez ajouter des demandes.
                </div>

            </td>
        </tr>

        <tbody *ngIf="!loading && intervetions.length > 0">
            <tr *ngFor="let intervetion of intervetions; let i = index ">
                <td style="width:10%;font-weight: bold">{{intervetion?.createdAt | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                <td style="width:20%">{{intervetion?.request}}</td>
                <td style="width:20%">{{intervetion?.content}}</td>
                <td style="width:5%;font-weight: bold">

                    <span *ngIf="intervetion?.priority == 0">Faible</span>
                    <span *ngIf="intervetion?.priority == 1">Moyen</span>
                    <span *ngIf="intervetion?.priority == 2">Urgent</span>

                </td>
                <td style="width:5%">

                    <span *ngIf="intervetion?.type == 'REQUEST'" data-toggle="tooltip" class="badge bg-orange">
                        Demande d'intervention
                    </span>

                    <span *ngIf="intervetion?.type == 'INPROGRESS'" data-toggle="tooltip" class="badge bg-blue">
                        En cours de traitement
                    </span>

                    <span *ngIf="intervetion?.type == 'CARRYOUT'" data-toggle="tooltip" class="badge bg-green">
                        Intervention effectuée
                    </span>

                    <span *ngIf="intervetion?.type == 'REJECTED'" data-toggle="tooltip" class="badge bg-red">
                        Intervention annulée
                    </span>

                </td>
                <td style="width:10%;font-weight: bold">{{intervetion?.submitAt | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                <td style="width:20%">{{intervetion?.response}}</td>
                <td style="width:10%">

                    <div class="pull-right" style="margin-top: 1.5%;" *ngIf="intervetion?.type == 'REQUEST' || intervetion?.type == 'INPROGRESS'">
                        <div class="btn-group">
                            <button (click)="currentIntervention = intervetion; deleteModal.show()" style="color: red;"
                                type="button" class="btn  btn-default">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                            <button (click)="mode ='UPDATE';currentIntervention = intervetion; addEditModal.show()"
                                style="color: rgb(2, 157, 196); " type="button" class="btn  btn-default">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row" *ngIf="intervetions.length >0">
    <div class="col-md-12" style="text-align: center">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" [maxSize]="maxSize" class="pagination-sm"
            [boundaryLinks]="true" [rotate]="false" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"></pagination>
    </div>
</div>

<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" tabindex="-1" #deleteModal="bs-modal" bsModal role="dialog" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="deleteModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                    Êtes-vous sûr de supprimer cette demande ?
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="deleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="onDelete()">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->


<!-- ========================================== ADD/Edit Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #addEditModal="bs-modal" bsModal role="dialog" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header">

                <button type="button" class="close" (click)="addEditModal.hide()">&times;</button>

                <h4 *ngIf="mode == 'ADD'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-plus"></i>
                    Ajouter une demande</h4>

                <h4 *ngIf="mode == 'UPDATE'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-edit"></i>
                    Modifier une demande</h4>
            </div>

            <div class="modal-body">
                <div class="box-body">
                    <app-intervention-form (wasAdded)="addEditModal.hide();loadIntervetions()" [mode]="mode"
                        [intervention]="currentIntervention"></app-intervention-form>
                </div>
            </div>

        </div>

    </div>
</div>
<!-- ========================================== ADD/Edit Modal (END) ====================================================-->
