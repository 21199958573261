import { PointInterest } from "src/app/client-management/data-management";

export class Passage{
    idPassage : number;
    name : string;
    numberOfPassage : number;
    mainPoi : PointInterest[];
    secondaryPoi : PointInterest[];

    constructor(){
        this.mainPoi = [];
        this.secondaryPoi = [];
    }

}

export class PassageForm{

    idPassage : number;
    name : string;
    numberOfPassage : number;
    mainPoi : number[];
    secondaryPoi : number[];

}