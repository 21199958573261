// left-sidebar.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSidebarComponent } from './left-sidebar.component';
import { SigninService } from '../authentification/signin/signin.service'; // Import the SigninService
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    LeftSidebarComponent // Declare the LeftSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    LeftSidebarComponent // Export the component for use in other modules
  ],
  providers: [
    SigninService // Register the SigninService for dependency injection
  ]
})
export class LeftSidebarModule { }
