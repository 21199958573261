import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DataManagementService, Group, Vehicule } from 'src/app/client-management/data-management';

@Component({
  selector: 'app-clocation',
  templateUrl: './clocation.component.html',
  styleUrls: ['./clocation.component.css']
})
export class CLocationComponent implements OnInit {

  selectedVehicule: Vehicule | null = null;

  selectedGroup: Group | null = null;

  getVehiculeWasSelectedSubscription!: Subscription;

  getGroupWasSelectedSubscription!: Subscription;

  documentType: 'C_LOCATION' = 'C_LOCATION';

  constructor(public toastr: ToastrService, private dataManagementService: DataManagementService) { }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
      this.selectedGroup = this.dataManagementService.selectedGroup;
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.selectedVehicule = vehicule;
    });
    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.selectedGroup = group;
    });
  }

  ngOnDestroy() {
    if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
  }
}
