import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentCirculationComponent } from './document-circulation.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using template-driven forms
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule if you're using reactive forms
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for ngx-bootstrap modals
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for toast notifications
import { DocumentCirculationService } from '../providers/document-circulation-service'; // Adjust the path as necessary
import { ImportExportFormsModule } from '../import-export-forms/import-export-forms.module';
import { DocumentFormComponent } from './document-form/document-form.component';
import { DocumentFormModule } from './document-form/document-form.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    DocumentCirculationComponent,

  ],
  imports: [
    CommonModule, // Include CommonModule for common directives
    FormsModule, // Include FormsModule if you are using forms
    ReactiveFormsModule, // Include ReactiveFormsModule if you are using reactive forms
    ModalModule.forRoot(), // Initialize ModalModule for ngx-bootstrap
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ImportExportFormsModule,
    DocumentFormModule,
  ],
  providers: [
    DocumentCirculationService // Provide your service here
  ],
  exports: [
    DocumentCirculationComponent // Export the component if it needs to be used in other modules
  ]
})
export class DocumentCirculationModule { }
