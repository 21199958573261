import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import if you're using forms or ngModel
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you're using ToastrService
import { SpeedSettingsComponent } from './speed-settings.component'; // Adjust the path as necessary
import { NgxLoadingModule } from 'ngx-loading';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    SpeedSettingsComponent,
     // Declare SpeedSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule, // Include if using forms or ngModel
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    EmailManagerModule,
    CronSettingsModule,
    MatSlideToggleModule

    // Include ToastrModule if using ToastrService
  ],
  exports: [
    SpeedSettingsComponent // Export SpeedSettingsComponent if needed in other modules
  ]
})
export class SpeedSettingsModule { }
