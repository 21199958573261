<div class="row">

  <div class="col-md-12">

    <!-- Error Section !-->
    <ng-container *ngIf="error">
      <div class="col-md-12">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
            ×
          </button>
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          {{error.errorMessage}}
        </div>
      </div>
    </ng-container>

    <div class="container">
      <!-- form -->
      <div class="col-lg-12 well formBC">
        <div class="row">
          <form #templateForm="ngForm" class="form-horizontal">

            <div class="form-group has-success form-horizontal">
              <!-- date -->
              <label class="col-sm-2 control-label">Date *</label>
              <div class="col-lg-2 col-md-2">
                <input required type="text" class="form-control input-sm" bsDatepicker name="date"
                  [(ngModel)]="fuelingPriceSetting.startDate" [bsConfig]="{
                dateInputFormat: 'DD-MM-YYYY',
                containerClass: 'theme-dark-blue'
              }" placement="bottom" autocomplete="off" placeholder="Date" />
              </div>
              <label class="col-sm-2 control-label">Prix unitaire *</label>
              <div class="col-lg-2 col-md-2">
                <input class="form-control input-sm" required [(ngModel)]="fuelingPriceSetting.unitCost" name="unitCost"
                  type="number" placeholder="Prix unitaire" class="form-control" min="0">
              </div>

              <div class="col-sm-2 mrg">
                <button (click)="saveTemplateSetting(templateForm)" class="btn btn-info pull-right"
                  [disabled]="!templateForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="globalLoading"></i> Enregistrer
                </button>
              </div>

              <div class="col-sm-2 mrg">
                <button class=" btn btn-dark" (click)="cancel(templateForm)">
                  Annuler
                </button>
              </div>
            </div>


          </form>
        </div>
      </div>

      <div class="col-lg-12">
        <!-- ========================================== LIST settings (START) ====================================================-->
        <div class="content">
          <div class="table-responsive tableFixHead">
            <table class="table table-fixed table-striped table-hover table-condensed">
              <thead>
                <tr>
                  <th style="width: 40%;">Date</th>
                  <th style="width: 40%;">Prix unitaire</th>
                  <th style="width: 20%;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="globalLoading">
                  <td style="text-align: center" colspan="7">
                    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                  </td>
                </tr>
                <tr *ngIf="!globalLoading && fuelingPriceSettings.length == 0">
                  <td colspan="7">
                    <div class="alert alert-warning alert-dismissible" style="border-radius: 0px;text-align: center">
                      <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                        ×
                      </button>
                      <h4 style="color: white">
                        <i class="fa fa-info-circle"></i> Information
                      </h4>
                      Veuillez ajouter des informations.
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let fuelingSetting of fuelingPriceSettings">
                  <td>{{fuelingSetting?.startDate | date: 'dd-MM-yyyy'}}
                  </td>
                  <td>
                    {{fuelingSetting?.unitCost}}
                  </td>
                  <td>
                    <button title="Supprimer" style="color: red;" type="button" class="btn btn-sm btn-default"
                      (click)="onConfirmDelete(fuelingSetting)">
                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- ========================================== LIST SETTINGS (END) ====================================================-->
      </div>
    </div>
  </div>
</div>

<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" bsModal #fuelingPriceSettingDeleteModal="bs-modal" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="fuelingPriceSettingDeleteModal.hide()">&times;</button>
        <h4 class="modal-title">
          <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          Êtes-vous sûr de supprimer ce paramètre ?
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="fuelingPriceSettingDeleteModal.hide()">
          Annuler
        </button>
        <button type="button" class="btn btn-success" (click)="onDelete()">
          <i class="fa fa-check" aria-hidden="true" *ngIf="!globalLoading"></i>
          <i class="fa fa-spinner fa-spin" *ngIf="globalLoading"></i> Oui, Je confirme
        </button>
      </div>
    </div>
  </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
