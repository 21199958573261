<div class="table-responsive tableFixHead">
      <table class="table table-fixed table-striped table-hover table-condensed">
            <thead>
                  <tr>
                        <th style="width: 2%; text-align: left;"><input type="checkbox" [disabled]="driverCharges.length == 0"
                                    [checked]="driverCharges.length == driverChargesId.length" (click)="onCheckedAll()"></th>
                        <th style="width:5%">Date</th>
                        <th style="width:5%">Salaire</th>
                        <th style="width:5%">Téléphone</th>
                        <th style="width:5%">Commission</th>
                        <th style="width:5%">Note Frais</th>
                        <th style="width:5%">Total <span style="background-color: white; color: #3c8dbc">{{totalAmount | number: '.0-4'}}</span></th>
                        <th style="width:5%">Chiffre d'affaire</th>
                        <th style="width:48%" style="border-left-style:none;"></th>
                        <th style="width:5%"></th>
                  </tr>
            </thead>

            <tbody>
                  <tr *ngIf="loading">
                        <td colspan="10" style="text-align: center">
                              <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                        </td>
                  </tr>
                  <tr *ngIf="!loading && driverCharges.length == 0">
                        <td colspan="10">
                              <div class="alert alert-dismissible" style="border-radius: 0px;text-align: center;background-color: #3c8dbc;">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                          ×
                                    </button>
                                    <h4 style="color: white">
                                          <i class="fa fa-info-circle"></i> Information
                                    </h4>
                                    Veuillez ajouter des informations pour charge conducteur.
                              </div>
                        </td>
                  </tr>
                  <tr *ngFor="let driverCharge of driverCharges">
                        <td>
                              <input type="checkbox" [checked]="isCheckedInList(driverCharge.id)" (click)="onChecked(driverCharge.id)">
                        </td>
                        <td style="font-weight: bold">{{driverCharge?.createAt | date: 'dd-MM-yyyy, HH:mm'}}
                        </td>
                        <td>
                              {{driverCharge?.salary}}
                              <span class="label label-info">{{(driverCharge?.salary * 100) / driverCharge?.total |
                                    number: '.0-2'}}%</span>
                        </td>
                        <td>
                              {{driverCharge?.phone}}
                              <span class="label label-info">{{(driverCharge?.phone * 100) / driverCharge?.total |
                                    number: '.0-2'}}%</span>
                        </td>
                        <td>
                              {{driverCharge?.commission}}
                              <span class="label label-info">{{(driverCharge?.commission * 100) / driverCharge?.total |
                                    number: '.0-2'}}%</span>
                        </td>
                        <td>
                              {{driverCharge?.note}}
                              <span class="label label-info">{{(driverCharge?.note * 100) / driverCharge?.total |
                                    number: '.0-2'}}%</span>
                        </td>
                        <td>
                              {{driverCharge?.total | number: '.0-4'}}
                        </td>
                        <td>
                              {{driverCharge?.turnover}}
                        </td>
                        <td></td>
                        <td>
                              <button title="Supprimer" style="color: red;" type="button" class="btn btn-sm btn-default"
                                    (click)="onConfirmDelete(driverCharge)">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </button>
                              <button title="Modifier" style="color: rgb(2, 157, 196); " type="button" class="btn btn-sm btn-default"
                                    (click)="onUpdate(driverCharge)">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                              </button>
                        </td>
                  </tr>
            </tbody>
      </table>
</div>

<!-- ============================== ADD/UPDATE MODAL (START) ========================== -->
<div class="modal fade" bsModal #driverChargeModal="bs-modal" role="dialog">
      <div class="modal-dialog modal-lg">
            <div class="modal-content">
                  <div class="modal-header">
                        <button type="button" class="close" (click)="driverChargeModal.hide()">&times;</button>
                        <h4 *ngIf="mode=='ADD'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                              <i class="fa fa-plus"></i>
                              Ajouter informations charge conducteur
                        </h4>
                        <h4 *ngIf="mode=='UPDATE'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                              <i class="fa fa-edit"></i>
                              Modifier informations charge conducteur
                        </h4>
                  </div>
                  <div class="modal-body">
                        <div class="box-body">
                              <app-driver-charge-form [driverCharge]="driverCharge" [group]="_group" [mode]="mode" [driverChargeModal]="driverChargeModal"
                                    (driverChargeWasAddedOrUpdated)="driverChargeWasAddedOrUpdated($event)">
                              </app-driver-charge-form>
                        </div>
                  </div>
            </div>
      </div>
</div>
<!-- ============================== ADD/UPDATE MODAL (END) ======================== -->
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" bsModal #driverChargeDeleteModal="bs-modal" role="dialog">
      <div class="modal-dialog modal-lg">
            <div class="modal-content">
                  <div class="modal-header">
                        <button type="button" class="close" (click)="driverChargeDeleteModal.hide()">&times;</button>
                        <h4 class="modal-title">
                              <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                        </h4>
                  </div>
                  <div class="modal-body">
                        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                              <h4 style="color: white">
                                    <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                              <span *ngIf="!isDeleteMultiple">Êtes-vous sûr de supprimer ce charge conducteur ?</span>
                              <span *ngIf="isDeleteMultiple">Êtes-vous sûr de supprimer ces {{driverChargesId.length}}
                                    charges conducteur
                                    ?</span>
                        </div>
                  </div>
                  <div class="modal-footer">
                        <button type="button" class="btn btn-default" (click)="driverChargeDeleteModal.hide()">
                              Annuler
                        </button>
                        <button type="button" class="btn btn-success" (click)="onDelete()">
                              <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                        </button>
                  </div>
            </div>
      </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
