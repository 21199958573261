<div class=" mt-2 mb-2 text-center well bgg">
  <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
    <i class="fa fa-plus-circle add"></i>
    <span> Balance Supply Chain - Historique Transport</span>
  </h4>
</div>
<div class="row" style="font-family: 'Inconsolata', monospace;">
  <section class="content col-md-12 groups-container">
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-12" style="margin-top: -10px;">
        <div class="box" style="border-radius: 0px;min-height: unset;">
          <div class="box-body">
            <form class="form-horizontal" #trackingForm="ngForm">
              <div class="form-group row">
                <label class="col-sm-1 control-label grp-lbl">Pôle</label>
                <div class="col-sm-1 grp-slct">
                  <select class="form-control input-sm" name="transportType" [ngModel]="selectedTransport"
                    (ngModelChange)="chooseTransport($event)" required>
                    <option *ngFor="let transport of transportTypeService?.transportTypes" [ngValue]="transport">
                      {{transport?.name}}
                    </option>
                  </select>
                </div>

                <label class="col-sm-1 control-label grp-lbl">Année</label>
                <div class="col-sm-1 grp-slct">
                  <select class="form-control input-sm" [(ngModel)]='selectedYear'
                    (ngModelChange)="onYearChange()" name='selectedYear' required>
                    <option style="font-size: 14px;" *ngFor="let year of years" [value]="year.id">
                      {{year.name}}</option>
                  </select>
                </div>

                <label class="col-sm-1 control-label grp-lbl">Mois</label>
                <div class="col-sm-1 grp-slct">
                  <select class="form-control input-sm" [(ngModel)]='selectedMonth'
                    (ngModelChange)="onMonthChange($event)" name='selectedMonth' required>
                    <option [ngValue]="null" style="font-weight: bold;">Choisir le mois</option>
                    <option style="font-size: 14px;" *ngFor="let month of months" [value]="month.id">
                      {{month.name}}</option>
                  </select>
                </div>

                <label class="col-sm-1 control-label grp-lbl">Site</label>
                <div class="col-sm-1 grp-slct">
                  <select class="form-control input-sm" name="chargesPfb" [(ngModel)]="selectedCharge"
                    (ngModelChange)="chooseCharge($event)" required>
                    <option *ngFor="let chargePfb of filtredChargesPfb" [ngValue]="chargePfb">
                      {{chargePfb?.name }}
                    </option>
                  </select>
                </div>

                <div class="col-lg-2 form-group">
                  <label class="col-lg-2 col-md-2 control-label">Du</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                      [(ngModel)]="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="firstDay"
                      [maxDate]="endDate" placement="bottom" autocomplete="off" required
                      (ngModelChange)="onChangeStartDate()">
                  </div>
                </div>

                <div class="col-lg-2 orm-group">
                  <label class="col-lg-2 col-md-2 control-label">Au</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="startDate" [maxDate]="lastDay"
                      placement="bottom" autocomplete="off" required>
                  </div>
                </div>



              </div>
              <div class="form-group row">
                <div class="col-sm-12 form-btns" style="text-align: center;">

                  <ng-container *ngIf="isManually">
                    <button type="button" class="btn btn-primary btn-flat btn-sm"
                      [disabled]="loadingFuelTracking || loadingCATracking || !trackingForm.form.valid" (click)="manualData()" title="Valider">
                      <b><i class="fa fa-truck" aria-hidden="true"></i></b>
                    </button>
                    </ng-container>

                  <ng-container *ngIf="!isManually">
                    <button type="button" class="btn btn-primary btn-flat btn-sm"
                      [disabled]="loadingCATracking || !trackingForm.form.valid" (click)="onTracking()" title="Valider">
                      <b><i class="fa fa-truck" aria-hidden="true"></i></b>
                    </button>
                    <button type="button" class="btn btn-success btn-flat btn-sm" [disabled]="loadingFuelTracking || !trackingForm.form.valid"
                      (click)="onFuelTracking()" title="Gasoil">
                      <b><i class="fa fa-tint" aria-hidden="true"></i></b>
                    </button>
                  </ng-container>
                  <!-- <button class="btn btn-primary btn-sm" (click)="export()" [disabled]="!trackingForm.form.valid" title="Excel">
                    <i *ngIf="!loader" class="fa fa-file-excel-o"></i>
                    <i *ngIf="loader" class="fa fa-refresh fa-spin"></i>
                  </button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingCATracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingCATracking"></i> Chargement ...
              </div>
              <app-correlation-location [isManually]="isManually" [selectedCharges]="selectedCharge" [dayDifference]="dayDif"
                [caRealise]="crCaRealise" [voyage]="voyageData"></app-correlation-location>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingFuelTracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingFuelTracking"></i> Chargement ...
              </div>
              <app-correlation-gasoil [isManually]="isManually" [selectedCharges]="selectedCharge" [dayDifference]="dayDif"
              [fueling]="fueling" [caRealise]="crCaRealise"></app-correlation-gasoil>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row" style="margin-top:32px;">



      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingCATracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingCATracking"></i> Chargement ...
              </div>
              <app-location-cost [isManually]="isManually" [selectedCharges]="selectedCharge" [locationDayDifference]="dayDif"
                [caRealise]="crCaRealise" [voyage]="voyageData"></app-location-cost>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingFuelTracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingFuelTracking"></i> Chargement ...
              </div>
              <app-fueling-charge [isManually]="isManually" [selectedCharges]="selectedCharge" [dayDifference]="dayDif"
               [caRealise]="crCaRealise" [fueling]="fueling">
              </app-fueling-charge>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingCATracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingCATracking"></i> Chargement ...
              </div>
              <app-turnover-achieved [selectedCharges]="selectedCharge" [caRealise]="caRealise"
                [dayDifference]="dayDif">
              </app-turnover-achieved>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingCATracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin" *ngIf="loadingCATracking"></i> Chargement ...
              </div>
              <app-rotation-charge [isManually]="isManually" [selectedCharges]="selectedCharge" [caRealise]="caRealise" [dayDifference]="dayDif"
              [voyage]="voyageData">
              </app-rotation-charge>
            </div>
          </div>
        </div>
      </div>

    </div>


  </section>
</div>
