import { Group } from './../../client-management/parc-management/model/index';

export class AffectationInfo {
    idChargeChantier?: number;
    chiffreAffaire: number;
    nbrJours: number;
    budgetCarburant: number;
    budgetLocation: number;
    budgetMaintenance: number;
    creat_at: Date;
    active: boolean;
    locationsRealise: LocationRealise[];
    group: Group;

    constructor(){
        this.group = new Group();
    }
}

export class AffectationAdd {
    idChargeChantier?: number;
    chiffreAffaire: number;
    nbrJours: number;
    budgetCarburant: number;
    budgetLocation: number;
    budgetMaintenance: number;
    creat_at: Date;
    active: boolean;
    locationsRealise: LocationRealise[];
    group: number;

    constructor(){
        this.locationsRealise=[]
    }
}

export class LocationRealise {
    idLocationChant?: number;
    operationTime: Date | null;
    location: number;
    caRealise: number;
    carburant: number;
    maintenanceRealise: number;

    constructor(){
        this.operationTime = new Date();
    }
}
