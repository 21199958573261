export class Schedule{
    deviceId : number;
    matricule : string;
    submark : string;
    beginStopTime : Date;
    endStopTime : Date;
    color : string;
    type : string;
    name :string;
    active : boolean;
    duration : number;
    poiDepart : string;
    poiArrive : string;
    dateStart : Date;
    vehiculeId: number;
    
}

export class ScheduleForm{
    deviceId : number;
    matricule : string;
    beginStopTime : Date;
    endStopTime : Date;
    name :string;
    passage : number;
    type : string;
    active : boolean;
    emails : string;
    constructor(){
        this.active = false;
    }
}

export class OtNumber{
    id: number;
    name: string ;
    otNumber: string ;
    deviceId: number;
    idVehicule:number;
    mission:string;
    missionType: string;
    nbVoyage: number;
    message: string;
    createdAt: Date;
   constructor(){}
}