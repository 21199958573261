import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { VidangeChartService } from './vidange-chart.service';
import { DateInterval } from 'src/app/shared/model';
import * as Highcharts2 from 'highcharts';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { GroupDto } from 'src/app/client-management/data-management';
require('highcharts/modules/no-data-to-display')(Highcharts2);

@Component({
  selector: 'app-vidange-chart',
  templateUrl: './vidange-chart.component.html',
  styleUrls: ['./vidange-chart.component.css'],
})
export class VidangeChartComponent implements OnInit {
  showModal: boolean = false;

  date: DateInterval = new DateInterval();
  endDate: Date = new Date();
  startDate: Date = new Date();
  groupeStatisticsofCarb: any[] = [];
  barChart: Object | null = null;
  barChartModal: Object | null = null;
  optionsSelect: Array<any>;
   ChartTypes: any[] = [];
  globalLoading: boolean;
  scrollToCstmElement: boolean = false;
  chartTitle: string = 'Carburant'; // Par Groupe
  DefaultType: string = 'FUELING';
  IsByParent = false;
  isByMultipleGroups: boolean = false;
  groupIds: GroupDto[] = [];

  @Input()
  groups: GroupDto[] = [];

  groupDropdownSettings = {};

  constructor(
    private vidangeChartService: VidangeChartService,
    public datepipe: DatePipe,
    public toastr: ToastrService
  ) {
    this.ChartTypes = this.getChartTypes();
  }
  @ViewChild('customScroll', { static: false }) MyScroller: ElementRef;

  ngOnInit() {
    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.getInfoVehiculeDashbord(this.DefaultType);
  }


  toTop() {
    this.MyScroller.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  onChange(TypeChart: any) {
    this.scrollToCstmElement = true;
    if (TypeChart == 'OILCHANGE' || TypeChart == 'FUELING') {
      this.DefaultType = TypeChart;
      if (!this.isByMultipleGroups) {
        this.getInfoVehiculeDashbord(TypeChart);
        if (TypeChart == 'OILCHANGE') {
          this.chartTitle = 'Vidanges'; // Par Groupe
        } else if (TypeChart == 'FUELING') {
          this.chartTitle = 'Carburant'; //  Par Groupe
        }
      }
    } else {
      this.toastr.error('Type Non Valide !', 'Erreur');
    }
  }

  onChangeType() {
    this.isByMultipleGroups = false;
    this.getInfoVehiculeDashbord(this.DefaultType);
  }

  getChartTypes() {
    return [
      { id: 'FUELING', name: 'Carburant' },
      { id: 'OILCHANGE', name: 'Vidange' },
    ];
  }

  onValidate() {
    this.getInfoVehiculeDashbord(this.DefaultType);
  }

  onChangeByMultipleGroups() {
    if (!this.isByMultipleGroups) {
      this.groupIds = [];
      this.getInfoVehiculeDashbord(this.DefaultType);
    }
  }

  getInfoVehiculeDashbord(TypeChart: string) {
    this.globalLoading = true;
    if (this.IsByParent) this.groupIds = [];
    var groups = this.groupIds.map((g) => g.idGroupe).toString();
    this.vidangeChartService
      .getDashbordInfoByType(TypeChart, groups, this.IsByParent)
      .subscribe((response) => {
        this.globalLoading = false;
        this.groupeStatisticsofCarb = response;
        this.vidangeChart();
      });
  }

  groupByFatherIdAndSumAllvalues(): any {
    const calculated = this.groupeStatisticsofCarb.reduce((acc, item) => {
      let accItem = acc.find((ai: { idGroupe: any; }) => ai.idGroupe === item.idGroupe);

      if (accItem) {
        accItem.totalThisMonth += item.totalThisMonth;
        accItem.totalPreviousMonth += item.totalPreviousMonth;
        accItem.totalBeforePreviousMonth += item.totalBeforePreviousMonth;
      } else {
        acc.push(item);
      }

      return acc;
    }, []);
    return calculated;
  }

  vidangeChart() {
    // Create the chart
    var dataThisMonth: any[][] = [];
    var dataPreviousMonth: any[][] = [];
    var dataBeforePreviousMonth: any[][] = [];
    /** Category **/
    var cat: any[] = [];
    var total = 0;

    if (this.IsByParent) {
      this.groupeStatisticsofCarb = this.groupByFatherIdAndSumAllvalues();
    }

    var groupesName = new Set(
      this.groupeStatisticsofCarb.map((s) => {
        return s;
      })
    );

    for (var i = 0; i < this.groupeStatisticsofCarb.length; i++) {
      total +=
        this.groupeStatisticsofCarb[i]
          .totalDocumentAndDriverChargeAndMaintenance;
    }

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let thisMonth = this.datepipe.transform(
      firstDay,
      'MMM-yyyy',
      '',
      'fr-fr'
    );
    var lastDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    let previousMonth = this.datepipe.transform(
      lastDay,
      'MMM-yyyy',
      '',
      'fr-fr'
    );
    var beforeLastDay = new Date(date.getFullYear(), date.getMonth() - 2, 1);
    let beforePreviousMonth = this.datepipe.transform(
      beforeLastDay,
      'MMM-yyyy',
      '',
      'fr-fr'
    );
    var sumOfThisMonth = 0;
    var sumOfPreviousMonth = 0;
    var sumOfBeforePreviousMonth = 0;

    groupesName.forEach((groupeName) => {
      cat.push(groupeName.nom);
      sumOfThisMonth += groupeName.totalThisMonth;
      sumOfPreviousMonth += groupeName.totalPreviousMonth;
      sumOfBeforePreviousMonth += groupeName.totalBeforePreviousMonth;
      dataThisMonth.push([groupeName.totalThisMonth]);
      dataPreviousMonth.push([groupeName.totalPreviousMonth]);
      dataBeforePreviousMonth.push([groupeName.totalBeforePreviousMonth]);
    });

    Highcharts2.setOptions({
      //colors:['#35a9e7','#4fa0e3','#6998de','#838fdb','#9d86d7','#b77ed3','#d176cf','#eb6ecb','#e77e22','#e5833a','#e98952','#ea8e6a','#ec9482','#eda99a','#eeaec2','#efb3da','#f0b8f2','#f2bdf9','#f3c3fa','#f4c8fb','#f5cdfa','#f6d2f9','#f7d7f8','#f8dcf7','#f9e2f6','#fad7f5','#fbcdf4','#fcc2f3','#fda7f2','#fe9df1','#29ad46'],
      colors: ['#007bff', '	#66b92e', '#d65b4a'],
    });

    this.barChart = {
      chart: {
        type: 'column',
        height: 385,
      },
      title: {
        text: this.chartTitle,
        style: {
          fontWeight: 'bold',
        },
      },
      xAxis: {
        categories: cat,
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            // fontSize: '11px',
            textDecoration: 'underline',
          },
        },
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'DH',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
          rotation: -90,
        },
      },
      tooltip: {
        valueSuffix: ' DH',
        pointFormat: '{point.y:,.2f}',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        shadow: true,
        fontWeight: 'Bold',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: thisMonth,
          data: dataThisMonth,
        },
        {
          name: previousMonth,
          data: dataPreviousMonth,
        },
        {
          name: beforePreviousMonth,
          data: dataBeforePreviousMonth,
        },
        {
          type: 'pie',
          name: 'Total',
          data: [
            {
              name: thisMonth,
              y: sumOfThisMonth,
            },
            {
              name: previousMonth,
              y: sumOfPreviousMonth,
            },
            {
              name: beforePreviousMonth,
              y: sumOfBeforePreviousMonth,
            },
          ],
          center: [100, 80],
          size: 70,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      lang: {
        noData: "Aucune donnée n'est disponible",
        decimalPoint: ',',
        thousandsSep: ' ',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
    };
    this.barChartModal = {
      chart: {
        type: 'column',
      },
      title: {
        text: this.chartTitle,
        style: {
          fontWeight: 'bold',
        },
      },
      xAxis: {
        categories: cat,
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            // fontSize: '11px',
            textDecoration: 'underline',
          },
        },
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'DH',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
          rotation: -90,
        },
      },
      tooltip: {
        valueSuffix: ' DH',
        pointFormat: '{point.y:,.2f}',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        shadow: true,
        fontWeight: 'Bold',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: thisMonth,
          data: dataThisMonth,
        },
        {
          name: previousMonth,
          data: dataPreviousMonth,
        },
        {
          name: beforePreviousMonth,
          data: dataBeforePreviousMonth,
        },
        {
          type: 'pie',
          name: 'Total',
          data: [
            {
              name: thisMonth,
              y: sumOfThisMonth,
            },
            {
              name: previousMonth,
              y: sumOfPreviousMonth,
            },
            {
              name: beforePreviousMonth,
              y: sumOfBeforePreviousMonth,
            },
          ],
          center: [100, 80],
          size: 70,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      lang: {
        noData: "Aucune donnée n'est disponible",
        decimalPoint: ',',
        thousandsSep: ' ',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
    };
    Highcharts2.chart('bar', this.barChart);
    Highcharts2.chart('barModal', this.barChartModal);
  }
}
