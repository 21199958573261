import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeoMarketingComponent } from './geo-marketing.component';
import { GeoMarketingService } from './geo-marketing.service';
import { DataManagementComponent } from '../data-management';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if needed
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule if you need modals
import { NgxLoadingModule } from 'ngx-loading';
import { GeoMarketingFormComponent } from './geo-marketing-form/geo-marketing-form.component';
import { GeoMarketingFormModule } from './geo-marketing-form/geo-marketing-form.module';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    GeoMarketingComponent,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Initialize Toastr if used
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    GeoMarketingFormModule,
    FilterPipeModule
  ],
  providers: [
    GeoMarketingService, // Provide the GeoMarketingService
    DataManagementComponent, // Provide DataManagementComponent if it's needed
  ],
  exports: [
    GeoMarketingComponent, // Export the component if it needs to be used in other modules
  ]
})
export class GeoMarketingModule { }
