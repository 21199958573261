import {Component, ViewChild, OnInit, OnDestroy, ElementRef} from '@angular/core';
import { ParentService } from './providers/parent-service';
import { dns, rapportsDir, imagesCarsDir } from '../../global.config';
import { Subscription } from 'rxjs/Subscription';
import { Group, Vehicule } from '../data-management/data-management.model';
import { DataManagementService } from '../data-management/data-management.service';
import { ToastrService } from 'ngx-toastr';
import { SigninService } from '../../authentification/signin/signin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parc-management',
  templateUrl: './parc-management.component.html',
  styleUrls: ['./parc-management.component.css']
})
export class ParcMangementComponent implements OnInit, OnDestroy {
  groups: Group[];
  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();

  @ViewChild('removeButton', { static: false }) removeButton: ElementRef<HTMLButtonElement>;
  @ViewChild('myModal', { static: false }) myModal: ElementRef<HTMLElement>;

  loading: Boolean = false;
  circulationDocument: Boolean = false;
  carburant: Boolean = false;
  vidange: Boolean = false;
  interview: Boolean = false;
  dashbord: Boolean = false;
  select: Boolean = true;
  dns: string = dns;
  subscriptionUpload: Subscription;
  indexFile: number = -1;
  item: any = null;
  matricule: string | null = null;
  showImport: Boolean = false;
  imgUrl: string = null;
  AllGroups: Subscription;
  odo = 0;

  rapports: string = rapportsDir;

  imagesCarsDir: string = imagesCarsDir;

  constructor(
    private parentService: ParentService,
    public toastrService: ToastrService,
    public signinService: SigninService,
    private dataManagementService: DataManagementService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(group => group.vehicules.length > 0);

      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        this.parentService.groupeName = this.selectedGroup.nom || null;

        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }

        if (this.selectedVehicule) {
          this.parentService.idVehicule = this.selectedVehicule.idVehicule;
          this.parentService.matricule = this.selectedVehicule.matricule || null;
        }

        this.dataManagementService.sendGroupWasSelected(this.selectedGroup);
        this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
      } else if (this.groups.length > 0) {
        this.selectedGroup = this.groups[0];
        this.parentService.groupeName = this.selectedGroup.nom;
        this.dataManagementService.selectedGroup = this.selectedGroup;

        if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
          this.parentService.idVehicule = this.selectedVehicule.idVehicule;
          this.parentService.matricule = this.selectedVehicule.matricule;
          this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
        }
      }

      this.getCurrentOdo();
    } else {
      this.loading = true;
      this.AllGroups = this.dataManagementService.getAllGroupsDetails('').subscribe(
        groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0) {
            this.selectedGroup = this.groups[0];
            this.parentService.groupeName = this.selectedGroup.nom;
            this.dataManagementService.selectedGroup = this.selectedGroup;

            if (this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.parentService.idVehicule = this.selectedVehicule.idVehicule;
              this.parentService.matricule = this.selectedVehicule.matricule;
              this.dataManagementService.sendGroupWasSelected(this.selectedGroup);
              this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
            }
          }

          this.loading = false;
          this.getCurrentOdo();
        },
        () => {
          this.loading = false;
        }
      );
    }
  }


  ngOnDestroy() {
    if (this.AllGroups) {
      this.AllGroups.unsubscribe();
    }
    this.parentService.idVehicule = 0;
    this.parentService.groupeName = null;
    this.parentService.matricule = null;
  }

  displayCirculationDocumentIcon() {
    this.circulationDocument = true;
    this.carburant = false;
    this.vidange = false;
    this.interview = false;
    this.dashbord = false;
  }

  displayCarburantIcon() {
    this.circulationDocument = false;
    this.carburant = true;
    this.vidange = false;
    this.interview = false;
    this.dashbord = false;
  }

  displayVidangeIcon() {
    this.circulationDocument = false;
    this.carburant = false;
    this.vidange = true;
    this.interview = false;
    this.dashbord = false;
  }

  displayInterviewIcon() {
    this.circulationDocument = false;
    this.carburant = false;
    this.vidange = false;
    this.interview = true;
    this.dashbord = false;
  }


  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      this.parentService.idVehicule = this.selectedVehicule.idVehicule;
      this.parentService.matricule = this.selectedVehicule.matricule;
      this.dataManagementService.sendGroupWasSelected(this.selectedGroup);
      this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
      this.dataManagementService
        .getCurrentOdo(this.selectedVehicule.idVehicule)
        .subscribe(value => {
          this.odo = value;
        });
    }
    this.changeTime();
    if (this.selectedVehicule.pathGrayCard == null) {
      this.showImport = true;
    } else {
      this.showImport = false;
    }
    this.parentService.groupeName = group.nom;
    this.getCurrentOdo();
  }

  changeTime() {
    let d = new Date();
    this.imgUrl = this.selectedVehicule.pathGrayCard + '?' + d.getTime();
  }

  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;

    this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
    this.parentService.idVehicule = this.selectedVehicule.idVehicule;
    this.parentService.matricule = this.selectedVehicule.matricule;
    if (this.selectedVehicule.pathGrayCard == null) {
      this.showImport = true;
    } else {
      this.showImport = false;
    }
    this.getCurrentOdo();
  }

  // uploadListener(item: any, indexFile: number) {
  //   item.file.name = this.matricule + '.png';
  //   this.item = item;
  //   this.indexFile = indexFile;
  // }
  //
  // changeName() {
  //   this.changeTime();
  //   this.myModal.nativeElement.click();
  // }
  // update offset vehicule with current value
  updateOdometre() {
    this.dataManagementService
      .updateVehiculeOffset(this.selectedVehicule.idVehicule, this.odo)
      .subscribe(value => {
        this.getCurrentOdo();
        this.toastrService.success('Odmotre bien modifié !');
      });
  }

  getCurrentOdo() {
    this.dataManagementService.getCurrentOdo(this.selectedVehicule.idVehicule)
      .subscribe(value => {
        this.odo = Math.floor((value) * 100) / 100;
      });
  }

  // Author: Zakaria Moujtazi
  getParentUrl(){
    return this.router.url.split("/")[3];
  }

}
