import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { Passage, PassageForm } from './passages';

@Injectable()
export class PassageService {

  
  passages: Passage[] = [];
  
  constructor(private _http: HttpClient) { }

  // list !
  findAllPassages(): Observable<Passage[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Passage[]>(dns + 'passages', { headers: headers });
  }

  addPassage(passage: PassageForm): Observable<Passage> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<Passage>(dns + 'passages', passage, options);
  }

  updatePassage(passage: PassageForm): Observable<Passage> {
    let headers = createAuthorizationHeader();
    return this._http.put<Passage>(dns + 'passages', passage, { headers: headers });
  }

  deletePassage(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'passages/' + id, { headers: headers });
  }

  // valid !
  getOnePassage(id: number): Observable<Passage> {
    let headers = createAuthorizationHeader();
    return this._http.get<Passage>(dns + 'passages/' + id, { headers: headers });
  }
}
