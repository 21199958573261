<section class="content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
    <div class="row" style="position: absolute; width: 100%;">
        <div class="datatable-container" id="datatable-container" style="margin-top: -10px;">
            <div class="col-md-12 datatable-container-col">
                <div class="box" style="border-radius: 0px;">
                    <div class="overlay" *ngIf="!isResultLoaded">
                        <i class="fa fa-refresh fa-spin"></i>
                    </div>
                    <div class="box-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <i class="col-sm-1 fa fa-list table-toggle" (click)="tableToggle()"></i>
                                <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                                <div class="col-sm-2 grp-slct">
                                    <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup"
                                        (ngModelChange)="chooseGroup($event)">
                                        <option style="font-size: 14px;" *ngFor="let group of groups" [ngValue]="group">
                                            <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst | truncate:[30]}}  / </span>  {{group.nom | capitalizefirst}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                                <div class="col-sm-2 vhcl-slct">
                                    <select class="form-control input-sm" name="vehicules"
                                        [(ngModel)]="selectedVehicule" (ngModelChange)="chooseVehicule($event)">
                                        <option style="font-size: 14px;"
                                            *ngFor="let vehicule of selectedGroup.vehicules" [ngValue]="vehicule">{{dataManagementService.getVehiculeName(vehicule) |
                                            capitalizefirst}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label dd-lbl">Date début</label>
                                <div class="col-sm-2 dd-npt">
                                    <div class="input-group">


                                        <input type="text" (click)="expandTable()" class="form-control input-sm"
                                            bsDatepicker name="startDate" [(ngModel)]="startDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                                            placement="bottom" [maxDate]="endDate" autocomplete="off">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>
                                    </div>
                                </div>
                                <label class="col-sm-1 control-label df-lbl">Date fin</label>
                                <div class="col-sm-2 df-npt">
                                    <div class="input-group">

                                        <input type="text" (click)="expandTable()" class="form-control input-sm"
                                            bsDatepicker name="endDate" [(ngModel)]="endDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm', containerClass: 'theme-dark-blue'}"
                                            placement="bottom" [minDate]="startDate" autocomplete="off">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                                    </div>
                                </div>
                                <div class="col-sm-1  form-btns">
                                    <button type="button" class="btn btn-success btn-flat btn-sm" style="border-radius: 4px;"
                                        (click)="getAllMileages()">
                                        <b><i class="fa fa-search" aria-hidden="true"></i></b>
                                    </button>
                                    <button class="btn btn-primary btn-sm excel-button" (click)="exportKilometrage()"
                                        [disabled]="mileages.length == 0"><i class="fa fa-file-excel-o"></i>
                                    </button>
                                    <button class="btn btn-primary btn-sm pdf-button" (click)="exportKilometragePDF()"
                                        [disabled]="mileages.length == 0" title="PDF"><i class="fa fa-file-pdf-o"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <table class="table table-striped" style="font-size: 12px;font-weight: bold" width="100%">
                            <thead>
                                <tr>
                                    <th width="33%" style="text-align: center"><i class="fa fa-clock-o"
                                            aria-hidden="true"></i>
                                        Date début
                                    </th>
                                    <th width="33%" style="text-align: center">Kilométrage</th>
                                    <th width="33%" style="text-align: center">Durée circulation</th>
                                </tr>
                            </thead>
                            <tbody class="datatable-body">
                                <tr *ngFor="let mileage of mileages" (click)="addMileageToArray(mileage, $event)"
                                    [class.mileage]="MileageSelected(mileage)">
                                    <td width="33%" style="text-align: center" data-toggle="tooltip"
                                        title="{{mileage.startDate | date:'dd-MM-yyyy'}}">
                                        {{mileage.startDate | date:'dd-MM-yyyy'}}
                                    </td>
                                    <td width="33%" style="text-align: center" data-toggle="tooltip"
                                        title="{{mileage.totalDrivingDistance}} Km">
                                        {{mileage.totalDrivingDistance | number:'1.2-2'}}
                                    </td>
                                    <td width="33%" style="text-align: center" data-toggle="tooltip"
                                        title="{{mileage.drivingDurationStr}}">
                                        {{mileage.drivingDurationStr}}
                                    </td>
                                </tr>
                                <tr style="font-weight: 900;color: green">
                                    <td width="33%" style="text-align: center" data-toggle="tooltip">
                                        Total:
                                    </td>
                                    <td width="33%" style="text-align: center" data-toggle="tooltip"
                                        title="{{getSumTotalDrivingDistance()}} Km">
                                        {{getSumTotalDrivingDistance() | number:'1.2-2'}}
                                    </td>
                                    <td width="33%" style="text-align: center" data-toggle="tooltip">

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="map-container">
            <app-leaflet></app-leaflet>
        </div>
    </div>
</section>