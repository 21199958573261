import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { DriverMissionFormComponent } from './driver-mission-form.component';
import { SigninService } from '../../../authentification/signin/signin.service';
import { DataManagementService } from '../../data-management/data-management.service';
import { MissionService } from '../../data-management';
import { NgxLoadingModule } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { MomentModule } from 'ngx-moment';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    DriverMissionFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),
    MomentModule,
    MatSlideToggleModule,
    TruncateModule,

  ],
  providers: [
    SigninService,
    DataManagementService,
    MissionService
  ],
  exports: [
    DriverMissionFormComponent
  ]
})
export class DriverMissionFormModule { }
