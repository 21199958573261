import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthentificationComponent } from './authentification.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AuthentificationComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    AuthentificationComponent
  ]
})
export class AuthentificationModule { }
