import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import {MultiType, Page} from '../model/index';
import {dns} from "../../../global.config";
import {createAuthorizationHeader} from "../../../utils/security/headers";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MultiTypeService {

    constructor(private _http: HttpClient) {}

    public getListType(type: string): Observable<MultiType[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<MultiType[]>(dns + 'types/list?type=' + type, {headers: headers});
    }

    public getListOfMultipleTypes(types: string[]): Observable<MultiType[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<MultiType[]>(dns + 'types/multiple/list?types=' + types, {headers: headers});
    }

    public getListTypeByPagination(type: string, page: number, size: number): Observable<Page<MultiType>> {
         let headers = createAuthorizationHeader();
        return this._http.get<Page<MultiType>>(dns + 'types/paginate/list?type=' + type + '&page=' + page + '&size=' + size, {headers: headers});
    }

    public delete(id: number): Observable<Boolean> {
         let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + "types/" + id, { headers: headers });
    }

    public add(type: MultiType): Observable<MultiType> {
         let headers = createAuthorizationHeader();
        return this._http.post<MultiType>(dns + "types", type, { headers: headers });
    }

    public update(type: MultiType): Observable<MultiType> {
         let headers = createAuthorizationHeader();
        return this._http.put<MultiType>(dns + "types", type, { headers: headers });
    }

}
