import {Component, OnInit} from '@angular/core';
import {imagesCarsDir} from "../../../global.config";
import { User } from 'src/app/authentification/signin/credentials';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-divers',
    templateUrl: './divers.component.html',
    styleUrls: ['./divers.component.css']
})
export class DiversComponent implements OnInit {

    imagesCarsDir = imagesCarsDir;
    public currentUser: {} = new User();

    constructor(
        public signinService: SigninService,
        private router: Router
    ) {
    }

  ngOnInit() {
    const storedUser = localStorage.getItem("currentUser");

    if (storedUser) {
      this.currentUser = JSON.parse(storedUser);
    } else {
      this.currentUser = {};
    }
  }


  getCurrentUrl(){
        return this.router.url.split("/").pop();
    }

}
