
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule for ngModel
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; // Import Angular Material Slide Toggle Module
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications

import { ActivitySettingsComponent } from './activity-settings.component'; // Import the ActivitySettingsComponent
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';

@NgModule({
  declarations: [
    ActivitySettingsComponent,
     // Declare the component
  ],
  imports: [
    CommonModule, // Common module for common directives
    FormsModule, // Include FormsModule for ngModel
    MatSlideToggleModule, // Include Angular Material Slide Toggle module
    ToastrModule.forRoot(),
    EmailManagerModule,
    CronSettingsModule
  ],
  exports: [
    ActivitySettingsComponent // Export the component if needed in other modules
  ]
})
export class ActivitySettingsModule { }
