import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { ParcManagementComponent } from './parc-management.component'; // Adjust the path if necessary
import { RouterModule } from '@angular/router'; // For routing functionalities
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
//import { DataManagementModule } from '../data-management/data-management.module'; // Import the DataManagementModule if used
import { ParcMangementComponent } from './parc-management.component';
import { ParentService } from './providers/parent-service';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    ParcMangementComponent // Declare the ParcManagementComponent here
  ],
  imports: [
    CommonModule,
    RouterModule, // Include RouterModule if you are using Angular routing
    ToastrModule.forRoot(),
    FormsModule,
    SharedPipesModule,
    NgxLoadingModule.forRoot({}),
    TruncateModule

    //DataManagementModule // Import your DataManagementModule if needed
  ],
  exports: [
    ParcMangementComponent // Export the ParcManagementComponent if needed in other modules
  ],
  providers: [
    ParentService, // Provide any services needed by this module
    SigninService // Include any additional services needed
  ]
})
export class ParcManagementModule { }
