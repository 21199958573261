import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DriverMissionManagementComponent } from './driver-mission-management.component';
import { SigninService } from '../../authentification/signin/signin.service';
import { NgxLoadingModule } from 'ngx-loading';
import { DriverMissionFormModule } from './driver-mission-form/driver-mission-form.module';

@NgModule({
  declarations: [
    DriverMissionManagementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxLoadingModule.forRoot({}),
    DriverMissionFormModule,
  ],
  providers: [SigninService],
  exports: [DriverMissionManagementComponent]
})
export class DriverMissionManagementModule { }
