import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupervisorComponent } from './supervisor.component'; // Adjust the path as necessary
import { SupervisorFormComponent } from './supervisor-form/supervisor-form.component'; // Import the SupervisorFormComponent
import { MatDialogModule } from '@angular/material/dialog'; // Import Angular Material Dialog module
import { MatTableModule } from '@angular/material/table'; // Import Angular Material Table module
import { MatPaginatorModule } from '@angular/material/paginator'; // Import Angular Material Paginator module
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { SupervisorFormModule } from './supervisor-form/supervisor-form.module';

@NgModule({
    declarations: [
        SupervisorComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        ToastrModule.forRoot(),
        SupervisorFormModule
    ],
    exports: [
        SupervisorComponent // Export the SupervisorComponent if you want to use it in other modules
    ]
})
export class SupervisorModule { }
