import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleComponent } from './schedule.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modals
import { ScheduleService } from './schedule.service'; // Import the ScheduleService
import { PassageService } from '../parametrage/passage'; // Import the PassageService
import { PesageService } from '../parametrage/pesage.service'; // Import the PesageService
import { TransportTypeService } from '../parametrage/transport-type'; // Import TransportTypeService
import { ValidatorSettingsService } from '../parametrage/validator-settings'; // Import ValidatorSettingsService
import { PathService } from '../../historical'; // Import PathService
import { NumeroOtModule } from './numero-ot/numero-ot.module';
import { NumeroOtComponent } from './numero-ot/numero-ot.component';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { ScheduleChartModule } from './schedule-chart/schedule-chart.module';
import { SchedulingFormModule } from './scheduling-form/scheduling-form.module';
import { PassageChartModule } from './passage-chart/passage-chart.module';
import { NgxLoadingModule } from 'ngx-loading';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    ScheduleComponent,
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule for template-driven forms
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    SharedPipesModule,
    ModalModule.forRoot(),
    NumeroOtModule,
    NgxLoadingModule.forRoot({}),
    SchedulingFormModule,
    PassageChartModule,
    BsDatepickerModule.forRoot(),
    ScheduleChartModule,
    FilterPipeModule,

  ],
  providers: [
    ScheduleService, // Provide ScheduleService
    PassageService, // Provide PassageService
    PesageService, // Provide PesageService
    TransportTypeService, // Provide TransportTypeService
    ValidatorSettingsService, // Provide ValidatorSettingsService
    PathService, // Provide PathService


    // PassageChartModule,
  ],
  exports: [
    ScheduleComponent // Export the ScheduleComponent for use in other modules
  ]
})
export class ScheduleModule { }
