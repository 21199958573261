import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentStateComponent } from './current-state.component';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular'; // Import the Highcharts module if using Highcharts
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    CurrentStateComponent // Declare the CurrentStateComponent
  ],
  imports: [
    CommonModule, // CommonModule for common directives
    FormsModule, // Import FormsModule if using ngModel
    HighchartsChartModule,
    ModalModule.forRoot(),

  ],
  exports: [
    CurrentStateComponent // Export the component if it needs to be used outside this module
  ]
})
export class CurrentStateModule { }
