import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidatorSettingsService } from '../validator-settings.service';
import { ValidatorDto } from '../validators';

@Component({
  selector: 'app-validator-settings-form',
  templateUrl: './validator-settings-form.component.html',
  styleUrls: ['./validator-settings-form.component.css']
})
export class ValidatorSettingsFormComponent implements OnInit {
  mode: "ADD" | "UPDATE";
  loading: boolean = false;
  currentValidator: ValidatorDto = new ValidatorDto();

  error: { errorMessage: string } = { errorMessage: '' };
  constructor(private route: ActivatedRoute, public toastr: ToastrService,
    private router: Router, private validatorService: ValidatorSettingsService) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
      this.init(params.id);
    });
  }

  init(validatorId: any) {


    if (this.mode == "UPDATE") {
      this.loading = true;

      this.validatorService.getOneValidator(validatorId).subscribe(
        (validator) => {
          this.currentValidator = validator;
          this.loading = false;
        },
        error => {
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }

          this.loading = false;
        }
      );
    }
  }

  cancel() {
    this.router.navigate(["/client/parametrage/chargesPfbvalidator"]);
  }


  addValidator() {

    this.loading = true;
    let validator: ValidatorDto = new ValidatorDto();
    Object.assign(validator, this.currentValidator);
    this.validatorService
      .addValidator(validator)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        validator => {
          this.validatorService.validators.push(validator)
          this.loading = false;
          this.toastr.success("Validateur bien ajouter !");
          this.router.navigate(["/client/parametrage/chargesPfbvalidator"]);
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

  updateValidator() {
    this.loading = true;
    let validator: ValidatorDto = new ValidatorDto();
    Object.assign(validator, this.currentValidator);
    this.validatorService
      .updateValidator(validator)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        validator => {
          for (let validator_ of this.validatorService.validators) {
            if (validator.idValidator == validator_.idValidator) {
              validator_ = validator;
              break;
            }
          }

          this.loading = false;
          this.router.navigate(["/client/parametrage/chargesPfbvalidator"]);
          this.toastr.success("Validateur bien modifié !");
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }


}
