<div class="row">
  <div class="col-md-12">
    <div class="">
      <div class="mt-2 mb-2 text-center well bgg">
        <h4
          style="
            font-family: 'Inconsolata', monospace;
            font-weight: bold;
            color: white;
          "
        >
          <i class="fa fa-cog"></i>
          <span>Modifier Superviseur </span>
        </h4>
      </div>
      <div class="col-lg-12 well formBC" style="background-color: #fff">
        <div class="row">
          <form class="form-horizontal" (submit)="onSubmit()">
            <div class="col-sm-12">
              <!-- label -->
              <div class="form-group mrg">
                <label>Superviseur</label>
                <input
                  [value]="superviseur?.superviseur?.completeName"
                  name="type"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>

              <div class="form-group mrg">
                <label>Véhicule(s)</label>
                <!-- <ng-multiselect-dropdown-angular7
                  name="vehicule"
                  [data]="vehicules"
                  placeholder="Select Véhicule(s)"
                  [(ngModel)]="selectedVehicule"
                  [settings]="vehiculeDropdownSettings"
                >
                </ng-multiselect-dropdown-angular7> -->

                <ng-multiselect-dropdown
                [placeholder]="'Select Véhicule(s)'"
                [settings]="groupDropdownSettings"
                [data]="vehicules"
                [(ngModel)]="selectedVehicule"
              >
              </ng-multiselect-dropdown>



              </div>
            </div>
            <!-- ========================== Modal FOOTER (START) ===============================-->
            <div class="box-footer">
              <button type="submit" class="btn btn-info pull-right">
                Enregistrer
              </button>
              <button type="button" class="btn btn-dark" (click)="close(false)">
                Annuler
              </button>
            </div>
            <!-- ========================== Modal FOOTER (END) ===============================-->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
