<div class="row">
    <div class="col-lg-12">
        <div class="box-header with-border">
            <!-- =================================== OPTIONS (START)==================================== -->
            <div class="pull-left">

                <!-- =================================== SELECT TYPE OILCHANGE (START)==================================== -->
                <ng-container *ngIf="isOilChange">
                    <span>Type de Référence</span>
                    <div style="display: inline-block">
                        <select class="form-control input-sm" [(ngModel)]="oilChangeType" name="oilChangeType"
                            (change)="loadTypes()">
                            <option *ngFor="let type of oilChangeTypes" [value]="type.name">{{type.label}}</option>
                        </select>
                    </div>
                </ng-container>
                <!-- =================================== SELECT TYPE OILCHANGE (END)==================================== -->


                <div class="option-add" style="border-left: hidden;"><span title="Ajouter" (click)="onAdd()"><i
                            class="fa fa-plus-circle"></i>
                        Ajouter</span></div>
            </div>
            <!-- =================================== OPTIONS (END)==================================== -->
            <!--==================================== Search(START)=============== -->
            <div class="pull-right">
                <div class="inputWithIcon">
                    <input [(ngModel)]="searchTerm" name="searchTerm" type="text" class="form-control input-sm"
                        placeholder="Nom...">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <!--==================================== Search(END)================= -->
        </div>
    </div>
    <div class="col-lg-12">
        <!-- ========================================== LIST PROVIDER (START) ====================================================-->
        <div class="content">
            <div class="table-responsive tableFixHead">
                <table class="table table-fixed table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 5%;">Type</th>
                            <th style="width: 90%; border-left: hidden;"></th>
                            <th style="width: 5%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="loading">
                            <td style="text-align: center" colspan="3">
                                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                            </td>
                        </tr>
                        <tr *ngIf="!loading && types.length == 0">
                            <td colspan="3">
                                <div class="alert alert-warning alert-dismissible"
                                    style="border-radius: 0px;text-align: center">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                        ×
                                    </button>
                                    <h4 style="color: white">
                                        <i class="fa fa-info-circle"></i> Information
                                    </h4>
                                    Veuillez ajouter des informations pour types !
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let type of types | filterBy: {'name':searchTerm}">
                            <td>{{type?.name}}</td>
                            <td></td>
                            <td>
                                <button title="Supprimer" style="color: red;" type="button"
                                    class="btn btn-sm btn-default" (click)="onConfirmDelete(type)">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                                <button title="Modifier" style="color: rgb(2, 157, 196); " type="button"
                                    class="btn btn-sm btn-default" (click)="onUpdate(type)">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- ========================================== LIST PROVIDER (END) ====================================================-->
    </div>
</div>
<!-- ========================================== PAGINATION (START) ====================================================-->
<div class="row">
    <div class="col-md-12 text-center">
        <pagination [totalItems]="totalItems" [itemsPerPage]="size" [boundaryLinks]="boundaryLinks" [maxSize]="maxSize"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [(ngModel)]="currentPage"
            (pageChanged)="onPageChanged($event)"></pagination>
    </div>
</div>
<!-- ========================================== PAGINATION (END) ====================================================-->
<!-- ========================================== ADD/UPDATE Modal (START) ====================================================-->
<div class="modal fade" bsModal #multiTypeModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- ========================== Modal TITLE (START) ===============================-->
            <div class="modal-header">
                <button type="button" class="close" (click)="multiTypeModal.hide()">&times;</button>
                <h4 *ngIf="mode=='ADD'" class="modal-title"
                    style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-plus"></i>
                    Ajouter informations type
                </h4>
                <h4 *ngIf="mode=='UPDATE'" class="modal-title"
                    style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-edit"></i>
                    Modifier informations type
                </h4>
            </div>
            <!-- ========================== Modal TITLE (END) ===============================-->
            <div class="modal-body">
                <div class="box-body">
                    <app-multi-type-settings-form [multiType]="multiType" [mode]="mode"
                        [multiTypeModal]="multiTypeModal" (multiTypeWasAdded)="multiTypeWasAdded($event)"
                        (multiTypeWasUpdated)="multiTypeWasUpdated($event)"></app-multi-type-settings-form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ========================================== ADD/UPDATE Modal (END) ====================================================-->
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" bsModal #multiTypeDeleteModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="multiTypeDeleteModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !
                    </h4>
                    Êtes-vous sûr de supprimer ce type '{{multiType.name}}' ?
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="multiTypeDeleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="onDelete()">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
