import {
  Component,
  OnInit,
  Host,
  AfterViewInit,
  ViewChild
} from "@angular/core";
import { DataManagementComponent } from "../data-management.component";
import { GroupService } from "../providers/group.service";
import { Router } from "@angular/router";
import { imagesCarsDir } from "../../../global.config";
import { ToastrService } from "ngx-toastr";
import { ModalDirective } from "ngx-bootstrap/modal";
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { Group } from '../data-management.model';

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.css"]
})
export class GroupComponent implements OnInit {
  // pag vars
  public maxSize: number = 5;
  public bigTotalItems: number = 175;
  public bigCurrentPage: number = 1;
  public numPages: number = 0;
  itemsPerPage = 30;
  keyword: string = "";

  groups: Group[] = [];
  url: string = imagesCarsDir;
  currentGroup: Group = new Group();
  loading: boolean;
  globalLoading: boolean = false;

  @ViewChild("confirmDeleteModal", { static: false }) confirmDeleteModal: ModalDirective;

  constructor(
    private groupeService: GroupService,
    private router: Router,
    private dataManagementComponent: DataManagementComponent,
    public toastr: ToastrService,
    private signinService: SigninService
  ) {
  }

  ngOnInit() {
    this.loadGroups();

  }

  /**
   * loadGroups : load groups according to pagination payload
   * */
  loadGroups() {
    this.loading = true;
    this.groupeService
      .getGroupsByPageAndSize(
        this.bigCurrentPage - 1,
        this.itemsPerPage,
        this.keyword
      )
      .subscribe(
        groups => {
          this.groups = groups.content;
          this.bigTotalItems = groups.totalElements;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * callback : when page change !
   * */
  public pageChanged(event: any): void {
    this.bigCurrentPage = event.page;
    this.loadGroups();
  }

  /**
   * confirm delete modal !
   * */
  confirmDeleteGroupe(group: Group) {
    this.currentGroup = group;
    this.confirmDeleteModal.show();
  }

  /**
   * delete specific group !
   * */
  deleteGroup() {
    this.globalLoading = true;
    this.groupeService.deleteGroupById(this.currentGroup.idGroupe).subscribe(
      suprimed => {
        this.globalLoading = false;
        if (suprimed) {
          this.confirmDeleteModal.hide();
          this.loadGroups();
          this.toastr.success("Votre suppression est bien effectuée!", "info");
        } else {
          this.toastr.error("L'operation de suppression est annulée!", "Error");
        }
      },
      () => {
        this.globalLoading = false;
        this.toastr.error("L'operation de suppression est annulée!", "Error");
      }
    );
  }

  // gotoDetail(idGroup) {
  //   this.router.navigate(["/groupe/details", idGroup]);
  // }

  /**
   * search specific group !
   * */
  searchGroup() {
    this.bigCurrentPage = 1;
    this.loadGroups();
  }
}
