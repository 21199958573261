import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentFormComponent } from './document-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { DocumentCirculationService } from '../../providers/document-circulation-service'; // Import the service

@NgModule({
  declarations: [
    DocumentFormComponent // Declare the DocumentFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule to handle form controls
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot() // Import BsDatepickerModule for date picking
  ],
  providers: [
    DocumentCirculationService // Provide DocumentCirculationService
  ],
  exports: [
    DocumentFormComponent // Export DocumentFormComponent for use in other modules
  ]
})
export class DocumentFormModule { }
