import { Component, OnInit } from '@angular/core';
import { imagesCarsDir } from '../../../global.config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {
  showTire = true;
  showBattery = false;
  showReparation = false;

  imagesCarsDir: string = imagesCarsDir;

  constructor(
    private router: Router
  ) {
    // this.maintenanceService.displayInterviewIcon();
  }

  ngOnInit() {}

  activeTire() {
    this.showTire = true;
    this.showBattery = false;
    this.showReparation = false;
  }

  activeBattery() {
    this.showBattery = true;
    this.showTire = false;
    this.showReparation = false;
  }

  activeReparation() {
    this.showBattery = false;
    this.showTire = false;
    this.showReparation = true;
  }

  // Author: Zakaria Moujtazi
  getCurrentUrl() {
    return this.router.url.split('/').pop();
  }
}
