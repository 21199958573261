import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { DateInterval } from 'src/app/shared/model';
import { DataManagementService, GroupDto } from '../../data-management';
import { PathService } from '../../historical';


@Component({
  selector: 'app-ciment-dashboard',
  templateUrl: './ciment-dashboard.component.html',
  styleUrls: ['./ciment-dashboard.component.css']
})
export class CimentDashboardComponent implements OnInit {

  scheduleList: any[] = [];

  vehiculesMap = new Map();
  dateInterval: DateInterval = new DateInterval();

  loading = false;

  /** states */
  ActiveLength = 0;
  stopInPathLength = 0;
  stopInPoiLength = 0;
  stopLength = 0;
  breakDownLength = 0;


  /** UPDATE TRACKING TIMER */
  updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;

  @ViewChild('breakModal', { static: false }) breakModal: ModalDirective;

  startDate: Date = new Date();

  now: Date = new Date();

  isToday = true;

  /** GROUP VAR */
  selectedGroup: GroupDto = new GroupDto();

  groups: GroupDto[] = [];
  vehicules: any[] = [];

  constructor(private stopService: PathService, private groupService : DataManagementService, public toastr: ToastrService) {

  }

  ngOnInit() {
    this.loadGroups();
  }

  loadGroups(){
    this.loading = true;
    this.groupService.getAllGroups().subscribe(groups => {
      this.groups = groups;
      if(this.groups.length > 0)
      this.selectedGroup = this.groups[0];
      this.loading = false;
    }, err => {
      this.loading = false;
      this.toastr.error("Erreur lors de chargements", "Group");
    })
  }

  ngOnDestroy() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
  }

  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 1200;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription!.unsubscribe();
        this.stop();
      }
    })
  }

  /** STOP TRACKING */
  stopTracking() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = null;
  }

  onChangeStartDate() {
    this.stopTracking();
  }

  chooseGroup(){
    this.stopTracking();
  }

  stop() {
    this.loading = true;
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.startDate.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);

    this.isToday = (this.dateInterval.startDate.toDateString() == this.now.toDateString());

    this.ActiveLength = 0;
    this.stopInPathLength = 0;
    this.stopInPoiLength = 0;
    this.stopLength = 0;
    this.breakDownLength = 0;
    this.scheduleList = [];
    this.vehiculesMap = new Map();
    this.vehicules = [];

    this.stopService.scheduleByGroup(this.dateInterval, this.selectedGroup.idGroupe).subscribe(data => {
      this.scheduleList = data;
      let matriculesOfInPath = new Set();
      let matriculesOfStop = new Set();
      let matriculesOfStopInPath = new Set();
      let matriculesOfMissions = new Set();
      let matriculesOfBreakDown = new Set();
      for(let schedule of this.scheduleList){
        if (schedule.type == 'inPath') {
          this.stopInPathLength += schedule.duration;
          matriculesOfInPath.add(schedule.matricule);
        } else if (schedule.type == 'Stop') {
          this.stopLength += schedule.duration;
          matriculesOfStop.add(schedule.matricule);
        } else if (schedule.type == 'stopInPoi') {
          this.stopInPoiLength += schedule.duration;
          matriculesOfStopInPath.add(schedule.matricule);
        } else if (schedule.type == 'Start'){
          this.ActiveLength += schedule.duration;
          matriculesOfMissions.add(schedule.matricule);
        }else{
          this.breakDownLength += schedule.duration;
          matriculesOfBreakDown.add(schedule.matricule);
        }
        this.vehiculesMap.set(schedule.deviceId, schedule.matricule);
      }

      this.vehicules = Array.from(this.vehiculesMap).map(([deviceId, matricule]) => ({ deviceId, matricule }));

      this.ActiveLength = this.ActiveLength / matriculesOfMissions.size;
      this.stopInPoiLength = this.stopInPoiLength / matriculesOfStopInPath.size;
      this.stopLength = this.stopLength / matriculesOfStop.size;
      this.stopInPathLength = this.stopInPathLength / matriculesOfInPath.size;
      this.breakDownLength =  this.breakDownLength / matriculesOfBreakDown.size;

      if (this.isToday) this.startUpdateTrackingTimer();

      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  breakWasSaved() {
    this.updateTrackingTimer = null;
    this.stop();
    this.breakModal.hide();
  }

  showBreakForm(){
    this.breakModal.show();
  }

}
