import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartExcesVitesseComponent } from './chart-exces-vitesse.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [ChartExcesVitesseComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot() // Importing ModalModule for modal functionalities if needed
  ],
  exports: [ChartExcesVitesseComponent] // Exporting the component for use in other modules
})
export class ChartExcesVitesseModule {}
