
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakDownFormComponent } from './break-down-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import Datepicker module
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ScheduleService } from '../../charges-pfb'; // Import the ScheduleService
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';

@NgModule({
  declarations: [
    BreakDownFormComponent // Declare the BreakDownFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule for template-driven forms
    BsDatepickerModule.forRoot(), // Import the datepicker module
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),

  ],
  providers: [
    ScheduleService // Provide ScheduleService
  ],
  exports: [
    BreakDownFormComponent // Export the BreakDownFormComponent for use in other modules
  ]
})
export class BreakDownFormModule { }
