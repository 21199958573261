import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ModalDirective } from "ngx-bootstrap/modal";
import { DataManagementService } from "../../data-management/data-management.service";
import { Group, Vehicule } from "../../data-management/data-management.model";
import { MapService } from "../../../utils/leaflet/service/map.service";
import { GeocodingService } from "../../../utils/leaflet/service/geocoding.service";
import { PathService } from "./path.service";
import { Path } from "./path";
import { imagesDir } from "../../../global.config";
import * as P from 'leaflet';
import { Subscription, Observable, Subject } from "rxjs";
import { saveAs as importedSaveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import {Icon, LatLng, LatLngLiteral, Marker} from 'leaflet';
import { Poi } from '../../client-management.model';
import '../../../../assets/leaflet-play/dist/LeafletPlayback.js';
import { SpiderChartComponent } from '../../statistical/spider-chart/spider-chart.component';
import { LineString, MultiLineString } from 'geojson';
declare let L: { Playback: new (arg0: P.Map, arg1: { type: string; geometry: { type: string; coordinates: any[][]; }; properties: { time: any[]; }; }, arg2: any, arg3: { playControl: boolean; dateControl: boolean; sliderControl: boolean; tracksLayer: boolean; staleTime: number; fadeMarkersWhenStale: boolean; }) => any; };
import * as moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';

@Component({
  selector: "app-path",
  templateUrl: "path.component.html",
  styleUrls: ["path.component.css"]
})
export class PathComponent implements OnInit {
  startDate: Date = new Date();
  endDate: Date = new Date();

  AllGroups: Subscription;
  AllPaths: Subscription;

  pathClicked: Path;

  groups: Group[];
  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();
  pathsArray: any[] = [];

  paths: Path[] = [];
  pois: Poi[];

  isResultLoaded = true;
  typicalPathName: string;
  isTableExpanded = false;
  showSpiderChart = false;
  showDetails = false;

  playback: any;

  selectedPath: any = 0;



  /** TYPICAL PATH VAR  **/
  allTypicalPath: any[] = [];
  selectedTypicalPath: any = null;
  selectedPathToCompare: any = null;

  public pointsPath: any | null = null;
  public pointsTypicalPath: any | null = null;


  isTypicalPath: boolean;
  isLoadingTypicalPath: boolean = false;

  polyline: P.Polyline<LineString | MultiLineString, any> = null;

  @ViewChild("childModal", { static: false }) public childModal: ModalDirective;

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  constructor(
    public dataManagementService: DataManagementService,
    private mapService: MapService,
    private geocodingService: GeocodingService,
    private pathService: PathService,
    public toastr: ToastrService,
    private datePipe: DatePipe,
    private localeService: BsLocaleService,
    private spiderChart: SpiderChartComponent
  ) {
    this.localeService.use('fr');

    this.startDate.setTime(this.startDate.getTime() - 3 * 86400000);
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);

    this.mapService.mapLoaded.subscribe(() => {
      this.mapService.map.removeLayer(this.mapService.baseMaps.OpenStreetMap);
      this.mapService.map.addLayer(this.mapService.baseMaps['Google Sat']);
    });
  }

  ngOnInit() {

    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
            }
          }
        });
    }

    /** GET ALL TYPiCAL PATH  **/
    this.getallTypicalPath();
  }

  ngAfterViewInit() {
    this.initViewSpiderChart();

  }
  initViewSpiderChart() {
    document.getElementById("details").style.display = 'none';
  }

  ngOnDestroy() {
    if (this.AllGroups) this.AllGroups.unsubscribe();
    if (this.AllPaths) this.AllPaths.unsubscribe();
  }

  distance(lat1: any, lon1: any, lat2: any, lon2: any) {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    var distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }

  displayDetails(){
    if (this.showSpiderChart) {
      if (this.playback) {
        this.playback.destroy();
      }
      this.showSpiderChart = !this.showSpiderChart;
    } else {
      this.getSelectedPath(this.selectedPath);
      document.getElementById("details").style.display = 'block';
      this.showSpiderChart = !this.showSpiderChart;
    }
  }

  getSelectedPath(path: any) {

    this.pathService
      .getPathDetails(this.selectedVehicule.device.idDevice, {
        startDate: path.beginPathTime,
        endDate: path.endPathTime
      })
      .subscribe(
        points => {
          try {
            var coordinates = points.coordinates
            var point = [];
            var times = [];

            for (var i = 0; i < coordinates.length; i++) {

              point[i] = [coordinates[i].lng, coordinates[i].lat];
              times[i] = coordinates[i].date;

            }

            var demoTracks = {
              "type": "Feature",
              "geometry": {
                "type": "MultiPoint",
                "coordinates": point
              },
              "properties": {
                "time": times
              }
            }

            // Playback options
            var playbackOptions = {
              playControl: true,
              dateControl: false,
              sliderControl: false,
              tracksLayer: false,
              staleTime: 2000,
              fadeMarkersWhenStale: true
            };


            //Initialize playback
            this.playback = new L.Playback(this.mapService.map, demoTracks, null, playbackOptions);

          } catch (error) {
          }

        });
  }

  trackingPath(path: Path) {
    if (this.selectedPath != path) {
      this.selectedPath = path;
      if (this.showSpiderChart) {
        this.getSelectedPath(this.selectedPath);
      }
    }


  }


  getallTypicalPath() {
    this.pathService
      .getAllTypicalPath()
      .subscribe(res => {
        this.allTypicalPath = res;
      }, err => {
        this.toastr.error("Erreur trajet typique !");
      });
  }


  getDistance(latLng1: LatLngLiteral, latLng2: LatLngLiteral): number {
    var markerFrom = P.circleMarker([latLng1.lat, latLng1.lng]);
    var markerTo = P.circleMarker([latLng2.lat, latLng2.lng]);
    var from = markerFrom.getLatLng();
    var to = markerTo.getLatLng();
    var distance: number = from.distanceTo(to);
    return distance;
  }

  findTypicalPath() {
    this.isTypicalPath = null;
    if (this.selectedTypicalPath) {

      this.selectedPathToCompare = null;
      this.isLoadingTypicalPath = true;
      this.pathService.getOnePath(this.selectedTypicalPath.deviceId, {startDate: this.selectedTypicalPath.beginPathTime,endDate: null})
        .subscribe(path => {
          this.selectedPathToCompare = path;
          this.pointsTypicalPath = null;
            this.drawPath(path, "#000000").subscribe(points => {
              this.pointsTypicalPath = points;
              if (this.pointsPath && this.pointsTypicalPath) {
                var ditanceBetweenPoints: number[] = [];
                var minDistance: number = null;
                this.pointsTypicalPath.coordinates.forEach((typicalPathCoordinate: { lat: any; lng: any; }) => {
                  var latLng1: LatLngLiteral = { lat: typicalPathCoordinate.lat, lng: typicalPathCoordinate.lng };
                  minDistance = null;
                  this.pointsPath.coordinates.forEach((pathCoordinate: { lat: any; lng: any; }) => {
                    var latLng2: LatLngLiteral = { lat: pathCoordinate.lat, lng: pathCoordinate.lng };
                    var distanceBetwwenLoc: number = this.getDistance(latLng1, latLng2);
                    if (minDistance == null) {
                      minDistance = distanceBetwwenLoc;
                    } else if (distanceBetwwenLoc < minDistance) {
                      minDistance = distanceBetwwenLoc;
                    }
                    if (minDistance == 0) return;
                  })
                  ditanceBetweenPoints.unshift(minDistance);
                })
                if (ditanceBetweenPoints.filter(d => d > 50).length > 0) {
                  this.isTypicalPath = false;
                  this.polyline.setStyle({
                    color: 'red'
                  });
                } else {
                  this.isTypicalPath = true;
                  this.polyline.setStyle({
                    color: 'green'
                  });
                }

              }
            this.isLoadingTypicalPath = false;
        }, err=>{
          this.isLoadingTypicalPath = false;
        });
      }, err => {
        this.isLoadingTypicalPath = false;
        this.toastr.error("Erreur trajet typique !");
      });
    }
  }

  getAllPaths() {
    this.isResultLoaded = false;
    this.paths = [];
    this.AllPaths = this.pathService
      .getAllPaths(this.selectedVehicule.idDevice, {
        startDate: this.startDate.getTime(),
        endDate: this.endDate.getTime()
      })
      .subscribe(
        paths => {
          if (paths.length) {
            this.toastr.success(
              this.dataManagementService.getVehiculeName(
                this.selectedVehicule
              ) +
              " a parcouru " +
              paths.length +
              " trajets entre " +
              this.datePipe.transform(this.startDate, "dd/MM/yyyy HH:mm:ss", null, 'fr-fr') +
              " et " +
              this.datePipe.transform(this.endDate, "dd/MM/yyyy HH:mm:ss", '', 'fr-fr')
            );

            this.paths = paths;

            this.paths.forEach(path => {
              path.beginPathGeocodingDetails = this.dataManagementService.getRelativePosition(
                path.beginPathLatitude,
                path.beginPathLongitude
              );
              path.beginPathGeocoding = path.beginPathGeocodingDetails;
              if (path.beginPathGeocodingDetails == null) {
                this.geocodingService
                  .inverseGeoconding(
                    path.beginPathLatitude,
                    path.beginPathLongitude,
                    17
                  )
                  .subscribe(
                    adress => {
                      path.beginPathGeocoding = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                      path.beginPathGeocodingDetails = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                    },
                    err => {
                      path.beginPathGeocodingDetails = null;
                      path.beginPathGeocoding = null;
                    }
                  );
              }
              path.endPathGeocodingDetails = this.dataManagementService.getRelativePosition(
                path.endPathLatitude,
                path.endPathLongitude
              );
              path.endPathGeocoding = path.endPathGeocodingDetails;
              if (path.endPathGeocodingDetails == null) {
                this.geocodingService
                  .inverseGeoconding(
                    path.endPathLatitude,
                    path.endPathLongitude,
                    17
                  )
                  .subscribe(
                    adress => {
                      path.endPathGeocoding = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                      path.endPathGeocodingDetails = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                    },
                    err => {
                      path.endPathGeocodingDetails = null;
                      path.endPathGeocoding = null;
                    }
                  );
              };
            });
          }
          this.isResultLoaded = true;
        },
        err => {
          this.isResultLoaded = true;
        }
      );
    document.getElementById("datatable-container").style.height = "auto";
    document.getElementById("datatable-container").style.overflow = "visible";
    this.isTableExpanded = true;
  }

  addPathToArray(path: any, event: any) {
    this.isTypicalPath = null;
    this.selectedPathToCompare = null;
    if (!event.ctrlKey) {
      this.pathsArray = [];
      this.mapService.removePolylinesFromMap();
      this.mapService.removeMarkersFromMap();
      if (this.playback) {
        this.playback.destroy();
      }
    }

    this.pathsArray.push(path);
    this.pointsPath = null;
    this.drawPath(path, "#0031D9").subscribe(points => {
      this.pointsPath = points;
    });
    this.trackingPath(path);
    this.spiderChart.getSpiderChart(this.selectedVehicule, path);
  }

  PathSelected(path: any) {
    return this.pathsArray.indexOf(path) !== -1;
  }


  drawPath(path: Path, polylineColor: string): Observable<any> {
    var subject = new Subject<any>();
    if (
      this.pathClicked == null ||
      this.pathClicked.beginPathTime !== path.beginPathTime
    ) {
      this.pathClicked = new Path();
      this.pathClicked = path;
    }

    this.pathService
      .getPathDetails(this.selectedVehicule.idDevice, {
        startDate: path.beginPathTime,
        endDate: path.endPathTime
      }).subscribe(
        points => {
          points.stops.forEach((stop: { stopDurationStr: string; stopLatitude: number; stopLongitude: number; }) => {
            let popup =
              "<img src='" +
              imagesDir +
              "stop_smal.png" +
              "'/> Durée d'arrêt : " +
              stop.stopDurationStr;
            let stopMarker = new Marker({
              lat: stop.stopLatitude,
              lng: stop.stopLongitude
            });
            this.geocodingService
              .inverseGeoconding(stop.stopLatitude, stop.stopLongitude, 18)
              .subscribe(adress => {
                let truncatedDisplayName = adress.display_name;
                let countCommas = 0;
                for (
                  var i = 0, len = adress.display_name.length;
                  i < len;
                  i++
                ) {
                  if (adress.display_name[i] == ",") {
                    countCommas = countCommas + 1;
                  } else if (countCommas >= 4) {
                    truncatedDisplayName = adress.display_name.substring(
                      0,
                      i - 1
                    );
                    break;
                  }
                }
                popup = popup + "<br><hr><b>" + truncatedDisplayName + "</b>";
                stopMarker.bindPopup(popup);
              });
            stopMarker.setIcon(
              new Icon({
                iconUrl: imagesDir + "stop_smal.png",
                iconAnchor: [-1, 18],
                popupAnchor: [10, -25]
              })
            );
            this.mapService.addMarker(stopMarker);
          });
          this.polyline = P.polyline(points.coordinates, {
            //color: "#0031D9",
            color: polylineColor,
            weight: 3
          });
          points.coordinates.forEach((coordinate: any, i: any) => {
            let marker = new Marker(coordinate);
            if (i != 0 && i != points.coordinates.length - 1) {
              var iconUrl = imagesDir + "green-point.png"
              if(coordinate.speed >= path.maxSpeed)
              iconUrl = imagesDir + "red-point.png"
              marker.setIcon(
                new Icon({
                  iconUrl: iconUrl,
                  iconAnchor: [2, 2]
                })
              );
              let pointTime: any = coordinate.date;
              let coordinateTime: any = pointTime;
              coordinate.date = coordinateTime;
              var pointDatePipe = new DatePipe(coordinate.date);
              let popup =
                "<b>Heure:</b> " +
                pointDatePipe.transform(
                  coordinate.date,
                  "dd/MM/yyyy HH:mm:ss", '', 'fr-fr'
                ) +
                "<b><br>vitesse:</b> " +
                coordinate.speed +
                " Km/h";
              marker.bindPopup(popup);
              marker.on("click", () => {
                this.mapService.map.setView(coordinate, 17);
              });
              this.mapService.addMarker(marker);
            }
          });
          var startMarker = new Marker({
            lat: path.beginPathLatitude,
            lng: path.beginPathLongitude
          });
          let startTime: any = path.beginPathTime;
          var startDatePipe = new DatePipe(startTime);
          startMarker.bindPopup(
            "<b> Lieu de début: </b>" +
            this.pathClicked.beginPathGeocoding +
            "<br><b> <i>[lat: </b>" +
            path.beginPathLatitude +
            "<b> ,long: </b>" +
            path.beginPathLongitude +
            "<b>]</i><br>Temps de debut du trajet : </b>" +
            startDatePipe.transform(startTime, "dd/MM/yyyy HH:mm:ss", '', 'fr-fr')
          );
          startMarker.setIcon(
            new Icon({
              iconUrl: imagesDir + "startMarker.png",
              iconAnchor: [-2, 30],
              popupAnchor: [10, -25]
            })
          );
          this.mapService.addMarker(startMarker);
          if (
            path.endPathLatitude != null &&
            path.endPathLongitude != null &&
            path.endPathTime != null
          ) {
            var endMarker = new Marker({
              lat: path.endPathLatitude,
              lng: path.endPathLongitude
            });
            let endTime: any = path.endPathTime;
            var endDatePipe = new DatePipe(endTime);
            endMarker.bindPopup(
              "<b> Lieu de fin: </b>" +
              this.pathClicked.endPathGeocoding +
              "<br><i>[<b>lat:  </b>" +
              path.endPathLatitude +
              "<b>,long:  </b>" +
              path.endPathLongitude +
              "]</i><br><b>Temps de fin du trajet : </b>" +
              endDatePipe.transform(endTime, "dd/MM/yyyy HH:mm:ss", '', 'fr-fr') +
              "<br><b> Durée du trajet : </b>" +
              path.pathDurationStr +
              "<br><b> Durée d'arrêt : </b>" +
              path.nextStopDurationStr +
              "<br><b> Vitesse maximum : </b>" +
              path.maxSpeed +
              " Km/h <br><b> Kilometrage parcouru : </b>" +
              path.distanceDriven.toFixed(2) +
              " Km"
            );
            endMarker.setIcon(
              new Icon({
                iconUrl: imagesDir + "endMarker.png",
                iconAnchor: [-2, 30],
                popupAnchor: [10, -25]
              })
            );
            this.mapService.addMarker(endMarker);
          }
          this.mapService.addPolyline(this.polyline);
          var middle =
            points.coordinates[Math.round((points.coordinates.length - 1) / 2)];

          //map center offset(axis y)
          this.mapService.offsetMap(middle, 12, 0, -120);
          subject.next(points);
        },
        err => {
          this.toastr.error("Erreur détails trajet", "Erreur")
          subject.next(null);
        }
      );
    return subject.asObservable();
  }

  tableToggle() {
    if (this.isTableExpanded) {
      document.getElementById("datatable-container").style.height = "55px";
      this.isTableExpanded = false;
    } else {
      document.getElementById("datatable-container").style.height = "auto";
      this.isTableExpanded = true;
    }
  }

  expandTable() {
    if (!this.isTableExpanded) {
      document.getElementById("datatable-container").style.height = "auto";
      this.isTableExpanded = true;
    }
  }

  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
    }
  }

  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
  }

  //==================================
  // Exporting data of Paths
  //==================================
  loader: boolean = false;
  exportPaths() {
    this.loader = true;
    let vehicule = this.pathService.getMatriculeByDeviceId(
      this.selectedGroup,
      this.selectedVehicule.idDevice
    );

    this.pathService
      .exportPaths(
        this.selectedVehicule.idDevice,
        this.selectedGroup.nom,
        vehicule, {
          startDate: this.startDate.getTime(),
          endDate: this.endDate.getTime()
        }
      )
      .subscribe(
        blob => {
          if (blob.size != 0) importedSaveAs(blob, "LISTE_TRAJET.xlsx");
          this.loader = false;
        }, error => {
          this.toastr.error('Erreur lors de l\'exportation !', 'Error');
          this.loader = false;
        }
      );
  }

  pdfLoader: boolean = false;
  exportPathsPDF() {
    this.pdfLoader = true;
    let vehicule = this.pathService.getMatriculeByDeviceId(
      this.selectedGroup,
      this.selectedVehicule.device.idDevice
    );
    let pathToExport: Path[] = this.paths.slice();

    this.pathService.exportPathsPDF(pathToExport,vehicule, this.selectedGroup.nom, this.startDate.getTime(), this.endDate.getTime()).subscribe(blob => {
      if (blob.size != 0)
        importedSaveAs(blob, "LISTE_TRAJET.pdf");
        this.pdfLoader = false;
    }, () => {
      this.toastr.error('aucune données entre ces deux dates !', 'Error');
      this.pdfLoader = false;
    });
  }


  savePath() {
    this.pathService
      .saveTypicalPath({
        deviceId: this.pathClicked.deviceId,
        beginPathTime: this.pathClicked.beginPathTime,
        name: this.typicalPathName
      })
      .subscribe(res => {
        this.toastr.success("Trajet " + this.typicalPathName + " enregistré!", "info");
        this.hideChildModal();
        this.getallTypicalPath();
      });
  }

  displaySavePath() {
    if (this.pathClicked != null) {
      return "visible";
    } else return "none";
  }

  displayGoogleAdress(path: Path) {
    if (path) {
      this.geocodingService
        .inverseGeocondingGoogle(
          path.beginPathLatitude,
          path.beginPathLongitude
        )
        .subscribe(response => {
          if (response && response.results[0]) {
            path.beginPathGeocodingDetails =
              response.results[0].formatted_address;
          }
        });

      this.geocodingService
        .inverseGeocondingGoogle(path.endPathLatitude, path.endPathLongitude)
        .subscribe(response => {
          if (response && response.results[0]) {
            path.endPathGeocodingDetails =
              response.results[0].formatted_address;
          }
        });
    }
  }
}
