import { Component, OnInit, Input } from '@angular/core';
import { ChargePfbInfoDto, ChargeRealise, VoyageDto } from '../../charges-pfb';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
const Highcharts4 = require('highcharts');

require('highcharts/modules/exporting')(Highcharts4);
require('highcharts/modules/no-data-to-display')(Highcharts4);
require('highcharts/highcharts-more')(Highcharts4);

@Component({
  selector: 'app-location-cost',
  templateUrl: './location-cost.component.html',
  styleUrls: ['./location-cost.component.css']
})
export class LocationCostComponent implements OnInit {
  caRealise_: any[] = [];
  chargePfbInfoDto: ChargePfbInfoDto;
  voyage_: VoyageDto = new VoyageDto();
  locationCostCharge: Object | null = null;
  locationCostChargeTimeLineChart: Object | null = null;
  globalLoading: boolean;

  selectedCharge: ChargePfbInfoDto = new ChargePfbInfoDto();
  dayDif: number = 0;
  objectif: number = 0;
  aRealiser: number = 0;
  realise: number = 0;

  @Input()
  set selectedCharges(chargePfb: ChargePfbInfoDto) {
    if (chargePfb) {
      this.selectedCharge = chargePfb;
    }
  }


  @Input()
  set locationDayDifference(dayDifference: number) {
    this.dayDif = dayDifference;
  }

  @Input() isManually: any;


  @Input()
  set caRealise(caRealise: ChargeRealise[]) {
    if (this.isManually) {
      this.caRealise_ = Object.assign(caRealise);
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.gaugeLocationCostChart();
    }
  }

  @Input()
  set voyage(voyage: VoyageDto) {
    if (!this.isManually) {
      if(voyage)
      this.voyage_ = Object.assign(voyage);
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.gaugeLocationCostChart();
    }
  }

  constructor(public toastr: ToastrService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.gaugeLocationCostChart();
  }

  timeChart() {


    this.objectif = 0;
    this.aRealiser = 0;
    this.realise = 0;

    var data: { x: Date, y: number }[] = [];
    var index = 0;


    if (this.selectedCharge) {
      this.objectif = this.selectedCharge.transportCharge / this.selectedCharge.nbDays;
    }
    if (this.isManually) {
      this.caRealise_.forEach(ca => {
        data.push({ x: ca.operationTime, y: ca.location });
        if (index == this.caRealise_.length - 1) {
          this.realise = ca.location;
          this.aRealiser = this.objectif - this.realise;
        }
        index++;
      })
    } else {

      const travelsByDate = new Map(Object.entries(this.voyage_.travelsByDate).sort((a, b) =>{
        return (a < b) ? -1 : 1
       }));

      if (travelsByDate.size > 0) {
        Array.from(travelsByDate.keys()).forEach(key => {
          let location = travelsByDate.get(key).reduce((acc: any, cur: { location: any; }) => acc + cur.location, 0);
          let date = new Date(+key);
          data.push({ x: date, y: location });

          if (index == travelsByDate.size - 1) {
            this.realise = location;
            this.aRealiser = this.objectif - this.realise;
          }
          index++;
        });
      }
    }

    this.locationCostChargeTimeLineChart = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      plotOptions: {
        enableMouseTracking: false
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: ' DH'
      },
      title: {
        text: 'Charge Location',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 86400000,
        labels: {
          formatter: function (this: { value: number }): string {
            return Highcharts4.dateFormat("%d/%m/%Y", this.value);
          }
        }
      },
      yAxis: {
        minRange: this.objectif,
        min: 0,
        plotLines: [{
          value: this.objectif,
          color: '#ff0000',
          dashStyle: 'shortdash',
          width: 2,
          zIndex: 4,
          label: {
            text: 'Budget',
            textAlign: 'center'
          }
        }],
      },
      series: [{
        findNearestPointBy: 'xy',
        name: 'Consommée',
        data: data,
        zones: [{
          value: this.objectif,
          color: 'green'
        }, {
          color: 'red'
        }]
      }]

    }, Highcharts4.chart('locationCostChart', this.locationCostChargeTimeLineChart);

  }


  gaugeLocationCostChart() {

    // Create the chart
    this.objectif = 0;
    this.aRealiser = 0;
    this.realise = 0;
    var color = '#008000';
    var caLocation = [];

    if (this.isManually)
      caLocation = this.caRealise_.filter(ca => ca.location > 0);
    var sumLocation = 0
    const travelsByDate = new Map(Object.entries(this.voyage_.travelsByDate).sort((a, b) =>{
      return (a < b) ? -1 : 1
     }));

    if (travelsByDate.size > 0)
      sumLocation = Array.from(travelsByDate.values()).pop().reduce((acc: any, cur: { location: any; }) => acc + cur.location, 0);
    if (caLocation.length > 0 || sumLocation > 0) {
      this.objectif = (this.selectedCharge.transportCharge / this.selectedCharge.nbDays) * this.dayDif;
      let location;
      if (this.isManually) {
        location = caLocation[caLocation.length - 1].location;
      } else {
        location = sumLocation;
      }
      this.aRealiser = this.objectif - location;
      this.realise = location;

      if (this.realise > this.objectif)
        color = '#c90000';
    }

    this.locationCostCharge = {

      chart: {
        type: 'solidgauge',
        events: {
          // load: function () {
          //   let yData = this.series[0].data[0].y;
          //   let xData = this.series[0].data[0].x;

          //   var chart = this;
          //   if (!chart.lbl) {
          //     chart.lbl = chart.renderer.label('', (this.chartWidth - 80) / 2, (this.plotHeight / 2) + 15)
          //       .attr({
          //         padding: 10,
          //         r: 10,
          //       })
          //       .css({
          //         color: '#000',
          //         fontSize: 13
          //       })
          //       .add();
          //   }

          //   chart.lbl
          //     .show()
          //     .attr({
          //       text: '<span style="font-size:15px;font-weight:bold;">' + this.series[0].name + '</span><br/><span style="font-size:13px;color:' + this.series[0].data[0].color + ';font-weight:bold;">' + Highcharts4.numberFormat(xData, 2) + 'DH</span><br/>'
          //         + '<span style="font-size:17px; color: ' + this.series[0].data[0].color + '; font-weight: bold">' + Highcharts4.numberFormat(yData, 1) + '%</span>',

          //     });

          // }
        }
      },
      title: {
        text: 'Charge Location ',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
        enabled: false,
        positioner: function (labelWidth: number) {
          // return {
          //   x: (this.chart.chartWidth - labelWidth) / 2,
          //   y: (this.chart.plotHeight / 2) + 15
          // };
        }
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ // objectif
          outerRadius: '112%',
          innerRadius: '88%',
          backgroundColor: "#98c5f5",
          borderWidth: 0
        }, { // Réalisé
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: '#98c5f5',
          borderWidth: 0
        }]
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        },
        series: {
          point: {
            events: {
              mouseOver: function () {
                // var chart = this.series.chart;
                // if (!chart.lbl) {
                //   chart.lbl = chart.renderer.label('', (this.chartWidth - 80) / 2, (this.plotHeight / 2) + 15)
                //     .attr({
                //       padding: 10,
                //       r: 10,
                //     })
                //     .css({
                //       color: '#000',
                //       fontSize: 13
                //     })
                //     .add();
                // }
                // chart.lbl
                //   .show()
                //   .attr({
                //     text: '<div style="width:100%;text-align:center;"><span style="font-size:15px;font-weight:bold;">' + this.name + '</span><br/><span style="font-size:13px;color:' + this.color + ';font-weight:bold;">' + Highcharts4.numberFormat(this.x, 2) + 'DH</span><br/>'
                //       + '<span style="font-size:17px;color:' + this.color + ';font-weight:bold;">' + Highcharts4.numberFormat(this.y, 1) + '%</span>',
                //   });
              }
            }
          },
        }
      },
      series: [{
        name: 'Budget',
        data: [{
          name: 'Budget',
          color: '#007bff',
          radius: '112%',
          innerRadius: '88%',
          y: (this.objectif / this.objectif) * 100,
          x: this.objectif
        }]
      }, {
        name: 'Réalisé',
        data: [{
          name: 'Réalisé',
          color: color,
          radius: '87%',
          innerRadius: '63%',
          y: (this.realise / this.objectif) * 100,
          x: this.realise
        }]
      }],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        noData: 'Aucune donnée n\'est disponible'
      }
    },
      Highcharts4.chart('locationCostChart', this.locationCostCharge);
  }

}
