<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<section>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-6">
          <i class="fa fa-tachometer"></i> <span>Charge</span>
          <i class="fa fa-plus-circle add" *ngIf="selectedMonth?.id == months[months?.length - 1]?.id && selectedYear?.id == years[0]?.id"
            aria-hidden="true">
            <span (click)="openAffectationForm('ADD')"> Ajouter </span>
          </i>
        </div>

        <div class="col-sm-3">
          <label class="col-sm-2 control-label grp-lbl">Année</label>
          <div class="col-sm-10 grp-slct">
            <select class="form-control input-sm"  [(ngModel)]='selectedYear' (ngModelChange)="onMonthOrYearChange(true)"
              name='selectedYear' required>
              <option style="font-size: 14px;" *ngFor="let year of years" [ngValue]="year" >
                {{year.id}}</option>
            </select>
          </div>
        </div>

        <div class="col-sm-3">
          <label class="col-sm-2 control-label grp-lbl">Mois</label>
          <div class="col-sm-10 grp-slct">
            <select class="form-control input-sm"  [(ngModel)]='selectedMonth' (ngModelChange)="onMonthOrYearChange(false)"
              name='selectedMonth' required>
              <option style="font-size: 14px;" *ngFor="let month of months" [ngValue]="month" >
                {{month.name}}</option>
            </select>
          </div>
        </div>

      </div>
    </div>


    <!-- CARD BODY -->
    <div class="card-body p-0">
      <div class="app">
        <div class="main">
          <div class="container p-0">

            <div class="messages">
              <form>
                <div class="inner-addon left-addon">
                  <i class="fa fa-search"></i>
                  <input type="search" class="search" placeholder="Rechercher..." name="search" [(ngModel)]="searchText"
                  (ngModelChange)="SearchAffectation()" />
                </div>
              </form>


              <!-- CHARGEPFB ITEMS -->
              <ul class="message-list">
                <li *ngFor="let affectation of affectationList"
                [ngClass]="{'active': selectedAffectation?.idChargeChantier == affectation.idChargeChantier}"
                  (click)="selectedAffectation = affectation">
                  <div class="preview">
                    <h5>{{affectation?.group?.nom}}  <small> {{affectation?.nbrJours}} </small></h5>
                    <p>Chiffre d'affaire: {{affectation?.chiffreAffaire}} DH</p>

                  </div>
                </li>
              </ul>
              <!-- END CHARGEPFB ITEMS -->
            </div>

            <!-- CHARGEPFB BODY -->
            <section  class="message">
              <h3> {{selectedAffectation?.group?.nom}} </h3>

              <div class="option-add" *ngIf="affectationList.length > 0">
                <span title="Modifier" (click)="openAffectationForm('UPDATE')" ><i class="fa fa-edit"></i> Modifier</span>
              </div>

              <div class="option-delete" *ngIf="selectedMonth?.id == months[months?.length - 1]?.id && selectedYear?.id == years[0]?.id && affectationList.length > 0">
                <span title="Supprimer" (click)="onConfirmDelete()"><i class="fa fa-minus-circle"></i> Supprimer</span>
              </div>

              <div class="meta-data">
                <div class="table-responsive" *ngIf="affectationList.length > 0">
                  <table class="table table-sm tableFixHead ">
                    <thead>
                      <tr>
                        <th>Pôle</th>
                        <th>Chiffre d'affaire (DH)</th>
                        <th>Budget carburant (DH)</th>
                        <th>Budget maintenance (DH)</th>
                        <th>Budget location (DH)</th>
                        <th>NBR. Jour</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{selectedAffectation?.group?.nom}}</td>
                        <td>{{selectedAffectation?.chiffreAffaire}}</td>
                        <td>{{selectedAffectation?.budgetCarburant}}</td>
                        <td>{{selectedAffectation?.budgetMaintenance}}</td>
                        <td>{{selectedAffectation?.budgetLocation}}</td>
                        <td>{{selectedAffectation?.nbrJours}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="alert alert-warning alert-dismissible" style="border-radius: 0px;text-align: center" *ngIf="affectationList.length == 0">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                      ×
                  </button>
                  <h4 style="color: white">
                      <i class="fa fa-info-circle"></i> Information
                  </h4>
                  Il n'y a aucune affectation à cette date.
              </div>

              </div>

            </section>
            <!-- END CHARGEPFB BODY -->
          </div>
        </div>
      </div>
    </div>
    <!-- END CARD BODY -->
  </div>
</section>



<!-- ========================================== DashBord Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1"  #AffectationFormModal="bs-modal" bsModal role="dialog" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- MODAL HEADER -->
      <div class="modal-header">
        <button type="button" class="close" (click)="AffectationFormModal.hide()">&times;</button>
        <h4 class="modal-title"><i class="fa fa-plus-circle" aria-hidden="true"></i> Charge Mensuelle PFB (
          {{selectedMonth?.name}} )</h4>
      </div>
      <!-- END MODAL HEADER -->

      <!-- MODAL BODY -->
      <div class="modal-body">
        <div class="box-body">
          <app-affectation-form (affectationSaved)="onAffectationSaved($event)" [selectedYear]="selectedYear?.id" [selectedMonth]="selectedMonth?.id"
          [selectedAffectationEdit]="selectedAffectationEdit" [mode]="mode"></app-affectation-form>
        </div>
      </div>
      <!-- END MODAL BODY -->

      <!-- MODAL FOOTER -->
      <div class="box-footer">
        <button  class="btn btn-default pull-right" (click)="AffectationFormModal.hide()">
          Fermer
        </button>
      </div>
      <!-- END MODAL FOOTER -->

    </div>
  </div>
</div>
<!-- ========================================== DashBord Modal (END) ====================================================-->


<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" tabindex="-1" #AffectationDeleteModal="bs-modal" bsModal role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="AffectationDeleteModal.hide()">&times;</button>
        <h4 class="modal-title">
          <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Suppression !
          </h4>
          <span>Êtes-vous sûr de supprimer cette Affectation '{{selectedAffectation?.group?.nom}}', ça risque de perdre tous les
            historiques !</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="AffectationDeleteModal.hide()">
          Annuler
        </button>
        <button type="button" class="btn btn-success" (click)="onDelete()">
          <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>Oui, Je confirme
        </button>
      </div>
    </div>
  </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
