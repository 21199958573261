<div class="row">
    <div class="col-xs-5 content-poi" style="background-color: transparent !important;padding: 0 0 0 20px;">
        <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
                <li class="active">
                    <a href="#points" data-toggle="tab" aria-expanded="true" (click)="searchTerm = null;importExport=true;initPolygons();">
  
                        <span class="pull-left-container">
                            <i class="fa fa-map-marker" aria-hidden="true"></i> Points
                            <small class="label bg-blue">{{pointInterestsPoint.length}}</small>
                        </span>
                    </a>
                </li>
                <li class="">
                    <a href="#zones" data-toggle="tab" aria-expanded="false" (click)="searchTerm = null;importExport=false;initPoints();">
                        <a href="#zones" data-toggle="tab" aria-expanded="false">
                            <span class="pull-left-container">
                                <i class="fa fa-map" aria-hidden="true"></i> Zones
                                <small class="label bg-blue">{{pointInterestsPolygon.length}}</small>
                            </span>
                        </a>
                    </a>
                </li>
  <!--==================================== import/Export functionality(START)=============== -->
                <div *ngIf="importExport" id="importExpo">
                    <li class="pull-right mt-2 pr-2">
  
                                    <button type="button" class="btn btn-primary exportImpo" title="Enregistrer sous format excel"
                                (click)='export()' >Exporter <span class="badge"><i class="fa fa-download"></i></span></button>
  
                    </li>
                    <li class="pull-right mt-2">
  
                                <button type="button" class="btn btn-primary exportImpo" title="Enregistrer sous format excel"
                                (click)='importModal.show()' >Importer <span class="badge"><i class="fa fa-upload"></i></span></button>
  
  
                    </li>
                </div>
  <!--==================================== import/Export functionality(END)================= -->
  
            </ul>
  
            <div class="tab-content" style="background-color: transparent !important;">
  
                <div class="overlay" *ngIf="!isDataLoaded">
                    <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
                </div>
  
                <div *ngIf="isDataLoaded" class="tab-pane active" id="points" style="background-color: transparent !important;">
  
                    <table class="table table-fixed" style="font-size: 12px;">
                        <thead>
                            <tr>
                                <th class="col-xs-3">Libellé</th>
                                <th class="col-xs-5">Adresse</th>
                                <th class="col-xs-4" colspan="2">
                                    <div class="input-group input-group-sm">
                                        <input type="text" name="table_search" class="form-control pull-right" placeholder="Rechercher..." [(ngModel)]="searchTerm" (ngModelChange)="initPoints()">
  
                                        <div class="input-group-btn">
                                            <button type="submit" class="btn btn-default">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="col-xs-12" *ngFor="let point of dataPoint" (click)="displayPointPolygon(point)"
                                [ngClass]="{'activePOI': point == pointInterest}">
                                <td class="col-xs-2" style="font-weight: bold">{{point.name | truncate :[30]}}</td>
                                <td class="col-xs-5" style="font-weight: bold">
                                    {{point.address | truncate :[50]}}
                                </td>
                                <td class="col-xs-3">
                                    <label>{{point?.coordinate?.lat | number:'2.2-6'}},{{point?.coordinate?.lng | number:'2.2-6'}}
                                    </label>
                                </td>
                                <td class="col-xs-1" (click)="$event.stopPropagation();updatePOI(point)">
                                    <i title="Modifier" class="fa fa-cog" aria-hidden="true"></i>
                                </td>
                                <td class="col-xs-1" (click)="$event.stopPropagation();deletePointPolygon(point)">
                                    <i title="Supprimer" class="fa fa-trash-o" style="color: red !important;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
  
                    <div class="box-footer clearfix" *ngIf="pointInterestsPoint.length >0" style="text-align: center">
                        <pagination class="pagination-sm no-margin"
                                [(ngModel)]="page"
                                [totalItems]="pointInterestsPoint.length"
                                [itemsPerPage]="itemsPerPage"
                                [maxSize]="maxSize"
                                [boundaryLinks]="true"
                                (pageChanged)="PageChangePoint($event)"
                                previousText="Précédent"
                                nextText="Suivant"
                                firstText="Premier"
                                lastText="Dernier">
                        </pagination>
                    </div>
  
                </div>
  
                <div *ngIf="isDataLoaded" class="tab-pane" id="zones" style="background-color: rgba(255, 255, 255, 0.55)">
  
                    <table class="table table-fixed" style="font-size: 12px;">
                        <thead>
                            <tr>
                                <th class="col-xs-3">Libellé</th>
                                <th class="col-xs-5">Adresse</th>
                                <th class="col-xs-4" colspan="2">
                                    <div class="input-group input-group-sm">
                                        <input type="text" name="table_search" class="form-control pull-right" placeholder="Rechercher..." [(ngModel)]="searchTerm" (ngModelChange)="initPolygons()">
  
                                        <div class="input-group-btn">
                                            <button type="submit" class="btn btn-default">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="col-xs-12" *ngFor="let point of dataPolygon" (click)="displayPointPolygon(point)"
                                [ngClass]="{'activePOI': point == pointInterest}">
                                <td class="col-xs-2" style="font-weight: bold">{{point.name | truncate :[30]}}</td>
                                <td class="col-xs-5" style="font-weight: bold">
                                    {{point.address | truncate :[50]}}
                                </td>
                                <td class="col-xs-3">
                                    <label>{{point?.coordinate?.lat | number:'2.2-6'}},{{point?.coordinate?.lng | number:'2.2-6'}}
                                    </label>
                                </td>
                                <td class="col-xs-1" (click)="$event.stopPropagation();updatePOI(point)">
                                    <i title="Modifier" class="fa fa-cog" aria-hidden="true"></i>
                                </td>
                                <td class="col-xs-1" (click)="$event.stopPropagation();deletePointPolygon(point)">
                                    <i title="Supprimer" class="fa fa-trash-o" style="color: red !important;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
  
                    <div class="box-footer clearfix" *ngIf="pointInterestsPolygon.length >0" style="text-align: center">
                        <pagination class="pagination-sm no-margin"
                                [(ngModel)]="pagePolgygon"
                                [totalItems]="pointInterestsPolygon.length"
                                [itemsPerPage]="itemsPerPagePolgygon"
                                [maxSize]="maxSizePolgygon"
                                [boundaryLinks]="true"
                                (pageChanged)="PageChangePolygon($event)"
                                previousText="Précédent"
                                nextText="Suivant"
                                firstText="Premier"
                                lastText="Dernier">
                        </pagination>
                    </div>
  
                </div>
  
            </div>
        </div>
    </div>
  </div>
  
  <section class="map-container">
    <app-leaflet [item]="'poi'" [hidebutton]="false"></app-leaflet>
  </section>
  <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
  
            <div class="modal-header black-bg">
                <h4 class="modal-title pull-left" style="font-family: 'Inconsolata', monospace;font-size: 17px;">
                    <i class="fa fa-plus-pen"></i> Modifier point d'intérêt
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="childModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
  
            <div class="modal-body">
                <app-poi-form [pointInterest]="pointInterest" [mode]="'UPDATE'" (pointInterestWasUpdated)="childModal.hide()"></app-poi-form>
            </div>
        </div>
    </div>
  </div> 
  
  <div class="modal fade" tabindex="-1" bsModal #poiDeleteModal="bs-modal">
    <div class="modal-dialog modal-lg">
          <div class="modal-content">
                <div class="modal-header">
                      <button type="button" class="close" (click)="poiDeleteModal.hide()">&times;</button>
                      <h4 class="modal-title">
                            <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                      </h4>
                </div>
                <div class="modal-body">
                      <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                            <h4 style="color: white">
                                  <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                                  Êtes-vous sûr de supprimer ce poi ?
                      </div>
                </div>
                <div class="modal-footer">
                      <button type="button" class="btn btn-default" (click)="poiDeleteModal.hide()">
                            Annuler
                      </button>
                      <button type="button" class="btn btn-success" (click)="onDelete()">
                            <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                      </button>
                </div>
          </div>
    </div>
  </div>
  
  
  <!--=================================Import fuel(start) ====================================-->
  <div class="modal" tabindex="-1" bsModal #importModal="">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="importModal.hide()">&times;
                </button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Importer la liste des POI
                </h4>
            </div>
            <div class="modal-body vertical-spacer">
                <app-import-export-forms [item]='selectedItem'
                    (importedItem)='itemWasImported($event)'></app-import-export-forms>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="importModal.hide()">
                    Fermer
                </button>
            </div>
        </div>
    </div>
  </div>
  <!--=================================Import fuel (end) ====================================-->