import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverComponent } from './driver.component'; // Adjust the path if necessary
import { ToastrModule } from 'ngx-toastr'; // For notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // For modals
import { DataManagementService } from '../data-management.service'; // Adjust the path as necessary
import { DriverService } from '../providers/driver.service'; // Ensure the path is correct
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AvatarModule } from 'ngx-avatar';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DriverComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(), // Import Toastr module for notifications
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    PaginationModule.forRoot(),
    AvatarModule,
    RouterModule
  ],
  providers: [
    DriverService, // Provide the driver service
    DataManagementService // Provide the data management service
  ],
  exports: [
    DriverComponent // Export if you want to use this component outside of this module
  ]
})
export class DriverModule { }
