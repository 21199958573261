import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PoiClientComponent } from './poi-client.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { GeocodingService } from '../../../utils/leaflet/service/geocoding.service';
import { MapService } from '../../../utils/leaflet/service/map.service';
import { DataManagementService } from '../../data-management/data-management.service';
import { ImportExportService } from '../../import-export/import-export.service';
import { PoiClientFormModule } from './poi-client-form/poi-client-form.module';
import { ImportExportFormsComponent } from '../../parc-management';
import { LeafletComponent } from 'src/app/utils/leaflet/leaflet.component';
import { ImportExportFormsModule } from '../../parc-management/import-export-forms/import-export-forms.module';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    PoiClientComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    PoiClientFormModule,
    ImportExportFormsModule,
    LeafletModule,
    PaginationModule.forRoot(),
    TruncateModule

  ],
  providers: [
    GeocodingService,
    MapService,
    DataManagementService,
    ImportExportService
  ],
  exports: [
    PoiClientComponent
  ]
})
export class PoiClientModule { }
