import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parametrage-canal',
  templateUrl: './parametrage-canal.component.html',
  styleUrls: ['./parametrage-canal.component.scss'],
})
export class ParametrageCanalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
