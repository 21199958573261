import { Injectable } from '@angular/core';
import { Brake, Offense, Sinistre } from "../model";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { dns } from "../../../global.config";
import { Observable } from "rxjs/Observable";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LossesService {

    constructor(private _http: HttpClient) {
    }

    getSinistres(idVehicule: number): Observable<Sinistre[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<Sinistre[]>(dns + 'sinistres/' + idVehicule, { headers: headers });
    }

    public addSinistre(sinistre: Sinistre, idVehicule: number, groupId: number): Observable<Sinistre> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.post<Sinistre>(dns + 'sinistres/' + idVehicule + "?groupId=" + groupId, sinistre, options);
    }

    public deleteSinistre(idSinistre: number): Observable<boolean> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.delete<boolean>(dns + 'sinistres/' + idSinistre, options);
    }

    public deleteMultipleSinistre(sinistresId: number[]): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + "sinistres/multiple/" + sinistresId, { headers: headers });
    }

    public updateSinistre(sinistre: Sinistre): Observable<Sinistre> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.put<Sinistre>(dns + 'sinistres', sinistre, options);
    }

    getOffenses(idVehicule: number): Observable<Offense[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<Offense[]>(dns + 'offenses/' + idVehicule, { headers: headers });
    }

    public addOffensee(offense: Offense, idVehicule: number, groupId: number): Observable<Offense> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.post<Offense>(dns + 'offenses/' + idVehicule + "?groupId=" + groupId, offense, options);
    }

    public deleteOffense(idOffense: number): Observable<boolean> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.delete<boolean>(dns + 'offenses/' + idOffense, options);
    }

    public deleteMultipleOffense(offensesId: number[]): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + "offenses/multiple/" + offensesId, { headers: headers });
    }

    public updateOffense(offense: Offense): Observable<Offense> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this._http.put<Offense>(dns + 'offenses', offense, options);
    }

}
