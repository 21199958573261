import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionTrackingComponent } from './mission-tracking.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker functionality
import { FormsModule } from '@angular/forms'; // For template-driven forms
import { GeocodingService } from '../../../../utils/leaflet/service/geocoding.service'; // Adjust the path as necessary
import { DataManagementService } from '../../data-management.service'; // Adjust the path as necessary
import { MissionService } from '../../providers/mission.service'; // Adjust the path as necessary
import { NgxLoadingModule } from 'ngx-loading';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TimediffPipeModule } from 'src/app/pipes/timediff.pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { PositiveNumberPipeModule } from 'src/app/pipes/positive-number.pipe.module';

@NgModule({
  declarations: [
    MissionTrackingComponent // Declare the MissionTrackingComponent
  ],
  imports: [
    CommonModule, // Provides Angular common directives
    ToastrModule.forRoot(), // For Toastr notifications
    BsDatepickerModule.forRoot(), // For Bootstrap datepicker functionality
    FormsModule, // For handling form controls
    NgxLoadingModule.forRoot({}),
    SharedPipesModule,
    TimediffPipeModule,
    MatTooltipModule,
    PopoverModule.forRoot(),
    TruncateModule,
    PositiveNumberPipeModule
  ],
  providers: [
    GeocodingService, // Provide GeocodingService
    DataManagementService, // Provide DataManagementService
    MissionService // Provide MissionService
  ],
  exports: [
    MissionTrackingComponent // Export the component to make it available elsewhere
  ]
})
export class MissionTrackingModule { }
