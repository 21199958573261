import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Schedule } from '../schedule';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

@Component({
  selector: 'app-passage-chart',
  templateUrl: './passage-chart.component.html',
  styleUrls: ['./passage-chart.component.css']
})
export class PassageChartComponent implements OnInit {
  passage: Object | null = null;
  passageList_: Schedule[] = [];
  cat = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  _numberOfPassage: number;

  date: Date = new Date();

  @Input()
  set selectedDate(date: Date) {
    this.date = date;
  }


  @Input()
  set numberOfPassage(numberOfPassage: number) {
    this._numberOfPassage = numberOfPassage
  }
  @Input()
  set passageList(passageList: Schedule[]) {
    if (passageList) {
      this.passageList_ = Object.assign([], passageList);
      this.CreateChart();
    }
  }


  constructor() { }

  ngOnInit() {
  }

  CreateChart() {

    var data: number[] = [];

    // for (let i = 0; i < this.cat.length; i++) {
    //   date.setHours(this.cat[i]);
    //   var count = 0;
    //   for (let j = 1; j < this.passageList_.length; j++) {
    //     var startDate = new Date(this.passageList_[j].createdAt);
    //     if (date.getDate() == startDate.getDate() && date.getHours() == startDate.getHours()) {
    //       count++;
    //     }
    //   }
    //   data.push(count);
    // }

    var matricules = new Set(this.passageList_.map(s => { return s.matricule }));

    for (let i = 0; i < this.cat.length; i++) {
      this.date.setHours(this.cat[i]);
      var count = 0;
      matricules.forEach(matricule => {
        var passages = this.passageList_.filter(p => p.matricule == matricule);

        for (let j = 1; j < passages.length; j++) {
          if (passages[j].type == 'Start') {
            if (passages[j - 1].type == 'Stop' || passages[j - 1].type == 'Between') {
              var startDate = new Date(passages[j].beginStopTime);
              if (this.date.getDate() == startDate.getDate() && this.date.getHours() == startDate.getHours()) {
                count++;
              }
            }
          }

        }
      })
      data.push(count);
    }

    this.passage = {
      chart: {
        type: 'column'
      },
      title: {
        text: null
      },
      xAxis: {
        categories: this.cat
      },
      yAxis: {
        min: 0,
        minRange:this._numberOfPassage,
        dataLabels: {
          enabled: true
        },
        title: {
          text: 'Passage'
        }, plotLines: [{
          value: this._numberOfPassage,
          color: 'red',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: 'Cible',
          }
        }],
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<b>{point.x} H</b><br/>',
        pointFormat: '{series.name}: {point.y}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        name: 'Passage',
        data: data
      }]
    }
    Highcharts.chart('passage', this.passage);
  }

}
