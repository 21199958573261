import {
  Component,
  OnInit,
  Host,
  AfterViewInit,
  ViewChild
} from "@angular/core";
import { DataManagementComponent } from "../data-management.component";
import { DriverService } from "../providers/driver.service";
import { ToastrService } from "ngx-toastr";
import { Driver } from "../data-management.model";
import { ModalDirective } from "ngx-bootstrap/modal";
import { DataManagementService } from "../data-management.service";
import { filesDir } from "../../../global.config";

@Component({
  selector: "app-driver",
  templateUrl: "./driver.component.html",
  styleUrls: ["./driver.component.css"]
})
export class DriverComponent implements OnInit {
  // pagination vars
  public maxSize: number = 5;
  public bigTotalItems: number = 175;
  public bigCurrentPage: number = 1;
  public numPages: number = 0;
  itemsPerPage = 30;
  keyword: string = "";

  // local vars
  drivers: Driver[] = [];
  loading: boolean;
  globalLoading: boolean = false;
  currentDriver: Driver;

  filesDir = filesDir;

  @ViewChild("confirmDeleteModal", { static: false }) confirmDeleteModal: ModalDirective;

  constructor(
    private driverService: DriverService,
    public dataManagementService: DataManagementService,
    private dataManagementComponent: DataManagementComponent,
    public toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadDrivers();
  }

  /**
   * loadDrivers : load driver accordnind to pagination payload
   * */
  loadDrivers() {
    this.loading = true;
    this.driverService
      .getDriversByPageAndSize(
        this.bigCurrentPage - 1,
        this.itemsPerPage,
        this.keyword
      )
      .subscribe(
        drivers => {
          this.drivers = drivers.content;
          this.bigTotalItems = drivers.totalElements;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * callback : when page change !
   * */
  public pageChanged(event: any): void {
    this.bigCurrentPage = event.page;
    this.loadDrivers();
  }

  /**
   * confirm delete modal !
   * */
  confirmDeleteDriver(driver: Driver) {
    this.currentDriver = driver;
    this.confirmDeleteModal.show();
  }

  /**
   * delete specific driver !
   * */
  deleteDriver() {
    this.globalLoading = true;
    this.driverService.deleteDriverById(this.currentDriver.idDriver).subscribe(
      suprimed => {
        this.globalLoading = false;
        if (suprimed) {
          this.loadDrivers();
          this.confirmDeleteModal.hide();
          this.toastr.success("Votre suppression est bien effectuée!", "info");
        } else {
          this.toastr.error("L'operation de suppression est annulée!", "Error");
        }
      },
      () => {
        this.globalLoading = false;
        this.toastr.error("L'operation de suppression est annulée!", "Error");
      }
    );
  }

  /**
   * search specific driver !
   * */
  searchDriver() {
    this.bigCurrentPage = 1;
    this.loadDrivers();
  }
}
