import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TireComponent } from './tire.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms'; // If you are using forms in your component
import { ModalModule } from 'ngx-bootstrap/modal'; // Import if you're using modals
import { TireService } from '../../providers/tire-service';
import { DataManagementService } from '../../../data-management/data-management.service'; // Import your services
import { ImportExportFormsModule } from '../../import-export-forms/import-export-forms.module';
import { TireFormModule } from './tire-form/tire-form.module';

@NgModule({
  declarations: [
    TireComponent,
  ],
  imports: [
    CommonModule, // Common module for common directives
    FormsModule,
    ToastrModule.forRoot(), // Toastr for notifications
    FormsModule, // Import FormsModule if you are using forms
    ModalModule.forRoot(),
    ImportExportFormsModule,
    TireFormModule,
  ],
  providers: [
    TireService, // Provide your services
    DataManagementService // Add other services used in this component
  ],
  exports: [
    TireComponent // Export the component to make it usable in other modules
  ]
})
export class TireModule { }
