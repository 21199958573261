<div class="row">
  <div class="col-md-12">
    <div class="box" style="border-radius: 0px;min-height: unset;">
      <div class="box-body">
        <form class="form-horizontal" #stopForm="ngForm">
          <div class="form-group row">

            <label class="col-sm-1 control-label grp-lbl">Date</label>
            <div class="col-sm-2">
              <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [maxDate]="now" placement="bottom" required
                (ngModelChange)="onChangeStartDate()">
            </div>

            <div class="col-sm-1 form-btns">
              <button type="button" class="btn btn-success btn-flat btn-sm" [disabled]="loading || !stopForm.form.valid"
                (click)="mission()" title="Valider">
                <b><i class="fa fa-play" aria-hidden="true"></i></b>
              </button>
            </div>
            <div *ngIf="loading" class="col-sm-2 text-center" style="font-size: 12px;margin-top: 8px;">
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
            </div>
            <div class="col-sm-4 text-center" *ngIf="!loading && updateTrackingTimer"
              style="font-size: 12px;margin-top: 8px;">
              <i class="fa fa-hourglass-half" aria-hidden="true"></i>
              Mise à jour dans {{updateTrackingTimer}} seconds...
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row seven-cols">

  <!-- ==================== Pas encore commencé Section ======================= -->
  <div class="col-xl-3 col-md-3 mb-4 col-xs-6">
    <div class="card redStyle">
      <div class="title">Pas encore commencé</div>
      <span class="glyphicon glyphicon-pause"></span>
      <div class="value">
        <span *ngIf="!loading && not_startedLength" class="font-size">{{not_startedLength}}</span>
        <span *ngIf="!loading && !not_startedLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>

      <div class="stat" *ngIf="!loading && not_startedLength > 0 && !showDetails1;" (click)="showDetails1 = true"><i
          class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
      </div>

      <div class="stat" *ngIf="!loading && (not_startedLength == 0)">
        <b>Aucun </b> véhicule
      </div>

      <div class="stat overflowdv" *ngIf="showDetails1" (click)="showDetails1 = false">
        <div *ngFor="let mission of scheduleList">
          <span *ngIf="mission.type == 'not_started'">
            {{mission?.matricule}}
          </span>
        </div>

      </div>
    </div>
  </div>


  <!-- ==================== En cours Section ======================= -->
  <div class="col-xl-3 col-md-3 mb-4 col-xs-6">
    <div class="card blueStyle">
      <div class="title">En cours</div>
      <span class="glyphicon glyphicon-th"></span>
      <div class="value">
        <span *ngIf="!loading && inprogressLength" class="font-size">{{inprogressLength}}</span>
        <span *ngIf="!loading && !inprogressLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>

      <div class="stat" *ngIf="!loading && inprogressLength > 0 && !showDetails3;" (click)="showDetails3 = true"><i
          class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
      </div>

      <div class="stat" *ngIf="!loading && (inprogressLength == 0)">
        <b>Aucun </b> véhicule
      </div>

      <div class="stat overflowdv" *ngIf="showDetails3" (click)="showDetails3 = false">
        <div *ngFor="let mission of scheduleList">
          <span *ngIf="mission.type == 'inprogress'">
            {{mission?.matricule}}
          </span>
        </div>

      </div>
    </div>
  </div>

  <!-- ==================== En retard Section======================= -->
  <div class="col-xl-3 col-md-3 mb-4 col-xs-6">
    <div class="card yellowStyle">
      <div class="title">Arrivé en retard</div>
      <span class="glyphicon glyphicon-pause"></span>
      <div class="value">
        <span *ngIf="!loading && delayLength" class="font-size">{{delayLength}}</span>
        <span *ngIf="!loading && !delayLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>

      <div class="stat" *ngIf="!loading && delayLength > 0 && !showDetails2;" (click)="showDetails2 = true"><i
          class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
      </div>

      <div class="stat" *ngIf="!loading && (delayLength == 0)">
        <b>Aucun </b> véhicule
      </div>

      <div class="stat overflowdv" *ngIf="showDetails2" (click)="showDetails2 = false">
        <div *ngFor="let mission of scheduleList">
          <span *ngIf="mission.type == 'delay'">
            {{mission?.matricule}}
          </span>
        </div>

      </div>
    </div>
  </div>


  <!-- ==================== Arrivé a temps Section ======================= -->
  <div class="col-xl-3 col-md-3 mb-4 col-xs-6">
    <div class="card greenStyle">
      <div class="title">Arrivé à temps</div>
      <span class="glyphicon glyphicon-play"></span>
      <div class="value">
        <span *ngIf="!loading && deliveryLength" class="font-size">{{deliveryLength}}</span>
        <span *ngIf="!loading && !deliveryLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>


      <div class="stat" *ngIf="!loading && deliveryLength > 0 && !showDetails3;" (click)="showDetails4 = true"><i
          class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
      </div>

      <div class="stat" *ngIf="!loading && (deliveryLength == 0)">
        <b>Aucun </b> véhicule
      </div>

      <div class="stat overflowdv" *ngIf="showDetails4" (click)="showDetails4 = false">
        <div *ngFor="let mission of scheduleList">
          <span *ngIf="mission.type == 'delivery'">
            {{mission?.matricule}}
          </span>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-12 col-md-12 mb-12 col-xs-12">
    <app-schedule-chart *ngIf="scheduleList.length > 0" [isCiment]="true" [scheduleList]="scheduleList">
    </app-schedule-chart>
    <div *ngIf="scheduleList.length == 0" class="alert alert-warning alert-dismissible text-center">
      <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
        ×
      </button>
      <h4 style="color: white">
        <i class="fa fa-info-circle"></i> Information
      </h4>
      Non Mission à suivre
    </div>
  </div>
</div>