<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<section>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-car"></i> <span>Secteur |</span>
      <i class="fa fa-plus-circle add" aria-hidden="true">
        <span (click)="openGeoMarketingForm()"> Ajouter </span>
      </i>
    </div>
    <!-- CARD BODY -->
    <div class="card-body p-0">
      <div class="app">
        <div class="main">
          <div class="container p-0">

            <div class="messages">
              <form>
                <div class="inner-addon left-addon">
                  <i class="fa fa-search"></i>
                  <input type="search" class="search" placeholder="Rechercher..." #searchTerm />
                </div>
              </form>

              <!-- GEOMARKETING ITEMS -->
              <ul class="message-list">
                <li *ngFor="let geo of geoMarketing | filterBy : {label : searchTerm.value}"
                  [ngClass]="{'active': selectedGeoMarketing?.id == geo.id}" (click)="selectedGeoMarketing = geo">
                  <div class="preview">
                    <h5>{{geo?.label}} <small>{{geo?.createAt | date:'dd-MM-yy'}}</small></h5>
                    <p>Zone: {{geo?.pointOfInterests?.length}}</p>
                  </div>
                </li>
              </ul>
              <!-- END GEOMARKETING ITEMS -->
            </div>

            <!-- GEOMARKETING BODY -->
            <section *ngIf="selectedGeoMarketing" class="message">
              <h3>{{selectedGeoMarketing?.label}}</h3>

              <div class="option-add">
                <span title="Modifier" (click)="onUpdate(selectedGeoMarketing)"><i class="fa fa-edit"></i>
                  Modifier</span>
              </div>

              <div class="option-delete">
                <span title="Supprimer" (click)="onConfirmDelete()"><i class="fa fa-minus-circle"></i> Supprimer</span>
              </div>

              <div class="option-export">
                <span title="Enregistrer sous format excel" (click)='export()'>Exporter
                  <i *ngIf="loader" class="fa fa-refresh fa-spin"></i>
                  <i *ngIf="!loader" class="fa fa-download"></i></span>
              </div>

              <div class="meta-data">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>Date création</th>
                      <th>Zone</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{selectedGeoMarketing?.createAt | date:'dd-MM-yy, HH:mm'}}</td>
                      <td>{{selectedGeoMarketing?.pointOfInterests?.length}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Zones -->
              <div class="body">
                <div class="panel-group" id="accordion">
                  <div *ngFor="let zone of selectedGeoMarketing.pointOfInterests; let i = index"
                    class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#panel{{zone.idPointClient}}">Zone {{i
                          +
                          1}} :
                          <b>{{zone?.name}}</b>({{zone?.myPois?.length}})</a>
                      </h4>
                    </div>
                    <div class="panel-collapse collapse" id="panel{{zone.idPointClient}}">
                      <div class="panel-body">
                        <span *ngFor="let poi of zone?.myPois" data-toggle="tooltip" class="badge" style="margin: 25px;"
                          [ngStyle]="{'background-color': (poi?.clientType == 'CLIENT') ? 'green'
                      : (poi?.clientType == 'INTERMEDIAIRE') ? 'orange'
                      : (poi?.clientType == 'PROSPECT') ? 'dodgerblue'
                      : (poi?.clientType == 'CONCURENT') ? 'red'
                    : 'grey'}">
                          {{poi.name}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END Zones -->


            </section>
            <!-- END GEOMARKETING BODY -->
          </div>
        </div>
      </div>
    </div>
    <!-- END CARD BODY -->
  </div>
</section>

<!-- ========================================== DashBord Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #geoMarketingFormModal="bs-modal" bsModal role="dialog" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- MODAL HEADER -->
      <div class="modal-header">
         <!-- <button type="button" class="close" (click)="geoMarketingFormModal.hide()">&times;</button> -->
        <button type="button" class="close" (click)="closeGeoMarketingForm()">&times;</button>

        <h4 *ngIf="mode=='ADD'" class="modal-title"><i class="fa fa-plus-circle" aria-hidden="true"></i> Nouveau secteur
        </h4>
        <h4 *ngIf="mode=='UPDATE'" class="modal-title"><i class="fa fa-edit"></i> Modifier secteur</h4>
      </div>
      <!-- END MODAL HEADER -->

      <!-- MODAL BODY -->
      <div class="modal-body">
        <div class="box-body">
          <app-geo-marketing-form [geoMarketing]="selectedGeo" [selectedPoi]="selectedPoi" [mode]="mode"
            (geoMarketingSaved)="onGeoMarketingSaved($event)" (geoMarketingUpdated)="onGeoMarketingUpdated($event)">
          </app-geo-marketing-form>
        </div>
      </div>
      <!-- END MODAL BODY -->

      <!-- MODAL FOOTER -->
      <div class="box-footer">
        <button (click)="closeGeoMarketingForm()" class="btn btn-default pull-right">
          Fermer
        </button>
      </div>
      <!-- END MODAL FOOTER -->

    </div>
  </div>
</div>
<!-- ========================================== DashBord Modal (END) ====================================================-->


<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" tabindex="-1" #geoMarketingDeleteModal="bs-modal" bsModal role="dialog" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="geoMarketingDeleteModal.hide()">&times;</button>
        <h4 class="modal-title">
          <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Suppression !
          </h4>
          <span>Êtes-vous sûr de supprimer le secteur ?</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="geoMarketingDeleteModal.hide()">
          Annuler
        </button>
        <button type="button" class="btn btn-success" (click)="onDelete()">
          <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
        </button>
      </div>
    </div>
  </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
