<div>
    <app-stops-parents
    [kmJourStop]="kmJourStop"
    (showingItems)="showingTrajet()"
    ></app-stops-parents>
    <div class="container-bar" *ngIf="showTrajet">
        <div class="custom-bar">
          <progress-custom-bar
            [steps]="(kmJourStop && kmJourStop.stopDto) ? kmJourStop.stopDto.points:[]"
          ></progress-custom-bar>
        </div>
        <div class="trajet-container">
            <app-stops-childs
            *ngFor="let item of kmJourStop?.stopDto?.points; let i = index"
            [childDetail]="item"
            [itemIndex]="i"
            (changeTheSelectedStop)="itemClicked($event)"
          ></app-stops-childs>
        </div>
    </div>
</div>