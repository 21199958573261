import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CorrelationGasoilComponent } from './correlation-gasoil.component';

@NgModule({
  declarations: [
    CorrelationGasoilComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  exports: [
    CorrelationGasoilComponent
  ]
})
export class CorrelationGasoilModule { }
