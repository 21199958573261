import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GroupFilterPipe } from './group-filter.pipe';  // Adjust the path based on your file structure

@NgModule({
  declarations: [
    GroupFilterPipe  // Declare the pipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    GroupFilterPipe  // Export the pipe so it can be used in other modules
  ]
})
export class GroupFilterModule { }
