<form #interventionForm="ngForm" class="form-horizontal">
    <div class="box-body">

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Demande *</label>

            <div class="col-lg-4 col-md-4">

                <div class="form-group has-success">

                    <div class="col-sm-12">
                        <input [(ngModel)]="intervention.request" required name="request" type="text" maxlength="50"
                            placeholder="Demande" class="form-control">
                    </div>

                </div>

            </div>

            <label class="col-sm-2 control-label">Priorité</label>
            <div class="col-lg-4 col-md-4">

                <div class="form-group has-success">

                    <div class="col-sm-12">
                        <select [(ngModel)]="intervention.priority" name="paymentTypeId" class="form-control" required>
                            <option [value]=0>Faible</option>
                            <option [value]=1>Moyen</option>
                            <option [value]=2>Urgent</option>
                        </select>
                    </div>

                </div>

            </div>

        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Détails</label>

            <div class="col-lg-10 col-md-10">

                <div class="form-group has-success">

                    <div class="col-sm-12">
                        <textarea [(ngModel)]="intervention.content" rows="12" name="content" type="text" maxlength="1000"
                            placeholder="Détails" class="form-control"></textarea>
                        <label style="color:black">Veuillez saisir une description détaillée de votre besoin, Merci <i
                                class="fa fa-smile-o" aria-hidden="true"></i></label>
                    </div>

                </div>

            </div>

        </div>

    </div>
    <div class="box-footer">
        <button class="btn btn-default">
            Annuler
        </button>

        <button (click)="onAdd()" *ngIf="mode == 'ADD'" [disabled]="!interventionForm.form.valid" class="btn btn-info pull-right">
            Valider
        </button>

        <button (click)="onUpdate()" *ngIf="mode == 'UPDATE'" [disabled]="!interventionForm.form.valid" class="btn btn-info pull-right">
            Enregistrer
        </button>
    </div>
</form>