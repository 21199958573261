import { Component, Input, OnInit } from '@angular/core';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
import { VehiculeClassification } from '../../activity-vehicule-classifications.model';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);

@Component({
  selector: 'app-classification-offense-chart',
  templateUrl: './classification-offense-chart.component.html',
  styleUrls: ['./classification-offense-chart.component.css']
})
export class ClassificationOffenseChartComponent implements OnInit {

  classmentObject: Object | null = null;
  offenseClassments: VehiculeClassification[] = [];

  @Input()
  set offenses(offenses: VehiculeClassification[]) {
    if (offenses) {
      this.offenseClassments = Object.assign(offenses);
      this.offenseChart();
    }
  }

  constructor(private chartConfig : ChartConfigService) { }

  ngOnInit() {
    this.offenseChart();
  }

  offenseChart() {
    this.chartConfig.setHighCartsLang();
    var seriesData = [];

    for (let classment of this.offenseClassments.filter(c => c.total > 0)) {
      seriesData.push([classment.matricule, classment.total])
    }

    this.classmentObject = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Infraction',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: "#000000",
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: 'DHs'
        },
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      accessibility: {
        point: {
          valueSuffix: 'DHs'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '<b>{point.y:.2f} DH</b>'
      },
      plotOptions: {
        column: {
          borderRadius: 5
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:.2f} '
          }
        }
      },
      series: [{
        colorByPoint: true,
        data: seriesData
      }]
    }
    Highcharts.chart('classmentOffenseObject', this.classmentObject);
  }



}
