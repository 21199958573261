<div class="row padd">
    <!-- ==================== Objectif Section ======================= -->
    <div class="col-xl-3 col-md-3 mb-2 col-xs-4">
        <div class="card blueStyle" title="Objectif">
            <div class="title">Objectif</div>
            <div class="value">
                <span
                    *ngIf="selectedCharge && budget">{{budget | number: '.0-2' }} DH</span>
                <span *ngIf="!selectedCharge || !budget"> - </span>
            </div>
        </div>
    </div>

    <!-- ==================== Réalisée Section ======================= -->
    <div class="col-xl-3 col-md-3 mb-2 col-xs-4">
        <div class="card yellowStyle" title="Réalisée">
            <div class="title">Réalisée</div>
            <div class="value">
                <span *ngIf="caRealise_.length > 0 && budget">{{caRealise_[caRealise_.length - 1]?.caRealise | number: '.0-2'}} DH</span>
                <span *ngIf="(caRealise_ && budget && caRealise_.length == 0)">0 DH </span>
                <span *ngIf="!caRealise_ || !budget"> - </span>
            </div>

        </div>
    </div>

        <!-- ==================== Écart Section ======================= -->
        <div class="col-xl-3 col-md-3 mb-2 col-xs-4">
            <div class="card redStyle"  [ngClass]="( (caRealise_.length > 0) && (budget - caRealise_[caRealise_.length - 1]?.caRealise) < 0) ? 'greenStyle' : 'redStyle'" title="Écart">
                <div class="title">Écart</div>
                <div class="value">
                    <span *ngIf="caRealise_.length > 0 && budget">{{caRealise_[caRealise_.length - 1]?.caRealise - budget | number: '.0-2'}} DH</span>
                    <span *ngIf="budget && caRealise_.length == 0">-{{budget | number: '.0-2' }} DH </span>
                    <span *ngIf="!caRealise_ || !budget"> - </span>
                </div>
    
            </div>
        </div>
    <!-- ==================== Date Section ======================= -->
    <div class="col-xl-3 col-md-3 mb-2 col-xs-4">
        <div class="card greyStyle" title="Date mise à jour">
            <div class="title">Date M.J</div>
            <div class="value" style="font-size: 12px;padding: 0;">
                <span *ngIf="caRealise_.length > 0 && budget">{{caRealise_[caRealise_.length - 1]?.operationTime |
                    date:'dd-MM-yy, HH:mm':'GMT'}} </span>
                <span *ngIf="(!caRealise_ || !budget) || (caRealise_.length == 0)"> - </span>
            </div>
        </div>
    </div>
</div>
<div id="turnoverAchieved" class="w-100 d-inline-block"></div>