import { Component, Input, OnInit } from '@angular/core';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
import { VehiculeClassification } from '../../activity-vehicule-classifications.model';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);

@Component({
  selector: 'app-classification-driver-turn-over-chart',
  templateUrl: './classification-driver-turn-over-chart.component.html',
  styleUrls: ['./classification-driver-turn-over-chart.component.css']
})
export class ClassificationDriverTurnOverChartComponent implements OnInit {

  classmentObject: Object | null = null;
  driverTurnOverClassments: VehiculeClassification[] = [];

  @Input()
  set driversTurnOver(driversTurnOver: VehiculeClassification[]) {
    if (driversTurnOver) {
      this.driverTurnOverClassments = Object.assign(driversTurnOver);
      this.TurnOverChart();
    }
  }

  constructor(private chartConfig : ChartConfigService) { }

  ngOnInit() {
    this.TurnOverChart();
  }

  TurnOverChart() {
    this.chartConfig.setHighCartsLang();
    var seriesData = [];

    for (let classment of this.driverTurnOverClassments) {
      seriesData.push([classment.matricule, classment.total])
    }

    this.classmentObject = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Chiffre d\'affaire',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: "#000000",
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: 'DHs'
        },
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      accessibility: {
        point: {
          valueSuffix: 'DHs'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '<b>{point.y:.2f} DH</b>'
      },
      plotOptions: {
        column: {
          borderRadius: 5
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:.2f} '
          }
        }
      },
      series: [{
        colorByPoint: true,
        data: seriesData
      }]
    }
    Highcharts.chart('classmentTurnOverObject', this.classmentObject);
  }

}
