import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // If you're using forms or ngModel
import { GlobalOverviewManagementComponent } from './global-overview-management.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    GlobalOverviewManagementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    GlobalOverviewManagementComponent // Export if this component needs to be used in other modules
  ]
})
export class GlobalOverviewManagementModule { }
