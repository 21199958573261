export class Mileage {
  deviceId: number;
  startDate: Date;
  totalDrivingDistance: number;
  totalDrivingDuration: number;
  drivingDuration: number;
 
  startHour: Date;
  endHour: Date;
  drivingDistance: number;
  drivingDurationStr: string;

  constructor() {
    this.drivingDistance = 0;
    this.drivingDurationStr = "";
  }
}