import { vehciuleIcons } from "./../../vehicule-icon.model";
import { Component, Inject, OnInit } from "@angular/core";
import { GroupService } from "../../providers/group.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import "rxjs/add/operator/switchMap";
import { Group, Vehicule } from "../../data-management.model";
import { imagesCarsDir } from "../../../../global.config";
import { DataManagementComponent } from "../../data-management.component";
import { VehiculeService } from "../../providers/vehicule.service";
import { forkJoin } from 'rxjs';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-group-form",
  templateUrl: "./group-form.component.html",
  styleUrls: ["./group-form.component.css"]
})
export class GroupFormComponent implements OnInit {
  mode: "ADD" | "ASSIGN" | "UPDATE" | "SHOW";
  loading: boolean = false;
  currentGroupe: Group = new Group();
  url: string = imagesCarsDir;
  imagesCarsDir = imagesCarsDir;

  images = vehciuleIcons;

  selectedImage: { text: string; uri: string; speed: number } = this.images[0];

  vehiculesItems: { text: string | number; id: number }[] = [];
  vehiculesSelected: { text: string | number; id: number }[] = [];
  selectedVehiculesHash = "";
  error: { errorMessage: string } = { errorMessage: '' };
  parents: Group[] = [];
  vehiculesDropdownSettings = {};
  dropdownSettings : any;

  constructor(
    public toastr: ToastrService,
    private vehiculeService: VehiculeService,
    private route: ActivatedRoute,
    private router: Router,
    private groupeService: GroupService,
    @Inject(DataManagementComponent) private parent: DataManagementComponent
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
      this.init(params.id);
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 22,
      allowSearchFilter: true
    };
  }

  init(groupId: any) {
    if (this.mode == "SHOW") {
      this.loadGroupById(groupId);
    }

    if (this.mode == "ADD") {
      this.currentGroupe.parent = new Group();
      // load all groups
      // load vehicule
      this.loading = true;
      forkJoin([
        this.groupeService.getGroups(),
        this.vehiculeService.getAll()]
      ).subscribe(
        ([groups, vehicules]) => {
          this.mapVehiculeToItems(vehicules);
          this.parents = groups.filter(g=> !g.nameGroupParent);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }

    if (this.mode == "UPDATE") {
      this.loading = true;
      forkJoin(
       [ this.vehiculeService.getAll(),
        this.groupeService.getOne(groupId),
        this.groupeService.getGroups()]
      ).subscribe(
        ([vehicules, group, groups]) => {
          this.currentGroupe = group;
          if(!this.currentGroupe.parent)
          this.currentGroupe.parent = new Group();
          if(groups.length>0)
          this.parents = groups.filter(g=>g.idGroupe != this.currentGroupe.idGroupe && !g.nameGroupParent);
          this.selectedImage = this.getImageByUri(group.imageUrl);
          this.mapVehiculeToItems(vehicules);
          this.mapSelectedVehiculeToItems(group.vehicules);
          this.initSelectedHash(group.vehicules);
          this.loading = false;
        },
        error => {
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }

          this.loading = false;
        }
      );
    }
  }

  loadGroupById(id: any) {
    this.loading = true;
    this.groupeService.getOne(id).subscribe(
      groupe => {
        this.currentGroupe = groupe;
        if(!this.currentGroupe.parent)
          this.currentGroupe.parent = new Group();
        this.loading = false;
        this.selectedImage = this.getImageByUri(groupe.imageUrl);

      },
      error => {
        this.error = JSON.parse(JSON.stringify(error.error || null));

        if (!this.error.errorMessage) {
          this.error.errorMessage = "SERVER ERROR";
        }

        this.loading = false;
      }
    );
  }

  getImageByUri(uri: any) {
    for (let i = 0; i < this.images.length; i++) {
      if (this.images[i].uri === uri) {
        return this.images[i];
      }
    }
    return this.images[0];
  }

  goToVehiculeUpdate(vehicule: any) {
    this.parent.displayVehiculeIcon();
    this.router.navigate([
      "/client/dataManagement/vehicule/form/" + vehicule.idVehicule + "/UPDATE"
    ]);
  }

  goToVehiculeDetail(vehicule: any) {
    this.parent.displayVehiculeIcon();
    this.router.navigate([
      "/client/dataManagement/vehicule/form/" + vehicule.idVehicule + "/SHOW"
    ]);
  }

  cancel() {
    this.router.navigate(["/client/dataManagement/groupe"]);
  }

  initSelectedHash(vehicules: Vehicule[]) {
    let prefix = "";
    this.selectedVehiculesHash = "";
    vehicules.forEach(vehicule => {
      this.selectedVehiculesHash += prefix + vehicule.idVehicule;
      prefix = ",";
    });
  }

  selectedVehicules(vehicules: { text: string | number; id: number }[]) {
    let prefix = "";
    this.selectedVehiculesHash = "";
    vehicules.forEach(vehicule => {
      this.selectedVehiculesHash += prefix + vehicule.id;
      prefix = ",";
    });
  }

  mapVehiculeToItems(vehicules: Vehicule[]) {
    this.vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesItems.push({
        id: vehicules[i].idVehicule,
        text: vehicules[i].matricule
      });
    }
  }

  mapSelectedVehiculeToItems(vehicules: Vehicule[]) {
    this.vehiculesSelected = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesSelected.push({
        id: vehicules[i].idVehicule,
        text: vehicules[i].matricule
      });
    }
  }

  addGroup() {

    this.loading = true;
    this.currentGroupe.imageUrl = this.selectedImage.uri;
    this.selectedVehicules(this.vehiculesSelected);
    this.groupeService
      .addGroup(this.currentGroupe, this.selectedVehiculesHash)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        vehicule => {
          this.loading = false;
          this.toastr.success("Groupe bien ajouter !");
          this.router.navigate(["/client/dataManagement/groupe"]);
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

  updateGroup() {
    this.loading = true;
    this.currentGroupe.imageUrl = this.selectedImage.uri;
    this.selectedVehicules(this.vehiculesSelected);
    this.groupeService
      .updateGroup(this.currentGroupe, this.selectedVehiculesHash)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        vehicule => {
          this.loading = false;
          this.router.navigate(["/client/dataManagement/groupe"]);
          this.toastr.success("Groupe bien modifié !");
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }
}
