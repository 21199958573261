import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityDrivingComponent } from './quality-driving.component'; // Import your component
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Datepicker module from ngx-bootstrap
import { FormsModule } from '@angular/forms'; // FormsModule for handling template-driven forms
import { TechnicalDataService } from '../../statistical/technical-data/technical-data.service'; // Service for technical data
import { DataManagementService } from '../../data-management/data-management.service'; // Service for data management
import { SigninService } from 'src/app/authentification/signin/signin.service'; // Authentication service
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';

@NgModule({
  declarations: [
    QualityDrivingComponent // Register your QualityDrivingComponent
  ],
  imports: [
    CommonModule, // For using Angular's common directives (ngIf, ngFor, etc.)
    ToastrModule.forRoot(), // Toastr for notifications
    BsDatepickerModule.forRoot(), // Bootstrap datepicker for date inputs
    FormsModule,
    SharedPipesModule,
    OrderPipModule,
    TruncateModule
  ],
  providers: [
    TechnicalDataService, // Provide the services used in your component
    DataManagementService,
    SigninService
  ],
  exports: [
    QualityDrivingComponent // Export the component so it can be used in other modules
  ]
})
export class QualityDrivingModule { }
