import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // For Angular animations
import { ModalModule } from 'ngx-bootstrap/modal'; // For ngx-bootstrap modals
import { CimentOverviewComponent } from './ciment-overview.component'; // Import your component
import { MileageService } from '../../historical'; // Import your service
import { RealtimeRestService } from '../../realtime/services/realtime-rest.service'; // Import the Realtime service
import { NgxLoadingModule } from 'ngx-loading';
import { BreakDownFormComponent } from '../../ciment-management';
import { BreakDownFormModule } from '../../ciment-management/break-down-form/break-down-form.module';

@NgModule({
  declarations: [
    CimentOverviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule, // Needed for animations
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    BreakDownFormModule,
  ],
  providers: [
    MileageService,
    RealtimeRestService // Provide your services
  ],
  exports: [
    CimentOverviewComponent // Export if needed in other modules
  ]
})
export class CimentOverviewModule { }
