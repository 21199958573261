import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SinistreChartComponent } from './sinistre-chart.component';
import { ToastrModule } from 'ngx-toastr'; // Assuming Toastr is used
import { HighchartsChartModule } from 'highcharts-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [SinistreChartComponent], // Declare the component
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Import Toastr module
    HighchartsChartModule,
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    FormsModule,

  ],
  exports: [SinistreChartComponent] // Export the component for use in other modules
})
export class SinistreChartModule {}
