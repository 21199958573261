import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverFormComponent } from './driver-form.component'; // Adjust the path if necessary
import { ToastrModule } from 'ngx-toastr'; // For notifications
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // If you're using reactive forms
import { RouterModule } from '@angular/router'; // If you need routing
import { DriverService } from '../../providers/driver.service'; // Ensure the path is correct
import { NgxLoadingModule } from 'ngx-loading';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EmailManagerModule } from 'src/app/client-management/alert-management/alert-settings/email-manager/email-manager.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AvatarModule } from 'ngx-avatar';
import { NoValuePipeModule } from 'src/app/pipes/no-value.module';
import { DriverChargeListModule } from 'src/app/client-management/parc-management/driver-charge/driver-charge-list/driver-charge-list.module';

@NgModule({
  declarations: [
    DriverFormComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(), // Import Toastr module for notifications
    ReactiveFormsModule, // Import ReactiveFormsModule if you're using reactive forms
    RouterModule, // Import RouterModule if needed
    NgxLoadingModule.forRoot({}),
    MatCheckboxModule,
    EmailManagerModule,
    BsDatepickerModule.forRoot(),
    AvatarModule,
    NoValuePipeModule,
    DriverChargeListModule

  ],
  providers: [
    DriverService // Provide the driver service
  ],
  exports: [
    DriverFormComponent // Export if you want to use this component outside of this module
  ]
})
export class DriverFormModule { }
