import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import {
  CanalDto,
  CanalInfoDto,
  SupervisorVehicleDTO,
  Vehicule,
} from './canals';

@Injectable()
export class CanalService {
  canals: CanalInfoDto[] = [];

  vehicules: Vehicule[] = [];

  constructor(private _http: HttpClient) {}

  // list !
  findAllCanals(): Observable<CanalInfoDto[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<CanalInfoDto[]>(dns + 'canals', { headers: headers });
  }

  addCanal(canal: CanalDto): Observable<CanalInfoDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<CanalInfoDto>(dns + 'canals', canal, options);
  }

  updateCanal(canal: CanalDto): Observable<CanalInfoDto> {
    let headers = createAuthorizationHeader();
    return this._http.put<CanalInfoDto>(dns + 'canals', canal, {
      headers: headers,
    });
  }

  deleteCanal(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'canals/' + id, { headers: headers });
  }

  // valid !
  getOneCanal(id: number): Observable<CanalInfoDto> {
    let headers = createAuthorizationHeader();
    return this._http.get<CanalInfoDto>(dns + 'canals/' + id, {
      headers: headers,
    });
  }

  getMonthlyChargesByCanal(
    canalId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(
      dns + 'canals/' + canalId + '/monthly/charges',
      dateInterval,
      { headers: headers }
    );
  }

  getMonthlyAverageMaintenanceCostByCanalId(
    canalId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(
      dns + 'canals/' + canalId + '/monthly/average/maintenance',
      dateInterval,
      { headers: headers }
    );
  }

  getMonthlyMaintenanceCostByCanalId(
    canalId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(
      dns + 'canals/' + canalId + '/monthly/cost/maintenance',
      dateInterval,
      { headers: headers }
    );
  }

  getMonthlySinisterCountsByCanalId(
    canalId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(
      dns + 'canals/' + canalId + '/monthly/count/sinistre',
      dateInterval,
      { headers: headers }
    );
  }
  getMonthlySinisterCostByCanalId(
    canalId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(
      dns + 'canals/' + canalId + '/monthly/cost/sinistre',
      dateInterval,
      { headers: headers }
    );
  }

  findAllByYear(year: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get<any>(dns + 'canals/list-by-year?year=' + year, {
      headers: headers,
    });
  }

  findAllByCanalGroupByYear(year: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get<any>(
      dns + 'canals/list-by-year-canal-groupe?year=' + year,
      { headers: headers }
    );
  }

  findAllByGroupeVehiculeByYear(year: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get<any>(
      dns + 'canals/list-by-year-groupe-vehicule?year=' + year,
      { headers: headers }
    );
  }

  // Get Superviseur
  getAllSuperviseur(): Observable<SupervisorVehicleDTO[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<SupervisorVehicleDTO[]>(
      dns + 'supervisor/getSupervisor',
      { headers: headers }
    );
  }

  updateSuperviseur(
    supervisorVehicleDTO: SupervisorVehicleDTO
  ): Observable<void> {
    let headers = createAuthorizationHeader();
    return this._http.put<void>(dns + 'supervisor', supervisorVehicleDTO, {
      headers: headers,
    });
  }

  resetService() {
    this.canals = [];
    this.vehicules = [];
  }
}
