<header class="main-header" style="font-family: 'Inconsolata', monospace;">
  <!-- Logo -->
  <a class="logo">
    <!-- mini logo for left-sidebar mini 50x50 pixels -->
    <span class="logo-mini"><b>{{ mini_owner }}</b></span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg"><b>{{ owner }}</b></span>
  </a>
  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top">
    <!-- Sidebar toggle button-->
    <a class="sidebar-toggle" data-toggle="offcanvas" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>

    <div class="navbar-custom-menu custom-menu">
      <ul class="nav navbar-nav">
        <li class="dropdown messages-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
            <i class="fa fa-bell-o"></i>
            <span class="label label-success">{{ notifications.length }}</span>
          </a>
          <ul class="dropdown-menu" style="width: 500px">
            <li *ngIf="notifications.length > 0" class="header" style="font-family: 'Inconsolata', monospace">
              Vous avez plus que {{ notifications.length }} notifications
            </li>
            <li *ngIf="notifications.length == 0" class="header" style="font-family: 'Inconsolata', monospace">
              Aucune notification
            </li>
            <li>
              <!-- inner menu: contains the actual data -->
              <ul class="menu">
                <li *ngFor="let notification of notifications">
                  <!-- start message -->
                  <a (click)="goToLink(notification)">
                    <div class="pull-left">
                      <img *ngIf="
                          notification.name == 'SPEED' ||
                          notification.name == 'SPEED_CARTO'
                        "
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Speed_ballonicon2.svg/1024px-Speed_ballonicon2.svg.png"
                        class="img-circle" alt="User Image" />
                      <img *ngIf="
                          notification.name != 'SPEED' &&
                          notification.name != 'SPEED_CARTO'
                        " src="https://cdn4.iconfinder.com/data/icons/flat-shaded-2/512/Notification-512.png"
                        class="img-circle" alt="User Image" />
                    </div>

                    <h4 style="font-family: 'Inconsolata', monospace">
                      {{
                      alertManagementService.getTitleFromNotification(
                      notification
                      )
                      }}
                      <small><i class="fa fa-clock-o"></i>
                        {{ notification.createdAt | amTimeAgo : true }}
                      </small>
                    </h4>
                    <p style="font-family: 'Inconsolata', monospace">
                      <!-- matricule -->
                      <span *ngIf="
                          notification.matricule &&
                          notification.matricule != 'null'
                        ">Matricule :
                        <span style="font-weight: bold">{{
                          notification.matricule
                          }}</span></span>

                      <!-- alias -->
                      <span *ngIf="
                          notification.alias && notification.alias != 'null'
                        ">, Surnom :
                        <span style="font-weight: bold">{{
                          notification.alias
                          }}</span></span>

                      <!-- mark -->
                      <span *ngIf="notification.mark && notification.mark != 'null'">, Marque :
                        <span style="font-weight: bold">{{
                          notification.mark
                          }}</span></span>

                      <!-- subMark -->
                      <span *ngIf="
                          notification.subMark && notification.subMark != 'null'
                        ">, Sous marque :
                        <span style="font-weight: bold">{{
                          notification.subMark
                          }}</span></span>
                    </p>
                  </a>
                </li>
                <!-- end message -->
              </ul>
            </li>
            <li class="footer" style="font-family: 'Inconsolata', monospace">
              <a routerLink="/client/alertManagement/alertNotifications">Plus de details</a>
            </li>
          </ul>
        </li>

        <!-- FuELING NOTIFICATION  -->
        <li *ngIf="
            signinService.isHasNotificationCarburant() ||
            signinService.isHasRole('ROLE_NOTIFICATION_CARBURANT')
          " class="dropdown messages-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
            <i class="fa fa-bell-o"></i>
            <span class="label label-success">{{
              fuelingNotificationDto.length
              }}</span>
          </a>
          <ul class="dropdown-menu" style="width: 500px">
            <li *ngIf="fuelingNotificationDto.length > 0" class="header" style="font-family: 'Inconsolata', monospace">
              Vous avez plus que
              {{ fuelingNotificationDto.length }} notifications
            </li>
            <li *ngIf="fuelingNotificationDto.length == 0" class="header" style="font-family: 'Inconsolata', monospace">
              Aucune notification
            </li>
            <li>
              <!-- inner menu: contains the actual data -->
              <ul class="menu">
                <li *ngFor="let fuelingNotification of fuelingNotificationDto">
                  <!-- start message -->

                  <a style="cursor: default">
                    <div class="pull-left">
                      <img src="https://cdn4.iconfinder.com/data/icons/flat-shaded-2/512/Notification-512.png"
                        class="img-circle" alt="User Image" />
                    </div>

                    <h4 *ngIf="
                        fuelingNotification.difference &&
                        fuelingNotification.difference != 'null'
                      " style="font-family: 'Inconsolata', monospace">
                      Consommation Carburant ({{
                      fuelingNotification.consumption | number : ".0-2"
                      }}) diff (<span style="color: red">{{
                        fuelingNotification.difference | number : ".0-2"
                        }}</span>)
                      <small><i class="fa fa-clock-o"></i>
                        {{
                        fuelingNotification.operationTime | amTimeAgo : true
                        }}
                      </small>
                    </h4>
                    <p style="font-family: 'Inconsolata', monospace">
                      <!-- matricule -->
                      <span *ngIf="
                          fuelingNotification.matricule &&
                          fuelingNotification.matricule != 'null'
                        ">Matricule :
                        <span style="font-weight: bold">{{
                          fuelingNotification.matricule
                          }}</span></span>
                      <!-- mark -->
                      <span *ngIf="
                          fuelingNotification.mark &&
                          fuelingNotification.mark != 'null'
                        ">, Marque :
                        <span style="font-weight: bold">{{
                          fuelingNotification.mark
                          }}</span></span>
                    </p>
                  </a>
                </li>
                <!-- end message -->
              </ul>
            </li>
            <li class="footer" style="font-family: 'Inconsolata', monospace">
              <a routerLink="/client/alertManagement/alertNotifications">Plus de details</a>
            </li>
          </ul>
        </li>

        <!-- User Account: style can be found in dropdown.less -->
        <li class="dropdown user user-menu">
          <a class="dropdown-toggle" data-toggle="dropdown">
            <img class="user-image" src="{{ avatar }}" />
            <!--class="user-image"-->

            <span class="hidden-xs">{{
              currentUser.username
              }}</span>
          </a>
          <ul class="dropdown-menu">
            <!-- User image -->
            <li class="user-header" style="height: 150px !important; background: #fff">
              <img src="{{ avatar }}" alt="User Image" />

              <p>
                {{ currentUser.username }}
              </p>
            </li>

            <!-- Menu Footer-->
            <li class="user-footer">
              <!--<div class="pull-left">
                                <a class="btn btn-default btn-flat">Profile</a>
                            </div>-->
              <div class="pull-right">
                <a class="btn btn-default btn-flat" (click)="logout()">Déconnexion</a>
              </div>
            </li>
          </ul>
        </li>
        <!-- Control Sidebar Toggle Button -->
        <li class="hov">
          <a data-toggle="control-sidebar"><i class="fa fa-gears"></i></a>
        </li>
      </ul>
    </div>

    <div class="navbar-custom-menu pull-left nav-phones">
      <ul class="nav navbar-nav">
        <li>
          <a><i class="fa fa-phone"></i> Support : 0808538705 / 0522583358</a>
        </li>
        <li (click)="openSupportModal()">
          <a title="support@rimtelecom.ma"><i class="fa fa-envelope"></i></a>
        </li>
      </ul>
    </div>
  </nav>
</header>

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
  <ul class="nav nav-tabs nav-justified control-sidebar-tabs"></ul>
  <div class="tab-content">
    <div class="tab-pane" id="control-sidebar-home-tab"></div>
    <div class="tab-pane" id="control-sidebar-settings-tab"></div>
    <div class="tab-pane" id="control-sidebar-theme-demo-options-tab"></div>

  </div>
</aside>
<!-- End Control Sidebar -->

<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header black-bg">
        <h4 class="modal-title pull-left" style="font-family: 'Inconsolata', monospace; font-size: 17px">
          <i class="fa fa-envelope"></i> Support (support@rimtelecom.ma)
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="childModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal" role="form">
          <div class="form-group">
            <label class="col-sm-2 control-label" style="font-family: 'Inconsolata', monospace">
              Object
            </label>
            <div class="col-sm-10">
              <input type="text" class="form-control" placeholder="Titre" [(ngModel)]="email.subject" name="Object"
                required style="font-family: 'Inconsolata', monospace" />
            </div>

          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label" style="font-family: 'Inconsolata', monospace">
              Réclamation contenu
            </label>

            <div class="col-sm-10">

              <textarea type="text" style="font-family: 'Inconsolata', monospace" class="form-control"
                placeholder="Reclamation" [(ngModel)]="email.body" name="subject" required="true"></textarea>
            </div>
          </div>

          <div class="form-group" style="font-family: 'Inconsolata', monospace">
            <button type="button" class="btn btn-success margin btn-flat pull-right" (click)="sendEmail()">
              Valider
            </button>
            <button type="button" class="btn btn-flat margin pull-right" (click)="childModal.hide(); email = {}">
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
