import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from './validation.service';
import { Validation } from './validations';

@Component({
  selector: 'app-validation-form',
  templateUrl: './validation-form.component.html',
  styleUrls: ['./validation-form.component.css']
})
export class ValidationFormComponent implements OnInit {
  loading: boolean = false;
  error: { errorMessage: string } = { errorMessage: '' };
  validation : Validation = new Validation();

  @ViewChild('validationForm', { static: false }) validationForm: NgForm;


  constructor(public toastr: ToastrService, private validationService : ValidationService) { }

  ngOnInit() {
  }

  addValidation() {

    this.loading = true;
    let validation: Validation = new Validation();
    Object.assign(validation, this.validation);
    this.validationService
      .add(validation)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        validation => {
          this.validation = new Validation();
          this.loading = false;
          if(this.validation)
          this.toastr.success("Validation bien ajouter !");
          else
          this.error.errorMessage = "Veuillez contacter l'administrateur";
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

  cancel(){
    this.validationForm.reset();
  }

  onEmailChange(emailPayload: any) {
    this.validation.emails = emailPayload.emails;
  }

}
