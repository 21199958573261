import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './maintenance.component'; // Adjust the path if necessary
import { RouterModule } from '@angular/router'; // For routing functionalities
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { ParcManagementModule } from '../parc-management.module'; // Import the parent module if necessary

@NgModule({
  declarations: [
    MaintenanceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule
  ],
  exports: [
    MaintenanceComponent
  ]
})
export class MaintenanceModule { }
