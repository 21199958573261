import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { Mission, RouteLegs, TrackingMission, TrackingMissionData } from 'src/app/client-management/data-management';
import { imagesCarsDir } from 'src/app/global.config';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { CimentMissionService } from '../ciment-mission.service';

@Component({
  selector: 'app-ciment-mission-tracking',
  templateUrl: './ciment-mission-tracking.component.html',
  styleUrls: ['./ciment-mission-tracking.component.css']
})
export class CimentMissionTrackingComponent implements OnInit {


    /** IMAGE CAR */
    imagesCarsDir = imagesCarsDir;

    /** GROUP SUBSCRIPTION */
    groupsSubscription: Subscription | null = null;


    missions: Mission[] = [];


    /** UPDATE TRACKING TIMER */
    updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;
  /** LOADING */
    loadingTracking: boolean = false;


    loading: boolean = false;

    missionIds : Number[] = [];

    trackingMissionData: TrackingMissionData[] = [];
    trackingMissionDataSorted: TrackingMissionData[] = [];

    trackingIndex: number[] = [];

    trackingSize = 0;

    constructor(private toastr: ToastrService,
        public missionService: CimentMissionService,
        private geocodingService: GeocodingService) { }

    ngOnInit() {
        this.loadMissions();
    }

    ngOnDestroy() {
        if (this.groupsSubscription) this.groupsSubscription.unsubscribe();
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    }

    /** START TIME OUT TRACKING FOR RELOAD */
    startUpdateTrackingTimer(index: any) {
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
        this.updateTrackingTimer = 300;
        this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
            this.updateTrackingTimer--;
            if (this.updateTrackingTimer == 0) {
                this.updateTrackingTimerSubscription.unsubscribe();
                if (
                    this.trackingMissionData[index] && this.trackingMissionData[index].trackingMission != null
                )
                    this.onTracking(index, true);

            }
        })
    }

    /** LOAD MISSIONS */
    loadMissions() {

        this.loading = true;
        if(this.missionIds.length == 0)
        this.missionIds.push(-1);
        this.missionService.getMissionsNotInIds(this.missionIds)
            .subscribe(missions => {

               for(let i = 0; i < missions.length ; i++){
                this.trackingMissionData.unshift(missions[i]);
                   this.trackingIndex.push( this.trackingMissionData.length - 1);
                   this.missionIds.unshift(missions[i].selectedVehicule.idVehicule);
               }
               this.trackingMissionDataSorted = this.trackingMissionData;
               if(missions.length > 0)
                this.onTracking(0, false);
                this.loading = false;
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de chargement", "Mission")
            })
    }

    /** ON TRACKING */
    onTracking(index: any, isSorting: any) {

        if (this.trackingIndex.indexOf(index) === -1)
            this.trackingIndex.push(index);

        this.loadingTracking = true;
        this.trackingSize = 0;
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
            for (let trIndex of this.trackingIndex) {
                this.tracking(trIndex, isSorting);
            }

    }

    tracking(trIndex: any, isSorting: any) {

        this.missionService.tracking(this.trackingMissionData[trIndex].selectedMission!.id, this.trackingMissionData[trIndex].selectedVehicule!.idVehicule)
        .finally(() => {
            this.loadingTracking = false;
            this.trackingSize++;

            if(isSorting && this.trackingSize >= this.trackingIndex.length){
                this.trackingMissionDataSorted = this.trackingMissionData;

                this.trackingMissionDataSorted.sort(
                    (a: any, b: any) =>
                      new Date(b.selectedMission.createAt).getTime() - new Date(a.selectedMission.createAt).getTime()
                  );
            }

          }).subscribe(response => {
                if(response.last){
                response.progressPercent = 100;
                response.accordingDistance = response.mission.travelDistance;
                }

                this.trackingMissionData[trIndex].trackingMission = response;
                this.trackingMissionData[trIndex].selectedMission = response.mission;


                this.inverseGeocoding(trIndex);
                this.loadingTracking = false;
                this.startUpdateTrackingTimer(trIndex);
                this.countDistance(this.trackingMissionData[trIndex].trackingMission, trIndex);


            }, err => {
                this.loadingTracking = false;
                var matricule = "";
                if(this.trackingMissionData[trIndex].selectedVehicule)
                matricule = this.trackingMissionData[trIndex].selectedVehicule!.matricule;
                this.toastr.error("Erreur lors de chargement", "Mission " + matricule);
            })

    }

    countDistance(tracking: TrackingMission, index: any) {
        this.trackingMissionData[index].trackingAccordingDistance = 0;
        this.trackingMissionData[index].trackingAccordingDuration = 0;
        for (var i = 0; i < tracking.mission.routeLegs.length; i++) {

            if (tracking.mission.routeLegs[i].selected === false) {
                this.trackingMissionData[index].trackingAccordingDistance += tracking.mission.routeLegs[i].travelDistance;
                this.trackingMissionData[index].trackingAccordingDuration += tracking.mission.routeLegs[i].travelDuration;
            } else {
                this.trackingMissionData[index].trackingAccordingDistance = tracking.mission.routeLegs[i].travelDistance;
                this.trackingMissionData[index].trackingAccordingDuration = tracking.mission.routeLegs[i].travelDuration;
            }
        }
    }

    /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
    selectedRouteLeg(index: any): RouteLegs | null {
        if (this.trackingMissionData[index].trackingMission) {
            return this.trackingMissionData[index].trackingMission.mission.routeLegs.find(
                route => route.selected == true
            );
        } else {
            return null;
        }
    }

    /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
    inverseGeocoding(index: any) {
        if(!this.trackingMissionData[index].trackingMission)
        return;
        this.geocodingService.inverseGeoconding(
            this.trackingMissionData[index].trackingMission.realTimeRecord.coordinate.lat,
            this.trackingMissionData[index].trackingMission.realTimeRecord.coordinate.lng,
            18)
            .subscribe(response => {
                this.trackingMissionData[index].trackingMission.realTimeRecord.geocoding = response.display_name;
            }, err => {
                //  this.toastr.warning("Inverse geocoding");
            });

    }
}
