import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { interval, Subscription } from 'rxjs';
import { RouteLegs, TrackingMission, TrackingMissionData } from '../../data-management';
import { imagesCarsDir } from "../../../global.config";
import { Passage, PassageService } from "../parametrage/passage";
import { TransportTypeInfoDto, TransportTypeService } from "../parametrage/transport-type";
import { ScheduleService } from "../schedule";

@Component({
  selector: 'app-mission-tracking-info',
  templateUrl: './mission-tracking-info.component.html',
  styleUrls: ['./mission-tracking-info.component.scss']
})
export class MissionTrackingInfoComponent implements OnInit {

  /** IMAGE CAR */
  imagesCarsDir = imagesCarsDir;
  imgsrc = "";

  trackingMissionData: TrackingMissionData[] = [];
  data: any = {};
  arret_poi: any = {};
  trackingIndex: number[] = [];
  missionIds: Number[] = [];
  trackingSize = 0;

  late_cars: any[] = [];

  //POle And Site
  selectedPassage: Passage = new Passage();
  selectedTransport: TransportTypeInfoDto = new TransportTypeInfoDto();
  vehiculesId: number[] = [];
  stateMap: Map<string, number> = new Map<string, number>(
    [
      ["INPROGRESS", 1],
      ["PENDING", 5],
      ["DELIVERY", 4],
      ["DELAY", 3],
      ["PROBABLE_ARRIVAL", 2]
    ]
  );
  /** UPDATE TRACKING TIMER */
  private updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;

  /** LOADING */
  loadingTracking: boolean = false;


  loading: boolean = false;

  constructor(private toastr: ToastrService,
    private geocodingService: GeocodingService,
    private scheduleService: ScheduleService,
    public transportTypeService: TransportTypeService,
    public passageService: PassageService) { }

  ngOnInit() {
    this.loadTransportTypes();
  }

  /** LOAD MISSIONS */
  loadMissions() {
    this.loading = true;
    this.missionIds = [];
    this.trackingIndex = [];
    this.trackingMissionData = [];
    this.late_cars = [];
    this.scheduleService.getMissionsBySiteAndPoleNotInIds([-1], this.selectedPassage.mainPoi[0].idPointInterest, this.selectedTransport.idTransportType, 2)
      .subscribe(missions => {
        if (this.selectedTransport.idTransportType == 3)
          this.imgsrc = "truck.png"
        else
          this.imgsrc = "mixer-truck-blue-left.png";
        for (let i = 0; i < missions.length; i++) {
          this.trackingMissionData.unshift(missions[i]);
          this.trackingIndex.push(this.trackingMissionData.length - 1);

          this.missionIds.unshift(missions[i].selectedVehicule.idVehicule);
        }
        if (missions.length > 0)
          this.onTracking(0, true);
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargement", "Mission")
      });
  }

  /** ON TRACKING */
  onTracking(index: any, isSorting: any) {
    if (this.trackingIndex.indexOf(index) === -1)
      this.trackingIndex.push(index);

    this.loadingTracking = true;
    this.trackingSize = 0;
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    for (let trIndex of this.trackingIndex) {
      this.tracking(trIndex, isSorting);
    }

  }

  tracking(trIndex: any, isSorting: boolean) {
    this.loadingTracking = true; // Ensure loading state is set
    this.scheduleService.trackingReturn(
      this.trackingMissionData[trIndex].selectedMission.id,
      this.trackingMissionData[trIndex].selectedVehicule.idVehicule,
      2
    )
      .pipe(
        finalize(() => {
          this.loadingTracking = false;
          this.trackingSize++;

          // Perform sorting if needed
          if (isSorting && this.trackingSize >= this.trackingIndex.length) {
            // Filter missions with tracking data
            this.trackingMissionData = this.trackingMissionData.filter(a => a.trackingMission !== null);

            // Sort by state
            this.trackingMissionData.sort((a: any, b: any) => {
              const stateA = this.stateMap[a.trackingMission.state];
              const stateB = this.stateMap[b.trackingMission.state];
              return stateB - stateA; // Descending order
            });

            // Sort by distance difference
            this.trackingMissionData.sort((a: any, b: any) => {
              const diffA = a.selectedMission.routeLegs[0].travelDistance - a.trackingMission.accordingDistance;
              const diffB = b.selectedMission.routeLegs[0].travelDistance - b.trackingMission.accordingDistance;
              return diffB - diffA; // Descending order
            });

            // Sort by end date for specific states
            this.trackingMissionData.sort((a: any, b: any) => {
              if (
                a.trackingMission.state === b.trackingMission.state &&
                a.trackingMission.state !== "DELIVERY" &&
                a.trackingMission.state !== "DELAY"
              ) {
                return (
                  new Date(b.trackingMission.endDate).getTime() -
                  new Date(a.trackingMission.endDate).getTime()
                );
              }
              return 0;
            });
          }
        })
      )
      .subscribe(
        (response: any) => {
          // Update tracking mission data
          if (response.last) {
            response.progressPercent = 100;
            response.accordingDistance = response.mission.travelDistance;
          }

          this.trackingMissionData[trIndex].trackingMission = response;
          this.trackingMissionData[trIndex].selectedMission = response.mission;

          // Additional processing
          this.inverseGeocoding(trIndex);
          this.startUpdateTrackingTimer(trIndex);
          this.countDistance(this.trackingMissionData[trIndex].trackingMission, trIndex);

          if (this.trackingMissionData[trIndex].trackingMission.timeinPoiClient > 5400) {
            this.late_cars.push({
              matricule: this.trackingMissionData[trIndex].selectedVehicule.matricule,
              duree: this.trackingMissionData[trIndex].trackingMission.timeinPoiClient,
            });
          }
        },
        (err: any) => {
          // Handle error
          this.loadingTracking = false;
          const matricule = this.trackingMissionData[trIndex]?.selectedVehicule?.matricule || "unknown";
          this.toastr.error("Erreur lors de chargement", `Mission ${matricule}`);
        }
      );
  }


  /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
  inverseGeocoding(index: any) {
    if (!this.trackingMissionData[index].trackingMission)
      return;
    this.geocodingService.inverseGeoconding(
      this.trackingMissionData[index].trackingMission!.realTimeRecord.coordinate.lat,
      this.trackingMissionData[index].trackingMission!.realTimeRecord.coordinate.lng,
      18)
      .subscribe(response => {
        this.trackingMissionData[index].trackingMission!.realTimeRecord.geocoding = response.display_name;
      }, err => {
        //  this.toastr.warning("Inverse geocoding");
      });

  }

  countDistance(tracking: TrackingMission, index: any) {
    this.trackingMissionData[index].trackingAccordingDistance = 0;
    this.trackingMissionData[index].trackingAccordingDuration = 0;
    for (var i = 0; i < tracking.mission.routeLegs.length; i++) {

      if (tracking.mission.routeLegs[i].selected === false) {
        this.trackingMissionData[index].trackingAccordingDistance += tracking.mission.routeLegs[i].travelDistance;
        this.trackingMissionData[index].trackingAccordingDuration += tracking.mission.routeLegs[i].travelDuration;
      } else {
        this.trackingMissionData[index].trackingAccordingDistance = tracking.mission.routeLegs[i].travelDistance;
        this.trackingMissionData[index].trackingAccordingDuration = tracking.mission.routeLegs[i].travelDuration;

      }
    }
  }

  /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
  selectedRouteLeg(index: any): RouteLegs {
    if (this.trackingMissionData[index].trackingMission) {
      return this.trackingMissionData[index].trackingMission.mission.routeLegs.find(
        route => route.selected == true
      );
    } else {
      return null;
    }
  }

  /** GET Travel Distance TRACKING MISSION */
  // getTravelDistance(index): number {
  //   if(this.trackingMissionData[index].trackingMission.mission.routeLegs[0].visited==false){
  //     return this.trackingMissionData[index].selectedMission.travelDistance-this.trackingMissionData[index].trackingMission.accordingDistance;
  //   }
  //   else if(this.trackingMissionData[index].trackingMission.mission.routeLegs[0].visited==true && this.trackingMissionData[index].trackingMission.mission.routeLegs[1].visited==true){
  //     return 0;
  //   }
  //   else{
  //     return this.trackingMissionData[index].selectedMission.travelDistance-this.trackingMissionData[index].trackingMission.mission.routeLegs[0].travelDistance-this.trackingMissionData[index].trackingMission.accordingDistance;
  //   }
  // }

  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer(index: any) {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 300;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        this.loadMissions();
        if (
          this.trackingMissionData[index] && this.trackingMissionData[index].trackingMission != null
        )
          this.onTracking(index, true);

      }
    })
  }
  /*Begin select Poi and Transport*/
  getTrackingTime(): string {
    if (this.updateTrackingTimer >= 100)
      return Math.round(this.updateTrackingTimer / 60) + " ' ";
    else var sec = this.updateTrackingTimer / 60;
    return sec.toFixed(1) + ' " ';
  }

  chooseSchedule(passage: any) {
    this.selectedPassage = passage;
    //this.missionIds = [];
    this.stopTracking();
  }

  chooseTransport(transport: any) {
    this.selectedTransport = transport;
    this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
      return v.idVehicule;
    });
    //this.missionIds = [];
    this.stopTracking();
  }

  stopTracking() {
    if (this.updateTrackingTimerSubscription)
      this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = null;
  }
  /*End select Poi and Transport*/

  /*Begin loading missions and Transport Types*/
  loadAllPassage() {
    this.loading = true;
    this.passageService.findAllPassages().subscribe(
      (passages) => {
        this.passageService.passages = passages;
        if (this.passageService.passages.length > 0) {
          this.selectedPassage = this.passageService.passages[0];
          this.loadMissions();
        } else {
          this.loading = false;
        }
      },
      (error) => {
        this.toastr.error('Erreur lors de chargements', 'Passage');
        this.loading = false;
      }
    );
  }

  passagesData() {
    if (this.passageService.passages.length > 0) {
      this.selectedPassage = this.passageService.passages[0];
      this.loadMissions();
      // this.stop();
    } else {
      this.loadAllPassage();
    }
  }

  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.loading = true;
      this.transportTypeService.findAllTransportTypes().subscribe(
        (transportTypes) => {
          this.transportTypeService.transportTypes = transportTypes;
          if (transportTypes.length > 0) {
            this.selectedTransport = transportTypes[transportTypes.length - 1];
            this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
              return v.idVehicule;
            });
            this.passagesData();
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Type de transport');
        }
      );
    } else {
      this.selectedTransport =
        this.transportTypeService.transportTypes[
        this.transportTypeService.transportTypes.length - 1
        ];
      this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
        return v.idVehicule;
      });
      this.passagesData();
    }
  }

  /*Begin loading missions and Transport Types*/
}
