import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityOverviewManagementComponent } from './activity-overview-management.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ActivityOverviewManagementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  exports: [
    ActivityOverviewManagementComponent
  ]
})
export class ActivityOverviewManagementModule { }
