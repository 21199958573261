import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CronSettingsComponent } from './cron-settings.component'; // Adjust the path as necessary
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    CronSettingsComponent // Declare your component here
  ],
  imports: [
    CommonModule, // Include CommonModule for Angular common directives
    FormsModule,
    ReactiveFormsModule, // For reactive forms if needed
    MatCheckboxModule,// For mat-checkbox
  ],
  exports: [
    CronSettingsComponent // Export the component if it needs to be used in other modules
  ]
})
export class CronSettingsModule { }
