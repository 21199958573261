<div style="margin-bottom: 10px">
    <mat-checkbox [(ngModel)]="cron.payload.morning">Matin</mat-checkbox>

    <mat-checkbox [(ngModel)]="cron.payload.night">Soir</mat-checkbox>

    <div class="form-group" style="background-color: #D8D8D8;border-radius: 5px;">

        <div class="checkbox">
            <mat-checkbox class="col-sm-4" [checked]="checked" [(ngModel)]="cron.payload.days.monday">
                Lundi
            </mat-checkbox>

            <mat-checkbox class="col-sm-4" [(ngModel)]="cron.payload.days.thesday">
                Mardi
            </mat-checkbox>

            <mat-checkbox class="col-sm-4" [(ngModel)]="cron.payload.days.wednesday">
                Mercredi
            </mat-checkbox>
        </div>

        <div class="checkbox">
            <mat-checkbox class="col-sm-4" [(ngModel)]="cron.payload.days.thursday">
                Jeudi
            </mat-checkbox>

            <mat-checkbox class="col-sm-4" [(ngModel)]="cron.payload.days.friday">
                Vendredi
            </mat-checkbox>

            <mat-checkbox class="col-sm-4" [(ngModel)]="cron.payload.days.saturday">
                Samedi
            </mat-checkbox>
        </div>

        <div class="checkbox">
            <mat-checkbox class="col-sm-4" [(ngModel)]="cron.payload.days.sunday">
                Dimache
            </mat-checkbox>
        </div>
    </div>
</div>
<!--
<div class="form-group mx-sm-3 mb-2">
    <label>Heure debut</label>
    <select class="form-control input-sm" [(ngModel)]="cron.payload.from.hour">
        <option style="font-size: 14px;" *ngFor="let hour of hours" [ngValue]="hour.value">
            {{hour.label}}
        </option>
    </select>
    <label>H</label>
    <select class="form-control input-sm" [(ngModel)]="cron.payload.from.minute">
        <option style="font-size: 14px;" *ngFor="let minute of minutes" [ngValue]="minute.value">
            {{minute.label}}
        </option>
    </select>
    <label>M</label>
    <label>- Heure fin</label>
    <select class="form-control input-sm" [(ngModel)]="cron.payload.to.hour">
        <option style="font-size: 14px;" *ngFor="let hour of hours" [ngValue]="hour.value">
            {{hour.label}}
        </option>
    </select>
    <label>H</label>
    <select class="form-control input-sm" [(ngModel)]="cron.payload.to.minute">
        <option style="font-size: 14px;" *ngFor="let minute of minutes" [ngValue]="minute.value">
            {{minute.label}}
        </option>
    </select>
    <label>M</label>
</div>
-->