import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import {OilChange} from '../model/index';
import {dns} from "../../../global.config";
import {createAuthorizationHeader} from "../../../utils/security/headers";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class OilChangeService {

    constructor(private _http: HttpClient) { }

    public getListOilChanges(idVehicule: number): Observable<OilChange[]> {
        let headers = createAuthorizationHeader();
        return this._http.get<OilChange[]>(dns + 'oilChanges/list/' + idVehicule, { headers: headers });
    }

    public delete(id: number): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + 'oilChanges/' + id, { headers: headers });
    }

    deleteMultiple(oilChangesId: number[]): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http.delete<Boolean>(dns + "oilChanges/multiple/" + oilChangesId, { headers: headers });
    }

    public add(oilChange: OilChange, idVehicule: number, groupId: number): Observable<OilChange> {
        let headers = createAuthorizationHeader();
        return this._http.post<OilChange>(dns + 'oilChanges?idVehicule=' + idVehicule + "&groupId=" + groupId, oilChange, { headers: headers });
    }

    public update(oilChange: OilChange): Observable<OilChange> {
        let headers = createAuthorizationHeader();
        return this._http.put<OilChange>(dns + 'oilChanges', oilChange, { headers: headers });
    }
}
