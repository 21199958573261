import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modals
import { CimentSitesComponent } from './ciment-sites.component'; // Import your component
import { CimentSiteService } from './ciment-site.service'; // Import the service
import { NgxLoadingModule } from 'ngx-loading';
import { RouterModule } from '@angular/router';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    CimentSitesComponent // Declare the component
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule,
    NgxLoadingModule.forRoot({}),
    OrderPipModule,
    FilterPipeModule,

  ],
  providers: [
    CimentSiteService // Provide the CimentSiteService
  ],
  exports: [
    CimentSitesComponent // Export the component if it will be used in other modules
  ]
})
export class CimentSitesModule { }
