import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CimentDashboardComponent } from './ciment-dashboard.component'; // Path to the component
import { ToastrModule } from 'ngx-toastr'; // For notifications
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Datepicker module
import { FormsModule } from '@angular/forms'; // For form handling
import { DataManagementService } from '../../data-management/data-management.service';
import { PathService } from '../../historical/path/path.service';
import { BreakDownFormComponent } from '../break-down-form/break-down-form.component';
import { BreakDownFormModule } from '../break-down-form/break-down-form.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { ScheduleChartModule } from '../../charges-pfb/schedule/schedule-chart/schedule-chart.module';
import { TimediffPipeModule } from 'src/app/pipes/timediff.pipe.module';

@NgModule({
  declarations: [
    CimentDashboardComponent,
  ],
  imports: [
    CommonModule, // Common Angular directives (ngIf, ngFor)
    ToastrModule.forRoot(), // For Toastr notifications
    ModalModule.forRoot(), // Bootstrap modal configuration
    BsDatepickerModule.forRoot(), // Bootstrap datepicker
    FormsModule, // Template-driven forms
    BreakDownFormModule,
    SharedPipesModule,
    ScheduleChartModule,
    TimediffPipeModule
  ],
  providers: [
    DataManagementService, // Providing the DataManagementService
    PathService // Providing the PathService
  ],
  exports: [
    CimentDashboardComponent // Exporting the component for use in other modules
  ]
})
export class CimentDashboardModule { }
