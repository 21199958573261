import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import * as L from 'leaflet';
import { DatePipe } from '@angular/common';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RealtimeComponent } from '../../realtime.component';
import {
  MileageService,
  PathService,
} from 'src/app/client-management/historical';
import { RealtimeRestService } from '../../services/realtime-rest.service';
import {
  DataManagementService,
  Vehicule,
} from 'src/app/client-management/data-management';
import { RealtimeService } from '../../services/realtime.service';
import { Subscription } from 'rxjs';
import { ToolKmJourService } from '../../services/tool-km-jour.service';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { DateInterval } from 'src/app/shared/model';
import { RealtimeProcessingService } from '../../services/real-time-processing.service';
import moment from 'moment';

@Component({
  selector: 'component-control-options',
  templateUrl: './control-options.component.html',
  styleUrls: ['./control-options.component.css'],
})
export class ControlOptionsComponent implements OnInit, OnDestroy {
  isClicked: boolean = false;
  isDisabledCondition: boolean = false;

  selectedVehicule: Vehicule;
  selectedVehiculeSubs: Subscription;
  hideComponent: boolean = true;
  hideComponentSubscription: Subscription;
  enCoursSubscription: Subscription;
  hideEncours: boolean = true;
  
  
  deviceId: number;
  
  vehicules: Vehicule[] = [];
  item: number;



  selected: {startDate: moment.Moment, endDate: moment.Moment} | null = {
    startDate: moment().startOf('day'),           
    endDate: moment().endOf('day')  
  };  
  locale: LocaleConfig = {
    format: 'DD/MM/YYYY HH:mm',
    applyLabel: 'Appliquer',
    customRangeLabel: ' - ',
    daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'], 
    monthNames: [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juill', 'Août', 'Septem', 'Octob', 'Nove', 'Déce'
    ],
    firstDay: 1
  }
  dateRangeFired = true;
  maxDate = moment(); 
  dateLimit = { days: 5 };




  constructor(
    public realtimeComp: RealtimeComponent,
    public mapService: MapService,
    public pathService: PathService,
    public mileageService: MileageService,
    public signinService: SigninService,
    public realRestTimeService: RealtimeRestService,
    public realTimeService: RealtimeService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private realTimeProcessing: RealtimeProcessingService,
    public dataManagementService: DataManagementService,
    private toolKmJourService : ToolKmJourService
  ) {}

  ngOnInit() {


    this.initializeData();
    this.subscribeToVehiculeUpdates();
    this.subscribeToBarToolStatus();
    this.subscribeToEnCours();
    this.routerSubscription();

  }
  routerSubscription(){
    this.router.events.subscribe(() => {
      const currentRoute = this.route.snapshot.url.map(segment => segment.path).join('/');
      this.isDisabledCondition = currentRoute.includes('en-cours');
    });
  }

  private initializeData() {
    this.selectedVehicule = this.realTimeService.selectedVehicule;
    this.deviceId = +this.route.snapshot.params['id'];
    this.mapService.controlMarkers(this.deviceId, true)
    this.toolKmJourService.setDeviceoption(this.deviceId);
    this.vehicules = this.dataManagementService.vehicules;
    this.item = this.deviceId;
  }

  onItemChange(changes: number) {
    if (changes && (changes !== this.selectedVehicule.idDevice)) {
      this.ngOnDestroy();
      this.realTimeService.selectedVehicule = this.vehicules.filter(vehicule => vehicule.idDevice == changes)[0];
      this.selectedVehicule =this.realTimeService.selectedVehicule;
      this.realTimeService.currentPathClickedDeviceId= this.selectedVehicule.idDevice;
      this.mapService.controlMarkers(changes, true);
      let currentUrlSplited = this.router.url.split('/realtime/');
      let stablePath = currentUrlSplited[0] + '/realtime/';
      
      let dynamicUrlSplited =currentUrlSplited[1].split('/');
      dynamicUrlSplited[0] = changes.toString();
      let distination = stablePath + dynamicUrlSplited.join('/');
      this.router.navigate([distination], { relativeTo: this.route });
      this.realTimeService.setChangeDevice();
    }
  }

  choosedDate() {
    if(this.selected){
      let dateInterval: DateInterval = new DateInterval();
      dateInterval.startDate = this.selected.startDate ? this.selected.startDate.toDate() : null;
      dateInterval.endDate = this.selected.endDate ? this.selected.endDate.toDate() : null;
      this.realTimeProcessing.setNormalDateInterval(dateInterval);
     }
  }



  private subscribeToVehiculeUpdates() {
    this.selectedVehiculeSubs = this.realTimeService.getVehicule().subscribe((v) => {
      this.selectedVehicule = v;
      this.updateMap(v);
    });
  }

  private subscribeToBarToolStatus() {
    this.hideComponentSubscription = this.realTimeService.isBarToolStatus().subscribe((status) => {
      this.hideComponent = status;
      this.cdr.detectChanges();
    });
  }
  private subscribeToEnCours(){
    this.enCoursSubscription= this.toolKmJourService.getEnCoursPath().subscribe((getEnCoursPath) =>{
      if(getEnCoursPath){
        this.hideEncours = false;
      }else{
        this.hideEncours = true;
      }
    })
  }



  displayCurrentPath() {
    this.mapService.map.setView(
      this.selectedVehicule.realTimeRecord.coordinate,
      17
    );
    clearInterval(this.realTimeService.liveReloadingRT);
    this.mapService.clearPolylines();

    this.pathService.getCurrentPath(this.deviceId).subscribe(
      (currentPath) => {
        if (currentPath != null) {
          let polyline = L.polyline(currentPath.coordinates, {
            color: '#0031D9',
            weight: 3,
          });
          currentPath.coordinates.forEach((coordinate: any, i: number) => {
            let marker = new L.Marker(coordinate);
            if (i != 0 && i != currentPath.coordinates.length - 1) {
              marker.setIcon(
                new L.Icon({
                  iconUrl: this.realTimeService.imagesDir + 'green-point.png',
                  iconAnchor: [8, 8],
                })
              );
              let pointDatePipe = new DatePipe(coordinate.date);
              let popup =
                '<b>Heure:</b> ' +
                pointDatePipe.transform(
                  coordinate.date,
                  'dd/MM/yyyy HH:mm:ss',
                  null,
                  'fr-fr'
                ) +
                '<b><br>vitesse:</b> ' +
                coordinate.speed +
                ' Km/h';
              marker.bindPopup(popup);
              marker.on('click', () => {
                this.mapService.map.setView(coordinate, 17);
              });
              this.mapService.addMarker(marker);
            }
          });
          let startMarker = new L.Marker({
            lat: currentPath.beginPathLatitude,
            lng: currentPath.beginPathLongitude,
          });
          let startDatePipe = new DatePipe(currentPath.beginPathTime);
          startMarker.bindPopup(
            '<b> <i>[lat: </b>' +
              currentPath.beginPathLatitude +
              '<b> ,long: </b>' +
              currentPath.beginPathLongitude +
              '<b>]</i><br>Temps de debut du trajet : </b>' +
              startDatePipe.transform(
                currentPath.beginPathTime,
                'dd/MM/yyyy HH:mm:ss',
                null,
                'fr-fr'
              )
          );
          startMarker.setIcon(
            new L.Icon({
              iconUrl: this.realTimeService.imagesDir + 'startMarker.png',
              iconAnchor: [-2, 30],
              popupAnchor: [10, -25],
            })
          );
          this.mapService.addMarker(startMarker);
          this.mapService.addPolyline(polyline);
          this.realTimeService.setCurentPathSelected(true);
          this.realTimeService.currentPathClickedDeviceId = this.deviceId;
        }
        document
          .getElementById('clear_ploylines_button')
          .classList.remove('hide');
      },
      (err) => {}
    );
  }

  moreOptions() {
    this.mapService.map.setView(
      this.selectedVehicule.realTimeRecord.coordinate,
      17
    );
  }

  stopRealTime() { 
    this.router.navigate(['/client/realtime']);
  }
  updateMap(vehicule: Vehicule) {
    this.mapService.updateMarker(vehicule.device.idDevice, vehicule.realTimeRecord.coordinate);
    this.mapService.map.setView(vehicule.realTimeRecord.coordinate, 17);
  }


  ngOnDestroy(): void {
    this.mapService.clearPolylines();
    this.mapService.map.setView(
      this.selectedVehicule.realTimeRecord.coordinate,
      8
    );
    this.mapService.removeMarkersAndClearArrays();
    this.realTimeService.currentPathClickedDeviceId = null;
    this.mapService.controlMarkers(
      this.selectedVehicule.device.idDevice,
      false
    ); 
    this.toolKmJourService.setChildPath(null);
    this.toolKmJourService.setKmJourPath(null);
    this.toolKmJourService.setLoaded(false);
    this.toolKmJourService.setMileage(null);
    this.toolKmJourService.setEnCoursPath(null);
    this.toolKmJourService.setPrintedKmJour(null);

    this.selectedVehiculeSubs.unsubscribe();
    this.enCoursSubscription.unsubscribe();
    this.hideComponentSubscription.unsubscribe();
    this.mapService.clearPolylines();
    this.realTimeService.clearRealTime();
  }
}