<div *ngIf="!show">
  <div class="row">
    <div class="col-md-4">
      <select
        class="form-control input-sm select3"
        (change)="onChange($event.target.value)"
        style="height: 25px"
      >
        <option [value]="year.id" *ngFor="let year of Years">
          {{ year.name }}
        </option>
      </select>
    </div>
    <div class="col-md-4">
      <select
        class="form-control input-sm select3"
        [(ngModel)]="IsByParent"
        (ngModelChange)="onChangeType()"
        style="height: 25px"
      >
        <option [ngValue]="false">Par Groupe</option>
        <option [ngValue]="true">Par Parent</option>
      </select>
    </div>
    <div class="col-md-4" *ngIf="!IsByParent">
      <select
        class="form-control input-sm select3"
        [(ngModel)]="isByMultipleGroups"
        (ngModelChange)="onChangeByMultipleGroups()"
        style="height: 25px"
      >
        <option [ngValue]="false">Tous les Groupes</option>
        <option [ngValue]="true">Groupe de Véhicules</option>
      </select>
    </div>
  </div>

  <div *ngIf="isByMultipleGroups && !IsByParent" class="row">
    <div class="col-md-12">
      <form #groupForm="ngForm" class="form-horizontal">
        <div class="col-lg-10 col-md-10">
          <label class="col-lg-4 col-md-4 control-label">Groupes</label>
          <div class="col-lg-8 form-group">

            <ng-multiselect-dropdown
            [placeholder]="'personnalisé'"
            [settings]="dropdownSettings"
            [data]="groups"
            [(ngModel)]="groupIds"
            (onSelect)="onItemSelect($event)"
          >
          </ng-multiselect-dropdown>

          </div>
        </div>

        <div class="col-lg-2 orm-group" *ngIf="!displayCalendar">
          <button
            type="button"
            (click)="onValidate()"
            class="btn btn-block btn-success btn-flat"
          >
            <i class="fa fa-search pull-left" aria-hidden="true"></i>
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="row" *ngIf="displayCalendar">
    <div class="col-md-12">
      <form #dashbordForm="ngForm" class="form-horizontal">
        <div class="col-lg-5 form-group">
          <label class="col-lg-4 col-md-4 control-label">Du</label>
          <div class="col-lg-8 col-md-8">
            <input
              type="text"
              class="form-control input-sm"
              bsDatepicker
              name="startDate"
              [(ngModel)]="startDate"
              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
              placement="bottom"
              required
              (ngModelChange)="onChangeStartDate()"
            />
          </div>
        </div>
        <div class="col-lg-5 orm-group">
          <label class="col-lg-4 col-md-4 control-label">Au</label>
          <div class="col-lg-8 col-md-8">
            <input
              type="text"
              class="form-control input-sm"
              bsDatepicker
              name="endDate"
              [(ngModel)]="endDate"
              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
              [minDate]="startDate"
              placement="bottom"
              required
            />
          </div>
        </div>
        <div class="col-lg-2 orm-group">
          <button
            type="button"
            (click)="onValidate()"
            class="btn btn-block btn-success btn-flat"
          >
            <i class="fa fa-search pull-left" aria-hidden="true"></i>
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
  <ngx-loading [show]="globalLoading"></ngx-loading>
  <div id="running" class="w-100 d-inline-block"></div>
</div>

<div *ngIf="show">
  <ngx-loading [show]="globalLoading"></ngx-loading>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6">
          <p (click)="openModal()">
            <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
            ...
          </p>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div id="running" style="zoom: 0.58" class="d-inline-block"></div>
    </div>
  </div>

  <!-- Bootstrap Modal -->
  <div
    class="modal"
    tabindex="-1"
    role="dialog"
    [ngClass]="{ show: showModal }"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <p>Historique Canal / Groupe / vehicule par Mois</p> -->
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div id="runningModal" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </div>
  </div>
</div>
