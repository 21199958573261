import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts5 from 'highcharts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ChargePfbInfoDto, ChargeRealise } from '../../charges-pfb';
require('highcharts/modules/exporting')(Highcharts5);
require('highcharts/modules/no-data-to-display')(Highcharts5);

@Component({
  selector: 'app-turnover-achieved',
  templateUrl: './turnover-achieved.component.html',
  styleUrls: ['./turnover-achieved.component.css']
})
export class TurnoverAchievedComponent implements OnInit {

  //Chart vars
  chart: any;
  options: Object;
  serieName: any;

  groupeStatistics: any;
  caRealise_: ChargeRealise[];
  turnoverAchieved: Object | null= null;
  globalLoading: boolean;
  selectedCharge: ChargePfbInfoDto = new ChargePfbInfoDto();
  dayDif: number = 0;
  budget: number = 0;

  @Input()
  set selectedCharges(chargePfb: ChargePfbInfoDto) {
    if (chargePfb) {
      this.selectedCharge = chargePfb;
    }
  }


  @Input()
  set dayDifference(dayDifference: number) {
    this.dayDif = dayDifference;
  }

  @Input()
  set caRealise(caRealise: ChargeRealise[]) {
    if (caRealise) {
      this.caRealise_ = Object.assign(caRealise);
      this.caRealise_ = this.caRealise_.filter(ca => ca.caRealise > 0)
      this.timeChart();
    }
  }

  constructor(public toastr: ToastrService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.timeChart();
  }

  timeChart() {

    this.setHighCartsLang();

    var data: { x: Date, y: number }[] = [];

    this.budget = (this.selectedCharge.caTransport / this.selectedCharge.nbDays) * this.dayDif;

    this.caRealise_.forEach(ca => {
      data.push({ x: ca.operationTime, y: ca.caRealise });
    })

    this.turnoverAchieved = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: 'Evolution Chiffre d\'affaire',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
       valueDecimals: 2,
       valueSuffix: ' DH'
   },
      xAxis: {
        type: 'datetime',
        tickInterval: 7200 * 1000,
        labels: {
          // formatter: function (): string { // Annotation de type ajoutée ici
          //   return Highcharts5.dateFormat("%d/%m/%Y %H:%M", this.value);
          // }
        }
      },
      yAxis: {
        min: 0,
        minRange: this.budget,
        plotLines: [{
          value: this.budget,
          color: 'red',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: 'Budget',
          }
        }],
      },

      series: [{
        name: 'CA.Réalisé',
        findNearestPointBy: 'xy',
        data: data
      }]

    }, Highcharts5.chart('turnoverAchieved', this.turnoverAchieved);

  }


  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts5.setOptions({
      colors: ['#21bf73', '#ed3833', '#007db1', '#FFFF00'],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        noData: 'Aucune donnée n\'est disponible'
      }
    });
  }


}
