<div (click)="collapseDashboard()" *ngIf="opened">
    <img *ngIf="((isMinifyDashboard && deviceopt) && (deviceopt.useFms != 0 || deviceopt.useJ1708 != 0))" class="minifyDash" [ngClass]="{'minifyDash': isMinifyDashboard,'maximizeDash': !isMinifyDashboard}"
         class="enclose problem" src="{{imagesCarsDir}}dashboard.png">

    <section *ngIf="((!isMinifyDashboard && deviceopt) && (deviceopt.useFms != 0 || deviceopt.useJ1708 != 0))">

        <div class="info2" style="font-family: 'Inconsolata', monospace !important;"  style="position: absolute;margin-bottom: -210px;z-index: 5;">
            <ul>
                <li style="padding-left: 25px; padding-top: 3px;"><b *ngIf="valueFuel">{{valueFuel | round}} %</b><b *ngIf="!valueFuel">- - -</b> </li>
            </ul>
        </div>

        <div class="info" style="font-family: 'Inconsolata', monospace !important;"  style="position: absolute;margin-bottom: -310px">
            <ul> 
                <li>CI : <b *ngIf="valueFuelRate && valueFuelRate <= 100">{{valueFuelRate | round}}</b><b *ngIf="!valueFuelRate || valueFuelRate > 100">--</b> <i>L/100</i></li>
                <li>TFU : <b *ngIf="valueTfu">{{valueTfu | round}}</b><b *ngIf="!valueTfu">--</b> <i>L</i></li>
                <li style="margin-top : 29px ; padding-left: 12px;"><b *ngIf="valueAccumOdo">{{valueAccumOdo | round}}</b><b *ngIf="!valueAccumOdo">- - - - -</b></li>
            </ul>
        </div>
        
        <div class="info3" style="font-family: 'Inconsolata', monospace !important;"  style="position: absolute;margin-bottom: -310px">

            <ul>
                 <li style="margin-top: 13px;font-size: 14px;color: white !important;margin-left: 100px;"><b>{{vehiculeName}}</b></li>
            </ul>

        </div>

        <img class="enclose problem" src="{{imagesCarsDir}}dashboard.png" style="position: absolute;margin-bottom: -310px">

        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4">
                    <div style="margin-top: 4px; margin-left: 200px;position: absolute">
                        <jqxGauge #myGaugeRpm
                                  [value]="valueRpm"
                                  [style]="styleRpm"
                                  [startAngle]="35"
                                  [endAngle]="280"
                                  [border]="borderRpm"
                                  [height]="250"
                                  [width]="250"
                                  [max]="9"
                                  [animationDuration]="900"
                                  [labels]="labelsRpm"
                                  [ticksMinor]="ticksMinorRpm" [ticksMajor]="ticksMajorRpm" [colorScheme]="'scheme05'">
                        </jqxGauge>
                    </div>
                </div>
                <div class="col-md-4">
                    <div style="margin-top: 4px; margin-left: 410px;position: absolute">
                        <jqxGauge #myGaugeFuel
                                  [value]="valueFuel"
                                  [style]="styleRpm"
                                  [startAngle]="-35"
                                  [endAngle]="77"
                                  [border]="borderRpm"
                                  [height]="148"
                                  [width]="148"
                                  [max]="100"
                                  [animationDuration]="500"
                                  [labels]="labelsFuel" [ticksMinor]="ticksMinorFuel" [ticksMajor]="ticksMajorFuel"
                                  [colorScheme]="'scheme05'">
                        </jqxGauge>
                    </div>

                    <div style="margin-top: 140px; margin-left: 410px;position: absolute">
                        <jqxGauge #myGaugeTemp
                                  [value]="valueTemp"
                                  [style]="styleRpm"
                                  [startAngle]="-35"
                                  [endAngle]="77"
                                  [border]="borderRpm"
                                  [height]="148"
                                  [width]="148"
                                  [min]="60" [max]="140"
                                  [animationDuration]="500"
                                  [labels]="labelsTemp" [ticksMinor]="ticksMinorTemp" [ticksMajor]="ticksMajorTemp"
                                  [colorScheme]="'scheme05'">
                        </jqxGauge>
                    </div>
                </div>
                <div class="col-md-4">
                    <div style="margin-top: 4px; margin-left: 520px;position: absolute">
                        <jqxGauge #myGaugeSpeed
                                  [value]="valueSpeed"
                                  [style]="styleRpm"
                                  [startAngle]="50"
                                  [endAngle]="330"
                                  [border]="borderRpm"
                                  [width]="250"
                                  [height]="250"
                                  [max]="210"
                                  [animationDuration]="500"
                                  [labels]="labelsRpm"
                                  [ticksMinor]="ticksMinorSpeed" [ticksMajor]="ticksMajorSpeed" [colorScheme]="'scheme05'">
                        </jqxGauge>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
