import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionFormComponent } from './mission-form.component';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
//import { DndModule } from 'ng2-dnd';

@NgModule({
  declarations: [
    MissionFormComponent, // Declare the component
  ],
  imports: [
    CommonModule,  // CommonModule is essential for common directives like *ngIf and *ngFor
    FormsModule,
    NgxLoadingModule.forRoot({}),
    //DndModule
  ],
  exports: [
    MissionFormComponent, // Export the component if it needs to be used in other modules
  ],
  providers: [
  ],
})
export class MissionFormModule { }
