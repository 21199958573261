import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeedCartoSettingsComponent } from './speed-carto-settings.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms'; // If you're using ngModel or any forms
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; // If you're using Angular Material slide toggle
import { AlertManagementService } from '../../alert-management.service';
import { DataManagementService } from 'src/app/client-management/data-management';
import { NgxLoadingModule } from 'ngx-loading';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';

@NgModule({
  declarations: [
    SpeedCartoSettingsComponent,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    FormsModule,
    MatSlideToggleModule,
    NgxLoadingModule.forRoot({}),
    EmailManagerModule,
    CronSettingsModule
  ],
  providers: [
    AlertManagementService, // Provide the AlertManagementService
    DataManagementService // Provide the DataManagementService
  ],
  exports: [
    SpeedCartoSettingsComponent // Export the component if it will be used outside this module
  ]
})
export class SpeedCartoSettingsModule { }
