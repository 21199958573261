<ngx-loading [show]="globalLoading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<section *ngIf="selectedVehicule != null">
    <app-vehicule-detail
        [selectedVehicule]="selectedVehicule"
        (showDocuments)="showDocumentsChild($event)"
    ></app-vehicule-detail>
</section>

<br>
<div class="col-lg-12">
    <div class="content" >
        <div class="table-responsive mt-2">
          <table class="table table-fixed table-striped table-hover table-condensed   shadowBox">
              <thead class=" tableFixHead">
              <tr>
                  <th style="width:6%">Id</th>
                  <th style="width:10%">Matricule</th>
                  <th style="width:12%">Groupe(s)</th>
                  <th style="width:6%">Ca</th>
                  <th style="width:6%">Surnom</th>
                  <th style="width:6%">Marque</th>
                  <th style="width:10%">So_Marque</th>
                  <th style="width:4%">V Max</th>
                  <th style="width:14%">Conducteur</th>
                  <th style="width:12%">N° SIM</th>
                  <th style="width:9%">Mise en circulation</th>
                  <!-- <th style="width:17%">E-mails</th> -->
                  <th style="width:4%">

                  </th>
                  <th style="width:21%">

                      <div class="input-group input-group-sm">
                          <input type="text" name="table_search" class="form-control pull-left" style="height: 25px;"
                                 placeholder="Immatricule, Marque, Surnom ..." name="search" [(ngModel)]="keyword"
                                 (keyup.enter)="searchVehicule()">

                          <div class="input-group-btn">
                              <button type="submit" class="btn btn-default" style="height: 25px;" (click)="searchVehicule()"><i
                                     style="    position: relative; top: -2px;" class="fa fa-search"></i>
                              </button>
                          </div>
                      </div>

                  </th>
              </tr>
              </thead>
              <tbody>

              <tr *ngIf="loading">
                  <td style="text-align: center" colspan="13"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></td>
              </tr>

              <ng-container *ngIf="!loading">
                  <tr *ngFor="let vehicule of vehicules">
                      <td>{{vehicule?.device?.idDevice}}</td>
                      <td>{{vehicule.matricule | noValue}}</td>
                      <td>
                        <span class="span-separator" *ngFor="let group of vehicule?.groups | slice:0:1">{{group.nom}}<span *ngIf="!vehicule?.groups || vehicule?.groups?.length > 1" title="{{groupsNames}}">, ...</span></span>
                        <ng-container *ngIf="!vehicule?.groups || vehicule?.groups?.length < 1">...</ng-container>
                      </td>
                      <td>{{vehicule.capacity | noValue}}</td>
                      <td>{{vehicule.alias | noValue}}</td>
                      <td>{{vehicule.mark | noValue}}</td>
                      <td>{{vehicule.subMark | noValue}}</td>
                      <td>{{vehicule.maxSpeed | noValue}}</td>
                      <td *ngIf="vehicule.driver">{{vehicule.driver.lastName}} {{vehicule?.driver?.firstName}}</td>
                      <td *ngIf="!vehicule.driver">...</td>
                      <td>{{vehicule?.device?.deviceNumber | noValue}}</td>
                      <td>{{vehicule.circulationAt | date: 'yyyy-MM-dd'}}</td>
                      <!-- <td>{{vehicule.emails | noValue}}</td> -->

                      <td>
                          <div *ngIf="vehicule.icon">
                              <img src="{{url+vehicule.icon}}" width="30px"/>
                          </div>
                      </td>

                      <!-- OPTIONS (SHOW/EDIT/ASSIGN/DELETE) -->
                      <td style="width:25%">

                          <button tooltip="Delete" (click)="confirmDeleteVehicule(vehicule)"
                                  class="btn ink-reaction btn-danger pull-right resizedBtn"><i class="fa fa-trash-o"
                                                                                    aria-hidden="true"></i>
                          </button>

                          <a class="pull-right"
                             [routerLink]="['/client/dataManagement/vehicule/form/', vehicule.idVehicule, 'UPDATE']"
                             routerLinkActive="active">
                              <button tooltip="Modifier" class="btn btn-warning resizedBtn"><i class="fa fa-cog" aria-hidden="true"></i>
                              </button>
                          </a>

                          <a class="pull-right"
                             [routerLink]="['/client/dataManagement/vehicule/form/', vehicule.idVehicule , 'ASSIGN']"
                             routerLinkActive="active">
                              <button class="btn btn-primary resizedBtn" tooltip="Affecter">
                                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                              </button>
                          </a>


                          <!-- [routerLink]="['/client/dataManagement/vehicule/form/', vehicule.idVehicule, 'SHOW']"
                          routerLinkActive="active" -->

                          <a class="pull-right">
                            <button tooltip="Details" class="btn ink-reaction btn-success resizedBtn" (click)="showDetails(vehicule)">
                                  <i class="fa fa-eye" aria-hidden="true"></i>
                              </button>
                          </a>

                      </td>
                  </tr>
              </ng-container>
              </tbody>
          </table>
        </div>
      </div>
</div>


<div class="row ">
    <div class="col-md-12 " *ngIf="vehicules.length >0" style="text-align: center">
        <pagination class="pagination-sm"
                    [totalItems]="bigTotalItems"
                    [(ngModel)]="bigCurrentPage"
                    [maxSize]="maxSize"
                    [boundaryLinks]="true"
                    [rotate]="false"
                    [itemsPerPage]="itemsPerPage"
                    (pageChanged)="pageChanged($event)"
                    previousText="Précédent"
                    nextText="Suivant"
                    firstText="Premier"
                    lastText="Dernier">
        </pagination>
    </div>
</div>



<!-- =================================== CONFIRM MODAL (START)====================================-->
<div class="modal fade" tabindex="-1" bsModal #confirmDeleteModal="bs-modal" role="dialog" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="confirmDeleteModal.hide()">&times;
                </button>

                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Es vous sur ?
                </h4>

            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white"><i class="fa fa-exclamation-triangle"></i> Attention !
                    </h4>
                    Êtes-vous sûr de supprimer ce véhicule '{{currentVehicule?.matricule}}', ça risque de perdre tous les documents et les réparations associent !
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="confirmDeleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="deleteVehicule()">
                    <i class="fa fa-check" aria-hidden="true"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =================================== CONFIRM MODAL (START)====================================-->


<!-- =================================== Show Documents (START)====================================-->

<div class="modal fade" tabindex="-1" #showDocumentModal="bs-modal" bsModal role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="showDocumentModal.hide()">&times;
                </button>

                <h4 class="modal-title">
                    <i class="fa fa-picture-o" aria-hidden="true"></i> {{imgType}}
                </h4>

            </div>
            <div class="ourCard">
                <div class="control" *ngIf="displayAll" (click)="before()" >&lt;</div>
                <div class="modal-body" style="min-height: 400px;">
                    <!-- <div *ngIf="isImageLoading" style="min-height: 400px;" class="spinner"></div> -->
                    <img
                        src="{{imgUrl}}"
                        style="width: 100%;"
                        alt=""
                    >
                 </div>
                 <div class="control" *ngIf="displayAll" (click)="next()">&gt;</div>
            </div>
        </div>
    </div>
</div>
<!-- =================================== Show Documents (START)====================================-->
