<div>
  <div class="row">
    <div class="col-md-6 col-sm-6">
      <h3>
        Écheances GP
      </h3>
    </div>
    <div class="bg-light text-right col-md-6 col-sm-6">
      <button class="btn btn-warning ml-2" (click)="clickExport()">
        Export
      </button>
    </div>
  </div>
  <ngx-datatable #myTable class="material" [rows]="deadlinesService.rows" [groupRowsBy]="'group'" [scrollbarH]="true"
    [headerHeight]="60" [footerHeight]="60" [rowHeight]="40" [limit]="4" [groupExpansionDefault]="true">
    <ngx-datatable-group-header [rowHeight]="60" #myGroupHeader (toggle)="onDetailToggle($event)">
      <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
        <div style="padding-left:10px;">
          <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
            <b>Groupe: {{group.value[0].group}}</b>
          </a>
        </div>
      </ng-template>
    </ngx-datatable-group-header>
    <ngx-datatable-column name="Matricule" prop="matricule" editable="true"></ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="Conducteur" prop="driver" editable="false" [width]="150"
      [frozenLeft]="true">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="Odomete" prop="odo" editable="false" [width]="150">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="F air (KM)" prop="air" editable="false" [width]="150">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="F huile (KM)" prop="oil" editable="false" [width]="150">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="H moteur (KM)" prop="engine" editable="false">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="F carburant (KM)" prop="fuel" editable="false">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="Pneu (KM)" prop="pneu" editable="false">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassDate" name="Assurance (JOURS)" prop="assurance" editable="false"
      [width]="150">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassDate" name="Vignettes (JOURS)" prop="vignette" editable="false"
      [width]="150">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassDate" name="Taxe de circulation (JOURS)" prop="taxecirculation"
      editable="false" [width]="150">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassDate" name="Visite technique (JOURS)" prop="visite" editable="false"
      [width]="150">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="Embrayage (KM)" prop="embrayage" editable="false"
      [width]="150">
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getCellClassKm" name="distribution (KM)" prop="distribution" editable="false"
      [frozenRight]="true">
    </ngx-datatable-column>
  </ngx-datatable>
</div>
