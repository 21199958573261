import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityTimeChartComponent } from './activity-time-chart.component';
import { ToastrModule } from 'ngx-toastr';
import { HighchartsChartModule } from 'highcharts-angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    ActivityTimeChartComponent,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    HighchartsChartModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
  ],
  exports: [
    ActivityTimeChartComponent
  ]
})
export class ActivityTimeChartModule { }
