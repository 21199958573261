export class Validation{

    nbl : number;
    client : string;
    destination : string;
    produit : string;
    quantite : number;
    matricule : string;
    nomChauffeur : string;
    cinChauffeur : string;
    telChauffeur : string;
    emails : string;

    constructor() {
    }
}