import { Component, OnInit } from "@angular/core";
import { DriverService } from "../../providers/driver.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Driver, Group } from "../../data-management.model";
import { DataManagementService } from "../../data-management.service";
import { ToastrService } from "ngx-toastr";
import { filesDir } from "../../../../global.config";

@Component({
  selector: "app-driver-form",
  templateUrl: "./driver-form.component.html",
  styleUrls: ["./driver-form.component.css"]
})
export class DriverFormComponent implements OnInit {

  driver: Driver = new Driver();

  deleteDriverPicture : boolean = false;

  group: Group = new Group();

  actionAdd: number = 0;

  actionDeleteMultiple: number = 0;

  driverChargesId: number[] = [];

  mode: string;

  groupSanguins: any[] = ["O-", "O+", "A-", "A+", "B-", "B+", "AB-", "AB+"];

  filesDir = filesDir;

  public loading: boolean = false;

  constructor(
    private driverService: DriverService,
    private route: ActivatedRoute,
    public dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
      this.init(params.id);
    });

    /**
     * charge conducteur pour toutes les groupes
     */
    this.group.idGroupe = -1;
  }

  init(idDriver: number) {
    if (this.mode !== "ADD") {
      this.loading = true;
      this.driverService.getOne(idDriver).subscribe(
        driver => {
          this.driver = driver;
          this.driver.healthCareExperiationDate = new Date(driver.healthCareExperiationDate);
          this.driver.permitExperiationDate = new Date(driver.permitExperiationDate);
          this.driver.profesionalCardExperiationDate = new Date(driver.profesionalCardExperiationDate);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }
  }

  onEmailChange(emailPayload: any) {
    this.driver.email = emailPayload.emails;
  }

  onAvatarChange($event: any) {
    this.driver.avatar = $event.target.files[0];
  }

  addDriver() {
    this.loading = true;
    const _formData = new FormData();

    if (this.driver.avatar) {
      _formData.append("avatar", this.driver.avatar, this.driver.avatar.name);
    }
    _formData.append("driver", JSON.stringify(this.driver));

    this.driverService.addDriver(_formData).subscribe(
      response => {
        this.loading = false;
        this.router.navigate(["/client/dataManagement/driver"]);
        this.toastr.success("Conducteur bien ajouter !");
      },
      () => {
        this.loading = false;
      }
    );
  }


  driverCheckboxChange(checked: boolean) {
    this.deleteDriverPicture = checked;
   }

  updateDriver() {
    this.loading = true;
    const _formData = new FormData();

    delete this.driver.avatarUri;

    if (this.driver.avatar) {
      _formData.append("avatar", this.driver.avatar, this.driver.avatar.name);
    }
    _formData.append("driver", JSON.stringify(this.driver));

    this.driverService.updateDriver(_formData, this.deleteDriverPicture).subscribe(
      response => {
        this.loading = false;

        this.router.navigate(["/client/dataManagement/driver"]);
        this.toastr.success("Conducteur bien modifié !");
      },
      () => {
        this.loading = false;
      }
    );
  }

  cancel() {
    this.router.navigate(["/client/dataManagement/driver"]);
  }

  onAdd() {
    this.actionAdd = this.actionAdd + 1;
  }

  onConfirmDeleteMultiple() {
    this.actionDeleteMultiple = this.actionDeleteMultiple + 1;
  }

  wasChecked(driverChargesId: number[]) {
    this.driverChargesId = driverChargesId;
  }

}
