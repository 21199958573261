import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateInterval } from 'src/app/shared/model';
import { TravelDashbordDto } from 'src/app/client-management/parc-management';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { HttpClient } from '@angular/common/http';
import { dns } from 'src/app/global.config';



@Injectable()
export class KilomtrageChartService {

  constructor(private _http: HttpClient) { }

  /** Return dashbord info of all the groups and vehicules  */
  public getKmInfo(
    date: DateInterval
  ): Observable<TravelDashbordDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };

    return this._http
      .post<TravelDashbordDto[]>(dns + 'groupes/getPathsBetweenDatesByGroup', date, options);
  }

  getKmBygroupe(idGroupe: number, date: DateInterval): Observable<TravelDashbordDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<TravelDashbordDto[]>(dns + 'paths/getPathsBetweenDatesByGroupId/' + idGroupe, date, options);
  }
}
