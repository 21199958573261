<div class="row">
    <div class="col-lg-12">
        <div class="box-header page-header with-border">
            <!-- =================================== TITLE (START)==================================== -->
            <!-- <h3 class="box-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;"><i class="fa fa-list"></i>

                <span style="text-decoration: underline">

                      <span>Liste des réparations du véhicule :</span>
                      <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher la véhicule" [routerLink]="['/client/dataManagement/vehicule/form', vehicule.idVehicule, 'SHOW']">{{vehicule.matricule}}</a>

                      <span *ngIf="vehicule.driver"> - conducteur :
                            <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher le conducteur" [routerLink]="['/client/dataManagement/driver/form', vehicule.driver.idDriver, 'SHOW']">{{vehicule?.driver?.firstName}} {{vehicule?.driver?.lastName}}</a>
                      </span>

                </span>


                <span *ngIf="!vehicule.driver" data-toggle="tooltip" class="badge label-warning">
                      <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                </span>

            </h3> -->
            <!-- =================================== TITLE (END)==================================== -->
            <!-- =================================== OPTIONS (START)==================================== -->
            <div class="option-add">


                    <button type="button" class="btn btn-success btn-sm  " title="Ajouter"
                    (click)="onAdd()" ><i class="fa fa-plus-circle"></i> </button>
            </div>
            <div *ngIf="reparationsId.length > 0" class="option-delete">
                <span title="Supprimer tout" (click)="onConfirmDeleteMultiple()">
                    <i class="fa fa-minus-circle"></i> Supprimer tout</span>
            </div>
            <!-- =================================== OPTIONS (END)==================================== -->
            <!--==================================== import/Export functionality(START)=============== -->
            <div style="display: inline-block; margin-left: 5px;">
                <div class="option-export">


                        <button type="button" class="btn btn-primary btn-sm " title="Exporter sous format excel"
                            (click)='export()' >Exporter <i class="fa fa-download"></i> </button>


                        <button type="button" class="btn btn-primary  btn-sm " title="Importer sous format excel"
                        (click)='importModal.show()' >Importer <i class="fa fa-upload"></i> </button>

                </div>
            </div>
            <!--==================================== import/Export functionality(END)================= -->
            <!--==================================== Search(START)=============== -->
            <div class="pull-right">
                <div class="inputWithIcon">
                    <input [(ngModel)]="searchTerm" name="searchTerm" type="text" class="form-control input-sm"
                        placeholder="Fournisseur...">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <!--==================================== Search(END)================= -->
        </div>
    </div>
    <div class="col-lg-12">
        <!-- ========================================== LIST REPARATIONS (START) ====================================================-->
        <div class="content">
            <div class="table-responsive tableFixHead">
                <table class="table table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 2%; text-align: left;"><input type="checkbox" [disabled]="reparations.length == 0"
                                    [checked]="reparations.length == reparationsId.length" (click)="onCheckedAll()"></th>
                            <th style="width:5%">Demander le</th>
                            <th style="width:5%">Réparer le</th>
                            <th style="width:5%">Jours</th>
                            <th style="width:5%">Quantité</th>
                            <th style="width:5%">Coût</th>
                            <th style="width:5%" title="Coût de main d'oeuvre">Coût M.O</th>
                            <th style="width:5%">Odomètre</th>
                            <th style="width:5%" title="Prochain odomètre prévisionnel">Prochain odomètre.P</th>
                            <th style="width:5%" title="Odomètre réparation">Odomètre.R</th>
                            <th style="width:5%">Fournisseur</th>
                            <th style="width:5%">Type</th>
                            <th style="width:5%">Réperation</th>
                            <th style="width:5%">Référence</th>
                            <th style="width:28%">Observations</th>
                            <th style="width:5%"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngIf="loading">
                            <td colspan="16" style="text-align: center">
                                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                            </td>
                        </tr>
                        <tr *ngIf="!loading && reparations.length == 0">
                            <td colspan="16">
                                <div class="alert alert-dismissible" style="border-radius: 0px;text-align: center;">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                        ×
                                    </button>
                                    <h4 style="color: orange">
                                        <i class="fa fa-info-circle"></i> Information
                                    </h4>
                                    Veuillez ajouter des informations pour réparations.
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let reparation of getReparations()">
                            <td>
                                <input type="checkbox" [checked]="isCheckedInList(reparation.id)" (click)="onChecked(reparation.id)">
                            </td>
                            <td style="font-weight: bold">{{reparation?.operationTime | date: 'dd-MM-yyyy, HH:mm'}}</td>
                            <td style="font-weight: bold">{{reparation?.actualRepairTime | date: 'dd-MM-yyyy, HH:mm'}}</td>
                            <td>{{reparation?.days}}</td>
                            <td>{{reparation?.quantity}}</td>
                            <td>{{reparation?.cost | number: '.0-4'}}</td>
                            <td> {{reparation?.laborCost | number: '.0-4'}}</td>
                            <td>{{reparation?.odometre}}</td>
                            <td> {{reparation?.nextForecastOdometer}}</td>
                            <td> {{reparation?.odometerRepair}}</td>
                            <td>{{reparation?.provider?.name}}</td>
                            <td>{{reparation?.reparationType?.name}}</td>
                            <td>{{reparation?.label}}</td>
                            <td> {{reparation?.referenceDADR}}</td>
                            <td>{{reparation?.observation}}</td>
                            <td>
                                <button title="Supprimer" style="color: red;" type="button" class="btn btn-sm btn-default"
                                    (click)="onConfirmDelete(reparation)">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                                <button title="Modifier" style="color: rgb(2, 157, 196); " type="button" class="btn btn-sm btn-default"
                                    (click)="onUpdate(reparation)">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- ========================================== LIST REPARATIONS (END) ====================================================-->
    </div>
</div>
<!-- ========================================== ADD/UPDATE Modal (START) ====================================================-->
<div class="modal fade" bsModal #reparationModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- ========================== Modal TITLE (START) ===============================-->
            <div class="modal-header">
                <button type="button" class="close" (click)="reparationModal.hide()">&times;</button>
                <h4 *ngIf="mode=='ADD'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-plus"></i>
                    Ajouter informations réparation</h4>
                <h4 *ngIf="mode=='UPDATE'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-edit"></i>
                    Modifier informations réparation</h4>
            </div>
            <!-- ========================== Modal TITLE (END) ===============================-->
            <div class="modal-body">
                <div class="box-body">
                    <app-reparation-form [reparation]="reparation" [vehicule]="vehicule" [group]="group" [mode]="mode"
                        (reparationWasAdded)="reparationWasAdded($event)" (reparationWasUpdated)="reparationWasUpdated($event)">
                    </app-reparation-form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ========================================== ADD/UPDATE Modal (END) ====================================================-->
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" bsModal #reparationDeleteModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="reparationDeleteModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                    <span *ngIf="!isDeleteMultiple">Êtes-vous sûr de supprimer cette réparation ?</span>
                    <span *ngIf="isDeleteMultiple">Êtes-vous sûr de supprimer cettes {{reparationsId.length}}
                        réparations
                        ?</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="reparationDeleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="onDelete()">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
<!-- ================================Import reparation (start) ====================================-->
<div class="modal" role="dialog" bsModal #importModal="bs-modal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="importModal.hide()">&times;
                </button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Importer la liste des reparations
                </h4>
            </div>
            <div class="modal-body vertical-spacer">
                <app-import-export-forms [item]='selectedItem' [vehiculeMatricule]='vehicule.matricule' [groupId]="group.idGroupe" (importedItem)='itemWasImported($event)'></app-import-export-forms>
            </div>
            <p></p>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="importModal.hide()">
                    Fermer
                </button>
            </div>
        </div>
    </div>
</div>
<!--=================================Import reparation (end) ====================================-->
