import { Component, OnInit, ViewChild } from '@angular/core';
import { Passage, PassageService, Schedule } from '../schedule';
import { ToastrService } from 'ngx-toastr';
import { DateInterval } from 'src/app/shared/model';
import { PathService } from '../../historical';
import { Observable, Subscription } from 'rxjs';
import {
  FuelingPriceSettingService,
  TravelReportDto,
} from '../../parc-management';
import { RotationTypeComponent } from './graphes/rotation-type/rotation-type.component';
import { ChartDisponibleFlotteComponent } from './graphes/chart-disponible-flotte/chart-disponible-flotte.component';
import { ChartTitreGraphiqueComponent } from './graphes/chart-titre-graphique/chart-titre-graphique.component';
import { ChargesTransportComponent } from './graphes/charges-transport/charges-transport.component';
import { ConsommationMoyenneComponent } from './graphes/consommation-moyenne/consommation-moyenne.component';
import { Notification } from '../../../client-management/alert-management/alert';
import { ChartArretPoiEpsComponent } from './graphes/chart-arret-poi-eps/chart-arret-poi-eps.component';
import {
  SubMarkDto,
  TransportTypeService,
  TransportTypeTravelReport,
} from '../parametrage/transport-type';
import { ChartExcesVitesseComponent } from './graphes/chart-exces-vitesse/chart-exces-vitesse.component';
import moment from 'moment';  // Updated import
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { CanalPfbService } from '../parametrage/_service/canalpfb.service';
import { CanalInfoPfbDto } from '../../canal-performance';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
})
export class PerformanceComponent implements OnInit {
  // Calendar import
  dates: Date[] = [];
  dateRangeFired = true;

  vehiculeBreakdown: Set<number> = new Set<number>();

  maxDate = moment();
  selected: { startDate: moment.Moment; endDate: moment.Moment };
  locale: LocaleConfig = {
    applyLabel: 'Appliquer',
    customRangeLabel: ' - ',
  };
  currentDate: number = 0;

  @ViewChild('rotation', { static: false }) rotation: RotationTypeComponent;
  @ViewChild('disponibleFlotte', { static: false })
  disponibleFlotte: ChartDisponibleFlotteComponent;
  @ViewChild('titreGraphique', { static: false }) titreGraphique: ChartTitreGraphiqueComponent;
  @ViewChild('chargeTransport', { static: false }) chargeTransport: ChargesTransportComponent;
  @ViewChild('consommationMoyenne', { static: false })
  consommationMoyenne: ConsommationMoyenneComponent;
  @ViewChild('arretPoi', { static: false }) arretPoi: ChartArretPoiEpsComponent;
  @ViewChild('vitesse', { static: false }) vitesse: ChartExcesVitesseComponent;

  //History
  now: Date = new Date();
  dateInterval: DateInterval = new DateInterval();

  //POle And Site
  selectedPassage: Passage = new Passage();
  selectedCanal: CanalInfoPfbDto = new CanalInfoPfbDto();
  selectedTransport: TransportTypeTravelReport =
    new TransportTypeTravelReport();
  typeSubMark: SubMarkDto = new SubMarkDto();
  filtretypeSubMark: SubMarkDto[] = [];
  selectedtypeSubMark: SubMarkDto = new SubMarkDto();
  travelReportsList: Observable<TravelReportDto[]>;
  travelReport_fil: TravelReportDto[] = [];

  notifications: Notification[] = [];
  notificationsLen = 0;

  Type_all: SubMarkDto = {
    mark: 'ALL',
    idTransportType: 0,
  };

  sybscriptionTool: Subscription;
  scheduleList: Schedule[] = [];
  scheduleList_fil: Schedule[] = [];
  matricule: Set<String> = new Set();
  vehiculesdisponible = 0;
  startDate: Date = new Date();

  availableLength = 0;
  productionLength = 0;
  ActiveLength = 0;
  availableInOtherSiteLength = 0;
  backToSiteLength = 0;
  breakDownLength = 0;
  unactiveLength = 0;
  vehiculesPlanifie = 0;

  expand: any[];
  sizeTable: number;
  s201: number = 0;
  s202: number = 0;
  s203: number = 0;
  s204: number = 0;
  s205: number = 0;
  s211: number = 0;
  s212: number = 0;

  NbObjectifRotation = 0;
  NbRealiseRotation = 0;
  AlldistanceDriven = 0;
  AlldistanceProductive = 0;
  SumChargeLocation = 0;
  SumTfu = 0;
  SumGasoil = 0;
  ArretMission = 0;
  ArretSite = 0;

  //Loading
  loading: boolean = false;

  canals: CanalInfoPfbDto[];
  VehiculeCanal = new Set();
  VehiculeMatriculeCanal = new Set();

  //Data
  vehiculesId: number[] = [];

  constructor(
    private toastr: ToastrService,
    public transportTypeService: TransportTypeService,
    private stopService: PathService,
    public passageService: PassageService,
    public canalPfbService: CanalPfbService,
    private pathService: PathService,
    private fuelingPriceSettingService: FuelingPriceSettingService
  ) {
    this.travelReportsList = this.pathService.getTravelReportList();

    this.sybscriptionTool = this.travelReportsList.subscribe((travelReport) => {
      this.sizeTable = travelReport.length;
      this.expand = new Array(travelReport.length).fill(false);
      var count = {};
      var sites = travelReport.map((x) => {
        return x.startAddress;
      });
      sites.forEach(function (i) {
        count[i] = (count[i] || 0) + 1;
      });
      var objToMap = new Map(Object.entries(count));
      this.s201 = objToMap.has('201') ? Number(objToMap.get('201')) : 0;
      this.s202 = objToMap.has('202') ? Number(objToMap.get('202')) : 0;
      this.s203 = objToMap.has('203') ? Number(objToMap.get('203')) : 0;
      this.s204 = objToMap.has('204') ? Number(objToMap.get('204')) : 0;
      this.s205 = objToMap.has('205') ? Number(objToMap.get('205')) : 0;
      this.s211 = objToMap.has('211') ? Number(objToMap.get('211')) : 0;
      this.s212 = objToMap.has('212') ? Number(objToMap.get('212')) : 0;
    });
  }

  ngOnInit() {
    this.loadLastFueulingPrice();
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.startDate.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);
    this.loadTransportTypes();
    //this.loadCanalPfb();
    this.generateDates();
  }

  // changeTheDay(index: number) {
  //   this.selected = null;
  //   this.currentDate = index;
  // }

  generateDates() {
    for (let i = 0; i < 3; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      this.dates.push(date);
    }
  }

  onChangeStartDate() {
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.startDate.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);
  }

  chooseTransport(transport: any) {
    this.selectedTransport = transport;
    this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
      return v.idVehicule;
    });
    this.filtretypeSubMark = this.transportTypeService.typeSubMark.filter(
      (item) => item.idTransportType == this.selectedTransport.idTransportType
    );
    this.filtretypeSubMark.push(this.Type_all);
    this.selectedtypeSubMark =
      this.filtretypeSubMark[this.filtretypeSubMark.length - 1];
  }

  chooseSchedule(canal: any) {
    this.selectedCanal = canal;
  }

  chooseSubMark(type: any) {
    this.selectedtypeSubMark = type;
    this.Generate_data_Inf_Ord();
    this.Generate_data_travel_report();
  }

  loadTransportTypes() {
    this.loading = true;
    if (this.transportTypeService.transportTypeTravelReport.length <= 0) {
      this.transportTypeService.getAllTransportType().subscribe(
        (transportTypes) => {
          this.transportTypeService.transportTypeTravelReport = transportTypes;
          if (transportTypes.length > 0) {
            this.transportTypeService.transportTypeTravelReport =
              transportTypes;
            this.selectedTransport = transportTypes[transportTypes.length - 1];
            this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
              return v.idVehicule;
            });
            for (let tp of transportTypes) {
              this.pathService.deviceStartDateMap.set(
                tp.idTransportType,
                this.dateInterval.startDate
              );
            }
            this.passagesData();
            this.getAlltypeSubMark();
          }
        },
        (err) => {
          this.toastr.error('Erreur lors de chargements', 'Type de transport');
        }
      );
    } else {
      this.selectedTransport =
        this.transportTypeService.transportTypeTravelReport[
          this.transportTypeService.transportTypeTravelReport.length - 1
        ];
      this.vehiculesId = this.selectedTransport.vehicules.map((v) => {
        return v.idVehicule;
      });
      this.passagesData();
      this.getAlltypeSubMark();
    }
    this.loading = false;
  }

  passagesData() {
    if (this.canalPfbService.canals.length > 0) {
      this.selectedCanal = this.canalPfbService.canals.find((p) =>
        p.name.startsWith('201')
      );

      this.getVehiculeByCanal();
      this.getInfOrd();
      this.loadTravelreport();
    } else {
      // this.loadAllPassage();
      this.loadCanalPfb();
    }
  }

  getAlltypeSubMark() {
    this.transportTypeService.findAllMark().subscribe((sub_marks) => {
      this.transportTypeService.typeSubMark = sub_marks;
      if (sub_marks.length > 0) {
        this.filtretypeSubMark = sub_marks.filter(
          (item) =>
            item.idTransportType == this.selectedTransport.idTransportType
        );
        this.filtretypeSubMark.push(this.Type_all);
        this.selectedtypeSubMark =
          this.filtretypeSubMark[this.filtretypeSubMark.length - 1];
      }
    });
  }

  loadAllPassage() {
    this.loading = true;
    this.passageService.findAllPassages().subscribe(
      (passages) => {
        this.passageService.passages = passages;
        if (this.passageService.passages.length > 0) {
          this.selectedPassage = this.passageService.passages.find((p) =>
            p.name.startsWith('201')
          );

          this.getVehiculeByCanal();
          this.getInfOrd();
          this.loadTravelreport();
        }
      },
      (error) => {
        this.toastr.error('Erreur lors de chargements', 'Passage');
        this.loading = false;
      }
    );
  }

  loadCanalPfb() {
    if (this.canalPfbService.canals.length <= 0) {
      this.loading = true;
      this.canalPfbService.findAllCanalPfb().subscribe(
        (canals) => {
          this.canalPfbService.canals = canals;
          this.selectedCanal = this.canalPfbService.canals.find((p) =>
            p.name.startsWith('201')
          );
          this.canals = canals;
          this.getVehiculeByCanal();
          this.getInfOrd();
          this.loadTravelreport();
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Canal');
        }
      );
    } else {
      this.canals = this.canalPfbService.canals;
      this.selectedCanal = this.canalPfbService.canals.find((p) =>
        p.name.startsWith('201')
      );
    }
  }

  getVehiculeByCanal() {
    this.VehiculeCanal.clear();
    this.selectedCanal.groups.forEach((c) => {
      c.vehicules.forEach((v) => {
        this.VehiculeCanal.add(v.matricule.trim());
      });
    });
  }

  getNotification(matricule: any) {
    if (localStorage.getItem('notifications')) {
      this.notifications = JSON.parse(localStorage.getItem('notifications') ?? '[]');
      this.notifications = this.notifications.filter(
        (nt) => nt.name == 'SPEED' || nt.name == 'SPEED_CARTO'
      );
    }
    this.notifications = this.notifications.filter((obj) =>
      matricule.has(obj.matricule)
    );
    this.notificationsLen = this.notifications.length;
  }

  changeContentStyle() {
    var x = document.getElementsByClassName(
      'content'
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      if (x[i].style.minHeight == '0px') x[i].style.minHeight = '250px';
      else x[i].style.minHeight = '0px';
    }
  }

  getInfOrd() {
    this.loading = true;
    this.stopService
      .scheduleByAllVehicule({
        startDate: this.dateInterval.startDate,
        endDate: this.dateInterval.endDate,
        vehiculesId: this.vehiculesId,
      })
      .subscribe(
        (data) => {
          this.scheduleList = data;
          this.Generate_data_Inf_Ord();

          this.loading = false;
          //this.loadTrackingInfo();
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  loadTravelreport() {
    this.loading = true;
    var transportTypeId: number[] = [];
    transportTypeId.push(this.selectedTransport.idTransportType);
    // var deviceIds = this.selectedTransport.vehicules.map(e => {return e.device.idDevice});
    var startDate = this.pathService.deviceStartDateMap.get(transportTypeId[0]);
    for (let i = 1; i < transportTypeId.length; i++) {
      if (
        startDate > this.pathService.deviceStartDateMap.get(transportTypeId[i])
      ) {
        startDate = this.pathService.deviceStartDateMap.get(transportTypeId[i]);
      }
    }
    this.pathService
      .generateDashboardSuivieVoyage(
        transportTypeId,
        this.selectedCanal.idCanal,
        {
          startDate: this.dateInterval.startDate,
          endDate: this.dateInterval.endDate,
        }
      )
      .subscribe(
        (travelReport) => {
          this.pathService.updateTravelReport2(
            travelReport,
            this.selectedTransport.vehicules,
            transportTypeId,
            startDate
          );
          this.Generate_data_travel_report();
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Vehicules');
        }
      );
  }

  Generate_data_travel_report() {
    this.travelReport_fil = [];
    if (this.sybscriptionTool) {
      this.sybscriptionTool.unsubscribe();
    }
    this.sybscriptionTool = this.travelReportsList.subscribe(
      (travel_Reports) => {
        // this.travelReport_fil = travel_Reports.filter(
        //   (item) => item.startAddress === this.selectedPassage.name
        // );
        this.travelReport_fil = travel_Reports.filter((item) => {
          return (
            this.VehiculeCanal.has(item.vehicule.matricule) &&
            !this.vehiculeBreakdown.has(item.deviceId)
          );
        });

        this.travelReport_fil = this.travelReport_fil;
        if (this.selectedtypeSubMark.idTransportType != 0)
          this.travelReport_fil = this.travelReport_fil.filter(
            (item) => item.vehicule.subMark === this.selectedtypeSubMark.mark
          );

        try {
          this.titreGraphique.TitreGraphique(
            this.travelReport_fil,
            this.vehiculesdisponible,
            this.vehiculesPlanifie
          );
        } catch (error) {}

        try {
          this.rotation.rotation_type(this.travelReport_fil);
        } catch (error) {}
        try {
          this.consommationMoyenne.consomation_moyenne(this.travelReport_fil);
        } catch (error) {}
        try {
          this.chargeTransport.ChargeTransport(this.travelReport_fil);
        } catch (error) {}
        //this.arretPoi.PoiChart(this.travelReport_fil);
        this.NbObjectifRotation = 0;
        this.NbRealiseRotation = 0;
        this.AlldistanceDriven = 0;
        this.AlldistanceProductive = 0;
        this.SumChargeLocation = 0;
        this.SumTfu = 0;
        this.SumGasoil = 0;
        this.ArretMission = 0;
        this.ArretSite = 0;
        let matricule: any[] = [];

        this.travelReport_fil.forEach((value) => {
          this.NbRealiseRotation += 1; // Ensure it's a number
          //this.NbObjectifRotation += value.objectifRotation | 0; // Ensure it's a number
          this.AlldistanceDriven += value.distanceDriven | 0; // Ensure it's a number
          this.SumTfu += value.fuelUsed | 0;
          this.SumGasoil += value.fuelPercentage | 0;
          this.ArretMission += value.stopDuration | 0;
          this.ArretSite += value.time_in_poi | 0;

          if (!matricule.includes(value.vehiculeId)) {
            matricule.push(value.vehiculeId);
            this.NbObjectifRotation += value.objectifRotation | 0;
            this.SumChargeLocation += value.vehicule.rental | 0; // Ensure it's a number
          }

          //this.SumChargeLocation += value.chargeLocation | 0; // Ensure it's a number

          if (value.missionType == 'Livraison')
            this.AlldistanceProductive += value.distanceDriven | 0;
        });
      }
    );

    this.SumGasoil = this.SumGasoil / this.travelReport_fil.length;
  }

  Generate_data_Inf_Ord() {
    if (this.selectedtypeSubMark.idTransportType != 0)
      this.scheduleList_fil = this.scheduleList.filter(
        (sl) => sl.submark == this.selectedtypeSubMark.mark
      );
    else this.scheduleList_fil = this.scheduleList;

    this.scheduleList_fil = this.scheduleList_fil.filter((sl) =>
      this.VehiculeCanal.has(sl.matricule.trim())
    );

    this.pathService.setSharedData(this.scheduleList_fil);

    this.disponibleFlotte.flotte_disponible(this.scheduleList_fil);
    this.matricule = new Set(
      this.scheduleList_fil.map((s) => {
        return s.matricule.trim();
      })
    );

    this.vitesse.getNotification(this.matricule);
    this.getNotification(this.matricule);

    this.matricule.forEach((mat) => {
      let schedules = this.scheduleList_fil.filter((g) => g.matricule == mat);
      if (schedules[schedules.length - 1].type == 'Stop') {
        this.availableLength++;
      } else if (schedules[schedules.length - 1].type == 'Between') {
        this.productionLength++;
      } else if (schedules[schedules.length - 1].type == 'Start') {
        this.ActiveLength++;
      } else if (schedules[schedules.length - 1].type == 'Other') {
        this.availableInOtherSiteLength++;
      } else if (schedules[schedules.length - 1].type == 'BreakDown') {
        this.breakDownLength++;
        this.vehiculeBreakdown.add(schedules[0].deviceId);
      } else {
        this.unactiveLength++;
      }
    });

    this.vehiculesdisponible = this.matricule.size - this.breakDownLength;
    this.vehiculesPlanifie = this.vehiculesdisponible - this.unactiveLength;
  }

  loadLastFueulingPrice() {
    this.loading = true;
    this.fuelingPriceSettingService.getLastFuelingPrice().subscribe(
      (fuelingPrice) => {
        this.pathService.fuelingPriceSetting = fuelingPrice;
      },
      (err) => {
        this.loading = false;
        this.toastr.error('Erreur lors du chargement', 'Prix Carburant');
      }
    );
  }

  formatNumberToK(value: number): string {
    const suffixes = ['', 'k', 'M', 'B', 'T']; // You can extend this array for larger numbers if needed
    let suffixIndex = 0;

    while (value >= 1000 && suffixIndex < suffixes.length - 1) {
      value /= 1000;
      suffixIndex++;
    }

    return value.toFixed(3).replace(/\.?0+$/, '') + ' ' + suffixes[suffixIndex];
  }

  LoadData() {
    this.loading = true;
    this.vehiculesdisponible = 0;
    this.availableLength = 0;
    this.productionLength = 0;
    this.ActiveLength = 0;
    this.availableInOtherSiteLength = 0;
    this.backToSiteLength = 0;
    this.breakDownLength = 0;
    this.unactiveLength = 0;
    this.vehiculesPlanifie = 0;
    this.NbObjectifRotation = 0;
    this.NbRealiseRotation = 0;
    this.AlldistanceDriven = 0;
    this.AlldistanceProductive = 0;
    this.SumChargeLocation = 0;
    this.SumTfu = 0;
    this.SumGasoil = 0;
    this.ArretMission = 0;
    this.ArretSite = 0;
    this.travelReport_fil = [];
    try {
      this.titreGraphique.TitreGraphique([], [], []);
    } catch (error) {}

    try {
      this.rotation.rotation_type([]);
    } catch (error) {}
    try {
      this.consommationMoyenne.consomation_moyenne([]);
    } catch (error) {}
    try {
      this.chargeTransport.ChargeTransport([]);
    } catch (error) {}
    this.getVehiculeByCanal();
    this.getInfOrd();
    this.loadTravelreport();
    this.loading = false;
  }

  choosedDate() {
    if (this.dateRangeFired) {
      this.dateRangeFired = false;
      let dateStart =
        this.selected && this.selected.startDate
          ? this.selected.startDate.toDate()
          : null;
      let dateEnd =
        this.selected && this.selected.endDate
          ? this.selected.endDate.toDate()
          : null;
      if (dateStart !== null && dateEnd !== null) {
        this.currentDate = -1;
      }
      this.dateRangeFired = true;
      return;
    }
    this.dateRangeFired = true;
  }
}
