import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeoMarketingFormComponent } from './geo-marketing-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { GeoMarketingService } from '../geo-marketing.service'; // Adjust the path as necessary
import { DataManagementService } from 'src/app/client-management/data-management'; // Adjust the path as necessary
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    GeoMarketingFormComponent // Declare the GeoMarketingFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule, // Include FormsModule for template-driven forms
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
  ],
  providers: [
    GeoMarketingService, // Provide the GeoMarketingService
    DataManagementService // Provide the DataManagementService
  ],
  exports: [
    GeoMarketingFormComponent // Export the component if it needs to be used in other modules
  ]
})
export class GeoMarketingFormModule { }
