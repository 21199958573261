import { GroupDto } from '../data-management';

export class CanalDto {
  idCanal: number;
  name: string;
  groups: number[];
  constructor() {}
}

export class CanalInfoDto {
  idCanal: number;
  name: string;
  groups: GroupDto[];
  passage: PassagePfbDto;
  constructor() {
    this.groups = [];
  }
}

export class CanalPfbDto {
  idCanal: number;
  name: string;
  groups: number[];
  passage: number;
  constructor() {}
}

export class CanalInfoPfbDto {
  idCanal: number;
  name: string;
  groups: GroupDto[];
  passage: PassagePfbDto;
  constructor(idCanal?: number, name?: string) {
    this.groups = [];

    this.idCanal = idCanal !== undefined ? idCanal : 0;
    this.name = name !== undefined ? name : '';
  }
}

export class PassagePfbDto {
  idPassage: number;
  name: string;
  numberOfPassage: number;
}

// vehicle-supervisor.dto.ts

export class Vehicule {
  idVehicule: number;
  matricule: string;
  mark: string;
  idDevice: number;
}

export interface Superviseur {
  idDriver: number;
  code: string | null;
  permitNumber: string | null;
  firstName: string;
  lastName: string;
  telephone: string;
  completeName: string;
}

export interface SupervisorVehicleDTO {
  vehicules: Vehicule[];
  superviseur: Superviseur;
}
