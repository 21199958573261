<ngx-loading [show]="globalLoading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="content" style="overflow-y: scroll;">
    <div class="table-responsive  mt-2">
    <table class="table table-striped shadowBox">
        <thead class="tableFixHead">
        <tr>
            <th width="10%">Photo</th>
            <th width="10%">Prénom</th>
            <th width="10%">Nom</th>
            <th width="10%">Fonction</th>
            <th width="10%">N° permis</th>
            <th width="10%">Téléphone</th>
            <th width="10%">CIN</th>
            <th width="6%">G.sanguin</th>
            <th width="24%">E-mails</th>
            <th width="20%">
                <div class="input-group input-group-sm">
                    <input type="text" name="table_search" class="form-control pull-left"
                           placeholder="Prénom, Nom, N° permis, tel..." name="search" [(ngModel)]="keyword"
                           (keyup.enter)="searchDriver()">

                    <div class="input-group-btn">
                        <button type="submit" class="btn btn-default" (click)="searchDriver()"><i
                                class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </th>
        </tr>
        </thead>
        <tbody>

        <tr *ngIf="loading">
            <td style="text-align: center" colspan="13"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></td>
        </tr>

        <ng-container *ngIf="!loading">
            <tr *ngFor="let driver of drivers">
                <td width="10%">
                    <span *ngIf="driver.avatarUri">
                        <img src="{{driver?.avatarUri}}" width="40px" class="img-circle">
                    </span>
                    <span *ngIf="!driver.avatarUri">
                        <ngx-avatar class="img-circle" size="40" [name]="dataManagementService.getDriverName(driver)"></ngx-avatar>
                    </span>
                </td>
                <td width="10%">{{driver.firstName}}</td>
                <td width="10%">{{driver.lastName}}</td>
                <td width="10%">{{driver.driverFunction}}</td>
                <td width="10%">{{driver.permitNumber}}</td>
                <td width="10%">{{driver.telephone}}</td>
                <td width="10%">{{driver.cin}}</td>
                <td width="6%">{{driver.bloodGroup}}</td>
                <td width="24%">{{driver.email}}</td>
                <td width="20%">
                    <button tooltip="Delete" (click)="confirmDeleteDriver(driver)"
                            class="btn ink-reaction btn-danger pull-right">
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                    <a class="pull-right"
                       [routerLink]="['/client/dataManagement/driver/form/',driver.idDriver,'UPDATE']"
                       routerLinkActive="active">
                        <button tooltip="Modifier" class="btn btn-warning">
                            <i class="fa fa-cog" aria-hidden="true"></i>
                        </button>
                    </a>
                    <a class="pull-right" [routerLink]="['/client/dataManagement/driver/form/',driver.idDriver,'SHOW']"
                       routerLinkActive="active">
                        <button tooltip="Details" class="btn ink-reaction btn-success">
                            <i class="fa fa-eye" aria-hidden="true"></i>
                        </button>
                    </a>
                </td>
            </tr>
        </ng-container>

        </tbody>
    </table>
</div>
</div>
<div class="row">
    <div class="col-md-12" *ngIf="drivers.length >0" style="text-align: center">
        <pagination class="pagination-sm"
                    [totalItems]="bigTotalItems"
                    [(ngModel)]="bigCurrentPage"
                    [maxSize]="maxSize"
                    [boundaryLinks]="true"
                    [rotate]="false"
                    [itemsPerPage]="itemsPerPage"
                    (pageChanged)="pageChanged($event)"
                    previousText="Précédent"
                    nextText="Suivant"
                    firstText="Premier"
                    lastText="Dernier">
        </pagination>
    </div>
</div>


<!-- =================================== CONFIRM MODAL (START)====================================-->

<div class="modal fade" tabindex="-1"  bsModal #confirmDeleteModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="confirmDeleteModal.hide()">&times;
                </button>

                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Es vous sur ?
                </h4>

            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white"><i class="fa fa-exclamation-triangle"></i> Attention !
                    </h4>
                    Êtes-vous sûr de supprimer ce conducteur '{{dataManagementService.getDriverName(currentDriver)}}', ça
                    risque de perdre tous historique de ce conducteur !
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="confirmDeleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="deleteDriver()">
                    <i class="fa fa-check" aria-hidden="true"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =================================== CONFIRM MODAL (START)====================================-->
