<div
  class="container"
  style="font-family: 'Inconsolata', monospace; font-size: 14px"
>
  <div class="box box-info">
    <div class="box-header with-border">
      <h3
        *ngIf="!modeAuto"
        style="font-family: 'Josefin Sans', sans-serif"
        class="box-title"
      >
        <i class="fa fa-file-excel-o"></i> Générateur des rapports
      </h3>
      <h3
        *ngIf="modeAuto"
        style="font-family: 'Josefin Sans', sans-serif"
        class="box-title"
      >
        <i class="fa fa-file-excel-o"></i> Envoie automatique de rapports
      </h3>
    </div>

    <form class="form-horizontal">
      <div class="box-body">
        <div class="form-group">
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Type de rapport</label
          >
          <div class="col-sm-10">
            <select
              class="form-control"
              (change)="selectImportOrExport($event.target.value)"
            >
              <option style="font-size: 14px" [value]="'export'">
                Exporter
              </option>
              <option
                *ngIf="!modeAuto"
                style="font-size: 14px"
                [value]="'import'"
              >
                Importer
              </option>
              <option style="font-size: 14px" [value]="'list'">List</option>
            </select>
          </div>
        </div>

        <!--select report-->
        <div class="form-group">
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Choix de rapport</label
          >
          <div class="col-sm-10">
            <select
              class="form-control"
              [(ngModel)]="repport.type"
              (change)="onSelectingReport()"
              name="reportType"
            >
              <option
                style="font-size: 14px"
                *ngFor="let rapport of rapportsItems"
                [value]="rapport.name"
              >
                {{ rapport.label }}
              </option>
            </select>
          </div>
        </div>

        <!--if report neads to choose start and end date-->
        <div class="row" *ngIf="starEnd && isExporting && !modeAuto">
          <div class="form-group" class="col-md-6">
            <label class="col-sm-2 control-label" style="color: limegreen"
              >Du</label
            >
            <div class="col-sm-10">
              <input
                *ngIf="isDateOnly"
                type="text"
                class="form-control input-sm"
                bsDatepicker
                name="startDate"
                [(ngModel)]="repport.dateInterval.startDate"
                [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY',
                  containerClass: 'theme-dark-blue'
                }"
                [maxDate]="maxDate"
                placement="bottom"
                autocomplete="off"
              />

              <input
                *ngIf="!isDateOnly"
                type="text"
                class="form-control input-sm"
                bsDatepicker
                name="startDate"
                [(ngModel)]="repport.dateInterval.startDate"
                [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY, HH:mm',
                  containerClass: 'theme-dark-blue'
                }"
                [maxDate]="maxDate"
                placement="bottom"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group" class="col-md-6">
            <label class="col-sm-2 control-label" style="color: limegreen"
              >Au</label
            >
            <div class="col-sm-10">
              <input
                *ngIf="isDateOnly"
                type="text"
                class="form-control input-sm"
                bsDatepicker
                name="endDate"
                [(ngModel)]="repport.dateInterval.endDate"
                [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY',
                  containerClass: 'theme-dark-blue'
                }"
                [minDate]="repport.dateInterval.startDate"
                placement="bottom"
                autocomplete="off"
              />

              <input
                *ngIf="!isDateOnly"
                type="text"
                class="form-control input-sm"
                bsDatepicker
                name="endDate"
                [(ngModel)]="repport.dateInterval.endDate"
                [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY, HH:mm',
                  containerClass: 'theme-dark-blue'
                }"
                [minDate]="repport.dateInterval.startDate"
                placement="bottom"
                autocomplete="off"
                (ngModelChange)="setTimeTo2359()"
              />
            </div>
          </div>
        </div>
        <!-- choose report option (all vehicule ,by group or by vehicule)-->
        <div
          class="form-group"
          *ngIf="reportOf && isExporting && !repport.rapportPayloadDto.global"
        >
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Rapport de
          </label>
          <div class="col-sm-10">
            <select
              class="form-control"
              [(ngModel)]="Selectedoption"
              (change)="onSelectingReportOf()"
              name="selectedoption"
            >
              <option
                style="font-size: 14px"
                *ngFor="let opt of selectReportOf"
                [value]="opt.name"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
        <!--display al groups if user has choose it-->
        <div
          class="form-group"
          *ngIf="
            reportOf && this.repport.rapportPayloadDto.byGroups && isExporting
          "
        >
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Groupes</label
          >
          <div class="col-sm-10">
            <ng-select
              *ngIf="groupsItems.length != 0 && multipleSelect"
              [items]="groupsItems"
              [(ngModel)]="selectedGroupsItems"
              [multiple]="true"
              bindLabel="text"
              bindValue="id"
              (data)="selectedGroups($event)"
              [clearable]="true"
              placeholder="aucun groupe selectionné"
            >
            </ng-select>
            <select
              *ngIf="!multipleSelect"
              class="form-control"
              [(ngModel)]="repport.rapportPayloadDto.idGroup"
              name="groups"
            >
              <option
                style="font-size: 14px"
                *ngFor="let g of groups"
                [ngValue]="g.idGroupe"
              >
                <span *ngIf="g.nameGroupParent"
                  >{{
                    g.nameGroupParent | capitalizefirst | truncate : [30]
                  }}
                  / </span
                >{{ g.nom }}
              </option>
            </select>
          </div>
        </div>

        <!-- display all vehicules of user choose this option-->
        <div
          class="form-group"
          *ngIf="
            reportOf &&
            this.repport.rapportPayloadDto.byVehicules &&
            isExporting
          "
        >
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Véhicules</label
          >
          <div class="col-sm-10">
            <ng-select
              *ngIf="vehiculesItems.length != 0 && multipleSelect"
              [multiple]="true"
              [items]="vehiculesItems"
              bindLabel="text"
              bindValue="id"
              placeholder="aucun vehicule selectionné"
              [(ngModel)]="vehiculesSelected"
              [clearable]="true"
              (data)="selectedVehicules($event)"
            >
            </ng-select>

            <select
              *ngIf="!multipleSelect"
              class="form-control"
              [(ngModel)]="repport.rapportPayloadDto.idVehicule"
              name="vehicules"
            >
              <option
                style="font-size: 14px"
                *ngFor="let v of vehicules"
                [ngValue]="v.idVehicule"
              >
                {{ v.mark != null && v.mark != "" ? v.mark : v.matricule }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isPole && isExporting">
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Site</label
          >
          <div class="col-sm-10">
            <select
              class="form-control"
              [(ngModel)]="selectedCanal"
              name="selectedCanal"
              (change)="selectCanal()"
            >
              <option style="font-size: 14px" [ngValue]="tousCanal">
                {{ tousCanal.name }}
              </option>
              <option
                style="font-size: 14px"
                *ngFor="let canal of canalPfbService.canals"
                [ngValue]="canal"
              >
                {{ canal.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isPole && isExporting">
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Pôle</label
          >
          <div class="col-sm-10">
            <select
              class="form-control"
              [(ngModel)]="selectedTransport"
              name="selectedTransport"
              (change)="selectPole()"
            >
              <option style="font-size: 14px" [ngValue]="tousTransport">
                {{ tousTransport.name }}
              </option>
              <option
                style="font-size: 14px"
                *ngFor="let transport of transportTypeService.transportTypes"
                [ngValue]="transport"
              >
                {{ transport.name }}
              </option>
            </select>
          </div>
        </div>

        <div
          class="form-group"
          *ngIf="isExporting && isByYear && !modeAuto && !starEnd"
        >
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Année</label
          >
          <div class="col-sm-10">
            <select
              class="form-control"
              [(ngModel)]="selectedYear"
              name="selectedYear"
            >
              <option
                style="font-size: 14px"
                *ngFor="let year of years"
                [ngValue]="year.id"
              >
                {{ year.id }}
              </option>
            </select>
          </div>
        </div>

        <div
          class="form-group"
          *ngIf="(isByPOI && isExporting) || (isByPoint && isExporting)"
        >
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Adresse POI</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="10"
              class="form-control"
              [(ngModel)]="repport.rapportPayloadDto.poiAdresses"
              name="filteredPoi"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of filteredPoi"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByPOIClient && isExporting">
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Client Détail</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="7"
              class="form-control"
              [(ngModel)]="poiClientSelected"
              name="pClient"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of poiClients"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByPOIClient && isExporting">
          <label class="col-sm-2 control-label" style="color: orange"
            >Client Gros</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="7"
              class="form-control"
              [(ngModel)]="clientGrosSelected"
              name="clientGros"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of poiClientsGros"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByPOIClient && isExporting">
          <label class="col-sm-2 control-label" style="color: blue"
            >Prospect</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="7"
              class="form-control"
              [(ngModel)]="prospectSelected"
              name="prospects"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of poiProspects"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByPOIClient && isExporting">
          <label class="col-sm-2 control-label" style="color: #bbbb09"
            >Dépôt</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="7"
              class="form-control"
              [(ngModel)]="depotSelected"
              name="depot"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of poiDepots"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByPOIClient && isExporting">
          <label class="col-sm-2 control-label" style="color: #964b00"
            >Intermédiaire</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="7"
              class="form-control"
              [(ngModel)]="intermediaireSelected"
              name="intermediaire"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of poiIntermediare"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByPOIClient && isExporting">
          <label class="col-sm-2 control-label" style="color: #7f00ff"
            >Concessionnaire</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="7"
              class="form-control"
              [(ngModel)]="concessionnaireSelected"
              name="concessionnaire"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of poiConcessionnaires"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByPOIClient && isExporting">
          <label class="col-sm-2 control-label" style="color: red"
            >Concurrent</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="7"
              class="form-control"
              [(ngModel)]="concurrentSelected"
              name="concurrent"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of poiConcurrents"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByPOIClient && isExporting">
          <label class="col-sm-2 control-label" style="color: black"
            >Parking</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="7"
              class="form-control"
              [(ngModel)]="driverSelected"
              name="poiVehicule"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of poiDrivers"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="isByEndPOI && isExporting">
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Adresse POI Arrivée</label
          >
          <div class="col-sm-10">
            <select
              *ngIf="multipleSelect"
              size="10"
              class="form-control"
              [(ngModel)]="repport.rapportPayloadDto.endPoiAdresses"
              name="filteredEndPoi"
              multiple
            >
              <option
                style="font-size: 14px"
                *ngFor="let p of filteredPoi"
                [ngValue]="p.name"
              >
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>

        <div
          class="form-group"
          *ngIf="(isByPOI || isByNonPOi || isByPOIClient) && isExporting"
        >
          <label class="col-sm-2 control-label" style="color: limegreen"
            >Durée(min) :</label
          >
          <div class="col-sm-2">
            <input
              class="form-control"
              [(ngModel)]="repport.duration"
              name="duration"
            />
          </div>
        </div>

        <div class="row" *ngIf="isExporting && retard && !isByPOI">
          <div class="form-group col-md-4">
            <label class="col-sm-5 control-label" style="color: limegreen"
              >Durée(min) :</label
            >
            <div class="col-sm-3">
              <input
                class="form-control"
                [(ngModel)]="repport.duration"
                name="duration"
              />
            </div>
          </div>

          <div class="form-group col-md-4">
            <label class="col-sm-5 control-label" style="color: limegreen"
              >Début travail :</label
            >
            <div class="col-sm-3">
              <input
                type="time"
                name="hours"
                [(ngModel)]="repport.rapportPayloadDto.hours"
                placeholder="HH:mm:ss"
              />
            </div>
          </div>
          <div class="form-group col-md-4">
            <label class="col-sm-5 control-label" style="color: limegreen"
              >Fin travail :</label
            >
            <div class="col-sm-3">
              <input
                type="time"
                name="endWork"
                [(ngModel)]="repport.endWork"
                placeholder="HH:mm:ss"
              />
            </div>
          </div>
        </div>

        <div *ngIf="isExporting && modeAuto">
          <div class="form-group">
            <label class="col-sm-2 control-label" style="color: limegreen"
              >Titre
            </label>
            <div class="col-sm-10">
              <input
                [(ngModel)]="currentReportAuto.title"
                type="text"
                name="title"
                placeholder="Titre"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label" style="color: limegreen"
              >Email
            </label>
            <div class="col-sm-4">
              <input
                [(ngModel)]="currentReportAuto.emails"
                type="email"
                name="emails"
                placeholder="Emails"
                class="form-control"
              />
            </div>
            <label class="col-sm-2 control-label" style="color: limegreen"
              >Période
            </label>
            <div class="col-sm-4">
              <select
                class="form-control input-sm"
                [(ngModel)]="currentReportAuto.period"
                (ngModelChange)="choseePeriode($event)"
                name="Période"
              >
                <option value="JOURNALIER" selected>JOURNALIER</option>
                <option value="HEBDOMADAIRE">HEBDOMADAIRE</option>
                <option value="MENSUEL">MENSUEL</option>
              </select>
            </div>
          </div>
          <button class="btn btn-success pull-right" (click)="saveAutoReport()">
            Energister
            <ngx-loading
              *ngIf="loader"
              [show]="loading"
              [config]="{ fullScreenBackdrop: true }"
            >
            </ngx-loading>
          </button>
          <button
            class="btn btn-success pull-right"
            (click)="
              loadAllReports(); mode = 'SHOW'; init(); loadReportModal.show()
            "
          >
            Liste
          </button>
        </div>

        <!-- export button-->
        <div class="row">
          <div
            *ngIf="isExporting && !modeAuto"
            class="box-footer col-sm-6 pull-right"
          >
            <button
              class="btn btn-success pull-right"
              (click)="onDownoaldingReport()"
              [disabled]="loading == false"
            >
              <ngx-loading
                *ngIf="loader"
                [show]="loading"
                [config]="{ fullScreenBackdrop: true }"
              >
              </ngx-loading
              >Exporter
            </button>
          </div>
          <!-- Auto button-->
          <div *ngIf="isExporting" class="box-footer col-sm-6 pull-left">
            <button
              [ngClass]="{
                'btn btn-danger': modeAuto,
                'btn btn-success': !modeAuto
              }"
              (click)="changeModeAuto()"
            >
              {{ modeAuto ? "Manuel" : "Auto" }}
            </button>
          </div>
        </div>
        <!-- if operation is related to importing templates-->
        <div *ngIf="!isExporting">
          <div class="form-group">
            <label
              *ngIf="withProvider"
              class="col-sm-2 control-label"
              style="color: limegreen"
              >Fournisseur</label
            >
            <div></div>
            <!--choose provider-->
            <div class="col-sm-3" *ngIf="withProvider === true">
              <select
                #provider
                class="form-control"
                (change)="onSelectingProvider(provider.value)"
              >
                <option
                  style="font-size: 14px"
                  *ngFor="let provider of providers"
                  [value]="provider.name"
                  [selected]="providers[0].name"
                >
                  {{ provider.label }}
                </option>
              </select>
            </div>
            <!--if no provider was choosen ,allow user to downoald standard tempaltes-->
            <div *ngIf="isdismissed && withProvider === true">
              <a
                class="col-lg-2 pull left"
                href="{{
                  templateDownoaldLocation
                }}TemplateExcel/CarburantTemplate.xlsx"
                download
              >
                <button type="button" (click)="dismiss()" class="btn btn-light">
                  Telecharger Template
                </button>
              </a>
            </div>

            <div *ngIf="!withProvider">
              <span class="col-lg-2"></span>
              <a
                class="col-lg-2 pull left"
                href="{{ templateDownoaldLocation }}TemplateExcel/{{
                  selectedTemplateUpload
                }}.xlsx"
                download
              >
                <button type="button" (click)="dismiss()" class="btn btn-light">
                  Telecharger Template
                </button>
              </a>
            </div>
            <!-- choose the source file -->
            <div *ngIf="choosen" class="col-sm-3">
              <input
                style="display: none"
                type="file"
                name="file"
                (change)="onFileChanged($event.target.files)"
                accept=".xlsx, .xls"
                #fileInput
              />
              <button (click)="fileInput.click()" class="btn btn-light">
                Choisir un fichier
              </button>
              <button
                (click)="onValidUploadedFile()"
                class="btn btn-success"
                [disabled]="!selectedFile && loading == true"
              >
                <ngx-loading
                  *ngIf="loader"
                  [show]="loading"
                  [config]="{
                    backdropBorderRadius: '14px',
                    fullScreenBackdrop: true
                  }"
                ></ngx-loading>
                Importer
              </button>
            </div>
            <p></p>
            <!--display all selected files-->
            <div *ngIf="selectedFile != null" class="col-lg-2">
              {{ selectedFile.name }}
            </div>
            <p></p>
          </div>
          <div class="col-lg-1"></div>
          <div *ngIf="showWarning" class="col-lg-10">
            <div
              class="alert-warning rounded"
              style="
                padding-left: 10px;
                padding-top: 1px;
                padding-bottom: 10px;
                border-radius: 3px !important;
              "
            >
              <h3><i class="fa fa-warning fa_custom"></i> Info !</h3>
              <span
                style="
                  font-family: Consolas, monaco, monospace;
                  font-size: medium;
                "
              >
                {{ modalBody }}</span
              >
            </div>
            <div class="box-footer">
              <p class="pull-right">
                <button class="btn btn-success" (click)="onUpload()">
                  <ngx-loading
                    *ngIf="loader"
                    [show]="loading"
                    [config]="{
                      backdropBorderRadius: '0px',
                      fullScreenBackdrop: true
                    }"
                  >
                  </ngx-loading>
                  Valider
                </button>
                <button class="btn btn-warning" (click)="concel()">
                  Annuler
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- ========================================== ADD/Edit Modal (START) ====================================================-->
<div
  class="modal fade"
  bsModal
  #loadReportModal="bs-modal"
  [config]="{ backdrop: 'static', keyboard: false }"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
  role="dialog"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="loadReportModal.hide()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="box-body">
          <app-report-sent [reportAutos]="reportAutos"> </app-report-sent>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          (click)="loadReportModal.hide()"
        >
          Fermer
        </button>
      </div>
    </div>
  </div>
</div>
<!-- ========================================== ADD/Edit Modal (END) ====================================================-->
