<form *ngIf="mode === 'ADD' || mode === 'UPDATE'" #poiForm="ngForm" style="font-family: 'Inconsolata', monospace;font-size: 14px;">

  <div class="form-group">
      <label>Nom</label>
      <input type="text" class="form-control" placeholder="Nom ..." [(ngModel)]="pointInterest.name"
             name="name" required>
  </div>

  <div class="form-group">
      <label>Adresse</label>
      <textarea class="form-control" rows="3" placeholder="Adresse ..."
                [(ngModel)]="pointInterest.address" name="address" rows=5></textarea>
  </div>

  <div class="form-group">
      <label>Rayon (m)</label>
      <input type="number" class="form-control" placeholder="" value="10"
             [(ngModel)]="pointInterest.ray" name="ray">
  </div>

  <div class="form-group" *ngIf="pointInterest.coordinate">
      <label>[Lat,Lng] = [{{pointInterest?.coordinate?.lat | number:'2.2-6'}},{{pointInterest?.coordinate?.lng | number:'2.2-6'}}]</label>
  </div>
<div class="row">
  <div class="col-md-3">
  <div class="form-group" *ngIf="(pointInterest.type == 0 || pointInterest.type == 'MARKER') || (pointInterest.type == 1 || pointInterest.type == 'POLYGON')">
      <div class="dropdown">
          <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1"
                  data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="true" style="border-radius: 0px;">
              <img src="{{selectedImage.uri}}" width="17px"/> {{selectedImage.text}}
              <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" style="border-radius: 0px;">
              <li *ngFor="let image of images" (click)="selectedImage = image;">
                  <a>
                      <img src="{{image.uri}}" width="17px"/> {{image.text}}
                  </a>
              </li>
          </ul>
      </div>
  </div>
</div>
  <div class="col-md-3">
  <div class="form-group" *ngIf="(pointInterest.type == 1 || pointInterest.type == 'POLYGON')">
      <div class="dropdown">
          <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu2"
                  data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="true" style="border-radius: 0px;">
                  {{selectedColor.text}}
              <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" style="border-radius: 0px;">
              <li *ngFor="let color of colors" (click)="selectedColor = color;">
                  <a>
                      {{color.text}}
                  </a>
              </li>
          </ul>
      </div>
  </div>
</div>
</div>
  
  <button *ngIf="mode === 'ADD'" type="submit" [disabled]="!poiForm.form.valid" class="btn btn-primary btn-block btn-flat" (click)="addPointInterest()">
      <i class="fa fa-plus" aria-hidden="true"></i> Ajouter
  </button>

  <button *ngIf="mode === 'UPDATE'" type="submit" [disabled]="!poiForm.form.valid" class="btn btn-primary btn-block btn-flat" (click)="updatePointInterest()">
          <ngx-loading  [show]="loader" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading> Modifier
  </button>

</form>