import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopsToolComponent } from './stops.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RealtimeProcessingService } from 'src/app/client-management/realtime/services/real-time-processing.service';
import { RealtimeService } from 'src/app/client-management/realtime/services/realtime.service';
import { StpsService } from 'src/app/client-management/realtime/services/stops.service';
import { ToolKmJourService } from 'src/app/client-management/realtime/services/tool-km-jour.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { StopsParentsModule } from './stops-parents/stops-parents.module';
import { StopsChildsModule } from './stops-childs/stops-childs.module';
import { AddStopPoiModule } from './add-stop-poi/add-stop-poi.module';
import { ProgressCustomBarModule } from 'src/app/shared/progress-bar/progress-custom-bar.module';

// Import shared modules

@NgModule({
  declarations: [
    StopsToolComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StopsParentsModule,
    StopsChildsModule,
    AddStopPoiModule,
    ProgressCustomBarModule
  ],
  providers: [
    RealtimeProcessingService,
    RealtimeService,
    StpsService,
    ToolKmJourService,
    MapService
  ],
  exports: [
    StopsToolComponent,
  ]
})
export class StopsModule { }
