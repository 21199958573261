import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if you use forms in the component
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PoiComponent } from './poi.component';
import { GeocodingService } from '../../../utils/leaflet/service/geocoding.service';
import { MapService } from '../../../utils/leaflet/service/map.service';
import { DataManagementService } from '../data-management.service';
import { ImportExportService } from '../../import-export/import-export.service';
import { ImportExportFormsComponent } from '../../parc-management/import-export-forms/import-export-forms.component';
import { PoiFormComponent } from './poi-form/poi-form.component';
import { PoiFormModule } from './poi-form/poi-form.module';
import { ImportExportFormsModule } from '../../parc-management/import-export-forms/import-export-forms.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    PoiComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PoiFormModule,
    ModalModule.forRoot(), 
    ImportExportFormsModule,
    PaginationModule.forRoot(),
    LeafletModule,
    TruncateModule
  ],
  providers: [
    BsModalService,  // Include providers if needed
    ToastrService,
    GeocodingService,
    MapService,
    DataManagementService,
    ImportExportService
  ],
  exports: [
    PoiComponent
  ]
})
export class PoiModule { }