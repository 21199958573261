import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataManagementComponent } from './data-management.component';
import { RouterModule } from '@angular/router'; // Import RouterModule for routing if necessary
import { VehiculeService } from './providers/vehicule.service'; // Import your service
import { SigninService } from 'src/app/authentification/signin/signin.service'; // Import SigninService if needed
import { VehiculeModule } from './vehicule/vehicule.module';

@NgModule({
  declarations: [
    DataManagementComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    DataManagementComponent // Export the component for use in other modules
  ],
  providers: [
    VehiculeService, // Register your service
    SigninService // Register SigninService if used in this module
  ]
})
export class DataManagementModule { }
