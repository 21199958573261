import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickedElement } from './directives/clickedelement.directive';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressCustomBarComponent } from 'src/app/shared/progress-bar/progress-custom-bar.component';
import { RouterModule } from '@angular/router';
import { EnCoursComponent } from './components/control-options/tool-bar/en-cours/en-cours.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { DashboardModule } from '../statistical/dashboard/dashboard.module';
import { RealtimeComponent } from './realtime.component';
import { FormatDurationPipesModule } from 'src/app/pipes/forma-duration.module';
import { FormatFullDurationModule } from 'src/app/pipes/format-full-duration.module';
import { DurationPipesModule } from 'src/app/pipes/duration-sec.module';
import { ProgressRangeBarModule } from 'src/app/shared/progress-range-bar/progress-range-bar.module';




@NgModule({
  imports: [
    CommonModule,
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
    RouterModule, // Add RouterModule to imports
    DashboardModule, // Import the module that declares app-dashboard
    LeafletModule,
    FormatDurationPipesModule,
    FormatFullDurationModule,
    DurationPipesModule,
    ProgressRangeBarModule
  ],
  declarations: [
    RealtimeComponent,
    ClickedElement,
    EnCoursComponent,
    
  ],
  exports: [
    ClickedElement,
    EnCoursComponent,
    ],
})
export class RealTimeModule {}
