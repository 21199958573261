import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AlertManagementService } from '../../alert-management.service';
import { Alert, AlertConfiguration } from '../../alert';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Subscription } from 'rxjs';
import { AlertManagementServiceUtils } from '../../alert-management-utils.service';

interface IHour {
  value: number;
  label: string;
}

@Component({
  selector: 'app-appro-fuel-settings',
  templateUrl: './appro-fuel-settings.component.html',
  styleUrls: ['./appro-fuel-settings.component.css']
})
export class ApproFuelSettingsComponent implements OnInit {


  public checked = true;
  public showAdvancedSettings = false;
  public loader = false;

  public hours: IHour[] = []; // Ajoutez le type ici
  public minutes: { value: number; label: string }[] = []

  public alert: Alert = new Alert();

  public speedAlertConfiguration: AlertConfiguration = new AlertConfiguration();
  public cronAlertConfiguration: AlertConfiguration = new AlertConfiguration();

  public isResultLoaded = true;

  public getVehiculeWasSelectedSubscription: Subscription;
  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  constructor(
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService,
    private dataManagementService: DataManagementService,
    private alertManagementServiceUtils: AlertManagementServiceUtils,
  ) {
    this.initHours();
    this.initMinutes();
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(this.dataManagementService.selectedVehicule.device.idDevice);
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe((vehicule) => {
        this.loadAlertConfigOfDevice(this.dataManagementService.selectedVehicule!.device.idDevice);
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService.getAlertByNameAndDeviceId('APPRO_FUEL', device).subscribe(
      (alert) => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration chargée', 'info !', {
          timeOut: 1000
        });
      },
      () => {
        this.loader = false;
        this.toastr.error('Configuration non chargée', 'info !', {
          timeOut: 1000
        });
      }
    );
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  initHours() {
    for (let i = 0; i <= 24; i++) {
      this.hours.push({ value: i, label: i > 9 ? i.toString() : '0' + i });
    }
  }

  initMinutes() {
    for (let i = 0; i < 4; i++) {
      this.minutes.push({
        value: i,
        label: i > 0 ? (i * 15).toString() : '0' + i
      });
    }
  }
  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrer', 'info !');
      },
      error => {
        this.toastr.error('Configuration non enregistrée', 'info !');
      }
    );
  }



  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean, enableGlobalConfigToAllVehicules: boolean }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }

  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails, id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }


}
