// spider-chart.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpiderChartComponent } from './spider-chart.component';
import { HighchartsChartModule } from 'highcharts-angular'; // Import Highcharts Angular module

@NgModule({
  declarations: [
    SpiderChartComponent
  ],
  imports: [
    CommonModule,
    HighchartsChartModule // Import Highcharts module for using Highcharts in your component
  ],
  exports: [
    SpiderChartComponent // Exporting the component for use in other modules
  ]
})
export class SpiderChartModule { }
