import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanalPfbComponent } from './canal-pfb.component'; // Adjust the path as needed
import { RouterModule } from '@angular/router'; // Import RouterModule for routing
// import { BsModalModule } from 'ngx-bootstrap/modal'; // Import Bootstrap modal module
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
import { NgxLoadingModule } from 'ngx-loading'; // Import NgxLoadingModule
import { ModalModule } from 'ngx-bootstrap/modal';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    CanalPfbComponent // Declare the CanalPfbComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    OrderPipModule,
    FilterPipeModule,

  ],
  exports: [
    CanalPfbComponent // Export if needed for other modules
  ]
})
export class CanalPfbModule { }
