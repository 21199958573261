import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // If you're using reactive or template-driven forms
import { ValidatorSettingsFormComponent } from './validator-settings-form.component'; // Adjust the import path accordingly
import { ToastrModule } from 'ngx-toastr'; // If you're using Toastr notifications
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    ValidatorSettingsFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule, // Import FormsModule if you are using forms
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({})

  ],
  exports: [
    ValidatorSettingsFormComponent // Export the component if needed in other modules
  ]
})
export class ValidatorSettingsFormModule {}
