import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import FormsModule and ReactiveFormsModule
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; // Import for mat-slide-toggle if needed
import { MatCheckboxModule } from '@angular/material/checkbox'; // Import for mat-checkbox if needed
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for toast notifications
import { BrakeSettingsComponent } from './brake-settings.component'; // Adjust the path as necessary
import { EmailManagerModule } from '../email-manager/email-manager.module';

@NgModule({
  declarations: [
    BrakeSettingsComponent,
  ],
  imports: [
    CommonModule, // Include CommonModule for Angular common directives
    FormsModule, // Include for using ngModel
    ReactiveFormsModule, // Include for reactive forms if you are using them
    MatSlideToggleModule, // Include for mat-slide-toggle if used in the component
    MatCheckboxModule, // Include for mat-checkbox if used in the component
    ToastrModule.forRoot(),
    EmailManagerModule

  ],
  exports: [
    BrakeSettingsComponent // Export the component if it needs to be used in other modules
  ]
})
export class BrakeSettingsModule { }
