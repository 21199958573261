import { Component, OnInit, ViewChild } from '@angular/core';

import { PathService } from '../../../../historical';

import * as Highcharts from 'highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
// import { TravelReportDto } from 'src/app/client-management/parc-management';
import { Schedule } from '../../../schedule';
import { TravelReportDto } from 'src/app/client-management/parc-management';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
// require('highcharts/highcharts-3d')(Highcharts)
// require('highcharts/modules/accessibility')(Highcharts)
// require('highcharts/modules/export-data')(Highcharts)

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
  selector: 'app-chart-titre-graphique',
  templateUrl: './chart-titre-graphique.component.html',
  styleUrls: ['./chart-titre-graphique.component.scss'],
})
export class ChartTitreGraphiqueComponent implements OnInit {
  private Schedule: Schedule[];
  private Schedule_fil: Schedule[];
  matricule: Set<String> = new Set();

  TravelReportsData: TravelReportDto[] = [];
  options: Object | null = null;
  optionsModal: Object | null = null;


  @ViewChild('titreGraphique', { static: false }) titreGraphique: ModalDirective;

  constructor(private pathService: PathService) {}

  ngOnInit() {
    this.TitreGraphique(this.TravelReportsData, 0, 0);
  }

  showGraphTitreGraphique() {
    this.titreGraphique.show();
  }

  transform(value: number, args?: any): any {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);

    let result = '';

    result = hours + 'h' + minutes + 'min';

    return result;
  }

  convertMillisecondsToHoursAndMinutes(milliseconds:any) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    let result = '';
    result = hours + 'h' + minutes + 'min';
    return result;
  }

    TitreGraphique(
      TravelReportsData: TravelReportDto[],
      vehiculesdisponible: any | any[],
      vehiculesPlanifie: any | any[]
    ) {
    this.TravelReportsData = TravelReportsData;

    // ============================= Begin Proccess FOR SCHEDULE
    this.Schedule = this.pathService.getSharedData();
    this.Schedule_fil = [];
    const subMarkRealiseMap = new Map<
      string,
      {
        DurreMission: number;
        ArretSite: number;
        ArretChomage: number;
        ArretCHargement: number;
        ArretMission: number;
        DurationMission: number;
        ArretClient: number;
        ArretTrajet: number;
      }
    >();
    const matriculeSetRealise = new Map<
      string,
      {
        DurreMission: number;
        ArretSite: number;
        ArretChomage: number;
        ArretCHargement: number;
        ArretMission: number;
        DurationMission: number;
        ArretClient: number;
        ArretTrajet: number;
      }
    >();
    const otMatriculeSetRealise = new Map<
      string,
      Array<{
        Ot: string;
        MissionType: string;
        DurreMission: number;
        ArretSite: number;
        ArretChomage: number;
        ArretCHargement: number;
        ArretMission: number;
        DurationMission: number;
        ArretClient: number;
        ArretTrajet: number;
      }>
    >();
    const subMarkTotalsMap = new Map<
      string,
      { totalDisponibilite: number; totalPlanifie: number }
    >();
    const matriculeSet = new Map<
      string,
      { disponibiliteIndicator: number; planifieIndicator: number }
    >();
    this.matricule = new Set(
      this.Schedule.map((s) => {
        return s.matricule;
      })
    );

    this.matricule.forEach((mat) => {
      let schedules = this.Schedule.filter((g) => g.matricule == mat);
      this.Schedule_fil.push(schedules[schedules.length - 1]);
    });

    this.Schedule_fil.forEach((item) => {
      const subMark = item.submark;

      if (!subMarkTotalsMap.has(subMark)) {
        subMarkTotalsMap.set(subMark, {
          totalDisponibilite: 0,
          totalPlanifie: 0,
        });
      }

      matriculeSet.set(item.matricule, {
        disponibiliteIndicator: 0,
        planifieIndicator: 0,
      });

      if (item.type !== 'BreakDown') {
        subMarkTotalsMap.get(subMark).totalDisponibilite += 1;
        matriculeSet.get(item.matricule).disponibiliteIndicator += 1;
        if (item.type !== 'Available') {
          subMarkTotalsMap.get(subMark).totalPlanifie += 1;
          matriculeSet.get(item.matricule).planifieIndicator += 1;
        }
      }
    });
    // ============================= Begin Proccess FOR REALISE
    let TotalTimeMission = 0;
    let TotalTimeArretMission = 0;
    let TotalTimeArretClient = 0;
    let TotalTimeArretTrajet = 0;
    let TotalTimeConduiteMission = 0;
    let TotalTimeArretSite = 0;
    let TotalTimeChomage = 0;
    let TotalTimeChargement = 0;

    TravelReportsData.forEach((item) => {
      const subMark = item.vehicule.subMark;
      let TimeMission = +item.endPathTime - +item.beginPathTime;
      let TimeArretClient =
        item.time_in_poi > item.retardPoiClient / 1000
          ? item.time_in_poi - item.retardPoiClient / 1000
          : 0;
      let TimeArretTrajet = +item.stopDuration - +item.time_in_poi;
      let TimeArretMission = TimeArretTrajet + TimeArretClient;

      let TimeConduiteMission = +item.pathDuration;

      let TimeArretSite = 0;
      let TimeChomage = 0;
      let TimeChargement = 0;
      if (item.timeChomage == null) {
        if (item.timeChargement == null) {
          TimeArretSite = 0;
          TimeChomage = 0;
          TimeChargement = 0;
        } else {
          TimeArretSite = +item.beginPathTime - +item.timeChargement;
          TimeChomage = 0;
          TimeChargement = TimeArretSite;
        }
      } else {
        TimeArretSite = +item.beginPathTime - +item.timeChomage;
        if (item.timeChargement == null) {
          TimeChomage = TimeArretSite;
          TimeChargement = 0;
        } else {
          TimeChomage = +item.timeChargement - +item.timeChomage;
          TimeChargement = +item.beginPathTime - +item.timeChargement;
        }
      }

      TimeChomage =
        TimeChomage > item.retardChomage
          ? TimeChomage - +item.retardChomage
          : 0;
      TimeChargement =
        TimeChargement > item.retardChargement
          ? TimeChargement - +item.retardChargement
          : 0;

      TotalTimeMission += TimeMission;
      TotalTimeArretMission = TotalTimeArretMission + TimeArretMission;
      TotalTimeArretClient = TotalTimeArretClient + TimeArretClient;
      TotalTimeArretTrajet = TotalTimeArretTrajet + TimeArretTrajet;
      TotalTimeConduiteMission = TotalTimeConduiteMission + TimeConduiteMission;

      TotalTimeChomage += TimeChomage;
      TotalTimeChargement += TimeChargement;
      TotalTimeArretSite += TimeArretSite;

      if (!subMarkRealiseMap.has(subMark)) {
        subMarkRealiseMap.set(subMark, {
          DurreMission: 0,
          ArretSite: 0,
          ArretChomage: 0,
          ArretCHargement: 0,
          ArretMission: 0,
          DurationMission: 0,
          ArretClient: 0,
          ArretTrajet: 0,
        });
      }
      if (!matriculeSetRealise.has(item.vehicule.matricule)) {
        matriculeSetRealise.set(item.vehicule.matricule, {
          DurreMission: 0,
          ArretSite: 0,
          ArretChomage: 0,
          ArretCHargement: 0,
          ArretMission: 0,
          DurationMission: 0,
          ArretClient: 0,
          ArretTrajet: 0,
        });
      }
      subMarkRealiseMap.get(subMark).DurreMission += TimeMission;
      subMarkRealiseMap.get(subMark).ArretSite += TimeArretSite;
      subMarkRealiseMap.get(subMark).ArretChomage += TimeChomage;
      subMarkRealiseMap.get(subMark).ArretCHargement += TimeChargement;
      subMarkRealiseMap.get(subMark).ArretMission += TimeArretMission;
      subMarkRealiseMap.get(subMark).DurationMission += TimeConduiteMission;
      subMarkRealiseMap.get(subMark).ArretClient += TimeArretClient;
      subMarkRealiseMap.get(subMark).ArretTrajet += TimeArretTrajet;

      matriculeSetRealise.get(item.vehicule.matricule).DurreMission +=
        TimeMission;
      matriculeSetRealise.get(item.vehicule.matricule).ArretSite +=
        TimeArretSite;
      matriculeSetRealise.get(item.vehicule.matricule).ArretChomage +=
        TimeChomage;
      matriculeSetRealise.get(item.vehicule.matricule).ArretCHargement +=
        TimeChargement;
      matriculeSetRealise.get(item.vehicule.matricule).ArretMission +=
        TimeArretMission;
      matriculeSetRealise.get(item.vehicule.matricule).DurationMission +=
        TimeConduiteMission;
      matriculeSetRealise.get(item.vehicule.matricule).ArretClient +=
        TimeArretClient;
      matriculeSetRealise.get(item.vehicule.matricule).ArretTrajet +=
        TimeArretTrajet;

      otMatriculeSetRealise;
      if (!otMatriculeSetRealise.has(item.vehicule.matricule)) {
        otMatriculeSetRealise.set(item.vehicule.matricule, []); // Initialize an empty array for the matricule if it doesn't exist
      }
      otMatriculeSetRealise.get(item.vehicule.matricule).push({
        Ot: item.otNumber,
        MissionType: item.missionType,
        DurreMission: TimeMission,
        ArretSite: TimeArretSite,
        ArretChomage: TimeChomage,
        ArretCHargement: TimeChargement,
        ArretMission: TimeArretMission,
        DurationMission: TimeConduiteMission,
        ArretClient: TimeArretClient,
        ArretTrajet: TimeArretTrajet,
      });
    });

    // ============================= END Proccess FOR REALISE

    const chartData = [
      {
        name: 'Disponible',
        data: [
          {
            name: 'Disponible',
            y: vehiculesdisponible * 12,
            time: vehiculesdisponible * 12 + 'h',
            drilldown: `disponible`,
            dataLabels: {
              enabled: true,
              format: '{y}h', // Display the y-value on top of the column
              style: {
                fontWeight: 'bold',
              },
            },
          },
        ],
      },
      {
        name: 'Planifié',
        data: [
          {
            name: 'Planifié',
            y: vehiculesPlanifie * 12,
            time: vehiculesPlanifie * 12 + 'h',
            drilldown: 'planifie',
            dataLabels: {
              enabled: true,
              format: '{y}h', // Display the y-value on top of the column
              style: {
                fontWeight: 'bold',
              },
            },
          },
        ],
      },
      {
        name: 'Réalisé',
        data: [
          {
            name: 'Réalisé',
            y: (TotalTimeArretSite + TotalTimeMission) / (1000 * 60 * 60),
            time: this.convertMillisecondsToHoursAndMinutes(
              TotalTimeArretSite + TotalTimeMission
            ),
            drilldown: 'Realise',
            dataLabels: {
              enabled: true,
              format: '{point.time}', // Display the y-value on top of the column
              style: {
                fontWeight: 'bold',
              },
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat:
                '<span style="color:{point.color}">{point.name}:</span> {point.time}<br>',
            },
          },
        ],
      },
    ];

    // ============================= BEGIN DRILLDOWN FOR REALISE
    // ************ FIRST LEVEL (by TIME REALISE)
    const drilldown_realise = {
      id: `Realise`,
      name: 'Temps Réalisé',
      data: [
        {
          name: 'Temps Mission',
          y: TotalTimeMission / (1000 * 60 * 60),
          time: this.convertMillisecondsToHoursAndMinutes(TotalTimeMission),
          color: this.getColorByIndex(0),
          drilldown: 'mission',
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        },
        {
          name: 'Séjours Site',
          y: TotalTimeArretSite / (1000 * 60 * 60),
          time: this.convertMillisecondsToHoursAndMinutes(TotalTimeArretSite),
          color: this.getColorByIndex(1),
          drilldown: 'arret',
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        },
      ],
    };

    // ********************* SECOND LEVEL (by TIME ARRET: chomage & chargement)
    const drilldown_realise_arret = {
      id: `arret`,
      name: 'Séjours Site',
      data: [
        {
          name: "Retard d'affectation",
          y: TotalTimeChomage / (1000 * 60 * 60),
          time: this.convertMillisecondsToHoursAndMinutes(TotalTimeChomage),
          color: this.getColorByIndex(2),
          drilldown: 'chomage',
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        },
        {
          name: 'Retard chargement',
          y: TotalTimeChargement / (1000 * 60 * 60),
          time: this.convertMillisecondsToHoursAndMinutes(TotalTimeChargement),
          color: this.getColorByIndex(3),
          drilldown: 'chargement',
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        },
      ],
    };

    // ********************* Third LEVEL ARRET CHOMAGE
    const drilldown_realise_arret_chomage = {
      id: `chomage`,
      name: `Retard d'affectation`,
      data: Array.from(subMarkRealiseMap.entries()).map(
        ([subMark, totals], index) => ({
          name: subMark,
          y: totals.ArretChomage / (1000 * 60 * 60),
          time: this.convertMillisecondsToHoursAndMinutes(totals.ArretChomage),
          color: this.getColorByIndex(index),
          drilldown: `${subMark}_chomage`,
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        })
      ),
    };

    // ********************* Third LEVEL ARRET CHARGEMENT
    const drilldown_realise_arret_chargement = {
      id: `chargement`,
      name: `Retard chargement`,
      data: Array.from(subMarkRealiseMap.entries()).map(
        ([subMark, totals], index) => ({
          name: subMark,
          y: totals.ArretCHargement / (1000 * 60 * 60),
          time: this.convertMillisecondsToHoursAndMinutes(
            totals.ArretCHargement
          ),
          color: this.getColorByIndex(index),
          drilldown: `${subMark}_chargement`,
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        })
      ),
    };

    // ********************* Firth LEVEL ARRET CHARGEment MATRICULE
    let mat: any[] = [];
    const drilldown_realise_arret_chargement_matricule = Array.from(
      subMarkRealiseMap.entries()
    ).map(([subMark, totals]) => {
      return {
        id: `${subMark}_chargement`,
        name: `Retard chargement ${subMark}`,
        data: TravelReportsData.filter(
          (item) => item.vehicule.subMark == subMark
        ).map((item) => {
          const matriculeTotals = matriculeSetRealise.get(
            item.vehicule.matricule
          );

          if (mat.indexOf(item.vehicule.matricule) !== 1)
            return {
              name: item.vehicule.matricule,
              y: matriculeTotals.ArretCHargement / (1000 * 60 * 60),
              time: this.convertMillisecondsToHoursAndMinutes(
                matriculeTotals.ArretCHargement
              ),
              drilldown: `${item.vehicule.matricule}_chargement`,
              dataLabels: {
                enabled: true,
                format: '{point.time}', // Display the y-value on top of the column
                style: {
                  fontWeight: 'bold',
                },
              },
            };
        }),
      };
    });

    // ********************* Firth LEVEL ARRET CHOMAGE MATRICULE
    mat = [];
    const drilldown_realise_arret_chomage_matricule = Array.from(
      subMarkRealiseMap.entries()
    ).map(([subMark, totals]) => {
      return {
        id: `${subMark}_chomage`,
        name: `Retard d'affectation ${subMark}`,
        data: TravelReportsData.filter(
          (item) => item.vehicule.subMark == subMark
        ).map((item) => {
          const matriculeTotals = matriculeSetRealise.get(
            item.vehicule.matricule
          );

          if (mat.indexOf(item.vehicule.matricule) !== 1)
            return {
              name: item.vehicule.matricule,
              y: matriculeTotals.ArretChomage / (1000 * 60 * 60),
              time: this.convertMillisecondsToHoursAndMinutes(
                matriculeTotals.ArretChomage
              ),
              drilldown: `${item.vehicule.matricule}_chomage`,
              dataLabels: {
                enabled: true,
                format: '{point.time}', // Display the y-value on top of the column
                style: {
                  fontWeight: 'bold',
                },
              },
            };
        }),
      };
    });

    // ********************* Six LEVEL ARRET CHOMAGE MATRICULE OT
    const drilldown_realise_arret_chomage_matricule_ot = Array.from(
      matriculeSetRealise.entries()
    ).map(([matricule, totals]) => {
      return {
        id: `${matricule}_chomage`,
        name: `Retard d'affectation ${matricule}`,
        data: otMatriculeSetRealise.has(matricule)
          ? otMatriculeSetRealise.get(matricule).map((dataEntry) => {
              return {
                name: dataEntry.Ot,
                y: dataEntry.ArretChomage / (1000 * 60 * 60),
                time: this.convertMillisecondsToHoursAndMinutes(
                  dataEntry.ArretChomage
                ),
                drilldown: `${matricule}_chomage`,
                dataLabels: {
                  enabled: true,
                  format: '{point.time}', // Display the y-value on top of the column
                  style: {
                    fontWeight: 'bold',
                  },
                },
              };
            })
          : [],
      };
    });

    // ********************* Six LEVEL ARRET CHARGEMENT MATRICULE OT
    const drilldown_realise_arret_chargement_matricule_ot = Array.from(
      matriculeSetRealise.entries()
    ).map(([matricule, totals]) => {
      return {
        id: `${matricule}_chargement`,
        name: `Retard de Chargement ${matricule}`,
        data: otMatriculeSetRealise.has(matricule)
          ? otMatriculeSetRealise.get(matricule).map((dataEntry) => {
              return {
                name: dataEntry.Ot,
                y: dataEntry.ArretCHargement / (1000 * 60 * 60),
                time: this.convertMillisecondsToHoursAndMinutes(
                  dataEntry.ArretCHargement
                ),
                drilldown: `${matricule}_chargement`,
                dataLabels: {
                  enabled: true,
                  format: '{point.time}', // Display the y-value on top of the column
                  style: {
                    fontWeight: 'bold',
                  },
                },
              };
            })
          : [],
      };
    });

    // ********************* SECOND LEVEL (by TIME ARRET: chomage & chargement)
    const drilldown_realise_mission = {
      id: `mission`,
      name: 'Temps de mission',
      data: [
        {
          name: 'Temps de conduite',
          y: TotalTimeConduiteMission / (1000 * 60 * 60),
          time: this.transform(TotalTimeConduiteMission),
          color: this.getColorByIndex(2),
          drilldown: 'conduite',
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        },
        {
          name: 'Temps d’arrêt globale',
          y: TotalTimeArretMission / (1000 * 60 * 60),
          time: this.transform(TotalTimeArretMission),
          color: this.getColorByIndex(3),
          drilldown: 'arret_global',
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        },
      ],
    };

    // ********************* Third LEVEL ARRET CHOMAGE
    const drilldown_realise_mission_conduite = {
      id: `conduite`,
      name: `Temps de conduite`,
      data: Array.from(subMarkRealiseMap.entries()).map(
        ([subMark, totals], index) => ({
          name: subMark,
          y: totals.DurationMission / (1000 * 60 * 60),
          time: this.transform(totals.DurationMission),
          color: this.getColorByIndex(index),
          drilldown: `${subMark}_conduite`,
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        })
      ),
    };

    // ********************* Third LEVEL ARRET CHARGEMENT
    const drilldown_realise_mission_arret = {
      id: `arret_global`,
      name: 'Temps d’arrêt globale',
      data: [
        {
          name: 'Retard de déchargement',
          y: TotalTimeArretClient / (1000 * 60 * 60),
          time: this.transform(TotalTimeArretClient),
          color: this.getColorByIndex(2),
          drilldown: 'arret_poi',
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        },
        {
          name: 'Retard de trajet',
          y: TotalTimeArretTrajet / (1000 * 60 * 60),
          time: this.transform(TotalTimeArretTrajet),
          color: this.getColorByIndex(3),
          drilldown: 'arret_trajet',
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        },
      ],
    };
    // const drilldown_realise_mission_arret_global={
    //   id: `arret_global`,
    //   name: `Temps d’arrêt globale`,
    //   data: Array.from(subMarkRealiseMap.entries()).map(([subMark, totals],index) => ({
    //     name: subMark,
    //     y: totals.ArretMission/(1000 * 60 * 60),
    //     time:this.transform(totals.ArretMission),
    //     color: this.getColorByIndex(index),
    //     drilldown: `${subMark}_arret_global`,
    //     dataLabels: {
    //       enabled: true,
    //       format: '{point.time}', // Display the y-value on top of the column
    //       style: {
    //         fontWeight: 'bold',
    //       }
    //     }
    //   })),
    // };

    // ********************* Firth LEVEL ARRET CHARGEment MATRICULE
    mat = [];
    const drilldown_realise_mission_conduite_matricule = Array.from(
      subMarkRealiseMap.entries()
    ).map(([subMark, totals]) => {
      return {
        id: `${subMark}_conduite`,
        name: `Temps de conduite ${subMark}`,
        data: TravelReportsData.filter(
          (item) => item.vehicule.subMark == subMark
        ).map((item) => {
          const matriculeTotals = matriculeSetRealise.get(
            item.vehicule.matricule
          );

          if (mat.indexOf(item.vehicule.matricule) !== 1)
            return {
              name: item.vehicule.matricule,
              y: matriculeTotals.DurationMission / (1000 * 60 * 60),
              time: this.transform(matriculeTotals.DurationMission),
              drilldown: `${item.vehicule.matricule}_conduite`,
              dataLabels: {
                enabled: true,
                format: '{point.time}', // Display the y-value on top of the column
                style: {
                  fontWeight: 'bold',
                },
              },
            };
        }),
      };
    });

    // ********************* Firth LEVEL ARRET CHOMAGE MATRICULE
    const drilldown_realise_mission_arret_poi_groupe = {
      id: `arret_poi`,
      name: `Retard de déchargement`,
      data: Array.from(subMarkRealiseMap.entries()).map(
        ([subMark, totals], index) => ({
          name: subMark,
          y: totals.ArretClient / (1000 * 60 * 60),
          time: this.transform(totals.ArretClient),
          color: this.getColorByIndex(index),
          drilldown: `${subMark}_arret_poi`,
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        })
      ),
    };

    // ********************* Firth LEVEL ARRET CHOMAGE MATRICULE
    const drilldown_realise_mission_arret_trajet_groupe = {
      id: `arret_trajet`,
      name: `Retard de trajet`,
      data: Array.from(subMarkRealiseMap.entries()).map(
        ([subMark, totals], index) => ({
          name: subMark,
          y: totals.ArretTrajet / (1000 * 60 * 60),
          time: this.transform(totals.ArretTrajet),
          color: this.getColorByIndex(index),
          drilldown: `${subMark}_arret_trajet`,
          dataLabels: {
            enabled: true,
            format: '{point.time}', // Display the y-value on top of the column
            style: {
              fontWeight: 'bold',
            },
          },
        })
      ),
    };

    // ********************* Six LEVEL ARRET CHOMAGE MATRICULE
    mat = [];
    const drilldown_realise_mission_arret_poi_matricule = Array.from(
      subMarkRealiseMap.entries()
    ).map(([subMark, totals]) => {
      return {
        id: `${subMark}_arret_poi`,
        name: `Retard de déchargement ${subMark}`,
        data: TravelReportsData.filter(
          (item) => item.vehicule.subMark == subMark
        ).map((item) => {
          const matriculeTotals = matriculeSetRealise.get(
            item.vehicule.matricule
          );

          if (mat.indexOf(item.vehicule.matricule) !== 1)
            return {
              name: item.vehicule.matricule,
              y: matriculeTotals.ArretClient / (1000 * 60 * 60),
              time: this.transform(matriculeTotals.ArretClient),
              drilldown: `${item.vehicule.matricule}_arret_poi`,
              dataLabels: {
                enabled: true,
                format: '{point.time}', // Display the y-value on top of the column
                style: {
                  fontWeight: 'bold',
                },
              },
            };
        }),
      };
    });

    // ********************* Six LEVEL ARRET CHOMAGE MATRICULE
    mat = [];
    const drilldown_realise_mission_arret_trajet_matricule = Array.from(
      subMarkRealiseMap.entries()
    ).map(([subMark, totals]) => {
      return {
        id: `${subMark}_arret_trajet`,
        name: `Retard de trajet ${subMark}`,
        data: TravelReportsData.filter(
          (item) => item.vehicule.subMark == subMark
        ).map((item) => {
          const matriculeTotals = matriculeSetRealise.get(
            item.vehicule.matricule
          );

          if (mat.indexOf(item.vehicule.matricule) !== 1)
            return {
              name: item.vehicule.matricule,
              y: matriculeTotals.ArretTrajet / (1000 * 60 * 60),
              time: this.transform(matriculeTotals.ArretTrajet),
              drilldown: `${item.vehicule.matricule}_arret_trajet`,
              dataLabels: {
                enabled: true,
                format: '{point.time}', // Display the y-value on top of the column
                style: {
                  fontWeight: 'bold',
                },
              },
            };
        }),
      };
    });

    // ********************* SEVEN LEVEL ARRET CHOMAGE MATRICULE
    const drilldown_realise_mission_conduite_matricule_ot = Array.from(
      matriculeSetRealise.entries()
    ).map(([matricule, totals]) => {
      return {
        id: `${matricule}_conduite`,
        name: `Temps de conduite ${matricule}`,
        data: otMatriculeSetRealise.has(matricule)
          ? otMatriculeSetRealise.get(matricule).map((dataEntry) => {
              return {
                name: dataEntry.Ot,
                y: dataEntry.DurationMission / (1000 * 60 * 60),
                time: this.transform(dataEntry.DurationMission),
                dataLabels: {
                  enabled: true,
                  format: '{point.time}', // Display the y-value on top of the column
                  style: {
                    fontWeight: 'bold',
                  },
                },
              };
            })
          : [],
      };
    });

    // ********************* SEVEN LEVEL ARRET CHOMAGE MATRICULE
    const drilldown_realise_mission_arret_poi_matricule_ot = Array.from(
      matriculeSetRealise.entries()
    ).map(([matricule, totals]) => {
      return {
        id: `${matricule}_arret_poi`,
        name: `Retard de déchargement ${matricule}`,
        data: otMatriculeSetRealise.has(matricule)
          ? otMatriculeSetRealise.get(matricule).map((dataEntry) => {
              return {
                name: dataEntry.Ot,
                y: dataEntry.ArretClient / (1000 * 60 * 60),
                time: this.transform(dataEntry.ArretClient),
                dataLabels: {
                  enabled: true,
                  format: '{point.time}', // Display the y-value on top of the column
                  style: {
                    fontWeight: 'bold',
                  },
                },
              };
            })
          : [],
      };
    });

    // ********************* SEVEN LEVEL ARRET CHOMAGE MATRICULE
    const drilldown_realise_mission_arret_trajet_matricule_ot = Array.from(
      matriculeSetRealise.entries()
    ).map(([matricule, totals]) => {
      return {
        id: `${matricule}_arret_trajet`,
        name: `Retard de trajet ${matricule}`,
        data: otMatriculeSetRealise.has(matricule)
          ? otMatriculeSetRealise.get(matricule).map((dataEntry) => {
              return {
                name: dataEntry.Ot,
                y: dataEntry.ArretTrajet / (1000 * 60 * 60),
                time: this.transform(dataEntry.ArretTrajet),
                dataLabels: {
                  enabled: true,
                  format: '{point.time}', // Display the y-value on top of the column
                  style: {
                    fontWeight: 'bold',
                  },
                },
              };
            })
          : [],
      };
    });

    // ============================= END DRILLDOWN FOR REALISE

    // ============================= BEGIN DRILLDOWN FOR DISPONIBLE
    // ************ FIRST LEVEL (by group)
    const drilldown_disponible_groupe = {
      id: `disponible`,
      name: 'Groupe Camion Disponible',
      data: Array.from(subMarkTotalsMap.entries()).map(
        ([subMark, totals], index) => ({
          name: subMark,
          y: totals.totalDisponibilite * 12,
          time: totals.totalDisponibilite * 12 + 'h',
          drilldown: `${subMark}_DP`,
          color: this.getColorByIndex(index),
        })
      ),
    };

    const drilldown_planifie_groupe = {
      id: `planifie`,
      name: 'Groupe Camion Planifié',
      data: Array.from(subMarkTotalsMap.entries()).map(
        ([subMark, totals], index) => ({
          name: subMark,
          y: totals.totalPlanifie * 12,
          time: totals.totalPlanifie * 12 + 'h',
          drilldown: `${subMark}_P`,
          color: this.getColorByIndex(index),
        })
      ),
    };

    // ************ SECOND LEVEL (by group)
    const drilldown_disponible_groupe_matricule = Array.from(
      subMarkTotalsMap.entries()
    ).map(([subMark, totals]) => {
      return {
        id: `${subMark}_DP`,
        name: `${subMark} Disponible`,
        data: this.Schedule_fil.filter((item) => item.submark === subMark).map(
          (item) => ({
            name: item.matricule,
            y: matriculeSet.get(item.matricule).disponibiliteIndicator * 12,
            time:
              matriculeSet.get(item.matricule).disponibiliteIndicator * 12 +
              'h',
          })
        ),
      };
    });

    const drilldown_planifie_groupe_matricule = Array.from(
      subMarkTotalsMap.entries()
    ).map(([subMark, totals]) => {
      return {
        id: `${subMark}_P`,
        name: `${subMark} Planifié`,
        data: this.Schedule_fil.filter((item) => item.submark === subMark).map(
          (item) => ({
            name: item.matricule,
            y: matriculeSet.get(item.matricule).planifieIndicator * 12,
            time: matriculeSet.get(item.matricule).planifieIndicator * 12 + 'h',
          })
        ),
      };
    });

    // ============================= END DRILLDOWN FOR DISPONIBLE

    // ============================= BEGIN CONCAT DRILLDOWN
    let drilldown_data = drilldown_disponible_groupe_matricule.concat(
      drilldown_disponible_groupe
    );
    drilldown_data = drilldown_data.concat(drilldown_realise);

    drilldown_data = drilldown_data.concat(drilldown_planifie_groupe);
    drilldown_data = drilldown_data.concat(drilldown_planifie_groupe_matricule);

    drilldown_data = drilldown_data.concat(drilldown_realise_arret);
    drilldown_data = drilldown_data.concat(drilldown_realise_arret_chargement);
    drilldown_data = drilldown_data.concat(drilldown_realise_arret_chomage);
    drilldown_data = drilldown_data.concat(
      drilldown_realise_arret_chomage_matricule
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_arret_chargement_matricule
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_arret_chomage_matricule_ot
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_arret_chargement_matricule_ot
    );

    drilldown_data = drilldown_data.concat(drilldown_realise_mission);
    drilldown_data = drilldown_data.concat(drilldown_realise_mission_conduite);
    drilldown_data = drilldown_data.concat(drilldown_realise_mission_arret);
    drilldown_data = drilldown_data.concat(
      drilldown_realise_mission_conduite_matricule
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_mission_conduite_matricule_ot
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_mission_arret_poi_groupe
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_mission_arret_trajet_groupe
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_mission_arret_poi_matricule
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_mission_arret_trajet_matricule
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_mission_arret_poi_matricule_ot
    );
    drilldown_data = drilldown_data.concat(
      drilldown_realise_mission_arret_trajet_matricule_ot
    );
    const drilldown = drilldown_data;

    this.setHighCartsLang();
    this.options = {
      chart: {
        type: 'column',
        height: 260,
      },
      title: {
        text: 'Gestion du temps Activité (H)',
        style: {
          color: '#3c8dbc',
          fontSize: '20px',
        },
        align: 'center',
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}:</span> {point.time}<br>',
        style: {
          textDecoration: 'none', // Remove underline
        },
      },
      xAxis: {
        type: 'category',
        title: {
          text: null,
        },
        labels: {
          enabled: true, // Set this to false to hide the xAxis labels
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: chartData,
      drilldown: {
        series: drilldown, // Include the drilldown series
      },
    };

    this.optionsModal = {
      chart: {
        type: 'column',
        height: 300,
      },
      title: {
        text: 'Gestion du temps Activité (H)',
        style: {
          color: '#3c8dbc',
          fontSize: 12,
        },
        align: 'center',
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}:</span> {point.time}<br>',
        style: {
          textDecoration: 'none', // Remove underline
        },
      },
      xAxis: {
        type: 'category',
        title: {
          text: null,
        },
        labels: {
          enabled: true, // Set this to false to hide the xAxis labels
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          dataLabels: {
            style: {
              textDecoration: 'none', // Remove underline
            },
            // Other data label options...
          },
        },
      },
      series: chartData,
      drilldown: {
        series: drilldown, // Include the drilldown series
      },
    };

    Highcharts.chart('titreGraphique', this.options);
    Highcharts.chart('titreGraphiqueModal', this.optionsModal);
  }

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7',
        '#e77e22',
        '#861ec9',
        '#7a17e6',
        '#7010f9',
        '#691af3',
        '#6225ed',
        '#5b30e7',
        '#533be1',
        '#4c46db',
        '#4551d5',
        '#3e5ccf',
        '#3667c9',
        '#2f72c3',
        '#277dbd',
        '#1f88b7',
        '#1693b1',
        '#0a9eaa',
        '#03c69b',
        '#00f194',
      ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: "Aucune donnée n'est disponible",
      },
    });
  }

  getColorByIndex(index: any) {
    const colors = [
      '#277dbd',
      '#1f88b7',
      '#1693b1',
      '#0a9eaa',
      '#03c69b',
      '#00f194',
    ];
    return colors[index % colors.length];
  }
}
