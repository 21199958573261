import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Alert, AlertConfiguration } from '../../alert';
import { AlertManagementService } from '../../alert-management.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-battery-settings',
  templateUrl: './battery-settings.component.html',
  styleUrls: ['./battery-settings.component.css']
})
export class BatterySettingsComponent implements OnInit, OnDestroy {
  showAdvancedSettings = false;
  public loader = false;
  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  alert: Alert = new Alert();

  batteryAlertConfiguration: AlertConfiguration = new AlertConfiguration();
  cronAlertConfiguration: AlertConfiguration = new AlertConfiguration();

  getVehiculeWasSelectedSubscription: Subscription;

  constructor(
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService,
    private dataManagementService: DataManagementService
  ) { }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService.selectedVehicule!.idDevice
        );
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('CHARGER', device)
      .subscribe(
        alert => {
          this.alert = alert;
          this.cronAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'DATE'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'DATE'
            )[0]
            : this.cronAlertConfiguration;
          this.batteryAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'CHARGER'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'CHARGER'
            )[0]
            : this.batteryAlertConfiguration;

          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrer', 'info !');
      },
      error => {
        this.toastr.error('Configuration non enregistrer', 'info !');
        this.loader = false;
      }
    );
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean, enableGlobalConfigToAllVehicules: boolean  }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }
  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails,id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }
}
