
<div class="row">
  <section class="content col-md-4 groups-container rm-bgcolor">
      <!-- Vehicule according to P.O.I -->
      <div class="col-md-12">
          <div class="box box-info collapsed-box" style="margin-bottom: 5px;margin-left: -7px;">
              <div class="box-header with-border">
                  <h3 class="box-title"
                      style="font-family: 'Inconsolata', monospace;font-size: 14px;;font-weight: bold;color: black">
                      <i class="fa fa-map"></i> Position
                      par P.O.I</h3>
                  <div class="box-tools pull-right">
                      <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-plus"
                              style="color:black"></i>
                      </button>
                  </div>
              </div>
              <div class="box-body">
                  <div class="conten">
                      <app-vehicule-according-poi></app-vehicule-according-poi>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>

<div class="test">
  <app-leaflet></app-leaflet>
  </div>