import { Injectable } from '@angular/core';
const Highcharts = require('highcharts');

@Injectable()
export class ChartConfigService {

  constructor() { }

  numberWithSpaces1(x: number): string {
    if (x === 0) return "0.00";

    const parts = x.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

  numberWithSpaces(x: number | string): string {
    if (x === 0 || x === "0 " || x === "0.00") return "0.00"; // Return '0.00' if x is 0 or "0 " or "0.00"

    const num = typeof x === "string" ? parseFloat(x) : x;
    const parts = num.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts.setOptions({
      colors: ['#28a745', "#343a40", '#ffc107', '#17a2b8', '#dc3545', '#0d233a', '#8bbc21', '#a6c96a', '#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
        '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', '#007bff', '#66B92E', '#DA932C', '#D65B4A', '#24CBE5', '#64E572', '#FF9655', '#6AF9C4',
        '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#2f7ed8', '#f39c12', '#D81B60', '#605ca8', '#3D9970', '#001F3F', '#00c0ef', ' #451e3e', '#adcbe3', '#f37736', '#854442', '#3d1e6d', '#c68642'],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        noData: 'Aucune donnée n\'est disponible'
      }
    });
  }

}
