import { Injectable } from '@angular/core';
import { DataManagementService, Driver, PoiClient } from '../../data-management';
import { DateInterval } from 'src/app/shared/model';
import { Vehicule } from '../../canal-performance';
import { StopAccident } from 'src/app/shared/model/sinistre.model';


@Injectable({
    providedIn: 'root',
})
export class VoyageHelperService {
    constructor(
        public dataManagementService: DataManagementService
    ) {}

    formatedDate(dateToFormate: Date): string {
        const newDate = new Date(dateToFormate);
        const year = newDate.getFullYear();
        const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
        const day = ('0' + newDate.getDate()).slice(-2);
        return `${day}-${month}-${year}`;
    }
    isDateWithinDay(date: Date, day: Date): boolean {
        const startOfDay = new Date(day);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(day);
        endOfDay.setHours(23, 59, 59, 999);
        return date >= startOfDay && date <= endOfDay;
    }
   

    getDateInterval(dateToFormat: Date): DateInterval {
        let start = new Date(dateToFormat);
        start.setHours(0, 0, 0, 0);
    
        let end = new Date(dateToFormat);
        end.setHours(23, 59, 59, 999);
    
        let interval = new DateInterval();
        interval.startDate = start;
        interval.endDate = end;

        return interval;
    }

    
    secondsUntilEndOfDay(date: Date, duration: number): number {
        const currentDate = new Date(date);
        const tomorrow = new Date(currentDate);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        const differenceMs = tomorrow.getTime() - currentDate.getTime();
        const secondsLeft = Math.floor(differenceMs / 1000);
        return secondsLeft;
    }

    displaedStop(stop: StopAccident) {
        if (stop.beginStopTime) {
            let adress: string[] | undefined =
                stop.adress.address.state_district.split(/de|d'/);
            let time = new Date(stop.beginStopTime).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
            return (
                time +
                ' <==>' +
                (adress == undefined ? '' : adress[adress.length - 1])
            );
        }
        return '';
    }

}
