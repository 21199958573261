<div class="row" style="height:85%;">
    <div class="col-lg-12">
        <div class="box-header page-header with-border">

            <!-- =================================== TITLE (START)==================================== -->
            <!-- <h3 class="box-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;"><i class="fa fa-list"></i>

                <span style="text-decoration: underline">

                      <span>Liste des infractions du véhicule :</span>
                      <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher la véhicule" [routerLink]="['/client/dataManagement/vehicule/form', selectedVehicule.idVehicule, 'SHOW']">{{selectedVehicule.matricule}}</a>

                      <span *ngIf="selectedVehicule.driver"> - conducteur :
                            <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher le conducteur" [routerLink]="['/client/dataManagement/driver/form', selectedVehicule.driver.idDriver, 'SHOW']">{{selectedVehicule?.driver?.firstName}} {{selectedVehicule?.driver?.lastName}}</a>
                      </span>

                </span>


                <span *ngIf="!selectedVehicule.driver" data-toggle="tooltip" class="badge label-warning">
                      <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                </span>

            </h3> -->
            <!-- =================================== TITLE (END)==================================== -->

            <!-- =================================== OPTIONS (START)==================================== -->
            <div class="option-add">

                    <button type="button" class="btn btn-success btn-sm " title="Ajouter"
                    (click)="init();mode = 'ADD';addEditModal.show()" >
                       <i class="fa fa-plus-circle"></i> </button>
            </div>
            <div *ngIf="offensesId.length > 0" class="option-delete">
                <span title="Supprimer tout" (click)="onConfirmDeleteMultiple()">
                    <i class="fa fa-minus-circle"></i> Supprimer tout</span>
            </div>
            <!-- =================================== OPTIONS (END)==================================== -->
            <!--==================================== import/Export functionality(START)=============== -->
            <div style="display: inline-block; margin-left: 5px;">
                <div class="option-export">

                        <button type="button" class="btn btn-primary btn-sm" title="Exporter sous format excel"
                            (click)='export()' >Exporter <i class="fa fa-download"></i> </button>



                        <button type="button" class="btn btn-primary btn-sm " title="Importer sous format excel"
                        (click)='importModal.show()' >Importer <i class="fa fa-upload"></i> </button>
                </div>
            </div>
            <!--==================================== import/Export functionality(END)================= -->
            <!--==================================== Search(START)=============== -->
            <div class="pull-right">
                <div class="inputWithIcon">
                    <input [(ngModel)]="searchTerm" name="searchTerm" type="text" class="form-control input-sm"
                        placeholder="Type d'infraction...">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <!--==================================== Search(END)================= -->
        </div>
    </div>
    <div class="col-lg-12">
        <!-- ========================================== LIST SINISTRES (START) ====================================================-->
        <div class="content">
            <div class="table-responsive tableFixHead">
                <table class="table table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 2%; text-align: left;"><input type="checkbox" [disabled]="offenses.length == 0"
                                    [checked]="offenses.length == offensesId.length" (click)="onCheckedAll()"></th>
                            <th style="width:5%">Date</th>
                            <th style="width:5%">Lieu</th>
                            <th style="width:5%">Type Infraction</th>
                            <th style="width:5%">Coût</th>
                            <th style="width:5%">Payant</th>
                            <th style="width:65%">Observation</th>
                            <th style="width:5%"></th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngIf="loading">
                            <td colspan="9" style="text-align: center">
                                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                            </td>
                        </tr>

                        <tr *ngIf="!loading && offenses.length == 0">
                            <td colspan="9">
                                <div class="alert alert-dismissible" style="border-radius: 0px;text-align: center;">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                        ×
                                    </button>
                                    <h4 style="color: orange">
                                        <i class="fa fa-info-circle"></i> Information
                                    </h4>
                                    Veuillez ajouter des informations pour infractions.
                                </div>
                            </td>
                        </tr>


                        <tr *ngFor="let offense of getOffenses()">
                            <td>
                                <input type="checkbox" [checked]="isCheckedInList(offense.id)" (click)="onChecked(offense.id)">
                            </td>
                            <td style="font-weight: bold">
                                {{offense?.operationTime | date: 'dd-MM-yyyy, HH:mm'}}
                            </td>
                            <td>
                                {{offense?.place}}
                            </td>
                            <td>
                                {{offense?.type?.name}}
                            </td>
                            <td>
                                {{offense?.cost | number: '.0-4'}}
                            </td>
                            <td>
                                {{offense?.paying}}
                            </td>
                            <td>
                                {{offense?.observation}}
                            </td>
                            <td>
                                <button title="Supprimer" style="color: red;" (click)="onConfirmDelete(offense)" type="button"
                                    class="btn btn-sm btn-default">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                                <button title="Modifier" style="color: rgb(2, 157, 196); " type="button" class="btn btn-sm btn-default"
                                    (click)="updateoffense(offense)">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
        <!-- ========================================== LIST SINISTRES (END) ====================================================-->
    </div>

</div>

<!-- ========================================== ADD/Edit Modal (START) ====================================================-->
<div class="modal fade" bsModal #addEditModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- ========================== Modal TITLE (START) ===============================-->
            <div class="modal-header">

                <button type="button" class="close" (click)="addEditModal.hide()">&times;</button>

                <h4 *ngIf="mode == 'ADD'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-plus"></i>
                    Ajouter informations infraction</h4>

                <h4 *ngIf="mode == 'UPDATE'" class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-edit"></i>
                    Modifier informations infraction</h4>
            </div>
            <!-- ========================== Modal TITLE (END) ===============================-->

            <div class="modal-body">
                <form #batteryForm="ngForm" class="form-horizontal">
                    <div class="box-body">

                        <app-offense-form [vehicule]="selectedVehicule" [group]="selectedGroup" [mode]="mode" [offense]="currentOffense"
                            (offenseWasAdded)="offenseWasAdded($event)" (offenseWasUpdated)="offenseWasUpdated($event)"></app-offense-form>

                    </div>
                </form>
            </div>

        </div>

    </div>
</div>
<!-- ========================================== ADD/Edit Modal (END) ====================================================-->
<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" bsModal #offenseDeleteModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="offenseDeleteModal.hide()">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                </h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !</h4>
                    <span *ngIf="!isDeleteMultiple">Êtes-vous sûr de supprimer cette infraction ?</span>
                    <span *ngIf="isDeleteMultiple">Êtes-vous sûr de supprimer cettes {{offensesId.length}}
                        infractions
                        ?</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="offenseDeleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="onDelete()">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
<!--=================================Import offense(start) ====================================-->
<div class="modal" role="dialog" bsModal #importModal="bs-modal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="importModal.hide()">&times;
                </button>
                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Importer la liste des infractions
                </h4>
            </div>
            <div class="modal-body vertical-spacer">
                <app-import-export-forms [item]='selectedItem' [vehiculeMatricule]='selectedVehicule.matricule' [groupId]="selectedGroup.idGroupe"
                    (importedItem)='itemWasImported($event)'></app-import-export-forms>
            </div>
            <p></p>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="importModal.hide()">
                    Fermer
                </button>
            </div>
        </div>
    </div>
</div>
<!--=================================Import offense (end) ====================================-->
