import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { FuelingDashboardDto } from '../../parc-management';
import { AffectationAdd, AffectationInfo } from '../affectations';

@Injectable()
export class AffectationChargeService {

  months = [
    { 'id': 1, 'name': 'janvier' },
    { 'id': 2, 'name': 'février' },
    { 'id': 3, 'name': 'mars' },
    { 'id': 4, 'name': 'avril' },
    { 'id': 5, 'name': 'mai' },
    { 'id': 6, 'name': 'juin' },
    { 'id': 7, 'name': 'juillet' },
    { 'id': 8, 'name': 'août' },
    { 'id': 9, 'name': 'septembre' },
    { 'id': 10, 'name': 'octobre' },
    { 'id': 11, 'name': 'novembre' },
    { 'id': 12, 'name': 'décembre' },
  ];

  getYears() {
    return [
      { id: new Date().getFullYear(), name: 'Année En cours' },
      { id: new Date().getFullYear() - 1, name: 'Année Antérieure' },
      { id: new Date().getFullYear() - 2, name: 'Année -2' }
    ];
  }

  constructor(private http: HttpClient) { }


  getAffectations(annee: number, mois: number): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<any>(dns + "AffectationChargeChantier/list?annee="+annee+"&mois="+mois, options);
  }

  getAffectationsWithotParams(): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<any>(dns + "AffectationChargeChantier/AllAffectations", options);
  }

  addAffectation(payload: AffectationAdd): Observable<AffectationInfo> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<AffectationInfo>(dns + "AffectationChargeChantier", payload, options);
  }

  updateAffectation(payload: AffectationAdd, annee: number, mois: number): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.put(dns + "AffectationChargeChantier/?annee="+annee+"&mois="+mois , payload, options);
  }

  deleteAffectation(id : number): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.delete(dns + "AffectationChargeChantier/" + id ,options);
  }

  getLocationRealise(id: number, dateInterval: DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<any>(dns + 'AffectationChargeChantier/manually/locationRealise/' + id+ "?timezoneOffset=" + (new Date().getTimezoneOffset()), dateInterval, options);
  }

  getCARealise(id: number, dateInterval: DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<any>(dns + 'AffectationChargeChantier/locationRealise/' + id+ "?timezoneOffset=" + (new Date().getTimezoneOffset()), dateInterval, options);
  }

  getFuelCharge(groupId: number, dateIntervalDto: DateInterval): Observable<FuelingDashboardDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<FuelingDashboardDto[]>(dns + "fuelings/groupe/" + groupId + "?timezoneOffset=" + (new Date().getTimezoneOffset()), dateIntervalDto, options);
  }

  getMaintenanceCharge(groupId: number, dateIntervalDto: DateInterval): Observable<any[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<any[]>(dns + "groupes/"+ groupId +"/maintenance/?timezoneOffset=" + (new Date().getTimezoneOffset()), dateIntervalDto, options);
  }

  getLocationCharge(groupId: number, dateIntervalDto: DateInterval): Observable<any[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<any[]>(dns + "fuelings/groupe/" + groupId + "?timezoneOffset=" + (new Date().getTimezoneOffset()), dateIntervalDto, options);
  }

}
