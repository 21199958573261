import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleChartComponent } from './schedule-chart.component';
import { FusionChartsModule } from 'angular-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';;
import Gantt from 'fusioncharts/fusioncharts.gantt';
// Load FusionCharts
// Load fusion theme
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

// Initialiser les modules nécessaires
FusionChartsModule.fcRoot(FusionCharts, Charts, Gantt, FusionTheme);


@NgModule({
  declarations: [
    ScheduleChartComponent // Declare the ScheduleChartComponent
  ],
  imports: [
    CommonModule,
    FusionChartsModule // Import the configured FusionChartsModule
  ],
  exports: [
    ScheduleChartComponent // Export if you need it in other modules
  ]
})
export class ScheduleChartModule { }
