import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // For ngModel and form handling
import { RouterModule } from '@angular/router';

// Import your component
import { CanalPerformanceComponent } from './canal-performance.component';

@NgModule({
  declarations: [
    CanalPerformanceComponent, // Declare the CanalPerformanceComponent
  ],
  imports: [
    CommonModule,    // Required for common Angular directives
    FormsModule,     // Required for two-way data binding with ngModel
    RouterModule     // For routing functionality
  ],
  exports: [
    CanalPerformanceComponent  // Export if needed elsewhere in the project
  ]
})
export class CanalPerformanceModule { }
