import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaintenanceChargeComponent } from './maintenance-charge.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For Bootstrap date picker
import { ToastrModule } from 'ngx-toastr'; // For toastr notifications
import { HighchartsChartModule } from 'highcharts-angular'; // For Highcharts integration
import { CorrelationGasoilModule } from 'src/app/client-management/charges-pfb/charges-pfb-tracking/correlation-gasoil/correlation-gasoil.module';

@NgModule({
  declarations: [
    MaintenanceChargeComponent  // Declare MaintenanceChargeComponent
  ],
  imports: [
    CommonModule,               // Import CommonModule for common Angular directives
    FormsModule,                // For template-driven forms
    BsDatepickerModule.forRoot(), // Bootstrap datepicker module
    ToastrModule.forRoot(),      // Toastr for showing notifications
    HighchartsChartModule,
    CorrelationGasoilModule,

  ],
  exports: [
    MaintenanceChargeComponent  // Export the component to make it available in other modules
  ],
  providers: [
    // BsLocaleService  // Service for handling locale settings in datepicker
  ]
})
export class MaintenanceChargeModule { }
