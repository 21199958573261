<form class="form-inline">

  <table style="border-spacing: 10px;border-collapse: separate;">
    <tr>
      <td>
        <label>Durée d'arrêt hors Chargement (min)</label>
      </td>
      <td>
        <select *ngIf="stopTimeSelectMode" class="form-control" [(ngModel)]="chargementAlertConfiguration.value1"
                name="stopTime"
                (ngModelChange)="chooseStopTime($event)">
          <option *ngFor="let minute of minutes" [ngValue]="minute" style="font-size: 14px;"
                  [ngClass]="{'autre': minute == 'Autre..'}">
            {{minute}}
          </option>
        </select>
      </td>
    </tr>
  </table>

  <input *ngIf="!stopTimeSelectMode" type="number" name="stopTime" [(ngModel)]="stopTime" class="form-control"
         placeholder="Durée d'arrêt">

  <i *ngIf="!stopTimeSelectMode" class="fa fa-times" style="color: #aa1111"
     (click)="stopTimeSelectMode = true;chargementAlertConfiguration.value1='10'"></i>

  <!--  <div style="margin: 10px 0 10px 0" *ngIf="!showAdvancedSettings" (click)="collapseAdvancedSettings()">-->
  <!--    <i class="fa fa-plus-square"></i> <span class="link">paramétrage avancé</span>-->
  <!--  </div>-->

  <!--  <div style="margin: 10px 0 10px 0" *ngIf="showAdvancedSettings" (click)="collapseAdvancedSettings()">-->
  <!--    <i class="fa fa-minus-square"></i> <span class="link">masquer paramétrage avancé</span>-->
  <!--  </div>-->

  <!--  <app-cron-settings *ngIf="showAdvancedSettings" [cron]="cronAlertConfiguration"></app-cron-settings>-->

    <app-email-manager [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails"
    [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" (onChange)="emailSettings($event)"></app-email-manager>

  <br>

  <div class="form-group">
    <label>Activer l'alert</label>
    <div class="input-group">
      <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive">Active/Désactive</mat-slide-toggle>
    </div>
  </div>

  <button type="submit" class="btn btn-flat btn-primary mb-2" (click)="save()">
    <ngx-loading [show]="loader" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
    Sauvegarder</button>
</form>
