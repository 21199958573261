import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { imagesPOIDir } from "../../../../global.config";
import { MapService } from "../../../../utils/leaflet/service/map.service";
import { GeocodingService } from "../../../../utils/leaflet/service/geocoding.service";

import * as L from 'leaflet';
import { ToastrService } from "ngx-toastr";
import { ClientTypesList } from '../client-types.model';
import { DataManagementService, PoiClient } from 'src/app/client-management/data-management';
import {Icon, Marker, Polygon} from "leaflet";

@Component({
    selector: 'app-poi-client-form',
    templateUrl: './poi-client-form.component.html',
    styleUrls: ['./poi-client-form.component.css']
})
export class PoiClientFormComponent implements OnInit {

    /** loader */
    loader: boolean = false;
    @Input()
    mode: string = 'ADD';

    @Input()
    poiClient: PoiClient = new PoiClient();

    @Output()
    poiClientWasAdded = new EventEmitter();

    @Output()
    poiClientWasUpdated = new EventEmitter();

    images = [
        { text: "marker1", uri: imagesPOIDir + "marker1.png", value: "marker1.png" },
        { text: "marker2", uri: imagesPOIDir + "marker2.png", value: "marker2.png" },
        { text: "marker3", uri: imagesPOIDir + "marker3.png", value: "marker3.png" },
        { text: "marker4", uri: imagesPOIDir + "marker4.png", value: "marker4.png" },
        { text: "marker5", uri: imagesPOIDir + "marker5.png", value: "marker5.png" },
        { text: "marker6", uri: imagesPOIDir + "marker6.png", value: "marker6.png" },
        { text: "marker7", uri: imagesPOIDir + "marker7.png", value: "marker7.png" },
        { text: "marker8", uri: imagesPOIDir + "marker8.png", value: "marker8.png" },
        { text: "marker9", uri: imagesPOIDir + "marker9.png", value: "marker9.png" },
        { text: "marker10", uri: imagesPOIDir + "marker10.png", value: "marker10.png" },
        { text: "marker11", uri: imagesPOIDir + "marker11.png", value: "marker11.png" },
        { text: "marker12", uri: imagesPOIDir + "marker12.png", value: "marker12.png" },
        { text: "marker13", uri: imagesPOIDir + "marker13.png", value: "marker13.png" },
        { text: "marker14", uri: imagesPOIDir + "marker14.png", value: "marker14.png" },
        { text: "marker15", uri: imagesPOIDir + "marker15.png", value: "marker15.png" },
        { text: "flag1", uri: imagesPOIDir + "flag1.png", value: "flag1.png" },
        { text: "flag2", uri: imagesPOIDir + "flag2.png", value: "flag2.png" },
        { text: "house1", uri: imagesPOIDir + "house1.png", value: "house1.png" },
        { text: "house2", uri: imagesPOIDir + "house.png", value: "house.png" }
    ];

    selectedImage: { text: string, uri: string, value: string } = this.images[0];

    colors = [
        { text: "", value: null },
        { text: "Bleu", value: "blue" },
        { text: "Rouge", value: "red" },
        { text: "Vert", value: "green" },
        { text: "Jaune", value: "yellow" },
        { text: "Orange", value: "orange" },
        { text: "Violette", value: "purple" }

    ];

    clientTypes = ClientTypesList;

    selectedColor: { text: string, value: string } = this.colors[0];

    @Input()
    poiItems: PoiClient[] = [];

    poiDropdownSettings = {}

    constructor(private mapService: MapService, private dataManagementService: DataManagementService, private geocodingService: GeocodingService, public toastr: ToastrService) {
    }

    ngOnInit() {
        this.poiDropdownSettings = {
            singleSelection: false,
            idField: 'idPointClient',
            textField: 'name',
            enableCheckAll: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            allowSearchFilter: true
        };
    }

    onItemSelect(item: PoiClient) {
        let point = this.poiItems.filter(p => p.idPointClient == item.idPointClient)[0];
        let isInside = this.geocodingService.insidePolygon(point.coordinate, this.poiClient.hash);
        if (!isInside) {
            this.poiClient.myPois = this.poiClient.myPois.filter(p => p.idPointClient != point.idPointClient)
            this.toastr.warning(point.name + " est en dehors de cette zone", "Warning");
            return;
        }
    }

    addPoiClient() {

        if (this.poiClient.type == 'MARKER' || this.poiClient.type == 0){
            this.poiClient.imageUri = this.setImageValue(this.poiClient.clientType);
            this.poiClient.myPois = [];
        }
        else this.poiClient.imageUri = this.selectedImage.value;

        this.poiClient.plyColor = this.selectedColor.value;
        this.dataManagementService.addPointClient(this.poiClient).subscribe(poiClient => {

            this.drawPointInterest(poiClient);

            if (poiClient.type == "MARKER") {
                this.poiItems.push(poiClient);
                let circle = L.circle(this.poiClient.coordinate, {
                    color: 'red',
                    fillColor: '#f03',
                    fillOpacity: 0.1,
                    radius: this.poiClient.ray
                });
                this.mapService.addCircle(circle);

                setTimeout(() => {
                    this.mapService.removeCirclesFromMap();
                }, 3000);
            }
            this.dataManagementService.pointClient!.push(this.poiClient);
            this.toastr.success("Client ajouté !", "Success");
            this.poiClientWasAdded.emit(true);
        }, (err) => {
            this.toastr.error("Client non ajouté !", "Success");
        });

    }

    setImageValue(type: any): string {
        if (type === 'CONCURENT') return this.images[3].value;
        else if (type === 'DEPOT') return this.images[8].value;
        else if (type === 'PROSPECT') return this.images[1].value;
        else if(type === 'CLIENT_GROS') return this.images[12].value;
        else if(type === 'DRIVER') return this.images[11].value;
        else if(type === 'CONCESSIONNAIRE') return this.images[13].value;
        else if(type === 'INTERMEDIAIRE') return this.images[14].value;
        return this.images[2].value;
    }

    updatePoiClient() {

        this.loader = true;
        if (this.poiClient.type == 'MARKER' || this.poiClient.type == 0){
            this.poiClient.imageUri = this.setImageValue(this.poiClient.clientType);
            this.poiClient.myPois = [];
        }
        else this.poiClient.imageUri = this.selectedImage.value;

        this.poiClient.plyColor = this.selectedColor.value;

        this.dataManagementService.updatePointClient(this.poiClient).subscribe(poiClient => {

            this.mapService.removeMarkersFromMap();
            this.mapService.removeMarkersPoiFromMap();
            this.mapService.removePolygonsPoiFromMap();

            this.dataManagementService.pointClient.map(poi => {
                if (poi.idPointClient == poiClient.idPointClient) {
                    poi = poiClient;
                }
            });

            this.drawPointInterest(poiClient);
            this.loader = false;
            this.toastr.success("Client modifié !", "Success");
            this.poiClientWasUpdated.emit(true);
        }, (err) => {
            this.toastr.error("Client non modifié !", "Success");
        });
    }

    drawPointInterest(poiClient: PoiClient) {
        let popup = "<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" + poiClient.name + "</strong><br><hr><b>Adresse : " + poiClient.address + "</b>";
        let marker: L.Marker<any> | null= null;

        if (poiClient.type == "POLYGON") {
            let polygon = new Polygon(poiClient.decode);
            /** Change Polygon color **/
            polygon.setStyle({ fillColor: this.poiClient.plyColor, color: this.poiClient.plyColor, weight: 2 });
            this.mapService.addPolygonPoi(polygon);
        }

        marker = new Marker(poiClient.coordinate);
        marker.on("click", () => {
            this.mapService.map.setView(poiClient.coordinate, 17);
        });

        marker.on("mouseover", () => {
            marker.openPopup();
        });

        marker.bindPopup(popup);
        marker.setIcon(new Icon({
            iconUrl: imagesPOIDir + poiClient.imageUri,
            iconAnchor: [-2, 10],
            popupAnchor: [10, -25]
        }));

        this.mapService.addMarkerPoi(marker);
    }
}
