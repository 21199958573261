<div class="button-container" >  
  <div class="detail-trajet trajet" (click)="goToSimulation()">
    <div
      class="titles"
      width="8%"
      data-toggle="tooltip"
      title="{{ childDetail?.startAddress }}">
      {{ childDetail?.startAddress | slice : 0 : 15 }}
    </div>

    <div class="duree-time-with-icon">
      <div 
        class="temps"
        width="8%"
        data-toggle="tooltip"
        title="{{ childDetail?.beginPathTime | date }}"
      >
        {{ childDetail?.beginPathTime | date : "HH:mm" }}
      </div>
      <div class="depart-icon">
        <img
          src="assets/icons/realtime/km-jour/location-green.png"
          class="icon"
          alt="My Icon"
        />
      </div>
    </div>
    <div class="duree-time">
      <div class="temps-info">
        <span
          width="8%"
          data-toggle="tooltip"
          >{{ childDetail?.distanceDriven | number : "1.0-2" }} Km</span
        >
        &nbsp; &nbsp; &nbsp;
        {{ childDetail.pathDuration | durationSec }}  &nbsp;  &nbsp; &nbsp;{{
          childDetail?.maxSpeed
        }}
        Km/h
      </div>
      <div class="trajet-time">
        <hr />
      </div>
      <div class="bottom-text">
        <div 
          class="temps-info-fms" 
          *ngIf="useFms"
          width="8%"
          data-toggle="tooltip"
          title="{{ calculeConsumation(childDetail?.consomation) }}"
          >
          <img
          src="assets/icons/realtime/km-jour/gas-station.png"
          class="parking"
          alt="My Icon"
          />
          <span  *ngIf="childDetail.ful > 0">{{ childDetail.ful | number : "1.0-2" }} L</span>
          <span *ngIf="childDetail.ful === 0">PC</span>
        </div>
        <div class="temps-info ">
          <img
          src="assets/icons/realtime/km-jour/parking.png"
          class="parking"
          alt="My Icon"
          />
          {{ childDetail.stopDuration| durationSec }}
        </div>
      </div> 
    </div>
    <div class="duree-time-with-icon"> 
      <div class="temps"
          width="8%"
          data-toggle="tooltip"
          title="{{ childDetail?.endPathTime | date }}"
        >
        {{ childDetail?.endPathTime | date : "HH:mm" }}
      </div>
      <div class="arr-icon">
        <img
          src="assets/icons/realtime/km-jour/location-red.png"
          class="icon"
          alt="My Icon"
        />
      </div>
    </div>
    <div
      class="titles"
      width="8%"
      data-toggle="tooltip"
      title="{{ childDetail?.endAddress }}"
    >
      {{ childDetail?.endAddress | slice : 0 : 15 }}
    </div>
  </div>
</div>
