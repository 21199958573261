<div class="row" style="font-family: 'Inconsolata', monospace;">

  <div class="col-md-7">

      <app-driver-mission-form [mode]="'ADD'" [isCollabsed]="true">

      </app-driver-mission-form>

  </div>

  <div class="col-md-5 style-1" *ngIf="accounts && accounts?.length > 0">
      <app-driver-mission-form *ngFor="let account of accounts" [user]="account" [mode]="'SHOW'" [isCollabsed]="false">

      </app-driver-mission-form>
  </div>

  <div class="col-md-5" *ngIf="(!accounts || accounts?.length == 0) && !loading">
      <div class="alert alert-warning alert-dismissible">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <h4><i class="icon fa fa-info"></i> Info !</h4>
          vous avez pas de compte "mission" pour instant..!
      </div>
  </div>

  <div class="col-md-5" *ngIf="loading">
      <div class="alert alert-success alert-dismissible">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <h4><i class="icon fa fa-info"></i> Info !</h4>
          Chargement..!
      </div>
  </div>

</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>