<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<section>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-car"></i> <span>Missions |</span>

      <button type="button" class="btn btn-primary btn-sm" (click)="openMissionForm()">
        <i style=" color: white;" class="fa fa-plus-circle add" aria-hidden="true"></i>
        Ajouter
      </button>
    </div>
    <!-- CARD BODY -->
    <div class="card-body p-0">
      <div class="app">
        <div class="main">
          <div class="container p-0">

            <div class="messages">
              <form>
                <div class="inner-addon left-addon">
                  <i class="fa fa-search"></i>
                  <input type="search" class="search" placeholder="Rechercher..." #searchTerm />
                </div>
              </form>

              <!-- MISSIONS ITEMS -->
              <ul class="message-list">
                <li *ngFor="let mission of missions | filterBy : {label : searchTerm.value}"
                  [ngClass]="{'active': selectedMission?.id == mission.id}" (click)="selectedMission = mission">
                  <div class="preview">
                    <h5>{{mission?.label}} <small>{{mission?.createAt | date:'dd-MM-yy'}}</small></h5>
                    <p>Trajets: {{mission?.routeLegs?.length}}</p>
                  </div>
                </li>
              </ul>
              <!-- END MISSIONS ITEMS -->
            </div>

            <!-- MISSIONS BODY -->
            <section *ngIf="selectedMission" class="message">
              <h3>{{selectedMission?.label}}</h3>


              <div class="option-add">
                <button type="button" class="btn btn-success" title="Modifier" (click)="openMissionEditForm()">
                  <i class="fa fa-edit" style="padding-right: 5px;" aria-hidden="true"></i>Modifier</button>
              </div>

              <div class="option-delete">
                <button type="button" class="btn btn-danger" title="Supprimer" (click)="onConfirmDelete()">
                  <i class="fa fa-minus-circle" style="padding-right: 5px;" aria-hidden="true"></i>Supprimer</button>
              </div>

              <div class="meta-data">
                <div class="table-responsive">
                  <table class="table table-sm tableFixHead ">
                    <thead>
                      <tr>
                        <th>Date création</th>
                        <th>Trajets</th>
                        <th>Poi de départ</th>
                        <th>Poi d'arrivé</th>
                        <th>Kilométrage</th>
                        <th style="width: 25%;">Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{selectedMission?.createAt | date:'dd-MM-yy, HH:mm'}}</td>
                        <td>{{selectedMission?.routeLegs?.length}}</td>
                        <td><b>{{selectedMission?.routeLegs[0]?.labelPOISource}}</b></td>
                        <td><b>{{selectedMission?.routeLegs[selectedMission?.routeLegs?.length -
                            1]?.labelPOITarget}}</b></td>
                        <td>{{selectedMission?.travelDistance | number:'.0-2'}} Km</td>
                        <td>{{selectedMission?.travelDuration | timediff}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="body">
                <!-- ROUTES -->
                <div class="panel-group" id="accordion">
                  <div *ngFor="let route of selectedMission?.routeLegs; let i = index" class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#panel{{route.id}}">Trajet {{i + 1}} :
                          <b>{{route?.labelPOISource}}</b> - <b>{{route?.labelPOITarget}}</b></a>
                      </h4>
                    </div>
                    <div class="panel-collapse collapse" id="panel{{route.id}}">
                      <div class="panel-body">
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <th>Poi de départ</th>
                              <th>Poi d'arrivé</th>
                              <th>Kilométrage</th>
                              <th>Duration</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><b>{{route?.labelPOISource}}</b></td>
                              <td><b>{{route?.labelPOITarget}}</b></td>
                              <td>{{route?.travelDistance}} Km</td>
                              <td>{{route?.travelDuration | timediff}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END ROUTES -->
              </div>
            </section>
            <!-- END MISSIONS BODY -->
          </div>
        </div>
      </div>
    </div>
    <!-- END CARD BODY -->
  </div>
</section>



<!-- ========================================== DashBord Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #missionFormModal="bs-modal" bsModal role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- MODAL HEADER -->
      <div class="modal-header">
        <button type="button" class="close" (click)="missionFormModalhide()">&times;</button>
        <h4 class="modal-title"><i class="fa fa-plus-circle" aria-hidden="true"></i> Nouvelle mission</h4>
      </div>
      <!-- END MODAL HEADER -->

      <!-- MODAL BODY -->
      <div class="modal-body">
        <div class="box-body">
          <app-mission-form [mode]="mode" [mission_]="missionForm" (missionSaved)="onMissionSaved($event)"></app-mission-form>
        </div>
      </div>
      <!-- END MODAL BODY -->

      <!-- MODAL FOOTER -->
      <div class="box-footer">
        <button (click)="missionFormModalhide()" class="btn btn-default pull-right">
          Fermer
        </button>
      </div>
      <!-- END MODAL FOOTER -->

    </div>
  </div>
</div>
<!-- ========================================== DashBord Modal (END) ====================================================-->


<!-- ============================== DELETE MODAL (START) ========================== -->
<div class="modal fade" tabindex="-1" #missionDeleteModal="bs-modal" bsModal role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="missionDeleteModalhide()">&times;</button>
        <h4 class="modal-title">
          <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Suppression !
          </h4>
          <span>Êtes-vous sûr de supprimer la mission ?</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="missionDeleteModalhide()">
          Annuler
        </button>
        <button type="button" class="btn btn-success" (click)="onDelete()">
          <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
        </button>
      </div>
    </div>
  </div>
</div>
<!-- =============================== DELETE MODAL (END) ============================= -->
