<form class="form-inline">
  <div class="form-group mx-sm-3 mb-2">
    <!-- -->
    <label>Nombre de km </label>
    <select *ngIf="value1AlertSelectMode" class="form-control" [(ngModel)]="value1" name="value1" (ngModelChange)="chooseKm($event, 0)">
      <option *ngFor="let km of alertManagementService.km" [ngValue]="km" style="font-size: 14px;" [ngClass]="{'autre': km == 'Autre..'}">
        {{km}}
      </option>
      <option *ngIf="isNewAlertConfig(value1)" [selected]="true" [value]="value1">
        {{value1}}</option>
    </select>
    <input *ngIf="!value1AlertSelectMode" type="number" min="1" name="value1" [(ngModel)]="value1" class="form-control" placeholder="KM">

    <i *ngIf="!value1AlertSelectMode" class="fa fa-times" style="color: #aa1111" (click)="value1AlertSelectMode = true;value1='1000'"></i>

  </div>

  <div style="margin: 10px 0 10px 0" *ngIf="showAdvancedSettings1" (click)="collapseAdvancedSettings()">
    <i class="fa fa-plus-square"></i>
    <span class="link">paramtrage avancé</span>
  </div>

  <div style="margin: 10px 0 10px 0" *ngIf="!showAdvancedSettings1" (click)="collapseAdvancedSettings()">
    <i class="fa fa-minus-square"></i>
    <span class="link">masquer paramtrage avancé</span>
  </div>
 
  <div *ngIf="showAdvancedSettings1">

    <table style="border-spacing: 10px;border-collapse: separate;">
      <tr>
        <td>H moteur</td>
        <td>
          <mat-slide-toggle [(ngModel)]="engineOilAlertConfig.isActive" name="engineOil" [labelPosition]="'before'"></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="engineOilAlertConfig.isActive">
            <label>Alerter avant </label>
            <select *ngIf="engineOilAlertSelectMode" class="form-control" [(ngModel)]="engineOilAlertConfig.value1" name="engineOilDay" (ngModelChange)="chooseKm($event, 1)">
              <option *ngFor="let km of alertManagementService.km" [ngValue]="km" style="font-size: 14px;" [ngClass]="{'autre': km == 'Autre..'}">
                {{km}}
              </option>
              <option *ngIf="isNewAlertConfig(engineOilAlertConfig.value1)" [selected]="true" [value]="engineOilAlertConfig.value1">
                {{engineOilAlertConfig.value1}}</option>
            </select>
            <input *ngIf="!engineOilAlertSelectMode" type="number" min="1" name="engineOilDay" [(ngModel)]="engineOilAlertConfig.value1" class="form-control" placeholder="KM">

            <i *ngIf="!engineOilAlertSelectMode" class="fa fa-times" style="color: #aa1111" (click)="engineOilAlertSelectMode = true;engineOilAlertConfig.value1='100'"></i>

            <b>(KM)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>F Huile</td>
        <td>
          <mat-slide-toggle [(ngModel)]="oilFAlertConfig.isActive" name="oilF" [labelPosition]="'before'"></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="oilFAlertConfig.isActive">
            <label>Alerter avant </label>
            <select *ngIf="oilFAlertSelectMode" class="form-control" [(ngModel)]="oilFAlertConfig.value1" name="oilFDay" (ngModelChange)="chooseKm($event, 2)">
              <option *ngFor="let km of alertManagementService.km" [ngValue]="km" style="font-size: 14px;" [ngClass]="{'autre': km == 'Autre..'}">
                {{km}}
              </option>
              <option *ngIf="isNewAlertConfig(oilFAlertConfig.value1)" [selected]="true" [value]="oilFAlertConfig.value1">
                {{oilFAlertConfig.value1}}</option>
            </select>
            <input *ngIf="!oilFAlertSelectMode" type="number" min="1" name="oilFDay" [(ngModel)]="oilFAlertConfig.value1" class="form-control" placeholder="KM">

            <i *ngIf="!oilFAlertSelectMode" class="fa fa-times" style="color: #aa1111" (click)="oilFAlertSelectMode = true;oilFAlertConfig.value1='100'"></i>
          
            <b>(KM)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>F Air</td>
        <td>
          <mat-slide-toggle [(ngModel)]="airFAlertConfig.isActive" name="airF" [labelPosition]="'before'"></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="airFAlertConfig.isActive">
            <label>Alerter avant </label>
            <select *ngIf="airFAlertSelectMode" class="form-control" [(ngModel)]="airFAlertConfig.value1" name="airFDay" (ngModelChange)="chooseKm($event, 3)">
              <option *ngFor="let km of alertManagementService.km" [ngValue]="km" style="font-size: 14px;" [ngClass]="{'autre': km == 'Autre..'}">
                {{km}}
              </option>
              <option *ngIf="isNewAlertConfig(airFAlertConfig.value1)" [selected]="true" [value]="airFAlertConfig.value1">
                {{airFAlertConfig.value1}}</option>
            </select>
            <input *ngIf="!airFAlertSelectMode" type="number" min="1" name="airFDay" [(ngModel)]="airFAlertConfig.value1" class="form-control" placeholder="KM">

            <i *ngIf="!airFAlertSelectMode" class="fa fa-times" style="color: #aa1111" (click)="airFAlertSelectMode = true;airFAlertConfig.value1='100'"></i>
          
            <b>(KM)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>F Carburant</td>
        <td>
          <mat-slide-toggle [(ngModel)]="carbFAlertConfig.isActive" name="carbF" [labelPosition]="'before'" ></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="carbFAlertConfig.isActive">
            <label>Alerter avant </label>
            <select *ngIf="carbAlertSelectMode" class="form-control" [(ngModel)]="carbFAlertConfig.value1" name="carbFDay" (ngModelChange)="chooseKm($event, 4)">
              <option *ngFor="let km of alertManagementService.km" [ngValue]="km" style="font-size: 14px;" [ngClass]="{'autre': km == 'Autre..'}">
                {{km}}
              </option>
              <option *ngIf="isNewAlertConfig(carbFAlertConfig.value1)" [selected]="true" [value]="carbFAlertConfig.value1">
                {{carbFAlertConfig.value1}}</option>
            </select>
            <input *ngIf="!carbAlertSelectMode" type="number" min="1" name="carbFDay" [(ngModel)]="carbFAlertConfig.value1" class="form-control" placeholder="KM">

            <i *ngIf="!carbAlertSelectMode" class="fa fa-times" style="color: #aa1111" (click)="carbAlertSelectMode = true;carbFAlertConfig.value1='100'"></i>
          
            <b>(KM)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>F Separator</td>
        <td>
          <mat-slide-toggle [(ngModel)]="separatorFAlertConfig.isActive" name="separatorF" [labelPosition]="'before'"></mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="separatorFAlertConfig.isActive">
            <label>Alerter avant </label>
            <select *ngIf="separatorFAlertSelectMode" class="form-control" [(ngModel)]="separatorFAlertConfig.value1" name="separatorFDay" (ngModelChange)="chooseKm($event, 5)">
              <option *ngFor="let km of alertManagementService.km" [ngValue]="km" style="font-size: 14px;" [ngClass]="{'autre': km == 'Autre..'}">
                {{km}}
              </option>
              <option *ngIf="isNewAlertConfig(separatorFAlertConfig.value1)" [selected]="true" [value]="separatorFAlertConfig.value1">
                {{separatorFAlertConfig.value1}}</option>
            </select>
            <input *ngIf="!separatorFAlertSelectMode" type="number" min="1" name="separatorFDay" [(ngModel)]="separatorFAlertConfig.value1" class="form-control" placeholder="KM">

            <i *ngIf="!separatorFAlertSelectMode" class="fa fa-times" style="color: #aa1111" (click)="separatorFAlertSelectMode = true;separatorFAlertConfig.value1='100'"></i>
          
            <b>(KM)</b>
          </span>
        </td>
      </tr>
    </table>

  </div>
  
  <app-email-manager [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails"
    (onChange)="emailSettings($event)"></app-email-manager>

  <br>

  <div class="form-group">
      <label>Activer l'alert</label>
      <div class="input-group">
          <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive" ></mat-slide-toggle>
      </div>
  </div>
 
  <button type="submit" class="btn btn-flat btn-primary pull-right" style="margin-top: 45px;" (click)="save()">
        Sauvegarder
  </button>
  
</form>