import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-management-new',
  templateUrl: './alert-management-new.component.html',
  styleUrls: ['./alert-management-new.component.css']
})
export class AlertManagementNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
