import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RotationTypeComponent } from './rotation-type.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [RotationTypeComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot() // Importing ModalModule if you are using modals in the component
  ],
  exports: [RotationTypeComponent] // Exporting the component if it needs to be used in other modules
})
export class RotationTypeModule {}
