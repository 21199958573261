import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationStr'
})
export class DurationPipe implements PipeTransform {

  // transform(value: number, args?: any): any {

  //   var minutes = Math.floor(value / 60);

  //   var hours = Math.floor(minutes / 60);
  //   minutes -= hours * 60;

  //   var days = Math.floor(hours / 24);
  //   hours -= days * 24;
 
  //   var str = hours + "h" + minutes + "min"

  //   return str;
  // }
  transform(value: number, args?: any): any {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);

    let result = '';

    result= hours + "h" + minutes + "min"

    return result;
  }

}