import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Vehicule, RapportDto, Group } from '../../../data-management/data-management.model';
import { BrakeService } from '../../providers/brake-service';
import { Brake, Provider } from '../../model';
import { imagesPOIDir } from '../../../../global.config';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ImportExportService } from '../../../import-export';

@Component({
    selector: 'app-brake',
    templateUrl: './brake.component.html',
    styleUrls: ['./brake.component.css']
})
export class BrakeComponent implements OnInit, OnDestroy {

    repport: RapportDto = new RapportDto();
    /**
     *  3 modal : Edit/add Modal Import/export Modal
     * */
    @ViewChild('addEditBrakeModal', { static: false }) addEditBrakeModal: ModalDirective;
    @ViewChild('myModalImport', { static: false }) myModalImport: ModalDirective;
    @ViewChild('myModalExport', { static: false }) myModalExport: ModalDirective;
    @ViewChild('importModal', { static: false }) importModal: ModalDirective;


    @ViewChild('brakeDeleteModal', { static: false })
    brakeDeleteModal: ModalDirective;

    getVehiculeWasSelectedSubscription: Subscription;

    getGroupWasSelectedSubscription: Subscription;

    selectedVehicule: Vehicule = new Vehicule();

    selectedGroup: Group = new Group();

    selectedItem = 'BRAKE';

    imagesPOIDir = imagesPOIDir;

    brakes: Brake[] = [];

    brakesId: number[] = [];

    mode = 'ADD';

    currentBrake: Brake = new Brake();

    loading = false;

    isDeleteMultiple: boolean = false;

  searchTerm: string | null = null;

    constructor(private dataManagementService: DataManagementService, private brakeService: BrakeService, public toastr: ToastrService, public exportImport: ImportExportService) {
    }

    ngOnInit() {
        if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
            this.selectedVehicule = this.dataManagementService.selectedVehicule;
            this.selectedGroup = this.dataManagementService.selectedGroup;
            this.loadBrakes();
        }
        this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
            this.selectedVehicule = vehicule;
            this.loadBrakes();
        });
        this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
            this.selectedGroup = group;
        });

    }

    ngOnDestroy() {
        if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
        if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
    }

    /**
     *
     * */
    loadBrakes() {
        if (!this.selectedVehicule.idVehicule) return;
        this.loading = true;
        this.brakeService.getListBatteries(this.selectedVehicule.idVehicule).subscribe(brakes => {
            this.brakes = brakes;
            this.loading = false;
        }, (error) => {
            this.loading = false;
        });
    }

    init() {
        this.currentBrake = new Brake();
    }

    brakeWasAdded(brake: any) {
        this.addEditBrakeModal.hide();
        this.brakes.unshift(brake);
    }

    brakeWasUpdated(brake: any) {

        for (let i = 0; i < this.brakes.length; i++) {
            if (this.brakes[i].id === brake.id) {
                this.brakes[i] = brake;
                break;
            }
        }

        this.addEditBrakeModal.hide();
    }

    /**
     * Afficher modal de confirmation du suppression
     */
    onConfirmDelete(brake: Brake) {
        this.currentBrake = brake;
        if (!this.currentBrake.provider) {
            this.currentBrake.provider = new Provider();
        }
        this.isDeleteMultiple = false;
        this.brakeDeleteModal.show();
    }

    updateBrake(brake: Brake) {
        this.currentBrake = Object.assign({}, brake);
        if (!this.currentBrake.provider) {
            this.currentBrake.provider = new Provider();
        }
        this.mode = 'UPDATE';
        this.addEditBrakeModal.show();
    }

    export() {
        this.repport.type = 'BRAKE';
        this.exportImport.export(this.brakes, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
    }

    itemWasImported(res: boolean) {
        if (res) {
            this.importModal.hide();
            this.loadBrakes();
        }
    }

    /**
     * Event confirm delete multiple
     */
    onConfirmDeleteMultiple() {
        if (this.brakesId.length == 0) {
            this.toastr.warning("Veuillez choisir des freins à supprimer !");
            return;
        }
        this.isDeleteMultiple = true;
        this.brakeDeleteModal.show();
    }

    /**
     * Event delete
     */
    onDelete() {
        if (this.isDeleteMultiple) {
            this.deleteMultiple();
        } else {
            this.delete();
        }
    }


    /**
     * Suppression du batterie
     */
    delete() {
        this.loading = true;
        this.brakeService.delete(this.currentBrake.id).subscribe(response => {

            if (response) {
                this.brakes = this.brakes.filter(battery => battery.id != this.currentBrake.id);
                this.brakesId = this.brakesId.filter(brakeId => brakeId != this.currentBrake.id);
                this.toastr.success('Frein informations bien supprimer !', 'info');
            } else {
                this.toastr.error('Le frein n\'a pas supprimé correctement !', 'Erreur');
            }

            this.brakeDeleteModal.hide();
            this.loading = false;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * Suppression multiple du pneu
     */
    deleteMultiple() {
        this.loading = true;
        this.brakeService.deleteMultiple(this.brakesId).subscribe(response => {

            if (response) {
                for (let brakeId of this.brakesId) {
                    this.brakes = this.brakes.filter(brake => brake.id != brakeId);
                }
                this.brakesId = [];
                this.toastr.success('Suppression effectuée avec succès.', 'info');
            } else {
                this.toastr.error('Le frein n\'a pas supprimé correctement !', 'Erreur');
            }

            this.brakeDeleteModal.hide();
            this.loading = false;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * Event search
     */
    getBrakes(): Brake[] {
        if ((this.searchTerm) && (this.searchTerm.length > 0)) {
            return this.brakes.filter(battery => (battery.provider) && (battery.provider.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
        } else {
            return this.brakes;
        }
    }


    /**
     * Event check row for delete multiple
     */
    onChecked(id: number) {
        if (this.brakesId.find(brakeId => brakeId == id)) {
            this.brakesId = this.brakesId.filter(brakeId => brakeId != id);
        } else {
            this.brakesId.push(id);
        }
    }

    /**
     * Event check all row for delete multiple
     */
    onCheckedAll() {
        if (this.brakesId.length == this.brakes.length) {
            this.brakesId = [];
        } else {
            this.brakesId = [];
            for (let brake of this.brakes) {
                this.brakesId.push(brake.id);
            }
        }
    }

    /**
     * check if id exists in array,
     */
    isCheckedInList(id: number): boolean {
        return this.brakesId.find(brakeId => brakeId == id) ? true : false;
    }
}
