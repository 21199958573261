import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupFormComponent } from './group-form.component'; // Adjust the path if necessary
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // If you're using reactive forms
import { ToastrModule } from 'ngx-toastr'; // If you're using Toastr for notifications
import { GroupService } from '../../providers/group.service'; // Ensure path is correct
import { VehiculeService } from '../../providers/vehicule.service'; // Ensure path is correct
import { NgxLoadingModule } from 'ngx-loading';
import { NgSelectModule } from '@ng-select/ng-select';
import { NoValuePipeModule } from 'src/app/pipes/no-value.module';
import { AvatarModule } from 'ngx-avatar';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    GroupFormComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, // Import if using reactive forms
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    NoValuePipeModule,
    AvatarModule,
    RouterModule

  ],
  providers: [
    GroupService, // Provide any services this component uses
    VehiculeService, // Provide the vehicule service if needed
  ],
  exports: [
    GroupFormComponent // Export if you want to use this component outside of this module
  ]
})
export class GroupFormModule { }
