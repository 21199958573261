import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { rapportsDir } from '../../../global.config';
import { ImportFileDto } from '../../data-management/data-management.model';
import { DataManagementService } from '../../data-management/data-management.service';
import { ImportExportService } from '../../import-export/import-export.service';

@Component({
  selector: 'app-import-export-forms',
  templateUrl: './import-export-forms.component.html',
  styleUrls: ['./import-export-forms.component.css']
})
export class ImportExportFormsComponent implements OnInit {

  /**import options */
  /**selected vehicule */
  @Input()
  vehiculeMatricule: string = '';

  @Input()
  groupId: number = null;

  @Output()
  importedItem: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  clientType: string = '';

  /**selected item */
  selecteditem: string = '';

  importData: ImportFileDto = new ImportFileDto();
  isLoading: Boolean = false;
  templateDownoaldLocation: string = rapportsDir;
  template = '';
  selectedFile: File = null;

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  constructor(private toastr: ToastrService, private service: ImportExportService, private dataManagementService: DataManagementService) { }

  @Input()
  set item(type: any) {
    if (type == null) return;
    this.selecteditem = type;

    if (['LAVAGE','TONNAGE', 'PARCKING', 'TOLL'].indexOf(this.selecteditem.toUpperCase()) != -1) {
      this.template = 'Divers.xlsx';
    } else {

      this.convertType();
    }
  }

  convertType() {
    switch (this.selecteditem) {
      case 'INSURANCE':
        this.template = 'Assurance.xlsx';
        break;
      case 'VISIT':
        this.template = 'Visite.xlsx';
        break;
      case 'ROAD_TAXES':
        this.template = 'Vignette.xlsx';
        break;
      case 'OPERATIONAL_CERTIFICATION':
        this.template = 'Certifications_Exploitation.xlsx';
        break;
      case 'PERMIT_CIRCULATION':
        this.template = 'Permis_Circulation.xlsx';
        break;
      case 'METOLOGICA_NOTBOOK':
        this.template = 'Carnets_Metrologique.xlsx';
        break;
      case 'EXTINCTEURS':
        this.template = 'Extincteur.xlsx';
        break;
      case 'FUEL':
        this.template = 'Carburant.xlsx';
        break;
      case 'TIRE':
        this.template = 'Pneu.xlsx';
        break;
      case 'BATTERY':
        this.template = 'Batterie.xlsx';
        break;
      case 'REPARATION':
        this.template = 'Reparation.xlsx';
        break;

      case 'EXTINCTEURS':
        this.template = 'Extincteurs.xlsx';
        break;
      case 'BRAKE':
        this.template = 'Frein.xlsx';
        break;
      case 'SINISTRE':
        this.template = 'Sinistre.xlsx';
        break;
      case 'OFFENSE':
        this.template = 'Infraction.xlsx';
        break;
      case 'POI':
        this.template = 'Pois.xlsx';
        break;
      case 'POICLIENT':
        this.template = 'Pois_Client.xlsx';
        break;
      case 'CA_REALIZATION':
        this.template = 'CA_Realise';
        break;
      case 'C_LOCATION':
        this.template = 'C_Location';
        break;
      default:
        this.template = this.selecteditem + '.xlsx';
        break;

    }

  }


  ngOnInit() {
    this.importData.provideName = 'TOTAL';
  }

  onFileChanged(files: FileList) {
    this.selectedFile = files.item(0);
  }



  onUpload() {
    this.isLoading = true;
    const formData: FormData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    if (this.selecteditem === 'POI' || this.selecteditem === 'POICLIENT') {
      this.groupId = 0;
    }
    this.service.import(formData, this.vehiculeMatricule, this.groupId, this.selecteditem, this.clientType).subscribe(res => {
      if (res) {
        this.toastr.success('Importé');
        this.importedItem.emit(res);
      } else {
        this.toastr.warning('L\'importation échoué !', 'Erreur');
      }
      this.selectedFile = null;
      this.fileInput.nativeElement.value = "";
      this.isLoading = false;
      this.importedItem.emit(res);
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.isLoading = false;
      this.importedItem.emit(false);
    });
  }
}
