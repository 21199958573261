import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule from ngx-bootstrap
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you're using ToastrService
import { VisitComponent } from './visit.component';
import { GeoMarketingService } from '../geo-marketing.service'; // Import your service
import { VisitFormComponent } from '../visit-form/visit-form.component';
import { VisitFormModule } from '../visit-form/visit-form.module';
import { NgxLoadingModule } from 'ngx-loading';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [VisitComponent], // Declare the VisitComponent
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    VisitFormModule,
    FilterPipeModule
  ],
  exports: [VisitComponent], // Export the VisitComponent if it needs to be used in other modules
  providers: [GeoMarketingService] // Provide the GeoMarketingService if needed
})
export class VisitModule {}
