import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as Highcharts from 'highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Schedule } from '../../../schedule';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
  selector: 'app-chart-disponible-flotte',
  templateUrl: './chart-disponible-flotte.component.html',
  styleUrls: ['./chart-disponible-flotte.component.scss'],
})
export class ChartDisponibleFlotteComponent implements OnInit {
  scheduleList_fil: Schedule[] = [];
  scheduleList_kk: Schedule[] = [];
  matricule: Set<String> = new Set();

  @ViewChild('disponibleFlotte', { static: false }) disponibleFlotte: ModalDirective;

  options: Object | null = null;
  optionsModal: Object | null = null;
  constructor() {}

  ngOnInit() {
    this.flotte_disponible(this.scheduleList_fil);
  }

  showGraphDisponibleFlotte() {
    this.disponibleFlotte.show();
  }

  // calculateMatriculeCount = (type, submark, inactive) => {
  //   const uniqueMatricules = new Set();
  //
  //   return this.scheduleList_fil.filter((obj) =>
  //     (!obj.type || obj.type.toLowerCase() !== type.toLowerCase()) &&
  //     (!obj.submark || obj.submark.toLowerCase() === submark.toLowerCase()) &&
  //     (!inactive || obj.active) &&
  //     uniqueMatricules.has(obj.matricule)
  //       ? false
  //       : uniqueMatricules.add(obj.matricule)
  //   ).length;
  // };

  flotte_disponible(scheduleList_fil: Schedule[]) {
    this.scheduleList_fil = scheduleList_fil;
    this.scheduleList_kk = [];
    const subMarkTotalsMap = new Map<
      string,
      { totalDisponibilite: number; totalPlanifie: number }
    >();
    const matriculeSet = new Map<
      string,
      { disponibiliteIndicator: number; planifieIndicator: number }
    >();
    this.matricule = new Set(
      this.scheduleList_fil.map((s) => {
        return s.matricule;
      })
    );

    this.matricule.forEach((mat) => {
      let schedules = this.scheduleList_fil.filter((g) => g.matricule == mat);
      this.scheduleList_kk.push(schedules[schedules.length - 1]);
    });

    this.scheduleList_kk.forEach((item) => {
      const subMark = item.submark;

      if (!subMarkTotalsMap.has(subMark)) {
        subMarkTotalsMap.set(subMark, {
          totalDisponibilite: 0,
          totalPlanifie: 0,
        });
      }

      matriculeSet.set(item.matricule, {
        disponibiliteIndicator: 0,
        planifieIndicator: 0,
      });

      if (item.type !== 'BreakDown') {
        subMarkTotalsMap.get(subMark).totalDisponibilite += 1;
        matriculeSet.get(item.matricule).disponibiliteIndicator += 1;
        if (item.type !== 'Available') {
          subMarkTotalsMap.get(subMark).totalPlanifie += 1;
          matriculeSet.get(item.matricule).planifieIndicator += 1;
        }
      }
    });
    const chartData = [
      {
        name: 'Camion Planifié',
        type: 'column',
        data: Array.from(subMarkTotalsMap.entries()).map(
          ([subMark, totals]) => ({
            name: subMark,
            y: totals.totalPlanifie,
            drilldown: `${subMark}_DP`,
          })
        ),
      },
      {
        name: 'Camion Disponible',
        type: 'spline',
        data: Array.from(subMarkTotalsMap.entries()).map(
          ([subMark, totals]) => ({
            name: subMark,
            y: totals.totalDisponibilite,
            drilldown: `${subMark}_DP`,
          })
        ),
      },
      {
        type: 'pie',
        name: 'Taux',
        data: Array.from(subMarkTotalsMap.entries()).map(
          ([subMark, totals], index) => ({
            name: subMark,
            y: parseFloat(
              (
                (totals.totalPlanifie * 100) /
                this.scheduleList_kk.length
              ).toFixed(0)
            ),
          })
        ),
        center: [200, 20],
        size: 50,
        innerSize: '0%',
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
      },
    ];

    const drilldown_data = Array.from(subMarkTotalsMap.entries()).map(
      ([subMark, totals]) => {
        return {
          id: `${subMark}_DP`,
          type: 'column',
          name: `${subMark} Disponible/ Planifie`,
          data: this.scheduleList_kk
            .filter((item) => item.submark === subMark)
            .map((item) => ({
              name: item.matricule,
              y:
                matriculeSet.get(item.matricule).disponibiliteIndicator +
                matriculeSet.get(item.matricule).planifieIndicator,
              color: this.getColorBasedOnValue(
                matriculeSet.get(item.matricule).disponibiliteIndicator +
                  matriculeSet.get(item.matricule).planifieIndicator
              ),
            })),
          drilldown: {
            xAxis: {
              type: 'category',
              labels: {
                rotation: 45, // Adjust the rotation angle as needed for each drilldown series
              },
            },
          },
          tooltip: {
            pointFormatter: function (this:any) {
              if (this.y === 2) {
                return `<b> Disponible / Planifie</b>`;
              } else if (this.y === 1) {
                return `<b>Disponible / Non Planifie</b> `;
              } else {
                return `<b>Non Disponible / Non Planifie</b> `;
              }
            },
          },
        };
      }
    );

    const staticLine = {
      name: 'test',
      y: 1,
      color: this.getColorBasedOnValue(1),
    };

    this.setHighCartsLang();

    this.options = {
      chart: {
        zoomType: 'xy',
        height: 260,
      },
      title: {
        text: 'Disponibilité Flotte',
        style: {
          color: '#3c8dbc',
          fontSize: '20px',
        },
        align: 'center',
      },
      xAxis: [
        {
          type: 'category',
          title: {
            text: null,
          },
          labels: {
            rotation: 0,
            useHTML: true,
          },
          //gridLineWidth: 1,
          lineWidth: 0,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: 'Camion Disponible',
          },
        },
        {
          // Secondary yAxis
          title: {
            text: 'Camion Planifié',
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        align: 'left',
        x: 80,
        verticalAlign: 'top',
        y: 60,
        floating: true,
      },
      series: chartData,
      drilldown: {
        series: drilldown_data,
        xAxis: {
          type: 'category',
          labels: {
            rotation: 45, // Adjust the rotation angle as needed
          },
        },
      },
    };

    this.optionsModal = {
      chart: {
        type: 'column',
        height: 300,
      },
      title: {
        text: 'Disponibilité Flotte',
        style: {
          color: '#3c8dbc',
        },
        align: 'center',
      },
      xAxis: {
        type: 'category',
        title: {
          text: null,
        },
        //gridLineWidth: 1,
        //lineWidth: 0,
      },
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: 'Camion Disponible',
          },
        },
        {
          // Secondary yAxis
          title: {
            text: 'Camion Planifié',
          },
          opposite: true,
        },
      ],
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      tooltip: {
        shared: true,
      },
      legend: {
        align: 'left',
        x: 80,
        verticalAlign: 'top',
        y: 60,
        floating: true,
      },
      credits: {
        enabled: false,
      },
      series: chartData,
      drilldown: {
        series: drilldown_data,
        xAxis: {
          type: 'category',
          labels: {
            rotation: 45, // Adjust the rotation angle as needed
          },
        },
      },
    };

    Highcharts.chart('disponibleFlotte', this.options);
    Highcharts.chart('disponibleFlotteModal', this.optionsModal);
  }

  getColorBasedOnValue(value: number): string {
    return value === 2 ? '#e77e22' : '#35a9e7';
  }

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#e77e22',
        '#35a9e7',
        '#861ec9',
        '#7a17e6',
        '#7010f9',
        '#691af3',
        '#6225ed',
        '#5b30e7',
        '#533be1',
        '#4c46db',
        '#4551d5',
        '#3e5ccf',
        '#3667c9',
        '#2f72c3',
        '#277dbd',
        '#1f88b7',
        '#1693b1',
        '#0a9eaa',
        '#03c69b',
        '#00f194',
      ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: "Aucune donnée n'est disponible",
      },
    });
  }
}
