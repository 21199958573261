import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { DeadlinesDto, DeadlinesGroupsDto, Vehicule } from '../model/index';
import { isNumeric } from 'rxjs/util/isNumeric';
import { DeadlinesService } from './deadlines.service';
import { ExportDatatableService } from './export-datatable.service';


@Component({
  selector: 'app-deadlines',
  templateUrl: './deadlines.component.html',
  styleUrls: ['./deadlines.component.css']
})
export class DeadlinesComponent implements AfterViewInit {
  selectedVehicule: Vehicule = new Vehicule();
  @ViewChild('myTable', { static: false }) table: any;
  columns = [{ prop: 'matricule' }, { prop: 'driver' }, { name: 'odo' }, { name: 'air' }, { name: 'oil' }, { name: 'engine' },
  { name: 'fuel' }, { name: 'pneu' }, { name: 'assurance' }, { name: 'vignette' }, { name :'taxecirculation' }, { name: 'visite', sortable: false },
  { name: 'embrayage' }, { name: 'distribution' }];
  rows: DeadlinesGroupsDto[] = new Array();
  deadlinesDto: DeadlinesDto[] = [];
  isLoading: boolean = false;

  constructor(public deadlinesService: DeadlinesService, private exportDatatableService: ExportDatatableService) {
  }

  ngAfterViewInit() {
    //this.deadlinesService.getDeadlines();
  }



  toggleExpandGroup(group: any) {
    this.table.groupHeader.toggleExpandGroup(group);
  }


  onDetailToggle(event:any) {
  }

  getCellClassDate({ row, column, value }: { row: any, column: any, value: any }): any {
    if (isNumeric(value)) {
      if (value < 0) {
        return { 'is-error': true };
      } else if (value < 7) {
        return { 'is-alert': true };
      } else if (value >= 7) {
        return { 'is-correct': true };
      }
    }
  }

  getCellClassKm({ row, column, value }: { row: any, column: any, value: any }): any {
    if (isNumeric(value)) {
      if (value < 0) {
        return { 'is-error': true };
      } else if (value < 200) {
        return { 'is-alert': true };
      } else if (value >= 200) {
        return { 'is-correct': true };
      }
    }
  }


  clickExport() {
    this.exportDatatableService.exportAsExcelFile(this.deadlinesService.rows, "Export.xlsx");
  }
}

