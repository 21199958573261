import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { dns } from 'src/app/global.config';
import { FuelingDashboardDto, MonthlyObjectDto } from 'src/app/client-management/parc-management';
import { DateInterval } from 'src/app/shared/model';

@Injectable()
export class AverageCostOfFuelConsumptionChartService {

  constructor(private _http: HttpClient) { }
  
  public getMonthlyFuelCostByGroup(date: DateInterval, group : number): Observable<FuelingDashboardDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<FuelingDashboardDto[]>(dns + 'fuelings/monthlyTotalCostOfFuelingBygroup/'+group, date, options);
  }
  
  public getMonthlyTotalFuelCostOfVehiculesOfCurrentYear(year : number): Observable<MonthlyObjectDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .get<MonthlyObjectDto[]>(dns + 'fuelings/'+year+'/monthlyTotalFuelCostOfVehicules', options);
  }
  
}
