import { Pipe, PipeTransform } from '@angular/core';
import { Group, Vehicule } from '../client-management/data-management/data-management.model';
import { RealTimeRecord, StateCounts } from '../client-management/realtime/model/real-time.model';

@Pipe({ name: 'groupfilter' })
export class GroupFilterPipe implements PipeTransform {
  transform(groups: Array<Group>, filterParams: { search?: string; filtred?: Record<number, StateCounts>; status?: keyof StateCounts | 'all' }): Group[] {
    const search = filterParams.search ? filterParams.search.toLocaleLowerCase() : '';
    const filtredElements = filterParams.filtred || {};
    const statusFilter = filterParams.status;

    if (!groups) {
      return [];
    }

    return groups.filter(group => {
      if (statusFilter === 'all' || !statusFilter || !filtredElements) {
        // No status filter applied, so just check for the search term in vehicles
        return this.filterVehiclesBySearch(group, search);
      }

      // Assurez-vous que statusFilter est une clé valide dans filtredElements
      const filteredStatusVehicles = filtredElements[group.idGroupe]?.[statusFilter] || [];

      const status = this.filterVehiclesByStatus(group.vehicules, statusFilter, filteredStatusVehicles);
      const word = this.filterVehiclesBySearch(group, search);

      // Apply status filter and search term
      return status && word;
    });
  }

  private filterVehiclesBySearch(group: Group, searchTerm: string): boolean {
    if (group.nom.toLocaleLowerCase().includes(searchTerm)) {
      return true;
    }

    return group.vehicules.some(vehicule => {
      const matricule = vehicule.matricule ? vehicule.matricule.toLocaleLowerCase() : '';
      const alias = vehicule.alias ? vehicule.alias.toLocaleLowerCase() : '';
      const driverFirstName = vehicule.driver && vehicule.driver.firstName
        ? vehicule.driver.firstName.toLocaleLowerCase()
        : '';
      const driverLastName = vehicule.driver && vehicule.driver.lastName
        ? vehicule.driver.lastName.toLocaleLowerCase()
        : '';
      const device = vehicule.device && vehicule.device.idDevice ? vehicule.device.idDevice.toString() : '';

      return (
        matricule.includes(searchTerm) ||
        alias.includes(searchTerm) ||
        driverFirstName.includes(searchTerm) ||
        driverLastName.includes(searchTerm) ||
        device === searchTerm
      );
    });
  }

  private filterVehiclesByStatus(vehicles: Vehicule[], status: keyof StateCounts, filteredStatusVehicles: RealTimeRecord[]): boolean {
    if (!filteredStatusVehicles || filteredStatusVehicles.length === 0) {
      return false;
    }

    return vehicles.some(vehicule => {
      return filteredStatusVehicles.some(filteredVehicle => {
        return vehicule.idDevice === filteredVehicle.idRealTimeRecord;
      });
    });
  }
}
