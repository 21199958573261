import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiculeFormComponent } from './vehicule-form.component'; // Adjust the path if necessary
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // For reactive forms
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker
import { ToastrModule } from 'ngx-toastr'; // For notifications
import { VehiculeService, GroupService, DriverService } from '../../providers'; // Ensure the path is correct
import { SigninService } from 'src/app/authentification/signin/signin.service'; // Ensure the path is correct
import { NgxLoadingModule } from 'ngx-loading';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmailManagerModule } from 'src/app/client-management/alert-management/alert-settings/email-manager/email-manager.module';
import { VehiculeDetailsModule } from './vehicule-details/vehicule-details.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    VehiculeFormComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, // Import ReactiveFormsModule
    BsDatepickerModule.forRoot(), // Import Bootstrap datepicker module
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    MatCheckboxModule,
    NgSelectModule,
    EmailManagerModule,
    VehiculeDetailsModule,
    BrowserAnimationsModule, // Required for Angular Material animations
    MatSlideToggleModule

  ],
  providers: [
    VehiculeService, // Provide the vehicule service
    GroupService, // Provide the group service
    DriverService, // Provide the driver service
    SigninService // Provide the signin service if needed
  ],
  exports: [
    VehiculeFormComponent // Export if you want to use this component outside of this module
  ]
})
export class VehiculeFormModule { }
