import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistical',
  templateUrl: 'statistical.component.html',
  styleUrls: ['statistical.component.css']
})
export class StatisticalComponent implements OnInit {
  chart: any;
  options: Object;
  serieName: any;

  constructor() {}

  ngOnInit() {}
}
