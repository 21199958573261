import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {TransportTypeInfoDto} from "./transport-types";
import {TransportTypeService} from "./transport-type.service";


@Component({
  selector: 'app-transport-type',
  templateUrl: './transport-type.component.html',
  styleUrls: ['./transport-type.component.css']
})
export class TransportTypeComponent implements OnInit {


  // local vars
  loading: boolean;
  globalLoading: boolean = false;
  transportType: TransportTypeInfoDto = new TransportTypeInfoDto();
  @ViewChild('deleteModal', { static: false })
  deleteModal: ModalDirective;

  constructor(private router: Router, private route: ActivatedRoute, public toastr: ToastrService, private transportTypeService: TransportTypeService) {
  }


  ngOnInit() {
    this.loadTransportTypes();
  }

  goToFormPage(mode: any, id: any) {
      this.router.navigate(['form/' + id + "/" + mode], { relativeTo: this.route })
  }

  loadTransportTypes() {
    if (this.transportTypeService.transportTypes.length <= 0) {
      this.loading = true;
      this.transportTypeService.findAllTransportTypes().subscribe(transportTypes => {
        this.transportTypeService.transportTypes = transportTypes;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Type de transport");
      })
    }

  }

  confirmDeleteTransportType(transportType: TransportTypeInfoDto) {
    this.transportType = transportType;
    this.deleteModal.show();
  }

  onDelete() {
    this.loading = true;
    this.transportTypeService.deleteTransportType(this.transportType.idTransportType).subscribe(deleted => {
      this.deleteModal.hide();
      this.loading = false;
      if (deleted) {
        this.transportTypeService.transportTypes = this.transportTypeService.transportTypes.filter(transportType => transportType.idTransportType !== this.transportType.idTransportType);
        this.toastr.success('Type bien supprimer !', 'info');
      } else {
        this.toastr.error("Erreur lors de suppression", "Type de transport");
      }
    }, err => {
      this.loading = false;
      this.toastr.error("Erreur lors de suppression", "Type de transport");
    }, () => {
      this.loading = false;
    })
  }

}
