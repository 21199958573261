<div class="row" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
    <section class="col-lg-12 connectedSortable">
        <div class="nav-tabs-custom">

            <div class="tab-content no-padding">
                <div class="col-lg-12">
                    <div class="box-header page-header with-border">

                        <h3 class="box-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;">
                            <i class="fa fa-list"></i>
                            <span style="text-decoration: underline">Liste des demandes </span>
                        </h3>
                    </div>
                </div>

                <div class="col-lg-12">

                    <app-intervention-list></app-intervention-list>

                </div>

            </div>

        </div>

    </section>
</div>