import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { TechnicalDataService } from "./../technical-data/technical-data.service";
import { Subscription } from "rxjs";
import { Group, Vehicule, RapportDto } from "./../../data-management/data-management.model";
import { DataManagementService } from "./../../data-management/data-management.service";
import {
  TechnicalData,
  DeviceOpt,
  DeviceCarb,
  RepFuelVariation
} from "./../technical-data/technical-data";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

// const Highcharts = require("highcharts/highstock");
import * as Highcharts from 'highcharts';

// import "highcharts/adapters/standalone-framework.src";
import { ImportExportService } from "src/app/client-management/import-export";
import { saveAs as importedSaveAs } from 'file-saver';


interface ISelectItem {
  option: string;
}

interface IChartDataPoint {
  x: number | Date;
  y: number;
}

interface IDataItem {
  y?: number;
}


interface IDeviceOpt {
  useIgnition: number;
  useFuel: number;
  useFms: number;
  useJ1708: number;
}

interface IDropdownItem {
  id: number;
  option: string;
  itemName: string;
}

@Component({
  selector: "app-technicaldata",
  templateUrl: "fuel-data.component.html",
  styleUrls: ["fuel-data.component.css"]
})
export class FuelDataComponent implements OnInit {
  //Chart vars
  chart: any;
  options: Object;
  serieName: any;

  //Device Options
  deviceopt: DeviceOpt;

  //Device Carburant
  devicecarb: DeviceCarb;

  groups: Group[];
  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();
  AllGroups: Subscription;
  vehicule: Vehicule;

  technicaldata: TechnicalData[];

  repFuelVariation: RepFuelVariation[] = [];

  startDate: Date = new Date();
  endDate: Date = new Date();

  //MultiSelelct vars

  dropdownList: IDropdownItem[] = [];
  selectItems: ISelectItem[] = [];
  dropdownSettings = {};

  isResultLoaded: boolean = true;

  volume: number;

  useFuel: boolean = false;

  constructor(
    private technicaldataservice: TechnicalDataService,
    private dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private localeService: BsLocaleService,
    private importExportService: ImportExportService
  ) {

    // Highcharts.setOptions({
    //   global: {
    //     useUTC: false
    //   }
    // });
  }

  ngOnInit() {
    this.localeService.use("fr");

    //init multiselect
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select option",
      selectAllText: "Select Tous",
      unSelectAllText: "UnSelect Tous",
      enableSearchFilter: false,
      classes: "custom-class-multiselect",
      badgeShowLimit: 1
    };

    this.startDate.setTime(this.startDate.getTime());
    this.endDate.setTime(this.endDate.getTime() + 3600000);
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);

    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule!;

      this.getDeviceOpt(this.selectedVehicule.idDevice);
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
              this.getDeviceOpt(this.selectedVehicule.idDevice);
            }
          }
        });
    }
  }

  processSelectItems(): string[] {
    let devopt: string[] = [];

    if (this.selectItems.length > 0) {
      this.selectItems.forEach(itm => {
        if (itm.option === "fuel") {
          this.useFuel = true;
        } else {
          this.useFuel = false;
        }
        devopt.push(itm.option);
      });
    }

    return devopt;
  }


  getDeviceOpt(deviceid: number) {
    if (deviceid <= 0) {
      this.toastr.warning("Id de boitier introuvable !", "Erreur");
      return;
    }
    this.dropdownList = [];
    this.isResultLoaded = false;
    this.technicaldataservice.getDeviceOpt(deviceid).subscribe(response => {
      this.deviceopt = response as DeviceOpt;
      if (this.deviceopt.useIgnition != 0) {
        this.dropdownList.push({
          id: 0,
          option: "ignition",
          itemName: "Ignition"
        });
      }
      if (
        this.deviceopt.useFuel != 0 ||
        this.deviceopt.useFms != 0 ||
        this.deviceopt.useJ1708 != 0
      ) {
        this.dropdownList.push({
          id: 1,
          option: "fuel",
          itemName: "Carburant"
        });
      }
      if (this.deviceopt.useFms != 0 || this.deviceopt.useJ1708 != 0) {
        this.dropdownList.push({
          id: 2,
          option: "tfu",
          itemName: "Carburant Total"
        });
        this.dropdownList.push({
          id: 3,
          option: "accum_odo",
          itemName: "ODOMETRE"
        });
      }
      this.isResultLoaded = true;
    });
  }

  onItemSelect(item: any) {
  }



  displayChart() {
    // Obtenir les options sélectionnées en appelant processSelectItems
    const devopt = this.processSelectItems();

    // Variables pour les options
    let fuel: IChartDataPoint[] = [];
    let ignition: IChartDataPoint[] = [];
    let tfu: IChartDataPoint[] = [];
    let accum_odo: IChartDataPoint[] = [];
    let ok: boolean = false;

    // Récupérer les variations de carburant
    this.technicaldataservice
      .getRepFuelVariation(this.selectedVehicule.idDevice, {
        startDate: this.startDate,
        endDate: this.endDate
      })
      .subscribe(res => {
        this.repFuelVariation = res;
      });

    // Récupérer les données de carburant du dispositif
    this.technicaldataservice
      .getDeviceCarb(this.selectedVehicule.idDevice)
      .subscribe(res => {
        this.devicecarb = res;
        this.volume = this.devicecarb.volume;
      });

    // Récupérer les détails du carburant et remplir toutes les options
    this.technicaldataservice
      .getFuelDetail(
        this.selectedVehicule.idDevice,
        {
          startDate: this.startDate,
          endDate: this.endDate
        },
        devopt
      )
      .subscribe(res => {
        this.technicaldata = res;

        if (this.technicaldata.length === 0) {
          this.toastr.warning("Pas de données à afficher");
        }

        this.technicaldata.forEach(stat => {
          if (stat.fuel !== undefined) {
            if (this.devicecarb.max - this.devicecarb.min > 0) {
              if (
                stat.fuel >= this.devicecarb.min &&
                stat.fuel <= this.devicecarb.max
              ) {
                fuel.push({
                  x: stat.date,
                  y: ((stat.fuel - this.devicecarb.min) / (this.devicecarb.max - this.devicecarb.min)) * this.devicecarb.volume
                });
              }
            } else {
              if (
                stat.fuel >= this.devicecarb.max &&
                stat.fuel <= this.devicecarb.min
              ) {
                fuel.push({
                  x: stat.date,
                  y: ((stat.fuel - this.devicecarb.min) / (this.devicecarb.max - this.devicecarb.min)) * this.devicecarb.volume
                });
              }
            }
          }

          if (stat.ignition !== undefined) {
            ignition.push({ x: stat.date, y: stat.ignition ? 1 : 0 });
            ok = true;
          }

          if (stat.tfu !== undefined && ok) {
            tfu.push({ x: stat.date, y: stat.tfu });
          }

          if (stat.accum_odo !== undefined && ok) {
            accum_odo.push({ x: stat.date, y: stat.accum_odo + this.selectedVehicule.odoOffset });
          }
        });

        // Variables pour les séries et les axes Y
        let serie: any[] = [];
        let yaxis: any[] = [];
        let flags: any[] = [];

        // Correction des données d'ignition
        for (let i = 1; i < ignition.length; i++) {
          if (
            ignition[i].y > ignition[i - 1].y ||
            ignition[i].y < ignition[i - 1].y
          ) {
            ignition[i].x = ignition[i - 1].x;
          }
        }

        // Ajouter des indicateurs (flags)
        for (let j = 0; j < this.repFuelVariation.length; j++) {
          for (let i = 1; i < fuel.length; i++) {
            if (
              fuel[i - 1].x === this.repFuelVariation[j].approStartTime &&
              this.repFuelVariation[j].type === "APPRO"
            ) {
              flags.push({
                type: "flags",
                data: [
                  {
                    x: +fuel[i].x,
                    title: "Appro",
                    text: "Appro Level: " +
                      (this.repFuelVariation[j].approEndFuelLevel - this.repFuelVariation[j].approStartFuelLevel)
                  }
                ],
                linkedTo: "dataseries",
                onSeries: "dataseries",
                shape: "flag",
                showInLegend: false,
                color: "rgb(44, 161, 33)",
                fillColor: "rgb(44, 161, 33)"
              });
            } else if (
              fuel[i - 1].x === this.repFuelVariation[j].approStartTime &&
              this.repFuelVariation[j].type === "THIEF"
            ) {
              flags.push({
                type: "flags",
                data: [
                  {
                    x: +fuel[i].x,
                    title: "Diminution",
                    text: "Diminution Level: " +
                      (this.repFuelVariation[j].approEndFuelLevel - this.repFuelVariation[j].approStartFuelLevel)
                  }
                ],
                linkedTo: "dataseries",
                onSeries: "dataseries",
                shape: "flag",
                showInLegend: false,
                color: "rgb(230, 20, 9)",
                fillColor: "rgb(230, 20, 9)"
              });
            }
          }
        }

        // Remplir toutes les séries et les axes Y
        if (this.ChartSeries(ignition)) {
          yaxis.push({
            title: {
              text: "Ignition",
              style: {
                color: "rgb(255, 127, 0)"
              }
            },
            labels: {
              style: {
                color: "rgb(255, 127, 0)"
              }
            },
            min: 0,
            max: 1,
            alignTicks: false,
            endOnTick: false,
            tickInterval: 1
          });
          serie.push({
            name: "Ignition",
            type: "area",
            color: "rgb(255, 127, 0)",
            data: ignition,
            yAxis: this.PositionYaxis(yaxis, "Ignition")
          });
        }

        if (this.ChartSeries(fuel)) {
          yaxis.push({
            title: {
              text: "Carburant",
              style: {
                color: "rgb(23, 119, 182)"
              }
            },
            labels: {
              format: "{value} L",
              style: {
                color: "rgb(23, 119, 182)"
              }
            },
            min: 0,
            opposite: yaxis.length !== 0
          });
          serie.push({
            name: "Carburant",
            data: fuel,
            id: "dataseries",
            color: "rgb(23, 119, 182)",
            yAxis: this.PositionYaxis(yaxis, "Carburant"),
            tooltip: {
              valueSuffix: "L",
              valueDecimals: 2
            }
          });
          flags.forEach(flag => {
            serie.push(flag);
          });
        }

        if (this.ChartSeries(tfu)) {
          yaxis.push({
            title: {
              text: "Carburant Total",
              style: {
                color: "rgb(107, 57, 156)"
              }
            },
            labels: {
              format: "{value} L",
              style: {
                color: "rgb(107, 57, 156)"
              }
            }
          });
          serie.push({
            name: "Carburant Total",
            data: tfu,
            color: "rgb(107, 57, 156)",
            yAxis: this.PositionYaxis(yaxis, "Carburant Total"),
            tooltip: {
              valueSuffix: "L",
              valueDecimals: 2
            }
          });
        }

        if (this.ChartSeries(accum_odo)) {
          yaxis.push({
            title: {
              text: "ODOMETRE",
              style: {
                color: "rgb(44, 161, 33)"
              }
            },
            labels: {
              format: "{value} KM",
              style: {
                color: "rgb(44, 161, 33)"
              }
            },
            opposite: yaxis.length !== 0
          });
          serie.push({
            name: "Odomètre",
            data: accum_odo,
            color: "rgb(44, 161, 33)",
            yAxis: this.PositionYaxis(yaxis, "ODOMETRE"),
            tooltip: {
              valueSuffix: "KM",
              valueDecimals: 2
            }
          });
        }

        // Créer un nouveau Highcharts
        this.options = new Highcharts.Chart({
          title: { text: "Véhicule: " + this.selectedVehicule.matricule },
          chart: {
            type: "spline",
            renderTo: "container",
            height: 400,
            zoomType: "x"
          },
          xAxis: [
            {
              type: "datetime",
              tickInterval: 3600 * 1000,
              labels: {
                // formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string {
                //   return Highcharts.dateFormat("%d/%m/%Y %H:%M", this.value);
                // }
              },

              crosshair: true
            }
          ],
          yAxis: yaxis,
          plotOptions: {
            series: {
              turboThreshold: 100000
            }
          },
          series: serie
        });
      });
  }

  //Return the position of Yaxis
  PositionYaxis(data: any, option: string) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.text === option) {
        return i;
      }
    }
  }

  //check if the series is empty or not

  ChartSeries(data: IDataItem[] = []): boolean {
    let ok: boolean = false;

    data.forEach((dt: IDataItem) => {
      ok = dt.y !== undefined;
    });

    return ok;
  }

  // saveInstance(chartInstance) {
  //   this.chart = chartInstance;
  // }
  //
  // onSeriesMouseOver(e) {
  //   this.serieName = e.context.name;
  // }

  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      this.selectItems = [];
      this.chooseVehicule(this.selectedVehicule);
    }
  }



  chooseVehicule(vehicule:any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
    this.getDeviceOpt(this.selectedVehicule.idDevice);
    this.selectItems = [];
  }

  /**
   * export data
   */
  loader: boolean = false;
  export() {
    this.loader = true;

    var repport: RapportDto = new RapportDto();
    repport.type = "REP_FUEL_VARIATION";
    repport.dateInterval = { startDate: this.startDate, endDate: this.endDate };
    repport.rapportPayloadDto.idVehicule = this.selectedVehicule.idVehicule;

    this.importExportService.getRepport(repport).subscribe(
      blob => {
        if (blob.size > 0) {
          importedSaveAs(blob, 'Rapport statistique carburant.xlsx');
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        }
        this.loader = false;
      },
      error => {
        this.toastr.warning('Erreur lors de l\'exportation !', 'Error');
        this.loader = false;
      }
    );
  }

}
