import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProviderService } from '../../../providers';
import { ToastrService } from 'ngx-toastr';
import { Provider } from '../../../model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'app-provider-settings-form',
    templateUrl: './provider-settings-form.component.html',
    styleUrls: ['./provider-settings-form.component.css']
})
export class ProviderSettingsFormComponent implements OnInit {

    @Input()
    providerModal: ModalDirective;

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';

    @Input()
    provider: Provider = new Provider();

    @Input()
    providerTypes: Object[] = [];

    @Output()
    providerWasAdded: EventEmitter<Provider> = new EventEmitter<Provider>();

    @Output()
    providerWasUpdated: EventEmitter<Provider> = new EventEmitter<Provider>();

    loading: boolean = false;

    constructor(private providerService: ProviderService, public toastr: ToastrService) { }

    ngOnInit() {}

    /**
     * Ajouter nouveau batterie
     */
    onAdd(form: NgForm) {
        this.loading = true;
        this.providerService.add(this.provider).subscribe(provider => {
            this.toastr.success('Fournisseur informations bien sauvegarder !', 'info');
            this.providerWasAdded.emit(provider);
            this.loading = false;
            form.reset();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * modifier batterie
     */
    onUpdate(form: NgForm) {
        this.loading = true;
        this.providerService.update(this.provider).subscribe(provider => {
            this.toastr.success('Fournisseur informations bien sauvegarder !', 'info');
            this.providerWasUpdated.emit(provider);
            this.loading = false;
            form.reset();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

}
