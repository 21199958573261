<form class="form-inline">

  <app-cron-settings *ngIf="showAdvancedSettings" [cron]="cronAlertConfiguration"></app-cron-settings>

  <br>

  <app-email-manager [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails"
  [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" (onChange)="emailSettings($event)"></app-email-manager>

  <hr>

  <div class="form-group">
    <label>Activer l'alert</label>
    <div class="input-group">
        <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive" ></mat-slide-toggle>
    </div>
</div>

  <button type="submit" class="btn btn-flat btn-primary pull-right" style="margin-top: 45px;" (click)="save()">
    Sauvegarder
</button>
</form>
