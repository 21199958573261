import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { MultiType, Provider, Reparation, Vehicule, Group } from '../../../model/index';
import { MultiTypeService } from '../../../providers';
import { ProviderService } from '../../../providers/provider-service';
import { ReparationService } from '../../../providers/reparation-service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-reparation-form',
    templateUrl: './reparation-form.component.html'
})

export class ReparationFormComponent implements OnInit {

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';

    @Input()
    reparation: Reparation = new Reparation();

    @Input()
    vehicule: Vehicule = new Vehicule();

    @Input()
    group: Group = new Group();

    @Output()
    reparationWasAdded: EventEmitter<Reparation> = new EventEmitter<Reparation>();

    @Output()
    reparationWasUpdated: EventEmitter<Reparation> = new EventEmitter<Reparation>();

    providers: Provider[] = [];

    reparationTypes: MultiType[] = [];

    loading: boolean = false;

    showProvider = false;

    maxDate: Date = new Date();

    constructor(private reparationService: ReparationService, private providerService: ProviderService, private multiTypeService: MultiTypeService, public toastr: ToastrService, private localeService: BsLocaleService) { }

    ngOnInit() {
        this.localeService.use('fr');
        this.loadProviders();
        this.loadTypes();
    }

    /**
     * Charger liste des fournisseurs
     */
    loadProviders() {
        this.providerService.getProviders("MAINTENANCE").subscribe(providers => {
            this.providers = providers;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Charger liste des types
     */
    loadTypes() {
        this.multiTypeService.getListType("REPARATION").subscribe(reparationTypes => {
            this.reparationTypes = reparationTypes;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Ajouter nouveau réparation
     */
    onAdd(form: NgForm) {
        this.loading = true;
        this.reparationService.add(this.reparation, this.vehicule.idVehicule, this.group.idGroupe).subscribe(reparation => {
            this.toastr.success('Réparation informations bien sauvegarder !', 'info');
            this.reparationWasAdded.emit(reparation);
            this.loading = false;
            form.reset();
            this.loadProviders();
            this.loadTypes();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * modifier réparation
     */
    onUpdate(form: NgForm) {
        this.loading = true;
        this.reparationService.update(this.reparation).subscribe(reparation => {
            this.toastr.success('Réparation informations bien sauvegarder !', 'info');
            this.reparationWasUpdated.emit(reparation);
            this.loading = false;
            form.reset();
            this.loadProviders();
            this.loadTypes();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * Action change date  opération
     */
    onChangeOperationTime() {
        if (this.reparation.actualRepairTime < this.reparation.operationTime) {
            this.reparation.actualRepairTime = null;
        }
    }
}
