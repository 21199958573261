import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { RentalAgreementSetting } from './rental-agreement';

@Injectable()
export class RentalAgreementManagementService {

  constructor(private _http: HttpClient) { }

  loadAllRentalAgreementIds() {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Number[]>(dns + 'rental-agreements', { headers: headers });
  }

  exportPDF(rentalId: number, avatarUri: string): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + "locationPdf/?rentalId=" + rentalId +
     "&avatarUri=" + avatarUri, { headers: headers, responseType: 'blob' as 'json' });
  }

  saveSetting(setting : RentalAgreementSetting): Observable<RentalAgreementSetting>{
    let headers = createAuthorizationHeader();
    return this._http.post<RentalAgreementSetting>(dns + "rental-agreements/setting",setting, { headers: headers });
  }

  getSetting(): Observable<RentalAgreementSetting>{
    let headers = createAuthorizationHeader();
    return this._http.get<RentalAgreementSetting>(dns + "rental-agreements/setting", { headers: headers });
  }

}
