<form #offenseForm="ngForm" class="form-horizontal">
    <div class="box-body">

        <!-- ========================== Modal FILEDS (START) ===============================-->

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Date infraction *</label>
            <div class="col-lg-4 col-md-4">
                    <input type="text" class="form-control input-sm" bsDatepicker name="operationTime" [(ngModel)]="operationTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    placeholder="Date sinistre" required autocomplete="off">
            </div>

            <label class="col-sm-2 control-label">Lieu</label>
            <div class="col-lg-4 col-md-4">
                <input name="referenceDADR" [(ngModel)]="_offense.place" maxlength="50" type="text"
                       class="form-control" placeholder="Lieu">
            </div>

        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Coût *</label>
            <div class="col-lg-4 col-md-4">
                <input name="prix" [(ngModel)]="_offense.cost" type="number" class="form-control"
                       required="true" miplaceholder="Coût de réparation">
            </div>

            <label class="col-sm-2 control-label">Payant *</label>
            <div class="col-lg-4 col-md-4">
                <input name="laborCost" [(ngModel)]="_offense.paying" type="text" class="form-control"
                       miplaceholder="Remboursement" required="true">
            </div>

        </div>

        <div class="form-group has-success">

            <label class="col-sm-2 control-label">Observation</label>
            <div class="col-lg-4 col-md-4">
                <textarea name="Observations" [(ngModel)]="_offense.observation" type="text"
                          class="form-control" placeholder="Observations" name="observation"></textarea>
            </div>

            <label class="col-sm-2 control-label">Type d'infraction</label>
            <div class="col-lg-4 col-md-4">

                <select [(ngModel)]="_offense.type" class="form-control select2"
                        name="currentOffense" *ngIf="showOffenseTypesDropDown"
                        (ngModelChange)="onChangeOffenseType($event)">
                    <option *ngFor="let offenseType of offenseTypes"
                            [ngValue]="offenseType"
                            [selected]="offenseType.id == _offense.type.id">{{offenseType.name}}
                    </option>
                </select>

                <div *ngIf="!showOffenseTypesDropDown">
                    <div style="float: left">
                        <input name="referenceDADR"
                               [(ngModel)]="_offense.type.name" maxlength="50" type="text"
                               class="form-control" placeholder="Type d'infraction">
                    </div>
                    <div *ngIf="this.offenseTypes.length > 0" class="col-sm-2"
                         style="color: #9f191f;text-align: center" (click)="toggleDropDown();">
                        <i class="fa fa-times"></i>
                    </div>
                </div>

            </div>

        </div>

        <!-- ========================== Modal FILEDS (END) ===============================-->

        <!-- ========================== Modal FOOTER (START) ===============================-->
        <div class="box-footer">
            <button *ngIf="mode === 'ADD'"
                    [disabled]="!offenseForm.form.valid" class="btn btn-info pull-right" (click)="addOffense()"><i
                    class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
            <button [disabled]="!offenseForm.form.valid" class="btn btn-info pull-right"
                    *ngIf="mode === 'UPDATE'" (click)="updateOffense()"><i class="fa fa-spinner fa-spin"
                                                                            *ngIf="loading"></i> Enregistrer
            </button>
        </div>
        <!-- ========================== Modal FOOTER (END) ===============================-->

    </div>
</form>