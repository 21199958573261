import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CimentMissionTrackingComponent } from './ciment-mission-tracking.component';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if used
import { FormsModule } from '@angular/forms'; // Import FormsModule if using ngModel or forms
import { HttpClientModule } from '@angular/common/http'; // Import HttpClientModule if making HTTP calls
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service'; // Service usage
import { NgxLoadingModule } from 'ngx-loading';
import { TimediffPipeModule } from 'src/app/pipes/timediff.pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    CimentMissionTrackingComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Add ToastrModule for notifications
    FormsModule, // Add if using forms
    HttpClientModule, // Add if making HTTP requests
    NgxLoadingModule.forRoot({}),
    TimediffPipeModule,
    MatTooltipModule,
    TruncateModule

  ],
  exports: [
    CimentMissionTrackingComponent
  ],
  providers: [
    GeocodingService // Register GeocodingService if it is provided at the module level
  ]
})
export class CimentMissionTrackingModule {}
