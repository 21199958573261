<div class="row" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
      <section class="col-lg-12 connectedSortable">
            <div class="nav-tabs-custom">
                  <ul class="nav nav-tabs pull-right">
                        <li *ngIf="isMenara" routerLinkActive="active"><a routerLink="/client/parcSettings/fueling-price" data-toggle="tab">Prix Unitaire</a></li>
                        <li routerLinkActive="active"><a routerLink="/client/parcSettings/fueling-template" data-toggle="tab">Template Carburant</a></li>
                        <li routerLinkActive="active"><a [routerLink]="['/client/parcSettings/types', 'OIL_CHANGE']" data-toggle="tab">Vidange</a></li>
                        <li routerLinkActive="active"><a routerLink="/client/parcSettings/providers" data-toggle="tab">Fournisseur</a></li>
                        <li routerLinkActive="active"><a [routerLink]="['/client/parcSettings/types', 'PAYMENT']" data-toggle="tab">Paiement</a></li>
                        <li routerLinkActive="active"><a [routerLink]="['/client/parcSettings/types', 'REPARATION']" data-toggle="tab">Réparation</a></li>
                        <li routerLinkActive="active"><a [routerLink]="['/client/parcSettings/types', 'CABINET_EXPERTISE']" data-toggle="tab">Sinistres</a></li>
                        <li routerLinkActive="active"><a [routerLink]="['/client/parcSettings/types', 'OFFENSE_TYPE']" data-toggle="tab">Infractions</a></li>
                        <li class="pull-left header"><i class="fa fa-gear"></i> Paramétrage du parc</li>
                  </ul>
                  <div class="tab-content">
                        <div class="tab-pane active">
                              <router-outlet></router-outlet>
                        </div>
                  </div>
            </div>
      </section>
</div>