import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Driver, DriverService } from 'src/app/client-management/data-management';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from "ngx-toastr";
import { DriverCharge, Group } from '../../model/index';
import { DriverChargeService } from '../../providers';

@Component({
    selector: 'app-driver-charge-form',
    templateUrl: './driver-charge-form.component.html'
})

export class DriverChargeFormComponent implements OnInit {

    @Input()
    driverChargeModal: ModalDirective;

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';

    @Input()
    driverCharge: DriverCharge = new DriverCharge();

    @Input()
    group: Group = new Group();

    @Output()
    driverChargeWasAddedOrUpdated: EventEmitter<DriverCharge> = new EventEmitter<DriverCharge>();

    drivers: Driver[] = [];

    loading: boolean = false;

    maxDate: Date = new Date();

    constructor(private driverChargeService: DriverChargeService, private driverService: DriverService, public toastr: ToastrService, private localeService: BsLocaleService) { }

    ngOnInit() {
        this.localeService.use('fr');
        this.loadDrivers();
    }

    /**
     * Charger liste des chauffeurs
     */
    loadDrivers() {
        this.driverService.getDriversLabel().subscribe(drivers => {
            this.drivers = drivers;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Ajouter nouveau charge conducteur
     */
    onAdd(form: NgForm) {
        this.loading = true;
        this.driverChargeService.add(this.driverCharge, this.group.idGroupe).subscribe(driverCharge => {
            this.toastr.success('Charge conducteur informations bien sauvegarder !', 'info');
            this.driverChargeWasAddedOrUpdated.emit(driverCharge);
            this.loading = false;
            form.reset();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * modifier charge conducteur
     */
    onUpdate(form: NgForm) {
        this.loading = true;
        this.driverChargeService.update(this.driverCharge).subscribe(driverCharge => {
            this.toastr.success('Charge conducteur informations bien sauvegarder !', 'info');
            this.driverChargeWasAddedOrUpdated.emit(driverCharge);
            this.loading = false;
            form.reset();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

}
