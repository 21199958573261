import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Brake, Provider } from '../../../model';
import { ProviderService } from '../../../providers/provider-service';
import { BrakeService } from '../../../providers/brake-service';
import { Vehicule, Group } from '../../../../data-management/data-management.model';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-brake-form',
    templateUrl: './brake-form.component.html',
    styleUrls: ['./brake-form.component.css']
})
export class BrakeFormComponent implements OnInit {

    _brake: Brake = new Brake();

    @Input()
    vehicule: Vehicule = new Vehicule();

    @Input()
    group: Group = new Group();

    @Input()
    mode = 'ADD';

    @Output()
    brakeWasAdded: EventEmitter<Brake> = new EventEmitter<Brake>();

    @Output()
    brakeWasUpdated: EventEmitter<Brake> = new EventEmitter<Brake>();

    operationTime: Date = new Date();

    actualRepairTime: Date = new Date();

    showProvider = false;

    listProviders: Provider[] = [];

    loading = false;

    maxDate: Date = new Date();

    constructor(private brakeService: BrakeService, private providerService: ProviderService, public toastr: ToastrService, private localeService: BsLocaleService) {
    }

    ngOnInit() {
        this.localeService.use('fr');
        this.loadProvider();
    }

    @Input()
    set brake(brake: Brake) {
        this._brake = brake;

        // init operation and actualRepear time !
        if (this._brake && this._brake.operationTime) {
            this.operationTime = new Date(this._brake.operationTime);
        } else {
            this.operationTime = new Date();
        }
        if (this._brake && this._brake.actualRepairTime) {
            this.actualRepairTime = new Date(this._brake.actualRepairTime);
        } else {
            this.actualRepairTime = new Date();
        }
    }

    loadProvider() {
        this.providerService.getProviders('MAINTENANCE').subscribe(response => {
            this.listProviders = response;
            if (this.listProviders.length === 0) {
                this.showProvider = true;
            }
        });
    }

    onChangeProvider(providerId: any) {
        this._brake.provider.id = providerId;
        if (providerId === -1) {
            this.showProvider = true;
        }
    }

    addBrake() {
        this.loading = true;
        this._brake.operationTime = this.operationTime.getTime();
        this._brake.actualRepairTime = this.actualRepairTime.getTime();
        this.brakeService.add(this._brake, this.vehicule.idVehicule, this.group.idGroupe).subscribe(brake => {
            this.toastr.success('frein informations bien sauvegarder !', 'info');
            this.brakeWasAdded.emit(brake);
            this.loading = false;
        }, (error) => {
            this.loading = false;
        });
    }

    updateBrake() {
        this.loading = true;
        this._brake.operationTime = this.operationTime.getTime();
        this._brake.actualRepairTime = this.actualRepairTime.getTime();
        this.brakeService.update(this._brake).subscribe(brake => {
            this._brake = brake;
            this.brakeWasUpdated.emit(brake);
            this.toastr.success('frein informations bien sauvegarder !', 'info');
            this.loading = false;
        }, (error) => {
            this.loading = false;
        });
    }

    /**
     * Action change date  opération
     */
    onChangeOperationTime() {
        if (this.actualRepairTime < this.operationTime) {
            this.actualRepairTime = null;
        }
    }
}
