import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassageComponent } from './passage.component';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modal functionality
import { NgxLoadingModule } from 'ngx-loading';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    PassageComponent // Declare the PassageComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Initialize ToastrModule for notifications
    ModalModule.forRoot(),
    OrderPipModule,
    NgxLoadingModule.forRoot({}),
    FilterPipeModule,
    RouterModule
  ],
  exports: [
    PassageComponent // Export the PassageComponent if used in other modules
  ]
})
export class PassageModule {}
