import { Component, OnInit } from '@angular/core';
import { Alert, AlertConfiguration } from '../../alert';
import { DataManagementService } from 'src/app/client-management/data-management';
import { ToastrService } from 'ngx-toastr';
import { AlertManagementService } from '../../alert-management.service';
import { Subscription } from 'rxjs';
import { AlertManagementServiceUtils } from '../../alert-management-utils.service';

@Component({
  selector: 'app-activity-settings',
  templateUrl: './activity-settings.component.html',
  styleUrls: ['./activity-settings.component.css']
})
export class ActivitySettingsComponent implements OnInit {


  public showAdvancedSettings = false;


  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  public loader = false;
  public alert: Alert = new Alert();
  public activityHourAlertConfiguration: AlertConfiguration = new AlertConfiguration();
  public cronAlertConfiguration: AlertConfiguration = new AlertConfiguration();

  public getVehiculeWasSelectedSubscription: Subscription;

  constructor(
    private dataManagementService: DataManagementService,
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService,
    private alertManagementServiceUtils: AlertManagementServiceUtils
  ) {
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService.selectedVehicule!.idDevice
        );
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('ACTIVITY', device)
      .subscribe(
        alert => {
          this.alert = alert;
          this.activityHourAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'ACTIVITY'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'ACTIVITY'
            )[0]
            : this.activityHourAlertConfiguration;

          if (this.activityHourAlertConfiguration == null) {
            this.alertManagementServiceUtils.initializeAlertConfiguration('ACTIVITY');
          }

          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }



  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrer', 'info !');
      },
      error => {
        this.toastr.error('Configuration non enregistrée', 'info !');
      }
    );
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean,enableGlobalConfigToAllVehicules: boolean }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }
  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails, id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }
}
