import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulingFormComponent } from './scheduling-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ScheduleService } from '../schedule.service'; // Import the ScheduleService
import { PassageService } from '../../parametrage/passage'; // Import the PassageService
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { EmailManagerModule } from 'src/app/client-management/alert-management/alert-settings/email-manager/email-manager.module';

@NgModule({
  declarations: [
    SchedulingFormComponent // Declare the SchedulingFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule for template-driven forms
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    SharedPipesModule,
    EmailManagerModule


  ],
  providers: [
    ScheduleService, // Provide ScheduleService
    PassageService // Provide PassageService
  ],
  exports: [
    SchedulingFormComponent // Export the SchedulingFormComponent for use in other modules
  ]
})
export class SchedulingFormModule { }
