import { Component, OnInit } from '@angular/core';
import { SigninService } from '../../authentification/signin/signin.service';
import { User } from 'src/app/authentification/signin/credentials';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.css']
})
export class AccountManagementComponent implements OnInit {
  accounts: User[];
  loading = false;

  constructor(private signinService: SigninService) {
    /**event listner for delete account */
    this.signinService.getAccountWasDeleted().subscribe(deletedUser => {
      this.accounts = this.accounts.filter(
        account => account.id !== deletedUser.id
      );
      this.signinService.accounts = this.accounts;
    });

    /**event listner for create account */
    this.signinService.getAccountWasCreated().subscribe(createdUser => {
      this.accounts.unshift(createdUser);
      this.signinService.accounts = this.accounts;
    });

    /**event listner for update account */
    this.signinService.getAccountWasChanged().subscribe(changedUser => {
      for (let i = 0; i < this.signinService.accounts.length; i++) {
        if (this.signinService.accounts[i].id === changedUser.id) {
          this.signinService.accounts[i] = changedUser;
          this.signinService.accounts[i].groups = changedUser.groups;
          this.signinService.accounts[i].vehicules = changedUser.vehicules;
          this.signinService.accounts[i].transportTypes = changedUser.transportTypes;
          this.signinService.accounts[i].passages = changedUser.passages;
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.loading = true;
    if (this.signinService.accounts) {
      this.accounts = this.signinService.accounts;
      this.loading = false;
    } else {
      this.signinService.getAccounts().subscribe(accounts => {
        this.accounts = accounts;
        this.signinService.accounts = accounts;
        this.loading = false;
      });
    }
  }
}
