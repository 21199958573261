import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  pad(num: any, totalChars: any): string {
    let pad = '0';
    let numStr = num.toString();
    while (numStr.length < totalChars) {
      numStr = pad + numStr;
    }
    return numStr;
  }

  changeColor(color: string, ratio: number, darker: boolean): string {
    color = color.trim();

    color = color.replace(
      /^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i,
      '#$1$1$2$2$3$3'
    );

    const difference = Math.round(ratio * 256) * (darker ? -1 : 1);
    const rgb = color.match(new RegExp('^rgba?\\(\\s*' +
      '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
      '\\s*,\\s*' +
      '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
      '\\s*,\\s*' +
      '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
      '(?:\\s*,\\s*' +
      '(0|1|0?\\.\\d+))?' +
      '\\s*\\)$'
      , 'i'));
    const alpha = !!rgb && rgb[4] != null ? rgb[4] : null;

    const decimal = !!rgb ? [rgb[1], rgb[2], rgb[3]] : color.replace(
      /^#?([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])/i,
      function () {
        return parseInt(arguments[1], 16) + ',' +
          parseInt(arguments[2], 16) + ',' +
          parseInt(arguments[3], 16);
      }
    ).split(/,/);

    return !!rgb ?
      'rgb' + (alpha !== null ? 'a' : '') + '(' +
      Math[darker ? 'max' : 'min'](
        parseInt(decimal[0], 10) + difference, darker ? 0 : 255
      ) + ', ' +
      Math[darker ? 'max' : 'min'](
        parseInt(decimal[1], 10) + difference, darker ? 0 : 255
      ) + ', ' +
      Math[darker ? 'max' : 'min'](
        parseInt(decimal[2], 10) + difference, darker ? 0 : 255
      ) +
      (alpha !== null ? ', ' + alpha : '') +
      ')' :
      [
        '#',
        this.pad(Math[darker ? 'max' : 'min'](
          parseInt(decimal[0], 10) + difference, darker ? 0 : 255
        ).toString(16), 2),
        this.pad(Math[darker ? 'max' : 'min'](
          parseInt(decimal[1], 10) + difference, darker ? 0 : 255
        ).toString(16), 2),
        this.pad(Math[darker ? 'max' : 'min'](
          parseInt(decimal[2], 10) + difference, darker ? 0 : 255
        ).toString(16), 2)
      ].join('');
  }

  lighterColor(color: string, ratio: number): string {
    return this.changeColor(color, ratio, false);
  }

  darkerColor(color: string, ratio: number): string {
    return this.changeColor(color, ratio, true);
  }
}

