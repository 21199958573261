import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


import { CarburantDashBordComponent } from './carburant-dashbord.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CarburantDashBordComponent
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    RouterModule
  ],
  providers: [
    ToastrService,
    // Include other services if needed
  ],
  exports: [
    CarburantDashBordComponent
  ]
})
export class CarburantDashbordModule { }
