<ngx-loading [show]="globalLoading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="row">

  <div class="col-md-12">

    <!-- Error Section !-->
    <ng-container *ngIf="error">
      <div class="col-md-12">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
            ×
          </button>
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          {{error.errorMessage}}
        </div>
      </div>
    </ng-container>

    <div class="container">
      <div class=" mt-2 mb-2 text-center   well bgg">
        <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color: white;">
          <i class="fa fa-cog"></i>
          <span>Configurer votre modèle </span>
        </h4>
      </div>
      <div class="col-lg-12 well formBC">
        <div class="row">
          <form #templateForm="ngForm" class="form-horizontal">
            <div class="col-sm-4">

              <!-- row -->
              <div class="form-group mrg">
                <label>Ligne de départ *</label>
                <input [(ngModel)]="fuelingTemplateSetting.rowNumber" name="rowNumber" type="number"
                  placeholder="Ligne de départ" class="form-control" min="1" required>
              </div>

              <!-- matricule -->
              <div class="form-group mrg">
                <label>matricule *</label>
                <input [(ngModel)]="fuelingTemplateSetting.matricule" name="matricule" type="number"
                  placeholder="matricule" class="form-control" min="1" required>
              </div>

              <!-- date -->
              <div class="form-group mrg">
                <label>Date *</label>
                <input [(ngModel)]="fuelingTemplateSetting.operationDate" name="operationDate" type="number" placeholder="date"
                  class="form-control" min="1" required>
              </div>

              <!-- Odomètre -->
              <div class="form-group mrg">
                <label>Odomètre *</label>
                <input [(ngModel)]="fuelingTemplateSetting.odometre" name="odometre" type="number" placeholder="Odomètre"
                  class="form-control" min="1" required>
              </div>

              <!-- Fournisseur -->
              <div class="form-group mrg">
                <label>Fournisseur</label>
                <input [(ngModel)]="fuelingTemplateSetting.provider" name="provider" type="number"
                  placeholder="Fournisseur" class="form-control">
              </div>

            </div>

            <div class="col-sm-4">

              <!-- Quantité -->
              <div class="form-group mrg">
                <label>Quantité *</label>
                <input [(ngModel)]="fuelingTemplateSetting.quantity" name="quantity" type="number"
                  placeholder="Quantité" class="form-control" min="1" required>
              </div>

              <!-- Coût Total -->
              <div class="form-group mrg">
                <label>Coût Total *</label>
                <input [(ngModel)]="fuelingTemplateSetting.cost" name="cost" type="number" placeholder="Coût Total"
                  class="form-control" min="1" required>
              </div>

              <!-- Coût/L -->
              <div class="form-group mrg">
                <label>Coût/L</label>
                <input [(ngModel)]="fuelingTemplateSetting.costUnit" name="costUnit" type="number" placeholder="Coût/L"
                  class="form-control">
              </div>

              <!-- Consommation -->
              <div class="form-group mrg">
                <label>Consommation</label>
                <input [(ngModel)]="fuelingTemplateSetting.consumption" name="consumption" type="number"
                  placeholder="Consommation" class="form-control">
              </div>

              <!-- Référence -->
              <div class="form-group mrg">
                <label>Référence</label>
                <input [(ngModel)]="fuelingTemplateSetting.reference" name="reference" type="number"
                  placeholder="Référence" class="form-control">
              </div>

            </div>

            <div class="col-sm-4">

              <!-- Endroit -->
              <div class="form-group mrg">
                <label>Endroit</label>
                <input [(ngModel)]="fuelingTemplateSetting.place" name="place" type="number" placeholder="Endroit"
                  class="form-control">
              </div>

              <!-- Observations -->
              <div class="form-group mrg">
                <label>Observations</label>
                <input [(ngModel)]="fuelingTemplateSetting.observation" name="place" type="number"
                  placeholder="observation" class="form-control">
              </div>



              <!-- Type Paiement -->
              <div class="form-group mrg">
                <label>Type Paiement</label>
                <input [(ngModel)]="fuelingTemplateSetting.paymentType" name="paymentType" type="number"
                  placeholder="Type Paiement" class="form-control">
              </div>

              <!-- N° Paiement -->
              <div class="form-group mrg">
                <label>N° Paiement</label>
                <input [(ngModel)]="fuelingTemplateSetting.numberPayment" name="numberPayment" type="number"
                  placeholder="N° Paiement" class="form-control">
              </div>

              <!-- numberPayment -->
              <div class="form-group mrg">
                <label>Carte</label>
                <input [(ngModel)]="fuelingTemplateSetting.carte" name="carte" type="number" placeholder="Carte"
                  class="form-control">
              </div>

            </div>

            <!-- ========================== Modal FOOTER (START) ===============================-->
            <div class="box-footer">
              <button (click)="saveTemplateSetting()" class="btn btn-info pull-right" [disabled]="!templateForm.valid">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
              </button>
              <button class=" btn btn-dark" (click)="cancel(templateForm)">
                Annuler
              </button>
            </div>
            <!-- ========================== Modal FOOTER (END) ===============================-->
          </form>
        </div>
      </div>

    </div>
  </div>
</div>