import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using forms in your component
import { PoiClientFormComponent } from './poi-client-form.component';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you are using Toastr notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule if you're using modals
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    PoiClientFormComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule, // Add FormsModule if you are using reactive forms or template-driven forms
    ToastrModule.forRoot(), // Initialize Toastr for notifications
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    PoiClientFormComponent // Export the component to be used in other modules
  ]
})
export class PoiClientFormModule { }
