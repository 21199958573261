import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AddStopPoiComponent } from './add-stop-poi.component';

@NgModule({
  declarations: [
    AddStopPoiComponent // Declare the AddStopPoiComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular directives
    ReactiveFormsModule, // Import ReactiveFormsModule for reactive forms
    ToastrModule.forRoot() // Import and configure ToastrModule for notifications
  ],
  exports: [
    AddStopPoiComponent // Export the component to be used in other modules
  ],
  providers: [] // Add providers here if necessary
})
export class AddStopPoiModule { }
