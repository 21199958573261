import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashbordComponent } from './dashbord.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { HttpClientModule } from '@angular/common/http'; // Import HttpClientModule for HTTP requests
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import Bootstrap Datepicker
import { DashbordService } from '../providers/dashbord-service'; // Adjust the path as necessary
import { DataManagementService } from "../../data-management/data-management.service"; // Adjust the path as necessary
import { ChartConfigService } from "src/app/utils/chart/chart-config.service"; // Adjust the path as necessary
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [
    DashbordComponent // Declare the DashbordComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Initialize Toastr if you're using it
    FormsModule, // Include FormsModule for template-driven forms
    HttpClientModule, // Include HttpClientModule for making HTTP requests
    BsDatepickerModule.forRoot(),
    HighchartsChartModule,
  ],
  providers: [
    DashbordService, // Provide the DashbordService
    DataManagementService, // Provide the DataManagementService
    ChartConfigService // Provide the ChartConfigService
  ],
  exports: [
    DashbordComponent // Export the component if it needs to be used in other modules
  ]
})
export class DashbordModule { }
