<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"
></ngx-loading>
<div class="row">
  <div class="col-md-2">
    <div class="row">
      <div class="col-md-6 selectStyle">
        <div class="grp-slct">
          <select
            class="form-control input-sm"
            name="transport"
            [ngModel]="selectedTransport"
            (ngModelChange)="chooseTransport($event)"
            required
          >
            <option
              *ngFor="
                let transport of transportTypeService?.transportTypeTravelReport
              "
              [ngValue]="transport"
            >
              {{ transport?.name | capitalizefirst }}
            </option>
          </select>
          <label>Pôle</label>
        </div>
      </div>
      <div class="col-md-6 selectStyle" style="margin-left: -11">
        <div>
          <div class="grp-slct">
            <select
              class="form-control input-sm"
              name="passage"
              [(ngModel)]="selectedCanal"
              (selectionchange)="chooseSchedule($event)"
              required
            >
              <option *ngFor="let canal of canals" [ngValue]="canal">
                {{ canal?.name | capitalizefirst }}
              </option>
            </select>
            <label>Site</label>
          </div>
        </div>
      </div>
      <div class="col-md-5 selectStyle" style="margin-right: -28px">
        <div>
          <div class="grp-slct">
            <select
              class="form-control input-sm"
              name="type_camion"
              [ngModel]="selectedtypeSubMark"
              (ngModelChange)="chooseSubMark($event)"
              required
            >
              <option
                *ngFor="let submark_ of filtretypeSubMark"
                [ngValue]="submark_"
              >
                {{ submark_?.mark }}
              </option>
            </select>
            <label>Type</label>
          </div>
        </div>
      </div>
      <div class="col-md-6 selectStyle" style="margin-right: -28px">
        <div>
          <div class="grp-slct">
            <input
              type="text"
              class="form-control input-sm"
              bsDatepicker
              name="startDate"
              [(ngModel)]="startDate"
              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
              [maxDate]="now"
              placement="bottom"
              required
              (ngModelChange)="onChangeStartDate()"
            />
            <label>Date</label>
          </div>
        </div>

        <!-- <div class="calender-trajet">
          <div class="calendar-card-container">
            <div class="calender-item">
              <input
                type="text"
                ngxDaterangepickerMd
                [locale]="locale"
                [(ngModel)]="selected"
                (datesUpdated)="choosedDate()"
                placeholder="Personnaliser"
                class="calender-input"
              />
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-md-1 selectStyle">
        <div class="form-btns">
          <button
            type="button"
            (click)="LoadData()"
            [disabled]="loading"
            class="btn btn-success btn-flat btn-sm"
            style="border-radius: 5px; padding: 5px"
            title="Valider"
          >
            <b><i class="fa fa-refresh" aria-hidden="true"></i></b>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10" style="margin-bottom: -31px">
    <div class="row gradient-background">
      <div class="col">
        <div class="card order-card" [popover]="trackingPopOverDisp">
          <div class="card-block">
            <h6 class="m-b-20">Disponibilité</h6>
            <h2 class="text-right">
              <img
                class="stati-icon f-left"
                src="../../../../assets/icons/btp/shipped.svg"
                alt="shipped.svg"
                srcset=""
                style="
                  filter: invert(72%) sepia(48%) saturate(4793%)
                    hue-rotate(171deg) brightness(96%) contrast(88%);
                "
              /><span>{{ vehiculesdisponible }}</span>
            </h2>
            <p class="m-b-0">
              <span class="f-right">{{ vehiculesdisponible * 12 }} H</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card order-card" [popover]="trackingPopOverPla">
          <div class="card-block">
            <h6 class="m-b-20">Planification</h6>
            <h2 class="text-right">
              <img
                class="stati-icon f-left"
                src="../../../../assets/icons/btp/planifie.svg"
                alt="planifie.svg"
                srcset=""
                style="
                  filter: invert(68%) sepia(48%) saturate(4793%)
                    hue-rotate(349deg) brightness(96%) contrast(88%);
                "
              /><span>{{ vehiculesPlanifie }}</span>
            </h2>
            <p class="m-b-0">
              <span class="f-right">{{ vehiculesPlanifie * 12 }} H</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card order-card" [popover]="trackingPopOverRot">
          <div class="card-block">
            <h6 class="m-b-20">Rotation</h6>
            <h2 class="text-right">
              <img
                class="stati-icon f-left"
                src="../../../../assets/icons/btp/rotation.svg"
                alt="rotation.svg"
                srcset=""
                style="
                  filter: invert(45%) sepia(98%) saturate(405%)
                    hue-rotate(81deg) brightness(97%) contrast(83%);
                "
              /><span>{{ NbObjectifRotation }}</span>
            </h2>
            <p class="m-b-0">
              <span class="f-right">{{ NbRealiseRotation }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card order-card" [popover]="trackingPopOverKm">
          <div class="card-block">
            <h6 class="m-b-20">Parcorus</h6>
            <h2 class="text-right">
              <img
                class="stati-icon f-left"
                src="../../../../assets/icons/btp/km.svg"
                alt="km.svg"
                srcset=""
                style="
                  filter: invert(35%) sepia(12%) saturate(4621%)
                    hue-rotate(325deg) brightness(99%) contrast(15%);
                "
              /><span>{{ AlldistanceDriven | number:'1.0-2' }}</span>
            </h2>
            <p class="m-b-0">
              <span class="f-right">{{
                AlldistanceProductive | number:'1.0-2' }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card order-card">
          <div class="card-block">
            <h6 class="m-b-20">Vitesse</h6>
            <h2 class="text-right">
              <img
                class="stati-icon f-left"
                src="../../../../assets/icons/btp/vitesse.svg"
                alt="vitesse.svg"
                srcset=""
                style="
                  filter: invert(72%) sepia(48%) saturate(4793%)
                    hue-rotate(13deg) brightness(96%) contrast(88%);
                "
              /><span>{{ this.notificationsLen }}</span>
            </h2>
            <p class="m-b-0"><span class="f-right" hidden>351</span></p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card order-card">
          <div class="card-block">
            <h6 class="m-b-20">Location</h6>
            <h2 class="text-right">
              <img
                class="stati-icon f-left"
                src="../../../../assets/icons/btp/location.svg"
                alt="location.svg"
                srcset=""
                style="
                  filter: invert(35%) sepia(12%) saturate(4621%)
                    hue-rotate(325deg) brightness(99%) contrast(101%);
                "
              /><span>{{ SumChargeLocation | number:'1.0-2' }}</span>
            </h2>
            <p class="m-b-0"><span class="f-right" hidden>000</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-2">
    <div class="col-md-12 vertical">
      <div class="card order-card">
        <div class="card-block">
          <h6 class="m-b-20">TFU</h6>
          <h2 class="text-right">
            <img
              class="stati-icon f-left"
              src="../../../../assets/icons/btp/tfu.svg"
              alt="tfu.svg"
              srcset=""
              style="
                filter: invert(72%) sepia(48%) saturate(4793%) hue-rotate(64deg)
                  brightness(96%) contrast(88%);
              "
            /><span>{{ SumTfu | number : "1.2-2" }} L</span>
          </h2>
          <p class="m-b-0"><span class="f-right" hidden>000</span></p>
        </div>
      </div>
    </div>
    <div class="col-md-12 vertical">
      <div class="card order-card">
        <div class="card-block">
          <h6 class="m-b-20">Gasoil</h6>
          <h2 class="text-right">
            <img
              class="stati-icon f-left"
              src="../../../../assets/icons/tmm/gasoline.svg"
              alt="gasoline.svg"
              srcset=""
              style="
                filter: invert(72%) sepia(48%) saturate(4793%)
                  hue-rotate(166deg) brightness(96%) contrast(88%);
              "
            /><span>{{ SumGasoil | number : "1.2-2" }} %</span>
          </h2>
          <p class="m-b-0"><span class="f-right" hidden>351</span></p>
        </div>
      </div>
    </div>
    <div class="col-md-12 vertical">
      <div class="card order-card">
        <div class="card-block">
          <h6 class="m-b-20">Temps d'arrêt</h6>
          <h2 class="text-right">
            <img
              class="stati-icon f-left"
              src="../../../../assets/icons/btp/time_arret.svg"
              alt="time_arret.svg"
              srcset=""
              style="
                filter: invert(72%) sepia(48%) saturate(4793%)
                  hue-rotate(228deg) brightness(96%) contrast(88%);
              "
            /><span>{{ ArretMission | durationStr }}</span>
          </h2>
          <p class="m-b-0"><span class="f-right" hidden>351</span></p>
        </div>
      </div>
    </div>
    <div class="col-md-12 vertical">
      <div class="card order-card">
        <div class="card-block">
          <h6 class="m-b-20">Temps de décharge</h6>
          <h2 class="text-right">
            <img
              class="stati-icon f-left"
              src="../../../../assets/icons/btp/time_echange.svg"
              alt="time_echange.svg"
              srcset=""
              style="
                filter: invert(72%) sepia(48%) saturate(4793%)
                  hue-rotate(341deg) brightness(96%) contrast(88%);
              "
            /><span>{{ ArretSite | durationStr }}</span>
          </h2>
          <p class="m-b-0"><span class="f-right" hidden>351</span></p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-10">
    <div class="row">
      <div class="col-md-4">
        <app-chart-disponible-flotte
          #disponibleFlotte
        ></app-chart-disponible-flotte>
      </div>
      <div class="col-md-4">
        <app-chart-titre-graphique #titreGraphique></app-chart-titre-graphique>
      </div>
      <div class="col-md-4">
        <app-rotation-type #rotation></app-rotation-type>
      </div>
      <div class="col-md-4">
        <app-charges-transport #chargeTransport></app-charges-transport>
      </div>
      <!-- <div class="col-md-4"><app-chart-arret-poi-eps #arretPoi></app-chart-arret-poi-eps></div> -->
      <!-- <div class="col-md-4"><app-chart-arret-poi></app-chart-arret-poi></div> -->
      <div class="col-md-4">
        <app-chart-exces-vitesse #vitesse></app-chart-exces-vitesse>
      </div>
      <div class="col-md-4">
        <app-consommation-moyenne
          #consommationMoyenne
        ></app-consommation-moyenne>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card box-style">
      <div class="card-body">
        <div class="row" style="margin: 0">
          <section class="content col-md-4 groups-container rm-bgcolor">
            <div class="col-md-12">
              <div
                class="box-poi box-info collapsed-box"
                style="margin-bottom: 5px; margin-left: -7px"
              >
                <div class="box-header with-border">
                  <h3
                    class="box-title"
                    style="
                      font-family: 'Inconsolata', monospace;
                      font-size: 14px;
                      font-weight: bold;
                      color: black;
                    "
                  >
                    <i class="fa fa-map"></i> Position par P.O.I
                  </h3>
                  <div class="box-tools pull-right">
                    <button
                      type="button"
                      class="btn btn-box-tool"
                      data-widget="collapse"
                      (click)="changeContentStyle()"
                    >
                      <i class="fa fa-plus" style="color: black"></i>
                    </button>
                  </div>
                </div>
                <div class="box-body">
                  <div class="conten" data-widget="collapse">
                    <app-vehicule-according-poi></app-vehicule-according-poi>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="col-12">
            <app-leaflet [according]="true"></app-leaflet>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12" style="padding-right: 0px">
    <div>
      <div class="card box-style" style="height: 555px">
        <div class="card-body">
          <app-quality-driving></app-quality-driving>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #trackingPopOverDisp>
  <fieldset class="without-border">
    <div>
      <h4>Disponibilité</h4>
      <ul class="listeNiveauUn jqInner">
        <li>
          <span
            >Vehicule disponible :
            <b>{{ vehiculesdisponible }}</b>
          </span>
        </li>
        <li>
          <span
            >Heure Disponible:
            <b>{{ vehiculesdisponible * 12 }} H</b>
          </span>
        </li>
      </ul>
    </div>
  </fieldset>
</ng-template>

<ng-template #trackingPopOverPla>
  <fieldset class="without-border">
    <div>
      <h4>Planification</h4>
      <ul class="listeNiveauUn jqInner">
        <li>
          <span
            >Vehicule Planifié :
            <b>{{ vehiculesPlanifie }}</b>
          </span>
        </li>
        <li>
          <span
            >Heure Planifié:
            <b>{{ vehiculesPlanifie * 12 }} H</b>
          </span>
        </li>
      </ul>
    </div>
  </fieldset>
</ng-template>

<ng-template #trackingPopOverRot>
  <fieldset class="without-border">
    <div>
      <h4>Rotation</h4>
      <ul class="listeNiveauUn jqInner">
        <li>
          <span
            >Objectif :
            <b>{{ NbObjectifRotation }} </b>
          </span>
        </li>
        <li>
          <span
            >Réalisé :
            <b>{{ NbRealiseRotation }}</b>
          </span>
        </li>
      </ul>
    </div>
  </fieldset>
</ng-template>

<ng-template #trackingPopOverKm>
  <fieldset class="without-border">
    <div>
      <h4>Km Parcorus</h4>
      <ul class="listeNiveauUn jqInner">
        <li>
          <span
            >Km Global :
            <b>{{ AlldistanceDriven | number:'1.0-2' }}</b>
          </span>
        </li>
        <li>
          <span
            >Km Productif :
            <b>{{ AlldistanceProductive | number:'1.0-2' }}</b>
          </span>
        </li>
      </ul>
    </div>
  </fieldset>
</ng-template>
