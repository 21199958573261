import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VehiculeAccordingPoiComponent } from './vehicule-according-poi.component';
import { OverviewService } from '../overview.service';
import { RealtimeRestService } from '../../realtime/services/realtime-rest.service';

@NgModule({
  declarations: [
    VehiculeAccordingPoiComponent
  ],
  imports: [
    CommonModule,
    FormsModule  // Required if you're using template-driven forms like [(ngModel)]
  ],
  providers: [
    OverviewService,
    RealtimeRestService
  ],
  exports: [
    VehiculeAccordingPoiComponent
  ]
})
export class VehiculeAccordingPoiModule { }
