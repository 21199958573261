import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import for ngModel and reactive forms
import { ToastrModule } from 'ngx-toastr'; // Import for toast notifications
import { BatteriesSettingsComponent } from './batteries-settings.component'; // Adjust the path as necessary
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    BatteriesSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    EmailManagerModule,
    MatSlideToggleModule
  ],
  exports: [
    BatteriesSettingsComponent // Export the component if needed
  ]
})
export class BatteriesSettingsModule { }
