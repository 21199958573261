import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrakeFormComponent } from './brake-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { BrakeService } from '../../../providers/brake-service'; // Import BrakeService
import { ProviderService } from '../../../providers/provider-service'; // Import ProviderService
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    BrakeFormComponent // Declare the BrakeFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule to handle form controls
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(),
    MatCheckboxModule,

  ],
  providers: [
    BrakeService, // Provide BrakeService
    ProviderService // Provide ProviderService
  ],
  exports: [
    BrakeFormComponent // Export BrakeFormComponent for use in other modules
  ]
})
export class BrakeFormModule { }
