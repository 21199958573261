import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassificationRotationChartComponent } from './classification-rotation-chart.component';

@NgModule({
  declarations: [
    ClassificationRotationChartComponent  // Declare the component here
  ],
  imports: [
    CommonModule  // Import necessary Angular modules
  ],
  exports: [
    ClassificationRotationChartComponent  // Export the component to make it available to other modules
  ]
})
export class ClassificationRotationChartModule { }
