import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import if using forms or ngModel
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you are using ToastrService
import { TireSettingsComponent } from './tire-settings.component'; // Adjust the path as necessary
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    TireSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule, // Include if using forms or ngModel
    ToastrModule.forRoot(),
    EmailManagerModule,
    MatSlideToggleModule
  ],
  exports: [
    TireSettingsComponent // Export TireSettingsComponent if needed in other modules
  ]
})
export class TireSettingsModule { }
