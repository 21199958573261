<!-- <div class=" mt-2 mb-2 text-center well bgg">
  <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
    <i class="fa fa-plus-circle add"></i>
    <span> Balance Supply Chain - Classification véhicule</span>
  </h4>
</div>

<div class="row" style="font-family: 'Inconsolata', monospace;">
  <section class="content col-md-12 groups-container">
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-12">
        <div class="box" style="border-radius: 0px;min-height: unset;">
          <div class="box-body">
            <form class="form-horizontal" #activityForm="ngForm">
              <div class="form-group row">

                <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                <div class="col-sm-2 grp-slct">
                  <select class="form-control input-sm" name="group" [(ngModel)]="selectedGroup"  (ngModelChange)="onGroupChange()" required>
                    <option *ngFor="let group of groups" [ngValue]="group">
                      {{group?.nom | capitalizefirst}}
                    </option>
                  </select>
                </div>

                <div class="col-lg-2 form-group">
                  <label class="col-lg-2 col-md-2 control-label">Du</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                      [(ngModel)]="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [maxDate]="endDate"
                      placement="bottom" required autocomplete="off" (ngModelChange)="onChangeStartDate()">
                  </div>
                </div>

                <div class="col-lg-2 orm-group">
                  <label class="col-lg-2 col-md-2 control-label">Au</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="startDate" autocomplete="off"
                      [maxDate]="now" placement="bottom" required>
                  </div>
                </div>

                <div class="col-sm-1 form-btns">
                  <button type="button" class="btn btn-success btn-flat btn-sm"
                    [disabled]="loading || !activityForm.form.valid" (click)="loadAllClasifications()" title="Valider">
                    <b><i class="fa fa-play" aria-hidden="true"></i></b>
                  </button>
                </div>

                <div class="col-sm-1 form-btns">
                  <button type="button" class="btn btn-warning btn-flat btn-sm" [disabled]="loading || groups.length == 0"
                    (click)="classificationModal.show()" title="Coefficient">
                    <b><i class="fa fa-gear" aria-hidden="true"></i></b>
                  </button>
                </div>

                <div *ngIf="loading" class="col-sm-2 text-center" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 32px;">

      <ng-container
        *ngIf="!classification.mileage && !classification.hActivity && !classification.workingTime && !classification.fueling && !classification.offense && !classification.sinistre && !classification.turnover">
        <div class="col-xl-12 col-md-12 mb-12 col-xs-12">
          <div class="alert alert-warning alert-dismissible text-center">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
              ×
            </button>
            <h4 style="color: white">
              <i class="fa fa-info-circle"></i> Information
            </h4>
            Veuillez définir votre coefficient
            <ng-container *ngIf="groups.length > 0">
            <span type="button" class="btn btn-flat btn-sm" (click)="classificationModal.show()" title="Coefficient">
              <b><i class="fa fa-gear" aria-hidden="true"></i></b>
            </span>
          </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="classification.hActivity">
        <div class="col-md-6">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
                <app-classification-percentage-chart [activities]="activities">
                </app-classification-percentage-chart>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="classification.mileage">
        <div class="col-md-6">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
                <app-classification-kmchart [activities]="distanceDriven"></app-classification-kmchart>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="classification.fueling">
        <div class="col-md-6">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
                <app-classification-fueling-chart [fuelings]="fuelings"></app-classification-fueling-chart>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="classification.workingTime">
        <div class="col-md-6">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
                <app-classification-hour-activity-chart [activities]="pathTime">
                </app-classification-hour-activity-chart>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="classification.offense">
        <div class="col-md-6">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
                <app-classification-offense-chart [offenses]="offenses">
                </app-classification-offense-chart>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="classification.sinistre">
        <div class="col-md-6">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
                <app-classification-sinistre-chart [sinistres]="sinistres">
                </app-classification-sinistre-chart>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="classification.turnover">
        <div class="col-md-6">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
                <app-classification-driver-turn-over-chart [driversTurnOver]="turnOvers">
                </app-classification-driver-turn-over-chart>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      
    </div>

  </section>
</div>


<ng-container *ngIf="ratings.length > 0 && !loading">
  <div class=" mt-2 mb-2 text-center well bgg">
    <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
      <i class="fa fa-plus-circle add"></i>
      <span> Balance Supply Chain - Top 5 véhicules</span>
    </h4>
  </div>

  <div class="row">
    <div class="col-md-2 col-lg-2 col-sm-4" *ngFor="let r of topFive(); let i = index;"
      [ngClass]="{'col-md-offset-1' : i == '0'}">
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img *ngIf="!r?.avatarUri" src="{{imagesDir}}/img_avatar.png" alt="Avatar">
            <img *ngIf="r?.avatarUri" src="{{r?.avatarUri}}" class="img-responsive img-radio">
          </div>
          <div class="flip-card-back">
            <h1>{{i + 1}}</h1>
            <h3>{{r?.matricule}}</h3>
            <h3>{{r?.driver}}</h3>
            <p>{{r?.note / 20 | number: '.0-2'}}/10</p>

            <span class="fa fa-star" [ngClass]="[r?.note > 0 && r?.note < 40 ? 'fa-star-half-full' : '',
            r.note > 0  ? 'checked' : ''  ]"></span>

            <span [ngClass]="[
             r?.note > 40 && r?.note < 80 ? 'fa-star-half-full' : '',
            r.note > 40  ? 'checked' : ''  ]" class="fa fa-star"></span>

            <span class="fa fa-star" [ngClass]="[
           r?.note > 80 && r?.note < 120  ? 'fa-star-half-full' : '',
            r.note > 80  ? 'checked' : ''  ]"></span>

            <span class="fa fa-star" [ngClass]="[
             r?.note > 120 && r?.note < 160  ? 'fa-star-half-full' : '',
             r.note > 120 ? 'checked' : '' ]"></span>

            <span class="fa fa-star" [ngClass]="[
             r?.note > 160 && r?.note < 200 ? 'fa-star-half-full' : '',
            r.note > 160  ? 'checked' : ''  ]"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->


<!-- <div class=" mt-2 mb-2 text-center well bgg">
  <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
    <i class="fa fa-plus-circle add"></i>
    <span> Balance Supply Chain - Classification véhicule</span>
  </h4>
</div> -->

<div class="row" style="font-family: 'Inconsolata', monospace;">
  <section class="content col-md-12 groups-container">
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-12">
        <div class="box" style="border-radius: 0px;min-height: unset; margin-top: -40px;">
          <div class="box-body">
            <form class="form-horizontal" #activityForm="ngForm">
              <div class="form-group row" style="margin-bottom: -15px;">

                <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                <div class="col-sm-2 grp-slct">
                  <select class="form-control input-sm" name="group" [(ngModel)]="selectedGroup"  (ngModelChange)="onGroupChange()" required>
                    <option *ngFor="let group of groups" [ngValue]="group">
                      {{group?.nom | capitalizefirst}}
                    </option>
                  </select>
                </div>
                <div class="col-lg-4 form-group">
                  <div class="form-group" style="padding-left: 10px;">
                    <select class="form-control input-sm select3" (change)="onChangePeriode($event.target.value)"
                      style="margin-bottom: 20px; width: 180px;" >
                      <option value="periode" hidden selected disabled>Période</option>
                      <option [value]="month.id" *ngFor="let month of months">
                        {{month.name}}
                      </option>
                    </select>
                    <!-- <label>Période</label> -->
                  </div>
                </div>    
                <!-- <div class="col-lg-2 form-group">
                  <label class="col-lg-2 col-md-2 control-label">Du</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                      [(ngModel)]="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [maxDate]="endDate"
                      placement="bottom" required autocomplete="off" (ngModelChange)="onChangeStartDate()">
                  </div>
                </div>

                <div class="col-lg-2 orm-group">
                  <label class="col-lg-2 col-md-2 control-label">Au</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="startDate" autocomplete="off"
                      [maxDate]="now" placement="bottom" required>
                  </div>
                </div> -->

                <div class="col-sm-1 form-btns">
                  <button type="button" class="btn btn-success btn-flat btn-sm"
                    [disabled]="loading || !activityForm.form.valid" (click)="loadAllClasifications()" title="Valider" style="border-radius: 5px;position: relative;right: 300px;margin-left: 68px;">
                    <b><i class="fa fa-play" aria-hidden="true"></i></b>
                  </button>
                </div>

                <div class="col-sm-1 form-btns">
                  <button type="button" class="btn btn-warning btn-flat btn-sm" [disabled]="loading || groups.length == 0"
                    (click)="classificationModal.show()" title="Coefficient" style="border-radius: 5px;position: relative;left: 300px;">
                    <b><i class="fa fa-gear" aria-hidden="true"></i></b> Paramétrage
                  </button>
                </div>

                <div *ngIf="loading" class="col-sm-2 text-center" style="font-size: 12px;margin-top: 8px;">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="ratings.length > 0 && !loading">
      <!-- <div class=" mt-2 mb-2 text-center well bgg">
        <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
          <i class="fa fa-plus-circle add"></i>
          <span> Balance Supply Chain - Top 5 véhicules</span>
        </h4>
      </div> -->
    
      <div class="row" style="margin-left: 20px;">
        <div class="col-md-2 col-lg-2 col-sm-2" *ngFor="let r of topFive(); let i = index;"
          >
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img *ngIf="!r?.avatarUri" src="{{imagesDir}}/img_avatar.png" alt="Avatar">
                <img *ngIf="r?.avatarUri" src="{{r?.avatarUri}}" class="img-responsive img-radio">
              </div>
              <div class="flip-card-back">
                <h1>{{i + 1}}</h1>
                <h3>{{r?.matricule}}</h3>
                <h3>{{r?.driver}}</h3>
                <p>{{r?.note / 20 | number: '.0-2'}}/10</p>
    
                <span class="fa fa-star" [ngClass]="[r?.note > 0 && r?.note < 40 ? 'fa-star-half-full' : '',
                r.note > 0  ? 'checked' : ''  ]"></span>
    
                <span [ngClass]="[
                 r?.note > 40 && r?.note < 80 ? 'fa-star-half-full' : '',
                r.note > 40  ? 'checked' : ''  ]" class="fa fa-star"></span>
    
                <span class="fa fa-star" [ngClass]="[
               r?.note > 80 && r?.note < 120  ? 'fa-star-half-full' : '',
                r.note > 80  ? 'checked' : ''  ]"></span>
    
                <span class="fa fa-star" [ngClass]="[
                 r?.note > 120 && r?.note < 160  ? 'fa-star-half-full' : '',
                 r.note > 120 ? 'checked' : '' ]"></span>
    
                <span class="fa fa-star" [ngClass]="[
                 r?.note > 160 && r?.note < 200 ? 'fa-star-half-full' : '',
                r.note > 160  ? 'checked' : ''  ]"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row" style="margin-top: 32px;">

      <ng-container
        *ngIf="!classification.mileage && !classification.hActivity && !classification.workingTime && !classification.fueling && !classification.offense && !classification.sinistre && !classification.turnover">
        <div class="col-xl-12 col-md-12 mb-12 col-xs-12">
          <div class="alert alert-warning alert-dismissible text-center">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
              ×
            </button>
            <h4 style="color: white">
              <i class="fa fa-info-circle"></i> Information
            </h4>
            Veuillez définir votre coefficient
            <ng-container *ngIf="groups.length > 0">
            <span type="button" class="btn btn-flat btn-sm" (click)="classificationModal.show()" title="Coefficient">
              <b><i class="fa fa-gear" aria-hidden="true"></i></b>
            </span>
          </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Section 1 -->

      <!-- <div class="row"> -->
        <ng-container *ngIf="classification.hActivity">
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-percentage-chart [activities]="activities">
                  </app-classification-percentage-chart>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
  
        <ng-container *ngIf="classification.mileage">
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-kmchart [activities]="distanceDriven"></app-classification-kmchart>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
  
        <ng-container *ngIf="classification.fueling">
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-fueling-chart [fuelings]="fuelings"></app-classification-fueling-chart>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      <!-- </div> -->

      <!-- Section 2 -->
      <!-- <div class="row" style="margin-top: 15px;"> -->
        <!-- <ng-container *ngIf="classification.workingTime">
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-hour-activity-chart [activities]="pathTime">
                  </app-classification-hour-activity-chart>
                </div>
              </div>
            </div>
          </div>
        </ng-container> -->
  
        <ng-container *ngIf="classification.offense">
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-offense-chart [offenses]="offenses">
                  </app-classification-offense-chart>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
  
        <ng-container *ngIf="classification.sinistre">
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-sinistre-chart [sinistres]="sinistres">
                  </app-classification-sinistre-chart>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        
      <!-- </div> -->

      <!-- Section 3 -->
      <!-- <div class="row" style="margin-top: 15px;"> -->
        <ng-container *ngIf="classification.workingTime">
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-hour-activity-chart [activities]="pathTime">
                  </app-classification-hour-activity-chart>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
  
        <!-- <ng-container *ngIf="classification.offense"> 
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-offense-chart [offenses]="offenses">
                  </app-classification-offense-chart>
                </div>
              </div>
            </div>
          </div>
         </ng-container> -->
  
        <!-- <ng-container *ngIf="classification.sinistre"> 
          <div class="col-md-4">
            <div>
              <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-sinistre-chart [sinistres]="sinistres">
                  </app-classification-sinistre-chart>
                </div>
              </div>
            </div>
          </div>
         </ng-container> -->
      <!-- </div> -->

      <!-- Section 4 -->
      <!-- <div class="row" style="margin-top: 15px;"> -->
        <ng-container *ngIf="classification.turnover">
          <div class="col-md-4">
            <div>
               <div class="box box-info collapsed-box">
                <div class="box-header with-border text-center">
                  <div *ngIf="loading" style="font-size: 12px;margin-top: 8px;">
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
                  </div>
                  <app-classification-driver-turn-over-chart [driversTurnOver]="turnOvers">
                  </app-classification-driver-turn-over-chart>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      <!-- </div> -->





      
    </div>

  </section>
</div>





<!-- ============================== ADD MODAL (START) ========================== -->
<div class="modal fade" bsModal #classificationModal="bs-modal" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classificationModal.hide()">&times;</button>
        <h4 class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
          <i class="fa fa-plus"></i>
          Réglage des coefficients
        </h4>
      </div>
      <div class="modal-body">
        <span class="text-warning">
          * vous pouvez choisir 4 paramètres comme max.
        </span>
        <br />
        <span class="text-warning">
          * Le total du coefficient doit être égal à 20/20
        </span>
        <div class="box-body">
          <app-activity-vehicle-classification-form [groups]="groups" [coefficient]="classification"
            (classificationWasSaved)="classificationWasSaved($event)">
          </app-activity-vehicle-classification-form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ============================== ADD/UPDATE MODAL (END) ======================== -->