<div class="text-center">
  <!-- Button controle by vehicule !-->
  <div class="btn-group"
  *ngIf="signinService.isHasEngineControl() || signinService.isHasRole('ROLE_ENGINE_CONTROLE')">
  <button type="button" title="Lancement moteur"
      class="btn btn-success btn-xs"
      style="font-size: 9px;"
      (click)="startEngine(vehicule.idDevice)">
      <i class="fa fa-play" aria-hidden="true"></i>
      Lancement
  </button>
  <button type="button" title="Arrêt moteur"
      class="btn btn-danger btn-xs"
      style="font-size: 9px;"
      (click)="stopEngine(vehicule.idDevice)">
      <i class="fa fa-stop" aria-hidden="true"></i>
      Arrêt
  </button>
  </div>

  <div class="btn-group">
    <button
        *ngIf="signinService.isRootAdmin() || signinService.isHasRole('ROLE_POI')"
        style="font-size: 9px;" type="button"
        title="Ajouter comme client"
        class="btn btn-primary btn-xs"
        (click)="groupsContainerComponent.addClient()">
        <i class="fa fa-map-marker"
            aria-hidden="true"></i>
    </button>
    <button
        *ngIf="signinService.isRootAdmin() || signinService.isHasRole('ROLE_POI')"
        style="font-size: 9px;" type="button"
        title="Ajouter comme poi"
        class="btn btn-warning btn-xs"
        (click)="groupsContainerComponent.addPOI()">
        <i class="fa fa-map-marker"
            aria-hidden="true"></i>
    </button>

      <button style="font-size: 9px;" type="button"
          title="Afficher adresse google"
          class="btn btn-success btn-xs"
          (click)="displayGoogleAdress(vehicule)">
          <i class="fa fa-google" aria-hidden="true"></i>
      </button>

      <button style="font-size: 9px;" type="button"
          title="Detail de vehicule"
          class="btn btn-primary btn-xs"
          (click)="goToVehiculeDetail(vehicule)">
          <i class="fa fa-eye" aria-hidden="true"></i>
      </button>

      <button style="font-size: 9px;" type="button"
          title="Afficher Dashboard"
          class="btn btn-warning btn-xs"
          (click)="displayDashboard(vehicule)">
          <i class="fa fa-dashboard"
              aria-hidden="true"></i>
      </button>

      <button
          *ngIf="signinService.isHasCarburantOption()"
          style="font-size: 9px;" type="button"
          title="Afficher Rapport Graphique (Carburant)"
          class="btn btn-success btn-xs"
          (click)="goToFuelData(vehicule)">
          <i class="fa fa-bar-chart"
              aria-hidden="true"></i>
      </button>

      <button style="font-size: 9px;" type="button"
          title="Afficher les trajets"
          class="btn btn-info btn-xs"
          (click)="goToHistoricalPath()">
          <i class="fa fa-road" aria-hidden="true"></i>
      </button>

      <button style="font-size: 9px;" type="button"
          title="Afficher les arrêts"
          class="btn btn-primary btn-xs"
          (click)="goToHistoricalStop()">
          <i class="fa fa-map-signs"
              aria-hidden="true"></i>
      </button>
      <button style="font-size: 9px;" type="button"
      title="more details"
      class="btn btn-primary btn-xs"
      (click)="goToRealTime()">
      <i class="fa fa-bullhorn"
          aria-hidden="true"></i>
      </button>
  </div>

</div>


