import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DailyKilomtrageChartComponent } from './daily-kilomtrage-chart.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms'; // Import FormsModule to use ngModel
import { NgxLoadingModule } from 'ngx-loading';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



@NgModule({
  declarations: [
    DailyKilomtrageChartComponent,  // Declare your component here
  ],
  imports: [
    CommonModule,       // Import CommonModule for common Angular directives
    FormsModule,        // Import FormsModule to use template-driven forms (ngForm)
    ReactiveFormsModule, // Optional: Only if you're using reactive forms
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),


  ],
  exports: [
    DailyKilomtrageChartComponent  // Export the component for use in other modules
  ]
})
export class DailyKilomtrageChartModule { }
