import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ParentDetails } from '../../../../../model/km-jour.model';
import { ToolKmJourService } from '../../../../../services/tool-km-jour.service';
import { Subscription } from 'rxjs';
import { DataManagementService, Vehicule } from 'src/app/client-management/data-management';
import { RealtimeService } from 'src/app/client-management/realtime/services/realtime.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { DateInterval } from 'src/app/shared/model';
import { RealtimeProcessingService } from 'src/app/client-management/realtime/services/real-time-processing.service';



@Component({
  selector: 'km-jour-trajet-parent',
  templateUrl: './km-jour-trajet-parent.component.html',
  styleUrls: ['./km-jour-trajet-parent.component.css'],
})
export class KmJrTrajetParentComponent implements OnInit, OnDestroy {
  @Output('showingItems') showingItems =  new EventEmitter();
  @Input() useFms: boolean;
  @Input() parentDetails: ParentDetails;
  
  intervaleDate: DateInterval;  
  intervaleDateSubscription: Subscription;

  dates: Date[]= [];
  currentDate:number = 0;

  loadedSuscription: Subscription;
  isDataLoaded: boolean = false;

  selectedVehicule: Vehicule;
  selectedVehiculeSubs: Subscription;


  constructor(private toolKmJourService: ToolKmJourService,
    private realTimeService: RealtimeService,
    private mapService: MapService,
    public dataManagementService: DataManagementService,
    private realTimeProcessing: RealtimeProcessingService
    ) {}

  ngOnInit() {
    this.loadVehicule();
    this.loadedSuscription= this.toolKmJourService.isLoaded().subscribe(isLoaded => {
      this.isDataLoaded = isLoaded;
    });
    this.intervaleDateSubscription= this.realTimeProcessing.getIntervalDate().subscribe((interval) => {
      this.intervaleDate = interval;
      this.toolKmJourService.setKmJourPath(null);
      this.mapService.clearPolylines();
      this.mapService.removeMarkersAndClearArrays();
      this.toolKmJourService.loadKmJourPath(this.selectedVehicule.idDevice,this.intervaleDate);
      this.currentDate= -1;
    })
  }
  calculeConsumation(consumtion: any){
    if(!consumtion || consumtion > 100){return 0}
    else{
      return consumtion;
    }
  }

  loadVehicule(){
    this.selectedVehiculeSubs = this.realTimeService
    .getVehicule()
    .subscribe((v) => {
      this.selectedVehicule = v; 
    });
  }

  showItem(){
    this.showingItems.emit();
  }

  ngOnDestroy(){
    this.loadedSuscription.unsubscribe();
    this.selectedVehiculeSubs.unsubscribe();
    this.intervaleDateSubscription.unsubscribe();
  }

}