export class TechnicalData {
	deviceId: number;
	date: number;
	speed: number;
	fuel: number;
	temp: number;
	rpm: number;
	temp_engine: number;
	fuel_rate: number;
	ignition: boolean;
	tfu: number;
	accum_odo: number;
	x: number;
	y: number;
	z: number;
	di1:number;
	di2:number;
	alg_tfu : number;
	filtred_carb_value : number;

  constructor() {}
}

export class DeviceOpt {
  idDevice: number;
  useIgnition: number;
  useFuel: number;
  useTemp: number;
  useFms: number;
  useJ1708: number;
  useIdDriver: number;
  useStop: number;

  constructor() {}
}

export class DeviceCarb {
  idDevice: number;
  max: number;
  min: number;
  filtrage: number;
  volume: number;
  fuel_src: number;
  fuel_algo: number;
  fuelAverage: number;
  margin: number;
  constructor() {
  }
}

export class DeviceMems {
  idDevice: number;
	nbFrein: number;
	nbAcc: number;
	nbDrift: number;
	nbJerk: number;
}

export class DeviceCarbGroupDto {
  id : number;
  fuelCost : number;
}

export class RepFuelVariation {
  deviceId: number;
  type: "APPRO" | "THIEF";
  consumeStartFuelLevel: number;
  consumeStartTime: Date;
  consumeStartOdo: number;
  consumeStartTfu: number;
  approStartFuelLevel: number;
  approStartTime: Date;
  approStartOdo: number;
  approStartTfu: number;
  approEndFuelLevel: number;
  approEndTime: Date;
  approEndOdo: number;
  approEndTfu: number;

  constructor() {
    // code...
  }
}
