import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightSidebarComponent } from './right-sidebar.component';

@NgModule({
  declarations: [
    RightSidebarComponent // Declare the RightSidebarComponent
  ],
  imports: [
    CommonModule // Import CommonModule for common directives
  ],
  exports: [
    RightSidebarComponent // Export the RightSidebarComponent for use in other modules
  ]
})
export class RightSidebarModule { }
