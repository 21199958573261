import { Injectable } from '@angular/core';
import * as Leaflet from 'leaflet';
import { MovingMarker } from '../model/real-time.model';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import 'leaflet-rotatedmarker';
import 'leaflet-routing-machine';
import { RealtimeHelperService } from './realtime-helper.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DateInterval } from 'src/app/shared/model';

// Extend Marker type to include setRotationOrigin and setRotationAngle methods
interface CustomMarker extends Leaflet.Marker {
  setRotationOrigin(origin: string): void;
  setRotationAngle(angle: number): void;
}

@Injectable({
  providedIn: 'root',
})
export class RealtimeProcessingService {
  newIcon = Leaflet.icon({
    iconUrl: 'assets/icons/realtime/km-jour/simulation.svg',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  });
  animationInterval: NodeJS.Timeout;
  mouvingMarker: MovingMarker = new MovingMarker();

  isMoving: boolean = false;
  initialStatus: boolean = false;

  speed: number = 0;
  startingDate: Date;
  competed= new Subject<boolean>();

  location = 1;
  animationDuration: number = 1000;
  locationSubject = new Subject<number>();
  simulationSubject= new Subject<boolean>();
  SpeedSubject = new Subject<number>();
  hidingChart = new Subject<boolean>();
  startingDateSubject = new Subject<Date>();
  intervalDate= new BehaviorSubject<DateInterval>(this.realtimeHelperService.IntervalDateConverter(new Date()));

  constructor(
    private mapService: MapService,
    private realtimeHelperService: RealtimeHelperService
  ) {}

  async move(cordinates: any): Promise<number> {
    return new Promise<number>(async (resolve) => {
      // Vérification que le marqueur existe avant d'appeler des méthodes sur lui
      if (this.mouvingMarker.marker) {
        (this.mouvingMarker.marker as CustomMarker).setRotationOrigin('center');
      } else {
        // Si le marqueur n'est pas défini, retourner une valeur par défaut
        return resolve(0);
      }

      this.isMoving = true;
      let angle: number | undefined;

      for (let index = this.location; index < cordinates.length; index++) {
        this.location++;
        this.setLocation(this.location);
        this.setSpeed(cordinates[index].speed);
        this.setStartingDate(cordinates[index].date);
        const coord = Leaflet.latLng(cordinates[index].lat, cordinates[index].lng);

        // Assurez-vous que le marqueur est toujours défini avant d'appeler getLatLng()
        if (this.mouvingMarker.marker) {
          angle = this.realtimeHelperService.calculeAngle(
            coord,
            this.mouvingMarker.marker.getLatLng()
          );
        }

        if (!this.isMoving) break;

        if (this.mouvingMarker.marker && this.realtimeHelperService.isCordinateEqual(this.mouvingMarker.marker.getLatLng(), coord)) {
          (this.mouvingMarker.marker as CustomMarker).setRotationAngle(angle || 0); // Utiliser 0 par défaut si angle est undefined
          await this.animation(coord);
        }
      }

      resolve(angle ?? 0); // Si angle est undefined, renvoyer 0
    });
  }

  async animation(coord: Leaflet.LatLng): Promise<void> {
    return new Promise<void>((resolve) => {
      // Vérifier si this.mouvingMarker et this.mouvingMarker.marker sont définis
      if (!this.mouvingMarker || !this.mouvingMarker.marker) {
        return resolve(); // Si undefined, sortir de la promesse
      }

      const markerLatLng = this.mouvingMarker.marker.getLatLng();

      // Vérifier si markerLatLng est défini
      if (!markerLatLng) {
        return resolve();
      }

      const numSteps = Math.ceil(this.animationDuration / 50);
      const stepLat = (coord.lat - markerLatLng.lat) / numSteps;
      const stepLng = (coord.lng - markerLatLng.lng) / numSteps;

      let stepCount = 0;
      this.animationInterval = setInterval(() => {
        const currentLatLng = this.mouvingMarker?.marker?.getLatLng();

        // Vérification si currentLatLng existe avant de calculer les nouvelles coordonnées
        if (currentLatLng) {
          const newLatLng = Leaflet.latLng(
            currentLatLng.lat + stepLat,
            currentLatLng.lng + stepLng
          );

          // Si le marqueur est défini, mettre à jour sa position
          if (this.mouvingMarker && this.mouvingMarker.marker) {
            this.mouvingMarker.marker.setLatLng(newLatLng);
          }

          // Vérification si la carte existe avant de mettre à jour la vue
          if (this.mapService && this.mapService.map) {
            this.mapService.map.setView(newLatLng, this.mapService.map.getZoom());
          }
        }

        stepCount++;

        if (stepCount >= numSteps) {
          clearInterval(this.animationInterval);
          resolve();
        }
      }, 50);
    });
  }

  async stopRealTimeProcessing() {
    clearInterval(this.animationInterval);
    clearTimeout(this.animationInterval);
    this.isMoving = false;
  }

  async restartSimulation(id: number, points: any[]){
    await this.endSimulation();
    await this.initializeMarker(id, points);
    await this.move(points);
  }

  async endSimulation() {
    this.stopRealTimeProcessing();

    // Vérifiez si this.mouvingMarker.marker est défini avant de tenter de le retirer
    if (this.mouvingMarker.marker) {
      this.mapService.map.removeLayer(this.mouvingMarker.marker);
    }

    this.mouvingMarker.clearMouvingMarker();
    this.location = 1;
  }

  async initializeMarker(id: number, points: any[]) {
    const newLatLng = Leaflet.latLng(points[0].lat, points[0].lng);
    const marker = new Leaflet.Marker(newLatLng) as CustomMarker; // Cast to CustomMarker
    const markerToRemove = this.mapService.getRTMarker(id);

    if (!this.mouvingMarker.isMarkerInitialized()) {
      marker.setIcon(this.newIcon);
      this.mouvingMarker.deviceId = id;
      this.mouvingMarker.marker = marker;
      marker.addTo(this.mapService.map);

      // Vérifiez si markerToRemove n'est pas null et si markerToRemove.value n'est pas null avant de le retirer
      if (markerToRemove && markerToRemove.value) {
        this.mapService.map.removeLayer(markerToRemove.value);
      }
    }
  }

  getStartingDate(){
    return this.startingDateSubject.asObservable();
  }

  async startingTheProcess(){
    this.setInitalSimulation(false);
    this.initialStatus = this.isMoving;
    await this.stopRealTimeProcessing();
  }

  setStartingDate(dateStart: Date) {
    this.startingDate = dateStart;
    this.startingDateSubject.next(dateStart);
  }


  updateMarker(points: any, location: any): boolean {
    // Vérifiez si this.mouvingMarker.marker est défini avant de l'utiliser
    if (!this.mouvingMarker.marker) {
      console.error('Marker is not initialized.');
      return this.initialStatus;
    }

    const newLatLng = Leaflet.latLng(points.lat, points.lng);
    let angle: number;

    if (location > this.location) {
      angle = this.realtimeHelperService.calculeAngle(
        newLatLng,
        this.mouvingMarker.marker.getLatLng()
      );
    } else {
      angle = this.realtimeHelperService.calculeAngle(
        this.mouvingMarker.marker.getLatLng(),
        newLatLng
      );
    }

    this.setLocation(location);
    this.setSpeed(points.speed);
    this.setStartingDate(points.date);
    this.mouvingMarker.marker.setLatLng(newLatLng);
    this.mapService.map.setView(this.mouvingMarker.marker.getLatLng(), this.mapService.map.getZoom());

    return this.initialStatus;
  }

  async endingTheProcess(points: any[]){
    if(this.initialStatus){
      this.setInitalSimulation(true);
      await this.move(points);
    }
  }

  speedUp(){
    this.animationDuration = this.animationDuration /2;
  }
  slowDown(){
    this.animationDuration = this.animationDuration * 2;
  }

  getLocation(): Observable<number> {
    return this.locationSubject.asObservable();
  }
  setLocation(location: number) {
    this.location = location;
    this.locationSubject.next(location);
  }

  getInitalSimulation(){
    return this.simulationSubject.asObservable();
  }

  setInitalSimulation(location: boolean) {
    this.initialStatus = location;
    this.simulationSubject.next(location);
  }

  getSpeed(){
    return this.SpeedSubject.asObservable();
  }

  setSpeed(speed: number) {
    this.speed = speed;
    this.SpeedSubject.next(speed);
  }

  getCompleted(){
    return this.competed.asObservable();
  }
  setCompleted() {
    this.competed.next(true);
  }

  getIntervalDate(){
    return this.intervalDate.asObservable();
  }
  setIntervalDate(inter: Date){
    this.intervalDate.next(this.realtimeHelperService.IntervalDateConverter(inter));
  }
  setNormalDateInterval(inter: DateInterval){
    this.intervalDate.next(inter);
  }

  getHidingChart(){
    return this.hidingChart.asObservable();
  }
  settHidingChart(inter: boolean){
    this.hidingChart.next(inter);
  }
}
