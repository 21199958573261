import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarburantFormComponent } from './carburant-form.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ProviderService } from "../../providers/provider-service"; // Import your service
import { MultiTypeService } from "../../providers/multi-type-service"; // Import your service
import { FuelingService } from "../../providers/fueling-service"; // Import your service

@NgModule({
  declarations: [
    CarburantFormComponent // Declare the CarburantFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(), // Import BsDatepickerModule for date picking
    FormsModule // Import FormsModule to handle form controls
  ],
  providers: [
    ProviderService, // Provide ProviderService
    MultiTypeService, // Provide MultiTypeService
    FuelingService // Provide FuelingService
  ],
  exports: [
    CarburantFormComponent // Export CarburantFormComponent for use in other modules
  ]
})
export class CarburantFormModule { }
