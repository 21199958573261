import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionInfoTrackingComponent } from './mission-info-tracking.component'; // Adjust the path if necessary
import { ToastrModule } from 'ngx-toastr';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
//import { PassageService } from '../schedule/passage.service'; // Adjust paths based on your folder structure
import { ScheduleService } from '../schedule/schedule.service';
//import { TransportTypeService } from '../transport-type/transport-type.service';
import { FormsModule } from '@angular/forms';  // If you're using ngModel in your component
import { HttpClientModule } from '@angular/common/http';  // If you are using HTTP services
import { NgxLoadingModule } from 'ngx-loading';
import { TransportTypeService } from '../parametrage/transport-type/transport-type.service';
import { PassageService } from '../parametrage/passage/passage.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { TimediffPipeModule } from 'src/app/pipes/timediff.pipe.module';

@NgModule({
  declarations: [
    MissionInfoTrackingComponent  // Declare the component
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),  // Toastr module for notifications
    FormsModule,  // For form handling and ngModel
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    MatTooltipModule,
    TruncateModule,
    TimediffPipeModule,
  ],
  providers: [
    GeocodingService,  // List of services used by the component
    PassageService,
    ScheduleService,
    TransportTypeService
  ],
  exports: [
    MissionInfoTrackingComponent  // Export if you want to use it in other modules
  ]
})
export class MissionInfoTrackingModule { }
