import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityVehicleClassificationFormComponent } from './activity-vehicle-classification-form.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { ActivityVehiculeClassificationsService } from '../activity-vehicule-classifications.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    ActivityVehicleClassificationFormComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatCheckboxModule,


  ],
  providers: [
    ActivityVehiculeClassificationsService
  ],
  exports: [
    ActivityVehicleClassificationFormComponent
  ]
})
export class ActivityVehicleClassificationFormModule { }
