import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule for common directives like ngIf, ngFor
import { ProgressCustomBarComponent } from './progress-custom-bar.component'; // Path to your component

@NgModule({
  declarations: [ProgressCustomBarComponent], // Declare the component here
  imports: [
    CommonModule, // Import CommonModule for common Angular features
  ],
  exports: [ProgressCustomBarComponent], // Export it to make it available in other modules
})
export class ProgressCustomBarModule {}