import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts6 from 'highcharts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ActivityChartService } from './activity-chart.service';
import { TravelDashbordDto } from 'src/app/client-management/parc-management';
import { DateInterval } from 'src/app/shared/model';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
require('highcharts/modules/exporting')(Highcharts6);
require('highcharts/modules/no-data-to-display')(Highcharts6);

if (!Highcharts6.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts6);
}
@Component({
  selector: 'app-activity-chart',
  templateUrl: './activity-chart.component.html',
  styleUrls: ['./activity-chart.component.css']
})
export class ActivityChartComponent implements OnInit {
  groupeStatistics: any[] = [];
  Activity: Object | null = null;
  date: DateInterval = new DateInterval();
  endDate: Date= new Date();
  startDate: Date = new Date();


  globalLoading: boolean = false;
  featching: boolean = true;

    /** UPDATE TRACKING TIMER */
    updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;

  constructor(private activityChartService: ActivityChartService, public toastr: ToastrService, private localeService: BsLocaleService) { }

  ngOnInit() {
    this.localeService.use('fr');
    this.endDate.setDate(new Date().getDate());
    this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate());
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);

     this.getActivityInfo();
  }

  getActivityInfo() {
    this.globalLoading = true;
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.date.startDate = this.startDate.getTime();
    this.date.endDate = this.endDate.getTime();
    this.activityChartService.getActivityInfo(this.date).subscribe(res => {
      this.groupeStatistics = res as TravelDashbordDto[];
      this.activityChart();
      this.startUpdateTrackingTimer();
      this.globalLoading = false;
    })
  }

    /** STOP TRACKING */
    stopTracking() {
      if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
      this.updateTrackingTimer = null;
    }

  activityChart() {
    this.setHighCartsLang();
    var that = this;
    var drilldata: any[] = [];
    var totalWorkingTimeOfAllgroups = 0;
    var data: { name: string, y: number, cust: string, drilldown: string }[] = [];
    var groupesName = new Set(this.groupeStatistics.map(s => {
      return s;
    })
    );

    for (var i = 0; i < this.groupeStatistics.length; i++) {
      totalWorkingTimeOfAllgroups += (this.groupeStatistics[i].pathTime / 1000);
    }

    groupesName.forEach(groupeName => {
      data.push({ name: groupeName.groupeName, y: ((groupeName.pathTime / 1000) * 100) / totalWorkingTimeOfAllgroups, cust: groupeName.workingDurationStr, drilldown: groupeName.groupeId });
    })
    this.Activity = {
      chart: {
        type: 'column',
        events: {
          drilldown: function (e: { seriesOptions: any; point: { drilldown: number; y: number; name: any; }; }) {
            if (!e.seriesOptions && that.featching) {
              that.featching = false;
              setTimeout(()=>{
                that.featching = true;
              }, 1000);
              var vehiculesdata: { name: string, y: number, cust: string }[] = [];
              that.activityChartService.getActivityBygroup(e.point.drilldown, that.date).pipe(take(1)).subscribe(res => {
                for (let i = 0; i < res.length; i++) {
                  vehiculesdata.push({ name: res[i].vehiculeMatricule, y: ((res[i].pathTime / 1000) * 100) / e.point.y, cust: res[i].workingDurationStr });
                  drilldata.push({ name: e.point.name, id: e.point.drilldown, data: vehiculesdata });
                }
              });
            }
          }
        }
      },
      title: {
        text: 'Heure de travail par groupe',
        style: {
          fontWeight: 'bold'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',

        labels: {
          rotation: -45,

          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: 'Σ.H Travail'
        },
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          borderRadius: 5
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.cust} ',
            softThreshold: true
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name} </span> <br><b>{point.cust}</b> <br/>'
      },
      series: [
        {
          name: "Groupe",
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.cust}'
          }, tooltip: {
            pointFormat: '<span style="color:{point.color}">{point.name} </span> <br> <b>{point.cust}</b> <br/>'
          },
          data: data
        }
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold'
        },
        series: drilldata
      }
    }
    Highcharts6.chart('activity', this.Activity);
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts6.setOptions({
      colors: ['#0d233a', '#8bbc21', '#a6c96a', '#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
        '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', '#007bff', '#66B92E', '#DA932C', '#D65B4A', '#24CBE5', '#64E572', '#FF9655', '#6AF9C4',
        '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#2f7ed8', '#f39c12', '#D81B60', '#605ca8', '#3D9970', '#001F3F', '#00c0ef', ' #451e3e', '#adcbe3', '#f37736', '#854442', '#3d1e6d', '#c68642'],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

  }

  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.endDate && this.endDate < this.startDate) {
      this.endDate = null;
    }
    this.stopTracking();
  }


  // ngOnDestroy() {
  //   if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
  // }

  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 900;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        this.getActivityInfo();
      }
    })
  }

}
