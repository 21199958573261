<div class="row padd">
    <div class="col-xl-2 col-md-2 mb-2 col-xs-2">
    </div>
    <!-- ==================== Cible Section ======================= -->
    <div class="col-xl-4 col-md-4 mb-2 col-xs-3">
        <div class="card blueStyle" title="Cible">
            <div class="value">
                <span *ngIf="percentageCible">Cible : {{percentageCible | number: '.0-1' }} %</span>
                <span *ngIf="!percentageCible">Cible : 0 %</span>
            </div>
        </div>
    </div>

    <!-- ==================== Réalisé Section ======================= -->
    <div class="col-xl-4 col-md-4 mb-2 col-xs-3">
        <div class="card" [ngClass]="((percentageCible > 0) && (percentageRealise >= percentageCible) && ((percentageRealise - percentageCible) > 5)) ? 'redStyle' : 
          ((percentageCible > 0) && (percentageRealise < percentageCible)) ? 'greenStyle' : 'yellowStyle'"
            title="Réalisé">

            <div class="value">
                <span *ngIf="percentageRealise">Réalisé : {{percentageRealise | number: '.0-1' }} %</span>
                <span *ngIf="!percentageRealise">Réalisé : 0 %</span>
            </div>

        </div>
    </div>

    <div class="col-xl-2 col-md-2 mb-2 col-xs-2">
    </div>

</div>
<div id="correlationMaintenanceChart" class="w-100 d-inline-block"></div>