<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="row">

  <div class="col-md-12">

    <!-- Error Section !-->
    <ng-container *ngIf="error.errorMessage">
      <div class="col-md-12">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
            ×
          </button>
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          {{error.errorMessage}}
        </div>
      </div>
    </ng-container>

    <div class="container">
      <ng-container *ngIf="(mode == 'ADD' || mode == 'UPDATE' )&& !loading">
        <div class=" mt-2 mb-2 text-center   well bgg">
          <h4 *ngIf="mode == 'ADD'" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: white;">
            <i class="fa fa-plus-circle add"></i>
            <span>Ajouter un nouveau validateur </span>
          </h4>

          <h4 *ngIf="mode == 'UPDATE'" style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
            <i class="fa fa-cog"></i>
            <span>Modifier validateur </span>
          </h4>
        </div>
        <div class="col-lg-12 well formBC">
          <div class="row">
            <form #validatorForm="ngForm" class="form-horizontal">
              <div class="col-sm-12">

                <!-- Nom -->
                <div class="form-group mrg">
                  <label>Nom</label>
                  <input [(ngModel)]="currentValidator.lastName" required name="lastName" type="text" placeholder="Nom"
                    class="form-control">
                </div>

                <div class="form-group mrg">
                  <label>Prénom</label>
                  <input [(ngModel)]="currentValidator.firstName" required name="firstName" type="text" placeholder="Prénom"
                    class="form-control">
                </div>

                <div class="form-group mrg">
                  <label>Téléphone</label>
                  <input [(ngModel)]="currentValidator.telephone" required name="telephone" type="text" placeholder="Téléphone"
                    class="form-control">
                </div>

              </div>
              <!-- ========================== Modal FOOTER (START) ===============================-->
              <div class="box-footer">
                <button (click)="addValidator()" *ngIf="mode == 'ADD'" class="btn btn-info pull-right"
                  [disabled]="!validatorForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
                </button>
                <button (click)="updateValidator()" *ngIf="mode == 'UPDATE'" class="btn btn-info pull-right"
                  [disabled]="!validatorForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
                </button>
                <button class=" btn btn-dark" (click)="cancel()">
                  Annuler
                </button>
              </div>
              <!-- ========================== Modal FOOTER (END) ===============================-->
            </form>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div>
