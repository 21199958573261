export class Path {
  nextStopDuration: number;
  nextStopDurationStr: string;
  pathDurationStr: string;

  beginPathLatitude: number;
  beginPathLongitude: number;

  endPathLatitude: number;
  endPathLongitude: number;

  beginPathGeocoding: string;
  endPathGeocoding: string;

  beginPathGeocodingDetails: string;
  endPathGeocodingDetails: string;

  beginPathTime: Date;
  endPathTime: Date;

  displayBeginPathTime: Date;
  displayEndPathTime: Date;

  deviceId: number;

  pathDuration: number;
  maxSpeed: number;

  distanceDriven: number;
  lastName: string;
  startAddress: string;
  endAddress: string;
  constructor() { }
}

export class PathPoi {

  beginPathLatitude: number;
  beginPathLongitude: number;

  endPathLatitude: number;
  endPathLongitude: number;

  beginPathGeocoding: string;
  endPathGeocoding: string;

  beginPathTime: Date;
  endPathTime: Date;

  deviceId: number;

  pathDuration: number;
  maxSpeed: number;

  distanceDriven: number;
  startAddress: string;
  endAddress: string;

  constructor() { }
}


export class PathConfig{

  deviceId : number;
  pathMinSpeed : number;
  pathMinSec : number;
  stopMinSec : number;
  pauseMinSec : number;
  distanceMinMeter : number;

  constructor() { }
}