<form class="form-inline">
  <div class="form-group mx-sm-3 mb-2">
    <!-- <label>Nombre de jour</label>
    <input [(ngModel)]="value1" type="number" class="form-control" placeholder="alerter avant" name="value1">-->
    <label>Nombre de km </label>
    <select class="form-control" [(ngModel)]="value1" name="value1" (change)="save()">
      <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
        {{day}}
      </option>
    </select>
  </div>
  <span *ngIf="showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-plus-square"></i>
    <span class="link">paramtrage avancé</span>
  </span>
  <span *ngIf="!showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-minus-square"></i>
    <span class="link">masquer paramtrage avancé</span>
  </span>
  <br>
  <hr>
  <div *ngIf="showAdvancedSettings">

    <table style="border-spacing: 10px;border-collapse: separate;">
      <tr>
        <td>
          <mat-slide-toggle [(ngModel)]="boardgamesAlertConfiguartion.isActive" name="engineOil" [labelPosition]="'before'">Plaquette</mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="boardgamesAlertConfiguartion.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="boardgamesAlertConfiguartion.value1" name="engineOilDay">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(KM)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <mat-slide-toggle [(ngModel)]="diskAlertConfiguartion.isActive" name="oilF" [labelPosition]="'before'">Disque</mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="diskAlertConfiguartion.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="diskAlertConfiguartion.value1" name="oilFDay">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(KM)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <mat-slide-toggle [(ngModel)]="pumpAlertConfiguartion.isActive" name="airF" [labelPosition]="'before'">Pompe</mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="pumpAlertConfiguartion.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="pumpAlertConfiguartion.value1" name="airFDay">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(KM)</b>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <mat-slide-toggle [(ngModel)]="oilAlertConfiguartion.isActive" name="carbF" [labelPosition]="'before'">Huile</mat-slide-toggle>
        </td>
        <td>
          <span *ngIf="oilAlertConfiguartion.isActive">
            <label>Alerter avant </label>
            <select class="form-control" [(ngModel)]="oilAlertConfiguartion.value1" name="carbFDay">
              <option *ngFor="let day of alertManagementService.days" [ngValue]="day" style="font-size: 14px;">
                {{day}}
              </option>
            </select>
            <b>(KM)</b>
          </span>
        </td>
      </tr>
    </table>

  </div>
  <br>
  <app-email-manager [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails"
    (onChange)="emailSettings($event)"></app-email-manager>

  <hr>

  <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive" (change)="save()"></mat-slide-toggle>

</form>