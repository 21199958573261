<ngx-loading [show]="globalLoading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="content" style="overflow-y: scroll; height:430px;">
    <div class="table-responsive  mt-2">
    <table class="table table-striped shadowBox">
        <thead class="tableFixHead">
        <tr>
            <th>Nom</th>
            <th>Image</th>
            <th>
                <div class="input-group input-group-sm">
                    <input type="text" name="table_search" class="form-control pull-left"
                           placeholder="Nom .." name="search" [(ngModel)]="keyword"
                           (keyup.enter)="searchGroup()">

                    <div class="input-group-btn">
                        <button type="submit" class="btn btn-default" (click)="searchGroup()"><i
                                class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </th>
        </tr>
        </thead>
        <tbody>

        <tr *ngIf="loading">
            <td style="text-align: center" colspan="13"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></td>
        </tr>

        <ng-container *ngIf="!loading">
            <tr *ngFor="let group of groups">
                <td>{{group.nom}}</td>
                <td>
                    <div *ngIf="group.imageUrl">
                        <img src="{{url+group.imageUrl}}" width="30px"/>
                    </div>
                </td>
                <td>
                    <button tooltip="Delete" (click)="confirmDeleteGroupe(group)"
                            class="btn ink-reaction btn-danger pull-right">
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                    <a class="pull-right" [routerLink]="['/client/dataManagement/groupe/form/', group.idGroupe, 'UPDATE']"
                       routerLinkActive="active">
                        <button class="btn btn-warning" tooltip="Modifier">
                            <i class="fa fa-cog" aria-hidden="true"></i>
                        </button>
                    </a>
                    <a class="pull-right" [routerLink]="['/client/dataManagement/groupe/form/', group.idGroupe, 'SHOW']"
                       routerLinkActive="active">
                        <button tooltip="Details" class="btn ink-reaction btn-success">
                            <i class="fa fa-eye" aria-hidden="true"></i>
                        </button>
                    </a>
                </td>
            </tr>
        </ng-container>

        </tbody>
    </table>
</div>
</div>
<div class="row">
    <div class="col-md-12" *ngIf="groups.length >0" style="text-align: center">
        <pagination class="pagination-sm"
                    [totalItems]="bigTotalItems"
                    [(ngModel)]="bigCurrentPage"
                    [maxSize]="maxSize"
                    [boundaryLinks]="true"
                    [rotate]="false"
                    [itemsPerPage]="itemsPerPage"
                    (pageChanged)="pageChanged($event)"
                    previousText="Précédent"
                    nextText="Suivant"
                    firstText="Premier"
                    lastText="Dernier">
        </pagination>
    </div>
</div>

<div class="modal fade" tabindex="-1"  bsModal #confirmDeleteModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="confirmDeleteModal.hide()">&times;
                </button>

                <h4 class="modal-title">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Es vous sur ?
                </h4>

            </div>
            <div class="modal-body">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <h4 style="color: white"><i class="fa fa-exclamation-triangle"></i> Attention !
                    </h4>
                    Êtes-vous sûr de supprimer ce groupe '{{currentGroup.nom}}'
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="confirmDeleteModal.hide()">
                    Annuler
                </button>
                <button type="button" class="btn btn-success" (click)="deleteGroup()">
                    <i class="fa fa-check" aria-hidden="true"></i> Oui, Je confirme
                </button>
            </div>
        </div>
    </div>
</div>
<!-- =================================== CONFIRM MODAL (START)====================================-->
