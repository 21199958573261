<div class="nav-tabs-custom" style="height:100%;">

  <!-- <ul class="nav nav-tabs pull-center">

    <li [routerLink]="['sinistres']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img-circle img1" title="Reparation" height="60px" width="60px" src="{{imagesCarsDir}}sinister.png" />
        <img class="img-circle img2" title="Reparation" height="60px" width="60px" src="{{imagesCarsDir}}sinister2.png" />
        <span>Sinistres</span>
      </a>
    </li>

    <li [routerLink]="['offenses']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img-circle img1" title="Reparation" height="60px" width="60px" src="{{imagesCarsDir}}infractions.png" />
        <img class="img-circle img2" title="Reparation" height="60px" width="60px" src="{{imagesCarsDir}}infractions2.png" />
        <span>Infractions</span>
      </a>
    </li>
  </ul> -->

  <!-- new design -->
  <div class="lossesmenu">
  
    <!-- Item 1 -->
  
        <input type="radio" name="lossesItem" id="losses-item-1" class="losses-toggle" [routerLink]="['sinistres']" 
          [checked]="getCurrentUrl() == 'sinistres'" />
        <label for="losses-item-1" title="Sinistres"><p class="icon"><img src="assets/icons/parcManagementIcons/car-accident.svg" class="svgImg" alt=""></p>
          <!-- <span>Sinistres</span> -->
        </label>
   
    <!-- Item 2 -->
  
        <input type="radio" name="lossesItem" id="losses-item-2" [routerLink]="['offenses']" 
        [checked]="getCurrentUrl() == 'offenses'" class="losses-toggle"/>
        <label for="losses-item-2" title="Infractions"><p class="icon"><img src="assets/icons/parcManagementIcons/high-speed.svg" class="svgImg" alt=""></p>
          <!-- <span>Infractions</span> -->
        </label>
    
    
    
    <div class="clear"></div>
    
    <!-- Bar -->
    <div class="losses">
      <div class="bar"></div>
    </div>
    
  </div>
  <!-- end new design -->

  <router-outlet></router-outlet>

</div>