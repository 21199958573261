import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartArretPoiEpsComponent } from './chart-arret-poi-eps.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [ChartArretPoiEpsComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot() // Importing ModalModule for modal functionalities if needed
  ],
  exports: [ChartArretPoiEpsComponent] // Exporting the component for use in other modules
})
export class ChartArretPoiEpsModule {}
