import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VidangeChartComponent } from './vidange-chart.component';
import { VidangeChartService } from './vidange-chart.service';
import { ToastrModule } from 'ngx-toastr';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxLoadingModule } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    VidangeChartComponent // Declare the component
  ],
  imports: [
    CommonModule,       // For basic Angular directives
    FormsModule,        // If you have forms in your component
    ToastrModule.forRoot(),  // For toastr notifications
    HighchartsChartModule,
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    VidangeChartService,  // Service related to the component
    DatePipe              // If you're using date manipulations
  ],
  exports: [
    VidangeChartComponent  // Exporting component so it can be used in other modules
  ]
})
export class VidangeChartModule { }
