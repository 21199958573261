<div class="col-md-12">

  <div class="box box-widget">
      <div class="box-header with-border">
          <div class="user-block">

              <img *ngIf="currentVehicule?.driver?.avatarUri" class="img-circle" src="{{currentVehicule?.driver?.avatarUri}}" alt="User Image">

              <ngx-avatar *ngIf="!currentVehicule?.driver?.avatarUri && (currentVehicule?.driver?.firstName || currentVehicule?.driver?.lastName)" class="img-circle" size="40" [name]="dataManagementService.getDriverName(currentVehicule?.driver)"></ngx-avatar>

              <span class="username">

                  <a *ngIf="currentVehicule.driver" (click)="goToDriverDetailPage()">{{dataManagementService.getDriverName(currentVehicule?.driver)}}</a>
                  <span *ngIf="!currentVehicule.driver">{{dataManagementService.getDriverName(currentVehicule?.driver)}}</span>

              </span>
              <span class="description">tel : {{currentVehicule?.driver?.telephone | noValue}} </span>
          </div>
          <div class="box-tools">

              <button (click)="goToUpdatePage()" type="button" class="btn btn-warning btn-xs"><i class="fa fa-edit"></i> Modifier</button>
              <button (click)="goToAssignPage()" type="button" class="btn btn-primary btn-xs"><i class="fa fa-hand-o-right"></i> Affecter</button>

          </div>
      </div>

      <!-- /.box-header -->
      <div class="box-body">

          <p><span style="font-weight: bold">Matricule :</span> {{currentVehicule?.matricule | noValue}} /
              <span style="font-weight: bold">Capacité :</span> {{currentVehicule?.capacity | noValue}} /
              <span style="font-weight: bold">Marque :</span> {{currentVehicule?.mark | noValue}} /
              <span style="font-weight: bold">Sous marque : </span>{{currentVehicule?.subMark | noValue}} /
              <span style="font-weight: bold">Surnom : </span>{{currentVehicule?.alias | noValue}} /
              <span style="font-weight: bold">V MAX :</span> {{currentVehicule?.maxSpeed | noValue}} /
              <span style="font-weight: bold">N° Jawaz :</span> {{currentVehicule?.jawazCardNumber | noValue}} /
              <span style="font-weight: bold">N° gasoil :</span> {{currentVehicule?.fuelCardNumber | noValue}} /
              <span style="font-weight: bold">S.gasoil :</span> {{currentVehicule?.cardFuelSolde | noValue}} /
              <span style="font-weight: bold">Secteur :</span> {{currentVehicule?.secteur | noValue}} /
              <span style="font-weight: bold">Mise en circulation :</span> {{currentVehicule?.circulationAt | date: 'yyyy-MM-dd HH:mm:ss' | noValue}} /
              <span style="font-weight: bold">Emails : </span>{{currentVehicule?.emails | noValue}}</p>

          <div class="row">
              <div class="col-md-12">
                  <form class="form-horizontal well">
                      <div class="row">
                          <div class="col-xs-6">
                              <p style="font-weight: bold">Assurance :</p>
                              <img style="margin: 0 auto;" *ngIf="currentVehicule?.avatarUri" src="{{currentVehicule?.avatarUri}}?{{refreshFiles}}" class="img-responsive img-radio">
                              <img style="margin: 0 auto;" *ngIf="!currentVehicule?.avatarUri" src="{{imagesDir}}/sign-add-icon.png" class="img-responsive img-radio">
                          </div>

                          <div class="col-xs-6">
                              <p style="font-weight: bold">Carte grise :</p>
                              <img style="margin: 0 auto;" *ngIf="currentVehicule?.grayCardUri" src="{{currentVehicule?.grayCardUri}}?{{refreshFiles}}" class="img-responsive img-radio">
                              <img style="margin: 0 auto;" *ngIf="!currentVehicule?.grayCardUri" src="{{imagesDir}}/sign-add-icon.png" class="img-responsive img-radio">
                          </div>

                      </div>
                  </form>
              </div>
          </div>

          <div class="row">
            <div class="col-md-12">
                <form class="form-horizontal well">
                    <div class="row">
                        <div class="col-xs-3">
                            <p style="font-weight: bold">Passation 1 :</p>
                            <img style="margin: 0 auto;" *ngIf="currentVehicule?.contractingUri" src="{{currentVehicule?.contractingUri}}?{{refreshFiles}}" class="img-responsive img-radio">
                            <img style="margin: 0 auto;" *ngIf="!currentVehicule?.contractingUri" src="{{imagesDir}}/sign-add-icon.png" class="img-responsive img-radio">
                        </div>

                        <div class="col-xs-3">
                            <p style="font-weight: bold">Passation 2 :</p>
                            <img style="margin: 0 auto;" *ngIf="currentVehicule?.secondContractingUri" src="{{currentVehicule?.secondContractingUri}}?{{refreshFiles}}" class="img-responsive img-radio">
                            <img style="margin: 0 auto;" *ngIf="!currentVehicule?.secondContractingUri" src="{{imagesDir}}/sign-add-icon.png" class="img-responsive img-radio">
                        </div>

                        <div class="col-xs-3">
                          <p style="font-weight: bold">Engagement :</p>
                          <img style="margin: 0 auto;" *ngIf="currentVehicule?.thirdContractingUri" src="{{currentVehicule?.thirdContractingUri}}?{{refreshFiles}}" class="img-responsive img-radio">
                          <img style="margin: 0 auto;" *ngIf="!currentVehicule?.thirdContractingUri" src="{{imagesDir}}/sign-add-icon.png" class="img-responsive img-radio">
                      </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

  </div>
</div>