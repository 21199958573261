<div class="row">
      <div class="col-lg-12">
            <div class="box-header page-header with-border">
                  <!-- =================================== TITLE (START)==================================== -->
                  <!-- <h3 class="box-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;"><i class="fa fa-list"></i>

                        <span style="text-decoration: underline">

                              <span>Liste des charges conducteur du véhicule :</span>
                              <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher la véhicule" [routerLink]="['/client/dataManagement/vehicule/form', vehicule.idVehicule, 'SHOW']">{{vehicule.matricule}}</a>

                              <span *ngIf="vehicule.driver"> - conducteur : 
                                    <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher le conducteur" [routerLink]="['/client/dataManagement/driver/form', vehicule.driver.idDriver, 'SHOW']">{{vehicule?.driver?.firstName}} {{vehicule?.driver?.lastName}}</a>
                              </span>

                        </span>
 
                        
                        <span *ngIf="!vehicule.driver" data-toggle="tooltip" class="badge label-warning">
                              <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                        </span>
 
                  </h3> -->
                  <!-- =================================== TITLE (END)====================================== -->
                  <!-- =================================== OPTIONS (START)==================================== -->
                  <div class="option-add">
                       <!-- <span title="Ajouter" (click)="onAdd()">
                              <i class="fa fa-plus-circle"></i> Ajouter</span>
                        -->
                              <button type="button" class="btn btn-success  btn-sm" title="Ajouter" 
                              (click)="onAdd()" ><i class="fa fa-plus-circle"></i> </button>
                  </div>
                  <div *ngIf="driverChargesId.length > 0" class="option-delete">
                        <span title="Supprimer tout" (click)="onConfirmDeleteMultiple()">
                              <i class="fa fa-minus-circle"></i> Supprimer tout</span>
                  </div>
                  <!-- =================================== OPTIONS (END)==================================== -->
            </div>
      </div>
      <div class="col-lg-12">
            <div class="content">
                  <app-driver-charge-list [driver]="vehicule.driver" [group]="group" [actionAdd]="actionAdd" [actionDeleteMultiple]="actionDeleteMultiple" (wasChecked)='wasChecked($event)'></app-driver-charge-list>
            </div>
      </div>
</div>