import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchDropdown } from './search-dropdown.component';

@NgModule({
  declarations: [
    SearchDropdown,
  ],
  imports: [
    CommonModule,   
    FormsModule,    
  ],
  exports: [
    SearchDropdown, 
  ],
})
export class SearchDropdownModule {}
