import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import { DataManagementService } from 'src/app/client-management/data-management';

@Injectable({providedIn: 'root'})
export class KmJourGuard implements CanActivate {

    constructor(private router: Router, private dataResourceMangement: DataManagementService) {
    }

    canActivate() {

        if ( this.dataResourceMangement.groups !== null) {
            return true;
        }


        this.router.navigate(['/client/realtime']);
        return false;
    }

}
