<section class="content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
    <div class="row" style="position: absolute; width: 100%;">
        <div class="datatable-container" style="margin-top: -10px;">
            <div class="col-md-12 datatable-container-col">
                <div class="box" style="border-radius: 0px;">
                    <div class="overlay" *ngIf="!isResultLoaded">
                        <i class="fa fa-refresh fa-spin"></i>
                    </div>
                    <div class="box-body">
                        <form class="form-horizontal">
                            <div class="form-group">
                                <i class="col-sm-1 fa fa-list table-toggle" (click)="tableToggle()"></i>
                                <label class="col-sm-1 control-label grp-lbl"
                                    style="padding-left: 14px !important">Groupe</label>
                                <div class="col-sm-2 grp-slct">
                                    <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup"
                                        (ngModelChange)="chooseGroup($event)">
                                        <option *ngFor="let group of groups" style="font-size: 14px;" [ngValue]="group">
                                            <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst | truncate:[30]}}  / </span>  {{group.nom | capitalizefirst}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                                <div class="col-sm-2">
                                    <select class="form-control input-sm vhcl-slct" name="vehicules"
                                        [(ngModel)]="selectedVehicule" (ngModelChange)="chooseVehicule($event)">
                                        <option *ngFor="let vehicule of selectedGroup.vehicules"
                                            style="font-size: 14px;" [ngValue]="vehicule">{{dataManagementService.getVehiculeName(vehicule)
                                            |
                                            capitalizefirst}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label dd-lbl">Date début</label>
                                <div class="col-sm-2">
                                    <div class="input-group dd-npt">

                                        <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                                            [(ngModel)]="startDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                                            placement="bottom" [maxDate]="endDate" autocomplete="off">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                                    </div>
                                </div>
                                <label class="col-sm-1 control-label df-lbl">Date fin</label>
                                <div class="col-sm-2">
                                    <div class="input-group df-npt">

                                        <input type="text" class="form-control input-sm" bsDatepicker name="endDate"
                                            [(ngModel)]="endDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm', containerClass: 'theme-dark-blue'}"
                                            placement="bottom" [minDate]="startDate" autocomplete="off">
                                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                                    </div>
                                </div>
                                <div class="col-sm-1 form-btns">
                                    <button type="button" class="btn btn-success btn-flat btn-sm" style="border-radius: 4px;"
                                        (click)="getAllStops()">
                                        <b><i class="fa fa-search" aria-hidden="true"></i></b>
                                    </button>
                                    <button class="btn btn-primary btn-sm excel-button" (click)="exportStops()"
                                        [disabled]="stops.length == 0"><i class="fa fa-file-excel-o"></i></button>
                                        <button class="btn btn-primary btn-sm pdf-button" (click)="exportStopsPDF()"
                                        [disabled]="stops.length == 0" title="PDF"><i class="fa fa-file-pdf-o"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-5 stop-table" *ngIf="showResultTable">
            <table class="table table-striped" style="font-size: 12px;font-weight: bold" width="100%">
                <thead>
                    <tr>
                        <th width="25%" style="text-align: center"><i class="fa fa-clock-o" aria-hidden="true"></i>
                            Date
                            Arrêt
                        </th>
                        <th width="45%" style="text-align: center">Lieu Arrêt</th>
                        <th width="30%" style="text-align: center">Durée Arrêt</th>
                    </tr>
                </thead>
                <tbody>

                    <ng-container *ngFor="let stop of stops">

                        <tr (click)="addStopToArray(stop, $event)" [class.stop]="StopSelected(stop)">

                            <td width="20%" data-toggle="tooltip" title="{{stop.beginStopTime | date:'dd-MM-y HH:mm'}}">
                                {{stop.beginStopTime | date:'dd-MM HH:mm'}}
                            </td>
                            <td width="60%" data-toggle="tooltip" title="{{stop.geocodingDetails}}">
                                {{stop.geocoding | truncate :[60]}}
                            </td>
                            <td width="20%" data-toggle="tooltip" title="{{stop.stopDurationStr}}">
                                {{stop.stopDurationStr}}
                            </td>

                        </tr>

                        <tr *ngIf="isSelectedStop(stop)">
                            <td colspan="3" style="text-align: center">

                                <button *ngIf="signinService.isRootAdmin() || this.signinService.isHasRole('ROLE_POI')" type="button" class="btn btn-success btn-xs" style="font-size: 10px;"
                                    (click)="addPOI(stop)">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i> Ajouter comme POI
                                </button>
                                <button *ngIf="signinService.isRootAdmin() || this.signinService.isHasRole('ROLE_POI')" type="button" class="btn btn-success btn-xs" style="font-size: 10px;"
                                    (click)="addClient(stop)">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i> Ajouter comme Client
                                </button>


                                <button style="font-size: 10px;" type="button" title="Afficher adresse google"
                                    class="btn btn-success btn-xs" (click)="displayGoogleAdress(stop)">
                                    <i class="fa fa-google" aria-hidden="true"></i>
                                </button>

                            </td>
                        </tr>

                    </ng-container>


                </tbody>
            </table>
        </div>
        <div class="map-container">
            <app-leaflet></app-leaflet>
        </div>
    </div>

</section>

<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header black-bg">
                <h4 class="modal-title pull-left" style="font-family: 'Inconsolata', monospace;font-size: 17px;">
                    <i class="fa fa-plus-circle"></i> Nouveau point d'intérêt
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="childModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <app-poi-form [pointInterest]="pointInterest" [mode]="'ADD'"
                    (pointInterestWasAdded)="childModal.hide()"></app-poi-form>

            </div>
        </div>
    </div>
</div>

<div bsModal #childModal2="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header black-bg">
                <h4 class="modal-title pull-left" style="font-family: 'Inconsolata', monospace;font-size: 17px;">
                    <i class="fa fa-plus-circle"></i> Nouveau client
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="childModal2.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-poi-client-form [poiClient]="poiClient" [mode]="'ADD'" (poiClientWasAdded)="childModal2.hide()">
                </app-poi-client-form>
            </div>
        </div>
    </div>
</div>
