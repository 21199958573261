import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';

@Injectable()
export class MonthlyMaintenanceCostsService {

  constructor(private _http: HttpClient) { }
  
  public getMonthlyCostOfMaintenaceByGroup(date : DateInterval): Observable<any[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<any[]>(dns + 'reparations/costOfMaintenaceByGroup', date, options);
  }

  public getMonthlyCostOfMaintenaceByGroupId(groupId : number, date : DateInterval): Observable<any[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<any[]>(dns + 'reparations/costOfMaintenaceByGroup/group/'+groupId, date, options);
  }
}
