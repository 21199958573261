import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatFullDurationPipe } from './format-full-duration.pipe';

@NgModule({
  declarations: [FormatFullDurationPipe], // Declare the pipe
  imports: [CommonModule],                // CommonModule is optional but useful for shared modules
  exports: [FormatFullDurationPipe]       // Export the pipe for use in other modules
})
export class FormatFullDurationModule {}
