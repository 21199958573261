<ngx-loading [show]="globalLoading"></ngx-loading>

<div class="row">
  <div class="col-md-12">
    <div class="box-header with-border">
      <form #dashbordForm="ngForm" class="form-horizontal form-class">

          <div class="col-lg-5 form-group input-section">
              <label class="control-label">Du</label>
              <div>
                  <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" placement="bottom"
                      required (ngModelChange)="onChangeStartDate()">
              </div>
          </div>

          <div class="col-lg-5 orm-group input-section">
              <label class="control-label">Au</label>
              <div>
                  <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" placement="bottom"
                      required>
              </div>
          </div>


          <div>

              <button type="button" (click)="getStopInfo()"  class="btn btn-success" style="padding: 6px;">
                  <i class="fa fa-search pull-left" aria-hidden="true"></i>
                  
              </button>

          </div>
      </form>
      <div class="row">
        <div *ngIf="updateTrackingTimer" class="tracking col-md-12">
            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
            Mise à jour dans {{updateTrackingTimer}} seconds...
        </div>
    </div>
  </div>
  </div>
</div>

<div id="stop" class="w-100 d-inline-block"></div>