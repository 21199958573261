import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { FuelingPriceSetting } from '../model/index';

@Injectable()
export class FuelingPriceSettingService {

  constructor(private _http: HttpClient) { }

  getList(): Observable<FuelingPriceSetting[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<FuelingPriceSetting[]>(dns + "fuelingPriceSettings", { headers: headers });
  }

  getLastFuelingPrice(): Observable<FuelingPriceSetting> {
    let headers = createAuthorizationHeader();
    return this._http.get<FuelingPriceSetting>(dns + "fuelingPriceSettings/last", { headers: headers });
  }

  save(fuelingPriceSetting: FuelingPriceSetting): Observable<FuelingPriceSetting> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<FuelingPriceSetting>(dns + "fuelingPriceSettings", fuelingPriceSetting, options);
  }

  delete(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.delete<Boolean>(dns + "fuelingPriceSettings/"+id, options);
  }
}
