import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterventionListComponent } from './intervention-list.component';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InterventionService } from './intervention.service'; // Adjust the path as needed
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { InterventionFormModule } from '../intervention-form/intervention-form.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
  declarations: [InterventionListComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    FormsModule,
    InterventionFormModule,
    PaginationModule.forRoot(),
  ],
  providers: [InterventionService], // Provide the InterventionService
  exports: [InterventionListComponent] // Export the component if it will be used in other modules
})
export class InterventionListModule { }
