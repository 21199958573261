<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="row">

  <div class="col-md-12">

    <!-- Error Section !-->
    <ng-container *ngIf="error.errorMessage">
      <div class="col-md-12">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
            ×
          </button>
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          {{error.errorMessage}}
        </div>
      </div>
    </ng-container>

    <div class="container">
      <ng-container *ngIf="(mode == 'ADD' || mode == 'UPDATE' )&& !loading">
        <div class=" mt-2 mb-2 text-center   well bgg">
          <h4 *ngIf="mode == 'ADD'" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: white;">
            <i class="fa fa-plus-circle add"></i>
            <span>Ajouter un nouveau type de transport </span>
          </h4>

          <h4 *ngIf="mode == 'UPDATE'" style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
            <i class="fa fa-cog"></i>
            <span>Modifier type de transport </span>
          </h4>
        </div>
        <div class="col-lg-12 well formBC">
          <div class="row">
            <form #transportTypeForm="ngForm" class="form-horizontal">
              <div class="col-sm-12">

                <!-- Type -->

                <div class="form-group mrg">
                  <label>Type *</label>
                  <select class="form-control input-sm" name="type" [(ngModel)]="currentTransportType.name" required>
                    <option value="BPE">
                      BPE
                    </option>
                    <option value="PF">
                      PF
                    </option>
                  </select>
                </div>
                <!-- end Type -->

                <div class="form-group mrg">
                  <label>Véhicules</label>
                  <ng-multiselect-dropdown-angular7
                  name="vehiclesSelected"
                  [data]="vehicules"
                    placeholder="select Véhicule"
                    [(ngModel)]="currentTransportType.vehicules"
                    [settings]="vehiculesDropdownSettings" required>
                  </ng-multiselect-dropdown-angular7>

                  <ng-multiselect-dropdown
                  [placeholder]="'personnalisé'"
                  [settings]="groupDropdownSettings"
                  [data]="vehicules"
                  [(ngModel)]="currentTransportType.vehicules"
                >
                </ng-multiselect-dropdown>
                </div>

              </div>
              <!-- ========================== Modal FOOTER (START) ===============================-->
              <div class="box-footer">
                <button (click)="addTransportType()" *ngIf="mode == 'ADD'" class="btn btn-info pull-right"
                  [disabled]="!transportTypeForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
                </button>
                <button (click)="updateTransportType()" *ngIf="mode == 'UPDATE'" class="btn btn-info pull-right"
                  [disabled]="!transportTypeForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
                </button>
                <button class=" btn btn-dark" (click)="cancel()">
                  Annuler
                </button>
              </div>
              <!-- ========================== Modal FOOTER (END) ===============================-->
            </form>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div>
