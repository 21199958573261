<div>

    <div *ngIf="emails.length > 0" style="padding: 10px;">
        <span *ngFor="let email of emails" (click)="emailValue = email;emailOldValue = email; actionMode = 'UPDATE'" class="badge badge-labeled"
            style="background: #00a65a;cursor: auto">{{email}}
            <i class="fa fa-trash" (click)="deleteEmail(email)"></i>
        </span>
    </div>

    <form class="form-inline" #emailForm="ngForm">

        <div class="form-group mx-sm-3 mb-2" *ngIf="emails.length < EMAIL_MAX_LENGTH">
            <label>Email</label>
            <div class="input-group">
                <input [(ngModel)]="emailValue" type="text" name="email" class="form-control" required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    placeholder="test@domain.ext">
                <span *ngIf="emailForm.valid && actionMode == 'ADD'" (click)="addEmail()" class="input-group-addon" style="color: black;background-color: #00a65a;">
                    <i class="fa fa-plus"></i>
                </span>

                <span *ngIf="emailForm.valid && actionMode == 'UPDATE'" (click)="updateEmail()" class="input-group-addon" style="color: black;background-color: #00a65a;">
                    <i class="fa fa-cog"></i>
                </span>

                <span *ngIf="!emailForm.valid" tooltip="email non valide !" class="input-group-addon" style="color: white;background-color: orange;">
                    <i class="fa fa-exclamation-triangle"></i>
                </span>

                <span (click)="init()" class="input-group-addon" style="color: white;background-color: #aa1111;">
                    <i class="fa fa-times"></i>
                </span>

            </div>
        </div>
    <div>
        <label *ngIf="showActiveSection">Copier dans les autres alertes</label>
        <mat-slide-toggle *ngIf="showActiveSection" [(ngModel)]="enableGlobalConfig" name="isActive" (change)="enableGlobalConfigToAllVehicules = false;emitValue();" title="Utiliser ces mails dans toutes les autres notifications">
            <i class="fa fa-bell"></i>
        </mat-slide-toggle>

        <label *ngIf="showActiveSectionOfAllVehicules" class="padd-left">Copier dans tous les véhicules</label>
        <mat-slide-toggle *ngIf="showActiveSectionOfAllVehicules" [(ngModel)]="enableGlobalConfigToAllVehicules" name="isActiveinAllVehicules" (change)="enableGlobalConfig = false;emitValue();" title="Utiliser ces mails dans toutes les autres notifications">
            <i class="fa fa-bell"></i>
        </mat-slide-toggle>
    </div>
    </form>
</div>