import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  // transform(value: number): string {
  //   if(value==0)
  //     return '0';
  //   const suffixes = ["", "k", "M", "B", "T"]; // You can extend this array for larger numbers if needed
  //   let suffixIndex = 0;

  //   while (value >= 1000 && suffixIndex < suffixes.length - 1) {
  //     value /= 1000;
  //     suffixIndex++;
  //   }

  //   return value.toFixed(3).replace(/\.?0+$/, '') + ' ' + suffixes[suffixIndex];
  // }

  transform(value: any, ...args: any[]): string {
    if (value == undefined) value = 0;

    if (value >= 1e9) {
      const integralPart = Math.floor(value / 1e9);
      const fractionalPart =
        value % 1e9 !== 0 ? `${value % 1e9}`.substring(0, 2) : '';
      return `${integralPart}B${fractionalPart}`;
    } else if (value >= 1e6) {
      const integralPart = Math.floor(value / 1e6);
      const fractionalPart =
        value % 1e6 !== 0 ? `${value % 1e6}`.substring(0, 2) : '';
      return `${integralPart}M${fractionalPart}`;
    } else if (value >= 1e3) {
      const integralPart = Math.floor(value / 1e3);
      const fractionalPart =
        value % 1e3 !== 0 ? `${value % 1e3}`.substring(0, 2) : '';
      return `${integralPart}K${fractionalPart}`;
    } else {
      return value.toString();
    }
  }
}
