import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositiveNumberPipe } from './positive-number.pipe'; // Adjust the path if needed

@NgModule({
  declarations: [PositiveNumberPipe],
  imports: [
    CommonModule // Import CommonModule to use common directives like ngIf, ngFor, etc.
  ],
  exports: [PositiveNumberPipe] // Export the pipe for use in other modules
})
export class PositiveNumberPipeModule { }
