import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Rx';
import { Alert, AlertConfiguration } from '../../alert';
import { DataManagementService } from '../../../data-management/data-management.service';
import { AlertManagementService } from '../../alert-management.service';

@Component({
  selector: 'app-towing-settings',
  templateUrl: './towing-settings.component.html',
  styleUrls: ['./towing-settings.component.css']
})
export class TowingSettingsComponent implements OnInit, OnDestroy {
  public isEnable: boolean = true;
  public checked: boolean = true;
  public showAdvancedSettings: boolean = false;
  public loader: boolean = false;
  public stopTimeSelectMode: boolean = true;
  public minutes: string[] = [];
  public stopTime: string | undefined;

  public alert: Alert = new Alert();
  public towAlertConfiguration: AlertConfiguration = new AlertConfiguration();
  public cronAlertConfiguration: AlertConfiguration = new AlertConfiguration();

  private getVehiculeWasSelectedSubscription: Subscription | undefined;

  constructor(
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService,
    private dataManagementService: DataManagementService
  ) {
    this.initMinutes();
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService!.selectedVehicule.idDevice
        );
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('TOW', device)
      .subscribe(
        alert => {
          this.alert = alert;
          this.cronAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'DATE'
          )
            ? this.alert.alertConfigurations.filter(
                alertConfiguration => alertConfiguration.type === 'DATE'
              )[0]
            : this.cronAlertConfiguration;
          this.towAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'TOW'
          )
            ? this.alert.alertConfigurations.filter(
                alertConfiguration => alertConfiguration.type === 'TOW'
              )[0]
            : this.towAlertConfiguration;
          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  chooseStopTime($event: any) {
    if ($event === 'Autre..') {
      this.stopTimeSelectMode = false;
      this.towAlertConfiguration.value1 = '10';
    }
  }

  initMinutes() {
    for (var i = 1; i <= 12; i++) {
      this.minutes.push((i * 10).toString());
    }
    this.minutes.push('Autre..');
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
  }

  save() {
    this.loader = true;
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrer', 'info !');
      },
      error => {
        this.loader = false;
        this.toastr.error('Configuration non enregistrée', 'info !');
      }
    );
  }
}
