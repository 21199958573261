import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { TechnicalDataService } from "./technical-data.service";
import { TechnicalData, DeviceOpt, DeviceCarb } from "./technical-data";
import { Subscription } from "rxjs";
import { Group, Vehicule } from "./../../data-management/data-management.model";
import { DataManagementService } from "./../../data-management/data-management.service";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

// const Highcharts = require("highcharts/highcharts.src");
// import "highcharts/adapters/standalone-framework.src";
import * as Highcharts from 'highcharts';
interface IDeviceOpt {
  id: number;
  name: string;
}
interface IChartData {
  y?: number;  // Propriété y est optionnelle et peut être un nombre
}

@Component({
  selector: "app-technicaldata",
  templateUrl: "technical-data.component.html",
  styleUrls: ["technical-data.component.css"]
})
export class TechnicalDataComponent implements OnInit {
  //Chart vars
  chart: any;
  options: Object;
  serieName: any;

  //Device Options
  deviceopt: DeviceOpt;

  //Device Carburant
  devicecarb: DeviceCarb;

  groups: Group[];
  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();
  AllGroups: Subscription;
  vehicule: Vehicule;

  technicaldata: TechnicalData[];

  startDate: Date = new Date();
  endDate: Date = new Date();

  //MultiSelelct vars

  dropdownList: any[] = [];
  selectItems: any[] = [];
  dropdownSettings = {};

  isResultLoaded: boolean = true;


  constructor(
    private technicaldataservice: TechnicalDataService,
    private dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private localeService: BsLocaleService
  ) {
    this.localeService.use("fr");
  }

  ngOnInit() {
    //init multiselect
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select option",
      selectAllText: "Select Tous",
      unSelectAllText: "UnSelect Tous",
      enableSearchFilter: false,
      classes: "custom-class-multiselect",
      badgeShowLimit: 1
    };

    this.startDate.setTime(this.startDate.getTime());
    this.endDate.setTime(this.endDate.getTime() + 3600000);
    this.startDate.setHours(0,0,0,0);
    this.endDate.setHours(23,59,59,59);

    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule;

      this.getDeviceOpt(this.selectedVehicule.idDevice);
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
              this.getDeviceOpt(this.selectedVehicule.idDevice);
            }
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.AllGroups) this.AllGroups.unsubscribe();
  }

  //Get Device Options and fill the dropdownList
  getDeviceOpt(deviceid: number) {
    if (deviceid <= 0) {
      this.toastr.warning("Id de boitier introuvable !", "Erreur")
      return;
    }
    this.dropdownList = [];
    this.isResultLoaded = false;
    this.technicaldataservice.getDeviceOpt(deviceid).subscribe(response => {
      this.deviceopt = response;
      this.dropdownList.push({ id: 1, option: "speed", itemName: "Vitesse" });
      if (this.deviceopt.useIgnition != 0) {
        this.dropdownList.push({
          id: 0,
          option: "ignition",
          itemName: "Ignition"
        });
      }
      if (this.deviceopt.useTemp != 0) {
        this.dropdownList.push({
          id: 2,
          option: "temp",
          itemName: "Température"
        });
      }
      if (
        this.deviceopt.useFuel != 0 ||
        this.deviceopt.useFms != 0 ||
        this.deviceopt.useJ1708 != 0
      ) {
        this.dropdownList.push({
          id: 3,
          option: "fuel",
          itemName: "Carburant"
        });
      }
      if (this.deviceopt.useFms != 0 || this.deviceopt.useJ1708 != 0) {
        this.dropdownList.push({ id: 4, option: "rpm", itemName: "RPM" });
        this.dropdownList.push({
          id: 5,
          option: "temp_engine",
          itemName: "Temp Moteur"
        });
        this.dropdownList.push({
          id: 6,
          option: "fuel_rate",
          itemName: "Consomation Inst"
        });
      }
      this.isResultLoaded = true;
    });
  }

  onItemSelect(item: any) {
  }

  displayChart() {
    //Get Selected Options
    let devopt: IDeviceOpt[] = [];
    if (this.selectItems.length > 0) {
      this.selectItems.forEach(itm => {
        devopt.push(itm.option);
      });
    }

    // Options vars
    let speed : any[] = [];
    let fuel : any[] = [];
    let temp : any[] = [];
    let ignition : any[] = [];
    let rpm : any[] = [];
    let temp_engine : any[] = [];
    let fuel_rate : any[] = [];
    let ok: boolean = false;

    //Select data Device carburant
    this.technicaldataservice
      .getDeviceCarb(this.selectedVehicule.idDevice)
      .subscribe(res => {
        this.devicecarb = res;
      });

    //Get Fuel Data and fill all options
    this.technicaldataservice
      .getFuelDetail(
        this.selectedVehicule.idDevice,
        {
          startDate: this.startDate,
          endDate: this.endDate
        },
        devopt
      )
      .subscribe(res => {
        this.technicaldata = res;
        if (this.technicaldata.length == 0) {
          this.toastr.warning("Pas de données à afficher");
        }
        this.technicaldata.forEach(stat => {
          if (stat.speed != undefined) {
            speed.push({ x: stat.date, y: stat.speed });
          }
          if (stat.fuel != undefined) {
            if (this.devicecarb.max - this.devicecarb.min > 0) {
              if (
                stat.fuel >= this.devicecarb.min &&
                stat.fuel <= this.devicecarb.max
              ) {
                fuel.push({
                  x: stat.date,
                  y:
                    ((stat.fuel - this.devicecarb.min) /
                      (this.devicecarb.max - this.devicecarb.min)) *
                    this.devicecarb.volume
                });
              }
            } else {
              if (
                stat.fuel >= this.devicecarb.max &&
                stat.fuel <= this.devicecarb.min
              ) {
                fuel.push({
                  x: stat.date,
                  y:
                    ((stat.fuel - this.devicecarb.min) /
                      (this.devicecarb.max - this.devicecarb.min)) *
                    this.devicecarb.volume
                });
              }
            }
          }
          /*if (stat.temp != undefined) {
            temp.push({ x: stat.date, y: parseInt(stat.temp.substr(0, 2)) });
          }*/
          if (stat.ignition != undefined) {
            ignition.push({ x: stat.date, y: stat.ignition ? 1 : 0 });
            ok = true;
          }
          if (stat.rpm != undefined && ok == true) {
            rpm.push({ x: stat.date, y: stat.rpm });
          }
          if (stat.temp_engine != undefined && ok == true) {
            temp_engine.push({ x: stat.date, y: stat.temp_engine });
          }
          if (stat.fuel_rate != undefined && ok == true) {
            fuel_rate.push({ x: stat.date, y: stat.fuel_rate });
          }
        });

        //Charts vars
        let serie = [];
        let yaxis = [];

        for (let i = 1; i < ignition.length; i++) {
          if (
            ignition[i].y > ignition[i - 1].y ||
            ignition[i].y < ignition[i - 1].y
          ) {
            ignition[i].x = ignition[i - 1].x;
          }
        }

        //Fill all series and yxais
        if (this.ChartSeries(ignition) == true) {
          yaxis.push({
            title: {
              text: "Ignition",
              style: {
                color: "rgb(255, 127, 0)"
              }
            },
            labels: {
              style: {
                color: "rgb(255, 127, 0)"
              }
            },
            min: 0,
            max: 1,
            alignTicks: false,
            endOnTick: false,
            tickInterval: 1
          });
          serie.push({
            name: "Ignition",
            type: "area",
            color: "rgb(255, 127, 0)",
            data: ignition,
            yAxis: this.PositionYaxis(yaxis, "Ignition")
          });
        }
        if (this.ChartSeries(speed) == true) {
          yaxis.push({
            title: {
              text: "vitesse",
              style: {
                color: "rgb(44, 161, 33)"
              }
            },
            labels: {
              format: "{value} " + "Km/h",
              style: {
                color: "rgb(44, 161, 33)"
              }
            },
            min: 0
          });
          serie.push({
            name: "Vitesse",
            data: speed,
            color: "rgb(44, 161, 33)",
            yAxis: this.PositionYaxis(yaxis, "vitesse"),
            tooltip: {
              valueSuffix: "KM/H",
              valueDecimals: 2
            }
          });
        }
        if (this.ChartSeries(fuel) == true) {
          yaxis.push({
            title: {
              text: "carburant",
              style: {
                color: "rgb(23, 119, 182)"
              }
            },
            labels: {
              format: "{value} " + "L",
              style: {
                color: "rgb(23, 119, 182)"
              }
            },
            min: 0,
            opposite: yaxis.length !== 0
          });
          serie.push({
            name: "Carburant",
            data: fuel,
            color: "rgb(23, 119, 182)",
            yAxis: this.PositionYaxis(yaxis, "carburant"),
            tooltip: {
              valueSuffix: "L",
              valueDecimals: 2
            }
          });
        }
        if (this.ChartSeries(temp) == true) {
          yaxis.push({
            title: {
              text: "temperature",
              style: {
                color: "rgb(107, 57, 156)"
              }
            },
            labels: {
              format: "{value} " + "°C",
              style: {
                color: "rgb(107, 57, 156)"
              }
            },
            min: 0,
            opposite: yaxis.length !== 0
          });
          serie.push({
            name: "Temperature",
            data: temp,
            color: "rgb(107, 57, 156)",
            yAxis: this.PositionYaxis(yaxis, "temperature"),
            tooltip: {
              valueSuffix: "°C",
              valueDecimals: 2
            }
          });
        }
        if (this.ChartSeries(rpm) == true) {
          yaxis.push({
            title: {
              text: "RPM",
              style: {
                color: "rgb(230, 20, 9)"
              }
            },
            labels: {
              format: "{value} " + "Tr/Min",
              style: {
                color: "rgb(230, 20, 9)"
              }
            },
            min: 0,
            opposite: yaxis.length !== 0
          });
          serie.push({
            name: "rpm",
            data: rpm,
            color: "rgb(230, 20, 9)",
            yAxis: this.PositionYaxis(yaxis, "RPM"),
            tooltip: {
              valueSuffix: "°C",
              valueDecimals: 2
            }
          });
        }
        if (this.ChartSeries(temp_engine) == true) {
          yaxis.push({
            title: {
              text: "temp Moteur",
              style: {
                color: "rgb(179, 89, 31)"
              }
            },
            labels: {
              format: "{value} " + "°C",
              style: {
                color: "rgb(179, 89, 31)"
              }
            },
            min: 0,
            opposite: yaxis.length !== 0
          });
          serie.push({
            name: "Temp Moteur",
            data: temp_engine,
            color: "rgb(179, 89, 31)",
            yAxis: this.PositionYaxis(yaxis, "temp Moteur"),
            tooltip: {
              valueSuffix: "°C",
              valueDecimals: 2
            }
          });
        }
        if (this.ChartSeries(fuel_rate) == true) {
          yaxis.push({
            title: {
              text: "Consomation Inst",
              style: {
                color: "rgb(75,93,157)"
              }
            },
            labels: {
              format: "{value} " + "L",
              style: {
                color: "rgb(75,93,157)"
              }
            },
            min: 0
          });
          serie.push({
            name: "Consomation Inst",
            data: fuel_rate,
            color: "rgb(75,93,157)",
            yAxis: this.PositionYaxis(yaxis, "Consomation Inst"),
            tooltip: {
              valueSuffix: "°C",
              valueDecimals: 2
            }
          });
        }

// Créez une nouvelle HighChart
        this.options = new Highcharts.Chart({
          title: { text: "Véhicule: " + this.selectedVehicule.matricule },
          chart: {
            type: "spline",
            renderTo: "container",
            height: 420,
            zoomType: "x"
          },

          xAxis: [
            {
              type: "datetime",
              tickInterval: 3600 * 1000,
              labels: {
                // formatter: function(this: Highcharts.AxisLabelsFormatterContextObject): string {
                //   return Highcharts.dateFormat("%d/%m/%Y %H:%M", this.value);
                // }
              },
              crosshair: true
            }],

          yAxis: yaxis,

          plotOptions: {
            series: {
              turboThreshold: 10000
            }
          },

          // series: serie
        });


      });
  }

  //Return the position of Yaxis
  PositionYaxis(data: any, option: string) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.text === option) {
        return i;
      }
    }
  }

  //check if the series is empty or not
  ChartSeries(data: IChartData[] = []): boolean {
    let ok: boolean = false;
    data.forEach(dt => {
      if (dt.y !== undefined) {
        ok = true;
      }
    });
    return ok;
  }



  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      this.selectItems = [];
      this.chooseVehicule(this.selectedVehicule);
    }
  }

  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
    this.getDeviceOpt(this.selectedVehicule.idDevice);
    this.selectItems = [];
  }
}
