import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Mission, PointInterest } from 'src/app/client-management/data-management';
import { Schedule, ScheduleForm } from '../schedule';
import { ScheduleService } from '../schedule.service';
import { ValidatorDto } from "../../parametrage/validator-settings";
import { Passage, PassageService } from "../../parametrage/passage";

@Component({
  selector: 'app-scheduling-form',
  templateUrl: './scheduling-form.component.html',
  styleUrls: ['./scheduling-form.component.css']
})
export class SchedulingFormComponent implements OnInit {

  scheduleList_: Schedule[] = [];
  schedule: ScheduleForm = new ScheduleForm();
  validatorId: number = -1;
  loading = false;
  poiSelectMode = true;
  selectedEmails: string[] = []; // Type explicite
  poiClient_: PointInterest[] = [];
  poiSource: number;
  isPoiClient: boolean = true;
  isAuthorSite: boolean = false;
  passage_: Passage = new Passage();

  mission: Mission = new Mission();
  selectedPoi: { id: number, name: string }[] = [];
  clientAffe: { id: number, name: string }[] = [];
  sites: { idPointInterest: number, name: string }[] = [];

  @Output()
  scheduleSaved: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  hideschedule: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set scheduleList(scheduleList: Schedule[]) {
    if (scheduleList) {
      this.scheduleList_ = [...scheduleList];
    }
  }

  validators_: ValidatorDto[] = [];

  @Input()
  set validators(validators: ValidatorDto[]) {
    if (validators) {
      this.validators_ = [...validators];
    }
  }

  pointInterests_: string[] = []; // Correction du type
  oldScheduleName: string | undefined; // Ajout du type explicite
  @Input()
  set pointInterests(pointInterests: string[]) {
    if (pointInterests) {
      this.poiSelectMode = true;
      this.pointInterests_ = [...pointInterests];
    }
  }

  @Input()
  set poiClient(poiClient: PointInterest[]) {
    if (poiClient) {
      this.poiClient_ = poiClient;
    }
  }

  @Input()
  set selectedPassage(passage: Passage) {
    this.sites = [];
    if (passage) {
      this.passage_ = { ...passage };
      for (let p of this.passage_.secondaryPoi) {
        this.sites.push({ idPointInterest: p.idPointInterest, name: p.name });
      }
    }
  }

  @Input()
  clientSource: number | undefined; // Ajout du type explicite

  @Input()
  isBreakDown: boolean = false;

  @Input()
  isBreakDownModal: boolean = false;

  vehiculesDropdownSettings = {};
  poiDropdownSettings = {};
  emailsDropdownSettings = {};

  emailsList = [
    { "email": "test1@gmail.com" },
    { "email": "test2@gmail.com" },
    { "email": "test3@gmail.com" }
  ];

  constructor(
    private scheduleService: ScheduleService,
    public toastr: ToastrService,
    public passageService: PassageService
  ) { }

  ngOnInit() {
    this.vehiculesDropdownSettings = {
      singleSelection: true,
      idField: 'deviceId',
      textField: 'matricule',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.poiDropdownSettings = {
      singleSelection: true,
      idField: 'idPointInterest',
      textField: 'name',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.emailsDropdownSettings = {
      singleSelection: false,
      idField: 'email',
      textField: 'email',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  loadAllPassage() {
    this.loading = true;
    this.passageService.findAllPassages().subscribe(passages => {
      this.passageService.passages = passages;
    }, error => {
      this.toastr.error("Erreur lors de chargements", "Passage");
      this.loading = false;
    });
  }

  choosePoi($event: any) {
    this.poiSource = 0;
    this.selectedPoi = [];
    if ($event === 'Autre..') {
      this.poiSelectMode = false;
      this.schedule.name = ''; // Utilisation d'une chaîne vide plutôt que null
    } else {
      this.poiSource = $event;
      this.selectedPoi.push({
          id: this.poiSource,
          name: ""
        },
        {
          id: this.passage_.mainPoi[0].idPointInterest,
          name: ""
        });
    }
  }

  emailsToHash() {
    let separator = ';';
    if (this.schedule.emails && this.schedule.emails.length > 0) {
      this.schedule.emails += separator;
    }
    for (let i = 0; i < this.selectedEmails.length; i++) {
      if (i == this.selectedEmails.length - 1) {
        separator = '';
      }
      this.schedule.emails += this.selectedEmails[i] + separator;
    }
  }

  onItemSelect(item: any) {
    this.schedule.deviceId = item.deviceId;
    this.schedule.matricule = item.matricule;
    if (this.isBreakDown) {
      if (this.isBreakDownModal) {
        if (this.scheduleList_.find(s => s.deviceId === this.schedule.deviceId && s.type === 'BreakDown' && s.active === true)) {
          this.schedule.active = true;
        } else {
          this.schedule.active = false;
        }
      } else {
        if (this.scheduleList_.find(s => s.deviceId === this.schedule.deviceId && s.type === 'Available' && s.active === true)) {
          this.schedule.active = true;
        } else {
          this.schedule.active = false;
        }
      }
    }
    const foundSchedule = this.scheduleList_.find(s => s.deviceId === item.deviceId);
    if (foundSchedule) {
      this.mission.vehicule = foundSchedule.vehiculeId;
    }
  }

  onPoiSelect(poi: any) {
    if (!this.isAuthorSite) {
      this.schedule.name = poi.name;
      this.oldScheduleName = poi.name;
      this.selectedPoi = [];
      this.clientAffe = [];
      this.clientAffe.push({
        id: poi.idPointInterest,
        name: poi.name
      });
      this.selectedPoi.push(
        {
          id: this.passage_.mainPoi[0].idPointInterest,
          name: this.passage_.mainPoi[0].name
        },
        {
          id: poi.idPointInterest,
          name: poi.name
        },
        {
          id: this.passage_.mainPoi[0].idPointInterest,
          name: this.passage_.mainPoi[0].name
        }
      );
    }
  }

  onPoiDeSelect(poi: any) {
    if (!this.isAuthorSite) {
      this.schedule.name = ''; // Utilisation d'une chaîne vide plutôt que null
      this.selectedPoi = [];
      this.clientAffe = [];
    }
  }

  onAutherPoiSelect(poi: any) {
    this.selectedPoi = [];
    this.schedule.name = poi.name;
    this.selectedPoi.push({
      id: this.passage_.mainPoi[0].idPointInterest,
      name: this.passage_.mainPoi[0].name
    }, {
      id: this.clientAffe[0].id,
      name: this.clientAffe[0].name
    }, {
      id: poi.idPointInterest,
      name: poi.name
    });
  }

  onAutherPoiDeSelect(poi: any) {
    this.schedule.name = ''; // Utilisation d'une chaîne vide plutôt que null
    this.selectedPoi = [];
  }

  clientToggler(event: Event) { // Spécifier le type pour event
    this.isPoiClient = !this.isPoiClient;
    this.selectedPoi = [];
    this.schedule.name = ''; // Utilisation d'une chaîne vide plutôt que null
  }

  siteToggler(event: Event) { // Spécifier le type pour event
    if (!this.isAuthorSite) {
      this.oldScheduleName = this.schedule.name;
      // this.schedule.name = '';
    } else {
      this.schedule.name = this.oldScheduleName || ''; // Utiliser une chaîne vide par défaut
    }
    this.isAuthorSite = !this.isAuthorSite;
  }

  // onSubmit(f: NgForm) {
  //   if (this.schedule.name === undefined || this.schedule.name === '') {
  //     this.toastr.error('Le nom est requis.', 'Erreur');
  //     return;
  //   }
  //
  //   if (this.schedule.deviceId === undefined || this.schedule.deviceId === '') {
  //     this.toastr.error('Le véhicule est requis.', 'Erreur');
  //     return;
  //   }
  //
  //   if (!this.schedule.active) {
  //     this.toastr.error('L\'activité est requise.', 'Erreur');
  //     return;
  //   }
  //
  //   this.scheduleService.saveSchedule(this.schedule).subscribe(response => {
  //     this.toastr.success('Le planning a été sauvegardé avec succès.', 'Succès');
  //     this.scheduleSaved.emit();
  //   }, error => {
  //     this.toastr.error('Erreur lors de la sauvegarde du planning.', 'Erreur');
  //   });
  // }

  cancel() {
    this.hideschedule.emit();
  }
}
