<div class="row">
    <div class="col-md-4">
        <select class="form-control input-sm select3" [(ngModel)]='selectedMonth' (ngModelChange)="onMonthChange()"
            name='selectedMonth' required>
            <option [ngValue]="null" style="font-weight: bold;">Choisir le mois</option>
            <option style="font-size: 14px;" *ngFor="let month of months" [value]="month.id">
                {{month.name}}</option>
        </select>
    </div>
    <div class="col-md-4">
        <select class="form-control input-sm select3" [(ngModel)]="isByMultipleGroups" (ngModelChange)="onChangeByMultipleGroups()"
            style="margin-bottom: 20px">
            <option [ngValue]=false>
                Tous les Groupes
             </option>
            <option [ngValue]=true>
               Groupe de Véhicules
            </option>
        </select>
    </div>
</div>
<div *ngIf="isByMultipleGroups" class="row">
    <div class="col-md-12">
        <form #groupForm="ngForm" class="form-horizontal">
            <div class="col-lg-10 col-md-10">
                <label class="col-lg-4 col-md-4 control-label">Groupes</label>
                <div class="col-lg-8 form-group">
                    <!-- <ng-multiselect-dropdown-angular7 name="groupSelected" [data]="groups"
                        placeholder="aucun groupe selectionné" [(ngModel)]="groupIds" [settings]="groupDropdownSettings"
                        required>
                    </ng-multiselect-dropdown-angular7> -->
                    <ng-multiselect-dropdown
                    [placeholder]="'personnalisé'"
                    [settings]="dropdownSettings"
                    [data]="groups"
                    [(ngModel)]="groupIds"
                    (onSelect)="onItemSelect($event)"
                  >
                  </ng-multiselect-dropdown>

                </div>

            </div>

            <div class="col-lg-2 orm-group" *ngIf="!displayCalendar">
                <button type="button" (click)="onValidate()" class="btn btn-block btn-success btn-flat">
                    <i class="fa fa-search pull-left" aria-hidden="true"></i>
                    Valider
                </button>
            </div>
        </form>
    </div>
</div>

<div class="row" *ngIf="displayCalendar">
    <div class="col-md-12">
        <form #dashbordForm="ngForm" class="form-horizontal">
            <div class="col-lg-5 form-group">
                <label class="col-lg-4 col-md-4 control-label">Du</label>
                <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                        [(ngModel)]="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" placement="bottom"
                        required (ngModelChange)="onChangeStartDate()">
                </div>
            </div>
            <div class="col-lg-5 orm-group">
                <label class="col-lg-4 col-md-4 control-label">Au</label>
                <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [minDate]="startDate" placement="bottom"
                        required>
                </div>
            </div>
            <div class="col-lg-2 orm-group">
                <button type="button" (click)="loadMonthlyFuelCostByGroup()" class="btn btn-block btn-success btn-flat">
                    <i class="fa fa-search pull-left" aria-hidden="true"></i>
                    Valider
                </button>
            </div>
        </form>
    </div>
</div>
<ngx-loading [show]="globalLoading"></ngx-loading>
<div id="fuelConsumption" style="zoom: 0.6500;" class="w-100 d-inline-block"></div>
