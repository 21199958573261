import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrakeComponent } from './brake.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
// import { BsModalModule } from 'ngx-bootstrap/modal'; // Import BsModalModule for modal dialogs
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { DataManagementService } from '../../../data-management/data-management.service'; // Import DataManagementService
import { BrakeService } from '../../providers/brake-service'; // Import BrakeService
import { ImportExportService } from '../../../import-export'; // Import ImportExportService
import { ModalModule } from 'ngx-bootstrap/modal';
import { ImportExportFormsModule } from '../../import-export-forms/import-export-forms.module';
import { BrakeFormComponent } from './brake-form/brake-form.component';
import { BrakeFormModule } from './brake-form/brake-form.module';

@NgModule({
  declarations: [
    BrakeComponent,

  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(), // Import ModalModule for Bootstrap modals
    ImportExportFormsModule,
    BrakeFormModule
  ],
  providers: [
    DataManagementService, // Provide DataManagementService
    BrakeService, // Provide BrakeService
    ImportExportService // Provide ImportExportService
  ],
  exports: [
    BrakeComponent // Export BrakeComponent for use in other modules
  ]
})
export class BrakeModule { }
