import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GroupDto } from 'src/app/client-management/data-management';
import {GroupedExpenses, ParentData} from './interfaces';
import {
  DashBordExpensesInfoDTO,
  // ParentData,
} from 'src/app/client-management/parc-management';
import { DateInterval } from 'src/app/shared/model';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}


@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.css'],
})
export class ChargeComponent implements OnInit {
  @ViewChild('pieCAModal', { static: false }) pieCAModal!: ModalDirective;
  options: Object | null = null;
  optionsModal: Object | null = null;
  date: DateInterval = new DateInterval();
  endDate: Date = new Date();
  startDate: Date = new Date();
  globalLoading: boolean = false;

  Years: { id: number; name: string; }[];
  displayCalendar: boolean = false;
  IsByParent = false;
  isByMultipleGroups: boolean = false;
  groupIds: GroupDto[] = [];
  depensedata_: ParentData = {};
  groupeStatistics: any[] = [];

  @Input()
  groups: GroupDto[] = [];

  @Input() show = false;

  @Input()
  set depensedata(depensedata: ParentData) {
    this.depensedata_ = Object.assign(depensedata);
    if (this.depensedata_) {
    }
  }

  @Input()
  set groupeStatisticsResult(groupeStatistics: any[]) {
    this.groupeStatistics = Object.assign(groupeStatistics);
    if (this.groupeStatistics) {
    }

    this.getDepenseInfo();
  }

  groupDropdownSettings = {};

  constructor(
    public toastr: ToastrService,
  ) {
    this.Years = this.getYears();
  }

  ngOnInit() {}



  showModal = false;

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  getYears(): { id: number; name: string; }[] {
    return [
      { id: 0, name: 'Année En cours' },
      { id: 1, name: 'Année Antérieure ' },
      { id: 2, name: 'Année -2' },
      { id: 3, name: 'Année -3' },
      { id: 4, name: 'Année -4' },
      { id: 5, name: 'Date Personnaliser' },
    ];
  }

  getDepenseInfo() {
    this.globalLoading = true;
    if (this.IsByParent) this.depenseParParentChart();
    else this.depenseChart();
    // this.depenseChartTwo();
    this.globalLoading = false;
  }

  formatNumberToK(value: number): string {
    const suffixes = ['', 'k', 'M', 'B', 'T']; // You can extend this array for larger numbers if needed
    let suffixIndex = 0;

    while (value >= 1000 && suffixIndex < suffixes.length - 1) {
      value /= 1000;
      suffixIndex++;
    }

    return value.toFixed(3).replace(/\.?0+$/, '') + ' ' + suffixes[suffixIndex];
  }

  depenseParParentChart() {
    this.setHighCartsLang();
    /** Create Pie Chart( depense chart ) **/

  }

  depenseChart() {
    this.setHighCartsLang();

    const series: Highcharts.SeriesOptionsType[] = [];
    const seriesData: Highcharts.PointOptionsObject[] = [];

    let total: number = 0;

    // Calculer le total des dépenses
    for (const group of this.groupeStatistics) {
      total += group.totalDriverCA;
    }

    // Préparer les données pour le graphique
    this.groupeStatistics.forEach((group) => {
      const dptotal: number = group.totalDriverCA;
      seriesData.push({
        name: group.nom,
        y: (dptotal / total) * 100,
        drilldown: group.idGroupe,
        x: dptotal,
      });
    });

    // Créer la série
    series.push({
      type: 'pie', // Type 'pie' pour le graphique en secteurs
      name: 'Groupe',
      tooltip: {
        headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}</b><br/>',
      },
      data: seriesData,
    });

    // Configuration des options du graphique
    this.options = {
      chart: {
        type: 'pie', // Utilisez 'pie' pour le type de graphique
        height: 320,
      },
      title: {
        text: 'Répartition des dépenses par groupe',
        style: {
          fontWeight: 'bold',
        },
        align: 'center',
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}: {point.x:,.2f} DH</span><br>' +
          '<span style="color:{point.color}"> {point.y:.2f}%</span>',
      },
      series: series,
      drilldown: {
        series: [], // Ajouter des données de drilldown si nécessaire
      },
    };

    // Initialiser le graphique
    Highcharts.chart('pieCA', this.options);
  }

  calculateDepense(charge: DashBordExpensesInfoDTO) {
    let total = 0;
    total =
      charge.insurance +
      charge.visit +
      charge.roadTaxes +
      charge.lavageAndParking +
      charge.tool +
      charge.fuel +
      charge.leasing +
      charge.driverCharge;

    if (charge.maintenance && Array.isArray(charge.maintenance)) {
      charge.maintenance.forEach((m) => {
        total += m.maintenance;
      });
    }
    return total;
  }

  createChartDashboard(dashboardData: DashBordExpensesInfoDTO[]) {
    this.setHighCartsLang();

    // Initialize an object to store the grouped expenses
    const groupedExpenses: GroupedExpenses = {};
    let Alltotal = 0;
    let AllCA = 0;

    // Iterate through the data
    dashboardData.forEach((entry) => {
      // Calculate Depenses
      const depense = this.calculateDepense(entry);

      Alltotal += depense;
      AllCA += entry.turnover;

      entry.transportType = entry.transportType.trim().toLocaleLowerCase();
      entry.nom = entry.nom.trim().toLocaleLowerCase();
      entry.mark = entry.mark.trim().toLocaleLowerCase();
      entry.matricule = entry.matricule.trim().toLocaleLowerCase();

      // First level: Group by transportType
      if (!groupedExpenses[entry.transportType]) {
        groupedExpenses[entry.transportType] = {
          total: 0,
          ca: 0,
          children: {}, // Initialize with an empty children object
        };
      }

      // Second level: Group by nom
      if (!groupedExpenses[entry.transportType].children[entry.nom]) {
        groupedExpenses[entry.transportType].children[entry.nom] = {
          total: 0,
          ca: 0,
          children: {}, // Initialize with an empty children object
        };
      }

      // Third level: Group by mark
      if (!groupedExpenses[entry.transportType].children[entry.nom].children[entry.mark]) {
        groupedExpenses[entry.transportType].children[entry.nom].children[entry.mark] = {
          total: 0,
          ca: 0,
          children: {}, // Initialize with an empty children object
        };
      }

      // Fourth level: Group by vehicule
      if (!groupedExpenses[entry.transportType].children[entry.nom].children[entry.mark].children[entry.matricule]) {
        groupedExpenses[entry.transportType].children[entry.nom].children[entry.mark].children[entry.matricule] = {
          total: 0,
          ca: 0,
          children: {}, // Initialize with an empty children object
        };
      }

      // Accumulate expenses at each level
      groupedExpenses[entry.transportType].total += depense;
      groupedExpenses[entry.transportType].children[entry.nom].total += depense;
      groupedExpenses[entry.transportType].children[entry.nom].children[entry.mark].total += depense;
      groupedExpenses[entry.transportType].children[entry.nom].children[entry.mark].children[entry.matricule].total += depense;

      // Accumulate expenses at each level
      groupedExpenses[entry.transportType].ca += entry.turnover;
      groupedExpenses[entry.transportType].children[entry.nom].ca += entry.turnover;
      groupedExpenses[entry.transportType].children[entry.nom].children[entry.mark].ca += entry.turnover;
      groupedExpenses[entry.transportType].children[entry.nom].children[entry.mark].children[entry.matricule].ca += entry.turnover;
    });

    // Rest of your method remains the same...
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7',
        '#e77e22',
        '#3ea6dc',
        '#47a4d2',
        '#51a2c7',
        '#5a9fbd',
        '#639db3',
        '#6d9ba8',
        '#76999e',
        '#7f9694',
        '#899489',
        '#92927f',
        '#9c9074',
        '#a58d6a',
        '#ae8b60',
        '#b88955',
        '#c1874b',
        '#ca8441',
        '#d48236',
        '#dd802c',
        '#e77e22',
      ],
      lang: {
        printChart: 'Imprimer',
        decimalPoint: ',',
        thousandsSep: ' ',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        viewFullscreen: 'Voir en plein écran',
        noData: "Aucune donnée n'est disponible",
      },
    });
  }
}
