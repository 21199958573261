import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TechnicalDataService } from '../../statistical/technical-data/technical-data.service';
import {
  TechnicalData,
  DeviceOpt,
  DeviceCarb,
} from '../../statistical/technical-data/technical-data';
import { Subscription } from 'rxjs';
import { DataManagementService } from './../../data-management/data-management.service';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { Group, Vehicule } from '../../data-management';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { time_difference } from 'src/app/global.config';

const Highcharts = require('highcharts/highcharts.src');
interface CustomTooltipContext {
  x: number;
  y: number;
  series: {
    name: string;
  };
}



function isCustomTooltipContext(context: any): context is CustomTooltipContext {
  return context && typeof context.x === 'number' && typeof context.y === 'number' && context.series;
}

@Component({
  selector: 'app-quality-driving',
  templateUrl: './quality-driving.component.html',
  styleUrls: ['./quality-driving.component.css'],
})
export class QualityDrivingComponent implements OnInit, OnDestroy {

  //Chart vars
  chart: any;
  options: Object;
  serieName: any;

  //Device Options
  deviceopt: DeviceOpt;

  //Device Carburant
  devicecarb: DeviceCarb;

  groups: Group[];
  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();
  AllGroups: Subscription;
  vehicule: Vehicule;

  technicaldata: TechnicalData[];

  startDate: Date = new Date();
  endDate: Date = new Date();

  //MultiSelelct vars
  selectItems :any[] = [];
  dropdownSettings = {};
  dropdownList : any[] = [];
  isResultLoaded: boolean = true;

  // customOption:CustomOption=new CustomOption();

  isGroupLoaded = false;
  constructor(
    private technicaldataservice: TechnicalDataService,
    public dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private signinService: SigninService,
    private localeService: BsLocaleService
  ) {
    Highcharts.setOptions({
      global: {
        useUTC: false,
      },
    });
    this.endDate.setHours(23, 59, 59, 0);
    // this.startDate.setTime(this.startDate.getTime()  * 86400000);
    this.startDate.setHours(0, 0, 0, 0);
  }

  ngOnInit() {
    this.localeService.use('fr');
    //init multiselect
    this.createDropList();
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select option',
      selectAllText: 'Select Tous',
      unSelectAllText: 'UnSelect Tous',
      enableSearchFilter: false,
      classes: 'custom-class-multiselect',
      badgeShowLimit: 1,
    };

    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        (group) => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectItems = [];
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
      this.displayChart();
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails('')
        .subscribe((groups) => {
          this.groups = groups.filter((group) => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          //this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            this.selectItems = [];
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule =
                this.selectedVehicule;
            }
          }
          this.displayChart();
        });
    }
  }

  ngOnDestroy() {
    if (this.AllGroups) this.AllGroups.unsubscribe();
  }
  createDropList() {
    this.dropdownList.push({ id: 0, option: 'speed', itemName: 'Vitesse' });
    this.dropdownList.push({ id: 1, option: 'x', itemName: 'Accélération' });
    this.dropdownList.push({ id: 4, option: 'x', itemName: 'Freinage' });
    this.dropdownList.push({ id: 2, option: 'y', itemName: 'Dérive' });
    this.dropdownList.push({ id: 3, option: 'z', itemName: 'Secousse' });
  }

  onItemSelect(item: any) { }

  displayChart() {
    //Get Selected Options
    let devopt: any[] = [];
    if (this.dropdownList.length > 0) {
      this.dropdownList.forEach((itm) => {
        devopt.push(itm.option);
      });
    }

    // Options vars
    let overSpeed: { x: number; y: number; }[] = [];
    let drift: { x: number; y: number; }[] = [];
    let shake: { x: number; y: number; }[] = [];
    let acceleration: { x: number; y: number; }[] = [];
    let braking: { x: number; y: number; }[] = [];
    let maxSpeed: { x: number; y: number; }[] = [];

    //Select data Device carburant

    this.technicaldataservice
      .getDeviceCarb(this.selectedVehicule.idDevice)
      .subscribe((res) => {
        this.devicecarb = res;
      });
    //Get Fuel Data and fill all options
    this.technicaldataservice
      .getFuelDetail(
        this.selectedVehicule.idDevice,
        {
          startDate: this.startDate,
          endDate: this.endDate,
        },
        devopt
      )
      .subscribe((res) => {
        this.technicaldata = res;
        if (this.technicaldata.length == 0) {
          this.toastr.warning('Pas de données à afficher');
        }
        this.technicaldata.forEach((stat) => {
          stat.date = stat.date + time_difference;
          if (stat.speed != undefined) {
            overSpeed.push({
              x: stat.date,
              y: stat.speed,
            });
            maxSpeed.push({
              x: stat.date,
              y: this.selectedVehicule.maxSpeed,
            });
          }
          if (stat.x != undefined) {
            if (stat.x > 0)
              acceleration.push({
                x: stat.date,
                y: (Math.abs(stat.x) / 128) * 2,
              });
            if (stat.x < 0)
              braking.push({ x: stat.date, y: (Math.abs(stat.x) / 128) * 2 });
          }
          if (stat.y != undefined) {
            drift.push({ x: stat.date, y: (Math.abs(stat.y) / 128) * 2 });
          }
          if (stat.z != undefined) {
            shake.push({ x: stat.date, y: (Math.abs(stat.z) / 128) * 2 });
          }
        });

        //Charts vars
        let serie: any[] = [];
        let yaxis: any[] = [];

        //Fill all series and yxais
        this.dropdownList.forEach((itm) => {
          if (itm.itemName == 'Accélération') {
            if (this.ChartSeries(acceleration) == true) {
              yaxis.push({
                title: {
                  text: 'Accélération',
                  style: {
                    color: 'rgb(255, 127, 0)',
                  },
                },
                labels: {
                  format: '{value}' + 'G',
                  style: {
                    color: 'rgb(255, 127, 0)',
                  },
                },
                min: 0,
                max: 2,
              });
              serie.push({
                name: 'Accélération',
                data: acceleration,
                color: 'rgb(255, 127, 0)',
                yAxis: this.PositionYaxis(yaxis, 'Accélération'),
                tooltip: {},
              });
            }
          }
          if (itm.itemName == 'Freinage') {
            if (this.ChartSeries(braking) == true) {
              yaxis.push({
                title: {
                  text: 'Freinage',
                  style: {
                    color: 'rgb(153, 51, 0)',
                  },
                },
                labels: {
                  format: '{value}' + 'G',
                  style: {
                    color: 'rgb(153, 51, 0)',
                  },
                },
                min: 0,
                max: 2,
              });
              serie.push({
                name: 'Freinage',
                data: braking,
                color: 'rgb(153, 51, 0)',
                yAxis: this.PositionYaxis(yaxis, 'Freinage'),
                tooltip: {
                  valueSuffix: '',
                  valueDecimals: 2,
                },
              });
            }
          }
        });

        if (this.ChartSeries(overSpeed) == true) {
          yaxis.push({
            title: {
              text: 'Vitesse',
              style: {
                color: 'rgb(44, 161, 33)',
              },
            },
            labels: {
              format: '{value}' + 'KM/H',
              style: {
                color: 'rgb(44, 161, 33)',
              },
            },
            min: 0,
            max: 100,
            opposite: yaxis.length !== 0,
          });
          serie.push({
            name: 'Vitesse',
            data: overSpeed,
            color: 'rgb(44, 161, 33)',
            tooltip: {
              valueSuffix: ' KM/H',
            },
          });

          serie.push({
            name: 'Vitesse Max',
            data: maxSpeed,
            color: 'rgb(230, 20, 9)',
            tooltip: {
              headerFormat: null,
              valueSuffix: 'KM/H',
            },
          });
        }
        if (this.ChartSeries(drift) == true) {
          yaxis.push({
            title: {
              text: 'Dérive',
              style: {
                color: 'rgb(23, 119, 182)',
              },
            },
            labels: {
              format: '{value} ',
              style: {
                color: 'rgb(23, 119, 182)',
              },
            },
            min: 0,
            max: 2,
          });
          serie.push({
            name: 'Dérive',
            data: drift,
            // visible: false,
            color: 'rgb(23, 119, 182)',
            yAxis: this.PositionYaxis(yaxis, 'Dérive'),
            tooltip: {
              valueSuffix: '',
              valueDecimals: 2,
            },
          });
        }
        if (this.ChartSeries(shake) == true) {
          yaxis.push({
            title: {
              text: 'Secousse',
              style: {
                color: 'rgb(107, 57, 156)',
              },
            },
            labels: {
              format: '{value} ',
              style: {
                color: 'rgb(107, 57, 156)',
              },
            },
            min: 0,
            max: 2,
          });
          serie.push({
            name: 'Secousse',
            data: shake,
            color: 'rgb(107, 57, 156)',
            yAxis: this.PositionYaxis(yaxis, 'Secousse'),
            tooltip: {
              valueSuffix: '',
              valueDecimals: 2,
            },
          });
        }

        //Create a new HighChart

        this.options = new Highcharts.Chart({
          time: {
            timezoneOffset: time_difference,
          },
          title: {
            text:
              'Qualité de conduite (Véhicule: ' +
              this.selectedVehicule.matricule +
              ' )',
          },
          chart: {
            type: 'spline',
            renderTo: 'drivingQuality',
            height: 420,
            zoomType: 'x',
          },
          xAxis: [
            {
              type: 'datetime',
              tickInterval: 1800 * 1000,
              labels: {
   
              },
              crosshair: true,
            },
          ],

          yAxis: yaxis,

          plotOptions: {
            series: {
              turboThreshold: 10000,
            },
          },

          series: serie,
        });
      });
  }

  //Return the position of Yaxis
  PositionYaxis(data: any, option: string) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.text === option) {
        return i;
      }
    }
  }

  //check if the series is empty or not
  ChartSeries(data: any = []) {
    let ok: boolean;
    data.forEach((dt: { y: any; }) => {
      ok = dt.y == undefined ? false : true;
    });
    return ok;
  }

  saveInstance(chartInstance: any) {
    this.chart = chartInstance;
  }

  onSeriesMouseOver(e: any) {
    this.serieName = e.context.name;
  }

  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      this.selectItems = [];
      this.chooseVehicule(this.selectedVehicule);
    }
  }

  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
    this.selectItems = [];
  }
}
