import { Component, Input, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DataManagementService, Group, PointInterest, VehiculesDto } from '../../data-management';
import { affecteTo } from '../affecte-items';
import { AlertManagementNewService } from '../alert-management-new.service';
import { Mesure, Operator, UserAlert, UserAlertConfiguration, UserAlertTemplate } from '../alert-new';

interface ErrorResponse {
  errorMessage: string;
  // Ajoutez d'autres propriétés si nécessaire
}


@Component({
  selector: 'app-alert-customize',
  templateUrl: './alert-customize.component.html',
  styleUrls: ['./alert-customize.component.css'],
})
export class AlertCustomizeComponent implements OnInit {

  @Input() public mode: string;
  public indexpoi: number | null = null;
  public indexzone: number | null = null;
  // id pour supprimer template
  public idDeleteTemp: number | null;
  public templateName: any;
  public showTempl = false;
  public selectedMesure: any;

  public mesuresobjs: Mesure[] = [];
  public operators: Operator[] = [];
  // public operatorsinout = [
  // ];
  public selectedMesures: Mesure[] = [];

  public enableGlobalConfigToAllVehicules = false;
  public enableGlobalConfig = false;
  public affectedTo = affecteTo;
  public Selectedoption = 'ALL_VEHICULES';

  @Input()
  set sendDataEdit(s: UserAlert) {
    this.alert = s;
    let index = 0;
    for (const c of this.alert.alertConfigurations) {
      this.selectedMesures.push(c.mesure);
      if (c.mesure.name == 'POI') {
        this.indexpoi = index;
      }
      if (c.mesure.name == 'ZONE') {
        this.indexzone = index;
      }
      index++;
    }

  }

  public confimrDelete = false;
  // group
  public groupsItems: { text: string | number; id: number }[] = [];
  public selectedGroupsHash = '';
  public selectedGroupsItems: any[] = [];
  //
  //  public operatorSpeed:any;
  public show = true;
  public vehiculesDropdownSettings = {};
  public zonesDropdownSettings = {};
  public groupsDropdownSettings = {};
  public mesuresDropdownSettings = {};
  public texthead = 'Ajouter Alerte Personnalise';
  public textButton = 'Import Template';
  public nameTemplates: string[] = [];
  // Objet
  public alert: UserAlert = new UserAlert();
  public alertConfg: UserAlertConfiguration = new UserAlertConfiguration();

  // Vehicules
  public vehicules: VehiculesDto[] = [];
  public multipleSelect = true;
  public vehiculesItems: { text: string | number; id: number }[] = [];
  public vehiculesSelected: any = [];
  // selectedVehiculesHash: string = "";
  public groups: Group[];
  public error: { errorMessage?: string } | null = null;
  // Zone
  public zonesSelected: PointInterest[] = [];
  public zones: PointInterest[] = [];
  public selectedItem = '';

  // POI

  public pois: PointInterest[] = [];
  public poisSelected: PointInterest[] = [];
  public selectedPoiItem = '';

  // NO_POI
  public stopTimeSelectMode = true;
  public minutes: string[] = [];
  @Input() public modalRef: BsModalRef;
  constructor(private toastr: ToastrService,
              private dataManagementService: DataManagementService,
              private AlertService: AlertManagementNewService,
  ) {
    this.initMinutes();

  }

  public ngOnInit() {

    this.loadPOI();

    this.dataManagementService.getAllGroupsDetails('').subscribe((res) => {
      this.groups = res.filter((group) => group.vehicules.length > 0);
      this.mapGroupsToItems(this.groups);
      if (this.groups) {
        this.getVehicules();
      }
    });

    // getOperators
    this.AlertService.getAllOperators().subscribe((res) => {

      this.operators = res;

    });
    // getNameOfTemplates
    this.AlertService.getAllNameTemp().subscribe((res) => {
      for (const t of res) {
        this.nameTemplates.push(t);
      }
    });
    // getAllMesures a partir de la
    this.AlertService.getAllMesures().subscribe((res) => {
      this.mesuresobjs = res;

    });

    this.vehiculesDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 22,
      allowSearchFilter: true,
    };
    this.zonesDropdownSettings = {
      singleSelection: false,
      idField: 'idPointInterest',
      textField: 'name',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 22,
      allowSearchFilter: true,
    };
    this.mesuresDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 22,
      allowSearchFilter: true,
    };

    // getGroups

    this.groupsDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 22,
      allowSearchFilter: true,
    };

  }

  public initMinutes() {
    for (let i = 1; i <= 12; i++) {
      this.minutes.push((i * 10).toString());
    }
  }
  // POI

  public onItemDeSelectPOI($event: any, index: any) {
    this.selectedPoiItem = '';
    for (const poi of this.poisSelected) {
      if (this.selectedPoiItem != '') {
        this.selectedPoiItem = this.selectedPoiItem + ';' + poi.idPointInterest;
      } else {
        this.selectedPoiItem = poi.idPointInterest.toString();
      }
    }
    this.alert.alertConfigurations[index].value1 = this.selectedPoiItem;

  }

  public selectedPoi($event: any, index: any) {
    this.selectedPoiItem = '';

    for (const poi of this.poisSelected) {
      if (this.selectedPoiItem != '') {
        this.selectedPoiItem = this.selectedPoiItem + ';' + poi.idPointInterest;
      } else {
        this.selectedPoiItem = poi.idPointInterest.toString();
      }
    }
    this.alert.alertConfigurations[index].value1 = this.selectedPoiItem;
  }

  public loadPOI() {
    if (this.dataManagementService.pointInterests) {
      this.pois = this.dataManagementService.getMarkers();
      this.zones = this.dataManagementService.getPolygons();

      if (this.mode === 'UPDATE') {
        if (this.indexpoi !== null && this.indexpoi >= 0) {
          this.poisSelected = this.selectedItemfromPoiId(this.alert.alertConfigurations[this.indexpoi].value1);
        }

        if (this.indexzone !== null && this.indexzone >= 0) {
          this.zonesSelected = this.selectedItemfromZoneId(this.alert.alertConfigurations[this.indexzone].value1);
        }
      }
    } else {
      this.dataManagementService.getAllPointInterests().subscribe((pointInterests) => {
        this.dataManagementService.pointInterests = pointInterests;
        this.pois = this.dataManagementService.getMarkers();
        this.zones = this.dataManagementService.getPolygons();

        if (this.mode === 'UPDATE') {
          if (this.indexpoi !== null && this.indexpoi >= 0) {
            this.poisSelected = this.selectedItemfromPoiId(this.alert.alertConfigurations[this.indexpoi].value1);
          }

          if (this.indexzone !== null && this.indexzone >= 0) {
            this.zonesSelected = this.selectedItemfromZoneId(this.alert.alertConfigurations[this.indexzone].value1);
          }
        }
      });
    }
  }

  // groupq
  public mapGroupsToItems(groups: Group[]) {
    this.groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      let parentName: string;
      if (groups[i].nameGroupParent) {
        parentName = groups[i].nameGroupParent + ' / ' + groups[i].nom;
      } else { parentName = groups[i].nom; }
      this.groupsItems.push({
        id: groups[i].idGroupe,
        text: parentName,
      });
    }

  }
  public onChangeGroups() {
    this.alert.ids = [];
  }

  // Vehicules
  public vehiculesToItems(vehicules: VehiculesDto[]) {
    this.vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesItems.push({

        id: vehicules[i].idVehicule,
        text: vehicules[i].matricule,
      });
    }
  }

  public checkIfVehiculeExists(vehicule: VehiculesDto) {
    const v = this.vehicules.filter((ve) => ve.idVehicule === vehicule.idVehicule);
    if (v.length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  // onChangeVehicules() {
  //   this.alert.ids = [];
  // }
  // selectedVehicules(vehicules: { id: number; text: string }[]) {
  //   let prefix = "";
  //   this.selectedVehiculesHash = "";
  //   vehicules.forEach(vehicule => {
  //     this.selectedVehiculesHash += prefix + vehicule.id;
  //     prefix = ",";
  //   });
  //   this.alert.ids = this.selectedVehiculesHash.split(',').map(x => +x);
  // }

  public getVehicules() {
    this.groups.forEach((group) => {
      group.vehicules.forEach((vehicule) => {
        const v = new VehiculesDto();
        v.matricule = vehicule.matricule;
        v.idVehicule = vehicule.idVehicule;
        if (this.checkIfVehiculeExists(v)) {
          this.vehicules.push(v);
        }
      });
    });

    this.vehiculesToItems(this.vehicules);
  }

  // Zones methodes

  public onSelectedZone($event: any, index: any) {

    this.selectedItem = '';
    for (const zone of this.zonesSelected) {
      if (this.selectedItem != '') {
        this.selectedItem = this.selectedItem + ';' + zone.idPointInterest;
      } else {
        this.selectedItem = zone.idPointInterest.toString();
      }
    }
    this.alert.alertConfigurations[index].value1 = this.selectedItem;
  }

  public onItemDeSelectZone($event: any, index: any) {
    this.selectedItem = '';
    for (const zone of this.zonesSelected) {
      if (this.selectedItem != '') {
        this.selectedItem = this.selectedItem + ';' + zone.idPointInterest;
      } else {
        this.selectedItem = zone.idPointInterest.toString();
      }
    }
    this.alert.alertConfigurations[index].value1 = this.selectedItem;

  }
  public onEmailChange($event: any) {
    this.alert.emails = $event.emails;
    this.alert.emailMode = $event.emailMode;
  }

  // onChange(value) {
  //   this.selectedMesure = value;
  // }

  public save() {
    if (this.Selectedoption === 'BY_GROUPS') {
      this.alert.ids = this.selectedGroupsItems.map((g: { id: number }) => g.id);
    }
    if (this.Selectedoption === 'BY_VEHICULE') {
      this.alert.ids = this.vehiculesSelected.map((a: { id: number }) => a.id);
    }
    this.AlertService.saveUserAlert(this.alert).subscribe(() => {
      this.toastr.success('Alert bien ajoutée !');
    });
  }

  public onItemDeSelect(item: any) {
    this.alertConfg = new UserAlertConfiguration();
    this.alertConfg.mesure = Object.assign(item, this.alertConfg.mesure);
    this.alert.alertConfigurations = this.alert.alertConfigurations.filter((a) => a.mesure.id != this.alertConfg.mesure.id);
  }

  public onItemSelect(item: any) {
    this.alertConfg = new UserAlertConfiguration();
    this.alertConfg.mesure = Object.assign(item, this.alertConfg.mesure);
    this.alert.alertConfigurations.push(this.alertConfg);
  }

  public onSelectAll(items: any) {

  }

  public onSelectAffecte() {
    switch (this.Selectedoption) {
      case 'ALL_VEHICULES':
        this.alert.byAllVehicules = true;
        this.alert.byGroups = false;
        this.alert.byVehicules = false;
        break;
      case 'BY_GROUPS':
        this.alert.byAllVehicules = false;
        this.alert.byGroups = true;
        this.alert.byVehicules = false;
        break;
      case 'BY_VEHICULE':
        this.alert.byAllVehicules = false;
        this.alert.byGroups = false;
        this.alert.byVehicules = true;
        break;
      default:
        this.alert.byAllVehicules = true;
    }
  }

  public onShow() {
    this.show = !this.show;
    if (!this.show) {
      this.texthead = 'Alerte Template';
    }
    else {
      this.texthead = 'Ajouter Alerte Personnalise';
    }
    this.showTempl = !this.showTempl;

    if (!this.showTempl) {
      this.textButton = 'Import Template';
    }
    else {
      this.textButton = 'Alerte Personnalise';
    }

  }

  public saveEtQueTemplate() {
    const alerttemplate = new UserAlertTemplate();
    Object.assign(alerttemplate, this.alert);
    alerttemplate.alertConfigurationsTemplate = this.alert.alertConfigurations;

    this.AlertService.addUserAlertTemplate(alerttemplate).subscribe(
      (temp) => {
        // this.modalRef.hide(); // Décommentez si nécessaire
        this.toastr.success('Alert template ajoutée avec succès');
      },
      (error) => {
        this.error = (error?.error as ErrorResponse) || { errorMessage: 'SERVER ERROR' };

        // Si errorMessage n'est pas défini, assignez une valeur par défaut
        if (!('errorMessage' in this.error)) {
          this.error.errorMessage = 'SERVER ERROR';
        }
      },

    );
  }

  public selectedItemfromZoneId(value1: any) {
    const zoneId = ('' + value1).split(';');
    const pois: PointInterest[] = [];
    let poi: PointInterest[] = [];
    for (const id of zoneId) {
      poi = this.zones.filter((p) => p.idPointInterest === +id);
      if (poi.length > 0) {
        pois.push(poi[0]);
      }
    }
    return pois;
  }

  public selectedItemfromPoiId(value1: any) {
    const poiId = ('' + value1).split(';');
    const pois: PointInterest[] = [];
    let poi: PointInterest[] = [];
    for (const id of poiId) {
      poi = this.pois.filter((p) => p.idPointInterest === +id);
      if (poi.length > 0) {
        pois.push(poi[0]);
      }
    }
    return pois;
  }

  public selectNameTemp(val: any) {
    this.alert = new UserAlert();
    this.AlertService.findUserAlertTemByName(this.templateName).subscribe((res) => {
      this.alert.name = res.name;
      this.alert.emails = res.emails;
      this.alert.active = res.active;
      this.alert.alertConfigurations = res.alertConfigurationsTemplate;
      const tabMesuresSelected: Mesure[] = [];
      for (const r of this.alert.alertConfigurations) {
        tabMesuresSelected.push(r.mesure);

        if (r.mesure.name == 'POI') {
          this.poisSelected = this.selectedItemfromPoiId(r.value1);
        }
        if (r.mesure.name == 'ZONE') {
          this.zonesSelected = this.selectedItemfromZoneId(r.value1);
        }

        //
        this.idDeleteTemp = res.id;
        //
      }
      this.selectedMesures = tabMesuresSelected;

    });
  }

  public EditAlert() {
    this.AlertService.updateUserAlert(this.alert).subscribe((e) => {
      if (e) {
        this.modalRef.hide();
        this.toastr.success('Alert bien Modifie !');
      }
    });

  }

  public ChangeMode() {
    this.mode = 'DELETE';
    this.showTempl = true;
  }

  public confirmDeleteTemp() {
    this.confimrDelete = true;
  }

  public deleteTemplate() {
    if (this.idDeleteTemp !== null) {
      this.AlertService.deleteUserAlertTemplate(this.idDeleteTemp).subscribe((a) => {
        if (a) {
          this.modalRef.hide();
          this.toastr.success('Votre suppression est bien effectuée!', 'info');
        } else {
          this.toastr.error('L\'opération de suppression est annulée!', 'Error');
        }
      });
    } else {
      this.toastr.error('Impossible de supprimer : identifiant invalide', 'Error');
    }
  }

}
