import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { ValidatorDto } from './validators';

@Injectable()
export class ValidatorSettingsService {

  validators: ValidatorDto[] = [];
  
  constructor(private _http: HttpClient) { }

  // list !
  findAllValidators(): Observable<ValidatorDto[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<ValidatorDto[]>(dns + 'validators', { headers: headers });
  }

  addValidator(validator: ValidatorDto): Observable<ValidatorDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<ValidatorDto>(dns + 'validators', validator, options);
  }

  updateValidator(validator: ValidatorDto): Observable<ValidatorDto> {
    let headers = createAuthorizationHeader();
    return this._http.put<ValidatorDto>(dns + 'validators', validator, { headers: headers });
  }

  deleteValidator(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'validators/' + id, { headers: headers });
  }

  // valid !
  getOneValidator(id: number): Observable<ValidatorDto> {
    let headers = createAuthorizationHeader();
    return this._http.get<ValidatorDto>(dns + 'validators/' + id, { headers: headers });
  }
}
