<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-md-6">
        <p (click)="openModal()">...</p>
      </div>
      <div class="col-md-6">
        <select
          class="form-control input-sm select3"
          [(ngModel)]="selectedMonth"
          (ngModelChange)="onMonthChange()"
          name="selectedMonth"
          required
          style="height: 25px"
        >
          <option [ngValue]="null" style="font-weight: bold">
            Choisir le mois
          </option>
          <option
            style="font-size: 14px"
            *ngFor="let month of months"
            [value]="month.id"
          >
            {{ month.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div id="history" style="zoom: 0.6"></div>
  </div>
</div>

<!-- Bootstrap Modal -->
<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showModal }"
  data-backdrop="static"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="historyModal" style="width: 100%; height: 100%"></div>
      </div>
    </div>
  </div>
</div>
