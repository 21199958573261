import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartDisponibleFlotteComponent } from './chart-disponible-flotte.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [ChartDisponibleFlotteComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot() // Importing ModalModule if you are using modals in the component
  ],
  exports: [ChartDisponibleFlotteComponent] // Exporting the component for use in other modules
})
export class ChartDisponibleFlotteModule {}
