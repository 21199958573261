import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportExportComponent } from './import-export.component'; // Adjust the path as necessary
import { RouterModule } from '@angular/router'; // Import RouterModule if routing is used
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import Datepicker if you're using it
import { ReportSentComponent } from './report-sent/report-sent.component';
import { ReportSentModule } from './report-sent/report-sent.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    ImportExportComponent,

  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    RouterModule, // Import RouterModule for routing functionalities, if needed
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ModalModule.forRoot(),
    ReportSentModule,
    FormsModule,
    ReportSentModule,
    SharedPipesModule,
    NgSelectModule,
    TruncateModule
  ],
  exports: [
    ImportExportComponent // Export the component if it needs to be used in other modules
  ]
})
export class ImportExportModule { }
