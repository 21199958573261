import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CorrelationMaintenanceComponent } from './correlation-maintenance.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {ToastrService } from 'ngx-toastr';
import { HighchartsChartModule } from 'highcharts-angular';  // Assuming you're using angular-highcharts for Highcharts integration

@NgModule({
  declarations: [
    CorrelationMaintenanceComponent  // Declare the component
  ],
  imports: [
    CommonModule,        // For Angular common directives
    FormsModule,         // For form handling in the component template
    BsDatepickerModule.forRoot(),  // Import ngx-bootstrap datepicker for date inputs
    // NgxToastrModule,     // Toastr for showing notifications
    HighchartsChartModule  // Module for Highcharts integration
  ],
  providers: [
    // BsLocaleService,  // Provide the locale service for ngx-bootstrap
    ToastrService     // Provide Toastr for notifications
  ],
  exports: [
    CorrelationMaintenanceComponent  // Export component for reuse
  ]
})
export class CorrelationMaintenanceModule { }
