import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ImportExportService } from '../import-export.service';
import { ReportAuto } from '../../data-management';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-report-sent',
  templateUrl: './report-sent.component.html',
  styleUrls: ['./report-sent.component.css']
})
export class ReportSentComponent implements AfterViewInit {

  // local vars
  loading: boolean;

  @Input()
  reportAutos: ReportAuto[];

  currentReportAuto: ReportAuto = new ReportAuto();

  @Input()
  mode = 'SHOW';

  @ViewChild('deleteModal' , { static: false })
  deleteModal: ModalDirective;

  @ViewChild('updateModal', { static: false })
  updateModal: ModalDirective;

  constructor(public toastr: ToastrService, private importExportService: ImportExportService) { }

  ngAfterViewInit() {
  }


  onDelete() {
    this.loading = true;
    this.importExportService.delete(this.currentReportAuto.id).subscribe(deleted => {
      this.loading = false;
      this.deleteModal.hide();
      if(deleted){
      this.reportAutos = this.reportAutos.filter(report => report.id !== this.currentReportAuto.id);
      this.toastr.success('Rapport automatique bien supprimer !', 'info');
      }else
      this.toastr.error("Erreur lors de suppression", "Rapport automatique");

    }, () => {
      this.loading = false;
      this.deleteModal.hide();
    })
  }

  onUpdate(){
    this.loading = true;
    this.importExportService.update(this.currentReportAuto).subscribe(currentReportAuto => {
      this.currentReportAuto = currentReportAuto;
      for (let i = 0; i < this.reportAutos.length; i++) {
        if (this.reportAutos[i].id == currentReportAuto.id) {
            this.reportAutos[i] = currentReportAuto;
            break;
        }
    }
      this.loading = false;
      this.updateModal.hide();
      this.toastr.success('email bien modifié');

    },
    err =>{
      this.loading = false;
      this.toastr.error("Erreur lors de modification", "Email");

    }, () => {
      this.loading = false;
      this.updateModal.hide();
    })
  }
}
