import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { GroupDto } from 'src/app/client-management/data-management';
import { FuelingDashboardDto } from 'src/app/client-management/parc-management';
import { DateInterval } from 'src/app/shared/model';
import { AverageCostOfFuelConsumptionChartService } from './average-cost-of-fuel-consumption-chart.service';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}
@Component({
  selector: 'app-average-cost-of-fuel-consumption-chart',
  templateUrl: './average-cost-of-fuel-consumption-chart.component.html',
  styleUrls: ['./average-cost-of-fuel-consumption-chart.component.css']
})
export class AverageCostOfFuelConsumptionChartComponent implements OnInit {

  globalLoading: boolean;
  fuelConsumptionChart: Object | null = null;
  fuelingDashboard: FuelingDashboardDto[] = [];
  fuelingDashboardOldList: FuelingDashboardDto[] = [];

  selectedMonth: number;
  selectedGroup: number = -1;
  isByMultipleGroups: boolean = false;
  groupIds: GroupDto[] = [];

  @Input()
  groups: GroupDto[] = [];

  dropdownSettings :any;

  startDate: Date = new Date();
  endDate: Date = new Date();
  date: DateInterval = new DateInterval();

  months = [{ id: 1, name: 'Janvier' }, { id: 2, name: 'Février' }, { id: 3, name: 'Mars' }, { id: 4, name: 'Avril' }, { id: 5, name: 'Mai' }, { id: 6, name: 'Juin' },
  { id: 7, name: 'Juillet' }, { id: 8, name: 'Août' }, { id: 9, name: 'Septembre' }, { id: 10, name: 'Octobre' }, { id: 11, name: 'Novembre' }, { id: 12, name: 'Décembre' },
  { id: -1, name: 'Date Personnaliser' }];

  displayCalendar: boolean = false;

  constructor(private averageCostOfFuelConsumptionChartService: AverageCostOfFuelConsumptionChartService) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.selectedMonth = new Date().getMonth() + 1;
    this.endDate = new Date(new Date().getFullYear(), this.selectedMonth, 0, 23, 59, 59, 59);
    this.startDate = new Date(new Date().getFullYear(), this.selectedMonth - 1, 1, 0, 0, 0, 0);
    this.loadMonthlyFuelCostByGroup();
  }


  loadMonthlyFuelCostByGroup() {
    this.globalLoading = true;
    this.date.startDate = this.startDate.getTime();
    this.date.endDate = this.endDate.getTime();
    this.averageCostOfFuelConsumptionChartService.getMonthlyFuelCostByGroup(this.date, this.selectedGroup).subscribe(res => {

      this.fuelingDashboardOldList = res;
      if(this.isByMultipleGroups)
      this.fuelingDashboard = this.fuelingDashboardOldList.filter(m => this.groupIds.map(g => g.idGroupe).includes(m.groupeId));
      else
      this.fuelingDashboard = res;
      this.MonthlyConsumptionChart();
      this.globalLoading = false;
    })
  }

  onMonthChange() {
    if (this.selectedMonth != null) {
      if (this.selectedMonth > 0) {
        this.displayCalendar = false;
        this.endDate = new Date(new Date().getFullYear(), this.selectedMonth, 0, 23, 59, 59, 59);
        this.startDate = new Date(new Date().getFullYear(), this.selectedMonth - 1, 1, 0, 0, 0, 0);
        this.loadMonthlyFuelCostByGroup();
      } else {
        this.displayCalendar = true;
      }
    }
  }

   /**
  * Action change date début
  */
    onChangeStartDate() {
      if (this.endDate < this.startDate) {
        this.endDate = null;
      }
    }

  onValidate() {
    this.fuelingDashboard = this.fuelingDashboardOldList.filter(m => this.groupIds.map(g => g.idGroupe).includes(m.groupeId));
    this.MonthlyConsumptionChart();
  }

  onChangeByMultipleGroups() {
    this.groupIds = [];
    if (!this.isByMultipleGroups) {
      this.fuelingDashboard = this.fuelingDashboardOldList;
      this.MonthlyConsumptionChart();
    }
  }

  MonthlyConsumptionChart() {

    var that = this;
    that.setHighCartsLang();

    /** SERIES **/
    var data: { name: string, y: number, i: number, n: number, j: number, drilldown: string }[] = [];
    var drilldata: any[] = [];

    var totalRealCost = this.fuelingDashboard.reduce((sum, current) => sum + current.cost, 0);
    var totalTheoreticalCost = this.fuelingDashboard.reduce((sum, current) => sum + current.theorecticalCost, 0);


    var groupsName = new Set(this.fuelingDashboard.map(s => { return s.groupName }));

    groupsName.forEach(groupName => {

      var costByGroup = this.fuelingDashboard.filter(s => s.groupName == groupName);

      var cost = costByGroup.reduce((sum, current) => sum + current.cost, 0);
      var theorecticalCost = costByGroup.reduce((sum, current) => sum + current.theorecticalCost, 0)

      if (theorecticalCost > 0) {


        /** SERIES **/
        data.push({
          name: groupName,
          y: (cost / theorecticalCost) * 100,
          i: cost,
          n: theorecticalCost,
          j: ((cost / theorecticalCost) * 100) - 100,
          drilldown: groupName
        });

        /** DATA **/
        var drilldownData: (string | number)[][] = [];
        var costByVehicules = new Set(costByGroup.map(p => { return p.matricule }));
        costByVehicules.forEach(matricule => {
          var vehiculeData = costByGroup.find(p => p.matricule == matricule);

          if (vehiculeData.theorecticalCost > 0) {
            drilldownData.push(["Véhicule : " + matricule, (vehiculeData.cost / vehiculeData.theorecticalCost) * 100])
          }

        })

        drilldata.push({
          name: groupName,
          id: groupName,
          data: drilldownData
        });
      }
    })

    this.fuelConsumptionChart = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Ecart du coût moyen du gasoil par groupe/véhicule',
        style: {
          fontWeight: 'bold'
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: "#000000",
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: 'Ecart ( % )',
        }, labels: {
          // formatter: function () {
          //   return this.value + '%';
          // }
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: '%',
      },
      plotOptions: {
        series: {
          colorByPoint: true,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f} ',
            softThreshold: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: "Groupe",
        dataLabels: {
          enabled: false,
          format: '{point.y} '
        }, tooltip: {
          pointFormat: '<span style="color:{point.color}">Ecart Coûts Moyen </span> <b>{point.y:.2f}%</b> <br/>'
            + '<span style="color:{point.color}">Ecart moyen Consom. :<b>{point.j:.2f}%</b></span> <br/>'
            + '<span style="color:{point.color}">Total coût réel :<b>{point.i:,.2f} DH</b></span> <br />'
            + '<span style="color:{point.color}">Total coût Théorique :<b>{point.n:,.2f} DH</b></span>'
        },
        data: data,
      }, {
        type: 'pie',
        name: 'Total',
        zones: [{
          value: 1.01,
          color: '#D65B4A'
        }, {
          color: '#66b92e'
        }],
        tooltip: {
          pointFormat: '<span style="color:{point.color}">Ecart Coûts Moyen  : </span><b>{point.x:.2f}%</b> <br/>'
            + '<span style="color:{point.color}">Ecart moyen Consom. :<b>{point.j:.2f}%</b></span> <br/>'
            + '<span style="color:{point.color}">Total coût réel : </span><b>{point.i:,.2f} DH</b> <br />'
            + '<span style="color:{point.color}">Total coût Théorique : </span><b>{point.n:,.2f} DH</b>'
        },
        data: [{
          name: 'Tout le parc',
          y: 100,
          i: totalRealCost,
          n: totalTheoreticalCost,
          x: (totalRealCost / totalTheoreticalCost - 1) * 100,
          j: (totalRealCost / totalTheoreticalCost - 1) * 100 - 100
        }],
        center: [100, 80],
        size: 70,
        showInLegend: false,
        dataLabels: {
          enabled: false
        }
      }],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold'
        },
        series: drilldata
      }
    }
    Highcharts.chart('fuelConsumption', this.fuelConsumptionChart);
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts.setOptions({
      colors: ['#FF9655', '#000000', '#6AF9C4', '#f39c12', '#007bff', '#66B92E', '#DA932C', '#D65B4A', '#24CBE5', '#64E572', '#adcbe3', '#f37736', '#854442', '#3d1e6d', '#c68642', '#D81B60', '#605ca8', '#3D9970', '#001F3F', '#00c0ef', ' #451e3e'],
      lang: {
        printChart: 'Imprimer',
        decimalPoint: ',',
        thousandsSep: ' ',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }, noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      }
    });
  }


}
