import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarburantComponent } from './carburant.component'; // Adjust the path as necessary
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modal dialogs
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule for handling forms
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import datepicker module
import { DataManagementService } from '../../data-management/data-management.service'; // Import services used
import { FuelingService } from '../providers/fueling-service';
import { TechnicalDataService } from 'src/app/client-management/statistical/technical-data/technical-data.service';
import { ImportExportService } from '../../import-export'; // Import ImportExportService
import { CarburantDashBordComponent } from './carburant-dashbord/carburant-dashbord.component';
import { CarburantDashbordModule } from './carburant-dashbord/carburant-dashbord.module';
import { CarburantFormModule } from './carburant-form/carburant-form.module';
import { ImportExportFormsModule } from '../import-export-forms/import-export-forms.module';

@NgModule({
  declarations: [
    CarburantComponent,
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ModalModule.forRoot(),
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(), // Import datepicker module for date handling
    FormsModule,
    CarburantDashbordModule,
    ImportExportFormsModule,
    CarburantFormModule,
  ],
  providers: [
    DataManagementService, // Provide DataManagementService
    FuelingService, // Provide FuelingService
    TechnicalDataService, // Provide TechnicalDataService
    ImportExportService // Provide ImportExportService
  ],
  exports: [
    CarburantComponent // Export CarburantComponent for use in other modules
  ]
})
export class CarburantModule { }
