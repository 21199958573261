import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanalInfoPfbDto } from 'src/app/client-management/canal-performance';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';

@Injectable({
  providedIn: 'root',
})
export class CanalPfbService {
  canals: CanalInfoPfbDto[] = [];

  constructor(private _http: HttpClient) {}

  // list !
  findAllCanalPfb(): Observable<CanalInfoPfbDto[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<CanalInfoPfbDto[]>(dns + 'canals/List', {
      headers: headers,
    });
  }
}
