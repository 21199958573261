import { Component, OnInit } from "@angular/core";
import { DataManagementService } from "../data-management/data-management.service";
import { ToastrService } from "ngx-toastr";
import { Group, Vehicule } from "../data-management/data-management.model";
//import { AlertManagementServiceUtils } from "./alert-Management-utils.service";
import { AlertManagementService } from "./alert-management.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: "app-alert-management",
  templateUrl: "./alert-management.component.html",
  styleUrls: ["./alert-management.component.css"]
})
export class AlertManagementComponent implements OnInit {
  loader: boolean = false;

  groups: Group[] = [];
  selectedGroup: Group = new Group();
  public selectedVehicule: Vehicule = new Vehicule();

  selectedType: string;
  filteredVehicules: Vehicule[] = [];
  types: string[] = [];

  isByType: string;

  constructor(
    private dataManagementService: DataManagementService,
    public toastr: ToastrService,
    public alertManagementService: AlertManagementService
  ) { }

  ngOnInit() {
    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );

      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }

      this.selectedVehicule = this.dataManagementService.selectedVehicule || null;
      this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);

      // Préciser le type lors de la réduction
      this.types = ['all', ...Array.from(new Set(this.groups.reduce<string[]>((acc, group) => {
        return acc.concat(group.vehicules.map(vehicule => vehicule.subMark));
      }, [])))];
    } else {
      this.loader = true;
      this.dataManagementService.getAllGroupsDetails("").subscribe(
        groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;

            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
            }
          }

          this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
          this.loader = false;

          // Préciser le type lors de la réduction
          this.types = ['all', ...Array.from(new Set(this.groups.reduce<string[]>((acc, group) => {
            return acc.concat(group.vehicules.map(vehicule => vehicule.subMark));
          }, [])))];
        },
        () => {
          this.loader = false;
        }
      );
    }

    this.selectedType = 'all';
    this.chooseType('all');
  }


  chooseType(type: string) {
    this.selectedType = type;
    this.filteredVehicules = this.selectedGroup.vehicules.filter(
      vehicule => vehicule.subMark === type
    );
    this.isByType = this.selectedType;

    if (this.filteredVehicules.length > 0) {
      this.selectedVehicule = this.filteredVehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
    }


  }


  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      //emit change of vehicules
      this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
    }
  }

  chooseVehicule(vehicule: any) {
    this.alertManagementService.isByGroup = false;
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
    //emit change of vehicules
    this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
  }

  onApplyGlobalAlert() {
    const selectedGroup = this.dataManagementService.selectedGroup;

    if (selectedGroup?.enbaleGlobalAlert) {
      let ok = confirm(
        "Est-vous sûr d'appliquer le présent paramétrage sur le groupe : " +
        selectedGroup.nom
      );
      if (!ok) {
        selectedGroup.enbaleGlobalAlert = false;
      }
    } else {
      let ok = confirm(
        "Est-vous sûr de désactiver l'alerte sur le groupe : " +
        selectedGroup?.nom // Utiliser l'opérateur optionnel ici aussi
      );
    }
  }


  onApplyGlobalAlertOnAllVehicles(event: any) {
    if (event.checked) {
      let ok = confirm(
        "Est-vous sure d'appliquer le present paramétrage sur tous les véhicules"
      );
    } else {
      let ok = confirm(
        "Est-vous sure de desactiver l'alerte sur tous les véhicules "
      );
    }
    this.alertManagementService.isByAllVehicules = event.checked;
  }

  onApplyGlobalAlertOnAllVehiclesByType(event: any) {
    if (event.checked) {
      let ok = confirm(
        "Est-vous sure d'appliquer le present paramétrage sur Type"
      );
    } else {
      let ok = confirm(
        "Est-vous sure de desactiver l'alerte sur Type "
      );
    }
    this.alertManagementService.isByType = event.checked;
  }


  isTypeAvailable(type: string, selectedGroupId: number): boolean {
    if (type === 'all') {
      return true;
    }

    return this.groups.some(group => group.idGroupe === selectedGroupId && group.vehicules.some(vehicule => vehicule.subMark === type));
  }

  chooseTypeSub(type: string) {
    this.selectedType = type;
    this.dataManagementService.selectedSubmark = type;
    if (type === 'all') {
      this.filteredVehicules = this.selectedGroup.vehicules;
    } else {
      this.filteredVehicules = this.selectedGroup.vehicules.filter(
        vehicule => vehicule.subMark === type
      );
    }
    if (this.filteredVehicules.length > 0) {
      this.selectedVehicule = this.filteredVehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      this.dataManagementService.sendVehiculeWasSelected(this.selectedVehicule);
    }
  }



  filterVehiclesByGroupAndType(): any[] {
    if (this.selectedGroup && this.selectedType) {
      if (this.selectedType === 'all') {
        return this.selectedGroup.vehicules;
      } else {
        return this.selectedGroup.vehicules.filter(vehicule => vehicule.subMark === this.selectedType);
      }
    }

    return [];
  }


  // onToggleChange() {
  //   if (this.isByType) {
  //     this.selectedType = this.types.length > 0 ? this.types[0] : null;
  //   } else {
  //     this.selectedType = null;
  //   }
  // }

  // onToggleChange(event: MatSlideToggleChange) {
  //   // Check if the toggle is checked or not
  //   if (event.checked) {
  //     // The toggle is checked, send the selected type
  //     // Call a method or perform actions based on the selected type
  //   } else {
  //     // The toggle is unchecked, perform actions if needed
  //   }
  // }

  // onToggleChange(event: MatSlideToggleChange) {
  //   if (event.checked) {
  //
  //     // Update the selectedType property in AlertManagementService
  //     this.alertManagementService.selectedType = this.selectedType;
  //
  //     // Send the selected type to the server
  //     this.alertManagementService.sendSelectedType(this.selectedType).subscribe(
  //       response => {
  //         // Handle the response from the server if needed
  //       },
  //       error => {
  //         console.error("Error updating selected type:", error);
  //
  //       }
  //     );
  //   } else {
  //
  //   }
  // }



}
