<div *ngIf="!show">
    <!-- ==================== En Déplacement Section ======================= -->
    <div class="col-xl-3 col-md-3 mb-4 col-xs-6">
        <div class="card greenStyle">
            <div class="title">En Déplacement</div>
            <span class="glyphicon glyphicon-play"></span>
            <div class="value">

                <span *ngIf="!loadingState && currentStartState"> {{currentStartStateLength}}</span>
                <span *ngIf="!loadingState && !currentStartState"> 0</span>
                <i *ngIf="loadingState" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>

            </div>

            <div class="stat" *ngIf="!loadingState && currentStartState && currentStartStateLength > 0 && !showDetails1;"
                 (click)="showDetails1 = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> détails
            </div>

            <div class="stat" *ngIf="!loadingState && (!currentStartState || currentStartStateLength == 0)">
                <b>Aucun </b> véhicule
            </div>

            <div class="stat overflowdv" *ngIf="showDetails1" (click)="showDetails1 = false">
                <div *ngFor="let startState of currentStartState">
                    <b style="color: #000;"><i class="fa fa-object-group icons"></i> {{startState.name}}</b>
                    <span>
                    {{realtimeRestService.getVehiculesAlias(startState.values)}}
                </span>
                </div>
                <i class="fa fa-caret-up" aria-hidden="true"></i>
            </div>

        </div>
    </div>

    <!-- ==================== En Arrêt Section ======================= -->
    <div class="col-xl-3 col-md-3 mb-4 col-xs-6">
        <div class="card orangeStyle">
            <div class="title">En Arrêt</div>
            <span class="glyphicon glyphicon-pause"></span>
            <div class="value">
                <span *ngIf="!loadingState && currentStopState"> {{currentStopStateLength}}</span>
                <span *ngIf="!loadingState && !currentStopState"> 0</span>
                <i *ngIf="loadingState" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>

            </div>

            <div class="stat" *ngIf="!loadingState && currentStopState && currentStopStateLength > 0 && !showDetails2;"
                 (click)="showDetails2 = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
            </div>
            <div class="stat" *ngIf="!loadingState && (!currentStopState || currentStopStateLength == 0)"><b>Aucun </b>
                véhicule
            </div>
            <div class="stat overflowdv" *ngIf="showDetails2" (click)="showDetails2 = false">
                <div *ngFor="let stopState of currentStopState">
                    <b style="color: #000;"><i class="fa fa-object-group icons"></i> {{stopState.name}}</b>
                    <span>
                    {{realtimeRestService.getVehiculesAlias(stopState.values)}}
                </span>
                </div>

                <i class="fa fa-caret-up" aria-hidden="true"></i>

            </div>
        </div>
    </div>
    <!-- ==================== En dysfonctionnement Section ======================= -->
    <div class="col-xl-3 col-md-3 mb-4 col-xs-6">
        <div class="card redStyle">
            <div class="title">En dysfonctionnement</div>
            <span class="glyphicon glyphicon-remove"></span>
            <div class="value">
                <span *ngIf="!loadingState && currentTechnicalIssue"> {{currentTechnicalIssueLength}}</span>
                <span *ngIf="!loadingState && !currentTechnicalIssue"> 0</span>
                <i *ngIf="loadingState" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>

            </div>

            <div class="stat"
                 *ngIf="!loadingState && currentTechnicalIssue && currentTechnicalIssueLength > 0 && !showDetails3;"
                 (click)="showDetails3 = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
            </div>
            <div class="stat" *ngIf="!loadingState && (!currentTechnicalIssue || currentTechnicalIssueLength == 0)">
                <b>Aucun </b>
                véhicule
            </div>
            <div class="stat overflowdv" *ngIf="showDetails3" (click)="showDetails3 = false">
                <div *ngFor="let technicalIssue of currentTechnicalIssue">
                    <b style="color: #000;"><i class="fa fa-object-group icons"></i> {{technicalIssue.name}}</b>
                    <span>
                    {{realtimeRestService.getVehiculesAlias(technicalIssue.values)}}
                </span>
                </div>

                <i class="fa fa-caret-up" aria-hidden="true"></i>

            </div>
        </div>
    </div>

    <!-- ==================== En TDB Section ======================= -->
    <div class="col-xl-3 col-md-3 mb-4 col-xs-6">
        <div class="card blueStyle">
            <div class="title">depenses du mois (DH)</div>

            <span class="glyphicon glyphicon-stats"></span>

            <div class="value">
                <span *ngIf="!loadingCostDetails && costDetails !== null">{{costDetails.total |number:'.2-3'}}</span>
                <span *ngIf="!loadingCostDetails && !costDetails"> 0</span>
                <i *ngIf="loadingCostDetails" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
            </div>

            <div class="stat"
                 *ngIf="!loadingCostDetails && costDetails !== null && costDetails.total !== 0 && !showDetails4;"
                 (click)="displayDashboard()"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
            </div>

            <div class="stat" *ngIf="!loadingCostDetails && costDetails !== null && costDetails.total == 0">
                <b>
                    Aucune dépense pour ce mois
                </b>
            </div>

        </div>
    </div>
</div>

<div class="cardContainer" *ngIf="show">

    <!-- ==================== Driving Quality ======================= -->
    <div class="qualityDriven" style="margin-right: -45px;">
        <div class="row">
            <div class="col-md-6" style="height: 57px;">
                <div class="stati-dq stati bg-nephritis"  (click)="showGraph()">
                    <i class=""></i>
                    <div>
                        <!-- <b style="font-size: 1.5em;">{{ vitesseAlert?.countAddedInfo || '00' }}</b> -->
                        <b style="font-size: 18px">{{ notificationsVitesse.length || '00' }}</b>
                        <span class="dq-text">Vitesse</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6" style="margin-left: -40px;">
                <div class="stati-dq stati bg-peter_river">
                    <i class=""></i>
                    <div>
                        <b style="font-size: 18px">{{ deriveeAlert?.countAddedInfo || '00' }}</b>
                        <span class="dq-text">Dérive</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" style="height: 57px;">
                <div class="stati-dq stati bg-orange" style="margin-top: -1px;">
                    <i class=""></i>
                    <div>
                        <b style="font-size: 18px">{{ freinageAlert?.countAddedInfo || '00' }}</b>
                        <span class="dq-text" style="top: 10px;">Freinage</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6" style="margin-left: -40px;">
                <div class="stati-dq stati bg-asbestos" style="margin-top: -1px;">
                    <i class=""></i>
                    <div>
                        <b style="font-size: 18px">{{ accAlert?.countAddedInfo || '00' }}</b>
                        <span class="dq-text" style="top: 10px; left: -74px;">Acc</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ==================== End Driving Quality ======================= -->
    <!-- ==================== En Déplacement Section ======================= -->
    <div class="stateSection">
        <div class="row">
            <div   class="col-xl-3 col-md-3 mb-4 col-xs-6 space">

                <div class="stati" style="color: #28a745;" >
                    <img class="stati-icon" src="assets/icons/shipped.svg" alt="" style="filter: invert(45%) sepia(98%) saturate(405%) hue-rotate(81deg) brightness(97%) contrast(83%);">
                    <div>
                        <b *ngIf="!loadingState && currentStartState"> {{currentStartStateLength}}</b>
                        <b *ngIf="!loadingState && !currentStartState"> 0</b>
                        <span class="stati-text" ><strong style="color: #28a745;">Deplacement</strong></span>
                    </div>
                </div>
                <div class="collapse-card">
                    <button class="drop-button collapse-button stati-button" type="button" title="plus d'info" data-toggle="collapse" data-target="#collapseInTime" aria-expanded="false" aria-controls="collapseExample" (click)="showDetails1=!showDetails1">
                        <i class="fa fa-chevron-circle-down" style="color: #28a745;" [ngStyle]="{transform:!showDetails1 ? 'rotate(0deg)':'rotate(180deg)' }"></i>
                    </button>

                    <div class="collapse collapse-content " id="collapseInTime">
                        <div class="card costum-card" style="background: #fff">
                            <div class="card-body scroll-bar">
                                <div class="stat" *ngIf="!loadingState && (!currentStartState || currentStartStateLength == 0)">
                                    <b>Aucun </b> véhicule
                                </div>
                                <div *ngFor="let startState of currentStartState">
                                    <b style="color: #28a745;">
                                        <!-- <i class="fa fa-object-group icons"></i> -->
                                        {{startState.name}}</b>
                                    <span style="color: #000;">
                            {{realtimeRestService.getVehiculesAlias(startState.values)}}
                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <!-- ==================== En Arrêt Section ======================= -->
            <div  class="col-xl-3 col-md-3 mb-4 col-xs-6 space">
                <div class="card orangeStyle" *ngIf="!show">
                    <div class="title">En Arrêt</div>
                    <span class="glyphicon glyphicon-pause"></span>
                    <div class="value">
                        <span *ngIf="!loadingState && currentStopState"> {{currentStopStateLength}}</span>
                        <span *ngIf="!loadingState && !currentStopState"> 0</span>
                        <i *ngIf="loadingState" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>

                    </div>

                    <div class="stat" *ngIf="!loadingState && currentStopState && currentStopStateLength > 0 && !showDetails2;"
                         (click)="showDetails2 = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
                    </div>
                    <div class="stat" *ngIf="!loadingState && (!currentStopState || currentStopStateLength == 0)"><b>Aucun </b>
                        véhicule
                    </div>
                    <div class="stat overflowdv" *ngIf="showDetails2" (click)="showDetails2 = false">
                        <div *ngFor="let stopState of currentStopState">
                            <b style="color: #000;"><i class="fa fa-object-group icons"></i> {{stopState.name}}</b>
                            <span>
                            {{realtimeRestService.getVehiculesAlias(stopState.values)}}
                        </span>
                        </div>

                        <i class="fa fa-caret-up" aria-hidden="true"></i>

                    </div>
                </div>
                <div *ngIf="show">
                    <div class="stati" style="color: #E67E22 ;" >
                        <img class="stati-icon" src="assets/icons/forbidden.svg" alt="" style="filter: invert(68%) sepia(48%) saturate(4793%) hue-rotate(349deg) brightness(96%) contrast(88%);">
                        <div>
                            <b *ngIf="!loadingState && currentStopState"> {{currentStopStateLength}}</b>
                            <b *ngIf="!loadingState && !currentStopState"> 0</b>
                            <span class="stati-text" ><strong style="color: #E67E22 ;">En Arrêt</strong></span>
                        </div>
                    </div>
                    <div class="collapse-card">
                        <button class="drop-button collapse-button stati-button" type="button" title="plus d'info" data-toggle="collapse" data-target="#collapseAttente" aria-expanded="false" aria-controls="collapseExample" (click)="showDetails2=!showDetails2">
                            <i class="fa fa-chevron-circle-down" style="color: #E67E22 ;" [ngStyle]="{transform:!showDetails2 ? 'rotate(0deg)':'rotate(180deg)' }"></i>
                        </button>

                        <div class="collapse collapse-content" id="collapseAttente">
                            <div class="card costum-card" style="background: #fff ;">
                                <div class="card-body scroll-bar">
                                    <div class="stat" *ngIf="!loadingState && (!currentStopState || currentStopStateLength == 0)"><b>Aucun </b>
                                        véhicule
                                    </div>
                                    <div *ngFor="let stopState of currentStopState">
                                        <b style="color: #E67E22 ;">
                                            <!-- <i class="fa fa-object-group icons"></i>  -->
                                            {{stopState.name}}</b>
                                        <span style="color: #000;">
                            {{realtimeRestService.getVehiculesAlias(stopState.values)}}
                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ==================== En dysfonctionnement Section ======================= -->
            <div  class="col-xl-3 col-md-3 mb-4 col-xs-6 space">
                <div class="card redStyle" *ngIf="!show">
                    <div class="title">En Dysfonctionnement</div>
                    <span class="glyphicon glyphicon-remove"></span>
                    <div class="value">
                        <span *ngIf="!loadingState && currentTechnicalIssue"> {{currentTechnicalIssueLength}}</span>
                        <span *ngIf="!loadingState && !currentTechnicalIssue"> 0</span>
                        <i *ngIf="loadingState" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>

                    </div>

                    <div class="stat"
                         *ngIf="!loadingState && currentTechnicalIssue && currentTechnicalIssueLength > 0 && !showDetails3;"
                         (click)="showDetails3 = true"><i class="fa fa-caret-down" aria-hidden="true"></i> <b>plus de </b> details
                    </div>
                    <div class="stat" *ngIf="!loadingState && (!currentTechnicalIssue || currentTechnicalIssueLength == 0)">
                        <b>Aucun </b>
                        véhicule
                    </div>
                    <div class="stat overflowdv" *ngIf="showDetails3" (click)="showDetails3 = false">
                        <div *ngFor="let technicalIssue of currentTechnicalIssue">
                            <b style="color: #000;"><i class="fa fa-object-group icons"></i> {{technicalIssue.name}}</b>
                            <span>
                            {{realtimeRestService.getVehiculesAlias(technicalIssue.values)}}
                        </span>
                        </div>

                        <i class="fa fa-caret-up" aria-hidden="true"></i>

                    </div>
                </div>
                <div *ngIf="show">
                    <div class="stati" style="color: #CB4335;" >
                        <img class="stati-icon" src="assets/icons/wrench.svg" alt="" style="filter: invert(35%) sepia(12%) saturate(4621%) hue-rotate(325deg) brightness(99%) contrast(101%);">
                        <div>
                            <b *ngIf="!loadingState && currentTechnicalIssue"> {{currentTechnicalIssueLength}}</b>
                            <b *ngIf="!loadingState && !currentTechnicalIssue"> 0</b>
                            <span class="stati-text" ><strong style="color: #CB4335;">Dysfonctionnement</strong></span>
                        </div>
                    </div>
                    <div class="collapse-card">
                        <button class="drop-button collapse-button stati-button" type="button" title="plus d'info" data-toggle="collapse" data-target="#collapsePanne" aria-expanded="false" aria-controls="collapseExample" (click)="showDetails3=!showDetails3">
                            <i class="fa fa-chevron-circle-down" style="color: #CB4335;" [ngStyle]="{transform:!showDetails3 ? 'rotate(0deg)':'rotate(180deg)' }"></i>
                        </button>

                        <div class="collapse collapse-content" *ngIf="showDetails3">
                            <div class="stat" *ngIf="!loadingState && (!currentTechnicalIssue || currentTechnicalIssueLength == 0)">
                                <b>Aucun </b>
                                véhicule
                            </div>
                            <div class="card costum-card" style="background: #fff;">
                                <div class="card-body scroll-bar">
                                    <div *ngFor="let technicalIssue of currentTechnicalIssue">
                                        <b style="color: #CB4335;;">
                                            <!-- <i class="fa fa-object-group icons"></i> -->
                                            {{technicalIssue.name}}
                                        </b>
                                        <span style="color: #000;">
                            {{realtimeRestService.getVehiculesAlias(technicalIssue.values)}}
                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div  class="col-xl-3 col-md-3 mb-4 col-xs-6 space">
                <div class="stati" style="color: #17a2b8;" (click)="showGraphNotif()">
                    <img class="stati-icon" src="assets/icons/back-in-time.svg" alt="" style="filter: invert(51%) sepia(26%) saturate(1505%) hue-rotate(141deg) brightness(98%) contrast(82%);">
                    <div>
                        <!-- <b *ngIf="!loadingCostDetails && costDetails !== null">00</b>
                        <b *ngIf="!loadingCostDetails && !costDetails"> 0</b> -->
                        <b style="font-size: 1.5em;">{{ notificationsPOI.length || '00' }}</b>
                        <span class="stati-text"><strong style="color: #17a2b8;">Arrêt POI</strong></span>
                    </div>
                </div>
                <div class="collapse-card">
                    <button class="drop-button collapse-button stati-button" type="button" title="plus d'info" data-toggle="collapse" data-target="#collapsePanne" aria-expanded="false" aria-controls="collapseExample" (click)="showDetails3=!showDetails3">
                        <i class="fa fa-chevron-circle-down" style="color: #17a2b8;"></i>
                    </button>
                </div>

            </div>
        </div>
    </div>

    <!--  -->



    <!-- ==================== Alert ======================= -->
    <div >
        <section style="width: 97%;">
            <div class="square_box box_three"></div>
            <div class="square_box box_four"></div>
            <div class="container mt-5">
                <div class="row" style="margin-top: 10px;">


                    <div class="col-sm-12"  style="height: 45px;">
                        <div class="alert  alert-simple alert-info alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show" style="margin-bottom: 2px;padding: 0px; height: 40px; border-radius: 7px; border-color: #e4670b;" role="alert" data-brk-library="component__alert">
                            <!-- <button type="button" class="close font__size-18" data-dismiss="alert">
                                    <span aria-hidden="true">
                                      <i class="fa fa-calendar"></i>
                                    </span>
                                    <span class="sr-only">Close</span>
                                  </button> -->
                            <div class="row">

                                <strong class="font__weight-semibold">
<!--                                    <div class="col-md-3 notif">-->
<!--                                        <input type="text" id="jours" (keyup)="joursEvent($event)"/> <small>JOURS</small>-->
<!--                                    </div>-->
                                    <div class="col-md-2 notif" >
                                        <i class="col-md-3 start-icon  fa fa-calendar" style="padding-top: 12px;padding-left: 45px;"></i>
                                    </div>
                                    <div class="col-md-2 notif">
                                        <b>{{notificationsAssuranceMoteurAfter.length}}</b> <small>Assurance</small>
                                    </div>
                                    <div class="col-md-2 notif">
                                        <b>{{notificationVidangeAfter.length}}</b> <small>Vidange</small>
                                    </div>
                                    <div class="col-md-2 notif">
                                        <b>{{notificationsHuileMoteurAfter.length}}</b> <small>Huile Moteur</small>
                                    </div>
                                    <div class="col-md-2 notif">
                                        <b>{{notificationsVisiteTechniqueAfter.length}}</b> <small>Visite Tech</small>
                                    </div>
                                    <div class="col-md-2 notif">
                                        <b>{{stateDocs["TIRE"].length}}</b> <small>Pneu</small>
                                    </div>

                                </strong>
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-12" style="height: 45px;">
                        <div class="alert  alert-simple alert-danger alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show" style="padding: 0px; height: 40px; border-radius: 7px;  " role="alert" data-brk-library="component__alert">
                            <!-- <button type="button" class="close font__size-18" data-dismiss="alert">
                                    <span aria-hidden="true">
                                      <i class="fa fa-bell "></i>
                                    </span>
                                    <span class="sr-only">Close</span>
                                  </button> -->
                            <div class="row">
                                <strong class="font__weight-semibold">
<!--                                    <div class="col-md-3 notif">-->
<!--                                        <input type="text" id="" (keyup)="kmEvent($event)"/> <small>KM</small>-->
<!--                                    </div>-->
                                    <div class="col-md-2 notif">
                                        <i class="col-md-3 start-icon fa fa-bell" style="padding-top: 12px;padding-left: 45px;"></i>
                                    </div>
                                    <div class="col-md-2 notif" data-toggle="modal" data-target="#outdated" (click)="filterData('assurance')">
                                        <b> {{notificationsAssuranceMoteurBefor.length}}</b> <small>Assurance</small>
                                    </div>
                                    <div class="col-md-2 notif" data-toggle="modal" data-target="#outdated" (click)="filterData('oil')">
                                        <b>{{notificationVidangeBefor.length}}</b> <small>Vidange</small>
                                    </div>
                                    <div class="col-md-2 notif" data-toggle="modal" data-target="#outdated" (click)="filterData('engine')">
                                        <b>{{notificationsHuileMoteurBefor.length}}</b> <small>Huile Moteur</small>
                                    </div>
                                    <div class="col-md-2 notif" data-toggle="modal" data-target="#outdated" (click)="filterData('visite')">
                                        <b>{{notificationsVisiteTechniqueBefor.length}}</b> <small>Visite Tech</small>
                                    </div>
                                    <div class="col-md-2 notif" data-toggle="modal" data-target="#outdated" (click)="filterData('pneu')">
                                        <b>{{stateDocs["TIRE_outdated"].length}}</b> <small>Pneu</small>
                                    </div>

                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
        <!-- <app-current-state-moodal [type]="type" [rows]="rows" [unite]="unite"></app-current-state-moodal> -->

</div>
<!-- End New Design -->

<!-- ========================================== ADD/Edit Modal (START) ====================================================-->
<div class="modal fade " bsModal #dashboardModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- ========================== Modal TITLE (START) ===============================-->
            <div class="modal-header">

                <button type="button" class="close" (click)="dashboardModal.hide()">&times;</button>

                <h4 class="modal-title" style="text-align:center; font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    Nombre des Excès de vitesse par vehicule
                </h4>
            </div>
            <!-- ========================== Modal TITLE (END) ===============================-->

            <div class="modal-body">
                <div style="text-align: center">
                    <div id="chartVitesse">
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

    <!-- ========================================== ADD/Edit Modal (END) ====================================================-->

    <div class="modal fade" bsModal #dashboardModalNotif="bs-modal" role="dialog">
      <div class="modal-dialog modal-lg">
          <div class="modal-content">
                <!-- ========================== Modal TITLE (START) ===============================-->
                <div class="modal-header">

                    <button type="button" class="close" (click)="dashboardModalNotif.hide()">&times;</button>

                    <h4 class="modal-title" style="text-align:center; font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                        Notification Arrêt POI
                    </h4>
                </div>
                <!-- ========================== Modal TITLE (END) ===============================-->

                <div class="modal-body">
                    <div style="text-align: center">
                        <div id="chartPoi">
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
