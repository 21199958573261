import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CanalInfoDto, CanalInfoPfbDto, CanalPfbDto, CanalService } from 'src/app/client-management/canal-performance';
import { CanalPfbService } from '../_service/canalpfb.service';

@Component({
  selector: 'app-canal-pfb',
  templateUrl: './canal-pfb.component.html',
  styleUrls: ['./canal-pfb.component.scss']
})
export class CanalPfbComponent implements OnInit {

  canalsPfp:CanalInfoPfbDto[];
  globalLoading: boolean = false; // Initialize your loading property

  // local vars
  loading: boolean;
  // globalLoading: boolean = false;
  canal: CanalInfoDto = new CanalInfoDto();
  @ViewChild('deleteModal', { static: false })
  deleteModal: ModalDirective;

  constructor(private router: Router, private route: ActivatedRoute, public toastr: ToastrService, private canalService: CanalService, private canalPfbService: CanalPfbService) {
  }


  ngOnInit() {
    this.loadCanalPfb();
  }

  goToFormPage(mode: any, id: any) {
      this.router.navigate(['form/' + id + "/" + mode], { relativeTo: this.route })
  }

  loadCanalPfb(){
    if (this.canalPfbService.canals.length <= 0) {
      this.loading = true;
      this.canalPfbService.findAllCanalPfb().subscribe(canals => {
        this.canalPfbService.canals = canals;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Canal");
      })
    }
  }

  confirmDeleteCanal(canal: CanalInfoDto) {
    this.canal = canal;
    this.deleteModal.show();
  }

  onDelete() {
    this.loading = true;
    this.canalService.deleteCanal(this.canal.idCanal).subscribe(deleted => {
      this.deleteModal.hide();
      this.loading = false;
      if (deleted) {
        this.canalPfbService.canals = this.canalPfbService.canals.filter(canal => canal.idCanal !== this.canal.idCanal);
        this.toastr.success('Canal bien supprimer !', 'info');
      } else {
        this.toastr.error("Erreur lors de suppression", "Canal");
      }
    }, err => {
      this.loading = false;
      this.toastr.error("Erreur lors de suppression", "Canal");
    }, () => {
      this.loading = false;
    })
  }

}
