import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MultiType } from '../../../model';
import { MultiTypeService } from '../../../providers';

@Component({
    selector: 'app-multi-type-settings-form',
    templateUrl: './multi-type-settings-form.component.html',
    styleUrls: ['./multi-type-settings-form.component.css']
})
export class MultiTypeSettingsFormComponent implements OnInit {

    @Input()
    multiTypeModal: ModalDirective;

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';

    @Input()
    multiType: MultiType = new MultiType();

    @Output()
    multiTypeWasAdded: EventEmitter<MultiType> = new EventEmitter<MultiType>();

    @Output()
    multiTypeWasUpdated: EventEmitter<MultiType> = new EventEmitter<MultiType>();

    loading: boolean = false;

    typeValues: Object[] = [{label: "Paiement", value: "PAYMENT"},
                {label: "Réparation", value: "REPARATION"},
                {label: "Expertise du cabinet", value: "CABINET_EXPERTISE"},
                {label: "Type d'infraction", value: "OFFENSE_TYPE"},
                {label: "Ref F.Huile", value: "OIL_CHANGE"},
                {label: "Ref F.Air", value: "REF_TYPE_AIR" },
                {label: "Ref H.Moteur", value: "REF_TYPE_ENGINE" },
                {label: "Ref F.Carburant", value: "REF_TYPE_FUEL" },
                {label: "Ref F.Séparateur", value: "REF_TYPE_SEPARATOR" }]

    constructor(private multiTypeService: MultiTypeService, public toastr: ToastrService) { }

    ngOnInit() { }

    /**
     * Ajouter nouveau type
     */
    onAdd(form: NgForm) {
        this.loading = true;
        this.multiTypeService.add(this.multiType).subscribe(multiType => {
            this.toastr.success('Type informations bien sauvegarder !', 'info');
            this.multiTypeWasAdded.emit(multiType);
            this.loading = false;
            form.reset();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * modifier type
     */
    onUpdate(form: NgForm) {
        this.loading = true;
        this.multiTypeService.update(this.multiType).subscribe(multiType => {
            this.toastr.success('Type informations bien sauvegarder !', 'info');
            this.multiTypeWasUpdated.emit(multiType);
            this.loading = false;
            form.reset();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

}


