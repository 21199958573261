import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Vehicule, VehiculeService } from 'src/app/client-management/data-management';
import { TransportTypeDto, TransportTypeInfoDto } from '../transport-types';
import { forkJoin } from 'rxjs';
import { TransportTypeService } from '../transport-type.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-transport-type-form',
  templateUrl: './transport-type-form.component.html',
  styleUrls: ['./transport-type-form.component.css']
})
export class TransportTypeFormComponent implements OnInit {
  mode: "ADD" | "UPDATE";
  loading: boolean = false;
  currentTransportType: TransportTypeInfoDto = new TransportTypeInfoDto();

  vehiculesItems: { text: string | number; id: number }[] = [];
  vehicules: Vehicule[] = [];
  error: { errorMessage: string } = { errorMessage: '' };
  vehiculesDropdownSettings = {};
  groupDropdownSettings:any;
  constructor(private route: ActivatedRoute, public toastr: ToastrService,
    private router: Router, private vehiculeService: VehiculeService, private transportTypeService: TransportTypeService) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
      this.init(params.id);
    });

    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idVehicule',
      textField: 'matricule',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
  }

  init(typeId: any) {


    if (this.mode == "ADD") {
      this.loading = true;
      this.vehiculeService.getAll().subscribe(vehicules => {
        this.vehicules = vehicules;
        this.loading = false;
      },
        () => {
          this.loading = false;
        }
      );
    }

    if (this.mode == "UPDATE") {
      this.loading = true;
      forkJoin(
        [this.vehiculeService.getAll(),
        this.transportTypeService.getOneTransportType(typeId)]
      ).subscribe(
        ([vehicules, transportType]) => {
          this.currentTransportType = transportType;
          this.vehicules = vehicules;
          this.loading = false;
        },
        error => {
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }

          this.loading = false;
        }
      );
    }
  }

  cancel() {
      this.router.navigate(["/client/parametrage/chargesPfbtransportType"]);
  }


  addTransportType() {

    this.loading = true;
    let transportType: TransportTypeDto = new TransportTypeDto();
    Object.assign(transportType, this.currentTransportType);
    transportType.vehicules = this.currentTransportType.vehicules.map(v => v.idVehicule);
    this.transportTypeService
      .addTransportType(transportType)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        transportType => {
          this.loading = false;
          this.transportTypeService.transportTypes.push(transportType);
          this.toastr.success("Type de transport bien ajouter !");
          this.cancel();
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

  updateTransportType() {
    this.loading = true;
    let transportType: TransportTypeDto = new TransportTypeDto();
    Object.assign(transportType, this.currentTransportType);
    transportType.vehicules = this.currentTransportType.vehicules.map(v => v.idVehicule);
    this.transportTypeService
      .updateTransportType(transportType)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        transportType => {
          this.loading = false;
          for (let i = 0; i < this.transportTypeService.transportTypes.length; i++) {
            if (transportType.idTransportType === this.transportTypeService.transportTypes[i].idTransportType) {
              this.transportTypeService.transportTypes[i] = transportType;
              break;
            }
          }
          this.cancel();
          this.toastr.success("Type de transport bien modifié !");
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

}
