import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticalComponent } from './statistical.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using forms
import { ToastrModule } from 'ngx-toastr'; // For notifications (if needed)
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker functionality (if needed)
import { NgxLoadingModule } from 'ngx-loading';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    StatisticalComponent // Declare the StatisticalComponent
  ],
  imports: [
    CommonModule, // Common module for common directives
    FormsModule, // Import FormsModule if you're using ngModel
    ToastrModule.forRoot(), // Import Toastr for notifications
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    RouterModule
  ],
  providers: [
    // Add any services if needed
  ],
  exports: [
    StatisticalComponent // Export the StatisticalComponent for use in other modules
  ]
})
export class StatisticalModule { }
