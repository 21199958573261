import {Component, Input, OnInit} from '@angular/core';
import {SigninService} from '../../../authentification/signin/signin.service';
import {imagesDir} from '../../../global.config';
import {DataManagementService} from '../../data-management/data-management.service';
import {ToastrService} from 'ngx-toastr';
// import { MatDialogRef, MatDialog } from '@angular/material';
import {UserMission} from 'src/app/authentification/signin/credentials';
import {MissionService} from '../../data-management';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-driver-mission-form',
  templateUrl: './driver-mission-form.component.html',
  styleUrls: ['./driver-mission-form.component.css']
})
export class DriverMissionFormComponent implements OnInit {

  @Input() user: UserMission = new UserMission();

  @Input() mode = 'ADD';

  @Input() isCollabsed = false;

  showPW = false;
  public id: number;
  public currentUser: UserMission | null = null;

  public profileAvatar: string = imagesDir + '/profil.png';
  public settingsAvatar: string = imagesDir + '/settings.png';

  vehiculesItems: any[] = [];
  missionItems: any[] = [];

  loading = false;
  globalLoading = false;

  vehiculesDropdownSettings = {};
  missionsDropdownSettings = {};



  constructor(
    private dialog: MatDialog,
    private signinService: SigninService,
    private dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private missionService: MissionService
  ) {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.signinService.getUserMissionWasUpdated().subscribe(account => {
      if (this.mode === 'ADD' || this.mode === 'UPDATE') {
        /**passage par copie */
        this.user = Object.assign({}, account);
        this.user.username = this.user.username.split("_")[1];
        //this.user.username = this.user.username.substring( this.user.username.indexOf('_')+1);
        this.mode = 'UPDATE';
      }
    });

  }

  ngOnInit() {

    if (this.mode !== 'SHOW') {
      this.getAllVehicules();
      this.loadMissions();
    }
    this.vehiculesDropdownSettings = {
      singleSelection: true,
      idField: 'idVehicule',
      textField: 'matricule',
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.missionsDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

  }

  toggleReset(): void {
    // this.user.enabled = !this.user.enabled;
  }

  getAllVehicules() {
    this.dataManagementService.getAllVehicules().subscribe(vehicules => {
      for (let i = 0; i < vehicules.length; i++) {
        this.vehiculesItems = vehicules;
    }
  });
  }

  loadMissions() {
    this.missionService.getMissions()
        .subscribe(missions => {
            this.missionItems = missions;
        });
}

  init() {
    this.user = new UserMission();
    this.mode = 'ADD';
  }

  toggle() {
    this.isCollabsed = !this.isCollabsed;
  }

  addSubAccount() {
    this.globalLoading = true;
    if (this.user) {
      if (this.user.email === '') {
        this.user.email = '';
      }
    }
    this.signinService.addUserMission(this.user).subscribe(
      subAccount => {
        this.init();
        this.signinService.sendUserMissionWasCreated(subAccount);
        this.globalLoading = false;
        this.toastr.success(
          'Compte ' + subAccount.username + ' est crée !',
          'info'
        );
      },
      (error) => {
        this.globalLoading = false;

        if (error.status == 409) {
          this.toastr.error('Login existe déjà', 'Erreur!');
        } else {
          this.toastr.error("l'opération a échoué !", 'Erreur');
        }
      }
    );
  }

  deleteSubAccount() {
    if (confirm('etes vous sur ?')) {
      this.globalLoading = true;
      this.signinService.deleteUserMission(this.user.id).subscribe(
        isDeleted => {
          this.signinService.sendUserMissionWasDeleted(this.user);
          this.globalLoading = false;

          this.toastr.success(
            'Sous compte ' + this.user.username + ' est supprimé !',
            'info'
          );
        },
        () => {
          this.globalLoading = false;

          this.toastr.error("l'opération a échoué !", 'Erreur');
        }
      );
    }
  }

  editSubAccount() {
    this.globalLoading = true;
    this.signinService.updateUserMission(this.user).subscribe(
      subAccount => {
        this.signinService.sendUserMissionWasChanged(subAccount);
        this.globalLoading = false;
        this.toastr.success(
          'Sous compte ' + subAccount.username + ' est modifié !',
          'info'
        );
        this.init();
      },
      () => {
        this.globalLoading = false;
        this.toastr.error("l'opération a échoué !", 'Erreur');
      }
    );
  }

  sendMail() {
    if (confirm('etes vous sur de vouloir envoyer cet email?'))
      this.signinService
        .sendMailUserMission(this.user)
        .map(res => res)
        .subscribe(subAccount => {
          if (subAccount.status === 200) {
            this.toastr.success('votre mail a été envoyer avec succes', 'info');
          } else { this.toastr.error("l'envoie de mail a échoué", 'info'); }
        });
    else { this.toastr.error("l'envoie de mail a été annuler", 'info'); }
  }

  updateSubAccount() {
    this.signinService.sendUserMissionWasUpdated(this.user);
  }

  /* déctiver ou activé sous compte */
  enableOrdisableAccount() {
    this.signinService.disableOrEnaleUserMission(this.user.id).subscribe(
      subAccount => {
        this.user = subAccount;
        this.toastr.success(
          'Sous compte ' + subAccount.username + ' est activé/désactivé !',
          'info'
        );
      },
      () => {
        this.toastr.error("l'opération a échoué !", 'Erreur');
      }
    );
  }

}
