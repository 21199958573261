<div class="row">
  <div class="col-md-12">
    <div class="box" style="border-radius: 0px;min-height: unset;">
      <div class="box-body">
        <form class="form-horizontal" #stopForm="ngForm">
          <div class="form-group row">

            <label class="col-sm-1 control-label grp-lbl">Groupe</label>
            <div class="col-sm-2 grp-slct">
              <select class="form-control input-sm" name="group" [(ngModel)]="selectedGroup"
                (ngModelChange)="chooseGroup()" required>
                <option *ngFor="let group of groups" [ngValue]="group">
                  {{group?.nom | capitalizefirst}}
                </option>
              </select>
            </div>

            <label class="col-sm-1 control-label grp-lbl">Date</label>
            <div class="col-sm-2">
              <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [maxDate]="now" placement="bottom" required
                (ngModelChange)="onChangeStartDate()">
            </div>

            <div class="col-sm-1 form-btns">
              <button type="button" class="btn btn-success btn-flat btn-sm" [disabled]="loading || !stopForm.form.valid"
                (click)="stop()" title="Valider">
                <b><i class="fa fa-play" aria-hidden="true"></i></b>
              </button>
            </div>
            <div *ngIf="loading" class="col-sm-2 text-center" style="font-size: 12px;margin-top: 8px;">
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
            </div>
            <div class="col-sm-4 text-center" *ngIf="!loading && updateTrackingTimer"
              style="font-size: 12px;margin-top: 8px;">
              <i class="fa fa-hourglass-half" aria-hidden="true"></i>
              Mise à jour dans {{updateTrackingTimer}} seconds...
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<div class="row flex">

  <!-- ==================== Arrêt Section ======================= -->
  <div class="col-xl-2 col-md-2 mb-4 col-xs-6 col-md-offset-1">
    <div class="card redStyle">
      <div class="title">Arrêt</div>
      <span class="glyphicon glyphicon-pause"></span>
      <div class="value">
        <span *ngIf="!loading && stopLength" class="font-size">{{stopLength | timediff}}</span>
        <span *ngIf="!loading && !stopLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>
    </div>
  </div>

  <!-- ==================== Arrêt v > 0 Section ======================= -->
  <div class="col-xl-2 col-md-2 mb-4 col-xs-6">
    <div class="card yellowStyle">
      <div class="title">Arrêt C = 1</div>
      <span class="glyphicon glyphicon-pause"></span>
      <div class="value">
        <span *ngIf="!loading && stopInPathLength" class="font-size">{{stopInPathLength | timediff}}</span>
        <span *ngIf="!loading && !stopInPathLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>
    </div>
  </div>

  <!-- ==================== Arrêt POI Section ======================= -->
  <div class="col-xl-2 col-md-2 mb-4 col-xs-6">
    <div class="card greyStyle">
      <div class="title">Arrêt POI</div>
      <span class="glyphicon glyphicon-th"></span>
      <div class="value">
        <span *ngIf="!loading && stopInPoiLength" class="font-size">{{stopInPoiLength | timediff}}</span>
        <span *ngIf="!loading && !stopInPoiLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>
    </div>
  </div>

  <!-- ==================== En mission Section ======================= -->
  <div class="col-xl-2 col-md-2 mb-4 col-xs-6">
    <div class="card greenStyle">
      <div class="title">En mission</div>
      <span class="glyphicon glyphicon-play"></span>
      <div class="value">
        <span *ngIf="!loading && ActiveLength" class="font-size">{{ActiveLength | timediff}}</span>
        <span *ngIf="!loading && !ActiveLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>
    </div>
  </div>

  <!-- ==================== En panne Section ======================= -->
  <div class="col-xl-2 col-md-2 mb-4 col-xs-6">
    <div class="card blackStyle">
      <div class="title">En Panne</div>
      <span class="glyphicon glyphicon-remove"></span>
      <div class="value">
        <span *ngIf="!loading && breakDownLength" class="font-size">{{breakDownLength | timediff}}</span>
        <span *ngIf="!loading && !breakDownLength"> 0</span>
        <i *ngIf="loading" style="text-align: center" class="fa fa-refresh fa-spin fa-1x fa-fw"></i>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="isToday && vehicules.length > 0">
  <div class="col-xl-2 col-md-2 mb-2 col-xs-2">
    <div class="option-dashbord">
      <span title="Panne" (click)="showBreakForm()"><i class="fa fa-plus-circle add"></i>
        En Panne</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-12 col-md-12 mb-12 col-xs-12">
    <app-schedule-chart *ngIf="scheduleList.length > 0" [isCiment]="true" [scheduleList]="scheduleList">
    </app-schedule-chart>
  </div>
</div>


<!-- ========================================== Panne Modal (START) ====================================================-->
<div class="modal fade" tabindex="-1" #breakModal="bs-modal" bsModal role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- ========================== Modal TITLE (START) ===============================-->
      <div class="modal-header">

        <button type="button" class="close" (click)="breakModal.hide()">&times;</button>

        <h4 class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
          <i class="fa fa-gear" aria-hidden="true"></i>
          <span>Panne</span>
        </h4>
      </div>
      <!-- ========================== Modal TITLE (END) ===============================-->

      <div class="modal-body">
        <div class="box-body">
          <app-break-down-form [vehicules]="vehicules" (breakDownSaved)="breakWasSaved()"></app-break-down-form>
        </div>
      </div>

      <div class="box-footer">
        <button (click)="breakModal.hide()" class="btn btn-default pull-right">
          Fermer
        </button>
      </div>

    </div>

  </div>
</div>
<!-- ========================================== Panne Modal (END) ====================================================-->
