import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using forms
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule if you're using date pickers
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading'; // Import NgxLoadingModule if you're using loading indicators
import { GeoMarketingTrackingComponent } from './geo-marketing-tracking.component'; // Adjust path if necessary
import { GeoMarketingService } from '../geo-marketing.service'; // Adjust path if necessary
import { DataManagementService } from 'src/app/client-management/data-management'; // Adjust path if necessary
import { ImportExportService } from 'src/app/client-management/import-export'; // Adjust path if necessary
import { GeoMarketingFormComponent } from '../geo-marketing-form/geo-marketing-form.component';
import { GeoMarketingFormModule } from '../geo-marketing-form/geo-marketing-form.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    GeoMarketingTrackingComponent,
    ],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(), // Use .forRoot() to configure ngx-bootstrap modules
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    GeoMarketingFormModule,
    SharedPipesModule,
    NgSelectModule,

  ],
  providers: [
    GeoMarketingService, // Provide the services needed in this module
    DataManagementService,
    ImportExportService
  ],
  exports: [
    GeoMarketingTrackingComponent // Export the component if needed
  ]
})
export class GeoMarketingTrackingModule { }
