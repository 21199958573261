import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import {
  GroupDto,
  GroupService,
} from 'src/app/client-management/data-management';
import { AvailableVehiculesOverviewDto } from '../../overview';
import { MonthlyAvailableVehiculesService } from './monthly-available-vehicules.service';
import { SigninService } from 'src/app/authentification/signin/signin.service';
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/stock')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);


@Component({
  selector: 'app-monthly-available-vehicules-chart',
  templateUrl: './monthly-available-vehicules-chart.component.html',
  styleUrls: ['./monthly-available-vehicules-chart.component.css'],
})
export class MonthlyAvailableVehiculesChartComponent implements OnInit {
  showModal: boolean = false;
  globalLoading: boolean;
  monthlyAvailableVehiculeChart: Object | null = null;
  monthlyAvailableVehiculeChartModal: Object | null = null;
  availableVehiculesDto: AvailableVehiculesOverviewDto[] = [];
  availableVehicules: AvailableVehiculesOverviewDto[] = [];
  nbVehiculesOfEachGroup: any[] = [];
  selectedMonth: number;
  today: Date = new Date();
  isByMultipleGroups: boolean = false;

  groupIds: GroupDto[] = [];
  isRoot = true;

  @Input()
  groups: GroupDto[] = [];

  groupDropdownSettings = {
    singleSelection: false,
    textField: 'nom',
    enableCheckAll: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 22,
    allowSearchFilter: true,
  };

  months = [
    { id: 1, name: 'Janvier' },
    { id: 2, name: 'Février' },
    { id: 3, name: 'Mars' },
    { id: 4, name: 'Avril' },
    { id: 5, name: 'Mai' },
    { id: 6, name: 'Juin' },
    { id: 7, name: 'Juillet' },
    { id: 8, name: 'Août' },
    { id: 9, name: 'Septembre' },
    { id: 10, name: 'Octobre' },
    { id: 11, name: 'Novembre' },
    { id: 12, name: 'Décembre' },
  ];
  dropdownSettings: any;

  constructor(
    private monthlyAvailableVehiculesService: MonthlyAvailableVehiculesService,
    private groupService: GroupService,
    private signInService: SigninService
  ) {
    console.log("test 1", this.groups)
  }

  ngOnInit() {

    this.loaddata();

    this.groupDropdownSettings = {
      singleSelection: false,
      textField: 'nom',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 22,
      allowSearchFilter: true,
    };

    this.selectedMonth = this.today.getMonth() + 1;
    this.getCountVehiculeByGroup();

    if (!this.signInService.isRootAdmin) {
      this.subAccountVehiculeGroup();
    }
    console.log("test 1", this.groups)

  }




  dropdownList: any[] = [];
  selectedItems: any[] = [];

  @ViewChild('multiSelect') multiSelect: any;

  loaddata() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  subAccountVehiculeGroup() {
    console.log("test 1", this.groups)

    this.groupIds = this.groups;
    this.isByMultipleGroups = true;
    this.isRoot = false;
    this.onValidate();
  }

  getCountVehiculeByGroup() {
    console.log("test 1", this.groups)

    this.globalLoading = true;
    this.groupService.getCountVehiculesByGroupes().subscribe((res) => {
      this.nbVehiculesOfEachGroup = res;
      this.loadMonthlyAvailableVehiculesByGroup();
    });
  }

  loadMonthlyAvailableVehiculesByGroup() {

    this.globalLoading = true;
    this.monthlyAvailableVehiculesService
      .getMonthlyAvailableNumberOfVehicles(-1, this.selectedMonth)
      .subscribe((res) => {
        this.availableVehiculesDto = res;
        this.availableVehicules = res;
        this.MonthlyAvailableVehiculesChart();
        this.globalLoading = false;
      });
  }

  daysInMonth() {
    return new Date(
      this.today.getUTCFullYear(),
      this.selectedMonth,
      0
    ).getDate();
  }

  onMonthChange() {
    if (this.selectedMonth != null) this.loadMonthlyAvailableVehiculesByGroup();
    if (!this.isRoot) {
      this.subAccountVehiculeGroup();
    }
  }

  onMonthChangeGrp() {
  }

  onValidate() {

    console.log(this.groupIds)

    this.availableVehiculesDto = this.availableVehicules.filter((m) =>
      this.groupIds.map((g) => g.idGroupe).includes(m.id)
    );
    this.MonthlyAvailableVehiculesChart();
  }

  onChangeByMultipleGroups() {
    this.groupIds = [];
    if (!this.isByMultipleGroups) {
      this.availableVehiculesDto = this.availableVehicules;
      this.MonthlyAvailableVehiculesChart();
    }
  }

  MonthlyAvailableVehiculesChart() {
    this.setHighCartsLang();
    var that = this;
    var drilldata: { name: any; id: number; data: any[]; }[] = [];
    var data: { name: string; y: number; drilldown: number }[] = [];

    this.availableVehiculesDto.forEach((activity) => {
      var nbVehiculesOfEachGroup = this.nbVehiculesOfEachGroup.find(
        (g) => g[0] == activity.id
      )[1];

      let percentage =
        (activity.availability /
          (nbVehiculesOfEachGroup * this.daysInMonth())) *
        100;
      if (percentage > 100) percentage = 100;
      data.push({
        name: activity.nom,
        y: percentage,
        drilldown: activity.id,
      });
    });

    this.monthlyAvailableVehiculeChart = {
      chart: {
        type: 'column',
        height: 360,
        events: {
          drilldown: function (e: { seriesOptions: any; point: { drilldown: number; name: any; }; }) {
            if (!e.seriesOptions) {
              var vehiculesdata: any[] = [];
              that.monthlyAvailableVehiculesService
                .getMonthlyAvailableNumberOfVehicles(
                  e.point.drilldown,
                  that.selectedMonth
                )
                .subscribe((res) => {
                  for (let i = 0; i < res.length; i++) {
                    let percentage =
                      (res[i].availability / that.daysInMonth()) * 100;

                    vehiculesdata.push([
                      'Vehicule : ' + res[i].nom + '  <br /> ',
                      percentage,
                    ]);
                    drilldata.push({
                      name: e.point.name,
                      id: e.point.drilldown,
                      data: vehiculesdata,
                    });
                  }
                });
            }
          },
        },
      },
      title: {
        text: 'Disponibilité véhicules', // par groupe
        style: {
          fontWeight: 'bold',
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: 'underline',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Disponibilité véhicules',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f} ',
            softThreshold: true,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span> <b>{point.y:,.2f}</b> <br/>',
      },
      series: [
        {
          name: 'Groupe',
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f}',
          },
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">{point.name} </span> <b>{point.y:,.2f}</b> <br/>' +
              '<b>{point.p:.1f}%</b>',
          },
          data: data,
        },
        {
          type: 'pie',
          name: 'Tout le parc',
          tooltip: {
            pointFormat:
              '<span style="color:#007bff">Coût total :<b>{point.y:,.2f} DH</b></span>',
          },
          data: [
            {
              name: 'Tout le parc',
              color: '#64e572',
            },
          ],
          center: [100, 30],
          size: 70,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        series: drilldata,
      },
    };
    this.monthlyAvailableVehiculeChartModal = {
      chart: {
        type: 'column',
        height: 360,
        events: {
          drilldown: function (e: { seriesOptions: any; point: { drilldown: number; name: any; }; }) {
            if (!e.seriesOptions) {
              var vehiculesdata: any[] = [];
              that.monthlyAvailableVehiculesService
                .getMonthlyAvailableNumberOfVehicles(
                  e.point.drilldown,
                  that.selectedMonth
                )
                .subscribe((res) => {
                  for (let i = 0; i < res.length; i++) {
                    let percentage =
                      (res[i].availability / that.daysInMonth()) * 100;

                    vehiculesdata.push([
                      'Vehicule : ' + res[i].nom + '  <br /> ',
                      percentage,
                    ]);
                    drilldata.push({
                      name: e.point.name,
                      id: e.point.drilldown,
                      data: vehiculesdata,
                    });
                  }
                });
            }
          },
        },
      },
      title: {
        text: 'Disponibilité véhicules', // par groupe
        style: {
          fontWeight: 'bold',
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: 'underline',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Disponibilité véhicules',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f} ',
            softThreshold: true,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span> <b>{point.y:,.2f}</b> <br/>',
      },
      series: [
        {
          name: 'Groupe',
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f}',
          },
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">{point.name} </span> <b>{point.y:,.2f}</b> <br/>' +
              '<b>{point.p:.1f}%</b>',
          },
          data: data,
        },
        {
          type: 'pie',
          name: 'Tout le parc',
          tooltip: {
            pointFormat:
              '<span style="color:#007bff">Coût total :<b>{point.y:,.2f} DH</b></span>',
          },
          data: [
            {
              name: 'Tout le parc',
              color: '#64e572',
            },
          ],
          center: [100, 30],
          size: 70,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        series: drilldata,
      },
    };
    Highcharts.chart(
      'monthlyAvailableVehicules',
      this.monthlyAvailableVehiculeChart
    );

    Highcharts.chart(
      'monthlyAvailableVehiculesModal',
      this.monthlyAvailableVehiculeChartModal
    );
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7',
        '#e77e22',
        '#3ea6dc',
        '#47a4d2',
        '#51a2c7',
        '#5a9fbd',
        '#639db3',
        '#6d9ba8',
        '#76999e',
        '#7f9694',
        '#899489',
        '#92927f',
        '#9c9074',
        '#a58d6a',
        '#ae8b60',
        '#b88955',
        '#c1874b',
        '#ca8441',
        '#d48236',
        '#dd802c',
        '#e77e22',
      ],
      //colors: ['#73f312','#c7c114','#682ef0','#ab23eb','#eb233a','#289e77','#611e0a','#6AF9C4', '#DA932C', '#D65B4A', '#f39c12','#007bff','#FF9655', '#000000',  '#66B92E', '#24CBE5','#f37736', '#854442', '#3d1e6d', '#64E572', '#D81B60', '#605ca8', '#3D9970','#adcbe3',  '#c68642',  '#001F3F', '#00c0ef', ' #451e3e' ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: "Aucune donnée n'est disponible",
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
    });
  }
}
