import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {LastDates, Provider, RunningDocument, RunningDocumentInfo} from "../model/index";

import {dns} from "../../../global.config";
import {createAuthorizationHeader} from "../../../utils/security/headers";
import {DateInterval} from "../../../shared/model";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DocumentCirculationService {
  constructor(private _http: HttpClient) { }

  readData(
    idVehicule: number,
    type: string,
    fileName: string
  ): Observable<RunningDocumentInfo[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<RunningDocumentInfo[]>(
        dns +
        "runningDocuments/readData/" +
        idVehicule +
        "?type=" +
        type +
        "&fileName=" +
        fileName,
        {
          headers: headers
        }
      )
      ;
  }

  getListDocuments(idVehicule: number, type: string): Observable<RunningDocument[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<RunningDocument[]>(dns + "runningDocuments/listByType/" + idVehicule + "?type=" + type, { headers: headers });
  }

  getListYears(idVehicule: number, type: string): Observable<number[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<number[]>(dns + "runningDocuments/listByTypeAndYear/" + idVehicule + "?type=" + type, { headers: headers });
  }

  getListCostByYear(idVehicule: number, type: string, year: number): Observable<RunningDocument[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<RunningDocument[]>(dns + "runningDocuments/costByTypeAndYear/" + idVehicule + "?type=" + type + "&year=" + year, { headers: headers });
  }



  getProviders(type: string): Observable<Provider[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Provider[]>(dns + "providers/listByType?type=" + type, { headers: headers })
      ;
  }

  getExpirationDates(idVehicule: number): Observable<LastDates> {
    let headers = createAuthorizationHeader();
    return this._http.get<LastDates>(dns + "runningDocuments/expirationDates/" + idVehicule, { headers: headers });
  }

  downloadFile(fileName: string): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(dns + "upload/files/" + fileName, { headers: headers });
  }

  delete(idRunningDocument: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete<Boolean>(dns + "runningDocuments/" + idRunningDocument, {
        headers: headers
      });
  }

  deleteMultiple(documentsId: number[]): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + "runningDocuments/multiple/" + documentsId, { headers: headers });
  }

  public getDataInExcel(idVehicule: number, type: string, date: DateInterval): Observable<Blob> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers, responseType: 'blob' as 'json' };
    return this._http.post<Blob>(dns + "runningDocuments/exportExcel/" + idVehicule + "?type=" + type, date, options);
  }

  public add(documente: RunningDocument, idVehicule: number, groupId: number): Observable<RunningDocument[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<RunningDocument[]>(dns + 'runningDocuments?idVehicule=' + idVehicule + "&groupId=" + groupId, documente, { headers: headers });
  }

  public update(documente: RunningDocument): Observable<RunningDocument[]> {
    let headers = createAuthorizationHeader();
    return this._http.put<RunningDocument[]>(dns + 'runningDocuments', documente, { headers: headers });
  }

}
