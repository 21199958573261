import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RotationChargeComponent } from './rotation-charge.component';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RotationChargeComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Importing ToastrModule
    BsDatepickerModule.forRoot(), // Importing DatepickerModule
    FormsModule // Importing FormsModule for template-driven forms
  ],
  exports: [
    RotationChargeComponent // Exporting the component for use in other modules
  ]
})
export class RotationChargeModule { }
