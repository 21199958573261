import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProgramComponent } from './program.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { DataManagementService } from '../data-management.service';
import { ProgramService } from '../providers/program.service';
import { NgxLoadingModule } from 'ngx-loading';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { RouterModule } from '@angular/router';
import { ProgramFormModule } from './program-form/program-form.module';

@NgModule({
  declarations: [
    ProgramComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),  // Import the ngx-bootstrap modal module
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    OrderPipModule,
    FilterPipeModule,
    RouterModule,
    ProgramFormModule
  ],
  providers: [
    DataManagementService,
    ProgramService
  ],
  exports: [
    ProgramComponent
  ]
})
export class ProgramModule { }
