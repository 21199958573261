import { Component, OnInit, ViewChild } from '@angular/core';
import { Provider, Group } from '../../model/index';
import { Subscription } from 'rxjs/Subscription';
import { ToastrService } from "ngx-toastr";
import { DataManagementService } from "../../../data-management/data-management.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Tire } from '../../model';
import { TireService } from '../../providers/tire-service';
import { imagesPOIDir } from '../../../../global.config';
import { ImportExportService } from '../../../import-export';
import { RapportDto, Vehicule } from '../../../data-management';

@Component({
  selector: 'app-tire',
  templateUrl: './tire.component.html',
  styleUrls: ['./tire.component.css']
})
export class TireComponent implements OnInit {

  tires: Tire[] = [];

  tiresId: number[] = [];

  repport: RapportDto = new RapportDto();

  vehicule: Vehicule = new Vehicule();

  group: Group = new Group();

  getVehiculeWasSelectedSubscription: Subscription;

  getGroupWasSelectedSubscription: Subscription;

  searchTerm: string | null = null;

  tire: Tire = new Tire();

  mode: 'ADD' | 'UPDATE';

  imagesPOIDir = imagesPOIDir;

  loading: boolean = false;

  isDeleteMultiple: boolean = false;

  selectedItem = 'TIRE';

  @ViewChild('tireModal', { static: false })
  tireModal: ModalDirective;

  @ViewChild('tireDeleteModal', { static: false })
  tireDeleteModal: ModalDirective;

  @ViewChild('importModal', { static: false }) importModal: ModalDirective;
  constructor(private tireService: TireService, private dataManagementService: DataManagementService, public toastr: ToastrService, public exportImport: ImportExportService) { }

  ngOnInit() {

    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.vehicule = this.dataManagementService.selectedVehicule;
      this.group = this.dataManagementService.selectedGroup;
      this.loadTires();
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.vehicule = vehicule;
      this.loadTires();
    });

    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.group = group;
    });
  }

  ngOnDestroy() {
    if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
  }

  /**
   * Charger la liste des pneus
   */
  loadTires() {
    if(!this.vehicule.idVehicule) return;
    this.loading = true;
    this.tireService.getListTires(this.vehicule.idVehicule, this.group.idGroupe).subscribe(tires => {
      this.tires = tires;
      this.loading = false;
    }, error => {
      this.loading = false;
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
    });
  }

  /**
   * Afficher modal de modification du pneu
   */
  onUpdate(tire: Tire) {
    if (!tire.provider) {
      tire.provider = new Provider();
    }
    this.tire = Object.assign({}, tire);
    if (this.tire.actualRepairTime) this.tire.actualRepairTime = new Date(this.tire.actualRepairTime);
    if (this.tire.buyDate) this.tire.buyDate = new Date(this.tire.buyDate);
    if (this.tire.operationTime) this.tire.operationTime = new Date(this.tire.operationTime);
    this.mode = 'UPDATE';
    this.tireModal.show();
  }

  /**
   * Afficher modal d'ajout du pneu
   */
  onAdd() {
    this.tire = new Tire();
    this.mode = 'ADD';
    this.tireModal.show();
  }

  /**
   * Afficher modal de confirmation du suppression
   */
  onConfirmDelete(tire: Tire) {
    this.tire = tire;
    if (!this.tire.provider) {
      this.tire.provider = new Provider();
    }
    this.isDeleteMultiple = false;
    this.tireDeleteModal.show();
  }

  /**
   * Suppression du pneu
   */
  delete() {
    this.loading = true;
    this.tireService.delete(this.tire.id).subscribe(response => {

      if (response) {
        this.tires = this.tires.filter(tire => tire.id != this.tire.id);
        this.tiresId = this.tiresId.filter(tireId => tireId != this.tire.id);
        this.toastr.success('Pneu informations bien supprimer !', 'info');
      } else {
        this.toastr.error('Le pneu n\'a pas supprimé correctement !', 'Erreur');
      }

      this.tireDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }

  /**
   * Suppression multiple du pneu
   */
  deleteMultiple() {
    this.loading = true;
    this.tireService.deleteMultiple(this.tiresId).subscribe(response => {

      if (response) {
        for (let tireId of this.tiresId) {
          this.tires = this.tires.filter(tire => tire.id != tireId);
        }

        this.tiresId = [];
        this.toastr.success('Suppression effectuée avec succès.', 'info');
      } else {
        this.toastr.error('Le pneu n\'a pas supprimé correctement !', 'Erreur');
      }

      this.tireDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }


  /**
   * Actualiser la liste des pneus apés l'événement d'ajout
   */
  tireWasAdded(tire: Tire) {
    this.tireModal.hide();
    this.tires.unshift(tire);
  }

  /**
   * Actualiser la liste des pneus apés l'événement de modification
   */
  tireWasUpdated(tire: Tire) {
    this.tireModal.hide();

    for (let i = 0; i < this.tires.length; i++) {
      if (this.tires[i].id == tire.id) {
        this.tires[i] = tire;
        break;
      }
    }
  }

  export() {
    this.repport.type = 'TIRE';
    this.exportImport.export(this.tires, this.repport, this.dataManagementService.selectedVehicule!.idVehicule);
  }

  itemWasImported(res: boolean) {
    if(res){
      this.importModal.hide();
      this.loadTires();
    }
  }


  /**
 * Event confirm delete multiple
 */
  onConfirmDeleteMultiple() {
    if (this.tiresId.length == 0) {
      this.toastr.warning("Veuillez choisir des pneus à supprimer !");
      return;
    }
    this.isDeleteMultiple = true;
    this.tireDeleteModal.show();
  }

  /**
   * Event delete
   */
  onDelete() {
    if (this.isDeleteMultiple) {
      this.deleteMultiple();
    } else {
      this.delete();
    }
  }

  /**
   * get list search
   */
  getTires(): Tire[] {
    if ((this.searchTerm) && (this.searchTerm.length > 0)) {
      return this.tires.filter(tire => (tire.provider) && (tire.provider.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
    } else {
     return this.tires;
    }
  }


  /**
   * Event check row for delete multiple
   */
  onChecked(id: number) {
    if (this.tiresId.find(tireId => tireId == id)) {
      this.tiresId = this.tiresId.filter(tireId => tireId != id);
    } else {
      this.tiresId.push(id);
    }
  }

  /**
   * Event check all row for delete multiple
   */
  onCheckedAll() {
    if (this.tiresId.length == this.tires.length) {
      this.tiresId = [];
    } else {
      this.tiresId = [];
      for (let tire of this.tires) {
        this.tiresId.push(tire.id);
      }
    }
  }

  /**
   * check if id exists in array,
   */
  isCheckedInList(id: number): boolean {
    return this.tiresId.find(tireId => tireId == id) ? true : false;
  }
}
