import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { KmJrToolComponent } from './tool-km-jour.component';
import { KmJrTrajetParentModule } from './km-jour-trajet-parent/km-jour-trajet-parent.module';
import { KmJrTrajetItemModule } from './km-jour-trajet-item/km-jour-trajet-item.module';
import { KmJrTrajetModule } from './km-jour-trajet/km-jour-trajet.module';
import { StopsParentsModule } from '../stops/stops-parents/stops-parents.module';
import { StopsChildsModule } from '../stops/stops-childs/stops-childs.module';
import { AddStopPoiModule } from '../stops/add-stop-poi/add-stop-poi.module';
import { ProgressCustomBarModule } from 'src/app/shared/progress-bar/progress-custom-bar.module';

@NgModule({
  declarations: [
    KmJrToolComponent // Declare the KmJrToolComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular directives
    RouterModule,
    KmJrTrajetParentModule,
    KmJrTrajetItemModule,
    ProgressCustomBarModule,
    StopsParentsModule,
    StopsChildsModule,
    AddStopPoiModule,
    

  ],
  exports: [
    KmJrToolComponent,
  ],
  providers: [] // Add service providers here if needed
})
export class ToolKmJourModule { }
