<div class="" style="height: 100% !important; ">
  <app-header></app-header>
  <app-left-sidebar></app-left-sidebar>
  <div class="content-wrapper" style="height: 100%;">
      <section class="content-header">
      </section>
      <section class="content" style="height:97%;background-color: white; margin-top: -5px; position: relative;">
          <router-outlet></router-outlet>
      </section>
  </div>
</div>