import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { Tire } from "../model/index";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TireService {

  constructor(private _http: HttpClient) { }

  getListTires(idVehicule: number, groupId: number): Observable<Tire[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Tire[]>(dns + "tires/list/" + idVehicule + "?groupId=" + groupId, { headers: headers });
  }

  delete(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + "tires/" + id, { headers: headers });
  }

  deleteMultiple(tiresId: number[]): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + "tires/multiple/" + tiresId, { headers: headers });
  }

  public add(tire: Tire, idVehicule: number, groupId: number): Observable<Tire> {
    let headers = createAuthorizationHeader();
    return this._http.post<Tire>(dns + "tires?idVehicule=" + idVehicule + "&groupId=" + groupId, tire, { headers: headers });
  }

  public update(tire: Tire): Observable<Tire> {
    let headers = createAuthorizationHeader();
    return this._http.put<Tire>(dns + "tires", tire, { headers: headers });
  }
}
