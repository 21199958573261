import {
  Driver,
  Device,
  VehiculeTravelReport,
} from 'src/app/client-management/data-management';

export class Vehicule {
  idVehicule: number;
  matricule: string;
  pathGrayCard: string;

  constructor() {}
}

export class Group {
  idGroupe: number;
  nom: string;

  constructor() {}
}

export class RunningDocumentInfo {
  id: number;
  operationTime: number;
  endTime: number;
  cost: number;
  providerName: string;
  idProvider: number;
  numPolice: string;
  place: string;

  constructor() {}
}

export class Provider {
  id: number;
  name: string;
  telephone?: string;
  email?: string;
  adresse?: string;
  codeTVA?: string;
  type?: string;

  constructor() {
    this.id = null;
  }
}

export class MultiType {
  id: number;
  name: string;
  type: string;

  constructor() {}
}

export class TypeOil {
  value: number;
  name: string;

  constructor() {}
}

export class LastDates {
  insurance: Date;
  visit: Date;
  certification: Date;
  taxe: Date;
  permit: Date;
  metologicalNotebook: Date;
  extincteurs: Date;
  tonnageTax: Date;
  grayCard: Date;

  constructor() {}
}

export class FuelingInfo {
  id: number;
  operationTime: number;
  quantity: number;
  costUnit: number;
  odometre: number;
  numberPayment: string;
  type: MultiType;
  observation: string;
  idProvider: number;
  providerName: string;
  cost: number;
  numCartFuel: string;

  constructor() {}
}

export class Maintenance {
  id: number;
  operationTime: Date;
  cost: number;
  observation: String;
  laborCost: number;
  referenceDADR: String;
  actualRepairTime: Date;
  provider: Provider;

  constructor() {
    this.provider = new Provider();
  }
}

export class OilChange extends Maintenance {
  quantity: number;
  engineOil: boolean;
  typeEngineOil: number;
  oilF: boolean;
  typeOilF: number;
  airF: boolean;
  typeAirF: number;
  fuelF: boolean;
  typeFuelF: number;
  separatorF: boolean;
  typeSeparatorF: number;
  odometre: number;
  airConditioningFilter: boolean;
  seal: boolean;
  oilMark: String;
  overtaking: number;
  refTypeOilF: MultiType;
  refTypeEngineOil: MultiType;
  refTypeFuelF: MultiType;
  refTypeAirF: MultiType;
  refTypeSeparatorF: MultiType;

  constructor() {
    super();
    this.refTypeOilF = new MultiType();
    this.refTypeEngineOil = new MultiType();
    this.refTypeFuelF = new MultiType();
    this.refTypeAirF = new MultiType();
    this.refTypeSeparatorF = new MultiType();
  }
}

export class OilChangeInfo {
  id: number;
  operationTime: number;
  quantity: number;
  cost: number;
  observation: string;
  engineOil: Boolean;
  typeEngineOil: number;
  oilF: Boolean;
  typeOilF: number;
  airF: Boolean;
  typeAirF: number;
  fuelF: Boolean;
  typeFuelF: number;
  separatorF: Boolean;
  typeSeparatorF: number;
  idProvider: number;
  providerName: string;
  odometre: number;
  airConditioningFilter: boolean;
  seal: boolean;
  oilMark: boolean;
  refTypeOilF: MultiType;
  refTypeEngineOil: MultiType;
  refTypeFuelF: MultiType;
  refTypeAirF: MultiType;
  refTypeSeparatorF: MultiType;
  constructor() {}
}

export class Tire extends Maintenance {
  position: String;
  buyDate: Date;
  maxKm: number;
  kmMontage: number;
  number: number;
  mark: String;
  serieNumber: String;
  parallelism: boolean;
  calibration: boolean;
  balancing: boolean;

  constructor() {
    super();
    this.number = 1;
  }
}

export class TireInfo {
  id: number;
  operationTime: number;
  actualRepairTime: number;
  buyDate: number;
  number: number;
  maxKm: number;
  kmMontage: number;
  cost: number;
  mark: string;
  serieNumber: string;
  position: string;
  observation: string;
  idProvider: number;
  providerName: string;
  laborCost: number;
  referenceDADR: string;
  parallelism: boolean;
  calibration: boolean;
  balancing: boolean;

  constructor() {}
}

export class Battery extends Maintenance {
  expirationDate: Date;
  duration: number;
  number: number;
  amperage: number;
  voltage: number;
  mark: number;
  serieNumber: String;

  constructor() {
    super();
  }
}

export class BatteryInfo {
  id: number;
  operationTime: number;
  actualRepairTime: number;
  expirationDate: number;
  number: number;
  duration: number;
  cost: number;
  mark: string;
  serieNumber: string;
  observation: string;
  amperage: number;
  voltage: number;
  idProvider: number;
  providerName: string;
  laborCost: number;
  referenceDADR: string;
  odometerRepair: number;
  nextForecastOdometer: number;

  constructor() {}
}

export class Reparation extends Maintenance {
  odometre: number;
  quantity: number;
  odometerRepair: number;
  nextForecastOdometer: number;
  label: String;
  days: number;
  reparationType: MultiType;

  constructor() {
    super();
    this.reparationType = new MultiType();
  }
}

export class ReparationInfo {
  id: number;
  operationTime: number;
  actualRepairTime: number;
  quantity: number;
  cost: number;
  odometre: number;
  observation: string;
  type: MultiType;
  idProvider: number;
  providerName: string;
  laborCost: number;
  referenceDADR: string;
  odometerRepair: number;
  nextForecastOdometer: number;
  label: string;

  constructor() {}
}

export class DashbordInfo {
  insurance: number;
  visit: number;
  roadTaxes: number;
  operationalCertification: number;
  permitCirculation: number;
  metologicalNotbook: number;
  extincteurs: number;
  tonnageTax: number;

  runningDocument: number;
  fuel: number;
  oilChange: number;
  tireMaintenance: number;
  batteryMaintenance: number;
  reparation: number;
  losses: number;
  brakeMaintenance: number;
  leasing: number;
  parking: number;
  lavage: number;
  tool: number;
  divers: number;
  total: number;

  driverCharge: number;
  turnover: number;

  constructor() {}
}

export class InterfaceMaintenace {
  total: number;
  reparation: number;
  oilChange: number;
  tireMaintenance: number;
  batteryMaintenance: number;
  brakeMaintenance: number;
  constructor() {}
}

export class Brake {
  id: number;
  operationTime: number;
  number: number;
  cost: number;
  mark: string;
  observation: string;
  laborCost: number;
  referenceDADR: string;
  provider: Provider;
  boardGames: boolean;
  disk: boolean;
  pump: boolean;
  oil: boolean;
  rightPosition: boolean;
  leftPosition: boolean;
  actualRepairTime: number;

  constructor() {
    this.provider = new Provider();
  }
}

export class Sinistre {
  id: number;
  operationTime: number;
  number: number;
  cost: number;
  mark: string;
  observation: string;
  laborCost: number;
  referenceDADR: string;
  provider: Provider;
  actualRepairTime: number;
  type: string;
  place: string;
  descriptionDamage: string;
  refund: number;
  cabinetExpertise: MultiType;
  driver: string;

  constructor() {
    this.provider = new Provider();
    this.cabinetExpertise = new MultiType();
  }
}

export class Offense {
  id: number;
  operationTime: number;
  number: number;
  cost: number;
  mark: string;
  observation: string;
  laborCost: number;
  referenceDADR: string;
  provider: Provider;
  actualRepairTime: number;

  place: string;
  paying: string;
  type: MultiType;

  constructor() {
    this.provider = new Provider();
    this.type = new MultiType();
  }
}

export class RunningDocument {
  id: number;
  operationTime: number;
  endTime: number;
  cost: number;
  type:
    | 'INSURANCE'
    | 'VISIT'
    | 'OPERATIONAL_CERTIFICATION'
    | 'PERMIT_CIRCULATION'
    | 'METOLOGICA_NOTBOOK'
    | 'ROAD_TAXES'
    | 'EXTINCTEURS'
    | 'LEASING'
    | 'PARCKING'
    | 'LAVAGE'
    | 'TONNAGE'
    | 'TOLL'
    | 'CA_REALIZATION'
    | 'C_LOCATION'
    | 'TONNAGE_TAX'
    | 'GRAY_CARD';
  provider: Provider;
  selected?: boolean;
  maturityAmount?: number;
  ref?: string;
  weight?: number;
  numPolice?: string;
  place?: string;

  constructor() {
    this.provider = new Provider();
  }
}

export class FuelingTemplateSetting {
  rowNumber: number;
  cost: number;
  matricule: number;
  operationDate: number;
  quantity: number;
  provider: number;
  costUnit: number;
  odometre: number;
  paymentType: number;
  numberPayment: number;
  carte: number;
  place: number;
  consumption: number;
  observation: number;
  reference: number;

  constructor() {}
}

export class FuelingPriceSetting {
  id: number;
  unitCost: number;
  startDate: Date;
  constructor() {}
}

export class Fueling {
  id: number;
  cost: number;
  operationTime: Date;
  quantity: number;
  provider: Provider;
  costUnit: number;
  odometre: number;
  paymentType: MultiType;
  numCartFuel: string;
  signification: string;
  place: string;
  consumption: number;
  numberPayment: string;
  observation: string;
  reference: string;
  caRealise: number;

  constructor() {
    this.provider = new Provider();
    this.paymentType = new MultiType();
  }
}

export class DriverCharge {
  id: number;
  salary: number;
  phone: number;
  commission: number;
  note: number;
  turnover: number;
  total: number;
  createAt: Date;
  driver: Driver;

  constructor() {
    this.driver = new Driver();
  }
}

export class GroupDashbordDto {
  idGroupe: number;
  nom: string;
  count: number;
  Total: number;
  TotalDocument: number;
  TotalDriverCharge: number;
  TotalDriverCA: number;
  TotalMaintenance: number;
  TotalDocumentAndDriverChargeAndMaintenance: number;
}

export class GroupDashbordByDate {
  idGroupe: number;
  nom: string;
  totalPreviousMonth: number;
  totalThisMonth: number;
  totalBeforePreviousMonth: number;
  countVehiculesOfThisMonth: number;
  countVehiculesOfPreviousMonth: number;
  countVehiculesOfBeforePreviousMonth: number;
}

export class DashbordGroupInfoDto {
  insurance: number;
  visit: number;
  roadTaxes: number;
  operationalCertification: number;
  permitCirculation: number;
  metologicalNotbook: number;
  extincteurs: number;
  tonnageTax: number;

  runningDocument: number;
  fuel: number;
  oilChange: number;
  tireMaintenance: number;
  batteryMaintenance: number;
  reparation: number;
  losses: number;
  brakeMaintenance: number;
  leasing: number;
  parking: number;
  lavage: number;
  tool: number;
  divers: number;
  IdGroupe: number;
  nom: string;
  driverCharge: number;
}

export class TravelDashbordDto {
  vehiculeMatricule: string;
  vehiculeId: number;
  groupeId: number;
  groupeName: string;
  driverFullName: string;
  deviceId: number;
  distanceDriven: number;
  drivingDurationStr: string;
  workingDurationStr: string;
  drivingDuration: number;
  pathStopStr: string;
  beginPathTime: Date;
  endPathTime: Date;
  pathTime: number;
  pathStop: number;
  nbDays: number;
  capacity: String;

  constructor() {
    this.drivingDurationStr = '';
    this.workingDurationStr = '';
    this.pathStopStr = '';
  }
}

export class TravelReport {
  vehiculeMatricule: string;
  vehiculeId: number;
  groupeId: number;
  groupeName: string;
  driverFullName: string;
  deviceId: number;
  distanceDriven: number;
  pathTime: number;
  pathStop: number;
  pathDuration: number;
  drivingDurationStr: string;
  workingDurationStr: string;
  drivingDuration: number;
  pathStopStr: string;
  pourcentage: number;
  mark: number;
  avatarUri: string;
  rotation: number;
  location: number;
  constructor() {
    this.drivingDurationStr = '';
    this.workingDurationStr = '';
    this.pathStopStr = '';
  }
}

export class TravelReportDto {
  vehiculeMatricule: string;
  vehiculeId: number;
  groupeId: number;
  groupeName: string;
  driverFullName: string;
  deviceId: number;
  distanceDriven: number;
  pathTime: number;
  pathStop: number;
  maxSpeed: number;
  pathDuration: number;
  drivingDurationStr: string;
  workingDurationStr: string;
  drivingDuration: number;
  pathStopStr: string;
  pourcentage: number;
  mark: number;
  capacity: string;
  fuelUsed: number;
  avatarUri: string;
  rotation: number;
  chargeLocation: number;
  beginPathTime: Date;
  endPathTime: Date;
  averageRotation: number;
  objectifRotation: number;
  startOdo: number;
  endOdo: number;
  vehicule: VehiculeTravelReport;
  zone: string;
  fuelPercentage: number;
  otNumber: string;
  missionType: string;
  unitCost: number;
  fuelCost: number;
  chargeGlobal: number;
  startAddress: string;
  stopRpm: number;
  stopDuration: number;
  time_in_poi: number;
  timeChomage: Date;
  timeChargement: Date;
  retardChomage: number;
  retardChargement: number;
  retardPoiClient: number;

  constructor() {
    this.drivingDurationStr = '';
    this.workingDurationStr = '';
    this.pathStopStr = '';
    this.vehicule = new VehiculeTravelReport();
  }

  update(
    beginPathTime: Date,
    endPathTime: Date,
    maxSpeed: number,
    pathDuration: number,
    distanceDriven: number,
    fuelUsed: number,
    pathStop: number,
    otNumber: string,
    missionType: string,
    stopRpm: number
  ) {
    this.beginPathTime = beginPathTime;
    this.endPathTime = endPathTime;
    this.maxSpeed = maxSpeed;
    this.pathDuration = pathDuration;
    this.distanceDriven = distanceDriven;
    this.fuelUsed = fuelUsed;
    this.pathStop = pathStop;
    this.otNumber = otNumber;
    this.missionType = missionType;
    this.stopRpm = stopRpm;
    this.stopRpm = stopRpm;
    this.zone = this.getZone();
    this.calculateFuel();
    this.calculateFuelingPrincing();
  }

  calculateFuelingPrincing() {
    this.fuelCost = this.unitCost * this.fuelUsed;
    if (this.zone == 'HZ') {
      this.chargeLocation = (this.vehicule.rental * this.distanceDriven) / 500;
    } else {
      this.chargeLocation = this.vehicule.rental / this.objectifRotation;
    }
    this.chargeGlobal = this.fuelCost + this.chargeLocation;
  }

  calculateFuel() {
    var hasTfu = this.vehicule.useFms;
    if (this.vehicule.signification) {
      if (this.fuelUsed < 1 || !hasTfu) {
        if ('h' != this.vehicule.signification.toString().toLowerCase()) {
          this.fuelUsed =
            (this.distanceDriven * this.vehicule.fuelAverage) / 100;
        } else {
          this.fuelUsed =
            (this.vehicule.fuelAverage * this.pathDuration) / 3600;
        }
      }

      if (this.vehicule.fuelAverage > 0) {
        if ('h' != this.vehicule.signification.toString().toLowerCase()) {
          this.fuelPercentage = (this.fuelUsed / this.distanceDriven) * 100;
        } else {
          this.fuelPercentage = this.fuelUsed / (this.pathDuration / 3600);
        }
      }
    }
  }

  getZone(): string {
    var zone: string = null;
    this.chargeLocation = (this.vehicule.rental * this.distanceDriven) / 500;
    var devidedDistance = this.distanceDriven / 2;

    if (
      this.vehicule.subMark != null &&
      (this.vehicule.subMark.toUpperCase() == 'SOLO' ||
        this.vehicule.subMark.toUpperCase() == 'SOLO GRUE' ||
        this.vehicule.subMark.toUpperCase() == 'CANTER')
    ) {
      if (devidedDistance <= 20) {
        zone = 'Z1';
        this.objectifRotation = 4;
      } else if (devidedDistance <= 40) {
        zone = 'Z2';
        this.objectifRotation = 3;
      } else if (devidedDistance <= 60) {
        zone = 'Z3';
        this.objectifRotation = 3;
      } else if (devidedDistance <= 80) {
        zone = 'Z4';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 100) {
        zone = 'Z5';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 120) {
        zone = 'Z6';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 140) {
        zone = 'Z7';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 160) {
        zone = 'Z8';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 180) {
        zone = 'Z9';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 200) {
        zone = 'Z10';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 220) {
        zone = 'Z11';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 240) {
        zone = 'Z12';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 260) {
        zone = 'Z13';
        this.objectifRotation = 1;
      } else {
        zone = 'HZ';
        this.objectifRotation = 1;
      }
    } else if (
      this.vehicule.subMark != null &&
      (this.vehicule.subMark.toUpperCase() == 'SEMI REMORQUE' ||
        this.vehicule.subMark.toUpperCase() == 'SEMI REMORQUE GRUE')
    ) {
      if (devidedDistance <= 20) {
        zone = 'Z1';
        this.objectifRotation = 3;
      } else if (devidedDistance <= 40) {
        zone = 'Z2';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 60) {
        zone = 'Z3';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 80) {
        zone = 'Z4';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 100) {
        zone = 'Z5';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 120) {
        zone = 'Z6';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 140) {
        zone = 'Z7';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 160) {
        zone = 'Z8';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 180) {
        zone = 'Z9';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 200) {
        zone = 'Z10';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 220) {
        zone = 'Z11';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 240) {
        zone = 'Z12';
        this.objectifRotation = 1;
      } else if (devidedDistance <= 260) {
        zone = 'Z13';
        this.objectifRotation = 1;
      } else {
        zone = 'HZ';
        this.objectifRotation = 1;
      }
    } else if (
      this.vehicule.subMark != null &&
      this.vehicule.subMark.toUpperCase() == 'POMPE'
    ) {
      if (devidedDistance <= 20) {
        zone = 'Z1';
        this.objectifRotation = 8;
      } else if (devidedDistance <= 50) {
        zone = 'Z2';
        this.objectifRotation = 4;
      } else if (devidedDistance <= 90) {
        zone = 'Z3';
        this.objectifRotation = 3;
      } else if (devidedDistance <= 110) {
        zone = 'Z4';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 140) {
        zone = 'Z5';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 170) {
        zone = 'Z6';
        this.objectifRotation = 1;
      } else {
        zone = 'HZ';
        this.objectifRotation = 1;
      }
    } else {
      if (devidedDistance <= 20) {
        zone = 'Z1';
        this.objectifRotation = 5;
      } else if (devidedDistance <= 50) {
        zone = 'Z2';
        this.objectifRotation = 4;
      } else if (devidedDistance <= 90) {
        zone = 'Z3';
        this.objectifRotation = 2.4;
      } else if (devidedDistance <= 110) {
        zone = 'Z4';
        this.objectifRotation = 2;
      } else if (devidedDistance <= 140) {
        zone = 'Z5';
        this.objectifRotation = 1.5;
      } else if (devidedDistance <= 170) {
        zone = 'Z6';
        this.objectifRotation = 1;
      } else {
        zone = 'HZ';
        this.objectifRotation = 1;
      }
    }

    // if (this.distanceDriven <= 30) {
    // 	zone = "Z1";
    // 	this.objectifRotation = 3;

    // 	if (this.vehicule.subMark != null && (this.vehicule.subMark.toUpperCase() == "SOLO"
    // 			|| this.vehicule.subMark.toUpperCase() == "SOLO GRUE" || this.vehicule.subMark.toUpperCase() == "CANTER")) {
    // 		this.objectifRotation = 4.5;
    // 	}
    // } else if (this.distanceDriven <= 80) {
    // 	zone = "Z2";
    // 	this.objectifRotation = 4;
    // 	if (this.vehicule.subMark != null && (this.vehicule.subMark.toUpperCase() == "SEMI REMORQUE"
    // 			|| this.vehicule.subMark.toUpperCase() == "SEMI REMORQUE GRUE")) {
    //         this.objectifRotation = 2.5;
    // 	}
    // } else if (this.distanceDriven <= 130) {
    // 	zone = "Z3";
    // 	this.objectifRotation = 3;
    // 	if (this.vehicule.subMark != null && (this.vehicule.subMark.toUpperCase() == "SEMI REMORQUE"
    // 			|| this.vehicule.subMark.toUpperCase() == "SEMI REMORQUE GRUE")) {
    // 		this.objectifRotation = 2.5;
    // 	}
    // } else if (this.distanceDriven <= 180) {
    // 	zone = "Z4";
    // 	this.objectifRotation = 2;
    // 	if (this.vehicule.subMark != null && (this.vehicule.subMark.toUpperCase() == "SEMI REMORQUE"
    // 			|| this.vehicule.subMark.toUpperCase() == "SEMI REMORQUE GRUE")) {
    //         this.objectifRotation = 1;
    // 	}

    // } else if (this.distanceDriven <= 230) {
    // 	zone = "Z5";
    // 	this.objectifRotation = 1;
    // } else {
    // 	zone = "HZ";
    // 	this.objectifRotation = 1;
    // }

    if (zone != 'ZH') {
      this.chargeLocation = this.vehicule.rental / this.objectifRotation;
    }
    return zone;
  }
}

export class VehiculeInfoDto {
  idDevice: number;

  idVehicule: number;

  matricule: string;

  mark: string;

  subMark: string;

  capacity: string;

  alias: string;

  pathGrayCard: string;

  emails: string;

  maxSpeed: number;

  odoOffset: number;

  odometre: number;

  lastOdoUpdate: Date;

  icon: string;

  circulationAt: Date;

  driverInfoDto: DriverInfoDto;

  deviceInfoDTO: Device;

  dashbordDto: DashbordInfo;

  constructor() {
    this.driverInfoDto = new DriverInfoDto();
    this.dashbordDto = new DashbordInfo();
    this.deviceInfoDTO = new Device();
  }
}

export class DriverInfoDto {
  idDriver: number;

  code: string;

  firstName: string;

  flastName: string;

  telephone: string;

  cin: string;

  email: string;

  bloodGroup: string;
}

export interface Page<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  size: number;
  numberOfElements: number;
  first: boolean;
  last: boolean;
}

export class SinistreDashbordDto {
  id: number;
  cost: number;
  laborCost: number;
  vehiculeId: number;
  matricule: string;
  totalCost: number;
}

export class RunningDocumentDashbordDto {
  id: number;
  cost: number;
  vehiculeId: number;
  matricule: string;
}

export class MonthlyObjectDto {
  id: number;
  nom: string;
  matricule: string;
  cost: number;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
}

export interface MonthlyObjectDataDto {
  id: number;
  nom: string;
  matricule: string;
  mark: string;
  cost: string;
  jan: number[];
  feb: number[];
  mar: number[];
  apr: number[];
  may: number[];
  jun: number[];
  jul: number[];
  aug: number[];
  sep: number[];
  oct: number[];
  nov: number[];
  dec: number[];
}

export class TheoreticalCost {
  id: number;
  date: Date;
  cost: number;
  vehicule: number;
}

export class FuelingDashboardDto {
  vehiculeId: number;
  cost: number;
  theorecticalCost: number;
  matricule: string;
  groupeId: number;
  groupName: string;
  idDocument: number;
  idDevice: number;
  odometre: number;
  costUnit: number;
  quantity: number;
  consommation: number;
  operationDate: Date;
}

export class FuelingClassification {
  driver: string;
  matricule: string;
  consommation: number;
  quantity: number;
  cost: number;
  driverId: number;
  avatarUri: string;
  mark: number;
}

export class ParentData {
  datestart: Date | number | any;
  dateEnd: Date | number | any;
  groups: string;
  IsByParents: boolean;
  activatechange: boolean;
  constructor() {}
}

export class DeadlinesDto {
  idOp: number;
  currentValue: number;
  deadlineValue: number;
}

export class DeadlinesGroupsDto {
  matricule: string;
  group: string;
  driver: string;
  odo: any;
  assurance: number;
  vignette: number;
  taxecirculation: number;
  visite: number;
  pneu: number;
  oil: number;
  air: number;
  fuel: number;
  engine: number;
  embrayage: number;
  distribution: number;
}

export class DeadlinesNotifDto {
  matricule: string;
  group: string;
  value: number;
  type: string;

  constructor(matricule: string, group: string, value: number, type: string) {
    this.matricule = matricule;
    this.group = group;
    this.type = type;
    this.value = value;
  }
}

// dash-board-expenses-info.dto.ts

export class MaintenanceInfoDTO {
  vehiculeId: number;
  nom: string;
  matricule: string;
  maintenance_type: string;
  mark: string;
  canal: string;
  maintenance: number;
}

export class DashBordExpensesInfoDTO {
  vehiculeId: number;
  insurance: number;
  visit: number;
  roadTaxes: number;
  lavageAndParking: number;
  leasing: number;
  fuel: number;
  maintenance: MaintenanceInfoDTO[];
  tool: number;
  tonnage: number;
  driverCharge: number;
  turnover: number;
  total: number;
  matricule: string;
  mark: string;
  nom: string;
  driver: string;
  transportType: string;
}
