import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityComponent } from './activity.component';
import { FormsModule } from '@angular/forms'; // If you're using forms
import { ReactiveFormsModule } from '@angular/forms'; // If you're using reactive forms
import { ModalModule } from 'ngx-bootstrap/modal'; // If you are using modals
import { DailyKilomtrageChartComponent } from '../daily-kilomtrage-chart/daily-kilomtrage-chart.component';
import { KilomtrageChartComponent } from '../../activity-overview-management';
import { QualityDrivingComponent } from '../quality-driving/quality-driving.component';
import { DailyKilomtrageChartModule } from '../daily-kilomtrage-chart/daily-kilomtrage-chart.module';
import { KilomtrageChartModule } from '../../activity-overview-management/activity-overview/dashbord-chart/kilomtrage-chart/kilomtrage-chart.module';
import { QualityDrivingModule } from '../quality-driving/quality-driving.module';
import { VehiculeAccordingPoiModule } from '../../overview/vehicule-according-poi/vehicule-according-poi.module';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { MonthlyAvailableVehiculesChartModule } from '../../overview/dashbord-chart/monthly-available-vehicules-chart/monthly-available-vehicules-chart.module';
import { CurrentStateModule } from '../../overview/current-state/current-state.module';
import { ActivityTimeChartModule } from '../../activity-overview-management/activity-overview/dashbord-chart/activity-time-chart/activity-time-chart.module';
import { StopChartModule } from '../../activity-overview-management/activity-overview/dashbord-chart/stop-chart/stop-chart.module';
import { ActivityChartModule } from '../../activity-overview-management/activity-overview/dashbord-chart/activity-chart/activity-chart.module';
//import { ChartsModule } from 'ng2-charts'; // Assuming you want to use charts
// Add other necessary imports...

@NgModule({
  declarations: [
    ActivityComponent,
  ],
  imports: [
    CommonModule,
    FormsModule, // Add this if you're using forms in your component
    ReactiveFormsModule, // Add this if you're using reactive forms in your component
    ModalModule.forRoot(),
    DailyKilomtrageChartModule,
    KilomtrageChartModule,
    QualityDrivingModule,
    VehiculeAccordingPoiModule,
    LeafletModule,
    MonthlyAvailableVehiculesChartModule,
    CurrentStateModule,
    ActivityTimeChartModule,
    StopChartModule,
    ActivityChartModule

  ],
  exports: [
    ActivityComponent // Export the component if needed in other modules
  ]
})
export class ActivityModule { }
