import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import Highcharts, { Point } from 'highcharts';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { TravelReportDto } from '../../../../parc-management';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts)

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
  selector: 'app-rotation-type',
  templateUrl: './rotation-type.component.html',
  styleUrls: ['./rotation-type.component.scss']
})
export class RotationTypeComponent implements OnInit {
  TravelReportsData: TravelReportDto[] = [];

  @ViewChild("rotationTypeGraphe", { static: false }) rotationTypeGrapheModal: ModalDirective;
  options: Object | null = null;
  optionsModal: Object | null = null;
  constructor() { }

  ngOnInit() {
    this.rotation_type(this.TravelReportsData);
  }
/*
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.TravelReportsData && !changes.TravelReportsData.firstChange) {
      this.rotation_type();
    }
  } */

  showrotationTypeGraphique() {
    this.rotationTypeGrapheModal.show();
}

transform(value: number, args?: any): any {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);

  let result = '';

  result= hours + "h" + minutes + "min"

  return result;
}

  rotation_type(TravelReportsData: TravelReportDto[]){
    this.TravelReportsData= TravelReportsData;
    let AlltotalObjectifRotation=0,AlltotalAverageRotation=0;
    const subMarkTotalsMap = new Map<string, { totalObjectifRotation: number; totalAverageRotation: number }>();

    // Calculate the total 'objectifRotation' and 'averageRotation' for each 'subMark'
    let matricule: any[] = [];
    TravelReportsData.forEach(item => {
      const subMark = item.vehicule.subMark;

      if (!subMarkTotalsMap.has(subMark)) {
        subMarkTotalsMap.set(subMark, { totalObjectifRotation: 0, totalAverageRotation: 0 });
      }
      if(!matricule.includes(item.vehiculeId)){
        matricule.push(item.vehiculeId)
        subMarkTotalsMap.get(subMark).totalObjectifRotation += item.objectifRotation;
        AlltotalObjectifRotation+=item.objectifRotation | 0;
      }
      subMarkTotalsMap.get(subMark).totalAverageRotation += 1;
      AlltotalAverageRotation+=1
    });
    const chartData = [
      {
        name: 'Réalisé',
        type:"column",
        data: Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => ({
          name: subMark,
          y: totals.totalAverageRotation,
          drilldown: `${subMark}_OA`
        }))
      },
      {
        name: 'Objectif',
        type:"spline",
        data: Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => ({
          name: subMark,
          y: totals.totalObjectifRotation,
          drilldown: `${subMark}_OA`
        }))
      }
    ];

    const drilldown_dataq = Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => {
      const vehicles = this.TravelReportsData
        .filter(item => item.vehicule.subMark === subMark)
        .reduce((acc, item) => {
          const existingVehicle = acc.find(v => v.name === item.vehicule.matricule);

          if (existingVehicle) {
            existingVehicle.y += 1;
            existingVehicle.duree+=item.pathDuration;
            existingVehicle.arret+=item.stopDuration;
            existingVehicle.dureestr=this.transform(existingVehicle.duree);
            existingVehicle.arretstr=this.transform(existingVehicle.arret);
          } else {
            acc.push({
              name: item.vehicule.matricule,
              y: 1,
              average: item.objectifRotation,
              dureestr:this.transform(item.pathDuration),
              arretstr:this.transform(item.stopDuration),
              duree:item.pathDuration,
              arret:item.stopDuration,
              drilldown: `${item.vehicule.matricule}_OAM`
            });
          }

          return acc;
        }, []);

      return {
        id: `${subMark}_OA`,
        type: 'column',
        name: 'Objectif et Réalisé',
        data: vehicles,
        tooltip: {
          headerFormat: '<span style="font-size:11px">Réalisé</span>: <b>{point.y}</b><br>',
          pointFormat: 'Objectif</span>: <b>{point.average}<br/><span style="color:{point.color}">{point.name}</span> <b>{point.ot}</b><br/>{point.missionType}<br/>{point.missionType}<br/><b>Durre: </b>{point.dureestr}<br/><b>Arret: </b>{point.arretstr}<br/>',
          positioner: function (this :any,labelWidth: number, labelHeight: number, point: any) {
            // Adjust the position to display above the column
            return { x: point.plotX + this.chart.plotLeft - labelWidth / 2, y: point.plotY - labelHeight - 10 };
          },
        },

      };
    });

    const secondLevelDrilldown = this.TravelReportsData.reduce((acc, item) => {
      const existingVehicle = acc.find(drilldownItem => drilldownItem.id === `${item.vehicule.matricule}_OAM`);

      if (existingVehicle) {
        // If the vehicle already exists, add data to its array
        existingVehicle.data.push({
          name: item.otNumber,
            y: 1,
            missiontype:item.missionType,
            objectifRotation: item.objectifRotation,
            average: 1,
            dureestr:this.transform(item.pathDuration),
            arretstr:this.transform(item.stopDuration),
            duree:item.pathDuration,
            arret:item.stopDuration
        });
      } else {
        // If the vehicle doesn't exist, create a new entry
        acc.push({
          type:'column',
          id: `${item.vehicule.matricule}_OAM`,
          name: item.vehicule.matricule,
          data: [{
            name: item.otNumber,
            y: 1,
            missiontype:item.missionType,
            objectifRotation: item.objectifRotation,
            average: 1,
            dureestr:this.transform(item.pathDuration),
            arretstr:this.transform(item.stopDuration),
            duree:item.pathDuration,
            arret:item.stopDuration
          }],
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.missiontype}</span> <br/><b>Durre: </b>{point.dureestr}<br/><b>Arret: </b>{point.arretstr}<br/>'
        },
        });
      }

      return acc;
    }, []);

    const drilldown_data_all=drilldown_dataq.concat(secondLevelDrilldown);


    this.setHighCartsLang();
    this.options = {
      chart: {
        zoomType: 'xy',
        height: 260
      },
      title: {
        text: 'Rotation '+AlltotalAverageRotation+' sur '+AlltotalObjectifRotation,
        style:{
          color: '#3c8dbc',
          fontSize: "20px",
        },
        align: 'center'
      },
      xAxis: {
        //categories: ['CANTER', 'SEMI REMORQUE', 'SEMI REMORQUE GRUE','SOLO','SOLO GRUE' ],
        type: 'category',
        title: {
          text: null,
        },
        labels: {
          enabled: true, // Set this to false to hide the xAxis labels
        },
        gridLineWidth: 1,
      lineWidth: 0,
    },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      tooltip: {
        shared: true
    },
      // tooltip: {
      //   valueSuffix: ' millions',
      // },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      legend: {
          enabled: true,
      },
      credits: {
        enabled: false,
      },
      series: chartData,
      drilldown: {
        series: drilldown_data_all
      }
    };

    this.optionsModal = {
      chart: {
        type: 'column',
        height:300
      },
      title: {
        text: 'Rotation '+AlltotalAverageRotation+' sur '+AlltotalObjectifRotation,
        style:{
          color: '#3c8dbc'
        },
        align: 'center'
      },
      xAxis: {
        //categories: ['CANTER', 'SEMI REMORQUE', 'SEMI REMORQUE GRUE','SOLO','SOLO GRUE' ],
        type: 'category',
        title: {
          text: null,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        shadow: true,
      },
      credits: {
        enabled: false,
      },
      series: chartData,
      drilldown: {
        series: drilldown_data_all
      }
    };

    Highcharts.chart('rotationTypeGraphe', this.options);
    Highcharts.chart('rotationTypeGrapheModal', this.optionsModal);

  }

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#e77e22', '#35a9e7', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b',  '#00f194'
    ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

  }

}
