import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SinistreFormComponent } from './sinistre-form.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { MultiTypeService } from '../../../providers/multi-type-service'; // Import the necessary services
import { LossesService } from '../../../providers/losses.service'; // Import the necessary services
import { DriverService } from 'src/app/client-management/data-management'; // Import DriverService

@NgModule({
  declarations: [
    SinistreFormComponent // Declare the SinistreFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(), // Import BsDatepickerModule for date picking
    FormsModule // Import FormsModule to handle form controls
  ],
  providers: [
    MultiTypeService, // Provide MultiTypeService
    LossesService, // Provide LossesService
    DriverService // Provide DriverService
  ],
  exports: [
    SinistreFormComponent // Export SinistreFormComponent for use in other modules
  ]
})
export class SinistreFormModule { }
