import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RealtimeRestService } from './services/realtime-rest.service';
import { RealTimeRecord, StateCounts } from './model/real-time.model';
import { MapService } from '../../utils/leaflet/service/map.service';
import { DataManagementService } from '../data-management/data-management.service';
import { Group, Vehicule, } from '../data-management/data-management.model';
import { ToastrService } from 'ngx-toastr';
import { SigninService } from '../../authentification/signin/signin.service';
import { AlertManagementService } from '../alert-management';
import { DashboardComponent } from '../statistical/dashboard/dashboard.component';
import { HeaderService } from 'src/app/header/header.service';
import { PathConfig } from '../historical';
import { RealtimeService } from './services/realtime.service';
import { RealtimeHelperService } from './services/realtime-helper.service';
import { Subscription } from 'rxjs';
import Timer = NodeJS.Timer;

//apikey

@Component({
  selector: 'app-realtime',
  templateUrl: './realtime.component.html',
  styleUrls: ['./realtime.component.css'],
})
export class RealtimeComponent implements OnInit, OnDestroy {
  loaded$: Subscription;
  records: Record<number, StateCounts>;
  recordsSuscription: Subscription;

  // Declaration vars !!!
  selectedVehicule: Vehicule;
  selectedVehiculeSubs: Subscription;

  liveReloadingRT: Timer;
  searchWord = '';

  groupAutre: Group = new Group();
  pathConfigs: PathConfig[] = [];

  isGroupsExpanded = true;

  selectedGroup: Group = new Group();

  speech: any;
  speechData: any;

  searchedOption: string;
  SubscriptionsearchedOption: Subscription; 




  @ViewChild(DashboardComponent, { static: false }) carDashboard: DashboardComponent;


  constructor(
    private realTimeRestService: RealtimeRestService,
    public mapService: MapService,
    public realTimeService: RealtimeService,
    public toastr: ToastrService,
    public dataManagementService: DataManagementService,
    public alertManagementService: AlertManagementService,
    public signinService: SigninService,
    public realtimeHelperService: RealtimeHelperService,
    public headerService: HeaderService
  ) {
    this.loaded$ = this.mapService.mapLoaded.subscribe((value: boolean) => {
      this.mapService.map.removeLayer(this.mapService.baseMaps['Google Sat']);
      this.mapService.map.addLayer(this.mapService.baseMaps.OpenStreetMap);
    });
    this.realTimeService.textToSpeech();

  }
  ngOnInit() {
    this.realTimeService.getAllRealTimeRecords();
    this.mapService.stopExecution = false;
    this.mapService.initCluster();

    this.init();
    this.selectedVehiculeSubs = this.realTimeService.getVehicule().subscribe(v => {
      this.selectedVehicule = v;
    })
    this.recordsSuscription = this.realTimeService.getStatesCount()
      .subscribe((stats) => {
        this.records = stats;
      });
    this.SubscriptionsearchedOption = this.realTimeService.searchedOptionObs()
      .subscribe((search) => {
        this.searchedOption = search;
      });

    this.realTimeService.stopRealTime();
    // Center the map
    this.realTimeRestService
      .getAllRealTimeRecords()
      .subscribe((realTimeRecords) => {
        this.mapService.centerMap(
          realTimeRecords.filter(
            (rt) => rt.realTimeRecordStatus !== 'TECHNICAL_ISSUE'
          ),
          12
        );
      });
  }

  init() {
    this.loadGroups();
    this.loadPathConfigs();
    this.realTimeService.allRealTimeRecord();
  }


   

  loadPathConfigs() {
    if (this.realTimeRestService.pathConfigs) {
      this.pathConfigs = this.realTimeRestService.pathConfigs;
    } else {
      this.realTimeService.allPathConfigs = this.realTimeRestService
        .getAllPathConfigs()
        .subscribe((pathConfigs) => {
          this.pathConfigs = pathConfigs;
          this.realTimeRestService.pathConfigs = pathConfigs;
        });
    }
  }


  //To display the Dashboard of vehicule
  displayDashboard(vehicule: Vehicule) {
    if (vehicule && vehicule.realTimeRecord && this.carDashboard.opened) {
      this.carDashboard.openDashboard();
      const vehiculeName = this.dataManagementService.getVehiculeName(vehicule);
      if (vehiculeName !== undefined) {
        this.carDashboard.vehiculeName = vehiculeName;
      } else {
        this.carDashboard.vehiculeName = ''; // ou autre logique appropriée
      }
      this.carDashboard.reloadDashboard(vehicule.realTimeRecord);
    } else {
      this.carDashboard.initDashboard();

    }
  }


  // Load static data for the first shoot && associate RT object to group obj
  loadGroups() {
    if (this.realTimeService.groups) {
      this.handleLoadedGroups();
    } else {
      this.handleLoadAllGroupsDetails();
    }

    if (this.dataManagementService.selectedVehicule) {
      this.realTimeService.setVehicule(this.dataManagementService.selectedVehicule);
    }
  }

  handleLoadedGroups() {
    if (this.realTimeService.groups) {
      this.realTimeService.groups.forEach((group) => {
        group.isOpen = false;
        group.vehicules.forEach((vehicule) => {
          vehicule.realTimeRecord = new RealTimeRecord();
        });
      });
    }
  }

  handleLoadAllGroupsDetails() {
    this.realTimeService.allGroups = this.dataManagementService
      .getAllGroupsDetails(this.searchWord)
      .subscribe((groupes) => {
        this.dataManagementService.groups = groupes;
        this.dataManagementService.setGroups(groupes);
        this.realTimeService.setGroupsData(groupes);

        // Vérification que groups n'est pas null avant de procéder
        if (this.realTimeService.groups && this.realTimeService.groups.length > 0) {
          this.selectedGroup = this.realTimeService.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;

          // Vérification de selectedGroup et de ses vehicules
          if (this.selectedGroup && this.selectedGroup.vehicules && this.selectedGroup.vehicules.length > 0) {
            this.realTimeService.setVehicule(this.selectedGroup.vehicules[0]);
            this.dataManagementService.selectedVehicule = this.selectedGroup.vehicules[0];
          }
        }

        this.realTimeService.getAllRealTimeRecords();
        this.loadAccumOdo(
          this.realtimeHelperService.getVehicule(
            this.selectedVehicule?.idDevice,
            this.realTimeService.groups || []
          )
        );

        this.realTimeService.isGroupLoaded = true;
      });
  }

  distance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    let p = 0.017453292519943295; // Math.PI / 180
    let c = Math.cos;
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    let distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }


  loadAccumOdo(vehicule: Vehicule) {
    this.dataManagementService
      .getCurrentOdo(vehicule.idVehicule)
      .subscribe((odo) => {
        vehicule.odo = odo;
      });
  }


  // ----------- un used Functions ----------------


  // getNewRealTimeRecord(idRealTimeRecord: number): RealTimeRecord {
  //   let foundRealTimeRecord = new RealTimeRecord();
  //   for (let i = 0; i < this.realTimeService.newRealTimeRecords.length; i++) {
  //     if (this.realTimeService.newRealTimeRecords[i].idRealTimeRecord == idRealTimeRecord) {
  //       foundRealTimeRecord = this.realTimeService.newRealTimeRecords[i];
  //     }
  //   }
  //   return foundRealTimeRecord;
  // }


  groupsToggle() {
    this.isGroupsExpanded = !this.isGroupsExpanded;
  }

  isHiddenVehicule(group: Group, vehicule: Vehicule) {
    let searchingWord: boolean;
    let creatiria: boolean = true;
    if (this.searchWord) {
      if (
        vehicule.matricule
          .toLowerCase()
          .indexOf(this.searchWord.toLowerCase()) != -1 ||
        (vehicule.alias &&
          vehicule.alias.toLowerCase().indexOf(this.searchWord.toLowerCase()) !=
          -1) ||
        (vehicule.driver &&
          vehicule.driver.firstName &&
          vehicule.driver.firstName
            .toLowerCase()
            .indexOf(this.searchWord.toLowerCase()) != -1) ||
        (vehicule.driver &&
          vehicule.driver.lastName &&
          vehicule.driver.lastName
            .toLowerCase()
            .indexOf(this.searchWord.toLowerCase()) != -1) || (vehicule.device && vehicule.device.idDevice == Number(this.searchWord))

      ) {
        searchingWord = false;
      } else if (
        group.nom.toLowerCase().indexOf(this.searchWord.toLowerCase()) != -1
      ) {
        searchingWord = false;
      } else {
        searchingWord = true;
      }
    } else if (this.searchWord === "") {
      searchingWord = false;
    }

    if (this.records && this.searchedOption !== 'all') {
      const filtredElements = this.records[group.idGroupe]
      const filteredStatusVehicles: RealTimeRecord[] = filtredElements[this.searchedOption];
      creatiria = filteredStatusVehicles.some(filteredVehicle => {
        return vehicule.idDevice === filteredVehicle.idRealTimeRecord;
      });
    } else {
      creatiria = true;
    }

    return creatiria && !searchingWord;
  }

  collapeItem(item: any) {
    item.isOpen = !item.isOpen;
    this.selectedGroup = item;
    document.body.classList.add('sidebar-collapse');
  }


  ngOnDestroy() {
    this.realTimeService.clearRealTime();
    this.realTimeService.stopTimerTracking();
    this.selectedVehiculeSubs.unsubscribe();
    this.recordsSuscription.unsubscribe();
    this.SubscriptionsearchedOption.unsubscribe();
    this.mapService.resetAllMap();
    this.realTimeService.clearTables();

    this.realTimeService.realTimeLoaded = false;

    if (this.realTimeService.allRealTimeRecords) {
      this.realTimeService.allRealTimeRecords.unsubscribe();
    }
    if (this.realTimeService.geocodingSubscription) {
      this.realTimeService.geocodingSubscription.unsubscribe();
    }
    if (this.realTimeService.markerWasAddedSubscription) {
      this.realTimeService.markerWasAddedSubscription.unsubscribe();
    }
    if (this.realTimeService.allGroups) {
      this.realTimeService.allGroups.unsubscribe();
    }
    if (this.realTimeService.allPathConfigs) {
      this.realTimeService.allPathConfigs.unsubscribe();
    }
    this.toastr.clear();
    if (this.loaded$) { this.loaded$.unsubscribe() }
  }

}
