import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertCustomizeComponent } from './alert-customize.component';
// import { BsModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { DataManagementService } from '../../data-management';
import { AlertManagementNewService } from '../alert-management-new.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EmailManagerModule } from '../../alert-management/alert-settings/email-manager/email-manager.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AlertCustomizeComponent // Declare the AlertCustomizeComponent
  ],
  imports: [
    CommonModule,                  // Import necessary Angular modules
    // BsModalModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    MatCheckboxModule,
    EmailManagerModule


  ],
  providers: [
    DataManagementService,          // Provide the DataManagementService
    AlertManagementNewService       // Provide the AlertManagementNewService
  ],
  exports: [
    AlertCustomizeComponent // Export the AlertCustomizeComponent for use in other modules
  ]
})
export class AlertCustomizeModule { }
