import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from '../../../global.config';
import { DateInterval } from '../../../shared/model';
import { createAuthorizationHeader } from '../../../utils/security/headers';
import { Pesage } from './pesages';

@Injectable({
  providedIn: 'root'
})

export class PesageService {

  constructor(private _http: HttpClient) { }

  getPesageInPoiOfExit(dateInterval: DateInterval, transportTypeId : number): Observable<Pesage[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<Pesage[]>(dns + "pesages?timezoneOffset=" +
      (new Date().getTimezoneOffset()) + "&transportTypeId="+transportTypeId, dateInterval, { headers: headers });
  }
}
