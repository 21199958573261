import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { driverChargeComponent } from './driver-charge.component';
import { DataManagementService } from 'src/app/client-management/data-management';
import { ReactiveFormsModule } from '@angular/forms'; // Import if you use reactive forms
import { ToastrModule } from 'ngx-toastr';
import { DriverChargeListModule } from './driver-charge-list/driver-charge-list.module';

@NgModule({
  declarations: [
    driverChargeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule, // Import reactive forms if needed
    ToastrModule.forRoot(),
    DriverChargeListModule
  ],
  providers: [
    DataManagementService // Provide the service here
  ],
  exports: [
    driverChargeComponent // Export the component if it needs to be used outside of this module
  ]
})
export class DriverChargeModule { }
