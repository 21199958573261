import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletComponent } from './leaflet.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal'; // Import for modals
import { PoiClientFormModule } from 'src/app/client-management/geo-marketing/poi-client/poi-client-form/poi-client-form.module';
import { PoiFormModule } from 'src/app/client-management/data-management/poi/poi-form/poi-form.module';
// import { LeafletDrawModule } from 'ngx-leaflet-draw'; // Import for leaflet-draw if using ngx-leaflet-draw

@NgModule({
  declarations: [
    LeafletComponent // Declare the LeafletComponent
  ],
  imports: [
    CommonModule, // Common directives
    FormsModule, // For template-driven forms
    ReactiveFormsModule, // For reactive forms
    ModalModule.forRoot(),
    PoiClientFormModule,
    PoiFormModule
  ],
  exports: [
    LeafletComponent // Export the LeafletComponent for use in other modules
  ]
})
export class LeafletModule { }
