<div class="row" style="padding-top: 15px;">
  <!-- <div class="col-md-4"> -->
      <form #groupForm="ngForm" class="form-horizontal form-class">
          <div style="flex: 2;display: flex;gap: 5px;">
              <label class="control-label">Groupes</label>
              <div class="col-lg-8 form-group input-container" *ngIf="dataAvailable" >
                <div style="width: 78%;" class="drop">
                    <!-- <ng-multiselect-dropdown-angular7
                      name="groupSelected"
                      [data]="groups"
                      placeholder="aucun groupe selectionné"
                      [(ngModel)]="groupIds"
                      [settings]="groupDropdownSettings"
                      (onSelect)="onItemSelect($event)"
                      required>
                    </ng-multiselect-dropdown-angular7> -->

                    <ng-multiselect-dropdown
                    [placeholder]="'personnalisé'"
                    [settings]="groupDropdownSettings"
                    [data]="groups"
                    [(ngModel)]="groupIds"
                    (onSelect)="onItemSelect($event)"
                  >
                  </ng-multiselect-dropdown>

                </div>
              </div>

          </div>
          <!--  -->
          <div class="input-section">
              <label class="control-label">Du</label>
              <div class="input-container">
                  <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placement="bottom"
                      required (ngModelChange)="onChangeStartDate()">
              </div>
          </div>
          <div class="input-section">
              <label class="control-label">Au</label>
              <div class="input-container">
                  <input type="text" class="form-control input-sm" bsDatepicker name="endDate"
                  [(ngModel)]="endDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placement="bottom"
                      required>
              </div>
          </div>
          <!--  -->
          <div  style="width: 50px;">
              <button type="button" (click)="getDailyKm()"  class="btn btn-success " title="Valider" style="margin-top: 5px;padding: 6px;">
                  <i class="fa fa-search pull-left" aria-hidden="true"></i>
              </button>
          </div>
      </form>
  <!-- </div> -->
  <!-- <div class="col-md-6">
      <div *ngIf="updateTrackingTimer" class="tracking ">
          <i class="fa fa-hourglass-half" aria-hidden="true"></i>
          Mise à jour dans {{updateTrackingTimer}} seconds...
      </div>
  </div> -->
</div>
<div class="row" >
  <div class="col-md-12">
      <div *ngIf="updateTrackingTimer" class="tracking " style="margin-top: -5px;">
          <i class="fa fa-hourglass-half" aria-hidden="true"></i>
          Mise à jour dans {{updateTrackingTimer}} seconds...
      </div>
  </div>
</div>


<ngx-loading [show]="dailyKmLoading"></ngx-loading>
<div id="dailyKmChart" class="w-100 d-inline-block"></div>
