import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {createAuthorizationHeader} from 'src/app/utils/security/headers';
import {dns} from 'src/app/global.config';
import {GroupDashbordByDate} from 'src/app/client-management/parc-management';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class VidangeChartService {

  constructor(private _http: HttpClient) { }

  public getDashbordInfoByType( typechart : string,
    groupIds: string,
     isByParent : boolean
      ): Observable<GroupDashbordByDate[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .get<GroupDashbordByDate[]>(dns + 'groupes/getOilChangeOrFuelingBygroup?type='+typechart+'&IsByParent='+isByParent+ '&groupIds=' + groupIds, options);
  }

}
