import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Mission, TrackingMission} from '../data-management.model';
import {Observable} from 'rxjs';
import {createAuthorizationHeader} from 'src/app/utils/security/headers';
import {dns} from 'src/app/global.config';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MissionService {

    selectedMission: Mission | null= null;

    constructor(private http: HttpClient) { }

    getMissions(): Observable<Mission[]> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.get<Mission[]>(dns + "missions", options);
    }


    add(payload: Mission): Observable<Mission> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.post<Mission>(dns + "missions", payload, options);
    }

    update(payload: Mission): Observable<Mission> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.put<Mission>(dns + "missions", payload, options);
    }

    tracking(missionId: number, vehicleId: number): Observable<TrackingMission> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.get<TrackingMission>(dns + "missions/" + missionId + "/tracking/" + vehicleId, options);
    }

    delete(missionId: number): Observable<boolean> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.delete<boolean>(dns + "missions/" + missionId, options);
    }

}
