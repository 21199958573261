import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.css']
})
export class ClientManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.className = "hold-transition skin-blue sidebar-mini sidebar-collapse fixed";
    document.body.style.background = "none";
    document.body.style.backgroundSize = "cover";
  }

}
