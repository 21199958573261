import { Component, OnInit } from '@angular/core';
import { DateInterval } from 'src/app/shared/model';
import { ToastrService } from 'ngx-toastr';
import { KilomtrageChartService } from './kilomtrage-chart.service';
import { TravelDashbordDto } from 'src/app/client-management/parc-management';
import * as Highcharts5 from 'highcharts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { interval, Subscription } from 'rxjs';
require('highcharts/modules/exporting')(Highcharts5);
require('highcharts/modules/no-data-to-display')(Highcharts5);

if (!Highcharts5.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts5);
}

@Component({
  selector: 'app-kilomtrage-chart',
  templateUrl: './kilomtrage-chart.component.html',
  styleUrls: ['./kilomtrage-chart.component.css']
})
export class KilomtrageChartComponent implements OnInit {

  groupeStatistics: any[] = [];
  travelDashbords: TravelDashbordDto[] = [];

  Distance: Object | null = null;
  date: DateInterval = new DateInterval();
  endDate: Date | null = new Date();
  startDate: Date = new Date();

  globalLoading: boolean = false;
  /** UPDATE TRACKING TIMER */
  updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;

  constructor(private kilometrageChartService: KilomtrageChartService, public toastr: ToastrService, private localeService: BsLocaleService) {
  }

  ngOnInit() {
    this.localeService.use('fr');
    this.endDate.setDate(new Date().getDate());
    this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate());
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);

    this.getKmInfo();
  }

  getKmInfo() {
    this.globalLoading = true;

    this.date.startDate = this.startDate.getTime();
    this.date.endDate = this.endDate.getTime();
    this.kilometrageChartService.getKmInfo(this.date).subscribe(res => {
      this.groupeStatistics = res as TravelDashbordDto[];
      this.KmChart();
      this.startUpdateTrackingTimer();
      this.globalLoading = false;
    })
  }


  KmChart() {
    this.setHighCartsLang();
    var that = this;
    var drilldata: any[] = [];
    var data: { name: string, y: number, drilldown: string }[] = [];
    var groupesName = new Set(this.groupeStatistics.map(s => {
      return s;
    })
    );
    groupesName.forEach(groupeName => {
      data.push({ name: groupeName.groupeName, y: groupeName.distanceDriven, drilldown: groupeName.groupeId });
    })
    this.Distance = {
      chart: {
        type: 'column',
        events: {
          drilldown: function (e: { seriesOptions: any; point: { drilldown: number; name: any; }; }) {
            if (!e.seriesOptions) {
              var vehiculesdata: any[] = [];
              that.kilometrageChartService.getKmBygroupe(e.point.drilldown, that.date).subscribe(res => {
                for (let i = 0; i < res.length; i++) {
                  vehiculesdata.push([res[i].vehiculeMatricule + "  <br /> ", res[i].distanceDriven]);
                  drilldata.push({ name: e.point.name, id: e.point.drilldown, data: vehiculesdata });
                }
              });
            }
          }
        }
      },
      title: {
        text: 'KM par groupe',
        style: {
          fontWeight: 'bold'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',

        labels: {
          rotation: -45,
          style: {
            color: "#000000",
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: 'Σ.KM'
        },
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          borderRadius: 5
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:.2f} '
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name} </span> <br><b>{point.y:.2f} KM</b> <br/>'
      },
      series: [
        {
          name: "Groupe",
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.y:.2f}'
          }, tooltip: {
            pointFormat: '<span style="color:{point.color}">{point.name} </span> <br><b>{point.y:.2f} KM</b> <br/>'
          },
          data: data
        }
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold'
        },
        series: drilldata
      }
    }
    Highcharts5.chart('distance', this.Distance);
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts5.setOptions({
      colors: ['#0d233a', '#8bbc21', '#a6c96a', '#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
        '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', '#007bff', '#66B92E', '#DA932C', '#D65B4A', '#24CBE5', '#64E572', '#FF9655', '#6AF9C4',
        '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#2f7ed8', '#f39c12', '#D81B60', '#605ca8', '#3D9970', '#001F3F', '#00c0ef', ' #451e3e', '#adcbe3', '#f37736', '#854442', '#3d1e6d', '#c68642'],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

  }

  /** STOP TRACKING */
  stopTracking() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = null;
  }

  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.endDate < this.startDate) {
      this.endDate = null;
    }
    this.stopTracking();
  }

  ngOnDestroy() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
  }
  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 900;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        this.getKmInfo();
      }
    })
  }
}
