import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DateInterval } from 'src/app/shared/model';
import {
  DriverChargeService,
  MonthlyObjectDto,
  MonthlyObjectDataDto,
} from '../../parc-management';
import { imagesCarsDir } from 'src/app/global.config';
import { CanalService } from '../canal.service';
import { CanalInfoDto } from '../canals';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-suivi-canal',
  templateUrl: './suivi-canal.component.html',
  styleUrls: ['./suivi-canal.component.css'],
})
export class SuiviCanalComponent implements OnInit {
  /**show loader element */
  loader = false;
  loading = false;
  currentMonth: number;

  /** graph option */
  options: any;
  monthlyChartOption: any;

  selectedCanal: CanalInfoDto = new CanalInfoDto();
  monthlyDriverCharge: any = null;
  monthlyCharges: any = null;
  monthlyMaintenanceCosts: any = null;
  monthlySinisterCounts: any = null;
  monthlySinisterCost: any = null;
  dateInterval: DateInterval = new DateInterval();
  selectedType = 'vue';
  selectedYear: number;

  imagesCarsDir: string = imagesCarsDir;
  totalExpense = 0;
  vehicules: MonthlyObjectDto[] = [];
  groupes: MonthlyObjectDto[] = [];
  canals: MonthlyObjectDataDto[] = [];

  years = [
    { id: new Date().getFullYear(), name: 'Année En cours' },
    { id: new Date().getFullYear() - 1, name: 'Année Antérieure' },
    { id: new Date().getFullYear() - 2, name: 'Année -2' },
  ];

  months = [
    { id: 'jan', name: 'Jan', nbVehicules: 0 },
    { id: 'feb', name: 'Fév', nbVehicules: 0 },
    { id: 'mar', name: 'Mar', nbVehicules: 0 },
    { id: 'apr', name: 'Apr', nbVehicules: 0 },
    { id: 'may', name: 'Mai', nbVehicules: 0 },
    { id: 'jun', name: 'Jui', nbVehicules: 0 },
    { id: 'jul', name: 'Juil', nbVehicules: 0 },
    { id: 'aug', name: 'Aôut', nbVehicules: 0 },
    { id: 'sep', name: 'Sept', nbVehicules: 0 },
    { id: 'oct', name: 'Oct', nbVehicules: 0 },
    { id: 'nov', name: 'Nov', nbVehicules: 0 },
    { id: 'dec', name: 'Déc', nbVehicules: 0 },
  ];

  startDate = new Date();

  parc: CanalInfoDto = new CanalInfoDto();

  constructor(
    public canalService: CanalService,
    private driverChargeService: DriverChargeService,
    public toastr: ToastrService
  ) {
    this.parc.idCanal = -1;
    this.parc.name = 'Parc';
    /** SET DATE INTERVAL **/

    this.startDate.setMonth(0);
    this.startDate.setDate(1);
    this.startDate = this.removeTime(this.startDate);

    this.dateInterval.startDate = this.startDate;
    this.dateInterval.endDate = new Date();
  }

  initialMonth() {
    this.months = [
      { id: 'jan', name: 'Jan', nbVehicules: 0 },
      { id: 'feb', name: 'Fév', nbVehicules: 0 },
      { id: 'mar', name: 'Mar', nbVehicules: 0 },
      { id: 'apr', name: 'Apr', nbVehicules: 0 },
      { id: 'may', name: 'Mai', nbVehicules: 0 },
      { id: 'jun', name: 'Jui', nbVehicules: 0 },
      { id: 'jul', name: 'Juil', nbVehicules: 0 },
      { id: 'aug', name: 'Aôut', nbVehicules: 0 },
      { id: 'sep', name: 'Sept', nbVehicules: 0 },
      { id: 'oct', name: 'Oct', nbVehicules: 0 },
      { id: 'nov', name: 'Nov', nbVehicules: 0 },
      { id: 'dec', name: 'Déc', nbVehicules: 0 },
    ];
  }

  formatNumberToK(value: number): string {
    const suffixes = ['', 'k', 'M', 'B', 'T']; // You can extend this array for larger numbers if needed
    let suffixIndex = 0;

    while (value >= 1000 && suffixIndex < suffixes.length - 1) {
      value /= 1000;
      suffixIndex++;
    }

    return value.toFixed(3).replace(/\.?0+$/, '') + ' ' + suffixes[suffixIndex];
  }

  ngOnInit() {
    this.selectedYear = this.years[0].id;
    //this.getVehiculeCounts();
    this.loadCanals();
  }

  getVehiculeCountsByCanal() {
    this.canalService.findAllByCanalGroupByYear(this.selectedYear).subscribe(
      (count) => {
        this.canals = count;
        this.getGroupeVehiculeByYear();
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        this.toastr.error('Erreur lors de chargements', 'Véhicule');
      }
    );
  }

  getGroupeVehiculeByYear() {
    this.canalService
      .findAllByGroupeVehiculeByYear(this.selectedYear)
      .subscribe(
        (count) => {
          this.groupes = count;
          for (let month of this.months) {
            month.nbVehicules = this.getNumberOfVehiculesByCanalByGroup(
              month.id
            );
          }
          this.monthlyConfigHighcharts();
          this.loader = false;
        },
        (err) => {
          this.loader = false;
          this.toastr.error('Erreur lors de chargements', 'Véhicule');
        }
      );
  }

  getNumberOfVehiculesByCanalByGroup(month: any) {
    // if (this.selectedType == 'sinistre')
    //   return 1;
    var count = 0;
    if (this.selectedCanal.idCanal == -1) {
      for (let d of this.groupes) {
        if (d[month]) {
          count += d[month];
        }
      }
    } else {
      let data = this.canals.find((v) => v.id == this.selectedCanal.idCanal);
      if (data != null) {
        let groupsMonth = data[month];
        for (let group of groupsMonth) {
          for (let d of this.groupes.filter((v) => v.id == group)) {
            if (d[month]) {
              count += d[month];
            }
          }
        }
      }
    }
    return count;
  }

  loadCanals() {
    this.loader = true;
    if (this.canalService.canals.length <= 0) {
      this.canalService.findAllCanals().subscribe(
        (canals) => {
          this.canalService.canals = canals;
          if (canals.length > 0) {
            this.selectedCanal = canals[0];
          }
          this.getVehiculeCountsByCanal();
          this.loader = false;
          this.loadTurnover();
        },
        (err) => {
          this.loader = false;
          this.toastr.error('Erreur lors de chargements', 'Type de canal');
        }
      );
    } else {
      this.loader = false;
      this.selectedCanal = this.canalService.canals[0];
      this.getVehiculeCountsByCanal();
      this.loadTurnover();
    }
  }

  getVehiculeCounts() {
    this.canalService.findAllByYear(this.selectedYear).subscribe(
      (count) => {
        this.vehicules = count;
        for (let month of this.months) {
          month.nbVehicules = this.getNumberOfVehicules(month.id);
        }
        this.monthlyConfigHighcharts();
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        this.toastr.error('Erreur lors de chargements', 'Véhicule');
      }
    );
  }

  onYearChange() {
    this.startDate.setFullYear(this.selectedYear);
    this.startDate.setMonth(0);
    this.startDate.setDate(1);
    this.startDate = this.removeTime(this.startDate);

    this.dateInterval.startDate = this.startDate;
    var endDate = new Date();
    if (this.selectedYear != new Date().getFullYear()) {
      endDate.setFullYear(this.selectedYear, 11, 31);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      endDate.setMilliseconds(0);
    }

    //this.getVehiculeCounts();
    this.getVehiculeCountsByCanal();
    this.dateInterval.endDate = endDate;
  }

  onCanalChange() {
    // for(let month of this.months){
    //   month.nbVehicules = this.getNumberOfVehicules(month.id);
    // }
    this.initialMonth();
    for (let month of this.months) {
      month.nbVehicules = this.getNumberOfVehiculesByCanalByGroup(month.id);
    }
    this.monthlyConfigHighcharts();
  }

  loadTurnover() {
    this.loading = true;

    this.driverChargeService
      .getMonthlyDriverChargeCostByCanalId(
        this.dateInterval,
        this.selectedCanal.idCanal
      )
      .subscribe(
        (monthlyDriverCharge) => {
          this.monthlyDriverCharge = monthlyDriverCharge;
          this.monthlyConfigHighcharts();
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', "Chiffre d'affaire");
        }
      );
  }

  loadDataBySelectedType() {
    if (this.selectedType == 'vue') {
      this.loadTurnover();
    } else if (this.selectedType == 'charges') {
      this.loadCharges();
    } else if (this.selectedType == 'maintenance') {
      this.loadMaintenanceCost();
    } else {
      this.loadSinistre();
    }
  }

  onTypeChange() {
    this.monthlyCharges = null;
    this.monthlyDriverCharge = null;
    this.monthlyMaintenanceCosts = null;
    this.monthlySinisterCounts = null;
    this.monthlySinisterCost = null;
    this.totalExpense = null;
    // for(let month of this.months){
    //   month.nbVehicules = this.getNumberOfVehicules(month.id);
    // }
    for (let month of this.months) {
      month.nbVehicules = this.getNumberOfVehiculesByCanalByGroup(month.id);
    }
    if (this.selectedType == 'charges') {
      this.configHighcharts();
    }
    this.monthlyConfigHighcharts();
  }

  loadCharges() {
    this.loading = true;
    this.canalService
      .getMonthlyChargesByCanal(this.selectedCanal.idCanal, this.dateInterval)
      .subscribe(
        (res) => {
          this.monthlyCharges = res;
          this.configHighcharts();
          this.monthlyConfigHighcharts();
          this.calculateTotalCharge();
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Charges');
        }
      );
  }

  calculateTotalCharge() {
    this.totalExpense = 0;
    if (this.monthlyCharges.INSURANCE)
      this.totalExpense = this.monthlyCharges.INSURANCE.total;
    if (this.monthlyCharges.TOLL)
      this.totalExpense += this.monthlyCharges.TOLL.total;
    if (this.monthlyCharges.LEASING)
      this.totalExpense += this.monthlyCharges.LEASING.total;
    if (this.monthlyCharges.LAVAGE)
      this.totalExpense += this.monthlyCharges.LAVAGE.total;
    if (this.monthlyCharges.PARCKING)
      this.totalExpense += this.monthlyCharges.PARCKING.total;
    if (this.monthlyCharges.FUELING)
      this.totalExpense += this.monthlyCharges.FUELING.total;
    if (this.monthlyCharges.MAINTENANCE)
      this.totalExpense += this.monthlyCharges.MAINTENANCE.total;
  }

  loadMaintenanceCost() {
    this.loading = true;
    this.canalService
      .getMonthlyMaintenanceCostByCanalId(
        this.selectedCanal.idCanal,
        this.dateInterval
      )
      .subscribe(
        (res) => {
          this.monthlyMaintenanceCosts = res;
          this.monthlyConfigHighcharts();
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error('Erreur lors de chargements', 'Maintenance');
        }
      );
  }

  loadSinistre() {
    this.loading = true;

    forkJoin([
      this.canalService.getMonthlySinisterCountsByCanalId(
        this.selectedCanal.idCanal,
        this.dateInterval
      ),
      this.canalService.getMonthlySinisterCostByCanalId(
        this.selectedCanal.idCanal,
        this.dateInterval
      ),
    ]).subscribe(
      ([count, cost]) => {
        this.monthlySinisterCounts = count;
        this.monthlySinisterCost = cost;
        this.monthlyConfigHighcharts();
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.toastr.error('Erreur lors de chargements', 'Sinistre');
      }
    );
  }

  getTotalCostByMonth(month: string): number {
    var total = 0;

    if (this.selectedType == 'maintenance') {
      if (this.monthlyMaintenanceCosts) {
        total = this.monthlyMaintenanceCosts[month];
      }
      return total;
    } else if (this.selectedType == 'vue') {
      if (this.monthlyDriverCharge) {
        total = this.monthlyDriverCharge[month];
      }
      return total;
    } else if (this.selectedType == 'sinistre') {
      if (this.monthlySinisterCounts) {
        total = this.monthlySinisterCounts[month];
      }
      return total;
    }

    if (!this.monthlyCharges) return total;

    if (this.monthlyCharges.INSURANCE) {
      total = this.monthlyCharges.INSURANCE[month];
    }

    if (this.monthlyCharges.FUELING) {
      total += this.monthlyCharges.FUELING[month];
    }
    if (this.monthlyCharges.TOLL) {
      total += this.monthlyCharges.TOLL[month];
    }
    if (this.monthlyCharges.LEASING) {
      total += this.monthlyCharges.LEASING[month];
    }

    if (this.monthlyCharges.PARCKING) {
      total += this.monthlyCharges.PARCKING[month];
    }
    if (this.monthlyCharges.LAVAGE) {
      total += this.monthlyCharges.LAVAGE[month];
    }
    if (this.monthlyCharges.MAINTENANCE) {
      total += this.monthlyCharges.MAINTENANCE[month];
    }
    if (this.monthlyCharges.SINISTRE) {
      total += this.monthlyCharges.SINISTRE[month];
    }

    return total;
  }

  getNumberOfVehicules(month: string): number {
    // if (this.selectedType == 'sinistre')
    //   return 1;
    var count = 0;
    if (this.selectedCanal.idCanal == -1) {
      for (let data of this.vehicules) {
        if (data[month]) {
          count += data[month];
        }
      }
    } else {
      for (let group of this.selectedCanal.groups) {
        for (let data of this.vehicules.filter((v) => v.id == group.idGroupe)) {
          if (data[month]) {
            count += data[month];
          }
        }
      }
    }
    return count;
  }

  monthlyConfigHighcharts() {
    var seriesData = [];
    var chartTitle = 'Charges Mensuelles';
    var yAxisTitle = 'Charge (DH)';
    var yaxistnb = 'Charge';
    var unite = '(DH)';

    var seriesName = 'Charges/véhicule';
    if (this.selectedType == 'sinistre') {
      seriesName = 'Sinistre';
      chartTitle = 'Nbr sinistres Mensuel';
      yAxisTitle = 'Sinistre (%)';
      yaxistnb = 'Nb.Sinistre';
      unite = '%';
    } else if (this.selectedType == 'maintenance') {
      seriesName = 'Moyenne/véhicule';
      chartTitle = 'Coût de maintenance Mensuelle';
      yAxisTitle = 'Maintenance (DH)';
      yaxistnb = 'maintenance';
      unite = '(DH)';
    } else if (this.selectedType == 'vue') {
      seriesName = 'CA/véhicule';
      chartTitle = 'CA Mensuel';
      yAxisTitle = 'CA (DH)';
      yaxistnb = 'CA';
      unite = '(DH)';
    }
    var nbVehiculesdata = [];
    var totalCostData = [];
    var NbCostData = [];
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth() + 1;
    let nb = 1;
    for (let month of this.months) {
      if (nb > this.currentMonth) break;
      //month.nbVehicules = month.nbVehicules > 0 ? month.nbVehicules : this.getNumberOfVehicules(month.id);
      nbVehiculesdata.push(month.nbVehicules);
      let cost = this.getTotalCostByMonth(month.id);
      let totalCost = 0;
      if (this.selectedType != 'sinistre') {
        totalCost =
          month.nbVehicules != 0
            ? Math.round(cost / month.nbVehicules)
            : Math.round(cost);
      } else {
        totalCost = (cost / month.nbVehicules) * 100;
      }
      if (totalCost % 1 !== 0) {
        totalCost = parseFloat(totalCost.toFixed(4));
      }
      totalCostData.push(totalCost);
      if (cost % 1 !== 0) {
        cost = parseFloat(cost.toFixed(2));
      }
      NbCostData.push(cost);
      nb++;
    }
    seriesData.push({
      name: seriesName,
      data: totalCostData,
      yAxis: 1,
      color: '#ff0909',
    });

    seriesData.unshift({
      name: yaxistnb,
      data: NbCostData,
      yAxis: 0,
      type: 'column',
      color: '#e77e22',
    });

    seriesData.unshift({
      name: 'Nbr véhicule',
      data: nbVehiculesdata,
      yAxis: 2,
      type: 'column',
      color: '#7cb5ec',
    });

    this.monthlyChartOption = {
      // chart: {
      //   type: 'line',
      // },
      title: {
        text: chartTitle,
      },
      xAxis: {
        categories: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
      },
      yAxis: [
        {
          title: {
            text: yaxistnb,
            style: {
              color: '#e77e22', // Apply color to the first y-axis title
            },
          },
        },

        {
          title: {
            text: yAxisTitle,
            style: {
              color: '#ff0909', // Apply color to the first y-axis title
            },
          },
        },

        {
          title: {
            text: 'Nbr véhicule',
            style: {
              color: '#7cb5ec', // Apply color to the first y-axis title
            },
          },
          opposite: true,
        },
      ],
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function (this :any) {
          return this.points.reduce(function (s: any, point: any) {
            let un = '';
            if (point.color == '#ff0909') un = unite;
            return s + '<br/>' + point.series.name + ': ' + point.y + un;
          }, '<b>' + this.x + '</b>');
        },
        shared: true,
      },


      series: seriesData,
    };
  }

  configHighcharts() {
    var data = [];
    if (this.monthlyCharges) {
      if (this.monthlyCharges.INSURANCE) {
        data.push(['Assurances', this.monthlyCharges.INSURANCE.total]);
      }

      if (this.monthlyCharges.FUELING) {
        data.push(['Gasoil', this.monthlyCharges.FUELING.total]);
      }
      if (this.monthlyCharges.TOLL) {
        data.push(['Péage', this.monthlyCharges.TOLL.total]);
      }

      if (this.monthlyCharges.MAINTENANCE) {
        data.push(['Maintenance', this.monthlyCharges.MAINTENANCE.total]);
      }

      if (this.monthlyCharges.SINISTRE) {
        data.push(['Sinistre', this.monthlyCharges.SINISTRE.total]);
      }

      if (this.monthlyCharges.LEASING) {
        data.push(['Leasing', this.monthlyCharges.LEASING.total]);
      }

      if (this.monthlyCharges.PARCKING || this.monthlyCharges.LAVAGE) {
        let diverCost = 0;
        if (this.monthlyCharges.PARCKING) {
          diverCost = this.monthlyCharges.PARCKING.total;
        }
        if (this.monthlyCharges.LAVAGE) {
          diverCost += this.monthlyCharges.LAVAGE.total;
        }
        data.push(['Divers', diverCost]);
      }
    }

    this.options = {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: 'Charges/Flotte',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
        },
      },
      series: [
        {
          type: 'pie',
          name: 'percentage',
          data: data,
        },
      ],
    };
  }

  /*** GET DATE ONLY */
  removeTime(date: Date): Date {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }
}
