import { Component, OnInit } from '@angular/core';
import { ImportExportService } from '../../import-export';
import { saveAs as importedSaveAs } from 'file-saver';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { RapportDto } from '../../data-management';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CanalService } from '../canal.service';

@Component({
  selector: 'app-canal-export',
  templateUrl: './canal-export.component.html',
  styleUrls: ['./canal-export.component.css']
})
export class CanalExportComponent implements OnInit {

  loader = false;

  public ANIMATION_TYPES = ngxLoadingAnimationTypes;

  loading: boolean;

  repport: RapportDto = new RapportDto();

  /**option */
  Selectedoption: string = 'ALL_VEHICULES';

  /**options */
  selectReportOf = [
    { name: 'BY_GROUPS', label: 'Types de canal' },
    { name: 'ALL_VEHICULES', label: 'Tous les types' }
  ];

  canalDownSettings = {};
  groupDropdownSettings :any;
  selectedCanals: any[] = [];

  constructor(private toastr: ToastrService,
    public exportImport: ImportExportService,
    private localeService: BsLocaleService,
    public canalService: CanalService) {
    this.loader = this.exportImport.loader;
  }

  ngOnInit() {
    this.localeService.use('fr');

    this.canalDownSettings = {
      singleSelection: false,
      idField: 'idCanal',
      textField: 'name',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 15,
      allowSearchFilter: true
    };
    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idCanal',
      textField: 'name',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };

    var toDay = new Date();
    this.repport.dateInterval.startDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 0, 0, 0, 0);
    this.repport.dateInterval.endDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59, 59);
    this.repport.dateInterval.startDate.setTime(this.repport.dateInterval.startDate.getTime())
    this.init();
  }

  init() {
    this.repport.rapportPayloadDto.allVehicules = true;
    this.repport.rapportPayloadDto.byGroups = false;
    this.repport.rapportPayloadDto.byVehicules = false;
    this.repport.type = 'PARC_BY_CANAL';
    this.Selectedoption = 'ALL_VEHICULES';

    this.loadCanals();

  }

  loadCanals() {
    if (this.canalService.canals.length <= 0) {
      this.loader = true;
      this.canalService.findAllCanals().subscribe(canals => {
        this.canalService.canals = canals;
        this.loader = false;
      }, err => {
        this.loader = false;
        this.toastr.error("Erreur lors de chargements", "Type de canal");
      })
    }
  }

  /**on switching between report of all vehicule and by group */
  onSelectingReportOf() {
    /**give ability to downoald */
    switch (this.Selectedoption) {
      case 'ALL_VEHICULES':
        this.repport.rapportPayloadDto.allVehicules = true;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = false;
        this.selectedCanals = [];
        break;
      case 'BY_GROUPS':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = true;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      default:
        this.repport.rapportPayloadDto.allVehicules = true;
    }
  }

  export() {
    this.loader = true;
    this.repport.rapportPayloadDto.idGroups = this.selectedCanals.map(c => { return c.idCanal });
    this.exportImport.getRepport(this.repport).subscribe(
      blob => {
        if (blob.size > 0) {
          importedSaveAs(blob, 'Dépense par type de canal.xlsx');
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        }

        this.loader = false;
      },
      error => {
        this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        this.loader = false;
      }
    );
  }
}
