<div class="row document-circulation">

    <!-- <div class="col-lg-12 ">
        <div class="box-header page-header with-border">
            <comment=================================== TITLE (START)====================================>
            <h3 class="box-title"
                style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc; margin-left: 10px;"><i
                    class="fa fa-list"></i>

                <span style="text-decoration: underline">

                    <span>Liste des dates expirations du véhicule :</span>
                    <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher la véhicule"
                        [routerLink]="['/client/dataManagement/vehicule/form', selectedVehicule.idVehicule, 'SHOW']">{{selectedVehicule.matricule}}</a>

                    <span *ngIf="selectedVehicule.driver"> - conducteur :
                        <a style="color: black; text-decoration-color: #3c8dbc;" title="Afficher le conducteur"
                            [routerLink]="['/client/dataManagement/driver/form', selectedVehicule.driver.idDriver, 'SHOW']">{{selectedVehicule?.driver?.firstName}}
                            {{selectedVehicule?.driver?.lastName}}</a>
                    </span>

                </span>


                <span *ngIf="!selectedVehicule.driver" data-toggle="tooltip" class="badge label-warning">
                    <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                </span>

            </h3>
            <comment =================================== TITLE (END)====================================>
        </div>
    </div> -->

    <form class="form-horizontal justify-content-center" role="form">
        <div class="box-body">
            <div class="col-lg-1"></div>
            <div class="col-lg-9" style="margin-top: 10px;">

                <!-- Assurance -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6 ">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'INSURANCE';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat ">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>Gestion assurances
                        </button>
                    </div>

                    <!-- Experation date -->
                    <div class="col-sm-6 " style="text-align: center">

                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <!-- Response case !-->
                        <span *ngIf="!dateExpirationLoading && datesExpiration.insurance == null"
                            class="badge badge-labeled">Veuillez ajouter des assurances</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.insurance != null"
                            class="badge badge-labeled">{{datesExpiration.insurance | date: 'dd-MM-yyyy'}}</span>
                    </div>

                </div>

                <!-- Visite technique -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'VISIT';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>Gestion visites
                        </button>
                    </div>

                    <!-- Experation date -->
                    <div class="col-sm-6" style="text-align: center">
                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <!-- Response case !-->
                        <span *ngIf="!dateExpirationLoading && datesExpiration.visit == null"
                            class="badge badge-labeled">Veuillez ajouter des visites techniques</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.visit != null"
                            class="badge badge-labeled">{{datesExpiration.visit | date: 'dd-MM-yyyy'}}</span>
                    </div>

                </div>

                <!-- Vignettes -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'ROAD_TAXES';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>Gestion vignettes
                        </button>
                    </div>

                    <!-- Experation date -->
                    <div class="col-sm-6" style="text-align: center">

                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <!-- Response case !-->
                        <span *ngIf="!dateExpirationLoading && datesExpiration.taxe == null"
                            class="badge badge-labeled">Veuillez ajouter des vignettes</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.taxe != null"
                            class="badge badge-labeled">{{datesExpiration.taxe | date: 'dd-MM-yyyy'}}</span>
                    </div>

                </div>

                <!-- Certification Exploitation -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'OPERATIONAL_CERTIFICATION';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>Gestion c.exploitation
                        </button>
                    </div>

                    <!-- Experation date -->
                    <div class="col-sm-6" style="text-align: center">
                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <span *ngIf="!dateExpirationLoading && datesExpiration.certification == null"
                            class="badge badge-labeled">Veuillez ajouter des c.exploitations</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.certification != null"
                            class="badge badge-labeled">{{datesExpiration.certification | date: 'dd-MM-yyyy'}}</span>
                    </div>

                </div>


                <!-- Certification Exploitation -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'PERMIT_CIRCULATION';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>Gestion permis circulation
                        </button>
                    </div>
                    <!-- Experation date -->
                    <div class="col-sm-6" style="text-align: center">

                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <!-- Response case !-->
                        <span *ngIf="!dateExpirationLoading && datesExpiration.permit == null"
                            class="badge badge-labeled">Veuillez ajouter des permis circulations</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.permit != null"
                            class="badge badge-labeled">{{datesExpiration.permit | date: 'dd-MM-yyyy'}}</span>
                    </div>

                </div>

                <!-- Certification Exploitation -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'METOLOGICA_NOTBOOK';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>Gestion carnet métrologique
                        </button>
                    </div>

                    <!-- Experation date -->
                    <div class="col-sm-6" style="text-align: center">

                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <!-- Response case !-->
                        <span *ngIf="!dateExpirationLoading && datesExpiration.metologicalNotebook == null"
                            class="badge badge-labeled">Veuillez ajouter des carnet métrologiques</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.metologicalNotebook != null"
                            class="badge badge-labeled">{{datesExpiration.metologicalNotebook | date:
                            'dd-MM-yyyy'}}</span>
                    </div>

                </div>

                <!-- Extincteurs -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'EXTINCTEURS';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>
                            Gestion extincteurs
                        </button>
                    </div>

                    <!-- Experation date -->
                    <div class="col-sm-6" style="text-align: center">

                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <!-- Response case !-->
                        <span *ngIf="!dateExpirationLoading && datesExpiration.extincteurs == null"
                            class="badge badge-labeled">Veuillez ajouter des extincteurs</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.extincteurs != null"
                            class="badge badge-labeled">{{datesExpiration.extincteurs | date: 'dd-MM-yyyy'}}</span>
                    </div>

                </div>


                <!-- taxe tonnage -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'TONNAGE_TAX';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>
                            Gestion taxe tonnage
                        </button>
                    </div>

                    <!-- Experation date -->
                    <div class="col-sm-6" style="text-align: center">

                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <!-- Response case !-->
                        <span *ngIf="!dateExpirationLoading && datesExpiration.tonnageTax == null"
                            class="badge badge-labeled">Veuillez ajouter des taxes de tonnage</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.tonnageTax != null"
                            class="badge badge-labeled">{{datesExpiration.tonnageTax | date: 'dd-MM-yyyy'}}</span>
                    </div>

                </div>

                <!-- carte grise -->
                <div class="form-group">

                    <!-- Management section -->
                    <div class="col-sm-6">
                        <button type="button"
                            (click)="this.selectedDocumentType = 'GRAY_CARD';loadDocument();managementModal.show();this.documentsId = [];"
                            class="btn btn-block btn-flat">
                            <i class="fa fa-cogs pull-left" aria-hidden="true"></i>
                            Gestion carte grise
                        </button>
                    </div>

                    <!-- Experation date -->
                    <div class="col-sm-6" style="text-align: center">

                        <!-- loading case -->
                        <span *ngIf="dateExpirationLoading" class="badge badge-labeled">
                            <i class="fa fa-spinner fa-spin fa-fw"></i> Veuillez patienter...</span>

                        <!-- Response case !-->
                        <span *ngIf="!dateExpirationLoading && datesExpiration.grayCard == null"
                            class="badge badge-labeled">Veuillez ajouter des cartes grises</span>
                        <span *ngIf="!dateExpirationLoading && datesExpiration.grayCard != null"
                            class="badge badge-labeled">{{datesExpiration.grayCard | date: 'dd-MM-yyyy'}}</span>
                    </div>

                </div>

            </div>
        </div>
    </form>


    <!-- =================================== MANAGEMENT MODAL (START)====================================-->
    <div class="modal fade" role="dialog" bsModal #managementModal="bs-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" (click)="managementModal.hide()">&times;</button>

                    <h4 *ngIf="this.selectedDocumentType == 'INSURANCE'" class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion assurance
                    </h4>

                    <h4 *ngIf="this.selectedDocumentType == 'VISIT'" class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion visite
                    </h4>

                    <h4 *ngIf="this.selectedDocumentType == 'OPERATIONAL_CERTIFICATION'"
                        class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion c.exploitation
                    </h4>

                    <h4 *ngIf="this.selectedDocumentType == 'PERMIT_CIRCULATION'"
                        class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion permis circulation
                    </h4>

                    <h4 *ngIf="this.selectedDocumentType == 'ROAD_TAXES'" class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion vignette
                    </h4>

                    <h4 *ngIf="this.selectedDocumentType == 'METOLOGICA_NOTBOOK'"
                        class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion carnet métrologique
                    </h4>

                    <h4 *ngIf="this.selectedDocumentType == 'EXTINCTEURS'" class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion extincteurs
                    </h4>

                    <h4 *ngIf="this.selectedDocumentType == 'TONNAGE_TAX'" class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion taxe tonnage
                    </h4>

                    <h4 *ngIf="this.selectedDocumentType == 'GRAY_CARD'" class="modal-title visible-lg-inline-block">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Gestion carte grise
                    </h4>

                    <!-- =================================== OPTIONS (START)==================================== -->

                    <button type="button" class="btn btn-success btn-sm" title="Ajouter"
                        (click)="openAddDocumentModal()">Ajouter
                        <i class="fa fa-plus-circle"></i> </button>

                    <div *ngIf="documentsId.length > 0" class="option-delete">
                        <span title="Supprimer tout" (click)="onConfirmDeleteMultiple()">
                            <i class="fa fa-minus-circle"></i> Supprimer tout</span>
                    </div>
                    <!-- =================================== OPTIONS (END)==================================== -->
                    <!--==================================== import/Export functionality(START)=============== -->

                    <div class="pull-right" style="line-height: 2;">
                        <div class="option-export ">

                            <button type="button" class="btn btn-primary btn-sm   "
                                title="Enregistrer sous format excel" (click)='export()'>Exporter <i
                                    class="fa fa-download"></i> </button>

                        </div>
                        <div class="option-export">

                            <button type="button" class="btn btn-primary btn-sm  " title="Enregistrer sous format excel"
                                (click)="showImportModal();managementModal.hide()">Importer <i class="fa fa-upload"></i>
                            </button>


                        </div>
                    </div>
                    <!--==================================== import/Export functionality(END)================= -->
                    <!--==================================== Search(START)=============== -->
                    <div class="pull-right" *ngIf="documentsNotHaveProvider.indexOf(selectedDocumentType) == -1">
                        <div class="inputWithIcon" [style.width]="documentsId.length > 0 ? '50px' : '175px'">
                            <input [(ngModel)]="searchTerm" name="searchTerm" type="text" class="form-control input-sm"
                                placeholder="Fournisseur...">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                    <!--==================================== Search(END)================= -->
                </div>
                <div class="modal-body">
                    <div class="table-responsive tableFixHead">

                        <div>

                            <div>
                                <h4 class="modal-title visible-lg-inline-block col-sm-4 control-label">
                                    <i class="fa fa-money"></i> Coût total par an :
                                </h4>
                                <div class="col-sm-4">
                                    <select [(ngModel)]="selectedYear" class="form-control input-sm select3"
                                        (change)="onChange($event.target.value)">
                                        <option [value]="year" *ngFor="let year of years">
                                            {{year}}
                                        </option>
                                        <option [value]="0" *ngIf="years.length > 0">
                                            Date Personnaliser
                                        </option>

                                    </select>
                                </div>
                            </div>
                            <div class="mrg" *ngIf="displayCalendar">
                                <div class="col-md-12 mrg">
                                    <form #dashbordForm="ngForm" class="form-horizontal">
                                        <div class="col-lg-5 form-group">
                                            <label class="col-lg-4 col-md-4 control-label">Du</label>
                                            <div class="col-lg-8 col-md-8">
                                                <input type="text" class="form-control input-sm" bsDatepicker
                                                    name="startDate" [(ngModel)]="startDate"
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
                                                    placement="bottom" required (ngModelChange)="onChangeStartDate()">
                                            </div>
                                        </div>
                                        <div class="col-lg-5 orm-group">
                                            <label class="col-lg-4 col-md-4 control-label">Au</label>
                                            <div class="col-lg-8 col-md-8">
                                                <input type="text" class="form-control input-sm" bsDatepicker
                                                    name="endDate" [(ngModel)]="endDate"
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
                                                    [minDate]="startDate" placement="bottom" required>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 orm-group">
                                            <button type="button" (click)="onValidate()"
                                                class="btn btn-block btn-success btn-flat">
                                                <i class="fa fa-search pull-left" aria-hidden="true"></i>
                                                Valider
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <br> <br>

                        <table class="table table-striped table-hover table-condensed" style="margin-top: 1.5%; ">
                            <thead>
                                <tr>
                                    <th style="width: 2%; text-align: left;"><input type="checkbox"
                                            [disabled]="documentsFiltered.length == 0"
                                            [checked]="documentsFiltered.length == documentsId.length"
                                            (click)="onCheckedAll()">
                                    </th>
                                    <th style="width: 5%">Date début</th>
                                    <th style="width: 5%">Date fin</th>
                                    <th style="width: 5%" *ngIf="isHaveProvider">Fournisseur</th>
                                    <th style="width: 5%">Coût
                                        <span style="background-color: white; color: #3c8dbc">total : {{costTotal()
                                            | number: '.0-4'}}
                                            DH</span>
                                    </th>
                                    <th style="width: 5%" *ngIf="selectedDocumentType == 'EXTINCTEURS'">Ref</th>
                                    <th style="width: 5%" *ngIf="selectedDocumentType == 'EXTINCTEURS'">Poids</th>
                                    <th style="width: 63%" style="border-left-style:none;"></th>
                                    <th style="width: 5%"></th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr *ngIf="documentsLoading">
                                    <td colspan="9" style="text-align: center">
                                        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                                    </td>
                                </tr>
                                <tr *ngIf="!documentsLoading && documentsFiltered.length == 0">
                                    <td colspan="9">
                                        <div class="alert alert-warning alert-dismissible"
                                            style="border-radius: 0px;text-align: center">
                                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                                ×
                                            </button>
                                            <h4 style="color: white">
                                                <i class="fa fa-info-circle"></i> Information
                                            </h4>
                                            Veuillez ajouter des informations pour documents.
                                        </div>
                                    </td>
                                </tr>

                                <tr *ngFor="let document of getDocuments()">
                                    <td>
                                        <input type="checkbox" [checked]="isCheckedInList(document.id)"
                                            (click)="onChecked(document.id)">
                                    </td>
                                    <td style="font-weight: bold">
                                        {{document?.operationTime | date: 'dd-MM-yyyy'}}
                                    </td>
                                    <td style="font-weight: bold">
                                        {{document?.endTime | date: 'dd-MM-yyyy'}}
                                    </td>
                                    <td *ngIf="isHaveProvider">
                                        {{document?.provider?.name}}
                                    </td>
                                    <td>
                                        {{document?.cost | number: '.0-4'}}
                                        <span class="label label-info">{{document?.cost / costTotal() * 100 |
                                            number: '.0-2'}}%</span>
                                    </td>
                                    <td *ngIf="selectedDocumentType == 'EXTINCTEURS'">
                                        {{document?.ref}}
                                    </td>
                                    <td *ngIf="selectedDocumentType == 'EXTINCTEURS'">
                                        {{document?.weight}}
                                    </td>
                                    <td></td>
                                    <td>
                                        <button title="Supprimer" style="color: red;"
                                            (click)="onConfirmDelete(document)" type="button"
                                            class="btn btn-sm btn-default">
                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                        </button>
                                        <button title="Modifier" style="color: rgb(2, 157, 196); " type="button"
                                            class="btn btn-sm btn-default" (click)="updateDocmuent(document)">
                                            <i class="fa fa-edit" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="offset-sm-4 col-sm-2" style="margin-top: 1.5%; ">
                    <button #myModalAdd data-backdrop="static" data-keyboard="false" data-target="#myModalAdd"
                        data-toggle="modal" style="color: green;" type="button"
                        class="btn btn-block btn-default hidden">
                        <i class="fa fa-plus" aria-hidden="true"></i>addForm
                    </button>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="managementModal.hide()">Fermer</button>
                </div>
            </div>

        </div>
    </div>
    <!-- =================================== MANAGEMENT MODAL (START)====================================-->

    <!-- ============================== DELETE MODAL (START) ========================== -->
    <div class="modal fade" bsModal #documentDeleteModal="bs-modal" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" (click)="documentDeleteModal.hide()">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Êtes-vous sûr ?
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                        <h4 style="color: white">
                            <i class="fa fa-exclamation-triangle"></i> Attention !
                        </h4>
                        <span *ngIf="!isDeleteMultiple">Êtes-vous sûr de supprimer ce document ?</span>
                        <span *ngIf="isDeleteMultiple">Êtes-vous sûr de supprimer ces {{documentsId.length}} documents
                            ?</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="onCancelDelete()">
                        Annuler
                    </button>
                    <button type="button" class="btn btn-success" (click)="onDelete()">
                        <i class="fa fa-check" aria-hidden="true" *ngIf="!loading"></i>
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Oui, Je confirme
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- =============================== DELETE MODAL (END) ============================= -->

    <!-- =================================== ADD/EDIT MODAL (START)====================================-->
    <div class="modal fade" role="dialog" bsModal #addEditModal="bs-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" (click)="addEditModal.hide();managementModal.show()">&times;
                    </button>
                    <h4 class="modal-title" *ngIf="mode === 'ADD'">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Ajouter informations document
                    </h4>

                    <h4 class="modal-title" *ngIf="mode === 'UPDATE'">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Modifier informations document
                    </h4>
                </div>
                <div class="modal-body">
                    <app-document-form [document]="selectedDocument" [mode]="mode" [vehicule]="selectedVehicule"
                        [group]="selectedGroup" (documentWasAdded)="documentWasAdded($event)"
                        (documentWasUpdated)="documentWasUpdated($event)"
                        (cancelOperation)="addEditModal.hide();managementModal.show()"></app-document-form>
                </div>
            </div>
        </div>
    </div>
    <!-- =================================== ADD/EDIT MODAL (START)====================================-->
    <!--=================================Import fuel(start) ====================================-->
    <div class="modal" role="dialog" bsModal #importModal="bs-modal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" (click)="importModal.hide();managementModal.show()">&times;
                    </button>
                    <h4 class="modal-title">
                        <i class="fa fa-cogs" aria-hidden="true"></i> Importer le fichier Excel
                    </h4>
                </div>
                <div class="modal-body vertical-spacer">
                    <app-import-export-forms [item]='selectedItem' [vehiculeMatricule]='selectedVehicule.matricule'
                        [groupId]='selectedGroup.idGroupe' (importedItem)='itemWasImported($event)'>
                    </app-import-export-forms>
                </div>
                <p></p>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="importModal.hide();managementModal.show()">
                        Fermer
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--=================================Import fuel (end) ====================================-->
</div>
