<div class="form-group">
  <div class="col-sm-10">
    <input style="display: none" type="file" name="file" (input)="onFileChanged($event.target.files)" accept=".xlsx" #fileInput>
    <button (click)="fileInput.click()" class="btn btn-light">Choisir un fichier</button>
    <button (click)="onUpload()" class="btn btn-success" [disabled]="selectedFile==null"><i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i> Importer</button>
    <a class="pull left" href="{{templateDownoaldLocation}}TemplateExcel/{{template}}" download>
      <button type="button" class="btn btn-success ">
        <i class="fa fa-download"></i>
        Telecharger Template
      </button>
    </a>
  </div>
  <div *ngIf="selectedFile!=null" class="col-lg-2">
    {{selectedFile.name}}
  </div>
</div>