import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationPipe } from './duration.pipe';  // Adjust the path as necessary

@NgModule({
  declarations: [
    DurationPipe  // Declare the DurationPipe here
  ],
  imports: [
    CommonModule  // Import CommonModule to use Angular common directives
  ],
  exports: [
    DurationPipe  // Export the DurationPipe so it can be used in other modules
  ]
})
export class DurationPipeModule { }
