import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlOptionsComponent } from './control-options.component';
//mport { ToolBarModule } from '../path-to-toolbar/toolbar.module'; // Adjust the import path accordingly
import { FormsModule } from '@angular/forms';
//import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToolBarModule } from './tool-bar/tool-bar.module';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { RouterModule } from '@angular/router';
import { SearchDropdownModule } from 'src/app/shared/search-dropdown/search-dropdown.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


@NgModule({
  declarations: [
    ControlOptionsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LeafletModule,
    ModalModule.forRoot(),
    ToolBarModule,
    NgxDaterangepickerMd.forRoot(),
    RouterModule,
    SearchDropdownModule
  ],
  exports: [
    ControlOptionsComponent
  ]
})
export class ControlOptionsModule { }
