<form #driverChargeForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Date</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" class="form-control input-sm" bsDatepicker [(bsValue)]="driverCharge.createAt"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    autocomplete="off">
            </div>
            <label class="col-sm-2 control-label">Conducteur *</label>
            <div class="col-lg-4 col-md-4">
                <select [disabled]="!driverCharge.driver.idDriver" [(ngModel)]="driverCharge.driver.idDriver"
                    name="driverId" #driverId="ngModel" class="form-control input-sm select2" required>
                    <option *ngFor="let driver of drivers" [value]="driver.idDriver"
                        [selected]="driver.idDriver == driverCharge.driver.id">
                        {{driver?.firstName}} {{driver?.lastName}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Salaire</label>
            <div class="col-lg-4 col-md-4">
                <input type="number" [(ngModel)]="driverCharge.salary" name="salary" class="form-control input-sm"
                    placeholder="Salaire" min="0">
            </div>
            <label class="col-sm-2 control-label">Téléphone</label>
            <div class="col-lg-4 col-md-4">
                <input type="number" [(ngModel)]="driverCharge.phone" name="phone" class="form-control input-sm"
                    placeholder="Téléphone" min="0">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Commission </label>
            <div class="col-lg-4 col-md-4">
                <input type="number" [(ngModel)]="driverCharge.commission" name="commission"
                    class="form-control input-sm" placeholder="Commission" min="0">
            </div>
            <label class="col-sm-2 control-label">Note de frais</label>
            <div class="col-lg-4 col-md-4">
                <input type="number" [(ngModel)]="driverCharge.note" name="note" class="form-control input-sm"
                    placeholder="Note de frais" min="0">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label" title="Chiffre d'affaire">CA </label>
            <div class="col-lg-4 col-md-4">
                <input type="number" [(ngModel)]="driverCharge.turnover" name="turnover" class="form-control input-sm"
                    placeholder="Chiffre d'affaire" min="0">
            </div>
        </div>

        <div class="box-footer">
            <button type="submit" *ngIf="mode == 'ADD'" [disabled]="!driverChargeForm.form.valid"
                class="btn btn-info pull-right" (click)="onAdd(driverChargeForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
            <button type="submit" *ngIf="mode == 'UPDATE'" [disabled]="!driverChargeForm.form.valid"
                class="btn btn-info pull-right" (click)="onUpdate(driverChargeForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
            <button type="reset" class="btn btn-default" (click)="driverChargeModal.hide()">Annuler</button>
        </div>
    </div>
</form>