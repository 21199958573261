import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryCanalGroupVehiculeComponent } from './history-canal-group-vehicule.component'; // Adjust the path as necessary
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modal directives
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule to handle form controls
import { TmmService } from '../../../_service/tmm.service';

@NgModule({
  declarations: [
    HistoryCanalGroupVehiculeComponent // Declare the component
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ModalModule.forRoot(), // Import ModalModule for Bootstrap modals
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    FormsModule // Import FormsModule for handling form data
  ],
  providers: [
    TmmService // Provide TmmService
  ],
  exports: [
    HistoryCanalGroupVehiculeComponent // Export the component
  ]
})
export class HistoryCanalGroupVehiculeModule { }
