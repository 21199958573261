import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertManagementService } from '../../alert-management.service';
import { Alert, AlertConfiguration } from '../../alert';
import { ToastrService } from 'ngx-toastr';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Subscription } from 'rxjs/Rx';

@Component({
  selector: 'app-oil-change-settings',
  templateUrl: './oil-change-settings.component.html',
  styleUrls: ['./oil-change-settings.component.css']
})
export class OilChangeSettingsComponent implements OnInit, OnDestroy {
  showAdvancedSettings1: boolean = true;
  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  public loader = false;

  value1: number = 1000;

  getVehiculeWasSelectedSubscription: Subscription;

  engineOilAlertConfig: AlertConfiguration = new AlertConfiguration();
  oilFAlertConfig: AlertConfiguration = new AlertConfiguration();
  airFAlertConfig: AlertConfiguration = new AlertConfiguration();
  carbFAlertConfig: AlertConfiguration = new AlertConfiguration();
  separatorFAlertConfig: AlertConfiguration = new AlertConfiguration();

  alert: Alert = new Alert();

  carbAlertSelectMode = true;
  separatorFAlertSelectMode = true;
  airFAlertSelectMode = true;
  oilFAlertSelectMode = true;
  engineOilAlertSelectMode = true;
  value1AlertSelectMode = true;

  constructor(
    private toastr: ToastrService,
    public alertManagementService: AlertManagementService,
    private dataManagementService: DataManagementService
  ) {
    this.alertManagementService.initKm();
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      //load oil change alert accorded to this vehicule, by default the first vehicule in the selected group
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService.selectedVehicule!.idDevice
        );
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  //load config based on name and device id (every device has one alert of type X)
  //if the config doesn't exist ,assign the null config
  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('VIDANGE', device)
      .subscribe(
        alert => {
          this.alert = alert;
          this.airFAlertConfig = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'Air_F'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'Air_F'
            )[0]
            : this.airFAlertConfig;
          //if config doesn't exists already ,will be created
          if (this.airFAlertConfig == null) {
            this.airFAlertConfig = this.alertManagementService.initializeAlertConfiguration(
              'Air_F'
            );
          }

          this.carbFAlertConfig = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'Fuel_F'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'Fuel_F'
            )[0]
            : this.carbFAlertConfig;
          this.carbFAlertConfig = this.carbFAlertConfig;

          if (this.carbFAlertConfig == null) {
            this.carbFAlertConfig = this.alertManagementService.initializeAlertConfiguration(
              'Fuel_F'
            );
          }

          this.separatorFAlertConfig = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'Separator_F'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'Separator_F'
            )[0]
            : this.separatorFAlertConfig;

          if (this.separatorFAlertConfig == null) {
            this.separatorFAlertConfig = this.alertManagementService.initializeAlertConfiguration(
              'Separator_F'
            );
          }
          this.oilFAlertConfig = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'Oil_F'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'Oil_F'
            )[0]
            : this.oilFAlertConfig;

          if (this.oilFAlertConfig == null) {
            this.oilFAlertConfig = this.alertManagementService.initializeAlertConfiguration(
              'Oil_F'
            );
          }

          this.engineOilAlertConfig = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'Engine_Oil'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'Engine_Oil'
            )[0]
            : this.engineOilAlertConfig;
          if (this.engineOilAlertConfig == null) {
            this.engineOilAlertConfig = this.alertManagementService.initializeAlertConfiguration(
              'Oil_F'
            );
          }

          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }

  chooseKm($event: any, paramNumber: number) {
    if ($event === 'Autre..') {
      if (paramNumber == 0) {
        this.value1AlertSelectMode = false;
        this.value1 = 1000;
      } else if (paramNumber == 1) {
        this.engineOilAlertSelectMode = false;
        this.engineOilAlertConfig.value1 = '100';
      } else if (paramNumber == 2) {
        this.oilFAlertSelectMode = false;
        this.oilFAlertConfig.value1 = '100';
      } else if (paramNumber == 3) {
        this.airFAlertSelectMode = false;
        this.airFAlertConfig.value1 = '100';
      } else if (paramNumber == 4) {
        this.carbAlertSelectMode = false;
        this.carbFAlertConfig.value1 = '100';
      } else if (paramNumber == 5) {
        this.separatorFAlertSelectMode = false;
        this.separatorFAlertConfig.value1 = '100';
      }
    }
  }
  isNewAlertConfig(type: number) {
    // Vérifiez si km est un tableau d'objets avec la propriété 'value'
    return !!this.alertManagementService.km.find((x: { value: string | number }) => x.value === type);
  }



  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean, enableGlobalConfigToAllVehicules: boolean }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings1 = !this.showAdvancedSettings1;
  }

  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    //if user doesn't choose advanced options ,initialize value1 of all configs with value1
    if (!this.showAdvancedSettings1) {
      this.alertManagementService.initializeAlertConfigurationWithSameValue1(
        this.value1,
        this.alert.alertConfigurations
      );
    }

    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrée', 'info !');
      },
      error => {
        this.loader = false;
        this.toastr.error('Configuration non enregistrée', 'info !');
      }
    );
  }
  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails, id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }
}
