import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargesPfbComponent } from './charges-pfb.component';
import { ChargesPfbFormComponent } from './charges-pfb-form/charges-pfb-form.component'; // Ensure this import matches your directory structure
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Importing the datepicker module
import { ModalModule } from 'ngx-bootstrap/modal'; // Importing the modal module
import { ToastrModule } from 'ngx-toastr'; // Importing the toastr module
import { FormsModule } from '@angular/forms'; // Importing FormsModule if you're using forms
import { ChargesPfbFormModule } from './charges-pfb-form/charges-pfb-form.module';
import { NgxLoadingModule } from 'ngx-loading';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    ChargesPfbComponent,
  ],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(), // Include the datepicker module
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    FormsModule,
    NgxLoadingModule.forRoot({}),
    ChargesPfbFormModule,
    MatSlideToggleModule,
    FilterPipeModule,

  ],
  exports: [
    ChargesPfbComponent // Export the component if it needs to be used in other modules
  ]
})
export class ChargesPfbModule { }
