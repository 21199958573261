import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravelReportComponent } from './travel-report.component'; // Adjust the path as needed
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you're using toastr notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using forms
import { RouterModule } from '@angular/router'; // Import RouterModule for routing
import { NgxLoadingModule } from 'ngx-loading';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { DurationPipeModule } from 'src/app/pipes/duration.module';
import { NoValuePipeModule } from 'src/app/pipes/no-value.module';

@NgModule({
  declarations: [
    TravelReportComponent // Declare the TravelReportComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    FormsModule,
    RouterModule,
    NgxLoadingModule.forRoot({}),
    SharedPipesModule,
    DurationPipeModule,
    NoValuePipeModule,

  ],
  exports: [
    TravelReportComponent
  ]
})
export class TravelReportModule { }
