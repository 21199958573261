import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleClassificationComponent } from './vehicle-classification.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using forms
import { RouterModule } from '@angular/router'; // Import RouterModule if you're using routing
import { ActivityVehiculeClassificationsService } from '../../activity-overview-management/activity-vehicle-classification/activity-vehicule-classifications.service'; // Adjust the path as necessary
import { TransportTypeService } from '../parametrage/transport-type'; // Adjust the path as necessary
import { ClassificationSpeedCartoChartModule } from '../../activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-speed-carto-chart/classification-speed-carto-chart.module';
import { ClassificationRotationChartModule } from '../../activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-rotation-chart/classification-rotation-chart.module';
import { ClassificationFuelingChartModule } from '../../activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-fueling-chart/classification-fueling-chart.module';
import { ClassificationPercentageChartModule } from '../../activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-percentage-chart/classification-percentage-chart.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClassificationKMChartModule } from '../../activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-kmchart/classification-kmchart.module';
import { ClassificationHourActivityChartModule } from '../../activity-overview-management/activity-vehicle-classification/activity-vehicle-classification-charts/classification-hour-activity-chart/classification-hour-activity-chart.module';
// import { ClassificationChartsModule } from '../path-to-classification-charts/classification-charts.module';
@NgModule({
  declarations: [
    VehicleClassificationComponent,

  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    FormsModule, // Import FormsModule for template-driven forms
    RouterModule,
    ClassificationSpeedCartoChartModule,
    ClassificationRotationChartModule,
    ClassificationFuelingChartModule,
    BsDatepickerModule.forRoot(),
    ClassificationKMChartModule,
    ClassificationPercentageChartModule,
    ClassificationHourActivityChartModule,
  ],
  providers: [
    ActivityVehiculeClassificationsService, // Provide ActivityVehiculeClassificationsService
    TransportTypeService
  ],
  exports: [
    VehicleClassificationComponent // Export the VehicleClassificationComponent for use in other modules
  ]
})
export class VehicleClassificationModule { }
