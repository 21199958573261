<div class="row" style="font-family: 'Inconsolata', monospace;">
  <section class="content col-md-12 groups-container">
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-12" style="margin-top: -10px;">
        <div class="box" style="border-radius: 0px;min-height: unset;">
          <div class="box-body">
            <form class="form-horizontal" #trackingForm="ngForm">
              <div class="form-group row">

                <label class="col-sm-1 control-label grp-lbl">Année</label>
                <div class="col-sm-1 grp-slct">
                  <select class="form-control input-sm" [(ngModel)]='selectedYear' (ngModelChange)="onYearChange()"
                    name='selectedYear' required>
                    <option style="font-size: 14px;" *ngFor="let year of years" [value]="year.id">
                      {{year.name}}</option>
                  </select>
                </div>

                <label class="col-sm-1 control-label grp-lbl">Mois</label>
                <div class="col-sm-1 grp-slct">
                  <select class="form-control input-sm" [(ngModel)]='selectedMonth'
                    (ngModelChange)="onMonthChange($event)" name='selectedMonth' required>
                    <option [ngValue]="null" style="font-weight: bold;">Choisir le mois</option>
                    <option style="font-size: 14px;" *ngFor="let month of months" [value]="month.id">
                      {{month.name}}</option>
                  </select>
                </div>

                <label class="col-sm-1 control-label grp-lbl">Affectation</label>
                <div class="col-sm-1 grp-slct">
                  <select class="form-control input-sm" name="affectation" [(ngModel)]="selectedaffectation"
                    (ngModelChange)="chooseAffectation($event)" required>
                    <option *ngFor="let affectation of affectations" [ngValue]="affectation">
                      {{affectation?.group?.nom}}
                    </option>
                  </select>
                </div>

                <div class="col-lg-2 form-group">
                  <label class="col-lg-2 col-md-2 control-label">Du</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                      [(ngModel)]="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="firstDay"
                      [maxDate]="endDate" placement="bottom" required autocomplete="off"
                      (ngModelChange)="onChangeStartDate()">
                  </div>
                </div>

                <div class="col-lg-2 orm-group">
                  <label class="col-lg-2 col-md-2 control-label">Au</label>
                  <div class="col-lg-8 col-md-8">
                    <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="startDate" autocomplete="off"
                      [maxDate]="lastDay" placement="bottom" required>
                  </div>
                </div>

                  <div class="col-sm-1 form-btns">
                    <button type="button" class="btn btn-primary btn-flat btn-sm" [disabled]="!trackingForm.form.valid"
                      (click)="onTracking()" title="Valider">
                      <b>Valider <i class="fa fa-play" aria-hidden="true"></i></b>
                    </button>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- corrélation localtion + carburant -->
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingTracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <app-correlation-location [isManually]="true" [selectedCharges]="selectedChargePfb"
                [dayDifference]="dayDif" [caRealise]="crCaRealise"></app-correlation-location>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingTracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <app-correlation-gasoil [isManually]="false" [selectedCharges]="selectedChargePfb" [dayDifference]="dayDif"
                [caRealise]="crCaRealise" [fueling]="fueling"></app-correlation-gasoil>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end corrélation localtion + carburant -->

    <!-- graph corrélation maintenance + chiffre d'affaire -->
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingTracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <app-correlation-maintenance [selectedCharges]="selectedaffectation" [dayDifference]="dayDif"
                [caRealise]="crCaRealise" [maintenance]="maintenances"></app-correlation-maintenance>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingTracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <app-turnover-achieved [selectedCharges]="selectedChargePfb" [caRealise]="caRealise"
                [dayDifference]="dayDif">
              </app-turnover-achieved>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end graph corrélation maintenance + chiffre d'affaire -->

    <!-- graph location + carburant consommé -->
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingTracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <app-location-cost [isManually]="true" [selectedCharges]="selectedChargePfb"
                [locationDayDifference]="dayDif" [caRealise]="crCaRealise"></app-location-cost>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="box box-info collapsed-box">
          <div class="box-header with-border text-center">
            <div *ngIf="loadingTracking" style="font-size: 12px;margin-top: 8px;">
              <i class="fa fa-spinner fa-spin"></i> Chargement ...
            </div>
            <app-fueling-charge [isManually]="false" [selectedCharges]="selectedChargePfb" [dayDifference]="dayDif"
              [caRealise]="crCaRealise" [fueling]="fueling">
            </app-fueling-charge>
          </div>
        </div>
      </div>
    </div>
    <!-- end graph location + carburant -->

    <!-- graph charge maintenance consommé -->
    <div class="row" style="margin-top: 32px;">
      <div class="col-md-6">
        <div>
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loadingTracking" style="font-size: 12px;margin-top: 8px;">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <app-maintenance-charge [selectedCharges]="selectedaffectation" [maintenanceDayDifference]="dayDif"
                [maintenance]="maintenances"></app-maintenance-charge>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end graph charge maintenance -->

  </section>
</div>