<div class="card">
  <div class="card-header" (click)="openModal()">
    <p>...</p>
  </div>
  <div class="card-body">
    <div id="pieCA" style="zoom: 0.52"></div>
  </div>
</div>

<!-- Bootstrap Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{ show: showModal }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="pieCAModal"></div>
      </div>
    </div>
  </div>
</div>
