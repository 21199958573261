<div class="fix-loading">

    <div class="row">
        <app-current-state></app-current-state>
    </div>
    
    <div class=" mt-2 mb-2 text-center well bgg">
        <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
            <i class="fa fa-plus-circle add"></i>
            <span> Balance Supply Chain - Rendement Véhicule & Collaborateur</span>
        </h4>
    </div>
    <div class="row" style="font-family: 'Inconsolata', monospace;">
        <section class="content col-md-12 groups-container">
            <div class="row">
                <div class="col-md-6">
                    <div>
                        <div class="box box-info collapsed-box">
                            <div class="box-header with-border">
                                <app-stop-chart></app-stop-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <div class="box box-info collapsed-box">
                            <div class="box-header with-border">
                                <app-activity-time-chart></app-activity-time-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-6">
                    <div>
                        <div class="box box-info collapsed-box">
                            <div class="box-header with-border">
                                <app-kilomtrage-chart></app-kilomtrage-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <div class="box box-info collapsed-box">
                            <div class="box-header with-border">
                                <app-activity-chart></app-activity-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
    
        </section>
    
    </div>
</div>