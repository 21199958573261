import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CimentManagementComponent } from './ciment-management.component';
import { RouterModule } from '@angular/router';
// Import other components that belong to this module, if any

@NgModule({
  declarations: [
    CimentManagementComponent,
    // Add other components here if necessary
  ],
  imports: [
    CommonModule,
    RouterModule
    // Add any other necessary modules here (e.g., FormsModule, ReactiveFormsModule, etc.)
  ],
  exports: [
    CimentManagementComponent // Export if you need to use it in other modules
  ]
})
export class CimentManagementModule { }
