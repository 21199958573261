import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AlertManagementService } from '../..';

@Component({
  selector: 'app-email-manager',
  templateUrl: 'email-manager.component.html',
  styleUrls: ['email-manager.component.css']
})
export class EmailManagerComponent {
  /*@Input()*/
  _emailsHash: string | null = null;

  @Input() emailMode: boolean;

  @Input() enableGlobalConfig: boolean = false;

  @Input() enableGlobalConfigToAllVehicules: boolean = false;

  public emailValue: string | null = null;

  public actionMode: 'ADD' | 'UPDATE' = 'ADD';

  public emailOldValue: string | null = ''; // Peut être une chaîne ou null


  @Input() showActiveSection = true;

  @Input() showActiveSectionOfAllVehicules = true;

  emails: string[] = [];

  EMAIL_MAX_LENGTH = 10;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() {
    if (this.emailsHash) {
      this.emails = this.emailsHash.split(';');
    }
  }

  get emailsHash(): string {
    return this._emailsHash;
  }

  @Input()
  set emailsHash(emailsHash: string) {
    this._emailsHash = emailsHash;
    if (this._emailsHash && this._emailsHash.trim() !== '') {
      this.emails = this._emailsHash.split(';');
    } else {
      this.emails = [];
    }
  }
  addEmail() {
    this.emailMode = true;
    this.emails.push(this.emailValue);
    this.emailValue = null;
    this.emitValue();
  }

  updateEmail() {
    this.emailMode = true;

    // Vérification que emailOldValue n'est pas null
    if (this.emailOldValue === null) {
      this.init();
      return;
    }

    const index = this.emails.indexOf(this.emailOldValue);

    if (index === -1) {
      this.init();
      return;
    }

    this.emails[index] = this.emailValue;
    this.actionMode = 'ADD';
    this.emailOldValue = null;
    this.emailValue = '';
    this.emitValue();
  }

  deleteEmail(email: any) {
    let index = this.emails.indexOf(email);
    if (index > -1) {
      this.emails.splice(index, 1);
    }
    if (this.emails.length > 0) {
      this.emailMode = true;
    } else {
      this.emailMode = false;
    }
    this.emitValue();
  }

  emailToHash(emails: string[]) {
    let separator = '';
    let hash = '';
    for (let i = 0; i < emails.length; i++) {
      hash += separator + emails[i];
      separator = ';';
    }
    return hash;
  }

  emitValue() {
    this.onChange.emit({
      emailMode: this.emailMode,
      emails: this.emailToHash(this.emails),
      enableGlobalConfig: this.enableGlobalConfig,
      enableGlobalConfigToAllVehicules: this.enableGlobalConfigToAllVehicules
    });
  }

  init() {
    this.actionMode = 'ADD';
    this.emailOldValue = null;
    this.emailValue = '';
  }
}
