import {Component, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {imagesPOIDir} from "../../../global.config";
import {GeocodingService} from "../../../utils/leaflet/service/geocoding.service";
import {MapService} from "../../../utils/leaflet/service/map.service";
import {ClientType, PoiClient, RapportDto} from "../../data-management/data-management.model";
import {DataManagementService} from "../../data-management/data-management.service";

import * as L from 'leaflet';
import {Icon, Marker, Polygon} from 'leaflet';
import {ImportExportService} from "../../import-export/import-export.service";
import {ClientTypesList} from "./client-types.model";

@Component({
  selector: "app-poi-client",
  templateUrl: "./poi-client.component.html",
  styleUrls: ["./poi-client.component.css"]
})
export class PoiClientComponent implements OnInit {

  importExport = true;

  @ViewChild('importModal',{ static: false }) importModal: ModalDirective;

  /** all poi*/
  poiClients: PoiClient[] = [];

  /** selected poi*/
  poiClient: PoiClient = new PoiClient();

  /** import  */
  selectedItem = 'POICLIENT';

  /** matker poi*/
  poiClientsPoint: PoiClient[] = [];

  /** polygon poi*/
  poiClientsPolygon: PoiClient[] = [];

  repport: RapportDto = new RapportDto();

  public searchTerm: string | null = null;
  /** poi by page*/
  dataPoint: any[] = [];
  dataPolygon: any[] = [];


  // pag vars
  public page: number = 1;
  public itemsPerPage: number = 25;
  public maxSize: number = 5;

  public pagePolgygon: number = 1;
  public itemsPerPagePolgygon: number = 25;
  public maxSizePolgygon: number = 5;
  /** delete modal */
  @ViewChild('poiDeleteModal', { static: false })
  poiDeleteModal: ModalDirective;
  /** current selected poi */
  selectedPoi: PoiClient
  /** loader */
  loading = false;

  isDataLoaded: boolean = false;

  clientTypes = ClientTypesList;

  selectedClientType: ClientType;

  @ViewChild("childModal", { static: false }) public childModal: ModalDirective;

  clientCounter = 0;
  prospectCounter = 0;
  intermediaireCounter = 0;
  depotCounter = 0;
  concurrentCounter = 0;
  driverCounter = 0;
  clientGrosCounter = 0;
  concessionnaireCounter = 0;
  isPoiExpanded = true;


  public showChildModal(): void {
    this.childModal.show();
  }

  constructor(
    private mapService: MapService,
    private dataManagementService: DataManagementService,
    private geocodingService: GeocodingService,
    public toastr: ToastrService,
    public exportImport: ImportExportService,
  ) { }

  ngOnInit() {
    this.loadPOI();
  }

  poiToggle() {
    this.isPoiExpanded = !this.isPoiExpanded;
  }

  clientTypeCounter(poiClient:any) {
    if (poiClient.clientType === "CONCURENT") this.concurrentCounter++;
    else if (poiClient.clientType === "INTERMEDIAIRE") this.intermediaireCounter++;
    else if (poiClient.clientType === "DEPOT") this.depotCounter++;
    else if (poiClient.clientType === "PROSPECT") this.prospectCounter++;
    else if (poiClient.clientType === "CLIENT_GROS") this.clientGrosCounter++;
    else if (poiClient.clientType === "DRIVER") this.driverCounter++;
    else if (poiClient.clientType === "CONCESSIONNAIRE") this.concessionnaireCounter++;
    else this.clientCounter++;
  }

  //GET list of Points and Polygons
  loadPOI() {
    this.clientCounter = 0;
    this.concurrentCounter = 0;
    this.prospectCounter = 0;
    this.intermediaireCounter = 0;
    this.depotCounter = 0;
    this.clientGrosCounter = 0;
    this.driverCounter = 0;
    this.concessionnaireCounter = 0;

    this.poiClientsPoint = [];
    this.poiClientsPolygon = [];

    if (this.dataManagementService.pointClient) {
      this.dataManagementService.pointClient.forEach(poiClient => {
        if (poiClient.type == "MARKER") {
          this.poiClientsPoint.push(poiClient);
          this.clientTypeCounter(poiClient);
        } else if (poiClient.type == "POLYGON") {
          this.poiClientsPolygon.push(poiClient);
        }
      });
      this.PageChangePoint({
        page: this.page,
        itemsPerPage: this.itemsPerPage
      });
      this.PageChangePolygon({
        page: this.pagePolgygon,
        itemsPerPage: this.itemsPerPagePolgygon
      });
      this.isDataLoaded = true;
    } else {
      this.dataManagementService
        .getAllPointClients()
        .subscribe(poiClients => {
          poiClients.forEach(poiClient => {
            if (poiClient.type == "MARKER") {
              this.poiClientsPoint.push(poiClient);
              this.clientTypeCounter(poiClient);
            } else if (poiClient.type == "POLYGON") {
              this.poiClientsPolygon.push(poiClient);
            }
          });
          this.dataManagementService.pointClient = poiClients;
          this.PageChangePoint({
            page: this.page,
            itemsPerPage: this.itemsPerPage
          });
          this.PageChangePolygon({
            page: this.pagePolgygon,
            itemsPerPage: this.itemsPerPagePolgygon
          });
          this.isDataLoaded = true;
        });
    }
  }

  //Pagination of Polygon page
  PageChangePolygon(page: any) {
    let start = (page.page - 1) * page.itemsPerPage;
    let end =
      page.itemsPerPage > -1
        ? start + page.itemsPerPage
        : this.poiClientsPolygon.length;
    this.dataPolygon = this.poiClientsPolygon.slice(start, end);
  }

  deletePointPolygon(poi: PoiClient) {
    this.selectedPoi = poi;
    this.poiDeleteModal.show();
  }
  onDelete() {
    this.deletePOI(this.selectedPoi);
  }

  initPoints() {
    var markersListLength = null;

    if (this.selectedClientType in ClientType) {
      markersListLength = this.dataManagementService.pointClient!.filter(
        poiClient => poiClient.type == "MARKER" &&
          poiClient.clientType === this.selectedClientType
      ).length;
    } else {
      markersListLength = this.dataManagementService.pointClient!.filter(
        poiClient => poiClient.type == "MARKER"
      ).length;
    }


    // init !
    this.poiClientsPoint = [];
    this.dataPoint = [];

    var filteredPointClient = [];
    if (this.selectedClientType in ClientType) {
      filteredPointClient = this.dataManagementService.pointClient!.filter(p => p.clientType === this.selectedClientType);
    } else {
      filteredPointClient = this.dataManagementService.pointClient;
    }

    this.mapService.removeMarkersFromMap();
    this.mapService.removeMarkersPoiFromMap();

    filteredPointClient.forEach(poiClient => {
      if (poiClient.type == "MARKER") {

        /** SEARCH WITH NAME IF VAR searchTerm EXISTS */
        if (this.searchTerm != null && this.searchTerm.length > 0) {
          if (poiClient.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1) {
            this.poiClientsPoint.push(poiClient);
          }
        } else {
          this.poiClientsPoint.push(poiClient);
        }
        this.drawPointInterest(poiClient);
      }
    });
    this.mapService.map.setView({ lat: 32.586163, lng: -9.912118 }, 6);

    /** if deleted element is the last element in the page*/
    if (markersListLength % this.itemsPerPage === 0) {
      if (this.page > 1) {
        this.page = this.page - 1;
      } else {
        this.page = 1;
      }
    }

    this.PageChangePoint({ page: this.page, itemsPerPage: this.itemsPerPage });
  }

  initPolygons() {
    var polygonsListLength = this.dataManagementService.pointClient!.filter(
      poiClient => poiClient.type == "POLYGON"
    ).length;

    // init !
    this.poiClientsPolygon = [];
    this.dataPolygon = [];

    this.dataManagementService.pointClient!.forEach(poiClient => {
      if (poiClient.type == "POLYGON") {

        /** SEARCH WITH NAME IF VAR searchTerm EXISTS */
        if (this.searchTerm != null && this.searchTerm.length > 0) {
          if (poiClient.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1) {
            this.poiClientsPolygon.push(poiClient);
          }
        } else {
          this.poiClientsPolygon.push(poiClient);
        }

      }
    });

    /** if deleted element is the last element in the page*/
    if (polygonsListLength % this.itemsPerPagePolgygon === 0) {
      if (this.pagePolgygon > 1) {
        this.pagePolgygon = this.pagePolgygon - 1;
      } else {
        this.pagePolgygon = 1;
      }
    }

    this.PageChangePolygon({
      page: this.pagePolgygon,
      itemsPerPage: this.itemsPerPagePolgygon
    });
  }

  //Pagination of Point page
  PageChangePoint(page: any) {
    let start = (page.page - 1) * page.itemsPerPage;
    let end =
      page.itemsPerPage > -1
        ? start + page.itemsPerPage
        : this.poiClientsPoint.length;
    this.dataPoint = this.poiClientsPoint.slice(start, end);
  }


  deletePOI(point: PoiClient) {
    this.loading = true;
    this.dataManagementService
      .deletePointClinet(point.idPointClient)
      .subscribe(
        suprimed => {
          this.poiDeleteModal.hide();
          if (suprimed) {
            this.mapService.removeMarkersFromMap();
            this.mapService.removeMarkersPoiFromMap();
            this.mapService.removePolygonsPoiFromMap();

            this.dataManagementService.pointClient = this.dataManagementService.pointClient!.filter(
              poiClient =>
                poiClient.idPointClient != point.idPointClient
            );

            if (point.type == "MARKER") {
              this.initPoints();
            }

            if (point.type == "POLYGON") {
              this.initPolygons();
            }

            this.toastr.success(
              "Operation de suppression est bien effectuée !",
              "info"
            );
            this.loading = false;
          } else {
            this.toastr.error(
              "L'operation de suppression est annulée !",
              "Error"
            );
            this.loading = false;
          }
        },
        () =>
          this.toastr.error("L'operation de suppression est annulée !", "Error")
      );
  }

  updatePOI(poiClient: PoiClient) {
    this.poiClient = poiClient;
    this.showChildModal();
  }

  drawPointInterest(poiClient: PoiClient) {
    let popup = "<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" + poiClient.name + "</strong><br><hr><b>Adresse : " + poiClient.address + "</b>";
    let marker: L.Marker<any> | null = null;

    marker = new Marker(poiClient.coordinate);
    marker.on("click", () => {
      this.mapService.map.setView(poiClient.coordinate, 17);
    });

    marker.on("mouseover", () => {
      marker.openPopup();
    });

    marker.bindPopup(popup);
    marker.setIcon(new Icon({
      iconUrl: imagesPOIDir + poiClient.imageUri,
      iconAnchor: [-2, 10],
      popupAnchor: [10, -25]
    }));

    this.mapService.addMarkerPoi(marker);
  }

  drawCircle(poiClient: PoiClient) {
    let circle = L.circle(poiClient.coordinate, {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.1,
      radius: poiClient.ray
    });
    this.mapService.addCircle(circle);

    setTimeout(() => {
      this.mapService.removeCirclesFromMap();
    }, 9000);
  }

  displayPointPolygon(poiClient: PoiClient) {
    this.toastr.success(
      poiClient.name +
      " couvre un rayon de " +
      poiClient.ray +
      " metres",
      "info"
    );

    this.mapService.removeMarkersFromMap();
    this.mapService.removeMarkersPoiFromMap();
    this.mapService.removePolygonsPoiFromMap();

    let popup =
      "<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" +
      poiClient.name +
      "</strong><br><hr><b>Adresse : " +
      poiClient.address +
      "</b>";
    let marker: L.Marker<any> = null;

    if (poiClient.type == "POLYGON") {
      let polygon = new Polygon(poiClient.decode);
      if (poiClient.plyColor != null)
        polygon.setStyle({ fillColor: poiClient.plyColor, color: poiClient.plyColor, weight: 2 });
      this.mapService.addPolygonPoi(polygon);
    }

    marker = new Marker(poiClient.coordinate);

    marker.on("click", () => {
      this.mapService.map.setView(poiClient.coordinate, 17);
    });

    marker.on("mouseover", () => {
      marker.openPopup();
    });

    marker.on("add", () => {
      marker.openPopup();
    });

    if (poiClient.type == "MARKER") {
      this.drawCircle(poiClient);
    }

    //Edit Marker
    marker.options.draggable = true;
    this.poiClient = poiClient;

    marker.on("drag", (e: any) => {
      poiClient.coordinate = e.target._latlng;
    });

    marker.on("dragend", (e: any) => {
      poiClient.coordinate = e.target._latlng;
      this.poiClient.coordinate = e.target._latlng;

      this.geocodingService
        .inverseGeoconding(
          this.poiClient.coordinate.lat,
          this.poiClient.coordinate.lng,
          17
        )
        .subscribe(adress => {
          this.poiClient.address = adress.display_name;
        });
      this.childModal.show();
    });

    marker.bindPopup(popup);
    marker.openPopup();
    marker.setIcon(
      new Icon({
        iconUrl: imagesPOIDir + poiClient.imageUri,
        iconAnchor: [-2, 10],
        popupAnchor: [10, -25]
      })
    );
    this.mapService.addMarkerPoi(marker);
    var mapCenterMarker = new L.LatLng(
      poiClient.coordinate.lat,
      poiClient.coordinate.lng - 0.08
    );
    var mapCenterPolygin = new L.LatLng(
      poiClient.coordinate.lat,
      poiClient.coordinate.lng - 0.003
    );

    if (poiClient.type == "POLYGON") {
      this.mapService.map.setView(mapCenterPolygin, 17);
    } else {
      this.mapService.map.setView(mapCenterMarker, 12);
    }
  }

  export() {
    this.repport.type = 'SITE_POICLIENT';
    this.repport.rapportPayloadDto.type = this.selectedClientType;
    this.exportImport.export(this.poiClient, this.repport, 0);
  }

  itemWasImported(res: boolean) {
    if (res) {
      this.importModal.hide();
      this.loadPOI();
    }
  }

}
