import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule for ngModel
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications

import { ActivityHourSettingsComponent } from './activity-hour-settings.component'; // Import the ActivityHourSettingsComponent
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    ActivityHourSettingsComponent,
  ],
  imports: [
    CommonModule, // Common module for common directives
    FormsModule, // Include FormsModule for ngModel
    ToastrModule.forRoot(),
    EmailManagerModule,
    CronSettingsModule,
    MatSlideToggleModule
  ],
  exports: [
    ActivityHourSettingsComponent // Export the component if needed in other modules
  ]
})
export class ActivityHourSettingsModule { }
