import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { CimentSiteFormComponent } from './ciment-site-form.component'; // Import your component
import { CimentSiteService } from '../ciment-site.service'; // Import the CimentSiteService if used in this component
import { NgxLoadingModule } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';

@NgModule({
  declarations: [
    CimentSiteFormComponent
  ],
  imports: [
    CommonModule, // CommonModule for basic Angular directives
    FormsModule, // FormsModule for template-driven forms
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    CommonModule, // Add CommonModule
    NgMultiSelectDropDownModule.forRoot(),

  ],
  providers: [
    CimentSiteService // Provide the CimentSiteService
  ],
  exports: [
    CimentSiteFormComponent // Export the component if it will be used in other modules
  ]
})
export class CimentSiteFormModule { }
