import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReparationComponent } from './reparation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { ImportExportFormsModule } from '../../import-export-forms/import-export-forms.module';
import { ReparationFormModule } from './reparation-form/reparation-form.module';

@NgModule({
  declarations: [
    ReparationComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    ImportExportFormsModule,
    ReparationFormModule,
    ImportExportFormsModule,
    FormsModule

  ],
  exports: [
    ReparationComponent
  ]
})
export class ReparationModule { }
