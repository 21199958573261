import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { ScheduleService } from '../../charges-pfb';
import { BreakDown } from './break-down';

@Component({
  selector: 'app-break-down-form',
  templateUrl: './break-down-form.component.html',
  styleUrls: ['./break-down-form.component.css']
})
export class BreakDownFormComponent implements OnInit {

  vehiculesDropdownSettings = {};
  loading = false;
  breakDown: BreakDown = new BreakDown();

  @Output()
  hideBreakdownForm: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  breakDownSaved: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  vehicules: any[] = [];

  maxDate: Date = new Date();

  constructor(private breakDownService: ScheduleService, public toastr: ToastrService, private localeService: BsLocaleService) {
  }

  ngOnInit() {
    this.localeService.use('fr');

    this.vehiculesDropdownSettings = {
      singleSelection: true,
      idField: 'deviceId',
      textField: 'matricule',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    this.breakDown.deviceId = item.deviceId;
    this.breakDown.matricule = item.matricule;
  }

  onSaveBreakDown(breakDownForm: NgForm) {
    this.loading = true;
    this.breakDown.name = 'Panne';

    this.breakDownService.addBreakDownCiment(this.breakDown).subscribe(breakDown => {
      breakDownForm.reset();
      this.breakDown = new BreakDown();
      this.loading = false;
      this.breakDownSaved.emit();
    }, error => {
      this.loading = false;

      let textError = JSON.parse(JSON.stringify(error.error || null));

      if (!textError.errorMessage) {
        textError.errorMessage = "SERVER ERROR";
      }

      this.toastr.error(textError.errorMessage, JSON.parse(JSON.stringify(error.error || null)).errorCode);

    })
  }

}
