<div
  class="accordion-toggle"
  style="color: black; display: flex"
  (click)="realTime.collapeItem(item)"
>
  <!-- group name et vehicules counter ! -->
  <div class="group-element">
    <div >
      <span *ngIf="item.nameGroupParent">
        {{ item.nameGroupParent | capitalizefirst | truncate : ["30"] }} /
      </span>
      <span>
        {{ item.nom | capitalizefirst | truncate : ["30"] }}
      </span>
      <span>({{ lengh }})</span>
    </div>

    <div class="circle-container">
<!--       <div class="element circle2" >
        <span class="element-number">{{ statesCount?.activeCars.length }}</span>
      </div>
      <div class="element circle3">
        <span class="element-number"> {{ statesCount?.desactiveCars.length }}</span>
      </div>
      <div class="element circle4">
        <span class="element-number"> {{ statesCount?.nonValidCars.length }}</span>
      </div>
      <div class="element circle5"> 
        <span class="element-number">{{ statesCount?.technicalIssueCars.length }}</span>
      </div> -->
      <div class="parent-element">
        <img
          class="pull-right"
          style="margin: 10px"
          *ngIf="item?.imageUrl"
          width="30px"
          src="{{ realTimeService.imagesCarsDir + item?.imageUrl }}"
        />
      </div>
    </div>
  </div>
</div>

<div *ngIf="item.isOpen">
  <group-item [item]="item"></group-item>
</div>
