<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }">
</ngx-loading>

<div class="row parc-management" style="height:100%;font-family: 'Inconsolata', monospace;">
    <section class=" col-lg-12 col-md-12" style="height:100%;">
        <div class="nav-tabs-custom" style="height:100%;">
            <!-- <div class="box box-info">
            </div> -->
            <div class="">
                <div class="row">
                    <form class="col-lg-12 col-md-12 form-horizontal">
                        <div class="col-lg-3 col-md-3 form-group">
                            <label style="width:auto !important;" class="col-lg-2 col-md-2 control-label">Groupe
                            </label>
                            <div class="col-lg-8 col-md-8">
                                <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup"
                                    (ngModelChange)="chooseGroup($event)">
                                    <option *ngFor="let group of groups" [ngValue]="group" style="font-size: 14px;">
                                        <span
                                            *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst | truncate:[30]}}
                                            / </span> {{group.nom | capitalizefirst}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 form-group">
                            <label style="width:auto !important;" class="col-lg-2 col-md-2 control-label">Véhicule
                            </label>
                            <div class="col-lg-8 col-md-8">
                                <select class="form-control input-sm" [(ngModel)]="selectedVehicule" name="vehicules"
                                    (ngModelChange)="chooseVehicule($event)">
                                    <option *ngFor="let vehicule of selectedGroup.vehicules" [ngValue]="vehicule">
                                        {{dataManagementService.getVehiculeName(vehicule)}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 form-group">
                            <label style="width:auto !important;" class="col-lg-2 col-md-2 control-label">Odomètre
                            </label>
                            <div class="col-lg-5 col-md-5">
                                <input type="text" [(ngModel)]="odo" class="form-control input-sm" name="odo"
                                    placeholder="" data-toggle="tooltip" data-placement="top"
                                    title="Modifie le : {{selectedVehicule?.lastOdoUpdate | date :'dd/MM/yyyy HH:mm'}} "style="width: 157px;" />
                            </div>
                            <div class="col-lg-3 col-md-3">
                                <button title="Modifier" style="color: rgb(2, 157, 196); height: 30px;" type="button"
                                    class="btn  btn-default" (click)="updateOdometre()">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 form-group">
                            <p>
                                    <a *ngIf="selectedVehicule?.driver"  style="color: black; text-decoration-color: #3c8dbc;" title="Afficher le conducteur" [routerLink]="['/client/dataManagement/driver/form', selectedVehicule?.driver?.idDriver, 'SHOW']"><input class="form-control" type="text" value="{{selectedVehicule?.driver?.firstName}} {{selectedVehicule?.driver?.lastName}}" disabled></a>
                              <span *ngIf="!selectedVehicule?.driver" data-toggle="tooltip" class="badge label-warning">
                                <i class="fa fa-info" aria-hidden="true"></i> Aucun conducteur trouvé !
                            </span>
                              </p>
                              
                        </div>
                        <div class="col-lg-1 col-md-1 form-group">
                            <button class="btn btn-default" style="float: right; margin-right: -91px;"
                            routerLink="/client/parcManagement/deadlines">
                            Échéance <span class="clock-icon">&#8986;</span>
                            <!-- <img src="/src/assets/icons/parcManagementIcons/deadline.png" alt="" srcset=""> -->
                            </button>
                        </div>
                    </form>
                </div>
                <!-- <ul class="nav nav-tabs " style="width:100%;">
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_CD') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a class="active" routerLink="/client/parcManagement/documentCirculation" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Documents de circulation"
                                src="{{imagesCarsDir}}doc_circul.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Documents de circulation"
                                src="{{imagesCarsDir}}doc_circul2.png" />
                        </a>
                    </li>
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_FUEL') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/carburant" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Carburant"
                                src="{{imagesCarsDir}}fuel.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Carburant"
                                src="{{imagesCarsDir}}fuel2.png" />
                        </a>
                    </li>
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_VIDANGE') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/vidange" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Vidange"
                                src="{{imagesCarsDir}}vidange.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Vidange"
                                src="{{imagesCarsDir}}vidange2.png" />
                        </a>
                    </li>
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_MAINTENANCE') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/entretien" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Entretien"
                                src="{{imagesCarsDir}}entretien.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Entretien"
                                src="{{imagesCarsDir}}entretien2.png" />
                        </a>
                    </li>
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_LOSSES') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/losses" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Dégâts"
                                src="{{imagesCarsDir}}accident.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Dégâts"
                                src="{{imagesCarsDir}}accident2.png" />
                        </a>
                    </li>
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_LEASING') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/leasing" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Location"
                                src="{{imagesCarsDir}}leasing.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Location"
                                src="{{imagesCarsDir}}leasing2.png" />
                        </a>
                    </li>
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_VARIOUS') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/divers" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Divers"
                                src="{{imagesCarsDir}}car-changing-lanes.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Divers"
                                src="{{imagesCarsDir}}car-changing-lanes2.png" />
                        </a>
                    </li>
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_CD') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/driverCharge" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Charges conducteur"
                                src="{{imagesCarsDir}}driver-charge.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Charges conducteur"
                                src="{{imagesCarsDir}}driver-charge2.png" />
                        </a>
                    </li>
                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_DASHBOARD') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/dashbord" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Tableau de bord"
                                src="{{imagesCarsDir}}tdb.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Tableau de bord"
                                src="{{imagesCarsDir}}tdb2.png" />
                        </a>
                    </li>

                    <li *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_DASHBOARD') || signinService.isRootAdmin()"
                        routerLinkActive="active" style="width:10%;">
                        <a routerLink="/client/parcManagement/deadlines" data-toggle="tab">
                            <img class="img-circle size-img-parcmanagement img1" title="Echeance"
                                src="{{imagesCarsDir}}tdb.png" />
                            <img class="img-circle size-img-parcmanagement img2" title="Echeance"
                                src="{{imagesCarsDir}}tdb2.png" />
                        </a>
                    </li>
                </ul> -->

                <!-- new design -->
                <div class="slidemenu" style="margin-top: 0px;">

                    <!-- Item 1 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_CD') || signinService.isRootAdmin()"
                        routerLinkActive="active">
                        <input type="radio" name="slideItem" id="slide-item-1" class="slide-toggle"
                            routerLink="/client/parcManagement/documentCirculation"
                            [checked]="getParentUrl() == 'documentCirculation'" />
                        <label for="slide-item-1" title="Document">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/car-insurance.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Document</span> -->
                        </label>
                    </ng-container>

                    <!-- Item 2 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_FUEL') || signinService.isRootAdmin()"
                        routerLinkActive="active">
                        <input type="radio" name="slideItem" id="slide-item-2"
                            routerLink="/client/parcManagement/carburant" class="slide-toggle"
                            [checked]="getParentUrl() == 'carburant'" />
                        <label for="slide-item-2" title="Carburant">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/gas-pump.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Carburant</span> -->
                        </label>
                    </ng-container>

                    <!-- Item 3 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_VIDANGE') || signinService.isRootAdmin()">
                        <input type="radio" name="slideItem" id="slide-item-3"
                            routerLink="/client/parcManagement/vidange" class="slide-toggle"
                            [checked]="getParentUrl() == 'vidange'" />
                        <label for="slide-item-3" title="Vidange">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/car-oil.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Vidange</span> -->
                        </label>
                    </ng-container>
                    <!-- Item 4 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_MAINTENANCE') || signinService.isRootAdmin()">
                        <input type="radio" name="slideItem" id="slide-item-4"
                            routerLink="/client/parcManagement/entretien" class="slide-toggle"
                            [checked]="getParentUrl() == 'entretien'" />
                        <label for="slide-item-4" title="Entretient">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/wrench.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Entretient</span> -->
                        </label>
                    </ng-container>

                    <!-- Item 5 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_LOSSES') || signinService.isRootAdmin()">
                        <input type="radio" name="slideItem" id="slide-item-5"
                            routerLink="/client/parcManagement/losses" [checked]="getParentUrl() == 'losses'"
                            class="slide-toggle" />
                        <label for="slide-item-5" title="Dégat">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/fender-bender.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Dégat</span> -->
                        </label>
                    </ng-container>

                    <!-- </div> -->
                    <!-- Item 6 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_LEASING') || signinService.isRootAdmin()">
                        <input type="radio" name="slideItem" id="slide-item-6"
                            routerLink="/client/parcManagement/leasing" [checked]="getParentUrl() == 'leasing'"
                            class="slide-toggle" />
                        <label for="slide-item-6" title="Location">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/rental-car.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Location</span> -->
                        </label>
                    </ng-container>

                    <!-- Item 7 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_VARIOUS') || signinService.isRootAdmin()">
                        <input type="radio" name="slideItem" id="slide-item-7"
                            routerLink="/client/parcManagement/divers" [checked]="getParentUrl() == 'divers'"
                            class="slide-toggle" />
                        <label for="slide-item-7" title="Divers">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/spaces.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Divers</span> -->
                        </label>
                    </ng-container>

                    <!-- Item 8 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_CD') || signinService.isRootAdmin()">
                        <input type="radio" name="slideItem" id="slide-item-8"
                            routerLink="/client/parcManagement/driverCharge"
                            [checked]="getParentUrl() == 'driverCharge'" class="slide-toggle" />
                        <label for="slide-item-8" title="Charge">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/driver.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Charge</span> -->
                        </label>
                    </ng-container>

                    <!-- Item 9 -->
                    <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_DASHBOARD') || signinService.isRootAdmin()">
                        <input type="radio" name="slideItem" id="slide-item-9"
                            routerLink="/client/parcManagement/dashbord" [checked]="getParentUrl() == 'dashbord'"
                            class="slide-toggle" />
                        <label for="slide-item-9" title="Tableau de bord">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/financial.svg" class="svgImg"
                                    alt=""></p>
                                    <!-- <span>Tableau de bord</span> -->
                        </label>
                    </ng-container>

                    <!-- Item 10 -->
                    <!-- <ng-container
                        *ngIf="signinService.isHasRole('ROLE_PM') || signinService.isHasRole('ROLE_PM_DASHBOARD') || signinService.isRootAdmin()">
                        <input type="radio" name="slideItem" id="slide-item-9"
                            routerLink="/client/parcManagement/deadlines" [checked]="getParentUrl() == 'deadlines'"
                            class="slide-toggle" />
                        <label for="slide-item-9">
                            <p class="icon"><img src="assets/icons/parcManagementIcons/date-decheance.png" class="svgImg" alt="">
                            </p><span>Echeance</span>
                        </label>
                    </ng-container> -->



                    <div class="clear"></div>

                    <!-- Bar -->
                    <div class="slider" style="margin-top: px;">
                        <div class="bar"></div>
                    </div>

                </div>
                <!-- end new design -->
                <div class="tab-content no-padding heightDiv">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </section>
</div>
