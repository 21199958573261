import {Component, OnInit, Host, ViewChild, ChangeDetectorRef} from '@angular/core';
import {DataManagementComponent} from "../data-management.component";
import {VehiculeService} from '../providers/vehicule.service'
import {Router} from '@angular/router';
import {imagesCarsDir, imagesDir} from "../../../global.config";
import {ToastrService} from "ngx-toastr";
import {Vehicule} from "../data-management.model";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
    selector: 'app-vehicule',
    templateUrl: './vehicule.component.html',
    styleUrls: ['./vehicule.component.css']
})
export class VehiculeComponent implements OnInit {

    @ViewChild('confirmDeleteModal', { static: false }) confirmDeleteModal: ModalDirective;
    @ViewChild('showDocumentModal', { static: false }) showDocumentModal: ModalDirective;

    imgUrl: string;
    imgType: string;
    isImageLoading: boolean = true;
    documents: Map<string, string | null> = new Map<string, string | null>();
    documentArray: [string, string | null][] = [];
    currentIndex: number = 0;

    // pagination vars
    public maxSize: number = 10;
    public bigTotalItems: number = 175;
    public bigCurrentPage: number = 1;
    public numPages: number = 0;
    itemsPerPage = 30;
    keyword: string = '';
  error: { errorMessage: string } = { errorMessage: '' };
  vehicules: Vehicule[] = [];
    currentVehicule: Vehicule;
    imagesDir = imagesDir;
    refreshFiles : Date = new Date();
    displayAll: boolean = false;

    url: string = imagesCarsDir;

    loading: boolean;
    globalLoading: boolean = false;

    constructor(
                private vehiculeService: VehiculeService,
                private router: Router,
                private dataManagementComponent: DataManagementComponent,
                public toastr: ToastrService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.loadVehicule();
    }

    /**
     * loadVehicule : load vehicule according to pagination payload
     * */
    loadVehicule() {
        this.loading = true;
        this.vehiculeService.getListVehicules(this.bigCurrentPage - 1, this.itemsPerPage, this.keyword).subscribe(response => {
            this.vehicules = response.content;
            this.bigTotalItems = response.totalElements;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    /**
     * callback : when page change !
     * */
    public pageChanged(event: any): void {
        this.bigCurrentPage = event.page;
        this.loadVehicule();
    }

    /**
     * confirm delete modal !
     * */
    confirmDeleteVehicule(vehicule: Vehicule) {
        this.currentVehicule = vehicule;
        this.confirmDeleteModal.show();
    }

    /**
     * delete specific driver !
     * */
    deleteVehicule() {
        this.globalLoading = true;
        this.vehiculeService.delete(this.currentVehicule.idVehicule).subscribe((suprimed) => {
            this.globalLoading = false;
            if (suprimed) {
                this.loadVehicule();

                this.toastr.success('Votre suppression est bien effectuée!', 'info');
                this.confirmDeleteModal.hide();
                this.vehiculeService.sendReloadUnusedDevices();
            } else {
                this.toastr.error('L\'operation de suppression est annulée!', 'Error');
            }
        }, () => {
            this.globalLoading = false;
            this.toastr.error('L\'operation de suppression est annulée!', 'Error');
        });
    }

    // gotoDetail(idVehicule) {
    //     this.router.navigate(['/vehicule/details', idVehicule]);
    // }
    next() {
        if (this.currentIndex < this.documentArray.length - 1) {
          this.currentIndex++;
        }else if(this.currentIndex === this.documentArray.length - 1){
            this.currentIndex = 0;
        }
        this.updateImage();
      }

      before() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        }else if(this.currentIndex === 0) {
            this.currentIndex = this.documentArray.length - 1;
        }
        this.updateImage();
      }

      updateImage() {
        const currentEntry = this.documentArray[this.currentIndex];
        this.imgType = currentEntry[0];
        this.imgUrl = (this.imgType === 'Car Image' || currentEntry[1].endsWith('sign-add-icon.png')) ? currentEntry[1] : "https://rimtrack.com"+currentEntry[1];
      }

    /**
     * search specific vehicule !
     * */
    searchVehicule() {
        this.bigCurrentPage = 1;
        this.loadVehicule();
    }
    selectedVehicule: any;
    groupsNames: string;

    showDetails(vehicule: any){
        this.selectedVehicule = null;
        if(vehicule.groups.lenght > 1){
            for(var i = 0; i < vehicule.groups.lenght ; i++){
                this.groupsNames = vehicule.groups[i+1].nom + ", ";
            }
        }
        this.loadVehiculeById(vehicule);
    }

    showDocumentsChild(emit: {imgUrl: string, imgType: string, all: boolean, documents: Map<string, string | null>}){
        this.displayAll = emit.all;
        this.documents = emit.documents;
        this.documentArray = Array.from(this.documents.entries());
        this.showDocuments(this.documentArray.findIndex(entry => entry[0] === emit.imgType));
    }
    showDocuments(index: number){
        this.currentIndex = index; // Start with the first image
        this.updateImage();
        this.showDocumentModal.show();
    }
    loadVehiculeById(vehicule: any) {
       // this.loading = true;
        this.vehiculeService.getOneWithDocuments(vehicule.idVehicule).subscribe(
          vec => {
            this.selectedVehicule = vec;
            this.selectedVehicule.groups = vehicule.groups
       //     this.loading = false;
           // this.currentVehicule.circulationAt = new Date(vehicule.circulationAt);
            // this.selectedImage = this.getImageByUri(vehicule.icon);
          },
          error => {
            this.error = error;
            this.error = JSON.parse(JSON.stringify(error.error || null));

            if (!this.error.errorMessage) {
              this.error.errorMessage = "SERVER ERROR";
            }

        //    this.loading = false;
          }
        );
      }
}
