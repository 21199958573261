import { Component, OnInit, Input } from '@angular/core';
import { ChargePfbInfoDto, ChargeRealise, VoyageDto } from '../../charges-pfb';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FuelingDashboardDto } from 'src/app/client-management/parc-management';
const Highcharts3 = require('highcharts');
require('highcharts/modules/exporting')(Highcharts3);
require('highcharts/modules/no-data-to-display')(Highcharts3);
require('highcharts/highcharts-more')(Highcharts3);

@Component({
  selector: 'app-correlation-gasoil',
  templateUrl: './correlation-gasoil.component.html',
  styleUrls: ['./correlation-gasoil.component.css']
})
export class CorrelationGasoilComponent implements OnInit {
  chargePfbInfoDto: ChargePfbInfoDto;
  fuelingCostCharge: Object | null = null;
  turnoverAchievedTimeLineChart: Object | null = null;
  globalLoading: boolean;
  // voyage_: VoyageDto = null;

  selectedCharge: ChargePfbInfoDto = new ChargePfbInfoDto();
  dayDif: number = 0;
  chart: any;
  caRealise_: any[] = [];
  percentageRealise = 0;
  percentageCible = 0;

  fuelings_: FuelingDashboardDto[] = [];


  @Input()
  set selectedCharges(chargePfb: ChargePfbInfoDto) {
    if (chargePfb) {
      this.selectedCharge = chargePfb;
    }
  }

  @Input()
  set dayDifference(dayDifference: number) {
    this.dayDif = dayDifference;
  }

  @Input() isManually: any;

  @Input()
  set caRealise(caRealise: ChargeRealise[]) {
    this.caRealise_ = Object.assign(caRealise);
    if (this.isManually) {
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.gaugeChart();
    }
  }

  @Input()
  set fueling(fueling: FuelingDashboardDto[]) {
    if (!this.isManually) {
      this.fuelings_ = fueling;
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.gaugeChart();
    }
  }

  constructor(public toastr: ToastrService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.gaugeChart();
  }


  timeChart() {

    var data: { x: Date, y: number }[] = [];
    var index = 0;

    if (this.selectedCharge) {
      var budget = (this.selectedCharge.caTransport / this.selectedCharge.nbDays);
      var fuelCharge = (this.selectedCharge.fuelCharge / this.selectedCharge.nbDays);

      this.percentageCible = (fuelCharge / budget) * 100;
    }

    this.caRealise_.forEach(ca => {

      var carburant;
      if (this.isManually)
        carburant = ca.carburant;
      else {
        let fuel = [];
        if (this.fuelings_ && this.fuelings_.length > 0) {
          let operationTime = new Date(ca.date);
          fuel = this.fuelings_.filter(f => new Date(f.operationDate).getTime() == operationTime.getTime());
          if (fuel.length > 0) {
            carburant = fuel[0].cost;
          }
        }
      }

      data.push({ x: ca.date, y: (carburant / ca.caRealise) * 100 });
      if (index == this.caRealise_.length - 1){
        this.percentageRealise = (carburant / ca.caRealise) * 100;
      }
      index++;

    })

    this.turnoverAchievedTimeLineChart = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      plotOptions: {
        series: {
          connectNulls: true
         },
        enableMouseTracking: false
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: ' %'
      },
      title: {
        text: 'Corrélation Charge gasoil/Chiffre d\'affaire',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 86400000,
        labels: {
          formatter: () => {
            // Here we need to get the value using `this` context from the arrow function
            const context = (this as any); // Use 'any' or define a more specific type
            return Highcharts3.dateFormat("%d/%m/%Y", context.value);
          }

        }

      },
      yAxis: {
        minRange: this.percentageCible,
        min: 0,
        plotLines: [{
          value: this.percentageCible,
          color: '#ff0000',
          dashStyle: 'shortdash',
          width: 2,
          zIndex: 4,
          label: {
            text: 'Cible',
            textAlign: 'center'
          }
        }],
      },
      series: [{
        name: 'Réalisé',
        data: data,
        zones: [{
          value: this.percentageCible,
          color: 'green'
        }, {
          value: this.percentageCible + 5,
          color: 'orange'
        }, {
          color: 'red'
        }]
      }]

    }, Highcharts3.chart('fuelingCostChart', this.turnoverAchievedTimeLineChart);
  }

  gaugeChart() {

    var color = '#FFA500';
    this.percentageRealise = 0;

    if (this.selectedCharge) {
      var budget = (this.selectedCharge.caTransport / this.selectedCharge.nbDays) * this.dayDif;
      var fuelCharge = (this.selectedCharge.fuelCharge / this.selectedCharge.nbDays) * this.dayDif;

      this.percentageCible = (fuelCharge / budget) * 100;
    }
    if (this.caRealise_) {
      var caR = this.caRealise_.filter(ca => ca.caRealise > 0);
      if (caR.length > 0) {
        if (this.isManually) {
          var caCarburant = this.caRealise_.filter(ca => ca.carburant > 0);
          if (caCarburant.length > 0) {
            this.percentageRealise = (caCarburant[caCarburant.length - 1].carburant / caR[caR.length - 1].caRealise) * 100;
          }
        } else {
          let fuel = [];
          let lastFuel = 0;
          if (this.fuelings_ && this.fuelings_.length > 0) {
            fuel = this.fuelings_.filter(f => new Date(f.operationDate).getTime() == new Date(caR[caR.length - 1].date).getTime());
            if (fuel.length > 0) {
              lastFuel = fuel[0].cost;
            }
          }
          this.percentageRealise = (lastFuel / caR[caR.length - 1].caRealise) * 100;
        }
      }
    }

    if (this.percentageCible > 0) {
      if (this.percentageRealise >= this.percentageCible) {
        if ((this.percentageRealise - this.percentageCible) > 5) {
          color = "#c90000";
        }
      } else {
        color = '#008000';
      }
    }

    this.fuelingCostCharge = {

      chart: {
        type: 'solidgauge',
      },
      title: {
        text: 'Corrélation Charge gasoil/Chiffre d\'affaire',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
        enabled: false,
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ // Réalisé
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: '#f5b198',
          borderWidth: 0
        }]
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {

          dataLabels: {
            enabled: true,
            y: -40,
            borderWidth: 0,
            backgroundColor: 'none',
            useHTML: true,
            shadow: false,
            style: {
              fontSize: '13px'
            },
            // formatter: function (): string {
            //   return '<div style="width:100%;text-align:center;"><span style="font-size:13px;font-weight:bold;">' + this.point.series.name + '</span><br/><span style="font-size:3em;color:' + color + ';font-weight:bold;">' + Highcharts3.numberFormat(this.y, 1) + '%</span>';
            // }

          },

          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },

      series: [{
        name: 'Réalisé',
        data: [{
          color: color,
          radius: '87%',
          innerRadius: '63%',
          y: this.percentageRealise
        }]
      }],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        noData: 'Aucune donnée n\'est disponible'
      }
    },
      Highcharts3.chart('fuelingCostChart', this.fuelingCostCharge)
  }



}
