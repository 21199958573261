import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RealtimeService } from '../../../services/realtime.service';
import { RealtimeRestService } from '../../../services/realtime-rest.service';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { PoiClient, PointInterest, Vehicule } from 'src/app/client-management/data-management';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { RealtimeComponent } from '../../../realtime.component';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { RealTimeRecord } from '../../../model/real-time.model';
import { GroupsContainerComponent } from '../groups-container.component';

@Component({
  selector: 'group-item-buttons',
  templateUrl: './group-item-buttons.component.html',
  styleUrls: ['./group-item-buttons.component.css'],
})
export class GroupItemButtonsComponent implements OnInit {
  @Input() vehicule: Vehicule;


  constructor(
    public realRestTimeService: RealtimeRestService,
    public realTimeService: RealtimeService,
    public signinService: SigninService,
    public geocodingService: GeocodingService,
    public router: Router,
    private route: ActivatedRoute,
    public realtimeComp: RealtimeComponent,
    public realTime: RealtimeComponent,
    public mapService: MapService,
    public groupsContainerComponent: GroupsContainerComponent
  ) {}

  ngOnInit() {}

  goToRealTime() {
    this.mapService.clearPolylines();
    this.realTimeService.stopTimerTracking();
    this.realTimeService.currentPathClickedDeviceId= this.vehicule.idDevice;
    this.realTimeService.trackingMode = true;
    this.mapService.controlMarkers(this.vehicule.idDevice, true);
    this.router.navigate([this.vehicule.idDevice, 'bartool'], {relativeTo: this.route});
  }

  startEngine(deviceId: any) {
    if (confirm('es vous sur ? (Lancement moteur)')) {
      this.realRestTimeService.startEngine(deviceId).subscribe((cmdSended) => {
        alert('commande lancement moteur envoyé !');
      });
    }
  }

  stopEngine(deviceId: any) {
    if (confirm('es vous sur ? (Arrêt moteur)')) {
      this.realRestTimeService.stopEngine(deviceId).subscribe((cmdSended) => {
        alert('commande arrêt moteur envoyé !');
      });
    }
  }

  goToVehiculeDetail(vehicule: Vehicule) {
    this.router.navigate([
      '/client/dataManagement/vehicule/form/',
      vehicule.idVehicule,
      'SHOW',
    ]);
  }
  goToHistoricalPath() {
    this.router.navigate(['/client/historical/path']);
  }

  goToHistoricalStop() {
    this.router.navigate(['/client/historical/stop']);
  }

  goToFuelData() {
    this.router.navigate(['/client/statistical/fuelData']);
  }

  displayDashboard(vehicule: Vehicule) {
    this.realtimeComp.carDashboard.openening() ;
    this.realtimeComp.displayDashboard(vehicule);
  }


  displayGoogleAdress(vehicule: Vehicule) {
    if (vehicule && vehicule.realTimeRecord) {
      this.geocodingService
        .inverseGeocondingGoogle(
          vehicule.realTimeRecord.coordinate.lat,
          vehicule.realTimeRecord.coordinate.lng
        )
        .subscribe((response) => {
          if (response && response.results[0]) {
            vehicule.realTimeRecord.geocoding =
              response.results[0].formatted_address;
            response.results.forEach((res: any) => {
              vehicule.realTimeRecord.geocodingDetails +=
                res.formatted_address + ' ,';
            });
          }
        });
    }
  }
}
