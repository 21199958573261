
<div class="row">
  <section class="content col-md-4 groups-container rm-bgcolor">
      <!-- Vehicule according to P.O.I -->
      <div class="col-md-12">
          <div class="box box-info collapsed-box" style="margin-bottom: 5px;margin-left: -7px;">
              <div class="box-header with-border">
                  <h3 class="box-title"
                      style="font-family: 'Inconsolata', monospace;font-size: 14px;;font-weight: bold;color: black">
                      <i class="fa fa-map"></i> Position
                      par P.O.I</h3>
                  <div class="box-tools pull-right">
                      <button type="button" class="btn btn-box-tool" data-widget="collapse" (click)="changeContentStyle()"><i class="fa fa-plus"
                              style="color:black"></i>
                      </button>
                  </div>
              </div>
              <div class="box-body">
                  <div class="conten" data-widget="collapse">
                      <app-vehicule-according-poi></app-vehicule-according-poi>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <div class="col-md-2">
  </div>
  <section class="content col-md-6 groups-container rm-bgcolor min-hgt">
  <div class="col-md-12">
      <div class="box box-info collapsed-box">
          <div class="box-header with-border">
              <app-vidange-chart></app-vidange-chart>
          </div>
      </div>
</div>
</section>

<!-- adde nw  -->

<section class="content col-md-6 groups-container rm-bgcolor min-hgt" style="margin-top: 32px;">
  <div class="col-md-12 datatable-container-col">
      <div class="box" style="border-radius: 0px;">
          <div class="overlay" *ngIf="!isResultLoaded">
              <i class="fa fa-refresh fa-spin"></i>
          </div>
          <div class="box-body">
              <form class="form-horizontal">
                  <div class="form-group">
                      <div class="row">
                      <label class="col-sm-2 control-label grp-lbl">Groupe</label>
                      <div class="col-sm-3 grp-slct">
                          <select class="form-control input-sm" [(ngModel)]="selectedGroup" name="groups" (ngModelChange)="chooseGroup($event)">
                              <option *ngFor="let group of groups" [ngValue]="group">
                                <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst | truncate:[30]}}  / </span>  {{group.nom}}
                              </option>
                          </select>
                      </div>
                      <label class="col-sm-2 control-label vhcl-lbl">Vehicule</label>
                      <div class="col-sm-3 vhcl-slct">
                          <select class="form-control input-sm" [(ngModel)]="selectedVehicule" name="vehicules" (ngModelChange)="chooseVehicule($event)">
                              <option *ngFor="let vehicule of selectedGroup.vehicules" [ngValue]="vehicule">{{dataManagementService.getVehiculeName(vehicule)}}
                              </option>
                          </select>
                      </div>
                  </div>
                  <div class="row pad">
                      <label class="col-sm-2 control-label dd-lbl">Date début</label>
                      <div class="col-sm-3 dd-npt">
                          <div class="input-group">

                              <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}" placement="bottom" [maxDate]="endDate" >
                              <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                          </div>
                      </div>
                      <label class="col-sm-2 control-label df-lbl">Date fin</label>
                      <div class="col-sm-3 df-npt">
                          <div class="input-group">

                              <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm', containerClass: 'theme-dark-blue'}" placement="bottom" [minDate]="startDate">
                              <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                          </div>
                      </div>
                      <div class="col-sm-1 form-btns">
                          <button type="button" class="btn btn-success btn-flat btn-sm" (click)="displayChart()">
                              Afficher
                          </button>
                      </div>
                  </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
  <div class="col-md-12 charts">
      <div id="container"></div>
  </div>
</section>
<section class="content col-md-6 groups-container rm-bgcolor min-hgt" style="margin-top: 32px;">

    <div class="col-md-12 datatable-container-col">
        <div class="box" style="border-radius: 0px;">
            <div class="overlay" *ngIf="!isResultLoaded">
                <i class="fa fa-refresh fa-spin"></i>
            </div>
            <div class="box-body">
                <form class="form-horizontal">
                    <div class="form-group">
                        <div class="row">
                        <label class="col-sm-2 control-label grp-lbl">Groupe</label>
                        <div class="col-sm-3 grp-slct">
                            <select class="form-control input-sm" [(ngModel)]="selectedGroup" name="groups" (ngModelChange)="chooseGroup($event)">
                                <option *ngFor="let group of groups" [ngValue]="group">
                                    <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst | truncate:[30]}}  / </span>  {{group.nom}}
                                </option>
                            </select>
                        </div>
                        <label class="col-sm-2 control-label vhcl-lbl">Vehicule</label>
                        <div class="col-sm-3 vhcl-slct">
                            <select class="form-control input-sm" [(ngModel)]="selectedVehicule" name="vehicules" (ngModelChange)="chooseVehicule($event)">
                                <option *ngFor="let vehicule of selectedGroup.vehicules" [ngValue]="vehicule">{{dataManagementService.getVehiculeName(vehicule)}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row pad">
                        <label class="col-sm-2 control-label vhcl-lbl">Année</label>
                        <div class="col-sm-3">
                            <select class="form-control input-sm select3" (change)="onChangeYear($event.target.value)">
                                <option [value]="Years[i].id" *ngFor="let year of Years; let i = index">
                                    {{Years[i].name}}
                                </option>
                            </select>
                        </div> 
                       
                        <div class="col-sm-2 form-btns">
                            <button type="button" class="btn btn-success btn-flat btn-sm" (click)="carburantInfo()">
                                <i class="fa fa-search pull-left" aria-hidden="true"></i>
                                Valider
                            </button>
                        </div>
                    </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

  <div class="col-md-12 carb-bg">
    <app-carburant-dashbord [fuels]="fuels" [currentCarbSetting]="currentCarbSetting" [vehicule]="selectedVehicule"></app-carburant-dashbord>
</div>
</section>
<!-- end added now  -->
</div>

<div>
  <app-leaflet [according]="true"></app-leaflet>
</div>
