<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
<div class="row" style="margin-top: -9px;margin-bottom: -20px;">
  <div style="border-radius: 0px;min-height: unset; margin-bottom: 0px !important;">
    <div class="box-body" style="padding: 8px 10px 0px 10px !important;">
      <div class="form-group row" style="margin-bottom: -4px !important;">
        <div class="col-md-2">
          <div class="row" style="width: 350px;">
            <div class="col-md-4 selectStyle">
              <div class="grp-slct">
                <select class="form-control input-sm" name="transport" [ngModel]="selectedTransport"
                (ngModelChange)="chooseTransport($event)" required>
                  <option *ngFor="let transport of transportTypeService?.transportTypes" [ngValue]="transport">
                    {{transport?.name}}
                  </option>
                </select>
                <label>Pôle</label>
              </div>
            </div>
            <div class="col-md-4 selectStyle" style="margin-left: -25px;">
              <div  style="display: grid; grid-template-columns: 106px 48px; gap: 5px;">
                <div class="grp-slct">
                  <select class="form-control input-sm" name="passage" [ngModel]="selectedPassage"
                  (ngModelChange)="chooseSchedule($event)" required>
                    <option *ngFor="let passage of passageService?.passages" [ngValue]="passage">
                      {{passage?.name}}
                    </option>
                  </select>
                  <label>Site</label>
                </div>
              </div>
            </div>
            <div class="col-md-4 selectStyle">
              <div class="form-btns">
                <button type="button" (click)="loadMissions()" class="btn btn-success btn-flat btn-sm" style="border-radius: 5px; padding: 5px;"
                  title="Valider"><span style="animation: blinker 1s linear infinite;"><b>{{getTrackingTime()}}</b></span> <b><i class="fa fa-refresh" aria-hidden="true"></i></b>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <h1 class="heading">Tracking Mission</h1>
        </div>
        <div class="col-md-2">
          <li class="dropdown messages-menu">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                        <i class="fa fa-clock-o"  style='font-size:28px;color:red'></i>
                        <span style="font-size: 8px;POSITION: absolute;" class="label label-success">{{late_cars.length}}</span>
                    </a>
                    <ul class="dropdown-menu" style="text-align: center; width: 200px;margin-left: -118px;">
                        <li *ngIf="late_cars.length > 0" class="header"
                            style="font-family: sans-serif;font-weight: bolder; ">Notifications Arret POI</li>
                        <li *ngIf="late_cars.length == 0" class="header"
                            style="font-family: 'Inconsolata', monospace;">Aucune Notifications Arret POI</li>
                          <hr style="margin-top: 0;margin-bottom: 0;">
                        <li>
                            <!-- inner menu: contains the actual data -->
                            <ul class="menu">
                                <li style="list-style-type: initial; font-weight: 500;" *ngFor="let late_car of late_cars">
                                    <!-- start message -->
                                    {{late_car. matricule}} <span style="color: red;">({{late_car.duree * 1000 | date:'H:mm:ss':'UTC'}})</span>
                                </li>
                                <!-- end message -->
                            </ul>
                        </li>
                    </ul>
                </li>
        </div>
      </div>
    </div>
  </div>
</div>
<hr style="margin-top: 0px;margin-bottom: 20px;">




<!-- TRACKING STATUS -->
<div *ngFor="let trackingData of trackingMissionData; let i = index;" class="row tracking-status">
  <div *ngIf="trackingData.selectedMission != null && trackingData.trackingMission != null " class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="stack-status">
            <div class="tracking-status-timeline"
                [ngStyle]="{'width': ((trackingData?.selectedMission?.routeLegs?.length) * 50) + '%'}">
                <ng-container *ngFor="let route of trackingData?.trackingMission?.mission?.routeLegs; let i = index">
                  <div *ngIf="route?.selected" class="tracking-status-timeline-completion"
                      [ngStyle]="{'left': 0 + '%','width': (( 50 * trackingData?.trackingMission?.progressPercent) / 100 + (i * 50)) + '%',
                      'background': trackingMission?.state == 'DELIVERY' ? '#00a65a' :
                        trackingData.trackingMission?.state != 'DELAY' && trackingData.trackingMission?.progressPercent == 100 ? '#00a65a' :
                        trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate ? '#f39c12' :
                        trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL' ? '#dd4b39' : '#4ab4dd'
                      }"
                      matTooltip="{{(trackingData?.trackingMission?.realTimeRecord?.recordTime | date:'HH:mm')+' / '+(trackingData.trackingMission?.progressPercent | number:'.0-2')}}%">
                      <div class="selected">
                          <img *ngIf="trackingData?.selectedVehicule.icon" class=" tooltip-350" [popover]="trackingPopOver"
                              [placement]="i+1==trackingData?.selectedMission?.routeLegs?.length && trackingData?.selectedMission?.routeLegs?.length>2 ? 'left': 'right'"
                              container="body" #pop="bs-popover" [ngStyle]="{'width': imgsrc=='truck.png' ? 60+'px' : 30+'px','height': imgsrc=='truck.png' ? 30+'px' : 45+'px','margin-top': imgsrc=='truck.png' ? 5+'px' : 2+'px'}" class=" tooltip-350" src="assets/images/flatbed-truck.png"
                              alt="img" [outsideClick]="true" [adaptivePosition]="false" />
                          <img *ngIf="!trackingData?.selectedVehicule.icon" class=" tooltip-350" [popover]="trackingPopOver"
                              [placement]="i+1==trackingData?.selectedMission?.routeLegs?.length && trackingData?.selectedMission?.routeLegs?.length>2 ? 'left': 'right'"
                              container="body" #pop2="bs-popover" src="{{imagesCarsDir + 'automobile_green.png'}}"
                              alt="img" [outsideClick]="true" [adaptivePosition]="false" />
                          <p class="kls"><b>{{trackingData.selectedVehicule?.matricule}}</b></p>
                      </div>
                  </div>
              </ng-container>
            </div>

            <ng-container *ngFor="let route of trackingData?.selectedMission?.routeLegs; let i = index; let j = index">
                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingData?.trackingMission?.mission?.routeLegs[i]?.visited,
                    'green' : trackingData.trackingMission?.state != 'DELAY' && trackingData.trackingMission?.progressPercent == 100,
                    'orange' : trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate,
                    'red' : trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL'
                }"
                [ngStyle]="{'left': (i * 50) + '%'}" >
                    <span *ngIf="trackingData.trackingMission?.state != 'PENDING'" class="statusHeure"
                        title="{{trackingData?.trackingMission?.startDate | date:'dd-MM-yy HH:mm'}}">{{route?.labelPOISource}}</span>
                <div class="icon"></div>
            </div>
            <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
              [ngClass]="{'active': trackingData?.trackingMission?.mission?.routeLegs[i]?.visited,
              'green' : trackingData.trackingMission?.state != 'DELAY' && trackingData.trackingMission?.progressPercent == 100,
              'orange' :trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate,
              'red' : trackingData?.trackingMission?.timeinPoiClient > 5400
              }"
                [ngStyle]="{'left': ((i + 1) * 50) + '%'}" matTooltip="{{route?.labelPOITarget}} | {{trackingData?.trackingMission?.timeinPoiClient * 1000 | date:'H:mm:ss':'UTC'}}">
                <span style="margin-left: -38px;" *ngIf="trackingData.trackingMission?.state != 'PENDING'" class="statusHeure kls">{{route?.labelPOITarget}}</span>
                <div class="icon"></div>
            </div>
            <div *ngIf="i > 0" class="image-tracking-status image-tracking-status img-circle"
              [ngClass]="{'active': trackingData?.trackingMission?.mission?.routeLegs[i]?.visited,
              'green' : trackingData.trackingMission?.state != 'DELAY' && trackingData.trackingMission?.progressPercent == 100,
              'orange' : trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate,
              'red' : trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL'
              }"
                [ngStyle]="{'left': ((i + 1) * 50) + '%'}">
                <span *ngIf="trackingData.trackingMission?.state != 'PENDING'" class="statusHeure kls">{{route?.labelPOITarget}}</span>
                <div class="icon"></div>
            </div>
                <span *ngIf="i == 0" style="font-weight: bold;" [ngStyle]="{'left': (i + 1 * 25) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
                <span *ngIf="i > 0" style="font-weight: bold;" [ngStyle]="{'left': ( (i+i + 1) * 25) + '%'}" class="klm">{{route?.travelDistance
                    | number:'.0-2'}} Km</span>
            </ng-container>
        </div>
        </div>
        <div class="col-md-6" style="margin-top: -18px;">
          <fieldset class="mt-2" style="margin-left: 85px;">
            <!-- <legend class="mb-0">Info Mission</legend> -->
            <div>
                <!-- <ng-container> Matricule : <b>{{trackingData.selectedVehicule?.matricule}}</b></ng-container> |
                <ng-container> Vitesse :
                    <b>{{trackingData.trackingMission?.realTimeRecord?.speed}}</b>
                </ng-container>| <ng-container> Position : <b
                        title="{{trackingData.trackingMission?.realTimeRecord?.geocoding}}">
                        {{trackingData.trackingMission?.realTimeRecord?.geocoding | truncate :[25]}}</b>
                </ng-container>|-->
                <ng-container> Site Départ : <b>{{trackingData?.selectedMission?.routeLegs[0]?.labelPOISource}}</b>
                </ng-container>|
                <ng-container> client : <b>{{trackingData?.selectedMission?.routeLegs[1]?.labelPOISource | truncate :[25]}}</b>
                </ng-container>|
                <ng-container> Site Arrivé : <b>{{trackingData?.selectedMission?.routeLegs[1]?.labelPOITarget}}</b>
                </ng-container>|
                <ng-container> Arret Poi : <b [style.color]="trackingData?.trackingMission?.timeinPoiClient > 5400 ? 'red' : 'black'">{{trackingData?.trackingMission?.timeinPoiClient * 1000 | date:'H:mm:ss':'UTC'}}</b>
                </ng-container>|
                <ng-container> Durée : <b>{{selectedRouteLeg(i)?.travelDuration | timediff}}</b></ng-container>
                <!-- <br /> -->
                <ng-container> A
                    proximité : <b>{{trackingData.trackingMission?.firstNearest ?
                        selectedRouteLeg(i)?.labelPOISource :
                        selectedRouteLeg(i)?.labelPOITarget}}</b>
                    de {{trackingData.trackingMission?.minDistance | number:'.0-2'}} Km</ng-container> |
                <ng-container> Distance
                    Parcourue :
                    <!-- <b>{{trackingData.selectedMission.travelDistance*trackingData?.trackingMission?.progressPercent / 100  | number:'.0-2'}} -->
                    <b>{{trackingData?.trackingMission?.accordingDistance | number:'.0-2'}}
                        Km</b></ng-container>|
                <ng-container *ngIf="trackingData.trackingMission?.startDate">| Date debut :
                    <b>{{trackingData.trackingMission?.startDate | date:'dd-MM-yy HH:mm'}}</b>
                </ng-container>
                | <ng-container>Distance restante : <b>{{trackingData.selectedMission.travelDistance - (trackingData.selectedMission.travelDistance*trackingData?.trackingMission?.progressPercent / 100)| number:'.0-2'}} Km</b></ng-container>
                <ng-container *ngIf="trackingData.trackingMission?.endDate">| Date Fin :
                  <b>{{trackingData.trackingMission?.endDate | date:'dd-MM-yy HH:mm'}}</b>
                </ng-container>
                <!--<ng-container *ngIf="trackingData.trackingMission?.state == 'DELAY'">
                    | Durée du retard : <b>{{ trackingData.trackingMission?.delayDuration }}</b>
                     | Durée du retard : <b>{{ calcDelayTime(trackingData.trackingMission?.endDate, trackingData.trackingMission?.startDate,trackingData.trackingMission?.estimatedEndDate) | timediff}}</b>
                </ng-container> -->
                <br />
                <span *ngIf="trackingData.trackingMission?.progressPercent == 0" class="badge label-secondary">En
                    attente</span>
                    <span *ngIf="trackingData.trackingMission?.state == 'INPROGRESS' && trackingData.trackingMission?.progressPercent != 0" class="badge label-info">En
                      cours</span>
                      <!-- <span *ngIf="trackingData.trackingMission?.progressPercent > 50 && trackingData.trackingMission?.progressPercent < 100" class="badge label-info">En
                        Retour</span> -->
                <span *ngIf="trackingData.trackingMission?.state != 'DELAY' && trackingData.trackingMission?.progressPercent == 100 && trackingData.trackingMission?.estimatedEndDate == trackingData.trackingMission.endDate" class="badge label-success">Arrivé à
                    temps</span>
                <span *ngIf="trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate" class="badge label-warning">Arrivé en
                    retard</span>
                <span *ngIf="trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL'" class="badge label-danger">Arrivée
                    probable</span>
            </div>
        </fieldset>
        </div>
      </div>
  </div>

  <!-- START TRACKING INFO -->
<ng-template #trackingPopOver>
  <fieldset class="without-border">
      <div>
          <h4>Info mission</h4>
          <ul class="listeNiveauUn jqInner">
              <li> <span>Poi de départ :
                      <b *ngIf="trackingData?.selectedMission">{{trackingData?.selectedMission?.routeLegs[0]?.labelPOISource}} </b>
                      <b *ngIf="!trackingData?.selectedMission">-</b>
                  </span></li>
              <li> <span>Poi d'arrivé :
                      <b *ngIf="trackingData?.selectedMission">
                          {{trackingData?.selectedMission?.routeLegs[trackingData?.selectedMission?.routeLegs?.length - 1]?.labelPOITarget}} </b>
                      <b *ngIf="!trackingData?.selectedMission">-</b>
                  </span></li>
              <li> <span>Kilométrage :
                      <b *ngIf="trackingData?.selectedMission">{{trackingData?.selectedMission?.travelDistance | number:'.0-2'}} Km</b>
                      <b *ngIf="!trackingData?.selectedMission">-</b>
                  </span></li>
              <li> <span>Durée :
                      <b *ngIf="trackingData?.selectedMission">{{trackingData?.selectedMission?.travelDuration | timediff}}</b>
                      <b *ngIf="!trackingData?.selectedMission">-</b>
                  </span></li>

              <li> <span>Distance restante :
                      <b *ngIf="trackingData?.trackingMission">{{trackingData?.selectedMission?.travelDistance -
                          (trackingData?.trackingAccordingDistance+trackingData?.trackingMission?.accordingDistance) | positiveNumber |
                          number:'.0-2'}}
                          Km</b>
                      <b *ngIf="!trackingData?.trackingMission">-</b>
                  </span></li>
          </ul>
      </div>
  </fieldset>
  <fieldset class="without-border">
      <div>
          <h4>Info trajet en cours</h4>
          <ul class="listeNiveauUn jqInner">
              <li> <span>Vitesse :
                      <b *ngIf="trackingData?.trackingMission">{{trackingData?.trackingMission?.realTimeRecord?.speed}} Km/H</b>
                      <b *ngIf="!trackingData?.trackingMission">-</b>
                  </span></li>
              <li> <span>Position actuelle :
                      <b *ngIf="trackingData?.trackingMission" matTooltip="{{trackingData?.trackingMission?.realTimeRecord?.geocoding}}">
                          {{trackingData?.trackingMission?.realTimeRecord?.geocoding | truncate :[60]}}</b>
                      <b *ngIf="!trackingMission">-</b>
                  </span></li>
              <li> <span>Durée :
                <b *ngIf="selectedRouteLeg(i)">{{selectedRouteLeg(i)?.travelDuration | timediff}}</b>
                <b *ngIf="!selectedRouteLeg(i)">-</b>
                  </span></li>
              <!-- <li> <span>A proximité P.O.I :
                      <b *ngIf="selectedRouteLeg && trackingMission">{{selectedRouteLeg?.travelDistance -
                          trackingMission?.accordingDistance | positiveNumber | number:'.0-2'}} Km de
                          {{selectedRouteLeg?.firstNearest ? selectedRouteLeg?.labelPOISource :
                          selectedRouteLeg?.labelPOITarget}}</b>
                      <b *ngIf="!selectedRouteLeg || !trackingMission">-</b>
                  </span></li> -->

                   <li> <span>A proximité de P.O.I :
                      <b *ngIf="trackingData?.trackingMission?.mission?.routeLegs && trackingData?.trackingMission">
                          {{trackingData?.trackingMission?.firstNearest ? selectedRouteLeg(i)?.labelPOISource :
                              selectedRouteLeg(i)?.labelPOITarget}}
                          de {{trackingMission?.minDistance | number:'.0-2'}} Km
                      </b>
                      <b *ngIf="!selectedRouteLeg(i) || !trackingMission">-</b>
                  </span></li>

              <li> <span>Distance parcourue :
                      <b *ngIf="trackingData?.trackingMission">{{trackingData?.trackingMission?.accordingDistance | number:'.0-2'}} Km</b>
                      <b *ngIf="!trackingData?.trackingMission">-</b>
                  </span></li>

              <li><span> Durée restante :
                      <b *ngIf="trackingData?.trackingMission">{{trackingData?.trackingMission?.accordingDuration | timediff}}</b>
                      <b *ngIf="!trackingData?.trackingMission">-</b>
                  </span></li>

          </ul>
      </div>
  </fieldset>
</ng-template>
<!-- END TRACKING INFO -->
</div>
<!-- END TRACKING STATUS -->
