<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
<div class="row">
    <div class="col-md-4 text-left" style="margin-top: -10px; margin-bottom: 40px;">
        <span (click)="loadMissions()" title="Add mission" style="cursor: pointer;">
            <i class="fa fa-plus-circle add" aria-hidden="true"></i></span>
    </div>
    <div class="col-md-4">
        <div *ngIf="loadingTracking" style="font-size: 12px; text-align: center;">
            <i class="fa fa-spinner fa-spin" *ngIf="loadingTracking"></i> Chargement ...
        </div>
        <div *ngIf="updateTrackingTimer && !loadingTracking" style="font-size: 12px;text-align: center;">
            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
            Mise à jour dans {{updateTrackingTimer}} seconds...
        </div>
    </div>
    <div *ngIf="trackingMissionDataSorted.length > 0" class="col-md-4 text-right"
        style="margin-top: -10px; margin-bottom: 40px;">
        <span (click)="onTracking(0, true)" title="Refresh mission" style="cursor: pointer;">
            <i *ngIf="!loadingTracking" class="fa fa-refresh add" aria-hidden="true"></i>
            <i *ngIf="loadingTracking" class="fa fa-refresh fa-spin add" aria-hidden="true"></i></span>
    </div>
</div>

<!-- trackingMissionData -->
<ng-container *ngFor="let trackingData of trackingMissionDataSorted; let i = index;">

    <!-- TRACKING STATUS -->
    <div *ngIf="trackingData.selectedMission" class="row tracking-status">
        <div class="col-md-6">
            <div class="stack-status">
                <div class="tracking-status-timeline"
                    [ngStyle]="{'width': ((trackingData.selectedMission?.routeLegs?.length) * 75) + '%'}">
                    <ng-container *ngFor="let route of trackingData.trackingMission?.mission?.routeLegs; let i = index">
                        <div *ngIf="route?.selected" class="tracking-status-timeline-completion" [ngStyle]="{'left': 0 + '%','width': ((  75 * trackingData.trackingMission?.progressPercent) / 100 + (i * 75)) + '%',
                       'background': trackingData.trackingMission?.state == 'DELIVERY' ? '#00a65a' :
                       trackingData.trackingMission?.state == 'DELAY' ? '#f39c12' :
                       trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL' ? '#dd4b39' : '#4ab4dd' }"
                            matTooltip="{{trackingData.trackingMission?.progressPercent | number:'.0-2'}}%">
                            <div class="selected">
                                <p *ngIf="trackingData.trackingMission?.accordingDistance" class="kls">
                                    {{trackingData.selectedMission?.travelDistance -
                                    trackingData.trackingMission?.accordingDistance | number:'.0-2'}} KM</p>
                                <img *ngIf="trackingData.selectedVehicule.icon" class=" tooltip-350" container="body"
                                    src="{{imagesCarsDir + trackingData.selectedVehicule.icon}}" alt="img" />
                                <img *ngIf="!trackingData.selectedVehicule.icon" class=" tooltip-350" container="body"
                                    src="{{imagesCarsDir + 'automobile_green.png'}}" alt="img" />
                                <p class="kls"><b>{{trackingData.selectedVehicule?.matricule}}</b></p>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <ng-container
                    *ngFor="let route of trackingData.selectedMission?.routeLegs; let i = index; let j = index">
                    <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle" [ngClass]="{'active': trackingData.trackingMission?.mission?.routeLegs[i]?.visited,
                        'green' : trackingData.trackingMission?.state == 'DELIVERY',
                        'orange' : trackingData.trackingMission?.state == 'DELAY',
                        'red' : trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL'
                    }" [ngStyle]="{'left': (i * 25) + '%'}" matTooltip="{{route?.labelPOISource}}">
                        <span *ngIf="trackingData.trackingMission?.state == 'PENDING'" class="status"
                            title="{{trackingData?.trackingMission?.estimatedStartDate | date:'dd-MM-yy HH:mm'}}">{{trackingData?.trackingMission?.estimatedStartDate
                            | date:'dd-MM-yy HH:mm'}}</span>
                        <span *ngIf="trackingData.trackingMission?.state != 'PENDING'" class="status"
                            title="{{trackingData?.trackingMission?.startDate | date:'dd-MM-yy HH:mm'}}">{{trackingData?.trackingMission?.startDate
                            | date:'dd-MM-yy HH:mm'}}</span>
                        <div class="icon"></div>
                    </div>
                    <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle" [ngClass]="{'active': trackingData.trackingMission?.mission?.routeLegs[i]?.visited && trackingData.trackingMission?.last,
                        'green' : trackingData.trackingMission?.state == 'DELIVERY',
                        'orange' : trackingData.trackingMission?.state == 'DELAY'}"
                        [ngStyle]="{'left': ((i + 1) * 75) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                        <span *ngIf="trackingData.trackingMission?.state == 'PENDING'" class="status kls"
                            title="{{trackingData?.trackingMission?.estimatedEndDate | date:'dd-MM-yy HH:mm'}}">{{trackingData?.trackingMission?.estimatedEndDate
                            | date:'dd-MM-yy HH:mm'}}</span>
                        <span
                            *ngIf="trackingData.trackingMission?.state == 'DELIVERY' || trackingData.trackingMission?.state == 'DELAY'"
                            class="status kls"
                            title="{{trackingData?.trackingMission?.endDate | date:'dd-MM-yy HH:mm'}}">{{trackingData?.trackingMission?.endDate
                            | date:'dd-MM-yy HH:mm'}}</span>
                        <div class="icon"></div>
                    </div>
                    <span *ngIf="i == 0" [ngStyle]="{'left': (i + 1 * 37.5) + '%'}" class="klm">{{route?.travelDistance
                        |
                        number:'.0-2'}} Km</span>
                </ng-container>
            </div>
        </div>
        <!-- CURRENT PATH INFO -->
        <div class="col-md-6">
            <fieldset class="mt-2">
                <legend class="mb-0">Info trajet</legend>
                <div>
                    <ng-container> Matricule : <b>{{trackingData.selectedVehicule?.matricule}}</b></ng-container> |
                    <ng-container> Vitesse :
                        <b>{{trackingData.trackingMission?.realTimeRecord?.speed}}</b>
                    </ng-container>|<ng-container> Position : <b
                            title="{{trackingData.trackingMission?.realTimeRecord?.geocoding}}">
                            {{trackingData.trackingMission?.realTimeRecord?.geocoding | truncate :[25]}}</b>
                    </ng-container>|
                    <ng-container> Duration : <b>{{selectedRouteLeg(i)?.travelDuration | timediff}}</b></ng-container>
                    <br />
                    <ng-container> A
                        proximité : <b>{{trackingData.trackingMission?.firstNearest ?
                            selectedRouteLeg(i)?.labelPOISource :
                            selectedRouteLeg(i)?.labelPOITarget}}</b>
                        de {{trackingData.trackingMission?.minDistance | number:'.0-2'}} Km</ng-container> |
                    <ng-container> Distance
                        Parcourue : <b>{{trackingData.trackingMission?.accordingDistance | number:'.0-2'}}
                            Km</b></ng-container>|
                    <ng-container> Pourcentage : <b>{{trackingData.trackingMission?.progressPercent |
                            number:'.0-2'}}%</b></ng-container> | <ng-container>Article :
                        <b>{{trackingData.selectedMission?.article}}</b>
                    </ng-container>
                    | <ng-container>Quantité :
                        <b>{{trackingData.selectedMission?.quantity}}</b>
                    </ng-container>
                     | <ng-container>B.L :
                        <b>{{trackingData.selectedMission?.bl}}</b>
                    </ng-container>
                    | <ng-container>C.chauffeur :
                        <b>{{trackingData.selectedMission?.codeChauffeur}}</b>
                    </ng-container>
                    <ng-container *ngIf="trackingData.trackingMission?.startDate">| Date debut :
                        <b>{{trackingData.trackingMission?.startDate | date:'dd-MM-yy HH:mm'}}</b>
                    </ng-container>
                    | <ng-container>Distance restante : <b>{{trackingData.selectedMission?.travelDistance -
                            trackingData.trackingMission?.accordingDistance | number:'.0-2'}} Km</b></ng-container>
                    <ng-container *ngIf="trackingData.trackingMission?.endDate">| Date Fin :
                        <b>{{trackingData.trackingMission?.endDate | date:'dd-MM-yy HH:mm'}}</b>
                    </ng-container>
                    <ng-container *ngIf="trackingData.trackingMission?.state == 'DELAY'">
                        | Durée du retard : <b>{{ trackingData.trackingMission?.delayDuration | timediff}}</b>
                    </ng-container>
                    <br />
                    <span *ngIf="trackingData.trackingMission?.state == 'PENDING'" class="badge label-secondary">En
                        attente</span>
                    <span *ngIf="trackingData.trackingMission?.state == 'INPROGRESS'" class="badge label-info">En
                        cours</span>
                    <span *ngIf="trackingData.trackingMission?.state == 'DELIVERY'" class="badge label-success">Arrivé à
                        temps</span>
                    <span *ngIf="trackingData.trackingMission?.state == 'DELAY'" class="badge label-warning">Arrivé en
                        retard</span>
                    <span *ngIf="trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL'" class="badge label-danger">Arrivée
                        probable</span>
                </div>
            </fieldset>
            <!-- END CURRENT PATH INFO -->
        </div>
    </div>
    <!-- END TRACKING STATUS -->

</ng-container>
<!-- END TRACKING INFO -->

<ng-container *ngIf="trackingMissionDataSorted.length == 0">
    <div class="row">
        <div class="col-xl-12 col-md-12 mb-12 col-xs-12">
            <div class="alert alert-warning alert-dismissible text-center">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                    ×
                </button>
                <h4 style="color: white">
                    <i class="fa fa-info-circle"></i> Information
                </h4>
                Non Mission à suivre en ce moment
            </div>
        </div>
    </div>
</ng-container>