<form #reparationForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Date de demande de réparation</label>
            <div class="col-sm-4">
                <input type="text" class="form-control input-sm" bsDatepicker name="operationTime" [(ngModel)]="reparation.operationTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    placeholder="Date de demande de réparation" (ngModelChange)="onChangeOperationTime()" autocomplete="off">
            </div>
            <label class="col-sm-2 control-label">Date de réparation réelle</label>
            <div class="col-sm-4">
                <input type="text" class="form-control input-sm" bsDatepicker name="actualRepairTime" [(ngModel)]="reparation.actualRepairTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" [minDate]="reparation.operationTime"
                    placement="bottom" placeholder="Date de réparation réelle" autocomplete="off">
            </div>
        </div>
        <div  class="form-group has-success">
            <label class="col-sm-2 control-label">Jours</label>
            <div class="col-sm-4">
                <input name="days" [(ngModel)]="reparation.days" type="number" class="form-control" placeholder="Jours de réparation"
                    min="1">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Quantité</label>
            <div class="col-sm-4">
                <input name="quantity" [(ngModel)]="reparation.quantity" type="number" class="form-control" placeholder="Quantité"
                    min="1">
            </div>
            <label class="col-sm-2 control-label">Coût *</label>
            <div class="col-sm-4">
                <input name="cout" [(ngModel)]="reparation.cost" #cout="ngModel" type="number" class="form-control"
                    placeholder="Coût" required min="1">
            </div>
        </div>
        <div *ngIf="reparation.provider.id != 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Fournisseur *</label>
            <div class="col-sm-10">
                <select class="form-control select2" [(ngModel)]="reparation.provider.id" name="providerId" #providerId="ngModel"
                    required>
                    <option [value]="0">Autres</option>
                    <option [selected]="provider.id == idProvider" *ngFor="let provider of providers" [value]="provider.id">
                        {{provider?.name}}
                    </option>
                    <option hidden [value]="null"></option>
                </select>
            </div>
        </div>
        <div *ngIf="reparation.provider.id == 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Nom Fournisseur *</label>
            <div class="col-sm-10">
                <input type="text" [(ngModel)]="reparation.provider.name" name="providerName" #providerName="ngModel"
                    class="form-control" placeholder="Nom Fournisseur" required maxlength="50">
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="reparation.provider.id = null;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Réperation *</label>
            <div class="col-sm-10">
                <input name="label" [(ngModel)]="reparation.label" #label="ngModel" type="text" class="form-control"
                    placeholder="Réparation" required maxlength="50">
            </div>
        </div>
        <div *ngIf="reparation.reparationType.id != 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Type Reparation *</label>
            <div class="col-sm-10">
                <select class="form-control select2" [(ngModel)]="reparation.reparationType.id" name="typeId" #typeId="ngModel"
                    required>
                    <option *ngFor="let type of reparationTypes" [selected]="type.id == reparation.reparationType.id"
                        [value]="type.id">{{type?.name}}</option>
                    <option [value]="0">Autres</option>
                </select>
            </div>
        </div>
        <div *ngIf="reparation.reparationType.id == 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Nom type *</label>
            <div class="col-sm-10">
                <input type="text" [(ngModel)]="reparation.reparationType.name" name="typeName" #typeName="ngModel"
                    class="form-control" placeholder="Nom type" required maxlength="50">
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="reparation.reparationType.id = null;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Odomètre</label>
            <div class="col-sm-4">
                <input type="number" name="odometre" [(ngModel)]="reparation.odometre" #odometre="ngModel" class="form-control"
                    placeholder="Odomètre" min="1">
            </div>
            <label class="col-sm-2 control-label">Observations</label>
            <div class="col-sm-4">
                <input type="text" name="Observations" [(ngModel)]="reparation.observation" class="form-control"
                    placeholder="Observations">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Coût de main d'oeuvre</label>
            <div class="col-lg-4 col-md-4">
                <input type="number" name="laborCost" [(ngModel)]="reparation.laborCost" class="form-control"
                    placeholder="Coût de main d'oeuvre" min="1">
            </div>
            <label class="col-sm-2 control-label">Références</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" name="referenceDADR" [(ngModel)]="reparation.referenceDADR" class="form-control"
                    placeholder="Références" maxlength="50">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Odomètre réparation</label>
            <div class="col-lg-4 col-md-4">
                <input type="number" name="odometerRepair" [(ngModel)]="reparation.odometerRepair" class="form-control"
                    placeholder="Odomètre réparation" min="1">
            </div>
            <label class="col-sm-2 control-label">Prochain odomètre prévisionne</label>
            <div class="col-lg-4 col-md-4">
                <input type="number" name="nextForecastOdometer" [(ngModel)]="reparation.nextForecastOdometer" class="form-control"
                    placeholder="Prochain odomètre prévisionne" maxlength="50">
            </div>
        </div>
        <div class="box-footer">
            <button type="submit" *ngIf="mode == 'ADD'" [disabled]="!reparationForm.form.valid" class="btn btn-info pull-right"
                (click)="onAdd(reparationForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
            <button type="submit" *ngIf="mode == 'UPDATE'" [disabled]="!reparationForm.form.valid" class="btn btn-info pull-right"
                (click)="onUpdate(reparationForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
        </div>
    </div>
</form>