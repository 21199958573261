import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SinistresComponent } from './sinistres.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ImportExportService } from '../../../import-export'; // Adjust the path as necessary
import { ModalModule } from 'ngx-bootstrap/modal';
import { ImportExportFormsModule } from '../../import-export-forms/import-export-forms.module';
import { SinistreFormModule } from './sinistre-form/sinistre-form.module';

@NgModule({
  declarations: [
    SinistresComponent // Declare the SinistresComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    ModalModule.forRoot(), // Import BsModalModule for modal functionality
    FormsModule,
    SinistreFormModule,
    ImportExportFormsModule,
  ],
  providers: [
    ImportExportService // Provide ImportExportService
  ],
  exports: [
    SinistresComponent // Export SinistresComponent for use in other modules
  ]
})
export class SinistresModule { }
