
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityChartComponent } from './activity-chart.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { HighchartsChartModule } from 'highcharts-angular';
import { ActivityChartService } from './activity-chart.service';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    ActivityChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    HighchartsChartModule,
    NgxLoadingModule.forRoot({}),


  ],
  providers: [
    ActivityChartService
  ],
  exports: [
    ActivityChartComponent
  ]
})
export class ActivityChartModule { }
