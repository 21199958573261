import { Injectable } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/Rx';
import { dns } from '../../global.config';
import { createAuthorizationHeader } from '../../utils/security/headers';
import {
  GroupsVehicules,
  ImportFileDto,
  RapportDto,
  ReportAuto,
} from '../data-management/data-management.model';
import { DateInterval } from '../../shared/model';
import { User } from 'src/app/authentification/signin/credentials';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ImportExportService {
  loader: boolean = false;
  /**current user */
  currentUser: User = new User();
  constructor(private _http: HttpClient, private toastr: ToastrService) {
    /**set current user  */
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  getAllGroups(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'groupes/minifyOnlyGroups', {
      headers: headers,
    });
  }

  getAllVehicules(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'vehicules/minifyVehicules', {
      headers: headers,
    });
  }

  getRepport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'generateRepport?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getAllPois(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'pointInterests/dtos', { headers: headers });
  }

  getPaths(
    repportOf: string,
    GroupsOrVehicules: GroupsVehicules,
    dateBegin: number,
    dateEnd: number
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'pathsExGlobal?repportOf=' +
        repportOf +
        '&startDate=' +
        dateBegin +
        '&endDate=' +
        dateEnd,
      GroupsOrVehicules,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getArretLieuxConnux(
    pois: string[],
    startDate: number,
    endDate: number,
    minute: number
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'arretLieuxConnusEx?minute=' +
        minute +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate,
      pois,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  readData(fileName: string): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.get<Boolean>(
      dns + 'pointInterests/readData?fileName=' + fileName,
      {
        headers: headers,
      }
    );
  }

  readDataVehicule(fileName: File): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.get<Boolean>(
      dns + 'vehicules/readData?fileName=' + fileName,
      {
        headers: headers,
      }
    );
  }
  /**downoald report */
  exportReportAsExcelFile(reportDto: RapportDto) {
    this.getRepport(reportDto).subscribe(
      (blob) => {
        if (blob.size > 0) {
          importedSaveAs(
            blob,
            'Rapport ' + this.getReportName(reportDto.type) + '.xlsx'
          );
          this.loader = false;
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        }
        this.loader = false;
      },
      (error) => {
        this.toastr.error('Aucune donnée entre ces deux dates !', 'Error');
        this.loader = false;
      }
    );
  }

  getChargePfbReport(
    chargePfbId: number,
    dateIntervalDto: DateInterval,
    month: number,
    daysBetween: number
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'historicalChargesPfb/' +
        chargePfbId +
        '?&month=' +
        month +
        '&daysBetween=' +
        daysBetween +
        '&timezoneOffset=' +
        new Date().getTimezoneOffset(),
      dateIntervalDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  export(data: any, repport: RapportDto, selectedvehiculeId: number) {
    if (repport.type != 'SITE_CLIENT' && repport.type != 'SITE_POICLIENT')
      this.setStartEndDateIfnotExist(data, repport);
    repport.rapportPayloadDto.idVehicules[0] = selectedvehiculeId;
    this.exportReportAsExcelFile(repport);
  }

  getReportName(reportType: string) {
    switch (reportType) {
      case 'KILOMETRAGE':
        return 'Kilometrage';
      case 'PATH_FUEL':
        return " d'approvisionnement";
      case 'ACTIVITY':
        return " d'activité journaliere";
      case 'ACTIVITY_DAILY_GLOBAL':
        return " d'activté journaliere globale";
      case 'KILOMETRAGE_DAILY':
        return 'de kilometrage journalier';
      case 'ARRETS':
        return ' des arrets';
      case 'FUELING':
        return 'de carburants';
      case 'RUNNING_DOCUMENT':
        return 'des documents de circulation ';
      case 'OIL_CHANGE':
        return 'Vidange';
      case 'BRAKE':
        return 'Freins';
      case 'OFFENSE':
        return 'Infraction';
      case 'SINISTRE':
        return 'Sinistre';
      case 'ARRETS_GLOBAL':
        return 'Arrets global';
      case 'TIRE':
        return 'Pneus';
      case 'BATTERY':
        return 'Batteries';
      case 'REPARATION':
        return 'Reparations';
      case 'STOP_BY_POI':
        return 'Arrets par poi';
      case 'STOP_BY_POI_CLIENT':
        return 'Arrets par poi client';
      case 'STOP_NON_POI':
        return 'Arrets par non poi';
      case 'ETAT_ACTUEL_STOP':
        return 'Listes des boitiers en arrets';
      case 'ETAT_ACTUEL':
        return 'Etat actuel des boitiers';
      case 'SITE_CLIENT':
        return 'Liste des poi';
      case 'DRIVER':
        return 'Liste des conducteurs';
      case 'SITE_POICLIENT':
        return 'Liste des clients';
      case 'TRAVEL':
        return 'Etat suivi des camions par site';
      case 'PARCK_MANAGEMENT':
        return 'Couts de gestion';
      case 'ROAD_TAXES':
        return 'Vignette';
      case 'OPERATIONAL_CERTIFICATION':
        return 'Certifications_Exploitation';
      case 'TONNAGE_TAX':
        return 'taxe_tonnage';
      case 'METOLOGICA_NOTBOOK':
        return 'Carnet métrologique';
      case 'LEASING':
        return 'Location';
      case 'TOLL':
        return 'Péage';
      case 'CA_REALIZATION':
        return 'CA réalisé';
      case 'INSURANCE':
        return 'Assurance';
      case 'VISIT':
        return 'Visite';
      case 'DELAY':
        return 'DES RETARDS ET DES ARRETS POI';
      case 'REP_FUEL_VARIATION_GN':
        return "d'approvisionnement Carburant";
      case 'ACTIVITY_MEAN':
        return "Moyenne D'activité";
      case 'DIVIDED_ACTIVITY_SPEED':
        return "Horaire D'activité";
      case 'SPEEDING':
      case 'GLOBAL_SPEEDING':
        return 'Excés Vitesse';
      case 'ACTIVITY_SPEED':
        return "D'activité journalier avec vitesse";
      case 'ACTIVITY_SPEED_GLOBAL':
        return "D'activité avec vitesse";
      case 'ACTIVITY_GLOBAL':
        return "D'activité";
      case 'CUMULATIVE_COST_OF_PARK_MANAGEMENT':
        return 'Coûts des documents de circulation';
      case 'OFFENSES_MODULE':
        return 'Des Dégâts';
      case 'REPARATION_MODULE':
        return 'Réparation';
      case 'PATH_WITH_START_OR_END_POI':
        return 'Des Trajets POI';
      case 'PATH_POI':
      case 'PATH_POI_COUNT':
        return 'Des voyages par POI';
      case 'TRANSPORT_BASE':
        return 'Base Transports';
      case 'PATH_ZONE':
        return 'Des voyages Par zone';
      case 'PATH_FUEL_POI':
        return ' Voyage par vehicule';
      case 'UNVISITED_POI':
        return 'Des Secteurs';
      case 'GEO_ASSIGNMENT':
        return 'Des tournées';
      case 'EXPIRATION_DOCUMENT':
        return ' Expiration Documents';
      case 'DRIVER_ASSIGNMENT_HISTORY':
        return ' des conducteurs';
      case 'OIL_CHANGE_MONITORING':
        return 'Suivi Vidanges';
      case 'FUELING_MONITORING_CONSUMPTION':
        return 'Suivi Consommation saisie';
      case 'FUEL_CONSUMPTION_DIFFERENCE':
        return 'Ecart consommation carburant';
      case 'STOP_BY_GROUP_AND_POI_GLOBAL':
        return 'Arrêts POI Global';
      case 'STOP_IN_POI_NOTIFICATION':
        return 'Arrêts POI';
      case 'MISSION_CIMENT':
        return 'des Missions';
      case 'ACTIVITY_NOTIFICATIONS':
        return "dépassement des Heures d'activité";
      case 'ACTIVITY_DAILY_NOTIFICATIONS':
        return 'dépassement des Heures de travail';
      case 'ARCHIVEPOI':
        return "Passage par points d'interêts";
      case 'PATH_APPRO_FUEL_POI':
        return 'IDP';
      case 'DAILY_PATH_APPRO_FUEL_POI':
        return 'des voyages journalier et approvisionnements périodique';
      case 'MONTHLY_PARC_MANAGEMENT':
        return 'des dépenses mensuelles';
      case 'FUELING_CONSUMPTION_ANALYSIS':
        return "de suivi et d'analyse consommation vehicule";
      default:
        return reportType;
    }
  }
  setStartEndDateIfnotExist(data: any, repport: RapportDto) {
    let end = Math.max.apply(
      Math,
      data.map(function (o: { operationTime: any; }) {
        return o.operationTime;
      })
    );
    let start = '2001-01-01 18:32:27.0';
    if (this.currentUser.avatartUri) {
      repport.avatarUri = this.currentUser.avatartUri;
    }
    repport.dateInterval.startDate = new Date(start);
    repport.dateInterval.endDate = new Date(end);
    repport.rapportPayloadDto.byVehicules = true;
    repport.rapportPayloadDto.allVehicules = false;
  }
  /** import functionality */
  /**==============================FUEL IMPORT ============================== */
  importData(
    fileToUpload: File,
    type: string,
    providerName: string
  ): Observable<Boolean> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    let importFileDto: ImportFileDto = new ImportFileDto();
    importFileDto.file = formData;
    importFileDto.type = type;
    let headers = createAuthorizationHeader();
    return this._http.post<Boolean>(
      dns + 'import/file?payload=' + providerName + '&type=' + type,
      formData,
      { headers: headers }
    );
  }
  importDataOfSelectedVehicule(
    formData: FormData,
    type: string,
    providerName: string,
    selectedvehiculeId: number
  ): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.post<Boolean>(
      dns +
        'import/file/selectedvehicule/' +
        selectedvehiculeId +
        '?type=' +
        type +
        '&providerName=' +
        providerName,
      formData,
      { headers: headers }
    );
  }
  validaDatabeforImport(
    fileToUpload: File,
    type: string,
    providerName: string
  ): Observable<Blob> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    let importFileDto: ImportFileDto = new ImportFileDto();
    importFileDto.file = formData;
    importFileDto.type = type;
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'import/valid?type=' + type + '&provider=' + providerName,
      formData,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }
  /**==========================================IMPORT(start)==================== */
  import(
    form: FormData,
    matricule: string,
    groupId: number,
    type: string,
    clientType: string
  ): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.post<Boolean>(
      dns +
        'import/?vehiuleMatricule=' +
        matricule +
        '&groupId=' +
        groupId +
        '&type=' +
        type +
        '&clientType=' +
        clientType,
      form,
      { headers: headers }
    );
  }
  /**=============================================END ========================== */

  /**
   * get report speed notification
   */
  getNotificationReport(
    deviceId: number,
    name: string,
    dateIntervalDto: DateInterval
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'generateRepport/notifications/' + deviceId + '/' + name,
      dateIntervalDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getFuelingNotificationReport(
    deviceId: number,
    vehiculeId: number,
    dateIntervalDto: DateInterval
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'generateRepport/fueling/notifications/' +
        vehiculeId +
        '/?deviceId=' +
        deviceId +
        '&timezoneOffset=' +
        new Date().getTimezoneOffset(),
      dateIntervalDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  /***Report Auto */
  getAllReportSent(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'reportAuto', { headers: headers });
  }
  delete(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http.delete<Boolean>(dns + 'reportAuto/' + id, {
      headers: headers,
    });
  }

  add(reportAuto: ReportAuto): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'reportAuto/add', reportAuto, {
      headers: headers,
    });
  }

  update(reportAuto: ReportAuto): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.put(dns + 'reportAuto/update', reportAuto, {
      headers: headers,
    });
  }

  // getTravelRepport(travelReports: TravelReportDto[], transportTypeName): Observable<any> {
  //   let headers = createAuthorizationHeader();
  //   return this._http.post(dns + "generateRepport/travelReport/" + transportTypeName , travelReports, { headers: headers, responseType: 'blob' as 'json' });
  // }

  getTravelRepport(rapportDto: RapportDto, transportTypeName: string): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(
      dns +
        'generateRepport/travelReport?timezoneOffset=' +
        new Date().getTimezoneOffset() +
        '&transportTypeName=' +
        transportTypeName,
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }
}
