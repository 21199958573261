import { Component, OnInit, ViewChild } from '@angular/core';

import * as Highcharts from 'highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TravelReportDto } from 'src/app/client-management/parc-management';
import {Point} from "leaflet";
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts)

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}
@Component({
  selector: 'app-consommation-moyenne',
  templateUrl: './consommation-moyenne.component.html',
  styleUrls: ['./consommation-moyenne.component.scss']
})
export class ConsommationMoyenneComponent implements OnInit {

  TravelReportsData: TravelReportDto[] = [];

  @ViewChild("consomationMoyenneGraphe", { static: false }) consomationMoyenneGrapheModal: ModalDirective;

  options: Object | null = null;
  optionsModal: Object | null = null;
  constructor() { }

  ngOnInit() {
    this.consomation_moyenne(this.TravelReportsData);
  }

  showConsomationMoyenneGrapheModal() {
    this.consomationMoyenneGrapheModal.show();
  }

  transform(value: number, args?: any): any {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);

    let result = '';

    result= hours + "h" + minutes + "min"

    return result;
  }

  getColorBasedOnValue(value1: number,value2: number,): string {
    const doubleValue1: number = Number(value1.toFixed(2));
    const doubleValue2: number = Number(value2.toFixed(2));

    // Check if conversion was successful
    if (!isNaN(doubleValue1) && !isNaN(doubleValue2)) {
      // Use a small epsilon for floating-point comparison
      const epsilon = 0.0001;

      // Compare the double values and return the color code
      return doubleValue1 >= doubleValue2 ? "#e77e22" : "#ff1800";
    } else {
      // If conversion fails, return a default color code
      return "#ff0000"; // Default color for invalid comparison
    }
    //return doubleValue > value2 ? '#e77e22' : '#c84234';

  }

consomation_moyenne(TravelReportsData: TravelReportDto[]){
  this.TravelReportsData= TravelReportsData;

    const subMarkTotalsMap = new Map<string, { totalFuelAverage: number, totalFuelUsed: number,FuelAverage: number, FuelUsed: number,nombre: number }>();
    const matriculeTotalsMap = new Map<string, { totalFuelUsed: number,FuelUsed:number,totalFuelAverage: number,FuelAverage:number, nombre: number }>();

    // Calculate the total 'objectifRotation' and 'averageRotation' for each 'subMark'
    TravelReportsData.forEach(item => {
      const subMark = item.vehicule.subMark;
      let nbVehicule=0;



      if (!matriculeTotalsMap.has(item.vehicule.matricule)) {
        nbVehicule+=1;
        matriculeTotalsMap.set(item.vehicule.matricule, { totalFuelUsed: 0,FuelUsed: 0,totalFuelAverage: 0,FuelAverage:0, nombre: 0 });
      }

      matriculeTotalsMap.get(item.vehicule.matricule).nombre+=1;
      matriculeTotalsMap.get(item.vehicule.matricule).totalFuelUsed+=item.fuelPercentage | 0;
      matriculeTotalsMap.get(item.vehicule.matricule).FuelUsed=matriculeTotalsMap.get(item.vehicule.matricule).totalFuelUsed/matriculeTotalsMap.get(item.vehicule.matricule).nombre;
      matriculeTotalsMap.get(item.vehicule.matricule).totalFuelAverage+=item.vehicule.fuelAverage | 0;
      matriculeTotalsMap.get(item.vehicule.matricule).FuelAverage=matriculeTotalsMap.get(item.vehicule.matricule).totalFuelAverage/matriculeTotalsMap.get(item.vehicule.matricule).nombre;


    });


    let mat: any[] = [];
    TravelReportsData.forEach(item=>{
      const subMark = item.vehicule.subMark;

      if (!subMarkTotalsMap.has(subMark)) {
        subMarkTotalsMap.set(subMark, { totalFuelAverage: 0, totalFuelUsed: 0 ,FuelAverage:0,FuelUsed:0,nombre:0});
      }
      if(mat.indexOf(item.vehicule.matricule)<0){

        mat.push(item.vehicule.matricule);
        subMarkTotalsMap.get(subMark).nombre++;
      subMarkTotalsMap.get(subMark).FuelUsed += matriculeTotalsMap.get(item.vehicule.matricule).FuelUsed | 0;
      subMarkTotalsMap.get(subMark).FuelAverage += matriculeTotalsMap.get(item.vehicule.matricule).FuelAverage | 0;
      subMarkTotalsMap.get(subMark).totalFuelAverage = subMarkTotalsMap.get(subMark).FuelAverage/subMarkTotalsMap.get(subMark).nombre;
      subMarkTotalsMap.get(subMark).totalFuelUsed = subMarkTotalsMap.get(subMark).FuelUsed/subMarkTotalsMap.get(subMark).nombre;
      }

    })


    const chartData = [
      {
        name: 'Consommé',
        type:"column",
        data: Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => ({
          name: subMark,
          y: totals.totalFuelUsed,
          drilldown: `${subMark}_CC`
        }))
      },
      {
        name: 'Cible',
        type:"spline",
        data: Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => ({
          name: subMark,
          y: totals.totalFuelAverage,
          drilldown: `${subMark}_CC`
        }))
      }
    ];


    const drilldown_data = Array.from(subMarkTotalsMap.entries()).map(([subMark, totals]) => {
      const vehicles = this.TravelReportsData
        .filter(item => item.vehicule.subMark === subMark)
        .reduce((acc, item) => {
          const existingVehicle = acc.find(v => v.name === item.vehicule.matricule);

          if (existingVehicle) {
            existingVehicle.n+=1;
            existingVehicle.fuel+=item.fuelPercentage
            existingVehicle.y = existingVehicle.fuel/existingVehicle.n;
            existingVehicle.average= item.vehicule.fuelAverage,
            existingVehicle.duree+=item.pathDuration;
            existingVehicle.arret+=item.stopDuration;
            existingVehicle.dureestr=this.transform(existingVehicle.duree);
            existingVehicle.arretstr=this.transform(existingVehicle.arret);
            existingVehicle.color=this.getColorBasedOnValue( existingVehicle.average,existingVehicle.y);
          } else {
            acc.push({
              name: item.vehicule.matricule,
              y: item.fuelPercentage,
              fuel: item.fuelPercentage,
              average: item.vehicule.fuelAverage,
              dureestr:this.transform(item.pathDuration),
              arretstr:this.transform(item.stopDuration),
              n:1,
              duree:item.pathDuration,
              arret:item.stopDuration,
              drilldown: `${item.vehicule.matricule}_CCM`,
              color:this.getColorBasedOnValue(item.vehicule.fuelAverage,item.fuelPercentage)
            });
          }

          return acc;
        }, []);

      return {
        id: `${subMark}_CC`,
        type: 'column',
        name: 'Cible / Consomation',
        data: vehicles,
        tooltip: {
          headerFormat: '<span style="font-size:11px">Consommation</span>: <b>{point.y:.2f}</b><br>',
          pointFormat: 'Cible</span>: <b>{point.average}<br/><span style="color:{point.color}">{point.name}</span> <b>{point.ot}</b><br/>{point.missionType}<br/>{point.missionType}<br/>',
          positioner: function (this :any,labelWidth: number, labelHeight: number, point: any) {
            // Adjust the position to display above the column
            return { x: point.plotX + this.chart.plotLeft - labelWidth / 2, y: point.plotY - labelHeight - 10 };
          },
        },

      };
    });

    const secondLevelDrilldown = this.TravelReportsData.reduce((acc, item) => {
      const existingVehicle = acc.find(drilldownItem => drilldownItem.id === `${item.vehicule.matricule}_CCM`);

      if (existingVehicle) {
        // If the vehicle already exists, add data to its array
        existingVehicle.data.push({
          name: item.otNumber,
            y: item.fuelPercentage,
            missiontype:item.missionType,
            cible: item.vehicule.fuelAverage,
            dureestr:this.transform(item.pathDuration),
            arretstr:this.transform(item.stopDuration),
            duree:item.pathDuration,
            arret:this.transform(item.stopDuration),
            arretRpm:this.transform(item.stopRpm),
            distance:item.distanceDriven,
        });
      } else {
        // If the vehicle doesn't exist, create a new entry
        acc.push({
          type:'column',
          id: `${item.vehicule.matricule}_CCM`,
          name: item.vehicule.matricule,
          data: [{
            name: item.otNumber,
            y: item.fuelPercentage,
            missiontype:item.missionType,
            cible: item.vehicule.fuelAverage,
            dureestr:this.transform(item.pathDuration),
            arretstr:this.transform(item.stopDuration),
            duree:item.pathDuration,
            arret:this.transform(item.stopDuration),
            arretRpm:this.transform(item.stopRpm),
            distance:item.distanceDriven,
          }],
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<b>Cible: </b>{point.cible}<br/><b>Consomme: </b>{point.y:.2f}<br/> <span style="color:{point.color}"> {point.missiontype}</span><br/><b>Km: </b><span>{point.distance:.2f}<br/><b>ARRET RPM: </b><span>{point.arretRpm}</span>'
        },
        });
      }

      return acc;
    }, []);

    const drilldown_data_all=drilldown_data.concat(secondLevelDrilldown);

    this.setHighCartsLang();
    this.options = {
      chart: {
        type: 'column',
        height:260
      },
      title: {
        text: 'C.Moyenne/type',
        style:{
          color: '#3c8dbc',
          fontSize: "20px",
        },
        align: 'center'
      },
      xAxis: {
        //categories: ['CANTER', 'SEMI REMORQUE', 'SEMI REMORQUE GRUE','SOLO','SOLO GRUE' ],
        type: 'category',
        title: {
          text: null,
        },
        labels: {
          enabled: true, // Set this to false to hide the xAxis labels
        },
        gridLineWidth: 1,
      lineWidth: 0,
    },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      tooltip: {
        //headerFormat: '<span style="font-size:11px">{series.name} {point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        shared: true
    },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      legend: {
          enabled: true,
      },
      credits: {
        enabled: false,
      },
      series: chartData,
      drilldown: {
        series: drilldown_data_all,
      },
    };

    this.optionsModal = {
      chart: {
        type: 'column',
        height:300
      },
      title: {
        text: 'Consommation Moyenne / type',
        style:{
          color: '#3c8dbc'
        },
        align: 'center'
      },
      xAxis: {
        //categories: ['CANTER', 'SEMI REMORQUE', 'SEMI REMORQUE GRUE','SOLO','SOLO GRUE' ],
        type: 'category',
        title: {
          text: null,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      }, tooltip: {
        //headerFormat: '<span style="font-size:11px">{series.name} {point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        shared: true
    },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        shadow: true,
      },
      credits: {
        enabled: false,
      },
      series: chartData,
      drilldown: {
        series: drilldown_data_all,
      },
    };

    Highcharts.chart('consomationMoyenneGraphe', this.options);
    Highcharts.chart('consomationMoyenneGrapheModal', this.optionsModal);

  }

  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#e77e22', '#35a9e7', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b',  '#00f194'
    ],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

  }

}
