import { Component, Input, OnInit } from '@angular/core';
import {
  GroupDashbordDto,
  ParentData,
  SinistreDashbordDto,
} from 'src/app/client-management/parc-management';
import * as Highcharts3 from 'highcharts';
require('highcharts/modules/exporting')(Highcharts3);
require('highcharts/modules/no-data-to-display')(Highcharts3);

if (!Highcharts3.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts3);
}

import { SinistreChartService } from './sinistre-chart.service';
import { DateInterval } from 'src/app/shared/model';
import { ToastrService } from 'ngx-toastr';
import { Group, GroupDto } from 'src/app/client-management/data-management';

@Component({
  selector: 'app-sinistre-chart',
  templateUrl: './sinistre-chart.component.html',
  styleUrls: ['./sinistre-chart.component.css'],
})
export class SinistreChartComponent implements OnInit {
  groupeStatistics: any[] = [];
  Sinistre: Object | null= null;
   showModal: boolean | null = null;
  SinistreModal: Object = null;
  DetailSinistre: any[] = [];
  date: DateInterval = new DateInterval();
  endDate: Date = new Date();
  startDate: Date = new Date();
  globalLoading: boolean;
   Years: any[] = [];

  displayCalendar: boolean = false;
  IsByParent = false;
  isByMultipleGroups: boolean = false;
  groupIds: GroupDto[] = [];
  sinistinfo_: ParentData;

  @Input()
  groups: GroupDto[] = [];

  @Input() show: boolean = false;

  @Input()
  set depensedata(depensedata: ParentData) {
    this.sinistinfo_ = Object.assign(depensedata);
    if (this.sinistinfo_) {
      this.getSinistreInfo();
    }
  }

  dropdownSettings: any;


  constructor(
    private sinistreChartService: SinistreChartService,
    public toastr: ToastrService
  ) {
    this.Years = this.getYears();
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.startDate = new Date(new Date().getFullYear(), 0, 1);
    this.endDate = new Date(this.startDate.getFullYear(), 11, 31);
    if (!this.show) this.getSinistreInfo();
  }

  getSinistreInfo() {
    this.globalLoading = true;
    if (this.show) {
      if (this.sinistinfo_.dateEnd != null) {
        this.date.startDate = this.sinistinfo_.datestart;
        this.date.endDate = this.sinistinfo_.dateEnd;
        this.IsByParent = this.sinistinfo_.IsByParents;
        if (this.IsByParent) this.groupIds = [];
        var groups = this.sinistinfo_.groups;
      }
    } else {
      this.date.startDate = this.startDate.getTime();
      this.date.endDate = this.endDate.getTime();
      if (this.IsByParent) this.groupIds = [];
      var groups = this.groupIds.map((g) => g.idGroupe).toString();
    }

    this.sinistreChartService
      .getSinistreInfo(this.date, groups, this.IsByParent)
      .subscribe((res) => {
        this.globalLoading = false;
        this.groupeStatistics = res as GroupDashbordDto[];
        this.sinistreChart();
      });
  }

  onChange(year: any) {
    if (year != 5) {
      this.displayCalendar = false;
      this.startDate = new Date(new Date().getFullYear() - year, 0, 1);
      this.endDate = new Date(this.startDate.getFullYear(), 11, 31);
      this.getSinistreInfo();
    } else {
      this.displayCalendar = true;
    }
  }

  getYears() {
    return [
      { id: 0, name: 'Année En cours' },
      { id: 1, name: 'Année Antérieure ' },
      { id: 2, name: 'Année -2' },
      { id: 3, name: 'Année -3' },
      { id: 4, name: 'Année -4' },
      { id: 5, name: 'Date Personnaliser' },
    ];
  }

  onValidate() {
    this.getSinistreInfo();
  }

  onChangeType() {
    this.isByMultipleGroups = false;
    if (!this.displayCalendar) {
      this.getSinistreInfo();
    }
  }

  onChangeByMultipleGroups() {
    if (!this.isByMultipleGroups) {
      this.groupIds = [];
      this.getSinistreInfo();
    }
  }

  sinistreChart() {
    this.setHighCartsLang();
    var that = this;
    var drilldata: { name: any; id: number; data: any[]; }[] = [];
    var data: { name: string; p: number; y: number; drilldown: string }[] = [];
    var sumCount = 0;
    var totalCost = 0;
    var groupesName = new Set(
      this.groupeStatistics.map((s) => {
        return s;
      })
    );

    for (var i = 0; i < this.groupeStatistics.length; i++) {
      sumCount += this.groupeStatistics[i].count;
      totalCost += this.groupeStatistics[i].total;
    }

    groupesName.forEach((groupeName) => {
      let percent = (groupeName.count * 100) / sumCount;
      data.push({
        name: groupeName.nom,
        p: percent,
        y: groupeName.count,
        drilldown: groupeName.idGroupe,
      });
    });
    this.Sinistre = {
      chart: {
        type: 'column',
        height: 320,
        events: {
          drilldown: function (e: { seriesOptions: any; point: { drilldown: number; name: any; }; }) {
            if (!e.seriesOptions) {
              var vehiculesdata: any[] = [];
              that.sinistreChartService
                .getSinistresBygroupe(
                  e.point.drilldown,
                  that.date,
                  that.IsByParent
                )
                .subscribe((res) => {
                  for (let i = 0; i < res.length; i++) {
                    const result = res.filter(
                      (s) => s.vehiculeId === res[i].vehiculeId
                    );
                    var totalCostsOfEachVeh: number = 0;
                    for (var j = 0; j < result.length; j++) {
                      totalCostsOfEachVeh += result[j].totalCost;
                    }
                    vehiculesdata.push([
                      'Vehicule : ' +
                        res[i].matricule +
                        '  <br /> Nbr : <b>' +
                        result.length +
                        '</b><br /> ',
                      totalCostsOfEachVeh,
                    ]);
                    drilldata.push({
                      name: e.point.name,
                      id: e.point.drilldown,
                      data: vehiculesdata,
                    });
                  }
                });
            }
          },
        },
      },
      title: {
        text: 'Sinistres par groupe',
        style: {
          fontWeight: 'bold',
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: 'underline',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Total Sinistres',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f} ',
            softThreshold: true,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span> <b>{point.y:,.2f}</b> <br/>',
      },
      series: [
        {
          name: 'Groupe',
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.y} ',
          },
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">{point.name} </span> <b>{point.y}</b> <br/>' +
              '<b>{point.p:.1f}%</b>',
          },
          data: data,
        },
        {
          type: 'pie',
          name: 'Tout le parc',
          tooltip: {
            pointFormat:
              '<span style="color:#007bff">NBR :<b>{point.y}</b></span> <br/>' +
              '<span style="color:#007bff">Coût total :<b>{point.x:,.2f} DH</b></span>',
          },
          data: [
            {
              color: '#64e572',
              name: 'Tout le parc',
              y: sumCount,
              x: totalCost,
            },
          ],
          center: [100, 80],
          size: 70,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        series: drilldata,
      },
    };
    this.SinistreModal = {
      chart: {
        type: 'column',
        height: 320,
        events: {
          drilldown: function (e: { seriesOptions: any; point: { drilldown: number; name: any; }; }) {
            if (!e.seriesOptions) {
              var vehiculesdata: any[] = [];
              that.sinistreChartService
                .getSinistresBygroupe(
                  e.point.drilldown,
                  that.date,
                  that.IsByParent
                )
                .subscribe((res) => {
                  for (let i = 0; i < res.length; i++) {
                    const result = res.filter(
                      (s) => s.vehiculeId === res[i].vehiculeId
                    );
                    var totalCostsOfEachVeh: number = 0;
                    for (var j = 0; j < result.length; j++) {
                      totalCostsOfEachVeh += result[j].totalCost;
                    }
                    vehiculesdata.push([
                      'Vehicule : ' +
                        res[i].matricule +
                        '  <br /> Nbr : <b>' +
                        result.length +
                        '</b><br /> ',
                      totalCostsOfEachVeh,
                    ]);
                    drilldata.push({
                      name: e.point.name,
                      id: e.point.drilldown,
                      data: vehiculesdata,
                    });
                  }
                });
            }
          },
        },
      },
      title: {
        text: 'Sinistres par groupe',
        style: {
          fontWeight: 'bold',
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: 'underline',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Total Sinistres',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f} ',
            softThreshold: true,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span> <b>{point.y:,.2f}</b> <br/>',
      },
      series: [
        {
          name: 'Groupe',
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.y} ',
          },
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">{point.name} </span> <b>{point.y}</b> <br/>' +
              '<b>{point.p:.1f}%</b>',
          },
          data: data,
        },
        {
          type: 'pie',
          name: 'Tout le parc',
          tooltip: {
            pointFormat:
              '<span style="color:#007bff">NBR :<b>{point.y}</b></span> <br/>' +
              '<span style="color:#007bff">Coût total :<b>{point.x:,.2f} DH</b></span>',
          },
          data: [
            {
              color: '#64e572',
              name: 'Tout le parc',
              y: sumCount,
              x: totalCost,
            },
          ],
          center: [100, 80],
          size: 70,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        series: drilldata,
      },
    };
    Highcharts3.chart('container', this.Sinistre);
    Highcharts3.chart('containerModal', this.SinistreModal);
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts3.setOptions({
      colors: [
        '#35a9e7',
        '#e77e22',
        '#3ea6dc',
        '#47a4d2',
        '#51a2c7',
        '#5a9fbd',
        '#639db3',
        '#6d9ba8',
        '#76999e',
        '#7f9694',
        '#899489',
        '#92927f',
        '#9c9074',
        '#a58d6a',
        '#ae8b60',
        '#b88955',
        '#c1874b',
        '#ca8441',
        '#d48236',
        '#dd802c',
        '#e77e22',
      ],
      //colors: ['#007bff', '#66B92E', '#DA932C', '#D65B4A', '#24CBE5', '#64E572', '#FF9655', '#000000', '#6AF9C4', '#f39c12', '#D81B60', '#605ca8', '#3D9970', '#001F3F', '#00c0ef', ' #451e3e', '#adcbe3', '#f37736', '#854442', '#3d1e6d', '#c68642'],
      lang: {
        printChart: 'Imprimer',
        decimalPoint: ',',
        thousandsSep: ' ',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: "Aucune donnée n'est disponible",
      },
    });
  }

  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.endDate < this.startDate) {
      this.endDate = null;
    }
  }
}
