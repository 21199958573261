import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricalComponent } from './historical.component'; // Adjust the path as needed
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HistoricalComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    HistoricalComponent
  ]
})
export class HistoricalModule { }
