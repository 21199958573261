import {Component, OnInit} from '@angular/core';
import {DataManagementService} from '../../data-management/data-management.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {Group, Vehicule} from '../../data-management/data-management.model';
import {TechnicalDataService} from '../technical-data/technical-data.service';
import {DeviceCarb, DeviceMems} from '../technical-data/technical-data';
import {HttpErrorResponse} from "@angular/common/http";

@Component({
    selector: 'app-fuel-settings',
    templateUrl: './fuel-settings.component.html',
    styleUrls: ['./fuel-settings.component.css']
})
export class FuelSettingsComponent implements OnInit {


    AllGroups: Subscription;

    groups: Group[];
    selectedGroup: Group = new Group();
    selectedVehicule: Vehicule = new Vehicule();

    currentCarbSetting: DeviceCarb = new DeviceCarb();
    isResultLoaded = false;
    isResutSuccess = false;

    message = 'Chargement ...';

  currentMemsSetting: DeviceMems | null = null;
  isMemsResultLoaded = false;
    isMemsResutSuccess = false;

    memsMessage = 'Chargement ...';

    constructor(private dataManagementService: DataManagementService, public toastr: ToastrService, private technicalDataService: TechnicalDataService) {
    }

    ngOnInit() {
        if (this.dataManagementService.groups) {
            this.groups = this.dataManagementService.groups.filter(group => group.vehicules.length > 0);
            if (this.dataManagementService.selectedGroup) {
                this.selectedGroup = this.dataManagementService.selectedGroup;
                if (this.dataManagementService.selectedVehicule) {
                    this.selectedVehicule = this.dataManagementService.selectedVehicule;
                }
                else if (this.selectedGroup.vehicules.length > 0) {
                    this.selectedVehicule = this.selectedGroup.vehicules[0];
                }
            }
            else {
                if (this.groups.length > 0 && this.groups) {
                    this.selectedGroup = this.groups[0];
                    this.dataManagementService.selectedGroup = this.selectedGroup;
                }
            }
            this.selectedVehicule = this.dataManagementService.selectedVehicule!;

            /** load device fuel settings*/
            this.loadDeviceFuelStettings();
             /** load device mems settings*/
             this.loadDeviceMemsStettings();

        }
        else {
            this.AllGroups = this.dataManagementService.getAllGroupsDetails('').subscribe(groups => {
                this.groups = groups.filter(group => group.vehicules.length > 0);
                this.dataManagementService.groups = groups;
                this.dataManagementService.setGroups(groups);
                if (this.groups.length > 0 && this.groups) {
                    this.selectedGroup = this.groups[0];
                    this.dataManagementService.selectedGroup = this.selectedGroup;
                    if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
                        this.selectedVehicule = this.selectedGroup.vehicules[0];
                        this.dataManagementService.selectedVehicule = this.selectedVehicule;
                    }

                    /** load device fuel settings*/
                    this.loadDeviceFuelStettings();
                    /** load device mems settings*/
                    this.loadDeviceMemsStettings();
                }
            });
        }
    }

    chooseGroup(group:any) {
        this.currentMemsSetting = null;
        this.dataManagementService.selectedGroup = group;
        this.selectedGroup = group;
        if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
            this.selectedVehicule = this.selectedGroup.vehicules[0];
            this.dataManagementService.selectedVehicule = this.selectedVehicule;
        }
        this.loadDeviceFuelStettings();
        this.loadDeviceMemsStettings();
    }

    chooseVehicule(vehicule:any) {
        this.currentMemsSetting = null;
        this.selectedVehicule = vehicule;
        this.dataManagementService.selectedVehicule = vehicule;
        this.loadDeviceFuelStettings();
        this.loadDeviceMemsStettings();
    }

    loadDeviceMemsStettings() {
        this.isMemsResultLoaded = false;
        this.memsMessage = 'Chargement ...';
        this.technicalDataService.getDeviceMems(this.selectedVehicule.idDevice)
        .subscribe(deviceMems => {
            this.currentMemsSetting = deviceMems;
            this.toastr.success('Configuration chargée', 'info !');
            this.isMemsResultLoaded = true;
            this.isMemsResutSuccess = true;
            this.memsMessage = 'Mems configuration chargée pour le véhicule : ' + this.dataManagementService.getVehiculeName(this.selectedVehicule);

        }, (error)=> {

            let err = JSON.parse(JSON.stringify(error.error || null));

            this.isMemsResultLoaded = true;
            this.isMemsResutSuccess = false;

            if (err.errorMessage) {
                this.memsMessage = err.errorMessage;
            } else {
                this.memsMessage = 'SERVER ERROR';
            }
        });
    }

    loadDeviceFuelStettings() {
        this.isResultLoaded = false;
        this.message = 'Chargement ...';
        this.technicalDataService.getDeviceCarb(this.selectedVehicule.idDevice).subscribe(deviceCarb => {
            this.currentCarbSetting = deviceCarb;
            this.toastr.success('Configuration chargée', 'info !');
            this.isResultLoaded = true;
            this.isResutSuccess = true;
            this.message = 'Configuration carburant pour le véhicule : ' + this.dataManagementService.getVehiculeName(this.selectedVehicule);

        }, (error)=> {

            let err = JSON.parse(JSON.stringify(error.error || null));

            this.isResultLoaded = true;
            this.isResutSuccess = false;

            if (err.errorMessage) {
                this.message = err.errorMessage;
            } else {
                this.message = 'SERVER ERROR';
            }
        });
    }

    updateDeviceCarb() {
        this.isResultLoaded = false;
        this.message = 'Chargement ...';
        this.technicalDataService.updateDeviceCarb(this.selectedVehicule.idDevice, this.currentCarbSetting).subscribe(reponse => {
            this.currentCarbSetting = reponse;
            this.toastr.success('Configuration enregistrée', 'info !');
            this.isResultLoaded = true;
            this.isResutSuccess = true;
            this.message = 'Configuration carburant enregistrée pour le véhicule : ' + this.dataManagementService.getVehiculeName(this.selectedVehicule);
        }, (error) => {
            this.isResultLoaded = true;
            this.isResutSuccess = false;

            let err = JSON.parse(JSON.stringify(error.error || null));

            if (err.errorMessage) {
                this.message = err.errorMessage;
            } else {
                this.message = 'SERVER ERROR';
            }
        });
    }

  updateDeviceMems() {
    if (this.currentMemsSetting === null) {
      this.toastr.error('Configuration MEMS invalide', 'Erreur !');
      return;
    }

    this.isMemsResultLoaded = false;
    this.memsMessage = 'Chargement ...';
    this.technicalDataService.updateDeviceMems(this.selectedVehicule.idDevice, this.currentMemsSetting)
      .subscribe((reponse: DeviceMems) => {
        this.currentMemsSetting = reponse;
        this.toastr.success('Configuration chargée', 'info !');
        this.isMemsResultLoaded = true;
        this.isMemsResutSuccess = true;
        this.memsMessage = 'Configuration MEMS pour le véhicule : ' + this.dataManagementService.getVehiculeName(this.dataManagementService.selectedVehicule);
      }, (error: HttpErrorResponse) => {
        this.isMemsResultLoaded = true;
        this.isMemsResutSuccess = false;

        let err = JSON.parse(JSON.stringify(error.error || null));

        if (err.errorMessage) {
          this.memsMessage = err.errorMessage;
        } else {
          this.memsMessage = 'SERVER ERROR';
        }
      });
  }


}
