import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AffectationInfo } from '../affectations';
import { AffectationChargeService } from './affectation-charge.service';
interface Month {
  id: number;
  name: string;
}
interface Year {
  id: number;
  name: string;
}

@Component({
  selector: 'app-affectation-charge',
  templateUrl: './affectation-charge.component.html',
  styleUrls: ['./affectation-charge.component.scss']
})
export class AffectationChargeComponent implements OnInit {

  @ViewChild('AffectationFormModal', { static: false })
  AffectationFormModal: ModalDirective;
  @ViewChild('AffectationDeleteModal', { static: false })
  AffectationDeleteModal: ModalDirective;

  affectations: AffectationInfo[] = [];
  affectationList: AffectationInfo[] = [];

  selectedAffectation: AffectationInfo | null= new AffectationInfo();
  selectedAffectationEdit: AffectationInfo | null = new AffectationInfo();

  loading: boolean = false;
  mode: string;
  now: Date = new Date();
  months: Month[];
  years: Year[];
  selectedMonth: { id: number, name: string };
  selectedYear: { id: number, name: string };

  //for search
  searchText = "";

  constructor(private affectationService: AffectationChargeService, private toastr: ToastrService) { }

  ngOnInit() {
    this.months = this.affectationService.months.filter(m => m.id <= this.now.getMonth() + 1);
    this.selectedMonth = this.months[this.months.length - 1];
    this.years = this.affectationService.getYears();
    this.selectedYear = this.years[0]
    this.loadAffectations();
  }



  openAffectationForm(mode: string) {
    this.mode = mode;
    if (this.mode === 'UPDATE')
      this.selectedAffectationEdit = this.selectedAffectation;
    else
      this.selectedAffectationEdit = null;  // Pas d'erreur ici maintenant
    this.AffectationFormModal.show();
  }


  onMonthOrYearChange(isByYear: boolean) {

    this.selectedAffectationEdit = null;
    this.affectations = [];
    if (this.selectedYear.id == this.now.getFullYear()) {
      this.months = this.affectationService.months.filter(m => m.id <= this.now.getMonth() + 1);
      if (isByYear)
        this.selectedMonth = this.months[this.months.length - 1]


    } else {
      this.months = this.affectationService.months;
    }
    this.loadAffectations();
  }



  loadAffectations() {
    this.loading = true;
    this.affectationService.getAffectations(this.selectedYear.id, this.selectedMonth.id)
      .subscribe((res: any[]) => {
        this.affectations = res;
        this.affectationList = this.affectations;
        this.selectedAffectation = this.affectations[0];
        this.loading = false;

      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Affectation")
      })
  }

  onAffectationSaved(affectation: AffectationInfo) {
    this.AffectationFormModal.hide();
    this.loadAffectations();

  }

  onDelete() {
    if (!this.selectedAffectation || this.selectedAffectation.idChargeChantier === undefined) {
      return;
    }

    this.loading = true;
    this.affectationService.deleteAffectation(this.selectedAffectation.idChargeChantier)
      .subscribe(() => {
        this.loading = false;
        this.AffectationDeleteModal.hide();
        this.toastr.success("Supprimé", "Affectation");
        this.loadAffectations();
      }, () => {
        this.loading = false;
        this.toastr.error("Erreur lors de suppression", "Affectation");
      });
  }


  SearchAffectation() {
    // init !
    this.affectationList = [];
    this.affectations.forEach(affectation => {

      if (this.searchText != null && this.searchText.length > 0) {
        if (affectation.group.nom.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1) {
          this.affectationList.push(affectation);
        }
      } else {
        this.affectationList.push(affectation);
      }
    });
  }


  onConfirmDelete() {
    this.AffectationDeleteModal.show();
  }

}
