<div class="rowpf">
  <!-- Left col -->
  <section class="col-lg-12 connectedSortable">
    <!-- Custom tabs (Charts with tabs)-->
    <div class="nav-tabs-custom">
      <!-- Tabs within a box -->
      <ul class="nav nav-tabs pull-right">

        <li  routerLink="/client/parametrage/chargesPfbvalidation" routerLinkActive="active">
          <a data-toggle="tab">
            <i class="fa fa-check-square-o" aria-hidden="true" style="color: #790fec"></i>
            &nbsp; Validation
          </a>
        </li>

        <li routerLink="/client/parametrage/chargesPfbvalidator" routerLinkActive="active">
          <a  data-toggle="tab">
            <i class="fa fa-user-plus" aria-hidden="true" style="color: #790fec" style="color: #790fec"></i> &nbsp;validateur
          </a>
        </li>

        <li  routerLink="/client/parametrage/chargesPfbtransportType" routerLinkActive="active">
          <a data-toggle="tab">
            <i class="fa fa-truck" aria-hidden="true" style="color: #790fec"></i>  &nbsp;Transport
          </a>
        </li>

        <li routerLink="/client/parametrage/canalsPfp" routerLinkActive="active">
          <a  data-toggle="tab">
            <i class="fa fa-university" aria-hidden="true" style="color: #790fec"></i>  &nbsp;Canal
          </a>
        </li>

        <li routerLink="/client/parametrage/chargesPfbPassage" routerLinkActive="active">
          <a  data-toggle="tab">
            <i class="fa fa-university" aria-hidden="true" style="color: #790fec"></i>  &nbsp;Passages
          </a>
        </li>
        <li class="pull-left header">
          <i class="fa fa-gear" matTooltip="Parametrage" aria-label="Gear Icon" matTooltipPosition="above"></i>
        </li>



        <li *ngIf="canalIcon" class="pull-left header">
          <i class="fa fa-university" aria-hidden="true" style="color: #790fec"></i> Canal |
          <button type="button" class="btn btn-primary btn-sm"
                  [routerLink]="['/client/parametrage/canalsPfp/form/', 0, 'ADD']"
                  routerLinkActive="active">Ajouter <i class="fa fa-plus-circle" aria-hidden="true"></i></button>
        </li>

        <li *ngIf="passageIcon" class="pull-left header">
          <i class="fa fa-university" aria-hidden="true" style="color: #790fec"></i> Passages |
          <button type="button" class="btn btn-primary btn-sm"
                  [routerLink]="['/client/parametrage/chargesPfbPassage/form/', 0, 'ADD']"
                  routerLinkActive="active">Ajouter <i class="fa fa-plus-circle" aria-hidden="true"></i></button>
        </li>

        <li *ngIf="transportIcon" class="pull-left header">
          <i class="fa fa-truck" aria-hidden="true" style="color: #790fec"></i>Transport |
          <button type="button" class="btn btn-primary btn-sm"
                  [routerLink]="['/client/parametrage/chargesPfbtransportType/form/', 0, 'ADD']"
                  routerLinkActive="active">Ajouter <i class="fa fa-plus-circle" aria-hidden="true"></i></button>
        </li>

        <li *ngIf="validateurIcon" class="pull-left header">
          <i class="fa fa-user-plus" aria-hidden="true" style="color: #790fec"></i>
          Validateur |
          <button type="button" class="btn btn-primary btn-sm "
                  [routerLink]="['/client/parametrage/chargesPfbValidator/form/', 0, 'ADD']"
                  routerLinkActive="active">Ajouter <i class="fa fa-plus-circle" aria-hidden="true"></i></button>
        </li>


        <li *ngIf="validationIcon" class="pull-left header">
          <i class="fa fa-check-square-o" aria-hidden="true" style="color: #790fec"></i>
          Validation |
<!--          <button type="button" class="btn btn-primary btn-sm "-->
<!--                  [routerLink]="['/client/parametrage/chargesPfbvalidation/']"-->
<!--                  routerLinkActive="active">Ajouter <i class="fa fa-plus-circle" aria-hidden="true" ></i></button>-->
<!--        </li>-->
      </ul>

      <div class="tab-content heightDiv">
        <router-outlet></router-outlet>
      </div>
    </div>
  </section>
</div>
