import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { AlertConfigurationTemplate, Mesure, Operator, UserAlert, UserAlertTemplate } from './alert-new';


@Injectable()
export class AlertManagementNewService {

  
  userAlertTemplates: UserAlertTemplate[] = [] ;

  alertConfigurationTemplates : AlertConfigurationTemplate[] = [];

  selectedAlertUserTemp: UserAlertTemplate = new UserAlertTemplate();  
  constructor(private _http: HttpClient) { }


  getAllMesures(): Observable<Mesure[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Mesure[]>(dns + 'alerts/mesures', { headers: headers });
  }
  getAllOperators(): Observable<Operator[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Operator[]>(dns + "alerts/operators", { headers: headers });
  }

  findUserAlertTemByName(name:string): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'alerts/gettemplatebyname',name, { headers: headers });
  }
  
  findAllAlertConfigTemplate(): Observable<AlertConfigurationTemplate[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<AlertConfigurationTemplate[]>(dns + 'alertstemplate/config', { headers: headers });
  }
  addUserAlertTemplate(userAlertTemplate:UserAlertTemplate): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post(dns + 'alerts/useralerttemplate', userAlertTemplate, options);

  }
  saveUserAlert(userAlert:UserAlert): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post(dns + 'alerts/useralert', userAlert, options);

  }

  getAllNameTemp(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get<any[]>(dns + 'alerts/nametemplates', { headers: headers });
  }

  getAllUserAlertes(): Observable<UserAlert[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<UserAlert[]>(dns + 'alerts/useralertes', { headers: headers });
  }

  deleteUserAlert(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete<Boolean>(dns + 'alerts/deleteUserAlert/' + id, { headers: headers });
  }

  updateUserAlert(edituserAlert:UserAlert): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.put(dns + 'alerts/updateuseralert', edituserAlert, options);

  }
  deleteUserAlertTemplate(id: number): Observable<Boolean> {
    let headers = createAuthorizationHeader();
    return this._http
      .delete<Boolean>(dns + 'alerts/deleteUserAlerttemp/' + id, { headers: headers });
  }


}
