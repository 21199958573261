import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsContainerComponent } from './groups-container.component';
import { RouterModule } from '@angular/router'; // Import RouterModule if you use routing
import { FormsModule } from '@angular/forms'; // Import FormsModule if you use forms
//import { LeafletModule } from '@asymmetrik/ngx-leaflet'; // Import Leaflet module if needed
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for Bootstrap modals
import { ToastrModule } from 'ngx-toastr'; // Import Toastr module if you're using it
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { PoiClientFormModule } from 'src/app/client-management/geo-marketing/poi-client/poi-client-form/poi-client-form.module';
import { PoiFormComponent } from 'src/app/client-management/data-management';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { PoiFormModule } from 'src/app/client-management/data-management/poi/poi-form/poi-form.module';
import { SearchBarModule } from './search-bar/search-bar.module';
import { GroupItemButtonsModule } from './group-item-buttons/group-item-buttons.module';
import { GroupFilterModule } from 'src/app/pipes/group-filter.module';
import { GroupElementModule } from './group-element/group-element.module';
import { GroupItemModule } from './group-item/group-item.module';

@NgModule({
  declarations: [
    GroupsContainerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule, // Include if your component uses routing
    FormsModule, // Include if your component uses forms
    LeafletModule, // Include if you're using Leaflet
    ModalModule.forRoot(), // Include if you're using ngx-bootstrap modal
    ToastrModule.forRoot(),
    PoiClientFormModule,
    PoiFormModule,
    SearchBarModule,
    GroupItemButtonsModule,
    GroupFilterModule,
    GroupElementModule,
    GroupItemModule

  ],
  exports: [
    GroupsContainerComponent // Export if needed
  ],
  providers: [
    // List any services you need to provide specifically for this module
  ]
})
export class GroupsContainerModule { }
