import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TonnageComponent } from './tonnage.component'; // Adjust the path if necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you're using Toastr
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using forms in the component
import { DiverManagementComponent } from '../diver-management/diver-management.component';
import { DiverManagementModule } from '../diver-management/diver-management.module';

@NgModule({
  declarations: [
    TonnageComponent,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Initialize Toastr module
    FormsModule,
    DiverManagementModule
  ],
  exports: [
    TonnageComponent // Export if you need to use this component in other modules
  ]
})
export class TonnageModule { }
