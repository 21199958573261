import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TollComponent } from './toll.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you plan to use ToastrService
import { DiverManagementComponent } from '../diver-management/diver-management.component';
import { DiverManagementModule } from '../diver-management/diver-management.module';

@NgModule({
  declarations: [
    TollComponent,
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(),
    DiverManagementModule
  ],
  exports: [
    TollComponent // Export the component if it needs to be used in other modules
  ]
})
export class TollModule { }
