import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/authentification/signin/credentials';

@Component({
    selector: 'app-parc-settings',
    templateUrl: './parc-settings.component.html',
    styleUrls: ['./parc-settings.component.css']
})
export class ParcSettingsComponent implements OnInit {

    isMenara = false;
    public currentUser: {} = new User();

  constructor() {
    const currentUserString = localStorage.getItem("currentUser");
    if (currentUserString) {
      this.currentUser = JSON.parse(currentUserString);
      // this.isMenara = this.currentUser.username.indexOf('Menara') !== -1;
    } else {
      this.currentUser = {};
      this.isMenara = false;
    }
  }


  ngOnInit() { }
}
