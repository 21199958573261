import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataManagementService, PoiClient, PointInterest } from 'src/app/client-management/data-management';
import { StopAccident } from 'src/app/shared/model/sinistre.model';

@Component({
  selector: 'app-add-stop-poi',
  templateUrl: './add-stop-poi.component.html',
  styleUrls: ['./add-stop-poi.component.css']
})
export class AddStopPoiComponent implements OnInit {
  @Input() childStop: StopAccident;
  @Output('closeEditing') closeEditing = new EventEmitter();

  poiForm: UntypedFormGroup;
  startSaving: boolean = false;

  constructor(private fb: UntypedFormBuilder, private dataManagementService: DataManagementService, private toastr: ToastrService) {
    this.createForm();
  }

  createForm() {
    this.poiForm = this.fb.group({
      latitude: [{ value: '', disabled: true }, Validators.required],
      longitude: [{ value: '', disabled: true }, Validators.required],
      region: [{ value: '', disabled: true }, Validators.required],
      address: ['', Validators.required],
      ray: [1000, Validators.required],
      name: ['', Validators.required],
      imageUri: [''],
      type: ['MARKER', Validators.required],
      typePoi: ['Client', Validators.required]
    });
  }


  ngOnInit(): void {
    this.setUpDefaultValue();
  }


  handleClick(event: any) {
    event.stopPropagation();
  }

  setUpDefaultValue() {
    const arabicRegex = /[\u0600-\u06FF]/g;
    const address = (this.childStop && this.childStop.adress && this.childStop.adress.display_name) 
      ? this.childStop.adress.display_name 
      : '';
    const filteredAddress = address.replace(arabicRegex, '');
    const region = (this.childStop && this.childStop.adress && this.childStop.adress.address && this.childStop.adress.address.region)
      ? this.childStop.adress.address.region
      : '';
    this.poiForm.patchValue({
      latitude: this.childStop.stopLatitude || '',
      longitude: this.childStop.stopLongitude || '',
      region: region, 
      address: filteredAddress,
      imageUri: 'marker1.png'
    });
  }
 

  onSubmit() {
    if (this.poiForm.valid) {
      if(this.poiForm.get('typePoi').value === 'Client'){
        const pointClientForm: PoiClient = this.poiForm.value;
        pointClientForm.coordinate = pointClientForm.coordinate || {
            lat: this.poiForm.get('latitude').value,
            lng: this.poiForm.get('longitude').value,
        };
        this.addPoiClient(pointClientForm);
      }else {
        const pointInteretForm: PointInterest = this.poiForm.value;
        pointInteretForm.coordinate = pointInteretForm.coordinate || {
            lat: this.poiForm.get('latitude').value,
            lng: this.poiForm.get('longitude').value,
        };
        this.addPoiInteret(pointInteretForm);
      }
    }
  }

  addPoiInteret(poiIntere: PointInterest){
    this.startSaving = true;
    this.dataManagementService.addPointInterest(poiIntere).subscribe(poiClient => {
      this.toastr.success("Point d'intérêt ajouté !", "Success");
      this.startSaving = false;
      this.closeAddPoi();
    }, (err) => {
      this.startSaving = false;
      this.toastr.error("Point d'intérêt non ajouté !", "Success");
    });
  }
  addPoiClient(poiClient: PoiClient){
    this.dataManagementService.addPointClient(poiClient).subscribe(poiClient => {
      this.toastr.success("Client ajouté !", "Success");
      this.startSaving = false;
      this.closeAddPoi();
    }, (err) => {
      this.startSaving = false;
      this.toastr.error("Client non ajouté !", "Success");
    });
  }

  closeAddPoi() {
    this.closeEditing.emit();
  }
}
