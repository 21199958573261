<div class="button-container">
    <div class="trajet-container">
        <div class="trajet" 
        [ngClass]="{ spinner: !isDataLoaded }" 
        (click)="showItem()"
      >
        <ng-container *ngIf="isDataLoaded; else loading">
          <div class="bars">
            <div
              width="8%"
              data-toggle="tooltip"
              title="{{ kmJourStop?.firstStopLocation }}"
            >
              <img
                src="assets/icons/realtime/km-jour/play-button.png"
                class="icon"
                alt="My Icon"
              />
              {{ kmJourStop?.firstStopLocation | slice : 0 : 20 }}
              <span *ngIf="kmJourStop && kmJourStop.firstStopLocation && kmJourStop.firstStopLocation.length > 20"> ...</span>
            </div>
            <div
              width="8%"
              data-toggle="tooltip"
              title="{{ kmJourStop?.lastStopLocation }}"
            >
              <img
                src="assets/icons/realtime/km-jour/stop-button.png"
                class="icon"
                alt="My Icon"
              />
              {{ kmJourStop?.lastStopLocation | slice : 0 : 20 }}
              <span *ngIf="kmJourStop && kmJourStop.lastStopLocation && kmJourStop.lastStopLocation.length > 20"> ...</span>
            </div>
            <div >
                <img
                  src="assets/icons/realtime/km-jour/Key.png"
                  class="icon"
                  alt="My Icon"
                />
                {{ (kmJourStop ? kmJourStop.maxDuration  : 00) | durationSec}}
            </div>
          </div>
          <div class="bars bar2">
            <div>
              <img
                src="assets/icons/realtime/km-jour/pin-map-red.png"
                class="icon"
                alt="My Icon"
              />{{ (kmJourStop ? kmJourStop.firstStopTime : null) | date :'d/M/yy HH:MM' }}
            </div>
            <div>
              <img
                src="assets/icons/realtime/km-jour/pin-map-red.png"
                class="icon"
                alt="My Icon"
              />{{ (kmJourStop ? kmJourStop.lastStopTime : null) | date :'d/M/yy HH:MM'}}
            </div>
            <div>
              <img
              src="assets/icons/realtime/km-jour/driver.png"
              class="icon"
              alt="My Icon"
              /> {{kmJourStop != null ? kmJourStop.chauffeur : ''}}
            </div>
          </div>
          <div 
          class="simulation" 
          (click)="activatSimulation($event)"
          >
            <img
            src="assets/icons/realtime/km-jour/itineraire-de-la-carte.png"
            class="icon-cart"
            alt="My Icon"
            [ngStyle]="{
                'opacity': stpsService.isInSimulation() ? '1' : '0.4'
                }"
                />
                
            </div>
        </ng-container>
      </div>
    </div>
  </div>
  
  <ng-template #loading>
    <div style="padding-bottom: 2px">
      <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
    </div>
  </ng-template>
  