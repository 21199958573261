import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountManagementComponent } from './account-management.component';
import { NgxLoadingModule } from 'ngx-loading'; // Import ngx-loading if you're using it
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using ngModel
import { AccountFormComponent } from './account-form/account-form.component';
import { AccountFormModule } from './account-form/account-form.module';

@NgModule({
  declarations: [
    AccountManagementComponent,
  ],
  imports: [
    CommonModule,
    NgxLoadingModule.forRoot({}),
    FormsModule,
    AccountFormModule
  ],
  exports: [
    AccountManagementComponent
  ]
})
export class AccountManagementModule { }
