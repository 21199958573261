import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CimentRotationComponent } from './ciment-rotation.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import Datepicker Module
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { NgxLoadingModule } from 'ngx-loading'; // Import Loading Module
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    CimentRotationComponent // Declare the CimentRotationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(), // Initialize Datepicker Module
    ToastrModule.forRoot(), // Initialize ToastrModule for notifications
    NgxLoadingModule.forRoot({})
  ],
  exports: [
    CimentRotationComponent // Export the CimentRotationComponent if used in other modules
  ]
})
export class CimentRotationModule {}
