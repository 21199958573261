import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimediffPipe } from './timediff.pipe';  // Adjust the path as necessary

@NgModule({
  declarations: [
    TimediffPipe   // Declare the TimediffPipe here
  ],
  imports: [
    CommonModule   // Import CommonModule to make common directives available
  ],
  exports: [
    TimediffPipe   // Export TimediffPipe so it can be used in other modules
  ]
})
export class TimediffPipeModule { }
