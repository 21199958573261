import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemisageSettingsComponent } from './remisage-settings.component';
import { AlertManagementService } from '../../alert-management.service';
import { DataManagementService } from 'src/app/client-management/data-management';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox'; // Importing Material Checkbox Module if needed
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; // Importing Material Slide Toggle Module if needed
import { FormsModule } from '@angular/forms';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';

@NgModule({
  declarations: [
    RemisageSettingsComponent // Declare the RemisageSettingsComponent
  ],
  imports: [
    CommonModule, // Common Angular directives
    ToastrModule.forRoot(), // For toastr notifications
    RouterModule, // For routing functionality
    MatCheckboxModule, // For Material Checkbox if used
    MatSlideToggleModule, // For Material Slide Toggle if used
    FormsModule,
    EmailManagerModule,
    CronSettingsModule
  ],
  providers: [
    AlertManagementService, // Service to manage alerts
    DataManagementService // Service for data management
  ]
})
export class RemisageSettingsModule { }
