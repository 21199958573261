import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReversePipe } from './reverse.pipe';  // Adjust the path as necessary

@NgModule({
  declarations: [
    ReversePipe   // Declare the ReversePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ReversePipe
  ]
})
export class ReverseModule { }
