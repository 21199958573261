import { Injectable } from '@angular/core';
import { MapService } from "../../utils/leaflet/service/map.service";
import { imagesPOIDir, dns } from "../../global.config";
import { VehiculesAccordingToPoi } from "./overview";

import * as L from 'leaflet';
import { createAuthorizationHeader } from '../../utils/security/headers';
import { Observable } from 'rxjs';
import { Sinistre, Fueling } from '../parc-management';
import { RealtimeRestService } from '../realtime/services/realtime-rest.service';
import { HttpClient } from '@angular/common/http';
import {Icon, Marker} from "leaflet";


@Injectable()
export class OverviewService {

  constructor(private _http: HttpClient, private mapService: MapService, public realTimeRestService: RealtimeRestService) {
  }

  displayVehiculesAccordingToPoi(vehiculesAccordingToPoi: VehiculesAccordingToPoi) {

    this.mapService.removeMarkersFromMap();
    this.mapService.removeMarkersPoiFromMap();
    this.mapService.removePolygonsPoiFromMap();
    this.mapService.removeCirclesFromMap();

    let popup = "<span style=\" text-decoration: underline;font-weight: bold\">Vehicules :</span> " + this.realTimeRestService.getVehiculesAlias(vehiculesAccordingToPoi.vehicules);
    popup = popup + '<hr><center><span class="leaflet-pelias-layer-icon-container"><div class="leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point"></div></span><strong>' + vehiculesAccordingToPoi.poi.name + '</strong></center>';
    let marker: L.Marker<any> = null;

    marker = new Marker(vehiculesAccordingToPoi.poi.coordinate);

    marker.on("click", () => {
      this.mapService.map.setView(vehiculesAccordingToPoi.poi.coordinate, 17);
    });

    marker.on("mouseover", () => {
      marker.openPopup();
    });

    marker.on('add', () => {
      marker.openPopup();
    });

    marker.bindPopup(popup);
    marker.openPopup();
    marker.setIcon(new Icon({
      iconUrl: imagesPOIDir + 'poiCenter.png',
      iconAnchor: [7, 10],
      popupAnchor: [10, -25],
      iconSize: [30, 30]
    }));

    let circle = L.circle(vehiculesAccordingToPoi.poi.coordinate, {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.1,
      radius: vehiculesAccordingToPoi.poi.ray
    });

    this.mapService.addCircle(circle);
    this.mapService.addMarkerPoi(marker);

    var mapCenterMarker = new L.LatLng(vehiculesAccordingToPoi.poi.coordinate.lat, vehiculesAccordingToPoi.poi.coordinate.lng + -0.003);

    this.mapService.map.setView(mapCenterMarker, 17);
  }

  getSinistres(idVehicule: number): Observable<Sinistre[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Sinistre[]>(dns + 'sinistres/' + idVehicule, { headers: headers });
  }


  getListFueling(idVehicule: number): Observable<Fueling[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Fueling[]>(dns + "fuelings/list/" + idVehicule, { headers: headers });
  }

}
