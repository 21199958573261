import { PointInterest } from '../data-management/data-management.model';

export class Alert {
  id: number;
  deviceId: number;
  groupId: number;
  isActive: boolean;
  isByGroup = true;
  isByAllVehicules = false;
  isByType = false;
  subMark: string;
  name:
    | 'SPEED'
    | 'SPEED_CARTO'
    | 'INGNITION_V0'
    | 'ZONE'
    | 'POI'
    | 'TOW'
    | 'NO_POI'
    | 'CHOMAGE'
    | 'DRIVING_QUALITY'
    | 'DOC'
    | 'VIDANGE'
    | 'TIRE'
    | 'BATTERY'
    | 'BRAKE'
    | 'CHARGER'
    | 'CONSUMPTION'
    | 'ACTIVITY'
    | 'REMISAGE'
    | 'ACTIVITY_DAILY'
    | 'ACTIVITY_DAILY_START'
    | 'OUT_OF_HOURS_TRAFIC'
    | 'APPRO_FUEL';
  timeToRealert: number;
  type: string;
  alertConfigurations: AlertConfiguration[];
  emailMode: boolean;
  emails: string;
  enableGlobalConfig: boolean;
  enableGlobalConfigToAllVehicules: boolean;
  constructor() { }
}

export class Notification {
  id: number;

  alertValue: string;

  createdAt: Date;

  deviceId: string;

  isReaded: boolean;

  name:
    | 'SPEED'
    | 'SPEED_CARTO'
    | 'INGNITION_V0'
    | 'ZONE'
    | 'POI'
    | 'TOW'
    | 'NO_POI'
    | 'CHOMAGE'
    | 'DRIVING_QUALITY'
    | 'DOC'
    | 'VIDANGE'
    | 'TIRE'
    | 'BATTERY'
    | 'BRAKE'
    | 'CHARGER'
    | 'CONSUMPTION'
    | 'ACTIVITY'
    | 'REMISAGE'
    | 'ACTIVITY_DAILY'
    | 'ACTIVITY_DAILY_START'
    | 'OUT_OF_HOURS_TRAFIC'
    | 'APPRO_FUEL';

  lat: number;

  lng: number;

  address: string;

  highway: boolean;

  poiId: number;

  message: string;

  matricule: string;

  alias: string;

  mark: string;

  subMark: string;

  constructor() { }
}


export class FuelingNotificationDto{
  idDevice: number;
	idVehicule: number;
	matricule: string;
  mark: string;
	consumption: number;
  fuelAverage: number;
  operationTime: Date;
  difference:number;
  constructor() {
  }
}

export class AlertConfiguration {
  id: number;
  isActive: boolean;
  value1: string | number | null;
  value2: string | null;
  type:
    | 'SPEED'
    | 'REMISAGE'
    | 'SPEED_CARTO'
    | 'INGNITION'
    | 'ZONE'
    | 'POI'
    | 'CHOMAGE'
    | 'CHARGEMENT'
    | 'TOW'
    | 'RT'
    | 'DATE'
    | 'CHARGER'
    | 'DRIVING_QUALITY'
    | 'INSURANCE'
    | 'VISIT'
    | 'ROAD_TAXES'
    | 'OPERATIONAL_CERTIFICATION'
    | 'PERMIT_CIRCULATION'
    | 'Air_F'
    | 'Fuel_F'
    | 'Separator_F'
    | 'Oil_F'
    | 'Engine_Oil'
    | 'Max_km'
    | 'BATTERY_DURATION'
    | 'BOARD_GAMES'
    | 'DISK'
    | 'PUMP'
    | 'OIL'
    | 'CONSUMPTION'
    | 'ACTIVITY'
    | 'ACTIVITY_DAILY';

  payload: CronPayload | PointInterest | any;

  constructor() {
    this.value1 = null;
    this.value2 = null;
    this.payload = new CronPayload();
  }
}

export class CronPayload {
  days: {
    monday: boolean;
    thesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  from: {
    hour: number;
    minute: number;
  };
  to: {
    hour: number;
    minute: number;
  };
  night: boolean;
  morning: boolean;

  constructor() {
    this.morning = false;
    this.night = false;
    this.initDays();

    this.from = {
      hour: 0,
      minute: 0
    };

    this.to = {
      hour: 0,
      minute: 0
    };


  }

  initDays() {
    this.days = {
      monday: false,
      thesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    };
  }
}
export class EmailAddress {
  id: number;
  emailAddress: string;
}
