import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts8 from 'highcharts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ActivityTimeChartService } from './activity-time-chart.service';
import { DateInterval } from 'src/app/shared/model';
import { TravelDashbordDto } from 'src/app/client-management/parc-management';
import { interval, Subscription } from 'rxjs';
require('highcharts/modules/exporting')(Highcharts8);
require('highcharts/modules/no-data-to-display')(Highcharts8);

if (!Highcharts8.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts8);
}
@Component({
  selector: 'app-activity-time-chart',
  templateUrl: './activity-time-chart.component.html',
  styleUrls: ['./activity-time-chart.component.css']
})
export class ActivityTimeChartComponent implements OnInit {
  groupeStatistics: any[] = [];
  travelDashbords: TravelDashbordDto[] = [];
  Activity: Object | null= null;
  date: DateInterval = new DateInterval();
  endDate: Date = new Date();
  startDate: Date = new Date();
  globalLoading: boolean = false;
    featching: boolean = true;

    /** UPDATE TRACKING TIMER */
    updateTrackingTimer: number | null = null;

  updateTrackingTimerSubscription: Subscription | null = null;


  constructor(private activityChartService: ActivityTimeChartService, public toastr: ToastrService,private localeService: BsLocaleService) { }

  ngOnInit() {
    this.localeService.use('fr');
    this.endDate.setDate(new Date().getDate());
    this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate());
    this.startDate.setHours(0,0,0,0);
    this.endDate.setHours(23,59,59,59);
    this.getActivityTimeInfo();

  }

  getActivityTimeInfo() {
    this.globalLoading = true;
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.date.startDate = this.startDate.getTime();
    this.date.endDate = this.endDate.getTime();
    this.activityChartService.getActivityTimeInfo(this.date).subscribe(res => {
      this.groupeStatistics = res as TravelDashbordDto[];
      this.activityChart();
      this.startUpdateTrackingTimer();
      this.globalLoading = false;
    })
  }


  activityChart() {
    this.setHighCartsLang();
    var that = this;
    var drilldata: any[] = [];
    var totalDrivingDurationOfAllgroups = 0;
    var data: { name: string, y: number, cust: string , drilldown: string }[] = [];
    var groupesName = new Set(this.groupeStatistics.map(s => {
      return s;
    })
    );

    for (var i = 0; i < this.groupeStatistics.length; i++) {
      totalDrivingDurationOfAllgroups += (this.groupeStatistics[i].drivingDuration/1000);
    }

    groupesName.forEach(groupeName => {

      data.push({ name: groupeName.groupeName, y: ((groupeName.drivingDuration/1000) * 100)/totalDrivingDurationOfAllgroups, cust:groupeName.drivingDurationStr, drilldown: groupeName.groupeId });
    })
    this.Activity = {
      chart: {
        type: 'column',
        events: {
          drilldown: function (e: { seriesOptions: any; point: { drilldown: number; y: number; name: any; }; }) {
            if (!e.seriesOptions && that.featching) {
              that.featching = false;
              setTimeout(()=>{
                that.featching = true;
              }, 1000);

            var vehiculesdata: { name: string, y: number, cust: string  }[] = [];

            that.activityChartService.getActivityTimeBygroup(e.point.drilldown, that.date).subscribe(res => {
              for (let i = 0; i < res.length; i++) {
                vehiculesdata.push({name: res[i].vehiculeMatricule , y:((res[i].drivingDuration/1000)* 100) / e.point.y, cust: res[i].drivingDurationStr});
                drilldata.push({ name: e.point.name, id: e.point.drilldown, data: vehiculesdata });

              }
            });
          }
          }
        }
      },
      title: {
        text: 'Temps d\'activité par groupe',
        style: {
          fontWeight: 'bold'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',

        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: 'Σ.t Activité'
        },
        labels: {
          enabled: false
      }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          borderRadius: 5
      },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.cust} ',
            softThreshold: true
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name} </span> <br><b>{point.cust}</b> <br/>'
      },
      series: [
        {
          name: "Groupe",
          colorByPoint: true,
          dataLabels: {
            enabled: false,
            format: '{point.cust}'
          }, tooltip: {
            pointFormat: '<span style="color:{point.color}">{point.name} </span> <br><b>{point.cust}</b> <br/>'
          },
          data: data
        }
      ],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold'
        },
        series: drilldata
      }
    }
    Highcharts8.chart('activityTime', this.Activity);
  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts8.setOptions({
      colors: ['#0d233a', '#8bbc21',  '#a6c96a','#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
      '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92','#007bff', '#66B92E', '#DA932C', '#D65B4A', '#24CBE5', '#64E572', '#FF9655', '#6AF9C4',
      '#910000', '#1aadce','#492970', '#f28f43', '#77a1e5', '#c42525','#2f7ed8', '#f39c12', '#D81B60', '#605ca8', '#3D9970', '#001F3F', '#00c0ef', ' #451e3e', '#adcbe3', '#f37736', '#854442', '#3d1e6d', '#c68642'],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        months: [
          'Janvier', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        weekdays: [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'
        ],
        shortMonths: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        noData: 'Aucune donnée n\'est disponible'

      }
    });

}

/** STOP TRACKING */
stopTracking() {
  if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
  this.updateTrackingTimer = null;
}

  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.endDate < this.startDate) {
      this.endDate = null;
    }
    this.stopTracking();
  }

  ngOnDestroy() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
  }

  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = 900;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      this.updateTrackingTimer--;
      if (this.updateTrackingTimer == 0) {
        this.updateTrackingTimerSubscription.unsubscribe();
        this.getActivityTimeInfo();
      }
    })
  }
}

