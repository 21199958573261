<form class="form-inline">

  <table style="border-spacing: 10px;border-collapse: separate;">
    <tr>
      <td>
        <label>Heure de Travail</label>
      </td>
      <td>
          <input type="number" class="form-control" placeholder="Heu..." name="workTime" min="1" max="24" [(ngModel)]="activityHourAlertConfiguration.value1">

      </td>
    </tr>
  </table>

  <div style="margin: 10px 0 10px 0" *ngIf="!showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-plus-square"></i> <span class="link">paramétrage avancé</span>
  </div>

  <div style="margin: 10px 0 10px 0" *ngIf="showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-minus-square"></i> <span class="link">masquer paramétrage avancé</span>
  </div>

  <app-cron-settings *ngIf="showAdvancedSettings" [cron]="cronAlertConfiguration"></app-cron-settings>

  <app-email-manager [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails"
  [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" (onChange)="emailSettings($event)"></app-email-manager>

  <br>
 
  <div class="form-group">
      <label>Activer l'alert</label>
      <div class="input-group">
          <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive" ></mat-slide-toggle>
      </div>
  </div>
 
  <button type="submit" class="btn btn-flat btn-primary pull-right" style="margin-top: 45px;" (click)="save()">
      Sauvegarder
  </button>
</form>