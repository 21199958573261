import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CimentSite } from './ciment-sites';
import { CimentSiteService } from './ciment-site.service';

@Component({
  selector: 'app-ciment-sites',
  templateUrl: './ciment-sites.component.html',
  styleUrls: ['./ciment-sites.component.css']
})
export class CimentSitesComponent implements OnInit {

  // local vars
  loading: boolean;
  globalLoading: boolean = false;
  @ViewChild('deleteModal', { static: false })
  deleteModal: ModalDirective;
  site : CimentSite = new CimentSite();

  constructor(public toastr: ToastrService, private cimentSiteService: CimentSiteService) { }


  ngOnInit() {
    this.loadCimentSites();
  }

  loadCimentSites() {
    if (this.cimentSiteService.sites.length <= 0) {
      this.loading = true;
      this.cimentSiteService.findAllCimentCites().subscribe(sites => {
        this.cimentSiteService.sites = sites;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Sites");
      })
    }
  }

  confirmDeleteCimentSite(site: CimentSite) {
    this.site = site;
    this.deleteModal.show();
  }

  onDelete() {
    this.loading = true;
    this.cimentSiteService.deleteCimentSite(this.site.id).subscribe(deleted => {
      this.deleteModal.hide();
      this.loading = false;
      if (deleted) {
        this.cimentSiteService.sites = this.cimentSiteService.sites.filter(site => site.id != this.site.id);
        this.toastr.success('Site bien supprimer !', 'info');
      } else {
        this.toastr.error("Erreur lors de suppression", "Site");
      }
    }, err => {
      this.loading = false;
      this.toastr.error("Erreur lors de suppression", "Site");
    }, () => {
      this.loading = false;
    })
  }

}
