import { PoiClient, Vehicule } from "../data-management";

export class GeoMarketingInfoDto {
    id: number;
    label: string;
    createAt: Date;
    pointOfInterests?: PoiClient[];

    constructor() {
        this.pointOfInterests = [];
    }
}


export class GeoMarketingAddDto{
    id: number;
    label: string;
    createAt: Date;
    idPointClient?: number[];

    constructor() {
        this.idPointClient = [];
    }
}


export class GeoMarketingAssignmentAddDto {
    id: number;
    idVehicule: number
    dateDebut: Date;
    dateFin: Date | undefined = null;
    idgeoMarketingMn: number | null = null;
    idgeoMarketingTu: number | null = null;
    idgeoMarketingWd: number | null = null;
    idgeoMarketingTr: number | null = null;
    idgeoMarketingFr: number | null = null;
    idgeoMarketingSt: number | null = null;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    label: string;
    constructor() {

    }
}


export class GeoMarketingAssignmentInfoDto {
    id: number;
    vehicule: Vehicule;
    dateDebut: Date;
    dateFin: Date;
    geoMarketingMn: GeoMarketingInfoDto;
    geoMarketingTu: GeoMarketingInfoDto;
    geoMarketingWd: GeoMarketingInfoDto;
    geoMarketingTr: GeoMarketingInfoDto;
    geoMarketingFr: GeoMarketingInfoDto;
    geoMarketingSt: GeoMarketingInfoDto;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    label: string;
    constructor() {
        this.vehicule = new Vehicule;
        this.geoMarketingMn = new GeoMarketingInfoDto();
        this.geoMarketingTu = new GeoMarketingInfoDto();
        this.geoMarketingWd = new GeoMarketingInfoDto();
        this.geoMarketingTr = new GeoMarketingInfoDto();
        this.geoMarketingFr = new GeoMarketingInfoDto();
        this.geoMarketingSt = new GeoMarketingInfoDto();
    }
}

