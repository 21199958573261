import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ToastrModule } from 'ngx-toastr';

import { ActivityDailySettingsComponent } from './activity-daily-settings.component';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';

@NgModule({
  declarations: [
    ActivityDailySettingsComponent,
     // Make sure this is declared if used in the component's template
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSlideToggleModule,
    ToastrModule.forRoot(),
    EmailManagerModule,
    CronSettingsModule
  ],
  exports: [
    ActivityDailySettingsComponent
  ]
})
export class ActivityDailySettingsModule { }
