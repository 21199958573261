import { vehciuleIcons } from './../../vehicule-icon.model';
import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import 'rxjs/add/operator/switchMap';
import { Device, Driver, Group, Vehicule } from '../../data-management.model';
import { DataManagementService } from '../../data-management.service';
import { forkJoin } from 'rxjs';
import { imagesCarsDir } from '../../../../global.config';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { VehiculeService, GroupService, DriverService } from '../../providers';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { User } from 'src/app/authentification/signin/credentials';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-vehicule-form',
  templateUrl: './vehicule-form.component.html',
  styleUrls: ['./vehicule-form.component.css'],
})
export class VehiculeFormComponent implements OnInit {
  currentVehicule: Vehicule = new Vehicule();

  passasions: string[] = [];
  vehicules: string[] = [];

  // format to use in select
  unusedDevicesItems: { text: string | number; id: number }[] = [];
  groupsItems: { text: string | number; id: number }[] = [];
  selectedGroupsItems: { text: string; id: number }[] = [];
  driversItems: { text: string | number; id: number }[] = [];

  loading: boolean = false;
  mode: 'ADD' | 'ASSIGN' | 'UPDATE' | 'SHOW';
  error: { errorMessage: string } = { errorMessage: '' };
  imagesCarsDir = imagesCarsDir;
  deleteAvatar: boolean = false;
  deleteGrayCard: boolean = false;
  deletePermisCard: boolean = false;
  deleteContracting: boolean = false;
  deleteSecondContracting: boolean = false;
  deletethirdContracting: boolean = false;

  // date Assignment
  endDate: Date = new Date();
  assignmentDate: Date = new Date();

  // circulationAt: Date = new Date();

  images = vehciuleIcons;

  selectedImage: { text: string; uri: string; speed: number } = this.images[0];

  selectedGroupsHash: string = '';

  isMenaraOrCarrier = false;
  public currentUser = new User();
  imageForm: UntypedFormGroup;
  selectedImages: (File | null)[] = [null, null, null, null, null];

  constructor(
    private vehiculeService: VehiculeService,
    private groupService: GroupService,
    private driverService: DriverService,
    public dataManagementService: DataManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private localeService: BsLocaleService,
    public signinService: SigninService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (
      this.currentUser &&
      (this.currentUser.username.toLocaleLowerCase().startsWith('menara') ||
        this.currentUser.username.toLocaleLowerCase().startsWith('carriere'))
    ) {
      this.isMenaraOrCarrier = true;
    }
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
      this.init(params.id);
    });
    this.imageForm = this.formBuilder.group({});
  }

  init(vehiculeId: any) {
    // ===============================================
    // init currrent vehicule object according to mode !
    // ===============================================
    if (this.mode !== 'ADD') {
      // retreice vehicule from server
      this.loadVehiculeById(vehiculeId);
    } else {
      // init current vehicule
      this.currentVehicule = new Vehicule();
      this.currentVehicule.device = new Device();
      // this.currentVehicule.circulationAt = new Date();
      this.currentVehicule.maxSpeed = this.selectedImage.speed;
    }
    // ===============================================
    // load usefull data according to mode !
    // ===============================================
    if (this.mode == 'ADD') {
      // load unused devices
      // load all groups
      // load driver
      this.loading = true;
      forkJoin(
        this.vehiculeService.getUnusedDevices(),
        this.groupService.getGroupsLabel(),
        this.driverService.getDriversLabel()
      ).subscribe(
        ([unusedDevices, groups, drivers]) => {
          this.mapDevicesToItems(unusedDevices);
          this.mapGroupsToItems(groups);
          this.mapDriversToItems(drivers);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }

    if (this.mode == 'UPDATE') {
      // load all groups
      this.loading = true;
      forkJoin(
        this.groupService.getGroupsLabel(),
        this.vehiculeService.getGroupsOfVehicule(vehiculeId)
      ).subscribe(
        ([groups, selectedGroups]) => {
          this.mapGroupsToItems(groups);
          this.mapSelectedGroupsToItems(selectedGroups);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }

    if (this.mode == 'ASSIGN') {
      // load driver
      this.loadDrivers();
    }
  }

  getImageByUri(uri: any) {
    for (let i = 0; i < this.images.length; i++) {
      if (this.images[i].uri === uri) {
        return this.images[i];
      }
    }
    return this.images[0];
  }

  deletePassion(checked: boolean, id: string) {
    if (checked) {
      this.currentVehicule.passasionToBeDeleted.push(id);
    } else if (
      !checked &&
      this.currentVehicule.passasionToBeDeleted.indexOf(id) !== -1
    ) {
      this.currentVehicule.passasionToBeDeleted =
        this.currentVehicule.passasionToBeDeleted.filter((ids) => ids !== id);
    }
  }

  deleteVehicule(checked: boolean, id: string) {
    if (checked) {
      this.currentVehicule.vehiculeImageToBeDeleted.push(id);
    } else if (
      !checked &&
      this.currentVehicule.vehiculeImageToBeDeleted.indexOf(id) !== -1
    ) {
      this.currentVehicule.vehiculeImageToBeDeleted =
        this.currentVehicule.vehiculeImageToBeDeleted.filter(
          (ids) => ids !== id
        );
    }
  }

  loadVehiculeById(id: any) {
    this.loading = true;
    // this.vehiculeService.getOne(id).subscribe(
    this.vehiculeService.getOneWithDocuments(id).subscribe(
      (vehicule) => {
        this.currentVehicule = vehicule;
        this.currentVehicule.permisUri =
          vehicule.documents['permis'] === undefined
            ? null
            : vehicule.documents['permis'];
        this.loading = false;
        this.currentVehicule.circulationAt = new Date(vehicule.circulationAt);
        this.currentVehicule.vehiculeImageToBeDeleted = [];
        this.selectedImage = this.getImageByUri(vehicule.icon);
        Object.entries(this.currentVehicule.documents).forEach(
          ([key, value]) => {
            if (key.startsWith('passasion_')) {
              let contractNumber = key.split('_')[1]; // Assuming the format is always 'contracting_X'
              this.passasions.push(contractNumber);
            }
            if (key.startsWith('vehicule_')) {
              let contractNumber = key.split('_')[1]; // Assuming the format is always 'contracting_X'
              this.vehicules.push(contractNumber);
            }
          }
        );
      },
      (error) => {
        this.error = error;
        this.error = JSON.parse(JSON.stringify(error.error || null));

        if (!this.error.errorMessage) {
          this.error.errorMessage = 'SERVER ERROR';
        }

        this.loading = false;
      }
    );
  }

  loadDrivers() {
    this.driverService.getDriversLabel().subscribe((response) => {
      this.mapDriversToItems(response);
    });
  }

  /**
   * =====================================================
   * mappers
   * =====================================================
   * */
  mapDevicesToItems(devices: Device[]) {
    this.unusedDevicesItems = [];
    for (let i = 0; i < devices.length; i++) {
      this.unusedDevicesItems.push({
        id: devices[i].idDevice,
        text: devices[i].idDevice + '',
      });
    }
  }

  mapGroupsToItems(groups: Group[]) {
    this.groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      this.groupsItems.push({
        id: groups[i].idGroupe,
        text: groups[i].nom,
      });
    }
  }

  mapSelectedGroupsToItems(groups: Group[]) {
    this.selectedGroupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      this.selectedGroupsItems.push({
        id: groups[i].idGroupe,
        text: groups[i].nom,
      });
    }

    this.selectedGroups(this.selectedGroupsItems);
  }

  mapDriversToItems(drivers: Driver[]) {
    this.driversItems = [];
    for (let i = 0; i < drivers.length; i++) {
      this.driversItems.push({
        id: drivers[i].idDriver,
        text: this.dataManagementService.getDriverName(drivers[i]),
      });
    }
    this.driversItems.push({ id: -1, text: 'Sans chauffeur' });
  }

  /**
   * =====================================================
   * callbacks
   * =====================================================
   * */
  selectedDevice(device: { id: number; text: string }) {
    if (device.id) {
      this.currentVehicule.device.idDevice = device.id;
    } else {
      this.currentVehicule.device.idDevice = undefined;
    }
  }

  selectedGroups(groups: { id: number; text: string }[]) {
    let prefix = '';
    this.selectedGroupsHash = '';
    groups.forEach((group) => {
      this.selectedGroupsHash += prefix + group.id;
      prefix = ',';
    });
  }

  selectedDrivers(driver: { id: number; text: string }) {
    if (driver.id) {
      this.currentVehicule.driver = new Driver();
      this.currentVehicule.driver.idDriver = driver.id;
    } else {
      this.currentVehicule.driver = null;
    }
  }

  onEmailChange(emailPayload: any) {
    this.currentVehicule.emails = emailPayload.emails;
  }

  onAvatarChange($event: any) {
    this.currentVehicule.avatar = $event.target.files[0];
  }

  onGrayCardChange($event: any) {
    this.currentVehicule.grayCard = $event.target.files[0];
  }

  onPermisChange($event: any) {
    this.currentVehicule.permis = $event.target.files[0];
  }

  onPassionChange($event : any) {
    this.currentVehicule.passasion = $event.target.files[0];
  }
  onVehiculeImageChange($event: any) {
    this.currentVehicule.vehiculeImage = $event.target.files[0];
  }

  onContractingChange($event: any) {
    this.currentVehicule.contracting = $event.target.files[0];
  }

  onSecondContractingChange($event: any) {
    this.currentVehicule.secondContracting = $event.target.files[0];
  }

  onThirdContractingChange($event: any) {
    this.currentVehicule.thirdContracting = $event.target.files[0];
  }

  avatarCheckboxChange(checked: boolean) {
    this.deleteAvatar = checked;
  }

  grayCardCheckboxChange(checked: boolean) {
    this.deleteGrayCard = checked;
  }
  permisCheckboxChange(checked: boolean) {
    this.deletePermisCard = checked;
  }

  contractingCheckboxChange(checked: boolean) {
    this.deleteContracting = checked;
  }

  secondContractingCheckboxChange(checked: boolean) {
    this.deleteSecondContracting = checked;
  }

  thirdContractingCheckboxChange(checked: boolean) {
    this.deletethirdContracting = checked;
  }

  addOrUpdateVehicule() {
    this.loading = true;

    this.currentVehicule.icon = this.selectedImage.uri;
    const _formData = new FormData();
    delete this.currentVehicule.realTimeRecord;
    delete this.currentVehicule.avatarUri;
    delete this.currentVehicule.grayCardUri;
    delete this.currentVehicule.permisUri;
    delete this.currentVehicule.contractingUri;
    delete this.currentVehicule.secondContractingUri;
    delete this.currentVehicule.thirdContractingUri;

    if (this.currentVehicule.driver) {
      delete this.currentVehicule.driver.avatarUri;
    }
    if (this.currentVehicule.avatar) {
      _formData.append(
        'avatar',
        this.currentVehicule.avatar,
        this.currentVehicule.avatar.name
      );
    }
    if (this.currentVehicule.grayCard) {
      _formData.append(
        'grayCard',
        this.currentVehicule.grayCard,
        this.currentVehicule.grayCard.name
      );
    }
    if (this.currentVehicule.permis) {
      _formData.append(
        'permis',
        this.currentVehicule.permis,
        this.currentVehicule.permis.name
      );
    }
    if (this.currentVehicule.passasion) {
      _formData.append(
        'passasion',
        this.currentVehicule.passasion,
        this.currentVehicule.passasion.name
      );
    }
    if (this.currentVehicule.vehiculeImage) {
      _formData.append(
        'vehiculeImage',
        this.currentVehicule.vehiculeImage,
        this.currentVehicule.vehiculeImage.name
      );
    }

    if (this.currentVehicule.contracting) {
      _formData.append(
        'contracting',
        this.currentVehicule.contracting,
        this.currentVehicule.contracting.name
      );
    }

    if (this.currentVehicule.secondContracting) {
      _formData.append(
        'secondContracting',
        this.currentVehicule.secondContracting,
        this.currentVehicule.secondContracting.name
      );
    }

    if (this.currentVehicule.thirdContracting) {
      _formData.append(
        'thirdContracting',
        this.currentVehicule.thirdContracting,
        this.currentVehicule.thirdContracting.name
      );
    }

    _formData.append('vehicule', JSON.stringify(this.currentVehicule));

    if (this.mode === 'ADD') {
      this.vehiculeService
        .add(_formData, this.selectedGroupsHash)
        .finally(() => {
          this.loading = false;
        })
        .subscribe(
          (vehicule) => {
            this.vehiculeService.sendReloadUnusedDevices();
            this.router.navigate(['/client/dataManagement/vehicule']);
            this.loading = false;
          },
          (error) => {
            this.error = error;
            this.error = JSON.parse(JSON.stringify(error.error || null));

            if (!this.error.errorMessage) {
              this.error.errorMessage = 'SERVER ERROR';
            }

            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }

    if (this.mode === 'UPDATE') {
      this.vehiculeService
        .updateDocument(
          _formData,
          this.selectedGroupsHash,
          this.deleteAvatar,
          this.deletePermisCard,
          this.deleteGrayCard,
          this.deleteContracting,
          this.deleteSecondContracting,
          this.deletethirdContracting
        )
        //this.vehiculeService.update(_formData, this.selectedGroupsHash, this.deleteAvatar, this.deleteGrayCard, this.deleteContracting, this.deleteSecondContracting, this.deletethirdContracting)
        .finally(() => {
          this.loading = false;
        })
        .subscribe(
          (vehicule) => {
            this.router.navigate(['/client/dataManagement/vehicule']);
            this.loading = false;
          },
          (error) => {
            this.error = error;
            this.error = JSON.parse(JSON.stringify(error.error || null));

            if (!this.error.errorMessage) {
              this.error.errorMessage = 'SERVER ERROR';
            }

            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }
  }

  cancel() {
    this.router.navigate(['/client/dataManagement/vehicule']);
  }

  assignDriverToVehicule() {
    this.loading = true;
    this.vehiculeService
      .assignement(
        this.currentVehicule.idVehicule,
        this.currentVehicule.driver.idDriver,
        this.assignmentDate.toISOString()
      )
      .subscribe(
        (response) => {
          this.router.navigate(['/client/dataManagement/vehicule']);
          this.loading = false;
        },
        (error) => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = 'SERVER ERROR';
          }

          this.loading = false;
        }
      );
  }

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
