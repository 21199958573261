import { HttpHeaders } from '@angular/common/http';

export var contentHeaders = new HttpHeaders();
contentHeaders = contentHeaders.append('Accept', 'application/json');
contentHeaders = contentHeaders.append('Content-Type', 'application/json');


export function createAuthorizationHeader(): HttpHeaders {
  const token = localStorage.getItem('id_token') ?? '';
   let headers = new HttpHeaders({
    'Accept': 'application/json',
    'Authorization': token
  });
  return headers;
}

