import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { OverviewComponent } from './overview.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MapService } from '../../utils/leaflet/service/map.service';
import { GeocodingService } from '../../utils/leaflet/service/geocoding.service';
import { MileageService } from '../historical/mileage/mileage.service';
import { OverviewService } from './overview.service';
import { DataManagementService } from '../data-management/data-management.service';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HighchartsChartModule } from 'highcharts-angular';
import { DashbordService } from '../parc-management';
import { AverageCostOfFuelConsumptionChartComponent } from './dashbord-chart/average-cost-of-fuel-consumption-chart/average-cost-of-fuel-consumption-chart.component';
import { MonthlyAvailableVehiculesChartComponent } from './dashbord-chart/monthly-available-vehicules-chart/monthly-available-vehicules-chart.component';
import { AverageCostOfFuelConsumptionChartModule } from './dashbord-chart/average-cost-of-fuel-consumption-chart/average-cost-of-fuel-consumption-chart.module';
import { MonthlyAvailableVehiculesChartModule } from './dashbord-chart/monthly-available-vehicules-chart/monthly-available-vehicules-chart.module';
import { BrowserModule } from '@angular/platform-browser';
import { MonthlyMaintenanceCostsModule } from './dashbord-chart/monthly-maintenance-costs/monthly-maintenance-costs.module';
import { RunningDocumentChartModule } from './dashbord-chart/running-document-chart/running-document-chart.module';
import { CurrentStateModule } from './current-state/current-state.module';
import { DepenseChartModule } from './dashbord-chart/depense-chart/depense-chart.module';
import { SinistreChartModule } from './dashbord-chart/sinistre-chart/sinistre-chart.module';
import { VidangeChartModule } from './dashbord-chart/vidange-chart/vidange-chart.module';

@NgModule({
  declarations: [
    OverviewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    AverageCostOfFuelConsumptionChartModule,
    MonthlyAvailableVehiculesChartModule,
    MonthlyMaintenanceCostsModule,
    RunningDocumentChartModule,
    CurrentStateModule,
    DepenseChartModule,
    SinistreChartModule,
    VidangeChartModule
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    MapService,
    GeocodingService,
    MileageService,
    OverviewService,
    DashbordService,
    DataManagementService,
    ChartConfigService
  ],
  exports: [
    OverviewComponent
  ]
})
export class OverviewModule { }
