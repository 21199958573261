//UserAlert
export class UserAlert {
    name: string;
    active: Boolean;
    alertConfigurations: UserAlertConfiguration[];
    deviceId: number;
    groupId: number;
    byGroups: boolean;
    byAllVehicules: boolean;
    byVehicules: boolean;
    timeToRealert: number;
    type: string;
    emailMode: boolean;
    emails: string;
    enableGlobalConfig: boolean;
    enableGlobalConfigToAllVehicules: boolean;
    ids: number[];
    constructor() {
        this.alertConfigurations = [];
        this.active = true;
        this.emailMode = false;
        this.byAllVehicules = true;
        this.byGroups = false;
        this.byVehicules = false;
        this.ids = [];
    }
}
export class Operator {
    id: number;
    name: string;
}
export class UserAlertConfiguration {
    mesure: Mesure;
    value1: string | number;
    value2: string;
    operator: Operator;
    constructor() {
        this.mesure = new Mesure();
        this.operator = new Operator();

    }
}
export class Mesure {
    id: number;
    name: string;
}
export class UserAlertTemplate {
    id: number;
    active: Boolean;
    name: string;
    timeToRealert: number;
    type: string;
    alertConfigurationsTemplate: AlertConfigurationTemplate[];
    emailMode: boolean;
    emails: string;
    enableGlobalConfig: boolean;
    enableGlobalConfigToAllVehicules: boolean;

    matricule: string;
    constructor() {

    }
}

export class AlertConfigurationTemplate {
    // id: number;
    //active: boolean;
    value1: string | number | null;
    value2: string | null;
    operator: Operator;
    mesure: Mesure;
    constructor() {
        this.value1 = null;
        this.value2 = null;
        this.mesure = new Mesure();
        this.operator = new Operator();
    }

}
export class ListeAlertes {
    id: number;
    name: string;
    namemesure: string;
    nameoperator: string;
    matricule: string;
    emails: string;
    active: Boolean;
    idoperator: number;
    idmesure: number;
    value1: string;
    poiIds: number[];
    zoneIds: number[];
    constructor() {
        this.poiIds = [];
        this.zoneIds = [];
    }
}
