import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { PathComponent } from './path.component';
import { PathService } from './path.service';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet'; // If you're using ngx-leaflet for maps
import { SpiderChartComponent } from '../../statistical/spider-chart/spider-chart.component'; // Ensure this is available
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { SpiderChartModule } from '../../statistical/spider-chart/spider-chart.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { DurationPipeModule } from 'src/app/pipes/duration.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';

@NgModule({
  declarations: [
    PathComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(), // Initialize ngx-bootstrap dropdown
    BsDatepickerModule.forRoot(), // Initialize ngx-bootstrap datepicker
    ModalModule.forRoot(), // Initialize modal component
    ToastrModule.forRoot(), // Initialize Toastr for notifications
    LeafletModule,
    SpiderChartModule,
    TruncateModule,
    DurationPipeModule,
    SharedPipesModule
  ],
  providers: [
    PathService // Provide your Path service
  ],
  exports: [
    PathComponent // Export if you need to use it in other modules
  ]
})
export class PathModule { }
