import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Driver, Group } from 'src/app/client-management/data-management';
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { DriverCharge } from '../../model/index';
import { DriverChargeService } from '../../providers';


@Component({
    selector: "app-driver-charge-list",
    templateUrl: "./driver-charge-list.component.html"
})
export class driverChargeListComponent {

    @Input()
    set driver(driver: Driver) {
        this._driver = driver;
        this.loadDriverCharges();
    }

    @Input()
    set group(group: Group) {
        this._group = group;
    }

    @Input()
    set actionAdd(n: number) {
        if (n > 0) this.onAdd();
    }

    @Input()
    set actionDeleteMultiple(n: number) {
        if (n > 0) this.onConfirmDeleteMultiple();
    }


    @Output()
    wasChecked: EventEmitter<number[]> = new EventEmitter<number[]>();

    _driver: Driver = new Driver();

    _group: Group = new Group();

    mode: 'ADD' | 'UPDATE';

    driverCharge: DriverCharge = new DriverCharge();

    driverCharges: DriverCharge[] = [];

    driverChargesId: number[] = [];

    loading: boolean = false;

    isDeleteMultiple: boolean = false;

    @ViewChild('driverChargeModal', { static: false })
    driverChargeModal: ModalDirective;

    @ViewChild('driverChargeDeleteModal', { static: false })
    driverChargeDeleteModal: ModalDirective;

    totalAmount: number = 0;

    constructor(private driverChargeService: DriverChargeService, public toastr: ToastrService) { }

    /**
     * Charger la liste des charges conducteur
     */
    loadDriverCharges() {
        if (!this._driver || !this._driver.idDriver) {
            this.driverCharges = [];
            return;
        }
        this.loading = true;
        this.driverChargeService.getList(this._driver.idDriver).subscribe(driverCharges => {
            this.driverCharges = driverCharges;
            this.calculateTotalAmount();
            this.loading = false;
        }, error => {
            this.loading = false;
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Afficher modal de modification du charge conducteur
     */
    onUpdate(driverCharge: DriverCharge) {
        if (!driverCharge.driver) {
            driverCharge.driver = new Driver();
        }
        this.driverCharge = Object.assign({}, driverCharge);
        if (this.driverCharge.createAt) this.driverCharge.createAt = new Date(this.driverCharge.createAt);
        this.mode = 'UPDATE';
        this.driverChargeModal.show();
    }

    /**
     * Afficher modal d'ajout du charge conducteur
     */
    onAdd() {
        this.driverCharge = new DriverCharge();
        if (this._driver && this._driver.idDriver) Object.assign(this.driverCharge.driver, this._driver);
        this.mode = 'ADD';
        this.driverChargeModal.show();
    }

    /**
     * Afficher modal de confirmation du suppression
     */
    onConfirmDelete(driverCharge: DriverCharge) {
        this.driverCharge = driverCharge;
        this.isDeleteMultiple = false;
        this.driverChargeDeleteModal.show();
    }

    /**
     * Event confirm delete multiple
     */
    onConfirmDeleteMultiple() {
        if (this.driverChargesId.length == 0) {
            this.toastr.warning("Veuillez choisir des vidanges à supprimer !");
            return;
        }
        this.isDeleteMultiple = true;
        this.driverChargeDeleteModal.show();
    }

    /**
     * Suppression du charge conducteur
     */
    delete() {
        this.loading = true;
        this.driverChargeService.delete(this.driverCharge.id).subscribe(response => {
            this.driverChargeDeleteModal.hide();
            if (response) {
                this.driverCharges = this.driverCharges.filter(driverCharge => driverCharge.id != this.driverCharge.id);
                this.driverChargesId = this.driverChargesId.filter(driverChargeId => driverChargeId != this.driverCharge.id);
                this.wasChecked.emit(this.driverChargesId);
                this.calculateTotalAmount();
                this.toastr.success('Suppression effectuée avec succès.', 'info');
            } else {
                this.toastr.error('Le charge conducteur n\'a pas supprimé correctement !', 'Erreur');
            }
            this.loading = false;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }


    /**
     * Suppression multiple du vidange
     */
    deleteMultiple() {
        this.loading = true;
        this.driverChargeService.deleteMultiple(this.driverChargesId).subscribe(response => {

            if (response) {
                for (let driverChargeId of this.driverChargesId) {
                    this.driverCharges = this.driverCharges.filter(driverCharge => driverCharge.id != driverChargeId);
                }

                this.driverChargesId = [];
                this.wasChecked.emit(this.driverChargesId);
                this.toastr.success('Suppression effectuée avec succès.', 'info');
            } else {
                this.toastr.error('La vidange n\'a pas supprimé correctement !', 'Erreur');
            }

            this.driverChargeDeleteModal.hide();
            this.loading = false;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * Actualiser la liste des charges conducteur apés l'événement d'ajout / modif
     */
    driverChargeWasAddedOrUpdated(driverCharge: DriverCharge) {
        this.driverChargeModal.hide();
        if (this._driver && this._driver.idDriver) {
            this.loadDriverCharges();
        } else {
            this.driverCharges = [];
        }
    }

    /**
     * Calculer le montant total
     */
    calculateTotalAmount() {
        this.totalAmount = 0;
        for (let driverCharge of this.driverCharges) {
            this.totalAmount += driverCharge.total;
        }
    }

    /**
     * Event delete
     */
    onDelete() {
        if (this.isDeleteMultiple) {
            this.deleteMultiple();
        } else {
            this.delete();
        }
    }


    /**
     * Event check row for delete multiple
     */
    onChecked(id: number) {
        if (this.driverChargesId.find(driverChargeId => driverChargeId == id)) {
            this.driverChargesId = this.driverChargesId.filter(driverChargeId => driverChargeId != id);
        } else {
            this.driverChargesId.push(id);
        }
        this.wasChecked.emit(this.driverChargesId);
    }

    /**
     * Event check all row for delete multiple
     */
    onCheckedAll() {
        if (this.driverChargesId.length == this.driverCharges.length) {
            this.driverChargesId = [];
        } else {
            this.driverChargesId = [];
            for (let driverCharge of this.driverCharges) {
                this.driverChargesId.push(driverCharge.id);
            }
        }
        this.wasChecked.emit(this.driverChargesId);
    }

    /**
     * check if id exists in array,
     */
    isCheckedInList(id: number): boolean {
        return this.driverChargesId.find(driverChargeId => driverChargeId == id) ? true : false;
    }

}
