import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Include if using forms or ngModel
import { ToastrModule } from 'ngx-toastr'; // Include ToastrModule if using ToastrService
import { PoiSettingsComponent } from './poi-settings.component'; // Adjust the path as necessary
//import { SelectModule } from 'ng2-select';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    PoiSettingsComponent,

  ],
  imports: [
    CommonModule,
    FormsModule, // Include if using forms or ngModel
    ToastrModule.forRoot(),
    NgSelectModule,
    EmailManagerModule,
    CronSettingsModule,
    MatSlideToggleModule
    // Include ToastrModule if using ToastrService
  ],
  exports: [
    PoiSettingsComponent // Export PoiSettingsComponent if needed in other modules
  ]
})
export class PoiSettingsModule { }
