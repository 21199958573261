import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if you are using ngModel
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'; // Import necessary ngx-bootstrap modules
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { AlertNewComponent } from './alert-new.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AlertCustomizeModule } from '../alert-customize/alert-customize.module';

@NgModule({
  declarations: [
    AlertNewComponent // Declare the component
  ],
  imports: [
    CommonModule,
    FormsModule, // Include FormsModule for ngModel support
    BsDropdownModule.forRoot(), // Include necessary Bootstrap modules
    ModalModule.forRoot(),
    ToastrModule.forRoot(), // Include Toastr module if you're using ToastrService
    Ng2SmartTableModule,
    AlertCustomizeModule
  ],
  exports: [
    AlertNewComponent // Optionally export the component if it will be used in other modules
  ]
})
export class AlertNewModule { }
