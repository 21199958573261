import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FuelingChargeComponent } from './fueling-charge.component';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    FuelingChargeComponent  // Declare the component here
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(),  // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
  ],
  providers: [
    BsLocaleService  // Provide the locale service for date pickers
  ],
  exports: [
    FuelingChargeComponent  // Export the component for use in other modules
  ]
})
export class FuelingChargeModule { }
