<div class="row" style="font-family: 'Inconsolata', monospace;">


    <section class="content col-md-12 groups-container">
        <div class="row">
            <app-current-state (showDashboard)="showDashboard($event)"></app-current-state>
        </div>

        <div class="row">

            <div class="col-md-6">
                <div>
                    <div class="box box-info collapsed-box">
                        <div class="box-header with-border">
                            <app-depense-chart [groups]="groups"></app-depense-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div>
                    <div class="box box-info collapsed-box">
                        <div class="box-header with-border">
                            <app-sinistre-chart [groups]="groups"></app-sinistre-chart>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <div>
                    <div class="box box-info collapsed-box">
                        <div class="box-header with-border">
                            <app-vidange-chart [groups]="groups"></app-vidange-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div>
                    <div class="box box-info collapsed-box">
                        <div class="box-header with-border">
                            <app-running-document-chart [groups]="groups"></app-running-document-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div>
                    <div class="box box-info collapsed-box">
                        <div class="box-header with-border">
                            <app-monthly-maintenance-costs [groups]="groups"></app-monthly-maintenance-costs>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div>
                    <div class="box box-info collapsed-box">
                        <div class="box-header with-border">
                            <app-average-cost-of-fuel-consumption-chart [groups]="groups">
                            </app-average-cost-of-fuel-consumption-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div>
                    <div class="box box-info collapsed-box">
                        <div class="box-header with-border">
                            <app-monthly-available-vehicules-chart [groups]="groups">
                            </app-monthly-available-vehicules-chart>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-md-6">
                <div>
                    <div class="box box-info collapsed-box">
                        <div class="box-header with-border">
                            <app-monthly-average-cost-of-fuel-consumption-chart></app-monthly-average-cost-of-fuel-consumption-chart>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

    </section>

</div>

<!-- ========================================== ADD/Edit Modal (START) ====================================================-->
<div class="modal fade " bsModal #dashboardModal="bs-modal" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-4">
                    <select class="form-control input-sm select3" [(ngModel)]='selectedMonth'
                        (ngModelChange)="onMonthChange()" name='selectedMonth' required>
                        <option [ngValue]="null" style="font-weight: bold;">Choisir le mois</option>
                        <option style="font-size: 14px;" *ngFor="let month of months" [value]="month.id">
                            {{month.name}}</option>
                    </select>
                </div>
                <div class="col-md-8 pull-right">
                    <span *ngIf="loader" style="color:#3c8dbc">
                        <i *ngIf="loader" class="fa fa-refresh fa-spin"></i> Chargement...</span>
                    <span *ngIf="!loader && error" style="color: red;">Error Lors de Chargement!</span>
                </div>
            </div>
            <!-- ========================== Modal TITLE (START) ===============================-->
            <div class="modal-header">

                <button type="button" class="close" (click)="dashboardModal.hide()">&times;</button>

                <h4 class="modal-title" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: #3c8dbc;">
                    <i class="fa fa-pie-chart"></i>
                    Dépenses du mois : {{now | date:'MMMM'}}
                    <span *ngIf="dashbordInfo" style="color: red; font-weight: bold;font-size: 14px;">TOTAL:
                        {{dashbordInfo?.total | number:'.2-3'}} DH</span>
                    <span *ngIf="dashbordInfo"
                        style="color: red; font-weight: bold;font-size: 14px; margin-left:10px">CA:
                        {{dashbordInfo?.turnover | number:'.2-3'}} DH</span>
                    <span *ngIf="dashbordInfo"
                        style="color: red; font-weight: bold;font-size: 14px; margin-left:10px">Résultat: {{
                        (dashbordInfo?.turnover - dashbordInfo?.total) | number:'.2-3'}} DH</span>

                </h4>
            </div>
            <!-- ========================== Modal TITLE (END) ===============================-->

            <div class="modal-body">
                <div style="text-align: center">
                    <div id="dashbordpie">
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- ========================================== ADD/Edit Modal (END) ====================================================-->
