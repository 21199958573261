<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<!-- DATA MANAGEMENT -->
<div class="row">
    <div class="col-md-12" style="margin-top: -10px;">
        <div class="box" style="border-radius: 0px;">
            <div class="box-body">
                <form class="form-horizontal" #trackingForm="ngForm">
                    <div class="form-group row">

                        <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                        <div class="col-sm-2 grp-slct">
                            <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup"
                                (ngModelChange)="chooseGroup($event, 1)" required>
                                <option *ngFor="let group of groups" [ngValue]="group">
                                    <span *ngIf="group.nameGroupParent">{{group?.nameGroupParent | capitalizefirst}} /
                                    </span>{{group?.nom | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="vehicules" [(ngModel)]="selectedVehicule"
                                (ngModelChange)="chooseVehicule($event, 1)" required>
                                <option *ngFor="let vehicule of selectedGroup.vehicules" [ngValue]="vehicule">
                                    {{dataManagementService?.getVehiculeName(vehicule) | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <label class="col-sm-1 control-label vhcl-lbl">Mission</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="missions" [(ngModel)]="selectedMission"
                                (ngModelChange)="chooseMission(1)" required>
                                <option *ngFor="let mission of missions" [ngValue]="mission">
                                    {{mission?.label | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <div class="col-sm-1 form-btns">
                            <button type="button" class="btn btn-primary btn-flat btn-sm"
                                [disabled]="loadingTracking || !trackingForm.form.valid" (click)="onTracking(1)">
                                <b><i class="fa fa-play" aria-hidden="true"></i></b>
                            </button>
                        </div>

                        <div *ngIf="loadingTracking" style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-spinner fa-spin" *ngIf="loadingTracking"></i> Chargement ...
                        </div>
                        <div *ngIf="updateTrackingTimer && !loadingTracking && trackingMission != null && trackingMission?.progressPercent < 100"
                            style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
                            Mise à jour dans {{updateTrackingTimer}} seconds...
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- END DATA MANAGEMENT -->

<!-- TRACKING STATUS -->
<div *ngIf="selectedMission" class="row tracking-status">
    <div class="col-md-12">
        <div class="stack-status">
            <div class="tracking-status-timeline"
                [ngStyle]="{'width': ((selectedMission?.routeLegs?.length) * 25) + '%'}">
                <ng-container *ngFor="let route of trackingMission?.mission?.routeLegs; let i = index">
                    <div *ngIf="route?.selected" class="tracking-status-timeline-completion"
                        [ngStyle]="{'left': 0 + '%','width': ((  25 * trackingMission?.progressPercent) / 100 + (i * 25)) + '%'}"
                        matTooltip="{{trackingMission?.progressPercent | number:'.0-2'}}%">
                        <div class="selected">
                            <img *ngIf="selectedVehicule.icon" class=" tooltip-350" [popover]="trackingPopOver"
                                [placement]="i+1==selectedMission?.routeLegs?.length && selectedMission?.routeLegs?.length>2 ? 'left': 'right'"
                                container="body" #pop="bs-popover" src="{{imagesCarsDir + selectedVehicule.icon}}"
                                alt="img" [outsideClick]="true" [adaptivePosition]="false" />
                            <img *ngIf="!selectedVehicule.icon" class=" tooltip-350" [popover]="trackingPopOver"
                                [placement]="i+1==selectedMission?.routeLegs?.length && selectedMission?.routeLegs?.length>2 ? 'left': 'right'"
                                container="body" #pop2="bs-popover" src="{{imagesCarsDir + 'automobile_green.png'}}"
                                alt="img" [outsideClick]="true" [adaptivePosition]="false" />
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngFor="let route of selectedMission?.routeLegs; let i = index; let j = index">
                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': (i * 25) + '%'}" matTooltip="{{route?.labelPOISource}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission?.mission?.routeLegs[i]?.visited && trackingMission?.last}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i > 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>
                <span *ngIf="i == 0" [ngStyle]="{'left': (i + 1 * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
                <span *ngIf="i > 0" [ngStyle]="{'left': ( (i+i + 1) * 12.5) + '%'}" class="klm">{{route?.travelDistance
                    | number:'.0-2'}} Km</span>
            </ng-container>
        </div>
    </div>
</div>
<!-- END TRACKING STATUS -->

<!-- DATA MANAGEMENT -->
<div class="row">
    <div class="col-md-12" style="margin-top: -10px;">
        <div class="box" style="border-radius: 0px;border-top: 3px solid #000000;">
            <div class="box-body">
                <form class="form-horizontal" #trackingForm2="ngForm">
                    <div class="form-group row">
                        <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                        <div class="col-sm-2 grp-slct">
                            <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup2"
                                (ngModelChange)="chooseGroup($event, 2)" required>
                                <option *ngFor="let group of groups" [ngValue]="group">
                                    <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst |
                                        truncate:[30]}} / </span>{{group?.nom | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="vehicules" [(ngModel)]="selectedVehicule2"
                                (ngModelChange)="chooseVehicule($event, 2)" required>
                                <option *ngFor="let vehicule of selectedGroup2.vehicules" [ngValue]="vehicule">
                                    {{dataManagementService?.getVehiculeName(vehicule) | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <label class="col-sm-1 control-label vhcl-lbl">Mission</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="missions" [(ngModel)]="selectedMission2"
                                (ngModelChange)="chooseMission(2)" required>
                                <option *ngFor="let mission of missions" [ngValue]="mission">
                                    {{mission?.label | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <div class="col-sm-1 form-btns">
                            <button type="button" class="btn btn-primary btn-flat btn-sm"
                                [disabled]="loadingTracking2 || !trackingForm2.form.valid" (click)="onTracking2(2)">
                                <b><i class="fa fa-play" aria-hidden="true"></i></b>
                            </button>
                        </div>

                        <div *ngIf="loadingTracking2" style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-spinner fa-spin" *ngIf="loadingTracking2"></i> Chargement ...
                        </div>
                        <div *ngIf="updateTrackingTimer && !loadingTracking2 && trackingMission2 != null && trackingMission2?.progressPercent < 100"
                            style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
                            Mise à jour dans {{updateTrackingTimer}} seconds...
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- END DATA MANAGEMENT -->

<!-- TRACKING STATUS -->
<div *ngIf="selectedMission2" class="row tracking-status">
    <div class="col-md-12">
        <div class="stack-status">

            <div class="tracking-status-timeline"
                [ngStyle]="{'width': ((selectedMission2?.routeLegs?.length) * 25) + '%'}">
                <ng-container *ngFor="let route of trackingMission2?.mission?.routeLegs; let i = index">
                    <div *ngIf="route?.selected" class="tracking-status-timeline-completion"
                        [ngStyle]="{'left': 0 + '%','width': ((  25 * trackingMission2?.progressPercent) / 100 + (i * 25)) + '%'}"
                        matTooltip="{{trackingMission2?.progressPercent | number:'.0-2'}}%">
                        <div class="selected">
                            <img *ngIf="selectedVehicule2.icon" class=" tooltip-350" [popover]="trackingPopOver2"
                                [placement]="i+1==selectedMission2?.routeLegs?.length  && selectedMission2?.routeLegs?.length>2 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop3="bs-popover"
                                src="{{imagesCarsDir + selectedVehicule2.icon}}" alt="img" [outsideClick]="true" />
                            <img *ngIf="!selectedVehicule2.icon" class=" tooltip-350" [popover]="trackingPopOver2"
                                [placement]="i+1==selectedMission2?.routeLegs?.length  && selectedMission2?.routeLegs?.length>2 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop4="bs-popover"
                                src="{{imagesCarsDir + 'automobile_green.png'}}" alt="img" [outsideClick]="true" />
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngFor="let route of selectedMission2?.routeLegs; let i = index; let j = index ">

                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission2?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': (i * 25) + '%'}" matTooltip="{{route?.labelPOISource}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission2?.mission?.routeLegs[i]?.visited && trackingMission2?.last}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i > 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission2?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>


                <span *ngIf="i == 0" [ngStyle]="{'left': (i + 1 * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
                <span *ngIf="i > 0" [ngStyle]="{'left': ((i+i + 1) * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
            </ng-container>

        </div>
    </div>
</div>
<!-- END TRACKING STATUS -->



<!-- DATA MANAGEMENT -->
<div class="row">
    <div class="col-md-12" style="margin-top: -10px;">
        <div class="box" style="border-radius: 0px;border-top: 3px solid #000000;">
            <div class="box-body">
                <form class="form-horizontal" #trackingForm3="ngForm">
                    <div class="form-group row">

                        <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                        <div class="col-sm-2 grp-slct">
                            <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup3"
                                (ngModelChange)="chooseGroup($event, 3)" required>
                                <option *ngFor="let group of groups" [ngValue]="group">
                                    <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst |
                                        truncate:[30]}} / </span>{{group?.nom | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="vehicules" [(ngModel)]="selectedVehicule3"
                                (ngModelChange)="chooseVehicule($event, 3)" required>
                                <option *ngFor="let vehicule of selectedGroup3.vehicules" [ngValue]="vehicule">
                                    {{dataManagementService?.getVehiculeName(vehicule) | capitalizefirst}}
                                </option>
                            </select>
                        </div>


                        <label class="col-sm-1 control-label vhcl-lbl">Mission</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="missions" [(ngModel)]="selectedMission3"
                                (ngModelChange)="chooseMission(3)" required>
                                <option *ngFor="let mission of missions" [ngValue]="mission">
                                    {{mission?.label | capitalizefirst}}
                                </option>
                            </select>
                        </div>


                        <div class="col-sm-1 form-btns">
                            <button type="button" class="btn btn-primary btn-flat btn-sm"
                                [disabled]="loadingTracking3 || !trackingForm3.form.valid" (click)="onTracking3(3)">
                                <b><i class="fa fa-play" aria-hidden="true"></i></b>
                            </button>
                        </div>

                        <div *ngIf="loadingTracking3" style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-spinner fa-spin" *ngIf="loadingTracking3"></i> Chargement ...
                        </div>
                        <div *ngIf="updateTrackingTimer && !loadingTracking3 && trackingMission3 != null && trackingMission3?.progressPercent < 100"
                            style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
                            Mise à jour dans {{updateTrackingTimer}} seconds...
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- END DATA MANAGEMENT -->


<!-- TRACKING STATUS -->
<div *ngIf="selectedMission3" class="row tracking-status">
    <div class="col-md-12">
        <div class="stack-status">

            <div class="tracking-status-timeline"
                [ngStyle]="{'width': ((selectedMission3?.routeLegs?.length) * 25) + '%'}">
                <ng-container *ngFor="let route of trackingMission3?.mission?.routeLegs; let i = index">
                    <div *ngIf="route?.selected" class="tracking-status-timeline-completion"
                        [ngStyle]="{'left': 0 + '%','width': ((  25 * trackingMission3?.progressPercent) / 100 + (i * 25)) + '%'}"
                        matTooltip="{{trackingMission3?.progressPercent | number:'.0-2'}}%">

                        <div class="selected">
                            <img *ngIf="selectedVehicule3.icon" [popover]="trackingPopOver3" class="tooltip-350"
                                [placement]="i+1==selectedMission3?.routeLegs?.length  && selectedMission3?.routeLegs?.length>3 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop3="bs-popover"
                                src="{{imagesCarsDir + selectedVehicule3.icon}}" alt="img" [outsideClick]="true" />
                            <img *ngIf="!selectedVehicule3.icon" [popover]="trackingPopOver3" class="tooltip-350"
                                [placement]="i+1==selectedMission3?.routeLegs?.length  && selectedMission3?.routeLegs?.length>3 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop4="bs-popover"
                                src="{{imagesCarsDir + 'automobile_green.png'}}" alt="img" [outsideClick]="true" />
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngFor="let route of selectedMission3?.routeLegs; let i = index">

                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission3?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': (i * 25) + '%'}" matTooltip="{{route?.labelPOISource}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission3?.mission?.routeLegs[i]?.visited && trackingMission3?.last}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i > 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission3?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>

                <span *ngIf="i == 0" [ngStyle]="{'left': (i + 1 * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
                <span *ngIf="i > 0" [ngStyle]="{'left': ((i+i + 1) * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
            </ng-container>

        </div>
    </div>
</div>
<!-- END TRACKING STATUS -->



<!-- DATA MANAGEMENT -->
<div class="row">
    <div class="col-md-12" style="margin-top: -10px;">
        <div class="box" style="border-radius: 0px;border-top: 3px solid #000000;">
            <div class="box-body">
                <form class="form-horizontal" #trackingForm4="ngForm">
                    <div class="form-group row">

                        <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                        <div class="col-sm-2 grp-slct">
                            <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup4"
                                (ngModelChange)="chooseGroup($event, 4)" required>
                                <option *ngFor="let group of groups" [ngValue]="group">
                                    <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst |
                                        truncate:[30]}} / </span>{{group?.nom | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="vehicules" [(ngModel)]="selectedVehicule4"
                                (ngModelChange)="chooseVehicule($event, 4)" required>
                                <option *ngFor="let vehicule of selectedGroup4.vehicules" [ngValue]="vehicule">
                                    {{dataManagementService?.getVehiculeName(vehicule) | capitalizefirst}}
                                </option>
                            </select>
                        </div>


                        <label class="col-sm-1 control-label vhcl-lbl">Mission</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="missions" [(ngModel)]="selectedMission4"
                                (ngModelChange)="chooseMission(4)" required>
                                <option *ngFor="let mission of missions" [ngValue]="mission">
                                    {{mission?.label | capitalizefirst}}
                                </option>
                            </select>
                        </div>


                        <div class="col-sm-1 form-btns">
                            <button type="button" class="btn btn-primary btn-flat btn-sm"
                                [disabled]="loadingTracking4 || !trackingForm4.form.valid" (click)="onTracking4(4)">
                                <b><i class="fa fa-play" aria-hidden="true"></i></b>
                            </button>
                        </div>

                        <div *ngIf="loadingTracking4" style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-spinner fa-spin" *ngIf="loadingTracking4"></i> Chargement ...
                        </div>
                        <div *ngIf="updateTrackingTimer && !loadingTracking4 && trackingMission4 != null && trackingMission4?.progressPercent < 100"
                            style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
                            Mise à jour dans {{updateTrackingTimer}} seconds...
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- END DATA MANAGEMENT -->


<!-- TRACKING STATUS -->
<div *ngIf="selectedMission4" class="row tracking-status">
    <div class="col-md-12">
        <div class="stack-status">

            <div class="tracking-status-timeline"
                [ngStyle]="{'width': ((selectedMission4?.routeLegs?.length) * 25) + '%'}">
                <ng-container *ngFor="let route of trackingMission4?.mission?.routeLegs; let i = index">
                    <div *ngIf="route?.selected" class="tracking-status-timeline-completion"
                        [ngStyle]="{'left': 0 + '%','width': ((  25 * trackingMission4?.progressPercent) / 100 + (i * 25)) + '%'}"
                        matTooltip="{{trackingMission4?.progressPercent | number:'.0-2'}}%">

                        <div class="selected">
                            <img *ngIf="selectedVehicule4.icon" [popover]="trackingPopOver4" class="tooltip-350"
                                [placement]="i+1==selectedMission4?.routeLegs?.length  && selectedMission4?.routeLegs?.length>3 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop3="bs-popover"
                                src="{{imagesCarsDir + selectedVehicule4.icon}}" alt="img" [outsideClick]="true" />
                            <img *ngIf="!selectedVehicule4.icon" [popover]="trackingPopOver4" class="tooltip-350"
                                [placement]="i+1==selectedMission4?.routeLegs?.length  && selectedMission4?.routeLegs?.length>3 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop4="bs-popover"
                                src="{{imagesCarsDir + 'automobile_green.png'}}" alt="img" [outsideClick]="true" />
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngFor="let route of selectedMission4?.routeLegs; let i = index">

                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission4?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': (i * 25) + '%'}" matTooltip="{{route?.labelPOISource}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission4?.mission?.routeLegs[i]?.visited && trackingMission4?.last}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i > 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission4?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>

                <span *ngIf="i == 0" [ngStyle]="{'left': (i + 1 * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
                <span *ngIf="i > 0" [ngStyle]="{'left': ((i+i + 1) * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
            </ng-container>

        </div>
    </div>
</div>
<!-- END TRACKING STATUS -->


<!-- DATA MANAGEMENT -->
<div class="row">
    <div class="col-md-12" style="margin-top: -10px;">
        <div class="box" style="border-radius: 0px;border-top: 3px solid #000000;">
            <div class="box-body">
                <form class="form-horizontal" #trackingForm5="ngForm">
                    <div class="form-group row">

                        <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                        <div class="col-sm-2 grp-slct">
                            <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup5"
                                (ngModelChange)="chooseGroup($event, 5)" required>
                                <option *ngFor="let group of groups" [ngValue]="group">
                                    <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst |
                                        truncate:[30]}} / </span>{{group?.nom | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="vehicules" [(ngModel)]="selectedVehicule5"
                                (ngModelChange)="chooseVehicule($event, 5)" required>
                                <option *ngFor="let vehicule of selectedGroup5.vehicules" [ngValue]="vehicule">
                                    {{dataManagementService?.getVehiculeName(vehicule) | capitalizefirst}}
                                </option>
                            </select>
                        </div>


                        <label class="col-sm-1 control-label vhcl-lbl">Mission</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="missions" [(ngModel)]="selectedMission5"
                                (ngModelChange)="chooseMission(5)" required>
                                <option *ngFor="let mission of missions" [ngValue]="mission">
                                    {{mission?.label | capitalizefirst}}
                                </option>
                            </select>
                        </div>


                        <div class="col-sm-1 form-btns">
                            <button type="button" class="btn btn-primary btn-flat btn-sm"
                                [disabled]="loadingTracking5 || !trackingForm5.form.valid" (click)="onTracking5(5)">
                                <b><i class="fa fa-play" aria-hidden="true"></i></b>
                            </button>
                        </div>

                        <div *ngIf="loadingTracking5" style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-spinner fa-spin" *ngIf="loadingTracking5"></i> Chargement ...
                        </div>
                        <div *ngIf="updateTrackingTimer && !loadingTracking5 && trackingMission5 != null && trackingMission5?.progressPercent < 100"
                            style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
                            Mise à jour dans {{updateTrackingTimer}} seconds...
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- END DATA MANAGEMENT -->


<!-- TRACKING STATUS -->
<div *ngIf="selectedMission5" class="row tracking-status">
    <div class="col-md-12">
        <div class="stack-status">

            <div class="tracking-status-timeline"
                [ngStyle]="{'width': ((selectedMission5?.routeLegs?.length) * 25) + '%'}">
                <ng-container *ngFor="let route of trackingMission5?.mission?.routeLegs; let i = index">
                    <div *ngIf="route?.selected" class="tracking-status-timeline-completion"
                        [ngStyle]="{'left': 0 + '%','width': ((  25 * trackingMission5?.progressPercent) / 100 + (i * 25)) + '%'}"
                        matTooltip="{{trackingMission5?.progressPercent | number:'.0-2'}}%">

                        <div class="selected">
                            <img *ngIf="selectedVehicule5.icon" [popover]="trackingPopOver5" class="tooltip-350"
                                [placement]="i+1==selectedMission5?.routeLegs?.length  && selectedMission5?.routeLegs?.length>3 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop3="bs-popover"
                                src="{{imagesCarsDir + selectedVehicule5.icon}}" alt="img" [outsideClick]="true" />
                            <img *ngIf="!selectedVehicule5.icon" [popover]="trackingPopOver5" class="tooltip-350"
                                [placement]="i+1==selectedMission5?.routeLegs?.length  && selectedMission5?.routeLegs?.length>3 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop4="bs-popover"
                                src="{{imagesCarsDir + 'automobile_green.png'}}" alt="img" [outsideClick]="true" />
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngFor="let route of selectedMission5?.routeLegs; let i = index">

                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission5?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': (i * 25) + '%'}" matTooltip="{{route?.labelPOISource}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission5?.mission?.routeLegs[i]?.visited && trackingMission5?.last}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i > 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission5?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>

                <span *ngIf="i == 0" [ngStyle]="{'left': (i + 1 * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
                <span *ngIf="i > 0" [ngStyle]="{'left': ((i+i + 1) * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
            </ng-container>

        </div>
    </div>
</div>
<!-- END TRACKING STATUS -->



<!-- DATA MANAGEMENT -->
<div class="row">
    <div class="col-md-12" style="margin-top: -10px;">
        <div class="box" style="border-radius: 0px;border-top: 3px solid #000000;">
            <div class="box-body">
                <form class="form-horizontal" #trackingForm6="ngForm">
                    <div class="form-group row">

                        <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                        <div class="col-sm-2 grp-slct">
                            <select class="form-control input-sm" name="groups" [ngModel]="selectedGroup6"
                                (ngModelChange)="chooseGroup($event, 6)" required>
                                <option *ngFor="let group of groups" [ngValue]="group">
                                    <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst |
                                        truncate:[30]}} / </span>{{group?.nom | capitalizefirst}}
                                </option>
                            </select>
                        </div>

                        <label class="col-sm-1 control-label vhcl-lbl">Véhicule</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="vehicules" [(ngModel)]="selectedVehicule6"
                                (ngModelChange)="chooseVehicule($event, 6)" required>
                                <option *ngFor="let vehicule of selectedGroup6.vehicules" [ngValue]="vehicule">
                                    {{dataManagementService?.getVehiculeName(vehicule) | capitalizefirst}}
                                </option>
                            </select>
                        </div>


                        <label class="col-sm-1 control-label vhcl-lbl">Mission</label>
                        <div class="col-sm-2 vhcl-slct">
                            <select class="form-control input-sm" name="missions" [(ngModel)]="selectedMission6"
                                (ngModelChange)="chooseMission(6)" required>
                                <option *ngFor="let mission of missions" [ngValue]="mission">
                                    {{mission?.label | capitalizefirst}}
                                </option>
                            </select>
                        </div>


                        <div class="col-sm-1 form-btns">
                            <button type="button" class="btn btn-primary btn-flat btn-sm"
                                [disabled]="loadingTracking6 || !trackingForm6.form.valid" (click)="onTracking6(6)">
                                <b><i class="fa fa-play" aria-hidden="true"></i></b>
                            </button>
                        </div>

                        <div *ngIf="loadingTracking6" style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-spinner fa-spin" *ngIf="loadingTracking6"></i> Chargement ...
                        </div>
                        <div *ngIf="updateTrackingTimer && !loadingTracking6 && trackingMission6 != null && trackingMission6?.progressPercent < 100"
                            style="font-size: 12px;margin-top: 8px;">
                            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
                            Mise à jour dans {{updateTrackingTimer}} seconds...
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- END DATA MANAGEMENT -->


<!-- TRACKING STATUS -->
<div *ngIf="selectedMission6" class="row tracking-status">
    <div class="col-md-12">
        <div class="stack-status">

            <div class="tracking-status-timeline"
                [ngStyle]="{'width': ((selectedMission6?.routeLegs?.length) * 25) + '%'}">
                <ng-container *ngFor="let route of trackingMission6?.mission?.routeLegs; let i = index">
                    <div *ngIf="route?.selected" class="tracking-status-timeline-completion"
                        [ngStyle]="{'left': 0 + '%','width': ((  25 * trackingMission6?.progressPercent) / 100 + (i * 25)) + '%'}"
                        matTooltip="{{trackingMission6?.progressPercent | number:'.0-2'}}%">

                        <div class="selected">
                            <img *ngIf="selectedVehicule6.icon" [popover]="trackingPopOver6" class="tooltip-350"
                                [placement]="i+1==selectedMission6?.routeLegs?.length  && selectedMission6?.routeLegs?.length>3 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop3="bs-popover"
                                src="{{imagesCarsDir + selectedVehicule6.icon}}" alt="img" [outsideClick]="true" />
                            <img *ngIf="!selectedVehicule6.icon" [popover]="trackingPopOver6" class="tooltip-350"
                                [placement]="i+1==selectedMission6?.routeLegs?.length  && selectedMission6?.routeLegs?.length>3 ? 'left': 'right'"
                                [adaptivePosition]="false" container="body" #pop4="bs-popover"
                                src="{{imagesCarsDir + 'automobile_green.png'}}" alt="img" [outsideClick]="true" />
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngFor="let route of selectedMission6?.routeLegs; let i = index">

                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission6?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': (i * 25) + '%'}" matTooltip="{{route?.labelPOISource}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission6?.mission?.routeLegs[i]?.visited && trackingMission6?.last}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>
            <div *ngIf="i > 0" class="image-tracking-status image-tracking-status img-circle"
                [ngClass]="{'active': trackingMission6?.mission?.routeLegs[i]?.visited}"
                [ngStyle]="{'left': ((i + 1) * 25) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                <div class="icon"></div>
            </div>

                <span *ngIf="i == 0" [ngStyle]="{'left': (i + 1 * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
                <span *ngIf="i > 0" [ngStyle]="{'left': ((i+i + 1) * 12.5) + '%'}" class="klm">{{route?.travelDistance |
                    number:'.0-2'}} Km</span>
            </ng-container>

        </div>
    </div>
</div>
<!-- END TRACKING STATUS -->


<!-- START TRACKING INFO -->
<ng-template #trackingPopOver>
    <fieldset class="without-border">

        <div>
            <h4>Info mission</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Poi de départ :
                        <b *ngIf="selectedMission">{{selectedMission?.routeLegs[0]?.labelPOISource}} </b>
                        <b *ngIf="!selectedMission">-</b>
                    </span></li>
                <li> <span>Poi d'arrivé :
                        <b *ngIf="selectedMission">
                            {{selectedMission?.routeLegs[selectedMission?.routeLegs?.length - 1]?.labelPOITarget}} </b>
                        <b *ngIf="!selectedMission">-</b>
                    </span></li>
                <li> <span>Kilométrage :
                        <b *ngIf="selectedMission">{{selectedMission?.travelDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!selectedMission">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedMission">{{selectedMission?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedMission">-</b>
                    </span></li>

                <li> <span>Distance restante :
                        <b *ngIf="trackingMission">{{selectedMission?.travelDistance -
                            (trackingAccordingDistance+trackingMission?.accordingDistance) | positiveNumber |
                            number:'.0-2'}}
                            Km</b>
                        <b *ngIf="!trackingMission">-</b>
                    </span></li>
            </ul>
        </div>
    </fieldset>
    <fieldset class="without-border">
        <div>
            <h4>Info trajet en cours</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Vitesse :
                        <b *ngIf="trackingMission">{{trackingMission?.realTimeRecord?.speed}} Km/H</b>
                        <b *ngIf="!trackingMission">-</b>
                    </span></li>
                <li> <span>Position actuelle :
                        <b *ngIf="trackingMission" matTooltip="{{trackingMission?.realTimeRecord?.geocoding}}">
                            {{trackingMission?.realTimeRecord?.geocoding | truncate :[60]}}</b>
                        <b *ngIf="!trackingMission">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedRouteLeg">{{selectedRouteLeg?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedRouteLeg">-</b>
                    </span></li>
                <!-- <li> <span>A proximité P.O.I :
                        <b *ngIf="selectedRouteLeg && trackingMission">{{selectedRouteLeg?.travelDistance -
                            trackingMission?.accordingDistance | positiveNumber | number:'.0-2'}} Km de
                            {{selectedRouteLeg?.firstNearest ? selectedRouteLeg?.labelPOISource :
                            selectedRouteLeg?.labelPOITarget}}</b>
                        <b *ngIf="!selectedRouteLeg || !trackingMission">-</b>
                    </span></li> -->
                
                    <li> <span>A proximité de P.O.I :
                        <b *ngIf="selectedRouteLeg && trackingMission">
                            {{trackingMission?.firstNearest ? selectedRouteLeg?.labelPOISource :
                                selectedRouteLeg?.labelPOITarget}}
                            de {{trackingMission?.minDistance | number:'.0-2'}} Km
                        </b>
                        <b *ngIf="!selectedRouteLeg || !trackingMission">-</b>
                    </span></li>

                <li> <span>Distance parcourue :
                        <b *ngIf="trackingMission">{{trackingMission?.accordingDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!trackingMission">-</b>
                    </span></li>

                <li><span> Durée restante :
                        <b *ngIf="trackingMission">{{trackingMission?.accordingDuration | timediff}}</b>
                        <b *ngIf="!trackingMission">-</b>
                    </span></li>

            </ul>
        </div>
    </fieldset>
</ng-template>

<!-- END TRACKING INFO -->



<!-- START TRACKING INFO -->
<ng-template #trackingPopOver2>
    <fieldset class="without-border">

        <div>
            <h4>Info mission</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Poi de départ :
                        <b *ngIf="selectedMission2">{{selectedMission2?.routeLegs[0]?.labelPOISource}} </b>
                        <b *ngIf="!selectedMission2">-</b>
                    </span></li>
                <li> <span>Poi d'arrivé :
                        <b *ngIf="selectedMission2">
                            {{selectedMission2?.routeLegs[selectedMission2?.routeLegs?.length - 1]?.labelPOITarget}}
                        </b>
                        <b *ngIf="!selectedMission2">-</b>
                    </span></li>
                <li> <span>Kilométrage :
                        <b *ngIf="selectedMission2">{{selectedMission2?.travelDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!selectedMission2">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedMission2">{{selectedMission2?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedMission2">-</b>
                    </span></li>

                <li> <span>Distance restante :
                        <b *ngIf="trackingMission2">{{selectedMission2?.travelDistance -
                            (trackingAccordingDistance+trackingMission2?.accordingDistance) | positiveNumber |
                            number:'.0-2'}}
                            Km</b>
                        <b *ngIf="!trackingMission2">-</b>
                    </span></li>
            </ul>
        </div>
    </fieldset>
    <fieldset class="without-border">
        <div>
            <h4>Info trajet en cours</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Vitesse :
                        <b *ngIf="trackingMission2">{{trackingMission2?.realTimeRecord?.speed}} Km/H</b>
                        <b *ngIf="!trackingMission2">-</b>
                    </span></li>
                <li> <span>Position actuelle :
                        <b *ngIf="trackingMission2">
                            {{trackingMission2?.realTimeRecord?.geocoding | truncate :[40]}}</b>
                        <b *ngIf="!trackingMission2">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedRouteLeg2">{{selectedRouteLeg2?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedRouteLeg2">-</b>
                    </span></li>
                    <li> <span>A proximité de P.O.I :
                        <b *ngIf="selectedRouteLeg2 && trackingMission2">
                            {{trackingMission2?.firstNearest ? selectedRouteLeg2?.labelPOISource :
                                selectedRouteLeg2?.labelPOITarget}}
                            de {{trackingMission2?.minDistance | number:'.0-2'}} Km
                        </b>
                        <b *ngIf="!selectedRouteLeg2 || !trackingMission2">-</b>
                    </span></li>
                <li> <span>Distance parcourue :
                        <b *ngIf="trackingMission2">{{trackingMission2?.accordingDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!trackingMission2">-</b>
                    </span></li>

                <li><span> Durée restante :
                        <b *ngIf="trackingMission2">{{trackingMission2?.accordingDuration | timediff}}</b>
                        <b *ngIf="!trackingMission2">-</b>
                    </span></li>


            </ul>
        </div>
    </fieldset>
</ng-template>

<!-- END TRACKING INFO -->


<!-- START TRACKING INFO -->
<ng-template #trackingPopOver3>
    <fieldset class="without-border">

        <div>
            <h4>Info mission</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Poi de départ :
                        <b *ngIf="selectedMission3">{{selectedMission3?.routeLegs[0]?.labelPOISource}} </b>
                        <b *ngIf="!selectedMission3">-</b>
                    </span></li>
                <li> <span>Poi d'arrivé :
                        <b *ngIf="selectedMission3">
                            {{selectedMission3?.routeLegs[selectedMission3?.routeLegs?.length - 1]?.labelPOITarget}}
                        </b>
                        <b *ngIf="!selectedMission3">-</b>
                    </span></li>
                <li> <span>Kilométrage :
                        <b *ngIf="selectedMission3">{{selectedMission3?.travelDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!selectedMission3">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedMission3">{{selectedMission3?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedMission3">-</b>
                    </span></li>

                <li> <span>Distance restante :
                        <b *ngIf="trackingMission3">{{selectedMission3?.travelDistance -
                            (trackingAccordingDistance+trackingMission3?.accordingDistance) | positiveNumber |
                            number:'.0-2'}}
                            Km</b>
                        <b *ngIf="!trackingMission3">-</b>
                    </span></li>
            </ul>
        </div>
    </fieldset>
    <fieldset class="without-border">
        <div>
            <h4>Info trajet en cours</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Vitesse :
                        <b *ngIf="trackingMission3">{{trackingMission3?.realTimeRecord?.speed}} Km/H</b>
                        <b *ngIf="!trackingMission3">-</b>
                    </span></li>
                <li> <span>Position actuelle :
                        <b *ngIf="trackingMission3">
                            {{trackingMission3?.realTimeRecord?.geocoding | truncate :[40]}}</b>
                        <b *ngIf="!trackingMission3">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedRouteLeg3">{{selectedRouteLeg3?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedRouteLeg3">-</b>
                    </span></li>
                    <li> <span>A proximité de P.O.I :
                        <b *ngIf="selectedRouteLeg3 && trackingMission3">
                            {{trackingMission3?.firstNearest ? selectedRouteLeg3?.labelPOISource :
                                selectedRouteLeg3?.labelPOITarget}}
                            de {{trackingMission3?.minDistance | number:'.0-2'}} Km
                        </b>
                        <b *ngIf="!selectedRouteLeg3 || !trackingMission3">-</b>
                    </span></li>
                <li> <span>Distance parcourue :
                        <b *ngIf="trackingMission3">{{trackingMission3?.accordingDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!trackingMission3">-</b>
                    </span></li>

                <li><span> Durée restante :
                        <b *ngIf="trackingMission3">{{trackingMission3?.accordingDuration | timediff}}</b>
                        <b *ngIf="!trackingMission3">-</b>
                    </span></li>

            </ul>
        </div>
    </fieldset>
</ng-template>

<!-- END TRACKING INFO -->

<!-- START TRACKING INFO -->
<ng-template #trackingPopOver4>
    <fieldset class="without-border">

        <div>
            <h4>Info mission</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Poi de départ :
                        <b *ngIf="selectedMission4">{{selectedMission4?.routeLegs[0]?.labelPOISource}} </b>
                        <b *ngIf="!selectedMission4">-</b>
                    </span></li>
                <li> <span>Poi d'arrivé :
                        <b *ngIf="selectedMission4">
                            {{selectedMission4?.routeLegs[selectedMission4?.routeLegs?.length - 1]?.labelPOITarget}}
                        </b>
                        <b *ngIf="!selectedMission4">-</b>
                    </span></li>
                <li> <span>Kilométrage :
                        <b *ngIf="selectedMission4">{{selectedMission4?.travelDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!selectedMission4">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedMission4">{{selectedMission4?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedMission4">-</b>
                    </span></li>

                <li> <span>Distance restante :
                        <b *ngIf="trackingMission4">{{selectedMission4?.travelDistance -
                            (trackingAccordingDistance+trackingMission4?.accordingDistance) | positiveNumber |
                            number:'.0-2'}}
                            Km</b>
                        <b *ngIf="!trackingMission4">-</b>
                    </span></li>
            </ul>
        </div>
    </fieldset>
    <fieldset class="without-border">
        <div>
            <h4>Info trajet en cours</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Vitesse :
                        <b *ngIf="trackingMission4">{{trackingMission4?.realTimeRecord?.speed}} Km/H</b>
                        <b *ngIf="!trackingMission4">-</b>
                    </span></li>
                <li> <span>Position actuelle :
                        <b *ngIf="trackingMission4">
                            {{trackingMission4?.realTimeRecord?.geocoding | truncate :[40]}}</b>
                        <b *ngIf="!trackingMission4">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedRouteLeg4">{{selectedRouteLeg4?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedRouteLeg4">-</b>
                    </span></li>
                    <li> <span>A proximité de P.O.I :
                        <b *ngIf="selectedRouteLeg4 && trackingMission4">
                            {{trackingMission4?.firstNearest ? selectedRouteLeg4?.labelPOISource :
                                selectedRouteLeg4?.labelPOITarget}}
                            de {{trackingMission4?.minDistance | number:'.0-2'}} Km
                        </b>
                        <b *ngIf="!selectedRouteLeg4 || !trackingMission4">-</b>
                    </span></li>
                <li> <span>Distance parcourue :
                        <b *ngIf="trackingMission4">{{trackingMission4?.accordingDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!trackingMission4">-</b>
                    </span></li>

                <li><span> Durée restante :
                        <b *ngIf="trackingMission4">{{trackingMission4?.accordingDuration | timediff}}</b>
                        <b *ngIf="!trackingMission4">-</b>
                    </span></li>

            </ul>
        </div>
    </fieldset>
</ng-template>

<!-- END TRACKING INFO -->


<!-- START TRACKING INFO -->
<ng-template #trackingPopOver5>
    <fieldset class="without-border">

        <div>
            <h4>Info mission</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Poi de départ :
                        <b *ngIf="selectedMission5">{{selectedMission5?.routeLegs[0]?.labelPOISource}} </b>
                        <b *ngIf="!selectedMission5">-</b>
                    </span></li>
                <li> <span>Poi d'arrivé :
                        <b *ngIf="selectedMission5">
                            {{selectedMission5?.routeLegs[selectedMission5?.routeLegs?.length - 1]?.labelPOITarget}}
                        </b>
                        <b *ngIf="!selectedMission5">-</b>
                    </span></li>
                <li> <span>Kilométrage :
                        <b *ngIf="selectedMission5">{{selectedMission5?.travelDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!selectedMission5">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedMission5">{{selectedMission5?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedMission5">-</b>
                    </span></li>

                <li> <span>Distance restante :
                        <b *ngIf="trackingMission5">{{selectedMission5?.travelDistance -
                            (trackingAccordingDistance+trackingMission5?.accordingDistance) | positiveNumber |
                            number:'.0-2'}}
                            Km</b>
                        <b *ngIf="!trackingMission5">-</b>
                    </span></li>
            </ul>
        </div>
    </fieldset>
    <fieldset class="without-border">
        <div>
            <h4>Info trajet en cours</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Vitesse :
                        <b *ngIf="trackingMission5">{{trackingMission5?.realTimeRecord?.speed}} Km/H</b>
                        <b *ngIf="!trackingMission5">-</b>
                    </span></li>
                <li> <span>Position actuelle :
                        <b *ngIf="trackingMission5">
                            {{trackingMission5?.realTimeRecord?.geocoding | truncate :[40]}}</b>
                        <b *ngIf="!trackingMission5">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedRouteLeg5">{{selectedRouteLeg5?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedRouteLeg5">-</b>
                    </span></li>
                    <li> <span>A proximité de P.O.I :
                        <b *ngIf="selectedRouteLeg5 && trackingMission5">
                            {{trackingMission5?.firstNearest ? selectedRouteLeg5?.labelPOISource :
                                selectedRouteLeg5?.labelPOITarget}}
                            de {{trackingMission5?.minDistance | number:'.0-2'}} Km
                        </b>
                        <b *ngIf="!selectedRouteLeg5 || !trackingMission5">-</b>
                    </span></li>
                <li> <span>Distance parcourue :
                        <b *ngIf="trackingMission5">{{trackingMission5?.accordingDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!trackingMission5">-</b>
                    </span></li>

                <li><span> Durée restante :
                        <b *ngIf="trackingMission5">{{trackingMission5?.accordingDuration | timediff}}</b>
                        <b *ngIf="!trackingMission5">-</b>
                    </span></li>

            </ul>
        </div>
    </fieldset>
</ng-template>

<!-- END TRACKING INFO -->


<!-- START TRACKING INFO -->
<ng-template #trackingPopOver6>
    <fieldset class="without-border">

        <div>
            <h4>Info mission</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Poi de départ :
                        <b *ngIf="selectedMission6">{{selectedMission6?.routeLegs[0]?.labelPOISource}} </b>
                        <b *ngIf="!selectedMission6">-</b>
                    </span></li>
                <li> <span>Poi d'arrivé :
                        <b *ngIf="selectedMission6">
                            {{selectedMission6?.routeLegs[selectedMission6?.routeLegs?.length - 1]?.labelPOITarget}}
                        </b>
                        <b *ngIf="!selectedMission6">-</b>
                    </span></li>
                <li> <span>Kilométrage :
                        <b *ngIf="selectedMission6">{{selectedMission6?.travelDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!selectedMission6">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedMission6">{{selectedMission6?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedMission6">-</b>
                    </span></li>

                <li> <span>Distance restante :
                        <b *ngIf="trackingMission6">{{selectedMission6?.travelDistance -
                            (trackingAccordingDistance+trackingMission6?.accordingDistance) | positiveNumber |
                            number:'.0-2'}}
                            Km</b>
                        <b *ngIf="!trackingMission6">-</b>
                    </span></li>
            </ul>
        </div>
    </fieldset>
    <fieldset class="without-border">
        <div>
            <h4>Info trajet en cours</h4>
            <ul class="listeNiveauUn jqInner">
                <li> <span>Vitesse :
                        <b *ngIf="trackingMission6">{{trackingMission6?.realTimeRecord?.speed}} Km/H</b>
                        <b *ngIf="!trackingMission6">-</b>
                    </span></li>
                <li> <span>Position actuelle :
                        <b *ngIf="trackingMission6">
                            {{trackingMission6?.realTimeRecord?.geocoding | truncate :[40]}}</b>
                        <b *ngIf="!trackingMission6">-</b>
                    </span></li>
                <li> <span>Durée :
                        <b *ngIf="selectedRouteLeg6">{{selectedRouteLeg6?.travelDuration | timediff}}</b>
                        <b *ngIf="!selectedRouteLeg6">-</b>
                    </span></li>
                    <li> <span>A proximité de P.O.I :
                        <b *ngIf="selectedRouteLeg6 && trackingMission6">
                            {{trackingMission6?.firstNearest ? selectedRouteLeg6?.labelPOISource :
                                selectedRouteLeg6?.labelPOITarget}}
                            de {{trackingMission6?.minDistance | number:'.0-2'}} Km
                        </b>
                        <b *ngIf="!selectedRouteLeg6 || !trackingMission6">-</b>
                    </span></li>
                <li> <span>Distance parcourue :
                        <b *ngIf="trackingMission6">{{trackingMission6?.accordingDistance | number:'.0-2'}} Km</b>
                        <b *ngIf="!trackingMission6">-</b>
                    </span></li>

                <li><span> Durée restante :
                        <b *ngIf="trackingMission6">{{trackingMission6?.accordingDuration | timediff}}</b>
                        <b *ngIf="!trackingMission6">-</b>
                    </span></li>

            </ul>
        </div>
    </fieldset>
</ng-template>

<!-- END TRACKING INFO -->