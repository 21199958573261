import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransportTypeComponent } from './transport-type.component'; // Adjust the import path based on your structure
import { ModalModule } from 'ngx-bootstrap/modal'; // Importing the modal module
import { ToastrModule } from 'ngx-toastr'; // Importing the toastr module
import { FormsModule } from '@angular/forms'; // Importing FormsModule if you're using forms
import { RouterModule } from '@angular/router'; // Importing RouterModule if you're using routing in this module
import { NgxLoadingModule } from 'ngx-loading';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    TransportTypeComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(), // Include the modal module
    ToastrModule.forRoot(), // Include toastr module for notifications
    FormsModule, // Include FormsModule for form handling
    RouterModule,
    OrderPipModule,
    NgxLoadingModule.forRoot({}),
    FilterPipeModule,
  ],
  exports: [
    TransportTypeComponent // Export the component if it needs to be used in other modules
  ]
})
export class TransportTypeModule { }
