import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Mission } from '../data-management.model';
import { DataManagementComponent } from '../data-management.component';
import { MissionService } from '../providers';

declare var $: any;

@Component({
    selector: 'app-mission',
    templateUrl: './mission.component.html',
    styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {
    loading: boolean = false;
    missions: Mission[] = [];
    selectedMission: Mission |null = null;
    missionForm : Mission = new Mission();

    @ViewChild('missionDeleteModal', { static: false }) missionDeleteModal: ElementRef;


    @ViewChild('missionFormModal', { static: false }) missionFormModal: ElementRef;
    

    mode = 'ADD';

    constructor(private parent: DataManagementComponent,
        private toastr: ToastrService,
        private missionService: MissionService) { }

    ngOnInit() {
        this.parent.initAllIcons();
        this.loadMissions();
    }

    loadMissions() {
        this.loading = true;
        this.missionService.getMissions()
            .subscribe(missions => {
                this.loading = false;
                this.missions = missions;
                this.selectedMission = this.missions[0];
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de chargements", "Mission")
            })
    }

    openMissionForm() {
        this.mode = 'ADD';
        this.missionForm = new Mission();
        $(this.missionFormModal.nativeElement).modal('show');
    }

    openMissionEditForm() {
        this.mode = 'UPDATE';
        this.missionForm = Object.assign({}, this.selectedMission);
        $(this.missionFormModal.nativeElement).modal('show');
        
    }


    onMissionSaved(mission: Mission) {
        $(this.missionFormModal.nativeElement).modal('hide');
        this.loadMissions();
    }

    onDelete() {
        if (!this.selectedMission) return;
        this.loading = true;
        this.missionService.delete(this.selectedMission.id)
            .subscribe(response => {
                this.loading = false;
                $(this.missionDeleteModal.nativeElement).modal('hide');
                if (response) {
                    this.toastr.success("Supprimé", "Mission");
                    this.loadMissions();
                } else {
                    this.toastr.error("Erreur lors de suppression", "Mission");
                }
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de suppression", "Mission");
            })
    }

    onConfirmDelete() {
        $(this.missionDeleteModal.nativeElement).modal('show');
    }

    missionFormModalhide(){
        $(this.missionFormModal.nativeElement).modal('hide');
    }
    missionDeleteModalhide(){
        $(this.missionDeleteModal.nativeElement).modal('hide');
    }


}
