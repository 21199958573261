import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { Schedule } from '../../charges-pfb';
import { Mission, TrackingMission, TrackingMissionData } from '../../data-management';

@Injectable()
export class CimentMissionService {

  selectedMission: Mission | null = null;

  constructor(private http: HttpClient) {
    }

    // getMissions(): Observable<TrackingMissionData[]> {
    //     let headers = createAuthorizationHeader();
    //     let options = { headers: headers };
    //     return this.http.get<TrackingMissionData[]>(dns + "missionCiments", options);
    // }

    getMissionsNotInIds(missionIds : Number[]): Observable<TrackingMissionData[]> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.post<TrackingMissionData[]>(dns + "missionCiments/notInIds",
             missionIds, options);
    }

    add(payload: Mission): Observable<Mission> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.post<Mission>(dns + "missionCiments", payload, options);
    }

    tracking(missionId: number, vehicleId: number): Observable<TrackingMission> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.get<TrackingMission>(dns + "missionCiments/" + missionId + "/tracking/" + vehicleId, options);
    }

    delete(missionId: number): Observable<boolean> {
        let headers = createAuthorizationHeader();
        let options = { headers: headers };
        return this.http.delete<boolean>(dns + "missionCiments/" + missionId, options);
    }

    dailyListOfMissionCiment(dateInterval: DateInterval): Observable<Schedule[]> {
        let headers = createAuthorizationHeader();
        return this.http.post<Schedule[]>(dns + "missionCiments/tracking/schedule", dateInterval, { headers: headers });
      }

}
