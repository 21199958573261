import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { ClocationComponent } from './clocation.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using template-driven forms
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule if you're using reactive forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for toast notifications
import { DataManagementService } from 'src/app/client-management/data-management'; // Adjust the path as necessary
import { CLocationComponent } from './clocation.component';
import { DiverManagementComponent } from '../diver-management/diver-management.component';
import { DiverManagementModule } from '../diver-management/diver-management.module';

@NgModule({
  declarations: [
    CLocationComponent,
  ],
  imports: [
    CommonModule, // Include CommonModule for common directives
    FormsModule, // Include FormsModule if you are using forms
    ReactiveFormsModule, // Include ReactiveFormsModule if you are using reactive forms
    ToastrModule.forRoot(),
    DiverManagementModule
  ],
  providers: [
    DataManagementService // Provide your service here if needed
  ],
  exports: [
    CLocationComponent // Export the component if it needs to be used in other modules
  ]
})
export class ClocationModule { }
