import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PathPoiComponent } from './path-poi.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet'; // If you're using ngx-leaflet for maps
import { PathService } from '../path/path.service'; // Import your service
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { DurationPipeModule } from 'src/app/pipes/duration.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    PathPoiComponent,
    // Add other components here if necessary
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(), // Initialize ngx-bootstrap datepicker
    ToastrModule.forRoot(), // Initialize Toastr for notifications
    SharedPipesModule,
    LeafletModule,
    TruncateModule,
    DurationPipeModule,
    MatSlideToggleModule
  ],
  providers: [
    PathService // Provide your service
  ],
  exports: [
    PathPoiComponent // Export if you need to use it in other modules
  ]
})
export class PathPoiModule { }
