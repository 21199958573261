import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { KmJrTrajetItemComponent } from './km-jour-trajet-item.component';
import { DurationPipesModule } from 'src/app/pipes/duration-sec.module';

@NgModule({
  declarations: [
    KmJrTrajetItemComponent // Declare the component
  ],
  imports: [
    CommonModule, // Provides Angular directives
    RouterModule,
    DurationPipesModule
  ],
  exports: [
    KmJrTrajetItemComponent // Export the component for use in other modules
  ],
  providers: [] // Add services if necessary
})
export class KmJrTrajetItemModule { }
