import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsommationMoyenneComponent } from './consommation-moyenne.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [ConsommationMoyenneComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot() // Importing ModalModule for modal functionalities if needed
  ],
  exports: [ConsommationMoyenneComponent] // Exporting the component for use in other modules
})
export class ConsommationMoyenneModule {}
