import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { OilChange, Provider, TypeOil, Vehicule, Group, MultiType } from '../../model/index';
import { OilChangeService } from '../../providers/oil-change-service';
import { ProviderService } from '../../providers/provider-service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MultiTypeService } from '../../providers/multi-type-service';

@Component({
    selector: 'app-vidange-form',
    templateUrl: './vidange-form.component.html'
})

export class VidangeFormComponent implements OnInit {

    @Input()
    mode: 'ADD' | 'UPDATE' = 'ADD';

    @Input()
    oilChange: OilChange = new OilChange();

    @Input()
    vehicule: Vehicule = new Vehicule();

    @Input()
    group: Group = new Group();

    @Output()
    oilChangeWasAdded: EventEmitter<OilChange> = new EventEmitter<OilChange>();

    @Output()
    oilChangeWasUpdated: EventEmitter<OilChange> = new EventEmitter<OilChange>();

    providers: Provider[] = [];

    loading: boolean = false;

    showProvider = false;

    listType: TypeOil[] = [
        { name: "5000", value: 5000 },
        { name: "8000", value: 8000 },
        { name: "10000", value: 10000 },
        { name: "15000", value: 15000 },
        { name: "20000", value: 20000 },
        { name: "30000", value: 30000 },
        { name: "40000", value: 40000 },
        { name: "50000", value: 50000 },
        { name: "Autres", value: -1 }];

    /**
     * Pour afficher/masquer une liste de formulaire
     */
    showTypeEngineOil: boolean = true;
    showTypeOilF: boolean = true;
    showTypeAirF: boolean = true;
    showTypeSeparatorF: boolean = true;
    showTypeFuelF: boolean = true;

    maxDate: Date = new Date();

    refTypeOilFilters: MultiType[] = [];
    refTypeSeparatorFilters: MultiType[] = [];
    refTypeAirFilters: MultiType[] = [];
    refTypeFuelFilters: MultiType[] = [];
    refTypeEngineOilFilters: MultiType[] = [];

    constructor(private oilChangeService: OilChangeService, private multiTypeService: MultiTypeService, private providerService: ProviderService, public toastr: ToastrService, private localeService: BsLocaleService) { }

    ngOnInit() {
        this.localeService.use('fr');
        this.loadProviders();
        this.loadTypes();
    }

    /**
         * Charger liste des types
         */
    loadTypes() {
        this.multiTypeService.getListOfMultipleTypes(["OIL_CHANGE","REF_TYPE_AIR","REF_TYPE_ENGINE","REF_TYPE_FUEL","REF_TYPE_SEPARATOR"]).subscribe(refTypeFilters => {
            this.refTypeOilFilters = refTypeFilters.filter(f => f.type == 'OIL_CHANGE');
            this.refTypeAirFilters = refTypeFilters.filter(f => f.type == 'REF_TYPE_AIR');
            this.refTypeEngineOilFilters = refTypeFilters.filter(f => f.type == 'REF_TYPE_ENGINE');
            this.refTypeFuelFilters = refTypeFilters.filter(f => f.type == 'REF_TYPE_FUEL');
            this.refTypeSeparatorFilters = refTypeFilters.filter(f => f.type == 'REF_TYPE_SEPARATOR');
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Charger liste des fournisseurs
     */
    loadProviders() {
        this.providerService.getProviders("MAINTENANCE").subscribe(providers => {
            this.providers = providers;
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
        });
    }

    /**
     * Ajouter nouveau vidange
     */
    onAdd(form: NgForm) {
        this.loading = true;
        this.readCheckBoxesForm();
        this.oilChangeService.add(this.oilChange, this.vehicule.idVehicule, this.group.idGroupe).subscribe(oilChange => {
            this.toastr.success('Vidange informations bien sauvegarder !', 'info');
            this.oilChangeWasAdded.emit(oilChange);
            this.loading = false;
            form.reset();
            this.loadProviders();
            this.loadTypes();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * modifier vidange
     */
    onUpdate(form: NgForm) {
        this.loading = true;
        this.readCheckBoxesForm();
        this.oilChangeService.update(this.oilChange).subscribe(oilChange => {
            this.toastr.success('Vidange informations bien sauvegarder !', 'info');
            this.oilChangeWasUpdated.emit(oilChange);
            this.loading = false;
            form.reset();
            this.loadProviders();
            this.loadTypes();
        }, error => {
            let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
            this.loading = false;
        });
    }

    /**
     * vérifier l'éxsitant d'un type dans la liste
     */
    isNewTypeOil(type: number) {
        if (!type) return false;
        if (this.listType.find(x => x.value == type)) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Evénement sur le changement du liste: H Moteur
     */
    onChangeSelectOilChange() {
      if (this.oilChange.typeEngineOil !== null && this.oilChange.typeEngineOil < 1) {
        this.showTypeEngineOil = false;
        this.oilChange.typeEngineOil = null;
      }
    }
  /**
   * Evénement sur le changement de la liste: F Huile
   */
  onChangeSelectTypeOilF() {
    if (this.oilChange.typeOilF !== null && this.oilChange.typeOilF < 1) {
      this.showTypeOilF = false;
      this.oilChange.typeOilF = null;
    }
  }

  /**
   * Evénement sur le changement de la liste: F Air
   */
  onChangeSelectTypeAirF() {
    if (this.oilChange.typeAirF !== null && this.oilChange.typeAirF < 1) {
      this.showTypeAirF = false;
      this.oilChange.typeAirF = null;
    }
  }

  /**
   * Evénement sur le changement de la liste: F Carburant
   */
  onChangeSelectTypeFuelF() {
    if (this.oilChange.typeFuelF !== null && this.oilChange.typeFuelF < 1) {
      this.showTypeFuelF = false;
      this.oilChange.typeFuelF = null;
    }
  }

  /**
   * Evénement sur le changement de la liste: F Séparateur
   */
  onChangeSelectSeparatorF() {
    if (this.oilChange.typeSeparatorF !== null && this.oilChange.typeSeparatorF < 1) {
      this.showTypeSeparatorF = false;
      this.oilChange.typeSeparatorF = null;
    }
  }


    /**
     * Vérification des cases à cocher de formulaire
     */
    readCheckBoxesForm() {
        /**
         * H Moteur
         */
        if (!this.oilChange.engineOil) {
            this.oilChange.typeEngineOil = null;
            this.oilChange.quantity = null;
        }

        /**
         * F Huile
         */
        if (!this.oilChange.oilF) {
            this.oilChange.typeOilF = null;
        }

        /**
         * F Air
         */
        if (!this.oilChange.airF) {
            this.oilChange.typeAirF = null;
        }
        /**
         * F Carburant
         */
        if (!this.oilChange.fuelF) {
            this.oilChange.typeFuelF = null;
        }

        /**
         * F Séparateur
         */
        if (!this.oilChange.separatorF) {
            this.oilChange.typeSeparatorF = null;
        }
    }

}
