<div class="container col-md-12" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
  <div class="box box-info">
    <form class="form-horizontal" #visitForm="ngForm">
      <div class="box-body">

        <div class="form-group">
          <label class="col-sm-2 control-label">Label</label>
          <div class="col-sm-4">
            <input class="form-control" type="text" name="label" [(ngModel)]="geoMarketingAssignmentAddDto.label"
              required autocomplete="off" />
          </div>
        </div>

        <!-- display all vehicules of user choose this option-->
        <div class="form-group">
          <label class="col-sm-2 control-label">Groupe</label>
          <div class="col-sm-10">



            <!-- <ng-select
            [multiple]="true"
            [items]="vehiculesItems"
            placeholder="aucun vehicule selectionné"
            [active]="vehiculesSelected"
            [allowClear]="true"
            (data)="selectedVehicules($event)">
            </ng-select> -->

          </div>
        </div>

        <!-- display all vehicules of user choose this option-->
        <div class="form-group">
          <label class="col-sm-2 control-label">Véhicule</label>
          <div class="col-sm-10">
            <!-- <ng-select [items]="vehiculesItems" [multiple]="false" [active]="vehiculesSelected" [allowClear]="false"
              (data)="selectedVehicules($event)" [childrenField]="vehiculesItems"
              placeholder="aucun vehicule selectionné">
            </ng-select> -->
          </div>
        </div>

        <!-- start and end date-->
        <div class="row">
          <div class="form-group" class="col-md-6">
            <label class="col-sm-2 control-label">Du</label>
            <div class="col-sm-10">

              <input type="text" class="form-control input-sm" bsDatepicker name="dateDebut"
                [(ngModel)]="geoMarketingAssignmentAddDto.dateDebut"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                [minDate]="maxDate" placement="bottom" autocomplete="off" (ngModelChange)="onChangeStartDate()" required>

            </div>
          </div>
          <div class="form-group" class="col-md-6">
            <label class="col-sm-2 control-label">Au</label>
            <div class="col-sm-10">
              <input type="text" class="form-control input-sm" bsDatepicker name="dateFin"
                [(ngModel)]="geoMarketingAssignmentAddDto.dateFin"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                [minDate]="geoMarketingAssignmentAddDto.dateDebut" placement="bottom" autocomplete="off" required>

            </div>
          </div>
        </div>


        <!-- started from here -->

        <div class="form-group has-success form-horizontal">
          <label class="col-sm-2 control-label">Lundi</label>
          <div class="col-lg-1 col-md-1">
            <mat-checkbox class="col-sm-4" [(ngModel)]="geoMarketingAssignmentAddDto.monday" name="monday">
            </mat-checkbox>
          </div>
          <label *ngIf="!geoMarketingAssignmentAddDto.monday" class="col-sm-1 control-label">Secteur</label>
          <label *ngIf="geoMarketingAssignmentAddDto.monday" class="col-sm-1 control-label">Secteur *</label>
          <div class="col-lg-2 col-md-2">
            <select [disabled]="!geoMarketingAssignmentAddDto.monday"
              [(ngModel)]="geoMarketingAssignmentAddDto.idgeoMarketingMn" name="idgeoMarketingMn"
              class="form-control input-sm select2" #idgeoMarketingMn="ngModel" required>
              <option *ngFor="let geo of geoMarketing" [ngValue]="geo.id"> {{geo?.label | capitalizefirst}}</option>
            </select>

          </div>

          <label class="col-sm-2 control-label">Mardi</label>
          <div class="col-lg-1 col-md-1">
            <mat-checkbox class="col-sm-4" [(ngModel)]="geoMarketingAssignmentAddDto.tuesday" name="tuesday">
            </mat-checkbox>
          </div>
          <label *ngIf="!geoMarketingAssignmentAddDto.tuesday" class="col-sm-1 control-label">Secteur</label>
          <label *ngIf="geoMarketingAssignmentAddDto.tuesday" class="col-sm-1 control-label">Secteur *</label>
          <div class="col-lg-2 col-md-2">
            <select [disabled]="!geoMarketingAssignmentAddDto.tuesday"
              [(ngModel)]="geoMarketingAssignmentAddDto.idgeoMarketingTu" name="idgeoMarketingTu"
              class="form-control input-sm select2" #idgeoMarketingTu="ngModel" required>
              <option *ngFor="let geo of geoMarketing" [ngValue]="geo.id"> {{geo?.label | capitalizefirst}}</option>
            </select>

          </div>
        </div>

        <div class="form-group has-success form-horizontal">
          <label class="col-sm-2 control-label">Mercredi</label>
          <div class="col-lg-1 col-md-1">
            <mat-checkbox class="col-sm-4" [(ngModel)]="geoMarketingAssignmentAddDto.wednesday" name="wednesday">
            </mat-checkbox>
          </div>
          <label *ngIf="!geoMarketingAssignmentAddDto.wednesday" class="col-sm-1 control-label">Secteur</label>
          <label *ngIf="geoMarketingAssignmentAddDto.wednesday" class="col-sm-1 control-label">Secteur *</label>
          <div class="col-lg-2 col-md-2">
            <select [disabled]="!geoMarketingAssignmentAddDto.wednesday"
              [(ngModel)]="geoMarketingAssignmentAddDto.idgeoMarketingWd" name="idgeoMarketingWd"
              class="form-control input-sm select2" #idgeoMarketingWd="ngModel" required>
              <option *ngFor="let geo of geoMarketing" [ngValue]="geo.id"> {{geo?.label | capitalizefirst}}</option>
            </select>

          </div>


          <label class="col-sm-2 control-label">Jeudi</label>
          <div class="col-lg-1 col-md-1">
            <mat-checkbox class="col-sm-4" [(ngModel)]="geoMarketingAssignmentAddDto.thursday" name="thursday">
            </mat-checkbox>
          </div>
          <label *ngIf="!geoMarketingAssignmentAddDto.thursday" class="col-sm-1 control-label">Secteur</label>
          <label *ngIf="geoMarketingAssignmentAddDto.thursday" class="col-sm-1 control-label">Secteur *</label>
          <div class="col-lg-2 col-md-2">
            <select [disabled]="!geoMarketingAssignmentAddDto.thursday"
              [(ngModel)]="geoMarketingAssignmentAddDto.idgeoMarketingTr" name="idgeoMarketingTr"
              class="form-control input-sm select2" #idgeoMarketingTr="ngModel" required>
              <option *ngFor="let geo of geoMarketing" [ngValue]="geo.id"> {{geo?.label | capitalizefirst}}</option>
            </select>

          </div>
        </div>


        <div class="form-group has-success form-horizontal">
          <label class="col-sm-2 control-label">Vendredi</label>
          <div class="col-lg-1 col-md-1">
            <mat-checkbox class="col-sm-4" [(ngModel)]="geoMarketingAssignmentAddDto.friday" name="friday">
            </mat-checkbox>
          </div>
          <label *ngIf="!geoMarketingAssignmentAddDto.friday" class="col-sm-1 control-label">Secteur</label>
          <label *ngIf="geoMarketingAssignmentAddDto.friday" class="col-sm-1 control-label">Secteur *</label>
          <div class="col-lg-2 col-md-2">
            <select [disabled]="!geoMarketingAssignmentAddDto.friday"
              [(ngModel)]="geoMarketingAssignmentAddDto.idgeoMarketingFr" name="idgeoMarketingFr"
              class="form-control input-sm select2" #idgeoMarketingFr="ngModel" required>
              <option *ngFor="let geo of geoMarketing" [ngValue]="geo.id"> {{geo?.label | capitalizefirst}}</option>
            </select>

          </div>


          <label class="col-sm-2 control-label">Samedi</label>
          <div class="col-lg-1 col-md-1">
            <mat-checkbox class="col-sm-4" [(ngModel)]="geoMarketingAssignmentAddDto.saturday" name="saturday">
            </mat-checkbox>
          </div>
          <label *ngIf="!geoMarketingAssignmentAddDto.saturday" class="col-sm-1 control-label">Secteur</label>
          <label *ngIf="geoMarketingAssignmentAddDto.saturday" class="col-sm-1 control-label">Secteur *</label>
          <div class="col-lg-2 col-md-2">
            <select [disabled]="!geoMarketingAssignmentAddDto.saturday"
              [(ngModel)]="geoMarketingAssignmentAddDto.idgeoMarketingSt" name="idgeoMarketingSt"
              class="form-control input-sm select2" #idgeoMarketingSt="ngModel" required>
              <option *ngFor="let geo of geoMarketing" [ngValue]="geo.id"> {{geo?.label | capitalizefirst}}</option>
            </select>

          </div>
        </div>

        <!-- end of started from here -->

        <!-- button-->
        <div class="box-footer">
          <button *ngIf="mode == 'ADD'" class="btn btn-info pull-right" [disabled]="!visitForm.form.valid"
            (click)="onSaveVisit()">
            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i> Valider
          </button>
          <button *ngIf="mode == 'UPDATE'" class="btn btn-info pull-right" [disabled]="!visitForm.form.valid"
            (click)="onUpdateVisit()">
            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i> Enregistrer
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
