import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportSentComponent } from './report-sent.component';
import { ImportExportService } from '../import-export.service';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ReportSentComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),  // Include this if Toastr is used globally, otherwise import as needed
    ModalModule.forRoot(),    // For ngx-bootstrap modal
    FormsModule,
  ],
  providers: [
    ImportExportService
  ],
  exports: [
    ReportSentComponent
  ]
})
export class ReportSentModule { }
