import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertSettingsComponent } from './alert-settings.component';
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using ngModel or other form features
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you're using Toastr notifications
import { RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    AlertSettingsComponent // Declare the AlertSettingsComponent
  ],
  imports: [
    CommonModule, // Provides common directives (e.g., ngIf, ngFor)
    FormsModule, // Import FormsModule if you're using ngModel or other form features
    ToastrModule.forRoot(),
    RouterModule,
    NgxLoadingModule.forRoot({}),

  ],
  exports: [
    AlertSettingsComponent // Export the component if it needs to be used in other modules
  ]
})
export class AlertSettingsModule { }
