import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { interval } from 'rxjs';
import { Subscription } from 'rxjs-compat';
import { DateInterval } from 'src/app/shared/model';
import { GroupDto } from '../../data-management/data-management.model';
import { DataManagementService } from '../../data-management/data-management.service';
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/stock')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
interface IDailyKmData {
  nameOfDay: string;
  dayOfMonth: number;
  month: number;
  year: number;
  distanceDriven: number;
}

interface IChartDataPoint {
  name: string;
  y: number;
  color?: string;
  day?: string;
  pondere?: number;
}
interface ICustomPoint extends Highcharts.Point {
  pondere?: number;
}

@Component({
  selector: 'app-daily-kilomtrage-chart',
  templateUrl: './daily-kilomtrage-chart.component.html',
  styleUrls: ['./daily-kilomtrage-chart.component.css']
})
export class DailyKilomtrageChartComponent implements OnInit {
  dailyKmOptions: Highcharts.Options = {};
  dailyKmLoading: boolean = false;
  groups: GroupDto[] = [];
  dailyKmGroup: IDailyKmData[] = [];
  groupDropdownSettings = {};
  groupIds :{idGroupe: number, nom: string}[] = [];
  dataAvailable: boolean = false;
  dateInterval = new DateInterval();
  endDate: Date | null = new Date();
  startDate: Date = new Date();
    /** UPDATE TRACKING TIMER */
    updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;


  @Input() set groupe(value: GroupDto[]) {
    if(value.length > 0){
     this.groups = value;
      this.groupIds.push({idGroupe: value[0].idGroupe, nom: this.truncateString(value[0].nom) });

      this.getDailyKm();
    }
}
  constructor(private groupService: DataManagementService) { }

  ngOnInit() {
    if (this.endDate) {
      this.endDate.setDate(new Date().getDate());
      this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth() - 1, this.endDate.getDate());
      this.startDate.setHours(0, 0, 0, 0);
      this.endDate.setHours(23, 59, 59, 59);
    }

    this.startUpdateTrackingTimer();

    this.groupDropdownSettings = {
      singleSelection: true,
      idField: 'idGroupe',
      textField: 'nom',
      enableCheckAll: false,
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }


  onItemSelect(grp: any): void {
    this.groupIds = [];
    grp.nom = this.truncateString(grp.nom);
    this.groupIds.push(grp);
  }


    /**
   * Action change date début
   */
    onChangeStartDate() {
      if (this.endDate! < this.startDate) {
        this.endDate = null;
      }
      this.stopTracking();
    }



  /** STOP TRACKING */
  stopTracking() {
    if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    this.updateTrackingTimer = null;
  }

  getDailyKm(){
    this.dailyKmLoading = true;
    //var date = new Date();
    // this.dateInterval.startDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDay());
    // this.dateInterval.endDate =  new Date();
    this.dateInterval.startDate = this.startDate;
     this.dateInterval.endDate =  this.endDate;
    var id  = this.groupIds[0].idGroupe;
    this.groupService.getDailyKm(id, this.dateInterval).subscribe(group=>{
      this.dailyKmGroup = group;
      this.dataAvailable = true;
      this.createDailyKmChart();
      this.startUpdateTrackingTimer();
      this.dailyKmLoading = false;
    })

  }



  /** START TIME OUT TRACKING FOR RELOAD */
  startUpdateTrackingTimer() {
    // Vérifiez si une souscription existe avant de tenter de la désabonner
    if (this.updateTrackingTimerSubscription) {
      this.updateTrackingTimerSubscription.unsubscribe();
    }

    // Assurez-vous que updateTrackingTimer est initialisé
    this.updateTrackingTimer = 900;

    // Créez une nouvelle souscription avec un intervalle
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      if (this.updateTrackingTimer !== null) {
        this.updateTrackingTimer--;

        // Vérifiez si le timer a atteint 0
        if (this.updateTrackingTimer === 0) {
          if (this.updateTrackingTimerSubscription) {
            this.updateTrackingTimerSubscription.unsubscribe();
          }
          this.getDailyKm();
        }
      }
    });
  }

    createDailyKmChart() {
      const dataStr: string[] = [];
      const data: IChartDataPoint[] = [];
      let calc = 0, calc1 = 0, calc2 = 0;

      this.dailyKmGroup.forEach((daily: IDailyKmData) => {
        if (daily.nameOfDay === "Saturday" || daily.nameOfDay === "Sunday") {
          calc2 += daily.distanceDriven;
          if (daily.nameOfDay === "Sunday") {
            calc = (calc2 / (calc1 + calc2)) * 100;
            data.push({
              name: `${daily.nameOfDay}: ${daily.dayOfMonth}/${daily.month}/${daily.year}`,
              y: daily.distanceDriven,
              color: 'red',
              day: daily.nameOfDay,
              pondere: calc
            });
            calc = 0;
            calc1 = 0;
            calc2 = 0;
          } else {
            data.push({
              name: `${daily.nameOfDay}: ${daily.dayOfMonth}/${daily.month}/${daily.year}`,
              y: daily.distanceDriven,
              color: 'red',
              day: daily.nameOfDay
            });
          }
        } else {
          data.push({
            name: `${daily.nameOfDay}: ${daily.dayOfMonth}/${daily.month}/${daily.year}`,
            y: daily.distanceDriven,
            color: '#5D6D7E',
            day: daily.nameOfDay
          });
          calc1 += daily.distanceDriven;
        }
        dataStr.push(`${daily.nameOfDay.substring(0, 3)} ${daily.dayOfMonth}`);
      });

      this.dailyKmOptions = {
        chart: {
          type: 'areaspline',
        },
        legend: { enabled: false },
        credits: { enabled: false },
        title: { text: 'Kilométrage journalier' },
        xAxis: { categories: dataStr },
        yAxis: {
          title: { text: 'Km' },
          tickPositions: [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000]
        },
        tooltip: {
        //   formatter: function (): string {
        //     const x = this.x as string;
        //     const y = this.y as number;
        //     const point = this.point as ICustomPoint;

        //     const isSunday = x.substring(0, x.indexOf(" ")) === "Sun";
        //     return `<span style="color:${point.color}">\u25CF</span> <b>${point.name}</b><br/>
        // Total kilométrage: <b>${Highcharts.numberFormat(y, 2)} Km</b><br/>
        // ${point.pondere ? `Pondéré: <b>${Highcharts.numberFormat(point.pondere, 2)}%</b>` : ''}`;
        //   }
        },
        series: [{
          type: 'areaspline',
          name: '',
          color: '#D7DBDD',
          data: data,
          marker: {
            symbol: 'circle'
          } as any
        }]

      };

      Highcharts.chart('dailyKmChart', this.dailyKmOptions);

    }


  truncateString(input: string): string {
    if (input.length > 15) {
      return input.slice(0, 15) + '...';
    }
    return input;
  }

}
