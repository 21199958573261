<div class="nav-tabs-custom" style="height:100%;">

  <!--<ul class="nav nav-tabs pull-center">

    <li [routerLink]="['parcking']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img1" title="Parcking" height="60px" width="60px" src="{{imagesCarsDir}}parking-sign.png" />
        <img class="img2" title="Parcking" height="60px" width="60px" src="{{imagesCarsDir}}parking-sign2.png" />
        <span>Parking</span>
      </a>
    </li>

    <li [routerLink]="['lavage']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img1" title="Lavage" height="60px" width="60px" src="{{imagesCarsDir}}car-wash.png" />
        <img class="img2" title="Lavage" height="60px" width="60px" src="{{imagesCarsDir}}car-wash2.png" />
        <span>Lavage</span>
      </a>
    </li>

    <li [routerLink]="['toll']" routerLinkActive="active" data-toggle="tab"><a>
        <img class="img1" title="Péage" height="60px" width="60px" src="{{imagesCarsDir}}toll.png" />
        <img class="img2" title="Péage" height="60px" width="60px" src="{{imagesCarsDir}}toll2.png" />
        <span>Péage</span>
      </a>
    </li>

     <li *ngIf="signinService.isHasCaRealization() || signinService.isHasRole('ROLE_IMPORT_CA_REALIZATION')" [routerLink]="['ca-realization']"
      routerLinkActive="active" data-toggle="tab"><a>
        <img class="img1" title="CA Réalisé" height="60px" width="60px" src="{{imagesCarsDir}}ca-realise.png" />
        <img class="img2" title="CA Réalisé" height="60px" width="60px" src="{{imagesCarsDir}}ca-realise-2.png" />
        <span>CA Réalisé</span>
      </a>
    </li>

    <li *ngIf="signinService.isHasCaRealization() || signinService.isHasRole('ROLE_IMPORT_CA_REALIZATION')" [routerLink]="['clocation']"
    routerLinkActive="active" data-toggle="tab"><a>
      <img class="img1" title="C Location" height="60px" width="60px" src="{{imagesCarsDir}}leasing.png" />
      <img class="img2" title="C Location" height="60px" width="60px" src="{{imagesCarsDir}}leasing2.png" />
      <span>C Location</span>
    </a>
  </li> 

  </ul>-->

   <!-- new design -->
   <div class="diversmenu">
  
    <!-- Item 1 -->
  
        <input type="radio" name="diversItem" id="divers-item-1" class="divers-toggle" [routerLink]="['parcking']" 
        [checked]="getCurrentUrl() == 'parcking'"/>
        <label for="divers-item-1" title="Parcking"><p class="icon"><img src="assets/icons/parcManagementIcons/parking-car.svg" class="svgImg" alt=""></p>
          <!-- <span>Parcking</span> -->
        </label>
   
    <!-- Item 2 -->
  
        <input type="radio" name="diversItem" id="divers-item-2" [routerLink]="['lavage']"
        [checked]="getCurrentUrl() == 'lavage'" class="divers-toggle"/>
        <label for="divers-item-2" title="Lavage"><p class="icon"><img src="assets/icons/parcManagementIcons/car-wash.svg" class="svgImg" alt=""></p>
          <!-- <span>Lavage</span> -->
        </label>
    
    <!-- Item 3 -->
 
    <input type="radio" name="diversItem" id="divers-item-3" [routerLink]="['toll']"
    [checked]="getCurrentUrl() == 'toll'" class="divers-toggle"/>
  <label for="divers-item-3" title="Péage"><p class="icon"><img src="assets/icons/parcManagementIcons/toll-road.svg" class="svgImg" alt=""></p>
    <!-- <span>Péage</span> -->
  </label>

  <!-- Item 3 -->
 
  <input type="radio" name="diversItem" id="divers-item-4" [routerLink]="['tonnage']"
  [checked]="getCurrentUrl() == 'tonnage'" class="divers-toggle"/>
<label for="divers-item-4" title="Tonnage"><p class="icon"><img src="assets/icons/parcManagementIcons/delivery.png" class="svgImg" alt=""></p>
  <!-- <span>Péage</span> -->
</label>
    
    
    <div class="clear"></div>
    
    <!-- Bar -->
    <div class="divers">
      <div class="bar"></div>
    </div>
    
  </div>
  <!-- end new design -->

  <router-outlet></router-outlet>

</div>