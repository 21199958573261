import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { dns } from 'src/app/global.config';
import { GeoMarketingInfoDto, GeoMarketingAddDto, GeoMarketingAssignmentAddDto, GeoMarketingAssignmentInfoDto } from './geo-marketing';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class GeoMarketingService {


  constructor(private http: HttpClient) { }

  getGeoMarketing(): Observable<GeoMarketingInfoDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<GeoMarketingInfoDto[]>(dns + "geoMarketing", options);
  }

  add(payload: GeoMarketingAddDto): Observable<GeoMarketingInfoDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<GeoMarketingInfoDto>(dns + "geoMarketing", payload, options);
  }

  update(payload: GeoMarketingAddDto): Observable<GeoMarketingInfoDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.put<GeoMarketingInfoDto>(dns + "geoMarketing", payload, options);
  }

  delete(geoMarketingId: number): Observable<boolean> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.delete<boolean>(dns + "geoMarketing/" + geoMarketingId, options);
  }

  addGeoMarketingAssignment(payload: GeoMarketingAssignmentAddDto): Observable<GeoMarketingAssignmentInfoDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.post<GeoMarketingAssignmentInfoDto>(dns + "geoMarketing/visit", payload, options);
  }

  updateGeoMarketingAssignment(payload: GeoMarketingAssignmentAddDto): Observable<GeoMarketingAssignmentInfoDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.put<GeoMarketingAssignmentInfoDto>(dns + "geoMarketing/visit", payload, options);
  }

  getGeoMarketingAssignment(): Observable<GeoMarketingAssignmentInfoDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.get<GeoMarketingAssignmentInfoDto[]>(dns + "geoMarketing/visit", options);
  }

  deleteGeoMarketingAssignment(geoMarketingAssignmentId: number): Observable<boolean> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this.http.delete<boolean>(dns + "geoMarketing/visit/" + geoMarketingAssignmentId, options);
  }

  exportGeoMarketing(geoMarketing: any): Observable<any> {
    let headers = createAuthorizationHeader();
    return this.http.post(dns + "geoMarketing/export", geoMarketing, { headers: headers, responseType: 'blob' as 'json' });
  }

}
