// shared-pipes.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizefirstPipe } from './capitalizefirst.pipe'; // Adjust the import path as necessary

@NgModule({
  declarations: [CapitalizefirstPipe], // Declare the pipe
  imports: [CommonModule], // Import CommonModule for Angular features
  exports: [CapitalizefirstPipe], // Export the pipe to make it available to other modules
})
export class SharedPipesModule {}
