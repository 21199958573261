import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { createAuthorizationHeader } from '../../../utils/security/headers';
import { dns } from '../../../global.config';
import { HttpClient } from '@angular/common/http';

export class Intervetion {
    id: number;

    type: 'REQUEST' | 'INPROGRESS' | 'CARRYOUT' | 'REJECTED';

    idBoitiers: string;

    content: string;

    request: string;

    response: string;

    priority: number;

    createdAt: Date;

    submitAt: Date;

    constructor() {
        this.priority = 1;
    }
}

@Injectable()
export class InterventionService {

    constructor(private _http: HttpClient) { }

    getAll(page: number, size: number): Observable<any> {
        let headers = createAuthorizationHeader();
        return this._http
            .get(dns + 'interventions?page=' + page + '&size=' + size, { headers: headers });
    }

    add(intervetion: Intervetion) : Observable<any> {
        let headers = createAuthorizationHeader();
        return this._http
            .post(dns + 'interventions', intervetion, { headers: headers });
    }

    update(intervetion: Intervetion) : Observable<any> { 
        let headers = createAuthorizationHeader();
        return this._http
            .put(dns + 'interventions', intervetion, { headers: headers });
    }

    delete(id: number): Observable<Boolean> {
        let headers = createAuthorizationHeader();
        return this._http
            .delete<Boolean>(dns + 'interventions/' + id, { headers: headers });
    }

}
