import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepenseChartComponent } from './depense-chart.component';
import { ToastrModule } from 'ngx-toastr'; // For toast notifications
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'; // For dropdowns, if needed
import { ModalModule } from 'ngx-bootstrap/modal'; // For modals
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [DepenseChartComponent], // Declare the component
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Import Toastr module
    ModalModule.forRoot(), // Import Modal module
    BsDatepickerModule.forRoot(), // Import Datepicker module
    HighchartsChartModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    FormsModule,


  ],
  exports: [DepenseChartComponent] // Export the component for use in other modules
})
export class DepenseChartModule {}
