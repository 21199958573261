import {Injectable} from '@angular/core';
import {GroupDashbordDto} from 'src/app/client-management/parc-management';
import {Observable} from 'rxjs';
import {DateInterval} from 'src/app/shared/model';
import {dns} from 'src/app/global.config';
import {createAuthorizationHeader} from 'src/app/utils/security/headers';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class DepenseChartService {

  _getDepenseInfo: GroupDashbordDto[] = [];

  constructor(private _http: HttpClient) { }

  /** Return dashbord info of all the groups  */
  public getDepenseInfo(
    date: DateInterval,
    groupIds: string,
    IsByParent: boolean
  ): Observable<GroupDashbordDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http
      .post<GroupDashbordDto[]>(dns + 'groupes/getAllExpensesByGroup?type=ALL&IsByParent=' + IsByParent + '&groupIds=' + groupIds, date, options);
  }

  getDepenseDetails(IdGroup: number, date: DateInterval, IsByParent: boolean): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<any>(dns + 'groupes/allCostsByGroupId/' + IdGroup + '?IsByParent=' + IsByParent, date, options);
  }

  get getDepenseInfoResult(): GroupDashbordDto[] {
    return this._getDepenseInfo;
  }

  // Setter
  set setDepenseInfo(depenseInfo: GroupDashbordDto[]) {
    this._getDepenseInfo = depenseInfo;
  }
}
