import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RentalAgreementManagementComponent } from './rental-agreement-management.component';
import { FormsModule } from '@angular/forms'; // Import FormsModule if using NgForm
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    RentalAgreementManagementComponent // Declare the RentalAgreementManagementComponent
  ],
  imports: [
    CommonModule, // Import necessary Angular modules
    FormsModule, // Import FormsModule for template-driven forms
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),

  ],
  exports: [
    RentalAgreementManagementComponent // Export the RentalAgreementManagementComponent for use in other modules
  ]
})
export class RentalAgreementManagementModule { }
