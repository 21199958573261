import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VidangeFormComponent } from './vidange-form.component'; // Adjust the path as necessary
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { OilChangeService } from '../../providers/oil-change-service'; // Adjust the path as necessary
import { ProviderService } from '../../providers/provider-service'; // Adjust the path as necessary
import { MultiTypeService } from '../../providers/multi-type-service'; // Adjust the path as necessary
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    VidangeFormComponent // Declare the VidangeFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(), // Import BsDatepickerModule for date picking
    FormsModule,
    MatCheckboxModule,

  ],
  providers: [
    OilChangeService, // Provide OilChangeService
    ProviderService, // Provide ProviderService
    MultiTypeService // Provide MultiTypeService
  ],
  exports: [
    VidangeFormComponent // Export VidangeFormComponent for use in other modules
  ]
})
export class VidangeFormModule { }
