import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoValuePipe } from './no-value.pipe'; // Adjust the path accordingly

@NgModule({
  declarations: [
    NoValuePipe // Declare your NoValuePipe here
  ],
  imports: [
    CommonModule // Import necessary Angular modules, e.g., CommonModule
  ],
  exports: [
    NoValuePipe // Export the pipe so it can be used in other modules
  ]
})
export class NoValuePipeModule { }
