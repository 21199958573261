import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if you are using ngModel or form features
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule if you are using ToastrService
import { TowingSettingsComponent } from './towing-settings.component'; // Adjust the path if needed
import { NgxLoadingModule } from 'ngx-loading';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    TowingSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule, // Include if using forms or ngModel
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    EmailManagerModule,
    CronSettingsModule,
    MatSlideToggleModule
  ],
  exports: [
    TowingSettingsComponent // Export the component if needed in other modules
  ]
})
export class TowingSettingsModule { }
