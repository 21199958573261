import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LossesComponent } from './losses.component'; // Adjust the path if necessary
import { RouterModule } from '@angular/router'; // For routing functionalities
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications

@NgModule({
  declarations: [
    LossesComponent // Declare the LossesComponent here
  ],
  imports: [
    CommonModule,
    RouterModule, // Include RouterModule if you are using Angular routing
    ToastrModule.forRoot() // Initialize Toastr module
  ],
  exports: [
    LossesComponent // Export the LossesComponent if needed in other modules
  ]
})
export class LossesModule { }
