<form #providerForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Nom *</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" [(ngModel)]="provider.name" name="name" #providerName="ngModel" class="form-control input-sm" placeholder="Nom" required maxlength="50">
            </div>
            <label class="col-sm-2 control-label">Type *</label>
            <div class="col-lg-4 col-md-4">
                <select class="form-control input-sm" [(ngModel)]="provider.type" name="providerType" #providerType="ngModel" required>
                    <option *ngFor="let type of providerTypes" [selected]="type.name == provider.type" [value]="type.name">{{type.label}}</option>
                </select>
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Email </label>
            <div class="col-lg-4 col-md-4">
                <input type="text" [(ngModel)]="provider.email" name="email" #email="ngModel" class="form-control input-sm" placeholder="Email" maxlength="100">
            </div>
            <label class="col-sm-2 control-label">Adresse</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" [(ngModel)]="provider.adresse" name="adresse" #adresse="ngModel" class="form-control input-sm" placeholder="Adresse" maxlength="50">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Téléphone</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" [(ngModel)]="provider.telephone" name="telephone" #telephone="ngModel" class="form-control input-sm" placeholder="Téléphone" maxlength="10">
            </div>
            <label class="col-sm-2 control-label">Code TVA</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" [(ngModel)]="provider.codeTVA" name="codeTVA" #codeTVA="ngModel" class="form-control input-sm" placeholder="Code TVA" maxlength="50">
            </div>
        </div>

        <div class="box-footer">
            <button type="submit" *ngIf="mode == 'ADD'" [disabled]="!providerForm.form.valid" class="btn btn-info pull-right" (click)="onAdd(providerForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
            <button type="submit" *ngIf="mode == 'UPDATE'" [disabled]="!providerForm.form.valid" class="btn btn-info pull-right" (click)="onUpdate(providerForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
            <button type="reset" class="btn btn-default" (click)="providerModal.hide()">Annuler</button>
        </div>
    </div>
</form>