<ngx-loading
  [show]="loader"
  [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"
></ngx-loading>

<div class="mt-2 mb-2 text-center well bgg">
  <h4
    style="
      font-family: 'Inconsolata', monospace;
      font-weight: bold;
      color: white;
    "
  >
    <i class="fa fa-plus-circle add"></i>
    <span> Suivi - Canal</span>
  </h4>
</div>
<div class="row" style="font-family: 'Inconsolata', monospace">
  <section class="content col-md-12 groups-container">
    <div class="row" style="margin-top: 32px">
      <div class="col-md-12" style="margin-top: -10px">
        <div class="box" style="border-radius: 0px; min-height: unset">
          <div class="box-body">
            <form class="form-horizontal" #trackingForm="ngForm">
              <div class="form-group row">
                <label class="col-sm-1 control-label grp-lbl">Canal</label>
                <div class="col-sm-2 grp-slct">
                  <select
                    class="form-control input-sm"
                    name="canalType"
                    [(ngModel)]="selectedCanal"
                    required
                    (ngModelChange)="onCanalChange()"
                  >
                    <option
                      *ngFor="let canal of canalService?.canals"
                      [ngValue]="canal"
                    >
                      {{ canal?.name | capitalizefirst }}
                    </option>
                    <option [ngValue]="parc">Parc</option>
                  </select>
                </div>

                <label class="col-sm-1 control-label grp-lbl">Type</label>
                <div class="col-sm-2 grp-slct">
                  <select
                    class="form-control input-sm"
                    name="graphType"
                    [(ngModel)]="selectedType"
                    required
                    (ngModelChange)="onTypeChange()"
                  >
                    <option value="vue">Vue Globale</option>
                    <option value="charges">Charges/Flotte</option>
                    <option value="maintenance">Coût de maintenance</option>
                    <option value="sinistre">Sinistre</option>
                  </select>
                </div>

                <label class="col-sm-1 control-label grp-lbl">Année</label>
                <div class="col-sm-2 grp-slct">
                  <select
                    class="form-control input-sm"
                    [(ngModel)]="selectedYear"
                    (ngModelChange)="onYearChange()"
                    name="selectedYear"
                    required
                  >
                    <option
                      style="font-size: 14px"
                      *ngFor="let year of years"
                      [value]="year.id"
                    >
                      {{ year.name }}
                    </option>
                  </select>
                </div>

                <ng-container>
                  <div class="col-sm-1 form-btns">
                    <button
                      type="button"
                      class="btn btn-primary btn-flat btn-sm"
                      [disabled]="!trackingForm.form.valid || loading"
                      (click)="loadDataBySelectedType()"
                      title="Valider"
                    >
                      <b
                        >Valider <i class="fa fa-play" aria-hidden="true"></i
                      ></b>
                    </button>
                  </div>
                </ng-container>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedType == 'charges'">
      <div class="row">
        <div class="col-md-5">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px; margin-top: 8px">
                  <i class="fa fa-spinner fa-spin"></i> Chargement ...
                </div>
                <div style="overflow-x: auto">
                  <highcharts-chart [options]="monthlyChartOption"></highcharts-chart>
                  <!-- <app-chart-multiple
                    [options]="monthlyChartOption"
                  ></app-chart-multiple> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div>
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px; margin-top: 8px">
                  <i class="fa fa-spinner fa-spin"></i> Chargement ...
                </div>
                <div style="overflow-x: auto">
                  <highcharts-chart [options]="options"></highcharts-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loading" style="font-size: 12px; margin-top: 8px">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <div style="overflow-x: auto">
                <div>
                  <h2>Total Charges</h2>
                  <h4 class="danger" *ngIf="totalExpense">
                    {{ totalExpense | number:'1.0-2' }}
                  </h4>
                  <img
                    src="{{ imagesCarsDir }}money-bag.jpg"
                    class="card-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        selectedType == 'maintenance' ||
        selectedType == 'vue' ||
        selectedType == 'sinistre'
      "
    >
      <div class="row">
        <div [ngClass]="selectedType == 'vue' ? 'col-md-8' : 'col-md-5'">
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loading" style="font-size: 12px; margin-top: 8px">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <div style="overflow-x: auto">
                <highcharts-chart [options]="monthlyChartOption"></highcharts-chart>
              </div>
            </div>
          </div>
        </div>

        <ng-container
          *ngIf="selectedType == 'sinistre' || selectedType == 'maintenance'"
        >
          <!-- sinistre table -->
          <div class="col-md-4" *ngIf="selectedType == 'sinistre'">
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px; margin-top: 8px">
                  <i class="fa fa-spinner fa-spin"></i> Chargement ...
                </div>
                <div style="overflow-x: auto">
                  <table>
                    <tr class="skin-blue bgg">
                      <th>Mois</th>
                      <th>NB.Sinistre</th>
                      <th>Coût</th>
                    </tr>

                    <tr *ngFor="let month of months">
                      <th>{{ month?.name }}</th>
                      <ng-container
                        *ngIf="monthlySinisterCounts; else noMonthly"
                      >
                        <td>{{ monthlySinisterCounts[month.id] }}</td>
                      </ng-container>
                      <ng-template #noMonthly>
                        <td>0</td>
                      </ng-template>

                      <ng-container *ngIf="monthlySinisterCost; else noCost">
                        <td>
                          {{ monthlySinisterCost[month.id] | number:'1.0-2' }}
                        </td>
                      </ng-container>
                      <ng-template #noCost>
                        <td>0</td>
                      </ng-template>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- end sinistre table -->

          <!-- maintenance table -->
          <div class="col-md-4" *ngIf="selectedType == 'maintenance'">
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px; margin-top: 8px">
                  <i class="fa fa-spinner fa-spin"></i> Chargement ...
                </div>
                <div style="overflow-x: auto">
                  <table>
                    <tr class="skin-blue bgg">
                      <th>Mois</th>
                      <th>Véhicules</th>
                      <th>Coût</th>
                      <th>Moyenne</th>
                    </tr>

                    <tr *ngFor="let month of months">
                      <th>{{ month?.name }}</th>
                      <td>{{ month?.nbVehicules }}</td>
                      <ng-container
                        *ngIf="monthlyMaintenanceCosts; else noData"
                      >
                        <td>
                          {{ monthlyMaintenanceCosts[month.id] | number:'1.0-2' }}
                        </td>
                        <td *ngIf="month?.nbVehicules != 0">
                          {{
                            monthlyMaintenanceCosts[month.id] /
                              month.nbVehicules
                              | number : "1.2-2"
                          }}
                        </td>
                        <td *ngIf="month?.nbVehicules == 0">---</td>
                      </ng-container>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- end maintenance table -->

          <div class="col-md-3">
            <div class="box box-info collapsed-box">
              <div class="box-header with-border text-center">
                <div *ngIf="loading" style="font-size: 12px; margin-top: 8px">
                  <i class="fa fa-spinner fa-spin"></i> Chargement ...
                </div>
                <div style="overflow-x: auto">
                  <div *ngIf="selectedType == 'sinistre'">
                    <h2>Sinistres</h2>
                    <h4 class="danger">{{ monthlySinisterCounts?.total }}</h4>
                    <img
                      src="{{ imagesCarsDir }}car-collision.png"
                      class="card-image"
                    />
                  </div>
                  <div *ngIf="selectedType == 'maintenance'">
                    <h2>Maintenance</h2>
                    <h4 class="danger" *ngIf="monthlyMaintenanceCosts">
                      {{ monthlyMaintenanceCosts?.total | number:'1.0-2' }}
                    </h4>
                    <img src="{{ imagesCarsDir }}cog.jpg" class="card-image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="selectedType == 'vue'" class="col-md-4">
          <div class="box box-info collapsed-box">
            <div class="box-header with-border text-center">
              <div *ngIf="loading" style="font-size: 12px; margin-top: 8px">
                <i class="fa fa-spinner fa-spin"></i> Chargement ...
              </div>
              <div style="overflow-x: auto">
                <table>
                  <tr class="skin-blue bgg">
                    <th style="width: 68px">Mois</th>
                    <th>Véhicules</th>
                    <th>C.A</th>
                    <th>C.A/Véhicules</th>
                  </tr>

                  <tr *ngFor="let month of months">
                    <th>{{ month?.name }}</th>
                    <td>{{ month?.nbVehicules }}</td>
                    <ng-container *ngIf="monthlyDriverCharge; else noData">
                      <td>
                        {{ monthlyDriverCharge[month.id] | number:'1.0-2' }}
                      </td>
                      <td *ngIf="month?.nbVehicules != 0">
                        {{
                          monthlyDriverCharge[month.id] / month?.nbVehicules | number:'1.0-2' }}
                      </td>
                      <td *ngIf="month?.nbVehicules == 0">---</td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
</div>

<ng-template #noData>
  <td>0</td>
  <td></td>
</ng-template>
