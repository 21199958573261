import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PositionByPoiComponent } from './position-by-poi.component';
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { LeafletModule } from 'src/app/utils/leaflet/leaflet.module';
import { VehiculeAccordingPoiModule } from '../../overview/vehicule-according-poi/vehicule-according-poi.module';

@NgModule({
  declarations: [
    PositionByPoiComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    VehiculeAccordingPoiModule,
    LeafletModule,

  ],
  providers: [
    GeocodingService,
    MapService,
    DecimalPipe,
    DatePipe
  ],
  exports: [
    PositionByPoiComponent
  ]
})
export class PositionByPoiModule { }
