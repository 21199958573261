import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ChildDetail } from '../../../../../model/km-jour.model';
import { ToolKmJourService } from '../../../../../services/tool-km-jour.service';
import { Subscription } from 'rxjs';
import { DataManagementService, Vehicule } from 'src/app/client-management/data-management';
import { RealtimeService } from 'src/app/client-management/realtime/services/realtime.service';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { RealtimeProcessingService } from 'src/app/client-management/realtime/services/real-time-processing.service';

@Component({
  selector: 'km-jour-trajet',
  templateUrl: './km-jour-trajet.component.html',
  styleUrls: ['./km-jour-trajet.component.scss'],
})
export class KmJrTrajetComponent implements OnInit, OnDestroy {
  childDetail: ChildDetail;
  selectedVehicule: Vehicule;
  speed: number = 1;
  position: number = 0;
  range: number = 10;
  speedSimulation: number = 1;
  simulation: boolean;
  destination: string;
  hidChart: boolean= false;

  childSubscription: Subscription;
  selectedVehiculeSubs: Subscription;
  locationSubs: Subscription;
  simulationSubs: Subscription;
  speedSubs: Subscription;


  destinationRoute: string;
  destinationSubs: Subscription;
  dateSubs: Subscription;

  constructor(
    private toolKmJourService: ToolKmJourService,
    private mapService: MapService,
    private realTimeService: RealtimeService,
    private realtimeProcessingService: RealtimeProcessingService,
    public dataManagementService: DataManagementService,

  ) {}

  ngOnInit() {
    this.initializeRealTime();
    this.setupSubscriptions();
  }
  initializeRealTime() {
    this.realTimeService.stopRealTime();
    this.realTimeService.clearRealTime();

  }

  setupSubscriptions() {
    this.setupChildSubscription();
    this.setupLocationSubscription();
    this.setupSimulationSubscription();
    this.setUpSpeedSubscreption();
    this.setUpDestination();
    this.setUpDateSubscreption();
  }
  setUpDateSubscreption() {
    this.dateSubs = this.realtimeProcessingService
      .getStartingDate()
      .subscribe((date) => {
        this.childDetail.beginPathTime = +date;
      });

  }

  loadVehicule(points: any[]) {
    this.selectedVehiculeSubs = this.realTimeService
      .getVehicule()
      .subscribe((v) => {
        this.selectedVehicule = v;
        this.realtimeProcessingService.initializeMarker(
          v.device.idDevice,
          points
        );
      });
  }
  processMileageData(childTrajet: any) {
    this.mapService.processMileageData(
      childTrajet.points,
      childTrajet.stops,
      this.realTimeService.imagesDir + 'stop_smal.png',
      '',
      Math.ceil(childTrajet.points.length / 500)
    );
    this.range = childTrajet.points.length;
    this.mapService.setFlagsIcons(childTrajet.points);
  }

  toggleMarkersVisibility(simulation: any) {
    if (simulation) {
      this.mapService.hideMarkers();
    } else {
      this.mapService.showMarkers();
    }
  }
  @HostListener('window:keydown', ['$event'])
  async onWindowKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case ' ': {
        if (this.simulation) {
          await this.stopSimulation();
        } else {
          await this.startSimulation();
        }
        break;
      }
      case 'ArrowRight': {
        await this.startDragin(false);
        let position = this.position + 1;
        if(position < this.childDetail.points.length ){
          await this.onRangeValueChange(++this.position);
        }
        break;
      }
      case 'ArrowLeft': {
        let position = this.position - 1;
        await this.startDragin(false);
        if(position >= 0 ){
          await this.onRangeValueChange(--this.position);
        }
        break;
      }
      case 'ArrowDown': {
        await this.slowDownSimulation();
        break;
      }
      case 'ArrowUp': {
        await this.speedUpSimulation();
        break;
      }
    }
  }
  async startSimulation() {
    this.realtimeProcessingService.setInitalSimulation(true);
    this.realtimeProcessingService.move(this.childDetail.points);
  }
  async stopSimulation() {
    this.realtimeProcessingService.setInitalSimulation(false);
    this.realtimeProcessingService.stopRealTimeProcessing();
  }
  async restartSimulation() {
    this.realtimeProcessingService.setInitalSimulation(true);
    this.realtimeProcessingService.restartSimulation(
      this.selectedVehicule.device.idDevice,
      this.childDetail.points
    );
  }

  async speedUpSimulation() {
    this.speedSimulation = this.speedSimulation * 2;
    this.realtimeProcessingService.speedUp();
  }

  async slowDownSimulation() {
    this.speedSimulation = this.speedSimulation / 2;
    this.realtimeProcessingService.slowDown();
  }
  async startDragin(clicked: boolean) {
    this.realtimeProcessingService.startingTheProcess();
  }

  async onRangeValueChange(newValue: number) {
    this.position = newValue;
    this.realtimeProcessingService.updateMarker(
      this.childDetail.points[newValue],
      newValue
    );
  }
  async endDragin(clicked: boolean) {
    this.realtimeProcessingService.endingTheProcess(this.childDetail.points);
  }

  displayChart(){
    this.hidChart= !this.hidChart;
    this.realtimeProcessingService.settHidingChart(this.hidChart);
  }

  setupLocationSubscription() {
    this.locationSubs = this.realtimeProcessingService
      .getLocation()
      .subscribe((location) => {
        this.position = location;
      });
  }

  setupChildSubscription() {
    this.childSubscription = this.toolKmJourService
      .getChildPath()
      .subscribe((childTrajet) => {
        this.childDetail = childTrajet;
        this.realtimeProcessingService.setInitalSimulation(false);
        this.loadVehicule(childTrajet.points);
        this.processMileageData(childTrajet);
      });
  }

  setupSimulationSubscription() {
    this.simulationSubs = this.realtimeProcessingService
      .getInitalSimulation()
      .subscribe((simulation) => {
        this.simulation = simulation;
        this.toggleMarkersVisibility(simulation);
      });
  }

  setUpSpeedSubscreption() {
    this.speedSubs = this.realtimeProcessingService
      .getSpeed()
      .subscribe((speed) => {
        this.speed = speed;
      });
  }
  setUpDestination() {
    this.destinationSubs = this.toolKmJourService
      .getDestination()
      .subscribe((des) => {
        this.destinationRoute = des;
      });
  }

  
  ngOnDestroy() {
    this.mapService.controlMarkers(this.selectedVehicule.device.idDevice, true);
    if(this.speedSubs){this.speedSubs.unsubscribe();}
    if(this.locationSubs){this.locationSubs.unsubscribe();}
    if(this.simulationSubs){this.simulationSubs.unsubscribe();}
    if(this.destinationSubs){this.destinationSubs.unsubscribe();}
    if(this.childSubscription){this.childSubscription.unsubscribe();}
    if(this.selectedVehiculeSubs){this.selectedVehiculeSubs.unsubscribe();}
    if(this.dateSubs){this.dateSubs.unsubscribe();}
    this.realtimeProcessingService.settHidingChart(false);

    this.realtimeProcessingService.endSimulation();
    this.mapService.clearPolylines();
    this.mapService.removeMarkersAndClearArrays();
    this.realTimeService.setBarToolStatus(true);
    this.realtimeProcessingService.setCompleted();
  }



}
