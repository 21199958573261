import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { ValidationFormComponent } from './validation-form.component'; // Adjust the path if necessary
import { ValidationService } from './validation.service'; // Import your validation service
import { NgxLoadingModule } from 'ngx-loading';
import { EmailManagerModule } from 'src/app/client-management/alert-management/alert-settings/email-manager/email-manager.module';

@NgModule({
  declarations: [
    ValidationFormComponent // Declare your ValidationFormComponent here
  ],
  imports: [
    CommonModule, // Import CommonModule for common directives
    FormsModule, // Import FormsModule for form handling
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    EmailManagerModule

  ],
  providers: [
    ValidationService // Provide your ValidationService
  ],
  exports: [
    ValidationFormComponent // Export if you want to use it in other modules
  ]
})
export class ValidationFormModule { }
