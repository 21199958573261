<section class="content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
  <div class="row" style="margin-left: 0;width: 100%;">
    <div class="datatable-container" id="datatable-container" style="margin-top: 20px;width: 98%;">
      <div class="col-md-12 datatable-container-col">
        <div class="box" style="border-radius: 0px;">
          <div class="overlay" *ngIf="!isResultLoaded">
            <i class="fa fa-refresh fa-spin"></i>
          </div>
          <div class="box-body">
            <form class="form-horizontal form-class">
              <div class="input-container">
                <label class="col-sm-1 control-label grp-lbl">Groupes</label>
                <div class="col-sm-1 grp-slct">
                  <select class="form-control input-sm" [(ngModel)]="selectedGroup" name="groups"
                    (ngModelChange)="chooseGroup($event)">
                    <option *ngFor="let group of groups" [ngValue]="group">
                      <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | truncate:[30]}} /
                      </span> {{group.nom}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="input-container">
                <label class="col-sm-1 control-label vhcl-lbl">Vehicule</label>
                <div class="col-sm-1 vhcl-slct">
                  <select class="form-control input-sm" [(ngModel)]="selectedVehicule" name="vehicules"
                    (ngModelChange)="chooseVehicule($event)">
                    <option *ngFor="let vehicule of selectedGroup.vehicules" [ngValue]="vehicule">
                      {{dataManagementService.getVehiculeName(vehicule)}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="input-container">

                <label class="col-sm-1 control-label dd-lbl">Du</label>
                <div class="col-sm-2 dd-npt" style="width: 170px;">
                  <div class="input-group">
                    <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                      [(ngModel)]="startDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                      placement="bottom" [maxDate]="endDate">
                    <span class="input-group-addon" style="border-radius: 0 5px 5px 0;"><i
                        class="fa fa-clock-o"></i></span>
                  </div>
                </div>
              </div>
              <div class="input-container">
                <label class="col-sm-1 control-label df-lbl">Au</label>
                <div class="col-sm-2 df-npt" style="width: 170px;">
                  <div class="input-group">
                    <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm', containerClass: 'theme-dark-blue'}"
                      placement="bottom" [minDate]="startDate">
                    <span class="input-group-addon" style="border-radius: 0 5px 5px 0;"><i
                        class="fa fa-clock-o"></i></span>
                  </div>
                </div>
              </div>

              <!-- <div class="col-sm-2 sl-npt">
                                  <angular2-multiselect name="selectItems" [data]="dropdownList"
                                      [(ngModel)]="selectItems" [settings]="dropdownSettings"
                                      (onSelect)="onItemSelect($event)">
                                  </angular2-multiselect>
                              </div> -->
              <!--  -->
              <div style="width: 50px;">
                <button type="button" (click)="displayChart()" class="btn btn-success " title="Valider"
                  style="padding: 6px;">
                  <i class="fa fa-search pull-left" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12 charts our-chart">
        <div id="drivingQuality" style="zoom: 1.0526;"></div>
      </div>
    </div>
  </div>
</section>
