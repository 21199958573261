import {
  Component,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormArray,
  Validators,
} from '@angular/forms';
import { SupervisorVehicleDTO, Vehicule } from '../../../canals';
import {
  DataManagementService,
  Group,
} from 'src/app/client-management/data-management';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CanalService } from '../../../canal.service';
import { ToastrService } from 'ngx-toastr';
import { SupervisorComponent } from '../supervisor.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-supervisor-form',
  templateUrl: './supervisor-form.component.html',
  styleUrls: ['./supervisor-form.component.css'],
})
export class SupervisorFormComponent implements OnInit, OnChanges, OnDestroy {
  supervisorForm!: UntypedFormGroup; // Assurez-vous que FormGroup est correctement typé
  searchControl = new UntypedFormControl();
  filteredVehicules!: Observable<Vehicule[]>;

  superviseur!: SupervisorVehicleDTO; // Utilisez l'opérateur non-null assertion pour indiquer que ces propriétés seront initialisées
  vehicules: Vehicule[] = [];
  selectedVehicule: Vehicule[] = [];
  vehiculeDropdownSettings = {};
  groupDropdownSettings :any;

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private canalService: CanalService,
    public dialogRef: MatDialogRef<SupervisorFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { superviseur: SupervisorVehicleDTO; vehicules: Vehicule[] }
  ) {}

  close(status: boolean) {
    this.dialogRef.close(status);
  }

  ngOnInit() {
    this.vehiculeDropdownSettings = {
      singleSelection: false,
      idField: 'idVehicule',
      textField: 'matricule',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 32,
      allowSearchFilter: true,
    };
    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idVehicule',
      textField: 'matricule',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.superviseur = this.data.superviseur;
    this.vehicules = this.data.vehicules;
    this.selectedVehicule = this.superviseur.vehicules;

    this.createFormSuperviseur();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Vous pouvez gérer les changements si nécessaire
  }

  createFormSuperviseur() {
    // Initialiser le FormGroup avec des valeurs par défaut
    this.supervisorForm = this.fb.group({
      vehicules: this.fb.array(this.selectedVehicule.map(v => this.createVehiculeGroup(v))),
    });
  }

  createVehiculeGroup(vehicule: Vehicule): UntypedFormGroup {
    return this.fb.group({
      idVehicule: [vehicule.idVehicule, Validators.required],
      matricule: [vehicule.matricule, Validators.required],
      idDevice: [vehicule.idDevice],
      mark: [vehicule.mark],
    });
  }

  onSubmit() {
    this.getVehiculeSelected();
    this.superviseur.vehicules = this.selectedVehicule;

    this.canalService.updateSuperviseur(this.superviseur).subscribe(
      () => {
        this.toastr.success('Superviseur bien modifié !');
        this.dialogRef.close(true);
      },
      (error) => {
        this.toastr.error('Erreur lors de la modification du superviseur !', 'Erreur !', {
          closeButton: true,
          positionClass: 'toast-top-center',
          timeOut: 3000,
        });
        console.error('Error fetching superviseurs', error);
      }
    );
  }

  getVehiculeSelected() {
    const idVehiculeList = this.selectedVehicule.map((v) => v.idVehicule);
    this.selectedVehicule = this.vehicules
      .filter((v) => idVehiculeList.includes(v.idVehicule))
      .map((v) => ({
        idVehicule: v.idVehicule,
        matricule: v.matricule,
        idDevice: v.idDevice,
        mark: v.mark,
      }));
  }

  ngOnDestroy(): void {
    this.dialogRef.close(false);
  }
}
