<ngx-loading
  [show]="loader"
  [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"
></ngx-loading>

<div
  class="row"
  style="font-family: 'Inconsolata', monospace; position: relative"
>
  <div
    class="col-md-12"
    style="width: 100% !important; position: absolute; padding: 12px 27px"
  >
    <div
      class="box box-primary1 groups-container"
      style="margin-right: 50px; width: 672px; height: 55px"
    >
      <div class="box-header" style="margin-inline-start: 10px">
        <form class="form-horizontal">
          <div class="form-group row">
            <label class="col-sm-2 control-label">Groupe</label>
            <div class="col-sm-3">
              <select
                class="form-control input-sm"
                name="groups"
                [(ngModel)]="selectedGroup"
                (ngModelChange)="chooseGroup($event)"
                style="width: 70px"
              >
                <option
                  *ngFor="let group of groups"
                  [ngValue]="group"
                  [selected]="selectedGroup.idGroupe === group.idGroupe"
                  style="font-size: 14px"
                >
                  <span *ngIf="group.nameGroupParent"
                    >{{
                      group.nameGroupParent | capitalizefirst | truncate : [30]
                    }}
                    /
                  </span>
                  {{ group.nom | capitalizefirst }}
                </option>
              </select>
            </div>

            <label class="col-sm-2 control-label">Type</label>
            <div class="col-sm-3">
              <select
                class="form-control input-sm"
                name="subMark"
                [(ngModel)]="selectedType"
                (ngModelChange)="chooseTypeSub(selectedType)"
                style="width: 70px"
              >
                <ng-container *ngFor="let type of types">
                  <ng-container
                    *ngIf="isTypeAvailable(type, selectedGroup?.idGroupe)"
                  >
                    <option [ngValue]="type" [selected]="selectedType === type">
                      {{ type }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </div>
            <div class="row" style="margin-top: -22px">
              <div class="col-sm-1">
                <p></p>
                <i class="fa fa-object-group icons"></i>
                <mat-checkbox
                  [(ngModel)]="selectedGroup.enbaleGlobalAlert"
                  title="Groupe"
                  name="isByGroup"
                  (change)="onApplyGlobalAlert()"
                ></mat-checkbox>
              </div>
              <div class="col-sm-1">
                <p></p>
                <i class="fa fa-object-ungroup icons"></i>
                <!--                <mat-checkbox  title="Type" name="isByType" [(ngModel)]="isByType" (change)="onToggleChange($event)"></mat-checkbox >-->
                <mat-checkbox
                  name="isByType"
                  title="isByType"
                  (change)="onApplyGlobalAlertOnAllVehiclesByType($event)"
                ></mat-checkbox>
              </div>

              <div class="col-sm-1">
                <p></p>
                <i class="fa fa-car icons"></i>
                <mat-checkbox
                  name="isByAllVehicules"
                  title="Tous les véhicules"
                  (change)="onApplyGlobalAlertOnAllVehicles($event)"
                ></mat-checkbox>
              </div>
            </div>

            <label class="col-sm-2 control-label">Véhicule</label>
            <div class="col-sm-3">
              <select
                class="form-control input-sm"
                [(ngModel)]="selectedVehicule"
                name="vehicules"
                (ngModelChange)="chooseVehicule($event)"
                style="width: 80px"
              >
                <ng-container
                  *ngFor="let vehicule of filterVehiclesByGroupAndType()"
                >
                  <option [ngValue]="vehicule">
                    {{
                      dataManagementService.getVehiculeName(vehicule)
                        | capitalizefirst
                    }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>

<section class="map-container">
  <app-leaflet></app-leaflet>
</section>
