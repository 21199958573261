<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="row">

  <div class="col-md-12">

    <!-- Error Section !-->
    <ng-container *ngIf="error.errorMessage">
      <div class="col-md-12">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
            ×
          </button>
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          {{error.errorMessage}}
        </div>
      </div>
    </ng-container>

    <div class="container">
      <ng-container *ngIf="!loading">
        <div class=" mt-2 mb-2 text-center   well bgg">
          <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color: white;">
            <i class="fa fa-plus-circle add"></i>
            <span>Ajouter une validation </span>
          </h4>

        </div>
        <div class="col-lg-12 well formBC">
          <div class="row">
            <form #validationForm="ngForm" class="form-horizontal">
              <div class="col-sm-12">


                <!-- Num Bon de livraison  -->
                <div class="col-sm-6 form-group mrg">
                  <label>Num Bon de livraison <span class="req">*</span></label>
                  <input [(ngModel)]="validation.nbl" required name="nbl" type="number" placeholder="Num Bon de livraison"
                    class="form-control">
                </div>

                <div class="col-sm-6 form-group mrg">
                  <label>Client <span class="req">*</span></label>
                  <input [(ngModel)]="validation.client" required name="client" type="text" placeholder="client"
                    class="form-control">
                </div>

                <div class="col-sm-6 form-group mrg">
                  <label>Destination <span class="req">*</span></label>
                  <input [(ngModel)]="validation.destination" required name="destination" type="text" placeholder="destination"
                    class="form-control">
                </div>

                <div class="col-sm-6 form-group mrg">
                  <label>Produit</label>
                  <input [(ngModel)]="validation.produit" name="produit" type="text" placeholder="produit"
                    class="form-control">
                </div>

                <div class="col-sm-6 form-group mrg">
                  <label>Quantité </label>
                  <input [(ngModel)]="validation.quantite" name="quantite" type="number" placeholder="quantite" min="0"
                    class="form-control">
                </div>

                <div class="col-sm-6 form-group mrg">
                  <label>Matricule <span class="req">*</span></label>
                  <input [(ngModel)]="validation.matricule" required name="Matricule" type="text" placeholder="Matricule"
                    class="form-control">
                </div>

                <div class="col-sm-6 form-group mrg">
                  <label>Nom Chauffeur <span class="req">*</span></label>
                  <input [(ngModel)]="validation.nomChauffeur" required name="nomChauffeur" type="text" placeholder="Nom Chauffeur"
                    class="form-control">
                </div>

                <div class="col-sm-6 form-group mrg">
                  <label>CIN Chauffeur</label>
                  <input [(ngModel)]="validation.cinChauffeur" name="cinChauffeur" type="text" placeholder="CIN Chauffeur"
                    class="form-control">
                </div>

                <div class="col-sm-6 form-group mrg">
                  <label>Tél Chauffeur <span class="req">*</span></label>
                  <input [(ngModel)]="validation.telChauffeur" required name="telChauffeur" type="text" placeholder="Tél Chauffeur"
                    class="form-control">
                </div>

                <div class="col-sm-12 form-group mrg">
                  <app-email-manager [showActiveSectionOfAllVehicules]="false" [showActiveSection]="false" [emailMode]="true"
                    [emailsHash]="validation.emails" (onChange)="onEmailChange($event)">
                  </app-email-manager>
                </div>

              </div>
              <div class="box-footer">
                <button (click)="addValidation()" class="btn btn-info pull-right"
                  [disabled]="!validationForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
                </button>
                <button class=" btn btn-dark" (click)="cancel()">
                  Annuler
                </button>
              </div>
            </form>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div>
