import { Component, Input, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { AffectationInfo, LocationRealise } from '../../affectations';
const Highcharts22 = require('highcharts');
require('highcharts/modules/exporting')(Highcharts22);
require('highcharts/modules/no-data-to-display')(Highcharts22);
require('highcharts/highcharts-more')(Highcharts22);

@Component({
  selector: 'app-maintenance-charge',
  templateUrl: './maintenance-charge.component.html',
  styleUrls: ['./maintenance-charge.component.css']
})
export class MaintenanceChargeComponent implements OnInit {

  value: number
  caRealise_: any[] = [];
  chargePfbInfoDto: AffectationInfo;
  maintenanceCostChart: Object | null = null;
  maintenanceCostChargeTimeLineChart: Object | null = null;

  globalLoading: boolean;

  selectedCharge: AffectationInfo = new AffectationInfo();
  dayDif: number = 0;
  objectif: number = 0;
  aRealiser: number = 0;
  realise: number = 0;

  @Input()
  set selectedCharges(chargePfb: AffectationInfo) {
    if (chargePfb) {
      this.selectedCharge = chargePfb;
    }
  }


  @Input()
  set maintenanceDayDifference(dayDifference: number) {
    this.dayDif = dayDifference;
  }

  @Input()
  set maintenance(maintenance: any[]) {
      this.caRealise_ = maintenance;
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.gaugeLocationCostChart();
  }

  constructor(public toastr: ToastrService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.gaugeLocationCostChart();
  }

  timeChart() {

    this.objectif = 0;
    this.aRealiser = 0;
    this.realise = 0;

    var data: { x: Date, y: number }[] = [];
    var index = 0;


    if (this.selectedCharge) {
      this.objectif = this.selectedCharge.budgetMaintenance / this.selectedCharge.nbrJours;
    }
    this.caRealise_.forEach(ca => {
      data.push({ x: ca.operationDate, y: ca.cost });
      if (index == this.caRealise_.length - 1) {
        this.realise = ca.cost;
        this.aRealiser = this.objectif - this.realise;
      }
      index++;
    })


    this.maintenanceCostChargeTimeLineChart = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      plotOptions: {
        enableMouseTracking: false
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: ' DH'
      },
      title: {
        text: 'Charge Maintenance',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 86400000,
        labels: {
          formatter: (): string => {
            return Highcharts22.dateFormat("%d/%m/%Y", this.value);
          }
        }


      },
      yAxis: {
        minRange: this.objectif,
        min: 0,
        plotLines: [{
          value: this.objectif,
          color: '#ff0000',
          dashStyle: 'shortdash',
          width: 2,
          zIndex: 4,
          label: {
            text: 'Budget',
            textAlign: 'center'
          }
        }],
      },
      series: [{
        findNearestPointBy: 'xy',
        name: 'Consommée',
        data: data,
        zones: [{
          value: this.objectif,
          color: 'green'
        }, {
          color: 'red'
        }]
      }]

    }, Highcharts22.chart('maintenanceChart', this.maintenanceCostChargeTimeLineChart);

  }


  gaugeLocationCostChart() {

    // Create the chart
    this.objectif = 0;
    this.aRealiser = 0;
    this.realise = 0;
    var color = '#008000';
    var caMaintenance = this.caRealise_;

    if (caMaintenance.length > 0) {
      this.objectif = (this.selectedCharge.budgetMaintenance / this.selectedCharge.nbrJours) * this.dayDif;
      let maintenance = caMaintenance[caMaintenance.length - 1].cost;

      this.aRealiser = this.objectif - maintenance;
      this.realise = maintenance;

      if (this.realise > this.objectif)
        color = '#c90000';
    }

    // this.maintenanceCostChart = {

    //   chart: {
    //     type: 'solidgauge',
    //     events: {
    //       load: function () {
    //         let yData = this.series[0].data[0].y;
    //         let xData = this.series[0].data[0].x;

    //         var chart = this;
    //         if (!chart.lbl) {
    //           chart.lbl = chart.renderer.label('', (this.chartWidth - 80) / 2, (this.plotHeight / 2) + 15)
    //             .attr({
    //               padding: 10,
    //               r: 10,
    //             })
    //             .css({
    //               color: '#000',
    //               fontSize: 13
    //             })
    //             .add();
    //         }

    //         chart.lbl
    //           .show()
    //           .attr({
    //             text: '<span style="font-size:15px;font-weight:bold;">' + this.series[0].name + '</span><br/><span style="font-size:13px;color:' + this.series[0].data[0].color + ';font-weight:bold;">' + Highcharts22.numberFormat(xData, 2) + 'DH</span><br/>'
    //               + '<span style="font-size:17px; color: ' + this.series[0].data[0].color + '; font-weight: bold">' + Highcharts22.numberFormat(yData, 1) + '%</span>',

    //           });

    //       }
    //     }
    //   },
    //   title: {
    //     text: 'Charge Maintenance ',
    //     align: 'center',
    //     style: {
    //       fontWeight: 'bold'
    //     }
    //   }, credits: {
    //     enabled: false
    //   },
    //   tooltip: {
    //     enabled: false,
    //     positioner: function (labelWidth) {
    //       return {
    //         x: (this.chart.chartWidth - labelWidth) / 2,
    //         y: (this.chart.plotHeight / 2) + 15
    //       };
    //     }
    //   },
    //   pane: {
    //     startAngle: 0,
    //     endAngle: 360,
    //     background: [{ // objectif
    //       outerRadius: '112%',
    //       innerRadius: '88%',
    //       backgroundColor: "#98c5f5",
    //       borderWidth: 0
    //     }, { // Réalisé
    //       outerRadius: '87%',
    //       innerRadius: '63%',
    //       backgroundColor: '#98c5f5',
    //       borderWidth: 0
    //     }]
    //   },
    //   yAxis: {
    //     min: 0,
    //     max: 100,
    //     lineWidth: 0,
    //     tickPositions: []
    //   },
    //   plotOptions: {
    //     solidgauge: {
    //       dataLabels: {
    //         enabled: false,
    //       },
    //       linecap: 'round',
    //       stickyTracking: false,
    //       rounded: true
    //     },
    //     series: {
    //       point: {
    //         events: {
    //           mouseOver: function () {
    //             var chart = this.series.chart;
    //             if (!chart.lbl) {
    //               chart.lbl = chart.renderer.label('', (this.chartWidth - 80) / 2, (this.plotHeight / 2) + 15)
    //                 .attr({
    //                   padding: 10,
    //                   r: 10,
    //                 })
    //                 .css({
    //                   color: '#000',
    //                   fontSize: 13
    //                 })
    //                 .add();
    //             }
    //             chart.lbl
    //               .show()
    //               .attr({
    //                 text: '<div style="width:100%;text-align:center;"><span style="font-size:15px;font-weight:bold;">' + this.name + '</span><br/><span style="font-size:13px;color:' + this.color + ';font-weight:bold;">' + Highcharts22.numberFormat(this.x, 2) + 'DH</span><br/>'
    //                   + '<span style="font-size:17px;color:' + this.color + ';font-weight:bold;">' + Highcharts22.numberFormat(this.y, 1) + '%</span>',
    //               });
    //           }
    //         }
    //       },
    //     }
    //   },
    //   series: [{
    //     name: 'Budget',
    //     data: [{
    //       name: 'Budget',
    //       color: '#007bff',
    //       radius: '112%',
    //       innerRadius: '88%',
    //       y: (this.objectif / this.objectif) * 100,
    //       x: this.objectif
    //     }]
    //   }, {
    //     name: 'Réalisé',
    //     data: [{
    //       name: 'Réalisé',
    //       color: color,
    //       radius: '87%',
    //       innerRadius: '63%',
    //       y: (this.realise / this.objectif) * 100,
    //       x: this.realise
    //     }]
    //   }],
    //   lang: {
    //     printChart: 'Imprimer',
    //     thousandsSep: ',',
    //     downloadPNG: 'Télécharger image PNG',
    //     downloadJPEG: 'Télécharger image JPEG',
    //     downloadPDF: 'Télécharger image PDF',
    //     downloadSVG: 'Télécharger image SVG',
    //     drillUpText: '◁ Retour',
    //     noData: 'Aucune donnée n\'est disponible'
    //   }
    // },Highcharts22.chart('maintenanceChart', this.maintenanceCostChart);
  }

}
