import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  GroupDashbordDto,
  DashbordGroupInfoDto,
  ParentData,
} from 'src/app/client-management/parc-management';
import { DepenseChartService } from './depense-chart.service';
import { DatePipe } from '@angular/common';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DateInterval } from 'src/app/shared/model';
import { ToastrService } from 'ngx-toastr';
import { GroupDto } from 'src/app/client-management/data-management';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
  selector: 'app-depense-chart',
  templateUrl: './depense-chart.component.html',
  styleUrls: ['./depense-chart.component.css'],
})
export class DepenseChartComponent implements OnInit {
  showModal: boolean = false;

  groupeStatistics: any[] = [];
  depenseDetails: DashbordGroupInfoDto;
  DEPENSES: any = null;
  DEPENSESModal: any = null;
  DEPENSESII: any = null;
  date: DateInterval = new DateInterval();
  endDate: Date = new Date();
  startDate: Date = new Date();
  globalLoading: boolean;
  Years: any[] = [];
  displayCalendar: boolean = false;
  IsByParent = false;
  isByMultipleGroups: boolean = false;
  groupIds: GroupDto[] = [];
  depensedata_: ParentData;

  @Input()
  groups: GroupDto[] = [];

  @Input() show = false;

  @ViewChild('deponseModal') deponseModal: ModalDirective;

  @Input()
  set depensedata(depensedata: ParentData) {
    this.depensedata_ = Object.assign(depensedata);
    if (this.depensedata_) {
      this.getDepenseInfo();
    }
    console.log("*********** aaaaa **********", this.depensedata_)

  }

  groupDropdownSettings = {};

  constructor(
    private depensechartservice: DepenseChartService,
    public toastr: ToastrService,
    private chartConfig: ChartConfigService
  ) {
    this.Years = this.getYears();
  }

  ngOnInit() {
    console.log("*********** HHHHHHHH **********", this.groups)
    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    // this.startDate = new Date(new Date().getFullYear(), 0, 1);
    // this.endDate = new Date(this.startDate.getFullYear(), 11, 31);
    // this.getDepenseInfo();
  }

  onValidate() {
    this.getDepenseInfo();
  }

  onChangeByMultipleGroups() {
    if (!this.isByMultipleGroups) {
      this.groupIds = [];
      this.getDepenseInfo();
    }
  }

  onChange(year: any) {
    if (year < 5) {
      this.displayCalendar = false;
      this.startDate = new Date(new Date().getFullYear() - year, 0, 1);
      this.endDate = new Date(this.startDate.getFullYear(), 11, 31);
      this.getDepenseInfo();
    } else {
      this.displayCalendar = true;
    }
  }

  onChangeType() {
    console.log("change : 2021");

    if (!this.displayCalendar) {
      this.getDepenseInfo();
    }
  }

  /**
   * Action change date début
   */
  onChangeStartDate() {
    if (this.endDate < this.startDate) {
      this.endDate = null;
    }
  }

  getYears() {
    return [
      { id: 0, name: 'Année En cours' },
      { id: 1, name: 'Année Antérieure ' },
      { id: 2, name: 'Année -2' },
      { id: 3, name: 'Année -3' },
      { id: 4, name: 'Année -4' },
      { id: 5, name: 'Date Personnaliser' },
    ];
  }

  getDepenseInfo() {
    this.globalLoading = true;
    if (this.depensedata_ && this.depensedata_.dateEnd != null) {
      this.date.startDate = this.depensedata_.datestart;
      this.date.endDate = this.depensedata_.dateEnd;
      this.IsByParent = this.depensedata_.IsByParents;
      if (this.IsByParent) this.groupIds = [];
      var groups = this.depensedata_.groups;
    }else{
      this.date.startDate = this.startDate.getTime();
      this.date.endDate = this.endDate.getTime();;
    }

    groups=""


    this.depensechartservice
      .getDepenseInfo(this.date, groups, this.IsByParent)
      .subscribe((res) => {
        this.globalLoading = false;
        this.groupeStatistics = res as GroupDashbordDto[];
        if (this.IsByParent) this.depenseParParentChart();
        else this.depenseChart();
        this.depenseChartTwo();

      });
  }

  getDepenseDetails(idGroupe: number) {
    this.date.startDate = this.startDate.getTime();
    this.date.endDate = this.endDate.getTime();
    this.depensechartservice
      .getDepenseDetails(idGroupe, this.date, this.IsByParent)
      .subscribe((res) => {
        this.depenseDetails = res;
      });
  }

  depenseParParentChart() {
    this.setHighCartsLang();
    /** Create Pie Chart( depense chart ) **/
    var series = [];
    var seriesData: any[] = [];
    var drilldownSeries: any[] = [];
    var that = this;
    var totalParent: number = 0;

    this.groupeStatistics =
      this.groupByFatherIdAndSumAllvalues() as GroupDashbordDto[];

    var parentsName = new Set(
      this.groupeStatistics.map((s) => {
        return s;
      })
    );
    for (var i = 0; i < this.groupeStatistics.length; i++) {
      totalParent +=
        this.groupeStatistics[i].totalDocumentAndDriverChargeAndMaintenance;
    }
    parentsName.forEach((group) => {
      var dptotalParent: number =
        group.totalDocumentAndDriverChargeAndMaintenance;
      /** SERIES **/
      seriesData.push({
        name: group.nom,
        y: (dptotalParent / totalParent) * 100,
        drilldown: group.idGroupe,
        x: dptotalParent,
      });
    });
    series.push({
      name: 'Groupe',
      colorByPoint: true,
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}  {point.x:,.2f} DH</span>   <br/>' +
          '<span style="color:{point.color}"> {point.y:.2f}%</span>',
      },
      data: seriesData,
    });

    this.DEPENSES = {
      chart: {
        type: 'pie',
        height: 260,
        events: {
          drilldown: function (e: any) {
            if (!e.seriesOptions) {
              that.depensechartservice
                .getDepenseDetails(e.point.drilldown, that.date, true)
                .subscribe((res) => {
                  var depenseDetails = res as any[];
                  var groupesName = new Set(
                    depenseDetails.map((s) => {
                      return s;
                    })
                  );
                  var total: number = 0;
                  for (var i = 0; i < res.length; i++) {
                    total +=
                      res[i].brakeMaintenance +
                      res[i].brakeMaintenance +
                      res[i].batteryMaintenance +
                      res[i].driverCharge +
                      res[i].extincteurs +
                      res[i].fuel +
                      res[i].insurance +
                      res[i].lavage +
                      res[i].leasing +
                      res[i].losses +
                      res[i].metologicalNotbook +
                      res[i].oilChange +
                      res[i].operationalCertification +
                      res[i].parking +
                      res[i].permitCirculation +
                      res[i].reparation +
                      res[i].roadTaxes +
                      res[i].tireMaintenance +
                      res[i].tonnageTax +
                      res[i].tool +
                      res[i].visit;
                  }
                  var data: any[];
                  groupesName.forEach((group) => {
                    var dptotal: number =
                      group.brakeMaintenance +
                      group.batteryMaintenance +
                      group.driverCharge +
                      group.extincteurs +
                      group.fuel +
                      group.insurance +
                      group.lavage +
                      group.leasing +
                      group.losses +
                      group.metologicalNotbook +
                      group.oilChange +
                      group.operationalCertification +
                      group.parking +
                      group.permitCirculation +
                      group.reparation +
                      group.roadTaxes +
                      group.tireMaintenance +
                      group.tonnageTax +
                      group.tool +
                      group.visit;
                    /** SERIES **/
                    data.push({
                      name: group.nom,
                      y: (dptotal / total) * 100,
                      drilldown: group.idGroupe,
                      x: dptotal,
                    });
                    var details = that.depenseDetailData(
                      new Array(group),
                      dptotal
                    );
                    drilldownSeries.push({
                      name: group.nom,
                      id: group.idGroupe,
                      data: details,
                    });
                  });

                  /** DATA **/
                  drilldownSeries.push({
                    colorByPoint: true,
                    tooltip: {
                      headerFormat:
                        '<span style="font-size:11px">{series.name}</span><br>',
                      pointFormat:
                        '<span style="color:{point.color}">{point.name}  {point.x:,.2f} DH</span>   <br/>' +
                        '<span style="color:{point.color}"> {point.y:.2f}%</span>',
                    },
                    name: e.point.name,
                    id: e.point.drilldown,
                    data: data,
                    keys: ['name', 'y', 'drilldown', 'x'],
                  });
                });
            }
          },
        },
      },
      title: {
        text: 'Dépenses Par Parent',
        style: {
          fontWeight: 'bold',
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
        },
        series: {
          dataLabels: {
            enabled: true,
            depth: 35,
            format: '{point.name}',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span>   <br/>',
      },
      series: series,
      drilldown: {
        series: drilldownSeries,
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        activeDataLabelStyle: {
          fontSize: '11px',
          fontWeight: 'bold',
          color: '#000000',
        },
      },
    };
    Highcharts.chart('pie', this.DEPENSES);
  }

  groupByFatherIdAndSumAllvalues(): any {
    const calculated = this.groupeStatistics.reduce((acc, item) => {
      let accItem = acc.find((ai: any) => ai.idGroupe === item.idGroupe);

      if (accItem) {
        accItem.count += item.count;
        accItem.total += item.total;
        accItem.totalDocumentAndDriverChargeAndMaintenance +=
          item.totalDocumentAndDriverChargeAndMaintenance;
        accItem.totalDocument += item.totalDocument;
        accItem.totalMaintenance += item.totalMaintenance;
        accItem.totalDriverCharge += item.totalDriverCharge;
      } else {
        acc.push(item);
      }

      return acc;
    }, []);
    return calculated;
  }

  depenseDetailData(res: any, e: any) {
    var data = [];
    var tireMaintenance = 0;
    var insurance = 0;
    var visit = 0;
    var roadTaxes = 0;
    var operationalCertification = 0;
    var permitCirculation = 0;
    var metologicalNotbook = 0;
    var fuel = 0;
    var extincteurs = 0;
    var tonnageTax = 0;
    var oilChange = 0;
    var batteryMaintenance = 0;
    var reparation = 0;
    var losses = 0;
    var brakeMaintenance = 0;
    var leasing = 0;
    var parking = 0;
    var lavage = 0;
    var tool = 0;
    var driverCharge = 0;

    var total = 0;

    if (!this.IsByParent) total = e.point.x;
    else total = e;

    for (let i = 0; i < res.length; i++) {
      insurance += res[i].insurance;
      visit += res[i].visit;
      roadTaxes += res[i].roadTaxes;
      operationalCertification += res[i].operationalCertification;
      permitCirculation += res[i].permitCirculation;
      metologicalNotbook += res[i].metologicalNotbook;
      fuel += res[i].fuel;
      extincteurs += res[i].extincteurs;
      tonnageTax += res[i].tonnageTax;
      oilChange += res[i].oilChange;
      tireMaintenance += res[i].tireMaintenance;
      batteryMaintenance += res[i].batteryMaintenance;
      reparation += res[i].reparation;
      losses += res[i].losses;
      brakeMaintenance += res[i].brakeMaintenance;
      leasing += res[i].leasing;
      parking += res[i].parking;
      lavage += res[i].lavage;
      tool += res[i].tool;
      driverCharge += res[i].driverCharge;
    }

    data = [
      [
        'Assurances :' + this.chartConfig.numberWithSpaces(insurance) + ' DH',
        (insurance / total) * 100,
      ],
      [
        'Visites :' + this.chartConfig.numberWithSpaces(visit) + ' DH',
        (visit / total) * 100,
      ],

      [
        'Vignettes :' + this.chartConfig.numberWithSpaces(roadTaxes) + ' DH',
        (roadTaxes / total) * 100,
      ],
      [
        'C.exploitation :' +
        this.chartConfig.numberWithSpaces(operationalCertification) +
        ' DH',
        (operationalCertification / total) * 100,
      ],
      [
        'Permis circulation :' +
        this.chartConfig.numberWithSpaces(permitCirculation) +
        ' DH',
        (permitCirculation / total) * 100,
      ],
      [
        'Carnet métrologique :' +
        this.chartConfig.numberWithSpaces(metologicalNotbook) +
        ' DH',
        (metologicalNotbook / total) * 100,
      ],
      [
        'Extincteurs :' +
        this.chartConfig.numberWithSpaces(extincteurs) +
        ' DH',
        (extincteurs / total) * 100,
      ],
      [
        'Taxe tonnage :' +
        this.chartConfig.numberWithSpaces(tonnageTax) +
        ' DH',
        (tonnageTax / total) * 100,
      ],
      [
        'Carburant : ' + this.chartConfig.numberWithSpaces(fuel) + ' DH',
        (fuel / total) * 100,
      ],
      [
        'Vidange : ' + this.chartConfig.numberWithSpaces(oilChange) + ' DH',
        (oilChange / total) * 100,
      ],
      [
        'Pneu : ' + this.chartConfig.numberWithSpaces(tireMaintenance) + ' DH',
        (tireMaintenance / total) * 100,
      ],
      [
        'Batterie :' +
        this.chartConfig.numberWithSpaces(batteryMaintenance) +
        ' DH',
        (batteryMaintenance / total) * 100,
      ],
      [
        'Reparation : ' + this.chartConfig.numberWithSpaces(reparation) + ' DH',
        (reparation / total) * 100,
      ],
      [
        'Dégâts : ' + this.chartConfig.numberWithSpaces(losses) + ' DH',
        (losses / total) * 100,
      ],
      [
        'Frein : ' +
        this.chartConfig.numberWithSpaces(brakeMaintenance) +
        ' DH',
        (brakeMaintenance / total) * 100,
      ],
      [
        'Location : ' + this.chartConfig.numberWithSpaces(leasing) + ' DH',
        (leasing / total) * 100,
      ],
      [
        'Parking : ' + this.chartConfig.numberWithSpaces(parking) + ' DH',
        (parking / total) * 100,
      ],
      [
        'Lavage : ' + this.chartConfig.numberWithSpaces(lavage) + ' DH',
        (lavage / total) * 100,
      ],
      [
        'Péage : ' + this.chartConfig.numberWithSpaces(tool) + ' DH',
        (tool / total) * 100,
      ],
      [
        'Charge conducteur :' +
        this.chartConfig.numberWithSpaces(driverCharge) +
        ' DH',
        (driverCharge / total) * 100,
      ],
    ];
    return data;
  }

  depenseChart() {
    this.setHighCartsLang();
    /** Create Pie Chart( depense chart ) **/
    var series = [];
    var seriesData: any[] = [];
    var drilldownSeries: any[] = [];
    var that = this;
    var total: number = 0;

    this.groupeStatistics = this.groupByFatherIdAndSumAllvalues() as GroupDashbordDto[];

    var groupesName = new Set(
      this.groupeStatistics.map((s) => {
        return s;
      })
    );
    for (var i = 0; i < this.groupeStatistics.length; i++) {
      total +=
        this.groupeStatistics[i].totalDocumentAndDriverChargeAndMaintenance;
    }
    groupesName.forEach((group) => {
      var dptotal: number = group.totalDocumentAndDriverChargeAndMaintenance;
      /** SERIES **/
      seriesData.push({
        name: group.nom,
        y: (dptotal / total) * 100,
        drilldown: group.idGroupe,
        x: dptotal,
      });
    });
    series.push({
      name: 'Groupe',
      colorByPoint: true,
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}  {point.x:,.2f} DH</span>   <br/>' +
          '<span style="color:{point.color}"> {point.y:.2f}%</span>',
      },
      data: seriesData,
    });
    /** SET OPTIONS **/
    this.DEPENSES = {
      chart: {
        type: 'pie',
        height: 360,
        events: {
          drilldown: function (e: any) {
            if (!e.seriesOptions) {
              that.depensechartservice
                .getDepenseDetails(
                  e.point.drilldown,
                  that.date,
                  that.IsByParent
                )
                .subscribe((res) => {
                  var data = that.depenseDetailData(res, e);
                  /** DATA **/
                  drilldownSeries.push({
                    name: e.point.name,
                    id: e.point.drilldown,
                    data: data,
                  });
                });
            }
          },
        },
      },
      title: {
        text: 'Dépenses', // Par Groupe
        style: {
          fontWeight: 'bold',
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
        },
        series: {
          dataLabels: {
            enabled: true,
            depth: 35,
            format: '{point.name}',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span>   <br/>',
      },
      series: series,
      drilldown: {
        series: drilldownSeries,
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        activeDataLabelStyle: {
          fontSize: '11px',
          fontWeight: 'bold',
          color: '#000000',
        },
      },
    };
    this.DEPENSESModal = {
      chart: {
        type: 'pie',
        events: {
          drilldown: function (e: any) {
            if (!e.seriesOptions) {
              that.depensechartservice
                .getDepenseDetails(
                  e.point.drilldown,
                  that.date,
                  that.IsByParent
                )
                .subscribe((res) => {
                  var data = that.depenseDetailData(res, e);
                  /** DATA **/
                  drilldownSeries.push({
                    name: e.point.name,
                    id: e.point.drilldown,
                    data: data,
                  });
                });
            }
          },
        },
      },
      title: {
        text: 'Dépenses', // Par Groupe
        style: {
          fontWeight: 'bold',
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
        },
        series: {
          dataLabels: {
            enabled: true,
            depth: 35,
            format: '{point.name}',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name} </span>   <br/>',
      },
      series: series,
      drilldown: {
        series: drilldownSeries,
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        activeDataLabelStyle: {
          fontSize: '11px',
          fontWeight: 'bold',
          color: '#000000',
        },
      },
    };
    Highcharts.chart('pie', this.DEPENSES);
    Highcharts.chart('pieModal', this.DEPENSESModal);
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  depenseChartTwo() {

    this.setHighCartsLang();
    /** Create Pie Chart( depense chart ) **/
    var series = [];
    var seriesData: { name: any; y: number; drilldown: any; x: number; }[] = [];
    var drilldownSeries: any[] = [];
    var that = this;
    var total: number = 0;

     this.groupeStatistics = this.groupByFatherIdAndSumAllvalues() as GroupDashbordDto[];

    var groupesName = new Set(this.groupeStatistics.map(s => { return s }));
    for (var i = 0; i < this.groupeStatistics.length; i++) {
      total += this.groupeStatistics[i].totalDocumentAndDriverChargeAndMaintenance
    }
    groupesName.forEach(group => {
      var dptotal: number = group.totalDocumentAndDriverChargeAndMaintenance;
      /** SERIES **/
      seriesData.push({
        name: group.nom,
        y: (dptotal / total) * 100,
        drilldown: group.idGroupe,
        x: dptotal
      });

    })
    series.push({
      name: "Groupe",
      colorByPoint: true,
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}  {point.x:,.2f} DH</span>   <br/>' +
          '<span style="color:{point.color}"> {point.y:.2f}%</span>'
      },
      data: seriesData
    });

    this.DEPENSESII = {
      chart: {
        type: 'pie',
        events: {
          drilldown: function (e: any) {
            if (!e.seriesOptions) {
              that.depensechartservice.getDepenseDetails(e.point.drilldown, that.date, that.IsByParent).subscribe(res => {
                var data = that.depenseDetailData(res, e);
                /** DATA **/
                drilldownSeries.push({
                  name: e.point.name,
                  id: e.point.drilldown,
                  data: data
                });
              });
            }
          }
        }
      },
      title: {
        text: 'Dépenses Par Groupe',
        style: {
          fontWeight: 'bold'
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {

          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}',

          }
        },
        series: {
          dataLabels: {
            enabled: true,
            depth: 35,
            format: '{point.name}'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name} </span>   <br/>'
      },
      series: series,
      drilldown: {
        series: drilldownSeries,
        activeAxisLabelStyle: {
          color: '#000000',
          fontSize: '11px',
          fontWeight: 'bold'
        },
        activeDataLabelStyle: {
          fontSize: '11px',
          fontWeight: 'bold',
          color: '#000000'
        },
      }
    }
    Highcharts.chart('pie', this.DEPENSES);

  }

  // function for set lang hightcarts
  setHighCartsLang() {
    Highcharts.setOptions({
      colors: [
        '#35a9e7',
        '#e77e22',
        '#3ea6dc',
        '#47a4d2',
        '#51a2c7',
        '#5a9fbd',
        '#639db3',
        '#6d9ba8',
        '#76999e',
        '#7f9694',
        '#899489',
        '#92927f',
        '#9c9074',
        '#a58d6a',
        '#ae8b60',
        '#b88955',
        '#c1874b',
        '#ca8441',
        '#d48236',
        '#dd802c',
        '#e77e22',
      ],
      lang: {
        printChart: 'Imprimer',
        decimalPoint: ',',
        thousandsSep: ' ',
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi',
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mars',
          'Avr',
          'Mai',
          'Jui',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        viewFullscreen: 'Voir en plein écran',
        noData: "Aucune donnée n'est disponible",
      },
    });
  }

}
