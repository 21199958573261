<form #documentForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success">
            <label class="col-sm-2 control-label" *ngIf="_document.type != 'LEASING'">Date début *</label>
            <label class="col-sm-2 control-label" *ngIf="_document.type == 'LEASING'">Début échéance *</label>
            <div class="col-sm-10">
                <input type="text" class="form-control  input-sm" bsDatepicker [(ngModel)]="operationTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" name="operationTime"
                    (ngModelChange)="onChangeOperationTime()" placement="bottom" [minDate]="minDate" required
                    autocomplete="off">
            </div>
        </div>

        <div class="form-group has-success"
            *ngIf="_document.type != 'PARCKING' && _document.type != 'TONNAGE' && _document.type != 'LAVAGE' && _document.type != 'TOLL' && _document.type != 'CA_REALIZATION' && _document.type != 'C_LOCATION'">
            <label class="col-sm-2 control-label" *ngIf="_document.type != 'LEASING'">Date fin *</label>
            <label class="col-sm-2 control-label" *ngIf="_document.type == 'LEASING'">Fin échéance *</label>

            <div class="col-sm-10">
                <input type="text" class="form-control  input-sm" bsDatepicker name="endTime" [(ngModel)]="endTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="operationTime" [disabled]="!operationTime"
                    placement="bottom" required autocomplete="off">
            </div>
        </div>


        <div class="form-group has-success" *ngIf="isHaveProvider">
            <div *ngIf="_document.provider.id != 0">
                <label class="col-sm-2 control-label">Fournisseur *</label>
                <div class="col-sm-10">
                    <select [(ngModel)]="_document.provider.id" name="providerId" #providerId="ngModel"
                        class="form-control input-sm select2" required>
                        <option *ngFor="let provider of providers" [value]="provider.id"
                            [selected]="provider.id == _document.provider.id">
                            {{provider?.name}}
                        </option>
                        <option hidden [value]="null"></option>
                        <option [value]="0">Autres</option>
                    </select>
                </div>
            </div>


            <div *ngIf="_document.provider.id == 0">
                <label class="col-sm-2 control-label">Nom Fournisseur *</label>
                <div class="col-sm-10">
                    <input type="text" [(ngModel)]="_document.provider.name" name="providerName"
                        class="form-control input-sm" placeholder="Nom Fournisseur" #providerName="ngModel" required
                        maxlength="50">
                    <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;"
                        (click)="_document.provider.id = null;">
                        <i class="fa fa-times"></i>
                    </span>
                </div>
            </div>
        </div>

        <div class="form-group has-success" *ngIf="_document.type == 'INSURANCE'">
            <div>
                <label class="col-sm-2 control-label">N° Police *</label>
                <div class="col-sm-10">
                    <input type="text" [(ngModel)]="_document.numPolice" name="numPolice" class="form-control input-sm"
                        placeholder="Numero Police" #numPolice="ngModel" maxlength="50" required>

                </div>
            </div>
        </div>

        <div class="form-group has-success" *ngIf="_document.type != 'LEASING'">
            <label class="col-sm-2 control-label">Coût *</label>
            <div class="col-sm-10">
                <input name="cout" [(ngModel)]="_document.cost" type="number" class="form-control input-sm"
                    required="true" placeholder="Coût" [min]="10" required>
            </div>
        </div>

        <!--<div class="form-group has-success" *ngIf="_document.type == 'LEASING' && mode == 'UPDATE'">
            <label class="col-sm-2 control-label">Montant échéance *</label>
            <div class="col-sm-10">
                <input name="cout" [(ngModel)]="_document.cost" type="number" class="form-control input-sm" required="true"
                    placeholder="Coût" [min]="10" required>
            </div>
        </div>-->

        <div class="form-group has-success" *ngIf="_document.type == 'LEASING'">
            <label class="col-sm-2 control-label">Montant crédit *</label>
            <div class="col-sm-10">
                <input name="maturityAmount" [(ngModel)]="_document.maturityAmount" type="number" class="form-control"
                    required="true" placeholder="Montant échéance" [min]="10">
            </div>
        </div>

        <div class="form-group has-success" *ngIf="_document.type == 'LEASING'">
            <label class="col-sm-2 control-label">Type</label>
            <div class="col-sm-10">
                <select [(ngModel)]="_document.place" name="place" #place="ngModel"
                    class="form-control input-sm select2">
                    <option value="Interne">Interne</option>
                    <option value="Externe">Externe</option>
                </select>
            </div>
        </div>

        <!--
        <div class="form-group has-success">
            <label class="col-sm-2 control-label" *ngIf="_document.type != 'LEASING'">Coût *</label>
            <label class="col-sm-2 control-label" *ngIf="_document.type == 'LEASING'">Montant échéance *</label>
            <div class="col-sm-10">
                <input name="cout" [(ngModel)]="_document.cost" type="number" class="form-control input-sm" required="true"
                    placeholder="Coût" [min]="10" required>
            </div>
        </div>

        <div class="form-group has-success" *ngIf="_document.type == 'LEASING'">
            <label class="col-sm-2 control-label">Montant crédit *</label>
            <div class="col-sm-10">
                <input name="maturityAmount" [(ngModel)]="_document.maturityAmount" type="number" class="form-control"
                    required="true" placeholder="Montant échéance" [min]="10">
            </div>
        </div>


-->


        <div class="form-group has-success" *ngIf="_document.type == 'EXTINCTEURS'">
            <label class="col-sm-2 control-label">Réf</label>
            <div class="col-sm-10">
                <input name="ref" [(ngModel)]="_document.ref" type="text" class="form-control" placeholder="#Réf">
            </div>
        </div>

        <div class="form-group has-success" *ngIf="_document.type == 'EXTINCTEURS'">
            <label class="col-sm-2 control-label">Poids</label>
            <div class="col-sm-10">
                <input name="weight" [(ngModel)]="_document.weight" type="number" class="form-control"
                    placeholder="Poids">
            </div>
        </div>

        <div class="box-footer">
            <button (click)="addDocument(documentForm)" *ngIf="mode == 'ADD'" [disabled]="!documentForm.form.valid"
                class="btn btn-info pull-right"><i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
            <button (click)="updateDocument(documentForm)" *ngIf="mode == 'UPDATE'"
                [disabled]="!documentForm.form.valid" class="btn btn-info pull-right"><i class="fa fa-spinner fa-spin"
                    *ngIf="loading"></i> Enregistrer
            </button>
        </div>


    </div>
</form>