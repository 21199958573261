import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { CimentSite, CimentSiteForm } from '.';

@Injectable()
export class CimentSiteService {

  sites: CimentSite[] = [];
  
  constructor(private _http: HttpClient) { }

  // list !
  findAllCimentCites(): Observable<CimentSite[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<CimentSite[]>(dns + 'sitesCiment', { headers: headers });
  }

  addCimentSite(cimentSite: CimentSiteForm): Observable<CimentSite> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<CimentSite>(dns + 'sitesCiment', cimentSite, options);
  }

  updateCimentSite(cimentSite: CimentSiteForm): Observable<CimentSite> {
    let headers = createAuthorizationHeader();
    return this._http.put<CimentSite>(dns + 'sitesCiment', cimentSite, { headers: headers });
  }

  deleteCimentSite(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'sitesCiment/' + id, { headers: headers });
  }

  getOneCimentSite(id: number): Observable<CimentSite> {
    let headers = createAuthorizationHeader();
    return this._http.get<CimentSite>(dns + 'sitesCiment/' + id, { headers: headers });
  }

}
