import { Injectable } from '@angular/core';
import { AlertConfiguration } from './alert';

@Injectable()
export class AlertManagementServiceUtils {
  isByGroup = true;
  alertConfiguration: AlertConfiguration;

  initializeAlertConfiguration(type: any) {
    this.alertConfiguration = new AlertConfiguration();
    this.alertConfiguration.type = type;
    this.alertConfiguration.value1 = 0;
    this.alertConfiguration.value2 = '';
    this.alertConfiguration.isActive = false;
    return this.alertConfiguration;
  }

  initializeAlertConfigurationWithSameValue1(
    val1: number,
    alertConfig: AlertConfiguration[]
  ) {
    for (let i = 0; i < alertConfig.length; i++) {
      alertConfig[i].isActive = true;
      alertConfig[i].value1 = val1;
    }
  }
}
