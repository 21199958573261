<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <i class="fa fa-hand-o-right"></i>
      <span style="text-decoration: underline" *ngIf="mode == 'ADD'">
        {{texthead}}
      </span>
      <span style="text-decoration: underline" *ngIf="mode == 'UPDATE'">
        Modification Alerte
      </span>
      <span style="text-decoration: underline" *ngIf="mode == 'DELETE'">
        Supression Template
      </span>
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="row">
        <div *ngIf="showTempl && (mode == 'ADD' || mode == 'DELETE')" class="form-group col-sm-8 mrg">
          <label class="template">Template</label>
          <select class="form-control input-sm template" [(ngModel)]="templateName" name="templates"
            (change)="selectNameTemp()">
            <option *ngFor="let t of nameTemplates">{{t}}</option>
          </select>
        </div>

        <!-- Error Section !-->
        <ng-container *ngIf="error">
          <div class="col-md-12">
            <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              <h4 style="color: white">
                <i class="fa fa-exclamation-triangle"></i> Attention !
              </h4>
              {{error.errorMessage}}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="mode == 'DELETE' && confimrDelete == true">
          <div class="col-md-12">
            <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                ×
              </button>
              <h4 style="color: white">
                <i class="fa fa-exclamation-triangle"></i> Attention !
                Est vous sur de supprimer cette Template {{templateName}}
              </h4>
              <button type="button" class="btn btn-default" (click)="confimrDelete = false">
                Annuler
              </button>
              <button type="button" class="btn btn-success" (click)="deleteTemplate()">
                <i class="fa fa-check" aria-hidden="true"></i> Oui, Je confirme
              </button>
            </div>
          </div>
        </ng-container>
        <div class="form-group col-sm-5 mrg" *ngIf="mode == 'ADD' || mode == 'UPDATE'">
          <label>Name</label>
          <input type="text" class="form-control input-sm " placeholder="Name" name="namealert" [(ngModel)]="alert.name"
            required>
        </div>
        <div class="form-group col-sm-7 mrg" *ngIf="mode == 'ADD' || mode == 'UPDATE'">
          <label>Mesure</label>
          <ng-multiselect-dropdown-angular7 name="mesures" [data]="mesuresobjs" placeholder="Select mesure"
            [(ngModel)]="selectedMesures" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
            [settings]="mesuresDropdownSettings">
          </ng-multiselect-dropdown-angular7>
        </div>
      </div>
      <div class="row" *ngIf="mode == 'ADD' || mode == 'UPDATE'">
        <ng-container *ngFor="let s of alert.alertConfigurations;let i=index" [ngSwitch]="s?.mesure?.name">
          <div *ngSwitchCase="'SPEED'">
            <div class="panel panel-default">
              <div class="panel-heading">Speed</div>
              <div class="panel-body">
                <div class="form-group col-sm-6 mrg">
                  <label> Operateur Vitesse </label>
                  <select class="form-control input-sm" [(ngModel)]="s.operator.id" name="operatorsVitesse">
                    <option *ngFor="let o of operators" style="font-size: 14px;" [ngValue]="o.id">
                      {{o.name}}

                    </option>
                  </select>

                </div>
                <div class="form-group col-sm-6 mrg">
                  <label *ngIf="s.operator.name=='>='">Vitesse Max</label>
                  <label *ngIf="s.operator.name=='<='">Vitesse Min</label>
                  <label *ngIf="s.operator.name!='>=' && s.operator.name!='<='">Vitesse</label>
                  <input type="number" class="form-control input-sm" placeholder="Vitesse" name="speed"
                    [(ngModel)]="s.value1">
                </div>
              </div>
            </div>
          </div>


          <div *ngSwitchCase="'INGNITION'">
            <div class="panel panel-default">
              <div class="panel-heading">Confg INGNITION</div>
              <div class="panel-body">
                <div class="col-sm-6 mrg">
                  <label>Durée d'échauffement (min)</label>
                  <input type="number" class="form-control input-sm" placeholder="Durée d'échauffement" name="name"
                    [(ngModel)]="s.value1">
                </div>

                <div class="col-sm-6 mrg">
                  <label> Operateur INGNITION</label>
                  <select class="form-control input-sm" [(ngModel)]="s.operator.id" name="operatorsIngnition">
                    <option *ngFor="let o of operators" style="font-size: 14px;" [ngValue]="o.id">
                      {{o.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>


          <div *ngSwitchCase="'ZONE'">
            <div class="panel panel-default">
              <div class="panel-heading">Confg Zone</div>
              <div class="panel-body">
                <div class="col-sm-5 mrg">
                  <label>Zone</label>
                  <ng-multiselect-dropdown-angular7 name="zones" [data]="zones" placeholder="Select Zone"
                    [(ngModel)]="zonesSelected" (onSelect)="onSelectedZone($event,i)"
                    (onDeSelect)="onItemDeSelectZone($event,i)" [settings]="zonesDropdownSettings">
                  </ng-multiselect-dropdown-angular7>

                </div>
                <div class="col-sm-4 mrg">
                  <label for="exampleInputName1">Durée d'arrêt (min)</label>
                  <input type="number" class="form-control input-sm" id="exampleInputName1" placeholder="Durée d'arrêt"
                    name="dureArretZone" [(ngModel)]="s.value2">
                </div>
                <div class="col-sm-3 mrg">
                  <label> Operateur Zone</label>
                  <select class="form-control input-sm" [(ngModel)]="s.operator.id" name="operatorsZone">
                    <option *ngFor="let o of operators" style="font-size: 14px;" [ngValue]="o.id">
                      {{o.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'POI'">
            <div class="panel panel-default">
              <div class="panel-heading">Config POI</div>
              <div class="panel-body">
                <div class="col-sm-5 mrg">
                  <label>POI</label>
                  <ng-multiselect-dropdown-angular7 name="Pois" [data]="pois" placeholder="Select POI"
                    [(ngModel)]="poisSelected" (onSelect)="selectedPoi($event,i)"
                    (onDeSelect)="onItemDeSelectPOI($event,i)" [settings]="zonesDropdownSettings">
                  </ng-multiselect-dropdown-angular7>

                </div>
                <div class="col-sm-4 mrg">
                  <label for="exampleInputName1">Durée d'arrêt (min)</label>
                  <input type="number" class="form-control input-sm" id="exampleInputName1" placeholder="Durée d'arrêt"
                    name="dureArretPoi" [(ngModel)]="s.value2">
                </div>
                <div class="col-sm-3 mrg">
                  <label> Operateur POI</label>
                  <select class="form-control input-sm" [(ngModel)]="s.operator.id" name="operatorsPoi">
                    <option *ngFor="let o of operators" style="font-size: 14px;" [ngValue]="o.id">
                      {{o.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>


          <div *ngSwitchCase="'NO_POI'">
            <div class="panel panel-default">
              <div class="panel-heading">Config NO_POI</div>
              <div class="panel-body">
                <div class="col-sm-6 mrg">
                  <label>Durée d'arrêt hors P.O.I (min)</label>
                  <select *ngIf="stopTimeSelectMode" class="form-control" [(ngModel)]="s.value1" name="stopTime">
                    <option *ngFor="let minute of minutes" [ngValue]="minute" style="font-size: 14px;"
                      [ngClass]="{'autre': minute == 'Autre..'}">
                      {{minute}}
                    </option>
                  </select>
                </div>

                <div class="col-sm-6 mrg">
                  <label> Operateur NO_POI</label>
                  <select class="form-control input-sm" [(ngModel)]="s.operator.id" name="operatorsNoPoi">
                    <option *ngFor="let o of operators" style="font-size: 14px;" [ngValue]="o.id">
                      {{o.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'CHOMAGE'">
            <div class="panel panel-default">
              <div class="panel-heading">Config CHOMAGE</div>
              <div class="panel-body">
                <div class="col-sm-6 mrg">
                  <label>Durée d'arrêt hors P.O.I (min)</label>
                  <select *ngIf="stopTimeSelectMode" class="form-control" [(ngModel)]="s.value1" name="stopTime">
                    <option *ngFor="let minute of minutes" [ngValue]="minute" style="font-size: 14px;"
                            [ngClass]="{'autre': minute == 'Autre..'}">
                      {{minute}}
                    </option>
                  </select>
                </div>

                <div class="col-sm-6 mrg">
                  <label> Operateur CHOMAGE</label>
                  <select class="form-control input-sm" [(ngModel)]="s.operator.id" name="operatorsChomage">
                    <option *ngFor="let o of operators" style="font-size: 14px;" [ngValue]="o.id">
                      {{o.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'CHARGEMENT'">
            <div class="panel panel-default">
              <div class="panel-heading">Config CHARGEMENT</div>
              <div class="panel-body">
                <div class="col-sm-6 mrg">
                  <label>Durée d'arrêt hors CHARGEMENT (min)</label>
                  <select *ngIf="stopTimeSelectMode" class="form-control" [(ngModel)]="s.value1" name="stopTime">
                    <option *ngFor="let minute of minutes" [ngValue]="minute" style="font-size: 14px;"
                            [ngClass]="{'autre': minute == 'Autre..'}">
                      {{minute}}
                    </option>
                  </select>
                </div>

                <div class="col-sm-6 mrg">
                  <label> Operateur CHARGEMENT</label>
                  <select class="form-control input-sm" [(ngModel)]="s.operator.id" name="operatorsChargement">
                    <option *ngFor="let o of operators" style="font-size: 14px;" [ngValue]="o.id">
                      {{o.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>


        </ng-container>
      </div>



      <div class="row" *ngIf="mode == 'ADD'">
        <div class="form-group col-sm-5 mrg">
          <label> Affecter a </label>
          <select class="form-control input-sm" [(ngModel)]='Selectedoption' (change)="onSelectAffecte()"
            name='selectedoption' required>
            <option style="font-size: 14px;" *ngFor="let opt of affectedTo" [value]="opt.name">
              {{opt.label}}</option>
          </select>
        </div>

        <div class="form-group col-sm-7 mrg">
          <ng-container [ngSwitch]="Selectedoption">
            <div *ngSwitchCase="'BY_VEHICULE'">
              <label> Vehicules</label>
              <ng-multiselect-dropdown-angular7 name="vehicules" [data]="vehiculesItems" [(ngModel)]="vehiculesSelected"
                placeholder="select Vehicule" [settings]="vehiculesDropdownSettings">
              </ng-multiselect-dropdown-angular7>

            </div>
            <div *ngSwitchCase="'BY_GROUPS'">
              <label> Groups</label>
              <ng-multiselect-dropdown-angular7 name="groups" [data]="groupsItems" [(ngModel)]="selectedGroupsItems"
                (change)="onChangeGroups()" placeholder="select Group" [settings]="groupsDropdownSettings">
              </ng-multiselect-dropdown-angular7>
            </div>
          </ng-container>
        </div>
      </div>


      <div class="row" *ngIf="mode == 'ADD' || mode == 'UPDATE'">
        <div class="form-group col-sm-5 mrg">
          <label>Active</label>
          <mat-checkbox class="form-control input-sm" name="isActive" [(ngModel)]="alert.active">
          </mat-checkbox>
        </div>
        <div class="form-group col-sm-5 mrg" *ngIf="mode == 'ADD' || mode == 'UPDATE'">
          <app-email-manager [showActiveSectionOfAllVehicules]="enableGlobalConfigToAllVehicules"
            [showActiveSection]="enableGlobalConfig" (onChange)="onEmailChange($event)" [emailsHash]="alert.emails">
          </app-email-manager>

        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <button type="submit" class="btn btn-primary pull-right shadow col-sm-3" *ngIf="mode == 'ADD'"
            (click)="save()">Valider</button>
          <button type="submit" class="btn btn-primary pull-right shadow col-sm-3" *ngIf="mode == 'UPDATE'"
            (click)="EditAlert()">Modifier</button>
          <button type="submit" class="btn btn-light pull-right shadow col-sm-3"
            (click)="modalRef?.hide()">Annuler</button>
          <button (click)="confirmDeleteTemp()" *ngIf="mode == 'DELETE'" class="btn btn-danger">Supprimer</button>

        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <button type="submit" *ngIf="mode == 'ADD' || mode == 'UPDATE'"
            class="btn btn-success pull-left shadow col-sm-5" (click)="saveEtQueTemplate()">Ajouter en Tant Que
            Template</button>
          <button *ngIf="mode == 'ADD'" type="submit" class="btn btn-warning pull-left shadow col-sm-3"
            (click)="onShow()">{{textButton}}</button>
          <button *ngIf="mode == 'ADD'" class="btn btn-danger pull-left shadow col-sm-3"
            (click)="ChangeMode()">Supprimer Template</button>

        </div>
      </div>

    </form>
  </div>
</div>
