import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GeoMarketingAssignmentAddDto, GeoMarketingAssignmentInfoDto } from '../geo-marketing';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DataManagementComponent } from 'src/app/client-management/data-management';
import { ToastrService } from 'ngx-toastr';
import { GeoMarketingService } from '../geo-marketing.service';
declare var $: any;

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss'],
  providers: [DataManagementComponent]
})
export class VisitComponent implements OnInit {


  loading: boolean = false;
  geoMarketing: GeoMarketingAssignmentInfoDto[] = [];
    selectedGeoMarketing: GeoMarketingAssignmentInfoDto | null = null;
    geoMarketingAssignment: GeoMarketingAssignmentInfoDto | null = null;


    @ViewChild('geoMarketingAssignmentDeleteModal', { static: false }) geoMarketingAssignmentDeleteModal: ElementRef;


    @ViewChild('geoMarketingAssignmentFormModal', { static: false }) geoMarketingAssignmentFormModal: ElementRef;


    mode: 'ADD' | 'UPDATE' = 'ADD';

    constructor(private parent: DataManagementComponent,
        private toastr: ToastrService,
        private geoMarketingService: GeoMarketingService) { }

    ngOnInit() {
      this.loadGeoMarketingAssignment();
    }

    loadGeoMarketingAssignment() {
        this.loading = true;
        this.geoMarketingService.getGeoMarketingAssignment()
            .subscribe(geoMarketing => {
                this.loading = false;
                this.geoMarketing = geoMarketing;
                this.selectedGeoMarketing = this.geoMarketing[0];
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de chargements", "Tournée")
            })
    }

    openGeoMarketingAssignmentForm() {
        this.mode = 'ADD';
         this.geoMarketingAssignment = null;
        $(this.geoMarketingAssignmentFormModal.nativeElement).modal('show');
    }

    onGeoMarketingSaved(geoMarketing: GeoMarketingAssignmentInfoDto) {
        $(this.geoMarketingAssignmentFormModal.nativeElement).modal('hide');

        this.geoMarketing.unshift(geoMarketing);
        if(this.geoMarketing.length>0)
        this.selectedGeoMarketing = this.geoMarketing[0];
    }

    onGeoMarketingUpdated(geoMarketing: GeoMarketingAssignmentInfoDto) {
        $(this.geoMarketingAssignmentFormModal.nativeElement).modal('hide');
        

        for (let i = 0; i < this.geoMarketing.length; i++) {
          if (this.geoMarketing[i].id == geoMarketing.id) {
            this.geoMarketing[i] = geoMarketing;
            break;
          }
        }
        this.selectedGeoMarketing = this.geoMarketing[0];
    }

            /**
       * Afficher modal de modification
       */
  onUpdate(geoMarketing: GeoMarketingAssignmentInfoDto) {
    this.mode = 'UPDATE';
    this.geoMarketingAssignment = geoMarketing;
    $(this.geoMarketingAssignmentFormModal.nativeElement).modal('show');
    
  }

    onDelete() {
        if (!this.selectedGeoMarketing) return;
        this.loading = true;
        this.geoMarketingService.deleteGeoMarketingAssignment(this.selectedGeoMarketing.id)
            .subscribe(response => {
                this.loading = false;
                $(this.geoMarketingAssignmentDeleteModal.nativeElement).modal('hide');
                
                if (response) {
                    this.toastr.success("Supprimé", "Tournée");
                    this.loadGeoMarketingAssignment();
                } else {
                    this.toastr.error("Erreur lors de suppression", "Tournée");
                }
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de suppression", "Tournée");
            })
    }

    onConfirmDelete() {
    $(this.geoMarketingAssignmentDeleteModal.nativeElement).modal('show');
    }

    marketingAssignmentFormModal(){
     $(this.geoMarketingAssignmentFormModal.nativeElement).modal('hide');
    }
}
