import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopsChildsComponent } from './stops-childs.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormatFullDurationModule } from 'src/app/pipes/format-full-duration.module';
import { AddStopPoiModule } from '../add-stop-poi/add-stop-poi.module';

@NgModule({
  declarations: [
    StopsChildsComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormatFullDurationModule,
    AddStopPoiModule
  ],
  exports: [
    StopsChildsComponent
  ],
  providers: []
})
export class StopsChildsModule { }
