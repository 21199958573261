<section class="content">
    <div class="row" style="font-family: 'Inconsolata', monospace;font-size: 14px;height: 30vh;">
        <!-- Left col -->
        <section class="col-lg-12 connectedSortable " style="padding: 0;">
            <!-- Custom tabs (Charts with tabs)-->
            <div class="nav-tabs-custom" style="margin-bottom: 10px;">
                <!-- Tabs within a box -->
                <ul *ngIf="vehiculeIcon || groupeIcon || driverIcon || programIcon" class="nav nav-tabs pull-right">
    
                    <!-- Programme -->
                    <li [ngClass]="{'active': programIcon}">
                        <a routerLink="/client/dataManagement/program" routerLinkActive="active" data-toggle="tab">
                            <i class="fa fa-calendar icons"></i>  Planification
                        </a>
                    </li>
    
                    <!-- conducteurs -->
                    <li [ngClass]="{'active': driverIcon}">
                        <a routerLink="/client/dataManagement/driver" routerLinkActive="active" data-toggle="tab" class="">
                            <i class="fa fa-users icons"></i> conducteurs
                        </a>
                    </li>
                    <!-- Groupes -->
                    <li [ngClass]="{'active': groupeIcon}">
                        <a routerLink="/client/dataManagement/groupe" routerLinkActive="active" data-toggle="tab">
                            <i class="fa fa-object-group icons"></i> Groupes
                        </a>
                    </li>
                    <!-- Vehicules -->
                    <li [ngClass]="{'active': vehiculeIcon}">
                        <a routerLink="/client/dataManagement/vehicule" routerLinkActive="active" data-toggle="tab">
                            <i class="fa fa-car icons"></i> Véhicules
                        </a>
                    </li>
    
                    <li *ngIf="vehiculeIcon" class="pull-left header">
    
                        <i class="fa fa-car"></i> Vehicules |
                        <a *ngIf="unusedDevices.length > 0"
                            [routerLink]="['/client/dataManagement/vehicule/form/', 0, 'ADD']" routerLinkActive="active">
    
                            <i class="fa fa-plus-circle add" aria-hidden="true">
    
                                <span style="font-family: 'Inconsolata', monospace;font-size: 14px;"> Ajouter </span></i>
                        </a>
    
                        <span *ngIf="unusedDevices.length > 0" data-toggle="tooltip" class="badge bg-orange">
                            <i class="fa fa-info" aria-hidden="true"></i> vous avez {{unusedDevices.length}} boitier(s) non
                            utilisés !
                        </span>
    
                        <span *ngIf="unusedDevices.length == 0" data-toggle="tooltip" class="badge bg-green">
                            <i class="fa fa-info" aria-hidden="true"></i> Tous les boîtiers sont utilisés <i
                                class="fa fa-smile-o" aria-hidden="true"></i>
                        </span>
                    </li>
    
                    <li *ngIf="groupeIcon" class="pull-left header">
                        <i class="fa fa-object-group"></i>Groupes |
    
                        <button type="button" class="btn btn-primary btn-sm"
                            [routerLink]="['/client/dataManagement/groupe/form/', 0, 'ADD']"
                            routerLinkActive="active">Ajouter <i class="fa fa-plus-circle" aria-hidden="true"></i></button>
                    </li>
    
                    <li *ngIf="driverIcon" class="pull-left header"><i class="fa fa-users"></i>Drivers |
                        <button type="button" class="btn btn-primary btn-sm "
                            [routerLink]="['/client/dataManagement/driver/form/', 0, 'ADD']"
                            routerLinkActive="active">Ajouter <i class="fa fa-plus-circle" aria-hidden="true"></i></button>
                    </li>
    
                    <li *ngIf="programIcon" class="pull-left header"><i class="fa fa-calendar"></i>
                        Planification |
                        <button type="button" class="btn btn-primary btn-sm "
                            [routerLink]="['/client/dataManagement/program/form/', 0, 'ADD']" routerLinkActive="active">
                            Ajouter <i class="fa fa-plus-circle" aria-hidden="true"></i></button>
                    </li>
    
                </ul>
    
                <div class="tab-content no-padding heightDiv">
                    <router-outlet></router-outlet>
                </div>
    
            </div>
    
        </section>
    </div>
</section>