import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { DataManagementService, GroupDto, PointInterest } from 'src/app/client-management/data-management';
import { CimentSiteService } from '../ciment-site.service';
import { CimentSite, CimentSiteForm } from '../ciment-sites';

@Component({
  selector: 'app-ciment-site-form',
  templateUrl: './ciment-site-form.component.html',
  styleUrls: ['./ciment-site-form.component.css']
})
export class CimentSiteFormComponent implements OnInit {

  mode: "ADD" | "UPDATE";
  loading: boolean = false;
  currentSite: CimentSite = new CimentSite();
  pointInterests : PointInterest[] = [];
  groups : GroupDto[] = [];

  error: { errorMessage: string } = { errorMessage: '' };
  mainPoiDropdownSettings = {};
  groupDropdownSettings = {};

  constructor(private route: ActivatedRoute, public toastr : ToastrService,
    private router: Router,
    private cimentSiteService: CimentSiteService, private dataManagementService : DataManagementService ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
       this.init(params.id);
    });

    this.mainPoiDropdownSettings = {
      singleSelection: false,
      idField: 'idPointInterest',
      textField: 'name',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  init(siteId: any) {


    if (this.mode == "ADD") {
      this.loading = true;
      forkJoin(
       [
        this.dataManagementService.getAllPointInterestsByType('MARKER'),
        this.dataManagementService.getAllGroups()]
      ).subscribe(
        ([pointInterests, groups]) => {
          this.groups = groups;
          this.pointInterests = pointInterests;
          this.loading = false;
        },
        error => {
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }

          this.loading = false;
        }
      );

    }

    if (this.mode == "UPDATE") {
      this.loading = true;
      forkJoin(
       [
        this.dataManagementService.getAllPointInterestsByType('MARKER'),
        this.dataManagementService.getAllGroups(),
        this.cimentSiteService.getOneCimentSite(siteId)]
      ).subscribe(
        ([pointInterests, groups, site]) => {
          this.currentSite = site;
          this.groups = groups;
          this.pointInterests = pointInterests;
          this.loading = false;
        },
        error => {
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }

          this.loading = false;
        }
      );
    }
  }

  cancel() {
    this.router.navigate(["/client/globalOverviewManagement/sites"]);
  }

  addSite() {

    this.loading = true;
    let cimentSiteForm : CimentSiteForm = new CimentSiteForm();
    Object.assign(cimentSiteForm, this.currentSite);
    cimentSiteForm.mainPoi = this.currentSite.mainPoi.map(p => p.idPointInterest);
    cimentSiteForm.groups = this.currentSite.groups.map(p => p.idGroupe);

    this.cimentSiteService
      .addCimentSite(cimentSiteForm)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        site => {
          this.loading = false;
          this.cimentSiteService.sites.push(site);
          this.toastr.success("Site bien ajouter !");
          this.router.navigate(["/client/globalOverviewManagement/sites"]);
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

  updateSite() {
    this.loading = true;
    let cimentSiteForm : CimentSiteForm = new CimentSiteForm();
    Object.assign(cimentSiteForm, this.currentSite);
    cimentSiteForm.mainPoi = this.currentSite.mainPoi.map(p => p.idPointInterest);
    cimentSiteForm.groups = this.currentSite.groups.map(p => p.idGroupe);

    this.cimentSiteService
      .updateCimentSite(cimentSiteForm)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        site => {
          this.loading = false;
          for(let site_ of this.cimentSiteService.sites){
            if(site_.id == site.id){
              site_ = site;
              break;
            }
          }
          this.router.navigate(["/client/globalOverviewManagement/sites"]);
          this.toastr.success("Site bien modifié !");
        },
        error => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = "SERVER ERROR";
          }
          this.loading = false;
        }
      );
  }

}
