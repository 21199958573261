import { Component, Input, OnInit } from '@angular/core';
import { TravelReport } from 'src/app/client-management/parc-management';
import { ChartConfigService } from 'src/app/utils/chart/chart-config.service';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);

@Component({
  selector: 'app-classification-percentage-chart',
  templateUrl: './classification-percentage-chart.component.html',
  styleUrls: ['./classification-percentage-chart.component.css']
})
export class ClassificationPercentageChartComponent implements OnInit {


  classments: TravelReport[] = [];
  classmentObject: Object | null = null;

  @Input()
  set activities(activities: TravelReport[]) {
    if (activities) {
      this.classments = Object.assign(activities);
      this.classmentChart();
    }
  }

  constructor(private chartConfig : ChartConfigService) { }

  ngOnInit() {
    this.classmentChart();
  }

  classmentChart() {
    this.chartConfig.setHighCartsLang();

    var seriesData : { name: string, y: number, cust: string} [] = [];

    var totalpathDuration = this.classments.reduce((acc, drd) => acc + drd.pathDuration, 0);
    for (let classment of this.classments) {
      let percent = (classment.pathDuration * 100) / totalpathDuration;
      seriesData.push({name : classment.vehiculeMatricule, y: percent, cust : classment.drivingDurationStr})
    }

    this.classmentObject = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Activité',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      },xAxis: {
        type: 'category',

        labels: {
          rotation: -45,
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textDecoration: "underline"
          }
        }
      },
      yAxis: {
        title: {
          text: 'Σ.t Activité'
        },
        labels: {
          enabled: false
      }
      },legend: {
        enabled: false
      }, credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.cust}</b>'
    },
    plotOptions: {
      column: {
        borderRadius: 5
    },
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          format: '{point.cust} ',
          softThreshold: true
        }
      }
    },
      series: [{
        colorByPoint: true,
        name: 'Durée d\'activité',
        data: seriesData
      }]
    }
    Highcharts.chart('classmentObject', this.classmentObject);
  }




}
