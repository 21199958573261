<ngx-loading *ngIf="loader" [show]="loader"></ngx-loading>

<ng-container *ngIf="showSetting; else pdfTemp">

  <!-- Error Section !-->
  <ng-container *ngIf="error">
    <div class="col-md-12">
      <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
          ×
        </button>
        <h4 style="color: white">
          <i class="fa fa-exclamation-triangle"></i> Attention !
        </h4>
        {{error.errorMessage}}
      </div>
    </div>
  </ng-container>

  <div class="container">
    <div class=" mt-2 mb-2 text-center   well bgg">
      <h4 style="font-family: 'Inconsolata', monospace;font-weight: bold;color: white;">
        <i class="fa fa-cog"></i>
        <span>Paramétrage </span>
      </h4>
    </div>
    <div class="col-lg-12 well formBC">
      <div class="box box-info">
        <form #settingForm="ngForm" class="form-horizontal">
          <div class="box-body">
            <div class="col-sm-6">

              <!-- email -->
              <div class="form-group mrg">
                <label>Email</label>
                <input [(ngModel)]="rentalAgreementSetting.email" name="email" type="text" placeholder="Email"
                  class="form-control">
              </div>

              <!-- telephone -->
              <div class="form-group mrg">
                <label>Téléphone</label>
                <input [(ngModel)]="rentalAgreementSetting.telephone" name="telephone" type="text"
                  placeholder="Téléphone" class="form-control">
              </div>

            </div>

            <div class="col-sm-6">
              <!-- webSite -->
              <div class="form-group mrg">
                <label>Site Web</label>
                <input [(ngModel)]="rentalAgreementSetting.webSite" name="webSite" type="text" placeholder="Site Web"
                  class="form-control">
              </div>

              <!-- address -->
              <div class="form-group mrg">
                <label>Adresse</label>
                <input [(ngModel)]="rentalAgreementSetting.address" name="address" type="text" placeholder="Adresse"
                  class="form-control">
              </div>

            </div>
          </div>
          <!-- ========================== Modal FOOTER (START) ===============================-->
          <div class="box-footer">
            <button (click)="saveTemplateSetting()" class="btn btn-info pull-right" [disabled]="!settingForm.valid">
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
            <button class="btn btn-warning pull-right" style="margin-right: 15px;" (click)="toggleOption(false)">
              PDF
            </button>
            <button class=" btn btn-dark" (click)="cancel(settingForm)">
              Annuler
            </button>


          </div>
          <!-- ========================== Modal FOOTER (END) ===============================-->
        </form>
      </div>
    </div>

  </div>

</ng-container>

<ng-template #pdfTemp>
  <div class="container" style="font-family: 'Inconsolata', monospace;font-size: 14px;">

    <div class=" mt-2 mb-2 text-center   well bgg">
      <h3 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
        Contrat de location</h3>
    </div>


    <div class="box box-info">
      <form class="form-horizontal">
        <div class="box-body">

          <div class="form-group">
            <label class="col-sm-2 control-label" style="color:limegreen;">N° contrat</label>
            <div class="col-sm-10">
              <select class="form-control" [(ngModel)]="rentalId" name="contrat">
                <option style="font-size: 14px;" *ngFor="let rental of rentalAgreementIds" [ngValue]="rental">
                  contrat N° {{rental}} </option>
              </select>
            </div>
          </div>

          <!-- export button-->
          <div class="box-footer">
            <button class="btn btn-success pull-right" (click)='export()'>
              Exporter
            </button>
            <button class="btn btn-warning pull-right" style="margin-right: 15px;" (click)='toggleOption(true)'>
              Paramétrage
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>