import { Mileage } from '../historical/mileage/mileage';
import { DateInterval } from '../../shared/model';
import { TravelReportDto } from '../parc-management';
import { RealTimeRecord } from '../realtime/model/real-time.model';

export class PointInterest {
  idPointInterest: number;
  name: string;
  address: string;
  ray: number;
  zoomLevel: number;
  imageUri: string;
  plyColor: string;
  coordinate: { lat: number; lng: number };
  type: 'MARKER' | 'POLYGON' | 'LINE' | number;
  decode: any[];
  hash: string;

  constructor() {
    this.ray = 100;
    this.decode = [];
    this.plyColor = 'blue';
  }
}

export enum ClientType {
  CLIENT = 'CLIENT',
  CLIENT_GROS = 'CLIENT_GROS',
  DRIVER = 'DRIVER',
  PROSPECT = 'PROSPECT',
  INTERMEDIAIRE = 'INTERMEDIAIRE',
  CONCURENT = 'CONCURENT',
  CONCESSIONNAIRE = 'CONCESSIONNAIRE',
  DEPOT = 'DEPOT',
}

export class PoiClient {
  idPointClient: number;
  name: string;
  address: string;
  ray: number;
  zoomLevel: number;
  imageUri: string;
  plyColor: string;
  coordinate: { lat: number; lng: number };
  type: 'MARKER' | 'POLYGON' | 'LINE' | number;
  decode: any[];
  hash: string;
  clientType: ClientType;
  myPois: PoiClient[];
  myZones: PoiClient[];

  constructor() {
    this.ray = 10;
    this.decode = [];
    this.plyColor = 'blue';
    this.myPois = [];
    this.myZones = [];
  }
}

export class GroupMinifyDto {
  idGroupe: number;
  nom: string;

  constructor() {
    this.idGroupe = 0;
    this.nom = '';
  }
}

export class VehiculesMinifyDto {
  idVehicule: number;
  matricule: string;
  mark: string;
  icon: string;
  idDevice: number;

  constructor() {
    this.idVehicule = 0;
    this.idDevice = 0;
    this.matricule = '';
    this.mark = '';
    this.icon = '';
  }
}

export class GroupsVehicules {
  vehicules: VehiculesMinifyDto[];
  groups: GroupMinifyDto[];

  constructor() {
    this.vehicules = [];
    this.groups = [];
  }
}

export class GroupDto {
  idGroupe: number = 0;
  nom: string;
  imageUrl: string;
  vehicules: VehiculesDto[];
  children: GroupMinifyDto[];
  idGroupParent: number;
  constructor() {
    this.children = [];
    this.vehicules = [];
  }
}

export class VehiculesDto {
  idVehicule: number;
  matricule: string;
  mark: string;
  icon: string;
  idDevice: number;

  constructor() {}
}

export class RapportPayloadDto {
  idVehicules: number[];
  idGroups: number[];
  idVehicule: number;
  idGroup: number;
  allVehicules: boolean;
  byVehicules: boolean;
  poiAdresses: string[];
  endPoiAdresses: string[];
  byGroups: boolean;
  global: boolean;
  duration: number;
  hours: Date;
  avatarUri: string;
  geoMarketingAssignment: number;
  type: string;

  constructor() {
    this.idVehicules = [];
    this.idGroups = [];
    this.poiAdresses = [];
    this.endPoiAdresses = [];
    this.allVehicules = true;
    this.byVehicules = false;
    this.byGroups = false;
    this.global = false;
    this.duration = 0;
  }
}

export class RapportDto {
  format: string;
  type: string;
  rapportPayloadDto: RapportPayloadDto;
  dateInterval: DateInterval;
  byPOI: boolean;
  byNONPOI: boolean;
  avatarUri: string;
  endWork: Date;
  duration: number;
  travelsReport: TravelReportDto[];
  constructor() {
    this.format = 'EXCEL';
    this.type = 'ETAT_ACTUEL';
    this.rapportPayloadDto = new RapportPayloadDto();
    this.dateInterval = new DateInterval();
    this.byPOI = false;
    this.byNONPOI = false;
    this.avatarUri = '';
    this.duration = 0;
    this.travelsReport = [];
  }
}

export class ReportAuto {
  id: number;
  title: string;
  emails: string;
  period: string;
  url: string;
  payload: string;
  signinUrl: string;
}

export class ImportFileDto {
  provideName: string;
  type: string;
  file: FormData = null;
  constructor() {}
}

export class VehiculesDtoWithGroup {
  idVehicule: number;
  matricule: string;
  mark: string;
  icon: string;
  idDevice: number;
  maxSpeed: number;
  idGroup: number;
  emails: string;

  constructor() {}
}

export class Group {
  idGroupe: number;
  nom: string;
  isOpen: boolean;
  imageUrl: string;
  enbaleGlobalAlert: boolean;
  vehicules: Vehicule[];
  parent: Group;
  nameGroupParent: string;
  constructor(
    vehicule?: Vehicule[],
    nom?: string,
    imageUrl?: string,
    id?: number
) {
    if (vehicule) {
        this.vehicules = vehicule;
    } else {
        this.vehicules = [];
    }
    if (nom) {
        this.nom = nom;
    }
    if (id) {
        this.idGroupe = id;
    }
    if (imageUrl) {
        this.imageUrl = imageUrl;
    }
    this.isOpen = false;
}
}

export class Vehicule {
  idDevice: number;
  idVehicule: number;
  matricule: string;
  capacity: string;
  mark: string;
  type: string;
  icon: string;
  driverName: string;
  maxSpeed: number;
  alias: string;
  pathGrayCard: string;
  emails: string;
  idGroup: number;
  odoOffset: number;
  lastOdoUpdate: Date | number;
  mileage: Mileage;
  odo: number;
  rental: number;
  rotation: number;
  jawazCardNumber: string;
  secteur: string;
  fuelCardNumber: string;
  cardFuelSolde: number;
  code: string;
  realTimeRecord: RealTimeRecord;
  circulationAt: any;
  subMark: string;
  lastName: string;
  idDriver: number;
  telephone: any;
  grayCardUri: string;
  avatarUri: string;
  contractingUri: string;
  secondContractingUri: string;
  thirdContractingUri: string;

  permisUri: string;
  permis: File;

  passasionUri: string;
  passasion: File;
  passasionToBeDeleted: string[];

  vehiculeImageUri: string;
  vehiculeImage: File;
  vehiculeImageToBeDeleted: string[];

  documents: Map<string, string>;

  driver: Driver;
  device: Device;
  avatar: File;
  grayCard: File;
  contracting: File;
  secondContracting: File;
  thirdContracting: File;
  signification: Signification;
  groupName: string;
  arretMoteur: boolean;

  constructor() {
    this.realTimeRecord = new RealTimeRecord();
    this.device = new Device();
    this.driver = new Driver();
    this.passasionToBeDeleted = [];
    this.vehiculeImageToBeDeleted = [];
  }
}

export class VehiculeTravelReport {
  device: Device;
  idVehicule: number;
  matricule: string;
  capacity: string;
  mark: string;
  maxSpeed: number;
  subMark: string;
  fuelAverage: number;
  useFms: boolean;
  signification: Signification;
  rotation: number;
  rental: number;
  constructor() {
    this.device = new Device();
  }
}

export enum Signification {
  H,
  KM,
}

export class Driver {
  idDriver: number;
  code: string;
  matricule: string;
  permitNumber: string;
  firstName: string;
  lastName: string;
  telephone: string;
  healthCareExperiationDate: Date;
  profesionalCardExperiationDate: Date;
  permitExperiationDate: Date;
  cin: string;
  email: string;
  avatar: File;
  public bloodGroup: string;
  avatarUri: string;
  driverFunction: string;

  constructor() {}
}

export class Device {
  idDevice: number;
  deviceNumber: number;
}

export class Mission {
  id: number;
  label: string;
  served: boolean;
  travelDistance: number;
  travelDuration: number;
  createAt: Date;
  routeLegs: RouteLegs[];
  pointOfInterests?: number[];
  bl: string;
  article: string;
  quantity: number;
  isReturn: boolean;
  vehicule: number;

  constructor() {
    this.routeLegs = [];
    this.pointOfInterests = [];
    this.isReturn = false;
  }
}

export class RouteLegs {
  id: number;
  sequence: number;
  travelDistance: number;
  travelDuration: number;
  idPOISource: number;
  labelPOISource: string;
  latPOISource: number;
  lngPOISource: number;
  idPOITarget: number;
  labelPOITarget: string;
  latPOITarget: number;
  lngPOITarget: number;
  selected: boolean;
  visited: boolean;

  constructor() {}
}

export enum StateMission {
  DELIVERY,
  INPROGRESS,
  DELAY,
}

export class TrackingMission {
  mission: Mission;
  realTimeRecord: RealTimeRecord;
  minDistance: number;
  accordingDistance: number;
  accordingDuration: number;
  progressPercent: number;
  timeinPoiClient: number;
  first: boolean;
  last: boolean;
  firstNearest: boolean;
  state: StateMission;

  constructor() {
    this.mission = new Mission();
    this.realTimeRecord = new RealTimeRecord();
  }
}

export class TrackingMissionData {
  selectedGroup: Group;
  /** VEHICULE VAR */
  selectedVehicule: Vehicule;
  /** MISSION VAR */
  selectedMission: Mission;
  /** TRACKING VAR */
  trackingMission: TrackingMission;
  trackingAccordingDistance: number;
  trackingAccordingDuration: number;

  constructor() {
    this.selectedGroup = null;
    this.selectedMission = null;
    this.selectedVehicule = null;
    this.trackingMission = null;
    this.trackingAccordingDistance = 0;
    this.trackingAccordingDuration = 0;
  }
}

export class TimePlan {
  idTimePlan: number;
  id: string;
  name: string;
  start: Date;
  end: Date;
  background: string;
  about: string;
  recurrenceRule: string;
}
export class DriveQualityAlerts {
  addedInfo: number;
  countAddedInfo: number;
  text: string;
  style: string;
}

export class Program {
  idProgram: number;
  name: string;
  timePlans: TimePlan[];
  vehiculeIds: number[];
  constructor() {
    this.timePlans = [];
  }
}

export class VehiculePlanificationDto {
  idVehicule: number;
  matricule: string;
  subMark: string;
  mark: string;
  type?: string;
  consommationTheorique?: number;
}
export class DriverPlanificationDto {
  idDriver: number;
  code: string;
  fullName: string;
  telephone: string;
  cin: string;
}