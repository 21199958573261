import { Component, OnInit, OnDestroy } from '@angular/core';
import { Alert, AlertConfiguration } from '../../alert';
import { ToastrService } from 'ngx-toastr';
import { AlertManagementService } from '../../alert-management.service';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Subscription } from 'rxjs/Rx';

@Component({
  selector: 'app-brake-settings',
  templateUrl: './brake-settings.component.html',
  styleUrls: ['./brake-settings.component.css']
})
export class BrakeSettingsComponent implements OnInit, OnDestroy {
  alert: Alert = new Alert();
  value1 = 1000;
  public loader = false;
  showAdvancedSettings = true;
  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  boardgamesAlertConfiguartion: AlertConfiguration = new AlertConfiguration();
  diskAlertConfiguartion: AlertConfiguration = new AlertConfiguration();
  pumpAlertConfiguartion: AlertConfiguration = new AlertConfiguration();
  oilAlertConfiguartion: AlertConfiguration = new AlertConfiguration();

  getVehiculeWasSelectedSubscription: Subscription;
  constructor(
    private toastr: ToastrService,
    public alertManagementService: AlertManagementService,
    private dataManagementService: DataManagementService
  ) {
    this.alertManagementService.initDays();
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService.selectedVehicule!.idDevice
        );
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean , enableGlobalConfigToAllVehicules: boolean }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.getVehiculeWasSelectedSubscription = this.alertManagementService
      .getAlertByNameAndDeviceId('BRAKE', device)
      .subscribe(
        alert => {
          this.alert = alert;
          this.boardgamesAlertConfiguartion = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'BOARD_GAMES'
          )
            ? this.alert.alertConfigurations.filter(
                alertConfiguration => alertConfiguration.type === 'BOARD_GAMES'
              )[0]
            : this.boardgamesAlertConfiguartion;
          if (this.boardgamesAlertConfiguartion == null) {
            this.alertManagementService.initializeAlertConfiguration(
              'BOARD_GAMES'
            );
          }

          this.diskAlertConfiguartion = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'DISK'
          )
            ? this.alert.alertConfigurations.filter(
                alertConfiguration => alertConfiguration.type === 'DISK'
              )[0]
            : this.diskAlertConfiguartion;
          if (this.diskAlertConfiguartion == null) {
            this.alertManagementService.initializeAlertConfiguration('DISK');
          }

          this.pumpAlertConfiguartion = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'PUMP'
          )
            ? this.alert.alertConfigurations.filter(
                alertConfiguration => alertConfiguration.type === 'PUMP'
              )[0]
            : this.pumpAlertConfiguartion;
          if (this.pumpAlertConfiguartion == null) {
            this.alertManagementService.initializeAlertConfiguration('PUMP');
          }

          this.oilAlertConfiguartion = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'OIL'
          )
            ? this.alert.alertConfigurations.filter(
                alertConfiguration => alertConfiguration.type === 'OIL'
              )[0]
            : this.oilAlertConfiguartion;
          if (this.oilAlertConfiguartion == null) {
            this.alertManagementService.initializeAlertConfiguration('OIL');
          }

          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }
  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    if (this.showAdvancedSettings) {
      this.alertManagementService.initializeAlertConfigurationWithSameValue1(
        this.value1,
        this.alert.alertConfigurations
      );
    }
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.loader = false;
        this.alert = alert;
        this.toastr.success('Configuration enregistrée', 'info !');
      },
      error => {
        this.toastr.error('Configurations non enregistrée', 'info');
      }
    );
  }
  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }
}
