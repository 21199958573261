
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'component-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css'],
})
export class ToolBarComponent implements OnInit {



  constructor(
  ) {}

  ngOnInit() {
   
  }

  

  
}
