import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { Program, TimePlan } from '../data-management.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  timePlans: TimePlan[] = [];
  programs: Program[] = [];
  constructor(private _http: HttpClient) { }

  // list !
  findAll(): Observable<TimePlan[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<TimePlan[]>(dns + 'timePlan', { headers: headers });
  }


  addTimePlan(timePlan: TimePlan): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post(dns + 'timePlan/add', timePlan, options);
  }

  updateTimePlan(timePlan: TimePlan): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.put(dns + 'timePlan/update', timePlan, { headers: headers });
  }

  deleteTimePlan(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'timePlan/' + id, { headers: headers });
  }

  // valid !
  // getOne(id): Observable<any> {
  //   let headers = createAuthorizationHeader();
  //   return this._http.get(dns + 'timePlan/' + id, { headers: headers });
  // }

  // list !
  findAllProgram(): Observable<Program[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Program[]>(dns + 'program', { headers: headers });
  }

  addProgram(program: Program): Observable<any> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post(dns + 'program/add', program, options);
  }

  updateProgram(program: Program): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.put(dns + 'program/update', program, { headers: headers });
  }

  deleteProgram(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'program/' + id, { headers: headers });
  }

  // valid !
  getOneProgram(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'program/' + id, { headers: headers });
  }

}
