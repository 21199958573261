import { Component, OnInit, OnDestroy } from '@angular/core';
import { Alert, AlertConfiguration } from '../../alert';
import { ToastrService } from 'ngx-toastr';
import { AlertManagementService } from '../../alert-management.service';
import { DataManagementService } from '../../../data-management/data-management.service';
import { Subscription } from 'rxjs/Rx';

@Component({
  selector: 'app-batteries-settings',
  templateUrl: './batteries-settings.component.html',
  styleUrls: ['./batteries-settings.component.css']
})
export class BatteriesSettingsComponent implements OnInit, OnDestroy {
  public loader = false;
  public enableGlobalConfig = false;
  alert: Alert = new Alert();
  batterieAlertConfiguartion: AlertConfiguration = new AlertConfiguration();
  getVehiculeWasSelectedSubscription: Subscription;
  public enableGlobalConfigToAllVehicules = false;

  constructor(
    private toastr: ToastrService,
    public alertManagementService: AlertManagementService,
    private dataManagementService: DataManagementService
  ) {
    this.alertManagementService.initDays();
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService.selectedVehicule.idDevice
        );
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean,enableGlobalConfigToAllVehicules: boolean }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('BATTERY', device)
      .subscribe(
        alert => {
          this.alert = alert;
          this.batterieAlertConfiguartion = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'BATTERY_DURATION'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration =>
                alertConfiguration.type === 'BATTERY_DURATION'
            )[0]
            : this.batterieAlertConfiguartion;
          if (this.batterieAlertConfiguartion == null) {
            this.alertManagementService.initializeAlertConfiguration(
              'BATTERY_DURATION'
            );
          }
          
          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }
  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrée', 'info !');
      },
      error => {
        this.loader = false;
        this.toastr.success('Configuration Non enregistrée', 'info !');
      }
    );
  }
  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails, id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }
}
