import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('currentUser.token');

    if (token && !helper.isTokenExpired(token)) {
      return true;
    }
    this.router.navigate(['/signin']);
    return false;
  }
}
