import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverChargeFormComponent } from './driver-charge-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { ModalModule } from 'ngx-bootstrap/modal'; // Import ModalModule for modals
import { DriverService } from 'src/app/client-management/data-management/providers/driver.service';
import { DriverChargeService } from '../../providers/driver-charge-service';

@NgModule({
  declarations: [
    DriverChargeFormComponent // Declare the DriverChargeFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule to handle form controls
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(), // Import BsDatepickerModule for date picking
    ModalModule.forRoot() // Import ModalModule for modal functionality
  ],
  providers: [
    DriverService, // Provide DriverService for use within the module
    DriverChargeService // Provide DriverChargeService for use within the module
  ],
  exports: [
    DriverChargeFormComponent // Export DriverChargeFormComponent for use in other modules
  ]
})
export class DriverChargeFormModule { }
