import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TireFormComponent } from './tire-form.component'; // Adjust the path as necessary
import { FormsModule } from '@angular/forms'; // Import FormsModule for template-driven forms
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import BsDatepickerModule for date pickers
import { ProviderService } from '../../../providers/provider-service'; // Import ProviderService
import { TireService } from '../../../providers/tire-service'; // Import TireService
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    TireFormComponent // Declare the TireFormComponent
  ],
  imports: [
    CommonModule, // Import CommonModule for Angular common directives
    FormsModule, // Import FormsModule to handle form controls
    ToastrModule.forRoot(), // Import ToastrModule for notifications
    BsDatepickerModule.forRoot(),
    MatCheckboxModule,

  ],
  providers: [
    ProviderService, // Provide ProviderService
    TireService // Provide TireService
  ],
  exports: [
    TireFormComponent // Export TireFormComponent for use in other modules
  ]
})
export class TireFormModule { }
