import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NumeroOtComponent } from './numero-ot.component';
 // Assuming you're using NgxToastr for toast messages
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';  // Multi-select dropdown module
import { ScheduleService } from '../schedule.service';

@NgModule({
  declarations: [
    NumeroOtComponent  // Declare NumeroOtComponent in this module
  ],
  imports: [
    CommonModule,       // Import CommonModule for common Angular directives
    FormsModule,        // Import FormsModule for template-driven forms (ngForm)
    // NgxToastrModule,    // Toastr for notifications
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    ScheduleService  // Provide the ScheduleService for the component
  ],
  exports: [
    NumeroOtComponent  // Export the component to make it available in other modules
  ]
})
export class NumeroOtModule { }
