import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs/Subscription';
import { RapportDto, Vehicule } from "../../data-management/data-management.model";
import { DataManagementService } from "../../data-management/data-management.service";
import { ImportExportService } from '../../import-export';
import { MultiType, Provider } from "../model";
import { Fueling, Group } from '../model/index';
import { FuelingService } from '../providers/fueling-service';
import { TechnicalDataService } from 'src/app/client-management/statistical/technical-data/technical-data.service';
import { DeviceCarb } from 'src/app/client-management/statistical/technical-data/technical-data';

@Component({
    selector: 'app-carburant',
    templateUrl: './carburant.component.html',
    styleUrls: ['./carburant.component.css']
})
export class CarburantComponent implements OnInit {

  @ViewChild('addEditFuelModal', { static: false }) addEditFuelModal: ModalDirective;
  @ViewChild('importModal', { static: false }) importModal: ModalDirective;
  @ViewChild('fuelDeleteModal', { static: false }) fuelDeleteModal: ModalDirective;
  @ViewChild('dashBordFuelModal', { static: false }) dashBordFuelModal: ModalDirective;


  fuels: Fueling[] = [];

    fuelsId: number[] = [];

    repport: RapportDto = new RapportDto();

    currentFuel: Fueling = new Fueling();

    getVehiculeWasSelectedSubscription: Subscription;

    getGroupWasSelectedSubscription: Subscription;

    selectedVehicule: Vehicule = new Vehicule();

    group: Group = new Group();

    /** import  */
    selectedItem = 'FUEL';

    loading: boolean = false;

    isDeleteMultiple: boolean = false;

    mode = 'ADD';

  searchTerm: string | null = null;

    currentCarbSetting: DeviceCarb = new DeviceCarb();

    constructor(private fuelingService: FuelingService, private dataManagementService: DataManagementService,
        public toastr: ToastrService, public exportImport: ImportExportService,
        private technicalDataService: TechnicalDataService) {
        this.init();
    }

    ngOnInit() {
        if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
            this.selectedVehicule = this.dataManagementService.selectedVehicule;
            this.group = this.dataManagementService.selectedGroup;
            this.loadFuels();
            this.loadDeviceFuelStettings();
        }

        this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
            this.selectedVehicule = vehicule;
            this.loadFuels();
            this.loadDeviceFuelStettings();
        });

        this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
            this.group = group;
        });
    }

    ngOnDestroy() {
        if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
        if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    }

    init() {
        this.currentFuel = new Fueling();
    }

    getCostUnit(fuel: Fueling): number {
        if (fuel.cost > 0 && fuel.quantity > 0) {
            var costUnit = (fuel.cost / fuel.quantity).toFixed(2);
            return +costUnit;
        }
    }


    // diffOdometre(fuel : Fueling, index: number): number {
    //     if(!fuel)
    //     return null;
    //         if(index+1 < this.fuels.length && fuel.quantity){
    //             return fuel.odometre -  this.fuels[index+1].odometre;
    //         }else{
    //             return null;
    //         }
    //
    // }

    loadDeviceFuelStettings() {
        this.technicalDataService.getDeviceCarb(this.selectedVehicule.idDevice).subscribe(reponse => {
            this.currentCarbSetting = reponse;
            this.toastr.info('Configuration carburant chargé', 'info');
        }, (error)=> {
            this.currentCarbSetting  = new DeviceCarb();
            this.toastr.warning('Erreur lors de chargement de configuration carburant', 'info');
        });
    }

    loadFuels() {
        if (!this.selectedVehicule.idVehicule) return;
        this.loading = true;
        this.fuelingService.getListFueling(this.selectedVehicule.idVehicule).subscribe(fuels => {
            this.fuels = fuels;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    onConfirmDelete(fuel: Fueling) {
        this.currentFuel = fuel;
        if (!this.currentFuel.provider) {
            this.currentFuel.provider = new Provider();
        }

        if (!this.currentFuel.paymentType) {
            this.currentFuel.paymentType = new MultiType();
        }
        this.isDeleteMultiple = false;
        this.fuelDeleteModal.show();
    }

    /**
     * Event delete
     */
    onDelete() {
        if (this.isDeleteMultiple) {
            this.deleteMultiple();
        } else {
            this.delete();
        }
    }


    /**
      * Delete single
      */
    delete() {
        this.loading = true;
        this.fuelingService.delete(this.currentFuel.id).subscribe(response => {
            if (response) {
                this.fuels = this.fuels.filter(fuel => fuel.id !== this.currentFuel.id);
                this.fuelsId = this.fuelsId.filter(fuelId => fuelId != this.currentFuel.id);
                this.toastr.success('Carburant bien supprimés !', 'info');
            } else {
                this.toastr.error('Les documents ne sont pas supprimés correctement !', 'Erreur');
            }
            this.fuelDeleteModal.hide();
            this.loading = false;
        }, error => {
            let textError = error.json().errorMessage;
            if (error.json().errors) textError += ' ' + error.json().errors;
            this.toastr.error(textError, error.json().errorCode);
            this.loading = false;
        });
    }


    /**
     * Delete multiple
     */
    deleteMultiple() {
        this.loading = true;
        this.fuelingService.deleteMultiple(this.fuelsId).subscribe(response => {

            if (response) {

                for (let fuelId of this.fuelsId) {
                    this.fuels = this.fuels.filter(fuel => fuel.id != fuelId);
                }

                this.fuelsId = [];
                this.toastr.success('Suppression effectuée avec succès.', 'info');
            } else {
                this.toastr.error('Le carburant n\'a pas supprimé correctement !', 'Erreur');
            }

            this.fuelDeleteModal.hide();
            this.loading = false;
        }, error => {
            let textError = error.json().errorMessage;
            if (error.json().errors) textError += ' ' + error.json().errors;
            this.toastr.error(textError, error.json().errorCode);
            this.loading = false;
        });
    }

    updateFuel(fuel: Fueling) {
        this.currentFuel = Object.assign({}, fuel);
        if (this.currentFuel.operationTime) this.currentFuel.operationTime = new Date(this.currentFuel.operationTime);
        if (!this.currentFuel.provider) {
            this.currentFuel.provider = new Provider();
        }

        if (!this.currentFuel.paymentType) {
            this.currentFuel.paymentType = new MultiType();
        }
        this.mode = 'UPDATE';
        this.addEditFuelModal.show();
    }

    addFuel() {
        this.currentFuel = new Fueling();
        this.mode = 'ADD';
        this.addEditFuelModal.show();
    }

    fuelWasAdded(fuel: Fueling) {
        this.addEditFuelModal.hide();
        this.fuels.unshift(fuel);
    }

    fuelWasUpdated(fuel: Fueling) {
        for (let i = 0; i < this.fuels.length; i++) {
            if (this.fuels[i].id == fuel.id) {
                this.fuels[i] = fuel;
                break;
            }
        }

        this.fuels = Object.assign([], this.fuels);

        this.addEditFuelModal.hide();
    }

    export() {
        this.repport.type = 'FUELING';
        this.exportImport.export(this.fuels, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
    }

    itemWasImported(res: boolean) {
        if (res) {
            this.importModal.hide();
            this.loadFuels();
        }
    }


    /**
     * Event confirm delete multiple
     */
    onConfirmDeleteMultiple() {
        if (this.fuelsId.length == 0) {
            this.toastr.warning("Veuillez choisir des carburant à supprimer !");
            return;
        }
        this.isDeleteMultiple = true;
        this.fuelDeleteModal.show();
    }

    /**
     * get list search
     */
    getFuels(): Fueling[] {
        if ((this.searchTerm) && (this.searchTerm.length > 0)) {
            return this.fuels.filter(fuel => (fuel.provider) && (fuel.provider.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
        } else {
            return this.fuels;
        }
    }


    /**
     * Event check row for delete multiple
     */
    onChecked(id: number) {
        if (this.fuelsId.find(fuelId => fuelId == id)) {
            this.fuelsId = this.fuelsId.filter(fuelId => fuelId != id);
        } else {
            this.fuelsId.push(id);
        }
    }

    /**
     * Event check all row for delete multiple
     */
    onCheckedAll() {
        if (this.fuelsId.length == this.fuels.length) {
            this.fuelsId = [];
        } else {
            this.fuelsId = [];
            for (let fuel of this.fuels) {
                this.fuelsId.push(fuel.id);
            }
        }
    }

    /**
     * check if id exists in array,
     */
    isCheckedInList(id: number): boolean {
        return this.fuelsId.find(fuelId => fuelId == id) ? true : false;
    }

    /**
     * show dashbord
     */
    showDashBord() {
        this.fuels = Object.assign([], this.fuels);
        this.dashBordFuelModal.show();
    }



    getFuelConsumption(fuel : Fueling, index: number): number {
        if(!fuel)
        return null;
        if(fuel.consumption){
            return fuel.consumption;
        }else{
            if(index+1 < this.fuels.length && fuel.quantity){
                var result;
                if(fuel.signification.toLowerCase() == 'h'){
                    result = fuel.quantity / (fuel.odometre -  this.fuels[index+1].odometre );
                }else{
                    result = fuel.quantity / (fuel.odometre -  this.fuels[index+1].odometre )* 100 ;
                }
                return +result;
            }else{
                return null;
            }
        }
    }
}
