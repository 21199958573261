import { Component, OnInit, Input } from '@angular/core';
import { ChargePfbInfoDto, ChargeRealise, VoyageDto } from '../../charges-pfb';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
const Highcharts16 = require('highcharts');

require('highcharts/modules/exporting')(Highcharts16);
require('highcharts/modules/no-data-to-display')(Highcharts16);
require('highcharts/highcharts-more')(Highcharts16);

@Component({
  selector: 'app-correlation-location',
  templateUrl: './correlation-location.component.html',
  styleUrls: ['./correlation-location.component.css']
})
export class CorrelationLocationComponent implements OnInit {
  chargePfbInfoDto: ChargePfbInfoDto;
  correlationLocationChart: Object | null = null;
  correlationLocationTimeLineChart: Object | null = null;
  voyage_: VoyageDto = new VoyageDto();
  globalLoading: boolean;

  selectedCharge: ChargePfbInfoDto = new ChargePfbInfoDto();
  dayDif: number = 0;
  chart: any;
  caRealise_: any[];
  percentageRealise = 0;
  percentageCible = 0;


  @Input()
  set selectedCharges(chargePfb: ChargePfbInfoDto) {
    if (chargePfb) {
      this.selectedCharge = chargePfb;
    }
  }

  @Input()
  set dayDifference(dayDifference: number) {
    this.dayDif = dayDifference;
  }

  @Input() isManually: any;

  @Input()
  set caRealise(caRealise: ChargeRealise[]) {
    this.caRealise_ = Object.assign(caRealise);
    if (this.isManually) {
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.gaugeChart();
    }
  }

  @Input()
  set voyage(voyage: VoyageDto) {
    if (!this.isManually) {
      if(voyage)
      this.voyage_ = Object.assign(voyage);
      if (this.dayDif > 1)
        this.timeChart();
      else
        this.gaugeChart();
    }
  }

  constructor(public toastr: ToastrService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  ngOnInit() {
    this.gaugeChart();
  }

  timeChart() {

    var data: { x: Date, y: number }[] = [];
    var index = 0;

    if (this.selectedCharge) {
      var budget = (this.selectedCharge.caTransport / this.selectedCharge.nbDays);
      var transportCharge = (this.selectedCharge.transportCharge / this.selectedCharge.nbDays);

      this.percentageCible = (transportCharge / budget) * 100;
    }

    this.caRealise_.forEach(ca => {
      let location;
      if (this.isManually)
        location = ca.location;
      else {
        let lc = [];
        const travelsByDate = new Map(Object.entries( this.voyage_.travelsByDate).sort((a, b) =>{
          return (a < b) ? -1 : 1
         }));

         if (travelsByDate.size > 0) {
          lc = travelsByDate.get(ca.date.toString());
        }
        if (lc && lc.length > 0)
          location = lc.reduce((acc: any, cur: any) => acc + cur.location, 0);
      }

      data.push({ x: new Date(ca.date), y: (location / ca.caRealise) * 100 });
      if (index == this.caRealise_.length - 1)
        this.percentageRealise = (location / ca.caRealise) * 100;
      index++;
    })

    this.correlationLocationTimeLineChart = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      plotOptions: {
        series: {
          connectNulls: true
         },
        enableMouseTracking: false
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: ' %'
      },
      title: {
        text: 'Corrélation Charge location/Chiffre d\'affaire',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 86400000,
        labels: {
          formatter: function (this: { value: number }): string {
            return Highcharts16.dateFormat("%d/%m/%Y", this.value);
          }
        }
      },
      yAxis: {
        minRange: this.percentageCible,
        min: 0,
        plotLines: [{
          value: this.percentageCible,
          color: '#ff0000',
          dashStyle: 'shortdash',
          width: 2,
          zIndex: 4,
          label: {
            text: 'Cible',
            textAlign: 'center'
          }
        }],
      },
      series: [{
        name: 'Réalisé',
        data: data,
        zones: [{
          value: this.percentageCible,
          color: 'green'
        }, {
          value: this.percentageCible + 6,
          color: 'orange'
        }, {
          color: 'red'
        }]
      }]

    }, Highcharts16.chart('correlationLocationChart', this.correlationLocationTimeLineChart);

  }


  gaugeChart() {

    var color = '#FFA500';
    this.percentageRealise = 0;
    if (this.selectedCharge) {
      var budget = (this.selectedCharge.caTransport / this.selectedCharge.nbDays) * this.dayDif;
      var transportCharge = (this.selectedCharge.transportCharge
        / this.selectedCharge.nbDays) * this.dayDif;

      this.percentageCible = (transportCharge / budget) * 100;
    }

    if (this.caRealise_) {
      var caR = this.caRealise_.filter(ca => ca.caRealise > 0);
      if (caR.length > 0) {
        if (this.isManually) {
          var caLocation = this.caRealise_.filter(ca => ca.location > 0);
          if (caLocation.length > 0) {
            this.percentageRealise = (caLocation[caLocation.length - 1].location / caR[caR.length - 1].caRealise) * 100;
          }
        } else {
          let location = [];
          let lastLocation = 0;
          const travelsByDate = new Map(Object.entries(this.voyage_.travelsByDate).sort((a, b) =>{
            return (a < b) ? -1 : 1
           }));

          if (travelsByDate.size > 0) {
            location = travelsByDate.get(caR[caR.length - 1].date.toString());
            if (location && location.length > 0) {
              lastLocation = location.reduce((acc: any, cur: { location: any; }) => acc + cur.location, 0);
            }
          }
          this.percentageRealise = (lastLocation / caR[caR.length - 1].caRealise) * 100;
        }
      }
    }

    if (this.percentageCible > 0) {
      if (this.percentageRealise >= this.percentageCible) {
        if ((this.percentageRealise - this.percentageCible) > 5) {
          color = "#c90000";
        }
      } else {
        color = '#008000';
      }
    }

    this.correlationLocationChart = {

      chart: {
        type: 'solidgauge',
      },

      title: {
        text: 'Corrélation Charge location/Chiffre d\'affaire',
        align: 'center',
        style: {
          fontWeight: 'bold'
        }
      }, credits: {
        enabled: false
      },
      tooltip: {
        enabled: false,
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ // Réalisé
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: '#f5b198',
          borderWidth: 0
        }]
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {

          dataLabels: {
            enabled: true,
            y: -40,
            borderWidth: 0,
            backgroundColor: 'none',
            useHTML: true,
            shadow: false,
            style: {
              fontSize: '13px'
            },
            // formatter: function () {
            //   return '<div style="width:100%;text-align:center;"><span style="font-size:13px;font-weight:bold;">' + this.point.series.name + '</span><br/><span style="font-size:3em;color:' + color + ';font-weight:bold;">' + Highcharts16.numberFormat(this.y, 1) + '%</span>';
            // }
          },


          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },

      series: [{
        name: 'Réalisé',
        data: [{
          color: color,
          radius: '87%',
          innerRadius: '63%',
          y: this.percentageRealise
        }]
      }],
      lang: {
        printChart: 'Imprimer',
        thousandsSep: ',',
        downloadPNG: 'Télécharger image PNG',
        downloadJPEG: 'Télécharger image JPEG',
        downloadPDF: 'Télécharger image PDF',
        downloadSVG: 'Télécharger image SVG',
        drillUpText: '◁ Retour',
        noData: 'Aucune donnée n\'est disponible'
      }
    },
      Highcharts16.chart('correlationLocationChart', this.correlationLocationChart)
  }



}
