import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SigninService } from '../../../authentification/signin/signin.service';
import { imagesDir } from '../../../global.config';
import { DataManagementService } from '../../data-management/data-management.service';
import { Subscription } from 'rxjs';
import {
  Group,
  VehiculesDtoWithGroup,
  VehiculesDto
} from '../../data-management/data-management.model';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DateInterval } from '../../../shared/model';
import { User, Authority } from 'src/app/authentification/signin/credentials';
import { Passage, PassageService, TransportTypeInfoDto, TransportTypeService } from '../../charges-pfb';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.css']
})
export class AccountFormComponent implements OnInit, OnDestroy {
  @Input() user: User = new User();

  @Input() mode = 'ADD';

  @Input() isCollabsed = false;

  showPW = false;
  public id: number;
  public currentUser: User | null = null;
  public profileAvatar: string = imagesDir + '/profil.png';
  public settingsAvatar: string = imagesDir + '/settings.png';

  AllGroups: Subscription;

  public authorities: Authority[];
  public authoritiesItems: any[];
  public authoritiesSelected: any[];

  groups: Group[];
  public groupsItems: any[];
  public groupsSelected: any[];

  vehicules: VehiculesDtoWithGroup[];
  public vehiculesItems: any[];
  public vehiculesSelected: any[];

  transports: TransportTypeInfoDto[];
  public transportsItems: any[];
  public transportsSelected: any[];

  passages: Passage[];
  public sitesItems: any[];
  public sitesSelected: any[];

  loading = false;
  globalLoading = false;

  dateInterval: DateInterval = new DateInterval();

  authoritiesDropdownSettings = {}
  groupDropdownSettings : any;

  constructor(
    private dialog: MatDialog,
    private signinService: SigninService,
    private dataManagementService: DataManagementService,
    private transportTypeService: TransportTypeService,
    private passageService: PassageService,
    public toastr: ToastrService
  ) {
    let now = new Date();
    this.dateInterval.endDate = now.getTime();
    this.dateInterval.startDate = now.setDate(now.getDate() - 3);

    const currentUserString = localStorage.getItem('currentUser');
    this.currentUser = currentUserString ? JSON.parse(currentUserString) : {};

    this.signinService.getAccountWasUpdated().subscribe(account => {
      if (this.mode === 'ADD' || this.mode === 'UPDATE') {
        /**passage par copie */
        this.user = { ...account };
        this.user.username = this.user.username.split("_")[1];
        this.mode = 'UPDATE';
        this.groupsSelected = this.getItemsFromGroup(this.user.groups);
        this.authoritiesSelected = this.getItemsFromAuthorities(this.user.authorities);
        this.vehiculesSelected = this.getItemsFromVehicule(this.user.vehicules);
        this.transportsSelected = this.getItemsFromTransportType(this.user.transportTypes);
        this.sitesSelected = this.getItemsFromSites(this.user.passages);
      }
    });

    this.signinService.getGroupeWasDeleted().subscribe(g => {
      for (let i = 0; i < this.signinService.accounts.length; i++) {
        for (let j = 0; j < this.signinService.accounts[i].groups.length; j++) {
          if (this.signinService.accounts[i].groups[j].idGroupe === g.idGroupe) {
            this.signinService.accounts[i].groups.splice(j, 1);
            j--;
          }
        }
      }
    });
  }


  ngOnInit() {
    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 22,
      allowSearchFilter: true,
    };

    this.authoritiesDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 22,
      allowSearchFilter: true
    };

    if (this.mode !== 'SHOW') {
      if (this.signinService.authorities) {
        this.authorities = this.signinService.authorities;
        this.authoritiesToItems(this.authorities);
      } else {
        this.signinService.getAuthorities().subscribe(authorities => {
          this.authorities = authorities;
          this.signinService.authorities = authorities;
          this.authoritiesToItems(this.authorities);
        });
      }

      if (this.transportTypeService.transportTypes.length > 0) {
        this.transports = this.transportTypeService.transportTypes;
        this.transportTypesToItems(this.transports);
      } else {
        this.transportTypeService.findAllTransportTypes().subscribe(transportTypes => {
          this.transports = transportTypes;
          this.transportTypeService.transportTypes = transportTypes;
          this.transportTypesToItems(this.transports);
        });
      }

      if (this.passageService.passages.length > 0) {
        this.passages = this.passageService.passages;
        this.sitesToItems(this.passages);
      } else {
        this.passageService.findAllPassages().subscribe(sites => {
          this.passages = sites;
          this.passageService.passages = sites;
          this.sitesToItems(this.passages);
        });
      }


      if (this.dataManagementService.groups) {
        this.groups = this.dataManagementService.groups;
        this.groupsToItems(this.groups);
      } else {
        this.AllGroups = this.dataManagementService
          .getAllGroupsDetails('')
          .subscribe(groups => {
            this.groups = groups;
            this.dataManagementService.groups = groups;
            this.dataManagementService.setGroups(groups);
            this.groupsToItems(this.groups);
          });
      }
      this.signinService.getGroupeWasCreatedOrUpdate().subscribe(group => {
        this.AllGroups = this.dataManagementService
          .getAllGroupsDetails('')
          .subscribe(groups => {
            this.groups = groups;
            this.dataManagementService.groups = groups;
            this.dataManagementService.setGroups(groups);
            this.groupsToItems(this.groups);
          });
      });

      this.getAllVehicules();
    }
  }

  toggleReset(): void {
    // this.user.enabled = !this.user.enabled;
  }

  getAllVehicules() {
    this.dataManagementService.getAllVehicules().subscribe(vehicule => {
      this.vehicules = vehicule;
      this.vehiculesToItems(this.vehicules, 0);
    });
  }

  ngOnDestroy() {
    if (this.AllGroups) { this.AllGroups.unsubscribe(); }
  }

  init() {
    this.user = new User();
    this.groupsSelected = [];
    this.vehiculesSelected = [];
    this.authoritiesSelected = [];
    this.transportsSelected = [];
    this.sitesSelected = [];
    this.mode = 'ADD';
  }

  toggle() {
    this.isCollabsed = !this.isCollabsed;
  }

  authoritiesToItems(authorities: Authority[]) {
    this.authoritiesItems = [];
    for (let i = 0; i < authorities.length; i++) {
      if (authorities[i].name !== 'ROLE_ROOT') {
        this.authoritiesItems.push({
          id: authorities[i].id,
          text: authorities[i].label
        });
      }
    }
  }

  transportTypesToItems(transportTypes: TransportTypeInfoDto[]) {
    this.transportsItems = [];
    for (let i = 0; i < transportTypes.length; i++) {
      this.transportsItems.push({
        id: transportTypes[i].idTransportType,
        text: transportTypes[i].name
      });
    }
  }

  sitesToItems(sites: Passage[]) {
    this.sitesItems = [];
    for (let i = 0; i < sites.length; i++) {
      this.sitesItems.push({
        id: sites[i].idPassage,
        text: sites[i].name
      });
    }
  }

  getItemsFromGroup(groups: Group[]) {
    let groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      let parentName: string;
      if (groups[i].parent) {
        parentName = groups[i].parent.nom + ' / ' + groups[i].nom;
      }
      else { parentName = groups[i].nom }
      groupsItems.push({
        id: groups[i].idGroupe,
        text: parentName
      });
    }
    return groupsItems;
  }

  getItemsFromVehicule(vehicules: VehiculesDtoWithGroup[]) {
    let vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      vehiculesItems.push({
        id: {
          id: vehicules[i].idVehicule,
          idG: 0
        },
        text: vehicules[i].matricule
      });
    }
    return vehiculesItems;
  }

  getItemsFromTransportType(transportTypes: TransportTypeInfoDto[]) {
    let transportTypesItems = [];
    for (let i = 0; i < transportTypes.length; i++) {
      transportTypesItems.push({
        id: transportTypes[i].idTransportType,
        text: transportTypes[i].name
      });
    }
    return transportTypesItems;
  }

  getItemsFromSites(sites: Passage[]) {
    let sitesItems = [];
    for (let i = 0; i < sites.length; i++) {
      sitesItems.push({
        id: sites[i].idPassage,
        text: sites[i].name
      });
    }
    return sitesItems;
  }

  getItemsFromAuthorities(authorities: Authority[]) {
    let authoritiesItems = [];
    for (let i = 0; i < authorities.length; i++) {
      authoritiesItems.push({
        id: authorities[i].id,
        text: authorities[i].label
      });
    }
    return authoritiesItems;
  }

  getItemsFromAuthorities2(authorities: any[]) {
    let authoritiesItems = [];
    for (let i = 0; i < authorities.length; i++) {
      authoritiesItems.push({
        id: authorities[i].id,
        label: authorities[i].text
      });
    }
    return authoritiesItems;
  }

  groupsToItems(groups: Group[]) {
    this.groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      let parentName: string;
      if (groups[i].nameGroupParent) {
        parentName = groups[i].nameGroupParent + ' / ' + groups[i].nom;
      } else { parentName = groups[i].nom }
      this.groupsItems.push({
        id: groups[i].idGroupe,
        text: parentName
      });
    }
  }

  vehiculesToItems(vehicules: VehiculesDto[], idGroup: number) {
    this.vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesItems.push({
        id: {
          id: vehicules[i].idVehicule,
          idG: idGroup
        },
        text: vehicules[i].matricule
      });
    }
  }

  itemsToGroups(
    items: {
      id: number;
      text: string;
    }[]
  ) {
    let groups: any[] = [];
    for (let i = 0; i < items.length; i++) {
      groups.push({
        idGroupe: items[i].id,
        nom: items[i].text
      });
    }
    return groups;
  }

  itemsToVehicules(
    items: {
      id: {
        id: number;
        idG: number;
      };
      text: string;
      parent: number;
    }[]
  ) {
    let vehicules: any[] = [];
    for (let i = 0; i < items.length; i++) {
      vehicules.push({
        idVehicule: items[i].id.id,
        idGroup: items[i].id.idG,
        matricule: items[i].text
      });
    }
    return vehicules;
  }

  // selectedAuthorities($event) {
  //   this.user.authorities = $event;
  // }

  selectedSites($event: any) {
    this.user.passages = this.itemsToSites($event);
  }

  itemsToSites(
    items: {
      id: number;
      text: string;
    }[]
  ) {
    let sites: any[] = [];
    for (let i = 0; i < items.length; i++) {
      sites.push({
        idPassage: items[i].id,
        name: items[i].text
      });
    }
    return sites;
  }

  selectedTransports($event: any) {
    this.user.transportTypes = this.itemsToTransports($event);
  }

  itemsToTransports(
    items: {
      id: number;
      text: string;
    }[]
  ) {
    let transports: any[] = [];
    for (let i = 0; i < items.length; i++) {
      transports.push({
        idTransportType: items[i].id,
        name: items[i].text
      });
    }
    return transports;
  }

  selectedGroups($event: any) {
    this.user.groups = this.itemsToGroups($event);
    if (this.user.groups.length > 0) {
      this.user.groups.forEach(group => {
        /**add vehicules of group without redundancy */
        let g = this.groups.filter(item => {
          return item.idGroupe === group.idGroupe ? item : null;
        });
        if (g.length > 0) {
          this.vehiculesToItems(g[0].vehicules, g[0].idGroupe);
        }
      });
    } else {
      this.getAllVehicules();
    }
  }

  selectedVehicules($event: any) {
    this.user.vehicules = this.itemsToVehicules($event);
  }

  addSubAccount() {
    this.globalLoading = true;
    this.user.authorities = this.authoritiesSelected;
    if (this.user) {
      if (this.user.email === '') {
        this.user.email = null;
      }
    }
    this.signinService.addAccount(this.user).subscribe(
      subAccount => {
        this.init();
        this.signinService.sendAccountWasCreated(subAccount);
        this.globalLoading = false;
        this.toastr.success(
          'Sous compte ' + subAccount.username + ' est crée !',
          'info'
        );
      },
      (error) => {
        this.globalLoading = false;

        if (error.status == 409) {
          this.toastr.error('Login existe déjà', 'Erreur!');
        } else {
          this.toastr.error("l'opération a échoué !", 'Erreur');
        }
      }
    );
  }

  deleteSubAccount() {
    if (confirm('etes vous sur ?')) {
      this.globalLoading = true;
      this.signinService.deleteAccount(this.user.id).subscribe(
        isDeleted => {
          this.signinService.sendAccountWasDeleted(this.user);
          this.globalLoading = false;

          this.toastr.success(
            'Sous compte ' + this.user.username + ' est supprimé !',
            'info'
          );
        },
        () => {
          this.globalLoading = false;

          this.toastr.error("l'opération a échoué !", 'Erreur');
        }
      );
    }
  }

  editSubAccount() {
    this.globalLoading = true;
    this.user.authorities = this.authoritiesSelected;
    this.signinService.updateAccount(this.user).subscribe(
      subAccount => {
        this.signinService.sendAccountWasChanged(subAccount);
        this.globalLoading = false;
        this.toastr.success(
          'Sous compte ' + subAccount.username + ' est modifié !',
          'info'
        );
        this.init();
      },
      () => {
        this.globalLoading = false;
        this.toastr.error("l'opération a échoué !", 'Erreur');
      }
    );
  }

  sendMail() {
    if (confirm('etes vous sur de vouloir envoyer cet email?'))
      this.signinService
        .sendMailAccount(this.user)
        .map(res => res)
        .subscribe(subAccount => {
          if (subAccount.status === 200) {
            this.toastr.success('votre mail a été envoyer avec succes', 'info');
          } else { this.toastr.error("l'envoie de mail a échoué", 'info'); }
        });
    else { this.toastr.error("l'envoie de mail a été annuler", 'info'); }
  }

  updateSubAccount() {
    this.signinService.sendAccountWasUpdated(this.user);
  }

  /* déctiver ou activé sous compte */
  enableOrdisableAccount() {
    this.signinService.disableOrEnaleAccount(this.user.id).subscribe(
      subAccount => {
        this.user = subAccount;
        this.toastr.success(
          'Sous compte ' + subAccount.username + ' est activé/désactivé !',
          'info'
        );
      },
      () => {
        this.toastr.error("l'opération a échoué !", 'Erreur');
      }
    );
  }

  loadAction(userId: number) {
    this.loading = true;
    this.signinService.getActions(userId, this.dateInterval).subscribe(
      actions => {
        this.user.actions = actions;
        this.loading = false;
        this.dateInterval.startDate =
          this.dateInterval.startDate - 3 * 24 * 3600 * 1000;
      },
      () => {
        this.loading = false;
      }
    );
  }

  nombreOfDays(): number {
    return Math.floor(
      (this.dateInterval.endDate - this.dateInterval.startDate) / 86400000
    );
  }
}
