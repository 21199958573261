<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="row">

  <div class="col-md-12">

    <!-- Error Section !-->
    <ng-container *ngIf="error.errorMessage">
      <div class="col-md-12">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
            ×
          </button>
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          {{error.errorMessage}}
        </div>
      </div>
    </ng-container>

    <div class="container">
      <ng-container *ngIf="(mode == 'ADD' || mode == 'UPDATE' )&& !loading">
        <div class=" mt-2 mb-2 text-center   well bgg">
          <h4 *ngIf="mode == 'ADD'" style="font-family: 'Inconsolata', monospace;font-weight: bold;color: white;">
            <i class="fa fa-plus-circle add"></i>
            <span>Ajouter un nouveau type de canal </span>
          </h4>

          <h4 *ngIf="mode == 'UPDATE'" style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
            <i class="fa fa-cog"></i>
            <span>Modifier type de canal </span>
          </h4>
        </div>
        <div class="col-lg-12 well formBC">
          <div class="row">
            <form #canalForm="ngForm" class="form-horizontal">
              <div class="col-sm-12">

                  <!-- label -->
                  <div class="form-group mrg">
                    <label>Label *</label>
                    <input [(ngModel)]="currentCanal.name" required name="type" type="text" placeholder="Label"
                      class="form-control">
                  </div>

                <div class="form-group mrg">
                  <label>Groupes</label>
                  <!-- <ng-multiselect-dropdown-angular7 name="groupsSelected" [data]="groups"
                    placeholder="select groupe" [(ngModel)]="currentCanal.groups"
                    [settings]="groupsDropdownSettings" required>
                  </ng-multiselect-dropdown-angular7> -->

                  <ng-multiselect-dropdown
                  [placeholder]="'personnalisé'"
                  [settings]="groupDropdownSettings"
                  [data]="groups"
                  [(ngModel)]="currentCanal.groups"
                >
                </ng-multiselect-dropdown>
                </div>

              </div>
              <!-- ========================== Modal FOOTER (START) ===============================-->
              <div class="box-footer">
                <button (click)="addCanal()" *ngIf="mode == 'ADD'" class="btn btn-info pull-right"
                  [disabled]="!canalForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
                </button>
                <button (click)="updateCanal()" *ngIf="mode == 'UPDATE'" class="btn btn-info pull-right"
                  [disabled]="!canalForm.valid">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
                </button>
                <button class=" btn btn-dark" (click)="cancel()">
                  Annuler
                </button>
              </div>
              <!-- ========================== Modal FOOTER (END) ===============================-->
            </form>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div>
