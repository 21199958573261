import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Intervetion, InterventionService } from '../intervention-list/intervention.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-intervention-form',
  templateUrl: './intervention-form.component.html',
  styleUrls: ['./intervention-form.component.css']
})
export class InterventionFormComponent implements OnInit {

  @Input()
  intervention: Intervetion = new Intervetion();

  @Input()
  mode = 'ADD';

  @Output()
  wasAdded: EventEmitter<Intervetion> = new EventEmitter<Intervetion>();

  @Output()
  wasUpdated: EventEmitter<Intervetion> = new EventEmitter<Intervetion>();

  constructor(private interventionService: InterventionService, public toastr: ToastrService) { }

  ngOnInit() {
  }

  onAdd() {
    this.interventionService.add(this.intervention).subscribe(intervention => {
      this.wasAdded.emit(intervention);
      this.toastr.success('Demande bien ajoutée');
      this.init();
    }, (err) => {
      this.toastr.error(err);
    });
  }

  onUpdate() {
    this.interventionService.update(this.intervention).subscribe(intervention => {
      this.wasAdded.emit(intervention);
      this.toastr.success('Demande bien modifiée');
      this.init();
    }, (err) => {
      this.toastr.error(err);
    });
  }

  init() {
    this.intervention = new Intervetion()
  }
}
