import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { interval, Observable, Subscription } from 'rxjs';
import { DateInterval } from 'src/app/shared/model';
import { DataManagementService, GroupDto, RapportDto } from '../../data-management';
import { PathService } from '../../historical';
import { FuelingPriceSettingService, TravelReportDto } from '../../parc-management';
import { saveAs as importedSaveAs } from 'file-saver';
import { ImportExportService } from '../../import-export';
import { TransportTypeService, TransportTypeTravelReport } from '../parametrage/transport-type';

@Component({
  selector: 'app-travel-report',
  templateUrl: './travel-report.component.html',
  styleUrls: ['./travel-report.component.css']
})
export class TravelReportComponent implements OnInit, OnDestroy {


  travelReports : Observable<TravelReportDto[]>;
  expandData = false;
  selectedGroup = new GroupDto();
  groups: GroupDto[] = [];
  loading = false;
  travelReportLoader = false;
  dateInterval: DateInterval = new DateInterval();
  startDate: Date = new Date();
  selectedTransport = new TransportTypeTravelReport();
  vehiculesId: number[] = [];
  // travelReports$: Observable<TravelReportDto[]>;
  travelReportToExport: TravelReportDto[] = [];
  repport = new RapportDto();

  updateTrackingTimer: number | null = null;
  updateTrackingTimerSubscription: Subscription | null = null;

  expand: boolean[] = [];
  sizeTable = 0;
  s201 = 0;
  s202 = 0;
  s203 = 0;
  s204 = 0;
  s205 = 0;
  s211 = 0;
  s212 = 0;

  idSelected: number | null = null;

  constructor(
    private groupService: DataManagementService,
    public transportTypeService: TransportTypeService,
    public toastr: ToastrService,
    private pathService: PathService,
    private fuelingPriceSettingService: FuelingPriceSettingService,
    private service: ImportExportService
  ) {
    this.travelReports = this.pathService.getTravelReport();

    this.repport.type = 'PATH_APPRO_FUEL_POI';
    this.repport.rapportPayloadDto.byGroups = false;
    this.repport.rapportPayloadDto.byVehicules = true;
    this.repport.rapportPayloadDto.allVehicules = false;

    this.travelReports.subscribe(travelReport => {
      this.travelReportToExport = travelReport;
      this.sizeTable = travelReport.length;
      this.expand = new Array(travelReport.length).fill(false);
      const count: Record<string, number> = {};
      const sites = travelReport.map(x => x.startAddress);
      sites.forEach(i => count[i] = (count[i] || 0) + 1);
      const objToMap = new Map(Object.entries(count));
      this.s201 = Number(objToMap.get("201") || 0);
      this.s202 = Number(objToMap.get("202") || 0);
      this.s203 = Number(objToMap.get("203") || 0);
      this.s204 = Number(objToMap.get("204") || 0);
      this.s205 = Number(objToMap.get("205") || 0);
      this.s211 = Number(objToMap.get("211") || 0);
      this.s212 = Number(objToMap.get("212") || 0);
    });
  }

  ngOnInit(): void {
    this.loadLastFueulingPrice();
    this.initializeDateInterval();
    this.loadTransportTypes();
    this.startUpdateTrackingTimer();
  }

  initializeDateInterval(): void {
    this.dateInterval.startDate = new Date(this.startDate.getTime());
    this.dateInterval.endDate = new Date(this.startDate.getTime());
    this.dateInterval.startDate.setHours(0, 0, 0, 0);
    this.dateInterval.endDate.setHours(23, 59, 59, 59);
  }

  loadLastFueulingPrice(): void {
    this.loading = true;
    this.fuelingPriceSettingService.getLastFuelingPrice().subscribe(
      fuelingPrice => {
        this.pathService.fuelingPriceSetting = fuelingPrice;
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.toastr.error('Erreur lors du chargement', 'Prix Carburant');
      }
    );
  }

  loadTransportTypes(): void {
    if (this.transportTypeService.transportTypeTravelReport.length <= 0) {
      this.loading = true;
      this.transportTypeService.getAllTransportType().subscribe(
        transportTypes => {
          this.transportTypeService.transportTypeTravelReport = transportTypes;
          if (transportTypes.length > 0) {
            this.selectTransport(transportTypes[transportTypes.length - 1]);
            this.loadTravelReport();
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.toastr.error('Erreur lors du chargement', 'Type de transport');
        }
      );
    } else {
      this.selectTransport(this.transportTypeService.transportTypeTravelReport[this.transportTypeService.transportTypeTravelReport.length - 1]);
      this.loadTravelReport();
    }
  }

  selectTransport(transport: TransportTypeTravelReport): void {
    this.selectedTransport = transport;
    this.idSelected = transport.idTransportType;
    this.vehiculesId = transport.vehicules.map(v => v.idVehicule);
    this.stopTracking();
    this.loadTravelReport();
    this.startUpdateTrackingTimer();
  }

  chooseTransport(item :any){
  }

  loadTravelReport(): void {
    this.loading = true;
    const transportTypeId = [this.selectedTransport.idTransportType];
    const startDate = this.pathService.deviceStartDateMap.get(transportTypeId[0]) || new Date();
    startDate.setHours(0, 0, 0, 0);
    this.pathService.generateTravelReport( transportTypeId, { startDate, endDate: this.dateInterval.endDate }
    ).subscribe(
      travelReport => {
        this.pathService.updateTravelReport(travelReport, this.selectedTransport.vehicules, transportTypeId, startDate);
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.toastr.error('Erreur lors du chargement', 'Véhicules');
      }
    );
  }

  expandRow(i: number): void {
    this.expand[i] = !this.expand[i];
  }
  
  expendedRow(i: number){
    return this.expand[i]
  }


  expandAll(): void {
    this.expand = new Array(this.sizeTable).fill(!this.expand[0]);
  }

  stopTracking(): void {
    this.updateTrackingTimerSubscription?.unsubscribe();
    this.updateTrackingTimer = null;
  }

  ngOnDestroy(): void {
    this.stopTracking();
  }

  startUpdateTrackingTimer(): void {
    this.stopTracking();
    this.updateTrackingTimer = 1200;
    this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
      if (this.updateTrackingTimer && this.updateTrackingTimer > 0) {
        this.updateTrackingTimer--;
      } else {
        this.stopTracking();
        this.loadTravelReport();
      }
    });
  }

  refresh(): void {
    this.loadTravelReport();
    this.startUpdateTrackingTimer();
  }

  exportTravelReport(): void {
    this.travelReportLoader = true;
    this.repport.dateInterval = this.dateInterval;
    this.repport.rapportPayloadDto.idVehicules = this.selectedTransport.vehicules.map(v => v.idVehicule);
    this.repport.travelsReport = this.travelReportToExport;

    this.service.getTravelRepport(this.repport, this.selectedTransport.name).subscribe(
      blob => {
        if (blob.size > 0) {
          importedSaveAs(blob, 'Suivi voyages.xlsx');
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates!', 'Erreur');
        }
        this.travelReportLoader = false;
      },
      () => {
        this.toastr.warning('Aucune donnée entre ces deux dates!', 'Erreur');
        this.travelReportLoader = false;
      }
    );
  }
}