import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PointInterest, PoiClient } from "../../data-management.model";
import { imagesPOIDir } from "../../../../global.config";
import { MapService } from "../../../../utils/leaflet/service/map.service";
import { DataManagementService } from "../../data-management.service";
import { GeocodingService } from "../../../../utils/leaflet/service/geocoding.service";
import * as L from 'leaflet';
import { ToastrService } from "ngx-toastr";
import {Icon, Marker, Polygon} from "leaflet";

@Component({
    selector: 'app-poi-form',
    templateUrl: './poi-form.component.html',
    styleUrls: ['./poi-form.component.css']
})
export class PoiFormComponent implements OnInit {

    /** loader */
    loader: boolean = false;
    @Input()
    mode: string = 'ADD';

    @Input()
    pointInterest: PointInterest = new PointInterest();

    @Input()
    selectedPoiForm: String;

    poiClient: PoiClient = new PoiClient();

    @Output()
    pointInterestWasAdded = new EventEmitter();

    @Output()
    pointInterestWasUpdated = new EventEmitter();

    images = [
        { text: "marker1", uri: imagesPOIDir + "marker1.png", value: "marker1.png" },
        { text: "marker2", uri: imagesPOIDir + "marker2.png", value: "marker2.png" },
        { text: "marker3", uri: imagesPOIDir + "marker3.png", value: "marker3.png" },
        { text: "marker4", uri: imagesPOIDir + "marker4.png", value: "marker4.png" },
        { text: "marker5", uri: imagesPOIDir + "marker5.png", value: "marker5.png" },
        { text: "marker6", uri: imagesPOIDir + "marker6.png", value: "marker6.png" },
        { text: "marker7", uri: imagesPOIDir + "marker7.png", value: "marker7.png" },
        { text: "marker8", uri: imagesPOIDir + "marker8.png", value: "marker8.png" },
        { text: "marker9", uri: imagesPOIDir + "marker9.png", value: "marker9.png" },
        { text: "marker10", uri: imagesPOIDir + "marker10.png", value: "marker10.png" },
        { text: "marker11", uri: imagesPOIDir + "marker11.png", value: "marker11.png" },
        { text: "flag1", uri: imagesPOIDir + "flag1.png", value: "flag1.png" },
        { text: "flag2", uri: imagesPOIDir + "flag2.png", value: "flag2.png" },
        { text: "house1", uri: imagesPOIDir + "house1.png", value: "house1.png" },
        { text: "house2", uri: imagesPOIDir + "house.png", value: "house.png" }
    ];

    selectedImage: { text: string, uri: string, value: string } = this.images[0];

    colors = [
        { text: "Bleu", value: "blue" },
        { text: "Rouge", value: "red" },
        { text: "Vert", value: "green" },
        { text: "Jaune", value: "yellow" },
        { text: "Orange", value: "orange" },
        { text: "Violette", value: "purple" }

    ];
    selectedColor: { text: string, value: string } = this.colors[0];

    constructor(private mapService: MapService, private dataManagementService: DataManagementService, private geocodingService: GeocodingService, public toastr: ToastrService) {
    }

    ngOnInit() {
    }

    addPoiClient() {
        this.poiClient = Object.assign(this.poiClient, this.pointInterest);
        this.poiClient.imageUri = this.selectedImage.value;
        this.poiClient.plyColor = this.selectedColor.value;

        this.dataManagementService.addPointClient(this.poiClient).subscribe(poiClient => {
            this.drawPointInterest(poiClient);
            if (poiClient.type == "MARKER") {
                let circle = L.circle(this.poiClient.coordinate, {
                    color: 'red',
                    fillColor: '#f03',
                    fillOpacity: 0.1,
                    radius: this.poiClient.ray
                });
                this.mapService.addCircle(circle);

                setTimeout(() => {
                    this.mapService.removeCirclesFromMap();
                }, 3000);
            }
            this.toastr.success("Client ajouté !", "Success");
            this.pointInterestWasAdded.emit(true);
        }, (err) => {
            this.toastr.error("Client non ajouté !", "Success");
        });
    }

    addPointInterest() {
        if (this.selectedPoiForm === 'CLIENT') {
            this.addPoiClient();
        } else {
            this.pointInterest.imageUri = this.selectedImage.value;
            this.pointInterest.plyColor = this.selectedColor.value;
            this.dataManagementService.addPointInterest(this.pointInterest).subscribe(pointInterest => {

                this.drawPointInterest(pointInterest);

                if (pointInterest.type == "MARKER") {
                    let circle = L.circle(this.pointInterest.coordinate, {
                        color: 'red',
                        fillColor: '#f03',
                        fillOpacity: 0.1,
                        radius: this.pointInterest.ray
                    });
                    this.mapService.addCircle(circle);

                    setTimeout(() => {
                        this.mapService.removeCirclesFromMap();
                    }, 3000);
                }

                this.dataManagementService.pointInterests!.push(pointInterest);
                this.toastr.success("point d'intérêt ajouté !", "Success");
                this.pointInterestWasAdded.emit(true);
            }, (err) => {
                this.toastr.error("point d'intérêt non ajouté !", "Success");
            });
        }
    }

    updatePointInterest() {

        this.loader = true;
        this.pointInterest.imageUri = this.selectedImage.value;
        this.pointInterest.plyColor = this.selectedColor.value;

        this.dataManagementService.updatePointInterest(this.pointInterest).subscribe(pointInterest => {

            this.mapService.removeMarkersFromMap();
            this.mapService.removeMarkersPoiFromMap();
            this.mapService.removePolygonsPoiFromMap();

            this.dataManagementService.pointInterests!.map(poi => {
                if (poi.idPointInterest == pointInterest.idPointInterest) {
                    poi = pointInterest;
                }
            });

            this.drawPointInterest(pointInterest);
            this.loader = false;
            this.toastr.success("point d'intérêt modifié !", "Success");
            this.pointInterestWasUpdated.emit(true);
        }, (err) => {
            this.toastr.error("point d'intérêt non modifié !", "Success");
        });
    }

    drawPointInterest(pointInterest: PointInterest | PoiClient) {
        let popup = "<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" + pointInterest.name + "</strong><br><hr><b>Adresse : " + pointInterest.address + "</b>";
        let marker: L.Marker<any> | null = null;

        if (pointInterest.type == "POLYGON") {
            let polygon = new Polygon(pointInterest.decode);
            /** Change Polygon color **/
            polygon.setStyle({ fillColor: this.pointInterest.plyColor, color: this.pointInterest.plyColor, weight: 2 });
            this.mapService.addPolygonPoi(polygon);
        }

        marker = new Marker(pointInterest.coordinate);
        marker.on("click", () => {
            this.mapService.map.setView(pointInterest.coordinate, 17);
        });

        marker.on("mouseover", () => {
            marker.openPopup();
        });

        marker.bindPopup(popup);
        marker.setIcon(new Icon({
            iconUrl: imagesPOIDir + pointInterest.imageUri,
            iconAnchor: [-2, 10],
            popupAnchor: [10, -25]
        }));

        this.mapService.addMarkerPoi(marker);
    }
}
