import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { DriverChargeListComponent } from './driver-charge-list.component'; // Adjust the path if necessary
import { ModalModule } from 'ngx-bootstrap/modal'; // For modals
import { ToastrModule } from 'ngx-toastr'; // For notifications
import { DriverChargeService } from '../../providers'; // Adjust the path as necessary
import { driverChargeListComponent } from './driver-charge-list.component';
import { DriverChargeFormModule } from '../driver-charge-form/driver-charge-form.module';

@NgModule({
  declarations: [
    driverChargeListComponent,
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(), // Import Modal module for ngx-bootstrap modals
    ToastrModule.forRoot(),
    DriverChargeFormModule,
  ],
  providers: [
    DriverChargeService // Provide the driver charge service
  ],
  exports: [
    driverChargeListComponent // Export if you want to use this component outside of this module
  ]
})
export class DriverChargeListModule { }
