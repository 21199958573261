import { Injectable } from '@angular/core';
import { CanalHistoryDto } from '../models';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { dns } from 'src/app/global.config';

@Injectable({
  providedIn: 'root',
})
export class TmmService {
  constructor(private _http: HttpClient) {}

  public getDashbordHistoryCanalGroupVehicule(
    year: number,
    month: number
  ): Observable<CanalHistoryDto[]> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.get<CanalHistoryDto[]>(
      dns + 'canalGroupeHistory/' + year + '/' + month,
      options
    );
  }
}
