import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { RapportDto, Vehicule } from '../../data-management/data-management.model';
import { DataManagementService } from '../../data-management/data-management.service';
import { ImportExportService } from '../../import-export';
import { Group, LastDates, Provider, RunningDocument } from '../model/index';
import { DocumentCirculationService } from '../providers/document-circulation-service';

@Component({
  selector: 'app-document-circulation',
  templateUrl: './document-circulation.component.html',
  styleUrls: ['./document-circulation.component.css']
})
export class DocumentCirculationComponent implements OnInit, OnDestroy {

  @ViewChild('managementModal', { static: false }) managementModal!: ModalDirective;
  @ViewChild('addEditModal', { static: false }) addEditModal!: ModalDirective;
  @ViewChild('importModal', { static: false }) importModal!: ModalDirective;
  @ViewChild('documentDeleteModal', { static: false }) documentDeleteModal!: ModalDirective;

  documentsId: number[] = [];

  isDeleteMultiple: boolean = false;

  loading: boolean = false;

  documents: RunningDocument[] = [];

  documentsFiltered: RunningDocument[] = [];

  years: number[] = [];

  selectedDocument: RunningDocument = new RunningDocument();

  repport: RapportDto = new RapportDto();

  selectedItem: string = null;

  TotalCost: number = 0;

  mode = 'ADD';

  selectedDocumentType:
    | 'INSURANCE'
    | 'VISIT'
    | 'OPERATIONAL_CERTIFICATION'
    | 'PERMIT_CIRCULATION'
    | 'METOLOGICA_NOTBOOK'
    | 'ROAD_TAXES'
    | 'EXTINCTEURS'
    | 'GRAY_CARD'
    | 'TONNAGE_TAX' = null!;

  dateExpirationLoading = false;

  documentsLoading = false;

  datesExpiration: LastDates = new LastDates();

  selectedVehicule: Vehicule = new Vehicule();

  selectedGroup: Group = new Group();

  getVehiculeWasSelectedSubscription!: Subscription;

  getGroupWasSelectedSubscription!: Subscription;

  documentsNotHaveProvider = [
    'PARCKING', 'LAVAGE', 'PERMIT_CIRCULATION', 'CA_REALIZATION', 'TONNAGE_TAX',
    'ROAD_TAXES', 'OPERATIONAL_CERTIFICATION', 'GRAY_CARD'
  ];
  isHaveProvider: boolean = true;

  searchTerm: string | null = null;

  selectedYear!: number;
  endDate: Date | null = null;
  startDate: Date = new Date();
  displayCalendar: boolean = false;


  constructor(
    public toastr: ToastrService,
    private documentService: DocumentCirculationService,
    private dataManagementService: DataManagementService,
    public exportImport: ImportExportService
  ) { }


  ngOnInit() {
    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
      this.selectedGroup = this.dataManagementService.selectedGroup;
      this.loadDatesExpiration();
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.selectedVehicule = vehicule;
      this.loadDatesExpiration();
    });

    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.selectedGroup = group;
    });

    if (this.selectedItem) {
      this.selectedItem = this.selectedDocumentType;
    }
  }

/**
  * Action change date début
  */
onChangeStartDate() {
  if (this.endDate < this.startDate) {
    this.endDate = null;
  }
}


  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadDatesExpiration() {
    if (!this.selectedVehicule.idVehicule) return;
    this.dateExpirationLoading = true;
    this.documentService.getExpirationDates(this.selectedVehicule.idVehicule).subscribe(datesExpiration => {
      this.datesExpiration = datesExpiration;
      this.dateExpirationLoading = false;
    }, () => {
      this.dateExpirationLoading = false;
    }
    );
  }

  getYears() {
    this.years = [];
    const flags: { [key: number]: boolean } = {};
    for (const document of this.documents) {
      const year = new Date(document.endTime).getFullYear();
      if (!flags[year]) {
        flags[year] = true;
        this.years.push(year);
      }
    }
  }


  loadDocument() {
    this.documentsFiltered = [];
    this.years = [];
    this.displayCalendar = false;
    this.documentsLoading = true;
    if (this.documentsNotHaveProvider.indexOf(this.selectedDocumentType) > -1) {
      this.isHaveProvider = false;
    } else {
      this.isHaveProvider = true;
    }
    this.documentService.getListDocuments(this.selectedVehicule.idVehicule, this.selectedDocumentType).subscribe(response => {
      this.documents = response;
      this.documentsFiltered = this.documents;
      //this.documents_full = response;
        this.getYears();
      this.documentsLoading = false;
    }, () => {
      this.documentsLoading = false;
    }

    );
  }

  getCostByYear() {
    this.documentsFiltered = this.documents.filter(t =>
      new Date(t.endTime) >= this.startDate && this.endDate && new Date(t.endTime) <= this.endDate
    );
  }

  onChange(selectedYear: number) {
    if (selectedYear !== 0) {
      this.startDate = new Date(selectedYear, 0, 1);
      this.endDate = new Date(selectedYear, 11, 31, 23, 59, 59);
      this.displayCalendar = false;
      this.getCostByYear();
    } else {
      this.displayCalendar = true;
    }
  }
  onValidate() {
    this.getCostByYear();
  }

  /**
   * */
  openAddDocumentModal() {
    this.selectedDocument = new RunningDocument();
    this.selectedDocument.type = this.selectedDocumentType;
    this.mode = 'ADD';
    this.managementModal.hide();
    this.addEditModal.show();
  }

  updateDocmuent(document: RunningDocument) {
    this.selectedDocument = Object.assign({}, document);

    if (!this.selectedDocument.provider) {
      this.selectedDocument.provider = new Provider();
    }

    this.mode = 'UPDATE';
    this.managementModal.hide();
    this.addEditModal.show();
  }

  documentWasAdded(runningDocuments: RunningDocument[]) {
    this.addEditModal.hide();
    this.managementModal.show();

    for (let document of runningDocuments) {
      document.selected = true;
      this.documents.unshift(document);
    }

    this.documentsFiltered = this.documents;

    this.loadDatesExpiration();
    this.toastr.success('Document bien ajouté !', 'Info');
      this.getYears();
  }

  documentWasUpdated(runningDocuments: RunningDocument[]) {
    this.addEditModal.hide();
    this.managementModal.show();

    for (let document of runningDocuments) {

      document.selected = true;

      if (!this.documents.find(d => d.id == document.id)) {

        this.documents.unshift(document);

      } else {

        for (var i = 0; i < this.documents.length; i++) {
          if (document.id == this.documents[i].id) {
            this.documents[i] = document;
            break;
          }
        }

      }

    }

    this.documentsFiltered = this.documents;
    this.loadDatesExpiration();
    this.toastr.success('Document bien modifié !', 'Info');
      this.getYears();
  }

  costTotal() {
    var costTotal = 0;
    this.documentsFiltered.forEach(document => {
      costTotal += document.cost;
    });
    return costTotal;
  }

  export() {
    this.repport.type = this.selectedDocumentType;
    this.exportImport.export(this.documentsFiltered, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
  }

  itemWasImported(res: boolean) {
    if (res) {
      this.importModal.hide();
      this.managementModal.show();
      this.loadDocument();
      this.loadDatesExpiration();
    }
  }

  showImportModal() {
    this.importModal.show();
    this.selectedItem = this.selectedDocumentType;
  }

  /**
   * Event confirm delete single document
   */
  onConfirmDelete(document: RunningDocument) {
    this.selectedDocument = document;
    this.isDeleteMultiple = false;
    this.managementModal.hide();
    this.documentDeleteModal.show();
  }

  /**
   * Event confirm delete multiple documents
   */
  onConfirmDeleteMultiple() {
    if (this.documentsId.length == 0) {
      this.toastr.warning("Veuillez choisir des documents à supprimer !");
      return;
    }
    this.isDeleteMultiple = true;
    this.managementModal.hide();
    this.documentDeleteModal.show();
  }

  /**
   * Event delete
   */
  onDelete() {
    if (this.isDeleteMultiple) {
      this.deleteMultiple();
    } else {
      this.delete();
    }
  }

  /**
   * Delete single
   */
  delete() {
    this.loading = true;
    this.documentService.delete(this.selectedDocument.id).subscribe(response => {

      if (response) {
        this.documents = this.documents.filter(document => document.id !== this.selectedDocument.id);
        this.documentsId = this.documentsId.filter(documentId => documentId != this.selectedDocument.id);
        this.documentsFiltered = this.documents;
        this.loadDatesExpiration();
        this.toastr.success('Documents bien supprimés !', 'info');
          if (this.years.length > 0 && this.documents.length == 0) {
          this.getYears();
          }

      } else {
        this.toastr.error('Les documents ne sont pas supprimés correctement !', 'Erreur');
      }

      this.documentDeleteModal.hide();
      this.loading = false;
      this.managementModal.show();
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }


  /**
   * Delete multiple
   */
  deleteMultiple() {
    this.loading = true;
    this.documentService.deleteMultiple(this.documentsId).subscribe(response => {
      if (response) {
        for (let documentId of this.documentsId) {
          this.documents = this.documents.filter(document => document.id != documentId);
        }
        this.documentsFiltered = this.documents;
        this.documentsId = [];
        this.loadDatesExpiration();
        this.toastr.success('Suppression effectuée avec succès.', 'info');
          if (this.years.length > 0 && this.documents.length == 0) {
            this.getYears();
        }
      } else {
        this.toastr.error('Le document n\'a pas supprimé correctement !', 'Erreur');
      }
      this.documentDeleteModal.hide();
      this.loading = false;
      this.managementModal.show();

    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }

  /**
   * Event cancel modal
   */
  onCancelDelete() {
    this.documentDeleteModal.hide();
    this.managementModal.show();
  }

  /**
   * get list search
   */
  getDocuments(): RunningDocument[] {
    if ((this.searchTerm) && (this.searchTerm.length > 0)) {
      return this.documentsFiltered.filter(document => (document.provider) && (document.provider.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
    } else {
      return this.documentsFiltered;
    }
  }

  /**
   * Event check row for delete multiple
   */
  onChecked(id: number) {
    if (this.documentsId.find(documentId => documentId == id)) {
      this.documentsId = this.documentsId.filter(documentId => documentId != id);
    } else {
      this.documentsId.push(id);
    }
  }

  /**
   * Event check all row for delete multiple
   */
  onCheckedAll() {
    if (this.documentsId.length == this.documentsFiltered.length) {
      this.documentsId = [];
    } else {
      this.documentsId = [];
      for (let document of this.documentsFiltered) {
        this.documentsId.push(document.id);
      }
    }
  }

  /**
   * check if document id exists in array,
   */
  isCheckedInList(id: number): boolean {
    return this.documentsId.find(documentId => documentId == id) ? true : false;
  }

}
