import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if you use forms in the component
import { PoiFormComponent } from './poi-form.component';
import { MapService } from '../../../../utils/leaflet/service/map.service';
import { DataManagementService } from '../../data-management.service';
import { GeocodingService } from '../../../../utils/leaflet/service/geocoding.service';
import { ToastrService } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    PoiFormComponent  // Declare the PoiFormComponent
  ],
  imports: [
    CommonModule,  // CommonModule is essential for common directives like *ngIf and *ngFor
    FormsModule,
    NgxLoadingModule.forRoot({}),


  ],
  providers: [
    MapService,               // Include providers if needed
    DataManagementService,
    GeocodingService,
    ToastrService
  ],
  exports: [
    PoiFormComponent   // Export the component if it will be used in other modules
  ]
})
export class PoiFormModule { }
