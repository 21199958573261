import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumptionSettingsComponent } from './consumption-settings.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms'; // Import FormsModule if you're using ngModel or other form features
import { NgxLoadingModule } from 'ngx-loading';
import { EmailManagerModule } from '../email-manager/email-manager.module';
import { CronSettingsModule } from '../cron-settings/cron-settings.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    ConsumptionSettingsComponent,
  ],
  imports: [
    CommonModule, // Provides common directives (e.g., ngIf, ngFor)
    FormsModule, // Import FormsModule if you're using ngModel or other form features
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    EmailManagerModule,
    CronSettingsModule,
    MatSlideToggleModule

  ],
  exports: [
    ConsumptionSettingsComponent // Export the component if it needs to be used in other modules
  ]
})
export class ConsumptionSettingsModule { }
