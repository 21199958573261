<form #tireForm="ngForm" class="form-horizontal">
    <div class="box-body">
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Date de réparation réelle</label>
            <div class="col-sm-4">
                <input type="text" class="form-control input-sm" bsDatepicker [(bsValue)]="tire.actualRepairTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    placeholder="Date de réparation réelle" autocomplete="off">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Date Acquisition</label>
            <div class="col-sm-4">
                <input type="text" class="form-control input-sm" bsDatepicker [(bsValue)]="tire.buyDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
                    [maxDate]="maxDate" placement="bottom" placeholder="Date Acquisition" autocomplete="off">
            </div>
            <label class="col-sm-2 control-label">Date Montage *</label>
            <div class="col-sm-4">
                <input type="text" class="form-control input-sm" bsDatepicker [(bsValue)]="tire.operationTime"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }" [maxDate]="maxDate" placement="bottom"
                    placeholder="Date Montage" required autocomplete="off">
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Position pneu</label>
            <div class="col-lg-4 col-md-4">
                <select class="form-control input-sm select2" [(ngModel)]="tire.position" name="position">
                    <option *ngFor="let position of listPositions" [selected]="position.value == tire.position" [value]="position.value">
                        {{position?.label}}
                    </option>
                </select>
            </div>
            <label class="col-sm-2 control-label">Km montage *</label>
            <div class="col-lg-4 col-md-4">
                <input name="Km montage" [(ngModel)]="tire.kmMontage" #kmMontage="ngModel" type="number" class="form-control input-sm"
                    placeholder="Km montage" required>
            </div>
        </div>
        <div class="form-group has-success form-horizontal">
            <label class="col-sm-2 control-label">Numéro Serie *</label>
            <div class="col-lg-4 col-md-4">
                <input name="Numéro Serie" [(ngModel)]="tire.serieNumber" #serieNumber="ngModel" type="text" class="form-control input-sm"
                    placeholder="Numéro Serie" required maxlength="50">
            </div>
            <label class="col-sm-2 control-label">Km Max</label>
            <div class="col-lg-4 col-md-4">
                <input name="Km Max" [(ngModel)]="tire.maxKm" type="number" class="form-control input-sm" placeholder="Km Max">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Parallélisme</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="tire.parallelism" name="parallelism">
                </mat-checkbox>
            </div>
            <label class="col-sm-2 control-label">Calibrage</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="tire.calibration" name="calibration">
                </mat-checkbox>
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Equilibrage</label>
            <div class="col-lg-4 col-md-4">
                <mat-checkbox class="col-sm-4" [(ngModel)]="tire.balancing" name="balancing">
                </mat-checkbox>
            </div>
            <label class="col-sm-2 control-label">Observations</label>
            <div class="col-lg-4 col-md-4">
                <input name="Observations" [(ngModel)]="tire.observation" minlength="2" maxlength="50" type="text"
                    class="form-control input-sm" placeholder="Observations">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Marque</label>
            <div class="col-lg-4 col-md-4">
                <input name="Marque" [(ngModel)]="tire.mark" #mark="ngModel" type="text" class="form-control input-sm"
                    placeholder="Marque" maxlength="50">
            </div>
            <label class="col-sm-2 control-label">Coût *</label>
            <div class="col-lg-4 col-md-4">
                <input name="cost" [(ngModel)]="tire.cost" #cost="ngModel" type="number" class="form-control input-sm"
                    placeholder="Coût" required min="1">
            </div>
        </div>
        <div *ngIf="tire.provider.id != 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Fournisseur *</label>
            <div class="col-sm-10">
                <select class="form-control input-sm select2" [(ngModel)]="tire.provider.id" name="providerId" #providerId="ngModel"
                    required>
                    <option [value]="0">Autres</option>
                    <option [selected]="provider.id == idProvider" *ngFor="let provider of providers" [value]="provider.id">
                        {{provider?.name}}
                    </option>
                    <option hidden [value]="null"></option>
                </select>
            </div>
        </div>
        <div *ngIf="tire.provider.id == 0" class="form-group has-success">
            <label class="col-sm-2 control-label">Nom Fournisseur *</label>
            <div class="col-sm-10">
                <input type="text" [(ngModel)]="tire.provider.name" name="providerName" #providerName="ngModel" class="form-control input-sm"
                    placeholder="Nom Fournisseur" required maxlength="50">
                <span style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="tire.provider.id = null;">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label"></label>
            <div class="col-sm-10">
                <img src="{{imagesDir}}4x2r.png" height="120px" width="100%">
            </div>
        </div>
        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Coût de main d'oeuvre</label>
            <div class="col-lg-4 col-md-4">
                <input name="laborCost" [(ngModel)]="tire.laborCost" type="number" class="form-control input-sm" min="1"
                    placeholder="Coût de main d'oeuvre">
            </div>
            <label class="col-sm-2 control-label">Références</label>
            <div class="col-lg-4 col-md-4">
                <input name="referenceDADR" [(ngModel)]="tire.referenceDADR" maxlength="50" type="text" class="form-control input-sm"
                    placeholder="Références">
            </div>
        </div>
        <div class="box-footer">
            <button type="submit" *ngIf="mode == 'ADD'" [disabled]="!tireForm.form.valid" class="btn btn-info pull-right"
                (click)="onAdd(tireForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
            <button type="submit" *ngIf="mode == 'UPDATE'" [disabled]="!tireForm.form.valid" class="btn btn-info pull-right"
                (click)="onUpdate(tireForm)">
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
        </div>
    </div>
</form>