import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RealtimeComponent } from '../../realtime.component';
import {
  DataManagementService,
  Group,
  PoiClient,
  PointInterest,
  Vehicule,
} from '../../../data-management';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { RealtimeService } from '../../services/realtime.service';
import { RealtimeHelperService } from '../../services/realtime-helper.service';
import { Subscription } from 'rxjs';
import { StateCounts } from '../../model/real-time.model';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-groups-container',
  templateUrl: './groups-container.component.html',
  styleUrls: ['./groups-container.component.css'],
})
export class GroupsContainerComponent implements OnInit, OnDestroy {
  @ViewChild('childModal') public childModal: ModalDirective;
  @ViewChild('childModal2') public childModal2: ModalDirective;

  records: Record<number, StateCounts>;
  recordsSuscription: Subscription;

  groups: Group[];
  groups$: Subscription;
  groupsRegions: Group[];
  groupsRegions$: Subscription;

  selectedVehicule: Vehicule;
  selectedVehiculeSubs: Subscription;

  searchedOption: string;
  SubscriptionsearchedOption: Subscription;

  constructor(
    public realTime: RealtimeComponent,
    public realTimeService: RealtimeService,
    public realtimeHelperService: RealtimeHelperService,
    public dataManagementService: DataManagementService,
    public mapService: MapService
  ) {}
  ngOnInit(): void {
    this.groupsSubscription();
    this.selectedVehiculeSubs = this.realTimeService
      .getVehicule()
      .subscribe((v) => {
        this.selectedVehicule = v;
      });
    this.realTimeService.allRealTimeRecord();
    this.recordsSuscription = this.realTimeService.getStatesCount()
    .subscribe((stats) => {
      this.records = stats;
    });
    this.SubscriptionsearchedOption = this.realTimeService.searchedOptionObs()
    .subscribe((search) => {
      this.searchedOption = search;
    });
  }

  addClient() {
    this.mapService.map.setView(
      this.selectedVehicule.realTimeRecord.coordinate,
      17
    );
    this.realTimeService.poiClient = new PoiClient();
    this.realTimeService.poiClient.type = 0;
    this.realTimeService.poiClient.coordinate = this.selectedVehicule.realTimeRecord.coordinate;
    this.realTimeService.poiClient.address = this.selectedVehicule.realTimeRecord.geocodingDetails;
    this.childModal2.show();
  }
  addPOI() {
    this.mapService.map.setView(
      this.selectedVehicule.realTimeRecord.coordinate,
      17
    );

    this.realTimeService.pointInterest = new PointInterest();
    this.realTimeService.pointInterest.type = 0;
    this.realTimeService.pointInterest.coordinate = this.selectedVehicule.realTimeRecord.coordinate;
    this.realTimeService.pointInterest.address =
    this.selectedVehicule.realTimeRecord.geocodingDetails;
    this.childModal.show();
  }
  groupsSubscription(){
    this.groups$ = this.dataManagementService
    .getGroups()
    .subscribe((groups) => {
      this.groups = groups;
    });
  }
  groupsRegionSubscription(){
    this.groupsRegions$ = this.dataManagementService
    .getGroupsRegion()
    .subscribe((groups) => {
      this.groups = groups;
      this.mapService.setActivePolygons(groups);
    });
  }
  filterGroups(groupFilter: string){
    this.destryGroups();
    switch(groupFilter) { 
      case "groups": { 
        this.realTimeService.regionDisplay = false;
        this.groupsSubscription();
        this.mapService.reseatPolygons();
        this.realTimeService.updateStateCounts(this.dataManagementService.groups);
        break; 
      } 
      case "regions": { 
        this.realTimeService.regionDisplay = true;
        this.groupsRegionSubscription();
        this.realTimeService.updateStateCounts(this.groups);
        break; 
      } 
      default: { 
         break; 
      } 
   } 
  }
  destryGroups(){
    if (this.groups$){ this.groups$.unsubscribe() }
    if (this.groupsRegions$){ this.groupsRegions$.unsubscribe() }
  }

  ngOnDestroy() {
    this.destryGroups();
    this.groups$.unsubscribe();
    this.recordsSuscription.unsubscribe();
    this.selectedVehiculeSubs.unsubscribe();
    this.SubscriptionsearchedOption.unsubscribe();
    
    this.realTimeService.stopRealTime();
    this.realTimeService.clearRealTime();
  }
}