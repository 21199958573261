import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-overview-management',
  templateUrl: './activity-overview-management.component.html',
  styleUrls: ['./activity-overview-management.component.css']
})
export class ActivityOverviewManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
