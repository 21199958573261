import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CimentMissionComponent } from './ciment-mission.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CimentMissionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    CimentMissionComponent
  ]
})
export class CimentMissionModule {}
