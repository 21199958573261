<ngx-loading [show]="globalLoading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="box"
  [ngClass]="{'box-success': user.enabled && mode === 'SHOW','box-danger': !user.enabled && mode === 'SHOW','box-info': mode === 'ADD' || mode === 'UPDATE'}"
  style="margin-right: 50px;">
    <div class="box-header with-border">

        <div class="user-block clr">
            <div class=" mt-2 mb-2 text-center   well bgg">
            <img *ngIf="mode === 'ADD' || mode === 'SHOW'" class="img-circle"
                 [src]="profileAvatar"
                 alt="User Image">

            <img *ngIf="mode === 'UPDATE'" class="img-circle"
                 [src]="settingsAvatar"
                 alt="User Image" >

            <span class="username">
                <a *ngIf="mode === 'SHOW'" (click)="toggle()" style="color: white;">{{user?.displayName | uppercase}}</a>
                <a *ngIf="mode === 'ADD'" style="color: white;">Ajouter compte mission</a>
                <a *ngIf="mode === 'UPDATE'" style="color: white;">Modifier compte mission {{user?.username}}</a>

            </span>

            <!-- use amTimeAgo  -->
            <span class="description" *ngIf="mode === 'SHOW'" style="color: white;">crée il y a : {{user?.createdAt | amTimeAgo:true}}</span>

               </div>

        </div>

        <!-- /.user-block -->
        <div class="box-tools" *ngIf="mode === 'SHOW'">
            <mat-slide-toggle [checked]="user.enabled" (change)='enableOrdisableAccount(user)'
                              *ngIf="mode === 'SHOW'"></mat-slide-toggle>
            <span data-toggle="tooltip" title="" class="badge"
                  data-original-title="3 New Messages"
                  [ngClass]="{'bg-green': user.enabled,'bg-red': !user.enabled}"></span>

            <button type="button" class="btn btn-box-tool mdf" data-toggle="tooltip" title="modifier sous compte"
                    (click)="updateSubAccount()">
                <i class="fa fa-cogs"></i>
            </button>
            <button type="button" class="btn btn-box-tool mins" data-toggle="tooltip" (click)="toggle()" title="minimuse">
                <i class="fa fa-minus"></i>
            </button>
            <button type="button" class="btn btn-box-tool cls" data-toggle="tooltip" (click)="deleteSubAccount()"
                    title="supprimer sous compte">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <!-- /.box-tools -->
    </div>
    <!-- /.box-header -->

    <div class="box-body" *ngIf="isCollabsed">

        <form class="form-horizontal" *ngIf="mode === 'SHOW'">
            <div class="box-body">
                <div class="form-group">
                    <label class="col-sm-2 control-label">Login</label>
                    <div class="col-sm-10">
                        <label class="col-sm-2 control-label">{{user.username | truncate:[20] | lowercase}}</label>
                    </div>
                </div>

                <div class="form-group" *ngIf="user.email">
                    <label class="col-sm-2 control-label">Email</label>

                    <div class="col-sm-9">
                        <label class="col-sm-2 control-label">{{user.email | lowercase}}</label>
                    </div>
                    <button type="button" class="btn btn-box-tool col-sm-1" data-toggle="tooltip" (click)="sendMail()"
                            title="envoyer les informations du sous compte">
                        <i class="fa fa-envelope"></i>
                    </button>
                </div>

                <div class="form-group">
                    <label class="col-sm-2 control-label">M.P</label>

                    <div class="col-sm-10">
                        <label class="col-sm-2 control-label" (click)="showPW = false"
                               *ngIf="showPW">{{user.password | lowercase}}</label>
                        <label class="col-sm-2 control-label" (click)="showPW = true"
                               *ngIf="!showPW">************</label>
                    </div>
                </div>

                <div class="form-group" *ngIf="user.vehicules && user.vehicules.length > 0">
                    <span data-toggle="tooltip" title="" class="badge"
                          style="margin: 5px;background-color: #3DA0DB"
                          *ngFor="let vehicule of user.vehicules">
                           {{vehicule.matricule}}
                    </span>
                </div>

                <div class="form-group" *ngIf="user.missions && user.missions.length > 0">
                    <span data-toggle="tooltip" title="" class="badge bg-green" style="margin: 5px;"
                          *ngFor="let mission of user.missions">
                           {{mission.label}}
                    </span>
                </div>

            </div>
        </form>

        <form class="form-horizontal" role="form" *ngIf="mode === 'ADD' || mode === 'UPDATE'" #accountForm="ngForm">

            <div class="box-body">
                <div class="form-group"
                     [ngClass]="{  'has-error': !displayName.valid && displayName.touched}">
                    <label class="col-sm-2 control-label">
                        Titre
                    </label>

                    <div class="col-sm-10">
                        <input type="text"
                               class="form-control"
                               placeholder="Titre"
                               [(ngModel)]="user.displayName"
                               name="displayName"
                               #displayName="ngModel"
                               required=true>
                    </div>
                </div>

                <div class="form-group"
                     *ngIf="mode === 'ADD' || mode === 'UPDATE'"
                     [ngClass]="{ 'has-error': !username.valid && username.touched}">

                    <label class="col-sm-2 control-label">
                        Login
                    </label>

                    <div class="col-sm-10">
                        <!-- <span class="input-group-addon" id="basic-addon1">rimtel_</span> -->
                        <input
                        [(ngModel)]="user.username"
                        name="username"
                        #username="ngModel"
                        required=true minlength="5" type="text" class="form-control" placeholder="Username" aria-describedby="basic-addon1">
                      </div>
                </div>

                <div class="form-group"
                     [ngClass]="{ 'has-error': !password.valid && password.touched}">

                    <label class="col-sm-2 control-label">
                        M.P
                    </label>

                    <div class="col-sm-10">
                        <input type="password" class="form-control" placeholder="Mot de passe"
                               [(ngModel)]="user.password"
                               name="password"
                               #password="ngModel"
                               required=true minlength="5">
                    </div>
                </div>

                <div class="form-group"
                     [ngClass]="{ 'has-error': !email.valid && email.touched}">

                    <label class="col-sm-2 control-label">
                        Email
                    </label>

                    <div class="col-sm-10">
                        <input type="email" class="form-control" placeholder="Email"
                               [(ngModel)]="user.email"
                               [ngModelOptions]="{ standalone: true }"
                               name="email"
                               #email="ngModel"
                               pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                    </div>
                </div>

                <div class="form-group  ">
                    <label class="col-sm-2 control-label">
                        Vehicule
                    </label>
                    <div class="col-sm-10">
                        <ng-multiselect-dropdown-angular7 name="vehSel" [data]="vehiculesItems"
                        placeholder="select Véhicule" [(ngModel)]="user.vehicules"
                        [settings]="vehiculesDropdownSettings" required>
                    </ng-multiselect-dropdown-angular7>
                    </div>
                </div>

                <div class="form-group  ">
                    <label class="col-sm-2 control-label">
                        Mission
                    </label>
                    <div class="col-sm-10">
                        <ng-multiselect-dropdown-angular7 name="missionSel" [data]="missionItems"
                        placeholder="select mission" [(ngModel)]="user.missions"
                        [settings]="missionsDropdownSettings" required>
                    </ng-multiselect-dropdown-angular7>
                    </div>
                </div>


            </div>
            <div class="box-footer">
                <button type="submit" class="btn btn-danger btn-flat margin btnStyle" (click)="init()">Annuler</button>
                <button type="button" class="btn btn-primary btn-flat margin pull-right btnStyle"
                        [disabled]="!accountForm.form.valid"
                        (click)="addSubAccount()" *ngIf="mode === 'ADD'">Valider
                </button>
                <button type="button" (click)="editSubAccount()" class="btn btn-success btn-flat margin pull-right btnStyle"
                        *ngIf="mode === 'UPDATE'" [disabled]="!accountForm.form.valid">Modifier
                </button>
            </div>

        </form>
    </div>
</div>
