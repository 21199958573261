import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PassageService } from './passage.service';
import { Passage } from './passages';

@Component({
  selector: 'app-passage',
  templateUrl: './passage.component.html',
  styleUrls: ['./passage.component.css']
})
export class PassageComponent implements OnInit {

  // local vars
  loading: boolean;
  globalLoading: boolean = false;
  @ViewChild('deleteModal', { static: false })
  deleteModal: ModalDirective;
  passage: Passage = new Passage();


  constructor(public toastr: ToastrService, private passageService: PassageService) {
  }

  ngOnInit() {
    this.loadPassages();
  }

  loadPassages() {
    if (this.passageService.passages.length <= 0) {
      this.loading = true;
      this.passageService.findAllPassages().subscribe(passages => {
        this.passageService.passages = passages;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Passage");
      })
    }

  }

  confirmDeletePassage(passage: Passage) {
    this.passage = passage;
    this.deleteModal.show();
  }

  onDelete() {
    this.loading = true;
    this.passageService.deletePassage(this.passage.idPassage).subscribe(deleted => {
      this.deleteModal.hide();
      this.loading = false;
      if (deleted) {
        this.passageService.passages = this.passageService.passages.filter(passage => passage.idPassage != this.passage.idPassage);
        this.toastr.success('Passage bien supprimer !', 'info');
      } else {
        this.toastr.error("Erreur lors de suppression", "Passage");
      }
    }, err => {
      this.loading = false;
      this.toastr.error("Erreur lors de suppression", "Passage");
    }, () => {
      this.loading = false;
    })
  }

}
