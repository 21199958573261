import { Component, OnInit } from '@angular/core';
import { DataManagementService } from 'src/app/client-management/data-management';
import { Subscription } from 'rxjs/Subscription';
import { Vehicule, Group } from '../../data-management/data-management.model';

@Component({
    selector: "app-driver-charge",
    templateUrl: "./driver-charge.component.html"
})
export class driverChargeComponent implements OnInit {

    vehicule: Vehicule = new Vehicule();

    group: Group = new Group();

    getVehiculeWasSelectedSubscription: Subscription;

    getGroupWasSelectedSubscription: Subscription;

    actionAdd: number = 0;

    actionDeleteMultiple: number = 0;

    driverChargesId: number[] = [];

    constructor(public dataManagementService: DataManagementService) { }

    ngOnInit() {
        /**
         * Obetenir la véhicule actual et sa conducteur
         */
        if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
            this.vehicule = this.dataManagementService.selectedVehicule;
            this.group = this.dataManagementService.selectedGroup;
        }

        /**
         * Souscrire avec l'événement de changement du véhicule
         */
        this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
            this.vehicule = vehicule;
        });

        /**
         * Souscrire avec l'événement de changement du groupe
         */
        this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
            this.group = group;
        });
    }

    ngOnDestroy() {
        if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
    }


    onAdd() {
        this.actionAdd = this.actionAdd + 1;
    }

    onConfirmDeleteMultiple() {
        this.actionDeleteMultiple = this.actionDeleteMultiple + 1;
    }

    wasChecked(driverChargesId: number[]) {
        this.driverChargesId = driverChargesId;
    }
}