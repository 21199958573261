import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatteryComponent } from './battery.component'; // Adjust the path if necessary
import { ToastrModule } from 'ngx-toastr'; // If you are using Toastr for notifications
import { ModalModule } from 'ngx-bootstrap/modal'; // Importing ngx-bootstrap modal
import { ImportExportFormsModule } from '../../import-export-forms/import-export-forms.module';
import { BatteryFormComponent } from './battery-form/battery-form.component';
import { BatteryFormModule } from './battery-form/battery-form.module';
import { FormsModule } from '@angular/forms';


//import { ImportExportModule } from '../../../import-export'; // Adjust the path as necessary

@NgModule({
  declarations: [
    BatteryComponent,

  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Initialize Toastr module
    ModalModule.forRoot(), // Initialize Modal module
    ImportExportFormsModule,

    FormsModule,

    BatteryFormModule
  ],
  exports: [
    BatteryComponent // Export the BatteryComponent if needed in other modules
  ]
})
export class BatteryModule { }
