import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundPipe } from './round.pipe';  // Adjust the path if necessary

@NgModule({
  declarations: [
    RoundPipe   // Declare the RoundPipe
  ],
  imports: [
    CommonModule  // Import CommonModule if needed for other common functionalities
  ],
  exports: [
    RoundPipe    // Export RoundPipe to make it available in other modules
  ]
})
export class RoundPipeModule { }
