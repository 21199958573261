<div class="container-fluid">
  <div class="page-header">
    <div class="row">
              <div class="col-md-4">
            <span style="line-height: 35px; padding: 0 10px; font-size: 30px; color: #444;"><i class="fa fa-gear"></i> Liste des alertes</span>
        </div>
        <div class="col-md-4">
          <button type="submit" class="btn btn-success" (click)="openModal(template)">
            <i class="fa fa-plus-circle"></i>
               ajouter
    </button>
    </div>
  </div>

  </div>
    <div class="row">
  <div class="col-12">
      <ng2-smart-table [settings]="settings" [source]="data"
      (custom)="onCustomAction($event)">

    </ng2-smart-table>


    </div>
    </div>

  </div>

<!-- =================================== CONFIRM MODAL (START)====================================-->
<div class="modal fade" tabindex="-1" #confirmDeleteModal="bs-modal" bsModal role="dialog" >
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" (click)="confirmDeleteModal.hide()">&times;
              </button>

              <h4 class="modal-title">
                  <i class="fa fa-cogs" aria-hidden="true"></i> Attention Suppression Alerte
              </h4>

          </div>
          <div class="modal-body">
              <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                  <h4 style="color: white"><i class="fa fa-exclamation-triangle"></i> Attention !
                  </h4>
                  Êtes-vous sûr de supprimer le parametrage de cette Alerte
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-default" (click)="confirmDeleteModal.hide()">
                  Annuler
              </button>
              <button type="button" class="btn btn-success" (click)="delete()">
                  <i class="fa fa-check" aria-hidden="true"></i> Oui, Je confirme
              </button>
          </div>
      </div>
  </div>
</div>

  <ng-template #template>
      <app-alert-customize [modalRef]="modalRef" [sendDataEdit]=sendDataEdit [mode]=mode></app-alert-customize>
  </ng-template>

