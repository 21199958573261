import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { RapportDto, Vehicule } from '../../data-management/data-management.model';
import { DataManagementService } from '../../data-management/data-management.service';
import { ImportExportService } from '../../import-export';
import { Provider, RunningDocument, Group } from '../model';
import { DocumentCirculationService } from '../providers/document-circulation-service';

@Component({
  selector: 'app-leasing',
  templateUrl: './leasing.component.html',
  styleUrls: ['./leasing.component.css']
})
export class LeasingComponent implements OnInit, OnDestroy {

  @ViewChild('addEditModal', { static: false }) addEditModal: ModalDirective;
  @ViewChild('importModal', { static: false }) importModal: ModalDirective;
  @ViewChild('leasingDeleteModal', { static: false }) leasingDeleteModal: ModalDirective;


  leasings: RunningDocument[] = [];

  leasingsId: number[] = [];

  leasing: RunningDocument = new RunningDocument();

  /**export  */
  repport: RapportDto = new RapportDto();

  selectedLeasing: RunningDocument = new RunningDocument();

  selectedVehicule: Vehicule = new Vehicule();

  selectedGroup: Group = new Group();

  mode: string = 'ADD';

  selectedItem = 'LEASING';

  getVehiculeWasSelectedSubscription: Subscription;

  getGroupWasSelectedSubscription: Subscription;

  loading: boolean = false;

  isDeleteMultiple: boolean = false;

  searchTerm: string | null = null;

  constructor(public toastr: ToastrService, private documentService: DocumentCirculationService, private dataManagementService: DataManagementService, public exportImport: ImportExportService) { }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
      this.selectedGroup = this.dataManagementService.selectedGroup;
      this.loadLeasing();
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.selectedVehicule = vehicule;
      this.loadLeasing();
    });

    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.selectedGroup = group;
    });
  }

  ngOnDestroy() {
    if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
  }

  loadLeasing() {
    if (!this.selectedVehicule.idVehicule) return;
    this.loading = true;
    this.documentService.getListDocuments(this.selectedVehicule.idVehicule, 'LEASING').subscribe(response => {
      this.leasings = response;
      this.loading = false;
    },
      () => {
        this.loading = false;
      }
    );
  }

  costTotal() {
    var costTotal = 0;
    this.leasings.forEach(document => {
      costTotal += document.cost;
    });
    return costTotal;
  }

  maturityAmountTotal() {
    var costTotal = 0;
    this.leasings.forEach(document => {
      costTotal += document.maturityAmount;
    });
    return costTotal;
  }

  openAddDocumentModal() {
    this.selectedLeasing.type = 'LEASING';
    this.selectedLeasing = Object.assign({}, this.selectedLeasing);
    this.selectedLeasing.cost = null;
    this.selectedLeasing.maturityAmount = null;
    this.mode = 'ADD';
    this.addEditModal.show();
  }

  documentWasAdded(runningDocuments: RunningDocument[]) {
    this.addEditModal.hide();

    for (let document of runningDocuments) {
      document.selected = true;
      this.leasings.unshift(document);
    }

    this.toastr.success('Location bien ajouté !', 'Info');

  }


  documentWasUpdated(runningDocuments: RunningDocument[]) {
    this.addEditModal.hide();

    for (let document of runningDocuments) {

      document.selected = true;

      if (!this.leasings.find(d => d.id == document.id)) {

        this.leasings.unshift(document);

      } else {

        for (var i = 0; i < this.leasings.length; i++) {
          if (document.id == this.leasings[i].id) {
            this.leasings[i] = document;
            break;
          }
        }

      }

    }

    this.toastr.success('Location bien modifié !', 'Info');
  }


  updateDocmuent(document: any) {
    this.selectedLeasing = Object.assign({}, document);

    if (!this.selectedLeasing.provider) {
      this.selectedLeasing.provider = new Provider();
    }

    this.mode = 'UPDATE';
    this.addEditModal.show();
  }


  export() {
    this.repport.type = 'LEASING';
    this.exportImport.export(this.leasings, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
  }

  itemWasImported(res: boolean) {
    if (res) {
      this.importModal.hide();
    }
  }

  /**
   * Afficher modal de confirmation du suppression
   */
  onConfirmDelete(leasing: RunningDocument) {
    this.leasing = leasing;
    if (!this.leasing.provider) {
      this.leasing.provider = new Provider();
    }
    this.isDeleteMultiple = false;
    this.leasingDeleteModal.show();
  }

  /**
   * Event confirm delete multiple
   */
  onConfirmDeleteMultiple() {
    if (this.leasingsId.length == 0) {
      this.toastr.warning("Veuillez choisir des locations à supprimer !");
      return;
    }
    this.isDeleteMultiple = true;
    this.leasingDeleteModal.show();
  }


  /**
   * Suppression du location
   */
  delete() {
    this.loading = true;
    this.documentService.delete(this.leasing.id).subscribe(response => {
      if (response) {

        this.toastr.success('Suppression effectuée avec succès.', 'info');

        this.leasings = this.leasings.filter(leasing => leasing.id != this.leasing.id);

        this.leasingsId = this.leasingsId.filter(leasingId => leasingId != this.leasing.id);

      } else {
        this.toastr.error('Le location n\'a pas supprimé correctement !', 'Erreur');
      }
      this.leasingDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }


  /**
   * Suppression multiple
   */
  deleteMultiple() {
    this.loading = true;
    this.documentService.deleteMultiple(this.leasingsId).subscribe(response => {
      if (response) {
        for (let leasingId of this.leasingsId) {
          this.leasings = this.leasings.filter(leasing => leasing.id != leasingId);
        }

        this.leasingsId = [];

        this.toastr.success('Suppression effectuée avec succès.', 'info');
      } else {
        this.toastr.error('Les locations n\'a pas supprimé correctement !', 'Erreur');
      }
      this.leasingDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }

  /**
  * Event delete
  */
  onDelete() {
    if (this.isDeleteMultiple) {
      this.deleteMultiple();
    } else {
      this.delete();
    }
  }

  /**
   * get list search
   */
  getLeasings(): RunningDocument[] {
    if ((this.searchTerm) && (this.searchTerm.length > 0)) {
      return this.leasings.filter(leasing => (leasing.provider) && (leasing.provider.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
    } else {
      return this.leasings;
    }
  }


  /**
   * Event check row for delete multiple
   */
  onChecked(id: number) {
    if (this.leasingsId.find(leasingId => leasingId == id)) {
      this.leasingsId = this.leasingsId.filter(leasingId => leasingId != id);
    } else {
      this.leasingsId.push(id);
    }
  }

  /**
   * Event check all row for delete multiple
   */
  onCheckedAll() {
    if (this.leasingsId.length == this.leasings.length) {
      this.leasingsId = [];
    } else {
      this.leasingsId = [];
      for (let leasing of this.leasings) {
        this.leasingsId.push(leasing.id);
      }
    }
  }

  /**
   * check if id exists in array,
   */
  isCheckedInList(id: number): boolean {
    return this.leasingsId.find(leasingId => leasingId == id) ? true : false;
  }
}
