import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupervisorFormComponent } from './supervisor-form.component'; // Adjust the path as necessary
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import Reactive Forms Module
import { MatDialogModule } from '@angular/material/dialog'; // Import Angular Material Dialog Module
import { ToastrModule } from 'ngx-toastr'; // Import Toastr for notifications
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
    declarations: [
        SupervisorFormComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatDialogModule,
        ToastrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
    ],
    exports: [
        SupervisorFormComponent
    ]
})
export class SupervisorFormModule { }
