import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { dns } from '../global.config';
import { createAuthorizationHeader } from "../utils/security/headers";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HeaderService{

    sound: boolean = true;
    realTimeNotificationSound: boolean = false;
    realTimeNotification: boolean = true;


  constructor(private _http: HttpClient) {
    // Vérifiez si l'élément existe dans localStorage avant de le parser
    const notificationSound = localStorage.getItem('notification_sound');
    this.sound = notificationSound ? JSON.parse(notificationSound) : false;

    const realTimeNotificationSound = localStorage.getItem('realTimeNotificationSound');
    this.realTimeNotificationSound = realTimeNotificationSound ? JSON.parse(realTimeNotificationSound) : false;

    const realTimeNotification = localStorage.getItem('realTimeNotification');
    this.realTimeNotification = realTimeNotification ? JSON.parse(realTimeNotification) : false;
  }




  // alertSeen(alertDate: any, deviceId): Observable<any> {
    //     let headers = createAuthorizationHeader();
    //     return this._http.post(dns + 'alert/seen/', deviceId + '/' + alertDate, { headers: headers });
    // }
    //
    // getUnseenAlerts(): Observable<any[]> {
    //     let headers = createAuthorizationHeader();
    //     return this._http.get<any[]>(dns + 'alert/unseen/', { headers: headers });
    // }

}
