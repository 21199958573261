import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertManagementNewComponent } from './alert-management-new.component';
import { FormsModule } from '@angular/forms'; // Import if you are using forms in your component
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AlertManagementNewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    AlertManagementNewComponent
  ]
})
export class AlertManagementNewModule { }
