<form #sisnitreForm="ngForm" class="form-horizontal">
    <div class="box-body">

        <!-- ========================== Modal FILEDS (START) ===============================-->

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Date sinistre *</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" class="form-control input-sm" bsDatepicker name="operationTime"
                    [(ngModel)]="operationTime" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
                    [maxDate]="maxDate" placement="bottom" placeholder="Date sinistre"
                    (ngModelChange)="onChangeOperationTime()" required autocomplete="off">
            </div>

            <label class="col-sm-2 control-label">Date de réparation réelle *</label>
            <div class="col-lg-4 col-md-4">
                <input type="text" class="form-control input-sm" bsDatepicker name="actualRepairTime"
                    [(ngModel)]="actualRepairTime" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' }"
                    [maxDate]="maxDate" [minDate]="operationTime" placement="bottom"
                    placeholder="Date de réparation réelle" required autocomplete="off">
            </div>

        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Coût de réparation *</label>
            <div class="col-lg-4 col-md-4">
                <input name="prix" [(ngModel)]="_sinistre.cost" type="number" class="form-control" required="true"
                    miplaceholder="Coût de réparation">
            </div>

            <label class="col-sm-2 control-label">Remboursement</label>
            <div class="col-lg-4 col-md-4">
                <input name="laborCost" [(ngModel)]="_sinistre.refund" type="number" class="form-control"
                    miplaceholder="Remboursement">
            </div>

        </div>

        <div class="form-group has-success">
            <label class="col-sm-2 control-label">Type</label>
            <div class="col-sm-4">
                <select [(ngModel)]="_sinistre.type" name="type" #type="ngModel" class="form-control input-sm select2">
                    <option value="Interne">Interne</option>
                    <option value="Externe">Externe</option>
                </select>
            </div>

            <label class="col-sm-2 control-label">Conducteur</label>
            <div class="col-sm-4">
                <select *ngIf="showDriverDropDown" [(ngModel)]="_sinistre.driver" name="driver"
                 #driver="ngModel" class="form-control input-sm select2"(ngModelChange)="onChangeDriver($event)">
                    <option *ngFor="let driver of drivers" [value]="driver.lastName">
                        {{driver?.lastName}}
                    </option>
                    <option *ngIf="isNewDriver(_sinistre.driver)" [selected]="true" [value]="_sinistre.driver">
                        {{_sinistre.driver}}</option>
                </select>

                <div *ngIf="!showDriverDropDown">
                    <div style="float: left">
                        <input name="newDriver" [(ngModel)]="_sinistre.driver"
                            type="text" class="form-control" placeholder="Conducteur">
                    </div>
                    <div *ngIf="drivers.length > 0" class="col-sm-2"
                        style="color: #9f191f;text-align: center" (click)="toggleDriverDropDown();">
                        <i class="fa fa-times"></i>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="form-group has-success">

            <label class="col-sm-2 control-label">Lieu</label>
            <div class="col-lg-4 col-md-4">
                <input name="referenceDADR" [(ngModel)]="_sinistre.place" maxlength="50" type="text"
                    class="form-control" placeholder="Lieu">
            </div>

            <label class="col-sm-2 control-label">Cabinet d'expertise</label>
            <div class="col-lg-4 col-md-4">

                <select [(ngModel)]="_sinistre.cabinetExpertise" class="form-control select2"
                    name="currentCabinetExpertise" *ngIf="showCabinetExpertisesDropDown"
                    (ngModelChange)="onChangeCabinetExpertise($event)">
                    <option *ngFor="let cabinetExpertise of cabinetExpertises" [ngValue]="cabinetExpertise"
                        [selected]="cabinetExpertise.id == _sinistre.cabinetExpertise.id">{{cabinetExpertise.name}}
                    </option>
                </select>

                <div *ngIf="!showCabinetExpertisesDropDown">
                    <div style="float: left">
                        <input name="referenceDADR" [(ngModel)]="_sinistre.cabinetExpertise.name" maxlength="50"
                            type="text" class="form-control" placeholder="Cabinet d'expertise">
                    </div>
                    <div *ngIf="this.cabinetExpertises.length > 0" class="col-sm-2"
                        style="color: #9f191f;text-align: center" (click)="toggleDropDown();">
                        <i class="fa fa-times"></i>
                    </div>
                </div>

            </div>

        </div>

        <div class="form-group has-success">

            <label class="col-sm-2 control-label">Observations</label>
            <div class="col-lg-4 col-md-4">
                <textarea name="Observations" [(ngModel)]="_sinistre.observation" type="text" class="form-control"
                    placeholder="Observations" name="observation"></textarea>
            </div>

            <label class="col-sm-2 control-label">Description de dégats</label>
            <div class="col-lg-4 col-md-4">
                <textarea name="Observations" [(ngModel)]="_sinistre.descriptionDamage" type="text" class="form-control"
                    placeholder="Description de dégats" name="descriptionDamage"></textarea>
            </div>

        </div>

        <!-- ========================== Modal FILEDS (END) ===============================-->

        <!-- ========================== Modal FOOTER (START) ===============================-->
        <div class="box-footer">
            <button *ngIf="mode === 'ADD'" [disabled]="!sisnitreForm.form.valid" class="btn btn-info pull-right"
                (click)="addSinistre()"><i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
            </button>
            <button [disabled]="!sisnitreForm.form.valid" class="btn btn-info pull-right" *ngIf="mode === 'UPDATE'"
                (click)="updateSinistre()"><i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
            </button>
        </div>
        <!-- ========================== Modal FOOTER (END) ===============================-->

    </div>
</form>