import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ciment-mission',
  templateUrl: './ciment-mission.component.html',
  styleUrls: ['./ciment-mission.component.css']
})
export class CimentMissionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
