import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule if using forms
import { PassageFormComponent } from './passage-form.component';
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
//import { DataManagementModule } from 'src/app/client-management/data-management'; // Ensure this is the correct path for your data management module
import { PassageService } from '../passage.service'; // Import the PassageService if it’s provided at the module level
import { NgxLoadingModule } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    PassageFormComponent // Declare your component here
  ],
  imports: [
    CommonModule,
    FormsModule, // Include FormsModule if your component uses forms
    ToastrModule.forRoot(), // Initialize ToastrModule
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),

  ],
  providers: [
    PassageService // Provide the PassageService here if needed
  ],
  exports: [
    PassageFormComponent // Export the component if used in other modules
  ]
})
export class PassageFormModule {}
