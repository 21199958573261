import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dns } from 'src/app/global.config';
import { DateInterval } from 'src/app/shared/model';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import { TransportTypeDto, TransportTypeInfoDto, TransportTypeTravelReport,SubMarkDto } from './transport-types';

@Injectable()
export class TransportTypeService {

  transportTypes: TransportTypeInfoDto[] = [];
  typeSubMark: SubMarkDto[] = [];
  transportTypeTravelReport: TransportTypeTravelReport[]= [];
  
  constructor(private _http: HttpClient) { }

  // list !
  findAllTransportTypes(): Observable<TransportTypeInfoDto[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<TransportTypeInfoDto[]>(dns + 'transportTypes', { headers: headers });
  }

  findAllMark(): Observable<SubMarkDto[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<SubMarkDto[]>(dns + 'performance/getAllType', { headers: headers });
  }

  getAllTransportType(): Observable<TransportTypeTravelReport[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<TransportTypeTravelReport[]>(dns + 'transportTypes/getAll', { headers: headers });
  }

  addTransportType(transportType: TransportTypeDto): Observable<TransportTypeInfoDto> {
    let headers = createAuthorizationHeader();
    let options = { headers: headers };
    return this._http.post<TransportTypeInfoDto>(dns + 'transportTypes', transportType, options);
  }

  updateTransportType(transportType: TransportTypeDto): Observable<TransportTypeInfoDto> {
    let headers = createAuthorizationHeader();
    return this._http.put<TransportTypeInfoDto>(dns + 'transportTypes', transportType, { headers: headers });
  }

  deleteTransportType(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'transportTypes/' + id, { headers: headers });
  }

  // valid !
  getOneTransportType(id: number): Observable<TransportTypeInfoDto> {
    let headers = createAuthorizationHeader();
    return this._http.get<TransportTypeInfoDto>(dns + 'transportTypes/' + id, { headers: headers });
  }

  getMonthlyChargesByTransportType(transportTypeId: number, dateInterval : DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(dns + 'transportTypes/' + transportTypeId + '/monthly/charges', dateInterval, { headers: headers });
  }

  getMonthlyAverageMaintenanceCostByTransportTypeId(transportTypeId: number, dateInterval : DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(dns + 'transportTypes/' + transportTypeId + '/monthly/average/maintenance', dateInterval, { headers: headers });
  }

  getMonthlyMaintenanceCostByTransportTypeId(transportTypeId: number, dateInterval : DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(dns + 'transportTypes/' + transportTypeId + '/monthly/cost/maintenance', dateInterval, { headers: headers });
  }

  getMonthlySinisterCountsByTransportTypeId(transportTypeId: number, dateInterval : DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post<any>(dns + 'transportTypes/' + transportTypeId + '/monthly/count/sinistre', dateInterval, { headers: headers });
  }
}
