import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupElementComponent } from './group-element.component';
import { RealtimeService } from '../../../services/realtime.service';  // Import any services if required
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { GroupItemModule } from '../group-item/group-item.module';

@NgModule({
  declarations: [
    GroupElementComponent  // Declare the component here
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    TruncateModule,
    GroupItemModule
  ],
  exports: [
    GroupElementComponent  // Export the component to be used in other modules if needed
  ],
  providers: [
    RealtimeService  // Provide any services if needed
  ]
})
export class GroupElementModule { }
