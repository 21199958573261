import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Vehicule } from '../../data-management';
import { PathService } from '../../historical/path/path.service';
// import Highcharts from "highcharts";



interface IPoint {
  memsX: number;
  memsY: number;
  memsZ: number;
}

@Component({
  selector: 'app-spider-chart',
  templateUrl: './spider-chart.component.html',
  styleUrls: ['./spider-chart.component.css']
})
export class SpiderChartComponent implements OnInit {
  overspeed: number = 0;
  braking: number = 0;
  shake: number = 0;
  drift: number = 0;
  acceleration: number = 0;
  points: IPoint[] = [];

  constructor(private pathService: PathService) {
    Highcharts.setOptions({
      time: {
        useUTC: false
      }
    });
  }


  ngOnInit() {
    // Initialisation si nécessaire
  }

  getSpiderChart(vehicule: Vehicule, path: any) {
    this.pathService.getPathDetails(vehicule.device.idDevice, {
      startDate: path.beginPathTime,
      endDate: path.endPathTime
    }).subscribe(response => {
      this.points = response.coordinates;
      let y = 0;
      let z = 0;
      let xpos = 0;
      let xneg = 0;

      this.points.forEach((point: IPoint) => {
        y += Math.abs(point.memsY);
        z += Math.abs(point.memsZ);
        if (point.memsX > 0) {
          xpos += Math.abs(point.memsX);
        } else if (point.memsX < 0) {
          xneg += Math.abs(point.memsX);
        }
      });

      this.drift = y / this.points.length;
      this.shake = z / this.points.length;
      this.acceleration = xpos / this.points.length;
      this.braking = xneg / this.points.length;

      this.drift = Math.log10(this.drift * 128 / 100) * 50;
      this.shake = Math.log10(this.shake * 128 / 100) * 50;
      this.acceleration = Math.log10(this.acceleration * 128 / 100) * 50;
      this.braking = Math.log10(this.braking * 128 / 100) * 50;

      if (vehicule.maxSpeed > 0) {
        this.overspeed = Math.log10((this.points.length * 100) / vehicule.maxSpeed) * 50;
      }

      this.DisplaySpiderChart();
    });
  }

  DisplaySpiderChart() {
    // Highcharts.chart('container', {
    //   chart: {
    //     type: 'line',
    //     polar: true,
    //     backgroundColor: null,
    //     style: {
    //       fontFamily: 'Dosis, sans-serif'
    //     }
    //   },

    //   title: {
    //     text: ''
    //   },

    //   pane: {
    //     size: '40%'
    //   },

    //   xAxis: {
    //     categories: ['SurVitesse', 'Accélération', 'Dérive', 'Freinage', 'Secousse'],
    //     tickmarkPlacement: 'on',
    //     lineWidth: 0,
    //     labels: {
    //       enabled: false
    //     },
    //     gridLineColor: '#000'
    //   },

    //   yAxis: {
    //     gridLineInterpolation: 'polygon',
    //     lineWidth: 0,
    //     min: 0,
    //     endOnTick: true,
    //     showLastLabel: true,
    //     tickPositions: [0, 32, 64, 96, 128],
    //     gridLineColor: '#000'
    //   },

    //   series: [
    //     {
    //       name: '',
    //       data: [this.overspeed, this.acceleration, this.drift, this.braking, this.shake],
    //       pointPlacement: 'on',
    //       color: '#ff3300'
    //     }
    //   ],

    //   responsive: {
    //     rules: [{
    //       condition: {
    //         maxWidth: 500
    //       },
    //       chartOptions: {
    //         legend: {
    //           align: 'center',
    //           verticalAlign: 'bottom',
    //           enabled: false
    //         },
    //         pane: {
    //           size: '70%'
    //         }
    //       }
    //     }]
    //   }
    // });
  }

}
