import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { imagesDir, owner, lastUpdate } from '../../global.config';
import { Action, Credentials, User } from './credentials';
import { SigninService } from './signin.service';
import * as accountGuards from "../../utils/security/subAccount.guard";
import { DataManagementService } from '../../client-management/data-management/data-management.service';
import { MapService } from '../../utils/leaflet/service/map.service';
import { PassageService, TransportTypeService } from 'src/app/client-management/charges-pfb';
import { RealtimeRestService } from 'src/app/client-management/realtime/services/realtime-rest.service';

@Component({
  selector: 'app-signin',
  templateUrl: 'signin.component.html'
})
export class SigninComponent implements OnInit {

  lastUpdate: Date | null = null;
  isNewVersion: boolean = false;
  owner: string;
  avatar: string;
  credentials: Credentials;
  loading = false;
  todayDate: Date = new Date();


  constructor(private signinService: SigninService,
    private router: Router,
    private toastr: ToastrService,
    public dataManagementService: DataManagementService, private mapService: MapService,
    public transportTypeService: TransportTypeService, public passageService: PassageService,
    public realtimeRestService: RealtimeRestService) {
    this.owner = owner;
    this.avatar = imagesDir + 'rim.png';
    this.credentials = new Credentials();
    this.lastUpdate = lastUpdate;

    //show new version during 2 days
    var h = (new Date().getTime() - this.lastUpdate.getTime()) / 1000 / 60 / 60;
    if (Math.floor(h) <= 48) this.isNewVersion = true;
  }

  ngOnInit() {
    document.body.className = 'hold-transition login-page';
    document.body.style.background = "url(" + imagesDir + "road-220058_960_720.jpg) no-repeat center fixed";
    document.body.style.backgroundSize = 'cover';
    document.body.style.height = '100% !important';
  }

  login() {

    this.loading = true;

    if (localStorage.getItem('id_token')) {
      localStorage.removeItem('id_token');
    }

    this.signinService.login(this.credentials).subscribe(
      currentUser => {

        this.loading = false;

        /**
         * init global vars !
         * */
        this.dataManagementService.pointInterests = null;
        this.dataManagementService.selectedGroup = null;
        this.dataManagementService.selectedVehicule = null;
        this.dataManagementService.groups = null;
        this.signinService.accounts = [];
        this.realtimeRestService.pathConfigs = null;
        this.transportTypeService.transportTypes = [];
        this.passageService.passages = [];

        this.mapService.init();
        /**
         * keep current user

         this.signinService.currentUser = currentUser;* */

        /**
         * store token in local storage
         * */
        localStorage.setItem('id_token', currentUser.token);
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        if (!localStorage.getItem('notification_sound'))
          localStorage.setItem('notification_sound', 'true');
        if (!localStorage.getItem('realTimeNotificationSound'))
          localStorage.setItem('realTimeNotificationSound', 'false');
        if (!localStorage.getItem('isManually'))
          localStorage.setItem('isManually', 'false');

        /***
         *
         * Account expiration
         */
        if (currentUser.expirationDate) {
          let days = 0;
          let expirationDate = new Date(currentUser.expirationDate);
          days = Math.floor((Date.UTC(expirationDate.getFullYear(), expirationDate.getMonth(), expirationDate.getDate()) -
            Date.UTC(this.todayDate.getFullYear(), this.todayDate.getMonth(), this.todayDate.getDate())) / (1000 * 60 * 60 * 24));
          if (days < 16) {
            this.toastr.warning('Expiration Compte ' + days + ' Jour(s) !', 'Alert!', {
              closeButton: true,
              positionClass: 'toast-top-center',
              timeOut: 10000
            });
          }
        }
        //this.router.navigate(['/client']);
        this.router.navigate([accountGuards.getDefaultRout()]);
        let action = new Action();
        action.type = 'CONNECT';
        if (!currentUser.isRoot) {
          this.signinService.addAction(currentUser.id, action).subscribe(action => {

          });
        }
      }
      , (error) => {
        if (error.status == 401) {
          this.toastr.error('Compte Provisoirement Suspendu ! Merci de contacter 0808538705 / 0522583358! ', 'Alert!', {
            closeButton: true,
            positionClass: 'toast-top-center',
            timeOut: 10000
          });
        } else {
          this.toastr.error('login ou mot de passe non valid ! ', 'Alert!', {
            closeButton: true,
            positionClass: 'toast-bottom-right',
            timeOut: 10000
          });
        }
        this.loading = false;
      });
  }

}
