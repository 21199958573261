import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { Mileage } from "./mileage";
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { DateInterval } from "../../../shared/model";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MileageService {
  constructor(private _http: HttpClient) { }

  getAllMileages(deviceId: number, dateInterval: any): Observable<Mileage[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<Mileage[]>(dns + "mileage/" + deviceId, dateInterval, { headers: headers });
  }

  getAllMileagesRealTime(deviceId: number): Observable<Mileage[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Mileage[]>(dns + "mileage/realTime/" + deviceId, { headers: headers });
  }

  getAllMileagesDaily(deviceId: number, dateInterval: any): Observable<Mileage[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<Mileage[]>(dns + "mileage/daily/" + deviceId + '?timezoneOffset=' + (new Date().getTimezoneOffset()), dateInterval, { headers: headers });
  }

  getDailyMileageOfAllVehicules(dateInterval: any): Observable<any[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .post<any[]>(dns + "mileage/all/daily", dateInterval, { headers: headers });
  }

  getMileages(): Observable<Mileage[]> {
    let headers = createAuthorizationHeader();
    return this._http
      .get<Mileage[]>(dns + "mileage/", { headers: headers });
  }

  getTodaysMileage(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + "mileage/todayDetails/" + deviceId, dateInterval, {
        headers: headers
      });
  }

  getTodaysRealTimeMileage(deviceId: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .get(dns + "mileage/realtime/todayDetails/" + deviceId,  {
        headers: headers
      });
  }

  getMileageDetails(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + "mileage/details/" + deviceId, dateInterval, {
        headers: headers
      });
  }

  exportMileage(idDevice: number, dateInterval: any): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + "mileageEx/" + idDevice + '?timezoneOffset=' + (new Date().getTimezoneOffset()), dateInterval, { headers: headers, responseType: 'blob' as 'json' });
  }

  getMileageDetailsParc(dateInterval: DateInterval): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http
      .post(dns + "mileage/", dateInterval, { headers: headers });
  }

  exportMileagePDF(idDevice : number, vehicule: string, groupName: string, startDate: number, endDate: number,
    totalDistance: number, totaleDuration: string): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + "mileagePdf?groupName=" + groupName + "&startDate=" +
      startDate + "&endDate=" + endDate + "&vehicule=" + vehicule + "&total=" +
      totalDistance + "&totalDrivingDuration=" + totaleDuration + "&timezoneOffset=" + (new Date().getTimezoneOffset()),
      idDevice, { headers: headers, responseType: 'blob' as 'json' });
  }
}
