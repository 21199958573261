import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InterventionFormComponent } from './intervention-form.component';
import { ToastrModule } from 'ngx-toastr'; // Assuming you're using ngx-toastr for toast notifications
import { InterventionService } from '../intervention-list/intervention.service'; // Service for handling interventions

@NgModule({
  declarations: [
    InterventionFormComponent  // Declare InterventionFormComponent
  ],
  imports: [
    CommonModule,   // Provides Angular common directives
    FormsModule,    // Required for template-driven forms with ngModel
    ToastrModule.forRoot()  // For displaying toast notifications
  ],
  providers: [
    InterventionService  // Provide the InterventionService
  ],
  exports: [
    InterventionFormComponent  // Export the component to make it available outside this module
  ]
})
export class InterventionFormModule { }
