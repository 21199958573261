<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
<div class="row">
  <div class="box" style="border-radius: 0px;min-height: unset; margin-bottom: 0px !important;">
    <div class="box-body" style="padding: 8px 10px 0px 10px !important;">
      <div class="form-group row" style="margin-bottom: -4px !important;">
        <div class="col-md-6">
          <div class="row" style="width: 350px;">
            <div class="col-md-4 selectStyle">
              <div class="grp-slct">
                <select class="form-control input-sm" name="transport" [ngModel]="selectedTransport"
                (ngModelChange)="chooseTransport($event)" required>
                  <option *ngFor="let transport of transportTypeService?.transportTypes" [ngValue]="transport">
                    {{transport?.name}}
                  </option>
                </select>
                <label>Pôle</label>
              </div>
            </div>
            <div class="col-md-4 selectStyle" style="margin-left: -25px;">
              <div  style="display: grid; grid-template-columns: 106px 48px; gap: 5px;">
                <div class="grp-slct">
                  <select class="form-control input-sm" name="passage" [ngModel]="selectedPassage"
                  (ngModelChange)="chooseSchedule($event)" required>
                    <option *ngFor="let passage of passageService?.passages" [ngValue]="passage">
                      {{passage?.name | capitalizefirst}}
                    </option>
                  </select>
                  <label>Site</label>
                </div>
              </div>
            </div>
            <div class="col-md-4 selectStyle">
              <div class="form-btns">
                <button type="button" (click)="loadMissions()" class="btn btn-success btn-flat btn-sm" style="border-radius: 5px; padding: 5px;"
                  title="Valider"><span style="animation: blinker 1s linear infinite;"><b>{{getTrackingTime()}}</b></span> <b><i class="fa fa-refresh" aria-hidden="true"></i></b>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="loadingTracking" style="font-size: 12px; text-align: center;">
            <i class="fa fa-spinner fa-spin" *ngIf="loadingTracking"></i> Chargement ...
        </div>
        <div *ngIf="updateTrackingTimer && !loadingTracking" style="font-size: 12px;text-align: center;">
            <i class="fa fa-hourglass-half" aria-hidden="true"></i>
            Tracking Retour Site {{updateTrackingTimer}} seconds...
        </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- trackingMissionData -->
<ng-container *ngFor="let trackingData of trackingMissionDataSorted; let i = index;">

    <!-- TRACKING STATUS && trackingData.trackingMission?.accordingDistance>1.0 && {'width': ((trackingData.selectedMission?.routeLegs?.length) * 25) + '%'}-->
    <div *ngIf="trackingData.trackingMission?.state != 'PENDING' && trackingData.selectedMission != null " class="row tracking-status">
        <div class="col-md-4">
          <div class="stack-status">
            <div class="tracking-status-timeline"
                [ngStyle]="{'width':'75%'}">
                <ng-container *ngFor="let route of trackingData.trackingMission?.mission?.routeLegs; let i = index">
                    <div *ngIf="route?.selected" class="tracking-status-timeline-completion" [ngStyle]="{'left': 0 + '%','width': ((  75 * trackingData.trackingMission?.progressPercent) / 100 + (i * 75)) + '%',
                   'background': trackingMission?.state == 'DELIVERY' && trackingData.trackingMission?.estimatedEndDate==trackingData?.trackingMission?.endDate ? '#00a65a' :
                   (trackingData.trackingMission?.state == 'INPROGRESS' && trackingData.trackingMission?.accordingDistance>1.0) ? '#00a65a' :
                   trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate ? '#f39c12' :
                   trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL' ? '#dd4b39' : '#4ab4dd' }"
                        matTooltip="{{(trackingData?.trackingMission?.realTimeRecord?.recordTime | date:'HH:mm')+' / '+(trackingData.trackingMission?.progressPercent | number:'.0-2')}}%">
                        <div class="selected">
                            <p *ngIf="trackingData.trackingMission?.accordingDistance" class="kls">
                                {{trackingData.selectedMission?.travelDistance -
                                trackingData.trackingMission?.accordingDistance | number:'.0-2'}} KM</p>
                            <img *ngIf="trackingData.selectedVehicule.icon" class=" tooltip-350" container="body"
                                src="{{imagesCarsDir + trackingData.selectedVehicule.icon}}" alt="img" />
                            <img *ngIf="!trackingData.selectedVehicule.icon" class=" tooltip-350" container="body"
                                src="{{imagesCarsDir + 'automobile_green.png'}}" alt="img" />
                            <p class="kls"><b>{{trackingData.selectedVehicule?.matricule}}</b></p>
                        </div>
                    </div>
                </ng-container>
            </div>

            <ng-container
                *ngFor="let route of trackingData.selectedMission?.routeLegs; let i = index; let j = index">
                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle" [ngClass]="{'active': trackingData.trackingMission?.mission?.routeLegs[i]?.visited,
                    'green' : (trackingData.trackingMission?.state == 'INPROGRESS' && trackingData.trackingMission?.accordingDistance>1.0),
                    'orange' :  trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate,
                    'red' : trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL'
                }" [ngStyle]="{'left': (i * 25) + '%'}" matTooltip="{{route?.labelPOISource}}">
                    <span *ngIf="trackingData.trackingMission?.state == 'PENDING'" class="status"
                        title="{{trackingData?.trackingMission?.estimatedStartDate | date:'dd-MM-yy HH:mm'}}">{{trackingData?.trackingMission?.estimatedStartDate
                        | date:'dd-MM-yy HH:mm'}}</span>
                    <span *ngIf="trackingData.trackingMission?.state != 'PENDING'" class="status"
                        title="{{trackingData?.trackingMission?.startDate | date:'dd-MM-yy HH:mm'}}">{{trackingData?.trackingMission?.startDate
                        | date:'dd-MM-yy HH:mm'}}</span>
                    <div class="icon"></div>
                </div>
                <div *ngIf="i == 0" class="image-tracking-status image-tracking-status img-circle" [ngClass]="{'active': trackingData.trackingMission?.mission?.routeLegs[i]?.visited && trackingData.trackingMission?.last,
                    'green' : trackingData.trackingMission?.state == 'INPROGRESS',
                    'orange' : trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate}"
                    [ngStyle]="{'left': ((i + 1) * 75) + '%'}" matTooltip="{{route?.labelPOITarget}}">
                    <span *ngIf="trackingData.trackingMission?.state == 'PENDING'" class="status kls"
                        title="{{trackingData?.trackingMission?.estimatedEndDate | date:'dd-MM-yy HH:mm'}}">{{trackingData?.trackingMission?.estimatedEndDate
                        | date:'dd-MM-yy HH:mm'}}</span>
                    <span
                        *ngIf="trackingData.trackingMission?.state == 'DELIVERY' || trackingData.trackingMission?.state == 'DELAY'"
                        class="status kls"
                        title="{{trackingData?.trackingMission?.endDate | date:'dd-MM-yy HH:mm'}}">{{trackingData?.trackingMission?.endDate
                        | date:'dd-MM-yy HH:mm'}}</span>
                    <div class="icon"></div>
                </div>
                <span *ngIf="i == 0" [ngStyle]="{'left': (i + 1 * 37.5) + '%'}" class="klm">{{route?.travelDistance
                    |
                    number:'.0-2'}} Km</span>
            </ng-container>
        </div>

        </div>
        <!-- CURRENT PATH INFO -->
        <div class="col-md-8">
            <fieldset class="mt-2">
                <legend class="mb-0">Info trajet</legend>
                <div>
                    <ng-container> Matricule : <b>{{trackingData.selectedVehicule?.matricule}}</b></ng-container> |
                    <ng-container> Vitesse :
                        <b>{{trackingData.trackingMission?.realTimeRecord?.speed}}</b>
                    </ng-container>|<ng-container> Position : <b
                            title="{{trackingData.trackingMission?.realTimeRecord?.geocoding}}">
                            {{trackingData.trackingMission?.realTimeRecord?.geocoding | truncate :[25]}}</b>
                    </ng-container>|
                    <ng-container> Duration : <b>{{selectedRouteLeg(i)?.travelDuration | timediff}}</b></ng-container>
                    <br />
                    <ng-container> A
                        proximité : <b>{{trackingData.trackingMission?.firstNearest ?
                            selectedRouteLeg(i)?.labelPOISource :
                            selectedRouteLeg(i)?.labelPOITarget}}</b>
                        de {{trackingData.trackingMission?.minDistance | number:'.0-2'}} Km</ng-container> |
                    <ng-container> Distance
                        Parcourue : <b>{{trackingData.trackingMission?.accordingDistance | number:'.0-2'}}
                            Km</b></ng-container>|
                    <ng-container> Pourcentage : <b>{{trackingData.trackingMission?.progressPercent |
                            number:'.0-2'}}%</b></ng-container> | <ng-container>Article :
                        <b>{{trackingData.selectedMission?.article}}</b>
                    </ng-container>
                    | <ng-container>Quantité :
                        <b>{{trackingData.selectedMission?.quantity}}</b>
                    </ng-container>
                     | <ng-container>B.L :
                        <b>{{trackingData.selectedMission?.bl}}</b>
                    </ng-container>
                    | <ng-container>C.chauffeur :
                        <b>{{trackingData.selectedMission?.codeChauffeur}}</b>
                    </ng-container>
                    <ng-container *ngIf="trackingData.trackingMission?.startDate">| Date debut :
                        <b>{{trackingData.trackingMission?.startDate | date:'dd-MM-yy HH:mm'}}</b>
                    </ng-container>
                    | <ng-container>Distance restante : <b>{{trackingData.selectedMission?.routeLegs[0].travelDistance -
                            trackingData.trackingMission?.mission.routeLegs[0].travelDistance | number:'.0-2'}} Km</b></ng-container>
                    <ng-container *ngIf="trackingData.trackingMission?.endDate">| Date Fin :
                        <b>{{trackingData.trackingMission?.endDate | date:'dd-MM-yy HH:mm'}}</b>
                    </ng-container>
                    <ng-container *ngIf="trackingData.trackingMission?.state == 'DELAY'">
                        | Durée du retard : <b>{{ trackingData.trackingMission?.delayDuration | timediff}}</b>
                        <!-- | Durée du retard : <b>{{ calcDelayTime(trackingData.trackingMission?.endDate, trackingData.trackingMission?.startDate,trackingData.trackingMission?.estimatedEndDate) | timediff}}</b> -->
                    </ng-container>
                    <br />
                    <span *ngIf="trackingData.trackingMission?.state == 'PENDING'" class="badge label-secondary">En
                        attente</span>
                    <span *ngIf="trackingData.trackingMission?.state == 'INPROGRESS'" class="badge label-info">En
                        cours</span>
                    <span *ngIf="trackingData.trackingMission?.state == 'DELIVERY' && trackingData.trackingMission?.estimatedEndDate==trackingData.trackingMission?.endDate" class="badge label-success">Arrivé à
                        temps</span>
                    <span *ngIf="trackingData.trackingMission?.estimatedEndDate<trackingData.trackingMission?.endDate" class="badge label-warning">Arrivé en
                        retard</span>
                    <span *ngIf="trackingData.trackingMission?.state == 'PROBABLE_ARRIVAL'" class="badge label-danger">Arrivée
                        probable</span>
                </div>
            </fieldset>
            <!-- END CURRENT PATH INFO -->
        </div>
    </div>
    <!-- END TRACKING STATUS -->

</ng-container>
<!-- END TRACKING INFO -->

<ng-container *ngIf="trackingMissionDataSorted.length == 0">
    <div class="row">
        <div class="col-xl-12 col-md-12 mb-12 col-xs-12">
            <div class="alert alert-warning alert-dismissible text-center">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                    ×
                </button>
                <h4 style="color: white">
                    <i class="fa fa-info-circle"></i> Information
                </h4>
                Aucune Mission à suivre en ce moment
            </div>
        </div>
    </div>
</ng-container>
