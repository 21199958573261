import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiTypeSettingsComponent } from './multi-type-settings.component';
import { MultiTypeService } from '../../providers/multi-type-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import if you use reactive forms
import { ToastrModule } from 'ngx-toastr'; // Import if you're using Toastr for notifications
import { ModalModule } from 'ngx-bootstrap/modal';
import { MultiTypeSettingsFormModule } from './multi-type-settings-form/multi-type-settings-form.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [
    MultiTypeSettingsComponent,
    // Declare your component here
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule, // Import if you need reactive forms
    ToastrModule.forRoot(), // Import Toastr for notifications
    ModalModule.forRoot(),
    MultiTypeSettingsFormModule,
    FormsModule,
    PaginationModule.forRoot(),
    FilterPipeModule

  ],
  providers: [
    MultiTypeService // Provide the MultiTypeService here
  ],
  exports: [
    MultiTypeSettingsComponent // Export the component if it needs to be used outside of this module
  ]
})
export class MultiTypeSettingsModule { }
