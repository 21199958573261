import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GroupsContainerComponent } from '../groups-container.component';
import { DataManagementService, Group, Vehicule } from 'src/app/client-management/data-management';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { RealtimeService } from '../../../services/realtime.service';
import { RealtimeComponent } from '../../../realtime.component';
import { Subscription } from 'rxjs';
import { RealtimeHelperService } from '../../../services/realtime-helper.service';

@Component({
  selector: 'group-item',
  templateUrl: './group-item.component.html',
  styleUrls: ['./group-item.component.css'],
})
export class GroupItemComponent implements OnInit, OnDestroy {
  @Input('item') item: any
  selectedVehicule: Vehicule;
  selectedVehiculeSubs: Subscription;

  constructor(
    private mapService: MapService,
    private realTimeService: RealtimeService,
    public realTime: RealtimeComponent,
    public dataManagementService: DataManagementService,
    public realtimeHelperService : RealtimeHelperService
  ) {}

  ngOnInit(): void {
    this.selectedVehiculeSubs = this.realTimeService
      .getVehicule()
      .subscribe((v) => {
        this.selectedVehicule = v;
      });
  }

  goToRealTimeRecord(group: Group, vehicule: Vehicule) {
    let selectedRTMarker = this.mapService.getRTMarker(vehicule.idDevice);

    if (selectedRTMarker && selectedRTMarker.value) {
      selectedRTMarker.value.openPopup();
    }


    this.mapService.clearPolylines();
    this.realTimeService.stopTimerTracking();

    if ( this.realTime.carDashboard &&!this.realTime.carDashboard.isMinifyDashboard && this.realTime.carDashboard.opened) {
      this.realTime.displayDashboard(vehicule);
    }

    if (selectedRTMarker && selectedRTMarker.value) {
      selectedRTMarker.value.openPopup();
    }

    if (this.selectedVehicule && vehicule && this.selectedVehicule.idDevice !== vehicule.idDevice) {
      if (this.realTimeService.newRealTimeRecords) {
        this.realTimeService.newRealTimeRecords.forEach((realTimeRecord) => {
          if (
            realTimeRecord.idRealTimeRecord === vehicule.idDevice &&
            this.selectedVehicule !== vehicule
          ) {
            this.mapService.map.setView(realTimeRecord.coordinate, 17);
          }
        });
      }

      this.realTimeService.setVehicule(vehicule);

      if (group) { // group !== null is sufficient since `group` is not undefined
        this.dataManagementService.selectedGroup = group;
      }

      this.dataManagementService.selectedVehicule = vehicule;

      if (this.selectedVehicule) {
        this.realTimeService.timerTracking(this.selectedVehicule.idDevice);
      }
    } else {
      this.realTimeService.stopRealTime();
      this.realTimeService.setVehicule(new Vehicule());
    }

    if (!vehicule.odo) {
      this.realTime.loadAccumOdo(vehicule);
    }
  }

  ngOnDestroy(): void {
    this.selectedVehiculeSubs.unsubscribe();
  }
}
