<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>
<div class="headerContainer">
  <div class="item-a">
  <div class="form-group row" style=" margin-bottom: 60px !important;">
    <label class="col-sm-2 control-label grp-lbl">Pôle</label>
          <div class="col-sm-9 grp-slct">
            <select class="form-control input-sm" name="transport" [ngModel]="selectedTransport"
              (ngModelChange)="chooseTransport($event)" required>
              <option *ngFor="let transport of transportTypeService?.transportTypeTravelReport" [ngValue]="transport">
                {{transport?.name}}
              </option>
            </select>
          </div>
  </div>

</div>
<div class="item-b">
  <div class="gridCard">
    <div class="">
      <div class="stati" style="color: #0078b5;">
          <img src="assets/icons/delivery-time.svg" alt="" style="width: 35px;filter: invert(25%) sepia(31%) saturate(6154%) hue-rotate(182deg) brightness(100%) contrast(101%);">
          <div>
              <b>{{s201}}</b>
              <span style="font-size: larger;"><strong style="color: #0078b5;">201</strong></span>
              </div>
          </div>

      </div>

      <div class="">
            <div class="stati" style="color: #017a02;">
                <img src="assets/icons/delivery-time.svg" alt="" style="width: 35px;filter: invert(27%) sepia(99%) saturate(994%) hue-rotate(89deg) brightness(96%) contrast(104%);">
                <div>
                    <b>{{s202}}</b>
                    <span style="font-size: larger;"><strong style="color: #017a02;">202</strong></span>
                    </div>
                </div>
      </div>

      <div class="">
            <div class="stati" style="color: #f9e900;">
                <img src="assets/icons/delivery-time.svg" alt="" style="width: 35px;filter: invert(77%) sepia(98%) saturate(3792%) hue-rotate(353deg) brightness(106%) contrast(108%);">
                <div>
                    <b>{{s203}}</b>
                    <span style="font-size: larger;"><strong style="color: #f9e900;">203</strong></span>
                    </div>
                </div>
      </div>
      <div class="">
              <div class="stati" style="color: #d60c1a;">
                  <img src="assets/icons/delivery-time.svg" alt="" style="width: 35px;filter: invert(16%) sepia(100%) saturate(4082%) hue-rotate(348deg) brightness(82%) contrast(106%);">
                  <div>
                      <b>{{s204}}</b>
                      <span style="font-size: larger;"><strong style="color: #d60c1a;">204</strong></span>
                      </div>
                  </div>
      </div>
      <div class="">
        <div class="stati" style="color: #db955a;">
            <img src="assets/icons/delivery-time.svg" alt="" style="width: 35px;filter: invert(61%) sepia(100%) saturate(315%) hue-rotate(337deg) brightness(93%) contrast(84%);">
            <div>
                <b>{{s205}}</b>
                <span style="font-size: larger;"><strong style="color: #db955a;">205</strong></span>
                </div>
            </div>
      </div>

      <div class="">
        <div class="stati" style="color: #2c342e;">
            <img src="assets/icons/delivery-time.svg" alt="" style="width: 35px;filter: invert(16%) sepia(16%) saturate(439%) hue-rotate(83deg) brightness(88%) contrast(86%);">
            <div>
                <b>{{s211}}</b>
                <span style="font-size: larger;"><strong style="color: #2c342e;">211</strong></span>
                </div>
            </div>
      </div>

      <div class="">
        <div class="stati" style="color: #28a745;">
            <img src="assets/icons/delivery-time.svg" alt="" style="width: 35px;filter: invert(45%) sepia(98%) saturate(405%) hue-rotate(81deg) brightness(97%) contrast(83%);">
            <div>
                <b>{{s212}}</b>
                <span style="font-size: larger;"><strong style="color: #28a745;">212</strong></span>
                </div>
            </div>
      </div>
   </div>
</div>
<div class="item-c">
  <div class="row">
    <div *ngIf="loading" class="col-sm-12 text-center" style="font-size: 12px;margin-top: 8px;">
      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Chargement ...
    </div>
    <div class="col-sm-12 text-center" *ngIf="!loading && updateTrackingTimer" style="font-size: 12px;margin-top: 8px;">
      <i class="fa fa-hourglass-half" aria-hidden="true"></i>
      Mise à jour dans {{updateTrackingTimer}} seconds...
    </div>
  </div>
</div>
</div>


<!-- Table -->
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h2 class="row__title">
        <!-- <button class="btn btn-success btn-sm" style="margin-left: 5px; margin-right: 5px;" (click)="exportTravelReportRt()" [disabled]="travelReportRtLoader" title="Suivi en cours">
          <i *ngIf="!travelReportRtLoader" class="fa fa-upload" style="color: #fff;" aria-hidden="true"></i>
          <i *ngIf="travelReportRtLoader" class="fa fa-refresh fa-spin" style="color: #fff;" aria-hidden="true"></i>
        </button> -->
        <button class="btn btn-primary btn-sm" (click)="exportTravelReport()" [disabled]="travelReportLoader" title="Suivi journalier">
          <i *ngIf="!travelReportLoader" class="fa fa-upload" style="color: #fff;" aria-hidden="true"></i>
          <i *ngIf="travelReportLoader" class="fa fa-refresh fa-spin" style="color: #fff;" aria-hidden="true"></i>
        </button>
        <button class="btn btn-warning btn-sm" style="float: right;" (click)="refresh()" [disabled]="loading"><i class="fa fa-refresh" [ngClass]="{'fa-spin': loading}" style="color: #fff;" aria-hidden="true"></i></button>
      </h2>
    </div>
  </div>
  <div class="row row--top-5">
    <div class="col-md-12">
      <div class="table-container">
        <table class="table">
          <thead class="table__thead">
            <tr>
              <th class="table__th">Matricule</th>
              <th class="table__th">Site Départ</th>
              <th class="table__th">Heure Départ</th>
              <th class="table__th">V.Max </th>
              <th class="table__th">Durée</th>
              <th class="table__th">Km</th>
              <th class="table__th">Arret</th>
              <th class="table__th">Arret RPM</th>
              <th class="table__th">TFU (L)</th>
              <th class="table__th">Cible (%)</th>
              <th class="table__th">Gasoil (%)</th>
              <th class="table__th">Zones</th>
              <th class="table__th">O.rotation</th>
              <th class="table__th">Numéro OT</th>
              <th class="table__th">Mission</th>




              <th class="table__th" style="padding: 0px 0px !important;width: 4px;">
                <button class="btn btn-secon btn-sm" (click)="expandAll()" style="color: #fff; border: none; background: none;"><i class="fa fa-th-list" style="width: 10px;" aria-hidden="true"></i></button>
              </th>
            </tr>
          </thead>
          <tbody class="table__tbody" *ngFor="let travelReport of travelReports | async;let i = index; let first = first; let last = last;">
              <tr class="table-row table-row--chris">

              <td data-column="Destination" class="table-row__td">
                {{travelReport?.vehicule?.matricule | noValue}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.startAddress | noValue}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.beginPathTime | date: 'HH:mm'}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.maxSpeed}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.pathDuration | durationStr}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.distanceDriven | number : '1.2-2'}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.stopDuration | durationStr}}
              </td>
              <td data-column="Destination" class="table-row__td">
                <span *ngIf="travelReport?.stopRpm != null; else nullStop">{{travelReport?.stopRpm | durationStr}}</span>
                <ng-template #nullStop>
                  ...
                </ng-template>

              </td>
              <td data-column="Destination" class="table-row__td">
                <!-- <span [ngStyle]="{color: travelReport.vehicule.useFms ? '' : '#f39c12'}"> </span>  -->
                {{travelReport?.fuelUsed | number : '1.2-2'}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.vehicule?.fuelAverage | number : '1.2-2'}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.fuelPercentage | number : '1.2-2'}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.zone | noValue}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.objectifRotation | noValue}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.otNumber | noValue}}
              </td>
              <td data-column="Destination" class="table-row__td">
                {{travelReport?.missionType | noValue}}
              </td>


              <td class="table-row__td" style="padding-left: 0px !important;padding-right: 0px !important;">
                <button class="btn btn-primary btn-sm" (click)="expandRow(i)" style="color: #fff;"><i class="fa fa-eye" aria-hidden="true"></i></button>
              </td>
            </tr>
            <tr class="table-row table-row--gray" *ngIf="expendedRow(i)">
              <td class="table-row__td" colspan="18">
                <div class="moreInfo">
                  <div>
                    <div><b>Mark: </b>{{travelReport?.vehicule?.mark | noValue}}</div>
                    <div><b>Type Camion: </b>{{travelReport?.vehicule?.subMark | noValue}}</div>
                  </div>
                  <div>
                    <div><b>PU: </b><span>{{travelReport?.unitCost | noValue}} DH/L</span></div>
                    <div><b>PU Location: </b><span>{{travelReport?.vehicule?.rental | number : '1.2-2' | noValue}} DH</span></div>
                  </div>
                  <div>
                    <div><b>Charge Location: </b><span>{{travelReport?.chargeLocation | number : '1.2-2' | noValue}} DH/V</span></div>
                    <div><b>Charge Transport: </b><span>{{travelReport?.chargeGlobal | number : '1.2-2' | noValue}} DH/V</span></div>
                  </div>
                  <div>
                    <div><b>Gasoil: </b><span>{{travelReport?.fuelCost | number : '1.2-2' | noValue}} DH</span></div>
                    <div><b>Poids: </b><span>{{travelReport?.vehicule?.capacity | noValue}} Kg</span></div>
                  </div>

                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


</div>
