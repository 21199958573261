import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule for Angular directives like ngIf and ngFor
import { TruncatePipe } from './truncate.pipe'; // Import the TruncatePipe

@NgModule({
  declarations: [
    TruncatePipe // Declare the TruncatePipe
  ],
  imports: [
    CommonModule // Import CommonModule to ensure Angular common features are available
  ],
  exports: [
    TruncatePipe // Export the TruncatePipe so it can be used in other modules
  ]
})
export class TruncateModule { }
