import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VidangeComponent } from './vidange.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { VidangeFormComponent } from './vidange-form/vidange-form.component';
import { ImportExportFormsComponent } from '../import-export-forms/import-export-forms.component';
import { VidangeFormModule } from './vidange-form/vidange-form.module';
import { ImportExportFormsModule } from '../import-export-forms/import-export-forms.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    VidangeComponent,

  ],
  imports: [
    CommonModule, // Import common functionalities
    ModalModule.forRoot(), // Import modal functionalities
    ToastrModule.forRoot(),
    VidangeFormModule,
    FormsModule,
    ImportExportFormsModule

  ],
  exports: [
    VidangeComponent // Export the component if it needs to be used outside of this module
  ]
})
export class VidangeModule { }
