import { Component, OnInit, ViewChild } from "@angular/core";
import { Reparation, Provider, MultiType, Group } from "../../model/index";
import { Subscription } from 'rxjs/Subscription';
import { ToastrService } from "ngx-toastr";
import { DataManagementService } from "../../../data-management/data-management.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ReparationService } from '../../providers/reparation-service';
import { ImportExportService } from "../../../import-export";
import { RapportDto, Vehicule } from "../../../data-management";

@Component({
  selector: "app-reparation",
  templateUrl: "./reparation.component.html",
  styleUrls: ["./reparation.component.css"]
})
export class ReparationComponent implements OnInit {

  reparations: Reparation[] = [];

  reparationsId: number[] = [];

  vehicule: Vehicule = new Vehicule();

  group: Group = new Group();

  repport: RapportDto = new RapportDto();

  getVehiculeWasSelectedSubscription: Subscription;

  getGroupWasSelectedSubscription: Subscription;

  reparation: Reparation = new Reparation();

  mode: 'ADD' | 'UPDATE';

  loading: boolean = false;

  isDeleteMultiple: boolean = false;

  /**import modal */
  @ViewChild('importModal', { static: false }) importModal: ModalDirective;
  selectedItem = 'REPARATION';

  @ViewChild('reparationModal', { static: false })
  reparationModal: ModalDirective;

  @ViewChild('reparationDeleteModal', { static: false })
  reparationDeleteModal: ModalDirective;

   searchTerm: string | null = null;


  constructor(private reparationService: ReparationService, private dataManagementService: DataManagementService, public toastr: ToastrService, public exportImport: ImportExportService) { }

  ngOnInit() {

    if (this.dataManagementService.selectedVehicule && this.dataManagementService.selectedGroup) {
      this.vehicule = this.dataManagementService.selectedVehicule;
      this.group = this.dataManagementService.selectedGroup;
      this.loadReparations();
    }

    this.getVehiculeWasSelectedSubscription = this.dataManagementService.getVehiculeWasSelected().subscribe(vehicule => {
      this.vehicule = vehicule;
      this.loadReparations();
    });

    this.getGroupWasSelectedSubscription = this.dataManagementService.getGroupWasSelected().subscribe(group => {
      this.group = group;
    });
  }

  ngOnDestroy() {
    if (this.getGroupWasSelectedSubscription) this.getGroupWasSelectedSubscription.unsubscribe();
    if (this.getVehiculeWasSelectedSubscription) this.getVehiculeWasSelectedSubscription.unsubscribe();
  }

  /**
   * Charger la liste des réparations
   */
  loadReparations() {
    if(!this.vehicule.idVehicule)  return;
    this.loading = true;
    this.reparationService.getListReparations(this.vehicule.idVehicule, this.group.idGroupe).subscribe(reparations => {
      this.reparations = reparations;
      this.loading = false;
    }, error => {
      this.loading = false;
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
            if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
            this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
    });
  }

  /**
   * Afficher modal de modification du réparation
   */
  onUpdate(reparation: Reparation) {
    if (!reparation.provider) {
      reparation.provider = new Provider();
    }
    if (!reparation.reparationType) {
      reparation.reparationType = new MultiType();
    }
    this.reparation = Object.assign({}, reparation);
    if (this.reparation.operationTime) this.reparation.operationTime = new Date(this.reparation.operationTime);
    if (this.reparation.actualRepairTime) this.reparation.actualRepairTime = new Date(this.reparation.actualRepairTime);
    this.mode = 'UPDATE';
    this.reparationModal.show();
  }

  /**
   * Afficher modal d'ajout du réparation
   */
  onAdd() {
    this.reparation = new Reparation();
    this.mode = 'ADD';
    this.reparationModal.show();
  }

  /**
   * Afficher modal de confirmation du suppression
   */
  onConfirmDelete(reparation: Reparation) {
    this.reparation = reparation;
    if (!this.reparation.provider) {
      this.reparation.provider = new Provider();
    }
    if (!this.reparation.reparationType) {
      this.reparation.reparationType = new MultiType();
    }
    this.isDeleteMultiple = false;
    this.reparationDeleteModal.show();
  }

  /**
   * Suppression du réparation
   */
  delete() {
    this.loading = true;
    this.reparationService.delete(this.reparation.id).subscribe(response => {

      if (response) {
        this.reparations = this.reparations.filter(reparation => reparation.id != this.reparation.id);
        this.reparationsId = this.reparationsId.filter(reparationId => reparationId != this.reparation.id);
        this.toastr.success('Réparation informations bien supprimer !', 'info');
      } else {
        this.toastr.error('La Réparation n\'a pas supprimé correctement !', 'Erreur');
      }

      this.reparationDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }


  /**
   * Suppression multiple du pneu
   */
  deleteMultiple() {
    this.loading = true;
    this.reparationService.deleteMultiple(this.reparationsId).subscribe(response => {

      if (response) {
        for (let reparationId of this.reparationsId) {
          this.reparations = this.reparations.filter(reparation => reparation.id != reparationId);
        }

        this.reparationsId = [];
        this.toastr.success('Suppression effectuée avec succès.', 'info');
      } else {
        this.toastr.error('Le réparation n\'a pas supprimé correctement !', 'Erreur');
      }

      this.reparationDeleteModal.hide();
      this.loading = false;
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null)).errorMessage;
      if (JSON.parse(JSON.stringify(error.error || null)).errors) textError += ' ' + JSON.parse(JSON.stringify(error.error || null)).errors;
      this.toastr.error(textError, JSON.parse(JSON.stringify(error.error || null)).errorCode);
      this.loading = false;
    });
  }

  /**
   * Actualiser la liste des réparations apés l'événement d'ajout
   */
  reparationWasAdded(reparation: Reparation) {
    this.reparationModal.hide();
    this.reparations.unshift(reparation);
  }

  /**
   * Actualiser la liste des réparations apés l'événement de modification
   */
  reparationWasUpdated(reparation: Reparation) {
    this.reparationModal.hide();

    for (let i = 0; i < this.reparations.length; i++) {
      if (this.reparations[i].id == reparation.id) {
        this.reparations[i] = reparation;
        break;
      }
    }

  }

  export() {
    this.repport.type = 'REPARATION';
    this.exportImport.export(this.reparations, this.repport, this.dataManagementService.selectedVehicule.idVehicule);
  }

  itemWasImported(res: boolean) {
    if (res) {
      this.importModal.hide();
      this.loadReparations();
    }
  }

  /**
 * Event confirm delete multiple
 */
  onConfirmDeleteMultiple() {
    if (this.reparationsId.length == 0) {
      this.toastr.warning("Veuillez choisir des pneus à supprimer !");
      return;
    }
    this.isDeleteMultiple = true;
    this.reparationDeleteModal.show();
  }

  /**
   * Event delete
   */
  onDelete() {
    if (this.isDeleteMultiple) {
      this.deleteMultiple();
    } else {
      this.delete();
    }
  }


  /**
   * get list search
   */
  getReparations() {
    if ((this.searchTerm) && (this.searchTerm.length > 0)) {
      return this.reparations.filter(reparation => (reparation.provider) && (reparation.provider.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1));
    } else {
      return this.reparations;
    }
  }


  /**
   * Event check row for delete multiple
   */
  onChecked(id: number) {
    if (this.reparationsId.find(reparationId => reparationId == id)) {
      this.reparationsId = this.reparationsId.filter(reparationId => reparationId != id);
    } else {
      this.reparationsId.push(id);
    }
  }

  /**
   * Event check all row for delete multiple
   */
  onCheckedAll() {
    if (this.reparationsId.length == this.reparations.length) {
      this.reparationsId = [];
    } else {
      this.reparationsId = [];
      for (let reparation of this.reparations) {
        this.reparationsId.push(reparation.id);
      }
    }
  }

  /**
   * check if id exists in array,
   */
  isCheckedInList(id: number): boolean {
    return this.reparationsId.find(reparationId => reparationId == id) ? true : false;
  }
}
