<section class="groups-container" id="groups-container">
  <div
    class="box"
    style="
      font-family: Inconsolata, monospace;
      font-size: 14px;
      border-radius: 0px;
    "
  >
    <search-bar [groups]="groups" (filterGroups)="filterGroups($event)" (groupsToggle)="realTime.groupsToggle()"></search-bar>
    <!-- toggle groups -->
    <div
      style="text-align: center"
      (click)="realTime.groupsToggle()"
      *ngIf="!realTime.isGroupsExpanded"
    >
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </div>

    <div
      class="box-body no-padding container-groups"
      [hidden]="!realTime.isGroupsExpanded"
          >
      <div
        *ngIf="
          !realTimeService.isGroupLoaded || !realTimeService.realTimeLoaded
        "
        style="text-align: center"
      >
        <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
      </div>
      <table
        *ngIf="realTimeService.isGroupLoaded && realTimeService.realTimeLoaded"
        class="table table-condensed"
        style="border-collapse: collapse"
      >
        <tbody class="style-2">
          <ng-container
            *ngFor="let item of groups | groupfilter : { search: realTime.searchWord, status: searchedOption, filtred: records }"
          >
            <group-element-component
              [item]="item"
            ></group-element-component>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</section>



<div bsModal #childModal="bs-modal"  class="modal fade"  tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header black-bg">
        <h4
          class="modal-title pull-left"
          style="font-family: Inconsolata, monospace; font-size: 17px"
        >
          <i class="fa fa-plus-circle"></i> Nouveau point d'intérêt
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="childModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-poi-form
          [pointInterest]="realTimeService.pointInterest"
          [mode]="'ADD'"
          (pointInterestWasAdded)="childModal.hide()"
        ></app-poi-form>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #childModal2="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header black-bg">
        <h4
          class="modal-title pull-left"
          style="font-family: Inconsolata, monospace; font-size: 17px"
        >
          <i class="fa fa-plus-circle"></i> Nouveau client
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="childModal2.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-poi-client-form
          [poiClient]="realTimeService.poiClient"
          [mode]="'ADD'"
          (poiClientWasAdded)="childModal2.hide()"
        >
        </app-poi-client-form>
      </div>
    </div>
  </div>
</div>
