<ngx-loading [show]="globalLoading" ></ngx-loading>
<div class="mainSection">
  <div class="card">
    <div class="block">
      <div class="blockImg">
        <div class="img" (click)="showDocument('Car Image')">
          <img src="assets/icons/carImg2.png" alt="" class="car-image"
            style="height: 165px; width: 290px;border-top-left-radius: 10px 10px;border-bottom-left-radius: 10px 10px;">
        </div>
      </div>
      <div class="blockData">
        <div class="vehicule-user">
          <div class="vehicule">
            <div class="group-info">
              <span style="font-size: 19px;" title="Matricule">
                <b style="font-weight: bold;">
                  {{selectedVehicule.matricule | noValue}}
                </b>
                /
                <b title="Groupe" style="font-weight: 100;font-size: 15px;">
                  <span class="span-separator" *ngFor="let group of selectedVehicule?.groups | slice:0:1">
                    {{group.nom}}
                    <span *ngIf="!selectedVehicule?.groups || selectedVehicule?.groups?.length > 1"
                      title="{{groupsNames}}">, ...</span>
                  </span>
                  <ng-container *ngIf="!selectedVehicule?.groups || selectedVehicule?.groups?.length < 1">...
                  </ng-container>
                </b>
              </span>
            </div>
            <div class="vehicule-info">
              <span title="marque">{{selectedVehicule.mark | noValue}} </span> ,
              <span title="Sous Marque">{{selectedVehicule.subMark | noValue}} </span> ,
              <span title="Surnom">{{selectedVehicule.alias | noValue}} </span>
            </div>
          </div>
          <div class="user">
            <div class="user-img">
              <img *ngIf="selectedVehicule?.driver?.avatarUri" class="img-circle"
                src="https://rimtrack.com/{{selectedVehicule?.driver?.avatarUri}}" alt="User Image">
            </div>
            <div class="user-info">
              <div class="user-name">
                <span class="username">

                  <a *ngIf="selectedVehicule.driver"
                    (click)="goToDriverDetailPage()">{{dataManagementService.getDriverName(selectedVehicule?.driver)}}</a>
                  <span
                    *ngIf="!selectedVehicule.driver">{{dataManagementService.getDriverName(selectedVehicule?.driver)}}</span>

                </span>

              </div>
              <div class="user-telephone">
                <span class="description">tel : {{selectedVehicule?.driver?.telephone | noValue}} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="info">
          <p>
            <span *ngIf="selectedVehicule.icon">
              <img src="{{url+selectedVehicule.icon}}" width="30px" />
            </span>
            <span style="font-weight: bold">Mise en circulation :</span> {{selectedVehicule?.circulationAt | date:
            'yyyy-MM-dd HH:mm:ss' | noValue}} /
            <span style="font-weight: bold">Capacité :</span> {{selectedVehicule?.capacity | noValue}} -
            <span style="font-weight: bold">V MAX :</span> {{selectedVehicule?.maxSpeed | noValue}} -
            <span style="font-weight: bold">Secteur :</span> {{selectedVehicule?.secteur | noValue}} <br>
            <span style="display: inline-block; width: 30px;">&nbsp;&nbsp;&nbsp; </span>
            <span style="font-weight: bold">N° Jawaz :</span> {{selectedVehicule?.jawazCardNumber | noValue}} -
            <span style="font-weight: bold">N° gasoil :</span> {{selectedVehicule?.fuelCardNumber | noValue}} -
            <span style="font-weight: bold">S.gasoil :</span> {{selectedVehicule?.cardFuelSolde | noValue}} -
          </p>
        </div>
        <div class="doc">
          <div *ngFor="let docEntry of documentEntries" (click)="showDoc(docEntry[0])" class="doc-card">
            {{docEntry[0]}}
          </div>
        </div>
        <div class="doc-card" style="width: 100%; background-color: white;">
          <div *ngFor="let passation of listPassation; let i = index">
              <div class="doc-cardII" >
                <span _ngcontent-shw-c2="" class="label label-success badgecss" style="z-index: 999;" (click)="confirmDeleteVehicule(passation)">X</span>
                <div  class="devPassation"(click)="exportPassationPdf(passation)">
                  <span *ngIf="!passation.loader" >P{{i+1}} - {{passation.datePassation | date: 'dd/MM/yyyy'}}</span>
                  <i *ngIf="passation.loader" class="fa fa-refresh fa-spin" style="width: 130px;"></i>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- =================================== CONFIRM MODAL (START)====================================-->
<div class="modal fade" role="dialog" bsModal #confirmDeleteModal="bs-modal">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" (click)="confirmDeleteModal.hide()">&times;
              </button>

              <h4 class="modal-title">
                  <i class="fa fa-cogs" aria-hidden="true"></i> Es vous sur ?
              </h4>

          </div>
          <div class="modal-body">
              <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                  <h4 style="color: white"><i class="fa fa-exclamation-triangle"></i> Attention !
                  </h4>
                  Êtes-vous sûr de vouloir supprimer cette passation ?
                  Cette action entraînera la perte définitive de toutes les passations et réparations associées. Attention, cette suppression est irréversible !
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-default" (click)="confirmDeleteModal.hide()">
                  Annuler
              </button>
              <button type="button" class="btn btn-success" (click)="supristionPassationPdf()">
                  <i class="fa fa-check" aria-hidden="true"></i> Oui, Je confirme
              </button>
          </div>
      </div>
  </div>
</div>
<!-- =================================== CONFIRM MODAL (START)====================================-->

