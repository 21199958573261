import {VehiculesDto, VehiculeTravelReport} from "../../../data-management";

export class TransportTypeDto{

    idTransportType: number;
    name: string;
    averageRotation: number;
    vehicules: number[];
    constructor() {
    }
}


export class TransportTypeInfoDto{

    idTransportType: number;
    name: string;
    averageRotation: number;
    vehicules: VehiculesDto[];
    constructor() {
        this.name = "PF";
        this.vehicules = [];
    }
}

export class SubMarkDto{

    idTransportType: number;
    mark: string;
    constructor() {
    }
}

export class TransportTypeTravelReport{
    idTransportType: number;
    name: string;
    averageRotation: number;
    vehicules: VehiculeTravelReport[];
    constructor() {
        this.name = "PF";
        this.vehicules = [];
    }

}
