export class BreakDown {
    deviceId: number;
    matricule: string;
    beginStopTime : Date;
    endStopTime : Date;
    name: string;
    active: boolean;
    constructor() {
        this.active = false;
        this.beginStopTime = new Date();
    }
}