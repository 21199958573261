import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassageChartComponent } from './passage-chart.component';

// Import any necessary Angular modules here

@NgModule({
  declarations: [
    PassageChartComponent  // Declare the component here
  ],
  imports: [
    CommonModule  // Import necessary Angular modules here (e.g., FormsModule, ReactiveFormsModule, etc.)
  ],
  exports: [
    PassageChartComponent  // Export the component if you want it to be used in other modules
  ]
})
export class PassageChartModule { }
