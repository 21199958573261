<ngx-loading *ngIf="loader" [show]="loader"></ngx-loading>


<div class="container" style="font-family: 'Inconsolata', monospace;font-size: 14px;">

    <div class=" mt-2 mb-2 text-center   well bgg">
        <h3 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
             Suivi Secteur</h3>
    </div>


    <div class="col-lg-12 well formBC">
        <div class="row">
            <form class="form-horizontal">

                <div class="col-sm-12">

                    <div class="form-group mrg">
                        <label>Secteur</label>
                        <select class="form-control" name="geoMarketing" [(ngModel)]="selectedGeoMarketing">
                            <option *ngFor="let geo of geoMarketing" [ngValue]="geo">
                                {{geo?.label | capitalizefirst}}
                            </option>
                        </select>
                    </div>



                    <!--if report neads to choose start and end date-->
                    <div class="row">
                        <div class="col-sm-6 form-group mrg">
                            <label>Du</label>
                            <input type="text" class="form-control  " bsDatepicker name="startDate"
                                [(ngModel)]="repport.dateInterval.startDate"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                                [maxDate]="maxDate" placement="bottom" autocomplete="off">

                        </div>
                        <div class="col-sm-6 form-group mrg">
                            <label>Au</label>
                            <input type="text" class="form-control " bsDatepicker name="endDate"
                                [(ngModel)]="repport.dateInterval.endDate"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                                [minDate]="repport.dateInterval.startDate" placement="bottom" autocomplete="off">
                        </div>
                    </div>

                        <!-- choose report option (all vehicule ,by group or by vehicule)-->

                        <div class="form-group mrg">
                            <label>Rapport de </label>
                            <select class="form-control" [(ngModel)]='Selectedoption' (change)="onSelectingReportOf()"
                                name='selectedoption'>
                                <option style="font-size: 14px;" *ngFor="let opt of selectReportOf" [value]="opt.name">
                                    {{opt.label}}</option>
                            </select>
                        </div>

                        <!--display al groups if user has choose it-->
                        <div class="form-group mrg" *ngIf="reportOf && this.repport.rapportPayloadDto.byGroups">
                            <label>Groupes</label>


                            <ng-select
                            *ngIf="groupsItems.length != 0"
                            [items]="groupsItems"
                            bindLabel="text"
                            bindValue="id"
                            [addTag]="addCustomUser"
                            [multiple]="true"
                            placeholder="aucun groupe selectionné"
                            [(ngModel)]="selectedGroupsItems">
                          </ng-select>
                        </div>


                        <!-- display all vehicules of user choose this option-->
                        <div class="form-group mrg" *ngIf="reportOf && this.repport.rapportPayloadDto.byVehicules">
                            <label>Véhicules</label>
                            <ng-select
                            *ngIf="vehiculesItems.length != 0"
                            [multiple]="true"
                            [items]="vehiculesItems"
                            bindLabel="text"
                            bindValue="id"
                            placeholder="aucun vehicule selectionné"
                            [(ngModel)]="vehiculesSelected"
                            [clearable]="true" (data)="selectedVehicules($event)">
                            </ng-select>
                        </div>

                        <div class="form-group mrg">
                            <label >Durée(min) :</label>
                                <input class="form-control" [(ngModel)]="repport.duration" name="duration" />
                        </div>


                        <!-- export button-->
                        <div class="box-footer">
                            <button class="btn btn-success pull-right shadow" (click)='export()'>
                                Exporter
                            </button>
                        </div>

                </div>
            </form>

        </div>
    </div>
</div>
