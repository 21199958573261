import { Component, Input, OnDestroy, OnInit } from '@angular/core';



@Component({
  selector: 'progress-custom-bar',
  templateUrl: './progress-custom-bar.component.html',
  styleUrls: ['./progress-custom-bar.component.css'],
})
export class ProgressCustomBarComponent implements OnInit, OnDestroy {
  @Input() steps?: any[];
  constructor() {}

  ngOnInit() {
  }

  calculateProgressBarHeight(): number {
    console.log(this.steps)
    if(this.steps){
      const totalSteps = this.steps.length;
      const stepHeight = 55; // Adjust as needed
      return totalSteps * stepHeight;
    }
    return 0;
  }

  calculateStepPosition(index: number): number {
    const stepHeight = 55; // Adjust as needed
    return stepHeight * index;
  }

  ngOnDestroy(){

  }



}
