<ngx-loading *ngIf="loader" [show]="loader"></ngx-loading>

<div class="container" style="font-family: 'Inconsolata', monospace;font-size: 14px;">

  <div class=" mt-2 mb-2 text-center   well bgg">
    <h3 style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
      Dépenses par Canal</h3>
  </div>


  <div class="box box-info">
    <form class="form-horizontal">
      <div class="box-body">

        <!--if report neads to choose start and end date-->
        <div class="row">
          <div class="form-group" class="col-md-6">
            <label class="col-sm-2 control-label">Du</label>
            <div class="col-sm-10">
              <input type="text" class="form-control input-sm" bsDatepicker name="startDate"
                [(ngModel)]="repport.dateInterval.startDate"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}" placement="bottom"
                autocomplete="off">

            </div>
          </div>
          <div class="form-group" class="col-md-6">
            <label class="col-sm-2 control-label">Au</label>
            <div class="col-sm-10">

              <input type="text" class="form-control input-sm" bsDatepicker name="endDate"
                [(ngModel)]="repport.dateInterval.endDate"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}"
                [minDate]="repport.dateInterval.startDate" placement="bottom" autocomplete="off">

            </div>
          </div>
        </div>

        <!-- choose report option (all vehicule ,by transport type)-->
        <div class=" form-group">
          <label class="col-sm-2 control-label">Rapport de </label>
          <div class="col-sm-10">
            <select class="form-control" [(ngModel)]='Selectedoption' (change)="onSelectingReportOf()"
              name='selectedoption'>
              <option style="font-size: 14px;" *ngFor="let opt of selectReportOf" [value]="opt.name">
                {{opt.label}}</option>
            </select>
          </div>
        </div>

        <!-- display all Canal types of user choose this option-->
        <div class="form-group mrg" *ngIf="repport.rapportPayloadDto.byGroups">
          <label class="col-sm-2 control-label">types de canal * </label>
          <div class="col-sm-10">
            <!-- <ng-multiselect-dropdown-angular7
            name="canalsSelected"
            [data]="canalService.canals"
            placeholder="aucun type de canal selectionné"
            [(ngModel)]="selectedCanals"
            [settings]="canalDownSettings"
            required>
            </ng-multiselect-dropdown-angular7> -->

            <ng-multiselect-dropdown
            [placeholder]="'personnalisé'"
            [settings]="groupDropdownSettings"
            [data]="canalService.canals"
            [(ngModel)]="selectedCanals"
            (onSelect)="onItemSelect($event)"
          >
          </ng-multiselect-dropdown>

          </div>
        </div>

        <!-- export button-->
        <div class="box-footer">
          <button class="btn btn-success pull-right" (click)='export()'>
            Exporter
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
