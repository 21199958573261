import {Component, Host, Inject, Input, OnInit} from '@angular/core';
import {Vehicule} from "../../../data-management.model";
import {DataManagementService} from "../../../data-management.service";
import {filesDir, imagesDir} from "../../../../../global.config";
import {Router} from "@angular/router";
import {DataManagementComponent} from "../../../data-management.component";

@Component({
    selector: 'app-vehicule-details',
    templateUrl: './vehicule-details.component.html',
    styleUrls: ['./vehicule-details.component.css']
})
export class VehiculeDetailsComponent implements OnInit {

    imagesDir = imagesDir;

    @Input()
    currentVehicule: Vehicule;

    filesDir = filesDir;

    refreshFiles : Date = new Date();

    constructor(public dataManagementService: DataManagementService, 
        private router: Router,
            @Inject(DataManagementComponent) 
            private parent: DataManagementComponent) {
    }

    ngOnInit() {
    }

    // refreshFiles() {
    //     return new Date();
    // }

    goToUpdatePage() {
        this.router.navigate(['/client/dataManagement/vehicule/form/' + this.currentVehicule.idVehicule + '/UPDATE']);
    }

    goToAssignPage() {
        this.router.navigate(['/client/dataManagement/vehicule/form/' + this.currentVehicule.idVehicule + '/ASSIGN']);
    }

    goToDriverDetailPage(){
        this.parent.displayDriverIcon();
        this.router.navigate(['/client/dataManagement/driver/form/' + this.currentVehicule.driver.idDriver + '/SHOW']);
    }

    setDefaultPic(){
        this.currentVehicule.contractingUri = 'images/sign-add-icon.png';
    }

}
