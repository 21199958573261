import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AverageCostOfFuelConsumptionChartComponent } from './average-cost-of-fuel-consumption-chart.component';
import { AverageCostOfFuelConsumptionChartService } from './average-cost-of-fuel-consumption-chart.service';
import { HighchartsChartModule } from 'highcharts-angular';  // Highcharts wrapper for Angular
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    AverageCostOfFuelConsumptionChartComponent  // Declare the chart component
  ],
  imports: [
    CommonModule,       // Import CommonModule for Angular directives
    HighchartsChartModule,  // Import Highcharts module for chart rendering
    FormsModule,
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),

  ],
  providers: [
    AverageCostOfFuelConsumptionChartService  // Provide the service for the chart component
  ],
  exports: [
    AverageCostOfFuelConsumptionChartComponent  // Export the chart component for use in other modules
  ]
})
export class AverageCostOfFuelConsumptionChartModule { }
