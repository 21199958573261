import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SigninService } from '../authentification/signin/signin.service';
import {
  Notification,
  FuelingNotificationDto,
} from '../client-management/alert-management/alert';
import { AlertManagementService } from '../client-management/alert-management/alert-management.service';
import { imagesDir, mini_owner, owner } from '../global.config';
import { DateInterval } from '../shared/model';
import { EmailsService } from '../utils/emails/emails.service';
import Timer = NodeJS.Timer;
import { User, Action } from '../authentification/signin/credentials';
import Speech from 'speak-tts';
import { HeaderService } from './header.service';
import { RealtimeService } from '../client-management/realtime/services/realtime.service';
import { DataManagementService } from '../client-management/data-management';
import { MapService } from '../utils/leaflet/service/map.service';
import { CanalService } from '../client-management/canal-performance';
interface SpeechData {
  // Définissez les propriétés attendues de l'objet data ici
  voices: any[];
  // Ajoutez d'autres propriétés selon la documentation de Speech
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})




export class HeaderComponent implements OnInit {
  public currentUser: User = new User();

  owner: string;
  avatar: string;
  mini_owner: string;

  email: any = {};
  @ViewChild('childModal', { static: false }) public childModal!: ModalDirective;
  dateInterval: DateInterval = new DateInterval();
  fuelNotificationStartDate: Date | number | any;
  notifications: Notification[] = [];
  fuelingNotificationDto: FuelingNotificationDto[] = [];

  notificationTimer: Timer | null = null;
  speech: any;
  speechData: any;

  constructor(
    public toastr: ToastrService,
    private router: Router,
    public signinService: SigninService,
    private emailsService: EmailsService,
    public alertManagementService: AlertManagementService,
    public headerService: HeaderService,
    private realTimeService: RealtimeService,
    private dataManagementService: DataManagementService,
    private canalService: CanalService,
    private mapService: MapService
  ) {
    this.owner = owner;
    this.mini_owner = mini_owner;

    // Assurez-vous que currentUser n'est pas null avant de l'utiliser
    const storedUser = localStorage.getItem('currentUser');
    this.currentUser = storedUser ? JSON.parse(storedUser) : {}; // Fournir un objet vide comme valeur par défaut

    if (this.currentUser.avatartUri) {
      this.avatar = 'files/' + this.currentUser.avatartUri;
    } else {
      this.avatar = imagesDir + 'profil.png';
    }

    this.textToSpeech();
  }


  ngOnInit() {
    this.loadScript();
    this.init();
    this.notificationTimer = setInterval(() => {
      this.init();
    }, 1 * 60 * 1000);
  }

  init() {
    this.initDateInterval();
    window.addEventListener('realtimeMuteClicked', (event: Event) =>
      this.onRealtimeMuteClicked(event)
    );
    window.addEventListener('alertSoundClicked', (event: Event) =>
      this.onAlertSoundClicked(event)
    );
    window.addEventListener('notificationsClicked', (event: Event) =>
      this.onNotificationsClicked(event)
    );
    this.loadNotification();
  }

  onRealtimeMuteClicked(event: Event) {
    this.headerService.realTimeNotificationSound =
      !this.headerService.realTimeNotificationSound;
  }

  onAlertSoundClicked(event: Event) {
    this.headerService.sound = !this.headerService.sound;
  }

  onNotificationsClicked(event: Event) {
    this.headerService.realTimeNotification =
      !this.headerService.realTimeNotification;
  }

  ngOnDestroy() {
    if (this.notificationTimer) clearTimeout(this.notificationTimer);
  }

  logout() {
    let action = new Action();
    action.type = 'DISCONNECT';
    if (!this.currentUser.isRoot)
      this.signinService
        .addAction(this.currentUser.id, action)
        .subscribe((action) => {});
    localStorage.removeItem('id_token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('notifications');
    this.router.navigate(['/']);

    this.realTimeService.resetService();
    this.dataManagementService.resetService();
    this.canalService.resetService();
    this.mapService.resetAllMap();
  }

  sendEmail() {
    this.emailsService.sendEmail(this.email).subscribe((mailSended) => {});

    this.email = {};
    this.childModal.hide();
    this.toastr.success('Réclamation envoyée !', 'info');
  }

  enableOrdisableNotification() {
    if (this.headerService.realTimeNotification) {
      this.headerService.realTimeNotification = false;
      localStorage.setItem('realTimeNotification', 'false');
    } else {
      this.headerService.realTimeNotification = true;
      localStorage.setItem('realTimeNotification', 'true');
      this.enableOrdisableSound(false);
      this.toggleRealTimeSound(false);
    }
  }

  enableOrdisableSound(active: boolean) {
    let value = active ? 'true' : 'false';
    localStorage.setItem('notification_sound', value);
  }

  toggleRealTimeSound(active: boolean) {
    this.headerService.realTimeNotificationSound = active;
    let value = active ? 'true' : 'false';
    localStorage.setItem('realTimeNotificationSound', value);
  }

  openSupportModal() {
    this.childModal.show();
  }

  initDateInterval() {
    let now = new Date();
    now.setDate(new Date().getDate() - 1);
    now.setHours(23, 59, 59, 999);
    this.dateInterval.endDate = new Date().getTime();
    //this.dateInterval.startDate = now.setDate(new Date().getDate() - 1);
    this.dateInterval.startDate = now.getTime();
    this.fuelNotificationStartDate = new Date().getTime() - 3 * 86400000;
  }

  startSpeaking(text: string) {
    if (!this.headerService.sound) return;

    this.speech
      .speak({
        text: text,
      })
      .then(() => {})
      .catch((e: Error) => {
        console.error('An error occurred :', e);
      });
  }


  textToSpeech() {
    this.speech = new Speech();
    // will throw an exception if not browser supported
    if (this.speech.hasBrowserSupport()) {
      // returns a boolean
      this.speech
        .init({
          volume: 1,
          lang: 'fr-FR',
        })
        .then((data: SpeechData) => {
          // The "data" object contains the list of available voices and the voice synthesis params
          this.speechData = data;
        })
        .catch((e: Error) => {
          console.error('An error occurred while initializing : ', e);
        });
    }
  }


  loadNotification() {
    this.alertManagementService
      .getNotificationsByUserId(this.dateInterval)
      .subscribe((notifications) => {
        if (this.headerService.realTimeNotification) {
          if (this.notifications.length == 0 && notifications.length > 0) {
            this.alertManagementService.toastrMessages.push({
              message:
                'Vous avez plus de ' +
                notifications.length +
                ' notifications !',
              title: 'Info !',
              type: 'info',
            });
          } else {
            for (let notification of notifications) {
              if (!this.notifications.find((n) => n.id == notification.id)) {
                this.startSpeaking(
                  'Notification ' +
                    this.alertManagementService.getTitleFromNotification(
                      notification
                    ) +
                    ' ' +
                    notification.matricule
                );
                this.alertManagementService.toastrMessages.push({
                  message:
                    'matricule : ' +
                    notification.matricule +
                    ', Marque : ' +
                    notification.mark,
                  title:
                    this.alertManagementService.getTitleFromNotification(
                      notification
                    ),
                  type: 'info',
                });
              }
            }
          }
        }

        this.notifications = notifications;
        // Save data to local storage
        localStorage.setItem(
          'notifications',
          JSON.stringify(this.notifications)
        );
      });

    /** Fueling Notification **/
    if (
      this.currentUser &&
      ((this.currentUser.options &&
        this.currentUser.options.find((op) => op.idOption == 6)) ||
        (this.currentUser.authorities &&
          this.currentUser.authorities.find(
            (a) => a.name == 'ROLE_NOTIFICATION_CARBURANT'
          )))
    ) {
      this.alertManagementService
        .getFuelingNotification({
          startDate: this.fuelNotificationStartDate,
          endDate: this.dateInterval.endDate,
        })
        .subscribe((fuelingNotifications) => {
          if (
            this.fuelingNotificationDto.length == 0 &&
            fuelingNotifications.length > 0
          ) {
            this.alertManagementService.toastrMessages.push({
              message:
                'Vous avez plus de ' +
                fuelingNotifications.length +
                ' notifications Carburant !',
              title: 'Info !',
              type: 'info',
            });
          } else {
            for (let fuelingNotification of fuelingNotifications) {
              if (
                !this.fuelingNotificationDto.find(
                  (n) =>
                    n.idVehicule == fuelingNotification.idVehicule &&
                    n.operationTime == fuelingNotification.operationTime
                )
              ) {
                this.startSpeaking(
                  'Notification Consommation Carburant ' +
                    fuelingNotification.matricule
                );
                this.alertManagementService.toastrMessages.push({
                  message:
                    'matricule : ' +
                    fuelingNotification.matricule +
                    ', Marque : ' +
                    fuelingNotification.mark,
                  title: 'Consommation Carburant',
                  type: 'info',
                });
              }
            }
          }
          this.fuelingNotificationDto = fuelingNotifications;
        });
    }
  }

  goToLink(notification: Notification) {
    if (notification.name == 'SPEED' || notification.name == 'SPEED_CARTO') {
      window.open(
        'http://maps.google.com/?q=' +
          notification.lat +
          ',' +
          notification.lng +
          '&z=10',
        '_blank'
      );
    } else {
      this.router.navigate(['/client/alertManagement/alertNotifications']);
    }
  }

  /** SCRIPTS FOR CUSTOMER STYLES TEMPLATE **/
  loadScript() {
    let urlScript1 = '../../assets/dist/js/adminlte.min.js';
    let urlScript2 = '../../assets/dist/js/demo.js';

    let body = document.body;

    // Création et ajout du premier script
    let script1 = document.createElement('script');
    script1.src = urlScript1;
    script1.async = true;
    script1.defer = true;

    // Ajout du script1 si ce n'est pas déjà dans le DOM
    if (document.querySelector('script[src="' + urlScript1 + '"]') === null) {
      body.appendChild(script1);
    }

    // Création du deuxième script
    let script2 = document.createElement('script');
    script2.src = urlScript2;
    script2.async = true;
    script2.defer = true;

    // Suppression de l'ancien script2 s'il existe
    let elem = document.querySelector('script[src="' + urlScript2 + '"]');
    if (elem !== null && elem.parentNode !== null) {
      elem.parentNode.removeChild(elem);
    }

    // Ajout du script2 au DOM
    body.appendChild(script2);
  }

}
