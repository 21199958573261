import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Required for ngModel
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

// Import your component
import { CanalTypeFormComponent } from './canal-type-form.component';

// Import any services used by the component
import { DataManagementService } from 'src/app/client-management/data-management';
import { CanalService } from '../../../canal.service';
import { NgxLoadingModule } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    CanalTypeFormComponent, // Declare the CanalTypeFormComponent
  ],
  imports: [
    CommonModule,          // Required for common Angular directives
    FormsModule,           // Required for ngModel
    RouterModule,          // Required for routing
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),

  ],
  providers: [
    CanalService,           // Provide CanalService for the component
    DataManagementService   // Provide DataManagementService for the component
  ],
  exports: [
    CanalTypeFormComponent  // Export the component if needed elsewhere
  ]
})
export class CanalTypeFormModule { }
