import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { GeocodingService } from '../../../../utils/leaflet/service/geocoding.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { imagesCarsDir } from "../../../../global.config";
import { Group, Mission, RouteLegs, TrackingMission, Vehicule } from "../../../data-management/data-management.model";
import { DataManagementComponent } from '../../data-management.component';
import { DataManagementService } from '../../data-management.service';
import { MissionService } from '../../providers';
declare var $: any;


@Component({
    selector: 'app-mission-tracking',
    templateUrl: './mission-tracking.component.html',
    styleUrls: ['./mission-tracking.component.css']
})
export class MissionTrackingComponent implements OnInit, OnDestroy {

    /** IMAGE CAR */
    imagesCarsDir = imagesCarsDir;

    /** GROUP VAR */
    selectedGroup: Group = new Group();
    selectedGroup2: Group = new Group();
    selectedGroup3: Group = new Group();
    selectedGroup4: Group = new Group();
    selectedGroup5: Group = new Group();
    selectedGroup6: Group = new Group();

    groups: Group[] = [];

    /** VEHICULE VAR */
    selectedVehicule: Vehicule = new Vehicule();
    selectedVehicule2: Vehicule = new Vehicule();
    selectedVehicule3: Vehicule = new Vehicule();
    selectedVehicule4: Vehicule = new Vehicule();
    selectedVehicule5: Vehicule = new Vehicule();
    selectedVehicule6: Vehicule = new Vehicule();

    /** GROUP SUBSCRIPTION */
    groupsSubscription: Subscription = null;

    /** MISSION VAR */
    selectedMission: Mission = null;
    selectedMission2: Mission = null;
    selectedMission3: Mission = null;
    selectedMission4: Mission = null;
    selectedMission5: Mission = null;
    selectedMission6: Mission = null;

    md: any;

    missions: Mission[] = [];

    /** TRACKING VAR */
    trackingMission: TrackingMission = null;
    trackingMission2: TrackingMission = null;
    trackingMission3: TrackingMission = null;
    trackingMission4: TrackingMission = null;
    trackingMission5: TrackingMission = null;
    trackingMission6: TrackingMission = null;

    /** UPDATE TRACKING TIMER */
    updateTrackingTimer: number = null;
    updateTrackingTimerSubscription: Subscription = null;

    /** LOADING */
    loadingTracking: boolean = false;
    loadingTracking2: boolean = false;
    loadingTracking3: boolean = false;
    loadingTracking4: boolean = false;
    loadingTracking5: boolean = false;
    loadingTracking6: boolean = false;

    loading: boolean = false;

    trackingAccordingDistance: number;
    trackingAccordingDuration: number;

    constructor(private toastr: ToastrService,
        private parent: DataManagementComponent,
        private dataManagementService: DataManagementService,
        private missionService: MissionService,
        private geocodingService: GeocodingService) { }

    ngOnInit() {
        this.parent.initAllIcons();
        this.loadDataManagement();
        this.loadMissions();
    }

    ngOnDestroy() {
        if (this.groupsSubscription) this.groupsSubscription.unsubscribe();
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
    }

    /** START TIME OUT TRACKING FOR RELOAD */
    startUpdateTrackingTimer() {
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
        this.updateTrackingTimer = 120;
        this.updateTrackingTimerSubscription = interval(1000).subscribe(() => {
            this.updateTrackingTimer--;
            if (this.updateTrackingTimer == 0) {
                this.updateTrackingTimerSubscription.unsubscribe();
                if (this.trackingMission != null && this.trackingMission.progressPercent <= 100)
                    this.onTracking(event);
                if (this.trackingMission2 != null && this.trackingMission2.progressPercent <= 100)
                    this.onTracking2(event);
                if (this.trackingMission3 != null && this.trackingMission3.progressPercent <= 100)
                    this.onTracking3(event);
                if (this.trackingMission4 != null && this.trackingMission4.progressPercent <= 100)
                    this.onTracking4(event);
                if (this.trackingMission5 != null && this.trackingMission5.progressPercent <= 100)
                    this.onTracking5(event);
                if (this.trackingMission6 != null && this.trackingMission6.progressPercent <= 100)
                    this.onTracking6(event);

            }
        })
    }


    /** LOAD MISSIONS */
    loadMissions() {

        this.loading = true;
        this.missionService.getMissions()
            .subscribe(missions => {
                this.missions = missions;
                if (this.missionService.selectedMission) {
                    let mission = this.missions.filter(m => m.id == this.missionService.selectedMission.id);
                    if (mission.length > 0)
                        this.selectedMission = mission[0];
                }
                this.loading = false;
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de chargement", "Mission")
            })
    }

    /** CHOOSE GROUP */
    chooseGroup(group: Group, mode: string | number) {
        this.dataManagementService.selectedGroup = group;
        if (mode == "2") {
            this.selectedGroup2 = group;
            if (this.selectedGroup2.vehicules.length > 0 && this.selectedGroup2) {
                this.selectedVehicule2 = this.selectedGroup2.vehicules[0];
            }
        } else if (mode == "3") {
            this.selectedGroup3 = group;
            if (this.selectedGroup3.vehicules.length > 0 && this.selectedGroup3) {
                this.selectedVehicule3 = this.selectedGroup3.vehicules[0];
            }
        } else if (mode == "4") {
            this.selectedGroup4 = group;
            if (this.selectedGroup4.vehicules.length > 0 && this.selectedGroup4) {
                this.selectedVehicule4 = this.selectedGroup4.vehicules[0];
            }
        } else if (mode == "5") {
            this.selectedGroup5 = group;
            if (this.selectedGroup5.vehicules.length > 0 && this.selectedGroup5) {
                this.selectedVehicule5 = this.selectedGroup5.vehicules[0];
            }
        } else if (mode == "6") {
            this.selectedGroup6 = group;
            if (this.selectedGroup6.vehicules.length > 0 && this.selectedGroup6) {
                this.selectedVehicule6 = this.selectedGroup6.vehicules[0];
            }
        } else {
            this.selectedGroup = group;
            if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
                this.selectedVehicule = this.selectedGroup.vehicules[0];
                this.dataManagementService.selectedVehicule = this.selectedVehicule;
            }
        }

        this.stopTracking(mode);
    }

    /** COOSE VEHICULE */
    chooseVehicule(vehicule: Vehicule, mode: string | number) {
        if (mode == "2") {
            this.selectedVehicule2 = vehicule;
        } else if (mode == "3") {
            this.selectedVehicule3 = vehicule;
        } else if (mode == "4") {
            this.selectedVehicule4 = vehicule;
        } else if (mode == "5") {
            this.selectedVehicule5 = vehicule;
        } else if (mode == "6") {
            this.selectedVehicule6 = vehicule;
        } else {
            this.selectedVehicule = vehicule;
            this.dataManagementService.selectedVehicule = vehicule;

        }

        this.stopTracking(mode);
    }

    /** CHOOSE MISSION */
    chooseMission(event: number) {
        if (event == 1)
            this.missionService.selectedMission = this.selectedMission;
        this.stopTracking(event);
    }

    /** STOP TRACKING */
    stopTracking(num: any) {
        if (num == 2)
            this.trackingMission2 = null;
        else if (num == 3)
            this.trackingMission3 = null;
        else if (num == 4)
            this.trackingMission4 = null;
        else if (num == 5)
            this.trackingMission5 = null;
        else if (num == 6)
            this.trackingMission6 = null;
        else if (num == 1)
            this.trackingMission = null;

        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();
        this.updateTrackingTimer = null;
    }

    /** ON TRACKING */
    onTracking(event: number | Event) {

        this.md = event;

        this.loadingTracking = true;
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();

        this.missionService.tracking(this.selectedMission.id, this.selectedVehicule.idVehicule)
            .subscribe(response => {
                this.trackingMission = response;
                this.inverseGeocoding();
                this.loadingTracking = false;
                this.startUpdateTrackingTimer();
                this.countDistance(this.trackingMission);

            }, err => {
                this.loadingTracking = false;
                this.toastr.error("Erreur lors de chargement", "Mission")
            })
    }


    countDistance(tracking: TrackingMission) {
        this.trackingAccordingDistance = 0;
        this.trackingAccordingDuration = 0;
        for (var i = 0; i < tracking.mission.routeLegs.length; i++) {

            if (tracking.mission.routeLegs[i].selected === false) {
                this.trackingAccordingDistance += tracking.mission.routeLegs[i].travelDistance;
                this.trackingAccordingDuration += tracking.mission.routeLegs[i].travelDuration;
            } else {
                break;
            }
        }
    }

    /** ON TRACKING */
    onTracking2(event: number | Event) {

        this.md = event;

        this.loadingTracking2 = true;
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();

        this.missionService.tracking(this.selectedMission2.id, this.selectedVehicule2.idVehicule)
            .subscribe(response => {
                this.trackingMission2 = response;
                this.inverseGeocoding2();
                this.loadingTracking2 = false;
                this.startUpdateTrackingTimer();
                this.countDistance(this.trackingMission2);
            }, err => {
                this.loadingTracking2 = false;
                this.toastr.error("Erreur lors de chargement", "Mission")
            })


    }


    /** ON TRACKING */
    onTracking3(event: number | Event) {

        this.md = event;

        this.loadingTracking3 = true;
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();

        this.missionService.tracking(this.selectedMission3.id, this.selectedVehicule3.idVehicule)
            .subscribe(response => {
                this.trackingMission3 = response;
                this.inverseGeocoding3();
                this.loadingTracking3 = false;
                this.startUpdateTrackingTimer();
                this.countDistance(this.trackingMission3);
            }, err => {
                this.loadingTracking3 = false;
                this.toastr.error("Erreur lors de chargement", "Mission")
            })


    }

    /** ON TRACKING */
    onTracking4(event: Event | number) {

        this.md = event;

        this.loadingTracking4 = true;
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();

        this.missionService.tracking(this.selectedMission4.id, this.selectedVehicule4.idVehicule)
            .subscribe(response => {
                this.trackingMission4 = response;
                this.inverseGeocoding4();
                this.loadingTracking4 = false;
                this.startUpdateTrackingTimer();
                this.countDistance(this.trackingMission4);
            }, err => {
                this.loadingTracking4 = false;
                this.toastr.error("Erreur lors de chargement", "Mission")
            })


    }

    /** ON TRACKING */
    onTracking5(event: number | Event) {

        this.md = event;

        this.loadingTracking5 = true;
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();

        this.missionService.tracking(this.selectedMission5.id, this.selectedVehicule5.idVehicule)
            .subscribe(response => {
                this.trackingMission5 = response;
                this.inverseGeocoding5();
                this.loadingTracking5 = false;
                this.startUpdateTrackingTimer();
                this.countDistance(this.trackingMission5);
            }, err => {
                this.loadingTracking5 = false;
                this.toastr.error("Erreur lors de chargement", "Mission")
            })


    }

    /** ON TRACKING */
    onTracking6(event: Event | number) {

        this.md = event;

        this.loadingTracking6 = true;
        if (this.updateTrackingTimerSubscription) this.updateTrackingTimerSubscription.unsubscribe();

        this.missionService.tracking(this.selectedMission6.id, this.selectedVehicule6.idVehicule)
            .subscribe(response => {
                this.trackingMission6 = response;
                this.inverseGeocoding6();
                this.loadingTracking6 = false;
                this.startUpdateTrackingTimer();
                this.countDistance(this.trackingMission6);
            }, err => {
                this.loadingTracking6 = false;
                this.toastr.error("Erreur lors de chargement", "Mission")
            })


    }


    /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
    get selectedRouteLeg(): RouteLegs {


        if (this.trackingMission) {
            return this.trackingMission.mission.routeLegs.find(route => route.selected == true);
        } else {
            return null;
        }

    }

    /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
    get selectedRouteLeg2(): RouteLegs {
        if (this.trackingMission2) {
            return this.trackingMission2.mission.routeLegs.find(route => route.selected == true);
        } else {
            return null;
        }

    }


    /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
    get selectedRouteLeg3(): RouteLegs {


        if (this.trackingMission3) {
            return this.trackingMission3.mission.routeLegs.find(route => route.selected == true);
        } else {
            return null;
        }

    }


    /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
    get selectedRouteLeg4(): RouteLegs {


        if (this.trackingMission4) {
            return this.trackingMission4.mission.routeLegs.find(route => route.selected == true);
        } else {
            return null;
        }

    }

    /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
    get selectedRouteLeg5(): RouteLegs {


        if (this.trackingMission5) {
            return this.trackingMission5.mission.routeLegs.find(route => route.selected == true);
        } else {
            return null;
        }

    }

    /** GET SELECTED ROUTELEG FROM TRACKING MISSION */
    get selectedRouteLeg6(): RouteLegs {


        if (this.trackingMission6) {
            return this.trackingMission6.mission.routeLegs.find(route => route.selected == true);
        } else {
            return null;
        }

    }

    /** LOAD DATA MANAGEMENT */
    loadDataManagement() {
        if (this.dataManagementService.groups) {
            this.groups = this.dataManagementService.groups.filter(
                group => group.vehicules.length > 0
            );
            if (this.dataManagementService.selectedGroup) {
                this.selectedGroup = this.dataManagementService.selectedGroup;
                if (this.dataManagementService.selectedVehicule) {
                    this.selectedVehicule = this.dataManagementService.selectedVehicule;
                } else if (this.selectedGroup.vehicules.length > 0) {
                    this.selectedVehicule = this.selectedGroup.vehicules[0];
                }
            } else {
                if (this.groups.length > 0 && this.groups) {
                    this.selectedGroup = this.groups[0];
                    this.dataManagementService.selectedGroup = this.selectedGroup;
                }
            }
            this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else {
            this.groupsSubscription = this.dataManagementService
                .getAllGroupsDetails("")
                .subscribe(groups => {
                    this.groups = groups.filter(group => group.vehicules.length > 0);
                    this.dataManagementService.groups = groups;
                    this.dataManagementService.setGroups(groups);

                    if (this.groups.length > 0 && this.groups) {
                        this.selectedGroup = this.groups[0];
                        this.dataManagementService.selectedGroup = this.selectedGroup;
                        if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
                            this.selectedVehicule = this.selectedGroup.vehicules[0];
                            this.dataManagementService.selectedVehicule = this.selectedVehicule;
                        }
                    }
                });
        }
    }

    /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
    inverseGeocoding() {
        this.geocodingService.inverseGeoconding(
            this.trackingMission.realTimeRecord.coordinate.lat,
            this.trackingMission.realTimeRecord.coordinate.lng,
            18)
            .subscribe(response => {
                this.trackingMission.realTimeRecord.geocoding = response.display_name;
            }, err => {
                //  this.toastr.warning("Inverse geocoding");
            });

    }


    /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
    inverseGeocoding2() {
        this.geocodingService.inverseGeoconding(
            this.trackingMission2.realTimeRecord.coordinate.lat,
            this.trackingMission2.realTimeRecord.coordinate.lng,
            18)
            .subscribe(response => {
                this.trackingMission2.realTimeRecord.geocoding = response.display_name;
            }, err => {
                // this.toastr.warning("Inverse geocoding");
            });

    }


    /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
    inverseGeocoding3() {
        this.geocodingService.inverseGeoconding(
            this.trackingMission3.realTimeRecord.coordinate.lat,
            this.trackingMission3.realTimeRecord.coordinate.lng,
            18)
            .subscribe(response => {
                this.trackingMission3.realTimeRecord.geocoding = response.display_name;
            }, err => {
                //   this.toastr.warning("Inverse geocoding");
            });

    }

    /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
    inverseGeocoding4() {
        this.geocodingService.inverseGeoconding(
            this.trackingMission4.realTimeRecord.coordinate.lat,
            this.trackingMission4.realTimeRecord.coordinate.lng,
            18)
            .subscribe(response => {
                this.trackingMission4.realTimeRecord.geocoding = response.display_name;
            }, err => {
                //   this.toastr.warning("Inverse geocoding");
            });

    }

    /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
    inverseGeocoding5() {
        this.geocodingService.inverseGeoconding(
            this.trackingMission5.realTimeRecord.coordinate.lat,
            this.trackingMission5.realTimeRecord.coordinate.lng,
            18)
            .subscribe(response => {
                this.trackingMission5.realTimeRecord.geocoding = response.display_name;
            }, err => {
                //   this.toastr.warning("Inverse geocoding");
            });

    }

    /** IVERSE GEO CODING FOR REAL TIME TRACKING*/
    inverseGeocoding6() {
        this.geocodingService.inverseGeoconding(
            this.trackingMission6.realTimeRecord.coordinate.lat,
            this.trackingMission6.realTimeRecord.coordinate.lng,
            18)
            .subscribe(response => {
                this.trackingMission6.realTimeRecord.geocoding = response.display_name;
            }, err => {
                //   this.toastr.warning("Inverse geocoding");
            });

    }

}