import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionReturnTrackingComponent } from './mission-return-tracking.component';
import { ToastrModule } from 'ngx-toastr';  // Ensure this is imported if you're using Toastr
//import { NgxDatatableModule } from 'ngx-datatable';  // Include if you're using ngx-datatable
import { FormsModule } from '@angular/forms';  // Include if you're using forms in your component
import { HttpClientModule } from '@angular/common/http'; // Include for HTTP requests
import { ScheduleService } from '../schedule/schedule.service'; // Adjust path if needed
//import { PassageService } from '../schedule/passage.service'; // Adjust path if needed
import { TransportTypeService } from '../parametrage/transport-type/transport-type.service'; // Adjust path if needed
import { GeocodingService } from 'src/app/utils/leaflet/service/geocoding.service'; // Adjust path if needed
import { PassageService } from '../schedule';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { TimediffPipeModule } from 'src/app/pipes/timediff.pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';

@NgModule({
  declarations: [
    MissionReturnTrackingComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(), // Configure Toastr if needed
    NgxLoadingModule.forRoot({}),
    FormsModule, // Include FormsModule for template-driven forms
    HttpClientModule, // Include HttpClientModule for HTTP requests
    SharedPipesModule,
    TimediffPipeModule,
    MatTooltipModule,
    TruncateModule

  ],
  providers: [
    ScheduleService,
    PassageService,
    TransportTypeService,
    GeocodingService
  ],
  exports: [
    MissionReturnTrackingComponent // Export the component if used in other modules
  ]
})
export class MissionReturnTrackingModule {}
