import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountFormComponent } from './account-form.component';

import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PassageService } from '../../charges-pfb';
import { NgxLoadingModule } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';


@NgModule({
  declarations: [
    AccountFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    SharedPipesModule,
    MomentModule,
    MatSlideToggleModule,
    TruncateModule
  ],
  providers: [
    PassageService,
  ],
  exports: [
    AccountFormComponent
  ]
})
export class AccountFormModule { }
