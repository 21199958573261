import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatDurationPipe } from './format-duration.pipe';  // Adjust the import path if necessary

@NgModule({
  declarations: [FormatDurationPipe],  // Declare the pipe here
  imports: [CommonModule],  // Import CommonModule to access common Angular functionalities
  exports: [FormatDurationPipe]  // Export the pipe so it can be used in other modules
})
export class FormatDurationPipesModule {}
