import { Component, OnInit, ViewChild } from '@angular/core';
import { imagesCarsDir } from '../../../global.config';
import { jqxGaugeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgauge';
//import { RealTimeRecord } from '../../real-time/real-time';
import { TechnicalDataService } from '../technical-data/technical-data.service';
import { DeviceOpt, DeviceCarb } from '../technical-data/technical-data';
import { RealTimeRecord } from '../../realtime/model/real-time.model';
import { ToolKmJourService } from '../../realtime/services/tool-km-jour.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  imagesCarsDir: string = imagesCarsDir;

  isMinifyDashboard: boolean = true;
  opened: boolean = false;

  // Dashboard values
  valueRpm: number = 0;
  valueSpeed: number = 0;
  valueFuel: number = 0;

  valueTemp: number = 60;
  valueAccumOdo: number | null = null;
  valueFuelRate: number | null = null;
  valueTfu: number | null = null;


  ticksMinorRpm: any;
  ticksMajorRpm: any;
  styleRpm: any;
  borderRpm: any;
  labelsRpm: any;

  /***SPEED Gauge***/
  ticksMinorSpeed: any;
  ticksMajorSpeed: any;

  /***TEMP Gauge***/
  ticksMinorTemp: any;
  ticksMajorTemp: any;
  labelsTemp: any;

  /***FUEL Gauge***/
  ticksMinorFuel: any;
  ticksMajorFuel: any;
  labelsFuel: any;

  //Device Options
  deviceopt: DeviceOpt;
  deviceopts: DeviceOpt[];

  //Device Carburant
  deviceCarb: DeviceCarb;
  deviceCarbs: DeviceCarb[];

  vehiculeName: string;

  @ViewChild('myGaugeRpm', { static: false }) public myGaugeRpm: jqxGaugeComponent;

  constructor(private technicaldataservice: TechnicalDataService, private toolKmJourService: ToolKmJourService) {
  }

  ngOnInit() {
    this.init();
    //load device cart & opt !
    this.getDeviceCarb();
    this.getDeviceOpt();
  }

  init() {
    this.ticksMinorRpm = { interval: 1, size: '5%', visible: false };
    this.ticksMajorRpm = { interval: 1, size: '9%', visible: false };
    this.styleRpm = { stroke: 'transparent', 'stroke-width': '0px', fill: 'transparent' };
    //RPM Gauge
    this.borderRpm = {
      size: '11%', style: { stroke: 'transparent', fill: 'transparent' }, visible: false
    }
    this.labelsRpm = {
      interval: 1,
      visible: false
    }

    //SPEED Gauge
    this.ticksMinorSpeed = { interval: 5, size: '5%', visible: false };
    this.ticksMajorSpeed = { interval: 10, size: '9%', visible: false };

    //TEMP Gauge
    this.ticksMinorTemp = { interval: 5, size: '5%' };
    this.ticksMajorTemp = { interval: 20, size: '9%' };
    this.labelsTemp = {
      interval: 20,
      // visible: false
    };

    //FUEL Gauge
    this.ticksMinorFuel = { interval: 12.5, size: '5%', visible: false };
    this.ticksMajorFuel = { interval: 25, size: '9%', visible: false };
    this.labelsFuel = {
      interval: 50,
      visible: false
    };
  }

  openening() {
    this.opened = !this.opened;
  }

  collapseDashboard() {
    this.isMinifyDashboard = !this.isMinifyDashboard;
  }

  openDashboard() {
    this.isMinifyDashboard = false;
  }

  closeDashboard() {
    this.isMinifyDashboard = false;
  }

  reloadDashboard(realTimeRecord: RealTimeRecord) {
    this.getDashboard(realTimeRecord);
  }

  initDashboard() {
    this.valueAccumOdo = null;
    this.valueFuelRate = null;
    this.valueTfu = null;
  }


  //Get RealTimeRecord data
  getDashboard(realTimeRecord: RealTimeRecord) {
    /**
     * init info
     */
    this.initDashboard();

    /**
     * start
     */
    this.deviceopt = this.getNewDeviceOpt(realTimeRecord.idRealTimeRecord);
    this.deviceCarb = this.getNewDeviceCarb(realTimeRecord.idRealTimeRecord);

    if (this.deviceCarb && this.deviceCarb && realTimeRecord) {

      this.valueSpeed = realTimeRecord.speed;

      //if use FMS and use J1708
      if (this.deviceopt.useFms != 0 || this.deviceopt.useJ1708 != 0) {
        //this.displayFms = true;
        //this.displayTemp = true;
        //this.displayRpm = true;
        if (realTimeRecord.temp_engine >= 60) {
          this.valueTemp = realTimeRecord.temp_engine;
        } else {
          this.valueTemp = 60;
        }
        this.valueSpeed = realTimeRecord.speed;
        this.valueRpm = realTimeRecord.rpm / 1000;
        if (this.deviceCarb.max - this.deviceCarb.min > 0) {
          if ((realTimeRecord.fuel >= this.deviceCarb.min) && (realTimeRecord.fuel <= this.deviceCarb.max)) {
            this.valueFuel = realTimeRecord.fuel;
          }
        } else {
          if ((realTimeRecord.fuel >= this.deviceCarb.max) && (realTimeRecord.fuel <= this.deviceCarb.min)) {
            this.valueFuel = realTimeRecord.fuel;
          }
        }
        //keep !
        this.valueAccumOdo = realTimeRecord.accum_odo;
        this.valueFuelRate = realTimeRecord.fuel_rate;
        this.valueTfu = realTimeRecord.tfu;
      }
      // if use Fuel
      if (this.deviceopt.useFuel != 0) {
        //this.displayFuel = true;
        //this.displayFms = false;
        //this.displayTemp = false;
        //this.displayRpm = false;
        this.valueSpeed = realTimeRecord.speed;
        if (this.deviceCarb.max - this.deviceCarb.min > 0) {
          if ((realTimeRecord.fuel >= this.deviceCarb.min) && (realTimeRecord.fuel <= this.deviceCarb.max)) {
            this.valueFuel = Math.round(((realTimeRecord.fuel - this.deviceCarb.min) / (this.deviceCarb.max - this.deviceCarb.min)) * 100);
          }
        } else {
          if ((realTimeRecord.fuel >= this.deviceCarb.max) && (realTimeRecord.fuel <= this.deviceCarb.min)) {
            this.valueFuel = Math.round(((realTimeRecord.fuel - this.deviceCarb.min) / (this.deviceCarb.max - this.deviceCarb.min)) * 100);
          }
        }
      }
      //if use ignition
      if (this.deviceopt.useIgnition != 0 && realTimeRecord.ignition == false) {
        this.valueTemp = 60;
        this.valueSpeed = 0;
        this.valueRpm = 0;
      }

    }
  }


  //load All Device Options
  getDeviceOpt() {
    if (this.technicaldataservice.deviceopts) {
      this.deviceopts = this.technicaldataservice.deviceopts;
      this.toolKmJourService.setDeviceopts(this.deviceopts);
    } else
      this.technicaldataservice.getAllDeviceOpt().subscribe(response => {
        this.deviceopts = response;
        this.toolKmJourService.setDeviceopts(this.deviceopts);
      });

  }

  //load All Device Carburants
  getDeviceCarb() {
    if (this.technicaldataservice.deviceCarbs) {
      this.deviceCarbs = this.technicaldataservice.deviceCarbs;
    } else
      this.technicaldataservice.getAllDeviceCarb().subscribe(response => {
        this.deviceCarbs = response;
      });
  }

  getNewDeviceOpt(idDevice: number): DeviceOpt {
    let deviceOpt = new DeviceOpt();
    for (let i = 0; i < this.deviceopts.length; i++) {
      if (this.deviceopts[i].idDevice == idDevice) {
        deviceOpt = this.deviceopts[i]
      }
    }
    return deviceOpt;
  }

  getNewDeviceCarb(idDevice: number): DeviceCarb {
    let deviceCarb = new DeviceCarb();
    for (let i = 0; i < this.deviceCarbs.length; i++) {
      if (this.deviceCarbs[i].idDevice == idDevice) {
        deviceCarb = this.deviceCarbs[i]
      }
    }
    return deviceCarb;

  }
}

